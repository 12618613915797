import { create } from "zustand";

export interface IComparisonList {
    type: "server" | "competitor";
    value: string;
    id?: number;
}

interface IPriceCompareData {
    [key: string]: any;
}

type RCPriceCompareStore = {
    mainServer: string | null;
    comparisonList: IComparisonList[];
    servers: string[];
    competitors: string[];
    selectedSymbols: string[];
    priceCompareData: IPriceCompareData;
};
type RCPriceCompareStoreActions = {
    setMainServer: (server: string | null) => void;
    setComparisonList: (list: IComparisonList[]) => void;
    removeEverything: () => void;
    setSelectedSymbols: (symbols: string[]) => void;
    updatePriceCompareData: (key: string, data: any) => void;
};

const useRCPriceCompareStore = create<RCPriceCompareStore & RCPriceCompareStoreActions>(set => ({
    mainServer: "",
    comparisonList: [],
    servers: [],
    competitors: [],
    selectedSymbols: [],
    priceCompareData: {},
    setMainServer: server => set({ mainServer: server }),
    setComparisonList: list => set({ comparisonList: list }),
    removeEverything: () => set({ mainServer: null, servers: [], competitors: [], selectedSymbols: [] }),
    setSelectedSymbols: symbols => set({ selectedSymbols: symbols }),
    updatePriceCompareData: (key, data) => set(state => ({ priceCompareData: { ...state.priceCompareData, [key]: data } })),
}));

export default useRCPriceCompareStore;
