import { Form, Row, Col, Popover, DatePicker, Button, List, Input } from "antd";
import moment from "moment";
import { ComponentType } from "../../constants";
import { FormComponent } from "../FormComponent";
import { KeyValuePair, OptionType } from "../../constants/type";
import { ReactNode, useEffect, useState } from "react";
import { ClockCircleOutlined, CloseCircleFilled, SearchOutlined } from "@ant-design/icons";
import { getAppSettings } from "../../services/localStorage";
import "./relativeDateRange.less";
import { cloneDeep } from "lodash";

export interface RelativeDateRangeProps {
    withForm?: boolean;
    valuesChangedCallback: (values: any) => void;
    optionsList: KeyValuePair[];
}

const RelativeDateRange = (props: RelativeDateRangeProps) => {
    const [dateRangeForm] = Form.useForm();
    const [selectedOption, setSelectedOption] = useState<OptionType | undefined>(undefined);
    const [open, setOpen] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>("");

    const optionsList: KeyValuePair[] = props.optionsList ? props.optionsList : getAppSettings().RELATIVE_DATE_RANGE;
    const [filteredOptionList, setFilteredOptionList] = useState<KeyValuePair[]>(optionsList);

    const onFormSubmit = (values: any) => {
        if (
            values?.hasOwnProperty("dateRange-date") &&
            values["dateRange-date"] !== undefined &&
            values["dateRange-date"] !== null &&
            values["dateRange-date"].length === 2
        ) {
            const dateFrom = moment(values["dateRange-date"][0]).format("YYYY-MM-DD");
            const dateTo = moment(values["dateRange-date"][1]).format("YYYY-MM-DD");
            setSelectedOption({ text: `${dateFrom} to ${dateTo}`, value: "" });
            props.valuesChangedCallback({ dateFrom, dateTo });
        } else {
            return;
        }
    };

    const onSelectOption = (item: any) => {
        dateRangeForm.resetFields();
        setSelectedOption(item);
        if (typeof item.value === "string") {
            let values = item.value.split(",");
            props.valuesChangedCallback({
                relativeDateRange: parseInt(values[0]),
                relativeNValue: parseInt(values[1]),
            });
        } else {
            props.valuesChangedCallback({
                relativeDateRange: item.value,
                // relativeNValue: ,
            });
        }
        setOpen(false);
        setFilteredOptionList(optionsList);
        setSearchText("");
    };

    const getFilteredOption = (itemList: KeyValuePair[], sText: string) => {
        return sText.length > 0 ? cloneDeep(itemList).filter(x => x.text.toLowerCase().indexOf(sText) > -1) : cloneDeep(itemList);
    };

    const onSearchTextChanged = (e: any) => {
        let currentText = e.target.value.toLowerCase();
        let filteredList = optionsList.filter((x: KeyValuePair) => x.text.toLowerCase().indexOf(currentText) > -1);
        setFilteredOptionList(filteredList);
        setSearchText(currentText);
    };

    const getTemplate = (content: ReactNode): ReactNode => {
        return props.withForm ? (
            <Form form={dateRangeForm} onFinish={onFormSubmit}>
                {content}
            </Form>
        ) : (
            content
        );
    };

    const setOptionListData = () => {
        setFilteredOptionList(getFilteredOption(optionsList, searchText));
    };

    useEffect(() => {
        setOptionListData();
        return () => {};
    }, []);

    useEffect(() => {
        setOptionListData();
        return () => {};
    }, [optionsList]);

    return (
        <>
            {getTemplate(
                <Row>
                    <Col span={24}>
                        {/* <FormComponent
                            label="Date Range"
                            name={"dateRange"}
                            extra={{
                                type: ComponentType.daterange2,
                                value: props.optionsList,
                            }}
                        /> */}

                        <Form.Item label={"Date Range"} name={"dateRange"}>
                            <>
                                <Popover
                                    title="Relative Date Range"
                                    trigger="click"
                                    placement="bottom"
                                    open={open}
                                    onOpenChange={(open: boolean) => setOpen(open)}
                                    // onMouseEnter={() => {}}
                                    content={
                                        <div className="date-range2">
                                            <div className="date-range-div">
                                                <Form.Item label={""} name={`dateRange-date`} noStyle>
                                                    <DatePicker.RangePicker
                                                        disabledDate={(current: any): boolean => {
                                                            return current && current > moment().endOf("day");
                                                        }}
                                                        style={{ width: "90%" }}
                                                    />
                                                </Form.Item>
                                                <Form.Item>
                                                    <Button
                                                        type="primary"
                                                        htmlType="submit"
                                                        className="apply-date-range"
                                                        onClick={() => {
                                                            dateRangeForm.validateFields().then(values => {
                                                                onFormSubmit(values);
                                                                setOpen(false);
                                                            });
                                                        }}
                                                    >
                                                        Apply date range
                                                    </Button>
                                                </Form.Item>
                                            </div>
                                            <div className="select-range-div">
                                                <div>
                                                    <FormComponent
                                                        label={""}
                                                        name={"searchInput"}
                                                        extra={{ type: ComponentType.hidden, value: "" }}
                                                    />
                                                    <Input
                                                        name="optionSearch"
                                                        prefix={<SearchOutlined />}
                                                        allowClear
                                                        onChange={onSearchTextChanged}
                                                        placeholder="Search quick ranges"
                                                        className="search-box"
                                                    />
                                                </div>
                                                <div className="custom-select-list nice-scrollbar">
                                                    <List
                                                        size="small"
                                                        dataSource={filteredOptionList}
                                                        renderItem={(item: any, i: number) => (
                                                            <List.Item
                                                                key={`custom-select-option-${i}`}
                                                                className={`relative-date-range-list-item ${
                                                                    selectedOption?.value === item.value ? "selected" : ""
                                                                }`}
                                                                onClick={() => onSelectOption(item)}
                                                            >
                                                                {item.text}
                                                            </List.Item>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                >
                                    <Button type="text" className="popover-btn">
                                        <ClockCircleOutlined />
                                        {selectedOption?.text || "Select relative date range"}
                                    </Button>
                                </Popover>
                                {selectedOption && (
                                    <Button
                                        type="text"
                                        style={{ color: "#00000040" }}
                                        onClick={() => {
                                            setSelectedOption(undefined);
                                            dateRangeForm.resetFields();
                                            props.valuesChangedCallback({});
                                        }}
                                    >
                                        <CloseCircleFilled />
                                    </Button>
                                )}
                            </>
                        </Form.Item>
                    </Col>
                </Row>
            )}
        </>
    );
};

export default RelativeDateRange;
