import { Datum, Line } from "@ant-design/charts";
import { LineChartOutlined } from "@ant-design/icons";
import moment from "moment";
import { useEffect, useState } from "react";
import { SUCCESS_FAILED } from "../../../../../constants";
import { SymbolAnalysisFilterParams } from "../../../../../constants/type";
import { apiRequest, APIs } from "../../../../../services/apiConfig";
import { currencyRender, ErrorCatchValidator, ErrorMessageHandler } from "../../../../../utils/Common";
import { ProfileInfoProps } from "../../viewProfile";
import ChartPanel from "../chartPanel";
import { PNLSymbolProps } from "../pnlSymbolSummary";
import EmptyData from "../../../../../components/Common/Empty";
import CustomSkeleton from "../../../../../components/Common/Skeleton";

export interface DailyPnlCurrSummaryChartProps extends ProfileInfoProps {
    selectedSymbol?: string;
    forPrint?: boolean;
    params: SymbolAnalysisFilterParams;
}

const DailyPnlCurrSymbolChart = (props: DailyPnlCurrSummaryChartProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [pnlDatas, setPnlDatas] = useState<PNLSymbolProps[]>([]);

    useEffect(() => {
        if (props.params.symbols === null || props.params.symbols === undefined || props.params.symbols.length === 0) {
            setPnlDatas([]);
        } else if (props.params.dateTo === "" && props.params.dateFrom === "") {
            delete props.params.dateTo;
            delete props.params.dateFrom;

            setIsLoading(true);
            apiRequest(APIs.ACCOUNT_HISTORICAL_PNL, {
                ...props.params,
                metricType: 2,
                timeFrame: 1,
                metricIds: [72, 99, 102],
                dataSamplingMode: 1,
                dataGroupingMode: 1,
            })
                .then((data: any) => {
                    if (data && data.length > 0) {
                        let markupData: any[] = [];
                        data.map((x: any) => {
                            if (x.symbol === props.selectedSymbol) {
                                x.groupedData.map((z: any) => {
                                    markupData = markupData.concat(
                                        z.plotData.map((zz: any) => ({
                                            name: z.metricName,
                                            date: moment(zz.date).format("YYYY-MM-DD"),
                                            value: zz.value,
                                        }))
                                    );

                                    return false;
                                });
                            }

                            return false;
                        });
                        setPnlDatas(markupData);
                    } else {
                        setPnlDatas([]);
                    }
                })
                .catch((error) => {
                    ErrorCatchValidator(error, (err: any) =>
                        ErrorMessageHandler(`daily pnl data for ${props.selectedSymbol}`, SUCCESS_FAILED.FAILED_LOAD_DATA, err)
                    );
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            setIsLoading(true);
            apiRequest(APIs.ACCOUNT_HISTORICAL_PNL, {
                ...props.params,
                metricType: 2,
                timeFrame: 1,
                metricIds: [72, 99, 102],
                dataSamplingMode: 1,
                dataGroupingMode: 1,
            })
                .then((data: any) => {
                    if (data && data.length > 0) {
                        let markupData: any[] = [];
                        data.map((x: any) => {
                            if (x.symbol === props.selectedSymbol) {
                                x.groupedData.map((z: any) => {
                                    markupData = markupData.concat(
                                        z.plotData.map((zz: any) => ({
                                            name: z.metricName,
                                            date: moment(zz.date).format("YYYY-MM-DD"),
                                            value: zz.value,
                                        }))
                                    );

                                    return false;
                                });
                            }

                            return false;
                        });
                        setPnlDatas(markupData);
                    } else {
                        setPnlDatas([]);
                    }
                })
                .catch((error) => {
                    ErrorCatchValidator(error, (err: any) =>
                        ErrorMessageHandler(`daily pnl data for ${props.selectedSymbol}`, SUCCESS_FAILED.FAILED_LOAD_DATA, err)
                    );
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
        return () => {};
    }, [props.params]);

    return (
        <>
            {isLoading ? (
                <ChartPanel forPrint={props.forPrint} type={1} icon={<LineChartOutlined />} title={"PNL"} subTitle={"Daily pnl for current symbol"}>
                    <CustomSkeleton rows={7} />
                </ChartPanel>
            ) : (
                <ChartPanel forPrint={props.forPrint} type={1} icon={<LineChartOutlined />} title={"PNL"} subTitle={"Daily pnl for current symbol"}>
                    <div className="chart">
                        {pnlDatas?.length > 0 ? (
                            <Line
                                {...{
                                    data: pnlDatas,
                                    appendPadding: 20,
                                    xField: "date",
                                    yField: "value",
                                    seriesField: "name",
                                    ...(props.forPrint
                                        ? {
                                              xAxis: {
                                                  label: {
                                                      autoRotate: true,
                                                  },
                                                  tickLine: {
                                                      alignTick: true,
                                                  },
                                              },
                                          }
                                        : {
                                              xAxis: {
                                                  label: {
                                                      autoRotate: true,
                                                  },
                                                  tickLine: {
                                                      alignTick: true,
                                                  },
                                              },
                                              slider: {
                                                  start: 0,
                                                  end: 1,
                                              },
                                          }),
                                    yAxis: {
                                        label: {
                                            formatter: (v: any) => currencyRender(v),
                                        },
                                    },
                                    tooltip: {
                                        showMarkers: false,
                                        formatter: (datum: Datum) => {
                                            return { name: datum.name, value: currencyRender(datum.value, 2) };
                                        },
                                    },
                                }}
                            />
                        ) : (
                            <>
                                <EmptyData />
                            </>
                        )}
                    </div>
                </ChartPanel>
            )}
        </>
    );
};

export default DailyPnlCurrSymbolChart;
