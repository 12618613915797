import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import HSGroupTemplate, { HSGroupTemplateProps } from "./components/groupTemplate";
import { Button, Form, Input, message, Select } from "antd";
import { ArrowRightOutlined, CloseOutlined, DragOutlined, SaveOutlined, SearchOutlined, SwapOutlined, SyncOutlined } from "@ant-design/icons";
import { APIs, apiRequest } from "../../../services/apiConfig";
import LoadingComponent from "../../../components/Loading";
import EmptyData from "../../../components/Common/Empty";
import MultiColumnDnD from "../../../components/DropAndDrop/MultiColumnDnD";
import { DND_ACTION, SUCCESS_FAILED } from "../../../constants";
import { DnDCallback } from "../../../constants/type";
import { ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";
import { hasAnyKey, objectRemoveProps } from "../../../utils/object";
import moment from "moment";
import AuthHelper, { AuthKeys } from "../../../helpers/authHelper";
import { caseInsensitiveCompare } from "../../../utils/string";
import { SortList } from "../../../utils/array";
import { HSGroupContentProps, HSGroupItemProps } from "./components/contentTemplate";
import { checkHubSwitchingAvailable } from "../../../services/localStorage";
import { getModeSource } from "../HubSwitcher";

export interface HubStatusDashboardProps { }

interface RBrandProps {
    brandName: string;
    sequence: number;
    securityGroups: RSecurityProps[];
}

interface RSecurityProps {
    groupName: string;
    details: RDetailsProps[];
}

interface RDetailsProps {
    index: number;
    ozStatus: number;
    peStatus: number;
    security: string;
    server: string;
    serverCode: string;
    serverId: number;
}

interface ArrangementProps {
    brandOrder: any;
    securityOrder: any;
}

const sortingOptions = [
    { label: "Server ASC", value: "server|ASC|security" },
    { label: "Server DESC", value: "server|DESC|security" },
    { label: "Security ASC", value: "security|ASC|server" },
    { label: "Security DESC", value: "security|DESC|server" },
];

export const CHECKBOX_KEYS = {
    BrandKey: "cbab-",
    GroupKey: "cbag-",
};

const HubStatusDashboard = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<HSGroupTemplateProps[]>([]);
    const [searchText, setSearchText] = useState<string>("");
    const [sortBy, setSortBy] = useState<string>(sortingOptions[0].value);
    const [enableDraggable, setEnableDraggable] = useState<boolean>(false);
    const [startSwitching, setStartSwitching] = useState<boolean>(false);
    const [defaultArrangement, setDefaultArrangement] = useState<any>(undefined);
    const [lastUpdatedTime, setLastUpdatedTime] = useState<string>("");

    const arrangement = useRef<ArrangementProps>({ brandOrder: {}, securityOrder: {} });
    const authHp = new AuthHelper();
    const allowEdit = authHp.isAuthorized(AuthKeys.ADMIN_TOOLS_HUB_STATUS_DASHBOARD_EDIT);
    const [selectionForm] = Form.useForm();
    const [tabs, setTabs] = useState<Window[]>([]);

    const filteredData: HSGroupTemplateProps[] = useMemo(() => {
        let returnData: any = data,
            lowerSearchText = searchText.toLowerCase(),
            sortArr = sortBy.split("|");

        if (lowerSearchText.length > 0) {
            // let tmpData = data
            //     .map((x) => ({
            //         ...x,
            //         data:
            //             x.name.toLowerCase().indexOf(lowerSearchText) > -1
            //                 ? x.data
            //                 : x.data
            //                       .filter(
            //                           (y) =>
            //                               y.groupName.toLowerCase().indexOf(lowerSearchText) > -1 ||
            //                               y.data.some(
            //                                   (z) =>
            //                                       z.server.toLowerCase().indexOf(lowerSearchText) > -1 ||
            //                                       z.security.toLowerCase().indexOf(lowerSearchText) > -1
            //                               )
            //                       )
            //                       .map((y) => ({
            //                           ...y,
            //                           data:
            //                               y.groupName.toLowerCase().indexOf(lowerSearchText) > -1
            //                                   ? y.data
            //                                   : y.data.filter(
            //                                         (z) =>
            //                                             z.server.toLowerCase().indexOf(lowerSearchText) > -1 ||
            //                                             z.security.toLowerCase().indexOf(lowerSearchText) > -1
            //                                     ),
            //                       })),
            //     }))
            //     .filter((x) => x.data.length > 0);
            let tmpData = data.map(x => ({
                ...x,
                data: x.data.map(y => ({
                    ...y,
                    data: SortList(
                        y.data.filter(
                            z =>
                                caseInsensitiveCompare(z.server, lowerSearchText, true) ||
                                caseInsensitiveCompare(z.security, lowerSearchText, true) ||
                                caseInsensitiveCompare(z.clusterName, lowerSearchText, true)
                        ),
                        sortArr[0],
                        (a: any, b: any) => {
                            let firstCompare =
                                sortArr[1] === "ASC"
                                    ? a[sortArr[0]].toLowerCase().localeCompare(b[sortArr[0]].toLowerCase())
                                    : b[sortArr[0]].toLowerCase().localeCompare(a[sortArr[0]].toLowerCase());
                            if (firstCompare === 0) {
                                return a[sortArr[2]].toLowerCase().localeCompare(b[sortArr[2]].toLowerCase());
                            }

                            return firstCompare;
                        }
                    ),
                })),
            }));

            returnData = tmpData.length > 0 ? tmpData : [];
        } else {
            returnData = data.map(x => ({
                ...x,
                data: x.data.map(y => ({
                    ...y,
                    data: SortList(y.data, sortArr[0], undefined, sortArr[1] as any),
                })),
            }));
        }

        return returnData;
    }, [data, searchText, sortBy]);

    const cleanArrangement = useMemo(() => {
        if (filteredData.length > 0) {
            let currObj = { ...defaultArrangement },
                availableBrandName = filteredData.map(x => x.name);
            if (currObj?.hasOwnProperty("brandOrder") && hasAnyKey(currObj.brandOrder)) {
                currObj.brandOrder = Object.keys(currObj.brandOrder).reduce((finalObj: any, x: string) => {
                    finalObj[x] = currObj.brandOrder[x].filter((y: string) => availableBrandName.includes(y));
                    return finalObj;
                }, {});
            }

            if (currObj?.hasOwnProperty("securityOrder") && hasAnyKey(currObj.securityOrder)) {
                currObj.securityOrder = Object.keys(currObj.securityOrder).reduce((finalObj: any, x: string) => {
                    if (availableBrandName.includes(x)) {
                        let fIdx: number = filteredData.findIndex(y => y.name === x);
                        if (fIdx > -1) {
                            let availableSecurityGroup: string[] = filteredData[fIdx].data.map(z => z.groupName);
                            finalObj[x] = currObj.securityOrder[x].filter((y: string) => availableSecurityGroup.includes(y));
                        }
                    }
                    return finalObj;
                }, {});
            }
            return currObj;
        }
        return undefined;
    }, [defaultArrangement, filteredData]);

    const processSwitching = useCallback(
        (selectedKeys: string[]) => {
            let tmoObj = filteredData.reduce((finalObj: any, x: HSGroupTemplateProps) => {
                if (x.data.length > 0) {
                    x.data.forEach((y: HSGroupContentProps) => {
                        if (y.data.length > 0) {
                            y.data
                                .filter((z: HSGroupItemProps) => selectedKeys.includes(`cb-${y.gKey}-${z.serverCode}-${z.security}`))
                                .forEach((z: HSGroupItemProps) => {
                                    finalObj[`${z.serverCode}-${z.security}`] = objectRemoveProps(
                                        { ...z, sourceStatus: z.ozStatus, targetStatus: z.peStatus },
                                        ["ozStatus", "peStatus"]
                                    );
                                });
                        }
                    });
                }

                return finalObj;
            }, {}),
                selectedKeysArray = Object.keys(tmoObj).map(x => tmoObj[x]);

            if (selectedKeysArray.length === 0) {
                message.warning("Please select at least 1 record for switching.");
                return;
            }

            let tmpArr: number[] = selectedKeysArray.map((x: any) => x.sourceStatus),
                sourceDirection = tmpArr[0];
            if (tmpArr.some(x => x !== sourceDirection)) {
                message.warning("Some securities status is not same between others security.");
                return;
            }

            let postParam = { ...getModeSource("1"), managerSecurities: selectedKeysArray },
                hexParam = btoa(JSON.stringify(postParam));

            if (!checkHubSwitchingAvailable(hexParam)) {
                message.warning("Switching process too frequent. Please try again shortly.");
                return;
            }

            //setStartSwitching(false);
            // window.open(`/admintools/hubSwitcher/preview/${res.batchId}`, "_blank", "noopener,noreferrer")?.focus();
            //setIsLoading(false);

            openNewWindow(selectedKeysArray);
            setTimeout(() => {
                selectionForm.resetFields();
                setStartSwitching(false);
                setSearchText("");
                getHubStatusList();
            }, 1000);
        },
        [filteredData]
    );

    const openNewWindow = (selectedKeysArray: any[]) => {
        const newWindow = window.open(`/admintools/hubSwitcher/preview`, `_blank`) as Window | null;
        if (newWindow) {
            newWindow.onload = () => {
                newWindow.postMessage(selectedKeysArray, "*");
            };
        } else {
            message.error("Failed to open new window. It might have been blocked by the browser.");
        };

        if (newWindow !== null) setTabs((prevTabs) => [...prevTabs, newWindow]);

        // to monitor the new tab
        const interval = setInterval(() => {
            if (newWindow && newWindow.closed) {
                clearInterval(interval);
                // Optionally, remove the closed tab from the state
                setTabs((prevTabs) => prevTabs.filter(tab => tab !== newWindow));
                getHubStatusList();
            }
        }, 1000); // Checking every second
    };

    const onValuesChange = (changedValues: any, allValues: any) => {
        Object.keys(changedValues).forEach((x: string) => {
            if (x.startsWith(CHECKBOX_KEYS.BrandKey)) {
                let brandCode = x.split(CHECKBOX_KEYS.BrandKey)[1],
                    targetChecked = changedValues[x];
                selectionForm.setFieldsValue(
                    Object.keys(allValues)
                        .filter(y => y.startsWith(`cb-${brandCode}-`) || y.startsWith(`${CHECKBOX_KEYS.GroupKey}${brandCode}-`))
                        .reduce((finalObj: any, y: string) => {
                            finalObj[y] = targetChecked;
                            return finalObj;
                        }, {})
                );
            } else if (x.startsWith(CHECKBOX_KEYS.GroupKey)) {
                let xArr = x.split("-"),
                    brandCode = xArr[1],
                    groupCode = xArr[2],
                    targetChecked = changedValues[x],
                    allCBKeys = Object.keys(allValues).filter(y => y.startsWith(`${CHECKBOX_KEYS.GroupKey}${brandCode}`)),
                    allCBCheckedKeys = allCBKeys.filter(y => allValues[y]);

                selectionForm.setFieldsValue(
                    Object.keys(allValues)
                        .filter(y => y.startsWith(`cb-${brandCode}-${groupCode}`))
                        .reduce((finalObj: any, y: string) => {
                            finalObj[y] = targetChecked;
                            return finalObj;
                        }, {})
                );

                if (allCBKeys.length === allCBCheckedKeys.length) {
                    selectionForm.setFieldsValue({ [`${CHECKBOX_KEYS.BrandKey}${brandCode}`]: true });
                } else if (allValues[`${CHECKBOX_KEYS.BrandKey}${brandCode}`]) {
                    selectionForm.setFieldsValue({ [`${CHECKBOX_KEYS.BrandKey}${brandCode}`]: false });
                }
            } else if (x.startsWith("cb-")) {
                let xArr = x.split("-"),
                    brandCode = xArr[1],
                    groupCode = xArr[2],
                    allCBKeys = Object.keys(allValues).filter(y => y.startsWith(`cb-${brandCode}-`)),
                    allCBCheckedKeys = allCBKeys.filter(y => allValues[y]),
                    groupKeys = allCBKeys.filter(y => y.startsWith(`cb-${brandCode}-${groupCode}`)),
                    groupCheckedKeys = groupKeys.filter(y => allValues[y]);

                if (allCBKeys.length === allCBCheckedKeys.length) {
                    selectionForm.setFieldsValue({ [`${CHECKBOX_KEYS.BrandKey}${brandCode}`]: true });
                } else if (allValues[`${CHECKBOX_KEYS.BrandKey}${brandCode}`]) {
                    selectionForm.setFieldsValue({ [`${CHECKBOX_KEYS.BrandKey}${brandCode}`]: false });
                }

                if (groupKeys.length === groupCheckedKeys.length) {
                    selectionForm.setFieldsValue({ [`${CHECKBOX_KEYS.GroupKey}${brandCode}-${groupCode}`]: true });
                } else if (allValues[`${CHECKBOX_KEYS.GroupKey}${brandCode}-${groupCode}`]) {
                    selectionForm.setFieldsValue({ [`${CHECKBOX_KEYS.GroupKey}${brandCode}-${groupCode}`]: false });
                }
            }
        });
    };

    const _itemTemplate = useCallback(
        (item: any, idx: number, provider: any, isDragDisabled: boolean, enableSwitching: boolean, callback?: DnDCallback) => (
            <HSGroupTemplate
                gKey={item.gKey}
                name={item.name}
                data={item.data}
                provider={provider}
                startSwitching={enableSwitching}
                isDragDisabled={isDragDisabled}
                {...(callback && {
                    callback: callback,
                })}
                {...(cleanArrangement !== undefined &&
                    cleanArrangement?.hasOwnProperty("securityOrder") && {
                    cleanArrangement: cleanArrangement["securityOrder"],
                })}
            />
        ),
        [cleanArrangement]
    );

    const getHubStatusList = () => {
        apiRequest(APIs.GET_HUB_STATUS_LIST, { source: "onezero", target: "priceengine" })
            .then((res: RBrandProps[]) => {
                if (res.length > 0) {
                    let unSortedList = [...res];
                    unSortedList.sort((a: RBrandProps, b: RBrandProps) => a.sequence - b.sequence);
                    setData(
                        unSortedList.map((x: RBrandProps, xIdx: number) => ({
                            gKey: `b${xIdx}`,
                            name: x.brandName,
                            data: x.securityGroups.map((y: RSecurityProps, yIdx: number) => ({
                                gKey: `b${xIdx}-g${yIdx}`,
                                groupName: y.groupName,
                                data: y.details,
                            })),
                        }))
                    );
                }
                setLastUpdatedTime(moment().format("DD MMM YYYY HH:mm:ssZZ"));
            })
            .finally(() => setIsLoading(false));
    };

    const updateSetting = (values: any) => {
        apiRequest(APIs.UPDATE_SETTING_BY_KEY, {
            key: "HubFailoverSwitchDashboardArrangement",
            value: JSON.stringify(values),
        })
            .then(() => {
                setDefaultArrangement(values);
            })
            .catch(error =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("hub failover arrangement", SUCCESS_FAILED.FAILED_UPDATE_DATA, err))
            );
    };

    useEffect(() => {
        apiRequest(APIs.GET_SETTING_LIST, { keys: ["HubFailoverSwitchDashboardArrangement"] })
            .then(res => {
                if (res.length > 0) {
                    try {
                        arrangement.current = JSON.parse(res[0].value);
                        setDefaultArrangement(arrangement.current);
                    } catch (error) { }
                }
            })
            .finally(() => getHubStatusList());
    }, []);

    return (
        <div className="hs-status-dashboard-container">
            <div className="title-container">
                <div className="left">
                    <span className="title-text">Hub Status Dashboard</span>
                </div>
            </div>
            <div className="top-panel shadow-light">
                <div className="left">
                    <Input.Group compact>
                        <Select
                            className="sorting-element"
                            placeholder="Sort By"
                            options={sortingOptions}
                            value={sortBy}
                            onChange={(value: string) => setSortBy(value)}
                        />
                        <Input
                            type="text"
                            name="search"
                            placeholder="Search"
                            className="search-input"
                            prefix={<SearchOutlined style={{ fontSize: "0.75rem" }} />}
                            allowClear
                            onChange={(e: any) => setSearchText(e.currentTarget.value)}
                        />
                    </Input.Group>
                    <div className="btn-groups">
                        {allowEdit && enableDraggable ? (
                            <Button
                                type="primary"
                                icon={<SaveOutlined />}
                                onClick={() => {
                                    updateSetting(arrangement.current);
                                    setEnableDraggable(false);
                                }}
                            >
                                Save
                            </Button>
                        ) : (
                            <Button
                                type="default"
                                icon={<DragOutlined />}
                                onClick={() => {
                                    setEnableDraggable(true);
                                    setStartSwitching(false);
                                }}
                            >
                                Re-arrange
                            </Button>
                        )}
                    </div>
                    <div className="btn-groups">
                        {allowEdit && startSwitching ? (
                            <>
                                <Button
                                    type="primary"
                                    icon={<ArrowRightOutlined />}
                                    onClick={() => {
                                        selectionForm.validateFields().then(values => {
                                            processSwitching(Object.keys(values).filter(x => values[x] && x.startsWith("cb-")));
                                        });
                                    }}
                                >
                                    Go to Preview Switching
                                </Button>
                                <Button
                                    danger
                                    type="primary"
                                    icon={<CloseOutlined />}
                                    onClick={() => {
                                        setStartSwitching(false);
                                    }}
                                >
                                    Cancel
                                </Button>
                            </>
                        ) : (
                            <Button
                                type="default"
                                icon={<SwapOutlined />}
                                onClick={() => {
                                    let allfields: any = selectionForm.getFieldValue(undefined),
                                        allCheckedKeys = Object.keys(allfields).filter((x: string) => allfields[x]);
                                    selectionForm.setFieldsValue(
                                        allCheckedKeys.reduce((finalObj: any, x: string) => {
                                            finalObj[x] = false;
                                            return finalObj;
                                        }, {})
                                    );
                                    setStartSwitching(true);
                                    setEnableDraggable(false);
                                }}
                            >
                                Switching
                            </Button>
                        )}
                    </div>
                </div>
                <div className="right">
                    <div className="resync-div">
                        <span className="time-panel">{isLoading ? <LoadingComponent /> : `Last Refresh Time: ${lastUpdatedTime}`}</span>
                        <Button type="primary" icon={<SyncOutlined />} onClick={() => getHubStatusList()} />
                    </div>
                </div>
            </div>
            {isLoading ? (
                <></>
            ) : filteredData.length === 0 ? (
                <EmptyData />
            ) : (
                <div className={`item-container ${enableDraggable ? "draggable" : ""}`}>
                    <Form form={selectionForm} layout="inline" onValuesChange={onValuesChange}>
                        <MultiColumnDnD
                            data={filteredData}
                            itemTemplate={_itemTemplate}
                            options={{ propertyId: "name" }}
                            propertyId={"hfo-brd"}
                            startSwitching={startSwitching}
                            isDragDisabled={!enableDraggable}
                            callback={(action: DND_ACTION, item: any) => {
                                if (action === DND_ACTION.DRAGGABLE_END) {
                                    if (item?.hasOwnProperty("id") && item?.hasOwnProperty("newOrder")) {
                                        if (item["id"] === "hfo-brd") {
                                            arrangement.current = {
                                                ...arrangement.current,
                                                brandOrder: item["newOrder"],
                                            };
                                        } else if (item["id"].indexOf("hfo-brd-gp-") === 0) {
                                            arrangement.current = {
                                                ...arrangement.current,
                                                securityOrder: {
                                                    ...arrangement.current.securityOrder,
                                                    [item["id"].substring(11)]: item["newOrder"],
                                                },
                                            };
                                        }
                                    }
                                }
                            }}
                            {...(cleanArrangement !== undefined &&
                                cleanArrangement?.hasOwnProperty("brandOrder") && {
                                defaultArrangement: cleanArrangement["brandOrder"],
                            })}
                        />
                    </Form>
                </div>
            )}
            { }
        </div>
    );
};

export default HubStatusDashboard;
