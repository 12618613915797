import { Column } from "@ant-design/charts";
import { useMemo } from "react";

export interface GroupChartsProps {}

const GroupCharts = (props: GroupChartsProps) => {
    const randomObject = (from: number, to: number, rangeValue: number) => {
        // Step 1: Create a random value between from and to
        const randomValue = Math.floor(Math.random() * (to - from + 1)) + from;

        // Step 2: Generate a random value within a range of 70 below and 70 above the random value
        const lowerRange = randomValue - rangeValue;
        const upperRange = randomValue + rangeValue;
        const randomInRange = Math.floor(Math.random() * (upperRange - lowerRange + 1)) + lowerRange;

        // Return the result
        return Math.abs(randomInRange);
    };

    const timeWithInterval = (from: string, to: string, interval: number) => {
        const result = [];
        let currentTime = new Date(`2000-01-01 ${from}`);
        const endTime = new Date(`2000-01-01 ${to}`);

        while (currentTime <= endTime) {
            result.push(currentTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }).replace(/\s?[APMapm]{2}/, ""));
            currentTime.setMinutes(currentTime.getMinutes() + interval);
        }

        return result;
    };

    const data = useMemo(() => {
        return timeWithInterval("00:00", "23:00", 1).reduce((Obj: any[], x: string) => {
            return Obj.concat(["Vantage", "IC", "PD"].map((y) => ({ time: x, type: y, value: randomObject(0, 14, 4) })));
        }, []);
    }, []);

    const config = {
        data,
        xField: "time",
        yField: "value",
        seriesField: "type",
        isGroup: true,
        columnStyle: {
            radius: [20, 20, 0, 0],
        },
    };

    return (
        <>
            <Column {...config} />
        </>
    );
};

export default GroupCharts;
