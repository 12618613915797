import { useCallback, useEffect, useState } from "react";
import FlexiDataTable from "../../../../../components/FlexiDataTable";
import { FlexiDataTableOptionsProps, FlexiDataTableCallbackProps } from "../../../../../constants/type";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "../../../../../constants";
import { ReasonProps } from "..";
import { Modal, Form } from "antd";
import { FormComponent } from "../../../../../components/FormComponent";
import { REQUIRED_FIELD } from "../../../../../constants/errorMessage";
import { plainAxiosInstance } from "../../../../../services/axiosSetup";
import { APIs } from "../../../../../services/apis";
import { ErrorCatchValidator, ErrorMessageHandler } from "../../../../../utils/Common";

export interface GroupTransferSettingsPageProps {
    isLoading: boolean;
    reasonList: ReasonProps[];
    callback: (type: GroupTransferSettingsPageCallbackKey, FormData: any) => void;
}

export enum GroupTransferSettingsPageCallbackKey {
    RefetchData = 1,
}

const GroupTransferSettingsPage = (props: GroupTransferSettingsPageProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [isCreate, setIsCreate] = useState<boolean>(false);
    const [settingForm] = Form.useForm();

    const columns = [
        {
            title: "Reason",
            dataIndex: "reason",
            key: "reason",
        },
    ];

    const options: FlexiDataTableOptionsProps = {
        enableFilter: false,
        showHideColumns: false,
        add: true,
        edit: true,
        delete: true,
    };

    const componentCallback: FlexiDataTableCallbackProps = (type: number, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                settingForm.setFieldsValue({ id: 0, reason: "" });
                setIsCreate(true);
                setIsModalVisible(true);
                break;
            case CALLBACK_KEY.DO_EDIT:
                settingForm.setFieldsValue(FormData);
                setIsCreate(false);
                setIsModalVisible(true);
                break;
            case CALLBACK_KEY.DO_DELETE:
                deleteReason(FormData);
                break;
            default:
                break;
        }
    };

    const updateReason = (values: ReasonProps) => {
        setIsLoading(true);
        plainAxiosInstance
            .post(APIs.RISK_TOOL.EDIT_GROUP_TRANSFER_TOOL_SETTING, values)
            .then((res: any) => {
                if (res.data > 0) {
                    ErrorMessageHandler("front desk reason", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                    refetchListing();
                } else {
                    ErrorMessageHandler("front desk reason", SUCCESS_FAILED.FAILED_UPDATE_DATA);
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("front desk reason", SUCCESS_FAILED.FAILED_UPDATE_DATA, err))
            )
            .finally(() => setIsLoading(false));
    };

    const deleteReason = (values: ReasonProps) => {
        setIsLoading(true);
        plainAxiosInstance
            .post(APIs.RISK_TOOL.DELETE_GROUP_TRANSFER_TOOL_SETTING, values)
            .then((res: any) => {
                if (res.data > 0) {
                    ErrorMessageHandler("front desk reason", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                    refetchListing();
                } else {
                    ErrorMessageHandler("front desk reason", SUCCESS_FAILED.FAILED_DELETE_DATA);
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("front desk reason", SUCCESS_FAILED.FAILED_DELETE_DATA, err))
            )
            .finally(() => setIsLoading(false));
    };

    const refetchListing = useCallback(() => props.callback(GroupTransferSettingsPageCallbackKey.RefetchData, {}), [props.callback]);

    return (
        <>
            <div className="reason-settings-container">
                <FlexiDataTable
                    bordered
                    rowKeyProperty="id"
                    title={<span style={{ fontSize: "1rem", fontWeight: "bold", color: "#000000" }}>Front Desk Reason Listing</span>}
                    columns={columns}
                    dataSource={props.reasonList}
                    options={options}
                    callback={componentCallback}
                    loading={props.isLoading || isLoading}
                />
            </div>
            <Modal
                style={{ top: 10 }}
                width={"40vw"}
                title={isCreate ? "Create Front Desk Reason" : "Edit Front Desk Reason"}
                open={isModalVisible}
                okText="Submit"
                onOk={() => {
                    settingForm
                        .validateFields()
                        .then((values: any) => {
                            updateReason(values);
                            setIsModalVisible(false);
                        })
                        .catch((err: any) => console.log(err));
                }}
                onCancel={() => setIsModalVisible(false)}
                maskClosable={false}
            >
                <Form form={settingForm} layout="horizontal" labelCol={{ span: 7 }} wrapperCol={{ span: 17 }}>
                    <FormComponent label="" name="id" extra={{ type: ComponentType.hidden, value: "" }} />
                    <FormComponent
                        label="Reason"
                        name="reason"
                        extra={{
                            type: ComponentType.text,
                            value: "",
                            rules: [{ required: true, message: REQUIRED_FIELD }],
                        }}
                    />
                </Form>
            </Modal>
        </>
    );
};

export default GroupTransferSettingsPage;
