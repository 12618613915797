import { CheckOutlined, CloseOutlined, EditOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import TinyTag from "../../../../components/Common/Tag";
import { FormComponent } from "../../../../components/FormComponent";
import { ComponentType } from "../../../../constants";
import { ItemProps } from "./AlarmFilterPanel";
import { useCallback, useMemo, useState } from "react";
import { ARRAY_ACTION_TYPE, GetConstraintKeyList, SortList, ToObjectWithKey } from "../../../../utils/array";
import CollapsePanel from "../../../../components/Common/CollapsePanel";
import { FormInstance } from "antd/es/form";
import { caseInsensitiveCompare, isEmptyOrNull } from "../../../../utils/string";
import { KeyValuePair } from "../../../../constants/type";
import ElasticSearchInput from "../../../../components/ElasticSearchInput";
import { apiRequest } from "../../../../services/apiConfig";
import { APIs } from "../../../../services/apis";
import { NamePath } from "antd/lib/form/interface";
import { DefaultIfEmpty } from "../../../../utils/object";
import IBRebateAccountInput, { IBRebateTagTextMarkup } from "../../../../components/FormComponent/IBRebateAccounts";

export interface FilterUpdateTemplateProps {
    form: FormInstance;
    parentFieldName: string;
    componentId: NamePath;
    settings: ItemProps;
    resetState: number;
}

interface SettingsObjProps {
    isRebateAccountSearch: boolean;
    isDropdownBulkSearch: boolean;
    isCheckBoxGroup: boolean;
    title: string;
    componentType: string;
    allOptionIds: (string | number | boolean)[];
    options: KeyValuePair[];
}

const FilterUpdateTemplate = (props: FilterUpdateTemplateProps) => {
    const filterInNameKey = useMemo(() => [props.parentFieldName, props.componentId, "FilterIn"], [props.parentFieldName, props.componentId]);
    const cSettings: SettingsObjProps = useMemo(() => {
        let isCheckBox = props.settings.componentType === ComponentType.checkboxgroup;
        return {
            isRebateAccountSearch: props.settings.componentType === "rebateAccountSearch",
            isDropdownBulkSearch: props.settings.componentType === ComponentType.dropdown,
            isCheckBoxGroup: isCheckBox,
            title: props.settings.title,
            componentType: props.settings.componentType,
            allOptionIds: isCheckBox ? props.settings.options.map(x => x.value) : [],
            options: props.settings.options,
        };
    }, [props.settings]);

    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>("");
    const [oldSelected, setOldSelected] = useState<any[]>([]);
    const watchSelectedKeys = Form.useWatch(filterInNameKey, props.form);
    const selectedKeys = useMemo(() => (watchSelectedKeys ? watchSelectedKeys : []), [watchSelectedKeys]);

    const filteredOptions = useMemo(() => {
        if (cSettings.isCheckBoxGroup) {
            if (isEmptyOrNull(searchText)) return cSettings.options;

            return cSettings.options.map(x => ({ ...x, ...(!caseInsensitiveCompare(x.text, searchText, true) && { style: { display: "none" } }) }));
        } else if (cSettings.isRebateAccountSearch) {
            return ToObjectWithKey(cSettings.options, "value"); // use to replace display server name by server Id
        }
        return [];
    }, [searchText, cSettings]);

    const sortedSelectedKeys = useMemo(() => {
        return cSettings.isCheckBoxGroup
            ? SortList(
                  cSettings.options.filter(x => selectedKeys.includes(x.value)),
                  "text"
              ).map(x => x.text)
            : selectedKeys;
    }, [selectedKeys, cSettings]);

    const isAllOptionSelected: boolean = useMemo(() => {
        if (cSettings.isRebateAccountSearch) return selectedKeys.length === 0;
        else if (cSettings.isDropdownBulkSearch) return selectedKeys.length === 0;

        return GetConstraintKeyList(selectedKeys, cSettings.allOptionIds as any[], ARRAY_ACTION_TYPE.INNER).length === cSettings.allOptionIds.length;
    }, [selectedKeys, cSettings]);

    const selectClearCallback = useCallback(
        (isClear: boolean = true) => {
            if (cSettings.isCheckBoxGroup) {
                let currentFilterOption = filteredOptions as KeyValuePair[];
                if (isClear) {
                    if (isEmptyOrNull(searchText)) {
                        props.form.setFieldValue(filterInNameKey, []);
                    } else {
                        let selectedOpts = GetConstraintKeyList(
                            props.form.getFieldValue(filterInNameKey) || [],
                            currentFilterOption.filter((x: any) => !x.style).map(x => x.value),
                            ARRAY_ACTION_TYPE.LEFT_OUTER
                        );
                        if (selectedOpts.length > 0 && filteredOptions.length > 0) {
                            if (typeof currentFilterOption[0].value === "number") {
                                selectedOpts = selectedOpts.map((x: string) => parseInt(x));
                            } else if (typeof currentFilterOption[0].value === "boolean") {
                                selectedOpts = selectedOpts.map((x: string) => x.toLowerCase() === "true");
                            }
                        }
                        props.form.setFieldValue(filterInNameKey, selectedOpts);
                    }
                } else {
                    if (isEmptyOrNull(searchText)) {
                        props.form.setFieldValue(filterInNameKey, cSettings.allOptionIds);
                    } else {
                        let selectedOpts = GetConstraintKeyList(
                            currentFilterOption.filter((x: any) => !x.style).map(x => x.value),
                            props.form.getFieldValue(filterInNameKey) || [],
                            ARRAY_ACTION_TYPE.UNION_DISTINCT
                        );
                        if (selectedOpts.length > 0 && filteredOptions.length > 0) {
                            if (typeof currentFilterOption[0].value === "number") {
                                selectedOpts = selectedOpts.map((x: string) => parseInt(x));
                            } else if (typeof currentFilterOption[0].value === "boolean") {
                                selectedOpts = selectedOpts.map((x: string) => x.toLowerCase() === "true");
                            }
                        }
                        props.form.setFieldValue(filterInNameKey, selectedOpts);
                    }
                }
            }
        },
        [filteredOptions, searchText, filterInNameKey, cSettings]
    );

    const prepareShowHideEdit = (isShow: boolean, saveChanges: boolean = false) => {
        setIsEditMode(isShow);

        if (isShow) {
            setSearchText("");
            setOldSelected(props.form.getFieldValue(filterInNameKey));
        } else {
            if (!saveChanges) {
                props.form.setFieldValue(filterInNameKey, oldSelected);
            }
        }
    };

    return (
        <div className={`item ${isEditMode ? "" : "view"}`}>
            <FormComponent label="" name={[props.componentId, "DimensionLevel"]} extra={{ type: ComponentType.hidden, value: "" }} />
            {isEditMode ? (
                <CollapsePanel
                    disabledCollapse
                    title={cSettings.title}
                    extraElement={
                        <div>
                            <Button
                                type="default"
                                icon={<CheckOutlined />}
                                onClick={() => prepareShowHideEdit(false, true)}
                                style={{ marginRight: "5px", color: "#004b57" }}
                            />
                            <Button
                                type="default"
                                icon={<CloseOutlined />}
                                onClick={() => prepareShowHideEdit(false)}
                                style={{ marginRight: "5px", color: "#f00f00" }}
                            />
                        </div>
                    }
                    contentStyle={{
                        maxHeight: "28.28vh",
                    }}
                    {...(cSettings.isCheckBoxGroup && {
                        fixedContent: (
                            <div className="features-div">
                                <div className="left">
                                    <Button type="link" size="small" onClick={() => selectClearCallback(false)}>
                                        Select {isEmptyOrNull(searchText) ? "All" : ""}
                                    </Button>
                                    <Button type="link" size="small" onClick={() => selectClearCallback()}>
                                        Clear {isEmptyOrNull(searchText) ? "All" : ""}
                                    </Button>
                                </div>
                                <div className="right">
                                    <Input
                                        type="text"
                                        name="search"
                                        placeholder="Search"
                                        className="search-input"
                                        prefix={<SearchOutlined />}
                                        allowClear
                                        onChange={(e: any) => setSearchText(e.currentTarget.value)}
                                    />
                                </div>
                            </div>
                        ),
                    })}
                >
                    {cSettings.isRebateAccountSearch ? (
                        <IBRebateAccountInput
                            componentId={[props.componentId, "FilterIn"]}
                            formName={filterInNameKey}
                            form={props.form}
                            servers={filteredOptions}
                        />
                    ) : cSettings.isDropdownBulkSearch ? (
                        <FormComponent
                            label=""
                            name={[props.componentId, "FilterIn"]}
                            extra={{
                                type: cSettings.componentType,
                                value: filteredOptions,
                                inputProps: {
                                    mode: "tags",
                                    placeholder: `Please insert keyword(s)`,
                                    tokenSeparators: [",", "，", " "],
                                },
                            }}
                        />
                    ) : (
                        <FormComponent
                            label=""
                            name={[props.componentId, "FilterIn"]}
                            extra={{ type: cSettings.componentType, value: filteredOptions }}
                        />
                    )}
                </CollapsePanel>
            ) : (
                <>
                    <FormComponent label="" name={[props.componentId, "FilterIn"]} extra={{ type: ComponentType.hidden, value: "" }} />
                    <div className="desc-panel">
                        <div className="desc-text">
                            <span className="text">{cSettings.title}</span>
                            {isAllOptionSelected && (
                                <TinyTag key={`cs-fr-${[props.componentId, "FilterIn"].join("_")}-all`} text={`All ${cSettings.title}`} />
                            )}
                        </div>
                        {!isAllOptionSelected && sortedSelectedKeys.length > 0 && (
                            <div className="details nice-scrollbar">
                                {cSettings.isRebateAccountSearch
                                    ? sortedSelectedKeys.map((y: any, idx: number) => (
                                          <TinyTag
                                              key={`cs-fr-${[props.componentId, "FilterIn"].join("_")}-${y}`}
                                              text={IBRebateTagTextMarkup(y, filteredOptions)}
                                          />
                                      ))
                                    : sortedSelectedKeys.map((y: any, idx: number) => (
                                          <TinyTag key={`cs-fr-${[props.componentId, "FilterIn"].join("_")}-${y}`} text={y} />
                                      ))}
                            </div>
                        )}
                    </div>
                    <div className="action-panel">
                        <Button type="text" icon={<EditOutlined />} onClick={() => prepareShowHideEdit(true)} />
                    </div>
                </>
            )}
        </div>
    );
};

export default FilterUpdateTemplate;
