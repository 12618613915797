import React, { useState } from "react";
import FloatingTitleBox from "../../components/FloatingTitleBox";
import { Tabs } from "antd";
import useRCIssuesLogStore from "../../../../store/useRCIssuesLogStore";
import CommonIssuesLogTable from "../CommonIssuesLogTable";
import CommonIssuesLogExcludeAccountSettingsTable from "../CommonIssuesLogExcludeAccountSettingsTable";
const IssueUpdateOrderUrgent = () => {
    const updateInView = useRCIssuesLogStore(state => state.updateInView);
    const [currTab, setCurrTab] = useState<string>("monitor");
    const handleChangeTab = (activeKey: string) => {
        setCurrTab(activeKey);
        // window.history.pushState({}, "", `${window.location.pathname}?tab=${activeKey}`);
    };
    return (
        <FloatingTitleBox
            title="Update Order Urgent"
            // titleExtra={<Badge count={rcIssuesLog?.length + 5} />}
            inViewCallback={(isInView: boolean) => {
                updateInView({ UPDATE_ORDER_URGENT: isInView });
            }}
            titleBarTabs={
                <Tabs
                    activeKey={currTab}
                    onChange={handleChangeTab}
                    type="card"
                    style={{
                        padding: "0.5rem 0.5rem 0 0.5rem",
                    }}
                    tabBarStyle={{
                        marginBottom: 0,
                    }}
                    items={[
                        {
                            label: "Monitoring",
                            key: "monitor",
                        },
                        {
                            label: "Settings",
                            key: "settings",
                        },
                    ]}
                />
            }
        >
            {currTab === "monitor" && <CommonIssuesLogTable type="UPDATE_ORDER_URGENT" />}
            {currTab === "settings" && <CommonIssuesLogExcludeAccountSettingsTable type="UPDATE_ORDER_URGENT" />}
        </FloatingTitleBox>
    );
};

export default IssueUpdateOrderUrgent;
