import { EyeTwoTone, EyeInvisibleOutlined, SyncOutlined, PlusOutlined, DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import {
    Form,
    Select,
    Input,
    Switch,
    Checkbox,
    Tree,
    DatePicker,
    Transfer,
    TimePicker,
    Radio,
    Space,
    InputNumber,
    Slider,
    Button,
    Col,
    Row,
    TreeSelect,
    Typography,
    Tooltip,
} from "antd";
import { FormItemProps, Rule } from "antd/lib/form";
import { NamePath } from "antd/lib/form/interface";
import { DefaultOptionType } from "antd/lib/select";
import { DataNode } from "antd/lib/tree";
import { CSSProperties, useCallback, useEffect, useMemo, useState } from "react";
import { ComponentType } from "../../constants";
import { KeyValuePair, OptionType } from "../../constants/type";
import LoadingComponent from "../Loading";
import moment from "moment";
import "./formComponent.less";
import { getAppSettings } from "../../services/localStorage";
import IBRebateAccountInput from "./IBRebateAccounts";
import { FormInstance } from "antd/es/form";
import CustomColorPicker from "./ColorPicker/CustomColorPicker";

const { Text } = Typography;
const { SHOW_PARENT } = TreeSelect;
const { TextArea } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

export interface FormComponentProps {
    label: string | React.ReactNode;
    name: NamePath;
    extra: ExtraProps;
    isLoading?: boolean;
}

export interface ExtraProps {
    type: string;
    rules?: Rule[];
    disabled?: boolean;
    itemProps?: FormItemProps<any>;
    inputProps?: any;
    value: any;
    dateFormat?: string;
    timeFormat?: string;
    withSyncBtn?: WithSyncBtnProps;
    multipleElementLabelColSpan?: number;
    form?: FormInstance;
}

export interface WithSyncBtnProps {
    enable: boolean;
    callback: Function;
}

export interface FieldData {
    name: string | number | (string | number)[];
    value?: any;
    touched?: boolean;
    validating?: boolean;
    errors?: string[];
}

export const hiddenWrapperProps = {
    className: "hidden-wrapper-form-item",
    label: "",
};

const FormComponent = (props: FormComponentProps) => {
    const { label, name, extra } = props;
    let defaultItemProps = {}; //{ labelCol: { flex: 8 }, wrapperCol: { flex: 16 } };
    switch (extra.type) {
        case ComponentType.labelOnly:
        case ComponentType.text:
        case ComponentType.number:
        case ComponentType.hidden:
        case ComponentType.password:
            const inputProps = {
                type: ComponentType.labelOnly === extra.type ? "text" : extra.type,
                autoComplete: "off",
                disabled: extra.disabled || ComponentType.labelOnly === extra.type,
                value: extra.value !== "" ? extra.value : extra.type === "number" ? 0 : "",
            };
            return (
                <Form.Item
                    hidden={extra.type === "hidden"}
                    className="form-text"
                    label={label}
                    name={name}
                    rules={extra.rules}
                    {...Object.assign(defaultItemProps, extra.itemProps || {})}
                >
                    {props.isLoading ? (
                        <LoadingComponent />
                    ) : ComponentType.labelOnly === extra.type ? (
                        <Input {...Object.assign({}, inputProps, extra.inputProps)} />
                    ) : ComponentType.password === extra.type ? (
                        <Input.Password
                            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            {...Object.assign({}, inputProps, extra.inputProps)}
                        />
                    ) : ComponentType.number === extra.type ? (
                        <InputNumber style={{ width: "100%" }} {...Object.assign({}, inputProps, extra.inputProps)} />
                    ) : (
                        <Input {...Object.assign({}, inputProps, extra.inputProps)} />
                    )}
                </Form.Item>
            );
        case ComponentType.textarea:
            return (
                <Form.Item
                    className="form-text"
                    label={label}
                    name={name}
                    rules={extra.rules}
                    {...Object.assign(defaultItemProps, extra.itemProps || {})}
                >
                    <TextArea disabled={extra.disabled || false} {...Object.assign({}, { rows: 4 }, extra.inputProps)} />
                </Form.Item>
            );
        case ComponentType.dropdown:
            return extra.withSyncBtn && extra.withSyncBtn.enable ? (
                <Form.Item label={label} className="withResyncBtn">
                    <Form.Item
                        noStyle
                        className="form-select"
                        name={name}
                        rules={extra.rules}
                        {...Object.assign(defaultItemProps, extra.itemProps || {})}
                    >
                        <Select
                            placeholder={"Please select " + (label as string).toString().toLowerCase()}
                            // Commented out due to the issue with mode == "tags", it will include search value in the dropdown list and cause non-unique key error
                            {...(extra.inputProps && extra.inputProps.mode && extra.inputProps.mode === "tags"
                                ? { optionFilterProp: "label" }
                                : {
                                      optionFilterProp: "children",
                                      filterOption: (inputVal: any, option: DefaultOptionType) => {
                                          return `${option.label}`.toLowerCase().indexOf(`${inputVal}`.toLowerCase()) > -1;
                                      },
                                  })}
                            options={extra.value ? extra.value.map((x: OptionType) => ({ label: x.text, value: x.value })) : []}
                            {...Object.assign(
                                {
                                    showSearch: true,
                                    allowClear: true,
                                    disabled: extra.disabled || false,
                                },
                                extra.inputProps && extra.inputProps.mode && extra.inputProps.mode === "multiple"
                                    ? { autoClearSearchValue: false }
                                    : {},
                                extra.inputProps
                            )}
                        />
                    </Form.Item>
                    <Button
                        className="btn-resync"
                        type="primary"
                        icon={<SyncOutlined />}
                        onClick={(e: any) => {
                            e.preventDefault();
                            extra.withSyncBtn?.callback && extra.withSyncBtn?.callback();
                        }}
                    />
                </Form.Item>
            ) : (
                <Form.Item
                    className="form-select"
                    label={label}
                    name={name}
                    rules={extra.rules}
                    {...Object.assign(defaultItemProps, extra.itemProps || {})}
                >
                    <Select
                        // Commented out due to the issue with mode == "tags", it will include search value in the dropdown list and cause non-unique key error
                        {...(extra.inputProps && extra.inputProps.mode && extra.inputProps.mode === "tags"
                            ? { optionFilterProp: "label" }
                            : {
                                  optionFilterProp: "children",
                                  filterOption: (inputVal: any, option: DefaultOptionType) => {
                                      return `${option.label}`.toLowerCase().indexOf(`${inputVal}`.toLowerCase()) > -1;
                                  },
                              })}
                        options={extra.value ? extra.value.map((x: OptionType) => ({ label: x.text, value: x.value })) : []}
                        {...Object.assign(
                            {
                                placeholder: "Please select " + (label as string).toString().toLowerCase(),
                                showSearch: true,
                                allowClear: true,
                                disabled: extra.disabled || false,
                            },
                            extra.inputProps && extra.inputProps.mode && extra.inputProps.mode === "multiple" ? { autoClearSearchValue: false } : {},
                            extra.inputProps
                        )}
                    />
                </Form.Item>
            );
        case ComponentType.treeselect:
            return (
                <Form.Item
                    className="form-treeselect"
                    label={label}
                    name={name}
                    rules={extra.rules}
                    {...Object.assign(defaultItemProps, extra.itemProps || {})}
                >
                    <TreeSelect
                        treeDataSimpleMode
                        treeCheckable
                        treeData={extra.value}
                        {...Object.assign(
                            {
                                autoClearSearchValue: false,
                                showCheckedStrategy: SHOW_PARENT,
                                maxTagCount: "responsive",
                                filterTreeNode: (filterValue: string, rowData: any) =>
                                    (rowData.title as string).toLowerCase().indexOf(filterValue.toLowerCase()) > -1,
                            },
                            extra.inputProps
                        )}
                    />
                </Form.Item>
            );
        case ComponentType.switch:
            return (
                <Form.Item
                    className="form-switch"
                    label={label}
                    name={name}
                    rules={extra.rules}
                    valuePropName="checked"
                    {...Object.assign(defaultItemProps, extra.itemProps || {})}
                >
                    <Switch
                        checkedChildren={extra.value[1]}
                        unCheckedChildren={extra.value[0]}
                        defaultChecked={false}
                        {...Object.assign({ disabled: extra.disabled || false }, extra.inputProps)}
                    />
                </Form.Item>
            );
        case ComponentType.checkbox:
            return (
                <Form.Item
                    className="form-checkbox"
                    label={label}
                    name={name}
                    rules={extra.rules}
                    {...Object.assign(defaultItemProps, extra.itemProps || {})}
                    valuePropName="checked"
                >
                    <Checkbox disabled={extra.disabled || false}>{extra.value}</Checkbox>
                </Form.Item>
            );
        case ComponentType.radio:
            return (
                <Form.Item
                    className="form-radio"
                    label={label}
                    name={name}
                    rules={extra.rules}
                    {...Object.assign(defaultItemProps, extra.itemProps || {})}
                >
                    <Radio.Group {...Object.assign({ disabled: extra.disabled || false }, extra.inputProps)}>
                        {extra.value &&
                            extra.value.map((x: OptionType) => (
                                <Radio key={`f-r-g-${x.value}`} value={x.value}>
                                    {x.text}
                                </Radio>
                            ))}
                    </Radio.Group>
                </Form.Item>
            );
        case ComponentType.radioverticalgroup:
            return (
                <Form.Item
                    className="form-veritcal-radiogroup"
                    label={label}
                    name={name}
                    rules={extra.rules}
                    {...Object.assign(defaultItemProps, extra.itemProps || {})}
                >
                    <Radio.Group {...Object.assign({ disabled: extra.disabled || false }, extra.inputProps)}>
                        <Space direction={"vertical"}>
                            {extra.value &&
                                extra.value.map((x: OptionType) => (
                                    <Radio key={`f-r-g-${x.value}`} value={x.value}>
                                        {x.text}
                                    </Radio>
                                ))}
                        </Space>
                    </Radio.Group>
                </Form.Item>
            );
        case ComponentType.checkboxgroup:
            return (
                <Form.Item
                    className="form-checkboxgroup"
                    label={label}
                    name={name}
                    rules={extra.rules}
                    {...Object.assign(defaultItemProps, extra.itemProps || {})}
                >
                    <Checkbox.Group
                        disabled={extra.disabled || false}
                        options={extra.value.map((x: any) => ({ label: x.text, value: x.value, ...(x.style && { style: x.style }) }))}
                    />
                </Form.Item>
            );
        case ComponentType.time:
            return (
                <Form.Item label={label} name={name} rules={extra.rules} {...Object.assign(defaultItemProps, extra.itemProps || {})}>
                    <TimePicker disabled={extra.disabled || false} style={{ width: "100%" }} format={"HH:mm:ss"} />
                </Form.Item>
            );
        case ComponentType.date:
            return (
                <Form.Item label={label} name={name} rules={extra.rules} {...Object.assign(defaultItemProps, extra.itemProps || {})}>
                    <DatePicker
                        style={{ width: "100%" }}
                        {...Object.assign(
                            {
                                format: (value: any) => value.format(extra.dateFormat || "YYYY-MM-DD HH:mm:ss"),
                                disabledDate: (current: any): boolean => {
                                    return current && current > moment().endOf("day");
                                },
                                disabled: extra.disabled || false,
                            },
                            extra.inputProps || {}
                        )}
                    />
                </Form.Item>
            );
        case ComponentType.daterange:
            return (
                <Form.Item label={label} name={name} rules={extra.rules} {...Object.assign(defaultItemProps, extra.itemProps || {})}>
                    <RangePicker
                        //format={(value) => value.format(extra.dateFormat || "YYYY-MM-DD HH:mm:ss")}
                        disabledDate={(current: any): boolean => {
                            return current && current > moment().endOf("day");
                        }}
                        disabled={extra.disabled || false}
                        {...{ ...{ style: { width: "100%" } }, ...(extra.inputProps || {}) }}
                    />
                </Form.Item>
            );
        case ComponentType.daterange2:
            return <DateRange2 {...props} />;
        case ComponentType.timerange:
            return (
                <Form.Item label={label} name={name} rules={extra.rules} {...Object.assign(defaultItemProps, extra.itemProps || {})}>
                    <TimePicker.RangePicker format={extra.timeFormat || "HH:mm:ss"} {...(extra.inputProps || {})} />
                </Form.Item>
            );
        case ComponentType.transfer:
            return (
                <Form.Item label={label} name={name} rules={extra.rules} {...Object.assign(defaultItemProps, extra.itemProps || {})}>
                    <Transfer {...(extra.inputProps || {})} />
                </Form.Item>
            );
        case ComponentType.tree:
            return (
                <Form.Item label={label} name={name} rules={extra.rules} {...Object.assign(defaultItemProps, extra.itemProps || {})}>
                    <TreeInput data={extra.value} />
                </Form.Item>
            );
        case ComponentType.numberrange:
            const componentProps = {
                type: "number",
                autoComplete: "off",
                disabled: extra.disabled,
                value: extra.value !== "" ? extra.value : 0,
            };

            return (
                <Form.Item label={label}>
                    <Input.Group compact>
                        <Form.Item name={[name as string, "from"]} noStyle rules={extra.rules}>
                            <Input
                                {...Object.assign({}, componentProps, extra.inputProps)}
                                style={{ width: "40%", borderRight: "none", textAlign: "right" }}
                                placeholder="Minimum"
                            />
                        </Form.Item>
                        <Input
                            style={{
                                width: "20%",
                                borderLeft: 0,
                                borderRight: 0,
                                pointerEvents: "none",
                                textAlign: "center",
                                background: "transparent",
                            }}
                            placeholder="~"
                            disabled
                        />
                        <Form.Item name={[name as string, "to"]} noStyle rules={extra.rules}>
                            <Input
                                {...Object.assign({}, componentProps, extra.inputProps)}
                                style={{ width: "40%", borderLeft: "none", textAlign: "right" }}
                                placeholder="Maximum"
                            />
                        </Form.Item>
                    </Input.Group>
                </Form.Item>
            );
        case ComponentType.numberrange2:
            return (
                <Form.Item label={label} name={name} rules={extra.rules} {...Object.assign(defaultItemProps, extra.itemProps || {})}>
                    <CustomNumberInputRange />
                </Form.Item>
            );
        case ComponentType.slider:
            return (
                <Form.Item name={name} label={label} rules={extra.rules} {...Object.assign(defaultItemProps, extra.itemProps || {})}>
                    <Slider range={{ draggableTrack: true }} {...(extra.inputProps || {})} />
                </Form.Item>
            );
        case ComponentType.multipletimerange:
            return (
                <Form.List name={name} rules={extra.rules as any}>
                    {(fields, { add, remove }, { errors }) => (
                        <div key={`mtr-m-${name}`} className="multiple-time-range-component">
                            {fields.map((field, index) => (
                                <Row key={`mtrc-${name}-${index}`}>
                                    <Col span={extra.multipleElementLabelColSpan}>{index === 0 && "Time Range"}</Col>
                                    <Col span={extra.multipleElementLabelColSpan ? 24 - extra.multipleElementLabelColSpan : 24} className="input-div">
                                        <Form.Item noStyle {...field} {...Object.assign(defaultItemProps, extra.itemProps || {})}>
                                            <TimePicker.RangePicker format={extra.timeFormat || "HH:mm:ss"} {...(extra.inputProps || {})} />
                                        </Form.Item>
                                        <Button icon={<DeleteOutlined style={{ color: "#f00f00" }} />} type="text" onClick={() => remove(index)} />
                                    </Col>
                                </Row>
                            ))}
                            <Row>
                                <Col span={extra.multipleElementLabelColSpan ? extra.multipleElementLabelColSpan : 0}>
                                    {fields.length === 0 ? <span>{label}</span> : ""}
                                </Col>
                                <Col span={extra.multipleElementLabelColSpan ? 24 - extra.multipleElementLabelColSpan : 24}>
                                    <div className="add-new-btn" onClick={() => add()}>
                                        <PlusOutlined />
                                        Add New Time Range
                                    </div>
                                    <Form.ErrorList errors={errors} />
                                </Col>
                            </Row>
                        </div>
                    )}
                </Form.List>
            );
        case ComponentType.rebateAccounts:
            return <IBRebateAccountInput label={label} componentId={name} formName={name} form={extra.form} servers={extra.value} />;
        case ComponentType.messageTemplate:
            return (
                <Form.Item className="form-text message-template-input" label={label} colon={false}>
                    <div className="form-input-outer-container">
                        <div className="title">
                            <Tooltip
                                mouseEnterDelay={0}
                                title="Customize your notification message by selecting metrics from the options below. Selected metric key used to display its corresponding value."
                            >
                                <span>Available Metrics</span>
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </div>
                        <div className="metric-container">
                            {extra.value &&
                                extra.value.length > 0 &&
                                extra.value.map((x: KeyValuePair, idx: number) => {
                                    return (
                                        <Text
                                            key={`fc-${name}-op-${idx}`}
                                            strong
                                            onClick={() => {
                                                if (extra.form) {
                                                    let currentText: string = extra.form.getFieldValue(name) || "";
                                                    extra.form.setFieldValue(
                                                        name,
                                                        `${currentText}${currentText.length > 0 ? "," : ""} ${x.text}: ${x.value}`
                                                    );
                                                }
                                            }}
                                        >
                                            <PlusOutlined />
                                            {x.text}
                                        </Text>
                                    );
                                })}
                        </div>
                        <Form.Item name={name} rules={extra.rules} {...Object.assign(defaultItemProps, extra.itemProps || {})}>
                            <TextArea disabled={extra.disabled || false} {...Object.assign({}, { rows: 4 }, extra.inputProps)} />
                        </Form.Item>
                    </div>
                </Form.Item>
            );
        case ComponentType.colorpicker:
            return (
                <Form.Item
                    className="form-text"
                    label={label}
                    name={name}
                    rules={extra.rules}
                    {...Object.assign(defaultItemProps, extra.itemProps || {})}
                >
                    <CustomColorPicker />
                </Form.Item>
            );
        // return (
        //     <Form.Item className="form-text message-template-input" label={label} colon={false}>
        //         <div className="form-input-outer-container">
        //             <div className="metric-container">
        //                 <div className="title">
        //                     <Tooltip
        //                         mouseEnterDelay={0}
        //                         title="Customize your notification message by selecting metrics from the options below. Selected metric key used to display its corresponding value."
        //                     >
        //                         <span>Available Metrics</span>
        //                         <QuestionCircleOutlined />
        //                     </Tooltip>
        //                 </div>
        //                 {extra.value &&
        //                     extra.value.length > 0 &&
        //                     extra.value.map((x: KeyValuePair, idx: number) => (
        //                         <Text
        //                             key={`fc-${name}-op-${idx}`}
        //                             strong
        //                             onClick={() => {
        //                                 if (extra.form) {
        //                                     extra.form.setFieldValue(name, `${extra.form.getFieldValue(name)}{${x.text}}`);
        //                                 }
        //                             }}
        //                             className="shadow-light"
        //                         >
        //                             {x.text}
        //                             <ArrowRightOutlined />
        //                         </Text>
        //                     ))}
        //             </div>
        //             <Form.Item name={name} rules={extra.rules} {...Object.assign(defaultItemProps, extra.itemProps || {})}>
        //                 <TextArea disabled={extra.disabled || false} {...Object.assign({}, { rows: 8 }, extra.inputProps)} />
        //             </Form.Item>
        //         </div>
        //     </Form.Item>
        // );
        // case ComponentType.upload:
        //     return (
        //         <Form.Item
        //             label={label}
        //             name={name}
        //             rules={extra.rules}
        //             {...Object.assign(defaultItemProps, extra.itemProps || {})}
        //             valuePropName="fileList"
        //         >
        //             <Upload listType="picture-card" {...(extra.inputProps || {})}>
        //                 <div>
        //                     <PlusOutlined />
        //                     <div style={{ marginTop: 8 }}>Upload</div>
        //                 </div>
        //             </Upload>
        //         </Form.Item>
        //     );
        //     break;
        default:
            return <></>;
    }
};

export interface CustomInputRangeProps {
    id?: string;
    value?: string;
    onChange?: (value: string) => void;
    inputProps?: any;
}

export const CustomNumberInputRange = (props: CustomInputRangeProps) => {
    const defaultValue = useMemo(() => {
        if (props.value !== undefined) {
            let tmp = `${props.value}`.split("|");
            return { from: tmp[0] || "", to: tmp[1] || "" };
        }
        return { from: "", to: "" };
    }, [props.value]);

    const triggerChange = useCallback(
        (type: number, changeValue: number) =>
            props.onChange?.(`${type === 0 ? changeValue : defaultValue.from}|${type === 1 ? changeValue : defaultValue.to}`),
        [defaultValue]
    );

    const fromToProps = useMemo(() => {
        let returnProps: any = { from: {}, to: {} };
        if (props.inputProps) {
            if (props.inputProps?.hasOwnProperty("from") || props.inputProps?.hasOwnProperty("to")) {
                if (props.inputProps?.hasOwnProperty("from")) returnProps.form = { ...props.inputProps.form };
                if (props.inputProps?.hasOwnProperty("to")) returnProps.to = { ...props.inputProps.to };
            } else {
                returnProps.form = { ...props.inputProps };
                returnProps.to = { ...props.inputProps };
            }
        }
        return returnProps;
    }, [props.inputProps]);

    return (
        <Input.Group compact style={{ width: "100%" }}>
            <Input
                value={defaultValue.from}
                onChange={(e: any) => triggerChange(0, e.target.value || "")}
                {...Object.assign(
                    {
                        type: "number",
                        style: {
                            width: "calc(50% - 15px)",
                            borderRight: "none",
                        },
                        placeholder: "from",
                    },
                    fromToProps.from
                )}
            />
            <Input
                className="site-input-split"
                style={{
                    width: "30px",
                    borderLeft: "none",
                    borderRight: "none",
                    pointerEvents: "none",
                    textAlign: "center",
                    background: "transparent",
                }}
                placeholder="~"
                disabled
            />
            <Input
                value={defaultValue.to}
                onChange={(e: any) => triggerChange(1, e.target.value || "")}
                {...Object.assign(
                    {
                        type: "number",
                        style: {
                            width: "calc(50% - 15px)",
                            borderLeft: "none",
                        },
                        placeholder: "to",
                    },
                    fromToProps.to
                )}
            />
        </Input.Group>
    );
};

//interface TreeItemProps { }

export interface TreeDataProps {
    data: DataNode[];
    value?: string[];
    onChange?: (value: string[]) => void;
}

export const TreeInput = (props: TreeDataProps) => {
    const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
    const [checkedKeys, setCheckedKeys] = useState<React.Key[]>(props.value as string[]);
    const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
    const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true);

    const onExpand = (expandedKeysValue: React.Key[]) => {
        setExpandedKeys(expandedKeysValue);
        setAutoExpandParent(false);
    };

    const onCheck = (checkedKeysValue: any) => {
        setCheckedKeys(checkedKeysValue);
        props.onChange && props.onChange(checkedKeysValue);
    };

    useEffect(() => {
        setCheckedKeys(props.value as string[]);
        return () => {};
    }, [props.value]);

    return (
        <div className="form-input-tree">
            <Tree
                checkable
                selectable={false}
                onExpand={onExpand}
                expandedKeys={expandedKeys}
                autoExpandParent={autoExpandParent}
                onCheck={onCheck}
                checkedKeys={checkedKeys}
                onSelect={(selectedKeysValue: React.Key[], info: any) => setSelectedKeys(selectedKeysValue)}
                selectedKeys={selectedKeys}
                treeData={props.data}
            />
        </div>
    );
};

const DateRange2 = (props: FormComponentProps) => {
    const [selectedOption, setSelectedOption] = useState<OptionType | undefined>(undefined);

    const optionsList: KeyValuePair[] = getAppSettings().RELATIVE_DATE_RANGE;

    return (
        <Form.Item label={props.label}>
            <Input.Group compact>
                <Form.Item label={""} name={props.name} noStyle rules={props.extra.rules} {...(props.extra.itemProps || {})}>
                    <Select
                        onChange={(value: any, opt: any) =>
                            setSelectedOption(value === undefined ? undefined : { text: opt.children, value: opt.value })
                        }
                        style={{
                            width: selectedOption
                                ? selectedOption.text.indexOf(" N ") > -1
                                    ? "50%"
                                    : selectedOption.value === "custom"
                                    ? "40%"
                                    : "100%"
                                : "100%",
                        }}
                        {...Object.assign({ allowClear: true }, props.extra.inputProps || {})}
                    >
                        {[...(props.extra.value === undefined ? optionsList : props.extra.value), { text: "Custom", value: "custom" }].map(
                            (x: OptionType) => (
                                <Option key={"s-o-" + x.value} value={`${x.value}`}>
                                    {x.text}
                                </Option>
                            )
                        )}
                    </Select>
                </Form.Item>
                {selectedOption && selectedOption.value === "custom" && (
                    <Form.Item label={""} name={`${props.name}-date`} noStyle>
                        <RangePicker
                            disabledDate={(current: any): boolean => {
                                return current && current > moment().endOf("day");
                            }}
                            style={{ width: "60%" }}
                        />
                    </Form.Item>
                )}
                {selectedOption && selectedOption.text.indexOf(" N ") > -1 && (
                    <Form.Item label={""} name={`${props.name}-value`} noStyle>
                        <InputNumber style={{ width: "50%" }} placeholder="Fill in 'N' value" min={1} />
                    </Form.Item>
                )}
            </Input.Group>
        </Form.Item>
    );
};

export enum FormInputType {
    Text = 1,
    Password = 2,
    Hidden = 3,
    Dropdown = 4,
    DateRange = 5,
    Date = 6,
    Time = 7,
    Transfer = 8,
    Number = 9,
}

const FORM_INPUT_TYPE: { [key: number]: string } = {
    1: "text",
    2: "password",
    3: "hidden",
    4: "dropdown",
    5: "daterange",
    6: "date",
    7: "time",
    8: "transfer",
    9: "number",
};

export interface FormInputProps {
    type?: FormInputType | undefined;
    label?: string | React.ReactNode;
    name: string;
    placeHolder?: string;
    value: any;
    styles?: CSSProperties;
    className?: string;
    rules?: Rule[];
    itemProps?: FormItemProps;
    inputProps?: any;
    extra?: any;
}

const FormInput = (props: FormInputProps): JSX.Element => {
    switch (props.type) {
        case FormInputType.Text:
        case FormInputType.Number:
        case FormInputType.Password:
        case FormInputType.Hidden:
            const inputProps = {
                size: "large",
                wrapperClassName: "form-input-wrapper",
                className: ["form-input-c", props.className].join(" "),
                style: props.styles,
                placeholder: props.placeHolder,
                addonBefore: <span className="form-input-label">{props.label}</span>,
                type: FORM_INPUT_TYPE[props.type],
                autoComplete: "off",
                value: props.type === FormInputType.Number ? (props.value as number) : (props.value as string),
            };
            return (
                <Form.Item
                    hidden={props.type === FormInputType.Hidden}
                    className="form-input-text"
                    name={props.name}
                    rules={props.rules}
                    {...Object.assign({}, props.itemProps)}
                >
                    {FormInputType.Password === props.type ? (
                        <Input.Password
                            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            {...Object.assign({}, inputProps, props.inputProps)}
                        />
                    ) : (
                        <Input {...Object.assign({}, inputProps, props.inputProps)} />
                    )}
                </Form.Item>
            );
    }

    return <></>;
};

FormInput.defaultProps = {
    type: "text",
    label: "",
    placeHolder: "",
    value: "",
    styles: {},
    className: "",
    rules: [],
    itemProps: {},
    inputProps: {},
};

export { FormComponent, FormInput };
