import { create } from "zustand";

interface IDisplay {
    ra: boolean;
    admin: boolean;
    success: boolean;
    warning: boolean;
    danger: boolean;
    summary: boolean;
    disabled: boolean;
}

interface IVendor {
    ra: string | null;
    admin: string | null;
    daily: string | null;
    weekend: string | null;
}

interface RCStatusMonitorStore {
    display: IDisplay;
    vendor: IVendor;
}

interface RCStatusMonitorStoreActions {
    updateDisplay: (newDisplay: Partial<IDisplay>) => void;
    updateVendor: (newVendor: Partial<IVendor>) => void;
}

// Create your store
const useRCStatusMonitorStore = create<RCStatusMonitorStore & RCStatusMonitorStoreActions>(set => ({
    display: {
        ra: true,
        admin: true,
        success: false,
        warning: true,
        danger: true,
        summary: false,
        disabled: false,
    },
    vendor: {
        ra: null,
        admin: null,
        daily: null,
        weekend: null,
    },
    updateDisplay: (
        newDisplay // need to get previous state to update newDisplay
    ) =>
        set(state => ({
            display: { ...state.display, ...newDisplay },
        })),
    updateVendor: (
        newVendor // need to get previous state to update newDisplay
    ) =>
        set(state => ({
            vendor: { ...state.vendor, ...newVendor },
        })),
}));

export default useRCStatusMonitorStore;
