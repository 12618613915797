import { Datum } from "@ant-design/charts";
import { LineChartOutlined } from "@ant-design/icons";
import { Line } from "@ant-design/plots";
import { Empty, Form, message } from "antd";
import moment from "moment";
import { useState, useEffect } from "react";
import { FormComponent } from "../../../../components/FormComponent";
import LoadingComponent from "../../../../components/Loading";
import { ComponentType, SUCCESS_FAILED } from "../../../../constants";
import { apiRequest } from "../../../../services/apiConfig";
import { APIs } from "../../../../services/apis";
import { currencyRender, ErrorCatchValidator, ErrorMessageHandler } from "../../../../utils/Common";
import { ProfileInfoProps } from "../viewProfile";
import ChartPanel from "./chartPanel";
import { GetUniqueKeysList, ToObjectWithKey } from "../../../../utils/array";
import { hasAnyKey } from "../../../../utils/object";
import EmptyData from "../../../../components/Common/Empty";
import CustomSkeleton from "../../../../components/Common/Skeleton";

export interface PNLSummaryChartProps extends ProfileInfoProps {
    forPrint?: boolean;
}

interface FilterData {
    dateFrom?: string;
    dateTo?: string;
}

const PNLSummaryChart = (props: PNLSummaryChartProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [refreshData, setRefreshData] = useState<boolean>(false);
    const [data, setData] = useState<any[]>([]);
    const [filterData, setFilterData] = useState<FilterData>({
        dateFrom: moment.utc().subtract(3, "months").format("YYYY-MM-DD"),
        dateTo: moment.utc().format("YYYY-MM-DD"),
    });
    const [chartFilterForm] = Form.useForm();

    /**
     *  29/02/2024 - Xin
     *  temporarily removed metricId 57 (floating pnl) from payload
     *  because inaccurate data
     */
    const getDataList = (dateFilter: any) => {
        setIsLoading(true);
        apiRequest(APIs.ACCOUNT_HISTORICAL_PNL, {
            accountId: props?.accountId,
            brandId: props?.brandId,
            serverId: props?.serverId,
            metricType: 1,
            timeFrame: 1,
            dataSamplingMode: 0,
            metricIds: [12, 52],
            ...filterData,
            ...(dateFilter && dateFilter),
        })
            .then((data: any) => {
                if (data && data.length > 0) {
                    let markupData: any[] = [];
                    let finalDateArr: string[] = GetUniqueKeysList(
                        data.reduce((final: string[], x: any) => final.concat(x.plotData), []),
                        "date",
                        true,
                        (a: string, b: string) => a.localeCompare(b)
                    );

                    data.map((x: any, index: number) => {
                        let kotmp = ToObjectWithKey(x.plotData, "date");
                        markupData = markupData.concat(
                            finalDateArr.map((y: string) => ({
                                name: x.metricName,
                                date: moment(y).format("YYYY-MM-DD"),
                                value: (kotmp[y] && kotmp[y].value) || 0,
                            }))
                        );
                        return false;
                    });
                    setData(markupData);
                    // let pnlIndx = data.stats.findIndex((x: any) => x.metricId === 12);
                    // let extraArr: any = {};
                    // for (let index = 0; index < data.stats.length; index++) {
                    //     const item = data.stats[index];
                    //     for (let sidx = 0; sidx < item.plotData.length; sidx++) {
                    //         const sItem: dataProps = item.plotData[sidx];
                    //         let dateKey = moment(sItem.date).format("YYYY-MM-DD");

                    //         if (extraArr[dateKey] === undefined) {
                    //             extraArr[dateKey] = { "52": { name: "", value: "" }, "57": { name: "", value: "" }, "12": { name: "", value: "" } };
                    //         }

                    //         extraArr[dateKey][`${item.metricId}`] = {
                    //             name: fillString(item.metricName, 25),
                    //             value: currencyRender(sItem.value, 2),
                    //         };
                    //     }
                    // }
                    // setData(
                    //     data.stats[pnlIndx].plotData.map((x: dataProps) => {
                    //         x.date = moment(x.date).format("YYYY-MM-DD");
                    //         return x;
                    //     })
                    // );
                    // setExtraData(extraArr);
                } else {
                    setData([]);
                }
            })
            .catch((error) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("account historical PNL data", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        setRefreshData(true);
        return () => {};
    }, [props.dateRangeFilter]);

    useEffect(() => {
        if (refreshData) {
            getDataList(props.dateRangeFilter);
            setRefreshData(false);
        }
        return () => {};
    }, [refreshData]);

    const onFilterValueCallback = (changedValues: any) => {
        if (changedValues.date && changedValues.date.length > 1) {
            setFilterData({
                dateFrom: moment.utc(changedValues.date[0]).format("YYYY-MM-DD"),
                dateTo: moment.utc(changedValues.date[1]).format("YYYY-MM-DD"),
            });
            setRefreshData(true);
        } else {
            message.warning("Please set a date range if you wish to continue.");
            setRefreshData(false);
        }
    };

    useEffect(() => {
        chartFilterForm.setFieldValue("date", [moment().subtract(3, "months"), moment()]);
    }, []);

    return (
        <>
            {isLoading ? (
                <ChartPanel
                    forPrint={props.forPrint}
                    type={1}
                    icon={<LineChartOutlined />}
                    title={"PNL"}
                    subTitle={"Overall profit & loss summary for current account"}
                >
                    <CustomSkeleton rows={10} />
                </ChartPanel>
            ) : (
                <ChartPanel
                    forPrint={props.forPrint}
                    type={1}
                    icon={<LineChartOutlined />}
                    title={"PNL"}
                    subTitle={"Overall profit & loss summary for current account"}
                    {...(!hasAnyKey(props.dateRangeFilter) && {
                        titleExtra: {
                            form: chartFilterForm,
                            content: (
                                <>
                                    <FormComponent
                                        label={""}
                                        name={"date"}
                                        extra={{
                                            type: ComponentType.daterange,
                                            value: "",
                                            inputProps: {
                                                disabledDate: (current: any) => current && current > moment().endOf("day"),
                                                ranges: {
                                                    Default: [moment().subtract(3, "months"), moment()],
                                                },
                                            },
                                            dateFormat: "YYYY-MM-DD",
                                        }}
                                    />
                                </>
                            ),
                            onValueChanged: onFilterValueCallback,
                        },
                    })}
                >
                    <div className="chart">
                        {data?.length > 0 ? (
                            <Line
                                {...{
                                    data,

                                    xField: "date",
                                    yField: "value",
                                    seriesField: "name",
                                    ...(props.forPrint
                                        ? {
                                              padding: [70, 50],
                                              xAxis: {
                                                  label: {
                                                      autoRotate: true,
                                                      offsetY: 0,
                                                  },
                                                  tickLine: {
                                                      alignTick: true,
                                                  },
                                              },
                                          }
                                        : {
                                              padding: "auto",
                                              xAxis: {
                                                  label: {
                                                      autoRotate: true,
                                                  },
                                                  tickLine: {
                                                      alignTick: true,
                                                  },
                                              },
                                              slider: {
                                                  start: 0,
                                                  end: 1,
                                              },
                                          }),
                                    yAxis: {
                                        label: {
                                            formatter: (v) => currencyRender(v),
                                        },
                                    },
                                    tooltip: {
                                        formatter: (datum: Datum) => {
                                            return { name: datum.name, value: currencyRender(datum.value, 2) };
                                        },
                                    },
                                }}
                            />
                        ) : (
                            <>
                                <EmptyData />
                            </>
                        )}
                    </div>
                </ChartPanel>
            )}
        </>
    );
};

export default PNLSummaryChart;
