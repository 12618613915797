import { Col, Form, Row } from "antd";
import { FormInstance } from "antd/es/form";
import { REQUIRED_FIELD } from "../../constants/errorMessage";
import { ToOptionTypeList } from "../../utils/array";
import { FormComponent } from "../FormComponent";
import BarTemplate from "./Templates/BarTemplate";
import LineTemplate from "./Templates/LineTemplate";
import "../../pages/ReportingModule/Templates/createEditTemplate.less";
import TableTemplate from "./Templates/TableTemplate";
import PieTemplate from "./Templates/PieTemplate";
import { ChartType, ComponentType } from "../../constants";

export interface ChartBuilderProps {
    form: FormInstance;
}

const ChartBuilder = (props: ChartBuilderProps) => {
    const cType = Form.useWatch("chartType", props.form);

    return (
        <>
            <div>
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label={"Display Type"}
                            name={"chartType"}
                            extra={{
                                type: ComponentType.dropdown,
                                value: ToOptionTypeList(ChartType),
                                itemProps: {
                                    labelAlign: "left",
                                    labelCol: { span: 5 },
                                    wrapperCol: { span: 11 },
                                },
                                inputProps: {
                                    allowClear: false,
                                    showSearch: false,
                                },
                                rules: [
                                    {
                                        required: true,
                                        message: REQUIRED_FIELD,
                                    },
                                ],
                            }}
                        />
                    </Col>
                </Row>
                {cType === 0 && <TableTemplate form={props.form} prefix={`c_${cType}`} />}
                {cType === 1 && <BarTemplate form={props.form} prefix={`c_${cType}`} />}
                {cType === 2 && <LineTemplate form={props.form} prefix={`c_${cType}`} />}
                {cType === 3 && <PieTemplate form={props.form} prefix={`c_${cType}`} />}
            </div>
        </>
    );
};

export default ChartBuilder;
