// 1. enable edit button to update content at mdEditor
// 2. screenshots images are stored at amazon s3 bucket "nv-risk-rnd-insight-static"
// 3. click save button and copy result at console, paste at src/assets/data json file
// 4. to display images into the UI Editor, use the following format: ![image](https://static.insight.risk-vantagefx.com/<folder_name>/<image_name>)

import { Button, Empty, Menu } from "antd";
import { useEffect, useState } from "react";
import { Module } from "../../constants/type";
import { useNavigate } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";
import CardBox from "../../components/Common/CardBox";
import AuthHelper from "../..//helpers/authHelper";
import ViewUserManual from "./ViewUserManual";
import EditUserManual from "./EditUserManual";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

interface UserManualViewProps {
    setOpen: (open: boolean) => void | boolean;
}

let modules: any = [];

const UserManualView = (props: UserManualViewProps) => {
    let navigate = useNavigate();
    const [manualLoading, setManualLoading] = useState<boolean>(true);
    const [modulePath, setModulePath] = useState<string>("");
    const [isViewMode, setIsViewMode] = useState<boolean>(false);
    const [isEditMode, setIsEditMode] = useState<boolean>(false);

    const findChild = (parentId: number, data: Module[], isDisabled: boolean = false): Module[] =>
        data
            .filter((x) => x.parentId === parentId)
            .map(
                (x) =>
                ({
                    title: x.title,
                    key: `${x.id}`,
                    path: `${x.key}`,
                    disableCheckbox: isDisabled,
                    children: findChild(x.id, data, isDisabled),
                } as unknown as Module)
            );

    const getTreeList = (data: Module[], isDisabled: boolean = false) => (data.length > 0 ? findChild(0, data, isDisabled) : []);

    const getConfigInfo = () => {
        let auth = new AuthHelper();
        let sortedData: Module[] = auth.getAuthModules().filter((x) => `${x.type}` === "1");

        sortedData.sort((a: Module, b: Module) => a.order - b.order);
        modules = getTreeList(sortedData);

        setManualLoading(false);
    };

    useEffect(() => {
        getConfigInfo();
    }, [modulePath]);

    const onEdit = (path: string) => {
        setIsViewMode(false);
        setIsEditMode(true);
    };

    const handleClick = (path: string) => {
        setModulePath("/" + path);
        setIsViewMode(true);
        setIsEditMode(false);
    };

    const renderSubMenu = (item: any) => {
        return (
            <SubMenu
                key={item.path}
                title={
                    <span>
                        <span>{item.title}</span>
                    </span>
                }
            >
                {item.children
                    ? item.children.map((y: any) => (
                        <Menu.Item key={y.path} onClick={() => handleClick(y.path)}>
                            {y.title}
                        </Menu.Item>
                    ))
                    : null}
            </SubMenu>
        );
    };

    const onCallBack = () => {
        setIsViewMode(true);
        setIsEditMode(false);
    };

    return (
        <CardBox title={"User Manual"}>
            <div className="user-manual-container">
                <div className="left">
                    {/* {isViewMode && !isEditMode ? (
                        <div className="edit-button-box">
                            <Button type="text" icon={<EditOutlined />} onClick={() => onEdit(modulePath)}></Button>
                        </div>
                    ) : null} */}
                    {isViewMode && !isEditMode ? <ViewUserManual modulePath={modulePath} /> : null}
                    {isEditMode && !isViewMode ? <EditUserManual modulePath={modulePath} onCallBack={onCallBack} /> : null}
                    {!isViewMode && !isEditMode && <Empty />}
                </div>
                <div className="right">
                    <Menu className="user-manual-menu" style={{ width: 256 }} defaultSelectedKeys={["1"]} defaultOpenKeys={["sub1"]} mode="inline">
                        {modules ? modules.map((item: any) => renderSubMenu(item)) : null}
                    </Menu>
                </div>
            </div>
        </CardBox>
    );
};

export default UserManualView;
