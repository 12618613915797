import { Empty, Form, Modal, Popover, Space, Timeline, Typography } from "antd";
import { useEffect, useMemo, useState } from "react";
import { DataTableColumnRender, ErrorCatchValidator, ErrorMessageHandler } from "../../../../utils/Common";
import { APIs, apiRequest } from "../../../../services/apiConfig";
import { DefaultIfEmpty, getAvailableObjectElement } from "../../../../utils/object";
import { ComponentType, INTRADAY_BREACH_SEVERITY, SUCCESS_FAILED } from "../../../../constants";
import { ToObjectWithKey, ToOptionTypeList } from "../../../../utils/array";
import { convertValueBasedOnDataType, isEmptyOrNull } from "../../../../utils/string";
import CustomSkeleton from "../../../../components/Common/Skeleton";
import { ClockCircleOutlined, EyeOutlined } from "@ant-design/icons";
import { getFilterRulesColumnRender, getMetricColumnRender } from "../../../IntradayMonitor/Alarm/List";
import { FormComponent } from "../../../../components/FormComponent";
import { REQUIRED_FIELD } from "../../../../constants/errorMessage";
import { FaRegCommentAlt, FaRegUserCircle } from "react-icons/fa";
import { getProfile } from "../../../../services/localStorage";
import { UserList } from "../../../IntradayMonitor/Search";
import {
    LPAlarmComment,
    LPSummaryAlarmSummaryProps,
    LPSummaryCommentProgressionProps,
    LPSummaryGroupDetailsProps,
    StatusListResponseProps,
} from "../../../../constants/type";
import moment from "moment";

interface AlarmSummaryProps {
    lpMappingData: any;
    isLoading: boolean;
    userList: UserList[];
    alarmStatusList: StatusListResponseProps[];
}

const AlarmSummaryForLPSummary = (props: AlarmSummaryProps) => {
    const [runRefetchData, setRunRefetchData] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<any[]>([]);
    const priorityObj = ToObjectWithKey(ToOptionTypeList(INTRADAY_BREACH_SEVERITY), "value");
    const [commentForm] = Form.useForm();
    const [visible, setVisible] = useState<boolean>(false);
    const [comData] = useState<LPAlarmComment>();
    const uPr: any = getProfile();
    const serverTime_UTCOffset: number | undefined = uPr?.hasOwnProperty("stom") ? uPr.stom : undefined;

    const getAlarmDetail = () => {
        setIsLoading(true);
        apiRequest(APIs.GET_MR_ALARM_SUMMARY, {})
            .then((data: any) => {
                if (data && data.length > 0) {
                    setData(data.sort((a: any, b: any) => moment.utc(b.eventDateTimeUtc).diff(moment.utc(a.eventDateTimeUtc))));
                } else {
                    setData([]);
                }
            })
            .catch(error => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("alarm summary", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
            })
            .finally(() => setIsLoading(false));
    };

    const toLowerCaseKeys = (obj: any) => Object.keys(obj).reduce((acc, key) => ({ ...acc, [key.toLowerCase()]: obj[key] }), {});

    const renderMetrics = (v: string, i: number, metricFilters: string, eventHashKey: any) => {
        const eventDetails = toLowerCaseKeys(JSON.parse(v));
        const metricInfo = JSON.parse(metricFilters);

        const transformedMetricInfo = metricInfo.map((item: any) => ({
            ...item,
            metricName: item.metricName.toLowerCase(),
        }));

        const newMetricInfo = transformedMetricInfo.map((item: any) => ({
            metricInnerFilters: [item],
        }));
        return getMetricColumnRender(eventHashKey, eventDetails, newMetricInfo, props.lpMappingData);
    };

    const massageLabel = (obj: any, value: any, extra: any = undefined) => {
        let objKey = DefaultIfEmpty(obj, extra, { objKey: "" }).objKey;

        if (!isEmptyOrNull(objKey)) {
            const options = props.lpMappingData[objKey];
            return DefaultIfEmpty(options, value, { text: "" }).text;
        }
        return value;
    };

    const renderDimensions = (eventDetails: string, i: number) => {
        let ED = JSON.parse(eventDetails);

        let lowerCaseED: { [key: string]: any } = {};
        Object.keys(ED).forEach(key => {
            lowerCaseED[key.toLowerCase()] = ED[key];
        });

        return (
            <div className="alarm-di-me" key={`tag-box-${i}`}>
                {Object.keys(lowerCaseED)
                    .filter(x => props.lpMappingData.dimension?.hasOwnProperty(x))
                    .map((x: any, idx: number) => {
                        return (
                            <div key={`tag-tag-${idx}`} className="item">
                                <span>{DefaultIfEmpty(props.lpMappingData.dimension, x, { text: "" }).text}</span>
                                <span>{massageLabel(props.lpMappingData.dimension, lowerCaseED[x], x)}</span>
                            </div>
                        );
                    })}
            </div>
        );
    };

    const getCombineSeverityProgressionAndCommentsAlarm = (
        groupDetails: LPSummaryGroupDetailsProps[],
        comments: LPSummaryCommentProgressionProps[],
        type1: number,
        type2: number
    ) => {
        const concated = [
            ...groupDetails.map(item => ({
                ...item,
                date: item.eventDateTimeUtc,
                type: type1,
            })),
            ...comments.map(item => ({
                ...item,
                date: item.commentDateUtc,
                type: type2,
            })),
        ];
        return concated.sort((a, b) => moment.utc(b.date).diff(moment.utc(a.date)));
    };

    useEffect(() => {
        getAlarmDetail();
    }, []);

    useEffect(() => {
        if (runRefetchData) {
            setIsLoading(true);
            getAlarmDetail();
            setRunRefetchData(false);
        }
        return () => {};
    }, [runRefetchData]);

    useEffect(() => {
        if (props.isLoading && !runRefetchData) {
            setRunRefetchData(true);
        }
        return () => {};
    }, [props.isLoading]);

    const memoizedTimelineItems = useMemo(() => {
        return data.map((currData: LPSummaryAlarmSummaryProps, i: number) => {
            let combinedArray = getCombineSeverityProgressionAndCommentsAlarm(currData["groupDetails"], currData["comments"], 1, 2);
            if (combinedArray.length === 0) return null;
            return (
                <Timeline.Item key={i}>
                    <div className="alarm-detail-box">
                        <div className={`content`}>
                            <div className="title-cont">
                                <div className="event-title">{currData.eventAlarmName}</div>
                                <Space>
                                    {!isEmptyOrNull(currData.correlationHashKey) && (
                                        <FaRegCommentAlt
                                            onClick={() => {
                                                setVisible(true);
                                                commentForm.setFieldsValue({
                                                    alarmCenterEventId: currData.alarmCenterEventId,
                                                    comment: "",
                                                    correlationHashKey: currData.correlationHashKey,
                                                    statusCode: currData.eventStatusCode,
                                                });
                                            }}
                                            style={{ cursor: "pointer" }}
                                        />
                                    )}

                                    <Popover
                                        placement="leftTop"
                                        overlayClassName="alarm-historical-popover-content"
                                        content={
                                            <div className="alarm-historical-popover-timeline-container nice-scrollbar">
                                                <Timeline>
                                                    {combinedArray.map((y: any, xIdx: number) => {
                                                        let eventDetails =
                                                                y.eventMessage &&
                                                                getAvailableObjectElement(JSON.parse(y.eventMessage as string), true),
                                                            metricInfo =
                                                                y.metricInnerFilters &&
                                                                JSON.parse(y.metricInnerFilters as string).map((x: any) => ({
                                                                    ...x,
                                                                    metricName: x.metricName.toLowerCase(),
                                                                    filterValue: [7].includes(x.filterOp)
                                                                        ? x.filterValue
                                                                        : convertValueBasedOnDataType(x.metricDataType, x.filterValue),
                                                                })),
                                                            metricNameArr = metricInfo && metricInfo.map((x: any) => x.metricName as string),
                                                            metricInfoByKey = metricInfo && ToObjectWithKey(metricInfo, "metricName"),
                                                            markupMetricInfo = [{ metricInnerFilters: metricInfo }];
                                                        eventDetails &&
                                                            Object.keys(eventDetails)
                                                                .filter(x => metricNameArr.includes(x))
                                                                .forEach(x => {
                                                                    eventDetails[x] = convertValueBasedOnDataType(
                                                                        metricInfoByKey[x].metricDataType,
                                                                        eventDetails[x]
                                                                    );
                                                                });
                                                        let timelineKey = `${Math.random()}`;
                                                        return (
                                                            <Timeline.Item
                                                                key={`tli-${timelineKey}-${xIdx}`}
                                                                color={y.type === 1 ? "#004b57" : "green"}
                                                            >
                                                                {y.type === 2 ? (
                                                                    <div className="alarm-popover-detail-container shadow-light">
                                                                        <div className="title-container">
                                                                            <div className="left">
                                                                                <div className="comment-by">
                                                                                    <FaRegUserCircle />
                                                                                    <span className="user-name">{y.commentedBy}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="right">
                                                                                <ClockCircleOutlined />{" "}
                                                                                {DataTableColumnRender.DateTime_UTC_TO_ServerTime(
                                                                                    y.commentDateUtc,
                                                                                    serverTime_UTCOffset
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                        <div className="comment-content">
                                                                            <Typography.Paragraph>{y.eventComment}</Typography.Paragraph>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div className="alarm-popover-detail-container shadow-light">
                                                                        <div className="title-container">
                                                                            <div className="left">
                                                                                <span className={`priority-div priority-color-${y.breachSeverity}`}>
                                                                                    {DefaultIfEmpty(priorityObj, y.breachSeverity, { text: "" }).text}
                                                                                </span>
                                                                            </div>
                                                                            <div className="right">
                                                                                <ClockCircleOutlined />{" "}
                                                                                {DataTableColumnRender.DateTime_UTC_TO_ServerTime(
                                                                                    y.eventDateTimeUtc,
                                                                                    serverTime_UTCOffset
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                        <div className="content-container">
                                                                            <div className="left">
                                                                                <div className="theader">Metrics</div>
                                                                                <div className="content">
                                                                                    {getMetricColumnRender(
                                                                                        `${y.alarmEventId}`,
                                                                                        eventDetails,
                                                                                        markupMetricInfo,
                                                                                        props.lpMappingData
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div className="right">
                                                                                <div className="theader">Filter Rule(s)</div>
                                                                                <div className="content">
                                                                                    {getFilterRulesColumnRender(
                                                                                        `${y.alarmEventId}`,
                                                                                        eventDetails,
                                                                                        markupMetricInfo,
                                                                                        props.lpMappingData
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Timeline.Item>
                                                        );
                                                    })}
                                                </Timeline>
                                            </div>
                                        }
                                        title={<span className="alarm-historical-popover-title">Breach Severity Progression</span>}
                                    >
                                        <EyeOutlined style={{ cursor: "pointer" }} />
                                    </Popover>
                                    <div className={`count-container priority-color-${currData.breachSeverity}`}>
                                        <span>{INTRADAY_BREACH_SEVERITY[currData.breachSeverity]}</span>
                                    </div>
                                </Space>
                            </div>
                            <div className="columns">
                                <div className="first-column">{renderDimensions(currData.eventMessage, i)}</div>
                                <div className="second-column">
                                    {renderMetrics(currData.eventMessage, i, currData.metricInnerFilters, currData.eventMappingHashKey)}
                                </div>
                            </div>
                        </div>
                        <div className="time">
                            {DataTableColumnRender.DateTime_UTC_TO_ServerTime(currData.eventDateTimeUtc, serverTime_UTCOffset)}
                        </div>
                    </div>
                </Timeline.Item>
            );
        });
    }, [data, props.lpMappingData]);

    const updateAlarmStatus = (values: any) => {
        const alarmComment = {
            alarmEventId: `${values.alarmCenterEventId}`,
            comment: values.comment,
            correlationHashKey: values.correlationHashKey,
            statusCode: values.statusCode,
        };
        apiRequest(APIs.UPDATE_ALARM_STATUS, alarmComment)
            .then(() => {
                ErrorMessageHandler("New comment", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                setRunRefetchData(true);
                setVisible(false);
            })
            .catch(error => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("New comment", SUCCESS_FAILED.FAILED_UPDATE_DATA, err)));
    };

    return (
        <div className={`alarm-summary-for-MR-Monitor half-view half-view-3`}>
            <div className="title">
                <span className="title-text">Alarm Summary</span>
            </div>
            <div className="alarm-det-container nice-scrollbar">
                {isLoading ? <CustomSkeleton /> : data.length === 0 ? <Empty /> : <Timeline>{memoizedTimelineItems}</Timeline>}
                <Modal
                    open={visible}
                    title={"Create New Comment"}
                    onCancel={() => {
                        commentForm.resetFields();
                        setVisible(false);
                    }}
                    onOk={() => {
                        commentForm
                            .validateFields()
                            .then((values: any) => {
                                updateAlarmStatus(values);
                            })
                            .catch((error: any) => {
                                console.log("error", error);
                            });
                    }}
                >
                    <Form form={commentForm} labelCol={{ span: 5 }} wrapperCol={{ span: 20 }} layout="horizontal" initialValues={comData}>
                        <FormComponent label={""} name={"alarmCenterEventId"} extra={{ type: ComponentType.hidden, value: "" }} />
                        <FormComponent label={""} name={"correlationHashKey"} extra={{ type: ComponentType.hidden, value: "" }} />
                        <FormComponent
                            label="Status"
                            name="statusCode"
                            extra={{
                                type: ComponentType.dropdown,
                                value: props.alarmStatusList,
                                rules: [
                                    {
                                        required: true,
                                        message: REQUIRED_FIELD,
                                    },
                                ],
                            }}
                        />
                        <FormComponent
                            label="Comment"
                            name={"comment"}
                            extra={{
                                type: ComponentType.textarea,
                                value: "",
                                rules: [
                                    {
                                        required: true,
                                        message: REQUIRED_FIELD,
                                    },
                                ],
                                inputProps: {
                                    showCount: true,
                                    rows: 5,
                                },
                            }}
                        />
                    </Form>
                </Modal>
            </div>
        </div>
    );
};

export default AlarmSummaryForLPSummary;
