import { Form, Row, Col } from "antd";
import { FormComponent } from "../../../../components/FormComponent";
import { ComponentType } from "../../../../constants";
import { REQUIRED_FIELD } from "../../../../constants/errorMessage";

export interface createEditTypeProps {
    form: any;
    data: any;
}

const CreateEditTypeComponent = (props: createEditTypeProps) => {
    return (
        <div>
            <Form form={props.form} layout="horizontal" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} initialValues={props.data}>
                <Row>
                    <Col span={22}>
                        <FormComponent
                            label=""
                            name="typeId"
                            extra={{
                                type: ComponentType.hidden,
                                value: "",
                            }}
                        />
                        <FormComponent
                            label="Name"
                            name="name"
                            extra={{
                                type: ComponentType.text,
                                rules: [
                                    {
                                        required: true,
                                        message: REQUIRED_FIELD,
                                    },
                                ],
                                value: "",
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={22}>
                        <FormComponent
                            label="Description"
                            name="description"
                            extra={{
                                type: ComponentType.text,
                                value: "",
                            }}
                        />
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default CreateEditTypeComponent;
