import { Button, Card, Form, Input } from "antd";
import { ComponentType, INTRADAY_FILTER_OPTIONS } from "../../../../constants";
import { REQUIRED_FIELD } from "../../../../constants/errorMessage";
import { CloseOutlined, DeleteOutlined } from "@ant-design/icons";
import { ARRAY_ACTION_TYPE, GetConstraintKeyList, ToObjectWithKey, ToOptionTypeList } from "../../../../utils/array";
import { useMemo } from "react";
import { MetricFilterPanelBasedProps } from "./MetricFilterPanel";
import { CustomNumberInputRange, FormComponent } from "../../../../components/FormComponent";
import { isEmptyOrNull } from "../../../../utils/string";
import { DimensionLevelObjectConfig } from "../../../../utils/Common";

export interface OrConditionGroupPanelProps extends MetricFilterPanelBasedProps {
    tkey: number;
}

const OrConditionGroupPanel = (props: OrConditionGroupPanelProps) => {
    const fieldName = "MetricOuterFilters";

    return (
        <Form.List name={[props.tkey, fieldName]}>
            {(fields, { add, remove }) => (
                <>
                    <Card
                        bordered={false}
                        title={
                            <div className="orcondition-card-header">
                                <span className="title">Filter condition</span>
                            </div>
                        }
                        size="small"
                        className="orcondition-group-panel"
                    >
                        {fields.length > 0 &&
                            fields.map((field, index) => {
                                return (
                                    <div key={`mf-fg-${field.name}-${index}`} className="orcondition-wrapper">
                                        <div className="orcondition-item shadow-light">
                                            <div className="btn-group">
                                                <Button
                                                    type="text"
                                                    size="small"
                                                    icon={<CloseOutlined />}
                                                    className="btn-delete"
                                                    onClick={() => remove(field.name)}
                                                />
                                            </div>
                                            <div className="content">
                                                <OrConditionInnerPanel
                                                    tkey={field.name}
                                                    form={props.form}
                                                    metrics={props.metrics}
                                                    componentId={[...props.componentId, ...[fieldName, field.name]]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        <div className="orcondition-add-button-container" onClick={() => add({ MetricInnerFilters: [] })}>
                            <span>Add "OR" Condition</span>
                        </div>
                    </Card>
                </>
            )}
        </Form.List>
    );
};

interface OrConditionInnerPanelProps extends OrConditionGroupPanelProps {}

const OrConditionInnerPanel = (props: OrConditionInnerPanelProps) => {
    const fieldName = "MetricInnerFilters";
    return (
        <Form.List name={[props.tkey, fieldName]}>
            {(fields, { add, remove }) => (
                <>
                    {fields.length > 0 &&
                        fields.map((field, index) => {
                            return (
                                <div key={`mf-fg-${field.name}-${index}`} className="and-item">
                                    <MetricFilterItem
                                        tKey={field.name}
                                        index={index + 1}
                                        form={props.form}
                                        metrics={props.metrics}
                                        componentId={[...props.componentId, ...[fieldName, field.name]]}
                                    />
                                    <Button type="text" danger icon={<DeleteOutlined />} onClick={() => remove(field.name)} />
                                </div>
                            );
                        })}
                    <div className="add-btn-panel" onClick={() => add({ MetricName: "", FilterOp: "", FilterValue: "" })}>
                        <span>Add "AND" Condition</span>
                    </div>
                </>
            )}
        </Form.List>
    );
};

interface MetricFilterItemProps extends MetricFilterPanelBasedProps {
    tKey: number;
    index: number;
}

const MetricFilterItem = (props: MetricFilterItemProps) => {
    const metricObj = useMemo(() => {
        return {
            validateDimensionObj: ToObjectWithKey(props.metrics, "metricName"),
            options: props.metrics.map(x => ({ text: x.metricDisplayName, value: x.metricName })),
        };
    }, [props.metrics]);
    const metricSelected = Form.useWatch([...props.componentId, "MetricName"], props.form);
    const operatorType = Form.useWatch([...props.componentId, "FilterOp"], props.form);
    const dimensionObj = ToObjectWithKey(DimensionLevelObjectConfig("IntradayAlarmDimensionOptions"), "value", "text");

    const valueComponent: React.ReactNode = useMemo(() => {
        let numberDataType = [1, 2, 3, 4, 5],
            metricType = metricObj.validateDimensionObj[metricSelected]?.metricDataType || -1,
            componentType = numberDataType.includes(metricType) ? ComponentType.number : ComponentType.text;
        return operatorType === 7 ? (
            <Form.Item
                name={[props.tKey, "FilterValue"]}
                style={{ width: "40%" }}
                rules={[
                    {
                        validator: (_: any, value: any) => {
                            if (`${value}`.split("|").filter(x => isEmptyOrNull(x)).length > 0) return Promise.reject(REQUIRED_FIELD);

                            return Promise.resolve();
                        },
                    },
                ]}
            >
                <CustomNumberInputRange />
            </Form.Item>
        ) : (
            <FormComponent
                label={""}
                name={[props.tKey, "FilterValue"]}
                extra={{
                    type: componentType,
                    value: "",
                    rules: [
                        { required: true, message: REQUIRED_FIELD },
                        {
                            validator: (_: any, value: any) => {
                                if (`${value}`.indexOf("|") > -1) return Promise.reject("Invalid format");

                                return Promise.resolve();
                            },
                        },
                    ],
                    itemProps: {
                        style: { width: "40%" },
                    },
                    inputProps: {
                        style: {
                            width: "100%",
                        },
                    },
                }}
            />
        );
    }, [operatorType, metricSelected]);

    return (
        <Input.Group compact>
            <FormComponent
                label={""}
                name={[props.tKey, "MetricName"]}
                extra={{
                    type: ComponentType.dropdown,
                    value: metricObj.options,
                    rules: [
                        { required: true, message: REQUIRED_FIELD },
                        {
                            validator: (_: any, value: any) => {
                                if (isEmptyOrNull(value)) return Promise.resolve();

                                let currentSelectedDimension = props.form.getFieldValue("DimensionSelectors") || [],
                                    supportedDimension = metricObj.validateDimensionObj[value]?.validForDimensions || [];
                                if (GetConstraintKeyList(currentSelectedDimension, supportedDimension, ARRAY_ACTION_TYPE.INNER).length === 0) {
                                    return Promise.reject(
                                        `Dimension needed: [${supportedDimension
                                            .map((x: number) => dimensionObj[x] || "")
                                            .filter((x: string) => !isEmptyOrNull(x))
                                            .join("],[")}]`
                                    );
                                } else if (GetConstraintKeyList(currentSelectedDimension, supportedDimension, ARRAY_ACTION_TYPE.INNER).length === 0) {
                                    return Promise.resolve();
                                }

                                return Promise.resolve();
                            },
                        },
                    ],
                    itemProps: {
                        style: { width: "30%" },
                    },
                    inputProps: {
                        style: {
                            width: "100%",
                        },
                    },
                }}
            />
            <FormComponent
                label={""}
                name={[props.tKey, "FilterOp"]}
                extra={{
                    type: ComponentType.dropdown,
                    value: ToOptionTypeList(INTRADAY_FILTER_OPTIONS),
                    rules: [{ required: true, message: REQUIRED_FIELD }],
                    itemProps: {
                        style: { width: "30%" },
                    },
                    inputProps: {
                        style: {
                            width: "100%",
                        },
                    },
                }}
            />
            {valueComponent}
        </Input.Group>
    );
};

export default OrConditionGroupPanel;
