import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
// import axios from "axios";
// import { getToken } from "../services/localStorage";
import { APIs } from "../services/apis";
import { plainAxiosInstance } from "../services/axiosSetup";

interface RCServerPerformanceServerChart {
    serverName: string;
    serverTime: string;
    users: number;
    cpu: number;
    freeMemory: number;
    network: number;
    sockets: number;
    timestamp: null;
    secondsSinceLastUpdate: null;
    isMissing: null;
    message: null;
}

const useRCServerPerformanceServerChart = (serverName: string | null) => {
    // const token = getToken();
    const { data, refetch, isLoading, error, isFetching } = useQuery({
        queryKey: ["rcServerPerformanceServerChart", serverName],
        queryFn: () =>
            plainAxiosInstance.get(`${APIs.RC_SERVER_PERFORMANCE.GET_PERFORMANCE_DETAIL}/${serverName}`, {
                headers: {
                    Accept: "application/json",
                },
            }),
        // axios.get(`${process.env.REACT_APP_API_URL}${APIs.RC_SERVER_PERFORMANCE.GET_PERFORMANCE_DETAIL}/${serverName}`, {
        //     headers: {
        //         Accept: "application/json",
        //         Authorization: `Bearer ${token}`,
        //     },
        // }),
        staleTime: 60 * 1000, //(10 x 1000ms = 10 seconds)
        enabled: !!serverName,
        // refetchInterval: 60 * 1000, //(10 x 1000ms = 10 seconds)
    });

    const rcServerPerformanceServerChart: RCServerPerformanceServerChart[] | null = data?.data?.status ? null : data?.data || null;
    // @ts-ignore
    const rcServerPerformanceServerChartError: string | null = data?.data?.message || error?.message || error || null;

    return {
        rcServerPerformanceServerChart,
        rcServerPerformanceServerChartError,
        refetchRcServerPerformanceServerChart: refetch,
        isFetching,
        isLoading,
    };
};

export default useRCServerPerformanceServerChart;
