import { Button, Input, notification, Space, Table, Typography } from "antd";
import React, { useState } from "react";
import { IRCOutOfPriceAlarm, useRCOutOfPriceAlarmMutation } from "../../../hooks/useRCOutOfPriceAlarm";
import Modal from "antd/lib/modal/Modal";
import { CheckOutlined } from "@ant-design/icons";
import { matchSorter } from "match-sorter";
import MessageCard from "../../SystemMonitor/components/MessageCard";

const OutOfPriceAlarmTable = ({ type, data }: { type: "alarm" | "solved"; data: IRCOutOfPriceAlarm[] | null }) => {
    const { solveAlarm, isLoadingSolveAlarm, solveAlarmError } = useRCOutOfPriceAlarmMutation();
    const [alarmToSolve, setAlarmToSolve] = React.useState<IRCOutOfPriceAlarm | null>(null);

    const handleSolveAlarm = async () => {
        if (!alarmToSolve) {
            return;
        }

        try {
            const resp = await solveAlarm({ data: alarmToSolve });
            if (resp?.data >= 0) {
                notification.success({
                    message: "Solved",
                    description: "Alarm has been solved",
                });
                setAlarmToSolve(null);
            } else {
                notification.error({
                    message: "Error",
                    description: "Failed to solve alarm",
                });
            }
        } catch (error) {
            console.log("Error on solving alarm:", error);
            notification.error({
                message: "Error",
                description: "Failed to solve alarm",
            });
        }
    };

    const columnsAlarm = [
        {
            title: "Server",
            dataIndex: "serverName",
        },
        {
            title: "Login",
            dataIndex: "login",
        },
        {
            title: "Symbol",
            dataIndex: "symbol",
        },
        {
            title: "Deal",
            dataIndex: "dealId",
        },
        {
            title: "AlarmPriceType",
            dataIndex: "showType",
        },
        {
            title: "Price",
            dataIndex: "price",
        },
        {
            title: "High",
            dataIndex: "high",
        },
        {
            title: "Low",
            dataIndex: "low",
        },
        {
            title: "Lots",
            dataIndex: "lots",
        },
        {
            title: "DealTime",
            dataIndex: "dealDtStr",
        },
        {
            title: "AlarmTime",
            dataIndex: "alarmDtStr",
        },
        {
            title: "Solved",
            dataIndex: "Action",
            render: (text: any, data: any) => (
                <Button type="primary" icon={<CheckOutlined />} onClick={e => setAlarmToSolve(data)} size="small">
                    Solve
                </Button>
            ),
        },
    ];

    const columnsSolved = [
        {
            title: "Server",
            dataIndex: "serverName",
        },
        {
            title: "Login",
            dataIndex: "login",
        },
        {
            title: "Symbol",
            dataIndex: "symbol",
        },
        {
            title: "Deal",
            dataIndex: "dealId",
        },
        {
            title: "AlarmPriceType",
            dataIndex: "showType",
        },
        {
            title: "Price",
            dataIndex: "price",
        },
        {
            title: "High",
            dataIndex: "high",
        },
        {
            title: "Low",
            dataIndex: "low",
        },
        {
            title: "Lots",
            dataIndex: "lots",
        },
        {
            title: "DealTime",
            dataIndex: "dealDtStr",
        },
        {
            title: "AlarmTime",
            dataIndex: "alarmDtStr",
        },
        {
            title: "Solved User",
            dataIndex: "solvedUser",
        },
        {
            title: "Solved Time",
            dataIndex: "solvedTimeStr",
        },
    ];

    const { Text } = Typography;
    const [searchText, setSearchText] = useState("");
    const result = matchSorter(data || [], searchText, { keys: ["serverName", "login", "symbol", "dealId", "solvedUser"] });

    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            {data && data?.length >= 1 ? (
                <MessageCard type="info">
                    {/* total how much records */}
                    <Text>
                        Total: {result.length} record
                        {
                            // if result.length > 1, add 's' to the end of 'record'
                            result.length > 1 ? "s" : ""
                        }
                    </Text>
                </MessageCard>
            ) : (
                <MessageCard type="info">
                    <Text>No records in 7 days</Text>
                </MessageCard>
            )}
            <Input
                placeholder="Search"
                value={searchText}
                onChange={e => {
                    setSearchText(e.target.value);
                }}
                allowClear
            />

            <Table
                columns={type === "alarm" ? columnsAlarm : columnsSolved}
                dataSource={result}
                pagination={false}
                // set table height to 600, and set the width of the table to 100%
                // style={{ height: 500, width: "100%" }}
                rowKey={record => record.serverUno + "_" + record.login + "_" + record.dealId + "_" + record.type}
                scroll={{ x: "max-content" }}
                loading={false}
                size="small"
            />
            <Modal
                title="Solve Alarm"
                open={alarmToSolve !== null}
                onOk={() => {
                    if (alarmToSolve) {
                        handleSolveAlarm();
                    }
                }}
                onCancel={() => {
                    setAlarmToSolve(null);
                }}
                okText="Solve"
                okButtonProps={{ loading: isLoadingSolveAlarm }}
                cancelButtonProps={{ loading: isLoadingSolveAlarm }}
            >
                <Space direction="vertical" size={4}>
                    <Text>Are you sure you want to solve this alarm?</Text>

                    {alarmToSolve && (
                        <div style={{ padding: "1rem", border: "1px solid rgba(0,0,0,0.05)", background: "rgba(0,0,0,0.05)" }}>
                            <Text>Server: {alarmToSolve.serverName}</Text>
                            <br />
                            <Text>Login: {alarmToSolve.login}</Text>
                            <br />
                            <Text>Symbol: {alarmToSolve.symbol}</Text>
                            <br />
                            <Text>Deal: {alarmToSolve.dealId}</Text>
                        </div>
                    )}
                </Space>
            </Modal>
        </Space>
    );
};

export default OutOfPriceAlarmTable;
