import { useEffect, useState } from "react";
import { Button, Col, Divider, Form, message, Row } from "antd";
import { FormComponent } from "@/components/FormComponent";
import { ComponentType, SUCCESS_FAILED } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { DownloadOutlined } from "@ant-design/icons";
import moment from "moment";
import LoadingComponent from "@/components/Loading";
import { ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { APIs } from "@/services/apis";
import { KeyValuePair } from "@/constants/type";
import { isEmptyOrNull } from "@/utils/string";

interface ReportTabProps {
    servers: KeyValuePair[];
    isFilterReady: boolean;
};

const ReportTab = (props: ReportTabProps) => {
    const [isExporting, setIsExporting] = useState<boolean>(false);
    const [isDownloadDisabled, setIsDownloadDisabled] = useState<boolean>(false);
    const [servers, setServers] = useState<KeyValuePair[]>([]);
    const [downloadForm] = Form.useForm();

    const handleSymbolBlur = (value: string) => {
        if (isEmptyOrNull(value)) return;
        else {
            downloadForm.setFieldValue("symbol", value.toUpperCase());
        };
    };

    const onFormSubmit = (values: any) => {
        let startDate = moment(values.dateValue[0]).format("YYYY-MM-DD");
        let endDate = moment(values.dateValue[1]).format("YYYY-MM-DD");
        setIsExporting(true);
        plainAxiosInstance
            .get(`${APIs.RC_PE_ORDER_COMPARE.GET_PEOC_DOWNLOAD}?startdate=${startDate}&enddate=${endDate}&serverUno=${values.serverUno}&symbol=${values.symbol}`,
                { responseType: "blob" }
            )
            .then((res: any) => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `Mid_Bias_Report_${values.serverUno}_${values.symbol}_${startDate}_${endDate}_${moment().format('x')}.csv`);
                document.body.appendChild(link);
                link.click();
                downloadForm.resetFields();
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("mid bias report", SUCCESS_FAILED.FAILED_DOWNLOAD_DATA, err))
            )
            .finally(() => setIsExporting(false));
    };

    useEffect(() => {
        if (props.isFilterReady) {
            setServers(props.servers);
            downloadForm.setFieldValue("serverUno", props.servers[0].value);
        };
    }, [props.isFilterReady]);

    return (
        <div className="peoc-report-tab">
            <LoadingComponent tip={!props.isFilterReady ? "Loading filters..." : "Downloading report..."} spinning={isExporting || !props.isFilterReady}>
                <Form
                    form={downloadForm}
                    layout="horizontal"
                    initialValues={servers.length > 0
                        ? { serverUno: servers[0].value, symbol: "", dateValue: [] }
                        : {}
                    }
                    onFinish={onFormSubmit}
                >
                    <Row gutter={[12, 12]}>
                        <Col span={6}>
                            <FormComponent
                                label="Server"
                                name="serverUno"
                                extra={{
                                    type: ComponentType.dropdown,
                                    value: servers,
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                        </Col>
                        <Col span={6}>
                            <FormComponent
                                label="Symbol"
                                name="symbol"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                    inputProps: {
                                        // onBlur: (e: any) => handleSymbolBlur(e.target.value),
                                    },
                                }}
                            />
                        </Col>
                        <Col span={6}>
                            <FormComponent
                                label="Download Date"
                                name="dateValue"
                                extra={{
                                    type: ComponentType.daterange,
                                    value: [],
                                    dateFormat: "YYYY-MM-DD",
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                    inputProps: {
                                        dateOnly: true,
                                        onCalendarChange: (dates: any) => {
                                            if (dates && dates.length === 2) {
                                                if (dates[1] && dates[1].diff(dates[0], "days") > 32) {
                                                    message.error("The selected date range should not exceed 32 days.");
                                                    setIsDownloadDisabled(true);
                                                } else {
                                                    setIsDownloadDisabled(false);
                                                };
                                            };
                                        },
                                    },
                                }}
                            />
                        </Col>
                    </Row>
                    <Divider orientation="left" className="divider-nogap"></Divider>
                    <Row style={{ width: "100%" }}>
                        <Col span={24} key="flt-f-c-btns" className="flt-f-c-btns">
                            <Button
                                type="default"
                                onClick={e => {
                                    e.preventDefault();
                                    downloadForm.resetFields();
                                    if (servers.length > 0) downloadForm.setFieldValue("serverUno", servers[0].value);
                                }}
                            >
                                Reset
                            </Button>
                            <Button icon={<DownloadOutlined />} type="primary" htmlType="submit" disabled={isDownloadDisabled}>
                                Download
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </LoadingComponent>
        </div>
    );
};

export default ReportTab;