// import React from "react";
// import useRCStatusMonitorDaily from "../../../hooks/useRCStatusMonitorDaily";
// import { massageDailyData } from "./statusUtils";
// import BasicFilter from "../../../components/BasicFilter";
// import ServerStatus, { ServerStatusProps } from "./ServerStatus";
// import ServerStatusFilterWeekend from "./ServerStatusFilterWeekend";
// import TabContent from "./TabContent";
// const TabDaily = () => {
//     const { rcStatusMonitorDaily, isLoading: isLoadingStatusMonitorDaily } = useRCStatusMonitorDaily();

//     const dataDaily = rcStatusMonitorDaily ? massageDailyData(rcStatusMonitorDaily) : [];
//     console.log(dataDaily);

//     return (
//         <TabContent>
//             <BasicFilter>
//                 <ServerStatusFilterWeekend />
//             </BasicFilter>
//             {dataDaily?.map((item: any, index: number) => (
//                 <ServerStatus key={index} data={item as ServerStatusProps} />
//             ))}
//         </TabContent>
//     );
// };

// export default TabDaily;

import React from "react";
import useRCStatusMonitorDaily from "../../../hooks/useRCStatusMonitorDaily";
import { massageDailyData } from "./statusUtils";
import BasicFilter from "../../../components/BasicFilter";
import ServerStatus, { ServerStatusProps } from "./ServerStatus";
import ServerStatusFilterWeekend from "./ServerStatusFilterWeekend";
import TabContent from "../components/TabContent";
import ServerStatusVendor from "./ServerStatusVendor";
import useRCStatusMonitorStore from "../../../store/useRCStatusMonitorStore";

import Loader from "../components/Loader";
import MessageCard from "../components/MessageCard";
const TabDaily = () => {
    const { rcStatusMonitorDaily, isLoading } = useRCStatusMonitorDaily();

    const dataDaily = rcStatusMonitorDaily ? massageDailyData(rcStatusMonitorDaily) : [];

    const vendor = useRCStatusMonitorStore(state => state.vendor.daily);
    const filterDataDaily = vendor ? dataDaily?.filter((item: any) => item.vendor === vendor) : [];

    return (
        <TabContent>
            <BasicFilter show={false}>
                <ServerStatusFilterWeekend />
            </BasicFilter>

            {isLoading && rcStatusMonitorDaily.length === 0 ? (
                <Loader />
            ) : (
                <>
                    <ServerStatusVendor data={dataDaily as ServerStatusProps[]} vendorType="daily" />
                    {!vendor && (
                        <div style={{ padding: "0 1rem" }}>
                            <MessageCard type="info">Please select a group from above</MessageCard>
                        </div>
                    )}
                    {filterDataDaily?.map((item: any, index: number) => (
                        <ServerStatus key={index} data={item as ServerStatusProps} />
                    ))}
                </>
            )}
        </TabContent>
    );
};

export default TabDaily;
