import { useMemo, useState } from "react";
import { DTColProps, DataTableColumnRender, currencyRender, scientificToDecimal } from "../../../../utils/Common";
import FlexiDataTable from "../../../../components/FlexiDataTable";
import { HightlightExpression } from "../../../../constants/type";
import { DecayAnalysisAccountStats } from "../../PMI/eventSnapshot";
import TinyAreaChart from "../../../../components/Charts/SubComponents/TinyArea";
import { rangeArrayInit } from "../../../../utils/array";

export interface DecayAnalysisAccountSummaryProps {
    accountStats: DecayAnalysisAccountStats;
    isCloseDecay?: boolean;
}

const DecayAnalysisAccountSummary = (props: DecayAnalysisAccountSummaryProps) => {
    const [data] = useState<any>(props.accountStats || {});

    const timeSeriesData = useMemo(() => {
        let tmpObj: any = rangeArrayInit(20, 1).reduce(
            (finalObj: any, x: number) => {
                if (data?.hasOwnProperty(`dpm${x * 60}`) && typeof data[`dpm${x * 60}`] === "number")
                    finalObj["DPM"][`${x * 60}sec`] = data[`dpm${x * 60}`] as number;
                if (data?.hasOwnProperty(`profit${x * 60}`) && typeof data[`profit${x * 60}`] === "number")
                    finalObj["Profit"][`${x * 60}sec`] = data[`profit${x * 60}`] as number;
                return finalObj;
            },
            { DPM: { type: "DPM" }, Profit: { type: "Profit" } }
        );
        return Object.keys(tmpObj).reduce((finalArr: any, key: string) => {
            finalArr.push(tmpObj[key]);
            return finalArr;
        }, []);
    }, [data]);

    const dmpDataOnly = useMemo(() => {
        let fidx: number = timeSeriesData.findIndex((x: any) => x.type === "DPM");
        if (fidx > -1) {
            let currentItem: any = timeSeriesData[fidx];
            return rangeArrayInit(20, 1).map((y: number) => currentItem[`${y * 60}sec`]);
        }
        return [];
    }, [timeSeriesData]);

    const getCheckColorIdx = (checkData: any[]) => {
        let checkColorIdx = checkData.length - 1;
        for (; checkColorIdx > 0; checkColorIdx--) {
            if (checkData[checkColorIdx] !== null && checkData[checkColorIdx] !== undefined) break;
        }
        return checkColorIdx;
    };

    const getColumnsConfig = () => {
        const hightExpression: HightlightExpression = (value: number) => value > 0;
        return [
            DTColProps.XSmall({
                title: "",
                dataIndex: "type",
                key: "type",
                width: "3.5vw",
                fixed: "left",
            }),
            DTColProps.SCurrency(
                {
                    title: "1min",
                    dataIndex: "60sec",
                    key: "60sec",
                    width: "4vw",
                },
                [],
                -1,
                hightExpression
            ),
            DTColProps.SCurrency(
                {
                    title: "2min",
                    dataIndex: "120sec",
                    key: "120sec",
                    width: "4vw",
                },
                [],
                -1,
                hightExpression
            ),
            DTColProps.SCurrency(
                {
                    title: "3min",
                    dataIndex: "180sec",
                    key: "180sec",
                    width: "4vw",
                },
                [],
                -1,
                hightExpression
            ),
            DTColProps.SCurrency(
                {
                    title: "4min",
                    dataIndex: "240sec",
                    key: "240sec",
                    width: "4vw",
                },
                [],
                -1,
                hightExpression
            ),
            DTColProps.SCurrency(
                {
                    title: "5min",
                    dataIndex: "300sec",
                    key: "300sec",
                    width: "4vw",
                },
                [],
                -1,
                hightExpression
            ),
            DTColProps.SCurrency(
                {
                    title: "6min",
                    dataIndex: "360sec",
                    key: "360sec",
                    width: "4vw",
                },
                [],
                -1,
                hightExpression
            ),
            DTColProps.SCurrency(
                {
                    title: "7min",
                    dataIndex: "420sec",
                    key: "420sec",
                    width: "4vw",
                },
                [],
                -1,
                hightExpression
            ),
            DTColProps.SCurrency(
                {
                    title: "8min",
                    dataIndex: "480sec",
                    key: "480sec",
                    width: "4vw",
                },
                [],
                -1,
                hightExpression
            ),
            DTColProps.SCurrency(
                {
                    title: "9min",
                    dataIndex: "540sec",
                    key: "540sec",
                    width: "4vw",
                },
                [],
                -1,
                hightExpression
            ),
            DTColProps.SCurrency(
                {
                    title: "10min",
                    dataIndex: "600sec",
                    key: "600sec",
                    width: "4vw",
                },
                [],
                -1,
                hightExpression
            ),
            DTColProps.SCurrency(
                {
                    title: "11min",
                    dataIndex: "660sec",
                    key: "660sec",
                    width: "4vw",
                },
                [],
                -1,
                hightExpression
            ),
            DTColProps.SCurrency(
                {
                    title: "12min",
                    dataIndex: "720sec",
                    key: "720sec",
                    width: "4vw",
                },
                [],
                -1,
                hightExpression
            ),
            DTColProps.SCurrency(
                {
                    title: "13min",
                    dataIndex: "780sec",
                    key: "780sec",
                    width: "4vw",
                },
                [],
                -1,
                hightExpression
            ),
            DTColProps.SCurrency(
                {
                    title: "14min",
                    dataIndex: "840sec",
                    key: "840sec",
                    width: "4vw",
                },
                [],
                -1,
                hightExpression
            ),
            DTColProps.SCurrency(
                {
                    title: "15min",
                    dataIndex: "900sec",
                    key: "900sec",
                    width: "4vw",
                },
                [],
                -1,
                hightExpression
            ),
            DTColProps.SCurrency(
                {
                    title: "16min",
                    dataIndex: "960sec",
                    key: "960sec",
                    width: "4vw",
                },
                [],
                -1,
                hightExpression
            ),
            DTColProps.SCurrency(
                {
                    title: "17min",
                    dataIndex: "1020sec",
                    key: "1020sec",
                    width: "4vw",
                },
                [],
                -1,
                hightExpression
            ),
            DTColProps.SCurrency(
                {
                    title: "18min",
                    dataIndex: "1080sec",
                    key: "1080sec",
                    width: "4vw",
                },
                [],
                -1,
                hightExpression
            ),
            DTColProps.SCurrency(
                {
                    title: "19min",
                    dataIndex: "1140sec",
                    key: "1140sec",
                    width: "4vw",
                },
                [],
                -1,
                hightExpression
            ),
            DTColProps.SCurrency(
                {
                    title: "20min",
                    dataIndex: "1200sec",
                    key: "1200sec",
                    width: "4vw",
                },
                [],
                -1,
                hightExpression
            ),
        ];
    };

    const currencyRenderCropByDecimal = (value: number | string, digitAfterDecimal: number) => {
        let currentValue: string = `${value}`;
        return currencyRender(
            scientificToDecimal(value),
            currentValue.indexOf(".") > -1 && currentValue.indexOf(".") + digitAfterDecimal < currentValue.length - 1 ? 4 : -1
        );
    };

    return (
        <div className={`decay-analysis-account-summary`}>
            <div className="top-div">
                <div className="title">Decay Analysis Account Summary</div>
                <div className="content">
                    <div className="left-panel">
                        <div className="decay-summary-info-container">
                            <div className="info-row">
                                <span className="info-label">{`Today ${props.isCloseDecay ? "Closed" : "Open"} Trade Count`}</span>
                                <span className="info-value">{props.isCloseDecay ? data.todayClosedTradeCount : data.todayOpenedTradeCount}</span>
                            </div>
                            <div className="info-row">
                                <span className="info-label">Today Closed PNL</span>
                                <span className="info-value">{currencyRenderCropByDecimal(data.todayClosedPnl, 4)}</span>
                            </div>
                        </div>
                        <div className="decay-summary-info-container">
                            <div className="info-row">
                                <span className="info-label">{`Last Trade ${props.isCloseDecay ? "Closed" : "Opened"} Time (Server)`}</span>
                                <span className="info-value">
                                    {props.isCloseDecay
                                        ? data.lastTradeClosedTime && DataTableColumnRender.DateTime_ServerTime(data.lastTradeClosedTime)
                                        : data.lastTradeOpenedTime && DataTableColumnRender.DateTime_ServerTime(data.lastTradeOpenedTime)}
                                </span>
                            </div>
                            <div className="info-row">
                                <span className="info-label">Decay Score</span>
                                <span className="info-value">{currencyRender(data.decayScore)}</span>
                            </div>
                        </div>
                        {}
                        <div className="decay-summary-info-container">
                            <div className="info-row">
                                <span className="info-label">Max DPM</span>
                                <span className="info-value">{currencyRenderCropByDecimal(data.maxDpmIn20Min, 4)}</span>
                            </div>
                            <div className="info-row">
                                <span className="info-label">Max Profit</span>
                                <span className="info-value">{currencyRenderCropByDecimal(data.maxProfitIn20Min, 4)}</span>
                            </div>
                        </div>
                        <div className="decay-summary-info-container">
                            <div className="info-row">
                                <span className="info-label">DPM Slope</span>
                                <span className="info-value">{currencyRenderCropByDecimal(data.dpmSlopeIn20Min, 4)}</span>
                            </div>
                            <div className="info-row">
                                <span className="info-label">DPM R-Squared</span>
                                <span className="info-value">{currencyRenderCropByDecimal(data.dpmRSquaredIn20Min, 4)}</span>
                            </div>
                        </div>
                    </div>
                    <div className="right-panel">
                        <div className="chart">
                            {dmpDataOnly.length > 0 ? (
                                <TinyAreaChart
                                    data={dmpDataOnly}
                                    config={
                                        dmpDataOnly[getCheckColorIdx(dmpDataOnly)] >= 0
                                            ? {
                                                  autoFit: true,
                                                  height: 40,
                                                  color: "#9bdab5",
                                                  line: {
                                                      color: "#009F42",
                                                  },
                                              }
                                            : {
                                                  autoFit: true,
                                                  height: 40,
                                                  color: "#ec9892",
                                                  line: {
                                                      color: "#f00f00",
                                                  },
                                              }
                                    }
                                />
                            ) : (
                                <div className="no-data-panel"> No Data</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom-div">
                <FlexiDataTable
                    rowKeyProperty="type"
                    title={false}
                    columns={getColumnsConfig()}
                    dataSource={timeSeriesData}
                    loading={false}
                    options={{
                        showHideColumns: false,
                        enableFilter: false,
                    }}
                    pagination={false}
                />
            </div>
        </div>
    );
};

export default DecayAnalysisAccountSummary;
