import React, { useState } from "react";
import { useRCOutOfPriceAlarmMutation, useRCOutOfPriceAlarmWhitelist } from "../../../hooks/useRCOutOfPriceAlarm";
import { notification, Table, Button, Upload, Modal, Space } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import type { UploadFile, UploadProps } from "antd/es/upload/interface";
import MessageCard from "../../SystemMonitor/components/MessageCard";

const OutOfPriceAlarmWhitelist = () => {
    const { rcOutOfPriceAlarmWhitelist, isLoading } = useRCOutOfPriceAlarmWhitelist();
    const { uploadWhitelist, isLoadingUploadWhitelist } = useRCOutOfPriceAlarmMutation();
    const [file, setFile] = useState<File | null>(null);
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [openModal, setOpenModal] = useState(false);

    const handleUploadWhitelist = async () => {
        if (!file) {
            notification.error({
                message: "Error",
                description: "Please select a file to upload",
            });
            return;
        }

        const formData = new FormData();
        formData.append("uploadFile", file);

        try {
            const resp = await uploadWhitelist({ formData });
            if (resp.data === 0) {
                notification.success({
                    message: "Success",
                    description: "Whitelist uploaded successfully",
                });
                setOpenModal(false);
                setFile(null);
                setFileList([]);
            }
        } catch (error) {
            console.error("Error on uploading whitelist:", error);
            notification.error({
                message: "Error",
                description: "Failed to upload whitelist",
            });
        }
    };

    const uploadProps: UploadProps = {
        onRemove: () => {
            setFile(null);
            setFileList([]);
        },
        beforeUpload: file => {
            setFile(file);
            setFileList([
                {
                    uid: "-1",
                    name: file.name,
                    status: "done",
                    url: URL.createObjectURL(file),
                },
            ]);
            return false;
        },
        fileList,
        maxCount: 1,
    };

    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button onClick={() => setOpenModal(true)} icon={<UploadOutlined />}>
                    Upload Whitelist
                </Button>
            </div>

            {isLoadingUploadWhitelist ? (
                <MessageCard type="info">Loading...</MessageCard>
            ) : (
                <Table
                    size="small"
                    dataSource={rcOutOfPriceAlarmWhitelist || []}
                    loading={isLoading}
                    pagination={false}
                    rowKey={record => record.serverName + "_" + record.login}
                    columns={[
                        { title: "Server", dataIndex: "serverName" },
                        { title: "Login", dataIndex: "login" },
                    ]}
                />
            )}

            <Modal
                title="Upload Whitelist"
                open={openModal}
                onOk={handleUploadWhitelist}
                okButtonProps={{ disabled: !file }}
                onCancel={() => {
                    setOpenModal(false);
                    setFile(null);
                    setFileList([]);
                }}
                okText="Upload"
            >
                <Space direction="vertical">
                    <Upload {...uploadProps} accept=".xlsx,.XLSX">
                        <Button icon={<UploadOutlined />}>Select File</Button>
                    </Upload>
                    <MessageCard type="info">
                        {/* let user know uploaded file will replace current value */}
                        Uploading a new file will replace the current whitelist
                    </MessageCard>
                </Space>
            </Modal>
        </Space>
    );
};

export default OutOfPriceAlarmWhitelist;
