import React, { useState } from "react";
import FloatingTitleBox from "../../components/FloatingTitleBox";
import useRCIssuesLogStore from "../../../../store/useRCIssuesLogStore";
import CommonIssuesLogTable from "../CommonIssuesLogTable";
import { Tabs } from "antd";
import CommonIssuesLogTypeDownload from "../CommonIssuesLogTypeDownload";
import AuthHelper, { AuthKeys } from "../../../../helpers/authHelper";

const IssueManagerAccountIntercepted = () => {
    const updateInView = useRCIssuesLogStore(state => state.updateInView);
    const authHp = new AuthHelper();
    const canUpload = authHp.isAuthorized(AuthKeys.RC.RC_ISSUES_LOG_MANAGER_ACCOUNT_INTERCEPTED_EDIT);
    const [currTab, setCurrTab] = useState<string>("monitor");
    const handleChangeTab = (activeKey: string) => {
        setCurrTab(activeKey);
    };
    return (
        <FloatingTitleBox
            title="Manager Account Intercepted"
            inViewCallback={(isInView: boolean) => {
                updateInView({ MANAGER_ACCOUNT_INTERCEPTED: isInView });
            }}
            titleBarTabs={
                <Tabs
                    activeKey={currTab}
                    onChange={handleChangeTab}
                    type="card"
                    style={{
                        padding: "0.5rem 0.5rem 0 0.5rem",
                    }}
                    tabBarStyle={{
                        marginBottom: 0,
                    }}
                    items={[
                        {
                            label: "Monitoring",
                            key: "monitor",
                        },
                        {
                            label: "Settings",
                            key: "settings",
                        },
                    ]}
                />
            }
        >
            {currTab === "monitor" && <CommonIssuesLogTable type="MANAGER_ACCOUNT_INTERCEPTED" />}
            {currTab === "settings" && <CommonIssuesLogTypeDownload type="MANAGER_ACCOUNT_INTERCEPTED" canUpload={canUpload} />}
        </FloatingTitleBox>
    );
};

export default IssueManagerAccountIntercepted;
