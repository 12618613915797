import React from "react";
import SystemBreadCrumbs from "../components/SystemBreadCrumbs";
import IssueNoMoney from "./IssueNoMoney";
import IssuesLogMenu from "./IssuesLogMenu";
import IssueAccountMonitor from "./IssueAccountMonitor";
import { Divider } from "antd";
import IssueClosedOrder from "./IssueClosedOrder";
import IssueRequestTimeout from "./IssueRequestTimeout";
import IssueModifyOpenOrder from "./IssueModifyOpenOrder";
import IssueManagerAccountIntercepted from "./IssueManagerAccountIntercepted";
import IssueMT5OrderType from "./IssueMT5OrderType";
import IssueRestoreOrder from "./IssueRestoreOrder";
import IssueUpdateOrderUrgent from "./IssueUpdateOrderUrgent";
import IssueBalanceAdjustmentMonitor from "./IssueBalanceAdjustmentMonitor";

const IssuesLog = () => {
    return (
        <div style={{ marginBottom: "3rem" }}>
            <SystemBreadCrumbs currMenu="Issues Log" />
            <IssuesLogMenu />
            <div
                style={{
                    position: "relative",
                    zIndex: 1,
                    marginTop: "1rem",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    gap: "1rem",
                }}
            >
                <section id="NO_MONEY">
                    <IssueNoMoney />
                </section>
                <Divider style={{ margin: "3rem 0" }} />
                <section id="REQUEST_TIMEOUT">
                    <IssueRequestTimeout />
                </section>
                <Divider style={{ margin: "3rem 0" }} />
                <section id="CLOSED_ORDER">
                    <IssueClosedOrder />
                </section>
                <Divider style={{ margin: "3rem 0" }} />
                <section id="MODIFY_OPEN_ORDER">
                    <IssueModifyOpenOrder />
                </section>
                <Divider style={{ margin: "3rem 0" }} />
                <section id="MANAGER_ACCOUNT_INTERCEPTED">
                    <IssueManagerAccountIntercepted />
                </section>
                <Divider style={{ margin: "3rem 0" }} />
                <section id="MT5_ORDER_TYPE">
                    <IssueMT5OrderType />
                </section>
                <Divider style={{ margin: "3rem 0" }} />
                <section id="ACCOUNT_MONITOR">
                    <IssueAccountMonitor />
                </section>
                <Divider style={{ margin: "3rem 0" }} />
                <section id="RESTORE_ORDER">
                    <IssueRestoreOrder />
                </section>
                <Divider style={{ margin: "3rem 0" }} />
                <section id="UPDATE_ORDER_URGENT">
                    <IssueUpdateOrderUrgent />
                </section>
                <Divider style={{ margin: "3rem 0" }} />
                <section id="BALANCE_ADJUSTMENT_MONITOR">
                    <IssueBalanceAdjustmentMonitor />
                </section>
            </div>
            {/* <div style={{ marginTop: "1rem", paddingBottom: "1rem", background: "white", height: "300vh" }}>issues logs</div> */}
        </div>
    );
};

export default IssuesLog;
