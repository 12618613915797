import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import CardBox from "../../../components/Common/CardBox";
import FlexiDataTable from "../../../components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "../../../constants";
import { BrandsList, FlexiDataColumnProps, FlexiDataTableCallbackProps, FlexiDataTableOptionsProps } from "../../../constants/type";
import AuthHelper, { AuthKeys } from "../../../helpers/authHelper";
import { apiRequest } from "../../../services/apiConfig";
import { APIs } from "../../../services/apis";
import { useGetBrandRiskScoreConfigurationsQuery } from "../../../store/apis/riskScore";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";

const RiskScoreBrandPage = () => {
    let navigate = useNavigate();

    const { isLoading, data, refetch } = useGetBrandRiskScoreConfigurationsQuery({});
    const [, setManualLoading] = useState<boolean>(false);
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.ADMIN_RISKSCORE_BRAND_EDIT);
    const [brands, setBrands] = useState<BrandsList[]>([]);

    const getFilterConfigList = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["brand"] })
            .then((data: any) => {
                setBrands(data.brands);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => console.log(err));
            })
            .finally(() => {
                setManualLoading(false);
            });
    };

    useEffect(() => {
        getFilterConfigList();
        refetch();
        return () => {};
    }, []);

    const columns: FlexiDataColumnProps[] = [
        {
            title: "Brand",
            dataIndex: "brandId",
            key: "brandId",
            render: (id: number) => {
                let idx = brands.findIndex((x) => x.id === id);
                return idx > -1 ? brands[idx].brand : id;
            },
            options: {
                visible: true,
                filter: {
                    type: ComponentType.dropdown,
                    value: brands.map((x) => ({ text: x.brand, value: x.id })),
                },
            },
        },
        DTColProps.Large({
            title: "Account Type",
            dataIndex: "accountTypeName",
            key: "accountTypeName",
        }),
    ];

    const options: FlexiDataTableOptionsProps = {
        view: (record: any, option: any) => {
            let newProps = { ...option };
            newProps = <Link to={`/siteadmin/riskscore/brand/view/${record.brandId}`}>{newProps}</Link>;
            return newProps;
        },
        add: enableUpdate,
        edit: enableUpdate,
        delete: enableUpdate,
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, data) => {
        switch (type) {
            case CALLBACK_KEY.VIEW_RECORD:
                navigate(`/siteadmin/riskscore/brand/view/${data.brandId}`, {
                    state: { Id: data.brandId },
                });
                break;
            case CALLBACK_KEY.CREATE_NEW:
                navigate("/siteadmin/riskscore/brand/create", { state: { brandId: null, action: "add" } });
                break;
            case CALLBACK_KEY.DO_EDIT:
                navigate("/siteadmin/riskscore/brand/edit", {
                    state: {
                        action: "edit",
                        brandId: data.brandId,
                    },
                });
                break;
            case CALLBACK_KEY.DO_DELETE:
                setManualLoading(true);
                apiRequest(APIs.DELETE_BRAND_RISK_SCORE, { brandId: data.brandId })
                    .then((data) => {
                        ErrorMessageHandler("The brand risk score", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                        setManualLoading(false);
                        refetch();
                    })
                    .catch((error) => {
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("brand risk score", SUCCESS_FAILED.FAILED_DELETE_DATA, err));
                        setManualLoading(false);
                    });
                break;
            default:
                break;
        }
    };

    return (
        <CardBox title={"Brand Risk Score Configuration"}>
            <FlexiDataTable
                rowKeyProperty="brandId"
                title=""
                columns={columns}
                options={options}
                dataSource={data?.data || []}
                callback={componentCallback}
                loading={isLoading}
            />
        </CardBox>
    );
};

export default RiskScoreBrandPage;
