import { Spin } from "antd";
import CommonPaddingWrapper from "../../SpreadReportPage/CommonPaddingWrapper";

const Loader = () => {
    return (
        <CommonPaddingWrapper>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "2rem 0" }}>
                <Spin />
            </div>
        </CommonPaddingWrapper>
    );
};

export default Loader;
