import { Col, Row, Space, Typography } from "antd";
import React, { useMemo } from "react";

import { Area, Line } from "@ant-design/charts";
import { colorMap } from "../StatusMonitor/statusUtils";
import useRCServerPerformanceStore from "../../../store/useRCServerPerformanceStore";
import useRCServerPerformanceServerChart from "../../../hooks/useRCServerPerformanceServerChart";
import ServerSelector from "./ServerSelector";
import MessageCard from "../components/MessageCard";
const ServerPerformanceChart = () => {
    const { Title } = Typography;

    const currentFocusServer = useRCServerPerformanceStore(state => state.currentFocusServer);
    const { rcServerPerformanceServerChart, rcServerPerformanceServerChartError, isLoading } = useRCServerPerformanceServerChart(currentFocusServer);
    // console.log("rcServerPerformanceServerChart", rcServerPerformanceServerChart);

    const flattenData = useMemo(() => {
        return rcServerPerformanceServerChart?.flatMap(({ users, sockets, ...rest }) => [
            { ...rest, category: "users", val: users },
            { ...rest, category: "sockets", val: sockets },
        ]);
    }, [rcServerPerformanceServerChart]);

    const thousandSeparator = (v: any) => {
        return v?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };
    const commonColsSettings = {
        xs: { span: 24 },
        lg: { span: 12 },
        xxl: { span: 8 },
    };
    const commonSettings = {
        xAxis: {
            range: [0, 1],
            tickCount: 5,
        },
        slider: {
            start: 0,
            end: 1,
        },
        height: 150,
    };
    const configCPU = {
        data: rcServerPerformanceServerChart || [],
        xField: "serverTime",
        yField: "cpu",
        seriesField: "type",
        // color: ["#1979C9", "#D62A0D"],
        // change yAxis value to percentage
        yAxis: {
            label: {
                formatter: (v: any) => {
                    return `${v}%`;
                },
            },
        },
        areaStyle: () => {
            return {
                fill: `l(270) 0:${colorMap.success} 0.5:${colorMap.warning} 1:${colorMap.error}`,
            };
        },
        ...commonSettings,
    };
    const configFreeMemory = {
        data: rcServerPerformanceServerChart || [],
        xField: "serverTime",
        yField: "freeMemory",
        seriesField: "type",
        // color: ["#1979C9", "#D62A0D"],
        yAxis: {
            label: {
                formatter: (v: any) => {
                    // add thousand separator
                    return `${thousandSeparator(v)} MB`;
                },
            },
        },
        areaStyle: () => {
            return {
                fill: `l(270) 0:${colorMap.error} 0.5:${colorMap.warning} 1:${colorMap.success}`,
            };
        },
        ...commonSettings,
    };
    const configNetwork = {
        data: rcServerPerformanceServerChart || [],
        xField: "serverTime",
        yField: "network",
        seriesField: "type",
        yAxis: {
            label: {
                formatter: (v: any) => {
                    return `${thousandSeparator(v)} bps`;
                },
            },
        },
        areaStyle: () => {
            return {
                fill: `l(270) 0:${colorMap.success} 0.5:${colorMap.warning} 1:${colorMap.error}`,
            };
        },
        ...commonSettings,
    };
    const configUserSockets = {
        data: flattenData ?? [],
        xField: "serverTime",
        // yField: "users",
        yField: "val",
        seriesField: "category",
        yAxis: {
            label: {
                formatter: (v: any) => {
                    return `${thousandSeparator(v)}`;
                },
            },
        },
        ...commonSettings,
    };

    return (
        <Space style={{ width: "100%" }} direction="vertical" id="server_performance_chart">
            <Title level={4} style={{ margin: 0 }}>
                Server Performance Chart
            </Title>
            <ServerSelector />

            <Title level={5} style={{ margin: 0 }}>
                {currentFocusServer}
            </Title>
            {currentFocusServer === null ? (
                <MessageCard type="info">Please select a server</MessageCard>
            ) : isLoading ? (
                <MessageCard type="info">Loading...</MessageCard>
            ) : rcServerPerformanceServerChartError ? (
                <MessageCard type="error">{rcServerPerformanceServerChartError}</MessageCard>
            ) : rcServerPerformanceServerChart?.length === 0 ? (
                <MessageCard type="warning">No data from server</MessageCard>
            ) : (
                <Row gutter={[8, 8]}>
                    <Col {...commonColsSettings}>
                        <ChartWrapper>
                            <Space direction="vertical" style={{ width: "100%" }}>
                                <Title level={5}>CPU Usage</Title>
                                <Area {...configCPU} />
                            </Space>
                        </ChartWrapper>
                    </Col>
                    <Col {...commonColsSettings}>
                        <ChartWrapper>
                            <Space direction="vertical" style={{ width: "100%" }}>
                                <Title level={5}>Free Memory</Title>
                                <Area {...configFreeMemory} />
                            </Space>
                        </ChartWrapper>
                    </Col>
                    <Col {...commonColsSettings}>
                        <ChartWrapper>
                            <Space direction="vertical" style={{ width: "100%" }}>
                                <Title level={5}>Network</Title>
                                <Area {...configNetwork} />
                            </Space>
                        </ChartWrapper>
                    </Col>
                    <Col {...commonColsSettings}>
                        <ChartWrapper>
                            <Space direction="vertical" style={{ width: "100%" }}>
                                <Title level={5}>Users & Sockets</Title>
                                <Line {...configUserSockets} />
                            </Space>
                        </ChartWrapper>
                    </Col>
                </Row>
            )}
        </Space>
    );
};

const ChartWrapper = ({ children }: { children: React.ReactNode }) => {
    return (
        <div style={{ width: "100%", backgroundColor: "rgba(240,242,245,0.5)", padding: "1rem", borderRadius: "5px" }}>
            <>{children}</>
        </div>
    );
};

export default React.memo(ServerPerformanceChart);
