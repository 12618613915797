import { HomeOutlined } from "@ant-design/icons";
import { Breadcrumb, Descriptions, Empty, Modal } from "antd";
import { cloneDeep } from "lodash";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ChartDisplayTemplate from "../../../components/Charts/UI";
import FlexiDataTable from "../../../components/FlexiDataTable";
import LoadingComponent from "../../../components/Loading";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "../../../constants";
import { FlexiDataTableCallbackProps, FlexiDataTableOptionsProps, HistoricalTrades, ViewProps } from "../../../constants/type";
import { apiRequest } from "../../../services/apiConfig";
import { APIs } from "../../../services/apis";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";
import { ViewCollectionItemProps } from "./TradeMonitoringPanel";
import { DateTimeUtil } from "../../../utils/datetime";

export interface TradeMonitorProfileComponentProps {
    currProfileId: string;
    hideBreadcrum?: boolean;
    isDashboardView?: boolean;
}

const TradeMonitorProfileComponent = (props: TradeMonitorProfileComponentProps) => {
    let navigate = useNavigate();

    const [currData, setCurrData] = useState<any>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLoadingView, setIsLoadingView] = useState<boolean>(true);
    const [isShowTicketModal, setIsShowTicketModal] = useState<boolean>(false);
    const [viewDataList, setViewDataList] = useState<any[]>([]);
    const [currViewId, setCurrViewId] = useState<string>("0");
    const [viewCollection, setViewCollection] = useState<ViewCollectionItemProps[]>([]);
    const [tickets, setTickets] = useState<any[]>([]);
    const [ticketLoading, setTicketLoading] = useState<boolean>(true);

    const checkAvailableChildren = (currView: any, datas: any) => {
        if (Object.keys(datas).indexOf("Children") > -1 && datas["Children"].length > 0) {
            let tmpObj = {
                level: currView.level + 1,
                chartType: 0,
                chartConfig: {},
                description: "",
                isEventRuleView: true,
                name: "",
                id: `${currView.id}.`,
                profileId: currView.profileId,
                ...datas["Children"][0].Config,
            };
            tmpObj.chartConfig = JSON.stringify(tmpObj.chartConfig);
            return tmpObj;
        }
        return undefined;
    };

    const getProfileView = (
        profileId: number,
        isEventSummaryProfile: boolean,
        threshold: any,
        viewId: string,
        isEventRuleView: boolean,
        params: any
    ) => {
        let new_collection_list = cloneDeep(viewCollection);
        let foundIdx = new_collection_list.findIndex((x) => x.profileId === profileId && x.viewId === viewId);
        if (foundIdx > -1) {
            new_collection_list[foundIdx] = { profileId, viewId, isEventRuleView, params };
        } else {
            new_collection_list.push({ profileId, viewId, isEventRuleView, params });
        }
        setViewCollection(new_collection_list);
        setCurrViewId(viewId);
        setIsLoadingView(true);
        if (isEventRuleView && typeof viewId === "string" && viewId.indexOf(".") > -1) {
            setViewDataList(
                params["Children"].map((y: any, index: number) => {
                    y["id"] = index;
                    return y;
                })
            );
            setTimeout(() => {
                setIsLoadingView(false);
                setIsLoading(false);
            }, 500);
        } else {
            apiRequest(APIs.VIEW_TRADE_PROFILE, { profileId, viewId, threshold, isEventRuleView, isEventSummaryProfile, params })
                .then((res: any) => {
                    if (res.length > 0) {
                        //columnAsMetric - refactor Data list to using column as metric
                        if (isEventSummaryProfile && isEventRuleView) {
                            let cIdx = currData.views.findIndex((x: any) => x.id === viewId);
                            if (cIdx > -1) {
                                let newCurrData = cloneDeep(currData);
                                let nextViewsProps = checkAvailableChildren(currData.views[cIdx], res[0]);
                                if (nextViewsProps) {
                                    let parentIdx = newCurrData.views.length;
                                    let tryFound = newCurrData.views.findIndex((x: any) => x.id === nextViewsProps.id);
                                    if (tryFound > -1) {
                                        newCurrData.views[tryFound] = nextViewsProps;
                                        parentIdx = tryFound - 1;
                                    } else {
                                        newCurrData.views.push(nextViewsProps);
                                        parentIdx = parentIdx - 1;
                                    }
                                    //Update parent config - START -
                                    newCurrData.views[parentIdx] = Object.assign({}, newCurrData.views[parentIdx], res[0].Config);
                                    if (typeof newCurrData.views[parentIdx].chartConfig === "object") {
                                        newCurrData.views[parentIdx].chartConfig = JSON.stringify(newCurrData.views[parentIdx].chartConfig);
                                    }
                                    //Update parent config - END -
                                } else {
                                    if (newCurrData.views.length > cIdx + 1) {
                                        newCurrData.views.splice(cIdx + 1);
                                    }
                                }

                                if (res[0].Config) {
                                    let newViewConfig = {
                                        ...res[0].Config,
                                        chartConfig:
                                            typeof res[0].Config.chartConfig === "object"
                                                ? JSON.stringify(res[0].Config.chartConfig)
                                                : res[0].Config.chartConfig,
                                    };
                                    newCurrData.views[cIdx] = Object.assign(newCurrData.views[cIdx], newViewConfig);
                                }

                                setCurrData(newCurrData);
                            }
                        }

                        setViewDataList(
                            res.map((y: any, index: number) => {
                                y["id"] = index;
                                return y;
                            })
                        );
                    } else {
                        setViewDataList([]);
                    }
                })
                .catch((error) => {
                    ErrorCatchValidator(error, (err: any) => {});
                    //Trigger re-render to make table's count down button to reset
                    setViewDataList([]);
                })
                .finally(() => {
                    setIsLoadingView(false);
                    setIsLoading(false);
                });
        }
    };

    const onComponentCallback = (type: CALLBACK_KEY, data: any) => {
        switch (type) {
            case CALLBACK_KEY.REFRESH:
                let currentViewCollectionIndex = viewCollection.findIndex((x) => x.viewId === `${data.id}`);
                if (currentViewCollectionIndex > -1) {
                    getProfileView(
                        parseInt(props.currProfileId),
                        currData.isEventSummaryProfile,
                        currData.threshold,
                        `${data.id}`,
                        data.isEventRuleView,
                        viewCollection[currentViewCollectionIndex].params
                    );
                }

                break;
            case CALLBACK_KEY.ROW_SELECTION_CALLBACK:
                let allParamsProps = Object.keys(data.params).map((x) => x.toString().toLowerCase());
                let currentIndex = currData.views.findIndex((x: ViewProps) => (x.id || "").toString() === data.viewId.toString());
                if (
                    allParamsProps.indexOf("account id") > -1 &&
                    allParamsProps.indexOf("server id") > -1 &&
                    currData.views.length - 1 === currentIndex
                ) {
                    return false;
                } else {
                    setIsLoading(true);
                    //let currentIndex = currData.views.findIndex((x: ViewProps) => (x.id || "").toString() === data.viewId.toString());
                    if (currData.isEventSummaryProfile) {
                        getProfileView(
                            data.profileId,
                            currData.isEventSummaryProfile,
                            currData.threshold,
                            `${currData.views[currentIndex + 1].id}`,
                            currData.views[currentIndex + 1].isEventRuleView,
                            data.params
                        );
                    } else {
                        getProfileView(
                            data.profileId,
                            currData.isEventSummaryProfile,
                            currData.threshold,
                            `${currData.views[currentIndex + 1].id}`,
                            currData.views[currentIndex + 1].isEventRuleView,
                            data.params
                        );
                    }
                }
                break;
            case CALLBACK_KEY.BACK_TO_PREVIOUS:
                setIsLoading(true);
                getProfileView(
                    data.profileId,
                    currData.isEventSummaryProfile,
                    currData.threshold,
                    `${data.viewId}`,
                    data.isEventRuleView,
                    data.params
                );
                break;
            case CALLBACK_KEY.OTHERS:
                setIsShowTicketModal(true);
                getTicketList(data["Ticket Batch"]);
                break;
        }
    };

    const ticketTableColumnConfig = [
        DTColProps.Small({
            title: "Ticket",
            dataIndex: "Ticket",
            key: "Ticket",
            //sorter: (a: any, b: any) => a["Ticket"] - b["Ticket"],
            options: {
                filter: {
                    type: ComponentType.number,
                    callback: (filterValue: any, rowData: any) => rowData["Ticket"].toString().indexOf(filterValue) > -1,
                },
            },
        }),
        DTColProps.Small({
            title: "Position ID",
            dataIndex: "Position ID",
            key: "Position ID",
            sorter: (a: any, b: any) => a["Position ID"] - b["Position ID"],
            options: {
                filter: {
                    type: ComponentType.number,
                    callback: (filterValue: any, rowData: any) => rowData["Position ID"].toString().indexOf(filterValue) > -1,
                },
            },
        }),
        DTColProps.Small({
            title: "Symbol",
            dataIndex: "Symbol",
            key: "Symbol",
        }),
        DTColProps.XSmall({
            title: "Type",
            dataIndex: "Type",
            key: "Type",
        }),
        DTColProps.XSmall({
            title: "Direction",
            dataIndex: "Direction",
            key: "Direction",
        }),
        DTColProps.Small(
            {
                title: "Contract Size",
                dataIndex: "Contract Size",
                key: "Contract Size",
                sorter: (a: any, b: any) => a["Contract Size"] - b["Contract Size"],
            },
            ["text-right"]
        ),
        DTColProps.Small(
            {
                title: "Volume",
                dataIndex: "Volume",
                key: "Volume",
                sorter: (a: any, b: any) => a["Volume"] - b["Volume"],
            },
            ["text-right"]
        ),
        DTColProps.Small(
            {
                title: "Volume Closed",
                dataIndex: "Volume Closed",
                key: "Volume Closed",
                sorter: (a: any, b: any) => a["Volume Closed"] - b["Volume Closed"],
            },
            ["text-right"]
        ),
        DTColProps.Small(
            {
                title: "Lot Size",
                dataIndex: "Lot Size",
                key: "Lot Size",
                sorter: (a: any, b: any) => a["Lot Size"] - b["Lot Size"],
            },
            ["text-right"]
        ),
        DTColProps.Small(
            {
                title: "Lot Size Closed",
                dataIndex: "Lot Size Closed",
                key: "Lot Size Closed",
                sorter: (a: any, b: any) => a["Lot Size Closed"] - b["Lot Size Closed"],
            },
            ["text-right"]
        ),
        DTColProps.DateTime({
            title: "Open Time",
            dataIndex: "Open Time",
            key: "Open Time",
            sorter: false,
            render: (text: any, record: HistoricalTrades) => DateTimeUtil.GetUTC(text, "YYYY-MM-DD HH:mm:ss"),
        }),
        DTColProps.DateTime({
            title: "Close Time",
            dataIndex: "Close Time",
            key: "Close Time",
            sorter: false,
            render: (text: any, record: HistoricalTrades) => DateTimeUtil.GetUTC(text, "YYYY-MM-DD HH:mm:ss"),
        }),
        DTColProps.SCurrency(
            {
                title: "Open Price",
                dataIndex: "Open Price",
                key: "Open Price",
                sorter: (a: any, b: any) => a["Open Price"] - b["Open Price"],
            },
            ["text-right"],
            -1
        ),
        DTColProps.SCurrency(
            {
                title: "Close Price",
                dataIndex: "Close Price",
                key: "Close Price",
                sorter: (a: any, b: any) => a["Close Price"] - b["Close Price"],
            },
            ["text-right"],
            -1
        ),
        DTColProps.SCurrency(
            {
                title: "Stop Loss",
                dataIndex: "Stop Loss",
                key: "Stop Loss",
                sorter: (a: any, b: any) => a["Stop Loss"] - b["Stop Loss"],
            },
            ["text-right"],
            -1
        ),
        DTColProps.SCurrency(
            {
                title: "Take Profit",
                dataIndex: "Take Profit",
                key: "Take Profit",
                sorter: (a: any, b: any) => a["Take Profit"] - b["Take Profit"],
            },
            ["text-right"],
            -1
        ),

        DTColProps.SCurrency(
            {
                title: "Profit",
                dataIndex: "Profit",
                key: "Profit",
                sorter: (a: any, b: any) => a["Profit"] - b["Profit"],
            },
            ["text-right"],
            -1
        ),
        DTColProps.SCurrency(
            {
                title: "Commission",
                dataIndex: "Commission",
                key: "Commission",
                sorter: (a: any, b: any) => a["Commission"] - b["Commission"],
            },
            ["text-right"],
            -1
        ),
        DTColProps.SCurrency(
            {
                title: "Swaps",
                dataIndex: "Swaps",
                key: "Swaps",
                sorter: (a: any, b: any) => a["Swaps"] - b["Swaps"],
            },
            ["text-right"],
            -1
        ),
        DTColProps.SCurrency(
            {
                title: "Taxes",
                dataIndex: "Taxes",
                key: "Taxes",
                sorter: (a: any, b: any) => a["Taxes"] - b["Taxes"],
            },
            ["text-right"],
            -1
        ),

        DTColProps.SCurrency(
            {
                title: "Fee",
                dataIndex: "Fee",
                key: "Fee",
                sorter: (a: any, b: any) => a["Fee"] - b["Fee"],
            },
            ["text-right"],
            -1
        ),
        DTColProps.SCurrency(
            {
                title: "P&L",
                dataIndex: "P&L",
                key: "P&L",
                sorter: (a: any, b: any) => a["P&L"] - b["P&L"],
            },
            ["text-right"],
            -1
        ),
        DTColProps.Middle({
            title: "Holding Duration",
            dataIndex: "Holding Duration",
            key: "Holding Duration",
            render: (text: any) => {
                try {
                    let tmp: any = typeof text === "string" ? JSON.parse(text) : text;
                    if (Array.isArray(tmp)) {
                        return (
                            <Descriptions column={1} size="small" className="tiny-description">
                                {(tmp as any[]).map((x) => (
                                    <Descriptions.Item key={`ti-${Math.random()}`}>{`${x["Holding Time"]} (${x["Ticket"]})`}</Descriptions.Item>
                                ))}
                            </Descriptions>
                        );
                    } else {
                        return tmp["Holding Time"];
                    }
                } catch (error) {}
                return text;
            },
        }),
    ];

    const options: FlexiDataTableOptionsProps = {
        enableFilter: false,
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, ServerData) => {};

    const getTicketList = (ticketGUID: string) => {
        setTicketLoading(true);
        apiRequest(APIs.GET_MONITOR_PROFILE_TICKETS, { ticketBatchId: ticketGUID })
            .then((res: any) => {
                setTickets(res);
            })
            .catch((error) => ErrorCatchValidator(error, (err: any) => {}))
            .finally(() => setTicketLoading(false));
    };

    useEffect(() => {
        apiRequest(APIs.GET_MONITOR_PROFILE, { profileId: props.currProfileId })
            .then((res: any) => {
                let data: any = { ...res, profileId: res.id };
                if (data.eventRules) {
                    data.eventRules = data.eventRules.map((x: any) => {
                        x.status = x.status === 1;
                        return x;
                    });
                }
                if (data.views.length > 0) {
                    data.views = data.views.map((x: any) => ({ ...x, id: x.id.toString() }));
                    data.views.sort((a: ViewProps, b: ViewProps) => a.level - b.level);
                }

                setCurrData(data);
                if (data.views.length > 0) {
                    getProfileView(
                        data.views[0].profileId,
                        data.isEventSummaryProfile,
                        data.threshold,
                        data.views[0].id,
                        data.views[0].isEventRuleView,
                        {}
                    );
                }
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("monitor profile details", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                navigate("/realtime/trademonitor");
            });
        return () => {};
    }, []);

    return (
        <>
            {!props.hideBreadcrum && (
                <div className="breadcrumb-panel">
                    <Breadcrumb>
                        <Breadcrumb.Item href="#" onClick={() => navigate("/realtime/trademonitor")}>
                            <HomeOutlined />
                            <span>Monitor Profile List</span>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>Monitor Panel</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            )}
            <div className="monitor-panel-container">
                {isLoading ? (
                    <div className="loading-container">
                        <LoadingComponent tip="Loading..." />
                    </div>
                ) : (
                    <div className="content">
                        <div className="tiles">
                            <ChartDisplayTemplate
                                configuration={{
                                    ...currData,
                                    disabledTimer: false,
                                    isLoadingView,
                                    currentViewId: currViewId,
                                    viewConfig: currData.views.filter((x: ViewProps) => x.id?.toString() === currViewId.toString())[0],
                                    viewCollection,
                                }}
                                dataList={viewDataList}
                                componentCallback={onComponentCallback}
                                isDashboardView={props.isDashboardView}
                            />
                        </div>
                    </div>
                )}
            </div>

            <Modal
                width="90%"
                destroyOnClose
                maskClosable={false}
                title={"Tickets"}
                open={isShowTicketModal}
                cancelText={"Close"}
                okButtonProps={{
                    style: {
                        display: "none",
                    },
                }}
                onCancel={() => setIsShowTicketModal(false)}
            >
                {ticketLoading ? (
                    <div className="loading-container">
                        <LoadingComponent tip="Loading..." />
                    </div>
                ) : tickets.length === 0 ? (
                    <Empty />
                ) : (
                    <FlexiDataTable
                        rowKeyProperty="Ticket"
                        title={false}
                        columns={ticketTableColumnConfig}
                        options={options}
                        dataSource={tickets}
                        loading={false}
                        callback={componentCallback}
                    />
                )}
            </Modal>
        </>
    );
};

export default TradeMonitorProfileComponent;
