import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { APIs } from "../services/apis";
import { plainAxiosInstance } from "../services/axiosSetup";

/*
sample response from the API
[
  false,
  [
    {
      "seperateStr": "@",
      "id": 8996,
      "batchName": "PriceAlarmSummary",
      "params": "startDate=2024-06-06&endDate=2024-06-08",
      "status": "COMPLETED",
      "startTime": "2024-06-08T13:54:00.000+00:00",
      "endTime": "2024-06-08T13:54:50.000+00:00",
      "fileName": "PriceAlarmSummary_2024-06-06_2024-06-08",
      "filePath": "/8996/PriceAlarmSummary_2024-06-06_2024-06-08.zip",
      "downloadCount": 1,
      "lastDownloadTime": "2024-06-10T00:56:01.000+00:00",
      "createUser": 394,
      "createTime": "2024-06-08T13:53:37.000+00:00",
      "note": "Job DONE.",
      "batchIdNameParams": "8996@PriceAlarmSummary@startDate=2024-06-06&endDate=2024-06-08"
    },
    {
      "seperateStr": "@",
      "id": 9011,
      "batchName": "PriceAlarmSummary",
      "params": "startDate=2024-06-01&endDate=2024-06-01",
      "status": "COMPLETED",
      "startTime": "2024-06-10T06:24:00.000+00:00",
      "endTime": "2024-06-10T06:24:49.000+00:00",
      "fileName": "PriceAlarmSummary_2024-06-01_2024-06-01",
      "filePath": "/9011/PriceAlarmSummary_2024-06-01_2024-06-01.zip",
      "downloadCount": 0,
      "lastDownloadTime": null,
      "createUser": 394,
      "createTime": "2024-06-10T06:23:45.000+00:00",
      "note": "Job DONE.",
      "batchIdNameParams": "9011@PriceAlarmSummary@startDate=2024-06-01&endDate=2024-06-01"
    }
  ]
]
*/

export type IRCDownload = [boolean, IRCDownloadItem[]];

export interface IRCDownloadItem {
    seperateStr: string;
    id: number;
    batchName: string;
    params: string;
    status: string;
    startTime: string;
    endTime: string;
    fileName: string;
    filePath: string;
    downloadCount: number;
    lastDownloadTime: string;
    createUser: number;
    createTime: string;
    note: string;
    batchIdNameParams: string;
}

const useRCDownloads = () => {
    const { data, refetch, isLoading, error, isFetching, dataUpdatedAt } = useQuery({
        queryKey: ["rcDownloads"],
        queryFn: () =>
            plainAxiosInstance.get(`${APIs.RC_ROOT.GET_DOWNLOADS}`, {
                headers: {
                    Accept: "application/json",
                },
            }),
        staleTime: 20 * 1000, //(10 x 1000ms = 10 seconds)
        refetchInterval: 20 * 1000, //(10 x 1000ms = 10 seconds)
    });

    const rcDownloads: IRCDownload | null = data?.data?.status ? null : data?.data || null;
    // @ts-ignore
    const rcDownloadsError: string | null = data?.data?.message || error?.message || error || null;

    return {
        rcDownloads,
        rcDownloadsError,
        refetchRcServers: refetch,
        isFetching,
        isLoading,
        dataUpdatedAt,
    };
};

export const useRCDownloadsMutation = () => {
    const queryClient = useQueryClient();
    // GENERATE_REPORT
    const {
        mutateAsync: mutateGenerateReport,
        isLoading: isLoadingGenerateReport,
        error: errorGenerateReport,
    } = useMutation(
        ({ startDate, endDate }: { startDate: string; endDate: string }) => {
            return plainAxiosInstance.get(`${APIs.RC_PRICE_ALARM.GET_REPORT}?startDate=${startDate}&endDate=${endDate}`, {
                headers: {
                    Accept: "application/json",
                },
            });
        },
        {
            onSuccess: res => {
                queryClient.invalidateQueries(["rcDownloads"]);
                return res;
            },
            onError: error => {
                queryClient.invalidateQueries(["rcDownloads"]);
                console.error(`Error on generating report:`, error);
                return error;
            },
        }
    );

    const {
        mutateAsync: mutateDeleteDownload,
        isLoading: isLoadingDownload,
        error: errorDeleteDownload,
    } = useMutation(
        ({ id }: { id: number }) => {
            return plainAxiosInstance.delete(`${APIs.RC_ROOT.DELETE_DOWNLOAD}?id=${id}`, {
                headers: {
                    Accept: "application/json",
                },
            });
        },
        {
            onSuccess: res => {
                queryClient.invalidateQueries(["rcDownloads"]);
                return res;
            },
            onError: error => {
                queryClient.invalidateQueries(["rcDownloads"]);
                console.error(`Error on deleting download item:`, error);
                return error;
            },
        }
    );
    return {
        mutateGenerateReport,
        isLoadingGenerateReport,
        errorGenerateReport,

        mutateDeleteDownload,
        isLoadingDownload,
        errorDeleteDownload,
    };
};

export default useRCDownloads;
