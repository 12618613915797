import React, { useEffect, useMemo, useState } from "react";
import FlexiDataTable from "../../../../components/FlexiDataTable";
import { currencyRender, DataTableColumnRender, DTColProps } from "../../../../utils/Common";
import { CaretDownOutlined, CaretUpOutlined, CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { BrandsList, CidLabelsList, PreMarketAccountProfile, ServersList } from "../../../../constants/type";
import { isEmptyOrNull } from "../../../../utils/string";

interface PreMarketAccModelAuditLogProps {
    auditLogData: PreMarketAccountProfile[];
    servers: ServersList[];
    brands: BrandsList[];
    cidLabels: CidLabelsList[];
    countries: string[];
};

interface PreMarketAccountProfileLog extends PreMarketAccountProfile {
    serverName?: string;
    icon: JSX.Element | null;
};

const ViewAuditLog = (props: PreMarketAccModelAuditLogProps) => {
    const [logData, setLogData] = useState<PreMarketAccountProfileLog[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const columns = useMemo(() => [
        DTColProps.DateTime_ServerTime({
            title: "Date (Server)",
            dataIndex: "createdTime",
            key: "createdTime",
            fixed: "left",
            sorter: false,
        }),
        DTColProps.XSmall({
            title: "Brand",
            dataIndex: "brandId",
            key: "brandId",
            render: (brandId: number | null) => {
                if (brandId === null || brandId === undefined) return "-";
                else {
                    let currBrand = props.brands.find((x: BrandsList) => x.id === brandId);
                    if (currBrand !== undefined) return currBrand.brand;
                    else return "-";
                }
            },
        }),
        DTColProps.Small({
            title: "Country",
            dataIndex: "country",
            key: "country",
            render: (country: string) => isEmptyOrNull(country) ? "-" : country,
        }),
        DTColProps.Small({
            title: "Nationality",
            dataIndex: "nationality",
            key: "nationality",
            render: (nationality: string) => isEmptyOrNull(nationality) ? "-" : nationality,
        }),
        DTColProps.XSmall({
            title: "Email",
            dataIndex: "email",
            key: "email",
            render: (email: string) => isEmptyOrNull(email) ? "-" : email,
            width: "10vw",
        }),
        DTColProps.DateTime_ServerTime({
            title: "Registration Date (Server)",
            dataIndex: "regDate",
            key: "regDate",
            sorter: false,
            render: (value: string) => (value ? DataTableColumnRender.DateTime_ServerTime(value, "YYYY-MM-DD HH:mm:ss") : "-"),
        }),
        DTColProps.DateTime_ServerTime({
            title: "Last Accessed Date (Server)",
            dataIndex: "lastDate",
            key: "lastDate",
            sorter: false,
            render: (value: string) => (value ? DataTableColumnRender.DateTime_ServerTime(value, "YYYY-MM-DD HH:mm:ss") : "-"),
        }),
        DTColProps.Small({
            title: "CID Label",
            dataIndex: "cidLabelIds",
            key: "cidLabelIds",
            render: (cidLabelIds: number[]) => {
                if (cidLabelIds.length === 0) return "-";

                return cidLabelIds.map((currId: number, idx: number) => {
                    let currCidLabel = props.cidLabels.find((y: CidLabelsList) => y.id === currId);
                    if (currCidLabel === undefined) return undefined;

                    return (
                        <div style={{ display: "flex" }} key={`${currCidLabel.id}-${idx}`}>
                            <div
                                style={{
                                    backgroundColor: `${currCidLabel.hexCode}`,
                                    color: "white",
                                    marginBottom: "0.9898vh",
                                    padding: "0.16vh 0.651vw",
                                    wordWrap: "break-word",
                                    borderRadius: 3,
                                    fontSize: "0.7rem",
                                }}
                            >
                                {currCidLabel.name}
                            </div>
                        </div>
                    );
                });
            },
        }),
        DTColProps.Small({
            title: "CID Toxicity Ratio",
            dataIndex: "cidToxicityRatio",
            key: "cidToxicityRatio",
        }),
        {
            title: "Special Rules",
            className: "special-rules",
            children: [
                {
                    title: "Has Comment",
                    className: "has-comment",
                    children: [
                        DTColProps.XSmall(
                            {
                                title: "Crypto D/W",
                                dataIndex: "hasCryptoDwCmt",
                                key: "hasCryptoDwCmt",
                                render: (hasCryptoDwCmt: boolean) =>
                                    hasCryptoDwCmt ? (
                                        <CheckCircleOutlined style={{ color: "#0ab76e", fontSize: "1.375rem" }} />
                                    ) : (
                                        <CloseCircleOutlined style={{ color: "#f00f00", fontSize: "1.375rem" }} />
                                    ),
                            },
                            ["text-center"]
                        ),
                        DTColProps.XSmall(
                            {
                                title: "Credit Card",
                                dataIndex: "hasCreditCardCmt",
                                key: "hasCreditCardCmt",
                                render: (hasCreditCardCmt: boolean) =>
                                    hasCreditCardCmt ? (
                                        <CheckCircleOutlined style={{ color: "#0ab76e", fontSize: "1.375rem" }} />
                                    ) : (
                                        <CloseCircleOutlined style={{ color: "#f00f00", fontSize: "1.375rem" }} />
                                    ),
                            },
                            ["text-center"]
                        ),
                        DTColProps.XSmall(
                            {
                                title: "Bank",
                                dataIndex: "hasBankCmt",
                                key: "hasBankCmt",
                                render: (hasBankCmt: boolean) =>
                                    hasBankCmt ? (
                                        <CheckCircleOutlined style={{ color: "#0ab76e", fontSize: "1.375rem" }} />
                                    ) : (
                                        <CloseCircleOutlined style={{ color: "#f00f00", fontSize: "1.375rem" }} />
                                    ),
                            },
                            ["text-center"]
                        ),
                    ],
                },
                DTColProps.XSmall(
                    {
                        title: "Has US IP",
                        dataIndex: "hasUsIp",
                        key: "hasUsIp",
                        render: (hasUsIp: boolean) =>
                            hasUsIp ? (
                                <CheckCircleOutlined style={{ color: "#0ab76e", fontSize: "1.375rem" }} />
                            ) : (
                                <CloseCircleOutlined style={{ color: "#f00f00", fontSize: "1.375rem" }} />
                            ),
                    },
                    ["text-center"]
                ),
                DTColProps.XSmall(
                    {
                        title: "Is Nationality Differ From Country",
                        dataIndex: "isNationalityDifferFromCountry",
                        key: "isNationalityDifferFromCountry",
                        render: (isNationalityDifferFromCountry: boolean) =>
                            isNationalityDifferFromCountry ? (
                                <CheckCircleOutlined style={{ color: "#0ab76e", fontSize: "1.375rem" }} />
                            ) : (
                                <CloseCircleOutlined style={{ color: "#f00f00", fontSize: "1.375rem" }} />
                            ),
                    },
                    ["text-center"]
                ),
            ],
        },
        {
            title: "Scores",
            className: "scores",
            children: [
                DTColProps.XSmall(
                    {
                        title: "Country",
                        dataIndex: "countryScore",
                        key: "countryScore",
                        sorter: true,
                    },
                    ["text-center"]
                ),
                DTColProps.XSmall(
                    {
                        title: "Email",
                        dataIndex: "emailScore",
                        key: "emailScore",
                        sorter: true,
                    },
                    ["text-center"]
                ),
                DTColProps.XSmall(
                    {
                        title: "CID",
                        dataIndex: "cidScore",
                        key: "cidScore",
                        sorter: true,
                    },
                    ["text-center"]
                ),
                DTColProps.XSmall(
                    {
                        title: "CID Toxicity Ratio",
                        dataIndex: "cidToxicityRatioScore",
                        key: "cidToxicityRatioScore",
                        sorter: true,
                    },
                    ["text-center"]
                ),
                DTColProps.XSmall(
                    {
                        title: "Crypto D/W",
                        dataIndex: "cryptoDwCmtScore",
                        key: "cryptoDwCmtScore",
                        sorter: true,
                    },
                    ["text-center"]
                ),
                DTColProps.XSmall(
                    {
                        title: "Credit Card",
                        dataIndex: "creditCardCmtScore",
                        key: "creditCardCmtScore",
                        sorter: true,
                    },
                    ["text-center"]
                ),
                DTColProps.XSmall(
                    {
                        title: "Bank",
                        dataIndex: "bankCmtScore",
                        key: "bankCmtScore",
                        sorter: true,
                    },
                    ["text-center"]
                ),
                DTColProps.XSmall(
                    {
                        title: "US IP",
                        dataIndex: "usIpScore",
                        key: "usIpScore",
                        sorter: true,
                    },
                    ["text-center"]
                ),
                DTColProps.XSmall(
                    {
                        title: "Nationality Differ From Country",
                        dataIndex: "nationalityDifferFromCountryScore",
                        key: "nationalityDifferFromCountryScore",
                    },
                    ["text-center"]
                ),
            ],
        },
        DTColProps.XSmall(
            {
                title: "Pre-Market Score",
                dataIndex: "preMarketScore",
                key: "preMarketScore",
                fixed: "right",
                render: (value: number, rowData: PreMarketAccountProfileLog) => (
                    <div>
                        <span>{currencyRender(value)}</span>
                        {rowData.icon}
                    </div>
                ),
            },
            ["text-center"]
        ),
        DTColProps.XXSmall(
            {
                title: "Is Toxic",
                dataIndex: "isToxic",
                key: "isToxic",
                fixed: "right",
                render: (isToxic: boolean) => isToxic
                    ? <CheckCircleOutlined style={{ color: "#0ab76e", fontSize: "1.375rem" }} />
                    : <CloseCircleOutlined style={{ color: "#f00f00", fontSize: "1.375rem" }} />,
            },
            ["text-center"]
        )
    ], [props]);

    const getIcon = (currScore: number, prevScore: number) => {
        if (currScore > prevScore) {
            return <CaretUpOutlined style={{ color: "green", marginLeft: 3, fontSize: "1rem" }} />;
        } else if (currScore < prevScore) {
            return <CaretDownOutlined style={{ color: "red", marginLeft: 3, fontSize: "1rem" }} />;
        } else {
            return null;
        };
    };

    useEffect(() => {
        if (props.auditLogData) {
            let thisServer = props.servers.find((x: ServersList) => x.id === props.auditLogData[0]?.serverId);
            let newData = props.auditLogData.map((currLog: PreMarketAccountProfile, i: number) => {
                if (i === props.auditLogData.length - 1) {
                    return {
                        ...currLog,
                        serverName: isEmptyOrNull(thisServer) ? "-" : thisServer?.server,
                        icon: null,
                    };
                } else {
                    return {
                        ...currLog,
                        serverName: isEmptyOrNull(thisServer) ? "-" : thisServer?.server,
                        icon: getIcon(currLog.preMarketScore, props.auditLogData[i + 1].preMarketScore),
                    };
                }
            });
            setLogData(newData);
            setIsLoading(false);
        };
    }, [props.auditLogData]);

    return (
        <div>
            <FlexiDataTable
                rowKeyProperty="createdTime"
                title={<>
                    <p style={{ margin: "auto", fontWeight: "bold" }}>
                        <span>Account ID: {props.auditLogData[0]?.accountId}</span>
                        <span style={{ marginLeft: "1.953vw" }}>Server: {logData[0]?.serverName}</span>
                    </p>
                </>}
                columns={columns}
                dataSource={logData || []}
                loading={isLoading}
                options={{
                    defaultCollapseFilterPanel: true,
                    enableFilter: false,
                }}
                callback={() => { }}
                tableProps={{ bordered: true }}
                pagination={{ hideOnSinglePage: true }}
            />
        </div>
    )
}

export default ViewAuditLog;