import CardBox from "@/components/Common/CardBox";
import FlexiDataTable from "@/components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType } from "@/constants";
import { FlexiDataTableCallbackProps, FlexiDataTableOptionsProps } from "@/constants/type";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { FileSearchOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, message, Modal } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import SpreadGoogleSheetModal, { SpreadGoogleSheetModalCallbackKey } from "./components/logModal";

export interface SpreadGoogleSheetPageProps {}

export interface SpreadGoogleSheetServerProps {
    type: number;
    serverUno: number;
    serverDn: string;
}

const SpreadGoogleSheetPage = (props: SpreadGoogleSheetPageProps) => {
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSyncing, setIsSyncing] = useState<boolean>(false);
    const [data, setData] = useState<SpreadGoogleSheetServerProps[]>([]);
    const [selectedItem, setSelectedItem] = useState<SpreadGoogleSheetServerProps | undefined>(undefined);
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

    const selectedButtonElement = useCallback((): React.ReactNode => {
        if (selectedRowKeys.length === 0) {
            return <></>;
        }
        return (
            <Button
                danger
                type="default"
                htmlType="button"
                style={{ marginLeft: "0.651vw", width: "auto" }}
                icon={<ReloadOutlined />}
                onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();
                    Modal.confirm({
                        title: `Reload Confirmation`,
                        content: <> {`Reload selected servers?`} </>,
                        onOk: () => {
                            setSelectedRowKeys([]);
                            resyncServerData(selectedRowKeys);
                        },
                    });
                }}
            >
                Reload Selected Tables
            </Button>
        );
    }, [selectedRowKeys]);

    const columns: any[] = [
        {
            title: "Server",
            dataIndex: "serverDn",
            key: "serverDn",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        },
    ];

    const options: FlexiDataTableOptionsProps = useMemo(
        () => ({
            extraButtons: selectedButtonElement, //[{ text: "Reload Selected Tables", icon: <ReloadOutlined />, value: "reloadSelectedServers" }],
            separateActionButton: true,
            rowExtra: [
                { text: "Reload Price Calculate Tool", icon: <ReloadOutlined />, value: "singleReload" },
                { text: "Query", icon: <FileSearchOutlined />, value: "query" },
            ],
            enableRowSelection: true,
            hideRowSelectionsSummary: true,
            rowSelectionData: {
                rowSelectionType: "checkbox",
                selectedRowKeys: selectedRowKeys,
                options: {
                    fixed: "left",
                },
            },
        }),
        [selectedButtonElement]
    );

    const componentCallback: FlexiDataTableCallbackProps = (type, FormData) => {
        switch (type) {
            case CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK:
                if (FormData.key === "query") {
                    setSelectedItem(FormData.data);
                    setIsModalVisible(true);
                } else if (FormData.key === "singleReload") {
                    Modal.confirm({
                        title: `Reload Confirmation`,
                        content: <> {`Reload "${(FormData.data as SpreadGoogleSheetServerProps).serverDn}" calculate tool?`} </>,
                        onOk: () => {
                            resyncServerData([FormData.data.serverUno.toString()]);
                        },
                    });
                }
                break;
            case CALLBACK_KEY.ROW_SELECTION_CALLBACK:
                setSelectedRowKeys(FormData.selectedRowKeys);
                break;
            default:
                break;
        }
    };

    const syncTable = (tableName: string) => {
        setIsSyncing(true);
        plainAxiosInstance
            .get(`${APIs.RC_SPREAD_GOOGLE_SHEET.SYNC_SPREAD_URL}/${tableName}`)
            .then((res: any) => {
                if (res.data === "Update Success") {
                    message.success(`Update "Spread Symbol" table successfully.`, 3);
                } else {
                    message.error(`Update "Spread Symbol" table failed.`, 3);
                }
            })
            .catch(err => {
                message.error(`Update "Spread Symbol" table failed.`, 3);
            })
            .finally(() => setIsSyncing(false));
    };

    const resyncServerData = (keys: string[]) => {
        plainAxiosInstance.post(APIs.RC_SPREAD_GOOGLE_SHEET.PROCESS_SPREAD_DATA_SYNC, keys).then((res: any) => {
            if (res.data === "ok") {
                message.success(`Batch processing starting, please waiting.`, 3);
            } else {
                message.error(`Batch processing failed. Please try again.`, 3);
            }
        });
    };

    const getServerList = () => {
        setIsLoading(true);
        plainAxiosInstance
            .get(APIs.RC_SPREAD_GOOGLE_SHEET.GET_SPREAD_TICK_RECEIVER_LIST)
            .then((res: any) => {
                setData(res.data && res.data.length > 0 ? res.data : []);
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        getServerList();
    }, []);

    return (
        <>
            <div className="spread-google-sheet-pages">
                <CardBox title={"Spread Google Sheet"}>
                    <div className="top-panel">
                        <Button icon={<ReloadOutlined />} onClick={() => syncTable("updateSpreadSheet")} loading={isSyncing}>
                            Synchronize Spread Symbol Table{" "}
                        </Button>
                    </div>
                    <FlexiDataTable
                        rowKeyProperty="serverUno"
                        title={<span style={{ fontSize: "1rem", fontWeight: "bold", color: "#000000" }}>Spread tick receiver tools</span>}
                        columns={columns}
                        options={options}
                        dataSource={data}
                        callback={componentCallback}
                        loading={isLoading}
                    />
                </CardBox>
            </div>
            <SpreadGoogleSheetModal
                isModalVisible={isModalVisible}
                modalTitle={selectedItem !== undefined ? `${selectedItem.serverDn} Settings` : ""}
                callback={(type: number, data: any) => {
                    switch (type) {
                        case SpreadGoogleSheetModalCallbackKey.Close:
                            setSelectedItem(undefined);
                            setIsModalVisible(false);
                            break;
                        default:
                            break;
                    }
                }}
                data={selectedItem}
            />
        </>
    );
};

export default SpreadGoogleSheetPage;
