import { StarFilled } from "@ant-design/icons";
import { Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CardBox from "../../../components/Common/CardBox";
import FlexiDataTable from "../../../components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType, STATUS_TYPE, SUCCESS_FAILED } from "../../../constants";
import { User, FlexiDataTableOptionsProps, FlexiDataTableCallbackProps, FlexiDataColumnProps } from "../../../constants/type";
import AuthHelper, { AuthKeys } from "../../../helpers/authHelper";
import { apiRequest, APIs } from "../../../services/apiConfig";
import { useGetRiskScoreConfigurationsQuery } from "../../../store/apis/riskScore";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";
import { RiskScoreState } from "./createEditPage";

const RiskScoreList = () => {
    let navigate = useNavigate();

    const { isLoading, data, refetch } = useGetRiskScoreConfigurationsQuery({ profileId: 0 });
    const [, setManualLoading] = useState<boolean>(false);
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.ADMIN_RISKSCORE_ACCOUNT_EDIT);

    useEffect(() => {
        refetch();
        return () => {};
    }, []);

    const columns: FlexiDataColumnProps[] = [
        {
            title: "Profile Name",
            dataIndex: "name",
            key: "name",
            options: {
                visible: true,
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
            render: (name: string, record: any) => {
                return record.isDefault === true ? (
                    <span key={"urnm-tlps"}>
                        {name}
                        <Tooltip title={"Default"} key={"urnm-tlps-cl"}>
                            <StarFilled twoToneColor="#004b57" style={{ marginLeft: 7 }} />
                        </Tooltip>
                    </span>
                ) : (
                    name
                );
            },
        },
        DTColProps.Large({
            title: "Account Type",
            dataIndex: "accountTypeName",
            key: "accountTypeName",
        }),
        DTColProps.Status({
            title: "Status",
            dataIndex: "status",
            key: "status",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: Object.keys(STATUS_TYPE)
                        .map((x) => parseInt(x))
                        .map((x) => ({ text: STATUS_TYPE[x], value: x })),
                },
            },
        }),
    ];

    const options: FlexiDataTableOptionsProps = {
        view: (record: any, option: any) => {
            let newProps = { ...option };
            newProps = <Link to={`/siteadmin/riskscore/account/view/${record.profileId}`}>{newProps}</Link>;
            return newProps;
        },
        add: enableUpdate,
        edit: enableUpdate,
        ...(enableUpdate && {
            delete: (record: any, deleteElement: React.ReactNode) => {
                if (record.isDefault) {
                    return undefined;
                }
                return deleteElement;
            },
        }),
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, data) => {
        switch (type) {
            case CALLBACK_KEY.VIEW_RECORD:
                navigate(`/siteadmin/riskscore/account/view/${data.profileId}`, {
                    state: { Id: data.profileId },
                });
                break;
            case CALLBACK_KEY.CREATE_NEW:
                navigate("/siteadmin/riskscore/account/create", { state: { data: null, action: "add" } });
                break;
            case CALLBACK_KEY.DO_EDIT:
                let state: RiskScoreState = {
                    action: "edit",
                    profileId: data.profileId,
                };
                navigate("/siteadmin/riskscore/account/edit", { state: state });
                break;
            case CALLBACK_KEY.DO_DELETE:
                setManualLoading(true);
                apiRequest(APIs.DELETE_RISK_SCORE_PROFILE, { profileId: data.profileId })
                    .then((data) => {
                        ErrorMessageHandler("The risk score profile", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                        setManualLoading(false);
                        refetch();
                    })
                    .catch((error) => {
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("risk score profile", SUCCESS_FAILED.FAILED_DELETE_DATA, err));
                        setManualLoading(false);
                    });
                break;
            default:
                break;
        }
    };

    return (
        <CardBox title={"Account Risk Score Configuration"}>
            <FlexiDataTable
                rowKeyProperty="profileId"
                title=""
                columns={columns}
                options={options}
                dataSource={data?.data || []}
                callback={componentCallback}
                loading={isLoading}
            />
        </CardBox>
    );
};

export default RiskScoreList;
