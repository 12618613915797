import { SUCCESS_FAILED } from "@/constants";
import { ToxicClientAlarmBrandPicsProps, ToxicClientAlarmConfigsProps } from "@/constants/type";
import { apiRequest, APIs } from "@/services/apiConfig";
import { ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { CheckOutlined, DeleteOutlined, PlusOutlined, SyncOutlined } from "@ant-design/icons";
import { Button, Col, Form, message, Popconfirm, Row, Space, Tabs, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import ToxicClientAlarmConfigsPage from "./AlarmConfigs";
import AssignUserModal from "./AssignUserModal";
import LoadingComponent from "@/components/Loading";
const { Text, Title } = Typography;
const { TabPane } = Tabs;

type TargetKey = React.MouseEvent | React.KeyboardEvent | string;

const ToxicClientNotificationConfigList = () => {
    const [runRefetchDataList, setRunRefetchDataList] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [activeKey, setActiveKey] = useState<string>("");
    const [alarmConfigsItems, setAlarmConfigsItems] = useState<any[]>([]);
    const [alarmBrandPics, setAlarmBrandPics] = useState<ToxicClientAlarmBrandPicsProps[]>([]);
    const newTabIndex = useRef(0);
    const [alarmConfigForm] = Form.useForm();
    const [assignUserForm] = Form.useForm();
    const [configData, setConfigData] = useState<any>({ labels: [], brands: [], users: [], toxicclientchecklistalarmmetric: [] });
    const [originalAlarmConfigs, setOriginalAlarmConfigs] = useState<any[]>([]);

    const onChange = (newActiveKey: string) => setActiveKey(newActiveKey);

    const createNewGroup = () => {
        const newActiveKey = `newTab${newTabIndex.current++}`;
        const newItems = {
            tabKey: newActiveKey,
            labelGroupId: 0,
            labelGroupName: "",
            labelIds: [],
            isActive: false,
            alarmConfigDetails: [],
        };
        const newPanes = [
            ...alarmConfigsItems,
            {
                label: "New Tab",
                children: <ToxicClientAlarmConfigsPage alarmConfigsItems={newItems} coData={configData} alarmConfigForm={alarmConfigForm} handleFormValuesChange={handleFormValuesChange} />,
                key: newActiveKey,
                closable: true,
            },
        ];
        setAlarmConfigsItems(newPanes);
        setActiveKey(newActiveKey);
    };

    const removeGroup = (targetKey: TargetKey) => {
        // Update the alarmConfigsItems by removing the selected group
        const updatedItems = alarmConfigsItems.filter(pane => pane.key !== targetKey);
        setAlarmConfigsItems(updatedItems);
        setActiveKey(updatedItems.length ? updatedItems[0].key : "");

        // Also remove the group from originalAlarmConfigs
        const updatedAlarmConfigs = originalAlarmConfigs.filter(config => config.labelGroupId.toString() !== targetKey);
        setOriginalAlarmConfigs(updatedAlarmConfigs);
    };

    const onEdit = (targetKey: TargetKey, action: "add" | "remove") => {
        if (action === "add") createNewGroup();
        else removeGroup(targetKey);
    };

    const convertFormResultToJson = (result: any): ToxicClientAlarmConfigsProps[] => {
        const labelGroups: ToxicClientAlarmConfigsProps[] = [];
        const groupPrefixes = Array.from(
            new Set(
                Object.keys(result)
                    .filter(key => key.startsWith("labelGroupName"))
                    .map(key => key.split("_")[1])
            )
        );

        groupPrefixes.forEach(prefix => {
            const labelGroup: ToxicClientAlarmConfigsProps = {
                labelGroupId: result[`labelGroupId_${prefix}`],
                labelGroupName: result[`labelGroupName_${prefix}`],
                labelIds: result[`labelIds_${prefix}`],
                isActive: result[`isActive_${prefix}`],
                alarmConfigDetails: [],
            };

            Object.keys(result)
                .filter(key => key.startsWith(`alarmConfigDetails_${prefix}`))
                .forEach(key => {
                    result[key].forEach((detail: { thres: any[]; metricId: any; brandIds: any }) => {
                        detail.thres.forEach(threshold => {
                            if (detail.brandIds.length > 0 && threshold.breachSeverity !== undefined && threshold.threshold !== undefined) {
                                labelGroup.alarmConfigDetails.push({
                                    metricId: detail.metricId,
                                    brandIds: detail.brandIds,
                                    breachSeverity: threshold.breachSeverity,
                                    threshold: threshold.threshold,
                                });
                            }
                        });
                    });
                });

            labelGroups.push(labelGroup);
        });

        return labelGroups;
    };

    const onSubmit = (res: any) => {
        const formattedJsonAlarmConfigForm = convertFormResultToJson(res);
        const formattedJsonAssignUserForm: ToxicClientAlarmBrandPicsProps[] = assignUserForm.getFieldValue("alarmBrandPics");

        // Create a new array by merging originalAlarmConfigs and formattedJsonAlarmConfigForm based on labelGroupName
        const mergedAlarmConfigs = originalAlarmConfigs.map(originalConfig => {
            // Find a matching config in the formatted JSON based on labelGroupName
            const updatedConfig = formattedJsonAlarmConfigForm.find(
                formattedConfig => formattedConfig.labelGroupId === originalConfig.labelGroupId
            );

            // If a match is found, overwrite the original config with the updated one, removing labelGroupId
            if (updatedConfig) {
                return {
                    ...updatedConfig,
                    // We remove labelGroupId since it's no longer needed
                };
            }

            // If no match is found, retain the original config but remove labelGroupId
            const { labelGroupId, ...configWithoutLabelGroupId } = originalConfig;
            return configWithoutLabelGroupId;
        });

        // Add any new configs from formattedJsonAlarmConfigForm that are not in originalAlarmConfigs
        formattedJsonAlarmConfigForm.forEach(formattedConfig => {
            const existsInOriginal = originalAlarmConfigs.some(originalConfig => originalConfig.labelGroupId === formattedConfig.labelGroupId);

            // If it's a new config (not in originalAlarmConfigs), push it to the merged array and remove labelGroupId
            if (!existsInOriginal) {
                const { labelGroupId, ...configWithoutLabelGroupId } = formattedConfig;
                mergedAlarmConfigs.push(configWithoutLabelGroupId);
            }
        });

        // Remove entries without a labelGroupName
        const filteredMergedAlarmConfigs = mergedAlarmConfigs.filter(config => config.labelGroupName && config.labelGroupName.trim() !== "");

        apiRequest(APIs.UPDATE_TOXIC_CLIENT_ALARM_CONFIG_LIST, {
            alarmConfigs: filteredMergedAlarmConfigs, // Only send valid entries, without labelGroupId
            alarmBrandPics: formattedJsonAssignUserForm,
        })
            .then(data => {
                message.success("Toxic client notification configuration updated successfully");
                setRunRefetchDataList(true);
            })
            .catch(error =>
                ErrorCatchValidator(error, (err: any) =>
                    ErrorMessageHandler("toxic client notification configuration", SUCCESS_FAILED.FAILED_LOAD_DATA, err)
                )
            )
            .finally(() => setIsLoading(false));
    };

    const submitAll = () => {
        assignUserForm
            .validateFields()
            .then(resp =>
                alarmConfigForm
                    .validateFields()
                    .then(res => onSubmit(res))
                    .catch(err => console.log("alarm config form-error:", err))
            )
            .catch(err => console.log("assign user form-error:", err));
    };

    const mergeBrandsWithAlarmPics = (brands: any[], alarmBrandPics: any[]) => {
        return brands.map((brandConfig: any) => {
            // Find matching brandId from alarmBrandPics
            const matchingBr = alarmBrandPics.find((alarm: any) => alarm.brandId === brandConfig.id);

            // If found, use its userId, otherwise assign an empty array
            return {
                brandId: brandConfig.id,
                userIds: matchingBr ? matchingBr.userIds : [],
            };
        });
    };

    const fetchConfig = () => {
        setIsLoading(true);
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["toxicclientlabel", "brand", "user", "toxicclientchecklistalarmmetric"] })
            .then(data => {
                const allBrands = [{ id: 0, brand: "All Brands" }, ...data.brands];
                let tempConfigData = {
                    labels: data.toxicClientLabels,
                    brands: allBrands,
                    users: data.users,
                    toxicclientchecklistalarmmetric: data.toxicClientChecklistAlarmMetrics,
                };
                setConfigData(tempConfigData);
                fetchNotificationConfig(tempConfigData);
            })
            .catch(error => ErrorCatchValidator(error, (err: any) => console.error("Failed to get filter config list: ", err)))
            .finally(() => setIsLoading(false));
    };

    const fetchNotificationConfig = (coData: any) => {
        setIsLoading(true);
        apiRequest(APIs.GET_TOXIC_CLIENT_ALARM_CONFIG_LIST, {})
            .then(data => {
                const initialTabKey = data.alarmConfigs[0]?.labelGroupId?.toString() || "";
                const updatedItems = data.alarmConfigs.map((item: any) => ({
                    label: item.labelGroupName,
                    children: (
                        <ToxicClientAlarmConfigsPage
                            alarmConfigsItems={{ ...item, tabKey: item.labelGroupId.toString() }}
                            coData={coData}
                            alarmConfigForm={alarmConfigForm}
                            handleFormValuesChange={handleFormValuesChange}
                        />
                    ),
                    key: item.labelGroupId.toString(),
                    closable: false,
                }));
                setAlarmConfigsItems(updatedItems);
                setAlarmBrandPics(data.alarmBrandPics);
                setOriginalAlarmConfigs(data.alarmConfigs);

                // Assuming coData.brands and data.alarmBrandPics are provided
                const tempAlarmBrandPics = mergeBrandsWithAlarmPics(coData.brands, data.alarmBrandPics);

                // Now you can set the form field
                assignUserForm.setFieldValue("alarmBrandPics", tempAlarmBrandPics);

                setActiveKey(initialTabKey);
            })
            .catch(error =>
                ErrorCatchValidator(error, (err: any) =>
                    ErrorMessageHandler("toxic client notification configuration list", SUCCESS_FAILED.FAILED_LOAD_DATA, err)
                )
            )
            .finally(() => setIsLoading(false));
    };

    const handleFormValuesChange = (changedValues: any, allValues: any) => {
        const updatedLabelGroupNameKey = Object.keys(changedValues).find(key =>
            key.startsWith("labelGroupName_")
        );

        if (updatedLabelGroupNameKey) {
            const labelGroupName = changedValues[updatedLabelGroupNameKey];

            setAlarmConfigsItems(prevItems =>
                prevItems.map(item => {
                    if (item.key === updatedLabelGroupNameKey.split("_")[1]) {
                        return {
                            ...item,
                            label: labelGroupName || "New Tab",
                        };
                    }
                    return item;
                })
            );
        }
    };

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            alarmConfigForm.resetFields();
            fetchNotificationConfig(configData);
            setRunRefetchDataList(false);
        }
    }, [runRefetchDataList]);

    useEffect(() => {
        setIsLoading(true);
        fetchConfig();
    }, []);

    return (
        <div className="single-page with-background noti-config">
            {isLoading ? (
                <div className="loading-container" style={{ width: "100%" }}>
                    <LoadingComponent tip="Loading..." />
                </div>
            ) : (
                <div className="active">
                    <div className="top-bar">
                        <Space>
                            <Button icon={<SyncOutlined />} onClick={() => setRunRefetchDataList(true)}>
                                Reset
                            </Button>
                            <Button type="primary" icon={<CheckOutlined />} onClick={submitAll}>
                                Submit All
                            </Button>
                        </Space>
                    </div>
                    <Row>
                        <Col span={18} className="left-cont">
                            <div className="left-top-bar">
                                <Title level={3}>Alarm Configuration</Title>
                            </div>
                            <Tabs
                                tabPosition="left"
                                onChange={onChange}
                                activeKey={activeKey}
                                type="editable-card"
                                onEdit={onEdit}
                                addIcon={
                                    <Button
                                        className={"align-configs-items-tab-create"}
                                        icon={<PlusOutlined />}
                                        onClick={createNewGroup}
                                        type="dashed"
                                    >
                                        Create
                                    </Button>
                                }
                            >
                                {alarmConfigsItems.map(item => (
                                    <TabPane
                                        tab={
                                            <div className="align-configs-items-tab-style">
                                                <div>{item.label}</div>
                                                <Popconfirm
                                                    title="Confirm to delete label group?"
                                                    onConfirm={() => removeGroup(item.key)}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    <DeleteOutlined style={{ marginLeft: 10 }} />
                                                </Popconfirm>
                                            </div>
                                        }
                                        key={item.key}
                                        closable={false}
                                    >
                                        {item.children} {/* The tab content goes here */}
                                    </TabPane>
                                ))}
                            </Tabs>
                        </Col>
                        <Col span={6} className="right-cont">
                            <div className="right-top-bar">
                                <Title level={3}>Alarm Brand PIC Assignee</Title>
                            </div>
                            <AssignUserModal assignUserForm={assignUserForm} configData={configData} />
                        </Col>
                    </Row>
                </div>
            )}
        </div>
    );
};

export default ToxicClientNotificationConfigList;
