import { Datum, DualAxes } from "@ant-design/charts";
import { useEffect, useState } from "react";
import { APIs, apiRequest } from "../../../../services/apiConfig";
import { ErrorCatchValidator, ErrorMessageHandler, currencyRender, roundUpValue } from "../../../../utils/Common";
import { INTRADAY_CHART_TYPE, SUCCESS_FAILED } from "../../../../constants";
import { MimMetricsExtra } from "../../../../constants/type";
import CustomSkeleton from "../../../../components/Common/Skeleton";
import moment from "moment";
import { DimemsionFilterType, mapFilterParams } from "../../Search";
import { hasAnyKey } from "../../../../utils/object";
import EmptyData from "../../../../components/Common/Empty";
import { cloneDeep } from "lodash";

interface PnlChartSummaryProps {
    metrics: MimMetricsExtra[];
    title: string;
    selectedParams?: DimemsionFilterType[] | boolean;
    isSearch?: boolean;
    resetState: number;
}

interface ApiReturnChartData {
    bucket: string;
    intraday_pnl: number;
    turnover: number;
    total_pnl: number;
}

const PnlChartSummary = (props: PnlChartSummaryProps) => {
    // component number 7
    const [barData, setBarData] = useState<any[]>([]);
    const [lineData, setLineData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [sliderPosition, setSliderPosition] = useState<number>(0);
    const [runRefetchData, setRunRefetchData] = useState<boolean>(false);
    const [isGroupBar, setIsGroupBar] = useState<boolean>(false);

    const getMetricRanking = () => {
        setIsLoading(true);
        apiRequest(APIs.GET_MIM_METRIC_TIME_SERIES, {
            metricSelectors: props.metrics.map((x: MimMetricsExtra) => ({
                metricName: x.metricName,
                columnOrder: x.metricOrder,
            })),
            ...(hasAnyKey(props.selectedParams) && { dimensionFilters: mapFilterParams(props.selectedParams) }),
        })
            .then((data: any) => {
                if (data.length > 0) {
                    const updatedData = data.map((x: any, i: number) => {
                        return {
                            ...x,
                            bucket: moment(x.bucket).format("HH:mm"),
                        };
                    });

                    let barChartData: any[] = [];
                    let lineChartData: any[] = [];
                    updatedData.forEach((currData: ApiReturnChartData) => {
                        Object.keys(currData)
                            .filter((k: string) => !k.includes("bucket"))
                            .forEach((currKey: string) => {
                                const currMetric = props.metrics.find((x: MimMetricsExtra) => x.metricName.includes(currKey));
                                if (currMetric && currMetric.chartType === INTRADAY_CHART_TYPE.LINE) {
                                    lineChartData.push({
                                        bucket: currData.bucket,
                                        name: currMetric.metricDisplayName,
                                        value: currData[currKey as keyof ApiReturnChartData],
                                    });
                                }
                                if (currMetric && currMetric.chartType === INTRADAY_CHART_TYPE.BAR) {
                                    barChartData.push({
                                        bucket: currData.bucket,
                                        name: currMetric.metricDisplayName,
                                        count: currData[currKey as keyof ApiReturnChartData],
                                    });
                                }
                            });
                    });
                    barChartData.sort((a, b) => (a.name < b.name ? -1 : 1));
                    lineChartData.sort((a, b) => (a.name < b.name ? -1 : 1));
                    setBarData(barChartData);
                    setLineData(lineChartData);

                    const zeroHourIndex = updatedData.findIndex((entry: { bucket: string }) => entry.bucket === "00:00");
                    setSliderPosition(zeroHourIndex !== -1 ? zeroHourIndex / (updatedData.length - 1) : 0.5);
                } else {
                    setBarData([]);
                    setLineData([]);
                    setSliderPosition(0);
                }
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("Daily P&L chart", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        getMetricRanking();

        /**
         *  13/3/2024 - Xin
         *  NOTE: if more than 1 metrics of bar chartType,
         *  need isGroup : true in geometryOptions in chart config
         */
        if (props.metrics.filter(x => x.chartType === INTRADAY_CHART_TYPE.BAR).length > 1) setIsGroupBar(true);
        else setIsGroupBar(false);
        return () => {};
    }, [props.resetState]);

    useEffect(() => {
        if (props.isSearch) {
            if (props.selectedParams && hasAnyKey(props.selectedParams)) {
                setRunRefetchData(true);
            }
        }
        return () => {};
    }, [props.selectedParams]);

    useEffect(() => {
        if (runRefetchData) {
            getMetricRanking();
            setRunRefetchData(false);
        }
        return () => {};
    }, [runRefetchData]);

    return (
        <div className={`pnl-chart-summary-panel ${props.isSearch ? "search" : ""}`}>
            {props.title.length > 0 ? (
                <div className="title">
                    <span className="title-text">{props.title}</span>
                </div>
            ) : (
                <></>
            )}
            <div className="graph-chart-div">
                {isLoading ? (
                    <CustomSkeleton rows={7} />
                ) : (
                    <>
                        {barData.length > 0 && lineData.length > 0 ? (
                            <DualAxes
                                autoFit={true}
                                // padding={[20, 30, 90, 35]}
                                padding={"auto"}
                                height={298}
                                width={450}
                                {...{
                                    data: [cloneDeep(barData), cloneDeep(lineData)],
                                    xField: "bucket",
                                    yField: ["count", "value"],
                                    geometryOptions: [
                                        {
                                            geometry: "column",
                                            seriesField: "name",
                                            ...(isGroupBar ? { isGroup: true } : {}),
                                        },
                                        {
                                            geometry: "line",
                                            seriesField: "name",
                                            lineStyle: {
                                                lineWidth: 2,
                                            },
                                        },
                                    ],
                                    legend: {
                                        position: "top",
                                        // padding: [15, 0, 0, 0],
                                    },
                                    xAxis: {
                                        nice: true,
                                        label: {
                                            autoHide: true,
                                            autoRotate: true,
                                        },
                                        tickLine: {
                                            alignTick: true,
                                        },
                                    },
                                    yAxis: {
                                        count: {
                                            nice: true,
                                            tickMethod: "d3-linear",
                                            label: {
                                                formatter: (v: any) => roundUpValue(v, 0),
                                            },
                                            title: {
                                                text: `${props.metrics
                                                    .filter(x => x.chartType === INTRADAY_CHART_TYPE.BAR)
                                                    .map(y => y.metricDisplayName)
                                                    .sort()
                                                    .join(" , ")}`,
                                            },
                                        },
                                        value: {
                                            nice: true,
                                            tickMethod: "d3-linear",
                                            label: {
                                                formatter: (v: any) => roundUpValue(v, 0),
                                            },
                                            title: {
                                                text: `${props.metrics
                                                    .filter(x => x.chartType === INTRADAY_CHART_TYPE.LINE)
                                                    .map(y => y.metricDisplayName)
                                                    .sort()
                                                    .join(" , ")}`,
                                            },
                                        },
                                    },
                                    tooltip: {
                                        formatter: (datum: Datum) => {
                                            if (Object.keys(datum).indexOf("count") > -1) {
                                                return { name: datum.name, value: datum.count !== 0 ? currencyRender(datum.count, 2, 0) : 0 };
                                            }
                                            return { name: datum.name, value: datum.value !== 0 ? currencyRender(datum.value, 2, 0) : 0 };
                                        },
                                    },
                                    animation: false,
                                    slider: {
                                        start: sliderPosition,
                                        end: 1,
                                    },
                                    limitInPlot: false,
                                    meta: {
                                        bucket: {
                                            sync: false,
                                        },
                                    },
                                }}
                            />
                        ) : (
                            <>
                                <EmptyData />
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default PnlChartSummary;
