import { FormComponent } from "@/components/FormComponent";
import { ComponentType } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { Modal, Form, Row, Col } from "antd";
import { useEffect } from "react";
import { PriceAnalysisSymbolInfoProps } from "./SymbolSettingsPage";

export interface EditSymbolSettingModalProps {
    isModalVisible: boolean;
    modalTitle: string;
    callback: (type: number, data: any) => void;
    data?: any | undefined;
    serverSymbolInfo: { [key: number]: PriceAnalysisSymbolInfoProps };
}

export enum EditSymbolSettingModalCallbackKey {
    Close = 0,
    FormSubmit = 1,
}

const EditSymbolSettingModal = (props: EditSymbolSettingModalProps) => {
    const [executeForm] = Form.useForm();

    useEffect(() => {
        if (props.isModalVisible) {
            if (props.data !== undefined) {
                executeForm.setFieldsValue(props.data);
            } else {
                executeForm.setFieldsValue({
                    symbol: "",
                    ...Object.keys(props.serverSymbolInfo).reduce((finalObj: any, x: string) => {
                        finalObj[parseInt(x)] = "";
                        return finalObj;
                    }, {}),
                });
            }
        }
    }, [props.isModalVisible, props.data, props.serverSymbolInfo]);

    return (
        <Modal
            style={{ top: 10 }}
            width={"40vw"}
            title={props.modalTitle}
            open={props.isModalVisible}
            okText="Submit"
            onOk={() => {
                executeForm
                    .validateFields()
                    .then((values: any) => {
                        props.callback(EditSymbolSettingModalCallbackKey.FormSubmit, values);
                    })
                    .catch((err: any) => console.log(err));
            }}
            onCancel={() => props.callback(EditSymbolSettingModalCallbackKey.Close, null)}
            maskClosable={false}
        >
            <Form form={executeForm} layout="vertical" labelAlign="left">
                <FormComponent label="" name="symbol" extra={{ type: ComponentType.hidden, value: "" }} />
                <Row gutter={[10, 20]} style={{ maxHeight: "76vh", overflowY: "auto" }}>
                    {Object.keys(props.serverSymbolInfo).map((key: string, idx: number) => {
                        let tmp = props.serverSymbolInfo[parseInt(key)];
                        return (
                            <Col key={`pastt-sym-${tmp.serverNo}-${idx}`} span={8}>
                                <FormComponent
                                    label={tmp.serverName}
                                    name={`${tmp.serverNo}`}
                                    extra={{
                                        type: ComponentType.dropdown,
                                        value: tmp.symbols,
                                        rules: [{ required: true, message: REQUIRED_FIELD }],
                                        inputProps: {
                                            allowClear: false,
                                        },
                                        ...(tmp.isBaseServer &&
                                            props.data !== undefined && {
                                                disabled: true,
                                            }),
                                    }}
                                />
                            </Col>
                        );
                    })}
                </Row>
            </Form>
        </Modal>
    );
};

export default EditSymbolSettingModal;
