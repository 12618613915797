import { Space } from "antd";
import DailySpreadAverageDownloadFilters from "./DailySpreadAverageDownloadFilters";

const DailySpreadAverageDownloadPage = () => {
    return (
        <Space style={{ width: "100%" }} direction="vertical" size="large">
            <DailySpreadAverageDownloadFilters />
        </Space>
    );
};

export default DailySpreadAverageDownloadPage;
