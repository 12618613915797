import { FilePdfOutlined } from "@ant-design/icons";
import { Button, Progress, Spin } from "antd";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useState } from "react";
import { ExportButtonProps } from "../../constants/type";
import { canvasProps } from "../../pages/Analysis/exportToPdf";

export const ExportItem = (props: ExportItemProps) => {
    return (
        <div className="print-ele" style={props.style ? props.style : {}}>
            {props.children}
        </div>
    );
};

export interface ExportItemProps {
    style?: React.CSSProperties;
    children?: React.ReactNode;
}

export interface ExportToExcelProps {
    children?: React.ReactNode;
    extraButtons?: ExportButtonProps[];
    onComponentCallback?: Function;
    exportFileName?: Function;
}

const ExportToExcel = (props: ExportToExcelProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [exportPercent, setExportPercent] = useState<number>(0);
    const calcCoordination = (canvas: HTMLCanvasElement, currentPage: number, usedHeight: number): canvasProps => {
        const maxWidth = 670;
        const maxHeight = 870;

        let resizeRatio = canvas.width > maxWidth ? maxWidth / canvas.width : 1;
        let isNewPage = usedHeight + canvas.height * resizeRatio > maxHeight;
        if (isNewPage) {
            usedHeight = 0;
        }

        return {
            xValue: 10,
            yValue: (currentPage === 1 ? 7 : maxHeight * -1) + usedHeight + 10,
            width: canvas.width * resizeRatio,
            height: canvas.height * resizeRatio,
            currentPage: currentPage + (isNewPage ? 1 : 0),
            useHeight: usedHeight + canvas.height * resizeRatio + 10,
        } as canvasProps;
    };

    const exportToPDF = async () => {
        const inputs = document.querySelectorAll(".print-ele");
        const pdf = new jsPDF({
            orientation: "portrait",
            unit: "pt",
            format: [900, 690],
            compress: true,
        });
        let currentPage = 1;
        let usedHeight = 0;
        for (let index = 0, count = inputs.length; index < count; index++) {
            const element = inputs[index];

            await html2canvas(element as HTMLElement, { scale: 2 })
                .then((canvas) => {
                    let info = calcCoordination(canvas, currentPage, usedHeight);
                    if (currentPage !== info.currentPage) {
                        pdf.addPage();
                    }
                    pdf.addImage(canvas.toDataURL("image/png"), "PNG", info.xValue, info.yValue, info.width, info.height);
                    usedHeight = info.useHeight;
                    if (count === index + 1) {
                        pdf.save(props.exportFileName ? props.exportFileName() : "download.pdf");
                        setIsLoading(false);
                    }
                })
                .finally(() => setTimeout(() => setExportPercent(parseInt((((index + 1) / count) * 100 - 1).toFixed(0))), 100));
        }
    };

    return (
        <div className="export-excel-container">
            <Spin
                wrapperClassName="overlay-spinning"
                spinning={isLoading}
                indicator={<Progress percent={exportPercent} status="active" strokeColor={{ from: "#108ee9", to: "#87d068" }} />}
                tip={"Exporting to pdf...."}
            >
                <div className="tools-panel">
                    {props.extraButtons &&
                        props.extraButtons.map((x, index) => {
                            if (x.render) {
                                return x.render({ key: `ept-btn-${x.key}-${index}`, style: { marginRight: "0.651vw" } });
                            }

                            return (
                                <Button
                                    key={`ept-btn-${x.key}-${index}`}
                                    {...(x.icon && { icon: x.icon })}
                                    onClick={() => props.onComponentCallback && props.onComponentCallback(x.key)}
                                    style={{ marginRight: "0.651vw" }}
                                >
                                    {x.text}
                                </Button>
                            );
                        })}
                    <Button
                        icon={<FilePdfOutlined />}
                        onClick={() => {
                            setIsLoading(true);
                            setTimeout(exportToPDF, 100);
                        }}
                        style={{ borderRadius: "3px" }}
                    >
                        Export
                    </Button>
                </div>
                <div className="print-content">{props.children}</div>
            </Spin>
        </div>
    );
};

export default ExportToExcel;
