import { HomeOutlined } from "@ant-design/icons";
import { Form, Popconfirm, Button, Row, Col } from "antd";
import { useState, useEffect } from "react";
import { useNavigate, useParams, NavigateFunction } from "react-router-dom";
import { FormComponent } from "../../../../components/FormComponent";
import LoadingComponent from "../../../../components/Loading";
import SitePageHeader from "../../../../components/PageHeader";
import { SUCCESS_FAILED, ComponentType } from "../../../../constants";
import { REQUIRED_FIELD } from "../../../../constants/errorMessage";
import { apiRequest } from "../../../../services/apiConfig";
import { APIs } from "../../../../services/apis";
import { ErrorMessageHandler, ErrorCatchValidator } from "../../../../utils/Common";
import { ServerState } from "../../ServersPage/ServerCreateEditPage";

export interface SymbolMapCreateEditPageProps {}

const SymbolMapCreateEditPage = (props: SymbolMapCreateEditPageProps) => {
    let navigate = useNavigate();
    let { id } = useParams();
    const paramsInfo = id && JSON.parse(atob(id));

    const [currentState] = useState<ServerState>(id ? { action: "edit", data: paramsInfo.symbol } : { action: "add", data: null });
    const [isAddAction, setIsAddAction] = useState<boolean>(currentState.action === "add");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
    const [cleanSymbol, setCleanSymbol] = useState<string[]>([]);
    const [symbolForm] = Form.useForm();

    const onSubmit = (values: any) => {
        setIsBtnLoading(true);
        if (isAddAction) {
            apiRequest(APIs.UPDATE_MT_SYMBOL, values)
                .then(() => {
                    ErrorMessageHandler("New symbol", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                    navigate("/siteadmin/symbolconfig/symbolmap");
                })
                .catch(error => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("new symbol", SUCCESS_FAILED.FAILED_CREATE_DATA, err)))
                .finally(() => setIsBtnLoading(false));
        } else {
            apiRequest(APIs.UPDATE_MT_SYMBOL, values)
                .then(() => {
                    ErrorMessageHandler("Existing symbol", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                    navigate("/siteadmin/symbolconfig/symbolmap");
                })
                .catch(error =>
                    ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("existing symbol", SUCCESS_FAILED.FAILED_UPDATE_DATA, err))
                )
                .finally(() => setIsBtnLoading(false));
        }
    };

    const getSymbolInfo = () => {
        setIsLoading(true);

        apiRequest(APIs.GET_MT_SYMBOL_LIST, { symbols: [currentState.data ? currentState.data : paramsInfo.symbol] })
            .then((data: any) => {
                if (currentState.data || paramsInfo.symbol) {
                    setIsAddAction(false);
                    symbolForm.setFieldsValue(data[0]);
                } else {
                    setIsAddAction(true);
                    symbolForm.resetFields();
                }
                setIsLoading(false);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("symbol", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                navigate("/siteadmin/symbolconfig/symbolmap");
            });
    };

    const deleteSymbol = (symbol: string, navigator: NavigateFunction) => {
        apiRequest(APIs.DELETE_SYMBOL, { symbol })
            .then(() => {
                ErrorMessageHandler("The symbol", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                navigator("/siteadmin/symbolconfig/symbolmap");
            })
            .catch(error => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("symbol", SUCCESS_FAILED.FAILED_DELETE_DATA, err)));
    };

    const getConfigList = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["cleansymbol"] })
            .then(res => {
                setCleanSymbol(res.cleanSymbols.map((x: any) => x.name));
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("config info", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            );
    };

    useEffect(() => {
        getConfigList();
        if (!isAddAction) getSymbolInfo();
        return () => {};
    }, [isAddAction]);

    return (
        <SitePageHeader
            title={isAddAction ? "Create New Symbol" : "Edit Symbol"}
            routes={[
                {
                    path: "/siteadmin/symbolconfig/symbolmap",
                    breadcrumbName: "Symbol Listing",
                    icon: <HomeOutlined />,
                },
                {
                    path: "",
                    breadcrumbName: isAddAction ? "Create New Symbol" : "Edit Symbol",
                },
            ]}
            onBack={() => navigate("/siteadmin/symbolconfig/symbolmap")}
            // extraProps={{
            //     extra: isAddAction
            //         ? []
            //         : isSysGenFlag === false && [
            //               <Popconfirm
            //                   key={"cetmp-del"}
            //                   title="Confirm to delete?"
            //                   onConfirm={() => deleteSymbol(currentState.data ? currentState.data : paramsInfo.symbol, navigate)}
            //                   okText="Yes"
            //                   cancelText="No"
            //               >
            //                   <Button type="primary" danger>
            //                       Delete Symbol
            //                   </Button>
            //               </Popconfirm>,
            //           ],
            // }}
        >
            {isLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Loading..." />
                </div>
            ) : (
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    form={symbolForm}
                    layout="horizontal"
                    initialValues={currentState.data}
                    onFinish={onSubmit}
                >
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Symbol"
                                name="symbol"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                    ...(!isAddAction && {
                                        inputProps: {
                                            readOnly: true,
                                        },
                                    }),
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Clean Symbol"
                                name="cleanSymbol"
                                extra={{
                                    type: ComponentType.dropdown,
                                    value: cleanSymbol.map(x => ({ text: x, value: x })),
                                    //rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Pricing Clean Symbol"
                                name="pricingCleanSymbol"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Suffix"
                                name="suffix"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div className="step-btns-group">
                                <Button type="primary" htmlType="submit" loading={isBtnLoading}>
                                    Submit
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            )}
        </SitePageHeader>
    );
};

export default SymbolMapCreateEditPage;
