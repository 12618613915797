import FlexiDataTable from "@/components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "@/constants";
import { FlexiDataTableOptionsProps, FlexiDataTableCallbackProps } from "@/constants/type";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { ErrorMessageHandler, ErrorCatchValidator, currencyRender, DTColProps } from "@/utils/Common";
import { defaultIfEmptyOrNull } from "@/utils/string";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { message, Modal } from "antd";
import { useState, useEffect } from "react";

export interface SpreadAlarmSettingPageProps {}

interface SpreadAlarmSettingProps {
    serverUno: number;
    serverName: string;
    symbol: string;
    spreadThreshold: number;
}

const SpreadAlarmSettingPage = (props: SpreadAlarmSettingPageProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<SpreadAlarmSettingProps[]>([]);
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.PRICE_SETTING_SPREAD_ALARM_EDIT);

    const columns: any[] = [
        {
            title: "Server",
            dataIndex: "serverName",
            key: "serverName",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        },
        {
            title: "Symbol",
            dataIndex: "symbol",
            key: "symbol",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        },
        DTColProps.Currency({
            title: "Spread Threshold",
            dataIndex: "spreadThreshold",
            key: "spreadThreshold",
            render: (text: number) => currencyRender(text.toFixed(0)),
        }),
    ];

    const handleSubmit = (file: any) => {
        try {
            let fileExtension: string[] = defaultIfEmptyOrNull(/\.[^\.]+/.exec(file.name), [""]),
                isLt5M = file.size / 1024 / 1024 < 5;

            if (fileExtension[0] !== ".xlsx") {
                message.error("Please check file type. Only .xlsx files are allowed.", 3);
                return;
            } else if (!isLt5M) {
                message.error("Please check file size less than 5 MB", 3);
                return;
            }

            Modal.confirm({
                icon: <ExclamationCircleOutlined />,
                title: "Are you sure you want to import?",
                width: "30%",
                onOk() {
                    var formData = new FormData();
                    formData.append("uploadFile", file);

                    plainAxiosInstance
                        .post(APIs.RC_PRICE_SETTINGS.UPLOAD_SPREAD_ALARM_SETTINGS, formData)
                        .then(res => {
                            if (res.status === 200 && res.data === 0) {
                                message.success("File upload successfully.", 3);
                            } else {
                                message.error(res.data, 3);
                            }
                        })
                        .catch((error: any) => {
                            message.error(`File upload failed: (${error.response.data.message})`, 3);
                        })
                        .finally(() => getSpreadAlarmSettings());
                },
                onCancel() {},
            });
        } catch (error) {
            message.error(`Error during uploading file`, 3);
        }
    };

    const options: FlexiDataTableOptionsProps = {
        ...(enableUpdate && {
            upload: {
                name: "file",
                multiple: false,
                accept: ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                showUploadList: false,
                onChange: (info: any) => {
                    if (info.file.status === "error") {
                        message.error(`${info.file.name} file upload failed.`);
                    }
                },
                customRequest: ({ file, onSuccess }: any) =>
                    setTimeout(() => {
                        onSuccess("ok");
                    }, 0),
                beforeUpload: (file: any) => handleSubmit(file),
            },
        }),
        export: {
            text: "Download",
        },
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, BrandData) => {
        switch (type) {
            case CALLBACK_KEY.EXPORT_CSV_EXCEL:
                try {
                    plainAxiosInstance
                        .get(`${APIs.RC_PRICE_SETTINGS.DOWNLOAD_SPREAD_ALARM_SETTINGS}`, {
                            headers: {
                                Accept: "application/octet-stream, */*",
                            },
                            responseType: "blob",
                        })
                        .then(response => {
                            const contentType = response.headers["content-type"];
                            if (
                                contentType === "application/octet-stream" ||
                                contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            ) {
                                const url = window.URL.createObjectURL(new Blob([response.data]));
                                const link = document.createElement("a");
                                link.href = url;
                                link.setAttribute("download", `SpreadAlarmSetting.xlsx`);
                                document.body.appendChild(link);
                                link.click();
                                // Clean up
                                window.URL.revokeObjectURL(url);
                            } else {
                                message.error(`Received non-file response. Error: ${response}`, 3);
                            }
                        })
                        .catch((error: any) => message.error(`Error occured during download: "${error.message}"`, 3));
                } catch (e: any) {
                    message.error(`Error occured during download: "${e.message}"`, 3);
                }
                break;
            default:
                break;
        }
    };

    const getSpreadAlarmSettings = () => {
        setIsLoading(true);
        plainAxiosInstance
            .get(APIs.RC_PRICE_SETTINGS.GET_SPREAD_ALARM_SETTINGS)
            .then((res: any) => {
                setData(
                    res.data && res.data.length > 0
                        ? res.data.map((x: SpreadAlarmSettingProps) => ({ ...x, id: `${x.serverUno}-${x.serverName}-${x.symbol}` }))
                        : []
                );
            })
            .catch(error =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("spread alarm setting", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        getSpreadAlarmSettings();
    }, []);

    return (
        <div className="spread-alarm-setting-container">
            <FlexiDataTable
                bordered
                rowKeyProperty="id"
                title=""
                columns={columns}
                options={options}
                dataSource={data}
                callback={componentCallback}
                loading={isLoading}
            />
        </div>
    );
};

export default SpreadAlarmSettingPage;
