import { useQuery } from "@tanstack/react-query";
import { APIs } from "../services/apis";
import { plainAxiosInstance } from "../services/axiosSetup";

/* sample api response

[
    {
        "cserv": "IC-ECN,IG",
        "symbol": "HK50",
        "symbolType": 0,
        "ourServerListStr": "UM",
        "firstAppear": "2024-06-13T02:14:04.000+00:00",
        "firstAppearStr": "2024/06/13 05:14:04",
        "lastAppear": "2024-06-13T02:14:04.000+00:00",
        "lastAppearStr": "2024/06/13 05:14:04",
        "count": 2,
        "detail": [
            {
                "cleansymbol": "HK50",
                "cserv": "IC-ECN,IG",
                "brandId": "UM",
                "count": 2,
                "firstAppear": "2024-06-13T02:14:04.000+00:00",
                "firstAppearStr": "2024/06/13 05:14:04",
                "lastAppear": "2024-06-13T02:14:04.000+00:00",
                "lastAppearStr": "2024/06/13 05:14:04",
                "detail": [
                    {
                        "symbolListStr": "HK50+",
                        "cleansymbol": "HK50",
                        "dateTime": "2024-06-13T02:14:04.000+00:00",
                        "dateTimeStr": "2024/06/13 05:14:04",
                        "count": 2,
                        "ourServerListStr": "57",
                        "detail": [
                            {
                                "dateTime": "2024-06-13T02:14:04.000+00:00",
                                "dateTimeStr": "2024/06/13 05:14:04",
                                "server": "UM",
                                "serverUno": 57,
                                "symbol": "HK50+",
                                "cleanSymbol": "HK50",
                                "symbolType": 0,
                                "compareServer": "IC-ECN",
                                "cserverUno": 501,
                                "compareSymbol": "HK50",
                                "alarmType": 2,
                                "scoreSum": 23.710146954702797,
                                "scoreMax": 2.0881747371504478,
                                "ppmcc": 99.1924243915146,
                                "solved": 0,
                                "solvedTime": "1969-12-31T21:00:00.000+00:00",
                                "solvedTimeStr": "1970/01/01 00:00:00",
                                "solvedUser": ""
                            },
                            {
                                "dateTime": "2024-06-13T02:14:04.000+00:00",
                                "dateTimeStr": "2024/06/13 05:14:04",
                                "server": "UM",
                                "serverUno": 57,
                                "symbol": "HK50+",
                                "cleanSymbol": "HK50",
                                "symbolType": 0,
                                "compareServer": "IG",
                                "cserverUno": 500,
                                "compareSymbol": "HK50",
                                "alarmType": 2,
                                "scoreSum": 21.363799794381563,
                                "scoreMax": 1.9241296957428111,
                                "ppmcc": 98.95910649280952,
                                "solved": 0,
                                "solvedTime": "1969-12-31T21:00:00.000+00:00",
                                "solvedTimeStr": "1970/01/01 00:00:00",
                                "solvedUser": ""
                            }
                        ]
                    }
                ]
            }
        ]
    }
]

*/

interface IRCMidBiasResponse {
    serverTime: string;
    data: IRCMidBias[];
}

export interface IRCMidBias {
    cserv: string;
    symbol: string;
    symbolType: number;
    ourServerListStr: string;
    firstAppear: string;
    firstAppearStr: string;
    lastAppear: string;
    lastAppearStr: string;
    count: number;
    detail: IRCMidBiasDetail[];
}

interface IRCMidBiasDetail {
    cleansymbol: string;
    cserv: string;
    brandId: string;
    count: number;
    firstAppear: string;
    firstAppearStr: string;
    lastAppear: string;
    lastAppearStr: string;
    detail: IRCMidBiasDetailDetail[];
}

interface IRCMidBiasDetailDetail {
    symbolListStr: string;
    cleansymbol: string;
    dateTime: string;
    dateTimeStr: string;
    count: number;
    ourServerListStr: string;
    detail: IRCMidBiasDetailDetailDetail[];
}

interface IRCMidBiasDetailDetailDetail {
    dateTime: string;
    dateTimeStr: string;
    server: string;
    serverUno: number;
    symbol: string;
    cleanSymbol: string;
    symbolType: number;
    compareServer: string;
    cserverUno: number;
    compareSymbol: string;
    alarmType: number;
    scoreSum: number;
    scoreMax: number;
    ppmcc: number;
    solved: number;
    solvedTime: string;
    solvedTimeStr: string;
    solvedUser: string;
    evaluate: number;
}

const useRCMidBias = ({ duration }: { duration: "20min" | "1day" | number }) => {
    //convert request to form data
    const durationToNumber = typeof duration === "number" ? duration : duration === "20min" ? 20 : duration === "1day" ? 1440 : 20;

    const { data, refetch, isLoading, error, isFetching, dataUpdatedAt } = useQuery({
        queryKey: ["rcMidBias", duration],
        queryFn: () =>
            plainAxiosInstance.get(`${APIs.RC_MIDBIAS.GET_MIDBIAS_PERIOD}/20/1/${durationToNumber}`, {
                headers: {
                    Accept: "application/json",
                },
            }),
        staleTime: 15 * 1000, //(60 x 1000ms = 1minute)
        refetchInterval: 15 * 1000,
    });

    const decimalFormat = (value: any, fixed: number) => {
        return parseFloat(value)
            .toFixed(fixed)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    };

    const rcMidBias: IRCMidBiasResponse = data?.data?.status ? null : data?.data || null;
    // const dummy = dummyMidBias20;
    const massagedMidBias: IRCMidBias[] = !rcMidBias
        ? []
        : rcMidBias?.data.map((x, i) => {
              return {
                  ...x,
                  detail: x.detail.map(item => {
                      return {
                          ...item,
                          detail: item.detail.map(z => {
                              let maxScoreSortRanks = z.detail.map(e => e.scoreMax).sort((a, b) => b - a);
                              let maxScoreRanked = z.detail.map(e => ({ ...e, maxScoreRank: maxScoreSortRanks.indexOf(e.scoreMax) + 1 }));
                              let scoreSumSortRanks = z.detail.map(e => e.scoreSum).sort((a, b) => b - a);
                              let scoreSumRanked = z.detail.map(e => ({ ...e, scoreSumRank: scoreSumSortRanks.indexOf(e.scoreSum) + 1 }));
                              let ppmccSortRanks = z.detail.map(e => e.ppmcc).sort((a, b) => a - b);
                              let ppmccRanked = z.detail.map(e => ({ ...e, ppmccRank: ppmccSortRanks.indexOf(e.ppmcc) + 1 }));
                              let scoreRankArr = z.detail.map(e => ({
                                  ...e,
                                  scoreRank:
                                      (maxScoreRanked?.find(
                                          element =>
                                              element.server === e.server && element.symbol === e.symbol && element.compareServer === e.compareServer
                                      )?.maxScoreRank || 0) *
                                          0.3 +
                                      (scoreSumRanked?.find(
                                          element =>
                                              element.server === e.server && element.symbol === e.symbol && element.compareServer === e.compareServer
                                      )?.scoreSumRank || 0) *
                                          0.2 +
                                      (ppmccRanked?.find(
                                          element =>
                                              element.server === e.server && element.symbol === e.symbol && element.compareServer === e.compareServer
                                      )?.ppmccRank || 0) *
                                          0.5,
                              }));
                              let scoreRankSortRanks = scoreRankArr.map(e => e.scoreRank).sort((a, b) => a - b);
                              let scoreRanked = scoreRankArr.map(e => ({ ...e, scoreRanks: scoreRankSortRanks.indexOf(e.scoreRank) + 1 })),
                                  firstScoreRanked = scoreRanked.find(element => element.scoreRanks === 1);
                              return {
                                  ...z,

                                  maxScoreRange:
                                      z.detail.length > 1
                                          ? decimalFormat(Math.min(...z.detail.map(o => o.scoreMax)), 2) +
                                            "~" +
                                            decimalFormat(Math.max(...z.detail.map(o => o.scoreMax)), 2)
                                          : decimalFormat(z.detail[0].scoreMax, 2),
                                  scoreSumRange:
                                      z.detail.length > 1
                                          ? decimalFormat(Math.min(...z.detail.map(o => o.scoreSum)), 2) +
                                            "~" +
                                            decimalFormat(Math.max(...z.detail.map(o => o.scoreSum)), 2)
                                          : decimalFormat(z.detail[0].scoreSum, 2),
                                  correlationRange:
                                      z.detail.length > 1
                                          ? decimalFormat(Math.min(...z.detail.map(o => o.ppmcc)), 2) +
                                            "~" +
                                            decimalFormat(Math.max(...z.detail.map(o => o.ppmcc)), 2)
                                          : decimalFormat(z.detail[0].ppmcc, 2),
                                  detail: z.detail.map(y => {
                                      return {
                                          ...y,

                                          showScoreSum: decimalFormat(y.scoreSum, 2),
                                          showMaxScore: decimalFormat(y.scoreMax, 2),
                                          showPPMCC: decimalFormat(y.ppmcc, 2),
                                          // scoreRank: maxScoreRanked.find((element) => element.server == y.server && element.symbol == y.symbol && element.compareServer == y.compareServer).maxScoreRank +"-"+ scoreSumRanked.find((element) => element.server == y.server && element.symbol == y.symbol && element.compareServer == y.compareServer).scoreSumRank +"-"+ ppmccRanked.find((element) => element.server == y.server && element.symbol == y.symbol && element.compareServer == y.compareServer).ppmccRank ,
                                          scoreRank: decimalFormat(
                                              (maxScoreRanked?.find(
                                                  element =>
                                                      element.server === y.server &&
                                                      element.symbol === y.symbol &&
                                                      element.compareServer === y.compareServer
                                              )?.maxScoreRank || 0) *
                                                  0.3 +
                                                  (scoreSumRanked?.find(
                                                      element =>
                                                          element.server === y.server &&
                                                          element.symbol === y.symbol &&
                                                          element.compareServer === y.compareServer
                                                  )?.scoreSumRank || 0) *
                                                      0.2 +
                                                  (ppmccRanked?.find(
                                                      element =>
                                                          element?.server === y.server &&
                                                          element.symbol === y.symbol &&
                                                          element.compareServer === y.compareServer
                                                  )?.ppmccRank || 0) *
                                                      0.5,
                                              2
                                          ),
                                      };
                                  }),
                                  server: firstScoreRanked?.server,
                                  symbol: firstScoreRanked?.symbol,
                                  serverUno: firstScoreRanked?.serverUno,
                                  compareServer: firstScoreRanked?.compareServer || null,
                                  compareSymbol: firstScoreRanked?.compareSymbol || null,
                                  cserverUno: firstScoreRanked?.cserverUno,
                                  evaluate: firstScoreRanked?.evaluate,
                              };
                          }),
                      };
                  }),
              };
          });
    // console.log("massagedMidBias", massagedMidBias);
    const rcMidBiasGeneral = massagedMidBias.filter((item: any) => item.symbolType === 0);
    const rcMidBiasFuture = massagedMidBias.filter((item: any) => item.symbolType === 1);
    const shouldPlayMidBiasAlarm = rcMidBiasGeneral?.some(item =>
        item.detail.some(z => z.detail.some(y => y.detail.some(x => x.ppmcc < 80 || x.scoreMax > 15)))
    );

    // @ts-ignore
    const rcMidBiasError = data?.data?.message || error?.message || error || null;

    return {
        rcMidBias,
        massagedMidBias,
        shouldPlayMidBiasAlarm, // only trigger alarm if general has any scoreMax > 15 or ppmcc < 80
        dataUpdatedAt,
        rcMidBiasGeneral,
        rcMidBiasFuture,
        rcMidBiasError,
        refetchRcMidBias: refetch,
        isFetching,
        isLoading,
    };
};

export default useRCMidBias;
