import CardBox from "@/components/Common/CardBox";
import FlexiDataTable from "@/components/FlexiDataTable";
import { ComponentType, CALLBACK_KEY, SUCCESS_FAILED } from "@/constants";
import { FlexiDataTableOptionsProps, FlexiDataTableCallbackProps, KeyValuePair, CustomPaginationProps } from "@/constants/type";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { useState, useEffect, useMemo, useCallback } from "react";
import moment from "moment";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { CheckCircleOutlined, CloseCircleOutlined, ContainerOutlined, DownloadOutlined } from "@ant-design/icons";
import FundingFeeProcessTradeDataModal, { FundingFeeProcessTradeDataModalCallbackKey } from "./tradesDataModal";
import ReportBatchModal, { ReportBatchModalCallbackKey } from "@/pages/ReportingModule/ReportBatch";
import { message } from "antd";

export interface FundingFeeProcessPageProps {}

export interface FundingProcessProps {
    createDate: string;
    group: string;
    login: number;
    receiveDate: string;
    receiveHour: number;
    serverUno: number;
    status: number;
    subAccount: number;
    swap: string;
    updateDate: string | null;
}

const FundingFeeProcessPage = (props: FundingFeeProcessPageProps) => {
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(true);
    const [isReportModalVisible, setIsReportModalVisible] = useState<boolean>(false);
    const [isTradeModalVisible, setIsTradeModalVisible] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<FundingProcessProps[]>([]);
    const [pagination, setPagination] = useState<CustomPaginationProps>({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [filterParams, setFilterParams] = useState<any>({
        serverUno: "",
        receiveDate: moment(),
        login: "",
    });
    const [servers, setServers] = useState<KeyValuePair[]>([]);
    const [selectedFilterParams, setSelectedFilterParams] = useState<FundingProcessProps | undefined>(undefined);

    const exportCSV = useCallback(() => {
        let params = {
            ...filterParams,
            receiveDate: moment(filterParams.receiveDate).format("YYYYMMDD"),
        };

        plainAxiosInstance
            .post(APIs.RISK_TOOL.GET_FUNDING_FEE_PROCESS_DOWNLOAD, params)
            .then((res: any) => {
                if (res.data === 0) {
                    message.success("Add to batch successed. Please download from【Report Listing】", 3);
                } else {
                    message.error(`Add to batch failed`, 3);
                }
            })
            .catch((error: any) => message.error(`Add to batch failed: "${error.message}"`, 3));
    }, [filterParams]);

    const columns: any[] = useMemo(
        () => [
            DTColProps.Middle({
                title: "Server",
                dataIndex: "serverUno",
                key: "serverUno",
                render: (serverUno: number) => {
                    let server = servers.find(x => x.value === serverUno);
                    return server ? server.text : "";
                },
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: servers,
                    },
                },
            }),
            DTColProps.Small({
                title: "Received Date",
                dataIndex: "receiveDate",
                key: "receiveDate",
                options: {
                    filter: {
                        type: ComponentType.date,
                        value: "",
                        dateFormat: "YYYY-MM-DD",
                        rules: [{ required: true, message: REQUIRED_FIELD }],
                    },
                },
            }),
            DTColProps.Small({
                title: "Received Hour",
                dataIndex: "receiveHour",
                key: "receiveHour",
            }),
            DTColProps.Small({
                title: "Login",
                dataIndex: "login",
                key: "login",
                options: {
                    filter: {
                        type: ComponentType.text,
                        value: "",
                    },
                },
            }),
            {
                title: "Group",
                dataIndex: "group",
                key: "group",
            },
            DTColProps.Status(
                {
                    title: "SubAccount",
                    dataIndex: "subAccount",
                    key: "subAccount",
                    render: (subAccount: number) => (
                        <span>
                            {subAccount === 1 ? (
                                <CheckCircleOutlined style={{ color: "#0ab76e", fontSize: "1.375rem" }} />
                            ) : (
                                <CloseCircleOutlined style={{ color: "#f00f00", fontSize: "1.375rem" }} />
                            )}
                        </span>
                    ),
                },
                ["text-center"]
            ),
            DTColProps.XSmall(
                {
                    title: "Status",
                    dataIndex: "status",
                    key: "status",
                    sorter: (a: any, b: any) => a.status - b.status,
                    render: (status: number) => <span>{status === 1 ? "Received" : status === 2 ? "Unnecessary" : "Unreceived"}</span>,
                },
                ["text-center"]
            ),
            DTColProps.SCurrency({
                title: "Swap",
                dataIndex: "swap",
                key: "swap",
            }),
            DTColProps.Small({
                title: "Create Date",
                dataIndex: "createDate",
                key: "createDate",
            }),
        ],
        [servers]
    );

    const options: FlexiDataTableOptionsProps = {
        serverFiltering: true,
        view: true,
        extraButtons: [
            { text: "Report Listing", value: "reportlist", icon: <ContainerOutlined /> },
            { text: "Download Report", value: "downloadreport", icon: <DownloadOutlined /> },
        ],
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, FormData) => {
        switch (type) {
            case CALLBACK_KEY.HANDLE_PAGINATION_SORTING:
                setPagination(prev => ({ ...prev, current: FormData.pagination.current, pageSize: FormData.pagination.pageSize }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                let filterParams: any = {};
                Object.keys(FormData)
                    .filter(x => FormData[x] && FormData[x].toString().length > 0)
                    .map(x => {
                        filterParams[x] = FormData[x];
                        return x;
                    });
                setFilterParams(filterParams);
                setPagination(prev => ({ ...prev, current: 1 }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.OTHERS:
                if (FormData === "reportlist") {
                    setIsReportModalVisible(true);
                } else if (FormData === "downloadreport") {
                    exportCSV();
                }
                break;
            case CALLBACK_KEY.VIEW_RECORD:
                setSelectedFilterParams(FormData);
                setIsTradeModalVisible(true);
                break;
            default:
                break;
        }
    };

    const getFundingFeeProcessList = useCallback(() => {
        setIsLoading(true);
        let params = {
            ...filterParams,
            receiveDate: moment(filterParams.receiveDate).format("YYYYMMDD"),
            ...{
                page: pagination.current,
                pageSize: pagination.pageSize,
            },
        };

        if (params.serverUno === "") delete params.serverUno;

        plainAxiosInstance
            .get(APIs.RISK_TOOL.GET_FUNDING_FEE_PROCESS, { params })
            .then((res: any) => {
                if (res.data && res.data.length > 0) {
                    setData(res.data.map((x: any) => ({ ...x, id: `${x.serverUno}-${x.login}-${x.createDate}` })));
                    setPagination(prev => ({ ...prev, total: res.data.total }));
                } else {
                    setData([]);
                    setPagination(prev => ({ ...prev, total: 0 }));
                }
            })
            .catch(error =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("funding fee process", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsLoading(false));
    }, [filterParams, pagination]);

    const getConfig = () => {
        plainAxiosInstance.get(APIs.RISK_TOOL.GET_FUNDING_FEE_PROCESS_SERVERS, {}).then((res: any) => {
            setServers(res.data.map((x: any) => ({ text: x.label, value: parseInt(x.value, 10) })));
        });
    };

    useEffect(() => {
        if (runRefetchDataList) {
            getFundingFeeProcessList();
            setRunRefetchDataList(false);
        }
    }, [runRefetchDataList]);

    useEffect(() => {
        getConfig();
    }, []);

    return (
        <>
            <div className="funding-fee-process-container">
                <CardBox title={"Funding Fee Process"}>
                    <FlexiDataTable
                        rowKeyProperty="id"
                        title=""
                        columns={columns}
                        options={options}
                        dataSource={data}
                        callback={componentCallback}
                        loading={isLoading}
                        pagination={pagination}
                        serverSide={true}
                        filterInitialValue={filterParams}
                    />
                </CardBox>
            </div>
            <ReportBatchModal
                isModalVisible={isReportModalVisible}
                modalTitle={"Download Report"}
                callback={(type: number) => {
                    switch (type) {
                        case ReportBatchModalCallbackKey.Close:
                            setIsReportModalVisible(false);
                            break;
                        default:
                            break;
                    }
                }}
                filterCallback={(record: any) => record.batchName === "FundingFeeTrades"}
            />
            <FundingFeeProcessTradeDataModal
                isModalVisible={isTradeModalVisible}
                callback={(type: number, data: any) => {
                    switch (type) {
                        case FundingFeeProcessTradeDataModalCallbackKey.Close:
                            setIsTradeModalVisible(false);
                            break;
                        default:
                            break;
                    }
                }}
                data={selectedFilterParams}
                servers={servers}
            />
        </>
    );
};

export default FundingFeeProcessPage;
