import { useQuery } from "@tanstack/react-query";
import { APIs } from "../services/apis";
import { plainAxiosInstance } from "../services/axiosSetup";
import { PriceAnalysisAlarmLatestList } from "../constants/type";
import { latestDummy } from "../pages/PriceMonitor/PriceAnalysisAlarm/dummydata/latestdummy";

/* Monitor Wall*/
// generateDummyData is for testing purpose
// const serverGroups = [
//     { groupId: 1, servers: [
//         { serverUno: 2, serverDn: "AU", isBaseServer: true },
//         { serverUno: 21, serverDn: "VT", isBaseServer: false },
//         { serverUno: 30, serverDn: "AU2", isBaseServer: false },
//         { serverUno: 36, serverDn: "AU3", isBaseServer: false },
//         { serverUno: 39, serverDn: "VT2", isBaseServer: false },
//         { serverUno: 40, serverDn: "PUI", isBaseServer: false },
//         { serverUno: 51, serverDn: "AU4", isBaseServer: false },
//         { serverUno: 63, serverDn: "PUI2", isBaseServer: false },
//         { serverUno: 68, serverDn: "VT4", isBaseServer: false },
//         { serverUno: 75, serverDn: "AU5", isBaseServer: false },
//         { serverUno: 82, serverDn: "AU6", isBaseServer: false },
//         { serverUno: 84, serverDn: "PU4", isBaseServer: false },
//         { serverUno: 95, serverDn: "VT7", isBaseServer: false },
//     ]},
//     { groupId: 2, servers: [
//         { serverUno: 3, serverDn: "UK", isBaseServer: true },
//         { serverUno: 31, serverDn: "UK2", isBaseServer: false },
//         { serverUno: 37, serverDn: "UK3", isBaseServer: false },
//         { serverUno: 52, serverDn: "UK4", isBaseServer: false },
//         { serverUno: 64, serverDn: "VT3", isBaseServer: false },
//         { serverUno: 65, serverDn: "UK5", isBaseServer: false },
//         { serverUno: 76, serverDn: "UK6", isBaseServer: false },
//         { serverUno: 78, serverDn: "PU3", isBaseServer: false },
//         { serverUno: 81, serverDn: "VT5", isBaseServer: false },
//         { serverUno: 83, serverDn: "UK7", isBaseServer: false },
//         { serverUno: 87, serverDn: "UK8", isBaseServer: false },
//         { serverUno: 90, serverDn: "VT6", isBaseServer: false },
//         { serverUno: 92, serverDn: "PU5", isBaseServer: false },
//         { serverUno: 94, serverDn: "UK9", isBaseServer: false },
//         { serverUno: 98, serverDn: "UK10", isBaseServer: false },
//     ]}
// ];

// function generateDummyData(count: number) {
//     const alarmTypes = ["SCORE_SUM", "SCORE_MAX", "PPMCC"];
//     const symbols = ["GBPUSD", "AUDUSD", "EURUSD", "USDJPY"];
//     const getRandomItem = (arr: string | any[]) => arr[Math.floor(Math.random() * arr.length)];
//     const getRandomNumber = (min: number, max: number) => (Math.random() * (max - min) + min).toFixed(4);
//     const getRandomDate = () => new Date(Date.now() - Math.floor(Math.random() * 10000000000)).toISOString().replace('T', ' ').split('.')[0];

//     const data = [];

//     for (let i = 0; i < count; i++) {
//         const group = getRandomItem(serverGroups);
//         const baseServer = group.servers.find((server: { isBaseServer: any; }) => server.isBaseServer);

//         let otherServer;
//         do {
//             otherServer = getRandomItem(group.servers);
//         } while (otherServer.serverUno === baseServer.serverUno && otherServer.serverDn === baseServer.serverDn);

//         data.push({
//             n: Math.random(),
//             groupId: group.groupId,
//             baseServerUno: baseServer.serverUno,
//             baseServerDn: baseServer.serverDn,
//             baseSymbol: getRandomItem(symbols),
//             otherServerUno: otherServer.serverUno,
//             otherServerDn: otherServer.serverDn,
//             otherSymbol: getRandomItem(symbols),
//             alarmTime: getRandomDate(),
//             alarmType: getRandomItem(alarmTypes),
//             scoreSum: getRandomNumber(1, 100),
//             scoreMax: getRandomNumber(1, 10),
//             ppmcc: getRandomNumber(-1, 1),
//             solved: false,
//             solvedTime: "1970-01-01 00:00:00",
//             solvedUser: ""
//         });
//     }

//     return data;
// }

const useRCPriceAnalysisAlarmLatest = (previousMinutes: number) => {
    const { data, refetch, isLoading, error, isFetching, dataUpdatedAt } = useQuery({
        queryKey: ["previousMinutes", previousMinutes],
        queryFn: () =>
            plainAxiosInstance.post(
                APIs.RC_PRICE_ANALYSIS.POST_PA_ALARM_LATEST_LIST,
                { previousMinutes: previousMinutes },
                {
                    headers: {
                        Accept: "application/json, text/plain, */*",
                    },
                }
            ),
        refetchInterval: 15 * 1000, //(10 x 1000ms = 10 seconds)
    });

    // @ts-ignore
    const rcPALatestList: PriceAnalysisAlarmLatestList[] | null = data?.data?.status ? null : data?.data || null;
    // @ts-ignore
    const rcPALatestListsError = data?.data?.message || error?.message || error || null;

    // const rcPALatestList = latestDummy;
    return {
        rcPALatestList,
        rcPALatestListsError,
        refetchRcPALatestList: refetch,
        isFetching,
        isLoading,
        dataUpdatedAt
    };
};
export default useRCPriceAnalysisAlarmLatest;

// const useRCPriceAnalysisAlarmLatest = (previousMinutes: number) => {
//     const { data, refetch, isLoading, error, isFetching, dataUpdatedAt } = useQuery({
//         queryKey: ["previousMinutes", previousMinutes],
//         queryFn: () => generateDummyData(10000),
//         refetchInterval: 15 * 1000, // 15 seconds
//     });

//     // @ts-ignore
//     const rcPALatestListsError = data?.data?.message || error?.message || error || null;

//     const rcPALatestList = data;

//     return {
//         rcPALatestList,
//         rcPALatestListsError,
//         refetchRcPALatestList: refetch,
//         isFetching,
//         isLoading,
//         dataUpdatedAt
//     };
// };

// export default useRCPriceAnalysisAlarmLatest;
