import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import SystemHelper from "../../helpers/systemHelper";

export interface SystemConfigProps {
    title: string;
    isLogin: boolean;
    sideBarCollapsed: boolean;
    refreshingToken: boolean;
    notifCount: number;
    isWsConnected: boolean;
    isMuteAlarm: boolean;
}

const getCurrentSystemState = () => {
    let sysConfig = new SystemHelper();

    return {
        title: sysConfig._systemConfig.title,
        isLogin: sysConfig._systemConfig.isLogin,
        sideBarCollapsed: sysConfig._systemConfig.sideBarCollapsed,
        refreshingToken: sysConfig._systemConfig.refreshingToken,
        notifCount: sysConfig._systemConfig.notifCount,
        isWsConnected: sysConfig._systemConfig.isWsConnected,
        isMuteAlarm: sysConfig._systemConfig.isMuteAlarm,
    };
};

export const systemConfigSlice = createSlice({
    name: "systemConfig",
    initialState: Object.assign({}, getCurrentSystemState()),
    reducers: {
        login: (state) => {
            state.isLogin = true;

            let sysConfig = new SystemHelper();
            sysConfig.setIsLogin(true);
        },
        logout: (state) => {
            state.isLogin = false;

            let sysConfig = new SystemHelper();
            sysConfig.setIsLogin(false);
        },
        setTitle: (state, action: PayloadAction<string>) => {
            state.title = action.payload;

            let sysConfig = new SystemHelper();
            sysConfig.setTitle(action.payload);
        },
        setSideBarCollapse: (state) => {
            let sysConfig = new SystemHelper();
            sysConfig.setSideBarCollapse(!state.sideBarCollapsed);

            state.sideBarCollapsed = !state.sideBarCollapsed;
        },
        setRefreshToken: (state, action: PayloadAction<boolean>) => {
            state.refreshingToken = action.payload;
        },
        setNotifCount: (state, action: PayloadAction<number>) => {
            state.notifCount = action.payload;

            let sysConfig = new SystemHelper();
            sysConfig.setNotifCount(state.notifCount);
        },
        setIsWsConnected: (state, action: PayloadAction<boolean>) => {
            state.isWsConnected = action.payload;

            let sysConfig = new SystemHelper();
            sysConfig.setIsWsConnected(state.isWsConnected);
        },
        setIsMuteAlarm: (state, action: PayloadAction<boolean>) => {
            state.isMuteAlarm = action.payload;

            let sysConfig = new SystemHelper();
            sysConfig.setIsMuteAlarm(state.isMuteAlarm);
        },
        incrementNotifCount: (state) => {
            state.notifCount += 1;

            let sysConfig = new SystemHelper();
            sysConfig.setNotifCount(state.notifCount);
        },
        decrementNotifCount: (state) => {
            state.notifCount -= 1;

            let sysConfig = new SystemHelper();
            sysConfig.setNotifCount(state.notifCount);
        },
        clearNotifCountByX: (state, action: PayloadAction<number>) => {
            state.notifCount -= action.payload;

            let sysConfig = new SystemHelper();
            sysConfig.setNotifCount(state.notifCount);
        },
    },
});

export const { login, logout, setTitle, setSideBarCollapse, setRefreshToken, setNotifCount, setIsWsConnected, setIsMuteAlarm, incrementNotifCount, decrementNotifCount, clearNotifCountByX } =
    systemConfigSlice.actions;

export default systemConfigSlice.reducer;
