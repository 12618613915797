import { useParams } from "react-router-dom";
import TradeMonitorProfileComponent from "./TradeMonitorProfileComponent";

export interface ViewCollectionItemProps {
    profileId: number;
    viewId: number | string;
    isEventRuleView: boolean;
    params: any;
}

const TradeMonitoringPanel = () => {
    let { id } = useParams();
    const currProfileId = atob(id as string);

    return <TradeMonitorProfileComponent currProfileId={currProfileId} />;
};

export default TradeMonitoringPanel;
