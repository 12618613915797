import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
// import axios from "axios";
// import { getToken } from "../services/localStorage";
import { APIs } from "../services/apis";
import { plainAxiosInstance } from "../services/axiosSetup";

/* sample response from the API
{
    "serverTime": "2024-05-16 09:49:46(GMT+0300)",
    "data": [
        {
            "serverName": "AU4",
            "serverTime": "2024-05-16T09:49:00",
            "users": 2638,
            "cpu": 4,
            "freeMemory": 620,
            "network": 655,
            "sockets": 3367,
            "timestamp": null,
            "secondsSinceLastUpdate": 31,
            "isMissing": null,
            "message": "Free memory is running out;"
        }
    ]
}
*/

interface RCServerPerformance {
    serverName: string;
    serverTime: string;
    users: number;
    cpu: number;
    freeMemory: number;
    network: number;
    sockets: number;
    timestamp: string | null;
    secondsSinceLastUpdate: number;
    isMissing: string | null;
    message: string;
}
interface RCServerPerformanceResponse {
    serverTime: string;
    data: RCServerPerformance[];
}

const useRCServerPerformance = () => {
    // const token = getToken();
    const { data, refetch, isLoading, error, isFetching, dataUpdatedAt } = useQuery({
        queryKey: ["rcServerPerformance"],
        queryFn: () =>
            plainAxiosInstance.get(`${APIs.RC_SERVER_PERFORMANCE.GET_PERFORMANCE_MONITOR}`, {
                headers: {
                    Accept: "application/json",
                },
            }),

        // axios.get(`${process.env.REACT_APP_API_URL}${APIs.RC_SERVER_PERFORMANCE.GET_PERFORMANCE_MONITOR}`, {
        //     headers: {
        //         Accept: "application/json",
        //         Authorization: `Bearer ${token}`,
        //     },
        // }),
        staleTime: 60 * 1000, //(10 x 1000ms = 10 seconds)
        refetchInterval: 60 * 1000, //(10 x 1000ms = 10 seconds)
    });

    const rcServerPerformance: RCServerPerformanceResponse | null = data?.data?.status ? null : data?.data || null;
    // @ts-ignore
    const rcServerPerformanceError: string | null = data?.data?.message || error?.message || error || null;

    return {
        rcServerPerformance,
        rcServerPerformanceError,
        refetchRcServerPerformance: refetch,
        isFetching,
        dataUpdatedAt,
        isLoading,
    };
};

export default useRCServerPerformance;
