import moment from "moment";
import { fillString } from "./Common";
import { getProfile } from "../services/localStorage";

export const DateTimeUtil = {
    GetUTC: (datetime: any, format: string = ""): string => (format.length > 0 ? moment.utc(datetime).format(format) : moment.utc(datetime).toJSON()),
    GetOrigin: (datetime: any, format: string = "YYYY-MM-DDTHH:mm:ss"): string => datetime.format(format),
    getCurrentTimeStamp: (): number => new Date().getTime(),
    getCurrentServerTime: (): moment.Moment => {
        const uPr: any = getProfile();
        return uPr?.hasOwnProperty("stom") ? moment().utcOffset(uPr.stom as number) : moment().tz("EET");
    },
    isTimeExceeded: (targetTimeStamp: number, seconds: number): boolean => seconds * 1000 + targetTimeStamp < DateTimeUtil.getCurrentTimeStamp(),
    convertSecondToReadableTimeDesc: (value: number, lightMode: boolean = false, defaultValue: string = ""): string => {
        let desc_text: string = "";
        let finalSec: number = [
            { type: "hr", divide: 3600 },
            { type: "min", divide: 60 },
        ].reduce((remaining: number, item: any) => {
            if (Math.trunc(remaining / item.divide) > 0) {
                if (lightMode) {
                    desc_text += `${fillString(`${Math.trunc(remaining / item.divide)}`, 2, "0", true)}:`;
                } else {
                    desc_text += ` ${Math.trunc(remaining / item.divide)} ${item.type}${Math.trunc(remaining / item.divide) > 1 ? "s" : ""}`;
                }
            } else if (lightMode) {
                desc_text += "00:";
            }
            return remaining % item.divide;
        }, value);

        if (finalSec > 0) {
            desc_text += lightMode ? `${fillString(`${finalSec}`, 2, "0", true)}` : ` ${finalSec} sec`;
        } else if (lightMode) {
            desc_text += "00";
        } else if (desc_text.length === 0) {
            desc_text = defaultValue;
        }
        return desc_text;
    },
    getDiffBetween2Date: (fromDate: string, toDate: string, returnType: number = -1): string | number => {
        let timeDiff = moment(toDate).diff(moment(fromDate));
        let duration = moment.duration(timeDiff);
        let hrs = Math.floor(duration.asHours());
        switch (returnType) {
            case 1: // get minutes only
                return duration.asMinutes();
            case 2: // get seconds only
                return duration.asSeconds();
            case 3:
                return moment.utc(timeDiff).format("mm:ss");
            default:
                return `${hrs.toString().length < 2 ? "0" : ""}${hrs}` + moment.utc(timeDiff).format(":mm:ss");
        }
    },
};
