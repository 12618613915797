import { useQuery } from "@tanstack/react-query";
import { APIs } from "../services/apis";
import { plainAxiosInstance } from "../services/axiosSetup";

/*
sample response from the API
[
  {
    "brandId": "Vantage",
    "brandName": "Vantage",
    "senderMail": "MT4@vantagemarkets.com",
    "brandMail": "support@vantagemarkets.com",
    "comment": null
  },
  {
    "brandId": "Ultimas",
    "brandName": "UM",
    "senderMail": "mt4@ultimamarkets.com",
    "brandMail": "trading@ultimamarkets.com,Operations.Coordinator@ultimamarkets.com",
    "comment": "UM"
  },
  {
    "brandId": "Moneta",
    "brandName": "Moneta",
    "senderMail": "MT4@monetamarkets.com",
    "brandMail": "Operation@monetamarkets.com,support@monetamarkets.com",
    "comment": null
  },
  {
    "brandId": "KCM",
    "brandName": "KCM",
    "senderMail": "MT4@vantagemarkets.com",
    "brandMail": "operation@klimexcm.com",
    "comment": null
  },
  {
    "brandId": "VT",
    "brandName": "VT",
    "senderMail": "mt4@vtmarkets.com",
    "brandMail": "trading.taipei@vtmarkets.com",
    "comment": null
  },
  {
    "brandId": "Pacific",
    "brandName": "PU",
    "senderMail": "MT4@puprime.com",
    "brandMail": "tickets@puprime.com,trading@puprime.com,mt4@puprime.com",
    "comment": "PU"
  },
  {
    "brandId": "IV",
    "brandName": "IV",
    "senderMail": "system.admin@startrader.com",
    "brandMail": "regional.control@hytechc.com,trading@startrader.com",
    "comment": ""
  },
  {
    "brandId": "Infinox",
    "brandName": "Infinox",
    "senderMail": "midoffice@infinox.com",
    "brandMail": "Tradingdesk@infinox.com,support@infinox.com,partners@infinox.com,accounts@infinox.com,Support.asia@infinox.com",
    "comment": null
  },
  {
    "brandId": "ATOM8",
    "brandName": "ATOM8",
    "senderMail": "mt4@vantagemarkets.co.uk",
    "brandMail": "operations@vantagemarkets.co.uk,support@vantagemarkets.co.uk",
    "comment": "VGPUK"
  },
  {
    "brandId": "VIDA",
    "brandName": "VIDA",
    "senderMail": "system.admin@vidamarkets.com",
    "brandMail": "regional.control@hytechc.com",
    "comment": null
  },
  {
    "brandId": "AT",
    "brandName": "AlphaTick",
    "senderMail": "system.admin@alphatick.com",
    "brandMail": "regional.control@hytechc.com,support@alphatick.com",
    "comment": null
  },
  {
    "brandId": "RF",
    "brandName": "RockFort",
    "senderMail": null,
    "brandMail": null,
    "comment": null
  },
  {
    "brandId": "VantageJPN",
    "brandName": "VantageJPN",
    "senderMail": "MT4@vantagetradings.com",
    "brandMail": "support@vantagetradings.com",
    "comment": null
  },
  {
    "brandId": "STPrime",
    "brandName": "Startrader Prime",
    "senderMail": "system.admin@startraderprime.com.au",
    "brandMail": "regional.control@hytechc.com",
    "comment": null
  },
  {
    "brandId": "Bybit",
    "brandName": "Bybit",
    "senderMail": null,
    "brandMail": null,
    "comment": null
  }
]
*/

const dummy = [
    {
        brandId: "Vantage",
        brandName: "Vantage",
        senderMail: "MT4@vantagemarkets.com",
        brandMail: "support@vantagemarkets.com",
        comment: null,
    },
    {
        brandId: "Ultimas",
        brandName: "UM",
        senderMail: "mt4@ultimamarkets.com",
        brandMail: "trading@ultimamarkets.com,Operations.Coordinator@ultimamarkets.com",
        comment: "UM",
    },
    {
        brandId: "Moneta",
        brandName: "Moneta",
        senderMail: "MT4@monetamarkets.com",
        brandMail: "Operation@monetamarkets.com,support@monetamarkets.com",
        comment: null,
    },
    {
        brandId: "KCM",
        brandName: "KCM",
        senderMail: "MT4@vantagemarkets.com",
        brandMail: "operation@klimexcm.com",
        comment: null,
    },
    {
        brandId: "VT",
        brandName: "VT",
        senderMail: "mt4@vtmarkets.com",
        brandMail: "trading.taipei@vtmarkets.com",
        comment: null,
    },
    {
        brandId: "Pacific",
        brandName: "PU",
        senderMail: "MT4@puprime.com",
        brandMail: "tickets@puprime.com,trading@puprime.com,mt4@puprime.com",
        comment: "PU",
    },
    {
        brandId: "IV",
        brandName: "IV",
        senderMail: "system.admin@startrader.com",
        brandMail: "regional.control@hytechc.com,trading@startrader.com",
        comment: "",
    },
    {
        brandId: "Infinox",
        brandName: "Infinox",
        senderMail: "midoffice@infinox.com",
        brandMail: "Tradingdesk@infinox.com,support@infinox.com,partners@infinox.com,accounts@infinox.com,Support.asia@infinox.com",
        comment: null,
    },
    {
        brandId: "ATOM8",
        brandName: "ATOM8",
        senderMail: "mt4@vantagemarkets.co.uk",
        brandMail: "operations@vantagemarkets.co.uk,support@vantagemarkets.co.uk",
        comment: "VGPUK",
    },
    {
        brandId: "VIDA",
        brandName: "VIDA",
        senderMail: "system.admin@vidamarkets.com",
        brandMail: "regional.control@hytechc.com",
        comment: null,
    },
    {
        brandId: "AT",
        brandName: "AlphaTick",
        senderMail: "system.admin@alphatick.com",
        brandMail: "regional.control@hytechc.com,support@alphatick.com",
        comment: null,
    },
    {
        brandId: "RF",
        brandName: "RockFort",
        senderMail: null,
        brandMail: null,
        comment: null,
    },
    {
        brandId: "VantageJPN",
        brandName: "VantageJPN",
        senderMail: "MT4@vantagetradings.com",
        brandMail: "support@vantagetradings.com",
        comment: null,
    },
    {
        brandId: "STPrime",
        brandName: "Startrader Prime",
        senderMail: "system.admin@startraderprime.com.au",
        brandMail: "regional.control@hytechc.com",
        comment: null,
    },
    {
        brandId: "Bybit",
        brandName: "Bybit",
        senderMail: null,
        brandMail: null,
        comment: null,
    },
];

interface RCBrand {
    brandId: string;
    brandName: string;
    senderMail: string | null;
    brandMail: string | null;
    comment: string | null;
}

const useRCBrands = () => {
    const { data, refetch, isLoading, error, isFetching } = useQuery({
        queryKey: ["rcBrands"],
        queryFn: () =>
            plainAxiosInstance.get(`${APIs.RC_ROOT.GET_BRANDS}`, {
                headers: {
                    Accept: "application/json",
                },
            }),
        staleTime: 60 * 1000 * 5, //(60 x 1000ms x 5 = 5 minutes)
    });

    const rcBrands: RCBrand[] | null = data?.data?.status ? null : data?.data || null;
    // const rcBrands: RCBrand[] | null = dummy;
    // @ts-ignore
    const rcBrandsError: string | null = data?.data?.message || error?.message || error || null;

    return {
        rcBrands,
        rcBrandsError,
        refetchRcBrands: refetch,
        isFetching,
        isLoading,
    };
};

export default useRCBrands;
