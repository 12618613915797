import { MotionValue, motion, useSpring, useTransform } from "framer-motion";
import { useEffect } from "react";

// const padding = 15;
// const height = 20;
// const height = fontSize + padding;

function Counter({ value, digits = 3, fontSize = 20 }: { value: number; digits?: number; fontSize?: number }) {
    return (
        <div
            style={{
                fontSize,
                display: "flex",
                overflow: "hidden",
                // padding: "0 0.5rem",
                //leading none
                lineHeight: 1,
                borderRadius: "0.25rem",
            }}
        >
            {/* loop digits, and render <Digit place={} value={value} />, make sure it's start from highest number to lowest */}
            {[...Array(digits).keys()].map(i => (
                <Digit key={i} place={10 ** (digits - i - 1)} value={value} fontSize={fontSize} />
            ))}
        </div>
    );
}

function Digit({ place, value, fontSize }: { place: number; value: number; fontSize: number }) {
    let valueRoundedToPlace = Math.floor(value / place);
    let animatedValue = useSpring(valueRoundedToPlace);

    useEffect(() => {
        animatedValue.set(valueRoundedToPlace);
    }, [animatedValue, valueRoundedToPlace]);

    return (
        <div style={{ height: fontSize, position: "relative", width: "1ch" }} className="relative w-[1ch] tabular-nums">
            {/* @ts-ignore */}
            {[...Array(10).keys()].map(i => (
                <Number key={i} mv={animatedValue} number={i} fontSize={fontSize} />
            ))}
        </div>
    );
}

function Number({ mv, number, fontSize }: { mv: MotionValue; number: number; fontSize: number }) {
    let y = useTransform(mv, latest => {
        let placeValue = latest % 10;
        let offset = (10 + number - placeValue) % 10;

        let memo = offset * fontSize;

        if (offset > 5) {
            memo -= 10 * fontSize;
        }

        return memo;
    });

    return (
        <motion.span style={{ y, position: "absolute", inset: 0, display: "flex" }} className="absolute inset-0 flex items-center justify-center">
            {number}
        </motion.span>
    );
}

export default Counter;
