import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { APIs } from "../services/apis";
import { plainAxiosInstance } from "../services/axiosSetup";

/*
sample response from the API
[
  {
    "serverUno": 16,
    "serverName": "MT5AU",
    "login": 741902,
    "symbol": "EURUSD",
    "dealId": 171190847,
    "type": 1,
    "price": 0.0857,
    "lots": 1,
    "dealDt": "2024-07-26T22:06:50",
    "dealDtStr": "2024/07/26 22:06:50",
    "high": 1.08575,
    "low": 1.08557,
    "alarmDt": "2024-07-26T22:08:00",
    "alarmDtStr": "2024/07/26 22:08:00",
    "solved": 0,
    "solvedTime": "1970-01-01T00:00:00",
    "solvedTimeStr": "1970/01/01 00:00:00",
    "solvedUser": ""
  },
  {
    "serverUno": 16,
    "serverName": "MT5AU",
    "login": 741902,
    "symbol": "USDCAD",
    "dealId": 171187279,
    "type": 1,
    "price": 0.08387,
    "lots": 1,
    "dealDt": "2024-07-26T21:57:18",
    "dealDtStr": "2024/07/26 21:57:18",
    "high": 1.38386,
    "low": 1.38358,
    "alarmDt": "2024-07-26T21:59:00",
    "alarmDtStr": "2024/07/26 21:59:00",
    "solved": 0,
    "solvedTime": "1970-01-01T00:00:00",
    "solvedTimeStr": "1970/01/01 00:00:00",
    "solvedUser": ""
  }
]
*/

export interface IRCOutOfPriceAlarm {
    serverUno: number;
    serverName: string;
    login: number;
    symbol: string;
    dealId: number;
    type: number;
    price: number;
    lots: number;
    dealDt: string;
    dealDtStr: string;
    high: number;
    low: number;
    alarmDt: string;
    alarmDtStr: string;
    solved: number;
    solvedTime: string;
    solvedTimeStr: string;
    solvedUser: string;
}

const useRCOutOfPriceAlarm = ({ type }: { type: "solved" | "alarm" }) => {
    const { data, refetch, isLoading, error, isFetching, dataUpdatedAt } = useQuery({
        queryKey: ["rcOutOfPriceAlarm", type],
        queryFn: () =>
            plainAxiosInstance.get(`${APIs.RC_OUT_OF_PRICE_ALARM.GET_ALARMS}/${type === "alarm" ? "0" : "1"}`, {
                headers: {
                    Accept: "application/json",
                },
            }),
        enabled: type === "alarm" || type === "solved",
        staleTime: 30 * 1000, //(30 x 1000ms = 30 seconds)
        refetchInterval: 30 * 1000, //(30 x 1000ms = 30 seconds)
    });

    const rcOutOfPriceAlarm: IRCOutOfPriceAlarm[] | null = data?.data?.status ? null : data?.data || null;
    // @ts-ignore
    const rcOutOfPriceAlarmError: string | null = data?.data?.message || error?.message || error || null;

    return {
        rcOutOfPriceAlarm,
        rcOutOfPriceAlarmError,
        refetchRcOutOfPriceAlarm: refetch,
        isFetching,
        isLoading,
        dataUpdatedAt,
    };
};

/* sample whitelist reposnse
[
  {
    "serverUno": 34,
    "serverName": "MT5INF",
    "login": 974
  },
  {
    "serverUno": 98,
    "serverName": "UK10",
    "login": 741853
  }
]

*/

interface RCOutOfPriceAlarmWhitelist {
    serverUno: number;
    serverName: string;
    login: number;
}

export const useRCOutOfPriceAlarmWhitelist = () => {
    const { data, refetch, isLoading, error, isFetching } = useQuery({
        queryKey: ["rcOutOfPriceAlarmWhitelist"],
        queryFn: () =>
            plainAxiosInstance.get(`${APIs.RC_OUT_OF_PRICE_ALARM.GET_SETTINGS}`, {
                headers: {
                    Accept: "application/json",
                },
            }),
        staleTime: 60 * 1000 * 5, //(60 x 1000ms x 5 = 5 minutes)
    });

    const rcOutOfPriceAlarmWhitelist: RCOutOfPriceAlarmWhitelist[] | null = data?.data?.status ? null : data?.data || null;
    // @ts-ignore
    const rcOutOfPriceAlarmWhitelistError: string | null = data?.data?.message || error?.message || error || null;

    return {
        rcOutOfPriceAlarmWhitelist,
        rcOutOfPriceAlarmWhitelistError,
        refetchRcOutOfPriceAlarmWhitelist: refetch,
        isFetching,
        isLoading,
    };
};

export const useRCOutOfPriceAlarmMutation = () => {
    const queryClient = useQueryClient();

    // upload whitelist excel

    const {
        mutateAsync: uploadWhitelist,
        isLoading: isLoadingUploadWhitelist,
        error: uploadWhitelistError,
    } = useMutation(
        async ({ formData }: { formData: FormData }): Promise<any> =>
            plainAxiosInstance.post(APIs.RC_OUT_OF_PRICE_ALARM.POST_UPLOAD_SETTINGS, formData),
        {
            onSuccess: res => {
                queryClient.invalidateQueries(["rcOutOfPriceAlarmWhitelist"]);
                return res;
            },
            onError: error => {
                queryClient.invalidateQueries(["rcOutOfPriceAlarmWhitelist"]);
                console.error(`Error on uploading whitelist:`, error);
                return error;
            },
        }
    );

    // solve alarm

    const {
        mutateAsync: solveAlarm,
        isLoading: isLoadingSolveAlarm,
        error: solveAlarmError,
    } = useMutation(
        async ({ data }: { data: IRCOutOfPriceAlarm }): Promise<any> => {
            const requestStr = `${data.serverUno},${data.login},${data.dealId},${data.type}`;
            // const requestStr =  data.serverUno + "," + data["login"] + "," + data["dealId"] + "," + data["type"];
            const formData = new FormData();
            formData.append("keyStr", requestStr);
            return plainAxiosInstance.post(`${APIs.RC_OUT_OF_PRICE_ALARM.POST_SOLVED}`, formData);
        },
        {
            onSuccess: res => {
                queryClient.invalidateQueries(["rcOutOfPriceAlarm", "alarm"]);
                // console.log("res", res);
                return res;
            },
            onError: error => {
                queryClient.invalidateQueries(["rcOutOfPriceAlarm", "alarm"]);
                console.error(`Error on solving alarm:`, error);
                return error;
            },
        }
    );

    return {
        uploadWhitelist,
        isLoadingUploadWhitelist,
        uploadWhitelistError,

        solveAlarm,
        isLoadingSolveAlarm,
        solveAlarmError,
    };
};

export default useRCOutOfPriceAlarm;
