import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const antIcon = <LoadingOutlined style={{ fontSize: "1.5rem" }} spin />;
const antIconSmall = <LoadingOutlined style={{ fontSize: "1rem" }} spin />;

const LoadingComponent = (props: any) => {
    return (
        <Spin indicator={props.size ? (props.size === "small" ? antIconSmall : antIcon) : antIcon} {...props}>
            {props.children}
        </Spin>
    );
};

export default LoadingComponent;
