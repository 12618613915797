import { useQuery } from "@tanstack/react-query";
import { APIs } from "../services/apis";
import { plainAxiosInstance } from "../services/axiosSetup";
import { massageData } from "./../pages/SystemMonitor/StatusMonitor/statusUtils";
const useRCStatusMonitor = ({ department }: { department: string }) => {
    //convert request to form data
    // const token = getToken();
    const { data, refetch, isLoading, error, dataUpdatedAt } = useQuery({
        queryKey: ["rcStatusMonitor", department],
        queryFn: () =>
            plainAxiosInstance.get(`${APIs.RC_SYSTEM_MONITOR.GET_STATUS_MONITOR}/${department}`, {
                headers: {
                    Accept: "application/json",
                },
            }),
        // axios.get(`${process.env.REACT_APP_API_URL}${APIs.RC_SYSTEM_MONITOR.GET_STATUS_MONITOR}/${department}`, {
        //     headers: {
        //         Accept: "application/json",
        //         // "Content-type": "multipart/form-data",
        //         Authorization: `Bearer ${token}`,
        //     },
        // }),
        staleTime: 10 * 1000, //(60 x 1000ms = 1minute)
        refetchInterval: 10 * 1000, //(60 x 1000ms = 1minute)
    });

    const rcStatusMonitor = data?.data?.status ? [] : data?.data || [];
    const massagedData = rcStatusMonitor ? massageData(rcStatusMonitor, department) : [];
    // console.log(massagedData, department);
    const totalErrorCount = massagedData?.reduce((acc: number, item: any) => acc + item.errorCount, 0) ?? 0;
    const totalWarningCount = massagedData?.reduce((acc: number, item: any) => acc + item.warningCount, 0) ?? 0;

    // @ts-ignore
    const rcStatusMonitorError = data?.data?.message || error?.message || error || null;
    return {
        rcStatusMonitor,
        rcStatusMonitorTotalErrorCount: totalErrorCount,
        rcStatusMonitorTotalWarningCount: totalWarningCount,
        rcStatusMonitorError,
        refetchRcStatusMonitor: refetch,
        dataUpdatedAt,
        isLoading,
    };
};

export default useRCStatusMonitor;
