import { FormComponent } from "@/components/FormComponent";
import { ComponentType } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { Form, Modal, Row, Col } from "antd";
import { useEffect } from "react";

export interface CADividendMapUploadModalProps {
    isModalVisible: boolean;
    callback: (type: number, data: any) => void;
}

export enum CADividendMapUploadModalCallbackKey {
    Close = 0,
    FormSubmit = 1,
}

const CADividendMapUploadModal = (props: CADividendMapUploadModalProps) => {
    const [executeForm] = Form.useForm();

    useEffect(() => {
        if (props.isModalVisible) {
            executeForm.setFieldsValue({ uploadDate: undefined });
        }
    }, [props.isModalVisible]);

    return (
        <Modal
            style={{ top: 10 }}
            width={"20vw"}
            title="Upload CA Dividend Map File"
            open={props.isModalVisible}
            okText="Execute"
            onOk={() => {
                executeForm
                    .validateFields()
                    .then((values: any) => {
                        values["uploadDate"] = values["uploadDate"].format("YYYY-MM-DD");
                        props.callback(CADividendMapUploadModalCallbackKey.FormSubmit, values);
                    })
                    .catch((err: any) => console.log(err));
            }}
            onCancel={() => props.callback(CADividendMapUploadModalCallbackKey.Close, null)}
            maskClosable={false}
        >
            <Form form={executeForm} layout="vertical">
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Upload Date"
                            name="uploadDate"
                            extra={{
                                type: ComponentType.date,
                                value: "",
                                dateFormat: "YYYY-MM-DD",
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                            }}
                        />
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default CADividendMapUploadModal;
