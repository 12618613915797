import React from "react";
import useRCStatusMonitor from "../../../hooks/useRCStatusMonitor";
import { massageData } from "./statusUtils";
import BasicFilter from "../../../components/BasicFilter";
import ServerStatus, { ServerStatusProps } from "./ServerStatus";
import ServerStatusFilterWeekend from "./ServerStatusFilterWeekend";
import TabContent from "../components/TabContent";
import ServerStatusVendor from "./ServerStatusVendor";
import useRCStatusMonitorStore from "../../../store/useRCStatusMonitorStore";
import ExcludedButton from "./ExcludedButton";
// import soundAdmin from "../../../assets/audios/rc/status_monitor_admin.mp3"; // AI Generated sound
import soundAdmin from "../../../assets/audios/rc/fireTruck.mp3"; // RC original sound - Admin Error
import soundAdminWarning from "../../../assets/audios/rc/marioCoin.mp3"; // RC original sound - Admin Warning
import SoundButton from "../components/SoundButton";
import Loader from "../components/Loader";
import MessageCard from "../components/MessageCard";
const TabAdmin = () => {
    const { rcStatusMonitor, isLoading } = useRCStatusMonitor({ department: "Admin" });
    // const dataAdmin = rcStatusMonitor ? massageData(rcStatusMonitor, "Admin") : [];
    const dataAdmin = rcStatusMonitor ? massageData(rcStatusMonitor, "Admin") : [];

    const vendor = useRCStatusMonitorStore(state => state.vendor.admin);
    const filterDataAdmin = vendor ? dataAdmin?.filter((item: any) => item.vendor === vendor) : [];
    const totalErrorCount = dataAdmin?.reduce((acc: number, item: any) => acc + item.errorCount, 0) ?? 0;
    const totalWarningCount = dataAdmin?.reduce((acc: number, item: any) => acc + item.warningCount, 0) ?? 0;

    return (
        <TabContent>
            <BasicFilter
                show={false}
                titleBarChildren={
                    <>
                        <SoundButton soundSrc={soundAdmin} isActive={totalErrorCount >= 1 || totalWarningCount >= 1} />
                        <ExcludedButton />
                    </>
                }
            >
                <ServerStatusFilterWeekend />
            </BasicFilter>

            {isLoading && rcStatusMonitor.length === 0 ? (
                <Loader />
            ) : (
                <>
                    <ServerStatusVendor data={dataAdmin as ServerStatusProps[]} vendorType="admin" />
                    {!vendor && (
                        <div style={{ padding: "0 1rem" }}>
                            <MessageCard type="info">Please select a group from above</MessageCard>
                        </div>
                    )}
                    {filterDataAdmin?.map((item: any, index: number) => (
                        <ServerStatus key={index} data={item as ServerStatusProps} />
                    ))}
                </>
            )}
        </TabContent>
    );
};

export default TabAdmin;
