import { FieldTimeOutlined, UserAddOutlined, ExportOutlined, CommentOutlined, UserOutlined } from "@ant-design/icons";
import { ALARM_BREACH_SEVERITY, ALARM_DASHBOARD_ITEM_CALLBACK_KEY } from "../../../../constants";
import { Button } from "antd";
import { getStandardMarkupDisplayText, isEmptyOrNull, isNumeric } from "../../../../utils/string";
import { DataTableColumnRender, currencyRender } from "../../../../utils/Common";
import { DefaultIfEmpty, getAvailableObjectElement } from "../../../../utils/object";
import { markupText } from "../../../IntradayMonitor/Alarm/List";
import { DateTimeUtil } from "../../../../utils/datetime";
import moment from "moment";
import Tooltip from "antd/es/tooltip";

export interface AlarmDashboardTimelineItemProps {
    data: TimelineItemProps;
    callback: (type: ALARM_DASHBOARD_ITEM_CALLBACK_KEY, data: any) => void;
    utcOffset: number | undefined;
    mappingData: any;
    lpMappingData: any;
    editable: boolean;
}

export interface TimelineItemProps {
    alarmDescription: string;
    alarmEventId: string;
    alarmName: string;
    alertMessage: string;
    assignedDateUtc: string | null;
    assignees: AssigneeProps[] | null;
    breachSeverity: number;
    breachSeverityDesc: string;
    clientApp: string;
    correlationHashKey: null | string;
    createdDateUtc: string;
    eventMappingHashKey: string;
    eventStatusCode: string;
    eventStatusDescription: string;
    eventStatusUID: string;
    moduleCode: string;
    moduleName: string;
    moduleUrl: string | null;
    targetDueDateUtc: string | null;
    stackCount?: number;
}

export interface AssigneeProps {
    name: string;
    userId: number;
}

const AlarmDashboardTimelineItem = (props: AlarmDashboardTimelineItemProps) => {
    const markupNumberText = (key: string, value: any) => {
        if (["accountId", "account_id"].includes(key)) return value;

        return isNumeric(value) ? currencyRender(value) : value;
    };

    const getRemarksTemplate = (data: TimelineItemProps, mappingData: any, lpMappingData: any): React.ReactNode => {
        try {
            switch (data.moduleCode) {
                case "MIM": {
                    let dataObj: any = isEmptyOrNull(data.alertMessage) ? {} : JSON.parse(data.alertMessage),
                        allKeys = Object.keys(dataObj),
                        dimensionKeys = Object.keys(mappingData.dimension),
                        dimensionKeysArr = allKeys.filter(x => dimensionKeys.includes(x)),
                        metricKeysArr = allKeys.filter(x => !dimensionKeys.includes(x));
                    return (
                        <div className="remarks">
                            <div className={`template-${data.moduleCode}`}>
                                <div className="left event-info-container">
                                    {dimensionKeysArr.length > 0 &&
                                        dimensionKeysArr.map(x => (
                                            <div key={`${x}-${dataObj[x]}`} className="item">
                                                <span>{`${DefaultIfEmpty(mappingData.dimension, x, { text: "" }).text}`}</span>
                                                <span>
                                                    {["account_id", "group"].includes(x)
                                                        ? dataObj[x]
                                                        : DefaultIfEmpty(
                                                              DefaultIfEmpty(mappingData, x, { [x]: {} }),
                                                              x === "symbol" ? dataObj[x].toLowerCase() : dataObj[x],
                                                              { text: dataObj[x] }
                                                          ).text}
                                                </span>
                                            </div>
                                        ))}
                                </div>
                                <div className="right event-info-container">
                                    {metricKeysArr.length > 0 &&
                                        metricKeysArr.map(x => (
                                            <div key={`${x}-${dataObj[x]}`} className="item">
                                                <span>{`${DefaultIfEmpty(mappingData.metricName, x, { text: "" }).text}`}</span>
                                                <span>{markupText({}, "filterValue", dataObj[x])}</span>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                    );
                }
                case "MR": {
                    let dataObj: any = getAvailableObjectElement(isEmptyOrNull(data.alertMessage) ? {} : JSON.parse(data.alertMessage)),
                        allKeys = Object.keys(dataObj),
                        dimensionKeys = Object.keys(lpMappingData.dimension),
                        dimensionKeysArr = allKeys.filter(x => dimensionKeys.includes(x.toLowerCase())),
                        metricKeysArr = allKeys.filter(x => !dimensionKeys.includes(x.toLowerCase()));
                    return (
                        <div className="remarks">
                            <div className={`template-${data.moduleCode}`}>
                                <div className="left event-info-container">
                                    {dimensionKeysArr.length > 0 &&
                                        dimensionKeysArr.map(x => {
                                            let lowercaseKey = x.toLowerCase();
                                            if (lowercaseKey === "company") return <></>;

                                            let propsText = DefaultIfEmpty(lpMappingData.dimension, lowercaseKey, { text: "" }).text,
                                                valueText = Array.isArray(dataObj[x])
                                                    ? dataObj[x].length === 1
                                                        ? dataObj[x]
                                                        : `${dataObj[x].join(", ")}`
                                                    : dataObj[x];

                                            return (
                                                <div key={`${x}-${dataObj[x]}`} className="item">
                                                    <span>{propsText}</span>
                                                    <span>{valueText}</span>
                                                </div>
                                            );
                                        })}
                                </div>
                                <div className="right event-info-container">
                                    {metricKeysArr.length > 0 &&
                                        metricKeysArr.map(x => {
                                            let lowercaseKey = x.toLowerCase(),
                                                propsText = DefaultIfEmpty(lpMappingData.metricName, lowercaseKey, { text: x }).text,
                                                valueText = Array.isArray(dataObj[x])
                                                    ? `[ ${dataObj[x].map((x: any) => getStandardMarkupDisplayText(x)).join(", ")} ]`
                                                    : getStandardMarkupDisplayText(dataObj[x], { decimalPoint: 2 });
                                            return (
                                                <div key={`${x}-${dataObj[x]}`} className="item">
                                                    <span>{propsText}</span>
                                                    <span>{valueText}</span>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>
                    );
                }
                default: {
                    let isObject = JSON.parse(data.alertMessage);
                    if (Array.isArray(isObject)) {
                        return (
                            <div className="remarks">
                                {isObject.map((x: any, idx: number) => {
                                    let objectKeys = Object.keys(x);
                                    return (
                                        <div key={`${data.alarmEventId}-rmk-${idx}`}>
                                            {objectKeys.reduce((displayText: string, key: string, index: number) => {
                                                return displayText.concat(
                                                    `${key}: ${markupNumberText(key, x[key])}${index < objectKeys.length - 1 ? ", " : ""}`
                                                );
                                            }, "")}
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    } else if (typeof isObject === "object") {
                        let objectKeys = Object.keys(isObject);
                        return (
                            <div className="remarks">
                                {objectKeys.reduce((displayText: string, key: string, index: number) => {
                                    return displayText.concat(
                                        `${key}: ${markupNumberText(key, isObject[key])}${index < objectKeys.length - 1 ? ", " : ""}`
                                    );
                                }, "")}
                            </div>
                        );
                    }
                }
            }
        } catch (error) {
            return <div className="remarks">{data.alertMessage}</div>;
        }

        return <div className="remarks">{data.alertMessage}</div>;
    };

    const countOverdueDateTime = (dueDate: string) => {
        let currentDate = moment().toJSON(),
            diffSeconds = Math.floor(DateTimeUtil.getDiffBetween2Date(currentDate, dueDate, 2) as number);
        if (diffSeconds < 0) {
            return (
                <span className="overdue-container error">{`(Overdue${DateTimeUtil.convertSecondToReadableTimeDesc(Math.abs(diffSeconds))})`}</span>
            );
        }

        return <span className="overdue-container">{`(Due date: ${DataTableColumnRender.DateTime(dueDate)})`}</span>;
    };

    return (
        <div className={`event-timelineitem priority-color-${props.data.breachSeverity}-border-left`}>
            <div className="title">
                <div className="left">
                    <span className={`priority-color-${props.data.breachSeverity}-tag`}>{ALARM_BREACH_SEVERITY[props.data.breachSeverity]}</span>
                    <span className="title-label">{props.data.alarmName}</span>
                    {!isEmptyOrNull(props.data.moduleUrl) && (
                        <Tooltip title={`Redirect to:\n${props.data.moduleUrl}`} placement="right">
                            <Button
                                type="text"
                                icon={<ExportOutlined />}
                                onClick={() => {
                                    const newTab = window.open(props.data.moduleUrl as string, "_blank");
                                    newTab?.focus();
                                }}
                            />
                        </Tooltip>
                    )}
                </div>
                <div className="right">
                    {/* <Button
                        type="text"
                        icon={<CommentOutlined />}
                        onClick={() => props.callback && props.callback(ALARM_DASHBOARD_ITEM_CALLBACK_KEY.COMMENT_HISTORICAL, props.data)}
                    />
                    {!isEmptyOrNull(props.data.correlationHashKey) && (
                        <Button
                            type="text"
                            icon={
                                // props.data.stackCount !== undefined && props.data.stackCount > 1 ? (
                                //     <Badge count={props.data.stackCount}>
                                //         <ProfileOutlined />
                                //     </Badge>
                                // ) : (
                                <ProfileOutlined />
                                //)
                            }
                            onClick={() => props.callback && props.callback(ALARM_DASHBOARD_ITEM_CALLBACK_KEY.SEVERITY_PROGRESS, props.data)}
                        />
                    )} */}
                    <Tooltip title={`Comments${!isEmptyOrNull(props.data.correlationHashKey) && " and Breach Severity Progress"}`} placement="left">
                        <Button
                            type="text"
                            icon={<CommentOutlined />}
                            onClick={() => props.callback && props.callback(ALARM_DASHBOARD_ITEM_CALLBACK_KEY.SEVERITY_PROGRESS, props.data)}
                        />
                    </Tooltip>
                </div>
            </div>
            <div className="details">
                <div className="sub-info">
                    <div className="left">
                        <span className="module-container">{`App: (${props.data.clientApp}) ${props.data.moduleName}`}</span>
                        {/* {!isEmptyOrNull(props.data.assignees) && (
                            <span className="assignee-container">{`Assignee: ${DefaultIfEmpty(
                                (props.data.assignees as AssigneeProps[])[0],
                                "name",
                                ""
                            )}`}</span>
                        )} */}
                    </div>
                    <div className="right">
                        <Tooltip title={"Task Assigning"} placement="left">
                            {!isEmptyOrNull(props.data.assignees) ? (
                                <span
                                    {...(props.editable
                                        ? {
                                              className: "assign-tag assigned",
                                              onClick: () =>
                                                  props.callback && props.callback(ALARM_DASHBOARD_ITEM_CALLBACK_KEY.TASK_ASSIGN, props.data),
                                          }
                                        : {
                                              className: "assign-tag unauth assigned",
                                          })}
                                >
                                    <UserOutlined />
                                    {DefaultIfEmpty((props.data.assignees as AssigneeProps[])[0], "name", "")}
                                </span>
                            ) : (
                                <span
                                    {...(props.editable
                                        ? {
                                              className: "assign-tag unassign",
                                              onClick: () =>
                                                  props.callback && props.callback(ALARM_DASHBOARD_ITEM_CALLBACK_KEY.TASK_ASSIGN, props.data),
                                          }
                                        : {
                                              className: "assign-tag unauth unassign",
                                          })}
                                >
                                    {props.editable && <UserAddOutlined />}
                                    Unassigned
                                </span>
                            )}
                        </Tooltip>
                    </div>
                </div>
                {getRemarksTemplate(props.data, props.mappingData, props.lpMappingData)}
            </div>
            <div className="footer-container">
                <div className="left">
                    <FieldTimeOutlined />
                    <span className="date-container">
                        {DataTableColumnRender.DateTime_UTC_TO_ServerTime(props.data.createdDateUtc, props.utcOffset)}
                    </span>
                    {!isEmptyOrNull(props.data.targetDueDateUtc) && countOverdueDateTime(props.data.targetDueDateUtc as string)}
                </div>
                <div className="right">
                    {/* {props.editable && (
                        <div className="btn-container shadow-light">
                            <Button
                                type="text"
                                icon={<UserAddOutlined />}
                                onClick={() => props.callback && props.callback(ALARM_DASHBOARD_ITEM_CALLBACK_KEY.TASK_ASSIGN, props.data)}
                            />
                            <Button
                                type="text"
                                icon={<MessageOutlined />}
                                onClick={() => props.callback && props.callback(ALARM_DASHBOARD_ITEM_CALLBACK_KEY.LEAVE_COMMENT, props.data)}
                            />

                        </div>
                    )} */}
                </div>
            </div>
        </div>
    );
};

export default AlarmDashboardTimelineItem;
