import { LineChartOutlined } from "@ant-design/icons";
import { Datum } from "@ant-design/charts";
import { Empty, Form, message } from "antd";
import moment from "moment";
import { Column } from "@ant-design/plots";
import { useEffect, useState } from "react";
import { FormComponent } from "../../../../components/FormComponent";
import LoadingComponent from "../../../../components/Loading";
import { apiRequest } from "../../../../services/apiConfig";
import { APIs } from "../../../../services/apis";
import ChartPanel from "./chartPanel";
import { currencyRender, ErrorCatchValidator, ErrorMessageHandler } from "../../../../utils/Common";
import { ProfileInfoProps } from "../viewProfile";
import { ComponentType, SUCCESS_FAILED } from "../../../../constants";
import { hasAnyKey } from "../../../../utils/object";
import EmptyData from "../../../../components/Common/Empty";
import CustomSkeleton from "../../../../components/Common/Skeleton";

export interface DailyPnlSummaryChartProps extends ProfileInfoProps {
    forPrint?: boolean;
}

interface FilterData {
    dateFrom?: string;
    dateTo?: string;
}

const DailyPnlSummaryChart = (props: DailyPnlSummaryChartProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [refreshData, setRefreshData] = useState<boolean>(false);
    const [data, setData] = useState<any[]>([]);
    const [filterData, setFilterData] = useState<FilterData>({
        dateFrom: moment.utc().subtract(3, "months").format("YYYY-MM-DD"),
        dateTo: moment.utc().format("YYYY-MM-DD"),
    });
    const [chartFilterForm] = Form.useForm();

    const getDataList = (dateFilter: any) => {
        setIsLoading(true);
        apiRequest(APIs.ACCOUNT_HISTORICAL_PNL, {
            accountId: props?.accountId,
            brandId: props?.brandId,
            serverId: props?.serverId,
            metricType: 1,
            timeFrame: 1,
            dataSamplingMode: 6,
            metricIds: [12],
            ...filterData,
            ...(dateFilter && dateFilter),
        })
            .then((data: any) => {
                if (data && data.length > 0) {
                    let markupData: any[] = [];
                    data.map((x: any) => {
                        markupData = markupData.concat(
                            x.plotData.map((y: any) => ({
                                name: "Daily PNL",
                                date: moment(y.date).format("YYYY-MM-DD"),
                                value: y.value,
                            }))
                        );

                        return false;
                    });
                    setData(markupData);
                } else {
                    setData([]);
                }
            })
            .catch((error) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("daily PNL data", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        setRefreshData(true);
        return () => {};
    }, [props.dateRangeFilter]);

    useEffect(() => {
        if (refreshData) {
            getDataList(props.dateRangeFilter);
            setRefreshData(false);
        }
        return () => {};
    }, [refreshData]);

    const onFilterValueCallback = (changedValues: any) => {
        if (changedValues.date && changedValues.date.length > 1) {
            setFilterData({
                dateFrom: moment.utc(changedValues.date[0]).format("YYYY-MM-DD"),
                dateTo: moment.utc(changedValues.date[1]).format("YYYY-MM-DD"),
            });
            setRefreshData(true);
        } else {
            message.warning("Please set a date range if you wish to continue.");
            setRefreshData(false);
        }
    };

    useEffect(() => {
        chartFilterForm.setFieldValue("date", [moment().subtract(3, "months"), moment()]);
    }, []);

    return (
        <>
            {isLoading ? (
                <ChartPanel
                    forPrint={props.forPrint}
                    type={1}
                    icon={<LineChartOutlined />}
                    title={"Daily PNL"}
                    subTitle={"Daily's profit & loss summary for current account"}
                >
                    <CustomSkeleton rows={10} />
                </ChartPanel>
            ) : (
                <ChartPanel
                    forPrint={props.forPrint}
                    type={1}
                    icon={<LineChartOutlined />}
                    title={"Daily PNL"}
                    subTitle={"Daily's profit & loss summary for current account"}
                    {...(!hasAnyKey(props.dateRangeFilter) && {
                        titleExtra: {
                            form: chartFilterForm,
                            content: (
                                <>
                                    <FormComponent
                                        label={""}
                                        name={"date"}
                                        extra={{
                                            type: ComponentType.daterange,
                                            value: "",
                                            inputProps: {
                                                disabledDate: (current: any) => current && current > moment().endOf("day"),
                                                ranges: {
                                                    Default: [moment().subtract(3, "months"), moment()],
                                                },
                                            },
                                            dateFormat: "YYYY-MM-DD",
                                        }}
                                    />
                                </>
                            ),
                            onValueChanged: onFilterValueCallback,
                        },
                    })}
                >
                    <div className="chart">
                        {data?.length > 0 ? (
                            <Column
                                {...{
                                    data,
                                    padding: "auto",
                                    xField: "date",
                                    yField: "value",
                                    ...(props.forPrint
                                        ? {
                                              xAxis: {
                                                  label: {
                                                      autoRotate: true,
                                                  },
                                                  tickLine: {
                                                      alignTick: true,
                                                  },
                                              },
                                          }
                                        : {
                                              xAxis: {
                                                  label: {
                                                      autoRotate: true,
                                                  },
                                                  tickLine: {
                                                      alignTick: true,
                                                  },
                                              },
                                              slider: {
                                                  start: 0,
                                                  end: 1,
                                              },
                                          }),
                                    yAxis: {
                                        label: {
                                            formatter: (v: any) => currencyRender(v),
                                        },
                                    },
                                    tooltip: {
                                        formatter: (datum: Datum) => {
                                            return {
                                                name: "Daily PNL",
                                                value: datum.value === 0 ? 0 : currencyRender(datum.value, 2),
                                            };
                                        },
                                    },
                                    color: ({ date }: any) => {
                                        let x = data.find((i) => i.date === date);
                                        if (x.value < 0) {
                                            return "#F4664A";
                                        } else return "#63d8a8";
                                    },
                                }}
                            />
                        ) : (
                            <>
                                <EmptyData />
                            </>
                        )}
                    </div>
                </ChartPanel>
            )}
        </>
    );
};

export default DailyPnlSummaryChart;
