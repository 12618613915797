import { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MDEditor from "@uiw/react-md-editor";
import ReportingResultData from "../../../../assets/data/reportingResultData.json";
import ReportingTemplateData from "../../../../assets/data/reportingTemplateData.json";
import RoleAndPermissionData from "../../../../assets/data/roleAndPermissionData.json";
import UserManagementData from "../../../../assets/data/userManagementData.json";
import AppConfigurationData from "../../../../assets/data/appConfigurationData.json";
import AccountListData from "../../../../assets/data/accountListData.json";
import AccountWatchListData from "../../../../assets/data/accountWatchListData.json";
import RiskScoreConfigurationListData from "../../../../assets/data/riskScoreConfigurationListData.json";
import ClientListData from "../../../../assets/data/clientListData.json";
import ServerData from "../../../../assets/data/serverData.json";
import BrandData from "../../../../assets/data/brandData.json";
import ServerBrandMappingData from "../../../../assets/data/serverBrandMappingData.json";
import GroupPurposeData from "../../../../assets/data/groupPurposeData.json";
import TagManagementData from "../../../../assets/data/tagManagementData.json";
import HubSwitcherData from "../../../../assets/data/hubSwitcherData.json";
import ToxicClientCheckData from "../../../../assets/data/toxicClientCheckData.json";
import { Empty } from "antd";
import { replaceZeroToDomainName } from "../../../../utils/array";
const md2json = require("md-2-json");

interface viewUserManualProps {
    modulePath?: string;
}

const ViewUserManual = (props: viewUserManualProps) => {
    let navigate = useNavigate();
    let location = useLocation();

    const [initialData, setInitialData] = useState<any>();

    useEffect(() => {
        return getInitialData(props.modulePath ? props.modulePath : location.pathname);
    }, [props.modulePath, location.pathname, initialData]);

    const massageData = (jsonData: any) => {
        const content = replaceZeroToDomainName(jsonData, "{0}", process.env.REACT_APP_AWS_S3_IMG_GALLERY || "");
        setInitialData(md2json.toMd(content));
    };

    const getInitialData = (pathname: string) => {
        switch (pathname) {
            case "/report/result":
                massageData(ReportingResultData.content);
                break;
            case "/report/template":
                massageData(ReportingTemplateData.content);
                break;
            case "/siteadmin/rolepermission":
                massageData(RoleAndPermissionData.content);
                break;
            case "/siteadmin/user":
                massageData(UserManagementData.content);
                break;
            case "/siteadmin/appconfig":
                massageData(AppConfigurationData.content);
                break;
            case "/analysis/accountprofile":
                massageData(AccountListData.content);
                break;
            case "/analysis/watchlist":
                massageData(AccountWatchListData.content);
                break;
            case "/siteadmin/riskscore/account":
                massageData(RiskScoreConfigurationListData.content);
                break;
            case "/analysis/clientlist":
                massageData(ClientListData.content);
                break;
            case "/siteadmin/serverbrand/server":
                massageData(ServerData.content);
                break;
            case "/siteadmin/serverbrand/brand":
                massageData(BrandData.content);
                break;
            case "/siteadmin/serverbrand/server-brand-mapping":
                massageData(ServerBrandMappingData.content);
                break;
            case "/siteadmin/serverbrand/group-purpose":
                massageData(GroupPurposeData.content);
                break;
            case "/siteadmin/tag":
                massageData(TagManagementData.content);
                break;
            case "/admintools/hubSwitcher":
                massageData(HubSwitcherData.content);
                break;
            case "/analysis/toxicClientCheck":
                massageData(ToxicClientCheckData.content);
                break;
            default:
                setInitialData("");
                break;
        }
    };
    return <div data-color-mode="light" className="user-manual-md-container">{initialData === "" ? <Empty /> : <MDEditor.Markdown source={initialData} />}</div>;
};

export default ViewUserManual;
