import { Button, Modal, Space, Table, Tag, notification } from "antd";
import React, { useState } from "react";
import useRCIssuesLog, { useRCIssuesLogMutate, useRCIssuesLogNoMoneyMail } from "../../../../hooks/useRCIssuesLog";
import MessageCard from "../../components/MessageCard";
import { colorMap, thousandSeparator, toFixed } from "../../StatusMonitor/statusUtils";
import BasicFilter from "../../../../components/BasicFilter";
import NoMoneyMailTableFilter from "./NoMoneyMailTableFilter";
import useRCIssuesLogStore from "../../../../store/useRCIssuesLogStore";
import { dummyNoMoneyMail } from "./dummyData";

const columns = [
    {
        title: "Server",
        dataIndex: "server",
        sorter: (a: any, b: any) => {
            // sort alphabetically and length
            if (a.server?.toLowerCase() < b.server?.toLowerCase()) {
                return -1;
            }
            if (a.server?.toLowerCase() > b.server?.toLowerCase()) {
                return 1;
            }
            return 0;
        },
    },
    {
        title: "Login",
        dataIndex: "login",
        sorter: (a: any, b: any) => a.login - b.login,
        render: (text: any, data: any) => <div style={{ textAlign: "right" }}>{text}</div>,
    },
    {
        title: "Balance",
        dataIndex: "balance",
        sorter: (a: any, b: any) => a.balance - b.balance,
        render: (text: any, data: any) => <div style={{ textAlign: "right" }}>{toFixed(thousandSeparator(text), 2)}</div>,
    },
    {
        title: "Count",
        dataIndex: "alarmCount",
        sorter: (a: any, b: any) => a.alarmCount - b.alarmCount,
        render: (text: any, data: any) => <div style={{ textAlign: "right" }}>{thousandSeparator(text)}</div>,
    },
    {
        title: "State",
        dataIndex: "state",
        sorter: (a: any, b: any) => a.state - b.state,
        render: (text: any, data: any) => (
            <Tag color={text === 1 ? colorMap.info : text === 2 ? colorMap.warning : colorMap.error}>
                {text === 1 ? "Minor" : text === 2 ? "Warning" : "Serious"}
            </Tag>
        ),
    },
    {
        title: "Count Down(Min)",
        dataIndex: "diffMinutes",
        sorter: (a: any, b: any) => a.diffMinutes - b.diffMinutes,
        render: (text: any, data: any) => <div style={{ textAlign: "right" }}>{thousandSeparator(text)}</div>,
    },
    {
        title: "Mail Type",
        dataIndex: "sentMailType",
        render: (text: any, data: any) => (text === 0 ? <Tag color="lime">Alarm</Tag> : <Tag color="red">Changed PWD</Tag>),
        sorter: (a: any, b: any) => a.sentMailType - b.sentMailType,
    },
    {
        title: "Create Time",
        dataIndex: "createTime",
        sorter: (a: any, b: any) => {
            // sort alphabetically and length
            if (a.createTime?.toLowerCase() < b.createTime?.toLowerCase()) {
                return -1;
            }
            if (a.createTime?.toLowerCase() > b.createTime?.toLowerCase()) {
                return 1;
            }
            return 0;
        },
    },
];

const NoMoneyMailTable = () => {
    const noMoneyFilter = useRCIssuesLogStore(state => state.noMoney.mailFilter);
    const updateNoMoneyMailFilter = useRCIssuesLogStore(state => state.updateNoMoneyMailFilter);
    const dummy = dummyNoMoneyMail;
    const { rcIssuesLogNoMoneyMail, isLoading } = useRCIssuesLogNoMoneyMail(noMoneyFilter);
    const handleFilterChange = (newFilter: Partial<typeof noMoneyFilter>) => {
        updateNoMoneyMailFilter(newFilter);
    };

    // console.log(rcIssuesLog);

    // massage rcIssuesLog with unique key
    const massagedData =
        rcIssuesLogNoMoneyMail?.resultList?.length >= 1
            ? rcIssuesLogNoMoneyMail?.resultList?.map((item: any, index: number) => {
                  return {
                      ...item,
                      key: `${item.server}_${item.login}_${item.createTime}_${item.uniqueKey}`,
                  };
              })
            : [];

    // const massagedData =
    //     dummy?.resultList?.length >= 1
    //         ? dummy?.resultList?.map((item: any, index: number) => {
    //               return {
    //                   ...item,
    //                   key: `${item.server}_${item.login}_${item.createTime}_${item.uniqueKey}`,
    //               };
    //           })
    //         : [];

    const total = rcIssuesLogNoMoneyMail?.total ?? 0;
    // totalMinor, reduce and sum up all the state === 1
    // const totalMinor = rcIssuesLogNoMoneyMail?.reduce((acc: any, cur: any) => {
    //     return acc + (cur.state === 1 ? 1 : 0);
    // }, 0);
    // const totalWarning = rcIssuesLogNoMoneyMail?.reduce((acc: any, cur: any) => {
    //     return acc + (cur.state === 2 ? 1 : 0);
    // }, 0);
    // const totalSerious = rcIssuesLogNoMoneyMail?.reduce((acc: any, cur: any) => {
    //     return acc + (cur.state === 3 ? 1 : 0);
    // }, 0);

    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            <NoMoneyMailTableFilter />
            <div style={{ padding: "1rem" }}>
                <Table
                    columns={columns}
                    dataSource={massagedData}
                    size="small"
                    loading={isLoading}
                    // able to adjust pagination size
                    pagination={{
                        pageSize: 10,
                        // pageSize: noMoneyFilter.rows, current: noMoneyFilter.page,
                        // showSizeChanger: true,
                        total: total,
                    }}
                    // handle pagination change
                    onChange={(pagination, filters, sorter, extra) => {
                        // console.log("params", pagination, filters, sorter, extra);
                        handleFilterChange({ page: pagination?.current, rows: pagination?.pageSize });
                    }}
                />
            </div>
        </Space>
    );
};

export default NoMoneyMailTable;
