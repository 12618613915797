import { Button, Tag } from "antd";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { FaRedo } from "react-icons/fa";
import { motion } from "framer-motion";

const TimeDiff = ({
    timestamp,
    styles,
    refetch,
    isFetching = false,
    rounded = false,
}: {
    timestamp: number;
    styles?: any;
    refetch?: () => void;
    isFetching?: boolean;
    rounded?: boolean;
}) => {
    const now = moment();
    //convert timestamp to date;
    const date = new Date(timestamp);
    const dateObj = moment(date);
    // console.log(dateObj);
    const [distanceToNow, setDistanceToNow] = useState<null | string>(null);
    const diff = now.diff(dateObj, "seconds");

    useEffect(() => {
        const interval = setInterval(() => {
            setDistanceToNow(diff + ` second${diff >= 2 ? "s" : ""} ago`);
        }, 1000);
        return () => clearInterval(interval);
    });
    if (!date || !distanceToNow)
        return (
            <Tag
                style={{
                    display: "inline-flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: rounded ? 20 : 0,
                    ...styles,
                }}
            >
                Loading...
            </Tag>
        );
    return (
        <Tag
            style={{
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: rounded ? 20 : 0,
                ...styles,
            }}
        >
            <span>{distanceToNow}</span>
            {refetch && (
                <Button
                    type="primary"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 4,
                        marginRight: -8,
                        padding: 0,
                        marginLeft: 4,
                    }}
                    onClick={() => {
                        refetch();
                    }}
                    size="small"
                    disabled={isFetching}
                    shape="circle"
                    icon={
                        <motion.div
                            animate={{
                                rotate: isFetching ? 180 : 0,
                            }}
                        >
                            <FaRedo style={{ fontSize: "0.55rem" }} />
                        </motion.div>
                    }
                />
            )}
        </Tag>
    );
};

export default TimeDiff;
