import React, { useRef, useState } from "react";
import { Button, Modal, Table, Tag, notification } from "antd";
import { FaClipboardCheck } from "react-icons/fa";
import useRCStatusMonitorExclude, { useRCStatusMonitorRemoveExclude } from "../../../hooks/useRCStatusMonitorExclude";

const ExcludedButton = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleModalOpen = (open: boolean) => {
        setIsModalOpen(open);
    };
    return (
        <>
            <Button onClick={() => handleModalOpen(true)}>
                <div style={{ display: "inline-flex", justifyContent: "center", alignItems: "center", gap: 8 }}>
                    <FaClipboardCheck />
                    <p style={{ padding: 0, margin: 0 }}>Manage hidden items</p>
                </div>
            </Button>
            {isModalOpen && <ExcludeModal open={isModalOpen} handleModalOpen={handleModalOpen} />}
        </>
    );
};

const columns = [
    {
        title: "Vendor",
        dataIndex: "vendor",
        filters: [
            {
                text: "RA",
                value: "RA@",
            },
            {
                text: "Admin",
                value: "Admin@",
            },
        ],
        render: (text: string) => {
            // if text doesn't have @, return text
            if (!text.includes("@")) {
                return text;
            }

            // Find the index of the first '@'
            const splitIndex = text.indexOf("@");

            // If '@' is found
            if (splitIndex !== -1) {
                // Extract the first part before '@'
                const firstPart = text.substring(0, splitIndex);
                // Extract the second part after '@'
                const secondPart = text.substring(splitIndex + 1);
                const color = firstPart === "RA" ? "green" : firstPart === "Admin" ? "blue" : "purple";
                return (
                    <span>
                        <Tag color={color} style={{ margin: 0 }}>
                            {firstPart}
                        </Tag>{" "}
                        {secondPart}
                    </span>
                );
            } else {
                // If '@' is not found after 'Weekend', return the text as is
                return text;
            }
        },
        // specify the condition of filtering result
        // here is that finding the name started with `value`
        onFilter: (value: any, record: any) => record.vendor.indexOf(value) === 0,
        // sorter: (a, b) => a.vendor.length - b.vendor.length,
        // sortDirections: ["descend"],
        sorter: (a: any, b: any) => {
            // sort alphabetically and length
            if (a.vendor?.toLowerCase() < b.vendor?.toLowerCase()) {
                return -1;
            }
            if (a.vendor?.toLowerCase() > b.vendor?.toLowerCase()) {
                return 1;
            }
            return 0;
            // return a.vendor.length - b.vendor.length
        },
    },
    {
        title: "Group ID",
        dataIndex: "groupId",
        sorter: (a: any, b: any) => {
            // sort alphabetically and length
            if (a.appName?.toLowerCase() < b.appName?.toLowerCase()) {
                return -1;
            }
            if (a.appName?.toLowerCase() > b.appName?.toLowerCase()) {
                return 1;
            }
            return 0;
            // return a.appName.length - b.appName.length
        },
    },
    {
        title: "App Name",
        dataIndex: "appName",
        // able to sort
        sorter: (a: any, b: any) => {
            // sort alphabetically and length
            if (a.appName?.toLowerCase() < b.appName?.toLowerCase()) {
                return -1;
            }
            if (a.appName?.toLowerCase() > b.appName?.toLowerCase()) {
                return 1;
            }
            return 0;
            // return a.appName.length - b.appName.length
        },
    },
];

const ExcludeModal = ({ open, handleModalOpen }: { open: boolean; handleModalOpen: (open: boolean) => void }) => {
    const { rcStatusMonitorExclude } = useRCStatusMonitorExclude();
    const checkedRow = useRef<any[]>([]);
    const tableRef = useRef<any>(null);
    // transform rcStatusMonitorExclude with adding key to each of them
    const transformedData = rcStatusMonitorExclude.map((item: any, index: number) => {
        return { ...item, key: index };
    });
    const [currStep, setCurrStep] = useState(0);

    const { removeExcludeStatus } = useRCStatusMonitorRemoveExclude();
    const handleOk = async () => {
        if (checkedRow.current.length === 0) {
            notification.warning({ message: "Please select at least one item" });
            return;
        }
        try {
            setCurrStep(1);
            const resp = await removeExcludeStatus(
                checkedRow.current.map((item: any) => ({
                    vendor: item.vendor,
                    groupId: item.groupId,
                    appName: item.appName,
                }))
            );

            if (resp) {
                notification.success({ message: `${checkedRow.current?.length} items enabled successfully` });
                checkedRow.current = [];
                setCurrStep(0);
            }
        } catch (error) {
            notification.error({ message: "Failed to enable selected items" });
            setCurrStep(0);
            console.error(error);
        }
    };

    return (
        <Modal
            width={"clamp(300px, 80%, 1000px)"}
            title="Excluded Items"
            open={open}
            okText={currStep === 0 ? "Enable selected" : "Processing..."}
            onOk={() => handleOk()}
            okButtonProps={{ loading: currStep === 1 }}
            cancelButtonProps={{ disabled: currStep === 1 }}
            onCancel={() => handleModalOpen(false)}
        >
            {currStep === 0 ? (
                <Table
                    ref={tableRef}
                    key={transformedData.length + "table"}
                    rowSelection={{
                        type: "checkbox",
                        onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
                            // console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows);
                            checkedRow.current = selectedRows;
                        },
                        getCheckboxProps: (record: any) => ({
                            disabled: record.vendor === "Disabled User", // Column configuration not to be checked
                            name: record.vendor,
                        }),
                    }}
                    columns={columns}
                    dataSource={transformedData}
                    size="small"
                />
            ) : currStep === 1 ? (
                <p>A moment please</p>
            ) : null}
        </Modal>
    );
};

export default ExcludedButton;
