import moment from "moment";
import FlexiDataTable from "../../../../../components/FlexiDataTable";
import { defaultIfEmptyOrNull, isEmptyOrNull } from "../../../../../utils/string";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "../../../../../constants";
import { FlexiDataTableOptionsProps, FlexiDataTableCallbackProps, GroupTransferHistoricalStatisticTradeProps } from "../../../../../constants/type";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../../../../utils/Common";
import { message } from "antd";
import { useState, useCallback, useEffect } from "react";
import { APIs } from "../../../../../services/apis";
import { plainAxiosInstance } from "../../../../../services/axiosSetup";
import { REQUIRED_FIELD } from "../../../../../constants/errorMessage";
import LoadingComponent from "../../../../../components/Loading";

export interface HistoricalMarketRiskProps {}

const HistoricalMarketRisk = (props: HistoricalMarketRiskProps) => {
    const [firstLoading, setFirstLoading] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [defaultDate, setDefaultDate] = useState<string>("");
    const [filterParams, setFilterParams] = useState<any>({});
    const [data, setData] = useState<GroupTransferHistoricalStatisticTradeProps[]>([]);

    const exportAsExcel = useCallback(() => {
        try {
            plainAxiosInstance
                .get(
                    `${plainAxiosInstance.defaults.baseURL}${APIs.RISK_TOOL.GET_GROUP_TRANSFER_TOOL_HISTORICAL_MARKET_RISK_DOWNLOAD}?startDate=${filterParams.startDate}&endDate=${filterParams.endDate}`,
                    {
                        headers: {
                            Accept: "application/octet-stream, */*",
                        },
                        responseType: "blob",
                    }
                )
                .then(response => {
                    const contentType = response.headers["content-type"];
                    if (
                        contentType === "application/octet-stream" ||
                        contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    ) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", `GroupTransferTradeBySymbol_${moment().format("YYYYMMDDHHmmss")}.xlsx`);
                        document.body.appendChild(link);
                        link.click();
                        // Clean up
                        window.URL.revokeObjectURL(url);
                    } else {
                        message.error(`Received non-file response. Error: ${response}`, 3);
                    }
                })
                .catch((error: any) => message.error(`Error occured during download: "${error.message}"`, 3));
        } catch (e: any) {
            message.error(`Error occured during download: "${e.message}"`, 3);
        }
    }, [filterParams]);

    const columns = [
        {
            title: "Date",
            dataIndex: "dateRange",
            key: "dateRange",
            options: {
                visible: false,
                filter: {
                    type: ComponentType.daterange,
                    value: [],
                    rules: [{ required: true, message: REQUIRED_FIELD }],
                    inputProps: {
                        dateOnly: true,
                        allowClear: false,
                    },
                },
            },
        },
        DTColProps.XSmall({
            title: "Server",
            dataIndex: "server",
            key: "server",
            fixed: "left",
        }),
        DTColProps.XSmall({
            title: "Login",
            dataIndex: "login",
            key: "login",
            fixed: "left",
        }),
        DTColProps.Middle({
            width: "10.5vw",
            title: "From Group",
            dataIndex: "fromGroup",
            key: "fromGroup",
            fixed: "left",
        }),
        DTColProps.Middle({
            width: "10.5vw",
            title: "To Group",
            dataIndex: "toGroup",
            key: "toGroup",
            fixed: "left",
        }),
        DTColProps.Small({
            title: "Symbol",
            dataIndex: "symbol",
            key: "symbol",
        }),
        DTColProps.XXSmall(
            {
                title: "CMD",
                dataIndex: "cmd",
                key: "cmd",
            },
            ["text-center"]
        ),
        {
            title: "Lots",
            dataIndex: "lots",
            key: "lots",
            className: `text-center`,
        },
        {
            title: "Contract Size",
            dataIndex: "contractSize",
            key: "contractSize",
            className: `text-center`,
        },

        {
            title: "Volume",
            dataIndex: "volume",
            key: "volume",
            className: `text-center`,
        },
        DTColProps.Small({
            title: "Operation Time",
            dataIndex: "operationTime",
            key: "operationTime",
            fixed: "right",
        }),
    ];

    const options: FlexiDataTableOptionsProps = {
        export: {
            text: "Download Report",
        },
    };

    const componentCallback: FlexiDataTableCallbackProps = (type: number, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                let filterParams: any = {};
                Object.keys(FormData)
                    .filter(x => FormData[x] && FormData[x].toString().length > 0)
                    .map(x => {
                        if (x === "dateRange") {
                            filterParams.startDate = moment(FormData[x][0]).format("YYYY-MM-DD");
                            filterParams.endDate = moment(FormData[x][1]).format("YYYY-MM-DD");
                        } else {
                            filterParams[x] = FormData[x];
                        }
                        return x;
                    });
                setFilterParams(filterParams);
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.EXPORT_CSV_EXCEL:
                exportAsExcel();
                break;
            default:
                break;
        }
    };

    const getRecordList = useCallback(() => {
        const formData = new FormData();
        formData.append("startDate", filterParams.startDate);
        formData.append("endDate", filterParams.endDate);

        plainAxiosInstance
            .post(APIs.RISK_TOOL.GET_GROUP_TRANSFER_TOOL_HISTORICAL_MARKET_RISK_LIST, formData)
            .then((res: any) => {
                if (res.data) {
                    setData(
                        res.data.map((x: GroupTransferHistoricalStatisticTradeProps) => ({
                            ...x,
                            keyId: `${x.server}-${x.login}-${x.symbol}-${defaultIfEmptyOrNull(x.order, "")}-${x.operationTime}`,
                        }))
                    );
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("market risk trade record", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsLoading(false));
    }, [filterParams]);

    const getDefaultDate = () => {
        plainAxiosInstance
            .get(APIs.RISK_TOOL.GET_GROUP_TRANSFER_TOOL_HISTORICAL_GETTODAYDEALDATE)
            .then((res: any) => {
                if (res.data) {
                    setDefaultDate(res.data);
                    setFilterParams({ startDate: res.data, endDate: res.data });
                    setRunRefetchDataList(true);
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("market risk trade record", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setFirstLoading(false));
    };

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            getRecordList();
            setRunRefetchDataList(false);
        }
        return () => {};
    }, [runRefetchDataList]);

    useEffect(() => {
        getDefaultDate();
    }, []);

    return (
        <div className="historical-market-risk-container">
            {firstLoading ? (
                <LoadingComponent />
            ) : (
                <FlexiDataTable
                    rowKeyProperty="keyId"
                    bordered
                    title={<span style={{ fontSize: "1rem", fontWeight: "bold", color: "#000000" }}>Transfer Trade By Symbol</span>}
                    columns={columns}
                    dataSource={data}
                    options={options}
                    callback={componentCallback}
                    loading={isLoading}
                    filterInitialValue={{
                        ...(!isEmptyOrNull(defaultDate) && {
                            dateRange: [moment(`${defaultDate}T00:00:00`), moment(`${defaultDate}T00:00:00`)],
                        }),
                    }}
                />
            )}
        </div>
    );
};

export default HistoricalMarketRisk;
