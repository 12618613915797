import { LOCAL_STORAGE_KEYS } from "../../constants";
import { APPSETTINGS, Module, ProfileProps } from "../../constants/type";
import { Location } from "react-router-dom";

export const setItem = (key: string, value: any) => {
    let actualValue = value;
    if (typeof value === "object") {
        actualValue = JSON.stringify(actualValue);
    }

    localStorage.setItem(key, actualValue);
};

export const getItem = (key: string, defaultValue: string | null = null): any => {
    let actualValue = localStorage.getItem(key);
    if (actualValue === null) return defaultValue;

    try {
        let jsonObj = JSON.parse(actualValue);
        return jsonObj;
    } catch {}

    return actualValue;
};

export const removeItem = (key: string) => localStorage.removeItem(key);

export const setToken = (token: string) => setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, token);

export const setRefreshToken = (token: string) => setItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, token);

export const removeToken = () => removeItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);

export const removeRefreshToken = () => removeItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);

export const getToken = () => getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);

export const getRefreshToken = () => getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);

export const setProfile = (profile: ProfileProps) => setItem(LOCAL_STORAGE_KEYS.PROFILE, btoa(JSON.stringify(profile)));

export const removeProfile = () => removeItem(LOCAL_STORAGE_KEYS.PROFILE);

export const getProfile = (): ProfileProps | undefined => {
    let up = getItem(LOCAL_STORAGE_KEYS.PROFILE);
    if (up !== null) {
        return JSON.parse(atob(up)) as ProfileProps;
    }
    return undefined;
};

export const setMenus = (modules: Module[]) => setItem(LOCAL_STORAGE_KEYS.MODULES, btoa(JSON.stringify(modules)));

export const removeMenus = () => removeItem(LOCAL_STORAGE_KEYS.MODULES);

export const getMenus = (): Module[] | undefined => {
    let up = getItem(LOCAL_STORAGE_KEYS.MODULES);
    if (up !== null) {
        return JSON.parse(atob(up)) as Module[];
    }
    return undefined;
};

export const setSource = (location: Location | string) => {
    if (typeof location === "string") {
        setItem(LOCAL_STORAGE_KEYS.SOURCE_KEY, location as string);
    } else {
        if (location.pathname.indexOf("/view") > -1) {
            setItem(LOCAL_STORAGE_KEYS.SOURCE_KEY, location.pathname.substring(1, location.pathname.indexOf("/view")));
        } else {
            setItem(LOCAL_STORAGE_KEYS.SOURCE_KEY, location.pathname.substring(1));
        }
    }
};

export const getSource = (location: Location | string) => {
    let sr = getItem(LOCAL_STORAGE_KEYS.SOURCE_KEY);
    if (sr === null && typeof location !== "string") {
        return (location as Location).pathname.substring(1, (location as Location).pathname.indexOf("/view"));
    }
    return sr;
};

export const removeSource = () => removeItem(LOCAL_STORAGE_KEYS.SOURCE_KEY);

export const setAppSettings = (item: APPSETTINGS) => setItem(LOCAL_STORAGE_KEYS.APP_SETTINGS, btoa(JSON.stringify(item)));

export const updateAppSettings = (item: any) =>
    setAppSettings({
        ...getAppSettings(),
        ...item,
    });

export const getAppSettings = (): APPSETTINGS => {
    let defaultValues: APPSETTINGS = {
        RELATIVE_TIME_RANGE: [],
        RELATIVE_DATE_RANGE: [],
        SYMBOL_TYPE: [],
        PRICE_OUTAGE_PAGE_CONFIG: {
            RefreshFullListInterval: -1,
            highlightSymbolColorLevel_1: "",
            highlightSymbolColorLevel_2: "",
            highlightSymbolColorLevel_3: "",
        },
    };
    let aps = getItem(LOCAL_STORAGE_KEYS.APP_SETTINGS);
    if (aps !== null) {
        try {
            return Object.assign(defaultValues, JSON.parse(atob(aps)));
        } catch (error) {
            console.log("atob decoding error.");
        }
    }
    return defaultValues;
};

export const removeAppSettings = () => removeItem(LOCAL_STORAGE_KEYS.APP_SETTINGS);

export const checkHubSwitchingAvailable = (hexValue: string) => {
    let hsHex = getItem(LOCAL_STORAGE_KEYS.HUB_SWITCHING);
    if (hsHex !== null) {
        let hs = JSON.parse(atob(hsHex));
        if (hs.value === hexValue && new Date().getTime() - hs.time < 5000) {
            return false;
        }
    }
    setItem(LOCAL_STORAGE_KEYS.HUB_SWITCHING, btoa(JSON.stringify({ time: new Date().getTime(), value: hexValue })));
    return true;
};
