import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import CardBox from "../../components/Common/CardBox";
import FlexiDataTable from "../../components/FlexiDataTable";
import { SUCCESS_FAILED, CALLBACK_KEY, ComponentType, TAGGING_EVENT_STATUS_ENUM } from "../../constants";
import {
    CustomPaginationProps,
    TagEvents,
    FlexiDataColumnProps,
    FlexiDataTableCallbackProps,
    FlexiDataTableOptionsProps,
    BrandsList,
    ServersList,
} from "../../constants/type";
import AuthHelper, { AuthKeys } from "../../helpers/authHelper";
import { apiRequest, APIs } from "../../services/apiConfig";
import { setSource, removeSource } from "../../services/localStorage";
import { DataTableColumnRender, DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../utils/Common";
import { ACCOUNT_PROFILE_FROM_MODULE, getAccountProfileId, getAccountProfileParams, getNavigationUrl } from "../Analysis/AccountProfileHelper";
import { Comment, Typography } from "antd";
import { DateTimeUtil } from "../../utils/datetime";

const { Paragraph } = Typography;

interface TagEvent extends TagEvents {
    info?: string;
}

const TaggingListPage = () => {
    let location = useLocation();

    const authHp = new AuthHelper();
    const getColumnsConfig = (brands: BrandsList[] = [], servers: ServersList[] = []) => {
        return [
            DTColProps.Small({
                title: "Account ID",
                dataIndex: "accountId",
                key: "accountId",
                ...(authHp.isAuthorized(AuthKeys.TAGGING_LIST_VIEW) && {
                    render: (accountId: number, rowData: TagEvent) =>
                        accountId ? (
                            <Link to={getNavigationUrl(ACCOUNT_PROFILE_FROM_MODULE.TAGGING_LIST, getAccountProfileParams(rowData.serverId, rowData.accountId, ACCOUNT_PROFILE_FROM_MODULE.TAGGING_LIST, "", "", "", "", "") || "")} style={{ color: "#0e65cc" }}>
                                {accountId}
                            </Link>
                        ) : (
                            "-"
                        ),
                }),
                options: {
                    filter: {
                        type: ComponentType.number,
                    },
                },
            }),

            DTColProps.Small({
                title: "Brand",
                dataIndex: "brand",
                key: "brand",
                render: (brand: string) => (brand ? brand : "-"),
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: brands.map((x) => ({ text: x.brand, value: x.id })),
                    },
                },
            }),

            DTColProps.Small({
                title: "Server",
                dataIndex: "server",
                key: "server",
                render: (server: string) => (server ? server : "-"),
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: servers.map((x) => ({ text: x.server, value: x.id })),
                    },
                },
            }),

            DTColProps.Middle({
                title: "Tag",
                dataIndex: "tag",
                key: "tag",
                render: (text: any, record: TagEvents) => {
                    return (
                        <Paragraph delete={record.status === TAGGING_EVENT_STATUS_ENUM.Abandoned} style={{ marginBottom: "0px" }}>
                            {text}
                        </Paragraph>
                    );
                },
                options: {
                    filter: {
                        type: ComponentType.text,
                    },
                },
            }),

            {
                title: "Event & Comment(s)",
                dataIndex: "event",
                key: "event",
                render: (text: any, record: TagEvents, index: number) => {
                    let isLineThrough = record.status === TAGGING_EVENT_STATUS_ENUM.Abandoned;
                    return (
                        <Comment
                            className="custom-comment"
                            author={<span className={`${isLineThrough ? "text-line-throught" : ""}`}>{text}</span>}
                            content={
                                record.comment && record.comment.length > 0 ? (
                                    <Paragraph ellipsis={{ rows: 4, expandable: true, symbol: "more" }} delete={isLineThrough}>
                                        {record.comment}
                                    </Paragraph>
                                ) : (
                                    <></>
                                )
                            }
                        />
                    );
                },
            },

            DTColProps.DateTime({
                title: "Created At (Local)",
                dataIndex: "createdDateUtc",
                key: "createdDateUtc",
                render: (text: any, record: TagEvents) => {
                    return (
                        <Paragraph delete={record.status === TAGGING_EVENT_STATUS_ENUM.Abandoned} style={{ marginBottom: "0px" }}>
                            {DataTableColumnRender.DateTime(`${text}+00:00`)}
                        </Paragraph>
                    );
                },
                options: {
                    filter: {
                        type: ComponentType.daterange,
                        value: "",
                        inputProps: {
                            showTime: { format: "HH:mm:ss" },
                        },
                    },
                },
                sorter: true,
            }),
        ];
    };

    const [data, setData] = useState<TagEvents[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(true);
    const [pagination, setPagination] = useState<CustomPaginationProps>({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [sorting, setSorting] = useState<string>("createdDateUtc,desc");
    const [filterParams, setFilterParams] = useState<any>({});
    const [tableCols, setTableCols] = useState<FlexiDataColumnProps[]>([...getColumnsConfig()]);

    const getInitialData = () => {
        apiRequest(APIs.GET_TAG_EVENT_LIST, {
            limit: pagination.pageSize,
            current: pagination.current,
            ...(sorting.length > 0 && { order: sorting }),
            ...(Object.keys(filterParams).length > 0 && { ...filterParams }),
        })
            .then((data: any) => {
                if (data && data.result && data.result.length > 0) {
                    let markupData = data.result.map((x: TagEvents, index: number) => {
                        const info = getAccountProfileId(x.serverId, x.accountId, ACCOUNT_PROFILE_FROM_MODULE.TAGGING_LIST, "", "", "", "", "");
                        return {
                            key: index,
                            ...x,
                            info,
                        };
                    });
                    setData(markupData);
                    setPagination((prev) => ({ ...prev, total: data.total }));
                } else {
                    setData([]);
                    setPagination((prev) => ({ ...prev, total: 0 }));
                }
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("tag event list", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        setSource(location);

        return () => {
            removeSource();
        };
    }, []);

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            getInitialData();
            setRunRefetchDataList(false);
        }
        return () => {};
    }, [runRefetchDataList]);

    const options: FlexiDataTableOptionsProps = {
        serverFiltering: true,
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, FormData) => {
        switch (type) {
            case CALLBACK_KEY.HANDLE_PAGINATION_SORTING:
                FormData.sorter.order === undefined
                    ? setSorting("createdDateUtc,desc")
                    : setSorting(
                          FormData.sorter.column && FormData.sorter.order
                              ? `${FormData.sorter.column.key},${FormData.sorter.order === "ascend" ? "asc" : "desc"}`
                              : ""
                      );
                setPagination((prev) => ({ ...prev, current: FormData.pagination.current, pageSize: FormData.pagination.pageSize }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                let filterParams: any = {};
                Object.keys(FormData)
                    .filter((x) => FormData[x] && FormData[x].toString().length > 0)
                    .map((x) => {
                        if (x === "server") {
                            filterParams["serverId"] = FormData[x];
                        } else if (x === "brand") {
                            filterParams["brandId"] = FormData[x];
                        } else if (x === "createdDateUtc") {
                            filterParams.dateFrom = DateTimeUtil.GetUTC(FormData[x][0]);
                            filterParams.dateTo = DateTimeUtil.GetUTC(FormData[x][1]);
                        } else {
                            filterParams[x] = FormData[x];
                        }
                        return x;
                    });
                setFilterParams(filterParams);
                setSorting("createdDateUtc,desc");
                setPagination((prev) => ({ ...prev, current: 1 }));
                setRunRefetchDataList(true);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["brand", "server"] })
            .then((data: any) => {
                setTableCols(getColumnsConfig(data.brands, data.servers));
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("brand and server list", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
            })
            .finally(() => {
                setIsLoading(false);
            });
        return () => {};
    }, []);

    return (
        <div>
            <CardBox title={"Tagging List"}>
                <FlexiDataTable
                    rowKeyProperty="id"
                    title=""
                    columns={tableCols}
                    options={options}
                    dataSource={data || []}
                    callback={componentCallback}
                    loading={isLoading}
                    pagination={pagination}
                    serverSide={true}
                />
            </CardBox>
        </div>
    );
};

export default TaggingListPage;
