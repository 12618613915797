import { Skeleton } from "antd";

type SkeletonType = "square" | "default";
type SizeType = "default" | "small" | "large";

export interface CustomSkeletonProps {
    type?: SkeletonType;
    className?: string;
    rows?: number;
    size?: SizeType;
}

const CustomSkeleton = (props: CustomSkeletonProps) => {
    return props.type ? (
        <div className={`custom-skeleton-input ${props.className || ""}`}>
            <Skeleton.Input
                active
                {...{
                    ...(props.size && {
                        size: props.size,
                    }),
                }}
            />
        </div>
    ) : (
        <div className={`custom-skeleton ${props.className || ""}`}>
            <Skeleton
                active
                {...{
                    ...(props.rows && {
                        paragraph: { rows: props.rows },
                    }),
                    ...(props.size && {
                        size: props.size,
                    }),
                }}
            />
        </div>
    );
};

export default CustomSkeleton;
