import { createApi } from "@reduxjs/toolkit/query/react";
import { APIs, defaultBaseQueryProps, ResponseProps } from "../../services/apiConfig";
import { Module, Role } from "../../constants/type";
import { axiosBaseQuery } from "../axiosBaseQuery";

export interface roleRequest {}

export interface roleResponse extends ResponseProps<Role[]> {}

export interface modulesRequest {}

export interface modulesResponse extends ResponseProps<Module[]> {}

export interface roleTypeRequest {}

export interface roleTypeResponse extends ResponseProps<any[]> {}

export const roleMenuApiSlice = createApi({
    reducerPath: "api_11",
    baseQuery: axiosBaseQuery(),
    keepUnusedDataFor: 5, //global caching duration (seconds)
    endpoints: (builder) => ({
        getRoleMenus: builder.query<roleResponse, roleRequest | undefined>({
            keepUnusedDataFor: 0, //disable for caching for particular api request
            query: (arg) => {
                return { ...defaultBaseQueryProps, url: APIs.GET_ROLE_MENU_LISTING, method: "POST", data: arg };
            },
            transformResponse: (returnValue: roleResponse, meta) => {
                return returnValue;
            },
        }),
        getModuleList: builder.query<modulesResponse, modulesRequest | undefined>({
            keepUnusedDataFor: 0, //disable for caching for particular api request
            query: (arg) => {
                return { ...defaultBaseQueryProps, url: APIs.GET_MENU_LISTING, method: "POST", data: arg };
            },
            transformResponse: (returnValue: modulesResponse, meta) => {
                return returnValue;
            },
        }),
        getRoleTypeList: builder.query<roleTypeResponse, roleTypeRequest | undefined>({
            keepUnusedDataFor: 0, //disable for caching for particular api request
            query: (arg) => {
                return { ...defaultBaseQueryProps, url: APIs.GET_USER_ROLE_LIST, method: "POST", data: arg };
            },
            transformResponse: (returnValue: roleTypeResponse, meta) => {
                return returnValue;
            },
        }),
    }),
});

export const { useGetModuleListQuery, useGetRoleMenusQuery, useGetRoleTypeListQuery } = roleMenuApiSlice;
