import { LineChartOutlined } from "@ant-design/icons";
import { Empty, Form } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { FormComponent } from "../../../../components/FormComponent";
import LoadingComponent from "../../../../components/Loading";
import { apiRequest } from "../../../../services/apiConfig";
import { APIs } from "../../../../services/apis";
import ChartPanel from "./chartPanel";
import { currencyRender, ErrorCatchValidator, ErrorMessageHandler } from "../../../../utils/Common";
import { Datum, Line } from "@ant-design/charts";
import { ProfileInfoProps } from "../viewProfile";
import { ComponentType, SUCCESS_FAILED } from "../../../../constants";
import { DateTimeUtil } from "../../../../utils/datetime";
import EmptyData from "../../../../components/Common/Empty";
import CustomSkeleton from "../../../../components/Common/Skeleton";

export interface MarginLevelChartProps extends ProfileInfoProps {
    forPrint?: boolean;
}

interface FilterData {
    dateFrom?: string;
    dateTo?: string;
}

const MarginLevelChart = (props: MarginLevelChartProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [refreshData, setRefreshData] = useState<boolean>(false);
    const [data, setData] = useState<any[]>([]);
    const [filterData, setFilterData] = useState<FilterData>({});
    const [chartFilterForm] = Form.useForm();

    const getDataList = () => {
        setIsLoading(true);
        apiRequest(APIs.ACCOUNT_HISTORICAL_PNL, {
            accountId: props?.accountId,
            brandId: props?.brandId,
            serverId: props?.serverId,
            metricType: 1,
            timeFrame: 0,
            dataSamplingMode: 0,
            metricids: [139],
            ...filterData,
        })
            .then((data: any) => {
                if (data && data.length > 0) {
                    let markupData: any[] = [];
                    data.map((x: any) => {
                        markupData = markupData.concat(
                            x.plotData.map((y: any) => ({
                                name: x.metricName,
                                date: moment(y.date).format("YYYY-MM-DD HH:mm:ss"),
                                value: y.value,
                            }))
                        );

                        return false;
                    });
                    setData(markupData);
                } else {
                    setData([]);
                }
            })
            .catch((error) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("Margin Level Metrics", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        setRefreshData(true);
        return () => {};
    }, []);

    useEffect(() => {
        if (refreshData) {
            getDataList();
            setRefreshData(false);
        }
        return () => {};
    }, [refreshData]);

    const onFilterValueCallback = (changedValues: any) => {
        if (changedValues.date && changedValues.date.length > 1) {
            setFilterData({
                dateFrom: DateTimeUtil.GetOrigin(changedValues.date[0]),
                dateTo: DateTimeUtil.GetOrigin(changedValues.date[1]),
            });
        } else {
            setFilterData({});
        }

        setRefreshData(true);
    };

    return (
        <>
            {isLoading ? (
                <ChartPanel
                    forPrint={props.forPrint}
                    type={1}
                    icon={<LineChartOutlined />}
                    title={"Margin Level"}
                    subTitle={"Overall margin level summary for current account"}
                >
                    <CustomSkeleton rows={9} />
                </ChartPanel>
            ) : (
                <ChartPanel
                    forPrint={props.forPrint}
                    type={1}
                    icon={<LineChartOutlined />}
                    title={"Margin Level"}
                    subTitle={"Overall margin level summary for current account"}
                    titleExtra={{
                        form: chartFilterForm,
                        content: (
                            <>
                                <FormComponent
                                    label={""}
                                    name={"date"}
                                    extra={{
                                        type: ComponentType.daterange,
                                        value: "",
                                        inputProps: {
                                            showTime: { format: "HH:mm:ss" },
                                        },
                                    }}
                                />
                            </>
                        ),
                        onValueChanged: onFilterValueCallback,
                    }}
                >
                    <div className="chart">
                        {data?.length > 0 ? (
                            <Line
                                {...{
                                    data,
                                    padding: "auto",
                                    xField: "date",
                                    yField: "value",
                                    seriesField: "name",
                                    legend: false,
                                    ...(props.forPrint
                                        ? {
                                              xAxis: {
                                                  label: {
                                                      autoRotate: true,
                                                  },
                                                  tickLine: {
                                                      alignTick: true,
                                                  },
                                              },
                                          }
                                        : {
                                              xAxis: {
                                                  label: {
                                                      autoRotate: true,
                                                  },
                                                  tickLine: {
                                                      alignTick: true,
                                                  },
                                              },
                                              slider: {
                                                  start: 0,
                                                  end: 1,
                                              },
                                          }),
                                    yAxis: {
                                        label: {
                                            formatter: (v) => currencyRender(v),
                                        },
                                    },
                                    tooltip: {
                                        showMarkers: false,
                                        formatter: (datum: Datum) => {
                                            return { name: datum.name, value: currencyRender(datum.value, 2) };
                                        },
                                    },
                                }}
                            />
                        ) : (
                            <>
                                <EmptyData />
                            </>
                        )}
                    </div>
                </ChartPanel>
            )}
        </>
    );
};

export default MarginLevelChart;
