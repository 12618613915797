import CardBox from "@/components/Common/CardBox";
import FlexiDataTable from "@/components/FlexiDataTable";
import { ComponentType, CALLBACK_KEY, SUCCESS_FAILED } from "@/constants";
import { FlexiDataTableOptionsProps, FlexiDataTableCallbackProps, KeyValuePair } from "@/constants/type";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { apiRequest } from "@/services/apiConfig";
import { APIs } from "@/services/apis";
import { ErrorMessageHandler, ErrorCatchValidator, DTColProps } from "@/utils/Common";
import { defaultIfEmptyOrNull, isEmptyOrNull } from "@/utils/string";
import { useState, useEffect, useMemo } from "react";
import { useNavigate, Link } from "react-router-dom";

export interface ServerPageV2Props {}

export interface ServerV2Props {
    id?: string;
    comment: null | string;
    refServerId: number;
    serverDisplayName: string;
    serverId: number;
    serverName: string;
    serverTypeId: number;
    serverType: string;
    serverUseCase: string | null;
    serverUseCaseId: number | null;
    serverActor: string | null;
    serverActorId: number | null;
    sourceId: number;
    serverUno: number;
}

export interface FilterOptionsProps {
    serverTypes: KeyValuePair[];
    serverActor: KeyValuePair[];
    serverUseCase: KeyValuePair[];
}

const ServerPageV2 = (props: ServerPageV2Props) => {
    let navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<ServerV2Props[]>([]);
    const [filterOptions, setFilterOptions] = useState<FilterOptionsProps>({ serverTypes: [], serverActor: [], serverUseCase: [] });
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.ADMIN_SERVER_V2_EDIT);

    const columns: any[] = useMemo(
        () => [
            DTColProps.Middle({
                title: "Server Name",
                dataIndex: "serverDisplayName",
                key: "serverDisplayName",
                options: {
                    filter: {
                        type: ComponentType.text,
                        value: "",
                    },
                },
            }),
            DTColProps.Small({
                title: "Server Type",
                dataIndex: "serverTypeId",
                key: "serverTypeId",
                render: (text: string, record: ServerV2Props) => record.serverType,
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: filterOptions.serverTypes,
                    },
                },
            }),
            DTColProps.Small({
                title: "Actor",
                dataIndex: "serverActorId",
                key: "serverActorId",
                render: (text: string, rowData: any) => defaultIfEmptyOrNull(rowData["serverActor"], "-"),
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: filterOptions.serverActor,
                        callback: (filterValue: any, rowData: any) =>
                            isEmptyOrNull(rowData["serverActorId"]) ? false : rowData["serverActorId"] === filterValue,
                    },
                },
            }),
            DTColProps.Small({
                title: "Use Case",
                dataIndex: "serverUseCaseId",
                key: "serverUseCaseId",
                render: (text: string, rowData: any) => defaultIfEmptyOrNull(rowData["serverUseCase"], "-"),
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: filterOptions.serverUseCase,
                        callback: (filterValue: any, rowData: any) =>
                            isEmptyOrNull(rowData["serverUseCaseId"]) ? false : rowData["serverUseCaseId"] === filterValue,
                    },
                },
            }),
            {
                title: "Comment",
                dataIndex: "comment",
                key: "comment",
            },
            DTColProps.Status({
                title: "Status",
                dataIndex: "enable",
                key: "enable",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: [
                            { text: "Active", value: true },
                            { text: "Inactive", value: false },
                        ],
                    },
                },
            }),
        ],
        [filterOptions]
    );

    const options: FlexiDataTableOptionsProps = {
        add: () => {
            if (enableUpdate) {
                return "/siteadmin/brandserver/server/create";
            } else return enableUpdate;
        },
        edit: (record: any, overwriteProps: any) => {
            if (enableUpdate) {
                let newProps = { ...overwriteProps };
                newProps.label = <Link to={`/siteadmin/brandserver/server/edit/${record.serverId}`}>{newProps.label}</Link>;
                return newProps;
            } else return enableUpdate;
        },
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, ServerData) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                navigate("/siteadmin/brandserver/server/create", { state: { data: null, action: "add" } });
                break;
            case CALLBACK_KEY.DO_EDIT:
                navigate(`/siteadmin/brandserver/server/edit/${ServerData.serverId}`, { state: { data: ServerData.serverId, action: "edit" } });
                break;
            case CALLBACK_KEY.DO_DELETE:
                apiRequest(APIs.DELETE_SERVER_V2, { serverId: ServerData.serverId })
                    .then(data => {
                        ErrorMessageHandler("server", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                        getServerList();
                    })
                    .catch(error => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("server", SUCCESS_FAILED.FAILED_DELETE_DATA, err)));
                break;
            default:
                break;
        }
    };

    const getServerList = () => {
        setIsLoading(true);
        apiRequest(APIs.GET_SERVERS_LIST_V2, { current: 1, limit: 999999 })
            .then((res: any) => {
                setData(
                    res.result && res.result.length > 0 ? res.result.map((x: ServerV2Props) => ({ ...x, id: `${x.sourceId}-${x.serverId}` })) : []
                );
            })
            .catch(error => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("server list", SUCCESS_FAILED.FAILED_LOAD_DATA, err)))
            .finally(() => setIsLoading(false));
    };

    const getConfig = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["riskinsightservertype", "riskinsightserveractor", "riskinsightserverusecase"] })
            .then((res: any) => {
                setFilterOptions({
                    serverTypes: res.riskInsightServerTypes.map((x: any) => ({ text: x.serverType, value: x.serverTypeId })),
                    serverActor: res.riskInsightServerActors.map((x: any) => ({ text: x.serverActor, value: x.serverActorId })),
                    serverUseCase: res.riskInsightServerUseCases.map((x: any) => ({ text: x.serverUseCase, value: x.serverUseCaseId })),
                });
            })
            .finally(() => getServerList());
    };

    useEffect(() => {
        getConfig();
    }, []);

    return (
        <div className="server-page-v2-container">
            <CardBox title={"Server Listing"}>
                <FlexiDataTable
                    rowKeyProperty="id"
                    title=""
                    columns={columns}
                    options={options}
                    dataSource={data}
                    callback={componentCallback}
                    loading={isLoading}
                />
            </CardBox>
        </div>
    );
};

export default ServerPageV2;
