import { useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { Form, Button, message, Col, Row } from "antd";
import { KeyValuePair, RoleType, User } from "../../../constants/type";
import SitePageHeader from "../../../components/PageHeader";
import { HomeOutlined } from "@ant-design/icons";
import { FormComponent } from "../../../components/FormComponent";
import { REQUIRED_FIELD } from "../../../constants/errorMessage";
import LoadingComponent from "../../../components/Loading";
import { apiRequest } from "../../../services/apiConfig";
import { APIs } from "../../../services/apis";
import { cloneDeep } from "lodash";
import { ComponentType, SUCCESS_FAILED } from "../../../constants";
import { ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";
import { SortList } from "@/utils/array";

interface SelectioOptsProps {
    roles: KeyValuePair[];
    teams: KeyValuePair[];
}

const UserEditPage = () => {
    let navigate = useNavigate();
    let location = useLocation();
    let selectedUser = location.state as User;
    const [comData] = useState<User>(selectedUser);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [roleList, setRoleList] = useState<RoleType[]>([]);
    const [teamList, setTeamList] = useState<string[]>([]);

    const [userForm] = Form.useForm();

    const selectionOpts: SelectioOptsProps = useMemo(() => {
        return {
            roles: SortList(
                roleList.map(x => ({ text: x.role, value: x.id })),
                "text"
            ),
            teams: teamList.map(x => ({ text: x, value: x })),
        };
    }, [roleList, teamList]);

    const dataMarkup = (data: User) => {
        let tmp: any = cloneDeep(data);
        tmp["roleIds"] = (tmp["roleIds"] as string).split(",").map((x: string) => parseInt(x));
        tmp["userId"] = tmp["id"];
        return tmp;
    };

    const onSubmit = (values: any) => {
        try {
            setIsLoading(true);
            values["roleIds"] = (values["roleIds"] as number[]).join(",");

            apiRequest(APIs.UPDATE_USER_ACCOUNT, { ...values })
                .then(() => {
                    ErrorMessageHandler("User data", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                    navigate("/siteadmin/user");
                })
                .catch(error => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("user detail", SUCCESS_FAILED.FAILED_UPDATE_DATA, err)))
                .finally(() => setIsLoading(false));
        } catch (err: any) {
            message.error(err.toString());
        }
    };

    const getUserRoles = () => {
        apiRequest(APIs.GET_USER_ROLE_LIST, { userId: comData.id })
            .then((response: any) => {
                let roles = response as RoleType[];
                setRoleList(roles);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => console.log(`Error: ${err}`));
            });
    };

    const getUserTeams = () => {
        apiRequest(APIs.GET_SETTING_LIST, { keys: ["UserTeams"] })
            .then((res: any) => {
                const userTeamsValue = JSON.parse(res.find((item: { key: string }) => item.key === "UserTeams")?.value || "null");
                setTeamList(userTeamsValue);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => console.log("Failed to get latest user teams: ", err));
            });
    };

    useEffect(() => {
        getUserRoles();
        getUserTeams();
        return () => {};
    }, []);

    return (
        <SitePageHeader
            title={"Edit User Account"}
            routes={[
                { path: "/siteadmin/user", breadcrumbName: "Users Management", icon: <HomeOutlined /> },
                { path: "", breadcrumbName: "Edit User Account" },
            ]}
            onBack={() => navigate("/siteadmin/user")}
        >
            {isLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Submitting..." />
                </div>
            ) : (
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    form={userForm}
                    layout="horizontal"
                    initialValues={dataMarkup(comData)}
                    onFinish={onSubmit}
                >
                    <Row>
                        <Col span={15}>
                            <FormComponent label={""} name={"userId"} extra={{ type: ComponentType.hidden, value: "" }} />
                            <FormComponent
                                label="Username"
                                name="username"
                                extra={{
                                    type: ComponentType.labelOnly,
                                    value: "",
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="First Name"
                                name="firstName"
                                extra={{
                                    type: ComponentType.labelOnly,
                                    value: "",
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Last Name"
                                name="lastName"
                                extra={{
                                    type: ComponentType.labelOnly,
                                    value: "",
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Email"
                                name="email"
                                extra={{
                                    type: ComponentType.labelOnly,
                                    value: "",
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="User Role"
                                name={"roleIds"}
                                extra={{
                                    type: ComponentType.dropdown,
                                    inputProps: {
                                        style: { width: "100%" },
                                        mode: "multiple",
                                        allowClear: true,
                                    },
                                    value: selectionOpts.roles,
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Team"
                                name={"team"}
                                extra={{
                                    type: ComponentType.dropdown,
                                    inputProps: {
                                        style: { width: "100%" },
                                        allowClear: true,
                                    },
                                    value: selectionOpts.teams,
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div className="step-btns-group">
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            )}
        </SitePageHeader>
    );
};

export default UserEditPage;
