import { Button, Form, message } from "antd";
import { FormComponent } from "../../../../../components/FormComponent";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "../../../../../constants";
import { SearchOutlined } from "@ant-design/icons";
import FlexiDataTable from "../../../../../components/FlexiDataTable";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../../../../utils/Common";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
    FlexiDataTableOptionsProps,
    GroupTransferHistoricalStatisticEquityProps,
    GroupTransferHistoricalStatisticTradeProps,
} from "../../../../../constants/type";
import { APIs } from "../../../../../services/apis";
import { plainAxiosInstance } from "../../../../../services/axiosSetup";
import moment from "moment";
import { REQUIRED_FIELD } from "../../../../../constants/errorMessage";

export interface HistoricalStatisticsProps {}

const HistoricalStatistics = (props: HistoricalStatisticsProps) => {
    const [isLoading, setIsLoading] = useState<number>(0);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [equityDatas, setEquityDatas] = useState<GroupTransferHistoricalStatisticEquityProps[]>([]);
    const [tradeDatas, setTradeDatas] = useState<GroupTransferHistoricalStatisticTradeProps[]>([]);
    const [filterParams, setFilterParams] = useState<any>({});
    const [searchForm] = Form.useForm();

    const isFetching = useMemo(() => isLoading !== 2, [isLoading]);

    const equityColumns = [
        DTColProps.XSmall({
            title: "Server",
            dataIndex: "server",
            key: "server",
            fixed: "left",
        }),
        DTColProps.XSmall({
            title: "Login",
            dataIndex: "login",
            key: "login",
            fixed: "left",
        }),
        DTColProps.Middle({
            width: "10.5vw",
            title: "From Group",
            dataIndex: "fromGroup",
            key: "fromGroup",
            fixed: "left",
        }),
        DTColProps.Middle({
            width: "10.5vw",
            title: "To Group",
            dataIndex: "toGroup",
            key: "toGroup",
            fixed: "left",
        }),
        DTColProps.XSCurrency(
            {
                title: "Leverage",
                dataIndex: "leverage",
                key: "leverage",
            },
            ["text-center"],
            -1
        ),
        DTColProps.SCurrency(
            {
                title: "Balance",
                dataIndex: "balance",
                key: "balance",
            },
            [],
            -1
        ),
        DTColProps.SCurrency(
            {
                title: "Equity",
                dataIndex: "equity",
                key: "equity",
            },
            [],
            -1
        ),
        DTColProps.XSmall(
            {
                title: "Volume",
                dataIndex: "volume",
                key: "volume",
            },
            ["text-center"]
        ),
        DTColProps.SCurrency(
            {
                title: "Margin",
                dataIndex: "margin",
                key: "margin",
            },
            [],
            -1
        ),
        DTColProps.SCurrency(
            {
                title: "Margin Free",
                dataIndex: "marginFree",
                key: "marginFree",
            },
            [],
            -1
        ),
        DTColProps.SCurrency(
            {
                title: "Level",
                dataIndex: "level",
                key: "level",
            },
            [],
            -1
        ),
        DTColProps.Small({
            title: "Operation Time",
            dataIndex: "operationTime",
            key: "operationTime",
            fixed: "right",
        }),
    ];

    const tradeColumns = [
        DTColProps.XSmall({
            title: "Server",
            dataIndex: "server",
            key: "server",
            fixed: "left",
        }),
        DTColProps.XSmall({
            title: "Login",
            dataIndex: "login",
            key: "login",
            fixed: "left",
        }),
        DTColProps.Middle({
            width: "10.5vw",
            title: "From Group",
            dataIndex: "fromGroup",
            key: "fromGroup",
            fixed: "left",
        }),
        DTColProps.Middle({
            width: "10.5vw",
            title: "To Group",
            dataIndex: "toGroup",
            key: "toGroup",
            fixed: "left",
        }),
        DTColProps.XSmall({
            title: "Order",
            dataIndex: "order",
            key: "order",
        }),
        DTColProps.XSmall({
            title: "Symbol",
            dataIndex: "symbol",
            key: "symbol",
        }),
        DTColProps.XXSmall(
            {
                title: "CMD",
                dataIndex: "cmd",
                key: "cmd",
            },
            ["text-center"]
        ),
        DTColProps.XSmall(
            {
                title: "Volume",
                dataIndex: "volume",
                key: "volume",
            },
            ["text-center"]
        ),
        DTColProps.Small({
            title: "Open Time",
            dataIndex: "openTime",
            key: "openTime",
        }),
        DTColProps.SCurrency(
            {
                title: "Open Price",
                dataIndex: "openPrice",
                key: "openPrice",
            },
            ["text-center"],
            -1
        ),
        DTColProps.SCurrency(
            {
                title: "Close Price",
                dataIndex: "closePrice",
                key: "closePrice",
            },
            ["text-center"],
            -1
        ),
        DTColProps.XSCurrency(
            {
                title: "Sl",
                dataIndex: "sl",
                key: "sl",
            },
            [],
            -1
        ),
        DTColProps.XSCurrency(
            {
                title: "Tp",
                dataIndex: "tp",
                key: "tp",
            },
            [],
            -1
        ),
        DTColProps.XSCurrency(
            {
                title: "Commission",
                dataIndex: "commission",
                key: "commission",
            },
            [],
            -1
        ),
        DTColProps.XSCurrency(
            {
                title: "Commission Agent",
                dataIndex: "commissionAgent",
                key: "commissionAgent",
            },
            [],
            -1
        ),
        DTColProps.XSmall(
            {
                title: "Reason",
                dataIndex: "reason",
                key: "reason",
            },
            ["text-center"]
        ),
        DTColProps.SCurrency(
            {
                title: "Storage",
                dataIndex: "storage",
                key: "storage",
            },
            [],
            -1
        ),
        DTColProps.SCurrency(
            {
                title: "Profit",
                dataIndex: "profit",
                key: "profit",
            },
            [],
            -1
        ),
        DTColProps.Small(
            {
                title: "Margin Rate",
                dataIndex: "marginRate",
                key: "marginRate",
            },
            ["text-right"]
        ),
        DTColProps.Middle({
            title: "Comment",
            dataIndex: "comment",
            key: "comment",
        }),
        DTColProps.Small({
            title: "Operation Time",
            dataIndex: "operationTime",
            key: "operationTime",
            fixed: "right",
        }),
    ];

    const options: FlexiDataTableOptionsProps = {
        enableFilter: false,
        export: {
            text: "Download Report",
        },
    };

    const downloadEquityReport = useCallback(() => {
        try {
            plainAxiosInstance
                .get(
                    `${plainAxiosInstance.defaults.baseURL}${APIs.RISK_TOOL.GET_GROUP_TRANSFER_TOOL_HISTORICAL_STATISTIC_EQUITY_DOWNLOAD}?startDate=${filterParams.startDate}&endDate=${filterParams.endDate}`,
                    {
                        headers: {
                            Accept: "application/octet-stream, */*",
                        },
                        responseType: "blob",
                    }
                )
                .then(response => {
                    const contentType = response.headers["content-type"];
                    if (
                        contentType === "application/octet-stream" ||
                        contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    ) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", `GroupTransferEquity_${moment().format("YYYYMMDDHHmmss")}.xlsx`);
                        document.body.appendChild(link);
                        link.click();
                        // Clean up
                        window.URL.revokeObjectURL(url);
                    } else {
                        message.error(`Received non-file response. Error: ${response}`, 3);
                    }
                })
                .catch((error: any) => message.error(`Error occured during download: "${error.message}"`, 3));
        } catch (e: any) {
            message.error(`Error occured during download: "${e.message}"`, 3);
        }
    }, [filterParams]);

    const downloadTradeReport = useCallback(() => {
        try {
            plainAxiosInstance
                .get(
                    `${plainAxiosInstance.defaults.baseURL}${APIs.RISK_TOOL.GET_GROUP_TRANSFER_TOOL_HISTORICAL_STATISTIC_TRADE_DOWNLOAD}?startDate=${filterParams.startDate}&endDate=${filterParams.endDate}`,
                    {
                        headers: {
                            Accept: "application/octet-stream, */*",
                        },
                        responseType: "blob",
                    }
                )
                .then(response => {
                    const contentType = response.headers["content-type"];
                    if (
                        contentType === "application/octet-stream" ||
                        contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    ) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", `GroupTransferTrade_${moment().format("YYYYMMDDHHmmss")}.xlsx`);
                        document.body.appendChild(link);
                        link.click();
                        // Clean up
                        window.URL.revokeObjectURL(url);
                    } else {
                        message.error(`Received non-file response. Error: ${response}`, 3);
                    }
                })
                .catch((error: any) => message.error(`Error occured during download: "${error.message}"`, 3));
        } catch (e: any) {
            message.error(`Error occured during download: "${e.message}"`, 3);
        }
    }, [filterParams]);

    const getEquityDataList = useCallback(() => {
        const formData = new FormData();
        formData.append("startDate", filterParams.startDate);
        formData.append("endDate", filterParams.endDate);
        plainAxiosInstance
            .post(APIs.RISK_TOOL.GET_GROUP_TRANSFER_TOOL_HISTORICAL_STATISTIC_EQUITY_LIST, formData)
            .then((res: any) => {
                if (res.data) {
                    setEquityDatas(
                        res.data.map((x: GroupTransferHistoricalStatisticEquityProps) => ({
                            ...x,
                            keyId: `${x.server}-${x.login}-${x.operationTime}`,
                        }))
                    );
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("equity records", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsLoading(prev => prev + 1));
    }, [filterParams]);

    const getTradeDataList = useCallback(() => {
        const formData = new FormData();
        formData.append("startDate", filterParams.startDate);
        formData.append("endDate", filterParams.endDate);
        plainAxiosInstance
            .post(APIs.RISK_TOOL.GET_GROUP_TRANSFER_TOOL_HISTORICAL_STATISTIC_TRADE_LIST, formData)
            .then((res: any) => {
                if (res.data) {
                    setTradeDatas(
                        res.data.map((x: GroupTransferHistoricalStatisticTradeProps) => ({
                            ...x,
                            keyId: `${x.server}-${x.login}-${x.order}-${x.operationTime}`,
                        }))
                    );
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("trade records", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsLoading(prev => prev + 1));
    }, [filterParams]);

    const getDefaultDate = () => {
        plainAxiosInstance
            .get(APIs.RISK_TOOL.GET_GROUP_TRANSFER_TOOL_HISTORICAL_GETTODAYDEALDATE)
            .then((res: any) => {
                if (res.data) {
                    setFilterParams({ startDate: res.data, endDate: res.data });
                    searchForm.setFieldsValue({ dateRange: [moment(`${res.data}T00:00:00`), moment(`${res.data}T00:00:00`)] });
                    setRunRefetchDataList(true);
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("group transfer statistics", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            );
    };

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(0);
            getEquityDataList();
            getTradeDataList();
            setRunRefetchDataList(false);
        }
        return () => {};
    }, [runRefetchDataList]);

    useEffect(() => {
        getDefaultDate();
    }, []);

    return (
        <div className="historical-statistics-container">
            <div className="top-search-panel">
                <Form
                    form={searchForm}
                    layout="inline"
                    onFinish={(values: any) => {
                        if (values?.hasOwnProperty("dateRange") && values["dateRange"].length === 2) {
                            setFilterParams({
                                startDate: values["dateRange"][0].format("YYYY-MM-DD"),
                                endDate: values["dateRange"][1].format("YYYY-MM-DD"),
                            });
                            setRunRefetchDataList(true);
                        }
                    }}
                >
                    <FormComponent
                        label="Date"
                        name="dateRange"
                        extra={{
                            type: ComponentType.daterange,
                            value: "",
                            rules: [{ required: true, message: REQUIRED_FIELD }],
                            inputProps: {
                                allowClear: false,
                            },
                        }}
                    />
                    <Button type="primary" htmlType="submit" icon={<SearchOutlined />} />
                </Form>
            </div>
            <div className="main-content">
                <FlexiDataTable
                    rowKeyProperty="keyId"
                    bordered
                    title={<span style={{ fontSize: "1rem", fontWeight: "bold", color: "#000000" }}>Transfer Equity</span>}
                    columns={equityColumns}
                    dataSource={equityDatas}
                    options={options}
                    loading={isFetching}
                    callback={(type: number, FormData: any) => {
                        switch (type) {
                            case CALLBACK_KEY.EXPORT_CSV_EXCEL:
                                downloadEquityReport();
                                break;
                            default:
                                break;
                        }
                    }}
                />
                <FlexiDataTable
                    rowKeyProperty="keyId"
                    bordered
                    title={<span style={{ fontSize: "1rem", fontWeight: "bold", color: "#000000" }}>Transfer Trade</span>}
                    columns={tradeColumns}
                    dataSource={tradeDatas}
                    options={options}
                    loading={isFetching}
                    callback={(type: number, FormData: any) => {
                        switch (type) {
                            case CALLBACK_KEY.EXPORT_CSV_EXCEL:
                                downloadTradeReport();
                                break;
                            default:
                                break;
                        }
                    }}
                />
            </div>
        </div>
    );
};

export default HistoricalStatistics;
