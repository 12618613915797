import { LineChartOutlined } from "@ant-design/icons";
import { Button, Modal, Space } from "antd";
import "./midbiastable.css";
import { useState } from "react";
import { plainAxiosInstance } from "../../../services/axiosSetup";
import { APIs } from "../../../services/apis";
import MidBiasLineChart from "./MidBiasLineChart";
import FlexiDataTable from "@/components/FlexiDataTable";

const MidBiasSolvedTable = ({ data, isLoading }: { data: any[]; isLoading: boolean }) => {
    const [biasLineCompareData, setBiasLineCompareData] = useState(null);
    const [biasLineTitle, setBiasLineTitle] = useState("");

    const columns: any[] = [
        {
            title: "Line",
            align: "center",
            dataIndex: "Action",
            render: (text: any, data: any) => (
                <Button className="m-1" type="primary" icon={<LineChartOutlined />} onClick={e => showBiasLine(data)} />
            ),
        },
        {
            title: "Datetime",
            dataIndex: "dateTimeStr",
            align: "left",
        },
        {
            title: "Server",
            dataIndex: "server",
            align: "left",
        },

        {
            title: "Symbol",
            dataIndex: "symbol",
            align: "left",
        },
        {
            title: "Compare Server",
            dataIndex: "compareServer",
            align: "left",
        },
        {
            title: "Max score",
            dataIndex: "showMaxScore",
            align: "right",
        },
        {
            title: "Score Sum",
            dataIndex: "showScoreSum",
            align: "right",
        },
        {
            title: "Correlation",
            dataIndex: "showPPMCC",
            align: "right",
        },
        {
            title: "Solved User",
            dataIndex: "solvedUser",
            align: "right",
        },
        {
            title: "Solved Time",
            dataIndex: "solvedTimeStr",
            align: "right",
        },
    ];

    const showBiasLine = async (data: any) => {
        const formData = new FormData();
        formData.append(
            "keyStr",
            `${data["dateTimeStr"].substring(0, data["dateTimeStr"].length - 3)},${data["server"]},${data["symbol"]},${data["compareServer"]},${
                data["compareSymbol"]
            },${1},${data["serverUno"]}`
        );
        const resp = await plainAxiosInstance.post(`${APIs.RC_MIDBIAS.GET_MIDBIAS_CHART}`, formData);
        setBiasLineCompareData(resp.data);
        setBiasLineTitle(`${data["server"]}-${data["symbol"]} VS ${data["compareServer"]}-${data["compareSymbol"]} ${data["dateTimeStr"]}`);
    };

    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            <FlexiDataTable
                bordered
                rowKeyProperty="rowKey"
                title={false}
                columns={columns}
                dataSource={data || []}
                options={{
                    enableFilter: false,
                    showHideColumns: false,
                    recordRowClassName: (record, index) =>
                        record.ppmcc < 80 || record.scoreMax > 15
                            ? "bg-red-800"
                            : record.ppmcc < 95 && record.ppmcc > 80 && (record.scoreSum > 50 || record.scoreMax > 8)
                            ? "bg-yellow-700"
                            : "",
                }}
                pagination={{
                    defaultPageSize: 20,
                }}
                loading={isLoading}
            />
            {biasLineCompareData && (
                <Modal
                    open={biasLineCompareData !== null}
                    closable={true}
                    onCancel={() => {
                        setBiasLineCompareData(null);
                        setBiasLineTitle("");
                    }}
                    width={1080}
                    footer={null}
                    title={biasLineTitle}
                >
                    <MidBiasLineChart biaslineCompareData={biasLineCompareData} />
                </Modal>
            )}
        </Space>
    );
};

export default MidBiasSolvedTable;
