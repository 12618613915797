import { useRef, useState, useEffect } from 'react';

const useAudioPlayer = (audioSrc: string) => {
  const audioRef = useRef(new Audio(audioSrc));
  const [isPlaying, setIsPlaying] = useState(false);
  const [hasUserInteracted, setHasUserInteracted] = useState(false);
  const [audioPromise, setAudioPromise] = useState<Promise<void> | null>(null);

  useEffect(() => {
    const audio = audioRef.current;

    const handlePlay = () => setIsPlaying(true);
    const handlePause = () => setIsPlaying(false);
    const handleEnded = () => setIsPlaying(false);

    audio.addEventListener('play', handlePlay);
    audio.addEventListener('pause', handlePause);
    audio.addEventListener('ended', handleEnded);

    return () => {
      audio.removeEventListener('play', handlePlay);
      audio.removeEventListener('pause', handlePause);
      audio.removeEventListener('ended', handleEnded);
    };
  }, []);


  useEffect(() => {
    //browsers have policies to prevent autoplay of audio or video without user interaction to avoid unwanted noise
    const setUserInteracted = () => setHasUserInteracted(true);

    window.addEventListener('click', setUserInteracted);
    window.addEventListener('keydown', setUserInteracted);
    window.addEventListener('touchstart', setUserInteracted);

    return () => {
      window.removeEventListener('click', setUserInteracted);
      window.removeEventListener('keydown', setUserInteracted);
      window.removeEventListener('touchstart', setUserInteracted);
    };
  }, []);

  const playAudio = () => {
    if (!hasUserInteracted) return;
    const promise = audioRef.current.play();
    setAudioPromise(promise);
    promise.catch((error) => {
      console.error('Audio play interrupted:', error);
    });
  };

  const pauseAudio = () => {
    if (!hasUserInteracted) return;
    if (audioPromise) {
      audioPromise.then(() => {
          audioRef.current.pause();
        })
        .catch((error) => {
          console.error('Audio pause error:', error);
        });
    } else {
      audioRef.current.pause();
    }
  };

  const stopAudio = () => {
    if (!hasUserInteracted) return;
    pauseAudio();
    audioRef.current.currentTime = 0;
  };

  const restartAudio = () => {
    if (!hasUserInteracted) return;
    audioRef.current.currentTime = 0;
    const promise = audioRef.current.play();
    setAudioPromise(promise);
    promise.catch((error) => {
      console.error('Audio restart interrupted:', error);
    });
  };

  return { isPlaying, playAudio, pauseAudio, stopAudio, restartAudio };
}

export default useAudioPlayer;