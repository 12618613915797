import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import FlexiDataTable from "../../../components/FlexiDataTable";
import { useGetServerBrandMappingQuery } from "../../../store/apis/serverBrandMapping";
import { apiRequest, APIs } from "../../../services/apiConfig";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";
import CardBox from "../../../components/Common/CardBox";
import AuthHelper, { AuthKeys } from "../../../helpers/authHelper";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "../../../constants";
import { BrandsList, FlexiDataTableCallbackProps, FlexiDataTableOptionsProps, ServersList } from "../../../constants/type";

const SBMListPage = () => {
    const { isLoading, data, refetch } = useGetServerBrandMappingQuery({});
    const [manualLoading, setManualLoading] = useState<boolean>(false);
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.ADMIN_SERVER_BRAND_MAPPING_EDIT);
    const [brands, setBrands] = useState<BrandsList[]>([]);
    const [servers, setServers] = useState<ServersList[]>([]);
    let navigate = useNavigate();

    useEffect(() => {
        getFilterConfigList();
        refetch();
        return () => {};
    }, []);

    const getFilterConfigList = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["server", "brand"] })
            .then((data: any) => {
                setServers(data.servers);
                setBrands(data.brands);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => console.log(err));
            })
            .finally(() => {
                setManualLoading(false);
            });
    };

    const columns = useMemo(
        () => [
            DTColProps.Middle({
                title: "Server Name",
                dataIndex: "serverId",
                key: "serverId",
                fixed: "left",
                render: (serverId: number) => {
                    let idx = servers.findIndex((x) => x.id === serverId);
                    return idx > -1 ? servers[idx].server : serverId;
                },
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: servers.map((x: any) => ({
                            text: x.server,
                            value: x.id,
                        })),
                    },
                },
            }),
            DTColProps.Middle({
                title: "Brand Name",
                dataIndex: "brandId",
                key: "brandId",
                render: (brandId: number) => {
                    let idx = brands.findIndex((x) => x.id === brandId);
                    return idx > -1 ? brands[idx].brand : brandId;
                },
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: brands.map((x) => ({ text: x.brand, value: x.id })),
                    },
                },
            }),
            {
                title: "Group Pattern",
                dataIndex: "groupPattern",
                key: "groupPattern",
                options: {
                    filter: {
                        type: ComponentType.text,
                        value: "",
                    },
                },
            },
        ],
        [servers, brands]
    );

    const options: FlexiDataTableOptionsProps = {
        add: () => {
            if (enableUpdate) {
                return "/siteadmin/serverbrand/server-brand-mapping/create";
            } else return enableUpdate;
        },
        edit: (record: any, option: any) => {
            if (enableUpdate) {
                let newProps = { ...option };
                newProps.label = <Link to={`/siteadmin/serverbrand/server-brand-mapping/edit/${record.sbmId}`}>{newProps.label}</Link>;
                return newProps;
            } else return enableUpdate;
        },
        delete: enableUpdate,
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, SBMData) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                navigate("/siteadmin/serverbrand/server-brand-mapping/create", { state: { data: null, action: "add" } });
                break;
            case CALLBACK_KEY.DO_EDIT:
                navigate(`/siteadmin/serverbrand/server-brand-mapping/edit/${SBMData.sbmId}`, { state: { data: SBMData.sbmId, action: "edit" } });
                break;
            case CALLBACK_KEY.DO_DELETE:
                setManualLoading(true);
                apiRequest(APIs.DELETE_SERVER_BRAND_MAPPING, {
                    sbmId: SBMData.sbmId,
                })
                    .then((data) => ErrorMessageHandler(SBMData.sbmId, SUCCESS_FAILED.SUCCESS_DELETE_DATA))
                    .catch((error) =>
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("server brand mapping", SUCCESS_FAILED.FAILED_DELETE_DATA, err))
                    )
                    .finally(() => {
                        setManualLoading(false);
                        refetch();
                    });
                break;
            default:
                break;
        }
    };

    return (
        <>
            <CardBox title={"Server Brand Map Listing"}>
                <FlexiDataTable
                    rowKeyProperty="sbmId"
                    title=""
                    columns={columns}
                    options={options}
                    dataSource={data?.data || []}
                    callback={componentCallback}
                    loading={isLoading || manualLoading}
                />
            </CardBox>
        </>
    );
};

export default SBMListPage;
