import { DownOutlined, RightOutlined } from "@ant-design/icons";
import { useMemo, useState, useEffect } from "react";
import AuthHelper, { AuthKeys } from "../../../../../helpers/authHelper";
import { Form, FormInstance, Tag } from "antd";
import FilterSearchInput from "./filterSearchInput";

export interface FilterTextFieldComponentProps {
    isOpen: boolean;
    label: string;
    showMe: (key: string) => void;
    getAccountList: any;
    form: FormInstance;
    filterType: string;
}

export const FilterTextFieldComponent = (props: FilterTextFieldComponentProps) => {
    const authHp = new AuthHelper();
    const [formValue, setFormValue] = useState<string[]>([]);

    useEffect(() => {
        const fieldValue = props.form.getFieldValue(props.filterType);
        if (fieldValue) {
            setFormValue(fieldValue);
        } else {
            setFormValue([]);
        }
    }, [props.filterType, props.form.getFieldValue(props.filterType)]);

    const onSelectSearchInput = (value: string) => {
        let removedServerNameValue = value.split("|").slice(0, 2).join("|");
        const updatedFormValue = [...formValue, value];
        setFormValue(updatedFormValue);
        props.form.setFieldsValue({ [props.filterType]: updatedFormValue });
    };

    const removeAccount = (value: string) => {
        const updatedFormValue = formValue.filter((item: string) => item !== value);
        setFormValue(updatedFormValue);
        props.form.setFieldsValue({ [props.filterType]: updatedFormValue });
    };

    const renderTextField = useMemo(
        () => (
            <div className="intraday-filter-ID-search">
                <Form.Item name={props.filterType} key={`focn-${props.filterType}`}>
                    <FilterSearchInput
                        inputType="number"
                        placeholder={`Fill in ${props.label}, press [Enter]`}
                        serverSide
                        options={props.getAccountList}
                        onSelect={onSelectSearchInput}
                    />
                </Form.Item>
                {formValue && formValue.length > 0 && (
                    <div className="intraday-filter-ID-search-result">
                        {formValue.map((x: string) => (
                            <Tag key={`fdb-fltg-ky-${x}`} closable onClose={() => removeAccount(x)}>
                                {x.split("|")[0]} | {x.split("|")[2]}
                            </Tag>
                        ))}
                    </div>
                )}
            </div>
        ),
        [formValue]
    );

    return (
        <div className="options-container">
            <div className="title">
                <b>{props.label}</b>
            </div>
            <div className={`content nice-scrollbar`}>{renderTextField}</div>
        </div>
    );
};

export default FilterTextFieldComponent;
