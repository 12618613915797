import { LineChartOutlined } from "@ant-design/icons";
import { Empty, Form, message } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { DualAxes } from "@ant-design/plots";
import { FormComponent } from "../../../../components/FormComponent";
import LoadingComponent from "../../../../components/Loading";
import { apiRequest } from "../../../../services/apiConfig";
import { APIs } from "../../../../services/apis";
import ChartPanel from "./chartPanel";
import { currencyRender, ErrorCatchValidator, ErrorMessageHandler } from "../../../../utils/Common";
import { Datum } from "@ant-design/charts";
import { ProfileInfoProps } from "../viewProfile";
import { ComponentType, SUCCESS_FAILED } from "../../../../constants";
import { cloneDeep } from "lodash";
import { hasAnyKey } from "../../../../utils/object";
import EmptyData from "../../../../components/Common/Empty";
import CustomSkeleton from "../../../../components/Common/Skeleton";

export interface BalanceEquityChartProps extends ProfileInfoProps {
    forPrint?: boolean;
}

interface FilterData {
    dateFrom?: string;
    dateTo?: string;
}

const BalanceEquityChart = (props: BalanceEquityChartProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [refreshData, setRefreshData] = useState<boolean>(false);
    const [data, setData] = useState<any[]>([]);
    const [data2, setData2] = useState<any[]>([]);
    const [filterData, setFilterData] = useState<FilterData>({
        dateFrom: moment.utc().subtract(3, "months").format("YYYY-MM-DD"),
        dateTo: moment.utc().format("YYYY-MM-DD"),
    });
    const [chartFilterForm] = Form.useForm();
    const [finalData, setFinalData] = useState<any[]>([]);
    const [finalData2, setFinalData2] = useState<any[]>([]);

    const getDataList = (dateFilter: any) => {
        setIsLoading(true);
        apiRequest(APIs.ACCOUNT_HISTORICAL_PNL, {
            accountId: props?.accountId,
            brandId: props?.brandId,
            serverId: props?.serverId,
            metricType: 1,
            timeFrame: 1,
            dataSamplingMode: 0,
            metricIds: [8, 9],
            ...filterData,
            ...(dateFilter && dateFilter),
        })
            .then((data: any) => {
                if (data && data.length > 0) {
                    let markupData: any[] = [];
                    data.map((x: any) => {
                        markupData = markupData.concat(
                            x.plotData.map((y: any) => ({
                                name: x.metricName,
                                date: moment(y.date).format("YYYY-MM-DD"),
                                value: y.value,
                            }))
                        );

                        return false;
                    });
                    setData(markupData);
                } else {
                    setData([]);
                }
            })
            .catch((error) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("account historical PNL", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
            })
            .finally(() => setIsLoading(false));
        apiRequest(APIs.ACCOUNT_HISTORICAL_PNL, {
            accountId: props.accountId,
            brandId: props.brandId,
            serverId: props.serverId,
            metricType: 1,
            timeFrame: 1,
            dataSamplingMode: 6,
            metricIds: [47, 58],
            ...filterData,
            ...(dateFilter && dateFilter),
        })
            .then((data: any) => {
                if (data && data.length > 0) {
                    let markupData: any[] = [];
                    data.map((x: any) => {
                        markupData = markupData.concat(
                            x.plotData.map((y: any) => ({
                                name: x.metricId.toString() === "58" ? "Withdrawal" : "Deposit",
                                date: moment(y.date).format("YYYY-MM-DD"),
                                count: Math.abs(y.value),
                            }))
                        );

                        return false;
                    });
                    setData2(markupData);
                } else {
                    setData2([]);
                }
            })
            .catch((error) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("account historical PNL", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        setRefreshData(true);
        return () => {};
    }, [props.dateRangeFilter]);

    useEffect(() => {
        if (refreshData) {
            getDataList(props.dateRangeFilter);
            setRefreshData(false);
        }
        return () => {};
    }, [refreshData]);

    useEffect(() => {
        if (data.length !== data2.length) {
            let dateArr1: any = data.filter(({ date }) => date).map(({ date }) => date);
            let dateArr2: any = data2.filter(({ date }) => date).map(({ date }) => date);

            let fullArrDates: string[] = Array.from(new Set(dateArr1.concat(dateArr2)));
            fullArrDates.sort((a: string, b: string) => (a < b ? -1 : 0));

            let tempData = data.concat(data2).reduce((prevAccObj, currObj) => {
                const key = currObj["name"];
                const currGroup = prevAccObj[key] ?? [];
                return { ...prevAccObj, [key]: [...currGroup, currObj] };
            }, {});

            let tempDataByDates = Object.keys(tempData).reduce((obj: any, currName: any) => {
                let valueKey = Object.keys(tempData[currName][0]).filter((y) => y !== "name" && y !== "date")[0];
                let tmp = tempData[currName].reduce((prevAccObj: any, currObj: any) => {
                    prevAccObj[currObj.date] = currObj[valueKey];
                    return prevAccObj;
                }, {});
                obj[currName] = tmp;
                return obj;
            }, {});

            let syncedData = Object.keys(tempDataByDates).reduce((obj: any, currName: any) => {
                let valueCountKey = Object.keys(tempData[currName][0]).filter((y) => y !== "name" && y !== "date")[0];
                let newTemp = cloneDeep(fullArrDates).map((currDate: any) => {
                    return {
                        date: currDate,
                        [valueCountKey]: tempDataByDates[currName][currDate] === undefined ? 0 : tempDataByDates[currName][currDate],
                        name: currName,
                    };
                });
                obj[currName] = newTemp;
                return obj;
            }, {});

            let dataNames = Array.from(new Set(data.map(({ name }) => name)));
            let data2Names = Array.from(new Set(data2.map(({ name }) => name)));

            let newData = Object.keys(syncedData).reduce((arr: any, currName: any) => {
                if (dataNames.includes(currName)) {
                    arr.push(syncedData[currName]);
                }
                return arr.flat();
            }, []);
            let newData2 = Object.keys(syncedData).reduce((arr: any, currName: any) => {
                if (data2Names.includes(currName)) {
                    arr.push(syncedData[currName]);
                }
                return arr.flat();
            }, []);

            setFinalData(newData);
            setFinalData2(newData2);
        } else {
            setFinalData(data);
            setFinalData2(data2);
        }
        return () => {};
    }, [data, data2, filterData]);

    const onFilterValueCallback = (changedValues: any) => {
        if (changedValues.date && changedValues.date.length > 1) {
            setFilterData({
                dateFrom: moment.utc(changedValues.date[0]).format("YYYY-MM-DD"),
                dateTo: moment.utc(changedValues.date[1]).format("YYYY-MM-DD"),
            });
            setRefreshData(true);
        } else {
            message.warning("Please set a date range if you wish to continue.");
            setRefreshData(false);
        }
    };

    useEffect(() => {
        chartFilterForm.setFieldValue("date", [moment().subtract(3, "months"), moment()]);
    }, []);

    return (
        <>
            {isLoading ? (
                <ChartPanel
                    forPrint={props.forPrint}
                    type={1}
                    icon={<LineChartOutlined />}
                    title={"Balance & Equity"}
                    subTitle={"Overall balance & equity summary for current account"}
                >
                    <CustomSkeleton rows={14} />
                </ChartPanel>
            ) : (
                <ChartPanel
                    forPrint={props.forPrint}
                    type={1}
                    icon={<LineChartOutlined />}
                    title={"Balance & Equity"}
                    subTitle={"Overall balance & equity summary for current account"}
                    {...(!hasAnyKey(props.dateRangeFilter) && {
                        titleExtra: {
                            form: chartFilterForm,
                            content: (
                                <>
                                    <FormComponent
                                        label={""}
                                        name={"date"}
                                        extra={{
                                            type: ComponentType.daterange,
                                            value: "",
                                            inputProps: {
                                                disabledDate: (current: any) => current && current > moment().endOf("day"),
                                                ranges: {
                                                    Default: [moment().subtract(3, "months"), moment()],
                                                },
                                            },
                                            dateFormat: "YYYY-MM-DD",
                                        }}
                                    />
                                </>
                            ),
                            onValueChanged: onFilterValueCallback,
                        },
                    })}
                >
                    <div className="chart">
                        {data?.length > 0 || data2.length > 0 ? (
                            <DualAxes
                                autoFit={true}
                                padding={[20, 42, 25, 42]}
                                height={550}
                                {...{
                                    data: [finalData, finalData2],
                                    xField: "date",
                                    yField: ["value", "count"],
                                    geometryOptions: [
                                        {
                                            geometry: "line",
                                            seriesField: "name",
                                        },
                                        {
                                            geometry: "column",
                                            isGroup: true,
                                            seriesField: "name",
                                        },
                                    ],
                                    ...(props.forPrint
                                        ? {
                                              xAxis: {
                                                  nice: true,
                                                  label: {
                                                      autoRotate: true,
                                                  },
                                                  tickLine: {
                                                      alignTick: true,
                                                  },
                                              },
                                          }
                                        : {
                                              xAxis: {
                                                  nice: true,
                                                  label: {
                                                      autoRotate: true,
                                                  },
                                                  tickLine: {
                                                      alignTick: true,
                                                  },
                                              },
                                              slider: {
                                                  start: 0,
                                                  end: 1,
                                                  padding: [100, 0, 0, 0],
                                              },
                                          }),
                                    yAxis: {
                                        value: {
                                            tickMethod: "d3-linear",
                                            tickCount: 8,
                                            label: {
                                                formatter: (v: any) => currencyRender(v, -1, 0),
                                            },
                                            title: {
                                                text: "Balance & Equity",
                                            },
                                        },
                                        count: {
                                            tickMethod: "d3-linear",
                                            tickCount: 8,
                                            label: {
                                                formatter: (v: any) => currencyRender(v, -1, 0),
                                            },
                                            title: {
                                                text: "Deposit & Withdrawal",
                                            },
                                        },
                                    },
                                    tooltip: {
                                        formatter: (datum: Datum) => {
                                            if (Object.keys(datum).indexOf("count") > -1) {
                                                return { name: datum.name, value: currencyRender(datum.count, 2, 0) };
                                            }
                                            return { name: datum.name, value: currencyRender(datum.value, 2, 0) };
                                        },
                                    },
                                }}
                            />
                        ) : (
                            <>
                                <EmptyData />
                            </>
                        )}
                    </div>
                </ChartPanel>
            )}
        </>
    );
};

export default BalanceEquityChart;
