import { useCallback, useEffect, useMemo, useState } from "react";
import { ToObjectWithKey } from "../../utils/array";
import { fillArrayEqually, hasAnyKey } from "../../utils/object";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Empty from "../Common/Empty";
import { DND_ACTION } from "../../constants";
import { DnDCallback } from "../../constants/type";

export interface MultiColumnDnDProps {
    propertyId: string;
    data: any[];
    options: {
        propertyId: string;
        columnPerRow?: number;
    };
    defaultArrangement?: any;
    itemTemplate?: (
        item: any,
        index: number,
        provider: any,
        isDragDisabled: boolean,
        startSwitching: boolean,
        callback?: DnDCallback
    ) => React.ReactNode;
    isDragDisabled?: boolean;
    startSwitching: boolean;
    callback?: DnDCallback;
}

const MultiColumnDnD = (props: MultiColumnDnDProps) => {
    const [markupData, setMarkupData] = useState<any>({ group: {}, groupOrder: props.defaultArrangement ? { ...props.defaultArrangement } : {} });
    const isDragDisabled = useMemo(() => (props.isDragDisabled === undefined ? true : props.isDragDisabled), [props.isDragDisabled]);

    const reorderList = (list: any, startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = useCallback(
        (result: any) => {
            if (!result.destination) {
                return;
            }

            // reordering in same list
            if (result.source.droppableId === result.destination.droppableId) {
                if (result.source.index === result.destination.index) return;

                // updating column entry
                setMarkupData((prev: any) => ({
                    ...prev,
                    groupOrder: {
                        ...prev.groupOrder,
                        [result.source.droppableId]: reorderList(
                            markupData.groupOrder[result.source.droppableId],
                            result.source.index,
                            result.destination.index
                        ),
                    },
                }));
                return;
            }

            // moving between lists
            const sourceGroup: string[] = markupData.groupOrder[result.source.droppableId];
            const destinationGroup: string[] = markupData.groupOrder[result.destination.droppableId];
            const item = sourceGroup[result.source.index];

            sourceGroup.splice(result.source.index, 1);
            destinationGroup.splice(result.destination.index, 0, item);
            setMarkupData((prev: any) => ({
                ...prev,
                groupOrder: {
                    ...prev.groupOrder,
                    [result.source.droppableId]: sourceGroup,
                    [result.destination.droppableId]: destinationGroup,
                },
            }));
        },
        [markupData]
    );

    const _columnPerRow = useMemo(() => (props.options.columnPerRow === undefined ? 4 : props.options.columnPerRow), [props.options.columnPerRow]);
    const _itemTemplate = useMemo(() => (props.itemTemplate ? props.itemTemplate : () => <></>), [props.itemTemplate]);

    useEffect(() => {
        if (props.data.length > 0) {
            let _finalObj: any = {},
                _colPrRw: number = props.options.columnPerRow === undefined ? 4 : props.options.columnPerRow,
                _dataOrderByKey: string[] = props.data.map(x => x[props.options.propertyId]);

            if (hasAnyKey(markupData.groupOrder)) {
                let count = 1,
                    _tmpObj = Object.keys(markupData.groupOrder).reduce((tmpArr: any, x: string) => {
                        if (count > _colPrRw) return tmpArr;
                        if (markupData.groupOrder[x].length < 1) return tmpArr;

                        tmpArr[`${count}`] = markupData.groupOrder[x].filter((y: string) => _dataOrderByKey.includes(y));
                        count++;
                        return tmpArr;
                    }, {}),
                    defaultAllOrderedKeys = Object.keys(_tmpObj).reduce((tmpArr: string[], x: string) => tmpArr.concat(_tmpObj[x]), []);

                for (let index = count; index <= _colPrRw; index++) {
                    _tmpObj[`${index}`] = [];
                }

                _finalObj = fillArrayEqually(
                    _tmpObj,
                    _dataOrderByKey.filter(x => !defaultAllOrderedKeys.includes(x))
                );
            } else {
                let _tmpObj: any = {};
                for (let index = 1; index <= _colPrRw; index++) {
                    _tmpObj[`${index}`] = [];
                }
                _finalObj = fillArrayEqually(_tmpObj, _dataOrderByKey);
            }

            setMarkupData({
                group: ToObjectWithKey(props.data, props.options.propertyId),
                groupOrder: _finalObj,
            });
        } else {
            setMarkupData({ group: {}, groupOrder: {} });
        }
    }, [props.data]);

    useEffect(() => {
        hasAnyKey(markupData.groupOrder) &&
            props.callback &&
            props.callback(DND_ACTION.DRAGGABLE_END, { id: props.propertyId, newOrder: markupData.groupOrder });
    }, [markupData]);

    return (
        <div className="multi-col-dnd-outer-container">
            {hasAnyKey(markupData.groupOrder) && hasAnyKey(markupData.group) ? (
                <DragDropContext onDragEnd={onDragEnd}>
                    <div className={`group-container col-${_columnPerRow}`}>
                        {Object.keys(markupData.groupOrder).map((groupId: string) => (
                            <Droppable
                                key={`${props.propertyId}-${groupId}`}
                                droppableId={groupId}
                                direction="vertical"
                                isDropDisabled={isDragDisabled}
                            >
                                {provided => (
                                    <div className="group-inner-container" {...provided.droppableProps} ref={provided.innerRef}>
                                        <InnerList
                                            propertyId={`${props.propertyId}-${groupId}`}
                                            itemList={markupData.groupOrder[groupId].map((x: string, xIdx: number) => ({
                                                ...markupData.group[x],
                                                id: x,
                                            }))}
                                            itemTemplate={_itemTemplate}
                                            isDragDisabled={isDragDisabled}
                                            startSwitching={props.startSwitching}
                                            {...(props.callback && { callback: props.callback })}
                                        />
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        ))}
                    </div>
                </DragDropContext>
            ) : (
                <>
                    <Empty />
                </>
            )}
        </div>
    );
};

interface InnerListProps {
    propertyId: string;
    itemList: any[];
    itemTemplate: (
        item: any,
        index: number,
        provider: any,
        isDragDisabled: boolean,
        startSwitching: boolean,
        callback?: DnDCallback
    ) => React.ReactNode;
    isDragDisabled: boolean;
    startSwitching: boolean;
    callback?: DnDCallback;
}

const InnerList = (props: InnerListProps) => {
    const itemList = useMemo(
        () =>
            props.itemList.map((x: any, xIdx: number) => (
                <Draggable key={`${props.propertyId}-${x.id}`} draggableId={x.id} index={xIdx} isDragDisabled={props.isDragDisabled}>
                    {provided => (
                        <div ref={provided.innerRef} {...provided.draggableProps}>
                            {props.itemTemplate(
                                x,
                                xIdx,
                                provided,
                                props.isDragDisabled,
                                props.startSwitching,
                                props.callback ? props.callback : undefined
                            )}
                        </div>
                    )}
                </Draggable>
            )),
        [props.itemList]
    );
    return <>{itemList}</>;
};

export default MultiColumnDnD;
