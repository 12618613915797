import { Tag } from "antd";
import ChartPanel from "../chartPanel";
import { BarChartOutlined } from "@ant-design/icons";
import { useRef } from "react";
import { Datum, Pie } from "@ant-design/charts";
import { AdvanceAnalysisFilterParams } from "../../advanceAnalysis";
import { currencyRender } from "../../../../../utils/Common";
import EmptyData from "../../../../../components/Common/Empty";
import CustomSkeleton from "../../../../../components/Common/Skeleton";

export interface SourceDistributionSummaryProps extends AdvanceAnalysisFilterParams {
    forPrint?: boolean;
    isLoading: boolean;
    data: any[];
}

const SourceDistributionSummary = (props: SourceDistributionSummaryProps) => {
    const ref = useRef<HTMLHeadingElement>(null);

    const getConfig = (currentData: any) => ({
        appendPadding: 10,
        data: currentData,
        angleField: "value",
        colorField: "key",
        radius: 0.8,
        label: {
            type: "outer",
            content: "{name} {percentage}",
        },
        interactions: [
            {
                type: "pie-legend-active",
            },
            {
                type: "element-active",
            },
        ],
        tooltip: {
            formatter: (datum: Datum) => {
                return {
                    name: datum.key,
                    value: currencyRender(datum.value),
                };
            },
        },
    });

    return (
        <>
            {props.isLoading ? (
                <ChartPanel
                    className="aa-c"
                    forPrint={props.forPrint}
                    type={1}
                    icon={<BarChartOutlined />}
                    title={"Source Distribution"}
                    subTitle={"Overall source distribution"}
                >
                    <CustomSkeleton rows={10} />
                </ChartPanel>
            ) : (
                <ChartPanel
                    className="aa-c"
                    forPrint={props.forPrint}
                    type={1}
                    icon={<BarChartOutlined />}
                    title={"Source Distribution"}
                    subTitle={"Overall source distribution"}
                    rightExtra={
                        props.data.length > 0
                            ? [
                                  {
                                      title: "Source Distribution Count",
                                      content: (
                                          <div className="tag-summary-desc-panel">
                                              {props.data &&
                                                  props.data.map((x, index) => {
                                                      return (
                                                          <div className="value-small-tag-div" key={`ts-${index}`}>
                                                              <div className="title">{x.key}</div>
                                                              <div className="tagDiv">
                                                                  <Tag>{currencyRender(x.value)}</Tag>
                                                              </div>
                                                          </div>
                                                      );
                                                  })}
                                          </div>
                                      ),
                                  },
                              ]
                            : []
                    }
                >
                    {props.data.length > 0 ? (
                        props.forPrint ? (
                            <Pie {...getConfig(props.data)} height={300} />
                        ) : (
                            <div ref={ref} className="pie-chart">
                                <Pie {...getConfig(props.data)} width={ref.current?.clientWidth} />
                            </div>
                        )
                    ) : (
                        <>
                            <EmptyData />
                        </>
                    )}
                </ChartPanel>
            )}
        </>
    );
};

export default SourceDistributionSummary;
