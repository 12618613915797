import { HomeOutlined } from "@ant-design/icons";
import { Form, Row, Col, Button } from "antd";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FormComponent } from "../../../../components/FormComponent";
import LoadingComponent from "../../../../components/Loading";
import SitePageHeader from "../../../../components/PageHeader";
import { SUCCESS_FAILED, ComponentType } from "../../../../constants";
import { REQUIRED_FIELD } from "../../../../constants/errorMessage";
import { apiRequest } from "../../../../services/apiConfig";
import { APIs } from "../../../../services/apis";
import { ErrorCatchValidator, ErrorMessageHandler } from "../../../../utils/Common";
import { ServerState } from "../../ServersPage/ServerCreateEditPage";

const MetricGlossaryEditPage = () => {
    let navigate = useNavigate();
    let location = useLocation();
    let com_state: ServerState = location.state as ServerState;
    const [currentState] = useState<ServerState>(com_state || { action: "add", data: null });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [metricGlossaryForm] = Form.useForm();

    const onSubmit = (values: any) => {
        setIsLoading(true);
        apiRequest(APIs.UPDATE_METRIC_GLOSSARY, values)
            .then(() => {
                ErrorMessageHandler("Existing metric", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                navigate("/siteadmin/appconfig/metricglossary");
            })
            .catch((error) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("existing metric", SUCCESS_FAILED.FAILED_UPDATE_DATA, err))
            )
            .finally(() => setIsLoading(false));
    };

    return (
        <SitePageHeader
            title={"Edit Metric Information"}
            routes={[
                {
                    path: "/siteadmin/appconfig/metricglossary",
                    breadcrumbName: "Metric Glosarry",
                    icon: <HomeOutlined />,
                },
                {
                    path: "",
                    breadcrumbName: "Edit Metric Information",
                },
            ]}
            onBack={() => navigate("/siteadmin/appconfig/metricglossary")}
        >
            {isLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Submitting..." />
                </div>
            ) : (
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    form={metricGlossaryForm}
                    layout="horizontal"
                    initialValues={currentState.data}
                    onFinish={onSubmit}
                >
                    <Row>
                        <Col span={15}>
                            <FormComponent label={""} name={"metricId"} extra={{ type: ComponentType.hidden, value: "" }} />
                            <FormComponent
                                label="Display Name"
                                name="displayName"
                                extra={{
                                    type: ComponentType.labelOnly,
                                    value: "",
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Description"
                                name="description"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div className="step-btns-group">
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            )}
        </SitePageHeader>
    );
};

export default MetricGlossaryEditPage;
