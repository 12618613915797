import { Button, Card, Col, Form, FormInstance, Popconfirm, Row, Tooltip } from "antd";
import EmptyData from "../../../../components/Common/Empty";
import { CloseOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { ComponentType, TOXIC_CLIENT_CHECK_BREACH_SEVERITY } from "../../../../constants";
import { FormComponent } from "../../../../components/FormComponent";
import { REQUIRED_FIELD } from "../../../../constants/errorMessage";
import { GetObjectCountByKey, ToOptionTypeList } from "../../../../utils/array";
import { BrandsList, KeyValuePair, ToxicClientAlarmConfigDetailsProps } from "../../../../constants/type";
import { isEmptyOrNull } from "@/utils/string";

export interface ToxicClientAlarmMetricPanelBasedProps {
    alarmConfigForm: FormInstance;
    dataItem: ToxicClientAlarmConfigDetailsProps;
    metricName: string;
    brands: BrandsList[];
}

const ToxicClientAlarmMetricPanel = (props: ToxicClientAlarmMetricPanelBasedProps) => {
    const fieldName = `alarmConfigDetails_${props.dataItem.tabKey}_${props.dataItem.metricId}`;
    const breachSeverityOptions = ToOptionTypeList(TOXIC_CLIENT_CHECK_BREACH_SEVERITY).filter(x => x.value !== 0);

    const filteredData = props.alarmConfigForm.getFieldValue("alarmConfigDetails").filter((item: { metricId: any }) => {
        return Number(item.metricId) === Number(props.dataItem.metricId);
    });

    const groupByMetricIdAndBrandIds = (data: any[]) => {
        const groupedData = data.reduce((acc, item) => {
            // Create a unique key based on metricId and brandIds
            const key = `${item.metricId}_${item.brandIds.sort().join(",")}`;

            // If the group doesn't exist, initialize it
            if (!acc[key]) {
                acc[key] = {
                    metricId: item.metricId,
                    brandIds: [...item.brandIds],
                    thres: [],
                };
            }

            // Add the breachSeverity and threshold to the thres array
            acc[key].thres.push({
                breachSeverity: item.breachSeverity,
                threshold: item.threshold,
            });

            return acc;
        }, {});

        // Convert the grouped object back into an array
        return Object.values(groupedData);
    };

    const transformedData: any[] = groupByMetricIdAndBrandIds(filteredData);

    const onAddThreshold = (metricId: number, brandIds: number[]) => {
        // Clone the existing thresholds
        let updatedThresholds =
            props.alarmConfigForm.getFieldValue(fieldName) && props.alarmConfigForm.getFieldValue(fieldName).length > 0
                ? [...props.alarmConfigForm.getFieldValue(fieldName)]
                : [];

        // Find if there is an existing entry for the given metricId and brandIds
        const existingIndex = updatedThresholds.findIndex(
            item =>
                item.metricId === metricId &&
                item.brandIds.length === brandIds.length &&
                item.brandIds.every((id: number, idx: number) => id === brandIds[idx])
        );

        // If found, add a new breachSeverity and threshold to the existing thres array
        if (existingIndex !== -1) {
            updatedThresholds[existingIndex].thres.push({
                breachSeverity: undefined,
                threshold: undefined,
            });
        } else {
            // If not found, create a new entry with the metricId, brandIds, and thres array
            updatedThresholds.push({
                metricId: metricId,
                brandIds: brandIds,
                thres: [{ breachSeverity: undefined, threshold: undefined }],
            });
        }

        // Set the updated thresholds back to the form field
        props.alarmConfigForm.setFieldsValue({ [fieldName]: updatedThresholds });
    };

    const onAddNewBrand = (metricId: number) => {
        // Get the existing form values for the field
        let currentData = props.alarmConfigForm.getFieldValue(fieldName) || [];

        // Create a new entry with the metricId, empty brandIds, and default threshold values
        const newBrandEntry = {
            metricId: metricId,
            brandIds: [], // Empty brand selection
            thres: [{ breachSeverity: undefined, threshold: undefined }], // Initialize new threshold
        };

        // Push the new entry to the current data array
        const updatedBrand = [newBrandEntry, ...currentData];

        // Update the form field with the new array
        props.alarmConfigForm.setFieldsValue({ [fieldName]: updatedBrand });
    };

    return (
        <Form.List name={fieldName} initialValue={transformedData}>
            {(fields, { add, remove }) => (
                <Card
                    size="small"
                    title={props.metricName}
                    className="big-card-container"
                    extra={
                        <Button
                            type="dashed"
                            icon={<PlusOutlined style={{ color: "#7e7e7e" }} />}
                            onClick={() => onAddNewBrand(props.dataItem.metricId)}
                        >
                            Add Brand Group
                        </Button>
                    }
                >
                    <div>
                        {fields.length === 0 ? (
                            <EmptyData />
                        ) : (
                            fields.map((field, index) => {
                                const fieldData = props.alarmConfigForm.getFieldValue(fieldName)[index];
                                if (!fieldData) return null;

                                const selectedBrandIds = props.alarmConfigForm
                                    .getFieldValue(fieldName)
                                    .reduce((acc: number[], curr: any, currIndex: number) => {
                                        if (currIndex !== index) {
                                            // Exclude the current field
                                            return acc.concat(curr.brandIds);
                                        }
                                        return acc;
                                    }, []);

                                return (
                                    <Card
                                        key={`${field.key}-${index}`} // Ensure unique key
                                        title={
                                            <FormComponent
                                                label="Brands"
                                                name={[field.name, "brandIds"]}
                                                extra={{
                                                    type: ComponentType.dropdown,
                                                    value: props.brands
                                                        .filter(x => !selectedBrandIds.includes(x.id))
                                                        .map(x => ({
                                                            text: x.brand,
                                                            value: x.id,
                                                        })),
                                                    inputProps: {
                                                        mode: "multiple",
                                                        allowClear: true,
                                                        onChange: (selectedValues: number[]) => {
                                                            // Update form state on change
                                                            const updatedFields = props.alarmConfigForm.getFieldValue(fieldName);
                                                            updatedFields[index].brandIds = selectedValues;
                                                            props.alarmConfigForm.setFieldsValue({ [fieldName]: updatedFields });

                                                            // Trigger a re-render by updating the state
                                                            props.alarmConfigForm.validateFields([fieldName]);
                                                        },
                                                    },
                                                    itemProps: {
                                                        style: {
                                                            marginBottom: 0,
                                                        },
                                                    },
                                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                                }}
                                            />
                                        }
                                        size="small"
                                        style={{ marginTop: 5 }}
                                        actions={[
                                            <Popconfirm
                                                key={`thres-brand-del-${field.key}`}
                                                title="Confirm to delete brand group?"
                                                onConfirm={() => {
                                                    // Remove the entire brand and its thresholds from the list
                                                    const updatedThresholds = props.alarmConfigForm
                                                        .getFieldValue(fieldName)
                                                        .filter((_: any, idx: number) => idx !== field.name);

                                                    // Update the form field with the new list
                                                    props.alarmConfigForm.setFieldsValue({ [fieldName]: updatedThresholds });
                                                }}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <Button type="link" danger icon={<DeleteOutlined />}>
                                                    Delete Brand Group
                                                </Button>
                                            </Popconfirm>,
                                            <Button
                                                type="link"
                                                icon={<PlusOutlined />}
                                                onClick={() => onAddThreshold(fieldData.metricId, fieldData.brandIds)}
                                                disabled={
                                                    fieldData.thres && fieldData.thres.length === breachSeverityOptions.length // Disable if all options are selected
                                                }
                                            >
                                                Add Threshold
                                            </Button>,
                                        ]}
                                    >
                                        {fieldData.thres && fieldData.thres.length > 0 ? (
                                            fieldData.thres.map((threshold: any, idx: any) => {
                                                // Collect all breachSeverity values (assuming it's not an array)
                                                const tempSelectedBreachSeverity = fieldData.thres
                                                    .filter((_: any, i: any) => i !== idx) // Exclude the current index
                                                    .map((item: any) => item.breachSeverity); // Directly access breachSeverity
                                                // Filter out the already selected options
                                                const availableBreachSeverityOptions = breachSeverityOptions.filter(
                                                    bs => !tempSelectedBreachSeverity.includes(bs.value)
                                                );
                                                return (
                                                    <Row key={`thres-${idx}`}>
                                                        <Tooltip title="Breach Severity">
                                                            <Col span={10}>
                                                                <FormComponent
                                                                    label=""
                                                                    name={[field.name, "thres", idx, "breachSeverity"]}
                                                                    extra={{
                                                                        type: ComponentType.dropdown,
                                                                        value: availableBreachSeverityOptions,
                                                                        rules: [
                                                                            {
                                                                                validator: (_: any, value: any) => {
                                                                                    if (isEmptyOrNull(value)) return Promise.reject(REQUIRED_FIELD);

                                                                                    if (
                                                                                        !breachSeverityOptions.some(
                                                                                            (x: KeyValuePair) => `${x.value}` === `${value}`
                                                                                        )
                                                                                    )
                                                                                        return Promise.reject("Invalid selected metric.");

                                                                                    return Promise.resolve();
                                                                                },
                                                                            },
                                                                        ],
                                                                        itemProps: {
                                                                            style: {
                                                                                marginBottom: 5,
                                                                            },
                                                                        },
                                                                        inputProps: {
                                                                            placeholder: "Please select Breach Severity",
                                                                        },
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Tooltip>
                                                        <Tooltip title="Threshold">
                                                            <Col span={12}>
                                                                <FormComponent
                                                                    label=""
                                                                    name={[field.name, "thres", idx, "threshold"]}
                                                                    extra={{
                                                                        type: ComponentType.number,
                                                                        value: threshold.threshold,
                                                                        rules: [{ required: true, message: REQUIRED_FIELD }],
                                                                        itemProps: {
                                                                            style: {
                                                                                marginBottom: 5,
                                                                            },
                                                                        },
                                                                        inputProps: {
                                                                            placeholder: "Please insert Threshold",
                                                                        },
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Tooltip>
                                                        <Col span={2}>
                                                            <Tooltip title="Delete Threshold">
                                                                <Button
                                                                    type="link"
                                                                    danger
                                                                    icon={<CloseOutlined />}
                                                                    onClick={() => {
                                                                        // Get the current form value
                                                                        const updatedThresholds = props.alarmConfigForm.getFieldValue(fieldName);

                                                                        // Update only the thres field by removing the correct index
                                                                        updatedThresholds[field.name].thres = updatedThresholds[
                                                                            field.name
                                                                        ].thres.filter((_: any, thresIdx: number) => thresIdx !== idx);

                                                                        // Update the form field with the new thresholds array
                                                                        props.alarmConfigForm.setFieldsValue({ [fieldName]: updatedThresholds });
                                                                    }}
                                                                />
                                                            </Tooltip>
                                                        </Col>
                                                    </Row>
                                                );
                                            })
                                        ) : (
                                            <EmptyData />
                                        )}
                                    </Card>
                                );
                            })
                        )}
                    </div>
                </Card>
            )}
        </Form.List>
    );
};

export default ToxicClientAlarmMetricPanel;
