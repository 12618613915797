import { Form, Row, Col } from "antd";
import { FormComponent } from "../../../../components/FormComponent";
import { ComponentType, RISK_SCORE_TIER_OPERATOR_TYPE } from "../../../../constants";
import { REQUIRED_FIELD } from "../../../../constants/errorMessage";
import { ToOptionTypeList } from "../../../../utils/array";

export interface createEditTierComponentProps {
    form: any;
    data: any;
}

const CreateEditTierComponent = (props: createEditTierComponentProps) => {
    const selectedOperatorType = Form.useWatch<number>("operator", props.form);

    return (
        <div>
            <Form form={props.form} layout="horizontal" initialValues={props.data}>
                <Row>
                    <Col span={1}></Col>
                    <Col span={10}>
                        <FormComponent
                            label=""
                            name="typeId"
                            extra={{
                                type: ComponentType.hidden,
                                value: "",
                            }}
                        />
                        <FormComponent
                            label=""
                            name="aggregateId"
                            extra={{
                                type: ComponentType.hidden,
                                value: "",
                            }}
                        />
                        <FormComponent
                            label=""
                            name="tierId"
                            extra={{
                                type: ComponentType.hidden,
                                value: "",
                            }}
                        />
                        <FormComponent
                            label="Value"
                            name="operator"
                            extra={{
                                type: ComponentType.dropdown,
                                rules: [
                                    {
                                        required: true,
                                        message: REQUIRED_FIELD,
                                    },
                                ],
                                value: ToOptionTypeList(RISK_SCORE_TIER_OPERATOR_TYPE),
                            }}
                        />
                    </Col>
                    <Col span={selectedOperatorType === 3 ? 6 : 12}>
                        <FormComponent
                            label=""
                            name="fromValue"
                            extra={{
                                type: ComponentType.text,
                                rules: [
                                    {
                                        required: true,
                                        message: REQUIRED_FIELD,
                                    },
                                ],
                                value: "",
                                inputProps:
                                    selectedOperatorType === 3
                                        ? {
                                            placeholder: "from",
                                        }
                                        : {},
                            }}
                        />
                    </Col>
                    {selectedOperatorType === 3 && (
                        <Col span={6}>
                            <FormComponent
                                label=""
                                name="toValue"
                                extra={{
                                    type: ComponentType.text,
                                    rules: [
                                        {
                                            required: true,
                                            message: REQUIRED_FIELD,
                                        },
                                    ],
                                    value: "",
                                    inputProps: {
                                        placeholder: "to",
                                    },
                                }}
                            />
                        </Col>
                    )}
                    <Col span={1}></Col>
                </Row>
                <Row>
                    <Col span={1}></Col>
                    <Col span={22}>
                        <FormComponent
                            label="Point"
                            name="point"
                            extra={{
                                type: ComponentType.text,
                                value: "",
                                rules: [
                                    {
                                        required: true,
                                        message: REQUIRED_FIELD,
                                    },
                                ],
                                inputProps: {
                                    addonAfter: "Point(s)",
                                },
                            }}
                        />
                    </Col>
                    <Col span={1}></Col>
                </Row>
            </Form>
        </div>
    );
};

export default CreateEditTierComponent;
