import { Collapse } from "antd";
import { useMemo } from "react";
import HFTReportDataTable from "./HFTReportDataTable";
import { HFTResultFormatted, HFTThreshold } from "./index";

const { Panel } = Collapse;

type SearchTabProps = {
    hftResults: HFTResultFormatted[]
    hftThreshold: HFTThreshold
    isLoading: boolean
    refreshData: () => void
}
type SearchTabDataTableColumns = HFTResultFormatted
const SearchTab = ({ hftResults, hftThreshold, isLoading, refreshData }: SearchTabProps) => {
    const [dataLessThan2Min, dataBetween2to10Min, dataBetween10to30Min] = useMemo(() => {
        const lessThan2Min: SearchTabDataTableColumns[] = []
        const between2to10Min: HFTResultFormatted[] = []
        const between10to30Min: HFTResultFormatted[] = []
        hftResults.forEach((item)=>{
          if(item.count! <= 0) return
          if(item.type! === 0) { lessThan2Min.push(item) }
          if(item.type! === 1) { between2to10Min.push(item) }
          if(item.type! === 2) { between10to30Min.push(item) }
        })
        return [lessThan2Min, between2to10Min, between10to30Min]
    }, [hftResults]);


    return (
        <div className="hft-report-search">
            <Collapse defaultActiveKey={['1']}>
                <Panel
                    header="Holding time less than 2 minutes on today profit."
                    key="1"
                >
                    <HFTReportDataTable 
                        refreshData={refreshData}
                        isLoading={isLoading}
                        data={dataLessThan2Min} 
                        rowKeyProperty={"login"} 
                        hftThreshold={hftThreshold}
                        min={2}
                        searchTabColumnType="profit"
                    />
                </Panel>
            </Collapse>
            <Collapse>
                <Panel
                    header="Holding time between 2 minutes and 10 minutes on today profit."
                    key="1"
                >
                    <HFTReportDataTable 
                        refreshData={refreshData}
                        isLoading={isLoading}
                        data={dataBetween2to10Min} 
                        rowKeyProperty={"login"} 
                        hftThreshold={hftThreshold}
                        min={10}
                        searchTabColumnType="profit"
                    />
                </Panel>
            </Collapse>
            <Collapse>
                <Panel
                    header="Holding time between 10 minutes and 30 minutes on today profit."
                    key="1"
                >
                    <HFTReportDataTable 
                        refreshData={refreshData}
                        isLoading={isLoading}
                        data={dataBetween10to30Min} 
                        rowKeyProperty={"login"} 
                        hftThreshold={hftThreshold}
                        min={30}
                        searchTabColumnType="profit"
                    />
                </Panel>
            </Collapse>  
            <Collapse>
                <Panel
                    header="Holding time less than 2 minutes on Count."
                    key="1"
                >
                    <HFTReportDataTable 
                        refreshData={refreshData}
                        isLoading={isLoading}
                        data={dataLessThan2Min} 
                        rowKeyProperty={"login"} 
                        hftThreshold={hftThreshold}
                        min={2}
                        searchTabColumnType="amount"
                    />
                </Panel>
            </Collapse>
            <Collapse>
                <Panel
                    header="Holding time between 2 minutes and 10 minutes on Count."
                    key="1"
                >
                    <HFTReportDataTable 
                        refreshData={refreshData}
                        isLoading={isLoading}
                        data={dataBetween2to10Min} 
                        rowKeyProperty={"login"} 
                        hftThreshold={hftThreshold}
                        min={10}
                        searchTabColumnType="amount"
                    />
                </Panel>
            </Collapse>
            <Collapse>
                <Panel
                    header="Holding time between 10 minutes and 30 minutes on Count."
                    key="1"
                >
                    <HFTReportDataTable 
                        refreshData={refreshData}
                        isLoading={isLoading}
                        data={dataBetween10to30Min} 
                        rowKeyProperty={"login"} 
                        hftThreshold={hftThreshold}
                        min={30}
                        searchTabColumnType="amount"
                    />
                </Panel>
            </Collapse>  
        </div>
    );
};

export default SearchTab;
