import { FormComponent } from "@/components/FormComponent";
import { ComponentType, SUCCESS_FAILED } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { Modal, Form, Row, Col, message } from "antd";
import { FundingFeeSettingProps } from ".";
import { useCallback, useEffect, useMemo } from "react";
import { KeyValuePair } from "@/constants/type";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { APIs } from "@/services/apis";
import { ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";

export interface FundingFeeSettingsCreditEditModalProps {
    isModalVisible: boolean;
    callback: (type: number, data: any) => void;
    data: undefined | FundingFeeSettingProps;
}

export enum FundingFeeSettingsCreditEditModalCallbackKey {
    Close = 0,
    SubmitForm = 1,
    SubmitDone = 2,
}

const FundingFeeSettingsCreditEditModal = (props: FundingFeeSettingsCreditEditModalProps) => {
    const [executeForm] = Form.useForm();

    const modalTitle = useMemo(() => (props.data === undefined ? "Create Funding Fee Setting" : "Edit Funding Fee setting"), [props.data]);

    const receiveHourArr: KeyValuePair[] = useMemo(
        () =>
            Array.from(Array(24).keys())
                .map((x: number) => `${x}`)
                .map((x: string) => ({ text: `${x.padStart(2, "0")}:00`, value: x } as KeyValuePair)),
        []
    );

    const submitForm = useCallback(
        (params: any) => {
            if (params.hasOwnProperty("receiveHour")) {
                params.receiveHour = params.receiveHour.reduce((acc: number, curr: string) => {
                    acc += 1 << parseInt(curr, 10);
                    return acc;
                }, 0);
            }

            if (props.data === undefined) {
                plainAxiosInstance
                    .post(APIs.RISK_TOOL.CREATE_FUNDING_FEE_SETTINGS, params)
                    .then(res => {
                        if (res.status === 200) {
                            ErrorMessageHandler("funding fee setting", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                        } else if (res.status === 409) {
                            ErrorMessageHandler("funding fee setting", SUCCESS_FAILED.FAILED_CREATE_DATA, { message: "Data already exists!" });
                        } else {
                            ErrorMessageHandler("funding fee setting", SUCCESS_FAILED.FAILED_CREATE_DATA, { message: res.data.message });
                        }
                    })
                    .catch((error: any) =>
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("funding fee setting", SUCCESS_FAILED.FAILED_CREATE_DATA, err))
                    )
                    .finally(() => props.callback(FundingFeeSettingsCreditEditModalCallbackKey.SubmitDone, null));
            } else {
                plainAxiosInstance
                    .put(APIs.RISK_TOOL.UPDATE_FUNDING_FEE_SETTINGS, params)
                    .then(res => {
                        if (res.status === 202 || res.status === 200) {
                            ErrorMessageHandler("funding fee setting", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                        } else {
                            ErrorMessageHandler("funding fee setting", SUCCESS_FAILED.FAILED_UPDATE_DATA, { message: res.data.message });
                        }
                    })
                    .catch((error: any) =>
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("funding fee setting", SUCCESS_FAILED.FAILED_UPDATE_DATA, err))
                    )
                    .finally(() => props.callback(FundingFeeSettingsCreditEditModalCallbackKey.SubmitDone, null));
            }
        },
        [props.data]
    );

    useEffect(() => {
        if (props.isModalVisible) {
            if (props.data !== undefined) {
                let editParams: any = { ...props.data },
                    receiveHour = props.data.receiveHour || 0;

                if (editParams.hasOwnProperty("receiveHour")) {
                    editParams.receiveHour = Array.from({ length: 24 }, (_, index) => ((receiveHour >> index) & 1) === 1)
                        .map((isChecked: boolean, index: number) => (isChecked ? index.toString() : null))
                        .filter(item => item !== null);
                } else {
                    editParams.receiveHour = [];
                }

                executeForm.setFieldsValue(editParams);
            } else {
                executeForm.resetFields();
            }
        }
    }, [props.isModalVisible]);

    return (
        <Modal
            style={{ top: 10 }}
            width={"40vw"}
            title={modalTitle}
            open={props.isModalVisible}
            onOk={() => {
                executeForm
                    .validateFields()
                    .then((values: any) => {
                        submitForm(values);
                        props.callback(FundingFeeSettingsCreditEditModalCallbackKey.SubmitForm, null);
                    })
                    .catch((err: any) => console.log(err));
            }}
            onCancel={() => props.callback(FundingFeeSettingsCreditEditModalCallbackKey.Close, null)}
            maskClosable={false}
        >
            <Form form={executeForm} layout="horizontal" labelCol={{ span: 7 }}>
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Symbol"
                            name="symbol"
                            extra={{
                                type: ComponentType.text,
                                value: "",
                                rules: [
                                    { required: true, message: REQUIRED_FIELD },
                                    { pattern: /^[a-zA-Z0-9.]{2,10}$/, message: "Please enter 2-10 characters english and number" },
                                ],
                                itemProps: {
                                    extra: <span style={{ color: "#006df4" }}>**This column can't edit after added</span>,
                                },
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Exchange Symbol"
                            name="symbolMap"
                            extra={{
                                type: ComponentType.text,
                                value: "",
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Receive Hour"
                            name="receiveHour"
                            extra={{
                                type: ComponentType.dropdown,
                                value: receiveHourArr,
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                                inputProps: {
                                    mode: "multiple",
                                },
                            }}
                        />
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default FundingFeeSettingsCreditEditModal;
