import React, { useEffect } from "react";
import { Table } from "antd";

const { Column, ColumnGroup } = Table;

interface RollOverComparisonTableProps {
    queryComparisonData: any;
    totalSupplementary: number;
    totalDeduction: number;
    totalSupplementaryDeduction: number;
    totalPositiveProfit: number;
    totalNegativeProfit: number;
    totalProfit: number;
}

const RollOverComparisonTable = (props: RollOverComparisonTableProps) => {
    useEffect(() => {
        props.queryComparisonData();
    }, []);

    const data = [
        {
            toatalSupplementaryDeduction: "(" + props.totalSupplementary + ") + (" +  props.totalDeduction + ") = " + props.totalSupplementaryDeduction,
            toatalProfit: "(" + props.totalPositiveProfit + ") + (" +  props.totalNegativeProfit + ") = " + props.totalProfit ,
        },
    ];

    return (
        <div>
            <Table dataSource={data} bordered pagination={false} size="small">
                <ColumnGroup title="Total">
                    <Column
                        align="center"
                        title="Supplementary + Deduction"
                        dataIndex="toatalSupplementaryDeduction"
                        key="toatalSupplementaryDeduction"
                    />
                    <Column
                        align="center"
                        title="Positive Profit + Negative Profit"
                        dataIndex="toatalProfit"
                        key="toatalProfit"
                    />
                </ColumnGroup>
            </Table>
        </div>
    );
}
export default RollOverComparisonTable;
