import { create } from "zustand";

interface IInView {
    PRICE_ALARM: boolean;
    LP_PRICE_ALARM: boolean;
    SERVER_STOP: boolean;
    CONNECTOR: boolean;
    CALCULATE_TOOL: boolean;
}

type RCPriceAlarmStore = {
    inView: IInView;
};
type RCPriceAlarmStoreActions = {
    updateInView: (newInView: Partial<IInView>) => void;
};

const useRCPriceAlarmStore = create<RCPriceAlarmStore & RCPriceAlarmStoreActions>(set => ({
    inView: {
        PRICE_ALARM: true,
        LP_PRICE_ALARM: false,
        SERVER_STOP: false,
        CONNECTOR: false,
        CALCULATE_TOOL: false,
    },
    updateInView: newInView =>
        set(state => ({
            inView: { ...state.inView, ...newInView },
        })),
}));

export default useRCPriceAlarmStore;
