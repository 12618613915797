import { isArray } from "lodash";
import { useMutation, useQuery } from "@tanstack/react-query";
import { APIs } from "../services/apis";
import { plainAxiosInstance } from "../services/axiosSetup";

/*
sample response from the API
{
  "rows": 50,
  "page": 1,
  "offset": 0,
  "sortBy": [],
  "sortDesc": [],
  "resultList": [
    {
      "serverId": 6,
      "server": "IUK",
      "separateDate": "20240814",
      "login": 113102,
      "symbol": "SPX500.pr",
      "group": "MCUK_TSW_P_EUR",
      "subAccount": 0,
      "cleanSymbol": "SPX500",
      "position": 0.7,
      "contractSize": 10,
      "baseDividend": 0.167,
      "eodPrice": 0.9096035947534065,
      "status": 1,
      "dividend": 1.06,
      "digits": 2,
      "createDate": "2024-08-13T21:06:41.000+00:00",
      "formatCreateDate": "2024-08-14 00:06:41",
      "updateDate": null
    },
    {
      "serverId": 6,
      "server": "IUK",
      "separateDate": "20240814",
      "login": 117713,
      "symbol": "SPX500.pr",
      "group": "M_API_FNR2_USD",
      "subAccount": 0,
      "cleanSymbol": "SPX500",
      "position": -1,
      "contractSize": 10,
      "baseDividend": 0.167,
      "eodPrice": 1,
      "status": 1,
      "dividend": -1.67,
      "digits": 2,
      "createDate": "2024-08-13T21:06:41.000+00:00",
      "formatCreateDate": "2024-08-14 00:06:41",
      "updateDate": null
    }
  ],
  "total": 2,
  "totalPage": 1,
  "server": null,
  "serverId": 6,
  "separateDate": "20240814",
  "symbol": null
}
*/

interface IRCDividendRecord {
    rows: number;
    page: number;
    offset: number;
    sortBy: any[];
    sortDesc: any[];
    resultList: IRCDividendRecordResults[];
    total: number;
    totalPage: number;
    server: string | null;
    serverId: number;
    separateDate: string | null;
    symbol: string | null;
}

interface IRCDividendRecordResults {
    serverId: number;
    server: string;
    separateDate: string;
    login: number;
    symbol: string;
    group: string;
    subAccount: number;
    cleanSymbol: string;
    position: number;
    contractSize: number;
    baseDividend: number;
    eodPrice: number;
    status: number;
    dividend: number;
    digits: number;
    createDate: string;
    formatCreateDate: string;
    updateDate: string | null;
}

/*
payload
{
  "page": 1,
  "rows": 50,
  "sortBy": [],
  "sortDesc": [],
  "serverId": 6,
  "separateDate": "20240814"
}
*/

interface IRCDividendRecordPayload {
    page: number;
    rows: number;
    sortBy: any[];
    sortDesc: any[];
    serverId: number;
    separateDate: string;
    symbol?: string | undefined;
}

const useRCDividendRecord = ({ page, rows, sortBy, sortDesc, serverId, separateDate, symbol = "" }: IRCDividendRecordPayload) => {
    const { data, refetch, isLoading, error, isFetching } = useQuery({
        queryKey: ["rcDividendRecord", page, rows, sortBy, sortDesc, serverId, separateDate],
        queryFn: () =>
            plainAxiosInstance.post(
                `${APIs.RC_DIVIDEND.POST_DIVIDEND}`,
                {
                    page,
                    rows,
                    sortBy,
                    sortDesc,
                    serverId,
                    separateDate,
                    symbol,
                },
                {
                    headers: {
                        Accept: "application/json",
                    },
                }
            ),
        staleTime: 60 * 1000, //(10 x 1000ms = 10 seconds)
        // refetchInterval: 60 * 1000, //(10 x 1000ms = 10 seconds)
    });

    const rcDividendRecord: IRCDividendRecord | null = data?.data?.status ? null : data?.data || null;

    const rcDividentRecordResult: IRCDividendRecordResults[] = rcDividendRecord?.resultList || [];
    // @ts-ignore
    const rcDividendRecordError: string | null = data?.data?.message || error?.message || error || null;

    return {
        rcDividendRecord,
        rcDividentRecordResult,
        rcDividendRecordError,
        refetchRcDividendRecord: refetch,
        isFetching,
        isLoading,
    };
};

export const useRCDividendRecordMutation = () => {
    // const queryClient = useQueryClient();
    const {
        mutateAsync: generateReport,
        isLoading: isLoadingGenerateReport,
        error: generateReportError,
    } = useMutation(
        async ({ separateDate, serverId, symbol = "" }: { separateDate: string; serverId: number; symbol: string }): Promise<any> => {
            return plainAxiosInstance.post(APIs.RC_DIVIDEND.POST_DOWNLOAD, {
                separateDate,
                serverId,
                symbol,
            });
        },
        {
            onSuccess: res => {
                // queryClient.invalidateQueries(["rcIssuesLogSettings", type]);
                return res;
            },
            onError: error => {
                // queryClient.invalidateQueries(["rcIssuesLogSettings", type]);
                console.error(`Error on generate dividend record report:`, error);
                return error;
            },
        }
    );

    const {
        mutateAsync: uploadDividend,
        isLoading: isLoadingUploadDividend,
        error: uploadDividendError,
    } = useMutation(
        async ({ uploadFile, uploadDate }: { uploadFile: any; uploadDate: string }): Promise<any> => {
            const formData = new FormData();
            formData.append("uploadFile", uploadFile);
            formData.append("uploadDate", uploadDate);
            return plainAxiosInstance.post(APIs.RC_DIVIDEND.POST_DIVIDEND_UPLOAD_MAP, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
        },
        {
            onSuccess: res => {
                // queryClient.invalidateQueries(["rcIssuesLogSettings", type]);
                return res;
            },
            onError: error => {
                // queryClient.invalidateQueries(["rcIssuesLogSettings", type]);
                console.error(`Error on upload dividend map:`, error);
                return error;
            },
        }
    );
    const {
        mutateAsync: uploadDividendExcludedSymbols,
        isLoading: isLoadingUploadDividendExcludedSymbols,
        error: uploadDividendExcludedSymbolsError,
    } = useMutation(
        async ({ uploadFile }: { uploadFile: any }): Promise<any> => {
            const formData = new FormData();
            formData.append("uploadFile", uploadFile);
            return plainAxiosInstance.post(APIs.RC_DIVIDEND.POST_DIVIDEND_UPLOAD_EXCLUDED_SYMBOLS, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
        },
        {
            onSuccess: res => {
                // queryClient.invalidateQueries(["rcIssuesLogSettings", type]);
                return res;
            },
            onError: error => {
                // queryClient.invalidateQueries(["rcIssuesLogSettings", type]);
                console.error(`Error on upload dividend excluded symbols:`, error);
                return error;
            },
        }
    );

    return {
        generateReport,
        isLoadingGenerateReport,
        generateReportError,

        uploadDividend,
        isLoadingUploadDividend,
        uploadDividendError,

        uploadDividendExcludedSymbols,
        isLoadingUploadDividendExcludedSymbols,
        uploadDividendExcludedSymbolsError,
    };
};

interface IRCDividendUploads {
    uploadDate: string;
    cleanSymbol: string;
    baseDividend: number;
}

export const useRCDividendUploads = ({ date, symbol = "" }: { date: string; symbol?: string }) => {
    const { data, refetch, isLoading, error, isFetching } = useQuery({
        queryKey: ["rcDividendUploads", date, symbol],
        queryFn: () =>
            plainAxiosInstance.get(`${APIs.RC_DIVIDEND.GET_DIVIDEND_UPLOADS}?uploadDate=${date}&symbol=${symbol}`, {
                headers: {
                    Accept: "application/json",
                },
            }),
        staleTime: 60 * 1000, //(10 x 1000ms = 10 seconds)
    });

    const rcDividendUploads: IRCDividendUploads[] | null = data?.data?.status ? null : data?.data || null;

    // @ts-ignore
    const rcDividendUploadsError: string | null = data?.data?.message || error?.message || error || null;

    return {
        rcDividendUploads,
        rcDividendUploadsError,
        refetchRcDividendUploads: refetch,
        isFetching,
        isLoading,
    };
};

export const useRCDividendExcludedSymbols = () => {
    const { data, refetch, isLoading, error, isFetching } = useQuery({
        queryKey: ["rcDividendExcludedSymbols"],
        queryFn: () =>
            plainAxiosInstance.get(`${APIs.RC_DIVIDEND.GET_DIVIDEND_EXCLUDED_SYMBOLS}`, {
                headers: {
                    Accept: "application/json",
                },
            }),
        staleTime: 60 * 1000, //(10 x 1000ms = 10 seconds)
    });

    const rcDividendExcludedSymbols: string[] | null = data?.data?.status ? null : data?.data || null;

    // @ts-ignore
    const rcDividendExcludedSymbolsError: string | null = data?.data?.message || error?.message || error || null;

    return {
        rcDividendExcludedSymbols,
        rcDividendExcludedSymbolsError,
        refetchRcDividendExcludedSymbols: refetch,
        isFetching,
        isLoading,
    };
};

export default useRCDividendRecord;
