import React, { useEffect, useState } from "react";
import { APIs, apiRequest } from "../../services/apiConfig";
import { DataTableColumnRender, ErrorCatchValidator, ErrorMessageHandler, currencyRender } from "../../utils/Common";
import { ComponentType, SUCCESS_FAILED } from "../../constants";
import CardBox from "../../components/Common/CardBox";
import { ActivityLog, CustomPaginationProps, InsightModuleTable, UsersList } from "../../constants/type";
import { Button, Col, Form, List, Row } from "antd";
import { FormComponent } from "../../components/FormComponent";
import { ArrowRightOutlined, DeleteOutlined, EditOutlined, FileAddOutlined } from "@ant-design/icons";
import LoadingComponent from "../../components/Loading";
import { findObjectKeys, objectToArray } from "../../utils/object";
import { ToObjectWithKey } from "../../utils/array";
import { isEmptyOrNull } from "../../utils/string";

const ActivityLogsPage = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [data, setData] = useState<ActivityLog[]>([]);
    const [pagination, setPagination] = useState<CustomPaginationProps>({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [filterParams, setFilterParams] = useState<any>({});
    const [filterForm] = Form.useForm();

    const [cData, setCData] = useState<any>({ users: {}, modules: {}, moduleTables: {} });

    useEffect(() => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["user", "insightmodule", "insightmoduletable"] }).then((data: any) => {
            setCData({
                users: ToObjectWithKey(data.users, "id"),
                modules: ToObjectWithKey(data.insightModules, "id"),
                moduleTables: ToObjectWithKey(data.insightModuleTables, "id"),
            });
            setRunRefetchDataList(true);
        });
        return () => {};
    }, []);

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            getAuditLogsList();
            setRunRefetchDataList(false);
        }
        return () => {};
    }, [runRefetchDataList]);

    const getAuditLogsList = () => {
        apiRequest(APIs.GET_AUDIT_LOGS, {
            limit: pagination.pageSize,
            current: pagination.current,
            ...(Object.keys(filterParams).length > 0 && { ...filterParams }),
        })
            .then((data: any) => {
                if (data.result && data.result.length > 0) {
                    setData(
                        data.result.map((x: ActivityLog) => ({
                            ...x,
                            createdByName: x.createdBy === 0 ? "Insight System" : cData.users[x.createdBy] && cData.users[x.createdBy].name,
                            moduleName: cData.modules[x.moduleId] && cData.modules[x.moduleId].name,
                            moduleTableName: cData.moduleTables && cData.moduleTables[x.moduleTableId].name,
                        }))
                    );
                    setPagination(prev => ({ ...prev, total: data.total }));
                } else {
                    setData([]);
                    setPagination(prev => ({ ...prev, total: 0 }));
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("activity logs", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsLoading(false));
    };

    const displayItemLog = (item: ActivityLog, type: string) => {
        switch (type) {
            case "both":
                if (item.before !== null && item.after !== null) {
                    try {
                        let beforeObj = JSON.parse(item.before),
                            afterObj = JSON.parse(item.after),
                            keys = Object.keys(beforeObj);
                        if (!Array.isArray(afterObj) && Array.isArray(beforeObj) && beforeObj.length === 1) {
                            beforeObj = beforeObj[0];
                        }
                        if (!Array.isArray(beforeObj) && Array.isArray(afterObj) && afterObj.length === 1) {
                            afterObj = afterObj[0];
                        }
                        return (
                            <div className="item-log">
                                {keys.map((k: string, idx: number) => {
                                    let compareContent: React.ReactNode = <></>;
                                    if (Array.isArray(beforeObj[k])) {
                                        compareContent = (
                                            <>
                                                <span className="before">
                                                    {beforeObj && beforeObj[k].length <= 0 ? "[ ]" : JSON.stringify(beforeObj[k])} <>&nbsp;</>
                                                </span>
                                                <ArrowRightOutlined style={{ fontSize: 12 }} /> <>&nbsp;</>
                                                {afterObj && afterObj[k].length <= 0 ? "[ ]" : JSON.stringify(afterObj[k])}
                                            </>
                                        );
                                    } else if (typeof beforeObj[k] === "object") {
                                        compareContent = (
                                            <>
                                                <span className="before">
                                                    {beforeObj && beforeObj[k] === null ? "null" : JSON.stringify(beforeObj[k])} <>&nbsp;</>
                                                </span>
                                                <ArrowRightOutlined style={{ fontSize: 12 }} /> <>&nbsp;</>
                                                {isEmptyOrNull(afterObj) ? "[ ]" : JSON.stringify(afterObj[k])}
                                            </>
                                        );
                                    } else if (typeof beforeObj[k] === "string") {
                                        compareContent = (
                                            <>
                                                <span className="before">
                                                    {beforeObj && beforeObj[k] === null ? "null" : beforeObj[k].toString()} <>&nbsp;</>
                                                </span>
                                                <ArrowRightOutlined style={{ fontSize: 12 }} /> <>&nbsp;</>
                                                {isEmptyOrNull(afterObj[k]) ? "null" : afterObj[k].toString()}
                                            </>
                                        );
                                    } else if (typeof beforeObj[k] === "number") {
                                        compareContent = (
                                            <>
                                                <span className="before">
                                                    {beforeObj && beforeObj[k] === null ? "null" : beforeObj[k]} <>&nbsp;</>
                                                </span>
                                                <ArrowRightOutlined style={{ fontSize: 12 }} /> <>&nbsp;</>
                                                {isEmptyOrNull(afterObj[k]) ? "null" : afterObj[k]}
                                            </>
                                        );
                                    } else if (typeof beforeObj[k] === "boolean") {
                                        compareContent = (
                                            <>
                                                <span className="before">
                                                    {beforeObj && beforeObj[k] === null ? "null" : beforeObj[k] === true ? "True" : "False"}{" "}
                                                    <>&nbsp;</>
                                                </span>
                                                <ArrowRightOutlined style={{ fontSize: 12 }} /> <>&nbsp;</>
                                                {isEmptyOrNull(afterObj[k]) ? "null" : afterObj[k] === true ? "True" : "False"}
                                            </>
                                        );
                                    }
                                    return (
                                        <div key={`al-ba-${idx}`}>
                                            <span className="item-log-name">
                                                {k}: <>&nbsp;</>
                                            </span>
                                            {compareContent}
                                            <br />
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    } catch (error) {
                        return console.log(JSON.stringify(item));
                    }
                }
                break;
            case "before":
                switch (item.moduleTableId) {
                    case 7:
                    case 8:
                    case 9:
                        return (
                            <div className="item-log" key={`al-b-${item.id}`}>
                                {item.before}
                            </div>
                        );
                    // case 1:
                    // case 2:
                    // case 3:
                    default:
                        if (item.before !== null) {
                            let beforeObj = JSON.parse(item.before);
                            let keys = Object.keys(beforeObj);
                            return (
                                <div className="item-log">
                                    {keys.map((k: string, idx: number) => {
                                        let compareContent: React.ReactNode = <></>;
                                        if (Array.isArray(beforeObj[k])) {
                                            compareContent = (
                                                <>
                                                    {beforeObj && beforeObj[k].length <= 0 ? "[ ]" : JSON.stringify(beforeObj[k])}
                                                    <br />
                                                </>
                                            );
                                        } else if (typeof beforeObj[k] === "object") {
                                            compareContent = (
                                                <>
                                                    {isEmptyOrNull(beforeObj) ? "null" : JSON.stringify(beforeObj[k])}
                                                    <br />
                                                </>
                                            );
                                        } else {
                                            compareContent = (
                                                <>
                                                    {isEmptyOrNull(beforeObj) ? "null" : beforeObj[k].toString()}
                                                    <br />
                                                </>
                                            );
                                        }
                                        return (
                                            <div key={`al-b-${item.id}-${idx}`}>
                                                <span className="item-log-name">
                                                    {k}: <>&nbsp;</>
                                                </span>
                                                {compareContent}
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        }
                        break;
                }
                break;
            case "after":
                switch (item.moduleTableId) {
                    case 7:
                    case 8:
                    case 9:
                        return (
                            <div className="item-log" key={`al-a-${item.id}`}>
                                {item.after}
                            </div>
                        );
                    // case 1:
                    // case 2:
                    // case 3:
                    default:
                        if (item.after !== null) {
                            let afterObj = JSON.parse(item.after);
                            let keys = Object.keys(afterObj);
                            return (
                                <div className="item-log">
                                    {keys.map((k: string, idx: number) => {
                                        let compareContent: React.ReactNode = <></>;
                                        if (Array.isArray(afterObj[k])) {
                                            compareContent = (
                                                <>
                                                    {afterObj && afterObj[k].length <= 0 ? "[ ]" : JSON.stringify(afterObj[k])}
                                                    <br />
                                                </>
                                            );
                                        } else if (typeof afterObj[k] === "object") {
                                            compareContent = (
                                                <>
                                                    {isEmptyOrNull(afterObj) ? "null" : JSON.stringify(afterObj[k])}
                                                    <br />
                                                </>
                                            );
                                        } else {
                                            compareContent = (
                                                <>
                                                    {isEmptyOrNull(afterObj) ? "null" : afterObj[k].toString()}
                                                    <br />
                                                </>
                                            );
                                        }
                                        return (
                                            <div key={`al-a-${item.id}-${idx}`}>
                                                <span className="item-log-name">
                                                    {k}: <>&nbsp;</>
                                                </span>
                                                {compareContent}
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        }
                        break;
                }
                break;
            default:
                break;
        }
    };

    const getLogListItem = (item: ActivityLog) => {
        let fontClass: any = { fontSize: "1.25rem", marginRight: "0.9765vw" };

        switch (item.crudAction) {
            case "C":
                return (
                    <>
                        <div className="item-title">
                            <FileAddOutlined style={{ color: "#0ab76e", ...fontClass }} />
                            <span>{item.createdByName}</span> added{" "}
                            <span>
                                {item.moduleName} - {item.moduleTableName}
                            </span>
                        </div>
                        <div className="item-date">{DataTableColumnRender.DateTime(item.createdDateUtc) + " (Local)"}</div>
                        {displayItemLog(item, "after")}
                    </>
                );
            case "U":
                return (
                    <>
                        <div className="item-title">
                            <EditOutlined style={{ color: "#D89614", ...fontClass }} />
                            <span>{item.createdByName}</span> updated{" "}
                            <span>
                                {item.moduleName} - {item.moduleTableName}
                            </span>
                        </div>
                        <div className="item-date">{DataTableColumnRender.DateTime(item.createdDateUtc) + " (Local)"}</div>
                        {displayItemLog(item, "both")}
                    </>
                );
            case "D":
                return (
                    <>
                        <div className="item-title">
                            <DeleteOutlined style={{ color: "#f00f00", ...fontClass }} />
                            <span>{item.createdByName}</span> deleted{" "}
                            <span>
                                {item.moduleName} - {item.moduleTableName}
                            </span>
                        </div>
                        <div className="item-date">{DataTableColumnRender.DateTime(item.createdDateUtc) + " (Local)"}</div>
                        {displayItemLog(item, "before")}
                    </>
                );
            default:
                break;
        }
    };

    const onFilterValueChanged = (values: any) => {
        let filterData = { ...filterParams };

        if (findObjectKeys(values, ["createdBy"])) {
            try {
                if (values["createdBy"] !== undefined) {
                    filterData["userIds"] = values["createdBy"];
                }
            } catch (error) {
                delete filterData["userIds"];
            }
        } else if (findObjectKeys(values, ["moduleId"])) {
            try {
                if (values["moduleId"] !== undefined) {
                    filterData["moduleIds"] = values["moduleId"];
                }
            } catch (error) {
                delete filterData["moduleIds"];
            }
        } else if (findObjectKeys(values, ["createdDateUtc"])) {
            try {
                if (values["createdDateUtc"] !== null) {
                    filterData["dateFrom"] = values["createdDateUtc"][0].toISOString();
                    filterData["dateTo"] = values["createdDateUtc"][1].toISOString();
                } else {
                    delete filterData["dateFrom"];
                    delete filterData["dateTo"];
                }
            } catch (error) {
                delete filterData["dateFrom"];
                delete filterData["dateTo"];
            }
        }
        setFilterParams(filterData);
    };

    const onFormSubmit = (values: any) => {
        let filterData: any = {};
        Object.keys(values)
            .filter(x => typeof values[x] !== "undefined")
            .map(x => {
                if (x === "createdBy") {
                    filterData["userIds"] = values[x];
                } else if (x === "moduleTableId") {
                    filterData["moduleTableIds"] = values[x];
                } else if (x === "createdDateUtc") {
                    filterData["dateFrom"] = values["createdDateUtc"][0].toISOString();
                    filterData["dateTo"] = values["createdDateUtc"][1].toISOString();
                } else {
                    filterData[x] = values[x];
                }
                return x;
            });
        setFilterParams(filterData);
        setPagination(prev => ({ ...prev, current: 1 }));
        setRunRefetchDataList(true);
    };

    return (
        <div className="activity-logs-container">
            <CardBox title="Activity Logs">
                <div className="filter-form-div">
                    <Form labelAlign={"left"} form={filterForm} layout="vertical" initialValues={{}} onFinish={onFormSubmit}>
                        <Row gutter={24} style={{ width: "100%" }}>
                            <Col span={5}>
                                <FormComponent
                                    label="Created At (Local)"
                                    name={"createdDateUtc"}
                                    extra={{
                                        type: ComponentType.daterange,
                                        value: [],
                                        inputProps: {
                                            style: { width: "100%" },
                                        },
                                    }}
                                />
                            </Col>
                            <Col span={5}>
                                <FormComponent
                                    label="User"
                                    name={"createdBy"}
                                    extra={{
                                        type: ComponentType.dropdown,
                                        value: objectToArray(cData.users || []).map((x: UsersList) => ({
                                            text: x.name + "  |  " + x.email,
                                            value: x.id,
                                        })),
                                        inputProps: {
                                            mode: "multiple",
                                        },
                                    }}
                                />
                            </Col>
                            <Col span={5}>
                                <FormComponent
                                    label="Module Table"
                                    name={"moduleTableId"}
                                    extra={{
                                        type: ComponentType.dropdown,
                                        value: objectToArray(cData.moduleTables || []).map((x: InsightModuleTable) => ({
                                            text: x.name,
                                            value: x.id,
                                        })),
                                        inputProps: {
                                            mode: "multiple",
                                        },
                                    }}
                                />
                            </Col>
                            <Col span={5}>
                                <FormComponent
                                    label="Log Actions"
                                    name={"crudActions"}
                                    extra={{
                                        type: ComponentType.dropdown,
                                        value: [
                                            {
                                                text: (
                                                    <span>
                                                        <FileAddOutlined style={{ color: "#0ab76e", marginRight: "0.651vw" }} />
                                                        Create
                                                    </span>
                                                ),
                                                value: "C",
                                            },
                                            {
                                                text: (
                                                    <span>
                                                        <EditOutlined style={{ color: "#D89614", marginRight: "0.651vw" }} />
                                                        Update
                                                    </span>
                                                ),
                                                value: "U",
                                            },
                                            {
                                                text: (
                                                    <span>
                                                        <DeleteOutlined style={{ color: "#f00f00", marginRight: "0.651vw" }} />
                                                        Delete
                                                    </span>
                                                ),
                                                value: "D",
                                            },
                                        ],
                                        inputProps: {
                                            mode: "multiple",
                                        },
                                    }}
                                />
                            </Col>
                        </Row>
                        <div className="filter-form-buttons">
                            <Button
                                onClick={e => {
                                    e.preventDefault();
                                    filterForm.resetFields();
                                    setFilterParams({});
                                    setRunRefetchDataList(true);
                                }}
                                style={{ width: "6.51vw", marginRight: "0.651vw" }}
                            >
                                Reset
                            </Button>
                            <Button type="primary" style={{ width: "6.51vw" }} htmlType="submit">
                                Search
                            </Button>
                        </div>
                    </Form>
                </div>
                {isLoading ? (
                    <div className="loading-container">
                        <LoadingComponent tip="Loading..." />
                    </div>
                ) : (
                    <List
                        className="activity-list-component"
                        itemLayout="vertical"
                        pagination={{
                            onChange: (page: number, pageSize: number) => {
                                setPagination(prev => ({
                                    ...prev,
                                    current: page,
                                    pageSize: pageSize,
                                }));
                                setRunRefetchDataList(true);
                            },
                            total: pagination.total,
                            showTotal: (total: number, range: number[]) =>
                                `${currencyRender(range[0])} - ${currencyRender(range[1])} of ${currencyRender(total)}`,
                            showSizeChanger: true,
                            current: pagination.current,
                            pageSize: pagination.pageSize,
                        }}
                        dataSource={data}
                        renderItem={item => (
                            <List.Item key={item.id} className="activity-list-item">
                                {getLogListItem(item)}
                            </List.Item>
                        )}
                    />
                )}
            </CardBox>
        </div>
    );
};

export default ActivityLogsPage;
