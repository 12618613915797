import { useQuery } from "@tanstack/react-query";
// import axios from "axios";
// import { getToken } from "../services/localStorage";
import { APIs } from "../services/apis";
import { plainAxiosInstance } from "../services/axiosSetup";
const useRCRejectOrderServers = () => {
    //convert request to form data
    // const token = getToken();
    const { data, refetch, isLoading, error, isFetching } = useQuery({
        queryKey: ["rcRejectOrderServers"],
        queryFn: () =>
            plainAxiosInstance.get(`${APIs.RC_REJECT_ORDER.GET_REJECT_ORDER_SERVERS}`, {
                headers: {
                    Accept: "application/json",
                },
            }),
        // axios.get(`${process.env.REACT_APP_API_URL}${APIs.RC_REJECT_ORDER.GET_REJECT_ORDER_SERVERS}`, {
        //     headers: {
        //         Accept: "application/json",
        //         // "Content-type": "multipart/form-data",
        //         Authorization: `Bearer ${token}`,
        //     },
        // }),
        staleTime: 60 * 1000, //(60 x 1000ms = 1minute)
    });

    const rcRejectOrderServers = data?.data?.status ? null : data?.data || null;
    // @ts-ignore
    const rcRejectOrderServersError = data?.data?.message || error?.message || error || null;

    return {
        rcRejectOrderServers,
        rcRejectOrderServersError,
        refetchRcRejectOrderServersError: refetch,
        isFetching,
        isLoading,
    };
};

export default useRCRejectOrderServers;
