import { useState, useMemo, useEffect } from "react";
import FlexiDataTable from "../../../../components/FlexiDataTable";
import { InnerPageActionMode, CALLBACK_KEY, SUCCESS_FAILED, ComponentType } from "../../../../constants";
import { FlexiDataTableOptionsProps, FlexiDataTableCallbackProps, CreateEditInlineFormBased, AlarmConfigProps } from "../../../../constants/type";
import AuthHelper, { AuthKeys } from "../../../../helpers/authHelper";
import { apiRequest } from "../../../../services/apiConfig";
import { APIs } from "../../../../services/apis";
import { ErrorMessageHandler, ErrorCatchValidator, DTColProps } from "../../../../utils/Common";
import IntradayMonitorAlarmCreateEditPage from "./CreateEditPage";

export interface IntradayMonitorAlarmConfigurationProps {}

const IntradayMonitorAlarmConfiguration = (props: IntradayMonitorAlarmConfigurationProps) => {
    const [isFirstOpt, setIsFirstOpt] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<AlarmConfigProps[]>([]);
    const [refetch, setRefetch] = useState<number>(0);
    const [formObject, setFormObject] = useState<CreateEditInlineFormBased>({ mode: InnerPageActionMode.CREATE_NEW });

    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.INTRADAY_ALARM_EDIT);

    const columns = useMemo(
        () => [
            DTColProps.XLarge({
                title: "Name",
                dataIndex: "alarmName",
                key: "alarmName",
                options: {
                    filter: {
                        type: ComponentType.text,
                        value: "",
                    },
                },
            }),
            {
                title: "Description",
                dataIndex: "alarmDescription",
                key: "alarmDescription",
            },
            DTColProps.Status({
                title: "Status",
                dataIndex: "isActive",
                key: "isActive",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: [
                            { text: "Active", value: true },
                            { text: "Inactive", value: false },
                        ],
                    },
                },
            }),
        ],
        []
    );

    const options: FlexiDataTableOptionsProps = {
        add: enableUpdate,
        edit: enableUpdate,
        delete: enableUpdate,
    };

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, rowData: any) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                setIsFirstOpt(prev => !prev);
                setFormObject({ mode: InnerPageActionMode.CREATE_NEW, id: undefined });
                setRefetch(prev => prev + 1);
                break;
            case CALLBACK_KEY.DO_EDIT:
                setIsFirstOpt(prev => !prev);
                setFormObject({ mode: InnerPageActionMode.EDIT, id: rowData["alarmId"] });
                setRefetch(prev => prev + 1);
                break;
            case CALLBACK_KEY.DO_DELETE:
                apiRequest(APIs.DELETE_MIM_ALARM_CONFIG, { alarmId: rowData["alarmId"] })
                    .then(res => {
                        ErrorMessageHandler("alarm configuration", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                        setTimeout(() => getList(), 300);
                    })
                    .catch(error =>
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("alarm configuration", SUCCESS_FAILED.FAILED_DELETE_DATA, err))
                    );
                break;
            default:
                break;
        }
    };

    const getList = () => {
        setIsLoading(true);
        apiRequest(APIs.GET_MIM_ALARM_CONFIG_LIST, {})
            .then((res: any) => {
                setData(res.length > 0 ? res : []);
            })
            .catch(error =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("alarm configuration", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        getList();
        return () => {};
    }, []);

    return (
        <div className="single-page with-background">
            <div className={`${isFirstOpt ? "active" : ""}`}>
                <FlexiDataTable
                    bordered
                    rowKeyProperty="alarmId"
                    title=""
                    columns={columns}
                    options={options}
                    dataSource={data}
                    callback={componentCallback}
                    loading={isLoading}
                />
            </div>
            <div className={`${isFirstOpt ? "" : "active"}`}>
                <IntradayMonitorAlarmCreateEditPage
                    {...formObject}
                    callback={(action: InnerPageActionMode, value: any) => {
                        switch (action) {
                            case InnerPageActionMode.BACK:
                                setIsFirstOpt(prev => !prev);
                                if (value?.hasOwnProperty("refreshMainList") && value["refreshMainList"]) {
                                    getList();
                                }
                                break;
                        }
                    }}
                    resetState={refetch}
                />
            </div>
        </div>
    );
};

export default IntradayMonitorAlarmConfiguration;
