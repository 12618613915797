import { ArrowLeftOutlined } from "@ant-design/icons";
import { PageHeader, Card, Button } from "antd";
import { objectRemoveProps } from "../../utils/object";

export enum SitePageHeaderInlineActionType {
    OnBack = 1,
    OnSubmit = 2,
    OnCancel = 3,
}

export interface SitePageHeaderInlineProps {
    title: string;
    subtitle?: string;
    callback: (actionType: SitePageHeaderInlineActionType, values?: any) => void;
    enableSubmit?: boolean;
    extraProps?: any;
    children?: React.ReactNode;
}

const SitePageHeaderInline = (props: SitePageHeaderInlineProps) => {
    return (
        <div className="site-main-content main-border">
            <PageHeader
                className="site-page-header"
                backIcon={<ArrowLeftOutlined />}
                onBack={() => props.callback && props.callback(SitePageHeaderInlineActionType.OnBack, {})}
                title={props.title}
                subTitle={props.subtitle || ""}
                extra={
                    <>
                        {props.enableSubmit && (
                            <>
                                <Button
                                    type="primary"
                                    onClick={() => props.callback && props.callback(SitePageHeaderInlineActionType.OnSubmit, {})}
                                    className="shadow-light"
                                >
                                    Submit
                                </Button>
                                <Button
                                    onClick={() => props.callback && props.callback(SitePageHeaderInlineActionType.OnCancel, {})}
                                    className="shadow-light"
                                >
                                    Cancel
                                </Button>
                            </>
                        )}
                        {props.extraProps && props.extraProps?.hasOwnProperty("extra") && props.extraProps.extra}
                    </>
                }
                {...(props.extraProps && objectRemoveProps(props.extraProps, ["extra"]))}
            />
            <div className="page-content">
                <Card bordered={false}>{props.children && props.children}</Card>
            </div>
        </div>
    );
};

export default SitePageHeaderInline;
