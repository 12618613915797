import React from "react";
import { DownloadOutlined } from "@ant-design/icons";
import { Button, notification } from "antd";
import moment from "moment-timezone";
import { APIs } from "../../../services/apis";
import { plainAxiosInstance } from "../../../services/axiosSetup";

const DownloadDataCenterButton = () => {
    const handleDownload = async () => {
        plainAxiosInstance
            .get(APIs.RC_DATA_CENTER.GET_DATA_CENTER_MONITOR_DOWNLOAD, {
                headers: {
                    Accept: "application/octet-stream, */*",
                },
                responseType: "blob",
            })
            .then(response => {
                // Check if the response content type is 'application/octet-stream'
                const contentType = response.headers["content-type"];
                if (
                    contentType === "application/octet-stream" ||
                    contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ) {
                    // Handle the file download response
                    const getCurrentDate = moment().format("YYYY-MM-DD_HH-mm-ss");
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `data_center_monitor_${getCurrentDate}.csv`);
                    document.body.appendChild(link);
                    link.click();
                    // Clean up
                    window.URL.revokeObjectURL(url);
                    notification.success({
                        message: "Downloaded",
                        description: `Data Center Monitor report downloaded successfully`,
                    });
                } else {
                    notification.error({
                        message: "Error",
                        description: `Received non-file response. Error: ${response}`,
                    });
                    console.log("Received non-file response:", response);
                }
            })
            .catch(err => {
                notification.error({
                    message: "Error",
                    description: `Download error: ${err}`,
                });
                console.log("download error", err);
            });
    };
    return (
        <>
            <Button type="ghost" icon={<DownloadOutlined />} onClick={() => handleDownload()}>
                Download
            </Button>
        </>
    );
};

export default DownloadDataCenterButton;
