import { Badge, Button, Divider, Space, Typography, notification } from "antd";
import FloatingMenu from "../components/FloatingMenu";
import useRCIssuesLog, { useRCIssuesLogMT5Monitor, useRCIssuesLogRequestTimeoutSettings } from "../../../hooks/useRCIssuesLog";
import { FaChevronRight, FaHome, FaRedo } from "react-icons/fa";
import useRCIssuesLogStore from "../../../store/useRCIssuesLogStore";
import useMeasure from "react-use-measure";
import SoundButton from "../components/SoundButton";
import soundFile from "../../../assets/audios/rc/clock_bell.mp3";
import MessageCard from "../components/MessageCard";
import useRCBalanceAdjustment from "../../../hooks/useRCBalanceAdjustment";

const IssuesLogMenuData = [
    {
        label: "No Money",
        key: "NO_MONEY",
    },
    {
        label: "Request Timeout",
        key: "REQUEST_TIMEOUT",
    },
    {
        label: "Closed Order",
        key: "CLOSED_ORDER",
    },
    {
        label: "Modify Open Order",
        key: "MODIFY_OPEN_ORDER",
    },
    {
        label: "Manager Account Intercepted",
        key: "MANAGER_ACCOUNT_INTERCEPTED",
    },
    {
        label: "MT5 Order State",
        key: "MT5_ORDER_TYPE",
    },
    {
        label: "Account Monitor",
        key: "ACCOUNT_MONITOR",
    },
    {
        label: "Restore Order",
        key: "RESTORE_ORDER",
    },
    {
        label: "Update Order Urgent",
        key: "UPDATE_ORDER_URGENT",
    },
    {
        label: "Balance Adjustment Monitor",
        key: "BALANCE_ADJUSTMENT_MONITOR",
    },
];

const IssuesLogMenu = () => {
    const { rcIssuesLog: NO_MONEY, refetchRcIssuesLog: REFETCH_NO_MONEY } = useRCIssuesLog("NO_MONEY");
    const { rcIssuesLog: REQUEST_TIMEOUT, refetchRcIssuesLog: REFETCH_REQUEST_TIMEOUT } = useRCIssuesLog("REQUEST_TIMEOUT");
    const { rcIssuesLog: CLOSED_ORDER, refetchRcIssuesLog: REFETCH_CLOSED_ORDER } = useRCIssuesLog("CLOSED_ORDER");
    const { rcIssuesLog: MODIFY_OPEN_ORDER, refetchRcIssuesLog: REFETCH_MODIFY_OPEN_ORDER } = useRCIssuesLog("MODIFY_OPEN_ORDER");
    const { rcIssuesLog: MANAGER_ACCOUNT_INTERCEPTED, refetchRcIssuesLog: REFETCH_MANAGER_ACCOUNT_INTERCEPTED } =
        useRCIssuesLog("MANAGER_ACCOUNT_INTERCEPTED");
    const { rcIssuesLog: ACCOUNT_MONITOR, refetchRcIssuesLog: REFETCH_ACCOUNT_MONITOR } = useRCIssuesLog("ACCOUNT_MONITOR");
    const { rcIssuesLog: RESTORE_ORDER, refetchRcIssuesLog: REFETCH_RESTORE_ORDER } = useRCIssuesLog("RESTORE_ORDER");
    const { rcIssuesLog: UPDATE_ORDER_URGENT, refetchRcIssuesLog: REFETCH_UPDATE_ORDER_URGENT } = useRCIssuesLog("UPDATE_ORDER_URGENT");
    const { rcRequestTimeoutSettings, isLoading } = useRCIssuesLogRequestTimeoutSettings();
    const { rcBalanceAdjustment: dataUnsolved, refetchRcBalanceAdjustment: refetchUnsolved } = useRCBalanceAdjustment({ type: "unsolved" });
    const { rcIssuesLogMT5Monitor, isLoading: isLoadingMT5Monitor, refetchRcIssuesLogMT5Monitor } = useRCIssuesLogMT5Monitor();
    // const { rcIssuesLogMT5: MT5_ORDER_TYPE, refetchRcIssuesLogMT5: REFETCH_MT5_ORDER_TYPE } = useRCIssuesLogMT5History({
    //     start: moment().subtract(1, "days").format("YYYY-MM-DD"),
    //     end: moment().format("YYYY-MM-DD"),
    // });

    const { Title } = Typography;

    const [api, contextHolder] = notification.useNotification();
    const handleRefetchAll = () => {
        // notification.info({
        //     message: "Refreshing Issues Log",
        //     // description: "Refreshing all Issues Log",
        // });
        api.open({
            key: "refresh",
            message: `Refreshing Issues Log`,
            description: `A moment please...`,
        });

        Promise.all([
            REFETCH_NO_MONEY(),
            REFETCH_REQUEST_TIMEOUT(),
            REFETCH_CLOSED_ORDER(),
            REFETCH_MODIFY_OPEN_ORDER(),
            REFETCH_MANAGER_ACCOUNT_INTERCEPTED(),
            REFETCH_ACCOUNT_MONITOR(),
            REFETCH_RESTORE_ORDER(),
            REFETCH_UPDATE_ORDER_URGENT(),
            refetchUnsolved(),
            refetchRcIssuesLogMT5Monitor(),
            // REFETCH_MT5_ORDER_TYPE(),
        ])
            .then(() => {
                api.open({
                    key: "refresh",
                    message: `Success`,
                    description: `All Issues Log has been refreshed`,
                });
            })
            .catch(error => {
                api.open({
                    key: "refresh",
                    message: `Error`,
                    description: `An error occurred while refreshing Issues Log`,
                });
                console.error("An error occurred while refreshing Issues Log:", error);
            });
    };

    const [ref, bounds] = useMeasure();

    const totalWarningCount = {
        NO_MONEY: NO_MONEY?.length || 0,
        REQUEST_TIMEOUT: REQUEST_TIMEOUT?.length || 0,
        CLOSED_ORDER: CLOSED_ORDER?.length || 0,
        MODIFY_OPEN_ORDER: MODIFY_OPEN_ORDER?.length || 0,
        MANAGER_ACCOUNT_INTERCEPTED: MANAGER_ACCOUNT_INTERCEPTED?.length || 0,
        ACCOUNT_MONITOR: ACCOUNT_MONITOR?.length || 0,
        RESTORE_ORDER: RESTORE_ORDER?.length || 0,
        UPDATE_ORDER_URGENT: UPDATE_ORDER_URGENT?.length || 0,
        BALANCE_ADJUSTMENT_MONITOR: dataUnsolved?.data?.length || 0,
        MT5_ORDER_TYPE: rcIssuesLogMT5Monitor?.length || 0,
    };

    const shouldPlaySound = {
        NO_MONEY: NO_MONEY?.length >= 1,
        REQUEST_TIMEOUT: REQUEST_TIMEOUT?.length >= rcRequestTimeoutSettings || false,
        CLOSED_ORDER: CLOSED_ORDER?.length >= 1,
        MODIFY_OPEN_ORDER: MODIFY_OPEN_ORDER?.length >= 1,
        MANAGER_ACCOUNT_INTERCEPTED: MANAGER_ACCOUNT_INTERCEPTED?.length >= 1,
        ACCOUNT_MONITOR: ACCOUNT_MONITOR?.length >= 1,
        RESTORE_ORDER: RESTORE_ORDER?.length >= 1,
        UPDATE_ORDER_URGENT: UPDATE_ORDER_URGENT?.length >= 1,
        BALANCE_ADJUSTMENT_MONITOR: dataUnsolved?.data !== undefined && dataUnsolved?.data?.length >= 1,
        MT5_ORDER_TYPE: rcIssuesLogMT5Monitor?.length >= 1,
    };

    const finalShouldPlaySound =
        // check if any of shouldPlaySound is true
        Object.values(shouldPlaySound).some(Boolean);

    // console.log(finalShouldPlaySound);

    const globalTotalWarnings = Object.values(totalWarningCount).reduce((acc, curr) => acc + curr, 0);

    const inView = useRCIssuesLogStore(state => state.inView);
    const handleHTMLScroll = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
            const elementPosition = element.getBoundingClientRect().top + window?.scrollY;
            const offsetPosition = elementPosition - (bounds.height + 56);
            window?.scrollTo({
                top: offsetPosition,
                behavior: "smooth",
            });
        }
        // scroll to the element with the id, and +200px offset
        // example the id is at 200 Y, i need to scroll to 400Y
        // document.getElementById(id)?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    };
    // const [openSettingsModal, setOpenSettingsModal] = useState(false);

    return (
        <>
            {contextHolder}
            <FloatingMenu>
                <div ref={ref} style={{ display: "flex", justifyContent: "flex-start", gap: 4, flexWrap: "wrap", alignItems: "center" }}>
                    <Button style={{ display: "flex", alignItems: "center" }} onClick={() => handleHTMLScroll("OVERVIEW")}>
                        <FaHome />
                    </Button>
                    {IssuesLogMenuData.map((item, index) => {
                        return (
                            <Button
                                // @ts-ignore
                                type={inView[item.key] ? "primary" : "default"}
                                key={item.key}
                                style={{ display: "flex", alignItems: "center", gap: 2 }}
                                onClick={() => handleHTMLScroll(item.key)}
                            >
                                {item.label}
                                {/* @ts-ignore */}
                                {totalWarningCount?.[item.key] !== undefined && <Badge count={totalWarningCount[item.key]} overflowCount={999999} />}
                            </Button>
                        );
                    })}
                    {/* <Divider type="vertical" />
                    <Button
                        type="ghost"
                        style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 4 }}
                        icon={<FaCog />}
                        onClick={() => setOpenSettingsModal(true)}
                        // onClick={() => handleHTMLScroll("SETTINGS")}
                    >
                        Settings
                    </Button> */}
                    <Divider type="vertical" />
                    <SoundButton soundSrc={soundFile} isActive={finalShouldPlaySound} repeatTime={20} />
                    <Button
                        type="ghost"
                        style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 4 }}
                        onClick={() => {
                            handleRefetchAll();
                        }}
                        shape="circle"
                        icon={<FaRedo style={{ fontSize: "0.75rem" }} />}
                    />
                </div>
            </FloatingMenu>
            <div
                id="OVERVIEW"
                style={{ flex: 1, minHeight: "50vh", background: "white", borderRadius: "0.25rem", position: "relative", margin: "1rem 0" }}
            >
                <Space direction="vertical" style={{ width: "100%", padding: "1rem" }}>
                    <Title level={4} style={{ margin: 0 }}>
                        Issues Log Overview
                    </Title>
                    <MessageCard type="info">Total Warnings: {globalTotalWarnings} warnings</MessageCard>
                    {IssuesLogMenuData.map((item, index) => {
                        if (
                            /* @ts-ignore */
                            (totalWarningCount?.[item.key] !== undefined && totalWarningCount[item.key] <= 0) ||
                            /* @ts-ignore */
                            totalWarningCount?.[item.key] === undefined
                        ) {
                            return null;
                        }
                        return (
                            <div
                                // @ts-ignore
                                key={item.key}
                                onClick={() => handleHTMLScroll(item.key)}
                                style={{ display: "inline-block", cursor: "pointer" }}
                            >
                                <MessageCard type="error">
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <span>
                                            {/* @ts-ignore */}
                                            {item.label} - {totalWarningCount[item.key]} warnings
                                        </span>
                                        <FaChevronRight style={{ marginLeft: "1rem" }} />
                                    </div>
                                </MessageCard>
                            </div>
                        );
                    })}
                </Space>
            </div>
            {/* <Modal
                title="Settings"
                open={openSettingsModal}
                onOk={() => setOpenSettingsModal(false)}
                onCancel={() => setOpenSettingsModal(false)}
                footer={null}
            >
                <Space direction="vertical" style={{ width: "100%" }}>
                    <Title level={4}>Settings</Title>
                    <MessageCard type="info">Settings will be available soon</MessageCard>
                </Space>
            </Modal> */}
        </>
    );
};

export default IssuesLogMenu;
