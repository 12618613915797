import { Form, FormInstance } from "antd";
import { DimensionFiltersProps, KeyValuePair } from "../../../../constants/type";
import FilterUpdateTemplate from "./FilterUpdateTemplate";

export interface AlarmFilterPanelProps {
    form: FormInstance;
    options: AlarmFilterOptionType;
    resetState: number;
}

export type AlarmFilterOptionType = { [key: number]: ItemProps };

export interface ItemProps {
    DimensionLevel: number;
    title: string;
    componentType: string;
    options: KeyValuePair[];
}

const AlarmFilterPanel = (props: AlarmFilterPanelProps) => {
    const fieldName = "DimensionFilters";
    return (
        <>
            <div className="filter-panel">
                <div className="title-panel">
                    <span>Dimension Filter:</span>
                </div>
                <div className="content">
                    <Form.List name={fieldName}>
                        {(fields) =>
                            fields.map((field, index) => {
                                let currentProps: DimensionFiltersProps = props.form.getFieldValue([fieldName, field.name]);
                                return (
                                    <FilterUpdateTemplate
                                        key={`im-fp-${field.key}-${index}`}
                                        form={props.form}
                                        parentFieldName={fieldName}
                                        componentId={field.name}
                                        settings={props.options[currentProps.DimensionLevel]}
                                        resetState={props.resetState}
                                    />
                                );
                            })
                        }
                    </Form.List>
                </div>
            </div>
        </>
    );
};

export default AlarmFilterPanel;
