import { Segmented } from "antd";
import CardBox from "../../../../components/Common/CardBox";
import { useState } from "react";
import SwapChargingToolSymbolSwap from "./components/symbolSwap";
import SwapChargingToolLogin from "./components/login";

export interface SwapChargingToolProps {}

const SwapChargingTool = (props: SwapChargingToolProps) => {
    const [currentTab, setCurrentTab] = useState<string>("Symbol Swap");

    return (
        <div className="swap-charging-tool-container">
            <CardBox title={"Swap Charging Tool"}>
                <div className="main-container">
                    <Segmented value={currentTab} options={["Symbol Swap", "Login"]} onChange={(activeKey: any) => setCurrentTab(activeKey)} />
                    {currentTab === "Symbol Swap" && <SwapChargingToolSymbolSwap />}
                    {currentTab === "Login" && <SwapChargingToolLogin />}
                </div>
            </CardBox>
        </div>
    );
};

export default SwapChargingTool;
