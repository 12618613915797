import React, { useEffect, useState } from "react";
import { APIs, apiRequest } from "../../../../services/apiConfig";
import { ErrorCatchValidator, ErrorMessageHandler, currencyRender, roundUpValue } from "../../../../utils/Common";
import { SUCCESS_FAILED } from "../../../../constants";
import { Col, Row, Tooltip } from "antd";
import CustomSkeleton from "../../../../components/Common/Skeleton";
import { MimMetricsExtra } from "../../../../constants/type";
import EmptyData from "../../../../components/Common/Empty";

interface SummaryStatsProps {
    metrics: MimMetricsExtra[];
    resetState: number;
}

const SummaryStats = (props: SummaryStatsProps) => {
    // component number 1
    const itemsPerRow = 1;
    const [data, setData] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        apiRequest(APIs.GET_MIM_METRIC_AS_OF_NOW, {
            metricSelectors: props.metrics.map((x: MimMetricsExtra) => ({
                metricName: x.metricName,
                columnOrder: x.metricOrder,
            })),
        })
            .then((res: any) => {
                const sortedDataWithValues = props.metrics.map((metric) => ({
                    ...metric,
                    value: metric.metricName in res[0] ? res[0][metric.metricName] : null,
                }));
                setData(sortedDataWithValues);
            })
            .catch((error) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("summary statistics", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsLoading(false));
        return () => {};
    }, [props.resetState]);

    const rowCount = Math.ceil(data.length / itemsPerRow);

    const rows = Array.from({ length: rowCount }, (_, rowIndex) => data.slice(rowIndex * itemsPerRow, (rowIndex + 1) * itemsPerRow));

    return (
        <div className="summary-stats-panel">
            {isLoading ? (
                <CustomSkeleton />
            ) : rows.length > 0 ? (
                rows.map((row, rowIndex) => (
                    <Row key={rowIndex} gutter={[8, 10]} justify={"space-around"} className="summary-blocks-info-row">
                        {row.map((col: any, colIndex: any) => (
                            <Col key={colIndex} span={12} className="summary-block-info-div">
                                <div className={`summary-value ${col.value > 0 ? "positive" : "negative"}`}>
                                    <Tooltip placement="top" title={currencyRender(col.value, 2)}>
                                        {roundUpValue(col.value)}
                                    </Tooltip>
                                </div>
                                <div className="summary-desc">{col.metricDisplayName}</div>
                            </Col>
                        ))}
                    </Row>
                ))
            ) : (
                <EmptyData />
            )}
            {/* {isLoading ? (
                <CustomSkeleton />
            ) : data.length > 0 ? (
                data.map((currData: any, i: number) => (
                    <div className="summary-block-info-div" key={i}>
                        <span className={`summary-value ${currData.value > 0 ? "positive" : "negative"}`}>
                            <Tooltip placement="top" title={currencyRender(currData.value)}>
                                {roundUpValue(currData.value)}
                            </Tooltip>
                        </span>
                        <span className="summary-desc">{currData.metricDisplayName}</span>
                    </div>
                ))
            ) : (
                <>
                    <EmptyData />
                </>
            )} */}
        </div>
    );
};

export default SummaryStats;
