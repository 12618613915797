import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import LoadingComponent from "../../components/Loading";
import { setProfileInfo } from "../../services/api";
import { removeSource } from "../../services/localStorage";
import { SUCCESS_FAILED } from "../../constants";
import { ErrorCatchValidator, ErrorMessageHandler } from "../../utils/Common";
import KeyClockHelper from "@/helpers/keyClockHelper";
import { message } from "antd";

function SSORedirection() {
    const [searchParams] = useSearchParams();
    const kcHelper = new KeyClockHelper();

    useEffect(() => {
        let qParams: any = {};
        searchParams.forEach((value: string, key: string) => {
            qParams[key] = value;
        });

        if (Object.keys(qParams).length === 0) {
            setProfileInfo({ manualCatch: 1 })
                .then(() => {
                    removeSource();
                    window.location.href = "/dashboard";
                })
                .catch((error: any) =>
                    ErrorCatchValidator(error, (err: any) => {
                        if (err.response.status === 403) {
                            window.location.href = "/forbidden";
                        } else {
                            ErrorMessageHandler("my account data", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                        }
                    })
                );
        } else {
            if (qParams.hasOwnProperty("kc_action_status") && qParams["kc_action_status"] === "cancelled") {
                message.error("Reset password failed. Please try again.", 3);
                return;
            }

            kcHelper.getAccessToken(qParams, () => {
                setProfileInfo({ manualCatch: 1 })
                    .then(() => {
                        removeSource();
                        window.location.href = "/dashboard";
                    })
                    .catch((error: any) =>
                        ErrorCatchValidator(error, (err: any) => {
                            if (err.response.status === 403) {
                                window.location.href = "/forbidden";
                            } else {
                                ErrorMessageHandler("my account data", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                            }
                        })
                    );
            });
        }

        return () => {};
    }, []);

    return (
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", height: "100vh", alignItems: "center", overflow: "hidden" }}>
            <LoadingComponent tip="Processing..." />
        </div>
    );
}

export default SSORedirection;
