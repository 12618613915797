import { ColourListProps } from "./type";

export const colours: ColourListProps[] = [
    {
        name: "Pastel red",
        hexCode: "#f67280"
    },
    {
        name: "Light coral",
        hexCode: "#f08080"
    },
    {
        name: "Salmon",
        hexCode: "#FA8072"
    },
    {
        name: "Dark salmon",
        hexCode: "#E9967A"
    },
    {
        name: "Light salmon",
        hexCode: "#FFA07A"
    },
    {
        name: "Deep peach",
        hexCode: "#ffcba4"
    },
    {
        name: "Peach puff",
        hexCode: "#ffdab9"
    },
    {
        name: "Light red",
        hexCode: "#ffcccb"
    },
    {
        name: "Pink",
        hexCode: "#FFC0CB"
    },
    {
        name: "Light pink",
        hexCode: "#FFB6C1"
    },
    {
        name: "Pastel pink",
        hexCode: "#fea3aa"
    },
    {
        name: "Hot pink",
        hexCode: "#FF69B4"
    },
    {
        name: "Pale violet red",
        hexCode: "#DB7093"
    },
    {
        name: "Pastel violet",
        hexCode: "#d291bc"
    },
    {
        name: "Violet",
        hexCode: "#EE82EE"
    },
    {
        name: "Mauve",
        hexCode: "#e0b0ff"
    },
    {
        name: "Blossom pink",
        hexCode: "#f9b7ff"
    },
    {
        name: "Pastel purple",
        hexCode: "#f2a2e8"
    },
    {
        name: "Plum",
        hexCode: "#DDA0DD"
    },
    {
        name: "Thistle",
        hexCode: "#D8BFD8"
    },
    {
        name: "Lavender",
        hexCode: "#E6E6FA"
    },
    {
        name: "Misty rose",
        hexCode: "#FFE4E1"
    },
    {
        name: "Cornsilk",
        hexCode: "#FFF8DC"
    },
    {
        name: "Antique white",
        hexCode: "#FAEBD7"
    },
    {
        name: "Blanched almond",
        hexCode: "#FFEBCD"
    },
    {
        name: "Bisque",
        hexCode: "#FFE4C4"
    },
    {
        name: "Moccasin",
        hexCode: "#FFE4B5"
    },
    {
        name: "Wheat",
        hexCode: "#F5DEB3"
    },
    {
        name: "Navajo white",
        hexCode: "#FFDEAD"
    },
    {
        name: "Coral",
        hexCode: "#FF7F50"
    },
    {
        name: "Dark orange",
        hexCode: "#FF8C00"
    },
    {
        name: "Orange",
        hexCode: "#FFA500"
    },
    {
        name: "Gold",
        hexCode: "#FFD700"
    },
    {
        name: "Yellow",
        hexCode: "#FFFF00"
    },
    {
        name: "Lemon yellow",
        hexCode: "#fef250"
    },
    {
        name: "Pastel yellow",
        hexCode: "#faf884"
    },
    {
        name: "Pale golden rod",
        hexCode: "#EEE8AA"
    },
    {
        name: "khaki",
        hexCode: "#F0E68C"
    },
    {
        name: "Pale green",
        hexCode: "#98FB98"
    },
    {
        name: "Light green",
        hexCode: "#90EE90"
    },
    {
        name: "Medium spring green",
        hexCode: "#3CB371"
    },
    {
        name: "Emerald",
        hexCode: "#50c878"
    },
    {
        name: "Yellow green",
        hexCode: "#9ACD32"
    },
    {
        name: "Pastel green",
        hexCode: "#77dd77"
    },
    {
        name: "Zombie green",
        hexCode: "#54c571"
    },
    {
        name: "Medium aquamarine",
        hexCode: "#66CDAA"
    },
    {
        name: "Aqua seafoam green",
        hexCode: "#93e9be"
    },
    {
        name: "Blue green",
        hexCode: "#7bccb5"
    },
    {
        name: "Dark Turquiose",
        hexCode: "#00ced1"
    },
    {
        name: "Turquiose",
        hexCode: "#40E0D0"
    },
    {
        name: "Aquamarine",
        hexCode: "#7FFFD4"
    },
    {
        name: "Light aquamarine",
        hexCode: "#93ffe8"
    },
    {
        name: "Electric blue",
        hexCode: "#9afeff"
    },
    {
        name: "Blue lagoon",
        hexCode: "#8eebec"
    },
    {
        name: "Pale Turquiose",
        hexCode: "#AFEEEE"
    },
    {
        name: "Robin egg blue",
        hexCode: "#bdedff"
    },
    {
        name: "Light day blue",
        hexCode: "#addfff"
    },
    {
        name: "Light sky blue",
        hexCode: "#87CEFA"
    },
    {
        name: "Sky blue",
        hexCode: "#87CEEB"
    },
    {
        name: "Crystal blue",
        hexCode: "#5cb3ff"
    },
    {
        name: "Iceberg",
        hexCode: "#56a5ec"
    },
    {
        name: "Sky blue dress",
        hexCode: "#6698ff"
    },
    {
        name: "Deep sky blue",
        hexCode: "#00bfff"
    },
    {
        name: "Midday blue",
        hexCode: "#3bb9ff"
    },
    {
        name: "Burly wood",
        hexCode: "#DEB887"
    },
    {
        name: "Sandy brown",
        hexCode: "#F4A460"
    },
    {
        name: "Bee yellow",
        hexCode: "#e9ab17"
    },
    {
        name: "Golden rod",
        hexCode: "#DAA520"
    },
    {
        name: "Peru",
        hexCode: "#CD853F"
    }
];