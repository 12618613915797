import { DownloadOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Modal, Row, Select, Typography, notification } from "antd";
import React, { useState } from "react";
import useRCRejectOrderServers from "../../../hooks/useRCRejectOrderServers";
import Loader from "../components/Loader";
import moment from "moment-timezone";
import { getToken } from "../../../services/localStorage";
import axios from "axios";
import { APIs } from "../../../services/apis";

const DownloadRejectOrderButton = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleModalOpen = (open: boolean) => {
        setIsModalOpen(open);
    };
    return (
        <>
            <Button type="ghost" icon={<DownloadOutlined />} onClick={() => handleModalOpen(true)}>
                Download
            </Button>
            {isModalOpen && <DownloadRejectOrderModal open={isModalOpen} handleModalOpen={handleModalOpen} />}
        </>
    );
};

const DownloadRejectOrderModal = ({ open, handleModalOpen }: { open: boolean; handleModalOpen: (open: boolean) => void }) => {
    const { RangePicker } = DatePicker;
    const { rcRejectOrderServers, rcRejectOrderServersError, refetchRcRejectOrderServersError, isLoading } = useRCRejectOrderServers();

    const [form] = Form.useForm();
    const { Item } = Form;
    const { Text } = Typography;

    const serverOptions = rcRejectOrderServers?.map((each: any) => ({ label: each, value: each })) ?? [];
    const initFormValue = {
        server: null,
        range: null,
    };

    const handleSubmit = async (values: any) => {
        const token = getToken();
        const startTime = moment(values.range[0]).format("YYYY-MM-DD") + " 00:00";
        const endTime = moment(values.range[1]).format("YYYY-MM-DD") + " 23:59";
        const server = values.server;
        axios
            .get(
                `${process.env.REACT_APP_API_URL}${APIs.RC_REJECT_ORDER.GET_REJECT_ORDER_DOWNLOAD}?startTime=${startTime}&endTime=${endTime}&server=${server}`,
                {
                    headers: {
                        Accept: "application/octet-stream, */*",
                        // "Content-type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    responseType: "blob",
                }
            )
            .then(response => {
                // Check if the response content type is 'application/octet-stream'
                const contentType = response.headers["content-type"];
                if (
                    contentType === "application/octet-stream" ||
                    contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ) {
                    // Handle the file download response
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `reject_order_${server}_${startTime}_${endTime}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                    // Clean up
                    window.URL.revokeObjectURL(url);
                    notification.success({
                        message: "Downloaded",
                        description: `Reject Order report for server ${server} from ${startTime} to ${endTime} downloaded successfully`,
                    });
                    handleModalOpen(false);
                } else {
                    notification.error({
                        message: "Error",
                        description: `Received non-file response. Error: ${response}`,
                    });
                    console.log("Received non-file response:", response);
                }
            })
            .catch(err => {
                notification.error({
                    message: "Error",
                    description: `Download error: ${err}`,
                });
                console.log("download error", err);
            });
        // sample url https://riskrc.unicornfintech.com/api/rejectOrder/downloadReport?startTime=2024-05-01%2015:47&endTime=2024-05-03%2015:47&server=AU1
    };

    return (
        <Form
            form={form}
            layout="vertical"
            initialValues={initFormValue}
            /* onValuesChange={handleFormUpdate} */ requiredMark={true}
            onFinish={handleSubmit}
        >
            <Modal
                title="Download Reject Order"
                okText={"Download"}
                okButtonProps={{
                    icon: <DownloadOutlined />,
                }}
                open={open}
                onOk={() => form.submit()}
                onCancel={() => handleModalOpen(false)}
            >
                {isLoading && !rcRejectOrderServers ? (
                    <Loader />
                ) : (
                    <Row gutter={[8, 8]}>
                        <Col span={8} xs={{ span: 24 }} md={{ span: 8 }} xl={{ span: 8 }}>
                            <Text>Server *</Text>
                            <Item style={{ margin: 0, padding: 0 }} name="server" rules={[{ required: true, message: "Please select server" }]}>
                                <Select
                                    allowClear
                                    style={{ width: "100%" }}
                                    placeholder="Select Servers"
                                    options={serverOptions}
                                    showSearch
                                    onChange={data => {
                                        form.setFieldsValue({ server: data });
                                    }}
                                />
                            </Item>
                        </Col>
                        <Col span={16} xs={{ span: 24 }} md={{ span: 16 }}>
                            <Text>Date Range *</Text>
                            <Item
                                style={{ margin: 0, padding: 0 }}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                required
                                name="range"
                                rules={[{ required: true, message: "Please select date range" }]}
                            >
                                <RangePicker
                                    style={{ width: "100%" }}
                                    // disable selecting the day after today, and 20 days before today
                                    disabledDate={current => {
                                        return current && (current > moment().endOf("day") || current < moment().subtract(20, "days").startOf("day"));
                                    }}
                                    onChange={data => {
                                        form.setFieldsValue({ range: data });
                                    }}
                                />
                            </Item>
                        </Col>
                    </Row>
                )}
            </Modal>
        </Form>
    );
};

export default DownloadRejectOrderButton;
