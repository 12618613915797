import { message } from "antd";
import { useEffect, useState } from "react";
import CardBox from "../../../components/Common/CardBox";
import FlexiDataTable from "../../../components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "../../../constants";
import {
    CustomPaginationProps,
    HistoricalTrades,
    FlexiDataTableOptionsProps,
    FlexiDataTableCallbackProps,
    TradeTypes,
    ReasonType,
} from "../../../constants/type";
import { apiRequest } from "../../../services/apiConfig";
import { APIs } from "../../../services/apis";
//import { ToOptionTypeList } from "../../../utils/array";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";
import { ProfileInfoProps } from "./viewProfile";
import { SOMETHING_WENT_WRONG } from "../../../constants/errorMessage";
import { DateTimeUtil } from "../../../utils/datetime";

export interface HistoricalTradesPageProps extends ProfileInfoProps {}

const HistoricalTradesPage = (props: HistoricalTradesPageProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [data, setData] = useState<HistoricalTrades[]>([]);
    const [pagination, setPagination] = useState<CustomPaginationProps>({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [filterParams, setFilterParams] = useState<any>({});
    const [sorting, setSorting] = useState<string>("ticket,desc");
    const [tradeTypes, setTradeTypes] = useState<TradeTypes[]>([]);
    const [reasonType, setReasonType] = useState<ReasonType[]>([]);

    const columns = [
        // DTColProps.Small({
        //     title: "Server",
        //     dataIndex: "server",
        //     key: "server",
        //     options: {
        //         filter: {
        //             type: ComponentType.text,
        //         },
        //     },
        // }),
        // DTColProps.Small({
        //     title: "Account ID",
        //     dataIndex: "accountId",
        //     key: "accountId",
        //     options: {
        //         filter: {
        //             type: ComponentType.text,
        //         },
        //     },
        // }),

        DTColProps.Small({
            title: "Ticket",
            dataIndex: "ticket",
            key: "ticket",
            sorter: true,
            options: {
                filter: {
                    type: ComponentType.number,
                },
            },
        }),
        DTColProps.Small(
            {
                title: "Position ID",
                dataIndex: "positionId",
                key: "positionId",
                options: {
                    filter: {
                        type: ComponentType.number,
                    },
                },
            },
            ["text-right"]
        ),

        DTColProps.Small({
            title: "Symbol",
            dataIndex: "symbol",
            key: "symbol",
            options: {
                filter: {
                    type: ComponentType.text,
                },
            },
        }),
        DTColProps.Small({
            title: "Type",
            dataIndex: "type",
            key: "type",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: tradeTypes.map((x) => ({ text: x.name, value: x.id })),
                },
            },
        }),
        DTColProps.XSmall({
            title: "Direction",
            dataIndex: "direction",
            key: "direction",
        }),
        DTColProps.Small({
            title: "Reason",
            dataIndex: "reason",
            key: "reason",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: reasonType.map((x) => ({ text: x.name, value: x.id })),
                },
            },
        }),
        DTColProps.Small(
            {
                title: "Lot",
                dataIndex: "lot",
                key: "lot",
                sorter: true,
            },
            ["text-right"]
        ),
        DTColProps.DateTime({
            title: "Open Time (Server)",
            dataIndex: "openTime",
            key: "openTime",
            sorter: true,
            render: (text: any, record: HistoricalTrades) => {
                return <span>{text}</span>;
            },
            options: {
                filter: {
                    type: ComponentType.daterange,
                    value: "",
                    inputProps: {
                        showTime: { format: "HH:mm:ss" },
                    },
                },
            },
        }),
        DTColProps.DateTime({
            title: "Close Time (Server)",
            dataIndex: "closeTime",
            key: "closeTime",
            sorter: true,
            render: (text: any, record: HistoricalTrades) => {
                return <span>{text}</span>;
            },
            options: {
                filter: {
                    type: ComponentType.daterange,
                    value: "",
                    inputProps: {
                        showTime: { format: "HH:mm:ss" },
                    },
                },
            },
        }),
        DTColProps.SCurrency(
            {
                title: "Open Price",
                dataIndex: "openPrice",
                key: "openPrice",
            },
            ["text-right"],
            -1
        ),
        DTColProps.SCurrency(
            {
                title: "Close Price",
                dataIndex: "closePrice",
                key: "closePrice",
            },
            ["text-right"],
            -1
        ),
        DTColProps.SCurrency(
            {
                title: "Stop Loss",
                dataIndex: "stopLoss",
                key: "stopLoss",
            },
            ["text-right"],
            -1
        ),
        DTColProps.SCurrency(
            {
                title: "Take Profit",
                dataIndex: "takeProfit",
                key: "takeProfit",
            },
            ["text-right"],
            -1
        ),

        DTColProps.SCurrency(
            {
                title: "Profit",
                dataIndex: "profit",
                key: "profit",
                sorter: true,
            },
            ["text-right"],
            -1
        ),
        DTColProps.SCurrency(
            {
                title: "Commission",
                dataIndex: "commission",
                key: "commission",
            },
            ["text-right"],
            -1
        ),
        DTColProps.SCurrency(
            {
                title: "Swaps",
                dataIndex: "swaps",
                key: "swaps",
            },
            ["text-right"],
            -1
        ),
        DTColProps.SCurrency(
            {
                title: "Taxes",
                dataIndex: "taxes",
                key: "taxes",
            },
            ["text-right"],
            -1
        ),
        DTColProps.SCurrency(
            {
                title: "Fee",
                dataIndex: "fee",
                key: "fee",
            },
            ["text-right"],
            -1
        ),
        DTColProps.Middle({
            title: "Comment",
            dataIndex: "comment",
            key: "comment",
            options: {
                filter: {
                    type: ComponentType.text,
                },
            },
        }),
    ];

    const options: FlexiDataTableOptionsProps = {
        add: false,
        serverFiltering: true,
        export: { text: "Export as CSV" },
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, FormData) => {
        switch (type) {
            case CALLBACK_KEY.HANDLE_PAGINATION_SORTING:
                FormData.sorter.order === undefined
                    ? setSorting("ticket,desc")
                    : setSorting(
                          FormData.sorter.column && FormData.sorter.order
                              ? `${FormData.sorter.column.key},${FormData.sorter.order === "ascend" ? "asc" : "desc"}`
                              : ""
                      );
                setPagination((prev) => ({ ...prev, current: FormData.pagination.current, pageSize: FormData.pagination.pageSize }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                let tmpFilterParams: any = {};
                Object.keys(FormData)
                    .filter((x) => (FormData[x] === undefined ? false : FormData[x].toString().length > 0))
                    .map((x) => {
                        if (x === "server") {
                            tmpFilterParams["serverId"] = FormData[x];
                        } else if (x === "brand") {
                            tmpFilterParams["brandId"] = FormData[x];
                        } else if (x === "openTime") {
                            tmpFilterParams["openTimeFrom"] = DateTimeUtil.GetOrigin(FormData[x][0]);
                            tmpFilterParams["openTimeTo"] = DateTimeUtil.GetOrigin(FormData[x][1]);
                        } else if (x === "closeTime") {
                            tmpFilterParams["closeTimeFrom"] = DateTimeUtil.GetOrigin(FormData[x][0]);
                            tmpFilterParams["closeTimeTo"] = DateTimeUtil.GetOrigin(FormData[x][1]);
                            //} else if (x === "volume") {
                            //    if (FormData[x].from !== undefined && FormData[x].from.length > 0) {
                            //        tmpFilterParams["volumeFrom"] = parseFloat(FormData[x].from);
                            //    }
                            //    if (FormData[x].to !== undefined && FormData[x].to.length > 0) {
                            //        tmpFilterParams["volumeTo"] = parseFloat(FormData[x].to);
                            //    }
                        } else if (x === "profit") {
                            if (FormData[x].from !== undefined && FormData[x].from.length > 0) {
                                tmpFilterParams["profitFrom"] = parseFloat(FormData[x].from);
                            }
                            if (FormData[x].to !== undefined && FormData[x].to.length > 0) {
                                tmpFilterParams["profitTo"] = parseFloat(FormData[x].to);
                            }
                        } else {
                            tmpFilterParams[x] = FormData[x];
                        }
                        return x;
                    });
                setFilterParams(tmpFilterParams);
                setPagination((prev) => ({ ...prev, current: 1 }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.EXPORT_CSV_EXCEL:
                setIsBtnLoading(true);
                apiRequest(
                    APIs.DOWNLOAD_TRADE,
                    {
                        serverId: props.serverId,
                        brandId: props.brandId,
                        accountId: props.accountId,
                        fileType: 0,
                        limit: 0,
                        ...(Object.keys(filterParams)?.length > 0 && { ...filterParams }),
                        ...(sorting.length > 0 && { order: sorting }),
                    },
                    "POST",
                    "arraybuffer"
                )
                    .then((res: any) => {
                        const fileName = res.headers["x-filename"];
                        const url = window.URL.createObjectURL(new Blob([res.data], { type: "application/zip" })); // Create blob link to download
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", fileName); // or any other extension
                        document.body.appendChild(link); // Append to html link element page
                        link.click(); // start download
                        document.body.removeChild(link); // Clean up and remove the link
                    })
                    .catch((error: any) => ErrorCatchValidator(error, (err: any) => message.error(SOMETHING_WENT_WRONG, 3)))
                    .finally(() => setIsBtnLoading(false));
                break;
            default:
                break;
        }
    };

    const getFilterConfigList = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["tradetype", "reasontype"] })
            .then((data: any) => {
                setTradeTypes(data.tradeTypes);
                setReasonType(data.reasonType);
            })
            .catch((error) => {
                ErrorCatchValidator(error, (err: any) => console.log(err));
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const getDataList = () => {
        apiRequest(APIs.GET_HISTORICAL_TRADES, {
            serverId: props.serverId,
            brandId: props.brandId,
            accountId: props.accountId,
            limit: pagination.pageSize,
            current: pagination.current,
            ...(Object.keys(filterParams).length > 0 && { ...filterParams }),
            ...(sorting.length > 0 && { order: sorting }), //`${sorting.toLowerCase().indexOf("opentime") > -1 ? "" : "openTime,desc|"}${sorting}` }),
        })
            .then((data: any) => {
                if (data.result && data.result.length > 0) {
                    getFilterConfigList();
                    setData(data.result);
                    setPagination((prev) => ({ ...prev, total: data.total }));
                } else {
                    setData([]);
                    setPagination((prev) => ({ ...prev, total: 0 }));
                }
            })
            .catch((error) => {
                ErrorCatchValidator(error, (res: any) => ErrorMessageHandler("historical trades", SUCCESS_FAILED.FAILED_LOAD_DATA, res));
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        setRunRefetchDataList(true);
        return () => {};
    }, []);

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            getDataList();
            setRunRefetchDataList(false);
        }
        return () => {};
    }, [runRefetchDataList]);

    return (
        <div className="historical-trades-container">
            <CardBox title={"Historical Trades"}>
                <FlexiDataTable
                    rowKeyProperty="ticket"
                    title=""
                    columns={columns}
                    options={options}
                    dataSource={data || []}
                    callback={componentCallback}
                    loading={isLoading}
                    pagination={pagination}
                    serverSide={true}
                    exporting={isBtnLoading}
                />
            </CardBox>
        </div>
    );
};

export default HistoricalTradesPage;
