import moment from "moment-timezone";
import { useEffect, useMemo, useState } from "react";
import FlexiDataTable from "@/components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "@/constants";
import { FlexiDataColumnProps, FlexiDataTableCallbackProps, FlexiDataTableOptionsProps } from "@/constants/type";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { ServerUnoMapName } from "./index";
import { Button, Modal } from "antd";
import { ContainerOutlined, EditOutlined } from "@ant-design/icons";
import CommentModal from "./CommentModal";
import ReportBatchModal, { ReportBatchModalCallbackKey } from "../ReportBatch";

type EquityMonitorTabProps = {
    servers: ServerUnoMapName;
};
const EquityMonitorTab = ({ servers }: EquityMonitorTabProps) => {
    const dateformat = "YYYY-MM-DD";
    const initialFilterParams = {
        createTime: moment(new Date(), dateformat).startOf("day"),
    };
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isDownloadLoading, setIsDownloadLoading] = useState<boolean>(false);
    const [datalist, setDatalist] = useState<any[]>([]);
    const [filterParams, setFilterParams] = useState<any>(initialFilterParams);
    const [showCommentModal, setShowCommentModal] = useState<boolean>(false);
    const [selectedData, setSelectedData] = useState<any>(undefined);
    const [isReportModalVisible, setIsReportModalVisible] = useState<boolean>(false);

    const columns: FlexiDataColumnProps[] = useMemo(
        () => [
            DTColProps.Small({
                title: "Server",
                dataIndex: "server",
                key: "server",
                sorter: (a: any, b: any) => a.server.localeCompare(b.server),
            }),
            DTColProps.Small({
                title: "Login",
                dataIndex: "login",
                key: "login",
                sorter: (a: any, b: any) => a.login - b.login
            }),
            DTColProps.DateTime_ServerDateOnly({
                title: "Reg Date",
                dataIndex: "regDate",
                key: "regDate",
                defaultSortOrder: "descend",
                sorter: (a: any, b: any) => {
                    const aDate = moment(a.regDate);
                    const bDate = moment(b.regDate);
                    if (aDate.isBefore(bDate)) return -1;
                    if (aDate.isAfter(bDate)) return 1;
                    return 0;
                },
            }),
            DTColProps.Small({
                title: "Group",
                dataIndex: "group",
                key: "group",
                sorter: (a: any, b: any) => a.group.localeCompare(b.group),
            }),
            DTColProps.Small({
                title: "Leverage",
                dataIndex: "leverage",
                key: "leverage",
                sorter: (a: any, b: any) => a.leverage - b.leverage
            }),
            DTColProps.Small({
                title: "Balance",
                dataIndex: "balance",
                key: "balance",
                sorter: (a: any, b: any) => a.balance - b.balance
            }),
            DTColProps.Small({
                title: "Equity",
                dataIndex: "equity",
                key: "equity",
                sorter: (a: any, b: any) => a.equity - b.equity
            }),
            DTColProps.Middle({
                title: "Change Reason",
                dataIndex: "changeReason",
                key: "changeReason",
                sorter: (a: any, b: any) => {
                    if (a.changeReason === null && b.changeReason === null) return 0;
                    if (a.changeReason === null) return 1;
                    if (b.changeReason === null) return -1;
                    return a.changeReason.localeCompare(b.changeReason);
                },
            }),
            DTColProps.DateTime_ServerTime({
                title: "Create Time",
                dataIndex: "createTime",
                key: "createTime",
                defaultSortOrder: "descend",
                options: {
                    filter: {
                        type: ComponentType.date,
                        value: "",
                        dateFormat: dateformat,
                        inputProps: {
                            showTime: false,
                        },
                        callback: (filterValue: any, rowData: any) => {
                            const _filterValue = moment(filterValue as moment.Moment).startOf("day").format(dateformat);
                            const rowCreateTime = moment(rowData["createTime"]).format(dateformat);
                            return _filterValue === rowCreateTime;
                        }
                    },
                },
            }),
            DTColProps.Middle({
                title: "Comment",
                dataIndex: "comment",
                key: "comment",
                sorter: (a: any, b: any) => {
                    if (a.comment === null && b.comment === null) return 0;
                    if (a.comment === null) return 1;
                    if (b.comment === null) return -1;
                    return a.comment.localeCompare(b.comment);
                },
                render: (comment: string, rowData: any) => {
                    return (
                        <>
                            <div className="comment-cell">
                                <Button
                                    type="link"
                                    icon={<EditOutlined />}
                                    title="Edit Comment"
                                    onClick={() => {
                                        setSelectedData(rowData);
                                        setShowCommentModal(true)
                                    }}
                                />
                                <span>{comment}</span>
                            </div>
                        </>
                    )
                },
            }),
        ],
        []
    );

    useEffect(() => {
        getDataList();
    }, []);

    function getDataList(_filterParams?: any) {
        const param = _filterParams ? _filterParams : { ...filterParams };
        const formData = new FormData();
        formData.append("createTime", param["createTime"] ? param["createTime"].format(dateformat).toString() : "");

        setIsLoading(true);
        plainAxiosInstance
            .post(`${APIs.RC_LINKED_ACCOUNT_RECORD.GET_EQUITY_MONITOR}`, formData)
            .then((res: any) => {
                const data = res.data;
                if (data) {
                    setDatalist(data.map((item: any) => {
                        return {
                            ...item,
                            key: item.serverUno + "_" + item.login + "_" + item.group + "_" + item.createTime,
                            server: servers[item.serverUno] ?? '',
                        }
                    }))
                }
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("data", SUCCESS_FAILED.FAILED_LOAD_DATA, err)))
            .finally(() => setIsLoading(false));
    }

    const componentCallback: FlexiDataTableCallbackProps = (type, FormData) => {
        switch (type) {
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                let fParams: any = {};
                Object.keys(FormData)
                    .filter(x => FormData[x] !== undefined && FormData[x].toString().length > 0)
                    .map(x => {
                        if (x === "createTime") {
                            fParams["createTime"] = FormData[x];
                        }
                        return x;
                    });
                setFilterParams(fParams);
                getDataList(fParams);
                break;
            case CALLBACK_KEY.EXPORT_CSV_EXCEL:
                if (!filterParams["createTime"]) {
                    Modal.error({
                        title: "Error",
                        content: "Please select a date to download report.",
                    });
                    return;
                }
                exportReportBatch();
                break;
            case CALLBACK_KEY.OTHERS:
                switch (FormData) {
                    case "reportlist":
                        setIsReportModalVisible(true);
                        break;
                    default:
                        break;
                }
                break;
            default:
                break;
        }
    };

    const options: FlexiDataTableOptionsProps = {
        recordRowClassName: (record) => {
            const today = moment().startOf("day");
            const regDay = moment(record.regDate).startOf("day");
            return today.isSame(regDay) ? "today-row" : "";
        },
        serverFiltering: true,
        export: { text: "Download" },
        extraButtons: [{ text: "Report Listing", value: "reportlist", icon: <ContainerOutlined /> }],
    }

    function exportReportBatch() {
        setIsDownloadLoading(true);
        const formData = new FormData();
        formData.append("createTime", filterParams["createTime"]?.format(dateformat).toString());

        plainAxiosInstance
            .post(APIs.RC_LINKED_ACCOUNT_RECORD.POST_DOWNLOAD_EQUITY_MONITOR_REPORT, formData)
            .then(() => {
                Modal.success({
                    width: "30%",
                    title: "Successfully added batch",
                    content: "Please download the report from 【Report Listing】 once it is completed",
                });
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("Equity Monitor Report", SUCCESS_FAILED.FAILED_DOWNLOAD_DATA, err))
            )
            .finally(() => setIsDownloadLoading(false));
    }

    return (
        <div className="equity-monitor">
            <FlexiDataTable
                rowKeyProperty="key"
                title="SBook Equity"
                columns={columns}
                dataSource={datalist}
                callback={componentCallback}
                loading={isLoading}
                filterInitialValue={initialFilterParams}
                exporting={isDownloadLoading}
                options={options}
                bordered
            />
            <CommentModal
                open={showCommentModal}
                onModalCancel={() => setShowCommentModal(false)}
                data={selectedData}
                onModalOk={() => {
                    getDataList();
                    setShowCommentModal(false);
                }}
            />
            <ReportBatchModal
                isModalVisible={isReportModalVisible}
                modalTitle={"Download Report"}
                callback={(type: number) => {
                    switch (type) {
                        case ReportBatchModalCallbackKey.Close:
                            setIsReportModalVisible(false);
                            break;
                        default:
                            break;
                    }
                }}
                filterCallback={(record: any) => record.batchName === "EquityMonitorReport"}
            />
        </div>
    );
};

export default EquityMonitorTab;
