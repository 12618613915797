import { useQuery } from "@tanstack/react-query";
import { APIs } from "../services/apis";
import { plainAxiosInstance } from "../services/axiosSetup";
import { serverData, dummySymbols } from "./../pages/PriceMonitor/PriceCompare/dummy";
import useRCPriceCompareStore, { IComparisonList } from "../store/useRCPriceCompareStore";

/*
sample response from the API
{
    "serverUno": 519,
    "serverDn": null,
    "brandId": null,
    "name": "Exness-Pro",
    "isDel": null,
    "timeZone": null,
    "ip": null,
    "port": null,
    "type": 2,
    "startDate": null,
    "endDate": null,
    "nowTime": null,
    "ids": null,
    "brandName": null,
    "zoneId": null,
    "order": null,
    "serverAttributes": null
}
*/

interface RCPriceCompareServer {
    serverUno: number;
    serverDn: number | null;
    brandId: number | null;
    name: string;
    isDel: number | null;
    timeZone: number | null;
    ip: string | null;
    port: number | null;
    type: number;
    startDate: string | null;
    endDate: string | null;
    nowTime: string | null;
    ids: string | null;
    brandName: string | null;
    zoneId: number | null;
    order: number | null;
    serverAttributes: number | null;
}

const useRCPriceCompareServers = () => {
    const { data, refetch, isLoading, error, isFetching, dataUpdatedAt } = useQuery({
        queryKey: ["rcPriceCompareServers"],
        queryFn: () =>
            plainAxiosInstance.get(`${APIs.RC_PRICE_COMPARE.GET_SERVERS}`, {
                headers: {
                    Accept: "application/json",
                },
            }),
        staleTime: 60 * 1000, //(10 x 1000ms = 10 seconds)
    });

    const rcPriceCompareServers: RCPriceCompareServer[] | null = data?.data?.status ? null : data?.data || null;

    const rcPriceCompareOwnServers = serverData
        ?.filter((server: any) => (server.type & 0x09) > 0)
        // sort by each name
        .sort((a: any, b: any) => a.name.localeCompare(b.name));
    const rcPriceCompareCompetitorServers = serverData
        ?.filter((server: any) => (server.type & 0x09) === 0)
        // sort by each name
        .sort((a: any, b: any) => a.name.localeCompare(b.name));
    // const rcPriceCompareOwnServers = rcPriceCompareServers?.filter(server => (server.type & 0x09) > 0);
    // const rcPriceCompareCompetitorServers = rcPriceCompareServers?.filter(server => (server.type & 0x09) === 0);

    // @ts-ignore
    const rcPriceCompareServersError: string | null = data?.data?.message || error?.message || error || null;

    return {
        rcPriceCompareServers,
        rcPriceCompareOwnServers,
        rcPriceCompareCompetitorServers,
        rcPriceCompareServersError,
        refetchRcPriceCompareServers: refetch,
        isFetching,
        isLoading,
        dataUpdatedAt,
    };
};

/* sample pricecomparesymbols api response
 
[{
    "id": 0,
    "serverUno": 3,
    "symbol": "AALG",
    "cleanSymbol": "AALG",
    "noPriceTime": 0,
    "openNoPrice": 0,
    "monday": null,
    "tuesday": null,
    "wednesday": null,
    "thursday": null,
    "friday": null,
    "saturday": null,
    "sunday": null,
    "digit": 0,
    "showStatus": 0,
    "alertStatus": 0,
    "forceSession": 0,
    "pointSite": 0,
    "modifyTime": null,
    "isNew": 0,
    "serverName": null,
    "serverType": 0,
    "typeName": null
}]

*/

interface IPriceCompareSymbol {
    id: number;
    serverUno: number;
    symbol: string;
    cleanSymbol: string;
    noPriceTime: number;
    openNoPrice: number;
    monday: number | null;
    tuesday: number | null;
    wednesday: number | null;
    thursday: number | null;
    friday: number | null;
    saturday: number | null;
    sunday: number | null;
    digit: number;
    showStatus: number;
    alertStatus: number;
    forceSession: number;
    pointSite: number;
    modifyTime: number | null;
    isNew: number;
    serverName: string | null;
    serverType: number;
    typeName: string | null;
}

export const useRCPriceCompareSymbols = ({ serverName }: { serverName?: string }) => {
    const { data, refetch, isLoading, error, isFetching, dataUpdatedAt } = useQuery({
        queryKey: ["rcPriceCompareSymbols", serverName],
        queryFn: () =>
            plainAxiosInstance.get(`${APIs.RC_PRICE_COMPARE.GET_SYMBOLDETAILS}?serverName=${serverName}`, {
                headers: {
                    Accept: "application/json",
                },
            }),
        staleTime: 60 * 1000, //(10 x 1000ms = 10 seconds)
        // refetchInterval: 60 * 1000, //(10 x 1000ms = 10 seconds)
        // disable if no serverName
        enabled: !!serverName,
    });

    const rcPriceCompareSymbols: IPriceCompareSymbol[] | null = data?.data?.status ? null : data?.data || null;
    // @ts-ignore
    const rcPriceCompareSymbolsError: string | null = data?.data?.message || error?.message || error || null;
    const dummy = dummySymbols;
    return {
        dummy,
        rcPriceCompareSymbols,
        rcPriceCompareSymbolsError,
        refetchRcPriceCompareOurServer: refetch,
        isFetching,
        isLoading,
        dataUpdatedAt,
    };
};

export interface IPriceCompareOurServer {
    d_idx: {
        ask: number;
        bid: number;
        mid: number;
        sign: number;
        spread: number;
        ticktime: number;
    };
    data: {
        [key: string]: number[];
    };
}

export const useRCPriceCompareOurServer = ({ serverName }: { serverName: string }) => {
    const { data, refetch, isLoading, error, isFetching, dataUpdatedAt } = useQuery({
        queryKey: ["rcPriceCompareOurServer", serverName],
        queryFn: () =>
            plainAxiosInstance.get(`${APIs.RC_PRICE_COMPARE.GET_COMPARE_OUR_SERVERS}?serverName=${serverName}`, {
                headers: {
                    Accept: "application/json",
                },
            }),
        staleTime: 2 * 1000, //(10 x 1000ms = 10 seconds)
        refetchInterval: 2 * 1000, //(10 x 1000ms = 10 seconds)
        // disable if no serverName
        enabled: !!serverName,
    });

    const rcPriceCompareOurServer: IPriceCompareOurServer | null = data?.data?.status ? null : data?.data || null;

    // @ts-ignore
    const rcPriceCompareOurServerError: string | null = data?.data?.message || error?.message || error || null;

    return {
        rcPriceCompareOurServer,
        rcPriceCompareOurServerError,
        refetchRcPriceCompareOurServer: refetch,
        isFetching,
        isLoading,
        dataUpdatedAt,
    };
};

export interface IPriceCompareCompetitor {
    c_idx: {
        [key: string]: number;
    };
    d_idx: {
        ask: number;
        bid: number;
        mid: number;
        sign: number;
        spread: number;
        ticktime: number;
    };
    data: {
        [key: string]: number[];
    };
}
export const useRCPriceCompareCompetitor = ({ serverName, competitorServerName }: { serverName: string; competitorServerName: string }) => {
    const { data, refetch, isLoading, error, isFetching, dataUpdatedAt } = useQuery({
        queryKey: ["rcPriceCompareCompetitor", serverName, competitorServerName],
        queryFn: () =>
            plainAxiosInstance.get(
                `${APIs.RC_PRICE_COMPARE.GET_COMPARE_COMPETITOR}?serverName=${serverName}&competitorServerName=${competitorServerName}`,
                {
                    headers: {
                        Accept: "application/json",
                    },
                }
            ),
        staleTime: 2 * 1000, //(10 x 1000ms = 10 seconds)
        refetchInterval: 2 * 1000, //(10 x 1000ms = 10 seconds)
        // disable if no serverName
        enabled: !!serverName,
    });

    const rcPriceCompareCompetitor: IPriceCompareCompetitor | null = data?.data?.status ? null : data?.data || null;

    // @ts-ignore
    const rcPriceCompareCompetitorError: string | null = data?.data?.message || error?.message || error || null;

    return {
        rcPriceCompareCompetitor,
        rcPriceCompareCompetitorError,
        refetchRcPriceCompareCompetitor: refetch,
        isFetching,
        isLoading,
        dataUpdatedAt,
    };
};

export default useRCPriceCompareServers;
