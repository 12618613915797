import React, { useEffect, useState } from "react";
import SitePageHeader from "../../../../components/PageHeader";
import { useLocation, useNavigate } from "react-router-dom";
import { HomeOutlined } from "@ant-design/icons";
import LoadingComponent from "../../../../components/Loading";
import { Button, Col, Form, Row } from "antd";
import { FormComponent } from "../../../../components/FormComponent";
import { ComponentType, SUCCESS_FAILED } from "../../../../constants";
import { REQUIRED_FIELD } from "../../../../constants/errorMessage";
import { APIs, apiRequest } from "../../../../services/apiConfig";
import { ErrorCatchValidator, ErrorMessageHandler } from "../../../../utils/Common";
import { WatchListGroup } from "../../../../constants/type";

const CreateEditAccWatchListGroup = () => {
    let navigate = useNavigate();
    let location = useLocation();
    let com_state: WatchListGroup = location.state as WatchListGroup;
    const [currentState] = useState<any>(com_state || { action: "add", data: null });
    const [isAddAction] = useState<boolean>(currentState.action === "add");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [accWatchListGroupForm] = Form.useForm();

    const onSubmit = (values: WatchListGroup) => {
        setIsLoading(true);

        if (isAddAction) {
            apiRequest(APIs.CREATE_ACC_WATCH_LIST_GROUP, values)
                .then((data) => {
                    ErrorMessageHandler("New account watch list group", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                    navigate("/siteadmin/appconfig/accWatchListGroup");
                })
                .catch((error) =>
                    ErrorCatchValidator(error, (err: any) =>
                        ErrorMessageHandler("new account watch list group", SUCCESS_FAILED.FAILED_CREATE_DATA, err)
                    )
                )
                .finally(() => setIsLoading(false));
        } else {
            apiRequest(APIs.UPDATE_ACC_WATCH_LIST_GROUP, { ...values })
                .then((data) => {
                    ErrorMessageHandler("Existing account watch list group", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                    navigate("/siteadmin/appconfig/accWatchListGroup");
                })
                .catch((error) =>
                    ErrorCatchValidator(error, (err: any) =>
                        ErrorMessageHandler("existing account watch list group", SUCCESS_FAILED.FAILED_UPDATE_DATA, err)
                    )
                )
                .finally(() => setIsLoading(false));
        }
    };

    useEffect(() => {
        !isAddAction ? accWatchListGroupForm.setFieldsValue(currentState.data) : accWatchListGroupForm.resetFields();
        return () => {};
    }, [currentState.data, isAddAction, accWatchListGroupForm]);

    return (
        <SitePageHeader
            title={isAddAction ? "Create New Account Watch List Group" : "Edit Account Watch List Group"}
            routes={[
                {
                    path: "/siteadmin/appconfig/accWatchListGroup",
                    breadcrumbName: "Account Watch List Group",
                    icon: <HomeOutlined />,
                },
                {
                    path: "",
                    breadcrumbName: isAddAction ? "Create New Account Watch List Group" : "Edit Account Watch List Group",
                },
            ]}
            onBack={() => navigate("/siteadmin/appconfig/accWatchListGroup")}
        >
            {isLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Submitting..." />
                </div>
            ) : (
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    form={accWatchListGroupForm}
                    layout="horizontal"
                    initialValues={currentState.data}
                    onFinish={onSubmit}
                >
                    <Row>
                        <Col span={15}>
                            <FormComponent label={""} name={"id"} extra={{ type: ComponentType.hidden, value: "" }} />
                            <FormComponent
                                label="Group Name"
                                name="name"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Description"
                                name="description"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                }}
                            />
                        </Col>
                    </Row>
                    {!isAddAction ? (
                        <Row>
                            <Col span={15}>
                                <FormComponent
                                    label="Status"
                                    name="status"
                                    extra={{
                                        type: ComponentType.switch,
                                        value: ["Inactive", "Active"],
                                    }}
                                />
                            </Col>
                        </Row>
                    ) : null}
                    <Row>
                        <Col span={24}>
                            <div className="step-btns-group">
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            )}
        </SitePageHeader>
    );
};

export default CreateEditAccWatchListGroup;
