import { PercentageOutlined, ClockCircleOutlined, NumberOutlined, OneToOneOutlined, CalendarOutlined } from "@ant-design/icons";
import { Tooltip, Typography } from "antd";
import moment from "moment";
import { useState } from "react";
import { METRIC_FORMAT_TYPE_ENUM } from "../../../constants";
import { convertNumToTime, currencyRender } from "../../../utils/Common";

interface SummaryBarProps {
    iconType: number;
    customIcon?: React.ReactNode;
    name: string;
    value: any;
    forPrint?: boolean;
    modalMode?: boolean;
    desc?: string;
}

const SummaryBar = (props: SummaryBarProps) => {
    const [isModalMode] = useState<boolean>(props.modalMode === undefined ? false : props.modalMode);

    const getIcon = (iconType: number) => {
        let styles: any = { fontSize: "1.875rem" };

        switch (iconType) {
            case METRIC_FORMAT_TYPE_ENUM.PERCENTAGE:
                return <PercentageOutlined style={styles} />;
            case METRIC_FORMAT_TYPE_ENUM.TIME:
                return <ClockCircleOutlined style={styles} />;
            case METRIC_FORMAT_TYPE_ENUM.COUNT:
                return <NumberOutlined style={styles} />;
            case METRIC_FORMAT_TYPE_ENUM.RATIO:
                return <OneToOneOutlined style={styles} />;
            case METRIC_FORMAT_TYPE_ENUM.DATE:
                return <CalendarOutlined style={styles} />;
            case METRIC_FORMAT_TYPE_ENUM.USD:
                return <span className={`summary-icon-currency${isModalMode ? "-tile" : ""}`}>USD</span>;
            default:
                return <span className={`summary-icon-currency${isModalMode ? "-tile" : ""}`}>{props.customIcon || ""}</span>;
        }
    };

    const markupValue = (iconType: number, value: any) => {
        switch (iconType) {
            case METRIC_FORMAT_TYPE_ENUM.PERCENTAGE:
                try {
                    return value ? currencyRender(value * 100, 2) : value === 0 ? "0.00" : "N/A";
                } catch (err: any) {
                    console.log(err.toString());
                }
                break;
            case METRIC_FORMAT_TYPE_ENUM.TIME:
                try {
                    return value ? convertNumToTime(value) : value === 0 ? "0 min" : "N/A";
                } catch (err: any) {
                    console.log(err.toString());
                }
                break;
            case METRIC_FORMAT_TYPE_ENUM.COUNT:
            case METRIC_FORMAT_TYPE_ENUM.RATIO:
                return value ? value : value === 0 ? 0 : "N/A";
            case METRIC_FORMAT_TYPE_ENUM.DATE:
                try {
                    return value ? moment(new Date(value * 1000)).format("YYYY-MM-DD") : "N/A";
                } catch (err: any) {
                    console.log(err.toString());
                }

                break;
            default:
                try {
                    return value ? currencyRender(value, 2) : value === 0 ? "0.00" : "N/A";
                } catch (err: any) {
                    console.log(err.toString());
                }
                break;
        }

        return value;
    };

    return (
        <div className={`metric-item${isModalMode ? "-modal" : ""}`}>
            <div className={`summary-icon${isModalMode ? "-tile" : ""}`}>{getIcon(props.iconType)}</div>
            <div className={`summary-info${isModalMode ? "-modal" : ""}`}>
                {props.forPrint ? (
                    <div className="summary-title-for-print">{props.name}</div>
                ) : (
                    <Tooltip title={`${props.name}${props.desc ? ` - ${props.desc}` : ""}`}>
                        <Typography.Text
                            className={`summary-title${isModalMode ? "-modal" : ""}`}
                            ellipsis={{ tooltip: `${props.name}${props.desc ? ` - ${props.desc}` : ""}` }}
                        >
                            {props.name}
                        </Typography.Text>
                    </Tooltip>
                )}
                <div className={`summary-value${isModalMode ? "-modal" : ""}`}>{markupValue(props.iconType, props.value)}</div>
            </div>
        </div>
    );
};

export default SummaryBar;
