import { useState, useEffect } from "react";
import { ALARM_HISTORICAL_ITEM_CALLBACK_KEY, ALARM_HISTORICAL_ITEM_KEY, ComponentType } from "../../../../constants";
import { KeyValuePair, alarmHistoricalComponentCallbackProps } from "../../../../constants/type";
import { DefaultIfEmpty, getAvailableObjectElement, objectRemoveProps } from "../../../../utils/object";
import { FormComponent } from "../../../../components/FormComponent";
import { Form } from "antd";
import moment from "moment-timezone";

export interface AlarmHistoricalFilterPanelProps {
    filterOptions: AlarmHistoricalFilterOption[];
    userOptions: KeyValuePair[];
    isLoading: boolean;
    callback: (callbackKey: ALARM_HISTORICAL_ITEM_CALLBACK_KEY, values: alarmHistoricalComponentCallbackProps) => void;
}

export interface AlarmHistoricalFilterOption {
    text: string;
    key: string;
    options: KeyValuePair[];
}

const AlarmHistoricalFilterPanel = (props: AlarmHistoricalFilterPanelProps) => {
    const [selectedFilter, setSelectedFilter] = useState<any>({});
    const [filterForm] = Form.useForm();

    const triggerCallback = (key: ALARM_HISTORICAL_ITEM_CALLBACK_KEY, changeType: ALARM_HISTORICAL_ITEM_KEY, values: any) => {
        let finalValues: any = { ...objectRemoveProps(values, ["dateFromTo", "assignees"]) };
        if (values?.hasOwnProperty("dateFromTo")) {
            finalValues["dateFrom"] = `${moment(values["dateFromTo"][0]).format("YYYY-MM-DD")}T00:00:00`;
            finalValues["dateTo"] = `${moment(values["dateFromTo"][1]).add(1, "day").format("YYYY-MM-DD")}T00:00:00`;
        }
        finalValues["assignees"] = values?.hasOwnProperty("assignees") ? [values["assignees"]] : [];
        props.callback && props.callback(key, { type: changeType, values: finalValues });
    };

    const toggleFilterParams = (key: string, enabled: boolean) => {
        setSelectedFilter((prev: any) => ({ ...prev, [key]: enabled }));
        props.callback &&
            props.callback(ALARM_HISTORICAL_ITEM_CALLBACK_KEY.FILTER_CHANGED, {
                type: ALARM_HISTORICAL_ITEM_KEY.MODULES_FILTER,
                values: { ...selectedFilter, [key]: enabled },
            });
    };

    useEffect(() => {
        if (props.filterOptions.length > 0) {
            setSelectedFilter(
                props.filterOptions.reduce((finalObj: any, x: AlarmHistoricalFilterOption) => {
                    if (x.options.length > 0) {
                        x.options.forEach(y => {
                            finalObj[`${x.key}|${y.value}`] = true;
                        });
                    }
                    return finalObj;
                }, {})
            );
        } else {
            setSelectedFilter({});
        }
    }, [props.filterOptions]);

    useEffect(() => {
        let currentUtcDate = moment().utc();
        filterForm.setFieldsValue({ dateFromTo: [currentUtcDate, currentUtcDate] });
    }, []);

    return (
        <>
            <div className="header-container">
                <div className="left">
                    {/* {!props.isLoading && (
                        <Button className="btn-filter shadow-light" type={isOpenFilterPanel ? "primary" : "default"} icon={<FilterOutlined />}>
                            Filter
                        </Button>
                    )} */}
                </div>
                <div className="right">
                    <div className="extra-item-container">
                        <Form
                            form={filterForm}
                            onValuesChange={(changedValue: any, allValues: any) =>
                                triggerCallback(
                                    ALARM_HISTORICAL_ITEM_CALLBACK_KEY.FILTER_CHANGED,
                                    ALARM_HISTORICAL_ITEM_KEY.DATE_ASSIGNEE_FILTER,
                                    getAvailableObjectElement(allValues)
                                )
                            }
                        >
                            <FormComponent label="Date" name="dateFromTo" extra={{ type: ComponentType.daterange, value: "" }} />
                            <FormComponent
                                label=""
                                name="assignees"
                                extra={{
                                    type: ComponentType.dropdown,
                                    value: props.userOptions,
                                    inputProps: {
                                        style: {
                                            width: "250px",
                                        },
                                        placeholder: "All assignee",
                                    },
                                }}
                            />
                        </Form>
                    </div>
                </div>
            </div>
            {!props.isLoading && props.filterOptions.length > 0 && (
                <div className="extra-filter-panel">
                    {props.filterOptions.map((x: AlarmHistoricalFilterOption) => (
                        <div key={`aldshflpl-flopt-${x.key}`} className="options-item">
                            <div className="label">{x.text}:</div>
                            <div className="options-container">
                                {x.options.map((y: KeyValuePair) => (
                                    <div
                                        key={`aldshflpl-flopt-${x.key}-v-${y.value}`}
                                        className={`item shadow-light ${
                                            DefaultIfEmpty(selectedFilter, `${x.key}|${y.value}`, false) ? "active" : ""
                                        }`}
                                        onClick={() =>
                                            toggleFilterParams(`${x.key}|${y.value}`, !DefaultIfEmpty(selectedFilter, `${x.key}|${y.value}`, false))
                                        }
                                    >
                                        {y.text}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};

export default AlarmHistoricalFilterPanel;
