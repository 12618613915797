import React from "react";
import useRCServerPerformance from "../../../hooks/useRCServerPerformance";
import { Button, Col, Row, Space, Table, Typography } from "antd";
import ServerTimeWidget from "../components/ServerTimeWidget";
import useRCServerPerformanceStore from "../../../store/useRCServerPerformanceStore";
import Loader from "../components/Loader";
import MessageCard from "../components/MessageCard";

const columns = [
    {
        title: "Server Name",
        dataIndex: "serverName",
    },
    {
        title: "Messages",
        dataIndex: "message",
    },
    {
        title: "Action",
        render: (text: any, data: any) => (
            <Button
                type={"primary"}
                children="view"
                size="small"
                onClick={() => {
                    useRCServerPerformanceStore.getState().setCurrentFocusServer(data.serverName);
                    // scroll to id server_performance_chart
                    const element = document.getElementById("server_performance_chart");
                    element?.scrollIntoView({ behavior: "smooth" });
                }}
            />
        ),
    },
];
const ServerPerformanceTable = () => {
    const { rcServerPerformance, isLoading } = useRCServerPerformance();
    // console.log("dummyPerformanceAlert", dummyPerformanceAlert);
    // console.log(rcServerPerformance);
    // add keys to each row
    const massagedData = rcServerPerformance?.data?.map((item, index) => {
        return {
            ...item,
            key: item.serverName,
        };
    });
    const ignoreServers = useRCServerPerformanceStore(state => state.ignoredServers);
    const finalMassagedData = massagedData?.filter(item => !ignoreServers.includes(item.serverName));
    const { Title } = Typography;
    return (
        <Space style={{ padding: "1rem", width: "100%" }} direction="vertical">
            <Row style={{ alignItems: "center" }}>
                <Col xs={{ span: 24, order: 1 }} md={{ span: 12, order: 0 }}>
                    <Space direction="vertical" size={2}>
                        <Title level={4} style={{ margin: 0 }}>
                            Server Performance Monitor
                        </Title>
                        {massagedData !== undefined && massagedData?.length >= 1 && (
                            <MessageCard type="error">
                                {massagedData?.length} alert{massagedData?.length > 1 ? "s" : ""}
                            </MessageCard>
                        )}
                        {ignoreServers.length >= 1 && (
                            <MessageCard type="warning">
                                {ignoreServers.length} server{ignoreServers.length > 1 ? "s" : ""} ignored
                            </MessageCard>
                        )}
                    </Space>
                </Col>
            </Row>
            {!rcServerPerformance && isLoading ? (
                <Loader />
            ) : (
                <>
                    {rcServerPerformance?.data?.length === 0 ? (
                        <MessageCard type="success">All good</MessageCard>
                    ) : (
                        <MessageCard type="error">
                            {rcServerPerformance?.data?.length ?? 0} error
                            {rcServerPerformance?.data?.length && rcServerPerformance?.data?.length > 1 ? "s" : ""}
                        </MessageCard>
                    )}
                    <Table
                        columns={columns}
                        dataSource={finalMassagedData}
                        rowKey={record => record.serverName}
                        loading={!rcServerPerformance && isLoading}
                        pagination={false}
                        size="small"
                        style={{ width: "100%" }}
                    />
                </>
            )}
        </Space>
    );
};

export default ServerPerformanceTable;
