import { FormComponent } from "@/components/FormComponent";
import { ComponentType } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { KeyValuePair } from "@/constants/type";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";

export interface BrandMappingComponentProps {
    brands: KeyValuePair[];
}

const BrandMappingComponent = (props: BrandMappingComponentProps) => {
    return (
        <Form.List name="brandServerSettings">
            {(fields, { add, remove }, { errors }) => (
                <>
                    {fields.map((field, index) => (
                        <Input.Group compact key={`brandServerSettings-${index}`} className="input-row-container brand-div">
                            <FormComponent label="" name={[field.name, "enable"]} extra={{ type: ComponentType.hidden, value: "" }} />
                            <FormComponent
                                label=""
                                name={[field.name, "brandId"]}
                                extra={{
                                    type: ComponentType.dropdown,
                                    value: props.brands,
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                    itemProps: {
                                        style: { width: "calc(25% - 20px)" },
                                        wrapperCol: { span: 24 },
                                    },
                                    inputProps: {
                                        placeholder: "Please select brand",
                                        style: {
                                            width: "100%",
                                        },
                                    },
                                }}
                            />
                            <FormComponent
                                label=""
                                name={[field.name, "groupPattern"]}
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    itemProps: { wrapperCol: { span: 24 } },
                                    inputProps: {
                                        placeholder: "Please fill in group pattern",
                                    },
                                }}
                            />
                            <Button type="text" icon={<DeleteOutlined />} className="btn-remove" onClick={() => remove(field.name)} />
                        </Input.Group>
                    ))}
                    <Button type="dashed" icon={<PlusOutlined />} className="btn-add-new" onClick={() => add({ enable: true })}>
                        Add New Brand Mapping
                    </Button>
                </>
            )}
        </Form.List>
    );
};

export default BrandMappingComponent;
