import { HomeOutlined } from "@ant-design/icons";
import { Button, Card, Popconfirm } from "antd";
import { useEffect, useState } from "react";
import { NavigateFunction, useLocation, useNavigate, useParams } from "react-router-dom";
import LoadingComponent from "../../../components/Loading";
import SitePageHeader from "../../../components/PageHeader";
import { STATUS_TYPE, SUCCESS_FAILED } from "../../../constants";
import { Brands2, Module, RoleState, TreeItemProps } from "../../../constants/type";
import { apiRequest } from "../../../services/apiConfig";
import { APIs } from "../../../services/apis";
import { useGetRoleMenusQuery } from "../../../store/apis/role";
import { ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";
import Permission from "./Components/permissions";

interface moduleProps {
    menuId: number;
    key: string;
    title: string;
    status: number;
    parentId: number;
    type: number;
}

interface RoleProps {
    roleId: number;
    role: string;
    description: string;
    active: number;
    modules: moduleProps[];
    brands: Brands2[];
}

export const findChild = (parentId: number, data: Module[], isDisabled: boolean = false): Module[] =>
    data
        .filter(x => x.parentId === parentId)
        .map(
            x =>
                ({
                    title: x.title,
                    key: `${x.id}`,
                    disableCheckbox: isDisabled,
                    children: findChild(x.id, data, isDisabled),
                } as unknown as Module)
        );

export const getTreeList = (data: Module[], isDisabled: boolean = false) => (data.length > 0 ? findChild(0, data, isDisabled) : []);

const ViewRolePage = () => {
    let navigate = useNavigate();
    let location = useLocation();
    let com_state: RoleState = location.state as RoleState;
    let { id } = useParams();

    const { isLoading, data } = useGetRoleMenusQuery({ roleId: id ? id : com_state.roleId, withModules: true });
    const [manualLoading, setManualLoading] = useState<boolean>(true);
    const [modules, setModules] = useState<TreeItemProps[]>([]);
    //const [oriMenulist, setOriMenulist] = useState<Module[]>([]);

    const gridStyle = { width: "32%" };
    const titleStyle = { backgroundColor: "#fafafa", width: "18%" };
    const currentRole = data?.data ? (data?.data as unknown as RoleProps[])[0] : undefined;

    const getConfigInfo = () => {
        apiRequest(APIs.GET_MENU_LISTING, {})
            .then((data: any) => {
                let sortedData: Module[] = data as Module[];
                sortedData.sort((a: Module, b: Module) => a.order - b.order);
                setModules(getTreeList(sortedData));
                //setOriMenulist(sortedData);
                setManualLoading(false);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("role listing", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                navigate("/siteadmin/rolepermission");
            });
    };

    const deleteProfile = (roleId: string, navigator: NavigateFunction) => {
        apiRequest(APIs.DELETE_ROLE_MENU, { roleId })
            .then(data => {
                ErrorMessageHandler("The role detail", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                navigator("/siteadmin/rolepermission");
            })
            .catch(error => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("role detail", SUCCESS_FAILED.FAILED_DELETE_DATA, err)));
    };

    useEffect(() => {
        getConfigInfo();
        return () => {};
    }, []);

    return (
        <SitePageHeader
            title={"View Role's Permission"}
            routes={[
                {
                    path: "/siteadmin/rolepermission",
                    breadcrumbName: "Role & Permission",
                    icon: <HomeOutlined />,
                },
                { path: "", breadcrumbName: "View Role's Permission" },
            ]}
            onBack={() => navigate("/siteadmin/rolepermission")}
            extraProps={{
                extra: [
                    <Popconfirm
                        key={"cetmp-del"}
                        title="Confirm to delete?"
                        onConfirm={() => deleteProfile((id ? id : com_state.roleId).toString(), navigate)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="primary" danger>
                            Delete Role
                        </Button>
                    </Popconfirm>,
                ],
            }}
        >
            {isLoading || manualLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Loading..." />
                </div>
            ) : (
                currentRole && (
                    <div className="risk-score-view-container">
                        <>
                            <Card title="">
                                <Card.Grid style={{ ...gridStyle, ...titleStyle }} hoverable={false}>
                                    Role Name
                                </Card.Grid>
                                <Card.Grid style={gridStyle} hoverable={false}>
                                    {currentRole.role}
                                </Card.Grid>
                                <Card.Grid style={{ ...gridStyle, ...titleStyle }} hoverable={false}>
                                    Description
                                </Card.Grid>
                                <Card.Grid style={gridStyle} hoverable={false}>
                                    {currentRole.description}
                                </Card.Grid>
                                {currentRole.brands && currentRole.brands.length > 0 ? (
                                    <>
                                        <Card.Grid style={{ ...gridStyle, ...titleStyle }} hoverable={false}>
                                            Brands
                                        </Card.Grid>
                                        <Card.Grid style={{ ...gridStyle, ...{ width: "82%" } }} hoverable={false}>
                                            {(currentRole.brands as Brands2[]).map(x => x.brandName).join(" , ") || <>&nbsp;</>}
                                        </Card.Grid>
                                        <Card.Grid style={{ ...gridStyle, ...titleStyle }} hoverable={false}>
                                            Status
                                        </Card.Grid>
                                        <Card.Grid style={{ ...gridStyle, ...{ width: "82%" } }} hoverable={false}>
                                            {STATUS_TYPE[currentRole.active]}
                                        </Card.Grid>
                                    </>
                                ) : (
                                    <>
                                        <Card.Grid style={{ ...gridStyle, ...titleStyle }} hoverable={false}>
                                            Brands
                                        </Card.Grid>
                                        <Card.Grid style={gridStyle} hoverable={false}>
                                            -
                                        </Card.Grid>
                                        <Card.Grid style={{ ...gridStyle, ...titleStyle }} hoverable={false}>
                                            Status
                                        </Card.Grid>
                                        <Card.Grid style={gridStyle} hoverable={false}>
                                            {STATUS_TYPE[currentRole.active]}
                                        </Card.Grid>
                                    </>
                                )}
                                <Card.Grid style={{ ...gridStyle, ...titleStyle }} hoverable={false}>
                                    Modules
                                </Card.Grid>
                                <Card.Grid style={{ ...gridStyle, ...{ width: "82%", padding: "0" } }} hoverable={false}>
                                    {/* <Tree
                                        checkable
                                        selectable={false}
                                        showLine={{ showLeafIcon: false }}
                                        switcherIcon={<SwitcherFilled />}
                                        checkedKeys={currentRole.modules.filter((x) => x.status === 1).map((x) => `${x.menuId}`)}
                                        expandedKeys={oriMenulist.filter((x) => x.type === 1).map((x) => `${x.id}`)}
                                        treeData={modules}
                                    /> */}
                                    <Permission
                                        viewOnly
                                        moduleList={modules}
                                        selectedList={
                                            data
                                                ? (data.data as any[]).length > 0
                                                    ? (data.data as any[])[0].modules
                                                          .filter((x: any) => x.status === 1)
                                                          .map((x: any) => `${x.menuId}`)
                                                    : []
                                                : []
                                        }
                                        keyPrefix={""}
                                    />
                                </Card.Grid>
                            </Card>
                        </>
                    </div>
                )
            )}
        </SitePageHeader>
    );
};

export default ViewRolePage;
