import { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate, Link } from "react-router-dom";
import CardBox from "../../../../components/Common/CardBox";
import FlexiDataTable from "../../../../components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "../../../../constants";
import { FlexiDataTableOptionsProps, FlexiDataTableCallbackProps, SymbolAssetTypesList } from "../../../../constants/type";
import AuthHelper, { AuthKeys } from "../../../../helpers/authHelper";
import { apiRequest } from "../../../../services/apiConfig";
import { APIs } from "../../../../services/apis";
import { ErrorMessageHandler, ErrorCatchValidator, DTColProps } from "../../../../utils/Common";
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { Badge, Button, Col, Form, Modal, Row, Segmented, Upload, message } from "antd";
import { SegmentedValue } from "antd/lib/segmented";
import { isEmptyOrNull } from "../../../../utils/string";
import * as XLSX from "xlsx";
import { FormComponent } from "../../../../components/FormComponent";
import { REQUIRED_FIELD } from "../../../../constants/errorMessage";
import { getAvailableObjectElementKeys } from "../../../../utils/object";
import LoadingComponent from "@/components/Loading";

export interface SymbolMappingProps { }

interface SymbolMap {
    cleanSymbol: string | null;
    createdBy: string;
    createdDateUtc: string;
    isMainStream: boolean;
    lowerSymbol: string | null;
    modifiedBy: number | null;
    modifiedDateUtc: string | null;
    pricingCleanSymbol: string | null;
    suffix: string | null;
    symbol: string;
    symbolAssetType: string | null;
    symbolAssetTypeId: number | null;
    upperSymbol: string | null;
}

const SymbolMapping = (props: SymbolMappingProps) => {
    let navigate = useNavigate();
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.SYMBOL_ASSET_TYPE_EDIT);

    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<SymbolMap[]>([]);
    const [symbolAssetType, setSymbolAssetType] = useState<SymbolAssetTypesList[]>([]);
    const [cleanSymbol, setCleanSymbol] = useState<string[]>([]);
    const [currentType, setCurrentType] = useState<string>("1");
    const [filterParams, setFilterParams] = useState<any>({});
    const [downloadForm] = Form.useForm();
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [isExporting, setIsExporting] = useState<boolean>(false);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);

    const filterOptions = useMemo(() => {
        return {
            symbolAssetType: symbolAssetType.map(x => ({ text: x.name, value: x.id })),
            cleanSymbol: cleanSymbol.map(x => ({ text: x, value: x })),
        };
    }, [cleanSymbol, symbolAssetType]);

    const showPreviewError = (data: any) => {
        Modal.error({
            title: "Error Preview",
            width: "52.08vw",
            bodyStyle: { padding: "1vw 2vh" },
            content: (
                <div className="upload-error-div">
                    <FlexiDataTable
                        title={false}
                        loading={false}
                        columns={[
                            DTColProps.XXSmall({
                                title: "Row",
                                dataIndex: "rowNum",
                                key: "rowNum",
                            }),
                            DTColProps.Small({
                                title: "Clean Symbol",
                                dataIndex: "cleanSymbol",
                                key: "cleanSymbol",
                            }),
                            {
                                title: "Remarks",
                                dataIndex: "remarks",
                                key: "remarks",
                            },
                        ]}
                        options={{
                            enableFilter: false,
                            showHideColumns: false,
                        }}
                        dataSource={data}
                        scroll={{ x: "max-content" }}
                        bordered
                        {...(data.length > 10 ? { pagination: { pageSize: 10 } } : { pagination: false })}
                    />
                </div>
            ),
        });
    };

    const convertExcelToJSON = (uploadedFile: any) => {
        if (!uploadedFile) return null;
        if (uploadedFile.size > 5000000) {
            message.error("Maximum file size is 5 mb");
            return null;
        }

        /* Boilerplate to set up FileReader */
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;

        // Set up callback for when FileReader is done loading
        reader.onload = (event: any) => {
            /* Parse data */
            const bstr = event.target.result;
            const wb = XLSX.read(bstr, {
                type: rABS ? "binary" : "array",
                bookVBA: true,
                raw: true,
            });

            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];

            /* Convert array of arrays */
            const excelRows: any[] = XLSX.utils.sheet_to_json(ws);

            if (excelRows.length === 0) {
                message.error(`File is empty`);
                return null;
            }

            setIsUploading(true);

            let formData1 = new FormData();
            formData1.append("IsPreviewOnly", "true");
            formData1.append("File", uploadedFile);
            apiRequest(APIs.UPLOAD_MT_SYMBOL_TEMPLATE, formData1, "POST", "json", {
                Accept: "text/plain",
                "Content-type": "multipart/form-data",
            })
                .then((data: any) => {
                    if (data.length > 0) {
                        showPreviewError(data);
                    } else {
                        let formData = new FormData();
                        formData.append("IsPreviewOnly", "false");
                        formData.append("File", uploadedFile);

                        apiRequest(APIs.UPLOAD_MT_SYMBOL_TEMPLATE, formData, "POST", "json", {
                            Accept: "text/plain",
                            "Content-type": "multipart/form-data",
                        })
                            .then((data: any) => {
                                message.success("MT symbols upload successfully.");
                                getListing();
                            })
                            .catch((err: any) =>
                                ErrorCatchValidator(err, (err: any) => {
                                    if (err.status === -3) {
                                        Modal.warning({
                                            title: "Warning!",
                                            content: err.message,
                                        });
                                    } else message.error(`Failed to upload MT symbols: ${err.message}`, 5);
                                })
                            );
                    }
                })
                .catch((err: any) =>
                    ErrorCatchValidator(err, (err: any) => {
                        if (err.status === -3) {
                            Modal.warning({
                                title: "Warning!",
                                content: err.message,
                            });
                        } else message.error(`Failed to upload MT symbols: ${err.message}`, 5);
                    })
                )
                .finally(() => setIsUploading(false));
        };

        // Call FileReader
        if (rABS) {
            reader.readAsBinaryString(uploadedFile);
        } else {
            reader.readAsArrayBuffer(uploadedFile);
        }
    };

    const exportToExcel = useCallback(() => {
        setIsExporting(true);
        apiRequest(
            APIs.EXPORT_MT_SYMBOL,
            {
                ...(currentType === "2" && {
                    isConfigured: false,
                }),
                ...filterParams,
            },
            "POST",
            "arraybuffer"
        )
            .then((res: any) => {
                const fileName = res.headers["x-filename"];
                const url = window.URL.createObjectURL(new Blob([res.data])); // Create blob link to download
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", fileName); // or any other extension
                // Append to html link element page
                document.body.appendChild(link);
                // start download
                link.click();
                // Clean up and remove the link
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch(error => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("MT symbols list excel", SUCCESS_FAILED.FAILED_DOWNLOAD_DATA, err));
            })
            .finally(() => setIsExporting(false));
    }, [filterParams, currentType]);

    const downloadTemplate = (values: any) => {
        setIsDownloading(true);
        apiRequest(APIs.DOWNLOAD_MT_SYMBOL_TEMPLATE, {
            ...values,
            downloadMode: 2
        }, "POST", "arraybuffer")
            .then((res: any) => {
                const fileName = res.headers["x-filename"];
                const url = window.URL.createObjectURL(new Blob([res.data])); // Create blob link to download
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", fileName); // or any other extension
                // Append to html link element page
                document.body.appendChild(link);
                // start download
                link.click();
                // Clean up and remove the link
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch(error => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("MT symbol template", SUCCESS_FAILED.FAILED_DOWNLOAD_DATA, err));
            })
            .finally(() => setIsDownloading(false));
    };

    const filteredDatas = useMemo(() => {
        if (currentType === "1") {
            return {
                data: data,
                countResult: {
                    All: data.length,
                    Unconfigured: data.filter(x => isEmptyOrNull(x.cleanSymbol)).length,
                },
            };
        }

        let unconfigList = data.filter(x => isEmptyOrNull(x.cleanSymbol));
        return {
            data: unconfigList,
            countResult: {
                All: data.length,
                Unconfigured: unconfigList.length,
            },
        };
    }, [currentType, data]);

    const SegmentOpt = useMemo(() => {
        return [
            {
                label: (
                    <div className="segment-container">
                        <span>All</span>
                        {filteredDatas.countResult.All > 0 && (
                            <Badge count={<div className="badge-count-customize">{filteredDatas.countResult.All}</div>} />
                        )}
                    </div>
                ),
                value: "1",
            },
            {
                label: (
                    <div className="segment-container">
                        <span>Clean Symbol Unconfigured</span>
                        {filteredDatas.countResult.Unconfigured > 0 && (
                            <Badge count={<div className="badge-count-customize">{filteredDatas.countResult.Unconfigured}</div>} />
                        )}
                    </div>
                ),
                value: "2",
            },
        ];
    }, [filteredDatas]);

    const columns: any[] = useMemo(
        () => [
            {
                title: "Symbol",
                dataIndex: "symbol",
                key: "symbol",
                options: {
                    filter: {
                        type: ComponentType.text,
                        value: "",
                    },
                },
            },

            DTColProps.Middle({
                title: "Clean Symbol",
                dataIndex: "cleanSymbol",
                key: "cleanSymbol",
                render: (value: string | null) => value || "",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: filterOptions.cleanSymbol,
                        inputProps: {
                            mode: "multiple",
                        },
                        callback: (filterValue: any, rowData: any) => {
                            if (rowData.cleanSymbol === null) {
                                return false;
                            }

                            return filterValue.some((v: string) => rowData.cleanSymbol === v);
                        },
                    },
                },
            }),

            DTColProps.Middle({
                title: "Pricing Clean Symbol",
                dataIndex: "pricingCleanSymbol",
                key: "pricingCleanSymbol",
                render: (value: string | null) => (isEmptyOrNull(value) ? "" : value),
            }),

            DTColProps.XSmall({
                title: "Suffix",
                dataIndex: "suffix",
                key: "suffix",
            }),

            DTColProps.Middle({
                title: "Symbol Asset Type",
                dataIndex: "symbolAssetTypeId",
                key: "symbolAssetTypeId",
                render: (value: string, rowData: SymbolMap) => rowData.symbolAssetType || "",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: filterOptions.symbolAssetType,
                        inputProps: {
                            mode: "multiple",
                        },
                        callback: (filterValue: any, rowData: any) => {
                            if (rowData.symbolAssetTypeId === null) {
                                return false;
                            }

                            return filterValue.some((value: number) => rowData.symbolAssetTypeId === value);
                        },
                    },
                },
            }),
        ],
        [filterOptions]
    );

    const options: FlexiDataTableOptionsProps = {
        edit: (record: any, overwriteProps: any) => {
            if (enableUpdate) {
                let newProps = { ...overwriteProps };
                newProps.label = (
                    <Link
                        to={`/siteadmin/symbolconfig/symbolmap/edit/${btoa(
                            JSON.stringify({
                                symbol: record.symbol,
                            })
                        )}`}
                    >
                        {newProps.label}
                    </Link>
                );
                return newProps;
            } else return enableUpdate;
        },
        extraButtons: () => (
            <>
                {enableUpdate && (
                    <>
                        <Button
                            key={`btn-sy-dt-${Math.random()}`}
                            htmlType="button"
                            style={{ marginLeft: "0.651vw", width: "auto" }}
                            onClick={event => {
                                event.preventDefault();
                                event.stopPropagation();
                                downloadForm.setFieldsValue({ downloadMode: "", cleanSymbols: [], symbolAssetTypeIds: [] });
                                setIsModalVisible(true);
                            }}
                            icon={<DownloadOutlined />}
                            disabled={isUploading}
                            loading={isDownloading}
                        >
                            Download Template
                        </Button>
                        <Upload
                            name="file"
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            showUploadList={false}
                            onChange={(info: any) => {
                                if (info.file.status === "error") {
                                    message.error(`${info.file.name} file upload failed.`);
                                }
                            }}
                            beforeUpload={(file: any) => {
                                convertExcelToJSON(file);
                            }}
                            customRequest={(info: any) => { }}
                        >
                            <Button
                                htmlType="button"
                                icon={<UploadOutlined />}
                                style={{ marginLeft: "0.651vw" }}
                                onClick={(e: any) => {
                                    e.preventDefault();
                                }}
                                loading={isUploading}
                            >
                                Upload Excel
                            </Button>
                        </Upload>
                    </>
                )}

                <Button icon={<DownloadOutlined />} style={{ marginLeft: "0.651vw" }} disabled={isUploading} loading={isExporting} onClick={() => exportToExcel()}>
                    Export as Excel
                </Button>
            </>
        ),
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, symbolItem) => {
        switch (type) {
            case CALLBACK_KEY.DO_EDIT:
                navigate(
                    `/siteadmin/symbolconfig/symbolmap/edit/${btoa(
                        JSON.stringify({
                            symbol: symbolItem.symbol,
                        })
                    )}`,
                    {
                        state: { data: symbolItem.symbol, action: "edit" },
                    }
                );
                break;
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                setFilterParams(
                    getAvailableObjectElementKeys(symbolItem).reduce((tmpObj: any, x: string) => {
                        if (x === "cleanSymbol") {
                            tmpObj["cleanSymbols"] = symbolItem[x];
                        } else if (x === "symbolAssetTypeId") {
                            tmpObj["symbolAssetTypeIds"] = symbolItem[x];
                        } else {
                            tmpObj[x] = symbolItem[x];
                        }

                        return tmpObj;
                    }, {})
                );
                break;
            default:
                break;
        }
    };

    const getListing = () => {
        setIsLoading(true);
        apiRequest(APIs.GET_MT_SYMBOL_LIST, {})
            .then((res: SymbolMap[]) => {
                setData(res);
            })
            .catch(error => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("symbols", SUCCESS_FAILED.FAILED_UPDATE_DATA, err));
            })
            .finally(() => setIsLoading(false));
    };

    const getConfigList = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, {
            filterType: ["symbolassettype", "cleansymbol"],
        })
            .then(res => {
                setSymbolAssetType(res.symbolAssetTypes);
                setCleanSymbol(res.cleanSymbols.map((x: any) => x.name));
                getListing();
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("config info", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                navigate("/siteadmin/symbolconfig/cleansymbol");
            });
    };

    useEffect(() => {
        getConfigList();
        return () => { };
    }, []);

    return (
        <>
            <CardBox title={"MT Symbol Listing"}>
                <LoadingComponent tip={"Uploading file..."} spinning={isUploading}>
                    <div className="symbol-list">
                        <div className="segment-div">
                            <Segmented
                                size="middle"
                                options={SegmentOpt}
                                onChange={(value: SegmentedValue) => setCurrentType(value as string)}
                                value={currentType}
                            />
                        </div>
                        <FlexiDataTable
                            rowKeyProperty="symbol"
                            title=""
                            columns={columns}
                            options={options}
                            dataSource={filteredDatas.data}
                            callback={componentCallback}
                            loading={isLoading}
                        />
                    </div>
                </LoadingComponent>
            </CardBox>
            <Modal
                width={600}
                title="Download Template"
                open={isModalVisible}
                cancelButtonProps={{ style: { display: "none" } }}
                onCancel={() => setIsModalVisible(false)}
                onOk={() => {
                    downloadForm
                        .validateFields()
                        .then(values => {
                            downloadTemplate(values);
                            setIsModalVisible(false);
                        })
                        .catch(errorInfo => console.log(errorInfo));
                }}
            >
                <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} form={downloadForm} layout="horizontal" initialValues={{}}>
                    {/* <Row>
                        <Col span={24}>
                            <FormComponent
                                label="Mode"
                                name="downloadMode"
                                extra={{
                                    type: ComponentType.dropdown,
                                    value: [
                                        {
                                            text: "With Existing MT Symbols",
                                            value: 2,
                                        },
                                        {
                                            text: "Blank Template",
                                            value: 1,
                                        },
                                    ],
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                        </Col>
                    </Row> */}
                    <Row>
                        <Col span={24}>
                            <FormComponent
                                label="Clean Symbol"
                                name="cleanSymbols"
                                extra={{
                                    type: ComponentType.dropdown,
                                    value: filterOptions.cleanSymbol,
                                    inputProps: { mode: "multiple" },
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <FormComponent
                                label="Symbol Asset Type"
                                name="symbolAssetTypeIds"
                                extra={{
                                    type: ComponentType.dropdown,
                                    value: filterOptions.symbolAssetType,
                                    inputProps: { mode: "multiple" },
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <FormComponent
                                label="Is Clean Symbol Configured"
                                name="isConfigured"
                                extra={{
                                    type: ComponentType.dropdown,
                                    value: [
                                        { text: "Yes", value: true },
                                        { text: "No", value: false },
                                    ],
                                }}
                            />
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};

export default SymbolMapping;
