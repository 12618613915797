import CardBox from "@/components/Common/CardBox";
import FlexiDataTable from "@/components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "@/constants";
import { FlexiDataTableOptionsProps, FlexiDataTableCallbackProps } from "@/constants/type";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { apiRequest } from "@/services/apiConfig";
import { APIs } from "@/services/apis";
import { ErrorMessageHandler, ErrorCatchValidator, DTColProps } from "@/utils/Common";
import { isEmptyOrNull } from "@/utils/string";
import { Tag } from "antd";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

export interface BrandListingPageProps {}

export interface BrandV2Props {
    brandDisplayName: string;
    brandId: number;
    brandMail: string | null;
    brandName: string;
    brandUid: string;
    comment: string | null;
    createBy: number;
    createTimeUtc: string;
    enable: boolean;
    senderMail: string | null;
    updateBy: number;
    updateTimeUtc: string;
}

const BrandListingPage = (props: BrandListingPageProps) => {
    let navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<BrandV2Props[]>([]);
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.ADMIN_BRANDSERVER_BRAND_EDIT);

    const columns: any[] = [
        DTColProps.Middle({
            title: "Brand Name",
            dataIndex: "brandName",
            key: "brandName",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.Middle({
            title: "Brand Display Name",
            dataIndex: "brandDisplayName",
            key: "brandDisplayName",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.Large({
            title: "Brand Mail",
            dataIndex: "brandMail",
            key: "brandMail",
            render: (text: string, rowData: BrandV2Props) =>
                isEmptyOrNull(text)
                    ? ""
                    : text.split(",").map((item: string, index: number) => (
                          <Tag key={`bm-tg-${rowData.brandId}-${index}`} style={{ marginBottom: "5px" }}>
                              {item}
                          </Tag>
                      )),
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.Large({
            title: "Sender Mail",
            dataIndex: "senderMail",
            key: "senderMail",
            render: (text: string, rowData: BrandV2Props) =>
                isEmptyOrNull(text)
                    ? ""
                    : text.split(",").map((item: string, index: number) => (
                          <Tag key={`sm-tg-${rowData.brandId}-${index}`} style={{ marginBottom: "5px" }}>
                              {item}
                          </Tag>
                      )),
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        {
            title: "Comment",
            dataIndex: "comment",
            key: "comment",
        },
        DTColProps.Status({
            title: "Status",
            dataIndex: "enable",
            key: "enable",
        }),
    ];

    const options: FlexiDataTableOptionsProps = {
        add: () => {
            if (enableUpdate) {
                return "/siteadmin/brandserver/brand/create";
            } else return enableUpdate;
        },
        edit: (record: any, overwriteProps: any) => {
            if (enableUpdate) {
                let newProps = { ...overwriteProps };
                newProps.label = <Link to={`/siteadmin/brandserver/brand/edit/${record.brandId}`}>{newProps.label}</Link>;
                return newProps;
            } else return enableUpdate;
        },
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, BrandData) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                navigate("/siteadmin/brandserver/brand/create", { state: { data: null, action: "add" } });
                break;
            case CALLBACK_KEY.DO_EDIT:
                navigate(`/siteadmin/brandserver/brand/edit/${BrandData.brandId}`, { state: { data: BrandData.brandId, action: "edit" } });
                break;
            default:
                break;
        }
    };

    const getBrandList = () => {
        setIsLoading(true);
        apiRequest(APIs.GET_BRANDS_LIST_V2, {})
            .then((res: any) => {
                setData(res && res.length > 0 ? res : []);
            })
            .catch(error => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("brand list", SUCCESS_FAILED.FAILED_LOAD_DATA, err)))
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        getBrandList();
    }, []);

    return (
        <div className="v2-brand-listing">
            <CardBox title={"Brand Listing"}>
                <FlexiDataTable
                    rowKeyProperty="brandId"
                    title=""
                    columns={columns}
                    options={options}
                    dataSource={data}
                    callback={componentCallback}
                    loading={isLoading}
                />
            </CardBox>
        </div>
    );
};

export default BrandListingPage;
