import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
// import axios from "axios";
// import { getToken } from "../services/localStorage";
import { APIs } from "../services/apis";
import { plainAxiosInstance } from "../services/axiosSetup";

/* sample response from the API
[
    {
        "serverName": "MT5Demo",
        "cpu": 80,
        "cpuCount": 1,
        "freeMemory": 300
    }
]
*/

interface RCServerPerformanceSettings {
    serverName: string;
    cpu: number;
    cpuCount: number;
    freeMemory: number;
}

const useRCServerPerformanceSettings = () => {
    // const token = getToken();
    const { data, refetch, isLoading, error, isFetching } = useQuery({
        queryKey: ["rcServerPerformanceSettings"],
        queryFn: () =>
            plainAxiosInstance.get(`${APIs.RC_SERVER_PERFORMANCE.GET_SETTINGS}`, {
                headers: {
                    Accept: "application/json",
                },
            }),

        // axios.get(`${process.env.REACT_APP_API_URL}${APIs.RC_SERVER_PERFORMANCE.GET_SETTINGS}`, {
        //     headers: {
        //         Accept: "application/json",
        //         Authorization: `Bearer ${token}`,
        //     },
        // }),
        staleTime: 60 * 1000, //(10 x 1000ms = 10 seconds)
        // refetchInterval: 60 * 1000, //(10 x 1000ms = 10 seconds)
    });

    const rcServerPerformanceSettings: RCServerPerformanceSettings[] | null = data?.data?.status ? null : data?.data || null;
    // @ts-ignore
    const rcServerPerformanceSettingsError: string | null = data?.data?.message || error?.message || error || null;

    const queryClient = useQueryClient();
    //POST
    const {
        mutateAsync: addSettings,
        isLoading: isLoadingAddSettings,
        error: addSettingsError,
    } = useMutation(
        async ({ serverName, cpuCount, freeMemory, cpu }: RCServerPerformanceSettings): Promise<any> => {
            // Add return type annotation
            if (!serverName) {
                return Promise.reject("server name shouldn't be empty");
            }
            return plainAxiosInstance.post(
                `${APIs.RC_SERVER_PERFORMANCE.GET_SETTINGS}`,
                { serverName, cpuCount, freeMemory, cpu },
                {
                    headers: {
                        Accept: "application/json",
                    },
                }
            );
            // return axios.post(
            //     `${process.env.REACT_APP_API_URL}${APIs.RC_SERVER_PERFORMANCE.GET_SETTINGS}`,
            //     { serverName, cpuCount, freeMemory, cpu },
            //     {
            //         headers: {
            //             Accept: "application/json",
            //             Authorization: `Bearer ${token}`,
            //         },
            //     }
            // );
        },
        {
            onSuccess: res => {
                queryClient.invalidateQueries(["rcServerPerformanceSettings"]);
                queryClient.invalidateQueries(["rcServerPerformance"]);
                // console.log(`Solving reject orders response 200`, res);
                return res;
            },
            onError: error => {
                queryClient.invalidateQueries(["rcServerPerformanceSettings"]);
                queryClient.invalidateQueries(["rcServerPerformance"]);
                // console.log(`Solving reject orders response 400`, error);
                return error;
            },
        }
    );
    // PUT
    const {
        mutateAsync: editSettings,
        isLoading: isLoadingEditSettings,
        error: editSettingsError,
    } = useMutation(
        async ({ serverName, cpuCount, freeMemory, cpu }: RCServerPerformanceSettings): Promise<any> => {
            // Add return type annotation
            if (!serverName) {
                return Promise.reject("server name shouldn't be empty");
            }
            return plainAxiosInstance.put(
                `${APIs.RC_SERVER_PERFORMANCE.GET_SETTINGS}`,
                { serverName, cpuCount, freeMemory, cpu },
                {
                    headers: {
                        Accept: "application/json",
                    },
                }
            );
            // return axios.put(
            //     `${process.env.REACT_APP_API_URL}${APIs.RC_SERVER_PERFORMANCE.GET_SETTINGS}`,
            //     { serverName, cpuCount, freeMemory, cpu },
            //     {
            //         headers: {
            //             Accept: "application/json",
            //             Authorization: `Bearer ${token}`,
            //         },
            //     }
            // );
        },
        {
            onSuccess: res => {
                queryClient.invalidateQueries(["rcServerPerformanceSettings"]);
                queryClient.invalidateQueries(["rcServerPerformance"]);
                // console.log(`Solving reject orders response 200`, res);
                return res;
            },
            onError: error => {
                queryClient.invalidateQueries(["rcServerPerformanceSettings"]);
                queryClient.invalidateQueries(["rcServerPerformance"]);
                // console.log(`Solving reject orders response 400`, error);
                return error;
            },
        }
    );
    // DELETE
    const {
        mutateAsync: deleteSettings,
        isLoading: isLoadingDeleteSettings,
        error: deleteSettingsError,
    } = useMutation(
        async ({ serverNames }: { serverNames: string[] }): Promise<any> => {
            // Add return type annotation
            if (!serverNames) {
                return Promise.reject("server name shouldn't be empty");
            }
            return plainAxiosInstance.delete(`${APIs.RC_SERVER_PERFORMANCE.GET_SETTINGS}?id=${serverNames.join(",")}`, {
                headers: {
                    Accept: "application/json",
                },
            });
            // return axios.delete(`${process.env.REACT_APP_API_URL}${APIs.RC_SERVER_PERFORMANCE.GET_SETTINGS}?id=${serverNames.join(",")}`, {
            //     headers: {
            //         Accept: "application/json",
            //         Authorization: `Bearer ${token}`,
            //     },
            // });
        },
        {
            onSuccess: res => {
                queryClient.invalidateQueries(["rcServerPerformanceSettings"]);
                queryClient.invalidateQueries(["rcServerPerformance"]);
                // console.log(`Solving reject orders response 200`, res);
                return res;
            },
            onError: error => {
                queryClient.invalidateQueries(["rcServerPerformanceSettings"]);
                queryClient.invalidateQueries(["rcServerPerformance"]);
                // console.log(`Solving reject orders response 400`, error);
                return error;
            },
        }
    );

    return {
        rcServerPerformanceSettings,
        rcServerPerformanceSettingsError,
        refetchRcServerPerformanceSettings: refetch,
        isFetching,
        isLoading,

        // post
        addSettings,
        addSettingsError,
        isLoadingAddSettings,

        //put
        editSettings,
        editSettingsError,
        isLoadingEditSettings,

        //delete
        deleteSettings,
        deleteSettingsError,
        isLoadingDeleteSettings,
    };
};

export default useRCServerPerformanceSettings;
