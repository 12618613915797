import { HomeOutlined } from "@ant-design/icons";
import { Badge, Button, Card, List, Popconfirm } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams, NavigateFunction } from "react-router-dom";
import TitleBox from "../../../components/Common/ScoreCardBox";
import LoadingComponent from "../../../components/Loading";
import SitePageHeader from "../../../components/PageHeader";
import { RISK_SCORE_TIER_OPERATOR_TYPE, STATUS_TYPE, SUCCESS_FAILED } from "../../../constants";
import { AccountTypes, metricItem, RiskScoreConfiguration, tierProps, typeProps } from "../../../constants/type";
import { apiRequest } from "../../../services/apiConfig";
import { APIs } from "../../../services/apis";
import { currencyRender, ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";

const ViewRiskScorePage = () => {
    let navigate = useNavigate();
    let location = useLocation();
    let profileId = location.state as any;
    let { id } = useParams();

    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<RiskScoreConfiguration | undefined>(undefined);
    const [accountTypes, setAccountTypes] = useState<AccountTypes[]>([]);

    useEffect(() => {
        apiRequest(APIs.GET_RISK_SCORE_LIST, { profileId: id ? id : profileId.Id })
            .then((res: any) => {
                getFilterConfigList();
                let current_data: RiskScoreConfiguration = res as RiskScoreConfiguration;
                current_data.types = current_data.types.map((x) => {
                    x.aggregates = x.aggregates.map((y) => {
                        let aObj = y.aggregations as metricItem;
                        y.aggregations = `${aObj.metricId}|${aObj.metricName}|${aObj.metricTypeId}`;
                        return y;
                    });
                    return x;
                });
                setData(current_data);
                setLoading(false);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("risk score list", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                navigate("/siteadmin/riskscore/account");
            });
        return () => {};
    }, []);

    const getFilterConfigList = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["accounttype"] })
            .then((res: any) => setAccountTypes(res.accountTypes))
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => console.log(err)))
            .finally(() => setLoading(false));
    };

    const getTierExpression = (item: tierProps, index: number): React.ReactNode => {
        let description =
            item.operator === 3
                ? ` ${RISK_SCORE_TIER_OPERATOR_TYPE[item.operator]} ${currencyRender(item.fromValue)} and ${currencyRender(item.toValue)}  `
                : ` ${RISK_SCORE_TIER_OPERATOR_TYPE[item.operator]} ${currencyRender(item.fromValue)}  `;
        return (
            <>
                <span className="dot">&#x2022;</span>
                <span>{description}</span>
                <span className="score">{item.point} point(s)</span>
            </>
        );
    };

    const gridStyle = { width: "32%" };
    const titleStyle = { backgroundColor: "#fafafa", width: "18%" };

    const getTypeTemplate = (item: typeProps) => {
        return (
            <TitleBox
                key={`vrsc-scb-${item.typeId}`}
                title={item.name}
                subTitle={item.description || ""}
                scoreText="" //"weight"
                score={0} //{item.weight}
                roundBorder={7}
                style={{ width: "100%", marginBottom: "1.6vw" }}
            >
                <List
                    bordered
                    className="metric-list-container"
                    itemLayout="horizontal"
                    dataSource={item.aggregates}
                    renderItem={(aggregate) => (
                        <Badge.Ribbon text="Tiers" color="#004a56">
                            <List.Item>
                                {/* <List.Item.Meta title={aggregate.name} description={aggregate.description || ""} /> */}
                                <div className="metric-panel">
                                    <div className="title">{aggregate.name}</div>
                                    <div className="subtitle">{aggregate.description || ""}</div>
                                </div>
                                <div className="metric-score-panel">
                                    <div className="metric-score">
                                        <span className="label">weight</span>
                                        <span>{aggregate.weight}</span>
                                    </div>
                                    <div className="tier-panel">
                                        {aggregate.tiers.map((x, index) => {
                                            return (
                                                <div key={`vrsc-scb-agg-tr-${x.tierId}`} className="tier-item">
                                                    {getTierExpression(x, index)}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </List.Item>
                        </Badge.Ribbon>
                    )}
                />
            </TitleBox>
        );
    };

    const deleteAccountRiskScore = (profileId: string, navigator: NavigateFunction) => {
        apiRequest(APIs.DELETE_RISK_SCORE_PROFILE, { profileId })
            .then((data) => {
                ErrorMessageHandler("The account risk score", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                navigator("/siteadmin/riskscore/account");
            })
            .catch((error) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("account risk score", SUCCESS_FAILED.FAILED_DELETE_DATA, err))
            );
    };

    return (
        <SitePageHeader
            title={"View Risk Score Profile"}
            routes={[
                {
                    path: "/siteadmin/riskscore/account",
                    breadcrumbName: "Account Risk Score Configuration",
                    icon: <HomeOutlined />,
                },
                { path: "", breadcrumbName: "View Risk Score Profile" },
            ]}
            onBack={() => navigate("/siteadmin/riskscore/account")}
            extraProps={{
                extra: [
                    <Popconfirm
                        key={"cetmp-del"}
                        title="Confirm to delete?"
                        onConfirm={() => deleteAccountRiskScore(id ? id : profileId.Id, navigate)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="primary" danger>
                            Delete Account Risk Score
                        </Button>
                    </Popconfirm>,
                ],
            }}
        >
            {loading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Loading..." />
                </div>
            ) : (
                <div className="risk-score-view-container">
                    <>
                        <Card title="">
                            <Card.Grid style={{ ...gridStyle, ...titleStyle }} hoverable={false}>
                                Profile Name
                            </Card.Grid>
                            <Card.Grid style={gridStyle} hoverable={false}>
                                {data?.name}
                            </Card.Grid>
                            <Card.Grid style={{ ...gridStyle, ...titleStyle }} hoverable={false}>
                                Description
                            </Card.Grid>
                            <Card.Grid style={gridStyle} hoverable={false}>
                                {data?.description}
                            </Card.Grid>
                            <Card.Grid style={{ ...gridStyle, ...titleStyle }} hoverable={false}>
                                Status
                            </Card.Grid>
                            <Card.Grid style={{ ...gridStyle }} hoverable={false}>
                                {STATUS_TYPE[data?.status || 0]}
                            </Card.Grid>
                            <Card.Grid style={{ ...gridStyle, ...titleStyle }} hoverable={false}>
                                Set Profile As
                            </Card.Grid>
                            <Card.Grid style={gridStyle} hoverable={false}>
                                {data?.isDefault === true ? "Default" : "None"}
                            </Card.Grid>
                            {data?.accountTypes && data?.accountTypes.length > 0 && (
                                <>
                                    <Card.Grid style={{ ...gridStyle, ...titleStyle }} hoverable={false}>
                                        Account Type(s)
                                    </Card.Grid>
                                    <Card.Grid style={{ ...gridStyle, ...{ width: "82%" } }} hoverable={false}>
                                        {data?.accountTypes
                                            .map((x) => {
                                                let find_any: any = accountTypes.find((y) => y.id === x);
                                                return find_any?.name;
                                            })
                                            .join(" , ") || <>&nbsp;</>}
                                    </Card.Grid>
                                </>
                            )}
                            {/* {data?.brands && data?.brands.length > 0 && (
                                <>
                                    <Card.Grid style={{ ...gridStyle, ...titleStyle }} hoverable={false}>
                                        Brand(s)
                                    </Card.Grid>
                                    <Card.Grid style={{ ...gridStyle, ...{ width: "82%" } }} hoverable={false}>
                                        {(data?.brands as Brands[]).map((x) => x.brand).join(" , ") || <>&nbsp;</>}
                                    </Card.Grid>
                                </>
                            )} */}
                            <Card.Grid
                                style={{ ...gridStyle, ...{ display: "flex", justifyContent: "space-between", width: "100%", boxShadow: "none" } }}
                                hoverable={false}
                                className="risk-score-items-panel"
                            >
                                {data && data.types.length > 0 && (
                                    <>
                                        <div style={{ width: "50%", marginRight: "0.8vw" }}>
                                            {data.types.map((x: typeProps, index: number) => (index % 2 !== 0 || index === 0) && getTypeTemplate(x))}
                                        </div>
                                        <div style={{ width: "50%", marginLeft: "0.8vw" }}>
                                            {data.types.map((x: typeProps, index: number) => index % 2 === 0 && index !== 0 && getTypeTemplate(x))}
                                        </div>
                                    </>
                                )}
                            </Card.Grid>
                        </Card>
                    </>
                </div>
            )}
        </SitePageHeader>
    );
};

export default ViewRiskScorePage;
