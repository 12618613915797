import { useEffect, useRef, useState } from "react";
import { Space } from "antd";
import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";
import { LineChart } from "echarts/charts";
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    DatasetComponent,
    LegendComponent,
    ToolboxComponent,
    DataZoomComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import chalk from "../../../../assets/echarts/chalk.json";
import LoadingComponent from "../../../../components/Loading";

export const secondTicksChartDataTemplate = (legend: any, series: any[], xAxis: any) => ({
    tooltip: {
        trigger: "axis",
    },
    legend: {
        data: legend,
    },
    toolbox: {
        show: true,
        itemSize: 20,
        iconStyle: {
            color: "#fff",
        },
        feature: {
            show: true,
            x: "right",
            y: "top",
            dataView: {
                show: true,
                readOnly: false,
            },
            saveAsImage: {
                show: true,
                title: "Save Image As",
            },
        },
    },
    grid: {
        left: "3%",
        right: "4%",
        containLabel: true,
    },
    xAxis: [
        {
            type: "category",
            axisLabel: {
                interval: "auto",
            },
            show: true,
            name: "Time",
            data: xAxis,
        },
    ],
    yAxis: [
        {
            type: "value",
            show: true,
            name: "Mid Price",
            splitArea: { show: true },
            max: (value: { max: number; min: number }) => value.max + (value.max - value.min) * 0.05,
            min: (value: { min: number; max: number }) => value.min - (value.max - value.min) * 0.05,
        },
    ],
    dataZoom: [
        {
            type: "inside",
            start: 0,
            end: 100,
        },
        {
            show: true,
            type: "slider",
            start: 0,
            end: 100,
        },
        {
            textStyle: {
                color: "#fff",
            },
        },
    ],
    series: series,
});

echarts.use([
    TitleComponent,
    TooltipComponent,
    ToolboxComponent,
    DataZoomComponent,
    GridComponent,
    DatasetComponent,
    LegendComponent,
    LineChart,
    CanvasRenderer,
]);

interface SecTicChartProps {
    secTicChartData: any;
    visible: boolean;
    onClose: () => void;
}

const SecTicChart = ({ secTicChartData, visible, onClose }: SecTicChartProps) => {
    const chartRef = useRef<ReactEChartsCore>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const [hasSize, setHasSize] = useState(false);

    const checkSize = () => {
        if (containerRef.current) {
            const width = containerRef.current.clientWidth;
            const height = containerRef.current.clientHeight;
            if (width > 0 && height > 0) {
                setHasSize(true);
            } else {
                setHasSize(false);
            }
        }
    };

    useEffect(() => {
        if (visible) {
            // Check size with a slight delay to ensure the modal is fully rendered
            setTimeout(checkSize, 100);
        }

        // Check size on window resize
        window.addEventListener("resize", checkSize);

        return () => {
            window.removeEventListener("resize", checkSize);
        };
    }, [visible]);

    return (
        <div>
            <div ref={containerRef} style={{ width: "100%", height: "500px" }}>
                {hasSize ? (
                    <ReactEChartsCore
                        ref={chartRef}
                        echarts={echarts}
                        option={secTicChartData}
                        theme={chalk}
                        notMerge
                        style={{ width: "100%", height: "100%" }} // Ensure the chart takes full container size
                    />
                ) : (
                    <LoadingComponent />
                )}
            </div>
        </div>
    );
};

export default SecTicChart;
