import React, { useState } from "react";
import SystemBreadCrumbs from "../components/SystemBreadCrumbs";
import { Typography, Space, Badge, Row, Col, notification, Button } from "antd";
import { colorMap } from "../StatusMonitor/statusUtils";
import useRCRejectOrders from "../../../hooks/useRCRejectOrders";
// import useRCMetaTraders from "../../../hooks/useRCMetaTraders";
import useRCMetaTraderMonitors from "../../../hooks/useRCMetaTraderMonitors";
import useRCServerPerformance from "../../../hooks/useRCServerPerformance";
import useRCDataCenter from "../../../hooks/useRCDataCenter";
import useRCStatusMonitor from "../../../hooks/useRCStatusMonitor";
import useRCStatusMonitorDaily from "../../../hooks/useRCStatusMonitorDaily";
import useRCIssuesLog from "../../../hooks/useRCIssuesLog";
import Counter from "../../../components/Motion/Counter";
import { FaCheck, FaChevronCircleRight, FaExpand, FaRedo } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import ServerTimeWidget from "../components/ServerTimeWidget";
import TimeDiff from "../components/TimeDiff";
import useRCBalanceAdjustment from "../../../hooks/useRCBalanceAdjustment";
const SystemMonitorOverview = () => {
    const { Title } = Typography;
    const { rcRejectOrders, refetchRcRejectOrders, dataUpdatedAt: rejectOrderUpdated } = useRCRejectOrders();
    const { rcMetaTraderMonitors, refetchRcMetaTraderMonitors, dataUpdatedAt: metaTraderUpdated } = useRCMetaTraderMonitors();
    const { rcServerPerformance, refetchRcServerPerformance, dataUpdatedAt: serverPerformanceUpdated } = useRCServerPerformance();
    const { rcDataCenter, refetchRcDataCenter, dataUpdatedAt: dataCenterUpdated } = useRCDataCenter();
    const {
        rcStatusMonitorTotalErrorCount: rcStatusMonitorRA,
        refetchRcStatusMonitor: refetchStatusMonitorRA,
        dataUpdatedAt: statusMonitorUpdated,
    } = useRCStatusMonitor({
        department: "RA",
    });

    const { rcStatusMonitorTotalErrorCount: rcStatusMonitorAdmin, refetchRcStatusMonitor: refetchStatusMonitorAdmin } = useRCStatusMonitor({
        department: "Admin",
    });

    const { rcStatusMonitorTotalErrorCount: rcStatusMonitorWeekend, refetchRcStatusMonitor: refetchStatusMonitorWeekend } = useRCStatusMonitor({
        department: "Weekend",
    });
    const { rcStatusMonitorDailyTotalErrorCount, refetchRcStatusMonitorDaily } = useRCStatusMonitorDaily();

    const {
        rcIssuesLog: rcIssuesLogNoMoney,
        refetchRcIssuesLog: refetchIssuesLogNoMoney,
        dataUpdatedAt: issuesLogUpdated,
    } = useRCIssuesLog("NO_MONEY");
    const { rcIssuesLog: rcIssuesLogAccountMonitor, refetchRcIssuesLog: refetchIssuesLogAccountMonitor } = useRCIssuesLog("ACCOUNT_MONITOR");
    const { rcIssuesLog: rcIssuesLogClosedOrder, refetchRcIssuesLog: refetchIssuesLogClosedOrder } = useRCIssuesLog("CLOSED_ORDER");
    const { rcIssuesLog: rcIssuesLogManagerAccountIntercepted, refetchRcIssuesLog: refetchIssuesLogManagerAccountIntercepted } =
        useRCIssuesLog("MANAGER_ACCOUNT_INTERCEPTED");
    const { rcIssuesLog: rcIssuesLogModifyOpenOrder, refetchRcIssuesLog: refetchIssuesLogModifyOpenOrder } = useRCIssuesLog("MODIFY_OPEN_ORDER");
    const { rcIssuesLog: rcIssuesLogMt5OrderType, refetchRcIssuesLog: refetchIssuesLogMt5OrderType } = useRCIssuesLog("MT5_ORDER_TYPE");
    const { rcIssuesLog: rcIssuesLogRequestTimeout, refetchRcIssuesLog: refetchIssuesLogRequestTimeout } = useRCIssuesLog("REQUEST_TIMEOUT");
    const { rcIssuesLog: rcIssuesLogRestoreOrder, refetchRcIssuesLog: refetchIssuesLogRestoreOrder } = useRCIssuesLog("RESTORE_ORDER");
    const { rcIssuesLog: rcIssuesLogUpdateOrderUrgent, refetchRcIssuesLog: refetchIssuesLogUpdateOrderUrgent } =
        useRCIssuesLog("UPDATE_ORDER_URGENT");
    const { rcBalanceAdjustment: dataUnsolved, refetchRcBalanceAdjustment: refetchUnsolved } = useRCBalanceAdjustment({ type: "unsolved" });

    const [refreshing, setRefreshing] = useState(false);
    const [fullScreen, setFullScreen] = useState(false);
    const handleRefetchAll = async () => {
        // refetch all queries
        try {
            notification.info({ message: "Refreshing 17 apis..." });
            setRefreshing(true);
            const resp = await Promise.all([
                refetchRcRejectOrders(),
                refetchRcMetaTraderMonitors(),
                refetchRcServerPerformance(),
                refetchRcDataCenter(),
                refetchStatusMonitorRA(),
                refetchStatusMonitorAdmin(),
                refetchStatusMonitorWeekend(),
                refetchRcStatusMonitorDaily(),
                refetchIssuesLogNoMoney(),
                refetchIssuesLogAccountMonitor(),
                refetchIssuesLogClosedOrder(),
                refetchIssuesLogManagerAccountIntercepted(),
                refetchIssuesLogModifyOpenOrder(),
                refetchIssuesLogMt5OrderType(),
                refetchIssuesLogRequestTimeout(),
                refetchIssuesLogRestoreOrder(),
                refetchIssuesLogUpdateOrderUrgent(),
                refetchUnsolved(),
            ]);
            if (resp) {
                notification.success({ message: "Data refreshed successfully" });
            }
        } catch (error) {
            notification.error({ message: "Error refreshing data" });
        } finally {
            setRefreshing(false);
        }
    };

    const alerts = {
        rcRejectOrders: rcRejectOrders?.filter((each: any) => each.status === 1).length || 0,
        rcMetaTraders: rcMetaTraderMonitors?.length || 0,
        rcServerPerformance: rcServerPerformance?.data?.length || 0,
        rcDataCenter: rcDataCenter?.data?.length || 0,

        // status monitor
        rcStatusMonitorRA: rcStatusMonitorRA || 0,
        rcStatusMonitorAdmin: rcStatusMonitorAdmin || 0,
        rcStatusMonitorWeekend: rcStatusMonitorWeekend || 0,
        rcStatusMonitorDaily: rcStatusMonitorDailyTotalErrorCount || 0,
        rcStatusMonitorTotal: rcStatusMonitorRA + rcStatusMonitorAdmin + rcStatusMonitorWeekend + rcStatusMonitorDailyTotalErrorCount,

        // issues log
        rcIssuesLogNoMoney: rcIssuesLogNoMoney?.length || 0,
        rcIssuesLogAccountMonitor: rcIssuesLogAccountMonitor?.length || 0,
        rcIssuesLogClosedOrder: rcIssuesLogClosedOrder?.length || 0,
        rcIssuesLogManagerAccountIntercepted: rcIssuesLogManagerAccountIntercepted?.length || 0,
        rcIssuesLogModifyOpenOrder: rcIssuesLogModifyOpenOrder?.length || 0,
        rcIssuesLogMt5OrderType: rcIssuesLogMt5OrderType?.length || 0,
        rcIssuesLogRequestTimeout: rcIssuesLogRequestTimeout?.length || 0,
        rcIssuesLogRestoreOrder: rcIssuesLogRestoreOrder?.length || 0,
        rcIssuesLogUpdateOrderUrgent: rcIssuesLogUpdateOrderUrgent?.length || 0,
        rcIssuesBalanceAdjustment: dataUnsolved?.data?.length || 0,

        rcIssuesLogTotal:
            (rcIssuesLogNoMoney?.length || 0) +
            (rcIssuesLogAccountMonitor?.length || 0) +
            (rcIssuesLogClosedOrder?.length || 0) +
            (rcIssuesLogManagerAccountIntercepted?.length || 0) +
            (rcIssuesLogModifyOpenOrder?.length || 0) +
            (rcIssuesLogMt5OrderType?.length || 0) +
            (rcIssuesLogRequestTimeout?.length || 0) +
            (rcIssuesLogRestoreOrder?.length || 0) +
            (rcIssuesLogUpdateOrderUrgent?.length || 0) +
            (dataUnsolved?.data?.length || 0),
    };

    return (
        <>
            <SystemBreadCrumbs currMenu="Overview" />
            {fullScreen && (
                <motion.div
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: "white",
                        zIndex: 998,
                        height: "100vh",
                        width: "100vw",
                    }}
                />
            )}
            <motion.div
                style={{ background: "white", padding: "1rem", minHeight: "80vh" }}
                animate={{
                    position: fullScreen ? "absolute" : "relative",
                    top: fullScreen ? 0 : "auto",
                    left: fullScreen ? 0 : "auto",
                    right: fullScreen ? 0 : "auto",
                    bottom: fullScreen ? 0 : "auto",
                    zIndex: fullScreen ? 999 : "auto",
                    width: fullScreen ? "100vw" : "auto",
                    height: fullScreen ? "100vh" : "auto",
                }}
            >
                <div style={{ display: "flex", alignItems: "center", marginBottom: "1rem", gap: 16 }}>
                    <Space style={{ alignItems: "center" }}>
                        <Title level={2} style={{ padding: 0, margin: 0 }}>
                            System Monitor Overview
                        </Title>
                        <Button
                            type="ghost"
                            style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 4 }}
                            onClick={() => {
                                handleRefetchAll();
                            }}
                            disabled={refreshing}
                            shape="circle"
                            icon={
                                <motion.div
                                    animate={{
                                        rotate: refreshing ? 180 : 0,
                                    }}
                                >
                                    <FaRedo style={{ fontSize: "0.75rem" }} />
                                </motion.div>
                            }
                        />
                        <Button
                            type="ghost"
                            style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 4 }}
                            onClick={() => {
                                setFullScreen(!fullScreen);
                            }}
                            shape="circle"
                            icon={
                                <motion.div>
                                    {!fullScreen ? <FaExpand style={{ fontSize: "0.75rem" }} /> : <AiOutlineClose style={{ fontSize: "0.75rem" }} />}
                                </motion.div>
                            }
                        />
                    </Space>
                    <div style={{ marginLeft: "auto" }}>
                        <ServerTimeWidget />
                    </div>
                </div>
                {/* <Space wrap style={{ alignItems: "stretch", width: "100%" }}> */}
                <Row gutter={[0, 4]}>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <Row gutter={[4, 4]}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                                <StatusCard
                                    title="Status Monitor"
                                    index={1}
                                    value={alerts.rcStatusMonitorTotal}
                                    href="/systemmonitor/statusmonitor"
                                    lastUpdated={statusMonitorUpdated}
                                    subSection={[
                                        {
                                            title: "RA",
                                            value: alerts.rcStatusMonitorRA,
                                            href: "/systemmonitor/statusmonitor?tab=ra",
                                        },
                                        {
                                            title: "Admin",
                                            value: alerts.rcStatusMonitorAdmin,
                                            href: "/systemmonitor/statusmonitor?tab=admin",
                                        },
                                        {
                                            title: "Daily",
                                            value: alerts.rcStatusMonitorDaily,
                                            href: "/systemmonitor/statusmonitor?tab=daily",
                                        },
                                        {
                                            title: "Weekend",
                                            value: alerts.rcStatusMonitorWeekend,
                                            href: "/systemmonitor/statusmonitor?tab=weekend",
                                        },
                                    ]}
                                />
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <StatusCard
                                    title="Reject Orders"
                                    lastUpdated={rejectOrderUpdated}
                                    index={2}
                                    value={alerts.rcRejectOrders}
                                    href="/systemmonitor/rejectorder"
                                />
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <StatusCard
                                    title="Meta Trader Server"
                                    lastUpdated={metaTraderUpdated}
                                    index={3}
                                    value={alerts.rcMetaTraders}
                                    href="/systemmonitor/metatrader"
                                />
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <StatusCard
                                    title="Server Performance"
                                    lastUpdated={serverPerformanceUpdated}
                                    index={4}
                                    value={alerts.rcServerPerformance}
                                    href="/systemmonitor/serverperformance"
                                />
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <StatusCard
                                    title="Data Center"
                                    lastUpdated={dataCenterUpdated}
                                    index={5}
                                    value={alerts.rcDataCenter}
                                    href="/systemmonitor/datacenter"
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <StatusCard
                            title="Issues Log"
                            lastUpdated={issuesLogUpdated}
                            index={6}
                            value={alerts.rcIssuesLogTotal}
                            href="/systemmonitor/issueslog"
                            subSection={[
                                {
                                    title: "No Money",
                                    value: alerts.rcIssuesLogNoMoney,
                                    // href: "/systemmonitor/issueslog#NO_MONEY",
                                },
                                {
                                    title: "Account Monitor",
                                    value: alerts.rcIssuesLogAccountMonitor,
                                    // href: "/systemmonitor/issueslog#ACCOUNT_MONITOR",
                                },
                                {
                                    title: "Closed Order",
                                    value: alerts.rcIssuesLogClosedOrder,
                                    // href: "/systemmonitor/issueslog#CLOSED_ORDER",
                                },
                                {
                                    title: "Manager Account Intercepted",
                                    value: alerts.rcIssuesLogManagerAccountIntercepted,
                                    // href: "/systemmonitor/issueslog#MANAGER_ACCOUNT_INTERCEPTED",
                                },
                                {
                                    title: "Modify Open Order",
                                    value: alerts.rcIssuesLogModifyOpenOrder,
                                    // href: "/systemmonitor/issueslog#MODIFY_OPEN_ORDER",
                                },
                                {
                                    title: "MT5 Order Type",
                                    value: alerts.rcIssuesLogMt5OrderType,
                                    // href: "/systemmonitor/issueslog#MT5_ORDER_TYPE",
                                },
                                {
                                    title: "Request Timeout",
                                    value: alerts.rcIssuesLogRequestTimeout,
                                    // href: "/systemmonitor/issueslog#REQUEST_TIMEOUT",
                                },
                                {
                                    title: "Restore Order",
                                    value: alerts.rcIssuesLogRestoreOrder,
                                    // href: "/systemmonitor/issueslog#RESTORE_ORDER",
                                },
                                {
                                    title: "Update Order Urgent",
                                    value: alerts.rcIssuesLogUpdateOrderUrgent,
                                    // href: "/systemmonitor/issueslog#UPDATE_ORDER_URGENT",
                                },
                                {
                                    title: "Balance Adjustment",
                                    value: alerts.rcIssuesBalanceAdjustment,
                                    // href: "/systemmonitor/issueslog#UPDATE_ORDER_URGENT",
                                },
                            ]}
                        />
                    </Col>
                </Row>
            </motion.div>
        </>
    );
};

const StatusCard = ({
    title,
    value,
    subSection,
    type = "info",
    href = "",
    index = 0,
    lastUpdated = 0,
}: {
    title: string;
    value: number;
    href?: string;
    subSection?: {
        title: string;
        value: number;
        href?: string;
    }[];
    type?: "success" | "error" | "warning" | "info" | "disabled";
    index?: number;
    lastUpdated?: number;
}) => {
    const { Title, Text } = Typography;
    if (value === 0) {
        type = "success";
    } else if (value <= 5) {
        type = "warning";
    } else if (value > 5) {
        type = "error";
    }
    return (
        <motion.div
            style={{ width: "100%", height: "100%", borderRadius: 16, padding: "1.5rem" }}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0, background: colorMap[type], transition: { delay: index * 0.05, type: "spring" } }}
        >
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <div style={{ alignItems: "center", gap: "1rem", display: "flex" }}>
                    <Title level={2} style={{ marginBottom: 0, lineHeight: 1, paddingBottom: 0, color: type === "error" ? "white" : "black" }}>
                        {title}
                    </Title>
                    <motion.div style={{ lineHeight: 1, marginLeft: "auto", opacity: 0.85 }} whileHover={{ opacity: 1, scale: 1.2 }}>
                        <Link to={href} style={{ lineHeight: 1, marginLeft: "auto" }}>
                            <FaChevronCircleRight style={{ fontSize: 30, color: type === "error" ? "white" : "black" }} />
                        </Link>
                    </motion.div>
                </div>
                {value === 0 ? (
                    <div style={{ alignItems: "center", gap: type === "success" ? "1rem" : "0.5rem", display: "flex" }}>
                        <FaCheck style={{ color: "white" }} />
                        <Text style={{ fontSize: 24, color: "white" }}> All Good</Text>
                    </div>
                ) : (
                    <>
                        <Text style={{ fontSize: 24, color: "white" }}>
                            <Counter value={value} digits={value.toString().length} fontSize={84} />
                        </Text>
                    </>
                )}
                {subSection && (
                    <Space wrap style={{ marginTop: "0.5rem", width: "100%" }}>
                        {subSection.map((each, index) => (
                            <Link to={each.href || href} key={index}>
                                <Badge count={each.value} style={{ backgroundColor: "red", zIndex: 99 }} overflowCount={99999}>
                                    <motion.div
                                        key={index}
                                        style={{
                                            borderRadius: 8,
                                            padding: "0.75rem 1rem",
                                            backgroundColor: "rgba(0,0,0,0.5)",
                                            opacity: each.value === 0 ? 0.25 : 0.85,
                                        }}
                                        whileHover={{ opacity: 1, scale: 1.05 }}
                                    >
                                        <Text style={{ fontSize: 18, color: "white" }}>{each.title}</Text>
                                    </motion.div>
                                </Badge>
                            </Link>
                        ))}
                    </Space>
                )}
                {lastUpdated !== 0 && (
                    <div style={{ marginTop: "auto", paddingTop: "1rem" }}>
                        <TimeDiff
                            timestamp={lastUpdated}
                            styles={{
                                borderRadius: 16,
                                background: "rgba(255,255,255,0.5)",
                                border: "none",
                            }}
                        />
                    </div>
                )}
            </div>
        </motion.div>
    );
};

export default SystemMonitorOverview;
