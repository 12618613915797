import { Space, Table } from "antd";
import React from "react";
import { useRCPriceAlarmServerStop } from "../../../hooks/useRCPriceAlarm";
import MessageCard from "../../SystemMonitor/components/MessageCard";
const columns = [
    {
        title: "Server",
        dataIndex: "serverName",
        width: 100,
    },
    {
        title: "Last Tick",
        dataIndex: "startDateStr",
        width: 100,
    },
    {
        title: "Duration",
        dataIndex: "duration",
        width: 100,
    },
];
const ServerStopTable = () => {
    const { rcPriceAlarmServerStop, isLoading, rcPriceAlarmServerStopError } = useRCPriceAlarmServerStop();

    return (
        <div>
            {isLoading ? (
                <MessageCard type="info">Loading...</MessageCard>
            ) : rcPriceAlarmServerStopError ? (
                <MessageCard type="error">{rcPriceAlarmServerStopError}</MessageCard>
            ) : (
                <Space direction="vertical" style={{ width: "100%" }}>
                    {rcPriceAlarmServerStop?.data?.length === 0 ? (
                        <MessageCard type="success">All good</MessageCard>
                    ) : (
                        <MessageCard type="error">{rcPriceAlarmServerStop?.data?.length} error</MessageCard>
                    )}

                    <Table columns={columns} dataSource={rcPriceAlarmServerStop?.data || []} rowKey="serverName" pagination={false} size="small" />
                </Space>
            )}
        </div>
    );
};

export default ServerStopTable;
