import { DownloadOutlined, SearchOutlined } from "@ant-design/icons";
import { Form, message, Button } from "antd";
import moment from "moment";
import { FormComponent } from "../../../../../components/FormComponent";
import { ComponentType } from "../../../../../constants";
import { FormInstance } from "antd/es/form";
import { KeyValuePair } from "../../../../../constants/type";
import { useCallback, useState } from "react";
import { APIs } from "../../../../../services/apis";
import { plainAxiosInstance } from "../../../../../services/axiosSetup";
import { DefaultIfEmpty } from "../../../../../utils/object";
import { StringFormat } from "../../../../../utils/array";
import { ErrorCatchValidator } from "../../../../../utils/Common";

export interface FilterFormComponentProps {
    KeyStr: string;
    currentTabKey: string;
    form: FormInstance;
    servers: KeyValuePair[];
    callback: (type: number, data: any) => void;
}

export enum FilterFormComponentCallbackKey {
    FormSubmit = 1,
}

const FilterFormComponent = (props: FilterFormComponentProps) => {
    const [filterParams, setFilterParams] = useState<any>({});

    const exportCSV = useCallback(() => {
        try {
            plainAxiosInstance
                .post(
                    `${plainAxiosInstance.defaults.baseURL}${APIs.RISK_TOOL.GET_CONFIG_DIFF_RECORD_LIST_DOWNLOAD}/SINGLE/${props.currentTabKey}`,
                    { configDiffType: props.KeyStr, ...filterParams },
                    {
                        headers: {
                            Accept: "application/octet-stream, */*",
                        },
                        responseType: "blob",
                    }
                )
                .then(response => {
                    const contentType = response.headers["content-type"];
                    if (
                        contentType === "application/octet-stream" ||
                        contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    ) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute(
                            "download",
                            `ConfigDiff_${props.KeyStr}_${DefaultIfEmpty(filterParams, "startDate", "")}_${DefaultIfEmpty(
                                filterParams,
                                "endDate",
                                ""
                            )}.xlsx`
                        );
                        document.body.appendChild(link);
                        link.click();
                        // Clean up
                        window.URL.revokeObjectURL(url);
                    } else {
                        message.error(`Received non-file response. Error: ${response}`, 3);
                    }
                })
                .catch((error: any) => message.error(`Error occured during download: "${error.message}"`, 3));
        } catch (e: any) {
            message.error(`Error occured during download: "${e.message}"`, 3);
        }
    }, [props.KeyStr, props.currentTabKey, filterParams]);

    const processDownload = useCallback(() => {
        plainAxiosInstance
            .post(`${StringFormat(APIs.RISK_TOOL.GET_CONFIG_DIFF_RECORD_LIST, [props.KeyStr])}/count`, {
                configDiffType: props.KeyStr,
                ...filterParams,
            })
            .then((res: any) => {
                if (res.data > 0) {
                    exportCSV();
                } else {
                    message.warning("No data found for the selected filter criteria.", 3);
                }
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => message.error(`Error occured during download: ${err.message}`, 4)));
    }, [props.KeyStr, filterParams]);

    return (
        <>
            <div className="left">
                <Form
                    form={props.form}
                    layout="inline"
                    initialValues={{ hddateRange: [moment().subtract(1, "day"), moment()] }}
                    onFinish={(values: any) => {
                        if (!(values?.hasOwnProperty("hddateRange") && values.hddateRange !== undefined && values.hddateRange.length === 2)) {
                            message.error("Please select date range.");
                            return;
                        }

                        let hlFilterParams: any = {};
                        Object.keys(values)
                            .filter(x => values[x] && (Array.isArray(values[x]) ? values[x].length > 0 : values[x].toString().length > 0))
                            .map(x => {
                                if (x === "hdserver") {
                                    hlFilterParams["server"] = values[x];
                                } else if (x === "hddateRange") {
                                    hlFilterParams["startDate"] = values[x][0].format("YYYY-MM-DD 00:00:00");
                                    hlFilterParams["endDate"] = values[x][1].format("YYYY-MM-DD 23:59:59");
                                } else {
                                    hlFilterParams[x] = values[x];
                                }
                                return x;
                            });
                        setFilterParams(hlFilterParams);
                        props.callback && props.callback(FilterFormComponentCallbackKey.FormSubmit, hlFilterParams);
                    }}
                >
                    <FormComponent
                        label=""
                        name="hdserver"
                        extra={{
                            type: ComponentType.dropdown,
                            value: props.servers,
                            inputProps: { placeholder: "All servers", style: { width: "10vw" } },
                            itemProps: {
                                style: { marginRight: "0" },
                            },
                        }}
                    />
                    <FormComponent
                        label=""
                        name="hddateRange"
                        extra={{
                            type: ComponentType.daterange,
                            value: [],
                            dateFormat: "YYYY-MM-DD",
                            inputProps: {
                                dateOnly: true,
                                allowClear: false,
                            },
                            itemProps: {
                                style: { marginRight: "0" },
                            },
                        }}
                    />
                    <Button type="primary" htmlType="submit" icon={<SearchOutlined />} />
                </Form>
            </div>
            <div className="right">
                <Button type="primary" icon={<DownloadOutlined />} onClick={() => processDownload()}>
                    Download Report
                </Button>
            </div>
        </>
    );
};

export default FilterFormComponent;
