import { useQuery } from "@tanstack/react-query";
import { APIs, apiRequest } from "../services/apiConfig";

const useFilterConfigs = ({ filterType = [] }: { filterType: string[] }) => {
    const { data, refetch, isLoading, error } = useQuery({
        queryKey: ["filterConfigs", filterType],
        queryFn: () => apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType }),
        staleTime: 60 * 1000, //(60 x 1000ms = 1minute)
    });
    return {
        filterConfigs: data,
        filterConfigsError: error,
        refetchFilterConfigs: refetch,
        isLoading,
    };
};

export default useFilterConfigs;
