import React, { useState, useEffect, useMemo } from "react";
import { Bar, BarConfig } from "@ant-design/plots";
import { Button, Space, Typography } from "antd";
import { colorMap } from "@/pages/SystemMonitor/StatusMonitor/statusUtils";
import { motion } from "framer-motion";
import { DownOutlined } from "@ant-design/icons";

interface OutageItem {
    serverId: string;
    symbol: string;
    cleanSymbol: string;
    startDate: string;
    duration: number;
}
interface PriceOutageDowntimeChartProps {
    data: OutageItem[];
    dataCurrent: OutageItem[];
}

interface ChartDataItem {
    cleanSymbol: string;
    historical: number;
    current: number;
    total: number;
    formattedHistorical: string;
    formattedCurrent: string;
    formattedTotal: string;
}

const formatDuration = (seconds: number): string => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    if (hours > 0) {
        return `${hours}h ${minutes}m ${remainingSeconds}s`;
    } else {
        return `${minutes}m ${remainingSeconds}s`;
    }
};

const PriceOutageDowntimeChart: React.FC<PriceOutageDowntimeChartProps> = ({ data, dataCurrent }) => {
    const [historicalData, setHistoricalData] = useState<OutageItem[]>([]);
    const [currentData, setCurrentData] = useState<OutageItem[]>([]);

    useEffect(() => {
        setHistoricalData(data);
    }, [data]);

    useEffect(() => {
        setCurrentData(dataCurrent);
    }, [dataCurrent]);

    const chartData: ChartDataItem[] = useMemo(() => {
        const aggregatedData = new Map<string, { historical: number; current: number }>();

        // Process historical data
        historicalData.forEach(item => {
            const existing = aggregatedData.get(item.cleanSymbol) || { historical: 0, current: 0 };
            existing.historical = Math.max(existing.historical, item.duration);
            aggregatedData.set(item.cleanSymbol, existing);
        });

        // Process current data
        currentData.forEach(item => {
            const existing = aggregatedData.get(item.cleanSymbol) || { historical: 0, current: 0 };
            existing.current = Math.max(existing.current, item.duration);
            aggregatedData.set(item.cleanSymbol, existing);
        });

        return Array.from(aggregatedData)
            .map(([cleanSymbol, { historical, current }]) => {
                const totalSeconds = (historical + current) / 1000;
                return {
                    cleanSymbol,
                    historical: historical / 1000,
                    current: current / 1000,
                    total: totalSeconds,
                    formattedHistorical: historical > 0 ? formatDuration(historical / 1000) : "-",
                    formattedCurrent: current > 0 ? formatDuration(current / 1000) : "-",
                    formattedTotal: formatDuration(totalSeconds),
                };
            })
            .sort((a, b) => b.total - a.total);
    }, [historicalData, currentData]);

    const chartHeight = chartData.length * 25 + 30;

    const config: BarConfig = {
        data: chartData.flatMap(item => [
            { ...item, type: "historical", value: item.historical },
            { ...item, type: "current", value: item.current },
        ]),
        isStack: true,
        xField: "value",
        yField: "cleanSymbol",
        seriesField: "type",
        // @ts-ignore
        color: ({ type }: { type: string }) => (type === "historical" ? colorMap.disabled : colorMap.error),
        legend: false,
        label: {
            position: "right",
            formatter: (datum: any) => {
                const item = chartData.find(d => d.cleanSymbol === datum.cleanSymbol);
                if (item) {
                    // Check if this is the last segment (either 'current' type or 'historical' if there's no current)
                    const isLastSegment = datum.type === "current" || item.current === 0;
                    return isLastSegment ? item.formattedTotal : "";
                }
                return "";
            },
            style: {
                fill: "#000000",
                fontSize: 12,
            },
            offset: 5,
        },
        tooltip: {
            customContent: (title: string, data: any[]) => {
                if (data.length === 0) return "";
                const item = chartData.find(d => d.cleanSymbol === data[0].data.cleanSymbol);
                if (!item) return "";
                return (
                    <Space direction="vertical" style={{ padding: "1rem 0" }}>
                        <div style={{ fontWeight: "bold" }}>{item.cleanSymbol}</div>
                        <div>Historical: {item.formattedHistorical}</div>
                        <div>Current: {item.formattedCurrent}</div>
                        <div>Total: {item.formattedTotal}</div>
                    </Space>
                );
            },
        },
        xAxis: { label: null },
        yAxis: { label: { style: { fontSize: 12 } } },
        padding: [20, 100, 20, 100],
        height: chartHeight,
        barStyle: { fillOpacity: 0.8 },
        interactions: [{ type: "active-region", enable: false }],
        animation: false,
    };

    const [expanded, setExpanded] = useState(false);
    const { Title } = Typography;
    // if there's no current outage and historical outage, don't render the chart
    if (chartData.length === 0)
        return (
            <div style={{ background: "rgba(0,0,0,0.025)", borderRadius: "0.25rem", padding: "1rem" }}>
                <Title level={5}>Outage Summary (1h ago to present)</Title>
                <div style={{ marginTop: "0.25rem", fontSize: "12px" }}>No outage from 1h ago to present</div>
            </div>
        );

    return (
        <div style={{ background: "rgba(0,0,0,0.025)", borderRadius: "0.25rem", padding: "1rem" }}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Title level={5} style={{ margin: 0, padding: 0 }}>
                    Outage Summary (1h ago to present)
                </Title>
                <Button
                    size="small"
                    type="text"
                    shape="circle"
                    onClick={() => setExpanded(prev => !prev)}
                    icon={
                        <motion.div
                            animate={{
                                rotate: expanded ? 180 : 0,
                            }}
                        >
                            <DownOutlined />
                        </motion.div>
                    }
                />
            </div>
            {expanded ? (
                <>
                    <div style={{ height: chartHeight }}>
                        <Bar {...config} />
                    </div>
                    <div style={{ marginTop: "0.25rem", fontSize: "12px" }}>Red bars indicate current outages</div>
                </>
            ) : (
                <div style={{ marginTop: "0.25rem", fontSize: "12px" }}>
                    {/* total historical outage in number, no need list out all the symbols */}
                    Total historical outage:
                    {/* {chartData.length >= 1 ? chartData.length : "-"} */}
                    {chartData.filter(item => item.historical > 0).length > 1 ? chartData.filter(item => item.historical > 0).length : "-"}
                    <br />
                    {/* total current outage in number, no need list out all the symbols */}
                    Total current outage:{" "}
                    {chartData.filter(item => item.current > 0).length > 1 ? chartData.filter(item => item.current > 0).length : "-"}
                </div>
            )}
        </div>
    );
};

export default PriceOutageDowntimeChart;
