import { PlusOutlined, CloseOutlined, DeleteOutlined } from "@ant-design/icons";
import { Card, Button, Empty, Badge, Form, Input } from "antd";
import EmptyData from "../../../../components/Common/Empty";
import { LPMetricFilterPanelBasedProps } from "./LPMetricFilterPanel";
import { useMemo } from "react";
import { CustomNumberInputRange, FormComponent } from "../../../../components/FormComponent";
import { ComponentType, LP_FILTER_OPTIONS, METRIC_DATA_TYPE_ENUM, METRIC_FILTER_OPTIONS, METRIC_FILTER_OPTIONS_ENUM } from "../../../../constants";
import { INVALID_VALUE_FORMAT, REQUIRED_FIELD } from "../../../../constants/errorMessage";
import { ToObjectWithKey, ToOptionTypeList } from "../../../../utils/array";
import { isEmptyOrNull } from "../../../../utils/string";
import { KeyValuePair } from "../../../../constants/type";
import moment from "moment";
import { isBoolean, isDate, isNumber } from "lodash";

export interface LPOrConditionGroupPanelProps extends LPMetricFilterPanelBasedProps {
    tkey: number;
}

const LPOrConditionGroupPanel = (props: LPOrConditionGroupPanelProps) => {
    const fieldName = "MetricOuterFilters";

    return (
        <Form.List name={[props.tkey, fieldName]}>
            {(fields, { add, remove }) => (
                <>
                    <Card
                        bordered={false}
                        title={
                            <div className="orcondition-card-header">
                                <span className="title">Filter condition</span>
                            </div>
                        }
                        size="small"
                        className="orcondition-group-panel"
                    >
                        {fields.length > 0 &&
                            fields.map((field, index) => {
                                return (
                                    <div key={`mf-fg-${field.name}-${index}`} className="orcondition-wrapper">
                                        <div className="orcondition-item shadow-light">
                                            <div className="btn-group">
                                                <Button
                                                    type="text"
                                                    size="small"
                                                    icon={<CloseOutlined />}
                                                    className="btn-delete"
                                                    onClick={() => remove(field.name)}
                                                />
                                            </div>
                                            <div className="content">
                                                <LPOrConditionInnerPanel
                                                    tkey={field.name}
                                                    form={props.form}
                                                    componentId={[...props.componentId, ...[fieldName, field.name]]}
                                                    metrics={props.metrics}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        <div className="orcondition-add-button-container" onClick={() => add({ MetricInnerFilters: [] })}>
                            <span>Add "OR" Condition</span>
                        </div>
                    </Card>
                </>
            )}
        </Form.List>
    );
};

interface LPOrConditionInnerPanelProps extends LPOrConditionGroupPanelProps {}

const LPOrConditionInnerPanel = (props: LPOrConditionInnerPanelProps) => {
    const fieldName = "MetricInnerFilters";
    return (
        <Form.List name={[props.tkey, fieldName]}>
            {(fields, { add, remove }) => (
                <>
                    {fields.length > 0 &&
                        fields.map((field, index) => {
                            return (
                                <div key={`mf-fg-${field.name}-${index}`} className="and-item">
                                    <LPMetricFilterItem
                                        tKey={field.name}
                                        index={index + 1}
                                        form={props.form}
                                        metrics={props.metrics}
                                        componentId={[...props.componentId, ...[fieldName, field.name]]}
                                    />
                                    <Button type="text" danger icon={<DeleteOutlined />} onClick={() => remove(field.name)} />
                                </div>
                            );
                        })}
                    <div className="add-btn-panel" onClick={() => add({ MetricName: "", FilterOp: "", FilterValue: "" })}>
                        <span>Add "AND" Condition</span>
                    </div>
                </>
            )}
        </Form.List>
    );
};

interface LPMetricFilterItemProps extends LPMetricFilterPanelBasedProps {
    tKey: number;
    index: number;
}

const LPMetricFilterItem = (props: LPMetricFilterItemProps) => {
    const metricObj = useMemo(() => {
        return {
            obj: ToObjectWithKey(props.metrics, "propertyName"),
            options: props.metrics.map(x => ({ text: x.propertyDisplayName, value: x.propertyName })),
        };
    }, [props.metrics]);
    const metricSelected = Form.useWatch([...props.componentId, "MetricName"], props.form);
    const operatorType = Form.useWatch([...props.componentId, "FilterOp"], props.form);

    const getOperatorOptions = (metricDataType: number) => {
        let options = ToOptionTypeList(METRIC_FILTER_OPTIONS);
        if (metricDataType === -1) return options;

        switch (metricDataType) {
            case METRIC_DATA_TYPE_ENUM.BOOLEAN: {
                let availableIds: number[] = [METRIC_FILTER_OPTIONS_ENUM.EQUAL_TO, METRIC_FILTER_OPTIONS_ENUM.NOT_EQUAL_TO];
                return options.filter(x => availableIds.includes(x.value as number));
            }
            case METRIC_DATA_TYPE_ENUM.CHAR:
            case METRIC_DATA_TYPE_ENUM.VARCHAR: {
                let availableIds: number[] = [
                    METRIC_FILTER_OPTIONS_ENUM.EQUAL_TO,
                    METRIC_FILTER_OPTIONS_ENUM.NOT_EQUAL_TO,
                    METRIC_FILTER_OPTIONS_ENUM.CONTAINS,
                    METRIC_FILTER_OPTIONS_ENUM.STARTS_WITH,
                    METRIC_FILTER_OPTIONS_ENUM.ENDS_WITH,
                ];
                return options.filter(x => availableIds.includes(x.value as number));
            }
            case METRIC_DATA_TYPE_ENUM.DATE:
            case METRIC_DATA_TYPE_ENUM.DATETIME: {
                let exceptIds: number[] = [
                    METRIC_FILTER_OPTIONS_ENUM.CONTAINS,
                    METRIC_FILTER_OPTIONS_ENUM.STARTS_WITH,
                    METRIC_FILTER_OPTIONS_ENUM.ENDS_WITH,
                ];
                return options.filter(x => !exceptIds.includes(x.value as number));
            }
            default: {
                let exceptIds: number[] = [
                    METRIC_FILTER_OPTIONS_ENUM.CONTAINS,
                    METRIC_FILTER_OPTIONS_ENUM.STARTS_WITH,
                    METRIC_FILTER_OPTIONS_ENUM.ENDS_WITH,
                ];
                return options.filter(x => !exceptIds.includes(x.value as number));
            }
        }
    };

    const operatorOptions = useMemo(() => getOperatorOptions(metricObj.obj[metricSelected]?.dataType || -1), [metricSelected]);

    const valueComponent: React.ReactNode = useMemo(() => {
        let metricType = metricObj.obj[metricSelected]?.dataType || -1,
            extraConfig: any = {
                type: ComponentType.text,
                value: "",
                rules: [{ required: true, message: REQUIRED_FIELD }],
                itemProps: {
                    style: { width: "40%" },
                },
                inputProps: {
                    style: {
                        width: "100%",
                    },
                },
            };

        switch (metricType) {
            case METRIC_DATA_TYPE_ENUM.BOOLEAN:
                extraConfig = Object.assign(extraConfig, {
                    type: ComponentType.dropdown,
                    value: [
                        { text: "True", value: true },
                        { text: "False", value: false },
                    ],
                    rules: [
                        {
                            validator: (_: any, value: any) => {
                                if (isEmptyOrNull(value)) return Promise.reject(REQUIRED_FIELD);
                                if (!isBoolean(value)) return Promise.reject(INVALID_VALUE_FORMAT);
                                return Promise.resolve();
                            },
                        },
                    ],
                });
                break;
            case METRIC_DATA_TYPE_ENUM.SMALL_INT:
            case METRIC_DATA_TYPE_ENUM.INTEGER:
            case METRIC_DATA_TYPE_ENUM.BIG_INT:
            case METRIC_DATA_TYPE_ENUM.DOUBLE:
            case METRIC_DATA_TYPE_ENUM.DECIMAL:
                if (operatorType === METRIC_FILTER_OPTIONS_ENUM.BETWEEN) {
                    return (
                        <Form.Item
                            name={[props.tKey, "FilterValue"]}
                            style={{ width: "40%" }}
                            rules={[
                                {
                                    validator: (_: any, value: any) => {
                                        if (`${value}`.split("|").filter(x => isEmptyOrNull(x)).length > 0) return Promise.reject(REQUIRED_FIELD);

                                        return Promise.resolve();
                                    },
                                },
                            ]}
                        >
                            <CustomNumberInputRange />
                        </Form.Item>
                    );
                }

                extraConfig = Object.assign(extraConfig, {
                    type: ComponentType.number,
                    value: "",
                    rules: [
                        {
                            validator: (_: any, value: any) => {
                                if (isEmptyOrNull(value)) return Promise.reject(REQUIRED_FIELD);
                                if (!isNumber(value)) return Promise.reject(INVALID_VALUE_FORMAT);
                                return Promise.resolve();
                            },
                        },
                    ],
                });
                break;
            case METRIC_DATA_TYPE_ENUM.DATE:
            case METRIC_DATA_TYPE_ENUM.DATETIME:
                extraConfig = Object.assign(extraConfig, {
                    type: ComponentType.date,
                    value: "",
                    rules: [
                        {
                            validator: (_: any, value: any) => {
                                if (isEmptyOrNull(value)) return Promise.reject(REQUIRED_FIELD);
                                if (!isDate(value)) return Promise.reject(INVALID_VALUE_FORMAT);
                                return Promise.resolve();
                            },
                        },
                    ],
                    ...(metricType === METRIC_DATA_TYPE_ENUM.DATE && {
                        dateFormat: "YYYY-MM-DD",
                    }),
                });
                break;
            default:
                break;
        }
        return <FormComponent label={""} name={[props.tKey, "FilterValue"]} extra={extraConfig} />;
    }, [operatorType, metricSelected]);

    return (
        <Input.Group compact>
            <FormComponent
                label={""}
                name={[props.tKey, "MetricName"]}
                extra={{
                    type: ComponentType.dropdown,
                    value: metricObj.options,
                    rules: [
                        {
                            validator: (_: any, value: any) => {
                                if (isEmptyOrNull(value)) return Promise.reject(REQUIRED_FIELD);

                                if (!metricObj.options.some((x: KeyValuePair) => `${x.value}` === `${value}`))
                                    return Promise.reject("Invalid selected metric.");

                                return Promise.resolve();
                            },
                        },
                    ],
                    itemProps: {
                        style: { width: "30%" },
                    },
                    inputProps: {
                        style: {
                            width: "100%",
                        },
                    },
                }}
            />
            <FormComponent
                label={""}
                name={[props.tKey, "FilterOp"]}
                extra={{
                    type: ComponentType.dropdown,
                    value: operatorOptions,
                    rules: [
                        {
                            validator: (_: any, value: any) => {
                                if (isEmptyOrNull(value)) return Promise.reject(REQUIRED_FIELD);
                                if (!operatorOptions.some((x: KeyValuePair) => `${x.value}` === `${value}`))
                                    return Promise.reject("Invalid selected operator.");

                                return Promise.resolve();
                            },
                        },
                    ],
                    itemProps: {
                        style: { width: "30%" },
                    },
                    inputProps: {
                        style: {
                            width: "100%",
                        },
                    },
                }}
            />
            {valueComponent}
        </Input.Group>
    );
};

export default LPOrConditionGroupPanel;
