import React, { useState, useEffect, memo } from "react";
import moment from "moment";
import { useRCMetaTraderServerTime } from "../../../hooks/useRCMetaTraders";
import { Space, Typography } from "antd";
import Counter from "../../../components/Motion/Counter";

interface TimeObject {
    year: number;
    month: number;
    date: number;
    hour: number;
    minute: number;
    second: number;
}

interface YearMonthDateProps {
    year: number;
    month: number;
    date: number;
}

interface GMTDisplayProps {
    gmt: string | number; // Adjust based on the actual type of gmt
}

interface TimeCounterProps {
    hour: number;
    minute: number;
    second: number;
}

const YearMonthDate: React.FC<YearMonthDateProps> = memo(({ year, month, date }) => {
    const { Text } = Typography;
    return (
        <Space size={2} style={{ lineHeight: 1, alignItems: "center", justifyContent: "center" }}>
            <Text style={{ fontSize: 12 }}>{year}</Text>
            <Text style={{ fontSize: 12 }}>{moment().month(month).format("MMM")}</Text>
            <Text style={{ fontSize: 12 }}>{date}</Text>
        </Space>
    );
});

const GMTDisplay: React.FC<GMTDisplayProps> = memo(({ gmt }) => {
    const { Text } = Typography;
    return <Text style={{ fontSize: 12, lineHeight: 1.15, color: "rgba(0,0,0,0.5)" }}>(GMT{gmt})</Text>;
});

const HourCounter: React.FC<{ hour: number }> = memo(({ hour }) => <Counter value={hour} digits={2} fontSize={24} />);

const MinuteCounter: React.FC<{ minute: number }> = memo(({ minute }) => <Counter value={minute} digits={2} fontSize={24} />);

const SecondCounter: React.FC<{ second: number }> = memo(({ second }) => <Counter value={second} digits={2} fontSize={24} />);

const TimeCounter: React.FC<TimeCounterProps> = ({ hour, minute, second }) => {
    return (
        <Space size={1} style={{ lineHeight: 1, userSelect: "none" }}>
            <HourCounter hour={hour} />:<MinuteCounter minute={minute} />:<SecondCounter second={second} />
        </Space>
    );
};

const ServerTimeWidget: React.FC = () => {
    const { gmt, isLoading } = useRCMetaTraderServerTime();
    const [timeObject, setTimeObject] = useState<TimeObject>({
        year: 0,
        month: 0,
        date: 0,
        hour: 0,
        minute: 0,
        second: 0,
    });
    const { Text } = Typography;

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (!isLoading && gmt) {
                const now = moment().utcOffset(gmt);
                setTimeObject({
                    year: now.year(),
                    month: now.month(),
                    date: now.date(),
                    hour: now.hour(),
                    minute: now.minute(),
                    second: now.second(),
                });
            }
        }, 1000);

        return () => clearInterval(intervalId); // Cleanup interval on unmount
    }, [gmt, isLoading]);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                padding: "0.5rem 1rem",
                borderRadius: "0.5rem",
                border: "1px solid rgba(0,0,0,0.05)",
                boxShadow: "0 0 1rem rgba(0,0,0,0.1)",
            }}
        >
            <Text style={{ lineHeight: "1em", color: "rgba(0,0,0,0.5)", borderBottom: "1px solid rgba(0,0,0,0.25)" }}>
                <small>Server Time</small>
            </Text>
            <Space direction="vertical" size={1} style={{ lineHeight: 1, alignItems: "flex-end" }}>
                <YearMonthDate year={timeObject.year} month={timeObject.month} date={timeObject.date} />
                <GMTDisplay gmt={gmt ?? 0} />
                <TimeCounter hour={timeObject.hour} minute={timeObject.minute} second={timeObject.second} />
            </Space>
        </div>
    );
};

export default ServerTimeWidget;
