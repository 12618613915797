import { Module, ProfileProps } from "../../constants/type";
import { ErrorCatchValidator, parseJwt } from "../../utils/Common";
import { apiRequest, APIs } from "../apiConfig";
import {
    removeToken,
    getToken,
    getProfile,
    removeProfile,
    removeMenus,
    removeSource,
    getRefreshToken,
    removeRefreshToken,
    setProfile,
    setMenus,
    setToken,
    setRefreshToken,
    removeAppSettings,
} from "../localStorage";
import * as qs from "qs";

export interface UserInfoProps {
    username: string;
    type: string;
    //userid: string;
    uuid: string;
    email: string;
    exp: string;
    isTokenExpired: () => boolean;
    isRefreshNeeded: () => boolean;
    isAdministrator: boolean;
}

export const userLogout = async () => {
    apiRequest(APIs.USER_LOGOUT, {})
        .then((response: any) => {})
        .catch((error: any) => ErrorCatchValidator(error, (err: any) => console.log(error)))
        .finally(() => {
            removeToken();
            removeRefreshToken();
            removeMenus();
            removeSource();
            removeAppSettings();
            removeProfile();
        });

    setTimeout(() => {
        window.location.href = "/login";
    }, 2500);
};

export const userInfo = (): UserInfoProps | undefined => {
    const accessTokenStr = getToken();
    if (accessTokenStr) {
        try {
            let userObj: UserInfoProps = parseJwt(accessTokenStr);
            let tokenExpirationDate = userObj.exp;
            let tokenExpiredDateMilliseconds = parseInt(tokenExpirationDate) * 1000;
            let currentTimeMilliseconds = new Date().getTime();
            let remainingTime = tokenExpiredDateMilliseconds - currentTimeMilliseconds;

            if (userObj) {
                userObj.isTokenExpired = () => (userObj ? currentTimeMilliseconds > tokenExpiredDateMilliseconds : true);
                userObj.isRefreshNeeded = () => (remainingTime <= 3600000 ? true : false);
            }

            const profileObj: ProfileProps | undefined = getProfile();
            if (profileObj) {
                userObj.username = profileObj.firstName;
                userObj.type = profileObj.roles;
                userObj.isAdministrator = profileObj.isAdministrator;
            } else {
                userObj.isAdministrator = false;
            }

            return userObj;
        } catch (error) {}
    }
    return undefined;
};

export const refreshToken = () => {
    return apiRequest(
        `${process.env.REACT_APP_VIMS_API + "/api/auth/v2/token"}`,
        qs.stringify({
            grant_type: "refresh_token",
            client_id: `${process.env.REACT_APP_CLIENT_ID}`,
            refresh_token: `${getRefreshToken()}`,
        }),
        "POST",
        "json",
        {
            Accept: "text/plain",
            "Content-type": "application/x-www-form-urlencoded",
        }
    );
};

export const setProfileInfo = (data: any = {}) => {
    return new Promise((resolve, reject) => {
        apiRequest(APIs.GET_USER_PROFILE_BY_ID, data)
            .then(async (res: any) => {
                setProfile({
                    id: res.id,
                    username: res.username,
                    firstName: res.firstName,
                    lastName: res.lastName,
                    roleIds: res.roleIds,
                    roles: res.roles,
                    isAdministrator: res.isAdministrator,
                    stom: res.stom,
                    team: res.team,
                });

                setMenus(
                    res.menus.map(
                        (x: any) =>
                            ({
                                id: x.menuId,
                                key: x.key,
                                title: x.title,
                                parentId: x.parentId,
                                type: x.type,
                                status: x.status,
                                order: x.order,
                            } as Module)
                    )
                );

                resolve({});
            })
            .catch(error => {
                ErrorCatchValidator(error, (err: any) => reject(err));
            });
    });
};

export const initialize = (accessToken: string, refreshToken: string) => {
    if (accessToken && accessToken.length > 0 && refreshToken && refreshToken.length > 0) {
        setToken(accessToken);
        setRefreshToken(refreshToken);
    }
};

export const getAppConfig = (keys: string[]) => {
    return apiRequest(APIs.GET_SETTING_LIST, { keys });
};
