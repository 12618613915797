import { useMemo } from "react";
import { Divider } from "antd";
import FlexiDataTable from "@/components/FlexiDataTable";
import { DTColProps } from "@/utils/Common";
import { FlexiDataTableOptionsProps, RollOverStopOutList } from "@/constants/type";
import moment from "moment";
export interface RollOverStopOutTableProps {
    data: RollOverStopOutList[];
    tbTitle: string;
    loading: boolean;
}

const RollOverStopOutTable = (props: RollOverStopOutTableProps) => {
    const columns = [
        DTColProps.XSmall({
            title: "Server",
            dataIndex: "server",
            key: "server",
            sorter: (a: any, b: any) => a.server - b.server,
        }),
        DTColProps.XSmall({
            title: "Ticket/Deal",
            dataIndex: "ticket",
            key: "ticket",
        }),
        DTColProps.XSmall({
            title: "Login",
            dataIndex: "login",
            key: "login",
        }),
        DTColProps.XSmall({
            title: "Type",
            dataIndex: "type",
            key: "type",
            render: (type: number) => <div>{type === 0 ? "buy" : "sell"}</div>,
        }),
        DTColProps.XSmall({
            title: "Open Time",
            dataIndex: "openTime",
            key: "openTime",
            render: (openTime: string) => <span>{openTime ? moment(openTime).format("YYYY-MM-DD HH:mm:ss") : ""}</span>,
        }),
        DTColProps.XSmall({
            title: "Close Price",
            dataIndex: "closePrice",
            key: "closePrice",
        }),
        DTColProps.XSmall({
            title: "Close Time",
            dataIndex: "closeTime",
            key: "closeTime",
            render: (closeTime: string) => <span>{closeTime ? moment(closeTime).format("YYYY-MM-DD HH:mm:ss") : ""}</span>,
        }),
        DTColProps.XSmall({
            title: "Profit",
            dataIndex: "profit",
            key: "profit",
        }),
        DTColProps.XSmall({
            title: "Comment",
            dataIndex: "comment",
            key: "comment",
        }),
    ];

    const options: FlexiDataTableOptionsProps = useMemo(
        () => ({
            showHideColumns: false,
            enableFilter: false,
        }),
        [props.data]
    );

    return (
        <>
            <br />
            <Divider style={{ borderColor: "#FFA500" }}>{props.tbTitle}</Divider>
            <FlexiDataTable
                rowKeyProperty="uniqueKey"
                title={false}
                columns={columns}
                options={options}
                dataSource={props.data}
                loading={props.loading}
                callback={() => { }}
                pagination={{ defaultPageSize: 10 }}
                scroll={{ x: true }}
            />
        </>
    );
};
export default RollOverStopOutTable;
