import React from "react";

import pageNotFound from "../../assets/images/page-not-found.jpg";

class NotFoundPage extends React.Component {
    render() {
        return (
            <div
                style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                    }}
                >
                    <div
                        style={{
                            // backgroundColor: 'white',
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            marginTop: "1.414vh",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                            }}
                        >
                            <img
                                alt={"pageNotFound"}
                                src={pageNotFound}
                                style={{
                                    width: "50%",
                                    display: "block",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    marginBottom: "1.414vh",
                                }}
                            />
                            <h3 style={{ textAlign: "center" }}>This page does not exist, are you sure this is the correct place?</h3>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default NotFoundPage;
