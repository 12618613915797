import { GlobalOutlined } from "@ant-design/icons";
import { Popover, Button } from "antd";
import moment from "moment";
import "moment-timezone";
import { useEffect, useState } from "react";
import TinyTime from "./tinyTime";
import { getProfile } from "../../services/localStorage";

export interface TimeProps {
    tz: string;
    name: string;
    utcOffset?: number;
}

export interface PopoverTimeProps {
    baseTimeZone: TimeProps;
    timeZones: TimeProps[];
}

export const PopoverTime = (props: PopoverTimeProps) => {
    const [dummySet, setDummySet] = useState<boolean>(false);

    const getClockTimeTemplate = (data: TimeProps, idx: number) => {
        const baseTimeOS: any = props.baseTimeZone?.hasOwnProperty("utcOffset")
            ? moment().utcOffset(props.baseTimeZone.utcOffset as number)
            : moment.tz(props.baseTimeZone.tz);
        const currentTime: any = data?.hasOwnProperty("utcOffset") ? moment().utcOffset(data.utcOffset as number) : moment().tz(data.tz);

        return (
            <div key={`wc-${idx}`} className="clock-panel">
                <div className="left">
                    <span className="name">{data.name}</span>
                    <span className="desc">
                        <span>UTC</span>
                        {currentTime.format("Z")}
                        <br />
                        <span>{props.baseTimeZone.name}</span>
                        {moment()
                            .utcOffset(currentTime.utcOffset() - baseTimeOS.utcOffset())
                            .format("Z")}
                    </span>
                </div>
                <div className="right">
                    <span>{currentTime.format("HH:mm:ss")}</span>
                    <span className="date-desc">{currentTime.format("YYYY-MM-DD, ddd")}</span>
                </div>
            </div>
        );
    };

    useEffect(() => {
        const secondInterval = setInterval(() => setDummySet(prev => !prev), 1000);

        return () => {
            clearInterval(secondInterval);
        };
    }, []);

    return (
        <>
            <div className="popover-time-container">{props.timeZones.map((x, i: number) => getClockTimeTemplate(x, i))}</div>
            <span style={{ display: "none" }}>{`${dummySet}`}</span>
        </>
    );
};

export interface WorldTimeProps {
    showTime?: string[];
}

const WorldTime = (props: WorldTimeProps) => {
    const uPr: any = getProfile();
    return (
        <div className="world-time-container">
            <Popover
                placement="bottomLeft"
                content={
                    <PopoverTime
                        baseTimeZone={{ tz: "Australia/Sydney", name: "Sydney" }}
                        timeZones={[
                            { tz: "Australia/Sydney", name: "Sydney" },
                            { tz: "Asia/Singapore", name: "Singapore" },
                            { tz: "EET", name: "Server", ...(uPr !== undefined && uPr.hasOwnProperty("stom") && { utcOffset: uPr.stom }) },
                            { tz: "Europe/London", name: "London" },
                            { tz: "America/New_York", name: "New York" },
                        ]}
                    />
                }
                trigger="click"
            >
                <Button icon={<GlobalOutlined />}>World Clock</Button>
            </Popover>
            <TinyTime showTime={{ tz: "EET", name: "Server", ...(uPr !== undefined && uPr.hasOwnProperty("stom") && { utcOffset: uPr.stom }) }} />
        </div>
    );
};

export default WorldTime;
