import { CheckCircleOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined, HomeOutlined, PlusOutlined } from "@ant-design/icons";
import { Form, Popconfirm, Button, Row, Col, Modal, Empty, message, Typography, Space } from "antd";
import { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams, NavigateFunction } from "react-router-dom";
import { FormComponent } from "../../../../components/FormComponent";
import LoadingComponent from "../../../../components/Loading";
import SitePageHeader from "../../../../components/PageHeader";
import { SUCCESS_FAILED, ComponentType, STATUS_TYPE } from "../../../../constants";
import { REQUIRED_FIELD } from "../../../../constants/errorMessage";
import { apiRequest } from "../../../../services/apiConfig";
import { APIs } from "../../../../services/apis";
import { ErrorMessageHandler, ErrorCatchValidator } from "../../../../utils/Common";
import CreateEditChannel, { LINEUserProps, WhatsappGroupProps } from "./createEditChannel";
import { ToObjectWithKey, ToOptionTypeList } from "../../../../utils/array";
import { OptionType } from "../../../../constants/type";
import { Subject } from "@microsoft/signalr";
const { Text } = Typography;

export interface CreateEditNotificationChannelProps {}

export type NotificationChannelState = {
    action: string;
    data: any;
};

const CreateEditNotificationChannel = (props: CreateEditNotificationChannelProps) => {
    let navigate = useNavigate();
    let location = useLocation();
    let com_state: NotificationChannelState = location.state as NotificationChannelState;
    const [currentState] = useState<NotificationChannelState>(com_state || { action: "add", data: null });
    const [isAddAction] = useState<boolean>(currentState.action === "add");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
    const [showAddChannelModal, setShowAddChannelModal] = useState<boolean>(false);
    const [channelModalTitle, setChannelModalTitle] = useState<string>("");
    const [notificationChannelForm] = Form.useForm();
    const [channelForm] = Form.useForm();
    const [lineQR, setlineQR] = useState<string>("");
    const [lineUsers, setLineUsers] = useState<LINEUserProps[]>([]);
    const [whatsappGroup, setWhatsappGroup] = useState<WhatsappGroupProps[]>([]);
    const [whatsappNumber, setWhatsappNumber] = useState<string>("");
    const [mediumsType, setMediumsType] = useState<OptionType[]>([]);
    let { id } = useParams();

    const onSubmit = (values: any) => {
        //Do basic validation
        if (!values?.hasOwnProperty("mediums") || values["mediums"] === undefined) {
            message.error(`At least a mediums was required.`, 3);
            return;
        } else {
            if (values.mediums.length < 1) {
                message.error(`At least a mediums was required.`, 3);
                return;
            } else if (values.mediums.filter((x: any) => x?.hasOwnProperty("action") && x.action === "delete").length === values.mediums.length) {
                message.error(`At least a mediums was required.`, 3);
                return;
            }
        }

        values["mediums"] = values["mediums"]
            .filter((x: any) => x?.hasOwnProperty("action"))
            .map((x: any) => {
                if (x["recipients"] !== undefined) {
                    if (typeof x["recipients"] === "string") {
                        if (x["recipients"].length > 0) {
                            x["recipients"] = x["recipients"].split(",");
                        } else {
                            x["recipients"] = [];
                        }
                    }
                } else {
                    x["recipients"] = [];
                }
                return x;
            });

        setIsBtnLoading(true);
        if (isAddAction) {
            apiRequest(APIs.CREATE_NOTIFICATION_CHANNEL, values)
                .then(data => {
                    ErrorMessageHandler("New notification channel", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                    navigate("/siteadmin/appconfig/notificationchannelconfig");
                })
                .catch(error =>
                    ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("new notification channel", SUCCESS_FAILED.FAILED_CREATE_DATA, err))
                )
                .finally(() => setIsBtnLoading(false));
        } else {
            // let finalObj: any = { ...values };
            // if (finalObj.mediums && finalObj.mediums.length > 0) {
            //     finalObj.mediums = finalObj.mediums.filter((x: any) => x?.hasOwnProperty("action"));
            // }
            apiRequest(APIs.UPDATE_NOTIFICATION_CHANNEL, values)
                .then(data => {
                    ErrorMessageHandler("Existing notification channel", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                    navigate("/siteadmin/appconfig/notificationchannelconfig");
                })
                .catch(error =>
                    ErrorCatchValidator(error, (err: any) =>
                        ErrorMessageHandler("existing  notification channel", SUCCESS_FAILED.FAILED_UPDATE_DATA, err)
                    )
                )
                .finally(() => setIsBtnLoading(false));
        }
    };

    const deleteNotificationChannel = (channelUid: string, navigator: NavigateFunction) => {
        apiRequest(APIs.UPDATE_NOTIFICATION_CHANNEL, {
            channelUid,
            status: 2,
            name: "",
        })
            .then(data => {
                ErrorMessageHandler("notification channel", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                navigator("/siteadmin/appconfig/notificationchannelconfig");
            })
            .catch(error =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("notification channel", SUCCESS_FAILED.FAILED_DELETE_DATA, err))
            );
    };

    const onAddEditChannelClicked = (isNew: boolean = false) => {
        setShowAddChannelModal(true);
        setChannelModalTitle(isNew ? "Create New Channel" : "Edit Channel");
        if (isNew) {
            channelForm.setFieldsValue({
                action: "add",
                mediumUid: `NCIDS-${Math.random()}`,
                type: undefined,
                name: undefined,
                subject: undefined,
                content: undefined,
                recipients: [],
                enableBroadcast: false,
                status: 1,
            });
        }
    };

    const getNotificationList = (channelUid: string) => {
        setIsLoading(true);
        apiRequest(APIs.GET_NOTIFICATION_CHANNEL_LIST, {
            channelUids: [channelUid],
            withDetails: true,
        })
            .then((data: any) => {
                let tmp: any = { ...data[0] };
                if (tmp?.hasOwnProperty("mediums") && tmp["mediums"].length > 0) {
                    tmp["mediums"] = tmp["mediums"].map((x: any) => {
                        if (typeof x["recipients"] === "object") {
                            x["recipients"] = x["recipients"].join(",");
                        }
                        return x;
                    });
                }
                notificationChannelForm.setFieldsValue(data[0]);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("notification channel", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                navigate("/siteadmin/appconfig/notificationchannelconfig");
            })
            .finally(() => setIsLoading(false));
    };

    const getLineUserList = () => {
        apiRequest(APIs.GET_NOTIFICATION_LINE_USERS, {})
            .then((data: any) => {
                let userList: LINEUserProps[] = data && data.length > 0 ? data : [];
                setLineUsers(userList);
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("notification line users", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            );
    };

    const getWhatsappGroupList = () => {
        apiRequest(APIs.GET_NOTIFICATION_WHATSAPP_GETGROUPS, {})
            .then((data: any) => {
                setWhatsappGroup(data && data.whatsAppGroups && data.whatsAppGroups.length > 0 ? data.whatsAppGroups : []);
                setWhatsappNumber(data && data.insightPhoneNumber.length > 0 ? data.insightPhoneNumber : "");
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("notification whatapps users", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            );
    };

    const initConfiguration = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["mediumstype"] })
            .then((data: any) =>
                setMediumsType(
                    data.mediumsType.length > 0
                        ? data.mediumsType.map((x: any) => ({
                              text: x.description,
                              value: x.name,
                          }))
                        : []
                )
            )
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("notification qrcode", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
            });

        apiRequest(APIs.GET_NOTIFICATION_QRCODE, {})
            .then((data: any) => {
                setlineQR(data);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("notification qrcode", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
            });

        getLineUserList();
        getWhatsappGroupList();
    };

    useEffect(() => {
        initConfiguration();
        if (!isAddAction) {
            getNotificationList(currentState.data.channelUid);
        }
        return () => {};
    }, [isAddAction]);

    return (
        <SitePageHeader
            title={isAddAction ? "Create New Notification Channel" : "Edit Notification Channel"}
            routes={[
                {
                    path: "/siteadmin/appconfig/notificationchannelconfig",
                    breadcrumbName: "Notification Channel Management",
                    icon: <HomeOutlined />,
                },
                {
                    path: "",
                    breadcrumbName: isAddAction ? "Create New Notification Channel" : "Edit Notification Channel",
                },
            ]}
            onBack={() => navigate("/siteadmin/appconfig/notificationchannelconfig")}
            extraProps={{
                extra: isAddAction
                    ? []
                    : [
                          <Popconfirm
                              key={"cetmp-del"}
                              title="Confirm to delete?"
                              onConfirm={() => deleteNotificationChannel(currentState.data ? currentState.data.channelUid : id, navigate)}
                              okText="Yes"
                              cancelText="No"
                          >
                              <Button type="primary" danger>
                                  Delete Notification Channel
                              </Button>
                          </Popconfirm>,
                      ],
            }}
        >
            {isLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Loading..." />
                </div>
            ) : (
                <Form form={notificationChannelForm} layout="horizontal" initialValues={currentState.data} onFinish={onSubmit}>
                    <Row>
                        <Col span={11}>
                            <FormComponent label={""} name={"channelUid"} extra={{ type: ComponentType.hidden, value: "" }} />
                            <FormComponent
                                label="Name"
                                name="name"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    itemProps: {
                                        labelAlign: "left",
                                        labelCol: { span: 5 },
                                    },
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                        </Col>
                        <Col span={1}></Col>
                        <Col span={11}>
                            <FormComponent
                                label="Description"
                                name="description"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                        </Col>
                    </Row>
                    {!isAddAction && (
                        <Row>
                            <Col span={11}>
                                <FormComponent
                                    label="Status"
                                    name="status"
                                    extra={{
                                        type: ComponentType.dropdown,
                                        value: ToOptionTypeList(STATUS_TYPE),
                                        itemProps: {
                                            labelAlign: "left",
                                            labelCol: { span: 5 },
                                        },
                                        rules: [{ required: true, message: REQUIRED_FIELD }],
                                    }}
                                />
                            </Col>
                        </Row>
                    )}
                    <Row>
                        <Col span={23}>
                            <div className="notification-channel-container">
                                <Form.List name={"mediums"}>
                                    {(fields, { add, remove }) => {
                                        let mediums = notificationChannelForm.getFieldValue("mediums");
                                        return (
                                            <>
                                                <div className="title-div">
                                                    <div>
                                                        <h1>Channels</h1>
                                                        <div className="uid-box">
                                                            <Space align="baseline">
                                                                <span className="uidText">Channel UID</span>
                                                                <Text copyable strong>
                                                                    {currentState.data ? currentState.data.channelUid : id}
                                                                </Text>
                                                            </Space>
                                                        </div>
                                                    </div>
                                                    <Button type="dashed" onClick={() => onAddEditChannelClicked(true)} icon={<PlusOutlined />}>
                                                        Add Channel
                                                    </Button>
                                                </div>
                                                <div className="channel-list">
                                                    {(fields.length === 0 ||
                                                        mediums.filter((x: any) => x?.hasOwnProperty("action") && x.action === "delete").length ===
                                                            fields.length) && (
                                                        <div className="empty-list-item">
                                                            <Empty />
                                                        </div>
                                                    )}
                                                    {fields.map((field, index) => {
                                                        let currentMedium = mediums[index],
                                                            fidx: number = mediumsType.findIndex(x => x.value === currentMedium.type),
                                                            typeString: string = fidx > -1 ? mediumsType[fidx].text : "",
                                                            userObj: any = ToObjectWithKey(lineUsers, "sourceId"),
                                                            whatsappObj: any = ToObjectWithKey(whatsappGroup, "id"),
                                                            recipientString: string = currentMedium.recipients;

                                                        if (currentMedium.type === "line") {
                                                            if (typeof currentMedium.recipients === "string") {
                                                                recipientString = currentMedium.recipients
                                                                    .split(",")
                                                                    .map((x: string) => (userObj[x] ? userObj[x].sourceName : x))
                                                                    .join(", ");
                                                            } else if (typeof currentMedium.recipients === "object") {
                                                                recipientString = currentMedium.recipients
                                                                    .map((x: string) => (userObj[x] ? userObj[x].sourceName : x))
                                                                    .join(", ");
                                                            }
                                                        }

                                                        if (currentMedium.type === "whatsapp") {
                                                            if (typeof currentMedium.recipients === "string") {
                                                                recipientString = currentMedium.recipients
                                                                    .split(",")
                                                                    .map((x: string) => (whatsappObj[x] ? whatsappObj[x].name : x))
                                                                    .join(", ");
                                                            } else if (typeof currentMedium.recipients === "object") {
                                                                recipientString = currentMedium.recipients
                                                                    .map((x: string) => (whatsappObj[x] ? whatsappObj[x].name : x))
                                                                    .join(", ");
                                                            }
                                                        }

                                                        if (currentMedium?.hasOwnProperty("action") && currentMedium["action"] === "delete") {
                                                            return <></>;
                                                        }

                                                        return (
                                                            <div className="channel-item" key={field.key}>
                                                                <div className="main-content">
                                                                    <div className="title">
                                                                        <div className="left">
                                                                            <span className="type-def">{typeString}</span>
                                                                            <span
                                                                                className={`status-def ${
                                                                                    currentMedium.status === 1 ? "success" : "error"
                                                                                }`}
                                                                            >
                                                                                {STATUS_TYPE[currentMedium.status]}
                                                                            </span>
                                                                            {currentMedium.name}
                                                                            {currentMedium.enableBroadcast && (
                                                                                <div className="broadcast-style">
                                                                                    <CheckCircleOutlined /> Broadcast
                                                                                </div>
                                                                            )}
                                                                        </div>

                                                                        <div className="actions">
                                                                            <Button
                                                                                type="text"
                                                                                icon={<EditOutlined />}
                                                                                onClick={() => {
                                                                                    channelForm.setFieldsValue({
                                                                                        ...currentMedium,
                                                                                        ...(!currentMedium?.hasOwnProperty("action") && {
                                                                                            action: "update",
                                                                                        }),
                                                                                        ...(currentMedium?.hasOwnProperty("recipients") &&
                                                                                            (currentMedium["type"] === "line" ||
                                                                                                currentMedium["type"] === "whatsapp") &&
                                                                                            typeof currentMedium["recipients"] === "string" && {
                                                                                                recipients: currentMedium["recipients"].split(","),
                                                                                            }),
                                                                                    });
                                                                                    onAddEditChannelClicked(false);
                                                                                }}
                                                                            />
                                                                            <Button
                                                                                type="text"
                                                                                icon={
                                                                                    <DeleteOutlined
                                                                                        style={{
                                                                                            color: "#f00f00",
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            Modal.confirm({
                                                                                                icon: <ExclamationCircleOutlined />,
                                                                                                title: "Are you sure you want to delete this channel?",
                                                                                                //width: "50%",
                                                                                                onOk() {
                                                                                                    let mediums =
                                                                                                            notificationChannelForm.getFieldValue(
                                                                                                                "mediums"
                                                                                                            ),
                                                                                                        currItem = mediums[index];

                                                                                                    if (currItem?.hasOwnProperty("mediumUid")) {
                                                                                                        if (
                                                                                                            currItem["mediumUid"].indexOf(
                                                                                                                "NCIDS-"
                                                                                                            ) === 0
                                                                                                        ) {
                                                                                                            mediums.splice(index, 1);
                                                                                                        } else {
                                                                                                            mediums[index]["action"] = "delete";
                                                                                                        }
                                                                                                    }

                                                                                                    notificationChannelForm.setFieldsValue({
                                                                                                        mediums: mediums,
                                                                                                    });
                                                                                                },
                                                                                                onCancel() {},
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="uid-box ">
                                                                        <Space align="baseline">
                                                                            <span className="uidText">Medium UID</span>
                                                                            <Text copyable strong>
                                                                                {currentMedium.mediumUid}
                                                                            </Text>
                                                                        </Space>
                                                                    </div>
                                                                    <div className="subject">
                                                                        <span className="subtitle">Subject : </span>
                                                                        {currentMedium.subject}
                                                                    </div>
                                                                    {currentMedium.recipients.length > 0 && (
                                                                        <div className="recipient">
                                                                            <span className="subtitle">Recipients : </span>
                                                                            {recipientString}
                                                                        </div>
                                                                    )}
                                                                    {currentMedium.content && currentMedium.content.length > 0 ? (
                                                                        <div
                                                                            className="content"
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: currentMedium.content,
                                                                            }}
                                                                        ></div>
                                                                    ) : (
                                                                        <div className="content empty-list-item">
                                                                            <Empty />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </>
                                        );
                                    }}
                                </Form.List>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <div className="step-btns-group">
                                <Button type="primary" htmlType="submit" {...(isBtnLoading && { loading: true })}>
                                    Submit
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            )}

            <Modal
                width="45.57vw"
                destroyOnClose
                maskClosable={false}
                title={channelModalTitle}
                open={showAddChannelModal}
                onOk={() => {
                    channelForm
                        .validateFields()
                        .then(values => {
                            let mediums: any[] = notificationChannelForm.getFieldValue("mediums");
                            if (mediums && mediums.length > 0) {
                                let fidx: number = mediums.findIndex(x => x.mediumUid === values["mediumUid"]);
                                if (fidx > -1) {
                                    mediums[fidx] = { ...values, ...(values["type"] !== "email" && { subject: "" }) };
                                    if (mediums[fidx]?.hasOwnProperty("action") && mediums[fidx]["action"] !== "add") {
                                        mediums[fidx].action = "update";
                                    }
                                    notificationChannelForm.setFieldsValue({ mediums });
                                } else {
                                    notificationChannelForm.setFieldsValue({
                                        mediums: [
                                            {
                                                ...values,
                                                ...(values["type"] !== "email" && { subject: "" }),
                                            },
                                            ...mediums,
                                        ],
                                    });
                                }
                            } else {
                                notificationChannelForm.setFieldsValue({ mediums: [values] });
                            }
                            setShowAddChannelModal(false);
                        })
                        .catch(err => {});
                }}
                onCancel={() => {
                    channelForm.resetFields();
                    setShowAddChannelModal(false);
                }}
            >
                <div className="notification-channel-creat-container">
                    <CreateEditChannel
                        form={channelForm}
                        lineQR={lineQR}
                        lineUserList={lineUsers}
                        whatsappGroupList={whatsappGroup}
                        whatsappNumber={whatsappNumber}
                        channelType={mediumsType}
                        callback={(type: string, data: any) => {
                            if (type === "user-list") {
                                getLineUserList();
                            } else if (type === "whatsapp-list") {
                                getWhatsappGroupList();
                            }
                        }}
                    />
                </div>
            </Modal>
        </SitePageHeader>
    );
};

export default CreateEditNotificationChannel;
