import { Card } from "antd";

export interface ScoreCardBoxProps {
    title: string;
    subTitle?: string;
    scoreText: string;
    score: number;
    children: React.ReactNode;
    roundBorder?: number;
    style?: React.CSSProperties;
}

const ScoreCardBox = (props: ScoreCardBoxProps) => {
    const borderRadius = { borderRadius: `${props.roundBorder ? props.roundBorder : 0}px` };
    return (
        <Card
            bordered
            title={
                <div className="header-panel" style={{ borderRadius: `0px ${props.roundBorder ? props.roundBorder : 0}px 0px 0px` }}>
                    <div className="info">
                        <span className="title">{props.title}</span>
                        {props.subTitle && <span className="subtitle">{props.subTitle}</span>}
                    </div>
                    {props.scoreText.length > 0 && props.score !== 0 && (
                        <div className="score-panel">
                            <div>
                                <span className="text">{props.scoreText}</span>
                                <span>{props.score}</span>
                            </div>
                        </div>
                    )}
                </div>
            }
            className="score-card-box-container"
            style={{ ...borderRadius, ...(props.style && props.style) }}
            headStyle={borderRadius}
        >
            <div
                className="content"
                style={{ borderRadius: `0px 0px ${props.roundBorder ? props.roundBorder : 0}px ${props.roundBorder ? props.roundBorder : 0}px` }}
            >
                {props.children}
            </div>
        </Card>
    );
};

export default ScoreCardBox;
