import MessageCard from "@/pages/SystemMonitor/components/MessageCard";
import { Tabs } from "antd";
import React, { useEffect, lazy, Suspense } from "react";

// import TabSymbolGroup from "./TabSymbolGroup";
// import TabCustomGroup from "./TabCustomGroup";
// import TabLevelSettings from "./TabLevelSettings";
const TabSymbolGroup = lazy(() => import("./TabSymbolGroup"));
const TabCustomGroup = lazy(() => import("./TabCustomGroup"));
const TabLevelSettings = lazy(() => import("./TabLevelSettings"));

const Concept = () => {
    const [activeTab, setActiveTab] = React.useState("alarm");
    useEffect(() => {
        const currTabParam = new URLSearchParams(window.location.search).get("settingsTab");
        if (currTabParam) {
            setActiveTab(currTabParam);
        } else {
            setActiveTab("alarm");
        }
    }, []);

    // Handle popstate event
    useEffect(() => {
        const handlePopState = () => {
            const currTabParam = new URLSearchParams(window.location.search).get("settingsTab");
            if (currTabParam) {
                setActiveTab(currTabParam);
            } else {
                setActiveTab("alarm");
            }
        };

        window.addEventListener("popstate", handlePopState);

        return () => {
            window.removeEventListener("popstate", handlePopState);
        };
    }, []);
    const handleChangeGroup = (activeKey: string) => {
        setActiveTab(activeKey);
        // Create a new URLSearchParams object from the current URL
        const searchParams = new URLSearchParams(window.location.search);

        // Set or update the parameters you need
        searchParams.set("tab", "settings");
        searchParams.set("settingsTab", activeKey);
        searchParams.delete("customGroup");
        searchParams.delete("alarmGroup");

        // Update the URL without reloading the page
        window.history.pushState({}, "", `${window.location.pathname}?${searchParams.toString()}`);
    };
    return (
        <div>
            {/* <div style={{ marginBottom: "0.5rem" }}>
                <MessageCard type="info">This page is demo purpose only</MessageCard>
            </div> */}
            <Tabs
                defaultActiveKey="alarm"
                onChange={key => {
                    handleChangeGroup(key);
                }}
                size="small"
                type="card"
                items={[
                    { key: "alarm", label: "Alarm Group" },
                    { key: "custom", label: "Custom Group" },
                    {
                        key: "levelSetting",
                        label: "Level Settings",
                    },
                ]}
                tabBarStyle={{ marginBottom: "0px" }}
            />
            <Suspense fallback={<MessageCard type="info">Loading...</MessageCard>}>
                {activeTab === "alarm" && <TabSymbolGroup />}
                {activeTab === "custom" && <TabCustomGroup />}
                {activeTab === "levelSetting" && <TabLevelSettings />}
            </Suspense>
        </div>
    );
};

export default Concept;
