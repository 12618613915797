import React, { useEffect, useState } from "react";

import CardBox from "@/components/Common/CardBox";
import TabContent from "@/pages/SystemMonitor/components/TabContent";
import { Tabs } from "antd";
import TabMap from "./TabMap";
import TabExcludedSymbols from "./TabExcludedSymbols";
const DividendUpload = () => {
    const [currTab, setCurrTab] = useState<string | null>(null);
    // Ensure user can change tab by URL params
    useEffect(() => {
        const currTabParam = new URLSearchParams(window.location.search).get("tab");
        if (currTabParam) {
            setCurrTab(currTabParam);
        } else {
            setCurrTab("map");
        }
    }, []);

    // Handle popstate event
    useEffect(() => {
        const handlePopState = () => {
            const currTabParam = new URLSearchParams(window.location.search).get("tab");
            if (currTabParam) {
                setCurrTab(currTabParam);
            } else {
                setCurrTab("map");
            }
        };

        window.addEventListener("popstate", handlePopState);

        return () => {
            window.removeEventListener("popstate", handlePopState);
        };
    }, []);
    const handleChangeTab = (activeKey: string) => {
        setCurrTab(activeKey);
        window.history.pushState({}, "", `${window.location.pathname}?tab=${activeKey}`);
    };
    if (!currTab) return null;
    return (
        <div style={{ margin: "1rem 0 3rem" }}>
            <CardBox title={"Dividend Upload"}>
                <div style={{ padding: "0 1rem" }}>
                    <Tabs
                        activeKey={currTab}
                        onChange={handleChangeTab}
                        type="card"
                        style={{ marginBottom: 0 }}
                        items={[
                            {
                                label: "Map",
                                key: "map",
                            },
                            {
                                label: "Excluded Symbols",
                                key: "excluded",
                            },
                        ]}
                        tabBarStyle={{ marginBottom: 0 }}
                    />
                    <TabContent>
                        {currTab === "map" && <TabMap />}
                        {currTab === "excluded" && <TabExcludedSymbols />}
                    </TabContent>
                </div>
            </CardBox>
        </div>
    );
};

export default DividendUpload;
