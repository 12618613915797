import { HomeOutlined } from "@ant-design/icons";
import { Button, Col, Form, Popconfirm, Row } from "antd";
import { useEffect, useState } from "react";
import { NavigateFunction, useLocation, useNavigate, useParams } from "react-router-dom";
import { FormComponent } from "../../../components/FormComponent";
import LoadingComponent from "../../../components/Loading";
import SitePageHeader from "../../../components/PageHeader";
import { ComponentType, SUCCESS_FAILED } from "../../../constants";
import { REQUIRED_FIELD } from "../../../constants/errorMessage";
import { apiRequest, APIs } from "../../../services/apiConfig";
import { ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";

export type BrandState = {
    action: string;
    data: any;
};

function BrandCreateEditPage() {
    let navigate = useNavigate();
    let location = useLocation();
    let com_state: BrandState = location.state as BrandState;
    const [currentState] = useState<BrandState>(com_state || { action: "add", data: null });
    const [isAddAction, setIsAddAction] = useState<boolean>(currentState.action === "add");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [brandForm] = Form.useForm();
    let { id } = useParams();

    const onSubmit = (values: any) => {
        setIsLoading(true);

        if (isAddAction) {
            apiRequest(APIs.CREATE_BRAND, {
                brandName: values.brand,
            })
                .then((data) => {
                    ErrorMessageHandler("New brand", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                    navigate("/siteadmin/serverbrand/brand");
                })
                .catch((error) => {
                    ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("new brand", SUCCESS_FAILED.FAILED_CREATE_DATA, err));
                    setIsLoading(false);
                });
        } else {
            apiRequest(APIs.UPDATE_BRAND, {
                brandId: values.id,
                brandName: values.brand,
            })
                .then((data) => {
                    ErrorMessageHandler("Existing brand", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                    navigate("/siteadmin/serverbrand/brand");
                })
                .catch((error) => {
                    ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("existing brand", SUCCESS_FAILED.FAILED_UPDATE_DATA, err));
                    setIsLoading(false);
                });
        }
    };

    const getBrandList = () => {
        setIsLoading(true);
        apiRequest(APIs.GET_BRANDS_LIST, { brandId: currentState.data ? currentState.data : id })
            .then((data: any) => {
                if (currentState.data || id) {
                    setIsAddAction(false);
                    brandForm.setFieldsValue(data);
                } else {
                    setIsAddAction(true);
                    brandForm.resetFields();
                }
                setIsLoading(false);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("brand list", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                navigate("/siteadmin/serverbrand/brand");
            });
    };

    const deleteBrand = (brandId: string, navigator: NavigateFunction) => {
        apiRequest(APIs.DELETE_BRAND, { brandId })
            .then((data) => {
                ErrorMessageHandler("The brand", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                navigator("/siteadmin/serverbrand/brand");
            })
            .catch((error) => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("brand", SUCCESS_FAILED.FAILED_DELETE_DATA, err)));
    };

    useEffect(() => {
        getBrandList();
        return () => {};
    }, [isAddAction, brandForm]);

    return (
        <SitePageHeader
            title={isAddAction ? "Create New Brand" : "Edit Brand"}
            routes={[
                {
                    path: "/siteadmin/serverbrand/brand",
                    breadcrumbName: "Brand Listing",
                    icon: <HomeOutlined />,
                },
                {
                    path: "",
                    breadcrumbName: isAddAction ? "Create New Brand" : "Edit Brand",
                },
            ]}
            onBack={() => navigate("/siteadmin/serverbrand/brand")}
            extraProps={{
                extra: isAddAction
                    ? []
                    : [
                          <Popconfirm
                              key={"cetmp-del"}
                              title="Confirm to delete?"
                              onConfirm={() => deleteBrand(currentState.data ? currentState.data : id, navigate)}
                              okText="Yes"
                              cancelText="No"
                          >
                              <Button type="primary" danger>
                                  Delete Brand
                              </Button>
                          </Popconfirm>,
                      ],
            }}
        >
            {isLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Submitting..." />
                </div>
            ) : (
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    form={brandForm}
                    layout="horizontal"
                    initialValues={currentState.data}
                    onFinish={onSubmit}
                >
                    <Row>
                        <Col span={15}>
                            <FormComponent label={""} name={"id"} extra={{ type: ComponentType.hidden, value: "" }} />
                            <FormComponent
                                label="Brand Name"
                                name="brand"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div className="step-btns-group">
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            )}
        </SitePageHeader>
    );
}

export default BrandCreateEditPage;
