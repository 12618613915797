import { Col, FormInstance, Row } from "antd";
import { ComponentType } from "../../../constants";
import { REQUIRED_FIELD } from "../../../constants/errorMessage";
import { FormComponent } from "../../FormComponent";

export interface PieTemplateProps {
    prefix: string;
    form: FormInstance;
}

const PieTemplate = (props: PieTemplateProps) => {
    return (
        <>
            <Row>
                <Col span={24}>
                    <FormComponent
                        label={"Angle Field"}
                        name={`${props.prefix}_yField`}
                        extra={{
                            type: ComponentType.text,
                            value: "",
                            itemProps: {
                                labelAlign: "left",
                                labelCol: { span: 5 },
                                wrapperCol: { span: 11 },
                            },
                            rules: [
                                {
                                    required: true,
                                    message: REQUIRED_FIELD,
                                },
                            ],
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <FormComponent
                        label={"Series Field"}
                        name={`${props.prefix}_xField`}
                        extra={{
                            type: ComponentType.text,
                            value: "",
                            itemProps: {
                                labelAlign: "left",
                                labelCol: { span: 5 },
                                wrapperCol: { span: 11 },
                            },
                            rules: [
                                {
                                    required: true,
                                    message: REQUIRED_FIELD,
                                },
                            ],
                        }}
                    />
                </Col>
            </Row>
        </>
    );
};

export default PieTemplate;
