import { useEffect, useState } from "react";
import BrandSummary from "./Components/brandSummary";
import { ErrorCatchValidator, genMetricSelectorsByComponent } from "../../../utils/Common";
import PnlChartSummary from "./Components/pnlChartSummary";
import AlarmSummaryMap from "./Components/alarmSummaryMap";
import { APIs, apiRequest } from "../../../services/apiConfig";
import { BrandsList, MimMetricsExtra, ServersList, SymbolAssetTypesList } from "../../../constants/type";
import SummaryStats from "./Components/summaryStats";
import SymbolSummary from "../Search/Components/SymbolSummary";
import { INTRADAY_COMPONENTS_BY_ID } from "../../../constants";
import FixedTimeCountDown from "../../../components/Common/CountDown/FixedTimeCountDown";
import { Col, Row, Space } from "antd";
import LoadingComponent from "../../../components/Loading";
import TopBottomTableSummary from "../Search/Components/TopBottomTableSummary";
import WorldMapData from "../../../assets/data/worldmap.json";
import AlarmSummary from "./Components/alarmSummaryListing";

interface IntradayMonitorSummaryFilterConfig {
    mimMetrics: MimMetricsExtra[];
    servers: ServersList[];
    brands: BrandsList[];
    symbolAssetTypes: SymbolAssetTypesList[];
}

const IntradayMonitorSummary = () => {
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [filterConfigs, setFilterConfigs] = useState<IntradayMonitorSummaryFilterConfig>({
        mimMetrics: [],
        servers: [],
        brands: [],
        symbolAssetTypes: [],
    });
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [PKLastBucketTimestamp, setPKLastBucketTimestamp] = useState<string>("");
    const [refetch, setRefetch] = useState<number>(0);

    const getConfigList = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["mimmetric", "server", "brand", "symbolassettype"] })
            .then((data: any) => {
                if (data !== null) {
                    setFilterConfigs({
                        mimMetrics: data.mimMetrics,
                        servers: data.servers,
                        brands: data.brands,
                        symbolAssetTypes: data.symbolAssetTypes,
                    });
                } else {
                    setFilterConfigs({
                        mimMetrics: [],
                        servers: [],
                        brands: [],
                        symbolAssetTypes: [],
                    });
                }
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => console.log("Failed to load MIM metrics: ", err));
            })
            .finally(() => setIsLoading(false));
    };

    const getLatestBucketTimestamp = () => {
        apiRequest(APIs.GET_SETTING_LIST, { keys: ["PKLastBucketTimestamp"] })
            .then((res: any) => {
                setPKLastBucketTimestamp(res[0].value.replace("T", " "));
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => console.log("Failed to get latest bucket timestamp: ", err));
            });
    };

    useEffect(() => {
        getLatestBucketTimestamp();
        getConfigList();
    }, []);

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            getLatestBucketTimestamp();
            getConfigList();
            setRunRefetchDataList(false);
        }
        return () => {};
    }, [runRefetchDataList]);

    useEffect(() => getLatestBucketTimestamp(), [refetch]);

    return (
        <div className="intraday-summary-container">
            <div className="header-row">
                <div className="left">
                    <div className="main-title">Multi-Level Intraday Monitor</div>
                </div>
                <div className="right">
                    <Space>
                        <FixedTimeCountDown onFinish={() => setRefetch((prev) => prev + 1)} />
                        <span className="last-refresh-time">Last Updated Time (Server): {PKLastBucketTimestamp}</span>
                    </Space>
                </div>
            </div>
            <div className="main-content">
                {isLoading ? (
                    <div className="loading-container" style={{ width: "100%" }}>
                        <LoadingComponent tip="Loading..." />
                    </div>
                ) : (
                    <>
                        <Row className="row-sum">
                            <Col span={14} className="left-panel">
                                <div className="top-row">
                                    <PnlChartSummary
                                        metrics={genMetricSelectorsByComponent(
                                            filterConfigs.mimMetrics,
                                            INTRADAY_COMPONENTS_BY_ID.PNL_CHART,
                                            "summary"
                                        )}
                                        title=""
                                        selectedParams={false}
                                        resetState={refetch}
                                    />
                                    <SummaryStats
                                        metrics={genMetricSelectorsByComponent(
                                            filterConfigs.mimMetrics,
                                            INTRADAY_COMPONENTS_BY_ID.SUMMARY_STATS,
                                            "summary"
                                        )}
                                        resetState={refetch}
                                    />
                                </div>
                                <AlarmSummaryMap
                                    metrics={genMetricSelectorsByComponent(filterConfigs.mimMetrics, INTRADAY_COMPONENTS_BY_ID.WORLD_MAP)}
                                    resetState={refetch}
                                />
                            </Col>
                            <Col span={10} className="right-panel">
                                <AlarmSummary />
                                <TopBottomTableSummary
                                    mimMetrics={filterConfigs.mimMetrics}
                                    filterOptList={{
                                        SymbolAssetType: filterConfigs.symbolAssetTypes.map((x: any) => ({ text: x.name, value: x.id.toString()})),
                                        Server: filterConfigs.servers.map((x: any) => ({ text: x.server, value: x.id.toString() })),
                                        Brand: filterConfigs.brands.map((x: any) => ({ text: x.brand, value: x.id.toString() })),
                                        Country: WorldMapData.features
                                            .map((x: any) => ({ text: x.properties.name, value: x.properties.iso_alpha_2_code }))
                                            .sort((a, b) => a.text.toLowerCase().localeCompare(b.text.toLowerCase())),
                                    }}
                                    selectedParams={false}
                                    resetState={refetch}
                                />
                            </Col>
                        </Row>

                        <Row className="row-sum">
                            <Col span={12} className="left-panel">
                                <BrandSummary
                                    metrics={genMetricSelectorsByComponent(filterConfigs.mimMetrics, INTRADAY_COMPONENTS_BY_ID.BRAND_SUMMARY)}
                                    brands={filterConfigs?.brands}
                                    resetState={refetch}
                                />
                            </Col>
                            <Col span={12} className="right-panel">
                                <SymbolSummary
                                    metrics={genMetricSelectorsByComponent(filterConfigs.mimMetrics, INTRADAY_COMPONENTS_BY_ID.SYMBOL_SUMMARY)}
                                    selectedParams={false}
                                    resetState={refetch}
                                />
                            </Col>
                        </Row>
                    </>
                )}
            </div>
        </div>
    );
};

export default IntradayMonitorSummary;
