import { createApi } from "@reduxjs/toolkit/query/react";
import { APIs, defaultBaseQueryProps, ResponseProps } from "../../services/apiConfig";
import { ResponseBase, TagEvents, TagProfiles } from "../../constants/type";
import { axiosBaseQuery } from "../axiosBaseQuery";

export interface tagProfilesRequest {
    // name?: string,
    // description?: string
}

export interface tagProfilesResponse extends ResponseProps<TagProfiles[]> { }

export interface tagEventRequest {
    accountId: string;
    serverId: string;
}

export interface tagEventListResponse extends ResponseProps<ResponseBase<TagEvents[]>> { }

export const tagProfilesApiSlice = createApi({
    reducerPath: "api_6",
    baseQuery: axiosBaseQuery(),
    keepUnusedDataFor: 5, //global caching duration (seconds)
    endpoints: (builder) => ({
        getTagProfiles: builder.query<tagProfilesResponse, tagProfilesRequest | undefined>({
            keepUnusedDataFor: 0, //disable for caching for particular api request
            query: (arg) => ({ ...defaultBaseQueryProps, url: APIs.GET_TAG_PROFILE_LISTING, method: "POST", params: arg }),
            transformResponse: (returnValue: tagProfilesResponse, meta) => {
                return returnValue;
            },
        }),
        getTagEventListing: builder.query<tagEventListResponse, tagEventRequest | undefined>({
            keepUnusedDataFor: 0, //disable for caching for particular api request
            query: (arg) => ({ ...defaultBaseQueryProps, url: APIs.GET_TAG_EVENT_LIST, method: "POST", params: arg }),
            transformResponse: (returnValue: tagEventListResponse, meta) => {
                return returnValue;
            },
        }),
    }),
});

export const { useGetTagProfilesQuery, useGetTagEventListingQuery } = tagProfilesApiSlice;
