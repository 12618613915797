import React, { useState } from "react";

import PriceCalculateTools from "../../PriceSettings/PriceBackendOpt/PriceCalculateTools";
import TabContent from "../../SystemMonitor/components/TabContent";
import { Alert, Tabs } from "antd";
import PriceConnector from "../../PriceSettings/PriceBackendOpt/PriceConnector";
import PriceAnalysisTools from "../../PriceSettings/PriceBackendOpt/PriceAnalysisTools";
import TickReceiverTools from "../../PriceSettings/PriceBackendOpt/TickReceiverTools";
const TabRestartServer = () => {
    const [currTab, setCurrTab] = useState<string>("priceCalculateTools");
    const handleChangeTab = (activeKey: string) => {
        setCurrTab(activeKey);
    };
    return (
        <TabContent style={{ background: "rgba(0,0,0,0.05)" }}>
            {/* using dummy data */}

            <div style={{ padding: "0.5rem" }}>
                <Tabs
                    activeKey={currTab}
                    onChange={handleChangeTab}
                    type="card"
                    style={{ marginBottom: 0 }}
                    tabBarStyle={{ marginBottom: 0 }}
                    items={[
                        {
                            label: "Price Calculate Tools",
                            key: "priceCalculateTools",
                        },
                        {
                            label: "Price Connector",
                            key: "priceConnector",
                        },
                        {
                            label: "Price Analysis Tools",
                            key: "priceAnalysisTools",
                        },
                        {
                            label: "Tick Receiver Tools",
                            key: "tickReceiverTools",
                        },
                    ]}
                />
                <TabContent style={{ padding: "1rem" }}>
                    {currTab === "priceCalculateTools" && <PriceCalculateTools />}
                    {currTab === "priceConnector" && <PriceConnector />}
                    {currTab === "priceAnalysisTools" && <PriceAnalysisTools />}
                    {currTab === "tickReceiverTools" && <TickReceiverTools />}
                </TabContent>
            </div>
        </TabContent>
    );
};

export default TabRestartServer;
