import { useEffect, useMemo, useState } from "react";
import WorldMap from "../../../../components/WorldMap";
import { APIs, apiRequest } from "../../../../services/apiConfig";
import { ErrorCatchValidator, ErrorMessageHandler, currencyRender } from "../../../../utils/Common";
import { SUCCESS_FAILED } from "../../../../constants";
import LoadingComponent from "../../../../components/Loading";
import { Button, Input, Select } from "antd";
import { MimMetricsList } from "../../../../constants/type";
import { DimemsionFilterType, mapFilterParams } from "..";
import { hasAnyKey } from "../../../../utils/object";
import { isEmptyOrNull } from "../../../../utils/string";
import { SearchOutlined, SortAscendingOutlined, SortDescendingOutlined } from "@ant-design/icons";
import { ToObjectWithKey } from "../../../../utils/array";
import WorldMapData from "../../../../assets/data/worldmap.json";

interface CompanyPNLSummaryProps {
    metrics: any[];
    selectedParams?: DimemsionFilterType[];
    resetState: number;
}

const CompanyPNLSummary = (props: CompanyPNLSummaryProps) => {
    // component number 6
    const [data, setData] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [runRefetchData, setRunRefetchData] = useState<boolean>(false);
    const [selectedMetric, setSelectedMetric] = useState<string>("total_pnl");
    const [topBottom, setTopBottom] = useState<boolean>(true);
    const [searchText, setSearchText] = useState<string>("");
    const [listData, setListData] = useState<any[]>([]);

    const currentSelectedMetricInfo: MimMetricsList | undefined = useMemo(() => {
        if (props.metrics.length > 0) {
            let fidx = props.metrics.findIndex((x) => x.metricName === selectedMetric);
            if (fidx > -1) {
                return props.metrics[fidx];
            }
        }
        return undefined;
    }, [selectedMetric]);

    const getWorldMapData = () => {
        setIsLoading(true);
        apiRequest(APIs.GET_MIM_METRIC_RANKING, {
            dimensionSelectors: [5],
            metricSelectors: [
                {
                    metricName: selectedMetric,
                    columnOrder: 1,
                },
            ],
            ...(hasAnyKey(props.selectedParams) && { dimensionFilters: mapFilterParams(props.selectedParams) }),
        })
            .then((res: any) => {
                if (res.length > 0) {
                    let newData = res
                        .map((currData: any) => {
                            const thisCountry = WorldMapData.features.find((x: any) => x.properties.iso_alpha_2_code === currData.country);
                            let valueKey = Object.keys(res[0]).filter((x: string) => x !== "country")[0],
                                keyObj = ToObjectWithKey(res, "country", valueKey);
                            if (thisCountry === undefined) {
                                return {
                                    name: currData.country,
                                    value: keyObj[currData.country],
                                };
                            } else
                                return {
                                    name: thisCountry.properties.name,
                                    value: keyObj[thisCountry.properties.iso_alpha_2_code],
                                };
                        })
                        .sort((a: any, b: any) => b.value - a.value);
                    setListData(newData);
                    setData(res && res.length > 0 ? res : []);
                } else {
                    setListData([]);
                    setData([]);
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("Company P&L Summary", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsLoading(false));
    };

    const getItemTemplate = (items: any[], isLast: boolean = false, totalItem: number = 10): React.ReactNode => {
        let returnTemplate: React.ReactNode[] = [];
        for (let index = 0; index < (items.length > totalItem ? totalItem : items.length); index++) {
            const x = items[isLast ? items.length - 1 - index : index];
            returnTemplate.push(
                <div className="title-value-div" key={`country-ts-${index}`}>
                    <div className="title">{x.name}</div>
                    <div className="value">{x.value === 0 ? 0 : currencyRender(x.value, 2)}</div>
                </div>
            );
        }

        return returnTemplate;
    };

    const filteredData: any[] = useMemo(() => {
        let returnData: any = listData;
        let lowerSearchText = searchText.toLowerCase();
        if (lowerSearchText.length > 0) {
            let tempData = listData.filter((currListData: any) => currListData.name.toLowerCase().indexOf(lowerSearchText) > -1);

            returnData = tempData.length > 0 ? tempData : [];

            if (topBottom) {
                // desc order = largest first
                return returnData;
            } else {
                // asc order = smallest first
                let sortedData: any[] = [...returnData];
                sortedData.sort((a, b) => a.value - b.value);
                return sortedData;
            }
        }

        if (topBottom) {
            // desc order = largest first
            return returnData;
        } else {
            // asc order = smallest first
            let sortedData: any[] = [...returnData];
            sortedData.sort((a, b) => a.value - b.value);
            return sortedData;
        }
    }, [listData, searchText, topBottom]);

    useEffect(() => {
        if (runRefetchData) {
            getWorldMapData();
            setRunRefetchData(false);
        }
        return () => {};
    }, [runRefetchData]);

    useEffect(() => {
        if (hasAnyKey(props.selectedParams)) {
            setRunRefetchData(true);
        }
        return () => {};
    }, [props.selectedParams]);

    useEffect(() => {
        setRunRefetchData(true);
        return () => {};
    }, [props.resetState]);

    return (
        <div className="company-pnl-summary-panel">
            <div className="c-left">
                <div className="title">
                    <span className="title-text">Client P&L Summary</span>
                </div>
                <div className="world-map-container">
                    <div className="content">
                        {isLoading ? (
                            <div className="loading-container">
                                <LoadingComponent tip="Loading..." />
                            </div>
                        ) : (
                            <WorldMap
                                apiReturnData={data}
                                {...(currentSelectedMetricInfo &&
                                    !isEmptyOrNull(currentSelectedMetricInfo.worldMapLegendConfig) && {
                                        legendConfig: currentSelectedMetricInfo.worldMapLegendConfig as string,
                                    })}
                            />
                        )}
                    </div>
                </div>
            </div>
            {!isLoading && (
                <div className="c-right">
                    <div className="main-content">
                        <div>
                            <div className="desc-tags">
                                <div className="tag-div">
                                    <div className="select-filter-div">
                                        <Select
                                            bordered={false}
                                            value={selectedMetric}
                                            onChange={(value: any) => {
                                                setSelectedMetric(value);
                                                setRunRefetchData(true);
                                            }}
                                            options={props.metrics.map((x: MimMetricsList) => ({
                                                label: x.metricDisplayName,
                                                value: x.metricName,
                                            }))}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="filter-div">
                                <Input
                                    className="search-input-filter"
                                    type="text"
                                    prefix={<SearchOutlined style={{ color: "#c4c3c3" }} />}
                                    placeholder="Search country"
                                    allowClear
                                    onChange={(e: any) => setSearchText(e.currentTarget.value)}
                                />
                                <Button
                                    icon={topBottom ? <SortDescendingOutlined /> : <SortAscendingOutlined />}
                                    onClick={() => setTopBottom((prev) => !prev)}
                                />
                            </div>
                            <div className="content">
                                <div className="country-values nice-scrollbar">{getItemTemplate(filteredData, false, filteredData.length)}</div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CompanyPNLSummary;
