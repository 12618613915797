import { useQuery } from "@tanstack/react-query";
import { APIs } from "../services/apiConfig";
import { plainAxiosInstance } from "../services/axiosSetup";

const useRCIssuesLogServers = () => {
    const { data, refetch, isLoading, error } = useQuery({
        queryKey: ["rcIssuesLogServers2"],
        queryFn: () =>
            plainAxiosInstance.get(APIs.RC_ISSUES_LOG.GET_ISSUES_LOG_SERVERS, {
                headers: {
                    Accept: "application/json",
                },
            }),
        staleTime: 60 * 1000, //(60 x 1000ms = 1minute)
    });

    const rcIssuesLogServers: { value: string; label: string }[] = data?.data ? data?.data : [];
    // @ts-ignore
    const rcIssuesLogServersError: string | null = data?.data?.message || error?.message || error || null;
    return {
        rcIssuesLogServers,
        rcIssuesLogServersError,
        refetchrcIssuesLogServers: refetch,
        isLoading,
    };
};

export default useRCIssuesLogServers;
