import { AlertOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Badge } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export interface AlarmBtnProps {
    count: number;
}

const AlarmBtn = (props: AlarmBtnProps) => {
    let navigate = useNavigate();

    return (
        <div className="alarm-container">
            <div className="alarm-btn" onClick={() => navigate("/lpmonitor/alarm")}>
                <AlertOutlined style={{ color: "#FF0000" }} />
                <span>Alarm</span>
                {props.count > 0 && <Badge count={props.count} />}
                <ArrowRightOutlined />
            </div>
        </div>
    );
};

export default AlarmBtn;
