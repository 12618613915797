import React, { useState } from "react";
import FloatingTitleBox from "../../components/FloatingTitleBox";
import { Tabs } from "antd";
import useRCIssuesLogStore from "../../../../store/useRCIssuesLogStore";
import NoMoneyTable from "./NoMoneyTable";
import NoMoneyMailTable from "./NoMoneyMailTable";
import NoMoneySettings from "./NoMoneySettings";
import CommonIssuesLogExcludeAccountSettingsTable from "../CommonIssuesLogExcludeAccountSettingsTable";

const IssueNoMoney = () => {
    const updateInView = useRCIssuesLogStore(state => state.updateInView);
    const [currTab, setCurrTab] = useState<string>("mail");
    const handleChangeTab = (activeKey: string) => {
        setCurrTab(activeKey);
        // window.history.pushState({}, "", `${window.location.pathname}?tab=${activeKey}`);
    };
    // if (!currTab) return null;
    return (
        <FloatingTitleBox
            title="No Money"
            // titleExtra={<Badge count={rcIssuesLog?.length + 5} />}
            inViewCallback={(isInView: boolean) => {
                updateInView({ NO_MONEY: isInView });
            }}
            titleBarTabs={
                <Tabs
                    activeKey={currTab}
                    onChange={handleChangeTab}
                    type="card"
                    style={{
                        padding: "0.5rem 0.5rem 0 0.5rem",
                    }}
                    tabBarStyle={{
                        marginBottom: 0,
                    }}
                    items={[
                        // {
                        //     label: "Monitoring",
                        //     key: "monitor",
                        // },
                        {
                            label: "Mail Log",
                            key: "mail",
                        },
                        {
                            label: "Settings",
                            key: "settings",
                        },
                        {
                            label: "Whitelist",
                            key: "whitelist",
                        },
                    ]}
                />
            }
        >
            {/* {currTab === "monitor" && <NoMoneyTable />} */}
            {currTab === "mail" && <NoMoneyMailTable />}
            {currTab === "settings" && <NoMoneySettings />}
            {currTab === "whitelist" && <CommonIssuesLogExcludeAccountSettingsTable type="NO_MONEY_WHITELIST" title="" />}
        </FloatingTitleBox>
    );
};

export default IssueNoMoney;
