import { Form, Row, Col, message } from "antd";
import { useMemo, useState, useCallback, useEffect } from "react";
import { FormComponent } from "../../../../components/FormComponent";
import LoadingComponent from "../../../../components/Loading";
import SitePageHeaderInline, { SitePageHeaderInlineActionType } from "../../../../components/PageHeader/inlineIndex";
import { ComponentType, DIMENSION_LEVEL, InnerPageActionMode, SUCCESS_FAILED } from "../../../../constants";
import AlarmFilterPanel, { AlarmFilterOptionType, ItemProps } from "../components/AlarmFilterPanel";
import { APIs, apiRequest } from "../../../../services/apiConfig";
import { isEmptyOrNull } from "../../../../utils/string";
import WorldMapData from "../../../../assets/data/worldmap.json";
import { ARRAY_ACTION_TYPE, GetConstraintKeyList, SortList, ToObjectWithKey } from "../../../../utils/array";
import MetricFilterPanel from "../components/MetricFilterPanel";
import {
    AlarmConfigProps,
    AlarmRuleConfigProps,
    CreateEditInlineFormBased,
    DimensionFiltersProps,
    KeyValuePair,
    MetricORConditionProps,
    MimMetricsBased,
    MimMetricsList,
} from "../../../../constants/type";
import { DimensionLevelObjectConfig, ErrorCatchValidator, ErrorMessageHandler } from "../../../../utils/Common";
import { REQUIRED_FIELD } from "../../../../constants/errorMessage";
import { DefaultIfEmpty, getObjectWithKeys, objectRemoveProps, objectToArray } from "../../../../utils/object";

export interface IntradayMonitorAlarmCreateEditPageProps extends CreateEditInlineFormBased {
    callback: (action: InnerPageActionMode, value?: any) => void;
    resetState: number;
}

const filterType = DimensionLevelObjectConfig("IntradayAlarmDimensionArr");

const exceptMetricName = ["account_count", "currency_exposure"];

const IntradayMonitorAlarmCreateEditPage = (props: IntradayMonitorAlarmCreateEditPageProps) => {
    const isAddAction = useMemo(() => props.mode === InnerPageActionMode.CREATE_NEW, [props.mode]);
    const [commonFilterArr, setCommonFilterArr] = useState<AlarmFilterOptionType>(
        ToObjectWithKey(
            filterType.map(
                (x: any) => ({ DimensionLevel: x.DimensionLevel, title: x.title, componentType: x.componentType, options: [] } as ItemProps)
            ),
            "DimensionLevel"
        )
    );
    const [mimMetrics, setMimMetrics] = useState<MimMetricsBased[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [resetState, setResetState] = useState<number>(0);
    const [configForm] = Form.useForm();

    const onSubmit = useCallback(
        (values: any) => {
            let submitParams = objectRemoveProps(values, ["DimensionFilters", "DimensionSelectors", "MetricFilters"]),
                alarmRule: any = {};

            if (values.DimensionSelectors && values.DimensionSelectors.length > 0) {
                alarmRule["DimensionSelectors"] = values.DimensionSelectors;
            }
            if (values.DimensionFilters && values.DimensionFilters.length > 0) {
                alarmRule["DimensionFilters"] = values.DimensionFilters.filter(
                    (x: DimensionFiltersProps) =>
                        x.FilterIn.length > 0 &&
                        (x.DimensionLevel === DIMENSION_LEVEL.ACCGROUP ? true : commonFilterArr[x.DimensionLevel].options.length > x.FilterIn.length)
                );
            }

            if (values.MetricFilters && values.MetricFilters.length > 0) {
                alarmRule["MetricFilters"] = values.MetricFilters.filter(
                    (x: MetricORConditionProps) =>
                        x.MetricOuterFilters.length > 0 && x.MetricOuterFilters.filter(y => y.MetricInnerFilters.length > 0).length > 0
                ).map((x: MetricORConditionProps) => ({
                    ...x,
                    MetricOuterFilters: x.MetricOuterFilters.filter(y => y.MetricInnerFilters.length > 0),
                }));

                let mfArray = objectToArray(
                    values.MetricFilters.reduce((arr: any, x: MetricORConditionProps) => {
                        let counter: number = 0;
                        if (x.MetricOuterFilters.length > 0) {
                            x.MetricOuterFilters.forEach(y => {
                                if (y.MetricInnerFilters.length > 0) {
                                    y.MetricInnerFilters.forEach(z => {
                                        if (!arr?.hasOwnProperty(z.MetricName)) {
                                            arr[z.MetricName] = { MetricName: z.MetricName, ColumnOrder: counter++ };
                                        }
                                    });
                                }
                            });
                        }
                        return arr;
                    }, {})
                );
                alarmRule["MetricSelectors"] = SortList(mfArray, "ColumnOrder");
            } else {
                message.error(`Please create at least one "Filter Rule"`);
                return;
            }

            submitParams["alarmRule"] = JSON.stringify(alarmRule);

            if (isAddAction) {
                apiRequest(APIs.CREATE_MIM_ALARM_CONFIG, submitParams)
                    .then(res => {
                        ErrorMessageHandler("alarm configuration", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                        setTimeout(() => props.callback && props.callback(InnerPageActionMode.BACK, { refreshMainList: true }), 400);
                    })
                    .catch(error =>
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("alarm configuration", SUCCESS_FAILED.FAILED_CREATE_DATA, err))
                    );
            } else {
                apiRequest(APIs.UPDATE_MIM_ALARM_CONFIG, submitParams)
                    .then(res => {
                        ErrorMessageHandler("alarm configuration", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                        setTimeout(() => props.callback && props.callback(InnerPageActionMode.BACK, { refreshMainList: true }), 400);
                    })
                    .catch(error =>
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("alarm configuration", SUCCESS_FAILED.FAILED_UPDATE_DATA, err))
                    );
            }
        },
        [isAddAction, commonFilterArr]
    );

    const fillForm = useCallback(
        (data?: AlarmConfigProps) => {
            if (isEmptyOrNull(data)) {
                let tmp = {
                    alarmId: "",
                    alarmName: "",
                    alarmDescription: "",
                    isActive: true,
                    DimensionSelectors: [],
                    DimensionFilters: [],
                    MetricFilters: [],
                };

                configForm.setFieldsValue(tmp);
                setResetState(prev => prev + 1);
            } else {
                let currentData = data as AlarmConfigProps,
                    tmp: any = {
                        alarmId: currentData.alarmId,
                        alarmName: currentData.alarmName,
                        alarmDescription: currentData.alarmDescription,
                        isActive: currentData.isActive,
                    };

                try {
                    let moreConfig: AlarmRuleConfigProps = JSON.parse(currentData.alarmRule),
                        tmpDFilter = ToObjectWithKey(
                            (isEmptyOrNull(moreConfig.DimensionFilters) ? [] : moreConfig.DimensionFilters).map(x => ({
                                DimensionLevel: x.DimensionLevel,
                                FilterIn: x.FilterIn,
                            })),
                            "DimensionLevel"
                        );
                    tmp["DimensionSelectors"] = DefaultIfEmpty(moreConfig, "DimensionSelectors", [], true);
                    tmp["DimensionFilters"] = SortList(filterType, "sequence")
                        .filter(x => tmp["DimensionSelectors"].includes(x.DimensionLevel))
                        .map(x => DefaultIfEmpty(tmpDFilter, x.DimensionLevel, { DimensionLevel: x.DimensionLevel, FilterIn: [] }))
                        .map(x => {
                            let isRebateAccountList = [1, 8].includes(x.DimensionLevel);
                            let isDropdownBulkList = [11].includes(x.DimensionLevel);
                            return isRebateAccountList || isDropdownBulkList
                                ? x
                                : {
                                      ...x,
                                      FilterIn:
                                          x.FilterIn.length > 0
                                              ? GetConstraintKeyList(
                                                    x.FilterIn,
                                                    DefaultIfEmpty(commonFilterArr, x.DimensionLevel, [])?.options.map(
                                                        (y: KeyValuePair) => y.value
                                                    ) || [],
                                                    ARRAY_ACTION_TYPE.INNER
                                                )
                                              : DefaultIfEmpty(commonFilterArr, x.DimensionLevel, [])?.options.map((y: KeyValuePair) => y.value) ||
                                                [],
                                  };
                        });
                    tmp["MetricFilters"] = DefaultIfEmpty(moreConfig, "MetricFilters", [], true);
                } catch (error) {
                    console.log(error);
                }

                configForm.setFieldsValue(tmp);
                setResetState(prev => prev + 1);
            }
        },
        [commonFilterArr]
    );

    const getServerGroupDetails = (id?: number | string | undefined) => {
        if (isEmptyOrNull(id)) {
            fillForm(undefined);
            setIsLoading(false);
            return;
        }

        setIsLoading(true);
        apiRequest(APIs.GET_MIM_ALARM_CONFIG_LIST, { alarmId: id as number })
            .then((res: AlarmConfigProps) => fillForm(res))
            .catch(error => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("alarm configuration", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                props.callback && props.callback(InnerPageActionMode.BACK);
            })
            .finally(() => setIsLoading(false));
    };

    const getOptions = (tkey: number) => {
        switch (tkey) {
            case 5: // country
                return WorldMapData.features.map((x: any) => ({ text: x.properties.name, value: x.properties.iso_alpha_2_code }));
            default:
                return [];
        }
    };

    const getConfigList = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, {
            filterType: ["mimmetric", ...filterType.filter((x: any) => !isEmptyOrNull(x.responseKey)).map((x: any) => x.requestKey)],
        }).then((res: any) => {
            setCommonFilterArr(
                filterType.reduce((obj: AlarmFilterOptionType, x: any) => {
                    if (isEmptyOrNull(x.responseKey)) {
                        obj[x.DimensionLevel] = {
                            DimensionLevel: x.DimensionLevel,
                            title: x.title,
                            componentType: x.componentType,
                            options: SortList(getOptions(x.DimensionLevel), "text"),
                        };
                    } else if (res?.hasOwnProperty(x.responseKey)) {
                        obj[x.DimensionLevel] = {
                            DimensionLevel: x.DimensionLevel,
                            title: x.title,
                            componentType: x.componentType,
                            options: SortList(
                                res[x.responseKey].map((y: any) => ({ text: y[x.resText], value: y[x.resId] })),
                                "text"
                            ),
                        };
                    }
                    return obj;
                }, {})
            );
            setMimMetrics(
                res["mimMetrics"]
                    ? res["mimMetrics"]
                          .filter((x: MimMetricsList) => !exceptMetricName.includes(x.metricName))
                          .map((x: MimMetricsList) =>
                              getObjectWithKeys(x, ["metricName", "metricDisplayName", "metricDataType", "validForComponents", "validForDimensions"])
                          )
                    : []
            );
        });
    };

    const formValueChangedCallback = useCallback(
        (changedValues: any) => {
            if (changedValues?.hasOwnProperty("DimensionSelectors")) {
                let oldDFObj = ToObjectWithKey(configForm.getFieldValue("DimensionFilters"), "DimensionLevel");
                configForm.setFieldsValue({
                    DimensionFilters: SortList(filterType, "sequence")
                        .filter(x => changedValues["DimensionSelectors"].includes(x.DimensionLevel))
                        .map(x =>
                            DefaultIfEmpty(oldDFObj, x.DimensionLevel, {
                                DimensionLevel: x.DimensionLevel,
                                FilterIn:
                                    x.componentType === "rebateAccountSearch"
                                        ? []
                                        : DefaultIfEmpty(commonFilterArr, x.DimensionLevel, [])?.options.map((y: KeyValuePair) => y.value) || [],
                            })
                        ),
                });
                setResetState(prev => prev + 1);
            }
        },
        [commonFilterArr]
    );

    useEffect(() => getServerGroupDetails(props.id), [props.resetState]);
    useEffect(() => getConfigList(), []);
    return (
        <SitePageHeaderInline
            enableSubmit
            title={isAddAction ? "Create New Alarm Configuration" : "Edit Alarm Configuration"}
            callback={(action: SitePageHeaderInlineActionType) => {
                switch (action) {
                    case SitePageHeaderInlineActionType.OnBack:
                    case SitePageHeaderInlineActionType.OnCancel:
                        props.callback && props.callback(InnerPageActionMode.BACK);
                        return;
                    case SitePageHeaderInlineActionType.OnSubmit:
                        configForm
                            .validateFields()
                            .then(res => onSubmit(res))
                            .catch(err => console.log("form-error:", err));
                        return;
                    default:
                        break;
                }
            }}
        >
            {isLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Loading..." />
                </div>
            ) : (
                <div className="intraday-monitor-create-edit-form">
                    <Form
                        labelAlign="left"
                        labelCol={{ span: 6 }}
                        form={configForm}
                        layout="horizontal"
                        initialValues={{}}
                        onFinish={onSubmit}
                        onValuesChange={formValueChangedCallback}
                    >
                        <FormComponent label="" name="alarmId" extra={{ type: ComponentType.hidden, value: "" }} />
                        <Row>
                            <Col span={14} className="main-panel-left">
                                <FormComponent
                                    label="Name"
                                    name="alarmName"
                                    extra={{ type: ComponentType.text, value: "", rules: [{ required: true, message: REQUIRED_FIELD }] }}
                                />
                                <FormComponent label="Description" name="alarmDescription" extra={{ type: ComponentType.text, value: "" }} />
                                <FormComponent label="Status" name="isActive" extra={{ type: ComponentType.switch, value: ["Inactive", "Active"] }} />
                                <MetricFilterPanel form={configForm} metrics={mimMetrics} componentId={[]} />
                            </Col>
                            <Col span={10} className="main-panel-right">
                                <FormComponent
                                    label="Dimension(s)"
                                    name="DimensionSelectors"
                                    extra={{
                                        type: ComponentType.dropdown,
                                        value: DimensionLevelObjectConfig("IntradayAlarmDimensionOptions"),
                                        rules: [{ required: true, message: REQUIRED_FIELD }],
                                        inputProps: {
                                            mode: "multiple",
                                        },
                                    }}
                                />
                                <AlarmFilterPanel form={configForm} options={commonFilterArr} resetState={resetState} />
                            </Col>
                        </Row>
                    </Form>
                </div>
            )}
        </SitePageHeaderInline>
    );
};

export default IntradayMonitorAlarmCreateEditPage;
