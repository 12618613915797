import { DownloadOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, message, Row, Spin, TimePicker } from "antd";
import moment from "moment-timezone";
import { useEffect, useMemo, useState } from "react";
import CardBox from "../../../components/Common/CardBox";
import { SUCCESS_FAILED } from "../../../constants";
import { APIs } from "../../../services/apis";
import { plainAxiosInstance } from "../../../services/axiosSetup";
import { ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";
import SpreadGroupCheckbox, { SpreadGroupFilterBtn } from "./SpreadGroupCheckbox";

type SpreadGroupType = {
    OurSpread: string[];
    CompetitorSpread: string[];
    LpSpread: string[];
};

const SpreadGroupFilters: { [key: string]: SpreadGroupFilterBtn[] } = {
    OurSpread: [
        { label: "ECN", regex: "-ECN", matchType: "endsWith" },
        { label: "STP", regex: "-STP", matchType: "endsWith" },
    ],
    CompetitorSpread: [{ label: "All", selectAll: true }],
    LpSpread: [
        { label: "NY", regex: "_MXT_", matchType: "includes" },
        { label: "LD", regex: "_UK_", matchType: "includes" },
    ],
};
const dateFormat = "YYYY-MM-DD";
const timeFormat = "HH:mm";
const initialSpreadOptions: SpreadGroupType = {
    OurSpread: [],
    CompetitorSpread: [],
    LpSpread: [],
};

const AverageSpread = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [spreadOptions, setSpreadOptions] = useState<SpreadGroupType>(initialSpreadOptions);
    const [selectedGroup, setSelectedGroup] = useState<SpreadGroupType>(initialSpreadOptions);
    const selectedGroupString = useMemo(() => {
        return selectedGroup.OurSpread.concat(selectedGroup.CompetitorSpread, selectedGroup.LpSpread).join(",");
    }, [selectedGroup]);
    const [selectedDate, setSelectedDate] = useState<string[]>([moment().format(dateFormat), moment().format(dateFormat)]);
    const [selectedTime, setSelectedTime] = useState<string[]>([
        moment("00:00", timeFormat).format(timeFormat),
        moment(new Date(), timeFormat).format(timeFormat),
    ]);
    const isDownloadDisabled = selectedGroupString === "" || isDownloading;

    useEffect(() => {
        getSpreadGroupKind();
    }, []);

    function getSpreadGroupKind() {
        setIsLoading(true);
        plainAxiosInstance
            .get(`${APIs.RC_AVERAGE_SPREAD.GET_SPREAD_GROUP_KIND}`)
            .then(res => {
                const data = res.data;
                if (data && data.length > 0) {
                    setSpreadOptions({
                        OurSpread: filterSpreadGroup(2, data),
                        CompetitorSpread: filterSpreadGroup(4, data),
                        LpSpread: filterSpreadGroup(1, data),
                    });
                }
            })
            .catch((error: any) => {
                return ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("servers", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
            })
            .finally(() => setIsLoading(false));
    }

    function filterSpreadGroup(gKind: number, data: { symbol: string; groupKind: number }[]) {
        return data.filter(item => item.groupKind === gKind).map(item => item.symbol);
    }

    function onDownloadOld() {
        const params = {
            startDate: selectedDate[0],
            endDate: selectedDate[1],
            startTime: selectedTime[0],
            endTime: selectedTime[1],
            spreadGroup: selectedGroupString,
        };
        setIsDownloading(true);
        plainAxiosInstance
            .post(APIs.RC_AVERAGE_SPREAD.DOWNLOAD_AVERAGE_SPREAD_OLD, params, { responseType: "blob" })
            .then(res => {
                const fileName = `Average_Spread_${moment().format("YYYYMMDD")}.xlsx`;
                const url = window.URL.createObjectURL(
                    new Blob([res.data], {
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
                    })
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", fileName);
                document.body.appendChild(link);
                link.click();

                // Clean up
                window.URL.revokeObjectURL(url);
                link.parentNode?.removeChild(link);
                ErrorMessageHandler("Average Spread", SUCCESS_FAILED.SUCCESS_DOWNLOAD_DATA);
            })
            .catch(error => {
                return ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("report", SUCCESS_FAILED.FAILED_DOWNLOAD_DATA, err));
            })
            .finally(() => setIsDownloading(false));
    }

    function onDownloadNew() {
        const params = {
            startDate: selectedDate[0],
            endDate: selectedDate[1],
            startTime: selectedTime[0],
            endTime: selectedTime[1],
            spreadGroup: selectedGroupString,
        };
        setIsDownloading(true);
        plainAxiosInstance
            .post(APIs.RC_AVERAGE_SPREAD.DOWNLOAD_AVERAGE_SPREAD_NEW, params)
            .then(res => {
                if (res.status === 200) {
                    message.success("Download Average Spread Report success. Please go to report batch and wait for download.", 3);
                } else {
                    message.error(`Download Average Spread Report fail.`, 3);
                }
            })
            .catch(err => {
                ErrorCatchValidator(err, (error: any) => ErrorMessageHandler("Average Spread Report", SUCCESS_FAILED.FAILED_DOWNLOAD_DATA, error));
            })
            .finally(() => {
                setIsDownloading(false);
            });
    }

    return (
        <div className="avg-spread-container">
            <CardBox title={"Average Spread"}>
                <div className="main-container">
                    <Row gutter={[16, 16]}>
                        <Col xs={12} xl={8}>
                            <DatePicker.RangePicker
                                format={dateFormat}
                                style={{ width: "100%" }}
                                placeholder={["Start Date", "End Date"]}
                                disabledDate={current => {
                                    return current && current > moment().endOf("day");
                                }}
                                onChange={(_, dateString) => setSelectedDate(dateString)}
                                value={[moment(selectedDate[0], dateFormat), moment(selectedDate[1], dateFormat)]}
                            />
                        </Col>
                        <Col xs={12} xl={8}>
                            <TimePicker.RangePicker
                                format={timeFormat}
                                style={{ width: "100%" }}
                                placeholder={["Start Time", "End Time"]}
                                onChange={(_, timeString) => setSelectedTime(timeString)}
                                value={[moment(selectedTime[0], timeFormat), moment(selectedTime[1], timeFormat)]}
                            />
                        </Col>
                        <Col xs={24} xl={8}>
                            <div className="download-button">
                                <Button
                                    type="primary"
                                    loading={isDownloading}
                                    icon={<DownloadOutlined />}
                                    onClick={onDownloadOld}
                                    disabled={isDownloadDisabled}
                                >
                                    {`Download (Old)`}
                                </Button>
                                <Button
                                    type="primary"
                                    loading={isDownloading}
                                    icon={<DownloadOutlined />}
                                    onClick={onDownloadNew}
                                    disabled={isDownloadDisabled}
                                >
                                    {`Download (New)`}
                                </Button>
                            </div>
                        </Col>
                    </Row>

                    <Spin spinning={isLoading}>
                        <Row gutter={16} wrap>
                            <SpreadGroupCheckbox
                                title={"Ours"}
                                options={spreadOptions.OurSpread}
                                onCheckboxChange={value => setSelectedGroup({ ...selectedGroup, OurSpread: value })}
                                filters={SpreadGroupFilters.OurSpread}
                            />
                            <SpreadGroupCheckbox
                                title={"Competitor"}
                                options={spreadOptions.CompetitorSpread}
                                onCheckboxChange={value => setSelectedGroup({ ...selectedGroup, CompetitorSpread: value })}
                                filters={SpreadGroupFilters.CompetitorSpread}
                            />
                            <SpreadGroupCheckbox
                                title={"LP"}
                                options={spreadOptions.LpSpread}
                                onCheckboxChange={value => setSelectedGroup({ ...selectedGroup, LpSpread: value })}
                                filters={SpreadGroupFilters.LpSpread}
                            />
                        </Row>
                    </Spin>
                </div>
            </CardBox>
        </div>
    );
};

export default AverageSpread;
