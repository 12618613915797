import HSGroupContent, { HSGroupContentProps } from "./contentTemplate";
import { DnDCallback } from "../../../../constants/type";
import SimpleDnD from "../../../../components/DropAndDrop/SimpleDnD";
import { ARRAY_ACTION_TYPE, GetConstraintKeyList } from "../../../../utils/array";
import { FormComponent } from "../../../../components/FormComponent";
import { ComponentType } from "../../../../constants";
import { CHECKBOX_KEYS } from "..";

export interface HSGroupTemplateProps {
    provider?: any;
    gKey: string;
    name: string;
    data: HSGroupContentProps[];
    startSwitching?: boolean;
    isDragDisabled?: boolean;
    callback?: DnDCallback;
    defaultArrangement?: any;
}

const HSGroupTemplate = (props: HSGroupTemplateProps) => {
    return (
        <div className="hs-group-container shadow-light">
            <div className="top" {...props.provider.dragHandleProps}>
                {props.startSwitching && (
                    <FormComponent
                        label={""}
                        name={`${CHECKBOX_KEYS.BrandKey}${props.gKey}`}
                        extra={{ type: ComponentType.checkbox, value: "", itemProps: { noStyle: true } }}
                    />
                )}
                {props.name}
            </div>
            <div className="content">
                <SimpleDnD
                    propertyId={`hfo-brd-gp-${props.name}`}
                    itemKey="groupName"
                    data={props.data}
                    startSwitching={props.startSwitching === undefined ? false : props.startSwitching}
                    isDragDisabled={props.isDragDisabled === undefined ? true : props.isDragDisabled}
                    itemTemplate={(x: any, idx: number, provider: any, startWitching: boolean) => (
                        <div ref={provider.innerRef} {...provider.draggableProps}>
                            <HSGroupContent
                                key={`hs-g-${idx}-${props.name}-${x.groupName}`}
                                provider={provider}
                                gKey={x.gKey}
                                groupName={x.groupName}
                                data={x.data}
                                startSwitching={startWitching}
                            />
                        </div>
                    )}
                    {...(props.callback && {
                        callback: props.callback,
                    })}
                    {...(props.defaultArrangement && {
                        defaultArrangement: GetConstraintKeyList(
                            props.defaultArrangement[props.name],
                            props.data.map(x => x.groupName),
                            ARRAY_ACTION_TYPE.INNER
                        ),
                    })}
                />
            </div>
        </div>
    );
};

export default HSGroupTemplate;
