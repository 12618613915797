import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { APIs } from "../services/apis";
import { plainAxiosInstance } from "../services/axiosSetup";
const useRCRejectOrderSettings = () => {
    // const token = getToken();
    const { data, refetch, isLoading, error, isFetching } = useQuery({
        queryKey: ["rcRejectOrderSettings"],
        queryFn: () =>
            plainAxiosInstance.get(`${APIs.RC_REJECT_ORDER.GET_REJECT_ORDER_SETTINGS}`, {
                headers: {
                    Accept: "application/json",
                },
            }),
        // axios.get(`${process.env.REACT_APP_API_URL}${APIs.RC_REJECT_ORDER.GET_REJECT_ORDER_SETTINGS}`, {
        //     headers: {
        //         Accept: "application/json",
        //         Authorization: `Bearer ${token}`,
        //     },
        // }),
        staleTime: 60 * 1000, //(60 x 1000ms = 60 seconds)
        // refetchInterval: 60 * 1000, //(60 x 1000ms = 60 seconds)
    });

    const rcRejectOrderSettings = data?.data?.status ? null : data?.data || null;
    // @ts-ignore
    const rcRejectOrderSettingsError = data?.data?.message || error?.message || error || null;

    return {
        rcRejectOrderSettings,
        rcRejectOrderSettingsError,
        refetchRcRejectOrdersSettings: refetch,
        isFetching,
        isLoading,
    };
};

export const useRCRejectOrderSettingsUpdate = () => {
    // const token = getToken();
    const queryClient = useQueryClient();
    const { mutateAsync, isLoading, error } = useMutation(
        async ({
            mainServerId,
            startTime = "00:00:00",
            endTime = "00:00:00",
            enable = 0,
        }: {
            mainServerId?: string;
            startTime?: string;
            endTime?: string;
            enable?: 0 | 1;
        }): Promise<any> => {
            // Add return type annotation
            if (!mainServerId) {
                return Promise.reject("server name shouldn't be empty");
            }
            return plainAxiosInstance.post(
                `${APIs.RC_REJECT_ORDER.POST_REJECT_ORDER_SETTINGS_UPDATE}`,
                { mainServerId, startTime, endTime, enable },
                {
                    headers: {
                        Accept: "application/json",
                    },
                }
            );
            // return axios.post(
            //     `${process.env.REACT_APP_API_URL}${APIs.RC_REJECT_ORDER.POST_REJECT_ORDER_SETTINGS_UPDATE}`,
            //     { mainServerId, startTime, endTime, enable },
            //     {
            //         headers: {
            //             Accept: "application/json",
            //             Authorization: `Bearer ${token}`,
            //         },
            //     }
            // );
        },
        {
            onSuccess: res => {
                queryClient.invalidateQueries(["rcRejectOrderSettings"]);
                // console.log(`Solving reject orders response 200`, res);
                return res;
            },
            onError: error => {
                queryClient.invalidateQueries(["rcRejectOrderSettings"]);
                // console.log(`Solving reject orders response 400`, error);
                return error;
            },
        }
    );

    return {
        mutateAsync,
        isLoading,
        error,
    };
};

// delete reject order settings

export const useRCRejectOrderSettingsDelete = () => {
    // const token = getToken();
    const queryClient = useQueryClient();
    const { mutateAsync, isLoading, error } = useMutation(
        async ({ mainServerId }: { mainServerId: string }): Promise<any> => {
            // Add return type annotation
            if (!mainServerId) {
                return Promise.reject("server name shouldn't be empty");
            }
            return plainAxiosInstance.delete(`${APIs.RC_REJECT_ORDER.DELETE_REJECT_ORDER_SETTINGS_UPDATE}?servers=${mainServerId}`, {
                headers: {
                    Accept: "application/json",
                },
            });
            // return axios.delete(
            //     `${process.env.REACT_APP_API_URL}${APIs.RC_REJECT_ORDER.DELETE_REJECT_ORDER_SETTINGS_UPDATE}?servers=${mainServerId}`,
            //     {
            //         headers: {
            //             Accept: "application/json",
            //             Authorization: `Bearer ${token}`,
            //         },
            //     }
            // );
        },
        {
            onSuccess: res => {
                queryClient.invalidateQueries(["rcRejectOrderSettings"]);
                // console.log(`Solving reject orders response 200`, res);
                return res;
            },
            onError: error => {
                queryClient.invalidateQueries(["rcRejectOrderSettings"]);
                // console.log(`Solving reject orders response 400`, error);
                return error;
            },
        }
    );

    return {
        mutateAsync,
        isLoading,
        error,
    };
};

export default useRCRejectOrderSettings;
