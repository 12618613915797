import { APIs } from "@/services/apis";
import { Card } from "antd";
import UploadDownloadComponent from "./component/uploadDownloadComponent";

const ScheduledChangeSecurity = () => {
    return (
        <div className="scheduled-change-security-container">
            <Card>
                <UploadDownloadComponent
                    upload={{
                        apiUrl: APIs.RC_MT_CLIENT_SECURITY.POST_UPLOAD_ACCOUNTS,
                        className: "scheduled-change-security",
                        title: "Upload Accounts for change security.",
                    }}
                    download={{
                        apiUrl: APIs.RC_MT_CLIENT_SECURITY.DOWNLOAD_SECURITY_CHANGE_LIST,
                        msgPrefix: "Security Change List",
                        title: "Download Security List",
                    }}
                />
            </Card>
        </div>
    );
};

export default ScheduledChangeSecurity;
