import React, { useEffect, useState } from "react";
import { DeleteOutlined, DownOutlined, RedoOutlined, WarningOutlined } from "@ant-design/icons";
import { Button, Col, notification, Row, Space, Tag, Tooltip, Typography } from "antd";
import { AnimatePresence, motion } from "framer-motion";
import ResizeablePanel from "../../../components/Motion/ResizablePanel";
import useRCStatusMonitorStore from "../../../store/useRCStatusMonitorStore";
import { colorMap, colorMapRGB } from "./statusUtils";
import { useRCStatusMonitorRerun } from "../../../hooks/useRCStatusMonitorDaily";
import { useRCStatusMonitorAddExclude } from "../../../hooks/useRCStatusMonitorExclude";
export interface IServerDetails {
    appName: string;
    department: string;
    exclude: string;
    groupId: string;
    ip: string;
    machineName: string;
    message: string;
    processed: boolean;
    sendTime: string;
    sendTimeStr: string;
    serverName: string;
    showStopTime: string;
    status: number;
    stopTime: string;
    timeStamp: number;
    vendor: string;
    warningAlarm?: number;
    warningEndTime?: string;
    warningStartTime?: string;
    warningTimeRange?: string;
    warningWeekOfDays?: number;
    warningWeekOfDaysStr?: string;
    enableRemoteStart?: number;
    comment?: string;
    scheduleName?: string;
}

export interface ServerStatusProps {
    errorCount: number;
    warningCount: number;
    vendor: string;
    groupData: {
        danger: number;
        data: IServerDetails[];
        group: string;
        success: number;
        warning: number;
        secondary?: number;
    }[];
}

const ServerStatus = ({ data }: { data: ServerStatusProps }) => {
    const [expandedData, setExpandedData] = useState<string[]>([]);
    const showDanger = useRCStatusMonitorStore(state => state.display.danger);
    const showWarning = useRCStatusMonitorStore(state => state.display.warning);
    const showSuccess = useRCStatusMonitorStore(state => state.display.success);
    const showSummary = useRCStatusMonitorStore(state => state.display.summary);
    const showDisabled = useRCStatusMonitorStore(state => state.display.disabled);
    const { Text } = Typography;

    // check groupData, if all servers are hidden, hide the group
    const filteredData = data.groupData
        .filter(group => {
            return group.data.some(server => {
                if (server.status === 2 && showDanger) return true;
                if (server.status === 1 && showWarning) return true;
                if (server.status === 0 && showSuccess) return true;
                if (server.status === -1 && showDisabled) return true;
                return false;
            });
        })
        ?.sort((a, b) => b.danger - a.danger);

    if (filteredData.length === 0) return null;

    if (showSummary && filteredData.length >= 1) {
        const checkingBeforeReturn = filteredData?.map((group, index) => {
            // check if any of the servers in the group has a status of 2 (error), or 1 (warning)
            const hasError = group.data.some(server => server.status === 2);
            const hasWarning = group.data.some(server => server.status === 1);

            const finalData = group.data
                .filter(server => {
                    // it can be showDanger, showWarning and showSuccess combination, or only 2 of them, or none
                    if (server.status === 2 && showDanger) return true;
                    if (server.status === 1 && showWarning) return true;
                    if (server.status === 0 && showSuccess) return true;
                    if (server.status === -1 && showDisabled) return true;
                    return false;
                })
                ?.sort((a, b) => b.status - a.status);
            if (finalData.length === 0) return null;
            return finalData.map((server, index) => (
                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 4 }} key={server.groupId + index}>
                    <ServerDetails server={server} />
                </Col>
            ));
        });
        return (
            <motion.div key="summary" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} style={{ padding: "1rem" }}>
                <Row gutter={[8, 8]}>{checkingBeforeReturn}</Row>
            </motion.div>
        );
    }

    return (
        <div style={{ borderBottom: "1px solid rgba(0,0,0,0.05)", padding: "1rem" }}>
            <h3>{data.vendor}</h3>
            <Row gutter={[8, 8]}>
                {filteredData?.map((group, index) => {
                    // check if any of the servers in the group has a status of 2 (error), or 1 (warning)
                    const hasError = group.data.some(server => server.status === 2);
                    const hasWarning = group.data.some(server => server.status === 1);

                    const finalData = group.data
                        .filter(server => {
                            // it can be showDanger, showWarning and showSuccess combination, or only 2 of them, or none
                            if (server.status === 2 && showDanger) return true;
                            if (server.status === 1 && showWarning) return true;
                            if (server.status === 0 && showSuccess) return true;
                            if (server.status === -1 && showDisabled) return true;
                            return false;
                        })
                        ?.sort((a, b) => b.status - a.status);

                    const isExpanded = expandedData.includes(group.group);
                    if (finalData.length === 0) return <></>;
                    return (
                        <Col
                            key={group.group + index}
                            xs={{ span: 24 }}
                            sm={{ span: isExpanded ? 24 : 12 }}
                            md={{ span: isExpanded ? 24 : 8 }}
                            lg={{ span: isExpanded ? 24 : 6 }}
                        >
                            <div
                                key={index}
                                style={{
                                    border: "1px solid rgba(0,0,0,0.25)",
                                    borderRadius: "4px",
                                    padding: "0.5rem",
                                    margin: "0.25rem",
                                    background: hasError
                                        ? `rgba(${colorMapRGB.error}, 0.1)`
                                        : hasWarning
                                        ? `rgba(${colorMapRGB.warning}, 0.1)`
                                        : "none",
                                    width: "100%",
                                    height: "100%",
                                }}
                            >
                                <div style={{ width: "100%", display: "flex", marginBottom: 4 }}>
                                    <Row style={{ flex: 1 }}>
                                        <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                            <Text>{group.group}</Text>
                                        </Col>
                                        <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                            <Space direction="horizontal" size={4} wrap>
                                                {group.danger >= 1 && (
                                                    <Tag color={colorMap.error} style={{ margin: 0 }}>
                                                        {group.danger} Danger
                                                    </Tag>
                                                )}
                                                {group.warning >= 1 && (
                                                    <Tag color={colorMap.warning} style={{ margin: 0 }}>
                                                        {group.warning} Warning
                                                    </Tag>
                                                )}
                                                {group.success >= 1 && (
                                                    <Tag color={colorMap.success} style={{ margin: 0 }}>
                                                        {group.success} Success
                                                    </Tag>
                                                )}
                                                {group.secondary !== undefined && group.secondary >= 1 && (
                                                    <Tag color={colorMap.disabled} style={{ margin: 0 }}>
                                                        {group.secondary} Disabled
                                                    </Tag>
                                                )}
                                            </Space>
                                        </Col>
                                    </Row>

                                    <Button
                                        type="text"
                                        shape="circle"
                                        size="small"
                                        icon={
                                            <motion.div
                                                animate={{
                                                    //expandedData is an array of expanded groups, check if group.group is one of them
                                                    rotate: isExpanded ? 180 : 0,
                                                }}
                                                onClick={() => {
                                                    if (isExpanded) {
                                                        setExpandedData(expandedData.filter(item => item !== group.group));
                                                    } else {
                                                        setExpandedData([...expandedData, group.group]);
                                                    }
                                                }}
                                                style={{ cursor: "pointer" }}
                                            >
                                                <DownOutlined />
                                            </motion.div>
                                        }
                                    />
                                </div>
                                <ResizeablePanel>
                                    <AnimatePresence>
                                        {
                                            // if not expanded, show summary
                                            !isExpanded ? (
                                                <div key="minimal" style={{ lineHeight: "10px" }}>
                                                    {finalData.map((server, index) => (
                                                        <ServerDetailsMinimal
                                                            server={server}
                                                            key={server.groupId + index.toString()}
                                                            delay={index * 0.015}
                                                        />
                                                    ))}
                                                </div>
                                            ) : (
                                                // if expanded, show details
                                                <motion.div key="full" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                                                    <Row gutter={[8, 8]}>
                                                        {finalData.map((server, index) => (
                                                            <Col
                                                                xs={{ span: 24 }}
                                                                sm={{ span: 12 }}
                                                                md={{ span: 6 }}
                                                                lg={{ span: 6 }}
                                                                xl={{ span: 4 }}
                                                                key={server.groupId + index.toString()}
                                                            >
                                                                <ServerDetails server={server} />
                                                            </Col>
                                                        ))}
                                                    </Row>
                                                </motion.div>
                                            )
                                        }
                                    </AnimatePresence>
                                </ResizeablePanel>
                            </div>
                        </Col>
                    );
                })}
            </Row>
        </div>
    );
};

const HoverInfo = ({ title, info }: { title: string; info: string }) => {
    const { Text, Paragraph } = Typography;
    const primaryTextStyle = { color: "white" };
    const secondaryTextStyle = { color: "rgba(255,255,255,0.75)", fontSize: "0.85em", lineHeight: "0.9", display: "block" };
    return (
        <Paragraph style={{ margin: 0 }}>
            <Text style={secondaryTextStyle}>{title}</Text>
            <Text style={primaryTextStyle}>{info || "-"}</Text>
        </Paragraph>
    );
};

const RerunButton = ({ server }: { server: IServerDetails }) => {
    const { rerun, isLoading } = useRCStatusMonitorRerun();
    const [api, contextHolder] = notification.useNotification();

    const handleRerun = async () => {
        try {
            const resp = await rerun({ serverName: server.serverName, scheduleName: server.scheduleName || undefined });
            api.open({
                key: server.serverName + server.scheduleName,
                message: `Rerun Service`,
                description: `Rerunning ${server.serverName} - ${server.scheduleName}...`,
            });
            if (resp.data === "ok") {
                api.open({
                    key: server.serverName + server.scheduleName,
                    message: "Rerun Complete",
                    description: `Successful rerun ${server.serverName} - ${server.scheduleName}`,
                });
            } else {
                api.open({
                    key: server.serverName + server.scheduleName,
                    message: "Rerun Failed",
                    description: `Failed to rerun ${server.serverName} - ${server.scheduleName}`,
                });
            }
            // console.log(resp);
        } catch (e) {
            console.error(e);
            api.open({
                key: server.serverName + server.scheduleName,
                message: "Rerun Failed",
                description: `Failed to rerun ${server.serverName} - ${server.scheduleName}, error: ${e}`,
            });
        }
    };
    if (
        // true // for demo
        server.status !== undefined &&
        server.status !== 0 &&
        server.warningAlarm !== undefined &&
        server.warningAlarm !== 0 &&
        server.enableRemoteStart !== undefined &&
        server.enableRemoteStart !== 0
    ) {
        return (
            <>
                {contextHolder}
                <Button
                    type="primary"
                    size="small"
                    style={{ width: "100%", marginTop: "0.5rem" }}
                    onClick={() => {
                        // rerun server
                        handleRerun();
                    }}
                    disabled={isLoading}
                    icon={<RedoOutlined />}
                >
                    Rerun
                </Button>
            </>
        );
    }
    return null;
};

const ServerDetailsMinimal = ({ server, delay }: { server: IServerDetails; delay: number }) => {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{
                opacity: 1,
                transition: { delay },
            }}
            exit={{ opacity: 0 }}
            style={{
                display: "inline-block",
                width: "10px",
                height: "10px",
                backgroundColor:
                    server.status === 0
                        ? colorMap.success
                        : server.status === 1
                        ? colorMap.warning
                        : server.status === 2
                        ? colorMap.error
                        : colorMap.disabled,
                margin: "1px",
                lineHeight: "10px",
                borderRadius: "2px",
                cursor: "pointer",
            }}
            whileHover={{
                scale: 1.2,
            }}
        >
            <Tooltip
                title={
                    <Space size={4} direction="vertical">
                        <div>
                            <HoverInfo title="Server Name" info={server.serverName.toString()} />
                            <Tag
                                color={
                                    server.status === 0
                                        ? colorMap.success
                                        : server.status === 1
                                        ? colorMap.warning
                                        : server.status === 2
                                        ? colorMap.error
                                        : colorMap.disabled
                                }
                            >
                                {server.status === 0 ? "Success" : server.status === 1 ? "Warning" : server.status === 2 ? "Danger" : "Disabled"}
                            </Tag>
                        </div>
                        {/* <HoverInfo title="Status" info={server.status === 2 ? "Error" : server.status === 1 ? "Warning" : "Good"} /> */}
                        {server.message && <HoverInfo title="Message" info={server.message.toString()} />}
                        {server.machineName && <HoverInfo title="Machine Name" info={server.machineName.toString()} />}
                        {server.warningTimeRange && <HoverInfo title="Alarm Time" info={server.warningTimeRange + " (GMT+8)"} />}
                        {server.warningWeekOfDaysStr && <HoverInfo title="Alarm Week" info={server.warningWeekOfDaysStr} />}
                        {server.warningAlarm !== undefined && server.warningAlarm !== null && (
                            <HoverInfo title="Enable Alarm" info={server.warningAlarm ? "Yes" : "No"} />
                        )}
                        {server.enableRemoteStart !== undefined && server.enableRemoteStart !== null && (
                            <HoverInfo title="Enable Remote" info={server.enableRemoteStart ? "Yes" : "No"} />
                        )}
                        {server.comment !== undefined && server.comment !== null && <HoverInfo title="Notes" info={server.comment} />}
                        <RerunButton server={server} />
                        <HideButton server={server} />
                    </Space>
                }
            >
                <motion.div
                    style={{
                        display: "inline-block",
                        width: "10px",
                        height: "10px",
                        // border: "1px solid rgba(0,0,0,0.25)",
                    }}
                />
            </Tooltip>
        </motion.div>
    );
};

const ServerDetails = ({ server }: { server: IServerDetails }) => {
    const isError = server.status === 2;
    const isWarning = server.status === 1;
    const isDisabled = server.status === -1;
    return (
        <div
            style={{
                padding: "0.5rem",
                backgroundColor: "rgba(0,0,0,0.8)",
                border: "4px solid",
                borderColor: isError ? colorMap.error : isWarning ? colorMap.warning : "transparent",
                height: "100%",
                borderRadius: "8px",
                overflow: "hidden",
                position: "relative",
            }}
        >
            {/* put WarningOutlined to bottom right of div, enlarge to 64px, and position absolute, zIndex below the content, need to be hidden if it flows outside */}
            <WarningOutlined
                style={{
                    position: "absolute",
                    right: "10px",
                    bottom: "10px",
                    fontSize: "6rem",
                    zIndex: 1,
                    // color: "rgba(0,0,0,0.1)",
                    color: isError ? colorMap.error : isWarning ? colorMap.warning : "transparent",
                    opacity: 0.8,
                }}
            />
            <Space direction="vertical" size={8} style={{ zIndex: 2, position: "relative" }}>
                {server.vendor && server.groupId ? (
                    <HoverInfo title="Vendor & Group" info={`${server.vendor.toString()} - ${server.groupId.toString()}`} />
                ) : server.vendor ? (
                    <HoverInfo title="Vendor" info={server.vendor.toString()} />
                ) : server.groupId ? (
                    <HoverInfo title="Group" info={server.groupId.toString()} />
                ) : null}
                <div>
                    <HoverInfo title="Server Name" info={server.serverName.toString()} />
                    <Tag color={isError ? colorMap.error : isWarning ? colorMap.warning : isDisabled ? colorMap.disabled : colorMap.success}>
                        {isError ? "Danger" : isWarning ? "Warning" : isDisabled ? "Disabled" : "Success"}
                    </Tag>
                </div>
                {/* <HoverInfo title="Status" info={server.status === 2 ? "Error" : server.status === 1 ? "Warning" : "Good"} /> */}
                {server.message && <HoverInfo title="Message" info={server.message.toString()} />}
                {server.machineName && <HoverInfo title="Machine Name" info={server.machineName.toString()} />}
                {server.warningTimeRange && <HoverInfo title="Alarm Time" info={server.warningTimeRange + " (GMT+8)"} />}
                {server.warningWeekOfDaysStr && <HoverInfo title="Alarm Week" info={server.warningWeekOfDaysStr} />}
                {server.warningAlarm !== undefined && server.warningAlarm !== null && (
                    <HoverInfo title="Enable Alarm" info={server.warningAlarm ? "Yes" : "No"} />
                )}
                {server.enableRemoteStart !== undefined && server.enableRemoteStart !== null && (
                    <HoverInfo title="Enable Remote" info={server.enableRemoteStart ? "Yes" : "No"} />
                )}
                {server.comment !== undefined && server.comment !== null && <HoverInfo title="Notes" info={server.comment} />}
                <RerunButton server={server} />
                <HideButton server={server} />
            </Space>
        </div>
    );
};

const HideButton = ({ server }: { server: IServerDetails }) => {
    const { excludeStatus, isLoading } = useRCStatusMonitorAddExclude();

    const handleHide = async () => {
        try {
            const resp = await excludeStatus({ vendor: `${server.department}@${server.vendor}`, groupId: server.groupId, appName: server.appName });
            if (resp.data === 0) {
                notification.success({ message: `Successful excluded ${server.department}@${server.vendor}` });
            } else {
                notification.error({ message: `Failed to exclude ${server.department}@${server.vendor}` });
            }
            // console.log(resp);
        } catch (e) {
            console.error(e);
            notification.error({ message: `Failed to exclude ${server.department}@${server.vendor}` });
        }
    };
    if (
        // true // for demo
        ["RA", "Admin"].includes(server.department) &&
        server.warningAlarm === undefined &&
        server.enableRemoteStart === undefined
    ) {
        return (
            <>
                <Button
                    type="primary"
                    size="small"
                    style={{ width: "100%", marginTop: "0.5rem" }}
                    onClick={() => {
                        handleHide();
                    }}
                    disabled={isLoading}
                    icon={<DeleteOutlined />}
                >
                    Exclude
                </Button>
            </>
        );
    }
    return null;
};

export default ServerStatus;
