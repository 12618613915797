import { FileSearchOutlined, HomeOutlined, MessageOutlined } from "@ant-design/icons";
import FlexiDataTable from "../../../components/FlexiDataTable";
import {
    DTColProps,
    DataTableColumnRender,
    ErrorCatchValidator,
    ErrorMessageHandler,
    currencyRender,
    getBrandNameByBrandId,
    getServerNameByServerId,
} from "../../../utils/Common";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "../../../constants";
import { BrandsList, FlexiDataTableCallbackProps, ServersList, ThresholdObjectProps } from "../../../constants/type";
import { getThresholdMarkupValue } from "../Trade/TradeMonitoring";
import { getAppSettings } from "../../../services/localStorage";
import { useEffect, useState } from "react";
import { Breadcrumb, Button, Descriptions, Form, Modal, Typography } from "antd";
import { apiRequest } from "../../../services/apiConfig";
import { APIs } from "../../../services/apis";
import { findObjectKeys, objectRemoveProps } from "../../../utils/object";
import LoadingComponent from "../../../components/Loading";
import { Link } from "react-router-dom";
import { getNavigationUrl, ACCOUNT_PROFILE_FROM_MODULE, getAccountProfileIdByObj } from "../../Analysis/AccountProfileHelper";
import { FormComponent } from "../../../components/FormComponent";
import { REQUIRED_FIELD } from "../../../constants/errorMessage";
import { DateTimeUtil } from "../../../utils/datetime";
import EmptyData from "../../../components/Common/Empty";

export type ModalProps = {
    visible: boolean;
    isShowComment: boolean;
    isShowCommentList: boolean;
};

export interface AccountLevelViewProps {
    brandList: BrandsList[];
    serverList: ServersList[];
    data: any;
    callback: Function;
}

const AccountLevelView = (props: AccountLevelViewProps) => {
    const appSettingObj = getAppSettings();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [visibleModal, setVisibleModal] = useState<boolean>(false);
    const [visibleCommentModal, setVisibleCommentModal] = useState<boolean>(false);
    const [ticketLoading, setTicketLoading] = useState<boolean>(true);
    const [eventAccountData, setEventAccountData] = useState<any[]>([]);
    const [dynamicCols, setDynamicCols] = useState<any[]>([]);
    const [selectedTicketInfo, setSelectedTicketInfo] = useState<any>({});
    const [commentForm] = Form.useForm();

    const [tickets, setTickets] = useState<any[]>([]);

    const ticketTableColumnConfig = [
        DTColProps.Small({
            title: "Ticket",
            dataIndex: "Ticket",
            key: "Ticket",
            //sorter: (a: any, b: any) => a["Ticket"] - b["Ticket"],
            options: {
                filter: {
                    type: ComponentType.number,
                    callback: (filterValue: any, rowData: any) => rowData["Ticket"].toString().indexOf(filterValue) > -1,
                },
            },
        }),
        DTColProps.Small({
            title: "Position ID",
            dataIndex: "Position ID",
            key: "Position ID",
            sorter: (a: any, b: any) => a["Position ID"] - b["Position ID"],
            options: {
                filter: {
                    type: ComponentType.number,
                    callback: (filterValue: any, rowData: any) => rowData["Position ID"].toString().indexOf(filterValue) > -1,
                },
            },
        }),
        DTColProps.Small({
            title: "Symbol",
            dataIndex: "Symbol",
            key: "Symbol",
        }),

        DTColProps.XSmall({
            title: "Type",
            dataIndex: "Type",
            key: "Type",
        }),
        DTColProps.XSmall({
            title: "Direction",
            dataIndex: "Direction",
            key: "Direction",
        }),
        DTColProps.Small(
            {
                title: "Contract Size",
                dataIndex: "Contract Size",
                key: "Contract Size",
                sorter: (a: any, b: any) => a["Contract Size"] - b["Contract Size"],
            },
            ["text-right"]
        ),
        DTColProps.Small(
            {
                title: "Volume",
                dataIndex: "Volume",
                key: "Volume",
                sorter: (a: any, b: any) => a["Volume"] - b["Volume"],
            },
            ["text-right"]
        ),
        DTColProps.Small(
            {
                title: "Volume Closed",
                dataIndex: "Volume Closed",
                key: "Volume Closed",
                sorter: (a: any, b: any) => a["Volume Closed"] - b["Volume Closed"],
            },
            ["text-right"]
        ),
        DTColProps.Small(
            {
                title: "Lot Size",
                dataIndex: "Lot Size",
                key: "Lot Size",
                sorter: (a: any, b: any) => a["Lot Size"] - b["Lot Size"],
            },
            ["text-right"]
        ),
        DTColProps.Small(
            {
                title: "Lot Size Closed",
                dataIndex: "Lot Size Closed",
                key: "Lot Size Closed",
                sorter: (a: any, b: any) => a["Lot Size Closed"] - b["Lot Size Closed"],
            },
            ["text-right"]
        ),
        DTColProps.DateTime({
            title: "Open Time (Server)",
            dataIndex: "Open Time",
            key: "Open Time",
            sorter: false,
            render: (text: any) => DateTimeUtil.GetUTC(text, "YYYY-MM-DD HH:mm:ss"),
        }),
        DTColProps.DateTime({
            title: "Close Time (Server)",
            dataIndex: "Close Time",
            key: "Close Time",
            sorter: false,
            render: (text: any) => DateTimeUtil.GetUTC(text, "YYYY-MM-DD HH:mm:ss"),
        }),
        DTColProps.SCurrency(
            {
                title: "Open Price",
                dataIndex: "Open Price",
                key: "Open Price",
                sorter: (a: any, b: any) => a["Open Price"] - b["Open Price"],
            },
            ["text-right"],
            -1
        ),
        DTColProps.SCurrency(
            {
                title: "Close Price",
                dataIndex: "Close Price",
                key: "Close Price",
                sorter: (a: any, b: any) => a["Close Price"] - b["Close Price"],
            },
            ["text-right"],
            -1
        ),
        DTColProps.SCurrency(
            {
                title: "Stop Loss",
                dataIndex: "Stop Loss",
                key: "Stop Loss",
                sorter: (a: any, b: any) => a["Stop Loss"] - b["Stop Loss"],
            },
            ["text-right"],
            -1
        ),
        DTColProps.SCurrency(
            {
                title: "Take Profit",
                dataIndex: "Take Profit",
                key: "Take Profit",
                sorter: (a: any, b: any) => a["Take Profit"] - b["Take Profit"],
            },
            ["text-right"],
            -1
        ),
        DTColProps.SCurrency(
            {
                title: "Profit",
                dataIndex: "Profit",
                key: "Profit",
                sorter: (a: any, b: any) => a["Profit"] - b["Profit"],
            },
            ["text-right"],
            -1
        ),
        DTColProps.SCurrency(
            {
                title: "Commission",
                dataIndex: "Commission",
                key: "Commission",
                sorter: (a: any, b: any) => a["Commission"] - b["Commission"],
            },
            ["text-right"],
            -1
        ),
        DTColProps.SCurrency(
            {
                title: "Swaps",
                dataIndex: "Swaps",
                key: "Swaps",
                sorter: (a: any, b: any) => a["Swaps"] - b["Swaps"],
            },
            ["text-right"],
            -1
        ),
        DTColProps.SCurrency(
            {
                title: "Taxes",
                dataIndex: "Taxes",
                key: "Taxes",
                sorter: (a: any, b: any) => a["Taxes"] - b["Taxes"],
            },
            ["text-right"],
            -1
        ),

        DTColProps.SCurrency(
            {
                title: "Fee",
                dataIndex: "Fee",
                key: "Fee",
                sorter: (a: any, b: any) => a["Fee"] - b["Fee"],
            },
            ["text-right"],
            -1
        ),
        DTColProps.SCurrency(
            {
                title: "P&L",
                dataIndex: "P&L",
                key: "P&L",
                sorter: (a: any, b: any) => a["P&L"] - b["P&L"],
            },
            ["text-right"],
            -1
        ),
        DTColProps.Middle({
            title: "Holding Duration",
            dataIndex: "Holding Duration",
            key: "Holding Duration",
            render: (text: any) => {
                try {
                    let tmp: any = typeof text === "string" ? JSON.parse(text) : text;
                    if (Array.isArray(tmp)) {
                        return (
                            <Descriptions column={1} size="small" className="tiny-description">
                                {(tmp as any[]).map((x) => (
                                    <Descriptions.Item key={`ti-${Math.random()}`}>{`${x["Holding Time"]} (${x["Ticket"]})`}</Descriptions.Item>
                                ))}
                            </Descriptions>
                        );
                    } else {
                        return tmp["Holding Time"];
                    }
                } catch (error) { }
                return text;
            },
        }),
    ];

    const getAccountHeaderInfo = (record: any) => {
        return findObjectKeys(record, ["Account ID", "Server"]) ? (
            <div className="historical-event-summary-account-info">
                <span className="title">Account: </span>
                <Link
                    target="_blank"
                    to={getNavigationUrl(
                        ACCOUNT_PROFILE_FROM_MODULE.HISTORICAL_EVENT_LIST,
                        getAccountProfileIdByObj({
                            accountId: record["Account ID"],
                            serverId: record["Server ID"],
                            fromModule: ACCOUNT_PROFILE_FROM_MODULE.HISTORICAL_EVENT_LIST,
                            viewReportId: "",
                        })
                    )}
                    style={{ color: "#0e65cc" }}
                >
                    {record["Account ID"]}
                </Link>

                <span className="title">Brand: </span>
                {record["Brand"]}
                <span className="title">Server: </span>
                {record["Server"]}
            </div>
        ) : (
            false
        );
    };

    const getTicketList = (ticketGUID: string, record: any) => {
        setSelectedTicketInfo(record);
        setVisibleModal(true);
        setTicketLoading(true);
        apiRequest(APIs.GET_MONITOR_PROFILE_TICKETS, { ticketBatchId: ticketGUID })
            .then((res: any) => {
                setTickets(res);
            })
            .catch((error) => ErrorCatchValidator(error, (err: any) => { }))
            .finally(() => setTicketLoading(false));
    };

    const getAccountColumnConfig = (record: any) => {
        return [
            DTColProps.Middle({
                title: "Event Batch ID",
                dataIndex: "eventBatchId",
                key: "eventBatchId",
                options: {
                    filter: {
                        type: ComponentType.text,
                        value: "",
                    },
                },
            }),
            DTColProps.Large({
                title: "Threshold",
                dataIndex: "threshold",
                key: "threshold",
                render: (name: any) => (
                    <div className={""}>
                        {name
                            .filter((x: ThresholdObjectProps) => (x.editable === undefined ? true : x.editable))
                            .filter((x: ThresholdObjectProps) => x.appSettingValueProps === undefined)
                            .map((x: ThresholdObjectProps, idx: number) => (
                                <div key={`pevevk-threshold-${idx}`} className="chart-threshold-item">
                                    <span>{x["label"]} : </span>
                                    <span>{getThresholdMarkupValue(appSettingObj, name, x)}</span>
                                </div>
                            ))}
                    </div>
                ),
            }),
            DTColProps.DateTime_ServerTime({
                title: <>Triggered Time (Server)</>,
                realTitle: "Triggered Time (Server)",
                dataIndex: "capturedTime",
                key: "capturedTime",
                sorter: (a: any, b: any, c: string) => {
                    if (c === "ascend") {
                        if (a.capturedTime === "") return 1;
                        if (b.capturedTime === "") return -1;
                    } else {
                        if (a.capturedTime === "") return -1;
                    }
                    return new Date(a.capturedTime).getTime() - new Date(b.capturedTime).getTime(); //* -1;
                },
                render: (value: string) => (value === "" ? "-" : DateTimeUtil.GetUTC(value.replace("Z", ""), "YYYY-MM-DD HH:mm:ss")),
            }),
            ...Object.keys(
                objectRemoveProps(record, ["eventBatchId", "threshold", "capturedTime", "comment", "id", "lastUpdatedBy", "lastUpdatedDateUtc"])
            ).map((x) => {
                let tmp: any = {
                    title: x,
                    dataIndex: x,
                    key: x,
                    ...(typeof record[x] === "number"
                        ? {
                            render: (a: any) => currencyRender(a),
                            sorter: (a: any, b: any) => a[x] - b[x],
                        }
                        : x.indexOf("%") > -1 && {
                            sorter: (a: any, b: any) => a[x].replace("%", "") - b[x].replace("%", ""),
                        }),
                    ...(x.toUpperCase().indexOf("TICKET BATCH") > -1 && {
                        render: (text: string, record: any) => (
                            <Button type="text" icon={<FileSearchOutlined />} onClick={() => getTicketList(text, record)} />
                        ),
                    }),
                };

                return x.toUpperCase().indexOf("TICKET BATCH") > -1 ? DTColProps.Small(tmp) : DTColProps.Middle(tmp);
            }),
            DTColProps.Large({
                title: "Comment",
                dataIndex: "comment",
                key: "comment",
                options: {
                    filter: {
                        type: ComponentType.text,
                        value: "",
                    },
                },
                render: (comment: string, record: any) => {
                    return comment && comment.length > 0 ? (
                        <div className="mini-comment-container">
                            <div className="comments">
                                <Typography.Paragraph ellipsis={{ rows: 2 }} style={{ marginBottom: "0" }}>
                                    {comment}
                                </Typography.Paragraph>
                            </div>
                            <div className="info">{`${DataTableColumnRender.DateTime(record["lastUpdatedDateUtc"])} - ${record["lastUpdatedBy"]
                                }`}</div>
                        </div>
                    ) : (
                        "-"
                    );

                    // <Comment
                    //     className="custom-comment"
                    //     author={record.lastUpdatedBy}
                    //     content={
                    //         record.comment && record.comment.length > 0 ? (
                    //             <Paragraph ellipsis={{ rows: 4, expandable: true, symbol: "more" }}>{record.comment}</Paragraph>
                    //         ) : (
                    //             <></>
                    //         )
                    //     }
                    //     datetime={` - ${DataTableColumnRender.DateTime(record.lastUpdatedDateUtc)}`}
                    // />
                },
            }),
        ];
    };

    const getHistoricalEventByAccount = (event: any) => {
        setIsLoading(true);
        apiRequest(APIs.GET_MONITOR_HISTORICAL_BY_ACCOUNT, { ...event })
            .then((res: any) => {
                if (res && res.length > 0) {
                    let newObj = res.map((x: any) => ({ ...objectRemoveProps(x, ["event"]), ...x["event"] }));
                    setEventAccountData(newObj);
                    setDynamicCols(getAccountColumnConfig(newObj[0]));
                } else {
                    setEventAccountData([]);
                }
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) =>
                    ErrorMessageHandler("Historical Event - Captured Event Listing", SUCCESS_FAILED.FAILED_LOAD_DATA, err)
                );
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK:
                if (FormData.key === "accountComment") {
                    let tmp: any = FormData.data;
                    commentForm.setFieldsValue({ comment: tmp.comment, id: tmp.id });
                    setVisibleCommentModal(true);
                }
                break;
        }
    };

    const updateAccountEventComment = (values: any) => {
        apiRequest(APIs.UPDATE_MONITOR_ACCOUNT_EVENT_COMMENT, {
            accountEventId: values.id,
            comment: values.comment,
        })
            .then(() => {
                ErrorMessageHandler("Comment", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                commentForm.setFieldsValue({ comment: "", id: "" });
                getHistoricalEventByAccount(props.data);
            })
            .catch((error) => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("comment", SUCCESS_FAILED.FAILED_UPDATE_DATA, err)));
    };

    useEffect(() => {
        getHistoricalEventByAccount(props.data);
        return () => { };
    }, []);

    return (
        <>
            {isLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Loading..." />
                </div>
            ) : (
                <div className="historical-account-level-container">
                    <Breadcrumb>
                        <Breadcrumb.Item href="#" onClick={() => props.callback && props.callback(CALLBACK_KEY.BACK_TO_PREVIOUS, props.data)}>
                            <HomeOutlined />
                            <span>Historical Event List</span>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>Account Level View</Breadcrumb.Item>
                    </Breadcrumb>
                    <div>
                        <div className="event-desc-container">
                            <span>Date : </span>
                            {(props.data["eventDate"] || "").split("T")[0]}
                            <br />
                            <span>Account ID : </span>
                            {props.data["accountId"]}
                            <br />
                            <span>Brand : </span>
                            {getBrandNameByBrandId(props.data["brandId"], props.brandList)}
                            <br />
                            <span>Server : </span>
                            {getServerNameByServerId(props.data["serverId"], props.serverList)}
                        </div>
                        <FlexiDataTable
                            rowKeyProperty="id"
                            title={"Captured Event Listing"}
                            columns={dynamicCols}
                            options={{
                                enableFilter: false,
                                separateActionButton: true,
                                rowExtra: () => {
                                    return [{ text: "", icon: <MessageOutlined />, value: "accountComment" }];
                                },
                            }}
                            callback={componentCallback}
                            dataSource={eventAccountData || []}
                            loading={false}
                        />
                    </div>
                </div>
            )}

            <Modal
                width={"90%"}
                destroyOnClose
                title={"Tickets"}
                open={visibleModal}
                cancelText={"Close"}
                okButtonProps={{
                    style: {
                        display: "none",
                    },
                }}
                onCancel={() => {
                    setVisibleModal(false);
                    setTicketLoading(true);
                }}
            >
                {ticketLoading ? (
                    <div className="loading-container">
                        <LoadingComponent tip="Loading..." />
                    </div>
                ) : tickets.length === 0 ? (
                    <EmptyData />
                ) : (
                    <>
                        <FlexiDataTable
                            rowKeyProperty="Ticket"
                            title={getAccountHeaderInfo(selectedTicketInfo)}
                            columns={ticketTableColumnConfig}
                            options={{ enableFilter: false }}
                            pagination={{ defaultPageSize: 100 }}
                            dataSource={tickets}
                            loading={false}
                            callback={componentCallback}
                        />
                    </>
                )}
            </Modal>

            <Modal
                width={"30%"}
                destroyOnClose
                maskClosable={false}
                title={"Comments"}
                open={visibleCommentModal}
                onOk={() => {
                    commentForm.validateFields().then((values) => {
                        updateAccountEventComment(values);
                        setVisibleCommentModal(false);
                    });
                }}
                onCancel={() => {
                    commentForm.resetFields();
                    setVisibleCommentModal(false);
                }}
            >
                <Form form={commentForm} layout="horizontal">
                    <FormComponent
                        label=""
                        name={"id"}
                        extra={{
                            type: ComponentType.hidden,
                            value: "",
                        }}
                    />
                    <FormComponent
                        label=""
                        name={"comment"}
                        extra={{
                            type: ComponentType.textarea,
                            value: "",
                            inputProps: {
                                placeholder: "Enter your comment here...",
                            },
                            rules: [{ required: true, message: REQUIRED_FIELD }],
                        }}
                    />
                </Form>
            </Modal>
        </>
    );
};

export default AccountLevelView;
