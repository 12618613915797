import { Input, Tabs } from "antd";
import MTServerHubConfiguration from "../../SiteAdminModule/Configuration/MTServerHubConfiguration";
import { SearchOutlined } from "@ant-design/icons";
import { useMemo, useState } from "react";
import SegmentOptionSelect from "../../../components/Common/SegmentOptionSelector";
import SecurityGroupListing from "./SecurityGroup";
import ServerGroupListing from "./ServerGroup/serverGroupListing";
import { InnerPageActionMode } from "../../../constants";
import DashboardConfigurationPage from "./DashboardConfiguration";

export interface HubConfigurationPageProps {}

export interface CreateEditFormMode {
    mode: InnerPageActionMode;
    groupId?: number;
}

const HubConfigurationPage = (props: HubConfigurationPageProps) => {
    const tabItems = useMemo(() => {
        return [
            {
                label: `Server Group Listing`,
                key: "1",
                children: <ServerGroupListing />,
            },
            {
                label: `Security Group Listing`,
                key: "2",
                children: <SecurityGroupListing />,
            },
            {
                label: `Hub Status Dashboard Configuration`,
                key: "3",
                children: <DashboardConfigurationPage />,
            },
            {
                label: `MT Server Configuration`,
                key: "4",
                children: (
                    <div className="hub-config-main-content shadow-light">
                        <MTServerHubConfiguration />
                    </div>
                ),
            },
        ];
    }, []);

    return (
        <>
            <Tabs type="card" defaultActiveKey="1" destroyInactiveTabPane tabBarStyle={{ marginBottom: "0" }} items={tabItems} />
        </>
    );
};

export default HubConfigurationPage;
