import React, { useEffect, useState } from "react";
import PriceMonitorBreadCrumbs from "../components/PriceMonitorBreadCrumbs";
import { Button, Space, Tabs } from "antd";
import PriceOutageTimeline from "./PriceOutageTimeline";
import PriceOutageDataFetcher from "./PriceOutageDataFetcher";
import useRCPriceOutageStore from "../../../store/useRCPriceOutageStore";
import PriceOutageSummary from "./PriceOutageSummary";
import MessageCard from "@/pages/SystemMonitor/components/MessageCard";
import PriceOutageTable from "./PriceOutageTable";
import TabContent from "../../SystemMonitor/components/TabContent";

import TabPriceOutageReports from "./TabPriceOutageReports";
import useRCPriceOutage from "@/hooks/useRCPriceOutage";
import PriceOutageDowntimeChart from "./PriceOutageDowntimeChart";
import Concept from "./settings/Concept";
const PriceOutage = () => {
    const [currTab, setCurrTab] = useState<string | null>(null);
    // Ensure user can change tab by URL params
    useEffect(() => {
        const currTabParam = new URLSearchParams(window.location.search).get("tab");
        if (currTabParam) {
            setCurrTab(currTabParam);
        } else {
            setCurrTab("prod");
        }
    }, []);

    // Handle popstate event
    useEffect(() => {
        const handlePopState = () => {
            const currTabParam = new URLSearchParams(window.location.search).get("tab");
            if (currTabParam) {
                setCurrTab(currTabParam);
            } else {
                setCurrTab("prod");
            }
        };

        window.addEventListener("popstate", handlePopState);

        return () => {
            window.removeEventListener("popstate", handlePopState);
        };
    }, []);
    const handleChangeTab = (activeKey: string) => {
        setCurrTab(activeKey);
        window.history.pushState({}, "", `${window.location.pathname}?tab=${activeKey}`);
    };
    if (!currTab) return null;

    return (
        <>
            <PriceMonitorBreadCrumbs currMenu="Price Outage" />
            <PriceOutageDataFetcher />
            <Tabs
                activeKey={currTab}
                onChange={handleChangeTab}
                type="card"
                style={{ marginBottom: 0 }}
                items={[
                    {
                        label: "Production",
                        key: "prod",
                    },
                    {
                        label: "Others",
                        key: "others",
                    },
                    {
                        label: "Reports",
                        key: "reports",
                    },
                    {
                        label: "Settings (demo only)",
                        key: "settings",
                    },
                ]}
                tabBarStyle={{ marginBottom: 0 }}
            />
            <TabContent>
                <Space direction="vertical" size={4} style={{ width: "100%", padding: "1rem" }}>
                    {currTab === "prod" && (
                        <div key="prod">
                            <ProdComponentWrapper />

                            <ProdChartWrapper />
                        </div>
                    )}
                    {currTab === "others" && (
                        <div key="others">
                            <OthersComponentWrapper />
                            <OthersChartWrapper />
                        </div>
                    )}
                    {currTab === "reports" && <TabPriceOutageReports />}
                    {currTab === "settings" && <Concept />}
                </Space>
            </TabContent>
        </>
    );
};

const ProdComponentWrapper = () => {
    const hourlyData = useRCPriceOutageStore(state => state.hourlyData); // do not remove, use to refresh data
    const selectedTimeStamp = useRCPriceOutageStore(state => state.selectedProdTimeStamp);
    const setSelectedTimeStamp = useRCPriceOutageStore(state => state.setSelectedProdTimeStamp);
    // const getHourlyStats = useRCPriceOutageStore(state => state.getHourlyStats);
    // const hourlyStats = getHourlyStats();
    const hourlyStats = useRCPriceOutageStore(state => state.hourlyStats);
    const selectedProdStats = useRCPriceOutageStore(state => state.selectedProdStats);
    const selectedProdRawData = useRCPriceOutageStore(state => state.selectedProdRawData);
    const { refetchRcPriceOutage, isFetching, dataUpdatedAt } = useRCPriceOutage({ type: "prod" });
    const historyData = useRCPriceOutageStore(state => state.historyRawDataProd);
    const currentRawData = useRCPriceOutageStore(state => state.currentRawDataProd);
    const latestProdStats = useRCPriceOutageStore(state => state.latestProdStats);
    const latestProdData = useRCPriceOutageStore(state => state.latestProdData);

    // reset to latest while switching tab
    useEffect(() => {
        let isSub = true;
        if (isSub) {
            setSelectedTimeStamp(null);
        }
        return () => {
            isSub = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // console.log("hourlyStats", hourlyStats);
    return (
        <>
            {hourlyStats?.length > 0 && (
                <Space direction="vertical" size={4} style={{ width: "100%" }}>
                    <PriceOutageTimeline
                        data={hourlyStats}
                        selectedData={selectedTimeStamp}
                        setSelectedData={setSelectedTimeStamp}
                        refetch={refetchRcPriceOutage}
                        isFetching={isFetching}
                        dataUpdatedAt={dataUpdatedAt}
                        latestData={latestProdStats}
                        latestRawData={latestProdData}
                    />
                    <PriceOutageDowntimeChart data={historyData || []} dataCurrent={currentRawData || []} />
                    <PriceOutageSummary
                        rawData={hourlyData}
                        selectedData={selectedTimeStamp}
                        summarized={hourlyStats}
                        setSelectedData={setSelectedTimeStamp}
                        finalRawStats={selectedTimeStamp === null ? latestProdStats : selectedProdStats}
                        finalRawData={selectedTimeStamp === null ? latestProdData : selectedProdRawData}
                    />
                </Space>
            )}
        </>
    );
};

const ProdChartWrapper = () => {
    const { isLoading, refetchRcPriceOutage, isFetching } = useRCPriceOutage({ type: "prod" });
    const selectedProdRawData = useRCPriceOutageStore(state => state.selectedProdRawData);
    const selectedTimeStamp = useRCPriceOutageStore(state => state.selectedProdTimeStamp);
    const latestProdData = useRCPriceOutageStore(state => state.latestProdData);
    if (isLoading) {
        return (
            <div style={{ marginTop: 4, display: "flex", justifyContent: "center" }}>
                <MessageCard size="small" type="info">
                    Loading...
                </MessageCard>
            </div>
        );
    }
    if (
        isLoading ||
        (selectedTimeStamp !== null
            ? selectedProdRawData === null || selectedProdRawData.length === 0
            : latestProdData === null || latestProdData.length === 0)
    ) {
        return (
            <div style={{ marginTop: 4, display: "flex", justifyContent: "center" }}>
                <MessageCard size="small" type="success">
                    No price outage for the selected time
                    {selectedTimeStamp === null ? (
                        <>
                            <br />

                            <Button
                                size="small"
                                type="primary"
                                disabled={isFetching || isLoading}
                                onClick={() => {
                                    refetchRcPriceOutage();
                                }}
                            >
                                {isFetching || isLoading ? "Refetching..." : "Refetch to double confirm"}
                            </Button>
                        </>
                    ) : null}
                </MessageCard>
            </div>
        );
    }
    return (
        <Space direction="vertical" size={4} style={{ width: "100%" }}>
            <PriceOutageTable data={selectedTimeStamp === null ? latestProdData : selectedProdRawData || []} />
        </Space>
    );
};
const OthersComponentWrapper = () => {
    const hourlyData = useRCPriceOutageStore(state => state.hourlyDataNonProd); // do not remove, use to refresh data
    const selectedTimeStamp = useRCPriceOutageStore(state => state.selectedNonProdTimeStamp);
    const setSelectedTimeStamp = useRCPriceOutageStore(state => state.setSelectedNonProdTimeStamp);
    const getHourlyStats = useRCPriceOutageStore(state => state.getHourlyStatsNonProd);
    const hourlyStats = getHourlyStats();
    const selectedProdStats = useRCPriceOutageStore(state => state.selectedNonProdStats);
    const selectedProdRawData = useRCPriceOutageStore(state => state.selectedNonProdRawData);
    const { refetchRcPriceOutage, isFetching, dataUpdatedAt } = useRCPriceOutage({ type: "others" });
    const historyData = useRCPriceOutageStore(state => state.historyRawDataNonProd);
    const currentRawData = useRCPriceOutageStore(state => state.currentRawDataNonProd);
    const latestNonProdStats = useRCPriceOutageStore(state => state.latestNonProdStats);
    const latestNonProdData = useRCPriceOutageStore(state => state.latestNonProdData);

    useEffect(() => {
        let isSub = true;
        if (isSub) {
            setSelectedTimeStamp(null);
        }
        return () => {
            isSub = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {hourlyStats?.length > 0 && (
                <Space direction="vertical" size={4} style={{ width: "100%" }}>
                    <PriceOutageTimeline
                        data={hourlyStats}
                        selectedData={selectedTimeStamp}
                        setSelectedData={setSelectedTimeStamp}
                        refetch={refetchRcPriceOutage}
                        isFetching={isFetching}
                        dataUpdatedAt={dataUpdatedAt}
                        latestData={latestNonProdStats}
                        latestRawData={latestNonProdData}
                    />
                    <PriceOutageDowntimeChart data={historyData || []} dataCurrent={currentRawData || []} />
                    <PriceOutageSummary
                        rawData={hourlyData}
                        selectedData={selectedTimeStamp}
                        summarized={hourlyStats}
                        setSelectedData={setSelectedTimeStamp}
                        finalRawStats={selectedTimeStamp === null ? latestNonProdStats : selectedProdStats}
                        finalRawData={selectedTimeStamp === null ? latestNonProdData : selectedProdRawData}
                    />
                </Space>
            )}
        </>
    );
};

const OthersChartWrapper = () => {
    const { isLoading, isFetching, refetchRcPriceOutage } = useRCPriceOutage({ type: "others" });
    const selectedProdRawData = useRCPriceOutageStore(state => state.selectedNonProdRawData);
    const selectedTimeStamp = useRCPriceOutageStore(state => state.selectedNonProdTimeStamp);
    const latestProdData = useRCPriceOutageStore(state => state.latestNonProdData);
    if (isLoading) {
        return (
            <div style={{ marginTop: 4, display: "flex", justifyContent: "center" }}>
                <MessageCard size="small" type="info">
                    Loading...
                </MessageCard>
            </div>
        );
    }
    if (
        isLoading ||
        (selectedTimeStamp !== null
            ? selectedProdRawData === null || selectedProdRawData.length === 0
            : latestProdData === null || latestProdData.length === 0)
    ) {
        return (
            <div style={{ marginTop: 4, display: "flex", justifyContent: "center" }}>
                <MessageCard size="small" type="success">
                    No price outage for the selected time
                    {selectedTimeStamp === null ? (
                        <>
                            <br />
                            <Button
                                size="small"
                                type="primary"
                                disabled={isFetching || isLoading}
                                onClick={() => {
                                    refetchRcPriceOutage();
                                }}
                            >
                                {!isFetching || !isLoading ? "Refetch to double confirm" : "Refetching..."}
                            </Button>
                        </>
                    ) : null}
                </MessageCard>
            </div>
        );
    }
    return (
        <Space direction="vertical" size={4} style={{ width: "100%" }}>
            <PriceOutageTable data={selectedTimeStamp === null ? latestProdData : selectedProdRawData || []} />
        </Space>
    );
};

export default PriceOutage;
