import { Modal, Form } from "antd";
import { FormComponent } from "../../../../../components/FormComponent";
import { ComponentType } from "../../../../../constants";
import { REQUIRED_FIELD } from "../../../../../constants/errorMessage";
import { useEffect } from "react";
import { DefaultIfEmpty } from "../../../../../utils/object";

export interface AddNoteModalProps {
    selectedKeys: string[];
    isModalVisible: boolean;
    callback: (type: number, data: any) => void;
}

export enum AddNoteModalCallbackKey {
    Close = 0,
    FormSubmit = 1,
}

const AddNoteModal = (props: AddNoteModalProps) => {
    const [executeForm] = Form.useForm();

    useEffect(() => {
        if (props.isModalVisible) {
            executeForm.setFieldsValue({ diffIds: props.selectedKeys, reason: "" });
        }
    }, [props.isModalVisible, props.selectedKeys]);

    return (
        <Modal
            style={{ top: 10 }}
            width={"40vw"}
            title={"Add New Reason"}
            open={props.isModalVisible}
            okText="Submit"
            onOk={() => {
                executeForm
                    .validateFields()
                    .then((values: any) => {
                        props.callback(
                            AddNoteModalCallbackKey.FormSubmit,
                            DefaultIfEmpty(values, "diffIds", []).map((x: string) => ({ diffId: x, note: values.reason }))
                        );
                    })
                    .catch((err: any) => console.log(err));
            }}
            onCancel={() => props.callback(AddNoteModalCallbackKey.Close, null)}
            maskClosable={false}
        >
            <Form form={executeForm} layout="horizontal" labelCol={{ span: 7 }} wrapperCol={{ span: 14 }}>
                <FormComponent label="" name="diffIds" extra={{ type: ComponentType.hidden, value: [] }} />
                <FormComponent
                    label="Reason"
                    name="reason"
                    extra={{
                        type: ComponentType.text,
                        value: "",
                        rules: [{ required: true, message: REQUIRED_FIELD }],
                    }}
                />
            </Form>
        </Modal>
    );
};

export default AddNoteModal;
