import { Form, FormInstance } from "antd";
import { KeyValuePair, LPDimensionFiltersProps } from "../../../../constants/type";
import LPFilterUpdateTemplate from "./LPFilterUpdateTemplate";

export interface LPAlarmFilterPanelProps {
    form: FormInstance;
    options: LPAlarmFilterOptionType;
    resetState: number;
}

export type LPAlarmFilterOptionType = { [key: string]: LPItemProps };

export interface LPItemProps {
    DimensionLevel: string;
    title: string;
    componentType: string;
    options: KeyValuePair[];
}

const LPAlarmFilterPanel = (props: LPAlarmFilterPanelProps) => {
    const fieldName = "DimensionFilters";
    return (
        <>
            <div className="filter-panel">
                <div className="title-panel">
                    <span>Dimension Filter:</span>
                </div>
                <div className="content">
                    <Form.List name={fieldName}>
                        {fields =>
                            fields.map((field, index) => {
                                let currentProps: LPDimensionFiltersProps = props.form.getFieldValue([fieldName, field.name]);
                                return (
                                    <LPFilterUpdateTemplate
                                        key={`im-fp-${field.key}-${index}`}
                                        form={props.form}
                                        parentFieldName={fieldName}
                                        componentId={field.name}
                                        settings={props.options[currentProps.Dimension]}
                                        resetState={props.resetState}
                                    />
                                );
                            })
                        }
                    </Form.List>
                </div>
            </div>
        </>
    );
};

export default LPAlarmFilterPanel;
