import { useEffect, useMemo, useState } from "react";
import FlexiDataTable from "@/components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "@/constants";
import { FlexiDataTableCallbackProps, FlexiDataTableOptionsProps, KeyValuePair } from "@/constants/type";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { Button, Form, message, Modal, Tooltip } from "antd";
import ViewHistoryModal from "./components/ViewHistoryModal";
import { CidLabelSettingsList, DailyReportData } from "./components/type";
import { CheckCircleOutlined, PlusOutlined, UserAddOutlined } from "@ant-design/icons";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { APIs } from "@/services/apis";
import moment from "moment";
import { DateTimeUtil } from "@/utils/datetime";

interface DailyReportProps {
    servers: KeyValuePair[];
    labels: CidLabelSettingsList[];
}

const DailyReport = (props: DailyReportProps) => {
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.CID_EDIT);

    const [data, setData] = useState<DailyReportData[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isExporting, setIsExporting] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [cidHistoryForm] = Form.useForm();
    const [maxReportDate, setMaxReportDate] = useState<string>("");
    const labels = useMemo(() => props.labels, [props.labels]);
    const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(true);

    const columns = [
        DTColProps.Middle({
            title: "CID",
            dataIndex: "cid",
            key: "cid",
            fixed: "left",
            sorter: (a: any, b: any) => a.cid.localeCompare(b.cid),
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.XSmall({
            title: "Report Date",
            dataIndex: "reportDate",
            key: "reportDate",
            sorter: (a: any, b: any) => a.reportDate.localeCompare(b.reportDate),
            options: {
                filter: {
                    type: ComponentType.daterange,
                    value: "",
                    dateFormat: "YYYY-MM-DD",
                    inputProps: {
                        showTime: false,
                        dateOnly: true,
                    },
                    callback: (filterValues: any, rowData: DailyReportData) => {
                        if (filterValues.length === 0) return true;
                        else {
                            const filterStartDate = moment(filterValues[0] as moment.Moment)
                                .startOf("day")
                                .format("YYYY-MM-DD");
                            const filterEndDate = moment(filterValues[1] as moment.Moment)
                                .startOf("day")
                                .format("YYYY-MM-DD");
                            return filterStartDate <= rowData["reportDate"] && rowData["reportDate"] <= filterEndDate;
                        }
                    },
                },
            },
        }),
        DTColProps.Small({
            title: "Journal Date",
            dataIndex: "showJournalDate",
            key: "showJournalDate",
            sorter: (a: any, b: any) => a.showJournalDate.localeCompare(b.showJournalDate),
            options: {
                filter: {
                    type: ComponentType.daterange,
                    value: "",
                    dateFormat: "YYYY-MM-DD",
                    inputProps: {
                        showTime: false,
                        dateOnly: true,
                    },
                    callback: (filterValues: any, rowData: DailyReportData) => {
                        if (filterValues.length === 0) return true;
                        else {
                            const filterStartDate = moment(filterValues[0] as moment.Moment)
                                .startOf("day")
                                .format("YYYY-MM-DD");
                            const filterEndDate = moment(filterValues[1] as moment.Moment)
                                .startOf("day")
                                .format("YYYY-MM-DD");
                            return filterStartDate <= (rowData["showJournalDate"] ?? "") && (rowData["showJournalDate"] ?? "") <= filterEndDate;
                        }
                    },
                },
            },
        }),
        DTColProps.Small({
            title: "Server",
            dataIndex: "mainServerId",
            key: "mainServerId",
            sorter: (a: any, b: any) => a.mainServerId.localeCompare(b.mainServerId),
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.Small({
            title: "Login",
            dataIndex: "login",
            key: "login",
            sorter: (a: any, b: any) => a.login - b.login,
            options: {
                filter: {
                    type: ComponentType.number,
                    value: "",
                },
            },
        }),
        DTColProps.Large({
            title: "Label",
            dataIndex: "labelName",
            key: "labelName",
            sorter: (a: any, b: any) => a.labelName.localeCompare(b.labelName),
            render: (labelName: string) => labelName.replace(/,/g, ", "),
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.XXSmall(
            {
                title: "Is Added",
                dataIndex: "added",
                key: "added",
                sorter: (a: any, b: any) => a.added - b.added,
                render: (added: number) => (added === 0 ? <></> : <CheckCircleOutlined style={{ color: "#52C41A", fontSize: "1.25rem" }} />),
            },
            ["text-center"]
        ),
    ];

    const options: FlexiDataTableOptionsProps = useMemo(
        () => ({
            export: { text: "Download" },
            separateActionButton: true,
            ...(enableUpdate && {
                customExtraActionButton: (record: DailyReportData, callback: FlexiDataTableCallbackProps) => {
                    if (record.added === 0)
                        return (
                            <>
                                <Tooltip title="Add CID history">
                                    <Button
                                        type="text"
                                        icon={<PlusOutlined />}
                                        onClick={e => {
                                            e.preventDefault();
                                            callback(CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK, { key: "add", data: record });
                                        }}
                                    />
                                </Tooltip>
                                <Tooltip title="Add to whitelist">
                                    <Button
                                        type="text"
                                        icon={<UserAddOutlined />}
                                        onClick={e => {
                                            e.preventDefault();
                                            callback(CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK, { key: "whitelist", data: record });
                                        }}
                                    />
                                </Tooltip>
                            </>
                        );
                    else return undefined;
                },
            }),
        }),
        [enableUpdate]
    );

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.EXPORT_CSV_EXCEL:
                setIsExporting(true);
                plainAxiosInstance
                    .get(`${APIs.RC_CID.GET_DAILY_REPORT_DOWNLOAD}?reportDate=${moment().format("YYYYMMDD")}`, {
                        headers: { Accept: "application/octet-stream, */*" },
                        responseType: "blob",
                    })
                    .then(res => {
                        const fileName = `Cid_daily_report-${moment().format("YYYYMMDD_HHmmss")}.xlsx`;
                        const contentType = res.headers["content-type"];
                        if (
                            contentType === "application/octet-stream" ||
                            contentType === "text/csv" ||
                            contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        ) {
                            // Handle the file download response
                            const url = window.URL.createObjectURL(new Blob([res.data]));
                            const link = document.createElement("a");
                            link.href = url;
                            link.setAttribute("download", `${fileName}`); // or any other extension
                            link.setAttribute("type", "hidden");
                            document.body.appendChild(link);
                            link.click();
                            if (link.parentNode) {
                                link.parentNode.removeChild(link); // Clean up and remove the link
                            } else document.body.removeChild(link);
                            // Clean up
                            window.URL.revokeObjectURL(url);
                        } else {
                            message.error(`Received non-file response. Error: ${res}`, 3);
                        }
                    })
                    .catch(err => {
                        message.error(`Download error: ${err}`, 3);
                    })
                    .finally(() => setIsExporting(false));
                break;
            case CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK:
                let tmp: any = FormData.data;
                if (FormData.key === "add") {
                    let labelNameArr = tmp.labelName.split(",");
                    let labelIdArr: any[] = [];
                    labelNameArr.forEach((x: string) => {
                        let label = labels.find((y: CidLabelSettingsList) => y.labelName === x);
                        if (label) labelIdArr.push(label.labelId);
                    });
                    cidHistoryForm.setFieldsValue({
                        cid: tmp.cid,
                        mainServerId: tmp.mainServerId,
                        login: tmp.login,
                        reportDate: tmp.reportDate,
                        index: tmp.index,
                        labelId: labelIdArr,
                    });
                    setIsModalOpen(true);
                } else if (FormData.key === "whitelist") {
                    Modal.confirm({
                        title: "Are you sure you want to add into Whitelist Settings?",
                        content: (
                            <>
                                <p>Server: {tmp.mainServerId}</p>
                                <p>Login: {tmp.login}</p>
                            </>
                        ),
                        width: "30%",
                        okText: "Confirm",
                        onOk: () => addToWhitelistSetting(tmp),
                    });
                }
                break;
            default:
                break;
        }
    };

    const onFormSubmit = (obj: any) => {
        let promises = [];
        promises.push(
            plainAxiosInstance.post(`${APIs.RC_CID.POST_UPDATE_CID_HISTORY}`, {
                ...obj,
                labelId: obj.labelId.join(","),
                updateDate: DateTimeUtil.GetOrigin(obj.updateDate),
            })
        );
        promises.push(
            plainAxiosInstance.post(`${APIs.RC_CID.POST_ADD_CID_HISTORY_UPDATE}`, {
                ...obj,
                labelId: obj.labelId.join(","),
                updateDate: DateTimeUtil.GetOrigin(obj.updateDate),
            })
        );

        setIsBtnLoading(true);
        Promise.all(promises)
            .then((res: any) => {
                if (res[0].data > 0 && res[1].data > 0) {
                    ErrorMessageHandler("CID history", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                    setIsModalOpen(false);
                    setRunRefetchDataList(true);
                    cidHistoryForm.resetFields();
                } else {
                    message.error("Failed to add into CID history", 3);
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => {
                    ErrorMessageHandler("CID history", SUCCESS_FAILED.FAILED_UPDATE_DATA, err);
                })
            )
            .finally(() => setIsBtnLoading(false));
    };

    const addToWhitelistSetting = (obj: any) => {
        let promises = [];
        promises.push(plainAxiosInstance.post(`${APIs.RC_CID.POST_CREATE_WHITELIST_SETTING}`, obj));
        promises.push(plainAxiosInstance.post(`${APIs.RC_CID.POST_ADD_CID_HISTORY_UPDATE}`, obj));

        setIsLoading(true);
        Promise.all(promises)
            .then((res: any) => {
                ErrorMessageHandler("Whitelist setting", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                setRunRefetchDataList(true);
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => {
                    ErrorMessageHandler("whitelist setting", SUCCESS_FAILED.FAILED_CREATE_DATA, err);
                    setIsLoading(false);
                })
            );
    };

    const getDailyReportData = () => {
        plainAxiosInstance
            .get(`${APIs.RC_CID.GET_DAILY_REPORT_DATA}?reportDate=${moment().format("YYYYMMDD")}`)
            .then((res: any) => {
                if (res.status === 200) {
                    if (res.data.length > 0) {
                        let newData = res.data.map((x: DailyReportData, i: number) => ({
                            ...x,
                            newKey: `${x.cid}|${x.mainServerId}|${x.login}`,
                            showJournalDate: moment(x.journalDate).format("YYYY-MM-DD HH:mm:ss"),
                            index: i,
                        }));
                        setData(newData);
                    } else setData([]);
                } else {
                    setData([]);
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => {
                    ErrorMessageHandler("daily report", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                    setData([]);
                })
            )
            .finally(() => setIsLoading(false));
    };

    const getMaxReportDate = () => {
        plainAxiosInstance
            .get(`${APIs.RC_CID.GET_MAX_DAILY_REPORT_DATE}`)
            .then((res: any) => {
                if (res.status === 200) {
                    setMaxReportDate(res.data);
                } else setMaxReportDate("");
            })
            .catch((error: any) => {
                console.log("Failed to load max report date: ", error);
                setMaxReportDate("");
            });
    };

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            getMaxReportDate();
            getDailyReportData();
            setRunRefetchDataList(false);
        }
        return () => {};
    }, [runRefetchDataList]);

    return (
        <div className="daily-report-tab">
            <FlexiDataTable
                rowKeyProperty={"newKey"} // record.cid|record.mainServerId|record.login
                title={<span className="table-title">Last Execution Time : {maxReportDate}</span>}
                columns={columns}
                options={options}
                callback={componentCallback}
                dataSource={data}
                loading={isLoading}
                exporting={isExporting}
            />
            <Modal
                width={700}
                title={`Create New CID History`}
                open={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
                footer={[
                    <Button
                        key={"cancel"}
                        onClick={() => {
                            cidHistoryForm.resetFields();
                            setIsModalOpen(false);
                        }}
                    >
                        Cancel
                    </Button>,
                    <Button
                        key={"regular-submit"}
                        type="primary"
                        htmlType="submit"
                        loading={isBtnLoading}
                        onClick={() => {
                            cidHistoryForm
                                .validateFields()
                                .then(res => onFormSubmit(res))
                                .catch(err => {
                                    message.error("Failed to submit form. Please check the form for errors.");
                                    // console.log("form-error:", err);
                                });
                        }}
                    >
                        Submit
                    </Button>,
                ]}
            >
                <ViewHistoryModal form={cidHistoryForm} isAddAction={false} servers={props.servers} labels={labels} />
            </Modal>
        </div>
    );
};

export default DailyReport;
