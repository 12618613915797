import { Button, Col, Form, Modal, Row } from "antd";
import React from "react";
import { FormComponent } from "../../../../components/FormComponent";
import { ComponentType } from "../../../../constants";
import { REQUIRED_FIELD, SOMETHING_WENT_WRONG } from "../../../../constants/errorMessage";
import { ExclamationCircleOutlined, FileAddOutlined } from "@ant-design/icons";
import { KeyValuePair } from "../../../../constants/type";
import { plainAxiosInstance } from "../../../../services/axiosSetup";
import { APIs } from "../../../../services/apis";
import { ErrorCatchValidator } from "../../../../utils/Common";

interface GenerateConfigFormProps {
    servers: KeyValuePair[];
};

const GenerateConfigForm = (props: GenerateConfigFormProps) => {
    const [downloadForm] = Form.useForm();
    const [isLoading, setIsLoading] = React.useState(false);
    const servers = React.useMemo(() => props.servers, [props.servers]);

    const onFormSubmit = (values: any) => {
        Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            title: "Are you sure you want to generate config?",
            content: `Selected servers: ${values.mainServer.join(", ")}`,
            onOk: () => {
                setIsLoading(true);
                plainAxiosInstance
                    .post(`${APIs.RC_CONFIG_DIFF.POST_GENERATE_CONFIG}`, values.mainServer)
                    .then((res: any) => {
                        if (res.data === "ok") {
                            return (
                                Modal.success({
                                    title: "Generate Success!",
                                    content: "Please wait a moment for the task to complete"
                                })
                            );
                        } else return (
                            Modal.error({
                                title: "Generate Failed!",
                                content: SOMETHING_WENT_WRONG
                            })
                        );
                    })
                    .catch((error: any) =>
                        ErrorCatchValidator(error, (err: any) => {
                            return (
                                Modal.error({
                                    title: "Generate Failed!",
                                    content: `${SOMETHING_WENT_WRONG} ${err}`
                                })
                            )
                        })
                    )
                    .finally(() => setIsLoading(false));
            },
            onCancel: () => { },
        })
    };

    return (
        <div className="generate-cd-form-div">
            <Form
                form={downloadForm}
                layout="horizontal"
                initialValues={{}}
                onFinish={onFormSubmit}
            >
                <Row gutter={[12, 12]}>
                    <Col span={6}>
                        <FormComponent
                            label="Main Server"
                            name="mainServer"
                            extra={{
                                type: ComponentType.dropdown,
                                value: servers,
                                rules: [{ required: true, message: `${REQUIRED_FIELD} in order to generate config` }],
                                inputProps: { mode: "multiple" }
                            }}
                        />
                    </Col>
                    <Col>
                        <Form.Item>
                            <Button
                                icon={<FileAddOutlined />}
                                type="primary"
                                loading={isLoading}
                                htmlType="submit"
                            >
                                Generate Config
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default GenerateConfigForm;