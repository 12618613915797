import {
    FILTER_OPERATOR_TYPE_ENUM,
    DATA_TYPES_ENUM,
    ROLES_LABELS,
    CronType,
    SUCCESS_FAILED_MSG,
    INTRADAY_COMPONENTS_BY_ID,
    DIMENSION_LEVEL,
    DIMENSION_METRICS_COL_ORDER,
    ComponentType,
} from "../constants";
import moment from "moment";
import {
    ConfigurationTemplateData,
    CreateTemplate,
    CustomFilterOption,
    CustomSortOption,
    MetricFilter,
    MetricInfo,
    MetricSetupDataProps,
    SymbolConfigData,
    SymbolConfigFormSetUp,
    ReportTemplateSetUp,
    ScheduledData,
    SchedulerInfo,
    SelectedMetric,
    BrandsList,
    ServersList,
    HightlightExpression,
    MarkupValueHandler,
    MimMetricsList,
} from "../constants/type";
import { Badge, message } from "antd";
import { isEmptyOrNull } from "./string";
import { SortList } from "./array";

export const getScheduledCronDescription = (item: ScheduledData): SchedulerInfo => {
    let exp = ["*", "*", "*", "*", "*", "?", "*"];
    let snapShot = false;

    switch (item.dType) {
        case CronType.Minute.toString():
            let mins = item.dMinute;
            exp[0] = "0";
            exp[1] = `0/${mins.toString()}`;
            break;
        case CronType.Hour.toString():
            let hrs = item.dHour;
            exp[0] = "0";
            exp[1] = "0";
            exp[2] = `0/${hrs.toString()}`;
            break;
        case CronType.Daily.toString():
            let time = item.dTime as moment.Moment;
            exp[0] = time.seconds().toString();
            exp[1] = time.minutes().toString();
            exp[2] = time.hours().toString();
            if (item.dWeekdayOnly) {
                exp[3] = "?";
                exp[5] = "MON-FRI";
            } else {
                exp[3] = "1/" + item.dDays.toString();
                exp[5] = "?";
            }

            if (item.dtSnapshot) snapShot = item.dtSnapshot;
            break;
        case CronType.Weekly.toString():
            let time2 = item.wTime as moment.Moment;
            exp[0] = time2.seconds().toString();
            exp[1] = time2.minutes().toString();
            exp[2] = time2.hours().toString();
            exp[3] = "?";
            exp[5] = item.wWeekday.join(",");
            if (item.wtSnapshot) snapShot = item.wtSnapshot;
            break;
        case CronType.Monthly.toString():
            let time3 = item.mTime as moment.Moment;
            exp[0] = time3.seconds().toString();
            exp[1] = time3.minutes().toString();
            exp[2] = time3.hours().toString();
            exp[5] = "?";
            if (item.mDays > 0) {
                exp[3] = item.mDays.toString();
                exp[4] = "1/1";
            } else if (item.mDays < 0) {
                exp[3] = "L" + item.mDays.toString();
                exp[4] = "*";
            }
            if (item.mtSnapshot) snapShot = item.mtSnapshot;
            break;
        default:
            break;
    }

    return { cronExpression: exp.join(" "), snapshot: snapShot };
};

export const convertMetricSetupDataToCreateTemplate = (from: MetricSetupDataProps, to: CreateTemplate, extra: MetricInfo): CreateTemplate => {
    let fromModel: MetricSetupDataProps = from;
    let newTemplateValue = { ...to };
    newTemplateValue.metricType = {
        ...newTemplateValue.metricType,
        typeId: fromModel.metricType as number,
        name: extra.metricType.find(x => x.typeId === fromModel.metricType)?.name || "",
    };
    newTemplateValue.metricSelected = fromModel.metricSelected.map<SelectedMetric>(x => {
        let currentMetricId = parseInt(x);
        let foundMetric = extra.metric.find(x => x.metricId === currentMetricId);
        return {
            metricId: foundMetric?.metricId || 0,
            dataType: foundMetric?.dataTypeId || 0,
            name: foundMetric?.metricName || "",
            displayName: foundMetric?.displayName || "",
        };
    });
    newTemplateValue.metricFilters =
        fromModel.metricFilters?.map(x => {
            switch (x.operator) {
                case FILTER_OPERATOR_TYPE_ENUM.Range:
                    if (x.valueobj !== undefined) {
                        if (x.dataType === DATA_TYPES_ENUM.DateTime) {
                            let dateObj: moment.Moment[] = x.valueobj as moment.Moment[];
                            x.value = dateObj[0].format("YYYY-MM-DDTHH:mm:ss") + "|" + dateObj[1].format("YYYY-MM-DDTHH:mm:ss");
                        } else if (x.dataType === DATA_TYPES_ENUM.Date) {
                            let dateObj: moment.Moment[] = x.valueobj as moment.Moment[];
                            x.value = dateObj[0].format("YYYY-MM-DD") + "|" + dateObj[1].format("YYYY-MM-DD");
                        } else {
                            let dateObj2: string[] = x.valueobj as string[];
                            x.value = (dateObj2[0] || "") + "|" + (dateObj2[1] || "");
                        }
                    }
                    break;
                case FILTER_OPERATOR_TYPE_ENUM.GreaterThanEqual:
                case FILTER_OPERATOR_TYPE_ENUM.GreaterThan:
                    if (x.dataType === DATA_TYPES_ENUM.DateTime) {
                        x.value = (x.valueobj as unknown as moment.Moment).format("YYYY-MM-DDTHH:mm:ss") + "|";
                    } else if (x.dataType === DATA_TYPES_ENUM.Date) {
                        x.value = (x.valueobj as unknown as moment.Moment).format("YYYY-MM-DD") + "|";
                    } else {
                        x.value = (x.valueobj as string) + "|";
                    }
                    break;
                case FILTER_OPERATOR_TYPE_ENUM.LessThanEqual:
                case FILTER_OPERATOR_TYPE_ENUM.LessThan:
                    if (x.dataType === DATA_TYPES_ENUM.DateTime) {
                        x.value = "|" + (x.valueobj as unknown as moment.Moment).format("YYYY-MM-DDTHH:mm:ss");
                    } else if (x.dataType === DATA_TYPES_ENUM.Date) {
                        x.value = "|" + (x.valueobj as unknown as moment.Moment).format("YYYY-MM-DD");
                    } else {
                        x.value = "|" + (x.valueobj as string);
                    }
                    break;
                default:
                    if (x.dataType === DATA_TYPES_ENUM.DateTime) {
                        x.value = (x.valueobj as unknown as moment.Moment).format("YYYY-MM-DDTHH:mm:ss");
                    } else if (x.dataType === DATA_TYPES_ENUM.Date) {
                        x.value = (x.valueobj as unknown as moment.Moment).format("YYYY-MM-DD");
                    } else {
                        x.value = (x.valueobj as string) + "";
                    }
                    break;
            }

            return {
                metricId: x.metricId,
                dataType: x.dataType,
                name: x.name,
                operator: x.operator,
                value: x.value,
            } as MetricFilter;
        }) || [];
    newTemplateValue.metricSorts = fromModel.metricSorts || [];
    newTemplateValue.limitRecords = fromModel.cblimitrecords
        ? typeof fromModel.limitRecords === "string"
            ? parseInt(fromModel.limitRecords)
            : fromModel.limitRecords
        : undefined;
    newTemplateValue.brands = [...fromModel.brands];
    newTemplateValue.tags = [...fromModel.tags];
    newTemplateValue.accountTypes = [...fromModel.accountTypes];
    newTemplateValue.symbols = [...fromModel.symbols];
    return newTemplateValue;
};

export const convertReportTemplateSetUpDataToCreateTemplate = (from: ReportTemplateSetUp, to: CreateTemplate, extra: MetricInfo): CreateTemplate => {
    let fromModel: ReportTemplateSetUp = from;
    let newTemplateValue = {
        ...to,
        name: fromModel.name,
        description: fromModel.description || "",
        sharingRules: fromModel.sharingRules as number,
        status: fromModel.status as number,
        adHocRun: fromModel.adHocRun === 1 || fromModel.adHocRun === 2,
        snapshotDate: fromModel.adHocRun === 2 ? (fromModel.snapshotDate as unknown as moment.Moment).format("YYYY-MM-DD") : "",
        schedulers:
            fromModel.adHocRun === 0 ? fromModel.schedulers.map(x => ({ jobId: 0, cronExpression: x.cronExpression, snapshot: x.snapshot })) : [],
        emails: fromModel.emails || [],
    };
    newTemplateValue.metricType = {
        ...newTemplateValue.metricType,
        typeId: fromModel.metricType as number,
        name: extra.metricType.find(x => x.typeId === fromModel.metricType)?.name || "",
    };
    newTemplateValue.metricSelected = fromModel.metricSelected.map<SelectedMetric>(x => {
        let currentMetricId = parseInt(x);
        let foundMetric = extra.metric.find(x => x.metricId === currentMetricId);
        return {
            metricId: foundMetric?.metricId || 0,
            dataType: foundMetric?.dataTypeId || 0,
            name: foundMetric?.metricName || "",
            displayName: foundMetric?.displayName || "",
        };
    });
    newTemplateValue.metricFilters =
        fromModel.metricFilters?.map(x => {
            switch (x.operator) {
                case FILTER_OPERATOR_TYPE_ENUM.Range:
                    if (x.valueobj !== undefined) {
                        if (x.dataType === DATA_TYPES_ENUM.DateTime) {
                            let dateObj: moment.Moment[] = x.valueobj as moment.Moment[];
                            x.value = dateObj[0].format("YYYY-MM-DDTHH:mm:ss") + "|" + dateObj[1].format("YYYY-MM-DDTHH:mm:ss");
                        } else if (x.dataType === DATA_TYPES_ENUM.Date) {
                            let dateObj: moment.Moment[] = x.valueobj as moment.Moment[];
                            x.value = dateObj[0].format("YYYY-MM-DD") + "|" + dateObj[1].format("YYYY-MM-DD");
                        } else {
                            let dateObj2: string[] = x.valueobj as string[];
                            x.value = (dateObj2[0] || "") + "|" + (dateObj2[1] || "");
                        }
                    }
                    break;
                case FILTER_OPERATOR_TYPE_ENUM.GreaterThanEqual:
                case FILTER_OPERATOR_TYPE_ENUM.GreaterThan:
                    if (x.dataType === DATA_TYPES_ENUM.DateTime) {
                        x.value = (x.valueobj as unknown as moment.Moment).format("YYYY-MM-DDTHH:mm:ss") + "|";
                    } else if (x.dataType === DATA_TYPES_ENUM.Date) {
                        x.value = (x.valueobj as unknown as moment.Moment).format("YYYY-MM-DD") + "|";
                    } else {
                        x.value = (x.valueobj as string) + "|";
                    }
                    break;
                case FILTER_OPERATOR_TYPE_ENUM.LessThanEqual:
                case FILTER_OPERATOR_TYPE_ENUM.LessThan:
                    if (x.dataType === DATA_TYPES_ENUM.DateTime) {
                        x.value = "|" + (x.valueobj as unknown as moment.Moment).format("YYYY-MM-DDTHH:mm:ss");
                    } else if (x.dataType === DATA_TYPES_ENUM.Date) {
                        x.value = "|" + (x.valueobj as unknown as moment.Moment).format("YYYY-MM-DD");
                    } else {
                        x.value = "|" + (x.valueobj as string);
                    }
                    break;
                default:
                    if (x.dataType === DATA_TYPES_ENUM.DateTime) {
                        x.value = (x.valueobj as unknown as moment.Moment).format("YYYY-MM-DDTHH:mm:ss");
                    } else if (x.dataType === DATA_TYPES_ENUM.Date) {
                        x.value = (x.valueobj as unknown as moment.Moment).format("YYYY-MM-DD");
                    } else {
                        x.value = (x.valueobj as string) + "";
                    }
                    break;
            }

            return {
                metricId: x.metricId,
                dataType: x.dataType,
                name: x.name,
                operator: x.operator,
                value: x.value,
            } as MetricFilter;
        }) || [];
    newTemplateValue.metricSorts = fromModel.metricSorts || [];
    newTemplateValue.limitRecords = fromModel.cblimitrecords
        ? typeof fromModel.limitRecords === "string"
            ? parseInt(fromModel.limitRecords)
            : fromModel.limitRecords
        : undefined;
    newTemplateValue.brands = [...fromModel.brands];
    newTemplateValue.tags = fromModel.cbtagging ? [...fromModel.tags] : [];
    newTemplateValue.accountTypes = [...fromModel.accountTypes];
    newTemplateValue.accountIds = fromModel.accountIds ? [...fromModel.accountIds] : [];
    newTemplateValue.symbols = fromModel.symbols ? [...fromModel.symbols] : [];
    return newTemplateValue;
};

export const convertCreateTemplateToMetricSetupData = (from: CreateTemplate, to: MetricSetupDataProps, extra: MetricInfo): MetricSetupDataProps => {
    let fromModel: CreateTemplate = from;
    let newTemplateValue = { ...to };

    newTemplateValue.metricType = fromModel.metricType.typeId === 0 ? "" : fromModel.metricType.typeId;
    newTemplateValue.metricSelected = fromModel.metricSelected.map(x => x.metricId.toString());
    if (fromModel.limitRecords) {
        newTemplateValue.cblimitrecords = true;
        newTemplateValue.limitRecords = fromModel.limitRecords;
    } else {
        newTemplateValue.cblimitrecords = false;
        newTemplateValue.limitRecords = undefined;
    }

    newTemplateValue.metricFilters = fromModel.metricFilters.map<CustomFilterOption>(x => {
        let tmpObj: CustomFilterOption = {
            metricDd: x.metricId + "|" + x.name + "|" + x.dataType,
            metricId: x.metricId,
            dataType: x.dataType,
            name: x.name,
            operator: x.operator,
            value: x.value as string,
            valueobj: undefined,
        };

        switch (x.operator) {
            case FILTER_OPERATOR_TYPE_ENUM.Range:
                {
                    let date_info = x.value.split("|");
                    if (x.dataType === DATA_TYPES_ENUM.DateTime || x.dataType === DATA_TYPES_ENUM.Date) {
                        tmpObj.valueobj = [moment(date_info[0]), moment(date_info[1])];
                    } else {
                        tmpObj.valueobj = [date_info[0], date_info[1]];
                    }
                }
                break;
            case FILTER_OPERATOR_TYPE_ENUM.GreaterThanEqual:
            case FILTER_OPERATOR_TYPE_ENUM.GreaterThan:
                {
                    let obj_info = x.value.split("|");
                    if (x.dataType === DATA_TYPES_ENUM.DateTime) {
                        tmpObj.valueobj = moment(obj_info[0], "YYYY-MM-DDTHH:mm:ss");
                    } else if (x.dataType === DATA_TYPES_ENUM.Date) {
                        tmpObj.valueobj = moment(obj_info[0], "YYYY-MM-DD");
                    } else {
                        tmpObj.valueobj = obj_info[0];
                    }
                }
                break;
            case FILTER_OPERATOR_TYPE_ENUM.LessThanEqual:
            case FILTER_OPERATOR_TYPE_ENUM.LessThan:
                {
                    let obj_info = x.value.split("|");
                    if (x.dataType === DATA_TYPES_ENUM.DateTime) {
                        tmpObj.valueobj = moment(obj_info[1], "YYYY-MM-DDTHH:mm:ss");
                    } else if (x.dataType === DATA_TYPES_ENUM.Date) {
                        tmpObj.valueobj = moment(obj_info[1], "YYYY-MM-DD");
                    } else {
                        tmpObj.valueobj = obj_info[1];
                    }
                }
                break;
            default:
                if (x.dataType === DATA_TYPES_ENUM.DateTime) {
                    tmpObj.valueobj = moment(x.value, "YYYY-MM-DDTHH:mm:ss");
                } else if (x.dataType === DATA_TYPES_ENUM.Date) {
                    tmpObj.valueobj = moment(x.value, "YYYY-MM-DD");
                } else {
                    tmpObj.valueobj = x.value;
                }
                break;
        }

        return tmpObj;
    });
    newTemplateValue.metricSorts = fromModel.metricSorts.map<CustomSortOption>(x => ({
        metricDd: x.metricId + "|" + x.name,
        metricId: x.metricId,
        name: x.name,
        order: x.order,
    }));
    newTemplateValue.brands = [...(fromModel.brands as number[])];
    newTemplateValue.tags = [...(fromModel.tags as number[])];
    newTemplateValue.accountTypes = [...(fromModel.accountTypes as number[])];
    newTemplateValue.accountIds = [...(fromModel.accountIds as string[])];
    newTemplateValue.symbols = [...(fromModel.symbols as string[])];
    return newTemplateValue;
};

export const convertCreateTemplateToReportTemplateSetUpData = (
    from: CreateTemplate,
    to: ReportTemplateSetUp,
    extra: MetricInfo
): ReportTemplateSetUp => {
    let fromModel: CreateTemplate = from;
    let newTemplateValue = {
        ...to,
        name: fromModel.name,
        description: fromModel.description,
        sharingRules: fromModel.sharingRules === 0 ? "" : fromModel.sharingRules,
        status: fromModel.status,
        adHocRun: fromModel.adHocRun ? 1 : 0,
        snapshotDate: fromModel.snapshotDate,
        schedulers: fromModel.schedulers as SchedulerInfo[],
        emails: fromModel.emails,
        cbemailto: fromModel.emails.length > 0,
        cbtagging: fromModel.tags.length > 0,
    };

    newTemplateValue.metricType = fromModel.metricType.typeId === 0 ? "" : fromModel.metricType.typeId;
    newTemplateValue.metricSelected = fromModel.metricSelected.map(x => x.metricId.toString());
    if (fromModel.limitRecords) {
        newTemplateValue.cblimitrecords = true;
        newTemplateValue.limitRecords = fromModel.limitRecords;
    } else {
        newTemplateValue.cblimitrecords = false;
        newTemplateValue.limitRecords = undefined;
    }

    newTemplateValue.metricFilters = fromModel.metricFilters.map<CustomFilterOption>(x => {
        let tmpObj: CustomFilterOption = {
            metricDd: x.metricId + "|" + x.name + "|" + x.dataType,
            metricId: x.metricId,
            dataType: x.dataType,
            name: x.name,
            operator: x.operator,
            value: x.value as string,
            valueobj: undefined,
        };

        switch (x.operator) {
            case FILTER_OPERATOR_TYPE_ENUM.Range:
                {
                    let date_info = x.value.split("|");
                    if (x.dataType === DATA_TYPES_ENUM.DateTime || x.dataType === DATA_TYPES_ENUM.Date) {
                        tmpObj.valueobj = [moment(date_info[0]), moment(date_info[1])];
                    } else {
                        tmpObj.valueobj = [date_info[0], date_info[1]];
                    }
                }
                break;
            case FILTER_OPERATOR_TYPE_ENUM.GreaterThanEqual:
            case FILTER_OPERATOR_TYPE_ENUM.GreaterThan:
                {
                    let obj_info = x.value.split("|");
                    if (x.dataType === DATA_TYPES_ENUM.DateTime) {
                        tmpObj.valueobj = moment(obj_info[0], "YYYY-MM-DDTHH:mm:ss");
                    } else if (x.dataType === DATA_TYPES_ENUM.Date) {
                        tmpObj.valueobj = moment(obj_info[0], "YYYY-MM-DD");
                    } else {
                        tmpObj.valueobj = obj_info[0];
                    }
                }
                break;
            case FILTER_OPERATOR_TYPE_ENUM.LessThanEqual:
            case FILTER_OPERATOR_TYPE_ENUM.LessThan:
                {
                    let obj_info = x.value.split("|");
                    if (x.dataType === DATA_TYPES_ENUM.DateTime) {
                        tmpObj.valueobj = moment(obj_info[1], "YYYY-MM-DDTHH:mm:ss");
                    } else if (x.dataType === DATA_TYPES_ENUM.Date) {
                        tmpObj.valueobj = moment(obj_info[1], "YYYY-MM-DD");
                    } else {
                        tmpObj.valueobj = obj_info[1];
                    }
                }
                break;
            default:
                if (x.dataType === DATA_TYPES_ENUM.DateTime) {
                    tmpObj.valueobj = moment(x.value, "YYYY-MM-DDTHH:mm:ss");
                } else if (x.dataType === DATA_TYPES_ENUM.Date) {
                    tmpObj.valueobj = moment(x.value, "YYYY-MM-DD");
                } else {
                    tmpObj.valueobj = x.value;
                }
                break;
        }

        return tmpObj;
    });
    newTemplateValue.metricSorts = fromModel.metricSorts.map<CustomSortOption>(x => ({
        metricDd: x.metricId + "|" + x.name,
        metricId: x.metricId,
        name: x.name,
        order: x.order,
    }));
    newTemplateValue.brands = [...(fromModel.brands as number[])];
    newTemplateValue.tags = [...(fromModel.tags as number[])];
    newTemplateValue.accountTypes = [...(fromModel.accountTypes as number[])];
    newTemplateValue.accountIds = [...(fromModel.accountIds as string[])];
    newTemplateValue.symbols = ["2", "3"].indexOf(newTemplateValue.metricType.toString()) > -1 ? [...(fromModel.symbols as string[])] : [];
    return newTemplateValue;
};

export const convertConfigurationSetupDataToCreatTemplete = (
    from: ConfigurationTemplateData,
    to: CreateTemplate,
    extra: MetricInfo
): CreateTemplate => {
    let fromModel: ConfigurationTemplateData = from;
    let newTemplateValue: CreateTemplate = {
        ...to,
        name: fromModel.name,
        description: fromModel.description || "",
        sharingRules: fromModel.sharingRules as number,
        status: fromModel.status as number,
        adHocRun: fromModel.adHocRun === 1 || fromModel.adHocRun === 2,
        snapshotDate: fromModel.adHocRun === 2 ? (fromModel.snapshotDate as unknown as moment.Moment).format("YYYY-MM-DD") : "",
        schedulers:
            fromModel.adHocRun === 0 ? fromModel.schedulers.map(x => ({ jobId: 0, cronExpression: x.cronExpression, snapshot: x.snapshot })) : [],
        emails: fromModel.emails || [],
    };

    return newTemplateValue;
};

export const convertCreateTemplateToConfigurationSetupData = (
    from: CreateTemplate,
    to: ConfigurationTemplateData,
    extra: MetricInfo
): ConfigurationTemplateData => {
    let fromModel: CreateTemplate = from;
    let newTemplateValue: ConfigurationTemplateData = {
        ...to,
        name: fromModel.name,
        description: fromModel.description,
        sharingRules: fromModel.sharingRules === 0 ? "" : fromModel.sharingRules,
        status: fromModel.status,
        adHocRun: fromModel.adHocRun ? 1 : 0,
        snapshotDate: fromModel.snapshotDate,
        schedulers: fromModel.schedulers as SchedulerInfo[],
        emails: fromModel.emails,
        cbemailto: fromModel.emails.length > 0,
    };

    return newTemplateValue;
};

export const DataTableColumnRender = {
    DateTime: (datetime: string | undefined) => {
        //return datetime ? moment(datetime).toLocaleString() : "";
        return !isEmptyOrNull(datetime) ? moment(datetime).format("DD MMM YYYY HH:mm:ssZZ") : "";
    },
    DateTime_ServerTime: (datetime: string | undefined, dateFormat: string = "YYYY-MM-DD HH:mm:ss") => {
        return !isEmptyOrNull(datetime) ? moment(datetime).format(dateFormat) : "";
        // return datetime
        //     ? new Date(datetime).toLocaleTimeString("en-US", {
        //           weekday: "short",
        //           year: "numeric",
        //           month: "short",
        //           day: "numeric",
        //           hour: "2-digit",
        //           minute: "2-digit",
        //           second: "2-digit",
        //       })
        //     : "";
    },
    DateTime_UTC_TO_ServerTime: (datetime: string | undefined, utcOffset: number | undefined, dateFormat: string = "YYYY-MM-DD HH:mm:ss") => {
        return !isEmptyOrNull(datetime)
            ? (utcOffset !== undefined ? moment(datetime).utcOffset(utcOffset as number) : moment(datetime).tz("EET")).format(dateFormat)
            : "";
    },
    DateTime_ServerDateOnly: (datetime: string | undefined) => {
        return !isEmptyOrNull(datetime) ? moment(datetime).format("YYYY-MM-DD") : "";
    },
    RoleList: (current_role: string) => {
        let current_role_text = current_role.toLowerCase();
        switch (current_role_text) {
            case "administrator":
                return Object.values(ROLES_LABELS).map(x => ({ text: x.name, value: x.id }));
            case "siteadmin":
                return Object.values(ROLES_LABELS)
                    .filter(x => x.key !== "administrator")
                    .map(x => ({ text: x.name, value: x.id }));
            default:
                return Object.values(ROLES_LABELS)
                    .filter(x => x.key === "user")
                    .map(x => ({ text: x.name, value: x.id }));
        }
    },
};

export const parseJwt = (token: string): undefined | any => {
    if (token && token.split(".").length === 3) {
        try {
            let base64 = token.split(".")[1].replace(/-/g, "+").replace(/_/g, "/");
            // var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            //     return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            // }).join(''));
            let jsonPayload = decodeURIComponent(encodeURIComponent(window.atob(base64)));
            return JSON.parse(jsonPayload);
        } catch (e) {
            console.log(e);
        }
    }
    return undefined;
};

export const scientificToDecimal = (ori_value: any) => {
    let currentValues: string = isEmptyOrNull(ori_value) ? "" : `${ori_value}`;
    if (currentValues === "NULL" || currentValues === "null" || currentValues === "") return currentValues;

    const getZero = (count: number) => {
        let rVal = "";
        for (var i = 0; i < count; i++) {
            rVal += "0";
        }
        return rVal;
    };

    let tmp_value: string = ori_value.toString().replace(/\s+/g, "").replace("*10^", "e");
    let value = parseFloat(tmp_value);
    let REGEX_SCIENTIFIC = /(\d+\.?\d*)e\d*(\+|-)(\d+)/;
    let valueString = value.toString();
    let result: any = REGEX_SCIENTIFIC.exec(valueString);
    let base: string;
    let positiveNegative: string;
    let exponents: string;
    let precision: number;
    let rVal: string;

    // is scientific
    if (result) {
        // [ '1e+32', '1', '+', '2', index: 0, input: '1e+32' ]
        base = result[1];
        positiveNegative = result[2];
        exponents = result[3];

        if (positiveNegative === "+") {
            precision = parseInt(exponents);

            // base is a decimal
            if (base.indexOf(".") !== -1) {
                result = /(\d+\.)(\d)/.exec(base);

                // [ 2 ] == right side after decimal
                // [ 1 ] == left side before decimal
                precision -= result[2].length + result[1].length;

                rVal = base.split(".").join("") + getZero(precision);
            } else {
                rVal = base + getZero(precision);
            }
        } else {
            precision = base.length + parseInt(exponents) - 1;

            // if it contains a period we want to drop precision by one
            if (base.indexOf(".") !== -1) {
                precision--;
            }

            rVal = value.toFixed(precision);
        }
    } else {
        rVal = value.toString();
    }

    return rVal;
};

export const DTColProps = {
    DateTime: (values: any, className: string[] = []) =>
        Object.assign(
            {},
            {
                width: "11.561vw",
                className: `row-datetime ${className.join(" ")}`,
                sorter: (a: number, b: number) => (moment(a) > moment(b) ? -1 : 1),
                render: (value: string) => (value ? DataTableColumnRender.DateTime(value) : ""),
            },
            values
        ),
    DateTime_ServerTime: (values: any, className: string[] = []) =>
        Object.assign(
            {
                width: "9.984vw",
                className: `row-datetime ${className.join(" ")}`,
                sorter: (a: number, b: number) => (moment(a) > moment(b) ? -1 : 1),
                render: (value: string) => (value ? DataTableColumnRender.DateTime_ServerTime(value) : ""),
            },
            values
        ),
    DateTime_UTC_To_ServerTime: (values: any, utcOffset: number | undefined, className: string[] = []) =>
        Object.assign(
            {
                width: "9.984vw",
                className: `row-datetime ${className.join(" ")}`,
                sorter: (a: number, b: number) => (moment(a) > moment(b) ? -1 : 1),
                render: (value: string) => (value ? DataTableColumnRender.DateTime_UTC_TO_ServerTime(value, utcOffset) : ""),
            },
            values
        ),
    DateTime_ServerDateOnly: (values: any, className: string[] = []) =>
        Object.assign(
            {
                width: "7.882vw",
                className: `row-datetime ${className.join(" ")}`,
                sorter: (a: number, b: number) => (moment(a) > moment(b) ? -1 : 1),
                render: (value: string) => (value ? DataTableColumnRender.DateTime_ServerDateOnly(value) : ""),
            },
            values
        ),
    Status: (values: any, className: string[] = []) => ({
        width: "5.555vw",
        className: `row-status ${className.join(" ")}`,
        render: (values: any) => {
            if (typeof values === "boolean") {
                return <Badge status={!values ? "default" : "success"} text={!values ? "Inactive" : "Active"} className="badge-blinking" />;
            }
            return <Badge status={values === 0 ? "default" : "success"} text={values === 0 ? "Inactive" : "Active"} className="badge-blinking" />;
        },
        ...values,
    }),
    Currency: (
        values: any,
        className: string[] = [],
        decimalPoint: number = 2,
        hightlight: HightlightExpression | undefined = undefined,
        hightlightClassName: string | undefined = undefined,
        markupValue: MarkupValueHandler | undefined = undefined
    ) => ({
        width: "11.561vw",
        className: `row-currency ${className.join(" ")}`,
        align: "right",
        render: (value: number, rowData: any) => {
            return hightlight !== undefined ? (
                <span className={hightlight(value, rowData) ? hightlightClassName || "highlight" : ""}>
                    {markupValue
                        ? markupValue(value, rowData)
                        : value && value !== 0
                            ? currencyRender(scientificToDecimal(value), decimalPoint)
                            : value}
                </span>
            ) : markupValue ? (
                markupValue(value, rowData)
            ) : value && value !== 0 ? (
                currencyRender(scientificToDecimal(value), decimalPoint)
            ) : (
                value
            );
        },
        ...values,
    }),
    SCurrency: (
        values: any,
        className: string[] = [],
        decimalPoint: number = 2,
        hightlight: HightlightExpression | undefined = undefined,
        markupValue: MarkupValueHandler | undefined = undefined
    ) => ({
        width: "7.882vw",
        className: `row-currency ${className.join(" ")}`,
        align: "right",
        render: (value: number, rowData: any) =>
            hightlight !== undefined ? (
                <span className={hightlight(value, rowData) ? "highlight" : ""}>
                    {markupValue
                        ? markupValue(value, rowData)
                        : value && value !== 0
                            ? currencyRender(scientificToDecimal(value), decimalPoint)
                            : value}
                </span>
            ) : markupValue ? (
                markupValue(value, rowData)
            ) : value && value !== 0 ? (
                currencyRender(scientificToDecimal(value), decimalPoint)
            ) : (
                value
            ),
        ...values,
    }),
    XSCurrency: (
        values: any,
        className: string[] = [],
        decimalPoint: number = 2,
        hightlight: HightlightExpression | undefined = undefined,
        markupValue: MarkupValueHandler | undefined = undefined
    ) => ({
        width: "5vw",
        className: `row-currency ${className.join(" ")}`,
        align: "right",
        render: (value: number, rowData: any) =>
            hightlight !== undefined ? (
                <span className={hightlight(value, rowData) ? "highlight" : ""}>
                    {markupValue
                        ? markupValue(value, rowData)
                        : value && value !== 0
                            ? currencyRender(scientificToDecimal(value), decimalPoint)
                            : value}
                </span>
            ) : markupValue ? (
                markupValue(value, rowData)
            ) : value && value !== 0 ? (
                currencyRender(scientificToDecimal(value), decimalPoint)
            ) : (
                value
            ),
        ...values,
    }),
    XSmall: (
        values: any,
        className: string[] = [],
        hightlight: HightlightExpression | undefined = undefined,
        hightlightClassName: string | undefined = undefined,
    ) => ({
        width: "5.555vw",
        className: `row-xsmall-size ${className.join(" ")}`,
        render: (value: any, rowData: any) =>
            hightlight !== undefined ? (
                <span className={hightlight(value, rowData) ? hightlightClassName || "highlight" : ""}>
                    {value}
                </span>
            ) : (
                value
            ),
        ...values,
    }),
    XXSmall: (
        values: any,
        className: string[] = [],
        hightlight: HightlightExpression | undefined = undefined,
        hightlightClassName: string | undefined = undefined,
    ) => ({
        width: "3.555vw",
        className: `row-xxsmall-size ${className.join(" ")}`,
        render: (value: any, rowData: any) =>
            hightlight !== undefined ? (
                <span className={hightlight(value, rowData) ? hightlightClassName || "highlight" : ""}>
                    {value}
                </span>
            ) : (
                value
            ),

        ...values,
    }),
    Small: (
        values: any,
        className: string[] = [],
        hightlight: HightlightExpression | undefined = undefined,
        hightlightClassName: string | undefined = undefined,
    ) => ({
        width: "7.882vw",
        className: `row-small-size ${className.join(" ")}`,
        render: (value: any, rowData: any) =>
            hightlight !== undefined ? (
                <span className={hightlight(value, rowData) ? hightlightClassName || "highlight" : ""}>
                    {value}
                </span>
            ) : (
                value
            ),

        ...values,
    }),
    Middle: (
        values: any,
        className: string[] = [],
        hightlight: HightlightExpression | undefined = undefined,
        hightlightClassName: string | undefined = undefined,
    ) => ({
        width: "13.137vw",
        className: `row-middle-size ${className.join(" ")}`,
        render: (value: any, rowData: any) =>
            hightlight !== undefined ? (
                <span className={hightlight(value, rowData) ? hightlightClassName || "highlight" : ""}>
                    {value}
                </span>
            ) : (
                value
            ),

        ...values,
    }),
    Large: (
        values: any,
        className: string[] = [],
        hightlight: HightlightExpression | undefined = undefined,
        hightlightClassName: string | undefined = undefined,
    ) => ({
        width: "18.392vw",
        className: `row-large-size ${className.join(" ")}`,
        render: (value: any, rowData: any) =>
            hightlight !== undefined ? (
                <span className={hightlight(value, rowData) ? hightlightClassName || "highlight" : ""}>
                    {value}
                </span>
            ) : (
                value
            ),

        ...values,
    }),
    XLarge: (
        values: any,
        className: string[] = [],
        hightlight: HightlightExpression | undefined = undefined,
        hightlightClassName: string | undefined = undefined,
    ) => ({
        width: "23.647vw",
        className: `row-xlarge-size ${className.join(" ")}`,
        render: (value: any, rowData: any) =>
            hightlight !== undefined ? (
                <span className={hightlight(value, rowData) ? hightlightClassName || "highlight" : ""}>
                    {value}
                </span>
            ) : (
                value
            ),

        ...values,
    }),
    XXLarge: (
        values: any,
        className: string[] = [],
        hightlight: HightlightExpression | undefined = undefined,
        hightlightClassName: string | undefined = undefined,
    ) => ({
        width: "28.902vw",
        className: `row-xxlarge-size ${className.join(" ")}`,
        render: (value: any, rowData: any) =>
            hightlight !== undefined ? (
                <span className={hightlight(value, rowData) ? hightlightClassName || "highlight" : ""}>
                    {value}
                </span>
            ) : (
                value
            ),

        ...values,
    }),
    Action: (values: any) => ({
        title: "",
        key: "Actions",
        width: "5.255vw",
        fixed: "right",
        ...values,
    }),
};

export const currencyRender = (value: string | number | undefined, decimalPoint: number = -1, defaultValue: any = undefined) => {
    if (isEmptyOrNull(value) || value === "NULL" || value === "null" || value === "NaN") return defaultValue !== undefined ? defaultValue : "";

    let returnText = (value as any).toString();
    if (returnText.indexOf(".") > -1) {
        let spStr: string[] = returnText.split(".");
        spStr[0] = spStr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        returnText = spStr.join(".");
    } else {
        returnText = returnText.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    if (decimalPoint !== -1) {
        if (returnText.indexOf(".") > -1) {
            if (returnText.length - (returnText.indexOf(".") + 1) < 2) {
                for (let index = returnText.length - (returnText.indexOf(".") + 1); index < 2; index++) {
                    returnText += "0";
                }

                return returnText;
            } else if (returnText.indexOf(".") + 1 + decimalPoint < returnText.length) {
                returnText = returnText.substr(0, returnText.indexOf(".") + 1 + decimalPoint);
            } else {
                for (let index = decimalPoint - (returnText.indexOf(".") + 1 - returnText.length); index < decimalPoint; index++) {
                    returnText += "0";
                }
            }
        } else {
            returnText += ".";
            for (let index = 0; index < decimalPoint; index++) {
                returnText += "0";
            }
        }
    }

    return returnText;
};

export const fillString = (value: string, stringLength: number, fillText: string = " ", fillFront: boolean = false) => {
    let finalStr: string[] = value.split("");
    if (finalStr.length < stringLength) {
        for (let index = finalStr.length; index < stringLength; index++) {
            if (fillFront) {
                finalStr.splice(0, 0, fillText);
            } else {
                finalStr.push(fillText);
            }
        }
    }

    return finalStr.join("");
};

export const getGUID = (isNumber: boolean = false) =>
    isNumber
        ? new Date().getTime() * -1
        : "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
            var r = (Math.random() * 16) | 0,
                v = c == "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });

export const convertNumToTime = (value: number) => {
    /**
     * 22/09/2022 Lee Xin
     * When account summary panel becomes more dynamic:
     *
     * 1) there will be an indicator in the future when user can congigure themselves which
     * format they want to be displayed, example in hours (hr min), mins (min sec) or secs)
     *    - so can use switch case/if...else condition
     * 2) better calculation to be precise
     *    - change value into seconds by (* 3600)
     *    - then change into whichever format user set up
     */

    // Check sign of given number
    let sign = value >= 0 ? 1 : -1;

    const hrs = Math.floor(Math.abs(value));
    const mins = Math.floor((Math.abs(value) * 60) % 60);

    // Add Sign in final result
    const newSign = sign === 1 ? "" : "-";

    // Concate hours and minutes
    const time = mins === 0 ? `${newSign}${hrs} hr` : hrs === 0 ? `${newSign}${mins} min` : `${newSign}${hrs} hr ${mins} min`;

    return time;
};

export const ErrorMessageHandler = (pagePrefix: string, promptMessage: number, extra: any = {}): void => {
    if (promptMessage > 0) {
        //success
        message.success(`${pagePrefix} ${SUCCESS_FAILED_MSG[promptMessage]}`);
    } else {
        //fail
        message.error(
            `${SUCCESS_FAILED_MSG[promptMessage]} ${pagePrefix}${extra.message && extra.message.length > 0 ? `: ${extra.message}` : ""}`,
            3
        );
    }
};

export const convertSymbolConfigDataToSymbolConfigFormSetUp = (from: SymbolConfigData, to: SymbolConfigFormSetUp): SymbolConfigFormSetUp => {
    let fromModel: SymbolConfigData = from;
    let newFormValue = {
        ...to,
        ...fromModel.priceOutageConfig,
        serverId: fromModel.serverId,
        server: fromModel.server,
        serverName: fromModel.sourceName,
        symbol: fromModel.symbol,
        cleanSymbol: fromModel.cleanSymbol,
        symbolTypeId: fromModel.symbolTypeId,
        symbolTradingHour: fromModel.symbolTradingHour,
        symbolUpcomingHolidays: fromModel.symbolUpcomingHolidays,
        midPriceMonitorConfigs: fromModel.midPriceMonitorConfigs,
        onMidPriceMonitorAlarmSound:
            fromModel.midPriceMonitorConfigs && fromModel.midPriceMonitorConfigs?.length > 0
                ? fromModel.midPriceMonitorConfigs[0].onMidPriceMonitorAlarmSound
                : false,
        midPriceMonitorStatus:
            fromModel.midPriceMonitorConfigs && fromModel.midPriceMonitorConfigs?.length > 0
                ? fromModel.midPriceMonitorConfigs[0].midPriceMonitorStatus
                : false,
        isWhitelistToMonitor: fromModel.isWhitelistToMonitor,
        symbolPriorityLevel: fromModel.symbolPriorityLevel,
        symbolId: fromModel.symbolId,
    };
    return newFormValue;
};

export const sleep = (seconds: number) => {
    return new Promise(resolve => setTimeout(resolve, seconds * 1000));
};

export const getBrandNameByBrandId = (brandId: number, brands: BrandsList[]) => {
    let idx = brands.findIndex(x => x.id === brandId);
    return idx > -1 ? brands[idx].brand : brandId;
};

export const getServerNameByServerId = (serverId: number, servers: ServersList[]) => {
    let idx = servers.findIndex(x => x.id === serverId);
    return idx > -1 ? servers[idx].server : serverId;
};

export const ErrorCatchValidator = (errObjCatch: any = {}, extraFn: any) => {
    if (errObjCatch && errObjCatch.name === "CanceledError") {
        console.log("Request canceled.");
    } else if (errObjCatch && errObjCatch.code === "ERR_NETWORK") {
        message.error("Server Error. Please contact your system administrator.");
    } else extraFn(errObjCatch);
};

export const roundUpValue = (value: number, decimalPoint: number = 2) => {
    const suffixes = ["", "K", "M", "B", "T"];
    let suffixIndex = 0;
    let formattedValue = Math.abs(value);

    while (formattedValue >= 1000 && suffixIndex < suffixes.length - 1) {
        formattedValue /= 1000;
        suffixIndex++;
    }

    const formattedString =
        formattedValue % 1 === 0
            ? `${formattedValue.toFixed(0)}${suffixes[suffixIndex]}`
            : `${formattedValue.toFixed(decimalPoint).replace(/\.?0+$/, "")}${suffixes[suffixIndex]}`;

    return value < 0 ? `-${formattedString}` : formattedString;
};

export const genMetricSelectorsByComponent = (mimMetrics: MimMetricsList[], componentId: INTRADAY_COMPONENTS_BY_ID, page?: string) => {
    return mimMetrics.length > 0
        ? SortList(
            mimMetrics
                .filter((x: MimMetricsList) => x.validForComponents.includes(componentId))
                .map((x: MimMetricsList, i: number) => {
                    const columnOrderingObj = x.extraParams !== null && JSON.parse(x.extraParams);
                    let orderSeq = columnOrderingObj[`${componentId}`];
                    return {
                        ...x,
                        metricName: x.metricName,
                        metricDisplayName: x.metricDisplayName,
                        metricOrder: typeof orderSeq !== "number" ? orderSeq[`${page}`] : orderSeq,
                    };
                })
                .filter(y => !isEmptyOrNull(y.metricOrder)),
            "metricOrder"
        )
        : [];
};

export const genMetricSelectorsByDimensions = (mimMetrics: MimMetricsList[], dimensionId: DIMENSION_LEVEL) => {
    return mimMetrics.length > 0
        ? SortList(
            mimMetrics
                .filter((x: MimMetricsList) => x.validForDimensions.includes(dimensionId))
                .map((x: MimMetricsList) => ({
                    ...x,
                    metricName: x.metricName,
                    metricDisplayName: x.metricDisplayName,
                    metricOrder: DIMENSION_METRICS_COL_ORDER[x.metricName],
                }))
                .filter(y => !isEmptyOrNull(y.metricOrder)),
            "metricOrder"
        )
        : [];
};

export const DimensionLevelObjectConfig: any = (type: string) => {
    switch (type) {
        case "IntradayDimensionArr":
            return [
                { value: "account_id", text: "Account ID", objKey: "", dLevel: 1 },
                { value: "symbol", text: "Symbol", objKey: "", dLevel: 2 },
                { value: "group", text: "Account Group", objKey: "", dLevel: 11 },
                { value: "server_id", text: "Server", objKey: "server", dLevel: 3 },
                { value: "brand_id", text: "Brand", objKey: "brand", dLevel: 4 },
                { value: "country", text: "Country", objKey: "country", dLevel: 5 },
                { value: "rebate_account_id", text: "IB (Rebate Account ID)", objKey: "", dLevel: 8 },
                { value: "rebate_server_id", text: "IB (Server)", objKey: "server", dLevel: 8 },
                { value: "symbol_asset_type_id", text: "Asset Type", objKey: "symbolAssetType", dLevel: 10 },
            ];
        case "LPDimensionArr":
            return [
                { value: "account_id", text: "Account ID" },
                { value: "symbol", text: "Symbol" },
                { value: "server_id", text: "Server" },
                { value: "brand_id", text: "Brand" },
                { value: "country", text: "Country" },
                { value: "rebate_account_id", text: "IB (Rebate Account ID)" },
                { value: "rebate_server_id", text: "IB (Server)" },
                { value: "symbol_asset_type_id", text: "Asset Type" },
                { value: "company", text: "Company" },
                { value: "marketgroup", text: "Market Group" },
                { value: "lp", text: "LP" },
                { value: "accountid", text: "Account ID" },
            ];
        case "IntradayAlarmDimensionArr":
            return [
                {
                    tkey: "dimension-1",
                    DimensionLevel: 1,
                    title: "Account ID",
                    requestKey: "server",
                    responseKey: "servers",
                    resId: "id",
                    resText: "server",
                    componentType: "rebateAccountSearch",
                    sequence: 1,
                },
                {
                    tkey: "dimension-11",
                    DimensionLevel: 11,
                    title: "Account Group",
                    componentType: ComponentType.dropdown,
                    sequence: 2,
                },
                {
                    tkey: "dimension-2",
                    DimensionLevel: 2,
                    title: "Symbol",
                    requestKey: "cleansymbol",
                    responseKey: "cleanSymbols",
                    resId: "name",
                    resText: "name",
                    componentType: ComponentType.checkboxgroup,
                    sequence: 3,
                },
                {
                    tkey: "dimension-10",
                    DimensionLevel: 10,
                    title: "Symbol Asset Type",
                    requestKey: "symbolassettype",
                    responseKey: "symbolAssetTypes",
                    resId: "id",
                    resText: "name",
                    componentType: ComponentType.checkboxgroup,
                    sequence: 4,
                },
                {
                    tkey: "dimension-3",
                    DimensionLevel: 3,
                    title: "Server",
                    requestKey: "server",
                    responseKey: "servers",
                    resId: "id",
                    resText: "server",
                    componentType: ComponentType.checkboxgroup,
                    sequence: 5,
                },
                {
                    tkey: "dimension-4",
                    DimensionLevel: 4,
                    title: "Brand",
                    requestKey: "brand",
                    responseKey: "brands",
                    resId: "id",
                    resText: "brand",
                    componentType: ComponentType.checkboxgroup,
                    sequence: 6,
                },
                {
                    tkey: "dimension-5",
                    DimensionLevel: 5,
                    title: "Country",
                    requestKey: "",
                    responseKey: "",
                    resId: "",
                    resText: "",
                    componentType: ComponentType.checkboxgroup,
                    sequence: 7,
                },
                {
                    tkey: "dimension-8",
                    DimensionLevel: 8,
                    title: "IB (Rebate Account)",
                    requestKey: "server",
                    responseKey: "servers",
                    resId: "id",
                    resText: "server",
                    componentType: "rebateAccountSearch",
                    sequence: 8,
                },
            ];
        case "IntradayAlarmDimensionOptions":
            return [
                {
                    text: "Account",
                    value: 1,
                },
                {
                    text: "Account Group",
                    value: 11,
                },
                {
                    text: "Symbol",
                    value: 2,
                },
                {
                    text: "Symbol Asset Type",
                    value: 10,
                },
                {
                    text: "Server",
                    value: 3,
                },
                {
                    text: "Brand",
                    value: 4,
                },
                {
                    text: "Country",
                    value: 5,
                },
                {
                    text: "IB",
                    value: 8,
                },
            ];
        case "LPAlarmDimensionArr":
            return [
                {
                    tkey: "dimension-1",
                    DimensionLevel: "MarketGroup",
                    title: "Market Group",
                    requestKey: "marketriskgroups",
                    responseKey: "mrGroups",
                    resId: "marketGroup",
                    resText: "marketGroup",
                    componentType: ComponentType.checkboxgroup,
                    sequence: 1,
                },
                {
                    tkey: "dimension-2",
                    DimensionLevel: "LP",
                    title: "LP",
                    requestKey: "marketrisklpaccount",
                    responseKey: "mrLPAccount",
                    resId: "",
                    resText: "",
                    componentType: ComponentType.checkboxgroup,
                    sequence: 2,
                },
                {
                    tkey: "dimension-3",
                    DimensionLevel: "AccountId",
                    title: "Account ID",
                    requestKey: "marketrisklpaccount",
                    responseKey: "mrLPAccount",
                    resId: "",
                    resText: "",
                    componentType: ComponentType.checkboxgroup,
                    sequence: 3,
                },
                {
                    tkey: "dimension-4",
                    DimensionLevel: "Symbol",
                    title: "Symbol",
                    requestKey: "symbol",
                    responseKey: "symbols",
                    resId: "name",
                    resText: "name",
                    componentType: ComponentType.checkboxgroup,
                    sequence: 4,
                },
            ];
        default:
            return [];
    }
};

export const isNumberValue = (value: any) => value !== undefined && value !== null && typeof value === "number";
