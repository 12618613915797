import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import FlexiDataTable from "../../../components/FlexiDataTable";
import { useGetGroupPurposesQuery } from "../../../store/apis/groupPurpose";
import { apiRequest, APIs } from "../../../services/apiConfig";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";
import CardBox from "../../../components/Common/CardBox";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import AuthHelper, { AuthKeys } from "../../../helpers/authHelper";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "../../../constants";
import { BrandsList, FlexiDataTableCallbackProps, FlexiDataTableOptionsProps, ServersList } from "../../../constants/type";

const GroupPurposeListPage = () => {
    const { isLoading, data, refetch } = useGetGroupPurposesQuery({});
    const [manualLoading, setManualLoading] = useState<boolean>(false);
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.ADMIN_GROUP_PURPOSE_EDIT);
    const [brands, setBrands] = useState<BrandsList[]>([]);
    const [servers, setServers] = useState<ServersList[]>([]);

    let navigate = useNavigate();

    useEffect(() => {
        getFilterConfigList();
        refetch();
        return () => {};
    }, []);

    const getFilterConfigList = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["server", "brand"] })
            .then((data: any) => {
                setServers(data.servers);
                setBrands(data.brands);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => console.log(err));
            })
            .finally(() => {
                setManualLoading(false);
            });
    };

    const columns = [
        DTColProps.Large({
            title: "Group Purpose",
            dataIndex: "groupPurpose",
            key: "groupPurpose",
            fixed: "left",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        {
            title: "Group Purpose Pattern",
            dataIndex: "groupPurposePattern",
            key: "groupPurposePattern",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        },
        DTColProps.Small({
            title: "Brand",
            dataIndex: "brandId",
            key: "brandId",
            render: (brandId: number) => {
                let idx = brands.findIndex((x) => x.id === brandId);
                return idx > -1 ? brands[idx].brand : brandId;
            },
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: brands.map((x) => ({ text: x.brand, value: x.id })),
                },
            },
        }),
        DTColProps.Small({
            title: "Server",
            dataIndex: "serverId",
            key: "serverId",
            render: (serverId: number) => {
                let idx = servers.findIndex((x) => x.id === serverId);
                return idx > -1 ? servers[idx].server : serverId;
            },
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: servers.map((x) => ({ text: x.server, value: x.id })),
                },
            },
        }),
        DTColProps.Small({
            title: "Include in Reports",
            dataIndex: "includeInReports",
            key: "includeInReports",
            align: "center",
            render: (includeInReportsFlag: boolean, rowData: any) => {
                if (rowData.includeInReports) {
                    return <CheckCircleOutlined style={{ color: "#0ab76e", fontSize: "1.375rem" }} />;
                } else {
                    return <CloseCircleOutlined style={{ color: "#f00f00", fontSize: "1.375rem" }} />;
                }
            },
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: [
                        {
                            text: "Yes",
                            value: true,
                        },
                        {
                            text: "No",
                            value: false,
                        },
                    ],
                },
            },
        }),
        DTColProps.Small({
            title: "Is Live",
            dataIndex: "isLive",
            key: "isLive",
            align: "center",
            render: (isLiveFlag: boolean, rowData: any) => {
                if (rowData.isLive) {
                    return <CheckCircleOutlined style={{ color: "#0ab76e", fontSize: "1.375rem" }} />;
                } else {
                    return <CloseCircleOutlined style={{ color: "#f00f00", fontSize: "1.375rem" }} />;
                }
            },
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: [
                        {
                            text: "Yes",
                            value: true,
                        },
                        {
                            text: "No",
                            value: false,
                        },
                    ],
                },
            },
        }),
    ];

    const options: FlexiDataTableOptionsProps = {
        add: () => {
            if (enableUpdate) {
                return "/siteadmin/serverbrand/group-purpose/create";
            } else return enableUpdate;
        },
        edit: (record: any, overwriteProps: any) => {
            if (enableUpdate) {
                let newProps = { ...overwriteProps };
                newProps.label = <Link to={`/siteadmin/serverbrand/group-purpose/edit/${record.id}`}>{newProps.label}</Link>;
                return newProps;
            } else return enableUpdate;
        },
        delete: enableUpdate,
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, GroupPurposeData) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                navigate("/siteadmin/serverbrand/group-purpose/create", { state: { data: null, action: "add" } });
                break;
            case CALLBACK_KEY.DO_EDIT:
                navigate(`/siteadmin/serverbrand/group-purpose/edit/${GroupPurposeData.id}`, {
                    state: { data: GroupPurposeData.id, action: "edit" },
                });
                break;
            case CALLBACK_KEY.DO_DELETE:
                setManualLoading(true);
                apiRequest(APIs.DELETE_GROUP_PURPOSE, {
                    groupPurposeId: GroupPurposeData.id,
                })
                    .then((data) => ErrorMessageHandler(GroupPurposeData.groupPurpose, SUCCESS_FAILED.SUCCESS_DELETE_DATA))
                    .catch((error) =>
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("group purpose", SUCCESS_FAILED.FAILED_DELETE_DATA, err))
                    )
                    .finally(() => {
                        setManualLoading(false);
                        refetch();
                    });
                break;
            default:
                break;
        }
    };

    return (
        <>
            <CardBox title={"Group Purpose Listing"}>
                <FlexiDataTable
                    rowKeyProperty="id"
                    title=""
                    columns={columns}
                    options={options}
                    dataSource={data?.data || []}
                    callback={componentCallback}
                    loading={isLoading || manualLoading}
                />
            </CardBox>
        </>
    );
};

export default GroupPurposeListPage;
