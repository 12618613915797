import { createApi } from "@reduxjs/toolkit/query/react";
import { APIs, defaultBaseQueryProps, ResponseProps } from "../../services/apiConfig";
import { AccRiskScore } from "../../constants/type";
import { axiosBaseQuery } from "../axiosBaseQuery";

export interface accRiskScoreRequest {}

export interface accRiskScoreResponse extends ResponseProps<AccRiskScore[]> {
    types_: any;
}

export const accRiskScoreApiSlice = createApi({
    reducerPath: "api_ars_1",
    baseQuery: axiosBaseQuery(),
    keepUnusedDataFor: 5, //global caching duration (seconds)
    endpoints: (builder) => ({
        getAccRiskScore: builder.query<accRiskScoreResponse, accRiskScoreRequest | undefined>({
            keepUnusedDataFor: 0, //disable for caching for particular api request
            query: (arg) => ({ ...defaultBaseQueryProps, url: APIs.GET_ACCOUNT_RISK_SCORE, method: "POST", params: arg }),
            transformResponse: (returnValue: accRiskScoreResponse, meta) => {
                return returnValue;
            },
        }),
    }),
});

export const { useGetAccRiskScoreQuery } = accRiskScoreApiSlice;
