import { HomeOutlined } from "@ant-design/icons";
import { Button, Col, Form, Popconfirm, Row, Tooltip } from "antd";
import { TransferItem } from "antd/lib/transfer";
import { useEffect, useState } from "react";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { FormComponent } from "../../../components/FormComponent";
import LoadingComponent from "../../../components/Loading";
import SitePageHeader from "../../../components/PageHeader";
import { ComponentType, SUCCESS_FAILED } from "../../../constants";
import { REQUIRED_FIELD } from "../../../constants/errorMessage";
import { AccountTypes, BrandRiskScore, metricItem } from "../../../constants/type";
import { apiRequest } from "../../../services/apiConfig";
import { APIs } from "../../../services/apis";
import { ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";

export type BrandRiskScoreState = {
    action: string;
    brandId: string;
};

type BrandItem = {
    id: number;
    brand: string;
};

const initialValue: BrandRiskScore = {
    id: 0,
    brandId: 0,
    brand: "",
    accountTypes: [],
    metrics: [],
};

const CreateEditTemplate = () => {
    let location = useLocation();
    let navigate = useNavigate();
    let com_state: BrandRiskScoreState = location.state as BrandRiskScoreState;

    const [currentState] = useState<BrandRiskScoreState>(com_state || { action: "add", data: null });
    const [isAddAction] = useState<boolean>(currentState.action === "add");
    const [loading, setLoading] = useState<boolean>(true);
    const [isOnSubmit] = useState<boolean>(false);
    const [comData, setComData] = useState<BrandRiskScore>({ ...initialValue });
    const [metrics, setMetrics] = useState<metricItem[]>([]);
    const [brands, setBrands] = useState<BrandItem[]>([]);
    const [selectedMetric, setSelectedMetric] = useState<string[]>([...initialValue.metrics.map((x) => x.metricId.toString())]);
    const [accountTypes, setAccountTypes] = useState<AccountTypes[]>([]);

    const [brandRiskScoreForm] = Form.useForm();

    useEffect(() => {
        getFilterConfigList();
        return () => {};
    }, []);

    const getFilterConfigList = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["accounttype"] })
            .then((data: any) => {
                setAccountTypes(data.accountTypes);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => console.log(err));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getAvailableMetricList = (): TransferItem[] => {
        return metrics.map<TransferItem>((x) => ({
            key: x.metricId.toString(),
            title: x.displayName,
        }));
    };

    const onSubmit = (values: any) => {
        let submitData: any = {
            brandId: isAddAction ? values.brand : values.brandId,
            accountTypes: values.accountTypes,
            metrics: values.metricSelected.map((x: any) => {
                return {
                    metricId: x,
                    value: values[`metric-${x}`],
                };
            }),
        };

        setLoading(true);
        if (isAddAction) {
            apiRequest(APIs.CREATE_BRAND_RISK_SCORE, submitData)
                .then((values) => {
                    ErrorMessageHandler("New brand risk score", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                    navigate("/siteadmin/riskscore/brand");
                })
                .catch((error) => {
                    ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("new brand risk score", SUCCESS_FAILED.FAILED_CREATE_DATA, err));
                    setLoading(false);
                });
        } else {
            apiRequest(APIs.UPDATE_BRAND_RISK_SCORE, submitData)
                .then((values) => {
                    ErrorMessageHandler("Existing brand risk score", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                    navigate("/siteadmin/riskscore/brand");
                })
                .catch((error) => {
                    ErrorCatchValidator(error, (err: any) =>
                        ErrorMessageHandler("existing brand risk score", SUCCESS_FAILED.FAILED_UPDATE_DATA, err)
                    );
                    setLoading(false);
                });
        }
    };

    useEffect(() => {
        setLoading(true);
        apiRequest(APIs.GET_RISK_SCORE_METRICS, {})
            .then((data: any) => {
                setMetrics(
                    data.metric.map((x: any) => ({
                        metricId: x.metricId,
                        metricTypeId: x.metricTypeId,
                        metricName: x.metricName,
                        dataTypeId: x.dataTypeId,
                        displayName: x.displayName,
                    }))
                );

                setBrands(data.brands);

                if (!isAddAction) {
                    apiRequest(APIs.GET_BRAND_RISK_SCORE_LIST, { brandId: currentState.brandId })
                        .then((data: any) => {
                            let current_data: BrandRiskScore = data[0] as BrandRiskScore;
                            let currentValues: any = {
                                brand: current_data.brand,
                                brandId: current_data.brandId,
                                accountTypes: current_data.accountTypes,
                                metricSelected: current_data.metrics.map((x) => x.metricId.toString()),
                            };
                            setComData(current_data);
                            setSelectedMetric(
                                current_data.metrics.map((x) => {
                                    currentValues[`metric-${x.metricId}`] = x.value.toString();
                                    return x.metricId.toString();
                                })
                            );
                            brandRiskScoreForm.setFieldsValue(currentValues);
                        })
                        .catch((error: any) => {
                            ErrorCatchValidator(error, (err: any) =>
                                ErrorMessageHandler("brand risk score list", SUCCESS_FAILED.FAILED_LOAD_DATA, err)
                            );
                            navigate("/siteadmin/riskscore/brand");
                        })
                        .finally(() => {
                            setLoading(false);
                        });
                } else {
                    setLoading(false);
                }
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("risk score brand data", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                navigate("/siteadmin/riskscore/brand");
            });
        return () => {};
    }, []);

    const deleteBrandRiskScore = (brandId: string, navigator: NavigateFunction) => {
        apiRequest(APIs.DELETE_BRAND_RISK_SCORE, { brandId })
            .then((data) => {
                ErrorMessageHandler("The brand risk score", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                navigator("/siteadmin/riskscore/brand");
            })
            .catch((error) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("brand risk score", SUCCESS_FAILED.FAILED_DELETE_DATA, err))
            );
    };

    return (
        <>
            <SitePageHeader
                title={isAddAction ? "Create New Brand Risk Score" : "Edit Brand Risk Score"}
                routes={[
                    {
                        path: "/siteadmin/riskscore/brand",
                        breadcrumbName: "Brand Risk Score Configuration",
                        icon: <HomeOutlined />,
                    },
                    {
                        path: "",
                        breadcrumbName: isAddAction ? "Create New Brand Risk Score" : "Edit Brand Risk Score",
                    },
                ]}
                onBack={() => navigate("/siteadmin/riskscore/brand")}
                extraProps={{
                    extra: isAddAction
                        ? []
                        : [
                              <Popconfirm
                                  key={"cetmp-del"}
                                  title="Confirm to delete?"
                                  onConfirm={() => deleteBrandRiskScore(currentState.brandId, navigate)}
                                  okText="Yes"
                                  cancelText="No"
                              >
                                  <Button type="primary" danger>
                                      Delete Brand Risk Score
                                  </Button>
                              </Popconfirm>,
                          ],
                }}
            >
                {loading ? (
                    <div className="loading-container">
                        <LoadingComponent tip="Loading..." />
                    </div>
                ) : (
                    <div className="brand-risk-score-form-container">
                        <Form form={brandRiskScoreForm} layout="horizontal" initialValues={comData} onFinish={onSubmit}>
                            <Row className="main-row">
                                <Col span={12}>
                                    <Row>
                                        <Col span={24}>
                                            {isAddAction ? (
                                                <FormComponent
                                                    label="Brands"
                                                    name={"brand"}
                                                    extra={{
                                                        type: ComponentType.dropdown,
                                                        itemProps: {
                                                            labelAlign: "left",
                                                            labelCol: { span: 5 },
                                                        },
                                                        inputProps: {
                                                            style: { width: "100%" },
                                                            allowClear: true,
                                                        },
                                                        value: brands.map((x) => ({
                                                            text: x.brand,
                                                            value: x.id.toString(),
                                                        })),
                                                        rules: [{ required: true, message: REQUIRED_FIELD }],
                                                    }}
                                                />
                                            ) : (
                                                <>
                                                    <FormComponent
                                                        label=""
                                                        name={"brandId"}
                                                        extra={{
                                                            type: ComponentType.hidden,
                                                            value: "",
                                                        }}
                                                    />
                                                    <FormComponent
                                                        label="Brands"
                                                        name={"brand"}
                                                        extra={{
                                                            type: ComponentType.labelOnly,
                                                            value: "",
                                                            itemProps: {
                                                                labelCol: { span: 5 },
                                                                labelAlign: "left",
                                                            },
                                                        }}
                                                    />
                                                </>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <FormComponent
                                                label="Account Type"
                                                name="accountTypes"
                                                extra={{
                                                    type: ComponentType.dropdown,
                                                    itemProps: {
                                                        labelAlign: "left",
                                                        labelCol: { span: 6 },
                                                    },
                                                    inputProps: {
                                                        style: { width: "100%" },
                                                        mode: "multiple",
                                                        allowClear: true,
                                                    },
                                                    value: accountTypes.map((x) => ({ text: x.name, value: x.id })),
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <FormComponent
                                                label={""}
                                                name={"metricSelected"}
                                                extra={{
                                                    type: ComponentType.transfer,
                                                    inputProps: {
                                                        titles: [
                                                            <span className="bold-title">Available Metrics</span>,
                                                            <span className="bold-title">Selected Metrics</span>,
                                                        ],
                                                        listStyle: { width: "100%", minHeight: "56.56vh" },
                                                        dataSource: getAvailableMetricList(),
                                                        showSearch: true,
                                                        filterOption: (inputValue: string, option: any) =>
                                                            option.title.toLowerCase().indexOf(inputValue.toLowerCase()) > -1,
                                                        targetKeys: selectedMetric,
                                                        onChange: (targetKeys: string[]) => {
                                                            setSelectedMetric(targetKeys);
                                                        },
                                                        render: (item: TransferItem) => (
                                                            <Tooltip title={item.description}>{item.title || ""}</Tooltip>
                                                        ),
                                                    },
                                                    value: "",
                                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={12}>
                                    <div className="selected-metric-title">
                                        <span>Selected Metrics: </span>
                                    </div>
                                    <div className="selected-metric-panel">
                                        {selectedMetric.map((x) => {
                                            let idx = metrics.findIndex((y) => y.metricId.toString() === x);
                                            const currentItem: metricItem = metrics[idx];
                                            return (
                                                <div key={`brs-mt-${currentItem.metricId}`} className="metric-item">
                                                    <FormComponent
                                                        label={currentItem.displayName}
                                                        name={`metric-${x}`}
                                                        extra={{
                                                            type: ComponentType.number,
                                                            value: "",
                                                            rules: [{ required: true, message: REQUIRED_FIELD }],
                                                        }}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <div className="step-btns-group">
                                        <Button
                                            loading={isOnSubmit}
                                            type="primary"
                                            htmlType="button"
                                            onClick={() => {
                                                brandRiskScoreForm
                                                    .validateFields()
                                                    .then((values) => {
                                                        onSubmit(values);
                                                    })
                                                    .catch((error) => {
                                                        brandRiskScoreForm.submit();
                                                    });
                                            }}
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                )}
            </SitePageHeader>
        </>
    );
};

export default CreateEditTemplate;
