import { create } from "zustand";

interface IDisplay {
    success: boolean;
    // warning: boolean;
    danger: boolean;
}

type RCMetaTraderStore = {
    display: IDisplay;
};
type RCMetaTraderStoreActions = {
    updateDisplay: (newDisplay: Partial<IDisplay>) => void;
};

const useRCMetaTraderStore = create<RCMetaTraderStore & RCMetaTraderStoreActions>(set => ({
    display: {
        success: false,
        // warning: true,
        danger: true,
    },
    updateDisplay: newDisplay =>
        set(state => ({
            display: { ...state.display, ...newDisplay },
        })),
}));

export default useRCMetaTraderStore;
