import { Button, Form, Input, Modal, Space, Table, Typography, notification } from "antd";
import React, { useState } from "react";
import { plainAxiosInstance } from "../../../services/axiosSetup";
import { APIs } from "../../../services/apis";
import MessageCard from "../components/MessageCard";
import { useRCIssuesLogMutate } from "../../../hooks/useRCIssuesLog";
import { read, utils } from "xlsx";

const columns = [
    {
        title: "Server Code",
        dataIndex: "serverCode",
        key: "serverCode",
    },
    {
        title: "Login ID",
        dataIndex: "loginId",
        key: "loginId",
    },
];

const CommonIssuesLogTypeDownload = ({
    type,
    fileExtension = ".xlsx",
    canUpload = true,
}: {
    type: "ACCOUNT_MONITOR" | "MANAGER_ACCOUNT_INTERCEPTED";
    fileExtension?: string;
    canUpload?: boolean;
}) => {
    const fileName = type;
    const downloadURL = () => {
        switch (type) {
            case "ACCOUNT_MONITOR":
                return APIs.RC_ISSUES_LOG.POST_DOWNLOAD_ACCOUNT_MONITOR;
            case "MANAGER_ACCOUNT_INTERCEPTED":
                return APIs.RC_ISSUES_LOG.POST_DOWNLOAD_MANAGER_ACCOUNT_INTERCEPTED;
        }
    };
    const { Title, Text } = Typography;
    const [openUploadModal, setOpenUploadModal] = useState(false);

    // get todayDate YYYYMMDD
    const todayDate = new Date();
    const todayDateStr = todayDate.toISOString().split("T")[0].replace(/-/g, "");

    const { uploadIssuesLogTypeList } = useRCIssuesLogMutate({ type });

    const [formUpload] = Form.useForm();
    const [uploadFileBlob, setUploadFileBlob] = useState<Blob | null>(null);
    const { Item } = Form;
    const initFormUploadValue = {
        file: null,
    };
    const [previewFile, setPreviewFile] = useState<any>(null);

    const handleUpload = async ({ formData }: { formData: FormData }) => {
        try {
            const resp = await uploadIssuesLogTypeList({ formData, type });

            if (resp.status === 200 && resp?.data !== 0) {
                notification.error({
                    message: "Failed to upload settings",
                    description: "Error: " + resp?.data,
                });
            } else {
                notification.success({
                    message: "Settings uploaded",
                    description: "Settings uploaded successfully",
                });
                formUpload.resetFields();
                setOpenUploadModal(false);
            }
            // console.log(resp);
        } catch (err) {
            notification.error({
                message: "Failed to upload settings",
                description: `Error: ${err}`,
            });
            console.log("error while uploading settings", err);
        }
    };

    const handlePreview = async () => {
        const dummyFormData = new FormData();
        plainAxiosInstance
            .post(downloadURL(), dummyFormData, {
                headers: {
                    Accept: "application/octet-stream,text/csv, */*",
                },
                responseType: "blob",
            })
            .then(response => {
                // console.log(response);
                // Check if the response content type is 'application/octet-stream'
                const contentType = response.headers["content-type"];
                if (
                    contentType === "application/octet-stream" ||
                    contentType === "text/csv" ||
                    contentType === "text/csv;charset=UTF-8" ||
                    contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ) {
                    const reader = new FileReader();
                    reader.onload = e => {
                        const data = e.target?.result;
                        const workbook = read(data, { type: "array" });
                        const firstSheetName = workbook.SheetNames[0];
                        const worksheet = workbook.Sheets[firstSheetName];
                        const dataParse = utils.sheet_to_json(worksheet);
                        setPreviewFile(dataParse);
                    };
                    reader.readAsArrayBuffer(response.data);
                } else {
                    notification.error({
                        message: "Error",
                        description: `Received non-file response. Error: ${response}`,
                    });
                    console.log("Received non-file response:", response);
                }
            })
            .catch(err => {
                notification.error({
                    message: "Error",
                    description: `Download error: ${err}`,
                });
                console.log("download error", err);
            });
    };

    const handleDownload = async () => {
        const dummyFormData = new FormData();
        plainAxiosInstance
            .post(downloadURL(), dummyFormData, {
                headers: {
                    Accept: "application/octet-stream,text/csv, */*",
                },
                responseType: "blob",
            })
            .then(response => {
                // console.log(response);
                // Check if the response content type is 'application/octet-stream'
                const contentType = response.headers["content-type"];
                if (
                    contentType === "application/octet-stream" ||
                    contentType === "text/csv" ||
                    contentType === "text/csv;charset=UTF-8" ||
                    contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ) {
                    // Handle the file download response
                    const url = window.URL.createObjectURL(
                        new Blob([response.data], {
                            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
                        })
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `${fileName}_${todayDateStr}${fileExtension}`);
                    document.body.appendChild(link);
                    link.click();
                    // Clean up
                    window.URL.revokeObjectURL(url);
                    notification.success({
                        message: "Downloaded",
                        description: `${fileName} downloaded successfully`,
                    });
                } else {
                    notification.error({
                        message: "Error",
                        description: `Received non-file response. Error: ${response}`,
                    });
                    console.log("Received non-file response:", response);
                }
            })
            .catch(err => {
                notification.error({
                    message: "Error",
                    description: `Download error: ${err}`,
                });
                console.log("download error", err);
            });
    };
    return (
        <Space direction="vertical" style={{ width: "100%", padding: "1rem" }}>
            <Title level={5}>Account Monitor list</Title>
            <Space style={{ width: "100%" }}>
                <Button onClick={() => handlePreview()}>Preview</Button>
                {canUpload && <Button onClick={() => setOpenUploadModal(true)}>Import</Button>}
                <Button onClick={() => handleDownload()}>Download</Button>
            </Space>

            <Table
                columns={columns}
                dataSource={previewFile ?? []}
                // pagination={false}
                rowKey={each => each.serverCode + each.loginId}
                size="small"
                // replace no data wording
                locale={{
                    emptyText: <MessageCard type="info">{!previewFile ? "Please click on preview" : "No data found"}</MessageCard>,
                }}
            />
            <Form
                form={formUpload}
                layout="vertical"
                initialValues={initFormUploadValue}
                /* onValuesChange={handleFormUpdate} */ requiredMark={true}
                onFinish={() => {
                    // const formFile = formUpload.getFieldValue("file");
                    // // convert formFile to File blob
                    // const formFileBlob = new Blob([formFile], { type: "text/csv" });

                    const formData = new FormData();
                    formData.append("uploadFile", uploadFileBlob as Blob);
                    handleUpload({ formData });
                }}
            >
                <Modal
                    title={"Upload settings"}
                    open={openUploadModal}
                    onCancel={() => {
                        formUpload.resetFields();
                        setOpenUploadModal(false);
                        setUploadFileBlob(null);
                    }}
                    onOk={() => formUpload.submit()}
                    okText="Import"
                    okButtonProps={{ danger: true }}
                >
                    <Space style={{ width: "100%" }} direction="vertical" size={16}>
                        <div>
                            <Text>Excel File *</Text>
                            <Item
                                style={{ margin: 0, padding: 0 }}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                required
                                name="file"
                                rules={[{ required: true, message: "Excel File is required" }]}
                            >
                                <Input
                                    type="file"
                                    // accept both .xlsx and .XLSX
                                    accept=".xlsx, .XLSX"
                                    onChange={e => {
                                        if (e.target.files && e.target.files.length > 0) {
                                            formUpload.setFieldsValue({ file: e.target.value });
                                            setUploadFileBlob(e.target.files[0]);
                                        } else {
                                            formUpload.setFieldsValue({ file: null });
                                            setUploadFileBlob(null);
                                        }
                                    }}
                                />
                            </Item>
                        </div>
                        <MessageCard type="info">This will replace duplicated settings</MessageCard>
                    </Space>
                </Modal>
            </Form>
        </Space>
    );
};

export default CommonIssuesLogTypeDownload;
