import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Steps, Divider, message, Space, Select } from "antd";
import { ArrowLeftOutlined, ArrowRightOutlined, ExclamationCircleOutlined, HomeOutlined, SearchOutlined } from "@ant-design/icons";
import { ErrorCatchValidator, ErrorMessageHandler } from "../../../../../utils/Common";
import { SUCCESS_FAILED } from "../../../../../constants";
import { APIs } from "../../../../../services/apis";
import { plainAxiosInstance } from "../../../../../services/axiosSetup";
import { stepInfo } from "../stepInfo";
import {
    KeyValuePair,
    RollOverAdjustmentList,
    RollOverBatchRecordList,
    RollOverDeductionList,
    RollOverOpenPositionList,
    RollOverServerList,
    RollOverStopOutList,
    RollOverSupplementaryList,
    RollOverVerificationList,
} from "../../../../../constants/type";
import AuthHelper, { AuthKeys } from "../../../../../helpers/authHelper";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SitePageHeader from "../../../../../components/PageHeader";
import LoadingComponent from "../../../../../components/Loading";
import moment from "moment";
import RollOverAdjustmentTable from "./RollOverAdjustmentTable";
import RollOverOpenPositionTable from "./RollOverOpenPositionTable";
import RollOverSuppllementaryTable from "./RollOverSupplementaryTable";
import RollOverDeductionTable from "./RollOverDeductionTable";
import RollOverVerificationTable from "./RollOverVerificationTable";
import RollOverComparisonTable from "./RollOverComparisonTable";
import RollOverStopOutTable from "./RollOverStopOutTable";

export enum SEC {
    OPEN_POSITION = 1,
    SUPPLEMENTARY = 2,
    DEDUCTION = 3,
    VERIFICATION = 4,
}

export type RollOverStepProps = {
    action: string;
    data: any;
};

const executeStatusLabel = [
    {
        label: "Init",
        value: 0,
    },
    {
        label: "Processing",
        value: 1,
    },
    {
        label: "Success",
        value: 2,
    },
    {
        label: "Failed",
        value: 3,
    },
    {
        label: "Not create execute record",
        value: -1,
    },
];

function RollOverStep() {
    let navigate = useNavigate();
    let location = useLocation();
    let com_state: RollOverBatchRecordList = location.state.data as RollOverBatchRecordList;
    const [currentState, setCurrentState] = useState<RollOverBatchRecordList>(com_state);
    let { id } = useParams();
    const alarmTime = 5000;
    const [current, setCurrent] = useState(1);

    const [stepItems, setStepItems] = useState(
        stepInfo.map(item => ({
            key: item.value,
            title: item.label,
            description: item.description,
        }))
    );
    const intervalRef = useRef<ReturnType<typeof setTimeout> | null>(null);
    const [adjustmentData, setAdjustmentData] = useState<RollOverAdjustmentList[]>([]);
    const [adjustmentLoading, setAdjustmentLoading] = useState<boolean>(false);
    const [emailTo, setEmailTo] = useState<string>("");

    const [serverDetailList, setServerDetailList] = useState<KeyValuePair[]>([]);
    const [openPositionServerId, setOpenPositionServerId] = useState<string>("");
    const [supplementaryServerId, setSupplementaryServerId] = useState<string>("");
    const [deductionServerId, setDeductionServerId] = useState<string>("");
    const [verificationServerId, setVerificationServerId] = useState<string>("");

    const [openPositionData, setOpenPositionData] = useState<RollOverOpenPositionList[]>([]);
    const [openPositionLoading, setOpenPositionLoading] = useState<boolean>(false);

    const [supplementaryData, setSupplementaryData] = useState<RollOverSupplementaryList[]>([]);
    const [supplementaryLoading, setSupplementaryLoading] = useState<boolean>(false);
    const [supplementaryExecutestatus, setSupplementaryExecutestatus] = useState<any>({});

    const [deductionData, setDeductionData] = useState<RollOverDeductionList[]>([]);
    const [deductionLoading, setDeductionLoading] = useState<boolean>(false);
    const [deductionExecutestatus, setDeductionExecutestatus] = useState<any>({});

    const [verificationData, setVerificationData] = useState<RollOverVerificationList[]>([]);
    const [verificationLoading, setVerificationLoading] = useState<boolean>(false);

    const [totalSupplementary, setTotalSupplementary] = useState<number>(0);
    const [totalDeduction, setTotalDeduction] = useState<number>(0);
    const [totalPositiveProfit, setTotalPositiveProfit] = useState<number>(0);
    const [totalNegativeProfit, setTotalNegativeProfit] = useState<number>(0);
    const [totalSupplementaryDeduction, setTotalSupplementaryDeduction] = useState<number>(0);
    const [totalProfit, setTotalProfit] = useState<number>(0);

    const [stopOutData, setStopOutData] = useState<any[]>([]);
    const [stopOutLoading, setStopOutLoading] = useState<boolean>(false);

    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.RISK_TOOLS_ROLLOVER_EDIT);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(true);
    const queryServerDetail = async () => {
        setIsLoading(true);
        plainAxiosInstance
            .get(`${APIs.RISK_TOOL.GET_ROLLOVER_SERVER_LIST}`)
            .then((res: any) => {
                // const realData: RollOverServerList[] = getRollOverServerListDummyData;
                const realData: RollOverServerList[] = res.data;
                if (realData.length > 0) {
                    const serverList = realData.map((server: any) => ({ text: server.serverName, value: server.serverId }));
                    setServerDetailList([...serverList, { text: "All", value: "" }]);
                } else {
                    setServerDetailList([]);
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => {
                    ErrorMessageHandler("roll over server list", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                    setServerDetailList([]);
                })
            )
            .finally(() => setIsLoading(false));
    };

    const queryBatchRecord = async () => {
        setIsLoading(true);
        plainAxiosInstance
            .get(`${APIs.RISK_TOOL.GET_ROLLOVER_BATCH_RECORD}?batchId=${currentState.id}`)
            .then((res: any) => {
                setCurrentState({ ...currentState, currentStep: res.data.currentStep, updateDate: res.data.updateDate });
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("roll over batch record", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsLoading(false));
    };

    const queryAdjustmentData = async () => {
        setAdjustmentLoading(true);
        plainAxiosInstance
            .get(`${APIs.RISK_TOOL.GET_ROLLOVER_ADJUSTMENT_LIST}?batchId=${currentState.id}`)
            .then((res: any) => {
                const realData: RollOverAdjustmentList[] = res.data;
                // const realData: RollOverAdjustmentList[] = getAdjustmentListDummyData;
                const adjData: RollOverAdjustmentList[] = realData.map((item: any, index: number) => ({
                    ...item,
                    uniqueKey: `${item.batchId}_${item.serverId}`,
                }));
                setAdjustmentData(adjData);
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => {
                    ErrorMessageHandler("roll over adjustment data", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                    setAdjustmentData([]);
                })
            )
            .finally(() => setAdjustmentLoading(false));
    };

    const queryAdjustmentValueEmailTo = async () => {
        setIsLoading(true);
        plainAxiosInstance
            .get(`${APIs.RISK_TOOL.GET_ROLLOVER_ADJUSTMENT_VALUE_EMAIL_TO}`)
            .then((res: any) => {
                setEmailTo(res.data);
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) =>
                    ErrorMessageHandler("roll over adjustment value email to data", SUCCESS_FAILED.FAILED_LOAD_DATA, err)
                )
            )
            .finally(() => setIsLoading(false));
    };

    const sendAdjustmentValueMail = async () => {
        setAdjustmentLoading(true);
        Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            title: "Are you sure to send adjustment value mail to these emails below?",
            content: <ol>{
                emailTo.split(",").map((item: string, index: number) => (
                    <li key={index}>{item}</li>
                ))
            }</ol>,
            width: "30%",
            onOk() {
                plainAxiosInstance
                    .get(`${APIs.RISK_TOOL.GET_ROLLOVER_SEND_ADJUSTMENT_VALUE_EMAIL}?batchId=${currentState.id}`)
                    .then(res => {
                        if (res.data === "success") {
                            message.success("Send mail successfully.", 3);
                            setRunRefetchDataList(true);
                        } else {
                            message.error(res.data, 3);
                        }
                    })
                    .catch((error: any) => {
                        message.error(`Send mail failed: (${error.response.data.message})`, 3);
                    })
                    .finally(() => setAdjustmentLoading(false));
            },
            onCancel: () => {
                setAdjustmentLoading(false);
            },
        });
    };

    const calcOpenPosition = async () => {
        setOpenPositionLoading(true);
        Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            title: "Are you sure calculate open position adjustment?",
            width: "30%",
            onOk() {
                plainAxiosInstance
                    .get(`${APIs.RISK_TOOL.GET_ROLLOVER_CALC_OPEN_POSITION}?batchId=${currentState.id}`)
                    .then(res => {
                        if (res.data === "success") {
                            message.success("Calculate successfully.", 3);
                            queryOpenPositionData();
                            queryBatchRecord();
                        } else {
                            message.error(res.data, 3);
                        }
                    })
                    .catch((error: any) => {
                        message.error(`Calculate failed: (${error.response.data.message})`, 3);
                    })
                    .finally(() => setRunRefetchDataList(true));
            },
            onCancel: () => {
                setOpenPositionLoading(false);
            },
        });
    };

    const queryOpenPositionData = async (secID?: string) => {
        const serverId = secID !== undefined ? secID : (openPositionServerId !== undefined ? openPositionServerId : "");
        setOpenPositionLoading(true);
        plainAxiosInstance
            .get(`${APIs.RISK_TOOL.GET_ROLLOVER_OPEN_POSITION_LIST}?batchId=${currentState.id}&serverId=${serverId}`)
            .then((res: any) => {
                const realData: RollOverOpenPositionList[] = res.data;
                // const realData: RollOverOpenPositionList[] = getOpenPositionListDummyData;
                const opList: any[] = realData.map((item: any, index: number) => ({
                    ...item,
                    uniqueKey: `${item.batchId}_${item.server}_${item.ticket}`,
                }));
                setOpenPositionData(opList);
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => {
                    ErrorMessageHandler("roll over open position data", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                    setOpenPositionData([]);
                })
            )
            .finally(() => setOpenPositionLoading(false));
    };

    const queryExecuteSupplementaryDeductionStatus = () => {
        plainAxiosInstance
            .get(`${APIs.RISK_TOOL.GET_ROLLOVER_EXECUTE_SUPPLEMENTARY_DEDUCTION_STATUS}?batchId=${currentState.id}`)
            .then((res: any) => {
                setSupplementaryExecutestatus(res.data.supplementary);
                setDeductionExecutestatus(res.data.deduction);
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) =>
                    ErrorMessageHandler("roll over execute supplementary deduction status data", SUCCESS_FAILED.FAILED_LOAD_DATA, err)
                )
            )
            .finally(() => { });
    };

    const clearExecuteSupplementaryDeductionStatus = () => {
        setSupplementaryExecutestatus({});
        setDeductionExecutestatus({});
    };

    const getExecuteStatusLabel = (value: number) => {
        return executeStatusLabel.filter(status => status.value == value).map(status => status.label);
    };

    const querySupplementaryData = async (secID?: string) => {
        const serverId = secID !== undefined ? secID : (supplementaryServerId !== undefined ? supplementaryServerId : "");
        setSupplementaryLoading(true);
        plainAxiosInstance
            .get(`${APIs.RISK_TOOL.GET_ROLLOVER_SUPPLEMENTARY_LIST}?batchId=${currentState.id}&serverId=${serverId}`)
            .then((res: any) => {
                // const supData: RollOverSupplementaryList[] = getSupplementaryListDummyData;
                setSupplementaryData(res.data);
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => {
                    ErrorMessageHandler("roll over execute supplementary deduction status data", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                    setSupplementaryData([]);
                })
            )
            .finally(() => setSupplementaryLoading(false));
    };

    const executeSupplementary = async () => {
        setSupplementaryLoading(true);
        Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            title: "Are you sure execute supplementary?",
            width: "30%",
            onOk() {
                plainAxiosInstance
                    .get(`${APIs.RISK_TOOL.GET_ROLLOVER_EXECUTE_SUPPLEMENTARY}?batchId=${currentState.id}`)
                    .then(res => {
                        if (res.data === 0) {
                            message.success("Execute Supplementary successfully.", 3);
                            setRunRefetchDataList(true);
                        } else {
                            message.error(res.data, 3);
                        }
                    })
                    .catch((error: any) => {
                        message.error(`Execute Supplementary failed: (${error.response.data.message})`, 3);
                    })
                    .finally(() => setSupplementaryLoading(false));
            },
            onCancel: () => {
                setSupplementaryLoading(false);
            },
        });
    };

    const refreshSupplementaryStatus = async () => {
        setSupplementaryLoading(true);
        plainAxiosInstance
            .get(`${APIs.RISK_TOOL.GET_ROLLOVER_REFRESH_SUPPLEMENTARY_STATUS}?batchId=${currentState.id}`)
            .then(async (res: any) => {
                if (res.data === "success") {
                    message.success("Refresh supplemented status success");
                    await querySupplementaryData();
                } else {
                    message.warning("Refresh supplemented status failed");
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) =>
                    ErrorMessageHandler("roll over refresh supplemented status data", SUCCESS_FAILED.FAILED_LOAD_DATA, err)
                )
            )
            .finally(() => setSupplementaryLoading(false));
    };

    const finishSupplementaryStep = async () => {
        setSupplementaryLoading(true);
        Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            title: "Are you sure finish supplementary step?",
            width: "30%",
            onOk() {
                plainAxiosInstance
                    .get(`${APIs.RISK_TOOL.GET_ROLLOVER_FINISH_SUPPLEMENTARY_STEP}?batchId=${currentState.id}`)
                    .then(res => {
                        if (res.data === "success") {
                            message.success("Finish supplementary step successfully.", 3);
                            setCurrent(currentState.currentStep);
                            queryBatchRecord();
                            setRunRefetchDataList(true);
                        } else {
                            message.error(res.data, 3);
                        }
                    })
                    .catch((error: any) => {
                        message.error(`Finish supplementary step failed: (${error.response.data.message})`, 3);
                    })
                    .finally(() => setSupplementaryLoading(false));
            },
            onCancel: () => {
                setSupplementaryLoading(false);
            },
        });
    };

    const queryDeductionData = async (secID?: string) => {
        const serverId = secID !== undefined ? secID : (deductionServerId !== undefined ? deductionServerId : "");
        setDeductionLoading(true);
        plainAxiosInstance
            .get(`${APIs.RISK_TOOL.GET_ROLLOVER_DEDUCTION_LIST}?batchId=${currentState.id}&serverId=${serverId}`)
            .then(async (res: any) => {
                // const deductData: RollOverDeductionList[] = getDeductionListDummyData;
                setDeductionData(res.data);
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => {
                    ErrorMessageHandler("roll over deduction list", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                    setDeductionData([]);
                })
            )
            .finally(() => setDeductionLoading(false));
    };

    const executeDeduction = async () => {
        setDeductionLoading(true);
        Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            title: "Are you sure execute deduction?",
            width: "30%",
            onOk() {
                plainAxiosInstance
                    .get(`${APIs.RISK_TOOL.GET_ROLLOVER_EXECUTE_DEDUCTION}?batchId=${currentState.id}&serverId=${deductionServerId}`)
                    .then(res => {
                        if (res.data === "success") {
                            message.success("Execute Deduction successfully.", 3);
                        } else {
                            message.error(res.data, 3);
                        }
                    })
                    .catch((error: any) => {
                        message.error(`Execute Deduction failed: (${error.response.data.message})`, 3);
                    })
                    .finally(() => setRunRefetchDataList(true));
            },
            onCancel: () => {
                setDeductionLoading(false);
            },
        });
    };

    const refreshDeductionStatus = async () => {
        setDeductionLoading(true);
        plainAxiosInstance
            .get(`${APIs.RISK_TOOL.GET_ROLLOVER_REFRESH_DEDUCTION_STATUS}?batchId=${currentState.id}`)
            .then(async (res: any) => {
                if (res.data === "success") {
                    message.success("Refresh deduction status success");
                    await queryDeductionData();
                } else {
                    message.warning("Refresh deduction status failed");
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) =>
                    ErrorMessageHandler("roll over refresh deduction status data", SUCCESS_FAILED.FAILED_LOAD_DATA, err)
                )
            )
            .finally(() => setDeductionLoading(false));
    };

    const finishDeductionStep = async () => {
        setDeductionLoading(true);
        Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            title: "Are you sure finish deduction step?",
            width: "30%",
            onOk() {
                plainAxiosInstance
                    .get(`${APIs.RISK_TOOL.GET_ROLLOVER_FINISH_DEDUCTION_STATUS}?batchId=${currentState.id}`)
                    .then(res => {
                        if (res.data === "success") {
                            queryBatchRecord();
                            message.success("Finish deduction step successfully.", 3);
                        } else {
                            message.error(res.data, 3);
                        }
                    })
                    .catch((error: any) => {
                        message.error(`Finish deduction step failed: (${error.response.data.message})`, 3);
                    })
                    .finally(() => setRunRefetchDataList(true));
            },
            onCancel: () => {
                setDeductionLoading(false);
            },
        });
    };

    const queryVerificationAndStopOutData = async () => {
        queryVerificationData();
        queryStopOutData();
    };

    const getFetchApi = (apimethod: any, batchId: any, serverId: any) => {
        return plainAxiosInstance.get(`${apimethod}?batchId=${batchId}&serverId=${serverId}`);
    };

    const getPromiseList = (apimethod: string) => {
        const promiseList = [];
        if (verificationServerId !== undefined && verificationServerId.length > 0) {
            promiseList.push(getFetchApi(apimethod, currentState.id, verificationServerId));
        } else {
            serverDetailList.map(server => {
                if (server.text !== "All") promiseList.push(getFetchApi(apimethod, currentState.id, server.value));
            });
        }
        return promiseList;
    };

    const queryVerificationData = async () => {
        setVerificationLoading(true);
        const promiseList = getPromiseList(APIs.RISK_TOOL.GET_ROLLOVER_VERIFICATION_LIST);

        const results: any[] = [];
        Promise.all(promiseList)
            .then(res => {
                res.map(data => {
                    if (data.data.length > 0)
                        // check data exist or not.
                        results.push(...data.data.map((obj: any) => obj));
                });
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("roll over get verification list", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => {
                const realData: RollOverVerificationList[] = results;
                // const realData: RollOverVerificationList[] = getVerificationListDummyData;
                const vData: RollOverVerificationList[] = realData.map((item: any, index: number) => ({
                    ...item,
                    uniqueKey: `${item.server}_${item.ticket}`,
                }));
                setVerificationData(vData);
                setVerificationLoading(false);
            });
    };

    const queryStopOutData = async () => {
        setStopOutLoading(true);
        const promiseList = getPromiseList(APIs.RISK_TOOL.GET_ROLLOVER_STOP_OUT_LIST);

        const results: any[] = [];
        await Promise.all(promiseList)
            .then(res => {
                if (res)
                    res.map(data => {
                        if (data.data.length > 0)
                            // check data exist or not.
                            results.push(...data.data.map((obj: any) => obj));
                    });
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("roll over get stop out list", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => {
                // const realData: RollOverStopOutList[] = getStopOutListDummyData;
                const realData: RollOverStopOutList[] = results;
                const vData: RollOverStopOutList[] = realData.map((item: any, index: number) => ({
                    ...item,
                    uniqueKey: `${item.server}_${item.ticket}`,
                }));
                setStopOutData(vData);
                setStopOutLoading(false);
            });
    };

    const queryComparisonData = async () => {
        let positiveProfit = 0;
        let negativeProfit = 0;
        let sumProfit = 0;
        let supplementary = 0;
        let deduction = 0;

        if (verificationData.length > 0) {
            verificationData.forEach(({ profit }) => {
                if (profit > 0) {
                    positiveProfit += profit;
                } else if (profit < 0) {
                    negativeProfit += profit;
                }

                sumProfit += profit;
            });
        }

        if (supplementaryData.length > 0) {
            supplementaryData.forEach(({ adjustmentClient }) => {
                supplementary += adjustmentClient;
            });
        }

        if (deductionData.length > 0) {
            deductionData.forEach(({ adjustmentClient }) => {
                deduction += adjustmentClient;
            });
        }

        setTotalPositiveProfit(decimalFormat(positiveProfit, 2));
        setTotalNegativeProfit(decimalFormat(negativeProfit, 2));
        setTotalProfit(decimalFormat(sumProfit, 2));

        setTotalSupplementary(decimalFormat(supplementary, 2));
        setTotalDeduction(decimalFormat(deduction, 2));
        setTotalSupplementaryDeduction(decimalFormat(supplementary + deduction, 2));
    };

    const decimalFormat = (value: any, decimal: number) => {
        return value !== null ? parseFloat(value).toFixed(decimal) : value;
    };

    const next = () => {
        setCurrent(current + 1);
        queryDataByStep(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
        queryDataByStep(current - 1);
    };

    const onChangeStep = (current: number) => {
        setCurrent(current);
        queryDataByStep(current);
    };

    const queryDataByStep = (current: number) => {
        if (current == 0) {
            queryAdjustmentData();
        } else if (current == 1) {
            queryOpenPositionData();
        } else if (current == 2) {
            querySupplementaryData();
        } else if (current == 3) {
            queryDeductionData();
        } else if (current == 4) {
            queryVerificationAndStopOutData();
            querySupplementaryData();
            queryDeductionData();
        }

        //  If step in execute supplementary or deduction, query supplementary and deduction status.
        if (current > 1 && current < 4) {
            queryExecuteSupplementaryDeductionStatus();
            //  If batch's step in execute supplementary or deduction, query supplementary and deduction status in schedule.
            if (currentState.currentStep > 1 && currentState.currentStep < 4 && intervalRef.current == null) {
                intervalRef.current = setInterval(() => {
                    queryExecuteSupplementaryDeductionStatus();
                }, alarmTime);
            }
        } else {
            if (intervalRef.current !== null) {
                clearInterval(intervalRef.current);
            }
        }
    };
    const callDataBasedOnID = (secID: string, sec: number) => {
        switch (sec) {
            case SEC.OPEN_POSITION:
                setOpenPositionServerId(secID);
                queryOpenPositionData(secID);
                break;
            case SEC.SUPPLEMENTARY:
                setSupplementaryServerId(secID);
                querySupplementaryData(secID);
                break;
            case SEC.DEDUCTION:
                setDeductionServerId(secID);
                queryDeductionData(secID);
                break;
            case SEC.VERIFICATION:
                setVerificationServerId(secID);
                queryVerificationAndStopOutData();
                break;
            default:
                break;
        }
    };

    const resetData = () => {
        setOpenPositionServerId("");
        setSupplementaryServerId("");
        setSupplementaryData([]);
        setDeductionServerId("");
        setDeductionData([]);
        setVerificationServerId("");
        setVerificationData([]);
        clearExecuteSupplementaryDeductionStatus();
        if (intervalRef.current !== null) {
            clearInterval(intervalRef.current);
        }
        intervalRef.current = null;
    };

    const backToList = () => {
        resetData();
        navigate("/admintools/rollover");
    };

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            initCall();
            setRunRefetchDataList(false);
        }
        return () => { };
    }, [runRefetchDataList]);

    useEffect(() => {
        initStep();
    }, []);

    const initStep = async () => {
        queryServerDetail();
        setCurrent(currentState.currentStep);
    };

    const initCall = () => {
        if (currentState.id != undefined) {
            queryAdjustmentValueEmailTo();
            queryDataByStep(currentState.currentStep);
        }
    };

    const renderContent = (current: number) => {
        const stepComponents: { [key: number]: React.ReactNode } = {
            0: (
                <RollOverAdjustmentTable
                    data={adjustmentData}
                    currentState={currentState}
                    tbTitle="Adjustment Table"
                    loading={isLoading}
                    queryAdjustmentData={queryAdjustmentData}
                    queryBatchRecord={queryBatchRecord}
                    setUploadLoading={setIsLoading}
                    sendAdjustmentValueMail={sendAdjustmentValueMail}
                />
            ),
            1: (
                <RollOverOpenPositionTable
                    data={openPositionData}
                    currentState={currentState}
                    tbTitle="Open Position Table"
                    loading={openPositionLoading}
                    serverDetailList={serverDetailList}
                    calcOpenPosition={calcOpenPosition}
                    callDataBasedOnID={callDataBasedOnID}
                />
            ),
            2: (
                <RollOverSuppllementaryTable
                    data={supplementaryData}
                    tbTitle="Supplementary Table"
                    loading={supplementaryLoading}
                    currentState={currentState}
                    supplementaryExecutestatus={supplementaryExecutestatus}
                    executeSupplementary={executeSupplementary}
                    getExecuteStatusLabel={getExecuteStatusLabel}
                    refreshSupplementaryStatus={refreshSupplementaryStatus}
                    finishSupplementaryStep={finishSupplementaryStep}
                    serverDetailList={serverDetailList}
                    callDataBasedOnID={callDataBasedOnID}
                />
            ),
            3: (
                <RollOverDeductionTable
                    data={deductionData}
                    tbTitle="Deduction Table"
                    loading={deductionLoading}
                    currentState={currentState}
                    deductionExecutestatus={deductionExecutestatus}
                    executeDeduction={executeDeduction}
                    getExecuteStatusLabel={getExecuteStatusLabel}
                    refreshDeductionStatus={refreshDeductionStatus}
                    finishDeductionStep={finishDeductionStep}
                    serverDetailList={serverDetailList}
                    callDataBasedOnID={callDataBasedOnID}
                />
            ),
            4: (
                <>
                    <RollOverVerificationTable
                        data={verificationData}
                        tbTitle={`${moment(currentState.updateDate).format("YYYY-MM-DD")} MT4/5 Roll Over Order`}
                        loading={verificationLoading}
                        currentState={currentState}
                        verificationServerId={verificationServerId}
                        serverDetailList={serverDetailList}
                        queryVerificationAndStopOutData={queryVerificationAndStopOutData}
                        setVerificationServerId={setVerificationServerId}
                    />
                    <br />
                    <Divider style={{ borderColor: "#FFA500" }} />
                    <RollOverComparisonTable
                        queryComparisonData={queryComparisonData}
                        totalSupplementary={totalSupplementary}
                        totalPositiveProfit={totalPositiveProfit}
                        totalDeduction={totalDeduction}
                        totalNegativeProfit={totalNegativeProfit}
                        totalProfit={totalProfit}
                        totalSupplementaryDeduction={totalSupplementaryDeduction}
                    />
                    <RollOverStopOutTable data={stopOutData} tbTitle="MT4/5 Stop Out Order" loading={stopOutLoading} />
                </>
            ),
        };

        return stepComponents[current] || null;
    };

    return (
        <SitePageHeader
            title={`Edit Batch ${id}`}
            routes={[
                {
                    path: "/admintools/rollover",
                    breadcrumbName: "Roll Over Tool",
                    icon: <HomeOutlined />,
                },
                {
                    path: "",
                    breadcrumbName: `Edit Batch ${id}`,
                },
            ]}
            onBack={backToList}
        >
            {isLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Submitting..." />
                </div>
            ) : (
                <>
                    <Steps size="small" current={current} onChange={onChangeStep} items={stepItems} />
                    <div className="roll-over-tool-step-content">{renderContent(current)}</div>
                    <div className="roll-over-tool-step-content-steps-action">
                        <Space>
                            {current > 0 && (
                                <Button onClick={prev} icon={<ArrowLeftOutlined />}>
                                    Previous
                                </Button>
                            )}
                            {current < stepInfo.length - 1 && (
                                <Button icon={<ArrowRightOutlined />} type="primary" onClick={() => next()}>
                                    Next
                                </Button>
                            )}
                            <Button key="back" onClick={backToList}>
                                Close
                            </Button>
                        </Space>
                    </div>
                </>
            )}
        </SitePageHeader>
    );
}
export default RollOverStep;
