import React, { useEffect, useState } from "react";
import { CALLBACK_KEY, ComponentType, STATUS_TYPE, SUCCESS_FAILED } from "../../../../constants";
import { WatchListGroup, FlexiDataTableCallbackProps, FlexiDataTableOptionsProps } from "../../../../constants/type";
import AuthHelper, { AuthKeys } from "../../../../helpers/authHelper";
import { useNavigate } from "react-router-dom";
import CardBox from "../../../../components/Common/CardBox";
import FlexiDataTable from "../../../../components/FlexiDataTable";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../../../utils/Common";
import { APIs, apiRequest } from "../../../../services/apiConfig";
import { ToOptionTypeList } from "../../../../utils/array";

const AccWatchListGroup = () => {
    let navigate = useNavigate();
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.APP_CONFIGURATION_EDIT);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(true);
    const [data, setData] = useState<WatchListGroup[]>([]);

    const columns = [
        DTColProps.Large({
            title: "Group Name",
            dataIndex: "name",
            key: "name",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
        },
        DTColProps.Status({
            title: "Status",
            dataIndex: "status",
            key: "status",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: ToOptionTypeList(STATUS_TYPE),
                },
            },
        }),
    ];

    const options: FlexiDataTableOptionsProps = {
        add: enableUpdate,
        edit: enableUpdate,
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, AccWatchListGroupData) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                navigate("/siteadmin/appconfig/accWatchListGroup/create", { state: { data: null, action: "add" } });
                break;
            case CALLBACK_KEY.DO_EDIT:
                navigate(`/siteadmin/appconfig/accWatchListGroup/edit`, { state: { data: AccWatchListGroupData, action: "edit" } });
                break;
            default:
                break;
        }
    };

    const getAccWatchListGroups = () => {
        apiRequest(APIs.GET_ACC_WATCH_LIST_GROUP_LIST, {})
            .then((data: any) => {
                setData(data);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("account watch list group", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            setRunRefetchDataList(false);
            getAccWatchListGroups();
        }
        return () => {};
    }, [runRefetchDataList]);

    return (
        <>
            <CardBox title={"Account Watch List Group"}>
                <FlexiDataTable
                    rowKeyProperty="id"
                    title=""
                    columns={columns}
                    options={options}
                    dataSource={data || []}
                    callback={componentCallback}
                    loading={isLoading}
                />
            </CardBox>
        </>
    );
};

export default AccWatchListGroup;
