import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip, Form, Modal } from "antd";
import { QuestionCircleOutlined, WarningOutlined } from "@ant-design/icons";
import { FlexiDataTableCallbackProps, FlexiDataTableOptionsProps, ProfileProps, RoleType, User } from "../../../constants/type";
import FlexiDataTable from "../../../components/FlexiDataTable";
import { apiRequest, APIs } from "../../../services/apiConfig";
import { CALLBACK_KEY, ComponentType, STATUS_TYPE, SUCCESS_FAILED } from "../../../constants";
import { DataTableColumnRender, DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";
import { FormComponent } from "../../../components/FormComponent";
import CardBox from "../../../components/Common/CardBox";
import { REQUIRED_FIELD } from "../../../constants/errorMessage";
import AuthHelper, { AuthKeys } from "../../../helpers/authHelper";
import { ToOptionTypeList } from "../../../utils/array";
import { getProfile } from "@/services/localStorage";
import moment from "moment";

const UsersManagementTab = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<User[]>([]);

    const [roleList, setRoleList] = useState<RoleType[]>([]);
    const [teamList, setTeamList] = useState<string[]>([]);
    const [manualLoading, setManualLoading] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [, setResetPasswordModal] = useState<any>({});
    const [resetPwdForm] = Form.useForm();

    let navigate = useNavigate();
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.ADMIN_USER_EDIT);
    const currentUser: ProfileProps = getProfile() as ProfileProps;

    const columns = useMemo(
        () => [
            {
                title: (
                    <span key={"urnm-tlps"}>
                        Username
                        <Tooltip title={"User's login name"} key={"urnm-tlps-cl"}>
                            <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                        </Tooltip>
                    </span>
                ),
                realTitle: "Username",
                dataIndex: "username",
                key: "username",
                fixed: "left",
                options: {
                    filter: {
                        type: ComponentType.text,
                    },
                },
            },

            {
                title: "User Role",
                dataIndex: "roles",
                key: "roles",
                render: (text: string, record: User) => {
                    return text.split(",").join(", ");
                },
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: roleList.map(x => ({ text: x.role, value: x.id })),
                        callback: (filter_values: any, record: User) => {
                            return record["roleIds"].split(",").indexOf(filter_values.toString()) > -1;
                        },
                    },
                },
            },

            {
                title: "User Team",
                dataIndex: "team",
                key: "team",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: teamList.map(x => ({ text: x, value: x })),
                        callback: (filterValue: any, rowData: any) => {
                            if (rowData.team === null) {
                                return false;
                            }

                            return filterValue.includes(rowData.team);
                        },
                    },
                },
            },

            DTColProps.Status({
                title: "Status",
                dataIndex: "active",
                key: "active",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: ToOptionTypeList(STATUS_TYPE),
                    },
                },
            }),

            DTColProps.Middle({
                title: "Last Login (Local)",
                dataIndex: "lastLoginTimeUtc",
                key: "lastLoginTimeUtc",
                sorter: (a: any, b: any) => {
                    const dateA = a.lastLoginTimeUtc ? moment(a.lastLoginTimeUtc) : null;
                    const dateB = b.lastLoginTimeUtc ? moment(b.lastLoginTimeUtc) : null;
                
                    if (dateA && dateB) {
                        return dateA.isAfter(dateB) ? -1 : 1;
                    } else if (dateA) {
                        return -1; // Non-null date should come first
                    } else if (dateB) {
                        return 1; // Non-null date should come first
                    } else {
                        return 0; // Both are null, consider them equal
                    }
                },
                
                render: (value: string) => (value ? DataTableColumnRender.DateTime(value) : ""),
                options: {
                    filter: {
                        type: ComponentType.daterange,
                        value: "",
                        inputProps: {
                            showTime: true,
                        },
                    },
                },
            }),
            DTColProps.Middle({
                title: "Last Access (Local)",
                dataIndex: "lastAccessTimeUtc",
                key: "lastAccessTimeUtc",
                sorter: (a: any, b: any) => {
                    const dateA = a.lastAccessTimeUtc ? moment(a.lastAccessTimeUtc) : null;
                    const dateB = b.lastAccessTimeUtc ? moment(b.lastAccessTimeUtc) : null;
                
                    if (dateA && dateB) {
                        return dateA.isAfter(dateB) ? -1 : 1;
                    } else if (dateA) {
                        return -1; // Non-null date should come first
                    } else if (dateB) {
                        return 1; // Non-null date should come first
                    } else {
                        return 0; // Both are null, consider them equal
                    }
                },
                
                render: (value: string) => (value ? DataTableColumnRender.DateTime(value) : ""),
                options: {
                    filter: {
                        type: ComponentType.daterange,
                        value: "",
                        inputProps: {
                            showTime: true,
                        },
                    },
                },
            }),
        ],
        [roleList, teamList]
    );

    const options: FlexiDataTableOptionsProps = {
        add: enableUpdate ? { text: "Bind User" } : false,
        edit: enableUpdate,
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, FormData) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                navigate("/siteadmin/user/create");
                break;
            case CALLBACK_KEY.DO_EDIT:
                navigate("/siteadmin/user/edit", { state: FormData });
                break;
            case CALLBACK_KEY.DO_DELETE:
                setManualLoading(true);
                apiRequest(`${APIs.DELETE_USER_ACCOUNT}`, { uuid: FormData.uuid }, "POST")
                    .then(res => {
                        ErrorMessageHandler("The user record", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                        setManualLoading(false);
                        getBindedUsers();
                    })
                    .catch(error => {
                        ErrorCatchValidator(error, (err: any) =>
                            ErrorMessageHandler("user. User needs to be deactivated first before deleting.", SUCCESS_FAILED.FAILED_DELETE_DATA, err)
                        );
                        setManualLoading(false);
                    });
                break;
            case CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK:
                if (FormData.key === "reset_password") {
                    setShowModal(true);
                    setResetPasswordModal(FormData.data);
                    resetPwdForm.setFieldsValue({ uuid: FormData.data["uuid"], password: "" });
                }
                break;
        }
    };

    const resetUserPassword = (uuid: string, password: string) => {
        apiRequest(`${APIs.RESET_PASSWORD_USER_ACCOUNT}`, { uuid, password })
            .then(res => {
                ErrorMessageHandler("Account password reset", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                setShowModal(false);
                resetPwdForm.resetFields();
                getBindedUsers();
            })
            .catch(error => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("account password reset", SUCCESS_FAILED.FAILED_UPDATE_DATA, err));
                setShowModal(false);
            });
    };

    const getUserTeams = () => {
        apiRequest(APIs.GET_SETTING_LIST, { keys: ["UserTeams"] })
            .then((res: any) => {
                const userTeamsValue = JSON.parse(res.find((item: { key: string }) => item.key === "UserTeams")?.value || "null");
                setTeamList(userTeamsValue);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => console.log("Failed to get latest user teams: ", err));
            });
    };

    const getUserRoles = useCallback(() => {
        apiRequest(APIs.GET_USER_ROLE_LIST, {}) //{ userId: currentUser.id }
            .then((response: any) => {
                let roles = response as RoleType[];
                setRoleList(roles);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => console.log(`Error: ${err}`));
            });
    }, [currentUser]);

    const getBindedUsers = useCallback(() => {
        setIsLoading(true);
        apiRequest(APIs.GET_USER_PROFILES, { isBinded: true })
            .then((res: User[]) => {
                setData(res && res.length > 0 ? res.filter(x => `${x.uuid}` !== `${currentUser.id}`) : []);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => console.log("Failed to get user list: ", err));
            })
            .finally(() => setIsLoading(false));
    }, [currentUser]);

    useEffect(() => {
        getBindedUsers();
        getUserTeams();
        getUserRoles();

        return () => {};
    }, []);

    return (
        <>
            <CardBox title={"Users Management"}>
                <FlexiDataTable
                    rowKeyProperty="uuid"
                    title=""
                    columns={columns}
                    options={options}
                    dataSource={data}
                    callback={componentCallback}
                    loading={isLoading || manualLoading}
                />
            </CardBox>
            <Modal
                open={showModal}
                title="Change Password"
                onCancel={() => {
                    setShowModal(false);
                    resetPwdForm.resetFields();
                }}
                onOk={() => {
                    resetPwdForm
                        .validateFields()
                        .then(values => resetUserPassword(values.uuid, values.password))
                        .catch(err => {});
                }}
            >
                <Form form={resetPwdForm} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} layout="horizontal">
                    <div className="reset-password-pattern">
                        <WarningOutlined className="error" style={{ height: "20px" }} />
                        <span className="error">
                            Password must contain at least 8 characters including at least 1 uppercase letter, 1 lowercase letter and 1 number.
                        </span>
                    </div>
                    <FormComponent label={""} name={"uuid"} extra={{ type: ComponentType.hidden, value: "" }} />
                    <FormComponent
                        label="Temporary Password"
                        name={"password"}
                        extra={{
                            type: ComponentType.password,
                            value: "",
                            rules: [
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || value.length < 1) {
                                            return Promise.reject(new Error(REQUIRED_FIELD));
                                        } else if (!(/[a-z]+/.test(value) && /[A-Z]+/.test(value) && /[0-9]+/.test(value)) || value.length < 8) {
                                            return Promise.reject(new Error("Invalid password."));
                                        }

                                        return Promise.resolve();
                                    },
                                }),
                            ],
                        }}
                    />
                </Form>
            </Modal>
        </>
    );
};

export default UsersManagementTab;
