import { create } from "zustand";
import { IPriceOutage, IPriceOutageHistory } from "../hooks/useRCPriceOutage";

export interface IHourlyStats {
    timestamp: number;
    totalIncidents: number;
    uniqueCleanSymbols: { [symbol: string]: number };
    uniqueServers: { [server: string]: number };
    uniqueLevels: { [level: string]: number };
}

interface PriceOutageState {
    hourlyData: { [timestamp: number]: IPriceOutage[] };
    hourlyDataNonProd: { [timestamp: number]: IPriceOutage[] };
    hourlyStats: IHourlyStats[];
    hourlyStatsNonProd: IHourlyStats[];
    latestProdData: IPriceOutage[];
    latestNonProdData: IPriceOutage[];
    latestProdStats: IHourlyStats | null;
    latestNonProdStats: IHourlyStats | null;
    addData: (newData: IPriceOutage[], isCurrent?: boolean) => void;
    addDataNonProd: (newData: IPriceOutage[], isCurrent?: boolean) => void;
    getHourlyStats: () => IHourlyStats[];
    getHourlyStatsNonProd: () => IHourlyStats[];
    selectedProdTimeStamp: number | null;
    selectedNonProdTimeStamp: number | null;
    setSelectedProdTimeStamp: (timestamp: number | null) => void;
    setSelectedNonProdTimeStamp: (timestamp: number | null) => void;
    selectedProdRawData: IPriceOutage[] | null;
    selectedNonProdRawData: IPriceOutage[] | null;
    selectedProdStats: IHourlyStats | null;
    selectedNonProdStats: IHourlyStats | null;
    historyRawDataProd: IPriceOutageHistory[] | null;
    historyRawDataNonProd: IPriceOutageHistory[] | null;
    setHistoryRawDataProd: (data: IPriceOutageHistory[]) => void;
    setHistoryRawDataNonProd: (data: IPriceOutageHistory[]) => void;
    currentRawDataProd: IPriceOutage[] | null;
    currentRawDataNonProd: IPriceOutage[] | null;
    setCurrentRawDataProd: (data: IPriceOutage[]) => void;
    setCurrentRawDataNonProd: (data: IPriceOutage[]) => void;
}

const useRCPriceOutageStore = create<PriceOutageState>((set, get) => ({
    hourlyData: {},
    hourlyDataNonProd: {},
    hourlyStats: [],
    hourlyStatsNonProd: [],
    latestProdData: [],
    latestNonProdData: [],
    latestProdStats: null,
    latestNonProdStats: null,
    selectedProdRawData: null,
    selectedNonProdRawData: null,
    selectedProdStats: null,
    selectedNonProdStats: null,
    selectedProdTimeStamp: null,
    selectedNonProdTimeStamp: null,
    historyRawDataProd: [],
    historyRawDataNonProd: [],
    setHistoryRawDataProd: (data: IPriceOutageHistory[]) => set({ historyRawDataProd: data }),
    setHistoryRawDataNonProd: (data: IPriceOutageHistory[]) => set({ historyRawDataNonProd: data }),
    currentRawDataProd: [],
    currentRawDataNonProd: [],
    setCurrentRawDataProd: (data: IPriceOutage[]) => set({ currentRawDataProd: data }),
    setCurrentRawDataNonProd: (data: IPriceOutage[]) => set({ currentRawDataNonProd: data }),

    setSelectedProdTimeStamp: (timestamp: number | null) =>
        set(state => {
            const latestTimestamp = timestamp === null ? Math.max(...Object.keys(state.hourlyData).map(Number)) : timestamp;

            const selectedProdRawData = state.hourlyData[latestTimestamp] || [];
            const selectedProdStats = state.hourlyStats.find(stats => stats.timestamp === latestTimestamp) || null;

            return {
                selectedProdTimeStamp: timestamp === null ? null : latestTimestamp,
                selectedProdRawData,
                selectedProdStats,
            };
        }),

    setSelectedNonProdTimeStamp: (timestamp: number | null) =>
        set(state => {
            const latestTimestamp = timestamp === null ? Math.max(...Object.keys(state.hourlyDataNonProd).map(Number)) : timestamp;

            const selectedNonProdRawData = state.hourlyDataNonProd[latestTimestamp] || [];
            const selectedNonProdStats = state.hourlyStatsNonProd.find(stats => stats.timestamp === latestTimestamp) || null;

            return {
                selectedNonProdTimeStamp: timestamp === null ? null : latestTimestamp,
                selectedNonProdRawData,
                selectedNonProdStats,
            };
        }),

    addData: (newData: IPriceOutage[], isCurrent?: boolean) => {
        set(state => {
            const updatedHourlyData = { ...state.hourlyData };

            const now = Date.now() - 5000;
            // make now variable -5 seconds to make sure the new data is included in latest timestamp

            const oneHourAgo = now - 3600001;

            // Remove data older than 1 hour
            Object.keys(updatedHourlyData).forEach(key => {
                if (Number(key) < oneHourAgo) {
                    delete updatedHourlyData[Number(key)];
                }
            });

            // Ensure we have entries for every 30-second interval in the last hour
            for (let t = oneHourAgo; t <= now; t += 60000) {
                const intervalKey = Math.floor(t / 60000) * 60000;
                if (!updatedHourlyData[intervalKey]) {
                    updatedHourlyData[intervalKey] = [];
                }
            }

            // Add new data to appropriate 30-second intervals
            newData.forEach(item => {
                const dateStringConversion = item.startDate.split(" ");
                const dateString = dateStringConversion[0] + "T" + dateStringConversion[1] + "+03:00";
                const localDate = new Date(dateString);

                const startTimestamp = localDate.getTime();
                const endTimestamp = startTimestamp + item.duration;

                for (let t = startTimestamp; t <= endTimestamp; t += 60000) {
                    const intervalKey = Math.floor(t / 60000) * 60000;
                    if (intervalKey >= oneHourAgo && intervalKey <= now) {
                        // Check if the item already exists in this interval
                        const itemExists = updatedHourlyData[intervalKey].some(
                            existingItem => existingItem.startDate === item.startDate && existingItem.symbol === item.symbol
                        );
                        if (!itemExists) {
                            updatedHourlyData[intervalKey].push(item);
                        }
                    }
                }
            });

            // Calculate hourly stats
            const hourlyStats = Object.entries(updatedHourlyData).map(([timestamp, data]) => ({
                timestamp: Number(timestamp),
                totalIncidents: data.length,
                uniqueCleanSymbols: data.reduce((acc, item) => {
                    acc[item.cleanSymbol] = (acc[item.cleanSymbol] || 0) + 1;
                    return acc;
                }, {} as { [symbol: string]: number }),
                uniqueServers: data.reduce((acc, item) => {
                    acc[item.serverId] = (acc[item.serverId] || 0) + 1;
                    return acc;
                }, {} as { [server: string]: number }),
                uniqueLevels: data.reduce((acc, item) => {
                    acc[item.level] = (acc[item.level] || 0) + 1;
                    return acc;
                }, {} as { [level: string]: number }),
            }));

            // Update selected data if no timestamp is currently selected
            if (state.selectedProdTimeStamp === null) {
                get().setSelectedProdTimeStamp(null);
            }

            if (isCurrent) {
                // recalculate, don't take from hourlyStats, timestamp put as now
                const latestProdStats = {
                    timestamp: now,
                    totalIncidents: newData.length,
                    uniqueCleanSymbols: newData.reduce((acc, item) => {
                        acc[item.cleanSymbol] = (acc[item.cleanSymbol] || 0) + 1;
                        return acc;
                    }, {} as { [symbol: string]: number }),
                    uniqueServers: newData.reduce((acc, item) => {
                        acc[item.serverId] = (acc[item.serverId] || 0) + 1;
                        return acc;
                    }, {} as { [server: string]: number }),
                    uniqueLevels: newData.reduce((acc, item) => {
                        acc[item.level] = (acc[item.level] || 0) + 1;
                        return acc;
                    }, {} as { [level: string]: number }),
                };

                return {
                    hourlyData: updatedHourlyData,
                    hourlyStats,
                    latestProdData: newData,
                    latestProdStats,
                };
            }

            return { hourlyData: updatedHourlyData, hourlyStats };
        });
    },

    addDataNonProd: (newData: IPriceOutage[], isCurrent?: boolean) => {
        set(state => {
            const updatedHourlyData = { ...state.hourlyDataNonProd };
            const now = Date.now() - 5000;
            // make now variable -5 seconds to make sure the new data is included in latest timestamp
            const oneHourAgo = now - 3600000;

            // Remove data older than 1 hour
            Object.keys(updatedHourlyData).forEach(key => {
                if (Number(key) < oneHourAgo) {
                    delete updatedHourlyData[Number(key)];
                }
            });

            // Ensure we have entries for every 30-second interval in the last hour
            for (let t = oneHourAgo; t <= now; t += 60000) {
                const intervalKey = Math.floor(t / 60000) * 60000;
                if (!updatedHourlyData[intervalKey]) {
                    updatedHourlyData[intervalKey] = [];
                }
            }

            // Add new data to appropriate 30-second intervals
            newData.forEach(item => {
                const dateStringConversion = item.startDate.split(" ");
                const dateString = dateStringConversion[0] + "T" + dateStringConversion[1] + "+03:00";
                const localDate = new Date(dateString);
                const startTimestamp = localDate.getTime();
                const endTimestamp = startTimestamp + item.duration;
                for (let t = startTimestamp; t <= endTimestamp; t += 60000) {
                    const intervalKey = Math.floor(t / 60000) * 60000;
                    if (intervalKey >= oneHourAgo && intervalKey <= now) {
                        // Check if the item already exists in this interval
                        const itemExists = updatedHourlyData[intervalKey].some(
                            existingItem => existingItem.startDate === item.startDate && existingItem.symbol === item.symbol
                        );
                        if (!itemExists) {
                            updatedHourlyData[intervalKey].push(item);
                        }
                    }
                }
            });

            // Calculate hourly stats
            const hourlyStatsNonProd = Object.entries(updatedHourlyData).map(([timestamp, data]) => ({
                timestamp: Number(timestamp),
                totalIncidents: data.length,
                uniqueCleanSymbols: data.reduce((acc, item) => {
                    acc[item.cleanSymbol] = (acc[item.cleanSymbol] || 0) + 1;
                    return acc;
                }, {} as { [symbol: string]: number }),
                uniqueServers: data.reduce((acc, item) => {
                    acc[item.serverId] = (acc[item.serverId] || 0) + 1;
                    return acc;
                }, {} as { [server: string]: number }),
                uniqueLevels: data.reduce((acc, item) => {
                    acc[item.level] = (acc[item.level] || 0) + 1;
                    return acc;
                }, {} as { [level: string]: number }),
            }));

            // Update selected data if no timestamp is currently selected
            if (state.selectedNonProdTimeStamp === null) {
                get().setSelectedNonProdTimeStamp(null);
            }

            if (isCurrent) {
                // recalculate, don't take from hourlyStats, timestamp put as now
                const latestNonProdStats = {
                    timestamp: now,
                    totalIncidents: newData.length,
                    uniqueCleanSymbols: newData.reduce((acc, item) => {
                        acc[item.cleanSymbol] = (acc[item.cleanSymbol] || 0) + 1;
                        return acc;
                    }, {} as { [symbol: string]: number }),
                    uniqueServers: newData.reduce((acc, item) => {
                        acc[item.serverId] = (acc[item.serverId] || 0) + 1;
                        return acc;
                    }, {} as { [server: string]: number }),
                    uniqueLevels: newData.reduce((acc, item) => {
                        acc[item.level] = (acc[item.level] || 0) + 1;
                        return acc;
                    }, {} as { [level: string]: number }),
                };

                return {
                    hourlyDataNonProd: updatedHourlyData,
                    hourlyStatsNonProd,
                    latestNonProdData: newData,
                    latestNonProdStats,
                };
            }

            return { hourlyDataNonProd: updatedHourlyData, hourlyStatsNonProd };
        });
    },

    getHourlyStats: () => {
        return get().hourlyStats;
    },

    getHourlyStatsNonProd: () => {
        return get().hourlyStatsNonProd;
    },
}));

export default useRCPriceOutageStore;
