import { useEffect, useState } from "react";
import { Tabs } from "antd";
import { ServersList, SymbolsList, TabNavItem } from "../../../constants/type";
import TicketList from "./TicketList";
import HistoricalList from "./HistoricalList";
import { APIs, apiRequest } from "../../../services/apiConfig";
import { ErrorCatchValidator } from "../../../utils/Common";

const TabNavList: TabNavItem[] = [
    { key: "1", label: "Ticket List" },
    { key: "2", label: "Historical List" },
];

const TicketRestoration = () => {
    const [currentActiveTab, setCurrentActiveTab] = useState<string>("1");
    const [servers, setServers] = useState<ServersList[]>([]);
    const [symbols, setSymbols] = useState<SymbolsList[]>([]);

    useEffect(() => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["server", "symbol"] })
            .then((data: any) => {
                setServers(data.servers.filter((x: ServersList) => x.isAllowedToRestoreTrades));
                setSymbols(data.symbols);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => console.log("Failed to load server and symbol list: ", err));
            });
        return () => {};
    }, []);

    return (
        <>
            <div className="ticket-restoration-container">
                <div className="content-panel">
                    <div className="list-panel">
                        <Tabs
                            className="tab-container"
                            defaultActiveKey={currentActiveTab}
                            type="card"
                            size="middle"
                            onChange={(activeKey: string) => setCurrentActiveTab(activeKey)}
                            items={TabNavList}
                        />
                        <>
                            {currentActiveTab === "1" && <TicketList servers={servers} symbols={symbols} />}
                            {currentActiveTab === "2" && <HistoricalList />}
                        </>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TicketRestoration;
