import React, { useEffect } from "react";
import CardBox from "../../../components/Common/CardBox";
import { Button, Col, DatePicker, Row, Select, Form, Typography, Checkbox } from "antd";
import moment from "moment-timezone";
import { DownloadOutlined, RedoOutlined } from "@ant-design/icons";
import { plainAxiosInstance } from "../../../services/axiosSetup";
import { APIs } from "../../../services/apis";
import { ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";
import { SUCCESS_FAILED } from "../../../constants";

type SelectOptionType = {
    label: string;
    value: string;
};
const TickReportTypes = [
    {
        label: "Symbol Daily Total",
        value: "SYMBOL_TOTAL",
    },
    {
        label: "Symbol Daily Average",
        value: "SYMBOL_AVG",
    },
    {
        label: "Server Daily Total",
        value: "SERVER_TOTAL",
    },
    {
        label: "Clean Symbol Daily Total",
        value: "CLEAN_SYMBOL_TOTAL",
    },
    {
        label: "Clean Symbol Daily Average",
        value: "CLEAN_SYMBOL_AVG",
    },
];
const DailyTickReport = () => {
    const [serverList, setServerList] = React.useState<SelectOptionType[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isDownloading, setIsDownloading] = React.useState<boolean>(false);
    const [downloadAll, setDownloadAll] = React.useState<boolean>(false);

    const [downloadForm] = Form.useForm();

    useEffect(() => {
        getServers();
    }, []);

    useEffect(() => {
        if(downloadForm.isFieldsTouched()){
            downloadForm.validateFields(["selectedServers"]);
        }
    }, [downloadAll, downloadForm]);

    function getServers() {
        downloadForm.setFieldValue("selectedServers", []);
        setIsLoading(true);
        plainAxiosInstance
            .get(`${APIs.RC_TICK_REPORT.GET_SERVERS}`)
            .then(res => {
                if (res.data) {
                    const servers = res.data.map((x: any) => {
                        return {
                            label: x.serverName,
                            value: x.serverUno,
                        };
                    });
                    setServerList(servers);
                }
            })
            .catch((error: any) => {
                return ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("servers", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
            })
            .finally(() => setIsLoading(false));
    }

    function onSubmit() {
        downloadForm
            .validateFields()
            .then(() => {
                const date: string = downloadForm.getFieldValue("date").format("YYYY-MM-DD");
                const reportType: string = downloadForm.getFieldValue("reportType");
                let url = `${APIs.RC_TICK_REPORT.POST_DOWNLOAD}/${reportType}?selectedDate=${downloadForm
                    .getFieldValue("date")
                    .format("YYYY-MM-DD")}`;
                const fileName = `${reportType.split(" ").join("_")}_${date}.xlsx`;
                if (!downloadAll) {
                    url += `&serverUno=${downloadForm.getFieldValue("selectedServers").join(",")}`;
                }

                // console.log(downloadForm.getFieldsValue(), downloadForm.getFieldValue("date").format("YYYY-MM-DD"), fileName);
                downloadReport(url,fileName);
            })
            .catch(() => {
                return ErrorMessageHandler("report", SUCCESS_FAILED.FAILED_DOWNLOAD_DATA, { message: "Please select all required parameters" });
            });
    }

    function downloadReport(url: string, fileName: string) {
        setIsDownloading(true);
        plainAxiosInstance
            .get(url, { responseType: "blob" })
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", fileName);
                document.body.appendChild(link);
                link.click();

                // Clean up
                window.URL.revokeObjectURL(url);
                link.parentNode?.removeChild(link);
                ErrorMessageHandler("Daily Tick Report", SUCCESS_FAILED.SUCCESS_DOWNLOAD_DATA);
            })
            .catch(error => {
                return ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("report", SUCCESS_FAILED.FAILED_DOWNLOAD_DATA, err));
            })
            .finally(() => setIsDownloading(false));
    }

    return (
        <div className="daily-tick-report-container">
            <CardBox title={"MT4/5 Daily Tick Report"}>
                <div className="main-container">
                    <Typography.Title level={4} children={`Please select the required parameters for download report`} />
                    <div className="content">
                        <Form form={downloadForm} initialValues={{ date: moment(new Date()) }}>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Row gutter={8}>
                                        <Col span={12}>
                                            <Form.Item name="reportType" rules={[{ required: true, message: "Please select report type" }]}>
                                                <Select<SelectOptionType>
                                                    placeholder="Select Report Type"
                                                    style={{ width: "100%" }}
                                                    options={TickReportTypes}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item name="date" rules={[{ required: true, message: "Please select date" }]}>
                                                <DatePicker
                                                    format={"YYYY-MM-DD"}
                                                    style={{ width: "100%" }}
                                                    placeholder="Select Date"
                                                    disabledDate={current => {
                                                        return current && current > moment().endOf("day");
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={12}>
                                    <Row gutter={8}>
                                        <Col span={18}>
                                            <Form.Item
                                                name="selectedServers"
                                                rules={[{ required: !downloadAll, message: "Please select servers", type: "array" }]}
                                                style={{ marginBottom: "4px" }}
                                            >
                                                <Select<SelectOptionType[]>
                                                    placeholder="Select servers..."
                                                    mode="multiple"
                                                    style={{ width: "100%" }}
                                                    options={serverList}
                                                    disabled={isLoading || downloadAll}
                                                />
                                            </Form.Item>
                                            <Checkbox onChange={e => setDownloadAll(e.target.checked)}>Select All</Checkbox>
                                        </Col>
                                        <Col span={6}>
                                            <Button icon={<RedoOutlined style={{ fontSize: "0.875rem" }} />} loading={isLoading} onClick={getServers}>
                                                Refresh
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={3}>
                                    <Button type="primary" loading={isDownloading} icon={<DownloadOutlined />} onClick={onSubmit}>
                                        Download
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
            </CardBox>
        </div>
    );
};

export default DailyTickReport;
