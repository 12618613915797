import { Modal, Segmented } from "antd";
import { SegmentedValue } from "antd/lib/segmented";
import { useState } from "react";
import ResolveSpreadAlarmList from "./resolvedSpreadAlarm";
import UnresolveSpreadAlarmList from "./unresolvedSpreadAlarm";
import { PriceMonitorSpreadAlarmDetailsProps } from "../../../../constants/type";
import SpreadLineChart from "./spreadLineChart";

export interface PriceMonitorSpreadAlarmHistoricalProps {}

const PriceMonitorSpreadAlarmHistorical = (props: PriceMonitorSpreadAlarmHistoricalProps) => {
    const [segmentVal, setSegmentVal] = useState<string>("Unsolved in 1 day");
    const [modalTitle, setModalTitle] = useState<string>("");
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [spreadLineData, setSpreadLineData] = useState<PriceMonitorSpreadAlarmDetailsProps | undefined>(undefined);

    const showSpreadLine = (data: PriceMonitorSpreadAlarmDetailsProps | undefined) => {
        if (data === undefined) {
            setSpreadLineData(data);
            setModalTitle("");
            setIsModalVisible(false);
        } else {
            setModalTitle(`${data.server}-${data.symbol} ${data.dateTimeStr}`);
            setSpreadLineData(data);
            setIsModalVisible(true);
        }
    };

    return (
        <>
            <div className="historical-alarm-container">
                <div className="top-panel">
                    <Segmented
                        options={["Unsolved in 1 day", "Resolved in 1 day"]}
                        value={segmentVal}
                        onChange={(value: SegmentedValue) => setSegmentVal(value as string)}
                    />
                </div>
                <div className="container">
                    {segmentVal === "Unsolved in 1 day" && <UnresolveSpreadAlarmList showSpreadLineCallback={showSpreadLine} />}
                    {segmentVal === "Resolved in 1 day" && <ResolveSpreadAlarmList showSpreadLineCallback={showSpreadLine} />}
                </div>
            </div>
            <Modal
                width={800}
                style={{ top: 5 }}
                open={isModalVisible}
                title={modalTitle}
                okButtonProps={{ style: { display: "none" } }}
                cancelText="Close"
                onCancel={() => showSpreadLine(undefined)}
            >
                <SpreadLineChart data={spreadLineData} />
            </Modal>
        </>
    );
};

export default PriceMonitorSpreadAlarmHistorical;
