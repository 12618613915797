import { createApi } from "@reduxjs/toolkit/query/react";
import { APIs, defaultBaseQueryProps, ResponseProps } from "../../services/apiConfig";
import { Template } from "../../constants/type";
import { axiosBaseQuery } from "../axiosBaseQuery";

export interface templateRequest {
    reportName: string;
}

export interface templateResponse extends ResponseProps<Template[]> {}

export const templateApiSlice = createApi({
    reducerPath: "api_2",
    baseQuery: axiosBaseQuery(),
    keepUnusedDataFor: 5, //global caching duration (seconds)
    endpoints: (builder) => ({
        getTemplates: builder.query<templateResponse, templateRequest | undefined>({
            keepUnusedDataFor: 0, //disable for caching for particular api request
            query: (arg) => {
                return { ...defaultBaseQueryProps, url: APIs.GET_REPORT_TEMPLATE, method: "POST", data: arg };
            },
            transformResponse: (returnValue: templateResponse, meta) => {
                return returnValue;
            },
        }),
    }),
});

export const { useGetTemplatesQuery } = templateApiSlice;
