import { Module } from "../constants/type";
import { getMenus } from "../services/localStorage";

class AuthHelper {
    private _authModules: Module[] = [];
    private _authModuleKeys: string[] = [];

    constructor() {
        let auth_menus: Module[] | undefined = getMenus();
        if (auth_menus) {
            this._authModules = auth_menus;
            this._authModuleKeys = this._authModules.map(x => `${x.key.substring(0, 1) !== "/" ? "/" : ""}${x.key}`);
        }
    }

    getAuthModules = (): Module[] => this._authModules;

    getAuthModuleKeys = (): string[] => this._authModuleKeys;

    getRouteItem = (key: string): Module | undefined => this._authModules.find(x => `/${x.key}` === key);

    isAuthorized = (key: string, ifTrue: any = undefined) => {
        if (this._authModuleKeys.indexOf(key) > -1) {
            if (ifTrue !== undefined) {
                return ifTrue;
            }
            return true;
        }
        return false;
    };
}

export default AuthHelper;

export const AuthKeys = {
    REPORT_HISTORY_VIEW: "/report/result/view",
    REPORT_HISTORY_EDIT: "/report/result/edit",
    TEMPLATE_VIEW: "/report/template/view",
    TEMPLATE_EDIT: "/report/template/edit",
    ANALYSIS_CLIENT_PROFILE_VIEW: "/analysis/clientlist/view",
    ANALYSIS_CLIENT_PROFILE_EDIT: "/analysis/clientlist/edit",
    ANALYSIS_ACCOUNT_PROFILE_VIEW: "/analysis/accountprofile/view",
    ANALYSIS_ACCOUNT_PROFILE_EDIT: "/analysis/accountprofile/edit",
    ANALYSIS_ACCOUNT_WATCHLIST_VIEW: "/analysis/watchlist/view",
    ANALYSIS_ACCOUNT_WATCHLIST_EDIT: "/analysis/watchlist/edit",
    TAGGING_LIST_VIEW: "/tagging/list/view",
    TAGGING_LIST_EDIT: "/tagging/list/edit",
    SYMBOL_CONFIG_VIEW: "/siteadmin/symbolconfig/view",
    SYMBOL_CONFIG_EDIT: "/siteadmin/symbolconfig/edit",
    ADMIN_USER_VIEW: "/siteadmin/user/view",
    ADMIN_USER_EDIT: "/siteadmin/user/edit",
    ADMIN_ROLE_PERMISSION_VIEW: "/siteadmin/rolepermission/view",
    ADMIN_ROLE_PERMISSION_EDIT: "/siteadmin/rolepermission/edit",
    ADMIN_SCHEDULER_VIEW: "/siteadmin/schedulers/view",
    ADMIN_SCHEDULER_EDIT: "/siteadmin/schedulers/edit",
    ADMIN_TAG_VIEW: "/siteadmin/tag/view",
    ADMIN_TAG_EDIT: "/siteadmin/tag/edit",
    ADMIN_RISKSCORE_ACCOUNT_VIEW: "/siteadmin/riskscore/account/view",
    ADMIN_RISKSCORE_ACCOUNT_EDIT: "/siteadmin/riskscore/account/edit",
    ADMIN_RISKSCORE_BRAND_VIEW: "/siteadmin/riskscore/brand/view",
    ADMIN_RISKSCORE_BRAND_EDIT: "/siteadmin/riskscore/brand/edit",
    ADMIN_BRANDSERVER_BRAND_VIEW: "/siteadmin/brandserver/brand/view",
    ADMIN_BRANDSERVER_BRAND_EDIT: "/siteadmin/brandserver/brand/edit",
    ADMIN_EXTERNAL_SYSTEM_V2_VIEW: "/siteadmin/brandserver/externalsystem/view",
    ADMIN_EXTERNAL_SYSTEM_V2_EDIT: "/siteadmin/brandserver/externalsystem/edit",
    ADMIN_COMMISSION_SETTING_VIEW: "/siteadmin/brandserver/commissionconfig/view",
    ADMIN_COMMISSION_SETTING_EDIT: "/siteadmin/brandserver/commissionconfig/edit",
    ADMIN_SERVER_VIEW: "/siteadmin/serverbrand/server/view",
    ADMIN_SERVER_EDIT: "/siteadmin/serverbrand/server/edit",
    ADMIN_BRAND_VIEW: "/siteadmin/serverbrand/brand/view",
    ADMIN_BRAND_EDIT: "/siteadmin/serverbrand/brand/edit",
    ADMIN_SERVER_V2_VIEW: "/siteadmin/serverbrand/server/view",
    ADMIN_SERVER_V2_EDIT: "/siteadmin/serverbrand/server/edit",
    ADMIN_DATA_CENTER_V2_VIEW: "/siteadmin/serverbrand/datacenter/view",
    ADMIN_DATA_CENTER_V2_EDIT: "/siteadmin/serverbrand/datacenter/edit",
    ADMIN_GROUP_PURPOSE_VIEW: "/siteadmin/serverbrand/group-purpose/view",
    ADMIN_GROUP_PURPOSE_EDIT: "/siteadmin/serverbrand/group-purpose/edit",
    ADMIN_SERVER_BRAND_MAPPING_VIEW: "/siteadmin/serverbrand/server-brand-mapping/view",
    ADMIN_SERVER_BRAND_MAPPING_EDIT: "/siteadmin/serverbrand/server-brand-mapping/edit",
    MONITOR_TRADE_VIEW: "/realtime/trademonitor/view",
    MONITOR_TRADE_EDIT: "/realtime/trademonitor/edit",
    HISTORICAL_EVENT_SUMMARY_VIEW: "/realtime/historicaleventsummary/view",
    HISTORICAL_EVENT_SUMMARY_EDIT: "/realtime/historicaleventsummary/edit",
    HISTORICAL_EVENT_BATCHES_VIEW: "/realtime/historicaleventbatches/view",
    HISTORICAL_EVENT_BATCHES_EDIT: "/realtime/historicaleventbatches/edit",
    DECAY_ANALYSIS_VIEW: "/realtime/decaychart/view",
    CLOSED_DECAY_ANALYSIS_VIEW: "/realtime/closeddecaychart/view",
    APP_CONFIGURATION_VIEW: "/siteadmin/appconfig/view",
    APP_CONFIGURATION_EDIT: "/siteadmin/appconfig/edit",
    PRICE_OUTAGE_VIEW: "/pricemonitor/priceoutage/view",
    HISTORICAL_PRICE_OUTAGE_VIEW: "/pricemonitor/historicalpriceoutage/view",
    MID_PRICE_MONITORING_VIEW: "/pricemonitor/midpricemonitoring/view",
    HISTORICAL_MID_PRICE_MONITORING_VIEW: "/pricemonitor/historicalmidpricemonitoring/view",
    HFT_CHART_VIEW: "/realtime/hftBiti/view",
    PRICE_SETTING_SPREAD_ALARM_VIEW: "/pricemonitor/pricesettings/view",
    PRICE_SETTING_SPREAD_ALARM_EDIT: "/pricemonitor/pricesettings/edit",
    // PMI_VIEW: "/realtime/pmi/view",
    TOXIC_ACCOUNT_VIEW: "/realtime/toxicaccount/view",
    ADMIN_TOOLS_TICKET_RESTORATION_VIEW: "/admintools/ticketrestoration/view",
    ADMIN_TOOLS_TICKET_RESTORATION_EDIT: "/admintools/ticketrestoration/edit",
    ADMIN_TOOLS_HUB_SWITCHER_EDIT: "/admintools/hubSwitcher/edit",
    ADMIN_TOOLS_HUB_STATUS_DASHBOARD_VIEW: "/admintools/hubStatusDashboard/view",
    ADMIN_TOOLS_HUB_STATUS_DASHBOARD_EDIT: "/admintools/hubStatusDashboard/edit",
    ADMIN_TOOLS_HUB_CONFIG_VIEW: "/admintools/hubConfig/view",
    ADMIN_TOOLS_HUB_CONFIG_EDIT: "/admintools/hubConfig/edit",
    RISK_TOOLS_SWAPCHARGINGTOOL_VIEW: "/admintools/swapchargingtool/view",
    RISK_TOOLS_SWAPCHARGINGTOOL_EDIT: "/admintools/swapchargingtool/edit",
    RISK_TOOLS_SYMBOLSWAPUPLOAD_VIEW: "/admintools/symbolswapupload/view",
    RISK_TOOLS_SYMBOLSWAPUPLOAD_EDIT: "/admintools/symbolswapupload/edit",
    RISK_TOOLS_GROUPTRANSFERTOOL_VIEW: "/admintools/grouptransfertool/view",
    RISK_TOOLS_GROUPTRANSFERTOOL_EDIT: "/admintools/grouptransfertool/edit",
    RISK_TOOLS_HEDGETOOL_VIEW: "/admintools/hedgetool/view",
    RISK_TOOLS_ORDER_ROUTING_RECORD_VIEW: "/admintools/orderroutingrecord/view",
    RISK_TOOLS_ORDER_ROUTING_RECORD_EDIT: "/admintools/orderroutingrecord/edit",
    RISK_TOOLS_SYMBOL_LEVERAGE_EDIT: "/admintools/symbolleverage/edit",
    RISK_TOOLS_SYMBOL_LEVERAGE_VIEW: "/admintools/symbolleverage/view",
    RISK_TOOLS_CA_DIVIDEND_MAP_UPLOAD_VIEW: "/admintools/cadividendmapupload/view",
    RISK_TOOLS_CA_DIVIDEND_MAP_UPLOAD_EDIT: "/admintools/cadividendmapupload/edit",
    RISK_TOOLS_CA_DIVIDEND_SEPARATE_PROCESS_VIEW: "/admintools/cadividendseparateprocess/view",
    RISK_TOOLS_CA_DIVIDEND_SEPARATE_PROCESS_EDIT: "/admintools/cadividendseparateprocess/edit",
    RISK_TOOLS_ROLLOVER_VIEW: "/admintools/rollover/view",
    RISK_TOOLS_ROLLOVER_EDIT: "/admintools/rollover/edit",
    FUNDING_FEE_SETTINGS_VIEW: "/admintools/fundingfeesettings/view",
    FUNDING_FEE_SETTINGS_EDIT: "/admintools/fundingfeesettings/edit",
    PROBLEMATIC_CLIENT_VIEW: "/analysis/problematicClients/view",
    PROBLEMATIC_CLIENT_EDIT: "/analysis/problematicClients/edit",
    TOXIC_ACCOUNTS_VIEW: "/realtime/toxicaccount/view",
    TOXIC_ACCOUNTS_EDIT: "/realtime/toxicaccount/edit",
    PREMARKET_ACCOUNT_VIEW: "/analysis/premarketAccountModel/view",
    SYMBOL_ASSET_TYPE_VIEW: "/siteadmin/symbolconfig/assettype/view",
    SYMBOL_ASSET_TYPE_EDIT: "/siteadmin/symbolconfig/assettype/edit",
    CLEAN_SYMBOL_VIEW: "/siteadmin/symbolconfig/cleansymbol/view",
    CLEAN_SYMBOL_EDIT: "/siteadmin/symbolconfig/cleansymbol/edit",
    SYMBOL_MAP_VIEW: "/siteadmin/symbolconfig/symbolmap/view",
    SYMBOL_MAP_EDIT: "/siteadmin/symbolconfig/symbolmap/edit",
    SYMBOL_LIST_MAP_VIEW: "/siteadmin/symbolconfig/symbolinfo/view",
    SYMBOL_LIST_MAP_EDIT: "/siteadmin/symbolconfig/symbolinfo/edit",
    LP360_VIEW: "/lpmonitor/lp360/view",
    LP360_ALARM_VIEW: "/lpmonitor/alarm/view",
    LP360_ALARM_EDIT: "/lpmonitor/alarm/edit",
    INTRADAY_SUMMARY: "/intradaymonitor/summary/view",
    INTRADAY_SEARCH_VIEW: "/intradaymonitor/search/view",
    INTRADAY_SEARCH_EDIT: "/intradaymonitor/search/edit",
    INTRADAY_ALARM_VIEW: "/intradaymonitor/alarm/view",
    INTRADAY_ALARM_EDIT: "/intradaymonitor/alarm/edit",
    TOXIC_CLIENT_CHECK_EDIT: "/analysis/toxicclientcheck/edit",
    TOXIC_CLIENT_CHECK_VIEW: "/analysis/toxicclientcheck/view",
    SYMBOL_ANALYSIS_SPREAD_ANALYSIA_VIEW: "/report/spreadanalysis/view",
    SYSTEM_MONITOR_VIEW: "/systemmonitor/statusmonitor/view",
    REJECT_ORDER_VIEW: "/systemmonitor/rejectorder/view",
    ALARM_CENTER_DASHBOARD_VIEW: "/alarm-center/dashboard/view",
    ALARM_CENTER_DASHBOARD_EDIT: "/alarm-center/dashboard/edit",
    ALARM_CENTER_HISTORICAL_VIEW: "/alarm-center/historical/view",
    ALARM_CENTER_HISTORICAL_EDIT: "/alarm-center/historical/edit",
    LP_GROUP_VIEW: "/lpmonitor/group/view",
    LP_GROUP_EDIT: "/lpmonitor/group/edit",
    LINKED_ACCOUNT_QUERY_VIEW: "report/linkedaccountquery/view",
    COMMISSION_RECORD_VIEW: "/report/commissionrecord/view",
    COMMISSION_RECORD_EDIT: "/report/commissionrecord/edit",
    NEGATIVE_EQUITY_RECORD_VIEW: "/report/negativeequityrecord/view",
    NEGATIVE_EQUITY_RECORD_EDIT: "/report/negativeequityrecord/edit",
    SYNDICATE_TRADING_VIEW: "/realtime/syndicateTrading/view",
    SYNDICATE_TRADING_EDIT: "/realtime/syndicateTrading/edit",
    CONFIG_DIFF_VIEW: "/admintools/configdiff/view",
    CONFIG_DIFF_EDIT: "/admintools/configdiff/edit",
    CREDIT_TO_BALANCE_UPLOAD_VIEW: "/admintools/credittobalanceupload/view",
    CREDIT_TO_BALANCE_UPLOAD_EDIT: "/admintools/credittobalanceupload/edit",
    CID_VIEW: "/admintools/cid/view",
    CID_EDIT: "/admintools/cid/edit",
    RISK_TOOLS_BALANCE_UPDATE_EDIT: "/admintools/balanceupdate/edit",

    //RC Modules
    RC: {
        RC_ISSUES_LOG_ACCOUNT_MONITOR_UPLOAD_EDIT: "/systemmonitor/issueslog_accountmonitorupload/edit",
        RC_ISSUES_LOG_MANAGER_ACCOUNT_INTERCEPTED_EDIT: "/systemmonitor/issueslog_manageraccountintercepted/edit",
        RISK_TOOLS_CONFIG_DIFF_VIEW: "admintools/configdiff/view",
        RISK_TOOLS_READ_ONLY_VIEW: "admintools/readOnly/view",
        LINKED_ACCOUNT_RECORD_VIEW: "report/linkedaccountrecord/view",
        HFT_REPORT_VIEW: "realtime/hftReport/view",
        RC_BRIDGE_CONFIG_API_VIEW: "/siteadmin/brandserver/bridgeconfigapi/view",
        RC_BRIDGE_CONFIG_API_EDIT: "/siteadmin/brandserver/bridgeconfigapi/edit",
        RC_SERVER_FEATURE_ACCOUNT_VIEW: "/siteadmin/brandserver/serverfeatureaccount/view",
        RC_SERVER_FEATURE_ACCOUNT_EDIT: "/siteadmin/brandserver/serverfeatureaccount/edit",
        RC_FEATURE_SETTING_VIEW: "/siteadmin/brandserver/featuresetting/view",
        RC_FEATURE_SETTING_EDIT: "/siteadmin/brandserver/featuresetting/edit",
        RC_STATUS_CONFIG_RESTART_VIEW: "/admintools/statusconfigurationrestart/view",
        RC_STATUS_CONFIG_RESTART_EDIT: "/admintools/statusconfigurationrestart/edit",
        RC_PE_ORDER_COMPARE_VIEW: "/realtime/peordercompare/view",
        RC_PE_ORDER_COMPARE_EDIT: "/realtime/peordercompare/edit",
        RC_MT_CLIENT_SECURITY_VIEW: "/admintools/mtClientSecurity/view",
        RC_MT_CLIENT_SECURITY_EDIT: "/admintools/mtClientSecurity/edit",
    },
};
