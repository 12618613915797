import React from "react";
import TabContent from "../../SystemMonitor/components/TabContent";
import MidBiasTable from "./MidBiasTable";
import ServerTimeWidget from "../../SystemMonitor/components/ServerTimeWidget";
import useRCMidBias from "../../../hooks/useRCMidBias";
import { motion } from "framer-motion";
import { Alert, Button, Space, Typography } from "antd";
import { FaRedo } from "react-icons/fa";
import SoundButton from "../../SystemMonitor/components/SoundButton";
import soundFile from "../../../assets/audios/rc/midbias.mp3";
import TimeDiff from "../../SystemMonitor/components/TimeDiff";

const Tab20min = () => {
    const { isLoading, refetchRcMidBias, dataUpdatedAt, shouldPlayMidBiasAlarm, rcMidBiasGeneral, rcMidBiasFuture } = useRCMidBias({
        duration: "20min",
    });
    const { Title } = Typography;
    return (
        <TabContent>
            <Space direction="vertical" style={{ padding: "1rem", width: "100%" }}>
                <div style={{ display: "flex", alignItems: "center", marginBottom: "1rem", gap: 16 }}>
                    <Space style={{ alignItems: "center" }} wrap>
                        <Title level={2} style={{ padding: 0, margin: 0 }}>
                            Mid Bias in 20 minutes
                        </Title>
                        <SoundButton
                            soundSrc={soundFile}
                            isActive={shouldPlayMidBiasAlarm}
                            // isMutedOutside={isMuted}
                            // isMutedOutsideCallback={handleIsMuted}
                        />
                        <TimeDiff timestamp={dataUpdatedAt} refetch={refetchRcMidBias} isFetching={isLoading} rounded />
                    </Space>
                </div>
                {/* <Alert type="info" message="Using dummy data, remember to remove" /> */}
                <MidBiasTable data={rcMidBiasGeneral} refetch={refetchRcMidBias} />
                <MidBiasTable data={rcMidBiasFuture} refetch={refetchRcMidBias} />
            </Space>
        </TabContent>
    );
};

export default Tab20min;
