import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import CardBox from "../../../../components/Common/CardBox";
import FlexiDataTable from "../../../../components/FlexiDataTable";
import { ComponentType, CALLBACK_KEY, SUCCESS_FAILED, STATUS_TYPE } from "../../../../constants";
import { FlexiDataTableOptionsProps, FlexiDataTableCallbackProps, User } from "../../../../constants/type";
import { apiRequest } from "../../../../services/apiConfig";
import { APIs } from "../../../../services/apis";
import { DTColProps, ErrorMessageHandler, ErrorCatchValidator } from "../../../../utils/Common";
import AuthHelper, { AuthKeys } from "../../../../helpers/authHelper";
import moment from "moment";
import { ToOptionTypeList } from "../../../../utils/array";

export interface NotificationChannelManagementProps {}

const NotificationChannelManagement = (props: NotificationChannelManagementProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<any[]>([]);
    let navigate = useNavigate();

    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.APP_CONFIGURATION_EDIT);

    const columns: any[] = [
        DTColProps.Large({
            title: "Channel Name",
            dataIndex: "name",
            key: "name",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
        },
        DTColProps.Status({
            title: "Status",
            dataIndex: "status",
            key: "status",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: ToOptionTypeList(STATUS_TYPE),
                },
            },
        }),
    ];

    const options: FlexiDataTableOptionsProps = {
        add: enableUpdate,
        edit: enableUpdate,
        delete: enableUpdate,
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, ServerData) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                navigate("/siteadmin/appconfig/notificationchannelconfig/create", { state: { data: null, action: "add" } });
                break;
            case CALLBACK_KEY.DO_EDIT:
                navigate(`/siteadmin/appconfig/notificationchannelconfig/edit`, { state: { data: ServerData, action: "edit" } });
                break;
            case CALLBACK_KEY.DO_DELETE:
                setIsLoading(true);
                apiRequest(APIs.UPDATE_NOTIFICATION_CHANNEL, { channelUid: ServerData["channelUid"], status: 2, name: "" })
                    .then(data => {
                        ErrorMessageHandler("", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                        getNotificationChannelListing();
                    })
                    .catch(error =>
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("notification channel", SUCCESS_FAILED.FAILED_DELETE_DATA, err))
                    )
                    .finally(() => {
                        setIsLoading(false);
                    });
                break;
            default:
                break;
        }
    };

    const getNotificationChannelListing = () => {
        apiRequest(APIs.GET_NOTIFICATION_CHANNEL_LIST, {})
            .then((res: any) => {
                let defData = res as any[];
                defData.sort((a: any, b: any) => (moment(a.createdDateUtc) > moment(b.createdDateUtc) ? -1 : 1));
                setData(defData);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("notification channel", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        getNotificationChannelListing();
        return () => {};
    }, []);

    return (
        <>
            <CardBox title={"Notification Channel Management"}>
                <FlexiDataTable
                    rowKeyProperty="channelUid"
                    title=""
                    columns={columns}
                    options={options}
                    dataSource={data || []}
                    callback={componentCallback}
                    loading={isLoading}
                />
            </CardBox>
        </>
    );
};

export default NotificationChannelManagement;
