import { Modal, Typography } from "antd";
import { useState, useCallback, useMemo } from "react";
import PAAlarmServerDetail from "./PAAlarmServerDetail";
import { motion } from "framer-motion";
import { AreaChartOutlined } from "@ant-design/icons";
import Counter from "../../../components/Motion/Counter";
import { FaCheck } from "react-icons/fa";
import { colorMap } from "../../SystemMonitor/StatusMonitor/statusUtils";
import { CALLBACK_KEY, PRICE_ANALYSIS_ALARM_TAB } from "../../../constants";

const { Title, Text } = Typography;

export interface PAAlarmServerGridProps {
    server: { alarm: any; serverDn: string };
    isLoading: boolean;
}

const PAAlarmServerGrid = ({ server, isLoading }: PAAlarmServerGridProps) => {
    const [modalPopUp, setModalPopUp] = useState<boolean>(false);

    const onCallback = useCallback((type: CALLBACK_KEY) => {
        if (type === CALLBACK_KEY.CLSE_MODAL_CALLBACK) {
            setModalPopUp(false);
        }
    }, []);

    const handleIconClick = useCallback(() => {
        setModalPopUp(true);
    }, []);

    const modalTitle = useMemo(() => {
        return `Alarm records of ${server.alarm && server.alarm[0].baseServerDn} vs ${server.serverDn}`;
    }, [server.alarm, server.serverDn]);

    const backgroundColor = useMemo(() => {
        return server.alarm ? colorMap.error : colorMap.success;
    }, [server.alarm]);

    const titleColor = useMemo(() => {
        return server.alarm ? "white" : "black";
    }, [server.alarm]);

    return (
        <motion.div
            style={{ width: "100%", height: "100%", borderRadius: 16, padding: "0.8rem", background: backgroundColor }}
            initial={{ opacity: 0, y: 30 }}
            animate={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.05, type: "spring" },
            }}
        >
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <div style={{ alignItems: "center", gap: "1rem", display: "flex" }}>
                    <Title level={4} style={{ marginBottom: 0, lineHeight: 1, paddingBottom: 0, color: titleColor }}>
                        {server.serverDn}
                    </Title>
                    {server.alarm && (
                        <motion.div
                            style={{ lineHeight: 1, marginLeft: "auto", opacity: 0.85 }}
                            whileHover={{ opacity: 1, scale: 1.2 }}
                            onClick={handleIconClick}
                        >
                            <AreaChartOutlined style={{ color: "white", fontSize: 30 }} onClick={handleIconClick}/>
                        </motion.div>
                    )}
                </div>
                {server.alarm ? (
                    <Text style={{ fontSize: 24, color: "white" }}>
                        <Counter value={server.alarm.length} digits={server.alarm.length.toString().length} fontSize={25} />
                    </Text>
                ) : (
                    <div style={{ alignItems: "center", gap: "1rem", display: "flex" }}>
                        <FaCheck style={{ color: "white" }} />
                        <Text style={{ fontSize: 18, color: "white" }}> All Good</Text>
                    </div>
                )}
            </div>
            <Modal
                className="PA-alarm-modal"
                centered
                title={modalTitle}
                width={1080}
                open={modalPopUp}
                footer={null}
                maskClosable={false}
                onCancel={() => setModalPopUp(false)}
            >
                <PAAlarmServerDetail
                    svData={server.alarm}
                    isLoading={isLoading}
                    from={PRICE_ANALYSIS_ALARM_TAB.MONITORWALL}
                    callback={onCallback}
                />
            </Modal>
        </motion.div>
    );
};

export default PAAlarmServerGrid;
