import { ArrowLeftOutlined, LeftOutlined } from "@ant-design/icons";
import { Button, Col, Popconfirm, Row, Select, Statistic } from "antd";
import { useState } from "react";
import { MONITORING_REFRESH_RATE, CALLBACK_KEY } from "../../constants";
import "./index.less";

export interface BackButtonProps {
    confirmBack?: boolean;
    className?: string;
    style?: any;
    text?: string;
    onBackClick: Function;
}

export const BackButton = (props: BackButtonProps) => {
    return props.confirmBack ? (
        <Popconfirm
            placement="right"
            title={"Confirm navigate back?"}
            onConfirm={() => props.onBackClick()}
            okText={"Yes"}
            cancelText={"No"}
            className="popconfirm-div-btn"
        >
            <Button
                htmlType="button"
                icon={<LeftOutlined />}
                onClick={e => {
                    e.preventDefault();
                }}
                className={props.className || ""}
                style={{ ...(props.style || { border: "none" }), ...{ marginBottom: "1.414vh" } }}
            >
                {props.text || "Back"}
            </Button>
        </Popconfirm>
    ) : (
        <Button
            htmlType="button"
            onClick={() => props.onBackClick()}
            icon={<ArrowLeftOutlined />}
            className={props.className || ""}
            style={{ ...(props.style || { border: "none" }), ...{ marginBottom: "1.414vh" } }}
        >
            {props.text || "Back"}
        </Button>
    );
};

export interface LabelDisplayListProps {
    LabelSpan: number;
    ValueSpan: number;
    data: { label: string | React.ReactElement; value: string | React.ReactElement }[];
}

export const LabelDisplayList = (props: LabelDisplayListProps) => {
    let random = Math.random();
    return (
        <div className="label-display-list">
            {props.data &&
                props.data.map((x, index) => (
                    <Row key={random + "" + index} className="label-display-list-row">
                        <Col span={props.LabelSpan}>{x.label}:</Col>
                        <Col span={props.ValueSpan}>{x.value}</Col>
                    </Row>
                ))}
        </div>
    );
};

export interface TimerButtonProps {
    key: any;
    period: number; // seconds
    enablePeriodSelection: boolean;
    onFinish: (type: CALLBACK_KEY, currentRefreshPeriod: number) => void;
}

const { Countdown } = Statistic;

export const TimerButton = (props: TimerButtonProps) => {
    const [refreshPeriod, setRefreshPeriod] = useState<number>(props.period);

    return (
        <div className={`counter-panel ${props.enablePeriodSelection ? "" : "less"}`}>
            {props.enablePeriodSelection && (
                <Select
                    style={{ width: "5.208vw", marginLeft: "0.651vw" }}
                    defaultValue={refreshPeriod}
                    options={Object.keys(MONITORING_REFRESH_RATE).map((x: string) => ({ label: x, value: MONITORING_REFRESH_RATE[x] }))}
                    onChange={(value: number) => {
                        setRefreshPeriod(value);
                        props.onFinish && props.onFinish(CALLBACK_KEY.REFRESH_RATE_CHANGED, value);
                    }}
                />
            )}
            <Button
                key={`btn-refresh-${Math.random()}`}
                htmlType="button"
                className="timer-button"
                style={{ marginLeft: "0.651vw" }}
                icon={
                    <Countdown
                        className={`spin-bg ${refreshPeriod > 3599 ? "hr" : refreshPeriod > 59 ? "min" : "sc"}`}
                        value={Date.now() + 1000 * refreshPeriod}
                        format={`${refreshPeriod > 3599 ? "HH:mm:ss" : refreshPeriod > 59 ? "mm:ss" : "ss"}`}
                        onFinish={() => props.onFinish && props.onFinish(CALLBACK_KEY.REFRESH, refreshPeriod)}
                    />
                }
                onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();
                    props.onFinish && props.onFinish(CALLBACK_KEY.REFRESH, refreshPeriod);
                }}
            >
                Refresh
            </Button>
        </div>
    );
};
