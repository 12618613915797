import { HomeOutlined } from "@ant-design/icons";
import { Button, Card, Popconfirm } from "antd";
import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams, NavigateFunction } from "react-router-dom";
import LoadingComponent from "../../../components/Loading";
import SitePageHeader from "../../../components/PageHeader";
import { SUCCESS_FAILED } from "../../../constants";
import { AccountTypes, BrandMetricView, BrandRiskScore } from "../../../constants/type";
import { apiRequest } from "../../../services/apiConfig";
import { APIs } from "../../../services/apis";
import { ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";

const ViewRiskScore = () => {
    let navigate = useNavigate();
    let location = useLocation();
    let brandInfo = location.state as any;

    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<BrandRiskScore>();
    let { id } = useParams();
    const [accountTypes, setAccountTypes] = useState<AccountTypes[]>([]);

    useEffect(() => {
        apiRequest(APIs.GET_BRAND_RISK_SCORE_LIST, { brandId: id ? id : brandInfo.Id })
            .then((data: any) => {
                let current_data: BrandRiskScore = data[0] as BrandRiskScore;
                setData(current_data);
                setLoading(false);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("brand risk score list", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                navigate("/siteadmin/riskscore/brand");
            });
        return () => {};
    }, []);

    useEffect(() => {
        getFilterConfigList();
        return () => {};
    }, []);

    const getFilterConfigList = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["accounttype"] })
            .then((data: any) => {
                setAccountTypes(data.accountTypes);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => console.log(err));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const gridStyle = { width: "32%" };
    const titleStyle = { backgroundColor: "#fafafa", width: "18%" };

    const deleteBrandRiskScore = (brandId: string, navigator: NavigateFunction) => {
        apiRequest(APIs.DELETE_BRAND_RISK_SCORE, { brandId })
            .then((data) => {
                ErrorMessageHandler("The brand risk score", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                navigator("/siteadmin/riskscore/brand");
            })
            .catch((error) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("brand risk score", SUCCESS_FAILED.FAILED_DELETE_DATA, err))
            );
    };

    return (
        <SitePageHeader
            title={"View Brand Risk Score"}
            routes={[
                {
                    path: "/siteadmin/riskscore/brand",
                    breadcrumbName: "Brand Risk Score Configuration",
                    icon: <HomeOutlined />,
                },
                { path: "", breadcrumbName: "View Brand Risk Score" },
            ]}
            onBack={() => navigate("/siteadmin/riskscore/brand")}
            extraProps={{
                extra: [
                    <Popconfirm
                        key={"cetmp-del"}
                        title="Confirm to delete?"
                        onConfirm={() => deleteBrandRiskScore(id ? id : brandInfo.Id, navigate)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="primary" danger>
                            Delete Brand Risk Score
                        </Button>
                    </Popconfirm>,
                ],
            }}
        >
            {loading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Loading..." />
                </div>
            ) : (
                <div className="brand-risk-score-view-container">
                    <>
                        <Card title="">
                            <Card.Grid style={{ ...gridStyle, ...titleStyle }} hoverable={false}>
                                Brand
                            </Card.Grid>
                            <Card.Grid style={{ ...gridStyle, ...{ width: "82%" } }} hoverable={false}>
                                {data?.brand}
                            </Card.Grid>
                            <Card.Grid style={{ ...gridStyle, ...titleStyle }} hoverable={false}>
                                Account Type
                            </Card.Grid>
                            <Card.Grid style={{ ...gridStyle, ...{ width: "82%" } }} hoverable={false}>
                                {data
                                    ? data.accountTypes
                                          .map((x) => {
                                              let find_any: any = accountTypes.find((y) => y.id === x);
                                              return find_any?.name;
                                          })
                                          .join(" , ")
                                    : "-"}
                            </Card.Grid>
                            <Card.Grid style={{ ...gridStyle, ...titleStyle }} hoverable={false}>
                                Metric Information
                            </Card.Grid>
                            <Card.Grid style={{ ...gridStyle, ...{ width: "82%" } }} hoverable={false}>
                                <Card title="">
                                    {((data?.metrics || []) as BrandMetricView[]).map((x: BrandMetricView) => {
                                        return (
                                            <React.Fragment key={x.metricId}>
                                                <Card.Grid style={{ ...gridStyle, background: "#fafafa" }} hoverable={false}>
                                                    {x.displayName}
                                                </Card.Grid>
                                                <Card.Grid
                                                    style={{ ...gridStyle, ...titleStyle, background: "#ffffff", textAlign: "center" }}
                                                    hoverable={false}
                                                >
                                                    {x.value}
                                                </Card.Grid>
                                            </React.Fragment>
                                        );
                                    })}
                                </Card>
                            </Card.Grid>
                        </Card>
                    </>
                </div>
            )}
        </SitePageHeader>
    );
};

export default ViewRiskScore;
