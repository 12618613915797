import { Row, Col, Form } from "antd";
import { FormComponent } from "../../../../components/FormComponent";
import LoadingComponent from "../../../../components/Loading";
import SitePageHeaderInline, { SitePageHeaderInlineActionType } from "../../../../components/PageHeader/inlineIndex";
import { ComponentType, InnerPageActionMode, SUCCESS_FAILED } from "../../../../constants";
import { REQUIRED_FIELD } from "../../../../constants/errorMessage";
import { useMemo, useState, useCallback, useEffect } from "react";
import {
    MetricORConditionProps,
    KeyValuePair,
    CreateEditInlineFormBased,
    LPAlarmConfigProps,
    LPMetricsBased,
    LPAlarmRuleConfigProps,
    LPDimensionFiltersProps,
    LPAlarmDimensionConfigProps,
} from "../../../../constants/type";
import { apiRequest } from "../../../../services/apiConfig";
import { APIs } from "../../../../services/apis";
import { ErrorMessageHandler, ErrorCatchValidator, DimensionLevelObjectConfig } from "../../../../utils/Common";
import { ARRAY_ACTION_TYPE, GetConstraintKeyList, GetUniqueKeysList, SortList, ToObjectWithKey } from "../../../../utils/array";
import { objectRemoveProps, DefaultIfEmpty } from "../../../../utils/object";
import { isEmptyOrNull } from "../../../../utils/string";
import LPMetricFilterPanel from "../components/LPMetricFilterPanel";
import LPAlarmFilterPanel, { LPAlarmFilterOptionType, LPItemProps } from "../components/LPAlarmFilterPanel";

interface LPMonitorAlarmCreateEditPageProps extends CreateEditInlineFormBased {
    callback: (action: InnerPageActionMode, value?: any) => void;
    resetState: number;
}

export interface MetricConfigObjProps {
    metricObj: LPMetricsBased[];
}

export const filterType = DimensionLevelObjectConfig("LPAlarmDimensionArr");

const LPMonitorAlarmCreateEditPage = (props: LPMonitorAlarmCreateEditPageProps) => {
    const dimensionOptions = filterType.map((x: any) => ({ text: x.title, value: x.DimensionLevel }));
    const isAddAction = useMemo(() => props.mode === InnerPageActionMode.CREATE_NEW, [props.mode]);
    const [commonFilterArr, setCommonFilterArr] = useState<LPAlarmFilterOptionType>(
        ToObjectWithKey(
            filterType.map(
                (x: any) => ({ DimensionLevel: x.DimensionLevel, title: x.title, componentType: x.componentType, options: [] } as LPItemProps)
            ),
            "DimensionLevel"
        )
    );
    const [metricProps, setMetricProps] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [resetState, setResetState] = useState<number>(0);
    const [configForm] = Form.useForm();
    const selectedSectionId: string[] = Form.useWatch("Dimensions", configForm);

    const metricAndSectionsConfig: MetricConfigObjProps = useMemo(() => {
        if (isEmptyOrNull(selectedSectionId)) return { metricObj: [] };

        let foundItem = metricProps.find((x: any) => x.dimensions.some((y: string) => selectedSectionId.includes(y)));
        return {
            metricObj: foundItem !== undefined ? foundItem.metrics : [],
        };
    }, [metricProps, selectedSectionId]);

    const onSubmit = useCallback(
        (values: any) => {
            let submitParams = objectRemoveProps(values, [
                    ...["Dimensions", "DimensionFilters", "MetricFilters"],
                    ...(isAddAction ? ["alarmConfigId"] : []),
                ]),
                alarmRule: any = {},
                dimensionRule: any = {};

            if (values.MetricFilters && values.MetricFilters.length > 0) {
                alarmRule["MetricFilters"] = values.MetricFilters.filter(
                    (x: MetricORConditionProps) =>
                        x.MetricOuterFilters.length > 0 && x.MetricOuterFilters.filter(y => y.MetricInnerFilters.length > 0).length > 0
                ).map((x: MetricORConditionProps) => ({
                    ...x,
                    MetricOuterFilters: x.MetricOuterFilters.filter(y => y.MetricInnerFilters.length > 0),
                }));
            }

            if (values.Dimensions && values.Dimensions.length > 0) {
                dimensionRule["Dimensions"] = values.Dimensions;
                dimensionRule["DimensionFilters"] = values.DimensionFilters.filter((y: LPDimensionFiltersProps) =>
                    commonFilterArr[y.Dimension].options.length === y.FilterIn.length ? false : true
                );
            }

            submitParams["dimensionRules"] = JSON.stringify(dimensionRule);
            submitParams["alarmRules"] = JSON.stringify(alarmRule);

            if (isAddAction) {
                apiRequest(APIs.CREATE_LP_ALARM_CONFIG, submitParams)
                    .then(res => {
                        ErrorMessageHandler("alarm configuration", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                        setTimeout(() => props.callback && props.callback(InnerPageActionMode.BACK, { refreshMainList: true }), 400);
                    })
                    .catch(error =>
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("alarm configuration", SUCCESS_FAILED.FAILED_CREATE_DATA, err))
                    );
            } else {
                apiRequest(APIs.UPDATE_LP_ALARM_CONFIG, submitParams)
                    .then(res => {
                        ErrorMessageHandler("alarm configuration", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                        setTimeout(() => props.callback && props.callback(InnerPageActionMode.BACK, { refreshMainList: true }), 400);
                    })
                    .catch(error =>
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("alarm configuration", SUCCESS_FAILED.FAILED_UPDATE_DATA, err))
                    );
            }
        },
        [isAddAction, commonFilterArr]
    );

    const formValueChangedCallback = useCallback(
        (changedValues: any) => {
            if (changedValues?.hasOwnProperty("Dimensions")) {
                let oldDFObj = ToObjectWithKey(configForm.getFieldValue("DimensionFilters"), "Dimension");
                configForm.setFieldsValue({
                    DimensionFilters: SortList(filterType, "sequence")
                        .filter(x => changedValues["Dimensions"].includes(x.DimensionLevel))
                        .map(x =>
                            DefaultIfEmpty(oldDFObj, x.DimensionLevel, {
                                Dimension: x.DimensionLevel,
                                FilterIn:
                                    x.componentType === "rebateAccountSearch"
                                        ? []
                                        : DefaultIfEmpty(commonFilterArr, x.DimensionLevel, [])?.options.map((y: KeyValuePair) => y.value) || [],
                            })
                        ),
                });
                setResetState(prev => prev + 1);
            }
        },
        [commonFilterArr]
    );

    const fillForm = useCallback(
        (data?: LPAlarmConfigProps) => {
            if (isEmptyOrNull(data)) {
                let tmp = {
                    alarmConfigId: "",
                    alarmName: "",
                    alarmDescription: "",
                    isEnabled: true,
                    Dimensions: [],
                    DimensionFilters: [],
                    MetricFilters: [],
                };

                configForm.setFieldsValue(tmp);
            } else {
                let currentData = data as LPAlarmConfigProps,
                    tmp: any = {
                        alarmConfigId: currentData.alarmConfigId,
                        alarmName: currentData.alarmName,
                        alarmDescription: currentData.alarmDescription,
                        isEnabled: currentData.isEnabled,
                    };

                try {
                    let moreConfig: LPAlarmRuleConfigProps = JSON.parse(currentData.alarmRules),
                        dimensionConfig: LPAlarmDimensionConfigProps = JSON.parse(currentData.dimensionRules),
                        tmpDFilter = ToObjectWithKey(DefaultIfEmpty(dimensionConfig, "DimensionFilters", [], true), "Dimension");
                    tmp["Dimensions"] = DefaultIfEmpty(dimensionConfig, "Dimensions", [], true);
                    tmp["DimensionFilters"] = SortList(filterType, "sequence")
                        .filter(x => tmp["Dimensions"].includes(x.DimensionLevel))
                        .map(x => DefaultIfEmpty(tmpDFilter, x.DimensionLevel, { Dimension: x.DimensionLevel, FilterIn: [] }))
                        .map(x => ({
                            ...x,
                            FilterIn:
                                x.FilterIn.length > 0
                                    ? GetConstraintKeyList(
                                          x.FilterIn,
                                          DefaultIfEmpty(commonFilterArr, x.Dimension, [])?.options.map((y: KeyValuePair) => y.value) || [],
                                          ARRAY_ACTION_TYPE.INNER
                                      )
                                    : DefaultIfEmpty(commonFilterArr, x.Dimension, [])?.options.map((y: KeyValuePair) => y.value) || [],
                        }));
                    tmp["MetricFilters"] = DefaultIfEmpty(moreConfig, "MetricFilters", [], true);
                } catch (error) {}

                configForm.setFieldsValue(tmp);
            }
        },
        [commonFilterArr]
    );

    const getAlarmConfigDetails = (id?: number | string | undefined) => {
        if (isEmptyOrNull(id)) {
            fillForm(undefined);
            setIsLoading(false);
            return;
        }

        setIsLoading(true);
        apiRequest(APIs.GET_LP_MONITOR_ALARM_CONFIG, { alarmConfigId: id as number })
            .then((res: any) => {
                if (res.result.length > 0) {
                    fillForm(res.result[0]);
                } else {
                    ErrorMessageHandler("alarm configuration", SUCCESS_FAILED.FAILED_LOAD_DATA);
                }
            })
            .catch(error => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("alarm configuration", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                props.callback && props.callback(InnerPageActionMode.BACK);
            })
            .finally(() => setIsLoading(false));
    };

    const getOptions = (tkey: number) => {
        switch (tkey) {
            default:
                return [];
        }
    };

    const getConfigList = () => {
        apiRequest(APIs.GET_LP_ALARM_CONFIG, {})
            .then((res: any) => {
                if (res.length > 0) {
                    setMetricProps(
                        SortList(
                            res.reduce((finalObj: any[], x: any) => {
                                if (x.alarmSectionId === 1) {
                                    finalObj.push({
                                        dimensions: ["MarketGroup"],
                                        priorityLevel: 3,
                                        metrics: x.dataSources.filter((y: any) => y.isEnabledForMetricFilter),
                                        //.map((y: any) => ({ value: y.propertyName, text: y.propertyDisplayName, dataType: y.dataType })),
                                    });
                                } else if (x.alarmSectionId === 2) {
                                    finalObj.push({
                                        dimensions: ["Symbol"],
                                        priorityLevel: 1,
                                        metrics: x.dataSources.filter((y: any) => y.isEnabledForMetricFilter),
                                        //.map((y: any) => ({ value: y.propertyName, text: y.propertyDisplayName, dataType: y.dataType })),
                                    });
                                } else if (x.alarmSectionId === 3) {
                                    finalObj.push({
                                        dimensions: ["LP", "AccountId"],
                                        priorityLevel: 2,
                                        metrics: x.dataSources.filter((y: any) => y.isEnabledForMetricFilter),
                                        //.map((y: any) => ({ value: y.propertyName, text: y.propertyDisplayName, dataType: y.dataType })),
                                    });
                                }
                                return finalObj;
                            }, []),
                            "priorityLevel"
                        )
                    );
                }
            })
            .catch(error => ErrorCatchValidator(error, (err: any) => console.log(err)));

        apiRequest(APIs.GET_FILTER_CONFIG_LIST, {
            filterType: GetUniqueKeysList(filterType.filter((x: any) => !isEmptyOrNull(x.responseKey)).map((x: any) => x.requestKey)),
        })
            .then((res: any) => {
                setCommonFilterArr(
                    filterType.reduce((obj: LPAlarmFilterOptionType, x: any) => {
                        if (isEmptyOrNull(x.responseKey)) {
                            obj[x.DimensionLevel] = {
                                DimensionLevel: x.DimensionLevel,
                                title: x.title,
                                componentType: x.componentType,
                                options: SortList(getOptions(x.DimensionLevel), "text"),
                            };
                        } else if (res?.hasOwnProperty(x.responseKey)) {
                            switch (x.DimensionLevel) {
                                case "LP":
                                    obj[x.DimensionLevel] = {
                                        DimensionLevel: x.DimensionLevel,
                                        title: x.title,
                                        componentType: x.componentType,
                                        options: SortList(GetUniqueKeysList(res[x.responseKey].map((y: any) => y.lp))).map(y => ({
                                            text: y,
                                            value: y,
                                        })),
                                    };
                                    break;
                                case "AccountId":
                                    obj[x.DimensionLevel] = {
                                        DimensionLevel: x.DimensionLevel,
                                        title: x.title,
                                        componentType: x.componentType,
                                        options: SortList(
                                            res[x.responseKey].map((y: any) => ({
                                                value: `${y.lp}-${y.accountId}`,
                                                text: `${y.lp}-${y.accountId}`,
                                            })),
                                            "text"
                                        ),
                                    };
                                    break;
                                default:
                                    obj[x.DimensionLevel] = {
                                        DimensionLevel: x.DimensionLevel,
                                        title: x.title,
                                        componentType: x.componentType,
                                        options: SortList(
                                            res[x.responseKey].map((y: any) => ({ text: y[x.resText], value: y[x.resId] })),
                                            "text"
                                        ),
                                    };
                                    break;
                            }
                        }
                        return obj;
                    }, {})
                );
            })
            .catch(error => ErrorCatchValidator(error, (err: any) => console.log(err)));
    };

    useEffect(() => getAlarmConfigDetails(props.id), [props.resetState]);
    useEffect(() => {
        getConfigList();
    }, []);

    return (
        <SitePageHeaderInline
            enableSubmit
            title={isAddAction ? "Create New Alarm Configuration" : "Edit Alarm Configuration"}
            callback={(action: SitePageHeaderInlineActionType) => {
                switch (action) {
                    case SitePageHeaderInlineActionType.OnBack:
                    case SitePageHeaderInlineActionType.OnCancel:
                        props.callback && props.callback(InnerPageActionMode.BACK);
                        return;
                    case SitePageHeaderInlineActionType.OnSubmit:
                        configForm
                            .validateFields()
                            .then(res => onSubmit(res))
                            .catch(err => console.log("form-error:", err));
                        return;
                    default:
                        break;
                }
            }}
        >
            {isLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Loading..." />
                </div>
            ) : (
                <div className="lpmonitor-create-edit-form">
                    <Form
                        labelAlign="left"
                        labelCol={{ span: 7 }}
                        form={configForm}
                        layout="horizontal"
                        initialValues={{}}
                        onFinish={onSubmit}
                        onValuesChange={formValueChangedCallback}
                    >
                        <FormComponent label="" name="alarmConfigId" extra={{ type: ComponentType.hidden, value: "" }} />
                        <Row>
                            <Col span={14} className="main-panel-left">
                                <FormComponent
                                    label="Name"
                                    name="alarmName"
                                    extra={{ type: ComponentType.text, value: "", rules: [{ required: true, message: REQUIRED_FIELD }] }}
                                />
                                <FormComponent label="Description" name="alarmDescription" extra={{ type: ComponentType.text, value: "" }} />
                                <FormComponent
                                    label="Status"
                                    name="isEnabled"
                                    extra={{ type: ComponentType.switch, value: ["Inactive", "Active"] }}
                                />
                                <LPMetricFilterPanel form={configForm} componentId={[]} metrics={metricAndSectionsConfig.metricObj} />
                            </Col>
                            <Col span={10} className="main-panel-right">
                                <FormComponent
                                    label="Dimension(s)"
                                    name="Dimensions"
                                    extra={{
                                        type: ComponentType.dropdown,
                                        value: dimensionOptions,
                                        rules: [{ required: true, message: REQUIRED_FIELD }],
                                        inputProps: {
                                            mode: "multiple",
                                        },
                                    }}
                                />
                                <LPAlarmFilterPanel form={configForm} options={commonFilterArr} resetState={resetState} />
                            </Col>
                        </Row>
                    </Form>
                </div>
            )}
        </SitePageHeaderInline>
    );
};

export default LPMonitorAlarmCreateEditPage;
