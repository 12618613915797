import React, { useEffect, useRef, useState } from "react";
import CustomSkeleton from "../../../../components/Common/Skeleton";
import { Treemap } from "@ant-design/charts";
import EmptyData from "../../../../components/Common/Empty";
import { cloneDeep } from "lodash";
import { ErrorCatchValidator, ErrorMessageHandler, currencyRender } from "../../../../utils/Common";
import { APIs, apiRequest } from "../../../../services/apiConfig";
import { SUCCESS_FAILED } from "../../../../constants";
import { BrandsList, MimMetricsList } from "../../../../constants/type";
import { Button, Select } from "antd";
import { SortAscendingOutlined, SortDescendingOutlined } from "@ant-design/icons";

interface BrandSummaryProps {
    metrics: MimMetricsList[];
    brands: BrandsList[];
    resetState: number;
}

const BrandSummary = (props: BrandSummaryProps) => {
    // component number 5
    const [oriData, setOriData] = useState<any[]>([]);
    const [listData, setListData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selectedMetric, setSelectedMetric] = useState<string>("total_pnl");
    const [topBottom, setTopBottom] = useState<boolean>(true);
    const [refetchData, setRefetchData] = useState<boolean>(false);

    const ref = useRef<HTMLHeadingElement>(null);

    const getItemTemplate = (items: any[], isLast: boolean = false, totalItem: number = 10): React.ReactNode => {
        let returnTemplate: React.ReactNode[] = [];
        for (let index = 0; index < (items.length > totalItem ? totalItem : items.length); index++) {
            const x = items[isLast ? items.length - 1 - index : index];
            returnTemplate.push(
                <div className="title-value-div" key={`pnl-ts-${index}`}>
                    <div className="title">{x.name}</div>
                    <div className="value">{currencyRender(x.value, 2)}</div>
                </div>
            );
        }

        return returnTemplate;
    };

    const config: any = {
        data: {
            name: "root",
            children: cloneDeep(oriData).map((x) => {
                x.value = Math.abs(x.value);
                return x;
            }),
        },
        legend: {
            position: "right",
        },
        colorField: "type",
        color: ({ type }: any) => (type === "Loss" || type === "< 0" ? "#1c9898" : "#dc1616"),
        ...(ref.current?.clientWidth !== undefined && {
            height: ref.current?.clientWidth / 2,
        }),
        tooltip: {
            formatter: (data: any) => {
                return { name: data.name, value: currencyRender(data.value * (data.type === "Loss" || data.type === "< 0" ? -1 : 1), 2) };
            },
        },
    };

    const getMetricRanking = () => {
        setIsLoading(true);
        apiRequest(APIs.GET_MIM_METRIC_RANKING, {
            dimensionSelectors: [4],
            metricSelectors: [
                {
                    metricName: selectedMetric,
                    columnOrder: 1,
                },
            ],
            metricSorters: [
                {
                    metricName: selectedMetric,
                    sortOrder: 1,
                    sortDirection: 1,
                },
            ],
        })
            .then((res: any) => {
                if (res && res.length > 0) {
                    let newData = res.map((x: any) => {
                        return {
                            ...x,
                            brandName: props.brands.find((y: BrandsList) => y.id === x.brand_id)?.brand,
                        };
                    });
                    let finalData: any[] = newData.map((x: any, index: number) => {
                        const currMetric = props.metrics.find((y: any) => y.metricName === selectedMetric);
                        if (currMetric && selectedMetric.includes("pnl")) {
                            return {
                                type: currMetric && x[selectedMetric] < 0 ? "Loss" : "Profit",
                                name: x.brandName,
                                value: currMetric && x[selectedMetric],
                                seq: index,
                            };
                        } else
                            return {
                                type: currMetric && x[selectedMetric] < 0 ? "< 0" : ">= 0",
                                name: x.brandName,
                                value: currMetric && x[selectedMetric],
                                seq: index,
                            };
                    });
                    setOriData(finalData);
                    setListData(finalData);
                } else {
                    setOriData([]);
                    setListData([]);
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("brand summary", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        setRefetchData(true);
        return () => {};
    }, [props.resetState]);

    useEffect(() => {
        if (refetchData) {
            getMetricRanking();
            setRefetchData(false);
        }
        return () => {};
    }, [refetchData]);

    useEffect(() => {
        if (oriData.length > 0) {
            if (topBottom) {
                // desc order = largest first
                setListData(oriData);
            } else {
                // asc order = smallest first
                let sortedData: any[] = [...oriData];
                sortedData.sort((a, b) => a.value - b.value);
                setListData(sortedData);
            }
        }
        return () => {};
    }, [topBottom]);

    return (
        <>
            <div className="intraday-brand-summary-panel">
                <div className="c-left">
                    <div className="title-box">
                        <span className={`title-box-text summary`}>Client Brand Summary</span>
                    </div>
                    {isLoading ? (
                        <CustomSkeleton rows={10} />
                    ) : oriData?.length > 0 ? (
                        // <div className="chart">
                        <Treemap {...config} width={ref.current?.clientWidth} />
                    ) : (
                        // </div>
                        <>
                            <EmptyData />
                        </>
                    )}
                </div>
                {!isLoading && (
                    <div className="c-right">
                        <div className="main-content">
                            <div>
                                <div className="desc-tags">
                                    <div className="tag-div">
                                        <div className="select-filter-div">
                                            <Select
                                                bordered={false}
                                                defaultValue={selectedMetric}
                                                onChange={(value: any) => {
                                                    setSelectedMetric(value);
                                                    setRefetchData(true);
                                                }}
                                                options={props.metrics.map((x: MimMetricsList) => ({
                                                    label: x.metricDisplayName,
                                                    value: x.metricName,
                                                }))}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="filter-div">
                                    <Button
                                        icon={topBottom ? <SortDescendingOutlined /> : <SortAscendingOutlined />}
                                        onClick={() => setTopBottom((prev) => !prev)}
                                    />
                                </div>
                                <div className="content summary">
                                    <div className="tag-summary-desc-panel nice-scrollbar">{getItemTemplate(listData, false, listData.length)}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default BrandSummary;
