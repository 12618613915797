import { motion, AnimatePresence } from "framer-motion";
import useMeasure from "react-use-measure";

const ResizeablePanel = ({ children, noFadeout = false }: { children: any; noFadeout?: boolean }) => {
    // @ts-ignore
    const [ref, { height, width }] = useMeasure();
    const duration = 0.5;
    return (
        <motion.div
            animate={{ height: height || "auto" }}
            // className="relative overflow-hidden"
            // style={{ position: "relative", overflow: "hidden" }}
            style={{
                position: "relative",
                overflow: "hidden",
            }}
            transition={{ duration: duration, ease: "easeInOut" }}
        >
            <AnimatePresence initial={false}>
                <motion.div
                    key={JSON.stringify(children, ignoreCircularReferences())}
                    initial={{
                        opacity: noFadeout ? 1 : 0,
                        // x: width / 2,
                    }}
                    animate={{
                        opacity: 1,
                        // x: 0,
                        transition: {
                            duration: duration / 2,
                            delay: duration / 2,
                        },
                    }}
                    exit={{
                        opacity: noFadeout ? 1 : 0,
                        // x: -width / 2,
                        transition: { duration: duration / 2 },
                    }}
                >
                    <div
                        ref={ref}
                        // className={`${
                        //     height ? "absolute" : "relative"
                        // } px-8 pb-8`}
                        style={{
                            position: height ? "absolute" : "relative",
                            width: "100%",
                            // padding: "8px 8px",
                        }}
                    >
                        {children}
                    </div>
                </motion.div>
            </AnimatePresence>
        </motion.div>
    );
};

const ignoreCircularReferences = () => {
    const seen = new WeakSet();
    // @ts-ignore
    return (key, value) => {
        if (key.startsWith("_")) return; // Don't compare React's internal props.
        if (typeof value === "object" && value !== null) {
            if (seen.has(value)) return;
            seen.add(value);
        }
        return value;
    };
};

export default ResizeablePanel;
