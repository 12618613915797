export const massageData = (ori: any, department: string) => {
    const resultData: any = [];
    const nowRes = ori;

    // if (nowRes.status) {
    const data3 = nowRes.map((item: any) => {
        const vendorArr = item.vendor.split(`${department}@`);
        const vendor = vendorArr.length === 2 ? vendorArr[1] : vendorArr[0];
        return {
            ...item,
            vendor: vendor ? vendor : "RiskCenter",
            department: department,
            processed: false,
        };
    });
    resultData.push(...data3);
    // }
    // console.log("resultData", resultData);

    // const eCount = resultData.filter((item: any) => item.status === 2).length;
    // const wCount = resultData.filter((item: any) => item.status === 1).length;
    // console.log(eCount, wCount);

    const uniqVendor = Array.from(new Set(resultData.map((item: any) => item.vendor)));

    const data = uniqVendor.map(vendor => {
        const vendorData = resultData.filter((item: any) => item.vendor === vendor);
        const uniqGroup = Array.from(new Set(vendorData.map((item: any) => item.groupId)));
        // 依據群組塞資料
        const groupData = uniqGroup.map(group => {
            const tempData = vendorData.filter((data2: any) => data2.groupId === group);
            let danger = 0,
                warning = 0,
                success = 0;

            tempData.forEach((data: any) => {
                if (data.status === 0) success++;
                if (data.status === 1) warning++;
                if (data.status === 2) danger++;
                const tempArr = data.appName.split("_");
                data.serverName = tempArr.length >= 2 ? tempArr[1] : data.appName;
            });

            return {
                group: group,
                data: tempData,
                danger: danger,
                warning: warning,
                success: success,
            };
        });
        // 此為要顯示的錯誤數量
        const errorCount = vendorData.filter((item: any) => item.status === 2).length;
        const warningCount = vendorData.filter((item: any) => item.status === 1).length;
        return {
            vendor: vendor,
            groupData: groupData,
            errorCount: errorCount,
            warningCount: warningCount,
        };
    });

    return data;
    // console.log("data",data)
};

export const massageDailyData = (ori: any) => {
    const resultData: any[] = [];
    const nowRes = ori;

    const data3 = nowRes.map((item: any) => {
        return {
            ...item,
            vendor: item.vendor ? item.vendor : "RiskCenter",
            processed: false,
        };
    });
    resultData.push(...data3);

    // console.log(resultData);

    const eCount = resultData.filter(item => item.status === 2).length;
    const wCount = resultData.filter(item => item.status === 1).length;

    // processAudioCondition(eCount, wCount);

    const uniqVendor = Array.from(new Set(resultData.map(item => item.vendor)));

    const data = uniqVendor.map(vendor => {
        const vendorData = resultData.filter(item => item.vendor === vendor);
        const uniqGroup = Array.from(new Set(vendorData.map(item => item.group)));
        // 依據群組塞資料
        const groupData = uniqGroup.map(group => {
            // make consistence, swap status code
            const swapSuccessAndWarning = vendorData.map(data => {
                if (data.status === 0) {
                    return { ...data, status: 1 };
                } else if (data.status === 1) {
                    return { ...data, status: 0 };
                }
                return data;
            });
            const tempData = swapSuccessAndWarning.filter(data2 => data2.group === group);
            let danger = 0,
                warning = 0,
                success = 0,
                secondary = 0;

            tempData.forEach(data => {
                if (data.status === 0) success++;
                if (data.status === 1) warning++;
                if (data.status === 2) danger++;
                if (data.status === -1) secondary++;
            });

            return {
                group: group,
                data: tempData,
                danger: danger,
                warning: warning,
                secondary: secondary,
                success: success,
            };
        });
        // 此為要顯示的錯誤數量
        const errorCount = vendorData.filter(item => item.status === 2).length ?? 0;
        const warningCount = vendorData.filter(item => item.status === 0).length ?? 0;
        const disabledCount = vendorData.filter(item => item.status === -1).length ?? 0;
        const successCount = vendorData.filter(item => item.status === 1).length ?? 0;
        return {
            vendor,
            groupData,
            errorCount,
            warningCount,
            disabledCount,
            successCount,
        };
    });

    return data;
};

export const thousandSeparator = (v: any) => {
    return v?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const toFixed = (v: any, n: number) => {
    // if it's string, try convert to number, if can't convert, return orignal value
    if (typeof v === "string") {
        const num = Number(v);
        if (isNaN(num)) {
            return v;
        }
        v = num;
    }
    return v.toFixed(n);
};

export const capsToTitleCase = (input: string) => {
    // if input is not string, return original value
    if (typeof input !== "string") {
        return input;
    }

    // Convert the string to lowercase
    let lowerCaseStr = input?.toLowerCase();

    // Split the string by underscores
    let words = lowerCaseStr?.split("_");

    // Capitalize the first letter of each word
    for (let i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }

    // Join the words back together with spaces
    let result = words.join(" ");

    return result;
};

export const colorMap = {
    primary: "#00615D",
    success: "#3ecf8e",
    // success: "#49aa19",
    warning: "#f0a500",
    error: "#f00f00",
    info: "#91caff",
    disabled: "#d3d3d3",
};
export const colorMapRGB = {
    primary: "0, 97, 93",
    success: "62, 207, 142",
    // success: "73, 170, 25",
    warning: "240, 165, 0",
    error: "240, 15, 0",
    info: "145, 202, 255",
    disabled: "211, 211, 211",
};
// Function to convert HEX to RGB
function hexToRgb(hex: string): { r: number; g: number; b: number } {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return { r, g, b };
}

// Function to convert RGB to HSL
function rgbToHsl(r: number, g: number, b: number): { h: number; s: number; l: number } {
    r /= 255;
    g /= 255;
    b /= 255;
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h = 0,
        s,
        l = (max + min) / 2;

    if (max === min) {
        h = s = 0; // achromatic
    } else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
            case r:
                h = (g - b) / d + (g < b ? 6 : 0);
                break;
            case g:
                h = (b - r) / d + 2;
                break;
            case b:
                h = (r - g) / d + 4;
                break;
        }
        h /= 6;
    }

    return { h, s, l };
}

// Function to convert HSL to RGB
function hslToRgb(h: number, s: number, l: number): { r: number; g: number; b: number } {
    let r, g, b;

    if (s === 0) {
        r = g = b = l; // achromatic
    } else {
        const hue2rgb = (p: number, q: number, t: number) => {
            if (t < 0) t += 1;
            if (t > 1) t -= 1;
            if (t < 1 / 6) return p + (q - p) * 6 * t;
            if (t < 1 / 2) return q;
            if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
            return p;
        };
        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;
        r = hue2rgb(p, q, h + 1 / 3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1 / 3);
    }

    return { r: Math.round(r * 255), g: Math.round(g * 255), b: Math.round(b * 255) };
}

// Function to convert RGB to HEX
function rgbToHex(r: number, g: number, b: number): string {
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
}

export const getContrastColor = ({ hexColor }: { hexColor: string }) => {
    const { r, g, b } = hexToRgb(hexColor);

    // Calculate perceived brightness
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;

    // Return black or white
    return brightness > 128 ? "#000000" : "#FFFFFF";
};
