import { FormComponent } from "@/components/FormComponent";
import { ComponentType } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { CrmBlockTypes, CrmBrandList, CrmProblematicStatus, RegulatorList, ServersList } from "@/constants/type";
import { Form, Row, Col } from "antd";
import { FormInstance } from "antd/es/form";

export interface CreateProblematicClientModalProps {
    filterConfigData: any;
    createForm: FormInstance;
}

const CreateProblematicClientModal = (props: CreateProblematicClientModalProps) => {
    return (
        <Form form={props.createForm} layout="horizontal" labelCol={{ span: 8 }} labelAlign="left">
            <Row>
                <Col span={24}>
                    <FormComponent
                        label="Server"
                        name="server"
                        extra={{
                            type: ComponentType.dropdown,
                            value: props.filterConfigData.servers.map((x: ServersList) => ({ text: x.server, value: `${x.id}|${x.server}` })),
                            rules: [{ required: true, message: REQUIRED_FIELD }],
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <FormComponent
                        label="Login"
                        name="accountId"
                        extra={{
                            type: ComponentType.number,
                            value: "",
                            rules: [{ required: true, message: REQUIRED_FIELD }],
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <FormComponent
                        label="Brand"
                        name="brand"
                        extra={{
                            type: ComponentType.dropdown,
                            value: props.filterConfigData.crmBrands.map((x: CrmBrandList) => ({
                                text: x.brand,
                                value: x.brand,
                            })),
                            rules: [{ required: true, message: REQUIRED_FIELD }],
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <FormComponent
                        label="Regulator"
                        name="regulator"
                        extra={{
                            type: ComponentType.dropdown,
                            value: props.filterConfigData.regulators.map((x: RegulatorList) => ({
                                text: x.name,
                                value: x.name,
                            })),
                            rules: [{ required: true, message: REQUIRED_FIELD }],
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <FormComponent
                        label="Status"
                        name="status"
                        extra={{
                            type: ComponentType.dropdown,
                            value: props.filterConfigData.crmProblematicStatus.filter((x: CrmProblematicStatus) => x.id !== 5).map((x: CrmProblematicStatus) => ({
                                text: x.status,
                                value: x.status,
                            })),
                            rules: [{ required: true, message: REQUIRED_FIELD }],
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <FormComponent
                        label="CRM Block Type"
                        name="blockType"
                        extra={{
                            type: ComponentType.dropdown,
                            value: props.filterConfigData.crmBlockTypes.map((x: CrmBlockTypes) => ({ text: x.blockType, value: x.blockType })),
                            rules: [{ required: true, message: REQUIRED_FIELD }],
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <FormComponent
                        label="Comment"
                        name="comment"
                        extra={{
                            type: ComponentType.textarea,
                            value: "",
                        }}
                    />
                </Col>
            </Row>
        </Form>
    );
};

export default CreateProblematicClientModal;
