import { Timeline } from "antd";
import { useCallback, useEffect, useState } from "react";
import LoadingComponent from "../../../../components/Loading";
import { APIs, apiRequest } from "../../../../services/apiConfig";
import { TimelineItemProps } from "../alarmDashboard/alarmDashboardTimelineItem";
import EmptyData from "../../../../components/Common/Empty";
import { isEmptyOrNull } from "../../../../utils/string";
import { DataTableColumnRender } from "../../../../utils/Common";
import AlarmModalWrapperComponent from "./wrapperComponent";

export interface AlarmDashboardCommentHistoryProps {
    data: TimelineItemProps;
}

export interface CommentHistoryItemProps {
    propsType?: number;
    commentDateUtc: string;
    commentedBy: string;
    eventComment: string;
    eventCommentUID: string;
}

const AlarmDashboardCommentHistory = (props: AlarmDashboardCommentHistoryProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [commentList, setCommentList] = useState<CommentHistoryItemProps[]>([]);

    const getCommentList = useCallback(() => {
        setIsLoading(true);
        apiRequest(
            APIs.GET_ALARM_COMMENT_HISTORY,
            isEmptyOrNull(props.data.correlationHashKey)
                ? { alarmEventId: props.data.alarmEventId }
                : { correlationHashKey: props.data.correlationHashKey }
        )
            .then((res: CommentHistoryItemProps[]) => {
                setCommentList(res && res.length > 0 ? res : []);
            })
            .finally(() => setIsLoading(false));
    }, [props.data]);

    useEffect(() => {
        getCommentList();
    }, [props.data]);

    return (
        <AlarmModalWrapperComponent>
            <div className="comment-history">
                {isLoading ? (
                    <LoadingComponent />
                ) : commentList.length > 0 ? (
                    <Timeline>
                        {commentList.map((x: CommentHistoryItemProps, index: number) => (
                            <Timeline.Item key={`aldsb-ch-r-${index}`}>
                                <div className="item">
                                    <div className="message-content">{x.eventComment}</div>
                                    <div className="message-footer">{`${DataTableColumnRender.DateTime(x.commentDateUtc)} - ${x.commentedBy}`}</div>
                                </div>
                            </Timeline.Item>
                        ))}
                    </Timeline>
                ) : (
                    <EmptyData />
                )}
            </div>
        </AlarmModalWrapperComponent>
    );
};

export default AlarmDashboardCommentHistory;
