import React from "react";
const FloatingMenu = ({ children }: { children?: React.ReactNode }) => {
    return (
        <div
            style={{
                position: "sticky",
                top: "0.5rem",
                background: "rgba(250,250,250,0.75)",
                backdropFilter: "blur(3px)",
                padding: "0.5rem",
                borderRadius: "0.25rem",
                zIndex: 100,
                boxShadow: `
                0px 0.4px 1.6px rgba(0, 0, 0, 0.02),
                0px 1px 3.8px rgba(0, 0, 0, 0.028),
                0px 1.9px 7.1px rgba(0, 0, 0, 0.035),
                0px 3.4px 12.7px rgba(0, 0, 0, 0.042),
                0px 6.3px 23.8px rgba(0, 0, 0, 0.05),
                0px 15px 57px rgba(0, 0, 0, 0.07)`,
                minWidth: 330,
            }}
        >
            <>{children || null}</>
        </div>
    );
};

export default FloatingMenu;
