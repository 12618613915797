import React, { useEffect, useMemo, useState } from "react";
import FlexiDataTable from "../../../../components/FlexiDataTable";
import { BrandsList, FlexiDataTableCallbackProps, ServersList, ToxicClientCheckReportDashboardData, UsersList } from "../../../../constants/type";
import { CALLBACK_KEY, SUCCESS_FAILED } from "../../../../constants";
import { currencyRender, DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../../../utils/Common";
import { APIs, apiRequest } from "../../../../services/apiConfig";
import { DateTimeUtil } from "../../../../utils/datetime";
import { Form, Table, Typography, message } from "antd";
import ReportDashboardCustomGroupedFilter from "./Components/reportDashboardCustomGroupedFilter";
import moment from "moment";
import LoadingComponent from "../../../../components/Loading";

interface TccReportDashboardCommonFilterPops {
    servers: ServersList[];
    brands: BrandsList[];
    users: UsersList[];
}

const devInitialFilterParams = {
    issuedDateFrom: DateTimeUtil.GetOrigin(moment().startOf("year"), "YYYY-MM-DD"),
    issuedDateTo: DateTimeUtil.GetOrigin(moment().endOf("day"), "YYYY-MM-DD"),
    brandIds: [5, 6, 7, 8, 10, 4, 12, 9],
};
const prodInitialFilterParams = {
    issuedDateFrom: DateTimeUtil.GetOrigin(moment().startOf("year"), "YYYY-MM-DD"),
    issuedDateTo: DateTimeUtil.GetOrigin(moment().endOf("day"), "YYYY-MM-DD"),
    brandIds: [5, 6, 7, 10, 3, 4, 15, 9, 16],
};
const devFilterInitialValues = {
    issuedDate: [moment().startOf("year"), moment().endOf("day")],
    brandId: [5, 6, 7, 8, 10, 4, 12, 9],
};
const prodFilterInitialValues = {
    issuedDate: [moment().startOf("year"), moment().endOf("day")],
    brandId: [5, 6, 7, 10, 3, 4, 15, 9, 16],
};

const ReportDashboard = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<ToxicClientCheckReportDashboardData[]>([]);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [filterParams, setFilterParams] = useState<any>({
        ...(process.env.REACT_APP_ENV === "dev"
            ? devInitialFilterParams
            : process.env.REACT_APP_ENV === "prod"
            ? prodInitialFilterParams
            : devInitialFilterParams),
    });
    const [cfData, setCfData] = useState<TccReportDashboardCommonFilterPops>({
        brands: [],
        users: [],
        servers: [],
    });
    const [filterForm] = Form.useForm();
    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);

    const columns = useMemo(
        () => [
            {
                title: "Toxic Client Label",
                dataIndex: "labelName",
                key: "labelName",
                width: "11vw",
            },
            DTColProps.XXSmall(
                {
                    title: "Count",
                    dataIndex: "labelCount",
                    key: "labelCount",
                },
                ["text-center"]
            ),
            DTColProps.SCurrency({
                title: (
                    <div className="header-title-container">
                        <div className="header-title-text">Illegal Profit (USD)</div>
                        <div className="header-title-text">(Client Aspect)</div>
                    </div>
                ),
                dataIndex: "illegalProfitUsd",
                key: "illegalProfitUsd",
                render: (illeglProfitUsd: number) => currencyRender(Math.round(illeglProfitUsd), -1),
            }),
            DTColProps.SCurrency({
                title: (
                    <div className="header-title-container">
                        <div className="header-title-text">Illegal Profit (USD)</div>
                        <div className="header-title-text">(Client Aspect)</div>
                        <div className="header-title-text marked">(Only Positive)</div>
                    </div>
                ),
                dataIndex: "onlyPositiveIllegalProfitUsd",
                key: "onlyPositiveIllegalProfitUsd",
                render: (onlyPositiveIllegalProfitUsd: number) => currencyRender(Math.round(onlyPositiveIllegalProfitUsd), -1),
            }),
            DTColProps.SCurrency({
                title: (
                    <div className="header-title-container">
                        <div className="header-title-text">Suggested Deduction (USD)</div>
                        <div className="header-title-text">(Client Aspect)</div>
                    </div>
                ),
                dataIndex: "suggestedDeductionUsd",
                key: "suggestedDeductionUsd",
                render: (suggestedDeductionUsd: number) => currencyRender(Math.round(suggestedDeductionUsd), -1),
            }),
            DTColProps.SCurrency({
                title: (
                    <div className="header-title-container">
                        <div className="header-title-text">Actual Deduction (USD)</div>
                        <div className="header-title-text">(Client Aspect)</div>
                    </div>
                ),
                dataIndex: "actualDeductionUsd",
                key: "actualDeductionUsd",
                render: (actualDeductionUsd: number) => currencyRender(Math.round(actualDeductionUsd), -1),
            }),
            DTColProps.SCurrency({
                title: (
                    <div className="header-title-container">
                        <div className="header-title-text">Missed (USD)</div>
                        <div className="header-title-text marked">(Only Positive)</div>
                    </div>
                ),
                dataIndex: "onlyPositiveMissedProfitUsd",
                key: "onlyPositiveMissedProfitUsd",
                render: (onlyPositiveMissedProfitUsd: number) => currencyRender(Math.round(onlyPositiveMissedProfitUsd), -1),
            }),
            DTColProps.SCurrency({
                title: (
                    <div className="header-title-container">
                        <div className="header-title-text">Catch Percentage (%)</div>
                        <div className="header-title-text marked">(Only Positive)</div>
                    </div>
                ),
                dataIndex: "onlyPositiveCatchPercentage",
                key: "onlyPositiveCatchPercentage",
            }),
            DTColProps.SCurrency({
                title: (
                    <div className="header-title-container">
                        <div className="header-title-text">Pickup by Risk Percentage (%)</div>
                    </div>
                ),
                dataIndex: "pickupByRiskPercentage",
                key: "pickupByRiskPercentage",
                render: (pickupByRiskPercentage: number, rowData: ToxicClientCheckReportDashboardData) => (
                    <span>
                        {pickupByRiskPercentage === 0 ? 0 : currencyRender(pickupByRiskPercentage, 2)} ({rowData.pickupByRiskCount})
                    </span>
                ),
            }),
        ],
        []
    );

    const displaySummaryTotal = (pageData: any) => {
        let totalLabelCount = 0,
            totalIllegalProfitUsd = 0,
            totalOnlyPositiveIllegalProfitUsd = 0,
            totalSuggestedDeductionUsd = 0,
            totalActualDeductionUsd = 0,
            totalOnlyPositiveMissedProfitUsd = 0,
            totalOnlyPositiveCatchPercentage = 0,
            totalPickupByRiskCount = 0,
            totalPickupByRiskPercentage = 0;

        pageData.forEach((x: ToxicClientCheckReportDashboardData) => {
            totalLabelCount += x.labelCount;
            totalIllegalProfitUsd += x.illegalProfitUsd;
            totalOnlyPositiveIllegalProfitUsd += x.onlyPositiveIllegalProfitUsd;
            totalSuggestedDeductionUsd += x.suggestedDeductionUsd;
            totalActualDeductionUsd += x.actualDeductionUsd;
            totalOnlyPositiveMissedProfitUsd += x.onlyPositiveMissedProfitUsd;
            totalOnlyPositiveCatchPercentage = (totalSuggestedDeductionUsd / totalOnlyPositiveIllegalProfitUsd) * 100;
            totalPickupByRiskCount += x.pickupByRiskCount;
            totalPickupByRiskPercentage = (totalPickupByRiskCount / totalLabelCount) * 100;
        });

        return (
            <>
                <Table.Summary.Row className="summary-row">
                    <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                    <Table.Summary.Cell index={1}>
                        <Typography.Text>{totalLabelCount}</Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2}>
                        <Typography.Text type={totalIllegalProfitUsd < 0 ? "danger" : undefined}>
                            {currencyRender(Math.round(totalIllegalProfitUsd), -1)}
                        </Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3}>
                        <Typography.Text type={totalOnlyPositiveIllegalProfitUsd < 0 ? "danger" : undefined}>
                            {currencyRender(Math.round(totalOnlyPositiveIllegalProfitUsd), -1)}
                        </Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4}>
                        <Typography.Text type={totalSuggestedDeductionUsd < 0 ? "danger" : undefined}>
                            {currencyRender(Math.round(totalSuggestedDeductionUsd), -1)}
                        </Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5}>
                        <Typography.Text type={totalActualDeductionUsd < 0 ? "danger" : undefined}>
                            {currencyRender(Math.round(totalActualDeductionUsd), -1)}
                        </Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={6}>
                        <Typography.Text type={totalOnlyPositiveMissedProfitUsd < 0 ? "danger" : undefined}>
                            {currencyRender(Math.round(totalOnlyPositiveMissedProfitUsd), -1)}
                        </Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={7}>
                        <Typography.Text type={totalOnlyPositiveCatchPercentage < 0 ? "danger" : undefined}>
                            {isNaN(totalOnlyPositiveCatchPercentage)
                                ? 0
                                : totalOnlyPositiveCatchPercentage === 0
                                ? 0
                                : currencyRender(totalOnlyPositiveCatchPercentage, 2)}
                        </Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={8}>
                        <Typography.Text type={totalPickupByRiskPercentage < 0 ? "danger" : undefined}>
                            {isNaN(totalPickupByRiskPercentage)
                                ? 0
                                : totalPickupByRiskPercentage === 0
                                ? 0
                                : currencyRender(totalPickupByRiskPercentage, 2)}
                        </Typography.Text>
                    </Table.Summary.Cell>
                </Table.Summary.Row>
            </>
        );
    };

    const customFilterComponentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                let fParams: any = {};
                let restrictionFilters: any = {};
                Object.keys(FormData)
                    .filter(x => FormData[x] !== undefined && FormData[x].toString().length > 0)
                    .map(x => {
                        if (x === "issuedDate") {
                            if (FormData[x] === null) return false;
                            else {
                                fParams["issuedDateFrom"] = DateTimeUtil.GetOrigin(FormData[x][0], "YYYY-MM-DD");
                                fParams["issuedDateTo"] = DateTimeUtil.GetOrigin(FormData[x][1], "YYYY-MM-DD");
                            }
                        } else if (x === "serverId") {
                            fParams["serverIds"] = FormData[x];
                        } else if (x === "brandId") {
                            fParams["brandIds"] = FormData[x];
                        } else if (x === "status") {
                            fParams["statuses"] = FormData[x];
                        } else if (x === "inspector") {
                            fParams["inspectors"] = FormData[x];
                        } else if (x === "pickupTeam") {
                            fParams["pickupTeams"] = FormData[x];
                        } else if (x === "illegalProfitUsd") {
                            if (FormData[x] === undefined) return false;
                            else {
                                let tmp = FormData[x].split("|");
                                fParams["illegalProfitUsdFrom"] = Number(tmp[0]);
                                fParams["illegalProfitUsdTo"] = Number(tmp[1]);
                            }
                        } else if (x === "groupChangeFilter") {
                            if (FormData[x].isGroupChangeHasValue === undefined) return false;
                            else {
                                restrictionFilters["isGroupChangeHasValue"] = true;
                                if (FormData[x].isGroupChangeHasValue === "byKeyword") {
                                    restrictionFilters["groupChange"] = FormData[x].groupChange;
                                }
                            }
                            fParams["restrictionFilters"] = restrictionFilters;
                        } else if (x === "addingCidTool") {
                            restrictionFilters["addingCidTool"] = FormData[x];
                            fParams["restrictionFilters"] = restrictionFilters;
                        } else if (x === "closeOnly") {
                            restrictionFilters["closeOnly"] = FormData[x];
                            fParams["restrictionFilters"] = restrictionFilters;
                        } else if (x === "readOnly") {
                            restrictionFilters["readOnly"] = FormData[x];
                            fParams["restrictionFilters"] = restrictionFilters;
                        } else if (x === "closeAccount") {
                            restrictionFilters["closeAccount"] = FormData[x];
                            fParams["restrictionFilters"] = restrictionFilters;
                        } else if (x === "kyc") {
                            restrictionFilters["kyc"] = FormData[x];
                            fParams["restrictionFilters"] = restrictionFilters;
                        } else if (x === "leverage") {
                            restrictionFilters["leverage"] = FormData[x];
                            fParams["restrictionFilters"] = restrictionFilters;
                        } else if (x === "warningLetter") {
                            restrictionFilters["warningLetter"] = FormData[x];
                            fParams["restrictionFilters"] = restrictionFilters;
                        } else if (x === "withdrawBan") {
                            restrictionFilters["withdrawBan"] = FormData[x];
                            fParams["restrictionFilters"] = restrictionFilters;
                        } else if (x === "creditPromotion") {
                            restrictionFilters["creditPromotion"] = FormData[x];
                            fParams["restrictionFilters"] = restrictionFilters;
                        } else if (x === "openAccount") {
                            restrictionFilters["openAccount"] = FormData[x];
                            fParams["restrictionFilters"] = restrictionFilters;
                        } else if (x === "swap") {
                            restrictionFilters["swap"] = FormData[x];
                            fParams["restrictionFilters"] = restrictionFilters;
                        } else if (x === "sBook") {
                            restrictionFilters["sBook"] = FormData[x];
                            fParams["restrictionFilters"] = restrictionFilters;
                        } else {
                            fParams[x] = FormData[x];
                        }
                        return x;
                    });

                if (
                    restrictionFilters?.hasOwnProperty("groupChange") &&
                    (restrictionFilters.groupChange === "" || restrictionFilters.groupChange === undefined)
                )
                    message.error("Please provide a keyword if you intend to execute a keyword-based search.");
                else {
                    setFilterParams(fParams);
                    setRunRefetchDataList(true);
                }
                break;
            default:
                break;
        }
    };

    const getReportDashboard = () => {
        setIsLoading(true);
        apiRequest(APIs.GET_TOXIC_CLIENT_CHECKLIST_REPORT_DASHBOARD, {
            ...(Object.keys(filterParams).length > 0 && { ...filterParams }),
        })
            .then((data: any) => {
                if (data && data.length > 0) {
                    setData(data);
                } else setData([]);
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) =>
                    ErrorMessageHandler("toxic client check report dashboard", SUCCESS_FAILED.FAILED_LOAD_DATA, err)
                )
            )
            .finally(() => setIsLoading(false));
    };

    const getConfig = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, {
            filterType: ["server", "brand", "user"],
        })
            .then((data: any) => {
                setCfData({
                    brands: data.brands,
                    users: data.users,
                    servers: data.servers,
                });
                setRunRefetchDataList(true);
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => console.log("Failed to get filter config list: ", err)))
            .finally(() => setIsFirstLoad(false));
    };

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            setRunRefetchDataList(false);
            getReportDashboard();
        }
        return () => {};
    }, [runRefetchDataList]);

    useEffect(() => {
        getConfig();
        return () => {};
    }, []);

    return (
        <div className="toxic-client-check-report-dashboard-tab">
            <div className="single-page with-background">
                <div className="active">
                    {isFirstLoad ? (
                        <div className="loading-container">
                            <LoadingComponent tip="Loading..." />
                        </div>
                    ) : (
                        <>
                            <ReportDashboardCustomGroupedFilter
                                callback={customFilterComponentCallback}
                                options={{}}
                                servers={cfData.servers}
                                users={cfData.users}
                                brands={cfData.brands}
                                filterFormInstance={filterForm}
                                filterInitialValue={{
                                    ...(process.env.REACT_APP_ENV === "dev"
                                        ? devFilterInitialValues
                                        : process.env.REACT_APP_ENV === "prod"
                                        ? prodFilterInitialValues
                                        : devFilterInitialValues),
                                }}
                            />
                            <FlexiDataTable
                                bordered
                                rowKeyProperty="labelId"
                                title={false}
                                columns={columns}
                                options={{
                                    summary: displaySummaryTotal,
                                    enableFilter: false,
                                    showHideColumns: false,
                                }}
                                dataSource={data ?? []}
                                callback={() => {}}
                                loading={isLoading}
                                pagination={{
                                    pageSize: 100,
                                    position: ["none"],
                                }}
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ReportDashboard;
