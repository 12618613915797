import React, { useState } from "react";
import { DownloadOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Modal, Row, Select, Typography, notification } from "antd";
import moment from "moment-timezone";
import { getToken } from "../../../services/localStorage";
import { APIs } from "../../../services/apis";
import { plainAxiosInstance } from "../../../services/axiosSetup";
import { useRCDataCenterDownloadHistory, useRCDataCenterMutate, useRCDataCenterServers } from "../../../hooks/useRCDataCenter";

const DownloadDataCenterHistoryButton = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleModalOpen = (open: boolean) => {
        setIsModalOpen(open);
    };
    return (
        <>
            <Button type="ghost" icon={<DownloadOutlined />} onClick={() => handleModalOpen(true)}>
                Download History
            </Button>
            {isModalOpen && <DownloadRejectOrderModal open={isModalOpen} handleModalOpen={handleModalOpen} />}
        </>
    );
};

interface IRCDataCenterDownloadHistoryRequest {
    type: "LOGIN" | "HEALTH" | "NIC_FLOW" | "PING_TIME";
    serverId: string;
    dcName: string;
    startDateTime: string;
    endDateTime: string;
}

const DownloadRejectOrderModal = ({ open, handleModalOpen }: { open: boolean; handleModalOpen: (open: boolean) => void }) => {
    const { RangePicker } = DatePicker;
    const [form] = Form.useForm();
    const { Item } = Form;
    const { Text } = Typography;
    const [dcOptions, setDcOptions] = useState<any[]>([]);

    const initFormValue = {
        type: null,
        range: [moment().subtract(1, "days"), moment()],
        serverId: null,
        dcName: null,
    };

    const typeOptions = [
        { label: "Health", value: "HEALTH" },
        { label: "Login", value: "LOGIN" },
        { label: "NIC Flow", value: "NIC_FLOW" },
        { label: "Ping Time", value: "PING_TIME" },
    ];

    const { rcDataCenterServers } = useRCDataCenterServers();
    // group by serverId
    const serverGroups = rcDataCenterServers?.reduce((acc: any, curr: any) => {
        if (!acc[curr.serverId]) {
            acc[curr.serverId] = [];
        }
        acc[curr.serverId].push(curr);
        return acc;
    }, {});
    // console.log(serverGroups);

    const handleSubmit = async () => {
        const values = form.getFieldsValue();
        const { type, range, serverId, dcName } = values;
        const [startDateTime, endDateTime] = range;
        plainAxiosInstance
            .post(
                `${APIs.RC_DATA_CENTER.POST_DATA_CENTER_MONITOR_DOWNLOAD_HISTORY}/${type}`,
                {
                    dcName: dcName || undefined,
                    serverId: serverId || undefined,
                    startDateTime: startDateTime?.format("YYYY-MM-DD HH:mm:ss"),
                    endDateTime: endDateTime?.format("YYYY-MM-DD HH:mm:ss"),
                },
                {
                    headers: {
                        Accept: "application/octet-stream, */*",
                    },
                    // responseType: "blob",
                }
            )
            .then(response => {
                // Check if the response content type is 'application/octet-stream'
                const contentType = response.headers["content-type"];
                if (
                    contentType === "application/octet-stream" ||
                    contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ) {
                    // Handle the file download response
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `data_center_history.csv`);
                    document.body.appendChild(link);
                    link.click();
                    // Clean up
                    window.URL.revokeObjectURL(url);
                    notification.success({
                        message: "Successfully Downloaded",
                        description: `Data Center History downloaded successfully`,
                    });
                    handleModalOpen(false);
                } else {
                    notification.error({
                        message: "Error",
                        description: `Received non-file response. Error: ${response}`,
                    });
                    console.log("Received non-file response:", response);
                }
            })
            .catch(err => {
                notification.error({
                    message: "Failed to download",
                    description: err?.response?.data?.message || `Failed to download data center history. Error: ${err}`,
                });
                console.log("download error", err);
            });
    };

    return (
        <Form form={form} layout="vertical" initialValues={initFormValue} requiredMark={true} onFinish={handleSubmit}>
            <Modal
                title="Download Data Center Monitor History"
                okText={"Download"}
                okButtonProps={{
                    icon: <DownloadOutlined />,
                }}
                open={open}
                onOk={() => form.submit()}
                onCancel={() => handleModalOpen(false)}
            >
                <Row gutter={[8, 8]}>
                    <Col xs={{ span: 24 }} md={{ span: 24 }}>
                        <Text>Report Type *</Text>
                        <Item
                            style={{ margin: 0, padding: 0 }}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            required
                            name="type"
                            rules={[{ required: true, message: "Please select report type" }]}
                        >
                            <Select
                                allowClear
                                style={{ width: "100%" }}
                                placeholder="Select Report Type"
                                // @ts-ignore
                                options={typeOptions}
                                showSearch
                                onChange={data => {
                                    form.setFieldsValue({ type: data });
                                }}
                            />
                        </Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }}>
                        <Text>Server</Text>
                        <Item
                            style={{ margin: 0, padding: 0 }}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            // required
                            name="serverId"
                            // rules={[{ required: true, message: "Please select server" }]}
                        >
                            <Select
                                allowClear
                                style={{ width: "100%" }}
                                placeholder="Select Server"
                                // @ts-ignore
                                options={
                                    // take the keys of the serverGroups object
                                    serverGroups &&
                                    Object.keys(serverGroups).map(serverId => {
                                        // get the first element of the serverGroups object
                                        return {
                                            label: serverId,
                                            value: serverId,
                                        };
                                    })
                                }
                                showSearch
                                onChange={data => {
                                    form.setFieldsValue({ serverId: data });
                                    form.setFieldsValue({ dcName: null });
                                    if (data && serverGroups[data]) {
                                        setDcOptions(serverGroups[data].map((each: any) => ({ label: each.dcName, value: each.dcName })));
                                    } else {
                                        setDcOptions([]);
                                    }
                                }}
                            />
                        </Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }}>
                        <Text>Data Center</Text>
                        <Item
                            style={{ margin: 0, padding: 0 }}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            // required
                            name="dcName"
                            // rules={[{ required: true, message: "Please select Data Center" }]}
                        >
                            <Select
                                allowClear
                                style={{ width: "100%" }}
                                placeholder="Select Data Center"
                                // @ts-ignore
                                options={
                                    // take the dcName of the selected serverId object
                                    dcOptions
                                }
                                showSearch
                                onChange={data => {
                                    form.setFieldsValue({ dcName: data });
                                }}
                            />
                        </Item>
                    </Col>
                    {/* <Col xs={{ span: 24 }} md={{ span: 24 }}>
                        <Text>Date Range *</Text>
                        <Item
                            style={{ margin: 0, padding: 0 }}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            required
                            name="ServerId"
                            rules={[{ required: true, message: "Please select server" }]}
                        >
                            <Select
                                allowClear
                                style={{ width: "100%" }}
                                placeholder="Select Server"
                                // @ts-ignore
                                options={serverOptions}
                                showSearch
                                onChange={data => {
                                    form.setFieldsValue({ serverId: data });
                                }}
                            />
                        </Item>
                    </Col> */}
                    <Col xs={{ span: 24 }} md={{ span: 24 }}>
                        <Text>Date Range *</Text>
                        <Item
                            style={{ margin: 0, padding: 0 }}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            required
                            name="range"
                            rules={[{ required: true, message: "Please select date range" }]}
                        >
                            <RangePicker
                                style={{ width: "100%" }}
                                showTime={{ format: "HH:mm:ss" }}
                                // disable selecting the day after today, and 20 days before today
                                disabledDate={current => {
                                    return current && current > moment().endOf("day"); //|| current < moment().subtract(20, "days").startOf("day"));
                                }}
                                onChange={data => {
                                    form.setFieldsValue({ range: data });
                                }}
                            />
                        </Item>
                    </Col>
                </Row>
            </Modal>
        </Form>
    );
};

export default DownloadDataCenterHistoryButton;
