import { FormInstance, Form } from "antd";
import { NamePath } from "antd/lib/form/interface";
import { FormComponent } from "..";
import { ComponentType } from "../../../constants";
import { APIs } from "../../../services/apis";
import { DefaultIfEmpty } from "../../../utils/object";
import TinyTag from "../../Common/Tag";
import ElasticSearchInput from "../../ElasticSearchInput";
import { useMemo } from "react";
import { apiRequest } from "../../../services/apiConfig";

export interface IBRebateAccountInputProps {
    form: FormInstance | undefined;
    formName: NamePath | string;
    componentId: NamePath;
    servers: any;
    label?: string | React.ReactNode;
}

export const IBRebateTagTextMarkup = (text: string, serverObj: any) => {
    return text
        .split("|")
        .map((x: string, idx: number) => (idx === 1 ? DefaultIfEmpty(serverObj, x, { text: "" }).text : x))
        .join(" | ");
};

const IBRebateAccountInput = (props: IBRebateAccountInputProps) => {
    const [currentFilterForm] = Form.useForm();
    const filterForm = useMemo(() => (props.form ? props.form : currentFilterForm), [props.form]);
    const accountList = Form.useWatch(props.formName, filterForm);

    const onSelect = (value: any) => {
        filterForm.setFieldValue(props.formName, [...filterForm.getFieldValue(props.formName), ...[value]]);
    };

    const getAccountList = (searchText: string) => {
        if (searchText && searchText.length > 0) {
            return new Promise((resolve) => {
                apiRequest(APIs.GET_ACCOUNT_SEARCH, { accountId: searchText }).then((res: any) => {
                    let returnResult: any[] = [];
                    if (res && res.length > 0) {
                        returnResult = [
                            {
                                label: <span>Accounts</span>,
                                options: res.map((x: any) => ({
                                    label: (
                                        <div className="els-account-container">
                                            <div>
                                                <span>ID: {x.accountId}</span>
                                                <span>Server: {x.server}</span>
                                            </div>
                                        </div>
                                    ),
                                    value: `${x.accountId}|${x.serverId}`,
                                })),
                            },
                        ];
                    }
                    resolve(returnResult);
                });
            });
        }
        return Promise.resolve([]);
    };

    return (
        <div>
            {props.label && <div className="label-container">{props.label}</div>}
            <FormComponent label="" name={props.componentId} extra={{ type: ComponentType.hidden, value: "" }} />
            <ElasticSearchInput
                inputType="number"
                placeholder={`Please insert keyword(s) and press [Enter]`}
                serverSide
                options={getAccountList}
                onSelect={onSelect}
                className="rebate-account-container"
                inputClassName="rebate-account-input"
            />
            <div className="accounts-container">
                {accountList &&
                    accountList.map((y: any, idx: number) => (
                        <TinyTag
                            key={`im-dfr-ib-a-${y}`}
                            text={IBRebateTagTextMarkup(y, props.servers)}
                            tagProps={{
                                closable: true,
                                onClose: () => {
                                    filterForm.setFieldValue(
                                        props.formName,
                                        (filterForm.getFieldValue(props.formName) as string[]).filter((x) => x !== y)
                                    );
                                },
                            }}
                        />
                    ))}
            </div>
        </div>
    );
};

export default IBRebateAccountInput;
