import { Switch } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CardBox from "../../../components/Common/CardBox";
import FlexiDataTable from "../../../components/FlexiDataTable";
import { SUCCESS_FAILED, TAGGING_STATUS, CALLBACK_KEY, ComponentType } from "../../../constants";
import { useGetTagProfilesQuery } from "../../../store/apis/tagProfiles";
import { ToOptionTypeList } from "../../../utils/array";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";
import { TagProfiles, FlexiDataTableOptionsProps, FlexiDataTableCallbackProps } from "../../../constants/type";
import { apiRequest, APIs } from "../../../services/apiConfig";
import AuthHelper, { AuthKeys } from "../../../helpers/authHelper";

function TagManagementPage() {
    let navigate = useNavigate();
    const { data, isLoading, refetch } = useGetTagProfilesQuery({ status: [0, 1] });
    const [manualLoading, setManualLoading] = useState<boolean>(false);

    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.ADMIN_TAG_EDIT);

    useEffect(() => {
        refetch();
        return () => {};
    }, []);

    const handleChange = (rowData: TagProfiles, checked: boolean) => {
        setManualLoading(true);
        const status = checked === true ? 1 : 0;

        apiRequest(APIs.UPDATE_TAG_PROFILE, {
            ...rowData,
            status,
            tagProfileId: rowData.id,
        })
            .then((data) => {
                ErrorMessageHandler("Existing tag profile's status", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
            })
            .catch((error) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("existing tag profile", SUCCESS_FAILED.FAILED_UPDATE_DATA, err))
            )
            .finally(() => {
                setManualLoading(false);
                refetch();
            });
    };

    const columns = [
        DTColProps.Large({
            title: "Name",
            dataIndex: "name",
            key: "name",
            fixed: "left",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        },
        DTColProps.Small({
            title: "Status",
            dataIndex: "status",
            key: "status",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: ToOptionTypeList(TAGGING_STATUS),
                },
            },
            // render: (text: number, record: any) => TAGGING_STATUS[text] ?? ""
            render: enableUpdate
                ? (status: number, rowData: any) => {
                      if (rowData.status !== 2) {
                          const switchValue = status === 1 ? true : false;

                          return (
                              <Switch
                                  onChange={(checked) => {
                                      handleChange(rowData, checked);
                                  }}
                                  checkedChildren="Active"
                                  unCheckedChildren="Inactive"
                                  defaultChecked={switchValue}
                              />
                          );
                      } else return "Deleted";
                  }
                : (text: number, record: any) => TAGGING_STATUS[text] ?? "",
        }),
    ];

    const options: FlexiDataTableOptionsProps = {
        add: enableUpdate,
        edit: enableUpdate,
        delete: enableUpdate,
        rowExtra: (record: any) => [{ text: "View Logs", value: "view_logs", link: `/siteadmin/tag/view/${btoa(`${record.id}|${record.name}`)}` }],
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, TagProfileData) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                navigate("/siteadmin/tag/create", { state: { data: null, action: "add" } });
                break;
            case CALLBACK_KEY.DO_EDIT:
                navigate("/siteadmin/tag/edit", { state: { data: TagProfileData, action: "edit" } });
                break;
            case CALLBACK_KEY.DO_DELETE:
                setManualLoading(true);
                apiRequest(APIs.DELETE_TAG_PROFILE, {
                    tagProfileId: TagProfileData.id,
                })
                    .then((data) => ErrorMessageHandler(TagProfileData.name, SUCCESS_FAILED.SUCCESS_DELETE_DATA))
                    .catch((error) =>
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("tag profile", SUCCESS_FAILED.FAILED_DELETE_DATA, err))
                    )
                    .finally(() => {
                        setManualLoading(false);
                        refetch();
                    });
                break;
            case CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK:
                let viewState = `${TagProfileData.data.id}|${TagProfileData.data.name}`;
                navigate(`/siteadmin/tag/view/${btoa(viewState)}`, { state: { data: TagProfileData.data, action: "view" } });
                break;
            default:
                break;
        }
    };

    return (
        <div>
            <CardBox title={"Tag Management"}>
                <FlexiDataTable
                    rowKeyProperty="id"
                    title=""
                    columns={columns}
                    options={options}
                    dataSource={data?.data || []}
                    callback={componentCallback}
                    loading={isLoading || manualLoading}
                />
            </CardBox>
        </div>
    );
}

export default TagManagementPage;
