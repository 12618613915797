import { Col, Form, FormInstance, Row } from "antd";
import { ExtraProps, FormComponent } from "../../../components/FormComponent";
import { ComponentType } from "../../../constants";
import { REQUIRED_FIELD } from "../../../constants/errorMessage";
import { useEffect } from "react";
import { ToObjectWithKey, ToOptionTypeList3 } from "../../../utils/array";
import { ThresholdObjectProps } from "../../../constants/type";
import { getAppSettings } from "../../../services/localStorage";

interface ThresholdEditProps {
    form: FormInstance;
    data: any;
    isAdministrator: boolean;
}

const ThresholdEdit = (props: ThresholdEditProps) => {
    const getInputProps = (type: string = ComponentType.text) => {
        return {
            type: type,
            value: "",
            rules: [{ required: true, message: REQUIRED_FIELD }],
        };
    };

    const getTemplates = (thresholdText: string) => {
        let tmArr: React.ReactNode[] = [];
        try {
            let arr: ThresholdObjectProps[] = JSON.parse(thresholdText);
            if (arr.length > 0) {
                let appSetting = getAppSettings();
                tmArr = arr
                    .filter((x: ThresholdObjectProps) => (x.editable === undefined ? true : x.editable))
                    .filter((x: any) => x.appSettingValueProps === undefined)
                    .map((x: ThresholdObjectProps, idx: number) => {
                        if (x.appSettingProps) {
                            switch (x.appSettingProps) {
                                case "RELATIVE_TIME_RANGE":
                                    let valueItem: ThresholdObjectProps =
                                        arr[
                                        arr.findIndex(
                                            (y: ThresholdObjectProps) => y.appSettingValueProps && y.appSettingValueProps === x.appSettingProps
                                        )
                                        ];
                                    return (
                                        <Row key={`mp-tsld-ff-${idx}`}>
                                            <Col span={14} style={{ textAlign: "right" }} className="ant-form-item-label">
                                                <label className="ant-form-item-required">{x.label}</label>
                                            </Col>
                                            <Col span={7}>
                                                <FormComponent
                                                    label={""}
                                                    name={x.name}
                                                    extra={
                                                        {
                                                            ...getInputProps(x.fieldType),
                                                            value: ToOptionTypeList3(appSetting[x.appSettingProps]),
                                                            itemProps: {
                                                                labelCol: 0,
                                                                wrapperCol: 24,
                                                            },
                                                        } as ExtraProps
                                                    }
                                                />
                                            </Col>
                                            <Col span={3}>
                                                <FormComponent
                                                    label={""}
                                                    name={valueItem.name}
                                                    extra={
                                                        {
                                                            ...getInputProps(valueItem.fieldType),
                                                            itemProps: {
                                                                labelCol: 0,
                                                                wrapperCol: 24,
                                                            },
                                                        } as ExtraProps
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    );
                                case "SYMBOL_TYPE":
                                    return (
                                        <Row key={`mp-tsld-ff-${idx}`}>
                                            <Col span={24}>
                                                <FormComponent
                                                    label={x.label}
                                                    name={x.name}
                                                    extra={{
                                                        ...getInputProps(x.fieldType),
                                                        value: appSetting.SYMBOL_TYPE,
                                                        inputProps: {
                                                            mode: "multiple",
                                                        },
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    );
                            }
                        }
                        return (
                            <Row key={`mp-tsld-ff-${idx}`}>
                                <Col span={24}>
                                    <FormComponent label={x.label} name={x.name} extra={getInputProps(x.fieldType)} />
                                </Col>
                            </Row>
                        );
                    });
            }
        } catch (error) { }
        return <>{tmArr}</>;
    };

    useEffect(() => {
        props.form.setFieldsValue(props.isAdministrator ? props.data : ToObjectWithKey(JSON.parse(props.data.threshold), "name", "value"));
        return () => { };
    }, []);

    return (
        <>
            <Form
                labelCol={{ span: props.isAdministrator ? 6 : 14 }}
                wrapperCol={{ span: props.isAdministrator ? 20 : 10 }}
                form={props.form}
                layout="horizontal"
                initialValues={{}}
            >
                {props.isAdministrator ? (
                    <Row>
                        <Col span={24}>
                            <FormComponent
                                label="Threshold"
                                name="threshold"
                                extra={{
                                    type: ComponentType.textarea,
                                    value: "",
                                    rules: [
                                        {
                                            validator: async (_, value) => {
                                                if (value === undefined || value.length === 0) {
                                                    return Promise.reject(new Error(REQUIRED_FIELD));
                                                }
                                                try {
                                                    let tmp: any = JSON.parse(value);
                                                    return Promise.resolve();
                                                } catch (error) {
                                                    return Promise.reject(new Error("Invalid JSON format."));
                                                }
                                            },
                                        },
                                    ],
                                    inputProps: {
                                        rows: 10,
                                    },
                                }}
                            />
                        </Col>
                    </Row>
                ) : (
                    <> {getTemplates(props.data.threshold as string)}</>
                )}
            </Form>
        </>
    );
};

export default ThresholdEdit;
