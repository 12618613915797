import { Row, Col, Form } from "antd";
import { useEffect, useState } from "react";
import { FormComponent } from "../../../components/FormComponent";
import { ComponentType, SUCCESS_FAILED } from "../../../constants";
import { apiRequest } from "../../../services/apiConfig";
import { APIs } from "../../../services/apis";
import { GetObjectSumByKey, ToObjectWithKey } from "../../../utils/array";
import { ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";
import { findObjectKeys } from "../../../utils/object";
import HoldingTimeDistributionSummary from "./Components/AdvanceAnalysisTab/HoldingTimeDistributionSummary";
import PNLByTradeDuration from "./Components/AdvanceAnalysisTab/PNLByTradeDuration";
import SourceDistributionSummary from "./Components/AdvanceAnalysisTab/SourceDistributionSummary";
import { ProfileInfoProps } from "./viewProfile";
import OpenTradeHourlyDistribution from "./Components/AdvanceAnalysisTab/OpenTradeHourlyDistrubution";
import PnLByTimeOfDayTradeOpened from "./Components/AdvanceAnalysisTab/PnLByTimeOfDayTradeOpened";

export interface AdvanceAnalysisPageProps extends ProfileInfoProps {
    symbols: string[];
}

export interface AdvanceAnalysisFilterParams {
    accountId: string | number;
    serverId: string | number;
    symbols?: string[];
    dateFrom?: string;
    dateTo?: string;
}

const AdvanceAnalysisPage = (props: AdvanceAnalysisPageProps) => {
    const [filterForm] = Form.useForm();
    const [sourceDistributionIsLoading, setSourceDistributionIsLoading] = useState<boolean>(true);
    const [holdingTimeIsLoading, setHoldingTimeIsLoading] = useState<boolean>(true);
    const [pnlByDurationIsLoading, setPnlByDurationIsLoading] = useState<boolean>(true);
    const [pnlByTimeOfDayTradeOpenedIsLoading, setPnlByTimeOfDayTradeOpenedIsLoading] = useState<boolean>(true);
    const [openTradeHourlyLoading, setOpenTradeHourlyLoading] = useState<boolean>(true);
    const [filterParams, setFilterParams] = useState<AdvanceAnalysisFilterParams>({
        accountId: props.accountId,
        serverId: props.serverId,
        symbols: [],
    });
    const [symbols] = useState<string[]>(["All", ...props.symbols]);
    const [sourceDistributionData, setSourceDistributionData] = useState<any[]>([]);
    const [holdingTimeData, setHoldingTimeData] = useState<any[]>([]);
    const [pnlByDuration, setPnlByDuration] = useState<any[]>([]);
    const [pnlByTimeOfDayTradeOpened, setPnlByTimeOfDayTradeOpened] = useState<any[]>([]);
    const [openTradeHourlyData, setOpenTradeHourlyData] = useState<any[]>([]);

    const onFilterValueChanged = (values: any) => {
        let filterData = {
            ...filterParams,
        };

        if (findObjectKeys(values, ["symbol"])) {
            filterData["symbols"] = values["symbol"] === "All" ? [] : [values["symbol"]];
        } else if (findObjectKeys(values, ["period"])) {
            try {
                if (values["period"] !== null) {
                    filterData["dateFrom"] = values["period"][0].toISOString();
                    filterData["dateTo"] = values["period"][1].toISOString();
                } else {
                    delete filterData["dateFrom"];
                    delete filterData["dateTo"];
                }
            } catch (error) {
                delete filterData["dateFrom"];
                delete filterData["dateTo"];
            }

            if (
                `${filterParams["dateFrom"] || ""}|${filterParams["dateTo"] || ""}` !==
                `${filterData["dateFrom"] || ""}|${filterData["dateTo"] || ""}`
            ) {
                getChartData(true, filterData);
            }
        }

        setFilterParams(filterData);
    };

    const getFilteredSourceDistributionData = (data: any[], selectedSymbols: string) => {
        return selectedSymbols === "All"
            ? GetObjectSumByKey(data, "key", "value", true)
            : GetObjectSumByKey(
                  data.filter((x: any) => x.symbol === selectedSymbols),
                  "key",
                  "value",
                  true
              );
    };

    const getFilteredHoldingTimeData = (data: any[], selectedSymbols: string) => {
        let tmp =
            selectedSymbols === "All"
                ? GetObjectSumByKey(data, "key", "value", false)
                : GetObjectSumByKey(
                      data.filter((x: any) => x.symbol === selectedSymbols),
                      "key",
                      "value",
                      false
                  );

        let final: any = ToObjectWithKey(data, "key");
        final = Object.keys(tmp).map((x) => ({ key: x, index: final[x].index, value: tmp[x] || 0 }));
        final.sort((a: any, b: any) => a.index - b.index);
        return final;
    };

    const getPNLByDurationData = (data: any[], selectedSymbols: string) => {
        let tmp =
            selectedSymbols === "All"
                ? GetObjectSumByKey(data, "key", "value", false)
                : GetObjectSumByKey(
                      data.filter((x: any) => x.symbol === selectedSymbols),
                      "key",
                      "value",
                      false
                  );

        let final: any = ToObjectWithKey(data, "key");
        final = Object.keys(tmp).map((x) => ({ key: x, index: final[x].index, value: tmp[x] || 0 }));
        final.sort((a: any, b: any) => a.index - b.index);
        return final;
    };

    const getPNLByTimeOfDayTradeData = (data: any[], selectedSymbols: string) => {
        let tmp =
            selectedSymbols === "All"
                ? GetObjectSumByKey(data, "key", "value", false)
                : GetObjectSumByKey(
                      data.filter((x: any) => x.symbol === selectedSymbols),
                      "key",
                      "value",
                      false
                  );

        let final: any = ToObjectWithKey(data, "key");
        final = Object.keys(tmp).map((x) => ({ key: x, index: final[x].index, value: tmp[x] || 0 }));
        final.sort((a: any, b: any) => a.index - b.index);
        return final;
    };

    const getFilteredOpenTradeHourlyDistributionData = (data: any[], selectedSymbols: string) => {
        let tmp =
            selectedSymbols === "All"
                ? GetObjectSumByKey(data, "key", "value", false)
                : GetObjectSumByKey(
                      data.filter((x: any) => x.symbol === selectedSymbols),
                      "key",
                      "value",
                      false
                  );

        let final: any = ToObjectWithKey(data, "key");
        final = Object.keys(tmp).map((x) => ({ key: x, index: final[x].index, value: tmp[x] || 0 }));
        final.sort((a: any, b: any) => a.index - b.index);
        return final;
    };

    const getOpenTradeHourlyDistributionData = (forceReload: boolean = false, params: AdvanceAnalysisFilterParams) => {
        if (forceReload) {
            setOpenTradeHourlyLoading(true);
            apiRequest(APIs.GET_SOURCE_DISTRIBUTION_SUMMARY, {
                ...params,
                symbols: [],
                analysisType: 4,
            })
                .then((data: any) => {
                    if (data.stats && data.stats.length > 0) {
                        setOpenTradeHourlyData(data.stats);
                    } else {
                        setOpenTradeHourlyData([]);
                    }
                })
                .catch((error) => {
                    ErrorCatchValidator(error, (err: any) =>
                        ErrorMessageHandler("open trade hourly distribution data", SUCCESS_FAILED.FAILED_LOAD_DATA, err)
                    );
                })
                .finally(() => setOpenTradeHourlyLoading(false));
        }
    };

    const getSourceDistributionSummaryData = (forceReload: boolean = false, params: AdvanceAnalysisFilterParams) => {
        if (forceReload) {
            setSourceDistributionIsLoading(true);
            apiRequest(APIs.GET_SOURCE_DISTRIBUTION_SUMMARY, {
                ...params,
                symbols: [],
                analysisType: 0,
            })
                .then((data: any) => {
                    if (data.stats && data.stats.length > 0) {
                        setSourceDistributionData(data.stats);
                    } else {
                        setSourceDistributionData([]);
                    }
                })
                .catch((error) => {
                    ErrorCatchValidator(error, (err: any) =>
                        ErrorMessageHandler("source distribution summary data", SUCCESS_FAILED.FAILED_LOAD_DATA, err)
                    );
                })
                .finally(() => setSourceDistributionIsLoading(false));
        }
    };

    const getHoldingTimeSummaryData = (forceReload: boolean = false, params: AdvanceAnalysisFilterParams) => {
        if (forceReload) {
            setHoldingTimeIsLoading(true);
            apiRequest(APIs.GET_SOURCE_DISTRIBUTION_SUMMARY, {
                ...params,
                symbols: [],
                analysisType: 1,
            })
                .then((data: any) => {
                    if (data.stats && data.stats.length > 0) {
                        setHoldingTimeData(data.stats);
                    } else {
                        setHoldingTimeData([]);
                    }
                })
                .catch((error) => {
                    ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("holding time summary data", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                })
                .finally(() => setHoldingTimeIsLoading(false));
        }
    };

    const getPNLByDurationSummaryData = (forceReload: boolean = false, params: AdvanceAnalysisFilterParams) => {
        if (forceReload) {
            setPnlByDurationIsLoading(true);
            apiRequest(APIs.GET_SOURCE_DISTRIBUTION_SUMMARY, {
                ...params,
                symbols: [],
                analysisType: 2,
            })
                .then((data: any) => {
                    if (data.stats && data.stats.length > 0) {
                        setPnlByDuration(data.stats);
                    } else {
                        setPnlByDuration([]);
                    }
                })
                .catch((error) => {
                    ErrorCatchValidator(error, (err: any) =>
                        ErrorMessageHandler("p&l by trade duration summary data", SUCCESS_FAILED.FAILED_LOAD_DATA, err)
                    );
                })
                .finally(() => setPnlByDurationIsLoading(false));
        }
    };

    const getPNLByTimeOfDayTradeDataSummaryData = (forceReload: boolean = false, params: AdvanceAnalysisFilterParams) => {
        if (forceReload) {
            setPnlByTimeOfDayTradeOpenedIsLoading(true);
            apiRequest(APIs.GET_SOURCE_DISTRIBUTION_SUMMARY, {
                ...params,
                symbols: [],
                analysisType: 5,
            })
                .then((data: any) => {
                    if (data.stats && data.stats.length > 0) {
                        setPnlByTimeOfDayTradeOpened(data.stats);
                    } else {
                        setPnlByTimeOfDayTradeOpened([]);
                    }
                })
                .catch((error) => {
                    ErrorCatchValidator(error, (err: any) =>
                        ErrorMessageHandler("p&l by trade duration summary data", SUCCESS_FAILED.FAILED_LOAD_DATA, err)
                    );
                })
                .finally(() => setPnlByTimeOfDayTradeOpenedIsLoading(false));
        }
    };

    const getChartData = (forceReload: boolean, params: AdvanceAnalysisFilterParams) => {
        getOpenTradeHourlyDistributionData(forceReload, params);
        getSourceDistributionSummaryData(forceReload, params);
        getHoldingTimeSummaryData(forceReload, params);
        getPNLByDurationSummaryData(forceReload, params);
        getPNLByTimeOfDayTradeDataSummaryData(forceReload, params);
    };

    useEffect(() => {
        filterForm.setFieldsValue({ symbol: "All" });
        getChartData(true, filterParams);
        return () => {};
    }, []);

    const paddingTopStyle: any = { paddingTop: "2.828vh" };

    return (
        <>
            <div className="main-panel" style={{ width: "100%" }}>
                <Row>
                    <Col span={12}>
                        <Form
                            labelCol={{ span: 5 }}
                            labelAlign={"left"}
                            wrapperCol={{ span: 19 }}
                            form={filterForm}
                            layout="horizontal"
                            onValuesChange={onFilterValueChanged}
                        >
                            <Row gutter={24} style={{ width: "100%" }}>
                                <Col span={12}>
                                    <FormComponent
                                        label="Symbol"
                                        name={"symbol"}
                                        extra={{
                                            type: ComponentType.dropdown,
                                            value: symbols.map((x) => ({ text: x, value: x })),
                                            inputProps: {
                                                allowClear: false,
                                            },
                                        }}
                                    />
                                </Col>
                                <Col span={12}>
                                    <FormComponent
                                        label="Period"
                                        name={"period"}
                                        extra={{
                                            type: ComponentType.daterange,
                                            value: [],
                                            dateFormat: "YYYY-MM-DD",
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col style={{ width: "49.5%" }}>
                        <div className="source-distribution-summary" style={paddingTopStyle}>
                            <SourceDistributionSummary
                                data={getFilteredSourceDistributionData(
                                    sourceDistributionData,
                                    filterParams.symbols && filterParams.symbols.length > 0 ? filterParams.symbols[0] : "All"
                                )}
                                isLoading={sourceDistributionIsLoading}
                                {...props}
                            />
                        </div>
                    </Col>
                    <Col style={{ width: "1%" }}></Col>
                    <Col style={{ width: "49.5%" }}>
                        <div className="holding-time-distribution-summary" style={paddingTopStyle}>
                            <HoldingTimeDistributionSummary
                                data={getFilteredHoldingTimeData(
                                    holdingTimeData,
                                    filterParams.symbols && filterParams.symbols.length > 0 ? filterParams.symbols[0] : "All"
                                )}
                                isLoading={holdingTimeIsLoading}
                                {...props}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col style={{ width: "49.5%" }}>
                        <div className="pnl-by-trade-summary" style={paddingTopStyle}>
                            <PNLByTradeDuration
                                data={getPNLByDurationData(
                                    pnlByDuration,
                                    filterParams.symbols && filterParams.symbols.length > 0 ? filterParams.symbols[0] : "All"
                                )}
                                isLoading={pnlByDurationIsLoading}
                                {...props}
                            />
                        </div>
                    </Col>
                    <Col style={{ width: "1%" }}></Col>
                    <Col style={{ width: "49.5%" }}>
                        <div className="open-trade-hourly-distribution" style={paddingTopStyle}>
                            <OpenTradeHourlyDistribution
                                data={getFilteredOpenTradeHourlyDistributionData(
                                    openTradeHourlyData,
                                    filterParams.symbols && filterParams.symbols.length > 0 ? filterParams.symbols[0] : "All"
                                )}
                                isLoading={openTradeHourlyLoading}
                                {...props}
                            />
                        </div>
                    </Col>
                </Row>
                {/* <div className="source-distribution-summary" style={paddingTopStyle}>
                    <SourceDistributionSummary
                        data={getFilteredSourceDistributionData(
                            sourceDistributionData,
                            filterParams.symbols && filterParams.symbols.length > 0 ? filterParams.symbols[0] : "All"
                        )}
                        isLoading={sourceDistributionIsLoading}
                        {...props}
                    />
                </div>
                <div className="holding-time-distribution-summary" style={paddingTopStyle}>
                    <HoldingTimeDistributionSummary
                        data={getFilteredHoldingTimeData(
                            holdingTimeData,
                            filterParams.symbols && filterParams.symbols.length > 0 ? filterParams.symbols[0] : "All"
                        )}
                        isLoading={holdingTimeIsLoading}
                        {...props}
                    />
                </div>
                <div className="pnl-by-trade-summary" style={paddingTopStyle}>
                    <PNLByTradeDuration
                        data={getPNLByDurationData(
                            pnlByDuration,
                            filterParams.symbols && filterParams.symbols.length > 0 ? filterParams.symbols[0] : "All"
                        )}
                        isLoading={pnlByDurationIsLoading}
                        {...props}
                    />
                </div>
                <div className="open-trade-hourly-distribution" style={paddingTopStyle}>
                    <OpenTradeHourlyDistribution
                        data={getFilteredOpenTradeHourlyDistributionData(
                            openTradeHourlyData,
                            filterParams.symbols && filterParams.symbols.length > 0 ? filterParams.symbols[0] : "All"
                        )}
                        isLoading={openTradeHourlyLoading}
                        {...props}
                    />
                </div>*/}
                <div className="pnl-by-time-of-day-trade-opened" style={paddingTopStyle}>
                    <PnLByTimeOfDayTradeOpened
                        data={getPNLByTimeOfDayTradeData(
                            pnlByTimeOfDayTradeOpened,
                            filterParams.symbols && filterParams.symbols.length > 0 ? filterParams.symbols[0] : "All"
                        )}
                        isLoading={pnlByTimeOfDayTradeOpenedIsLoading}
                        {...props}
                    />
                </div>
            </div>
            {/* <div className="sub-panel" style={{ borderLeft: "none" }}></div> */}
        </>
    );
};

export default AdvanceAnalysisPage;
