import { FormComponent } from "@/components/FormComponent";
import { ComponentType } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { ToxicClientAlarmConfigsProps, ToxicClientLabelsList } from "@/constants/type";
import { Col, Form, FormInstance, List, Row } from "antd";
import { useEffect, useState } from "react";
import ToxicClientAlarmMetricPanel from "./ToxicClientAlarmMetricPanel";

export interface ToxicClientAlarmConfigsPageProps {
    alarmConfigsItems: ToxicClientAlarmConfigsProps;
    coData: any;
    alarmConfigForm: FormInstance;
    handleFormValuesChange: (changedValues: any, allValues: any) => void;
}

const ToxicClientAlarmConfigsPage = (props: ToxicClientAlarmConfigsPageProps) => {
    const [data, setData] = useState<any[]>([]);

    useEffect(() => {
        const updatedMetrics = props.coData.toxicclientchecklistalarmmetric.map((metric: any) => ({
            ...metric,
            tabKey: props.alarmConfigsItems.tabKey,
            alarmConfigDetails: props.alarmConfigsItems.alarmConfigDetails.filter(alarm => alarm.metricId === metric.metricId),
        }));

        // Set form values for labelGroupName, labelIds, isActive as before
        props.alarmConfigForm.setFieldsValue({
            [`labelGroupId_${props.alarmConfigsItems.tabKey}`]: props.alarmConfigsItems.labelGroupId,
            [`labelGroupName_${props.alarmConfigsItems.tabKey}`]: props.alarmConfigsItems.labelGroupName,
            [`labelIds_${props.alarmConfigsItems.tabKey}`]: props.alarmConfigsItems.labelIds,
            [`isActive_${props.alarmConfigsItems.tabKey}`]: props.alarmConfigsItems.isActive,
            ...props.alarmConfigsItems,
        });

        setData(updatedMetrics);
    }, [props.alarmConfigsItems, props.alarmConfigForm]);

    return (
        <div className="toxic-client-alarm-config-form">
            <Form form={props.alarmConfigForm} layout="horizontal" labelCol={{ span: 6 }} labelAlign="left" initialValues={props.alarmConfigsItems} onValuesChange={props.handleFormValuesChange}>
                <Row>
                    <Col span={15} className="main-panel">
                        <FormComponent
                            label=""
                            name={`labelGroupId_${props.alarmConfigsItems.tabKey}`}
                            extra={{
                                type: ComponentType.hidden,
                                value: props.alarmConfigsItems.tabKey,
                            }}
                        />
                        <FormComponent
                            label="Label Group Name"
                            name={`labelGroupName_${props.alarmConfigsItems.tabKey}`}
                            extra={{
                                type: ComponentType.text,
                                value: "",
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                                inputProps: {
                                    onChange: () => {},
                                },
                            }}
                        />
                        <FormComponent
                            label="Label"
                            name={`labelIds_${props.alarmConfigsItems.tabKey}`}
                            extra={{
                                type: ComponentType.dropdown,
                                value: props.coData.labels.map((x: ToxicClientLabelsList) => ({
                                    value: x.labelId,
                                    text: x.labelName,
                                })),
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                                inputProps: {
                                    mode: "multiple",
                                },
                            }}
                        />
                        <FormComponent
                            label="Status"
                            name={`isActive_${props.alarmConfigsItems.tabKey}`}
                            extra={{ type: ComponentType.switch, value: ["Inactive", "Active"] }}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <List
                            grid={{ gutter: 16, column: 2 }}
                            dataSource={data}
                            renderItem={dataItem => (
                                <List.Item>
                                    <ToxicClientAlarmMetricPanel
                                        alarmConfigForm={props.alarmConfigForm}
                                        dataItem={dataItem}
                                        metricName={dataItem.metricName}
                                        brands={props.coData.brands}
                                    />
                                </List.Item>
                            )}
                        />
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default ToxicClientAlarmConfigsPage;
