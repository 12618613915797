import moment from "moment";
import { useState, useEffect } from "react";
import { ALARM_HISTORICAL_ITEM_CALLBACK_KEY, ALARM_HISTORICAL_ITEM_KEY, SUCCESS_FAILED } from "../../constants";
import { KeyValuePair, User, StatusListResponseProps, ClientProps, alarmHistoricalComponentCallbackProps } from "../../constants/type";
import { apiRequest } from "../../services/apiConfig";
import { APIs } from "../../services/apis";
import { DimensionLevelObjectConfig, ErrorCatchValidator, ErrorMessageHandler } from "../../utils/Common";
import { ToObjectWithKey, SortList } from "../../utils/array";
import AlarmHistoricalFilterPanel, { AlarmHistoricalFilterOption } from "./components/alarmHistorical/alarmHistoricalFilterPanel";
import WorldMapData from "../../assets/data/worldmap.json";
import { hasAnyKey } from "../../utils/object";
import AlarmHistoricalContentComponents from "./components/alarmHistorical/alarmHistoricalContentComponents";

export interface AlarmHistoricalPageProps {}

export interface AlarmHistoricalFilterParamsProps {
    dateFrom?: string;
    dateTo?: string;
    clientApps?: string[];
    moduleCodes?: string[];
    breachSeverities?: number[];
    assignees?: number[];
}

const AlarmHistoricalPage = (props: AlarmHistoricalPageProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [filterParams, setFilterParams] = useState<AlarmHistoricalFilterParamsProps>({
        dateFrom: moment().utc().toJSON(),
        dateTo: moment().utc().toJSON(),
    });
    const [configOpt, setConfigOpt] = useState<AlarmHistoricalFilterOption[]>([]);
    const [statusList, setStatusList] = useState<any>({});
    const [userOptions, setUserOptions] = useState<KeyValuePair[]>([]);
    const [mappingData, setMappingData] = useState<any>({
        dimension: {},
        country: {},
        metricName: {},
        symbol: {},
        symbol_asset_type_id: {},
        server_id: {},
        brand_id: {},
        group: {},
    });
    const [lpMappingData, setLpMappingData] = useState<any>({
        metricName: {},
        dimension: {},
    });

    const [refreshListState, setRefreshListState] = useState<number>(1);

    const componentCallback = (callbackKey: ALARM_HISTORICAL_ITEM_CALLBACK_KEY, values: alarmHistoricalComponentCallbackProps) => {
        if (ALARM_HISTORICAL_ITEM_CALLBACK_KEY.FILTER_CHANGED === callbackKey) {
            switch (values.type) {
                case ALARM_HISTORICAL_ITEM_KEY.DATE_ASSIGNEE_FILTER:
                    setFilterParams(prev => ({ ...prev, ...values.values }));
                    break;
                case ALARM_HISTORICAL_ITEM_KEY.MODULES_FILTER:
                    let finalObj: any = { clientApps: [], moduleCodes: [] };
                    if (hasAnyKey(values.values)) {
                        Object.keys(values.values).forEach((i: string) => {
                            if (values.values[i] === true) {
                                let tmp = i.split("|");
                                finalObj[tmp[0]].push(tmp[1]);
                            }
                        });
                    }
                    setFilterParams(prev => ({ ...prev, ...finalObj }));
                    break;
                default:
                    break;
            }
        } else if (ALARM_HISTORICAL_ITEM_CALLBACK_KEY.REFRESH_LIST === callbackKey) {
            setRefreshListState(prev => prev + 1);
        }
    };

    const getConfig = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, {
            filterType: ["mimmetric", "cleansymbol", "symbolassettype", "server", "brand", "user"],
        })
            .then(res => {
                setMappingData({
                    dimension: ToObjectWithKey(DimensionLevelObjectConfig("IntradayDimensionArr"), "value"),
                    metricName:
                        res["mimMetrics"] && res["mimMetrics"].length > 0
                            ? ToObjectWithKey(
                                  res.mimMetrics.map((x: any) => ({
                                      value: x["metricName"],
                                      text: x["metricDisplayName"],
                                      type: x["metricDataType"],
                                  })),
                                  "value"
                              )
                            : {},
                    country: ToObjectWithKey(
                        WorldMapData.features.map((x: any) => ({ text: x.properties.name, value: x.properties.iso_alpha_2_code })),
                        "value"
                    ),
                    symbol:
                        res["cleanSymbols"] && res["cleanSymbols"].length > 0
                            ? ToObjectWithKey(
                                  res.cleanSymbols.map((x: any) => ({ value: x["name"].toLowerCase(), text: x["name"] })),
                                  "value"
                              )
                            : {},
                    symbol_asset_type_id:
                        res["symbolAssetTypes"] && res["symbolAssetTypes"].length > 0
                            ? ToObjectWithKey(
                                  res.symbolAssetTypes.map((x: any) => ({ value: x["id"], text: x["name"] })),
                                  "value"
                              )
                            : {},
                    server_id:
                        res["servers"] && res["servers"].length > 0
                            ? ToObjectWithKey(
                                  res.servers.map((x: any) => ({ value: x["id"], text: x["server"] })),
                                  "value"
                              )
                            : {},
                    brand_id:
                        res["brands"] && res["brands"].length > 0
                            ? ToObjectWithKey(
                                  res.brands.map((x: any) => ({ value: x["id"], text: x["brand"] })),
                                  "value"
                              )
                            : {},
                });
            })
            .finally(() =>
                apiRequest(APIs.GET_USER_PROFILES, { isBinded: true })
                    .then((res: User[]) => {
                        if (res && res.length > 0) {
                            let allOptions: KeyValuePair[] = SortList(
                                res.filter((x: User) => x.id !== 0).map((x: User) => ({ text: x.firstName, value: x.id })),
                                "text"
                            );
                            setUserOptions(allOptions);
                        }
                    })
                    .finally(() =>
                        apiRequest(APIs.GET_ALARM_DASHBOARD_STATUS_LIST, {})
                            .then((res: StatusListResponseProps[]) => {
                                if (res && res.length > 0) {
                                    setStatusList(
                                        res.reduce((finalObj: any, i: StatusListResponseProps) => {
                                            finalObj[i.moduleCode] = i.status
                                                .filter(x => x.toRenderUIButton)
                                                .map(x => ({
                                                    text: x.statusDescription,
                                                    value: x.statusCode,
                                                    isConsiderCaseClosed: x.isConsiderCaseClosed,
                                                }));
                                            return finalObj;
                                        }, {})
                                    );
                                }
                            })
                            .finally(() =>
                                apiRequest(APIs.GET_ALARM_DASHBOARD_CONFIG, { isModulesEnabled: true })
                                    .then(res => {
                                        if (res && res.length > 0) {
                                            setConfigOpt([
                                                {
                                                    key: "clientApps",
                                                    text: "Application",
                                                    options: res.map((x: any) => ({ text: x.clientAppName, value: x.clientApp })),
                                                },
                                                {
                                                    key: "moduleCodes",
                                                    text: "Modules",
                                                    options: res.reduce((finalArr: KeyValuePair[], x: ClientProps) => {
                                                        if (x.clientAppModules.length > 0) {
                                                            x.clientAppModules.forEach(y => {
                                                                finalArr.push({ text: `(${x.clientApp}) ${y.moduleName}`, value: y.moduleCode });
                                                            });
                                                        }
                                                        return finalArr;
                                                    }, []),
                                                },
                                            ]);
                                            // Default values
                                            let currentUtcDate = moment().utc();
                                            setFilterParams(prev => ({
                                                ...prev,
                                                ...{
                                                    dateFrom: `${currentUtcDate.format("YYYY-MM-DD")}T00:00:00`,
                                                    dateTo: `${currentUtcDate.add(1, "day").format("YYYY-MM-DD")}T00:00:00`,
                                                    clientApps: res.map((x: any) => x.clientApp),
                                                    moduleCodes: res.reduce((finalArr: string[], x: ClientProps) => {
                                                        finalArr = [...finalArr, ...x.clientAppModules.map(x => x.moduleCode)];
                                                        return finalArr;
                                                    }, []),
                                                    assignees: [],
                                                },
                                            }));
                                        }
                                    })
                                    .catch(error =>
                                        ErrorCatchValidator(error, (err: any) =>
                                            ErrorMessageHandler("historical alarm summary", SUCCESS_FAILED.FAILED_LOAD_DATA, err)
                                        )
                                    )
                                    .finally(() =>
                                        apiRequest(APIs.GET_LP_ALARM_CONFIG, {})
                                            .then((res: any) => {
                                                setLpMappingData({
                                                    dimension: ToObjectWithKey(DimensionLevelObjectConfig("LPDimensionArr"), "value"),
                                                    metricName:
                                                        res.length > 0
                                                            ? ToObjectWithKey(
                                                                  res.reduce((arr: KeyValuePair[], i: any) => {
                                                                      arr = arr.concat(
                                                                          i.dataSources.map((x: any) => ({
                                                                              text: x.propertyDisplayName,
                                                                              value: x.propertyName.toLowerCase(),
                                                                          }))
                                                                      );
                                                                      return arr;
                                                                  }, []),
                                                                  "value"
                                                              )
                                                            : {},
                                                });
                                            })
                                            .catch(error => ErrorCatchValidator(error, (err: any) => console.log(err)))
                                            .finally(() => setIsLoading(false))
                                    )
                            )
                    )
            );
    };

    useEffect(() => {
        getConfig();
    }, []);

    return (
        <div className="alarm-historical-main-container">
            <div className="top-header">
                <span className="title-label">Historical Alarm Summary</span>
            </div>
            <div className="card-container shadow-light">
                <AlarmHistoricalFilterPanel filterOptions={configOpt} userOptions={userOptions} isLoading={isLoading} callback={componentCallback} />
                <AlarmHistoricalContentComponents
                    filterParams={filterParams}
                    isLoading={isLoading}
                    mappingData={mappingData}
                    statusObject={statusList}
                    userOptions={userOptions}
                    refreshState={refreshListState}
                    lpMappingData={lpMappingData}
                />
            </div>
        </div>
    );
};

export default AlarmHistoricalPage;
