import { Tag } from "antd";

export interface TinyTagProps {
    text: React.ReactNode;
    className?: string;
    tagProps?: any;
}

const TinyTag = (props: TinyTagProps) => {
    return (
        <Tag
            style={{ padding: "0 0.4vw 0 0.4vw", borderRadius: "5px", marginRight: "4px" }}
            className={props.className ? props.className : ""}
            {...(props.tagProps && props.tagProps)}
        >
            {props.text}
        </Tag>
    );
};

export default TinyTag;
