import { createApi } from "@reduxjs/toolkit/query/react";
import { APIs, defaultBaseQueryProps, ResponseProps } from "../../services/apiConfig";
import { ReportJobs } from "../../constants/type";
import { axiosBaseQuery } from "../axiosBaseQuery";

export interface reportJobsRequest {
    reportId?: number | undefined;
}

export interface reportJobsResponse extends ResponseProps<ReportJobs[]> {}

export const reportJobsApiSlice = createApi({
    reducerPath: "api_5",
    baseQuery: axiosBaseQuery(),
    keepUnusedDataFor: 5, //global caching duration (seconds)
    endpoints: (builder) => ({
        getReportJobs: builder.query<reportJobsResponse, reportJobsRequest | undefined>({
            keepUnusedDataFor: 0, //disable for caching for particular api request
            query: (arg) => ({ ...defaultBaseQueryProps, url: APIs.GET_REPORT_JOBS_BY_ID, method: "POST", params: arg }),
            transformResponse: (returnValue: reportJobsResponse, meta) => {
                return returnValue;
            },
        }),
    }),
});

export const { useGetReportJobsQuery } = reportJobsApiSlice;
