import { useQuery } from "@tanstack/react-query";
import { APIs } from "../services/apis";
import { plainAxiosInstance } from "../services/axiosSetup";

/*
sample response from the API
{
    "brand": "LP",
    "group": "EURTRY",
    "stopTime": 3618,
    "showTime": "01:00:18",
    "alarmCount": 1,
    "alarmStatus": 0,
    "alertStatus": 0,
    "servers": "INFTick",
    "detail": [
        {
            "server": "INFTick",
            "symbol": "EURTRY.cf",
            "bid": 35.00139,
            "ask": 35.31125,
            "startDate": "2024-06-07T00:14:31.000+00:00",
            "startDateStr": "2024-06-07 03:14:31",
            "endDate": "2024-06-07T01:14:49.560+00:00",
            "endDateStr": "2024-06-07 04:14:49",
            "stopTime": 3618,
            "isDel": 0,
            "brand": "LP",
            "serverId": 0,
            "symbolId": 0,
            "cleanSymbol": "EURTRY",
            "rowNum": null,
            "status": null,
            "nowDate": "2024-06-07T01:14:49.560+00:00",
            "nowDateStr": "2024-06-07 04:14:49",
            "showStopTime": "01:00:18",
            "typeId": null,
            "typeName": "Forex",
            "alarmStatus": 0,
            "alertStatus": 0
        }
    ]
}
*/
export interface IRCPriceAlarm {
    brand: string;
    group: string;
    stopTime: number;
    showTime: string;
    alarmCount: number;
    alarmStatus: number;
    alertStatus: number;
    servers: string;
    detail: {
        server: string | null;
        symbol: string | null;
        bid: number | null;
        ask: number | null;
        startDate: string | null;
        startDateStr: string | null;
        endDate: string | null;
        endDateStr: string | null;
        stopTime: number | null;
        isDel: number | null;
        brand: string | null;
        serverId: number | null;
        symbolId: number | null;
        cleanSymbol: string | null;
        rowNum: number | null;
        status: number | null;
        nowDate: string | null;
        nowDateStr: string | null;
        showStopTime: string | null;
        typeId: number | null;
        typeName: string | null;
        alarmStatus: number | null;
        alertStatus: number | null;
    }[];
}

interface IRCPriceAlarmResponse {
    data: IRCPriceAlarm[];
    serverTime: string;
}

const useRCPriceAlarm = () => {
    const { data, refetch, isLoading, error, isFetching, dataUpdatedAt } = useQuery({
        queryKey: ["rcPriceAlarm"],
        queryFn: () =>
            plainAxiosInstance.get(`${APIs.RC_PRICE_ALARM.GET_SUMMARY}`, {
                headers: {
                    Accept: "application/json",
                },
            }),
        staleTime: 15 * 1000, //(10 x 1000ms = 10 seconds)

        refetchInterval: 15 * 1000, //(60 x 1000ms = 60 seconds)
    });

    const rcPriceAlarm: IRCPriceAlarmResponse | null = data?.data?.status ? null : data?.data || null;

    const rcPriceAlarmSummary: IRCPriceAlarm[] = rcPriceAlarm?.data?.filter(data => data?.brand?.toLowerCase() !== "lp") || [];
    const rcPriceAlarmSummaryLP: IRCPriceAlarm[] = rcPriceAlarm?.data?.filter(data => data?.brand?.toLowerCase() === "lp") || [];

    // @ts-ignore
    const rcPriceAlarmError: string | null = data?.data?.message || error?.message || error || null;

    return {
        rcPriceAlarm,
        rcPriceAlarmSummary,
        rcPriceAlarmSummaryLP,
        rcPriceAlarmError,
        refetchRcPriceAlarm: refetch,
        isFetching,
        isLoading,
        dataUpdatedAt,
    };
};

/* sample response 
{
    "ip": "NoShow",
    "machineName": "Risk_release_c_price02",
    "appName": "AU",
    "groupId": "PriceBroadCaster",
    "sendTime": "2023-04-17T00:46:55.000+00:00",
    "sendTimeStr": "2023/04/17 03:46:55",
    "status": 2,
    "message": "App not responding",
    "timeStamp": 1681703215000,
    "stopTime": 36026133,
    "showStopTime": "10007:15:33",
    "vendor": "RA@RiskCenter",
    "exclude": null
}
*/
export interface IRCPriceAlarmMiscStop {
    ip: string;
    machineName: string;
    appName: string;
    groupId: string;
    sendTime: string;
    sendTimeStr: string;
    status: number | null;
    message: string;
    timeStamp: number;
    stopTime: number;
    showStopTime: string;
    vendor: string;
    exclude: string | null;
}

interface IRCPriceAlarmMiscStopResponse {
    data: IRCPriceAlarmMiscStop[];
    serverTime: string;
}
export interface IRCPriceAlarmServerStop {
    // ip: string;
    // machineName: string;
    // appName: string;
    // groupId: string;
    // sendTime: string;
    // sendTimeStr: string;
    // status: number | null;
    // message: string;
    // timeStamp: number;
    // stopTime: number;
    // showStopTime: string;
    // vendor: string;
    // exclude: string | null;
    serverName: string;
    startDateStr: string;
    duration: string;
}

interface IRCPriceAlarmServerStopResponse {
    data: IRCPriceAlarmServerStop[];
    serverTime: string;
}

export const useRCPriceAlarmMiscStop = () => {
    const { data, refetch, isLoading, error, isFetching, dataUpdatedAt } = useQuery({
        queryKey: ["rcPriceAlarmMiscStop"],
        queryFn: () =>
            plainAxiosInstance.get(`${APIs.RC_PRICE_ALARM.GET_RA}`, {
                headers: {
                    Accept: "application/json",
                },
            }),
        staleTime: 15 * 1000, //(10 x 1000ms = 10 seconds)

        refetchInterval: 15 * 1000, //(60 x 1000ms = 60 seconds)
    });

    const rcPriceAlarmMiscStop: IRCPriceAlarmMiscStopResponse | null = data?.data?.status ? null : data?.data || null;
    // setBroadCastorData(_data.filter(e => e.groupId == 'PriceBroadCaster'));
    // setReceiverData(_data.filter(e => e.groupId == 'PriceCalculateTool'));

    const rcPriceAlarmMiscStopConnector: IRCPriceAlarmMiscStop[] =
        rcPriceAlarmMiscStop?.data?.filter(data => data.groupId.toLowerCase().includes("pricebroadcaster")) || [];
    const rcPriceAlarmMiscStopCalculateTool: IRCPriceAlarmMiscStop[] =
        rcPriceAlarmMiscStop?.data?.filter(data => data.groupId.toLowerCase().includes("pricecalculatetool")) || [];
    // @ts-ignore
    const rcPriceAlarmMiscStopError: string | null = data?.data?.message || error?.message || error || null;

    return {
        rcPriceAlarmMiscStop,
        rcPriceAlarmMiscStopConnector,
        rcPriceAlarmMiscStopCalculateTool,
        rcPriceAlarmMiscStopError,
        refetchRcPriceAlarmMiscStop: refetch,
        isFetching,
        isLoading,
        dataUpdatedAt,
    };
};

export const useRCPriceAlarmServerStop = () => {
    const { data, refetch, isLoading, error, isFetching, dataUpdatedAt } = useQuery({
        queryKey: ["rcPriceAlarmServerStop"],
        queryFn: () =>
            plainAxiosInstance.get(`${APIs.RC_PRICE_ALARM.GET_SERVER_STOP}`, {
                headers: {
                    Accept: "application/json",
                },
            }),

        staleTime: 15 * 1000, //(10 x 1000ms = 10 seconds)
        refetchInterval: 15 * 1000, //(60 x 1000ms = 60 seconds)
    });

    const rcPriceAlarmServerStop: IRCPriceAlarmServerStopResponse | null = data?.data?.status ? null : data?.data || null;

    // @ts-ignore
    const rcPriceAlarmServerStopError: string | null = data?.data?.message || error?.message || error || null;
    // const rcPriceAlarmServerStop: IRCPriceAlarmMiscStopResponse | null = data?.data?.status ? null : data?.data || null;

    return {
        rcPriceAlarmServerStopError,
        rcPriceAlarmServerStop,
        refetchRcPriceAlarmServerStop: refetch,
        isFetching,
        isLoading,
        dataUpdatedAt,
    };
};

export default useRCPriceAlarm;
