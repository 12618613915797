import { Segmented } from "antd";
import CardBox from "../../../../components/Common/CardBox";
import { useState } from "react";
import RejectRecord from "./components/rejectRecord";
import RecordHistory from "./components/recordHistory";
import { SegmentedValue } from "antd/lib/segmented";

export interface OrderRoutingRecordProps {}

enum TAB {
    ANY = 1,
    OPEN = 2,
    HISTORY = 3,
}

const TAB_LABEL: { [key: number]: string } = {
    1: "Order Routing Record - Any Reject",
    2: "Order Routing Record - Open Reject",
    3: "Record History",
};

const OrderRoutingRecord = (props: OrderRoutingRecordProps) => {
    const [currentTab, setCurrentTab] = useState<number>(TAB.ANY);

    return (
        <div className="order-routing-record-container">
            <CardBox title={"Order Routing Record"}>
                <div className="main-container">
                    <Segmented
                        defaultValue={TAB.ANY}
                        value={currentTab}
                        options={[
                            { label: TAB_LABEL[TAB.ANY], value: TAB.ANY },
                            { label: TAB_LABEL[TAB.OPEN], value: TAB.OPEN },
                            { label: TAB_LABEL[TAB.HISTORY], value: TAB.HISTORY },
                        ]}
                        onChange={(value: SegmentedValue) => setCurrentTab(value as number)}
                    />
                    {currentTab === TAB.ANY && <RejectRecord mode={TAB.ANY} />}
                    {currentTab === TAB.OPEN && <RejectRecord mode={TAB.OPEN} />}
                    {currentTab === TAB.HISTORY && <RecordHistory />}
                </div>
            </CardBox>
        </div>
    );
};

export default OrderRoutingRecord;
