import { DownloadOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Modal, Row, Select, Typography, notification } from "antd";
import React, { useState } from "react";

import moment from "moment-timezone";
import { getToken } from "../../../services/localStorage";
import axios from "axios";
import { APIs } from "../../../services/apis";

const DownloadServerPerformanceButton = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleModalOpen = (open: boolean) => {
        setIsModalOpen(open);
    };
    return (
        <>
            <Button type="ghost" icon={<DownloadOutlined />} onClick={() => handleModalOpen(true)}>
                Download
            </Button>
            {isModalOpen && <DownloadServerPerformanceModal open={isModalOpen} handleModalOpen={handleModalOpen} />}
        </>
    );
};

const DownloadServerPerformanceModal = ({ open, handleModalOpen }: { open: boolean; handleModalOpen: (open: boolean) => void }) => {
    const [form] = Form.useForm();
    const { Item } = Form;
    const { Text } = Typography;

    const initFormValue = {
        server: null,
        date: null,
    };

    const handleSubmit = async (values: any) => {
        const token = getToken();
        const targetDate = moment(values.date).format("YYYY-MM-DD");
        const server = values.server;
        axios
            .get(`${process.env.REACT_APP_API_URL}${APIs.RC_SERVER_PERFORMANCE.GET_DOWNLOAD_MONITOR}/${targetDate}`, {
                headers: {
                    Accept: "application/octet-stream, */*",
                    // "Content-type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                responseType: "blob",
            })
            .then(response => {
                // Check if the response content type is 'application/octet-stream'
                const contentType = response.headers["content-type"];
                if (
                    contentType === "application/octet-stream" ||
                    contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ) {
                    // Handle the file download response
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `server_performance_${targetDate}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                    // Clean up
                    window.URL.revokeObjectURL(url);
                    notification.success({
                        message: "Downloaded",
                        description: `Server Performance on ${targetDate} downloaded successfully`,
                    });
                    handleModalOpen(false);
                } else {
                    notification.error({
                        message: "Error",
                        description: `Received non-file response. Error: ${response}`,
                    });
                    console.log("Received non-file response:", response);
                }
            })
            .catch(err => {
                notification.error({
                    message: "Error",
                    description: `Download error: ${err}`,
                });
                console.log("download error", err);
            });
    };

    return (
        <Form
            form={form}
            layout="vertical"
            initialValues={initFormValue}
            /* onValuesChange={handleFormUpdate} */ requiredMark={true}
            onFinish={handleSubmit}
        >
            <Modal
                title="Download Server Performance Log"
                okText={"Download"}
                okButtonProps={{
                    icon: <DownloadOutlined />,
                }}
                open={open}
                onOk={() => form.submit()}
                onCancel={() => handleModalOpen(false)}
            >
                <Row gutter={[8, 8]}>
                    <Col span={16} xs={{ span: 24 }} md={{ span: 16 }}>
                        <Text>Date *</Text>
                        <Item
                            style={{ margin: 0, padding: 0 }}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            required
                            name="date"
                            rules={[{ required: true, message: "Please select date" }]}
                        >
                            <DatePicker
                                style={{ width: "100%" }}
                                format="YYYY-MM-DD"
                                onChange={data => {
                                    form.setFieldsValue({ date: data });
                                }}
                                // disable the date after today
                                disabledDate={current => {
                                    return current && current > moment().endOf("day");
                                }}
                            />
                        </Item>
                    </Col>
                </Row>
            </Modal>
        </Form>
    );
};

export default DownloadServerPerformanceButton;
