import { useQuery } from "@tanstack/react-query";
import { APIs } from "../services/apis";
import { plainAxiosInstance } from "../services/axiosSetup";

/*
sample response from the API
[
  {
    "bridgeId": "ATOM8",
    "bridgeName": "ATOM8",
    "bridgeType": 1,
    "host": "https://vantage-llp-live.onezero.com",
    "port": 44300,
    "login": "RestApi",
    "password": "string",
    "brokerVhost": "",
    "brokerLogin": "",
    "brokerPassword": "",
    "version": "1.19",
    "updateUser": 272,
    "updateTime": "2023-10-28T02:13:04"
  },
]
*/

const dummy = [
    {
        bridgeId: "ATOM8",
        bridgeName: "ATOM8",
        bridgeType: 1,
        host: "https://www.someurl.com",
        port: 44300,
        login: "RestApi",
        password: "string",
        brokerVhost: "",
        brokerLogin: "",
        brokerPassword: "",
        version: "1.19",
        updateUser: 272,
        updateTime: "2023-10-28T02:13:04",
    },
    {
        bridgeId: "AUdemoHub",
        bridgeName: "AUdemoHub",
        bridgeType: 1,
        host: "https://www.someurl.com",
        port: 44300,
        login: "RestApi",
        password: "string",
        brokerVhost: "",
        brokerLogin: "",
        brokerPassword: "",
        version: "1.19",
        updateUser: 222,
        updateTime: "2023-03-24T08:40:08",
    },
    {
        bridgeId: "AUS",
        bridgeName: "AU",
        bridgeType: 1,
        host: "https://www.someurl.com",
        port: 44300,
        login: "RestApi",
        password: "string",
        brokerVhost: "",
        brokerLogin: "",
        brokerPassword: "",
        version: "1.19",
        updateUser: 222,
        updateTime: "2022-05-26T04:26:11",
    },
    {
        bridgeId: "BYBIT",
        bridgeName: "BYBIT",
        bridgeType: 1,
        host: "https://www.someurl.com",
        port: 44300,
        login: "RestApi",
        password: "string",
        brokerVhost: "",
        brokerLogin: "",
        brokerPassword: "",
        version: "1.22",
        updateUser: 272,
        updateTime: "2024-07-23T13:27:21",
    },
    {
        bridgeId: "GMN",
        bridgeName: "IV",
        bridgeType: 1,
        host: "https://www.someurl.com",
        port: 44300,
        login: "RestApi",
        password: "string",
        brokerVhost: "",
        brokerLogin: "",
        brokerPassword: "",
        version: "1.19",
        updateUser: 222,
        updateTime: "2022-05-26T04:26:40",
    },
    {
        bridgeId: "PXM_ICB_UK",
        bridgeName: "PXM_ICB_UK",
        bridgeType: 2,
        host: "https://www.someurl.com",
        port: 5673,
        login: "login",
        password: "string",
        brokerVhost: "host",
        brokerLogin: "login",
        brokerPassword: "string",
        version: "1.0",
        updateUser: 1,
        updateTime: "2021-12-28T09:46:51",
    },
];

interface RCBridge {
    bridgeId: string;
    bridgeName: string;
    bridgeType: number;
    host: string;
    port: number;
    login: string;
    password: string;
    brokerVhost: string;
    brokerLogin: string;
    brokerPassword: string;
    version: string;
    updateUser: number;
    updateTime: string;
}

const useRCBridges = () => {
    const { data, refetch, isLoading, error, isFetching } = useQuery({
        queryKey: ["rcBridges"],
        queryFn: () =>
            plainAxiosInstance.get(`${APIs.RC_ROOT.GET_BRIDGES}`, {
                headers: {
                    Accept: "application/json",
                },
            }),
        staleTime: 60 * 1000 * 5, //(60 x 1000ms x 5 = 5 minutes)
    });

    const rcBridges: RCBridge[] | null = data?.data?.status ? null : data?.data || null;
    // const rcBridges: RCBridge[] | null = dummy;
    // @ts-ignore
    const rcBridgesError: string | null = data?.data?.message || error?.message || error || null;

    return {
        rcBridges,
        rcBridgesError,
        refetchRcBridges: refetch,
        isFetching,
        isLoading,
    };
};

export default useRCBridges;
