import { useCallback, useEffect, useState } from "react";
import { GroupSettingProps } from "./PriceAnalysisGroup";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { APIs } from "@/services/apis";
import { SortList } from "@/utils/array";
import { Empty } from "antd";
import LoadingComponent from "@/components/Loading";
import { ServersProps } from "..";

export interface ShowServerComponentsProps {
    data: GroupSettingProps;
}

const ShowServerComponents = (props: ShowServerComponentsProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<ServersProps[]>([]);

    const getServerDetails = useCallback(() => {
        plainAxiosInstance
            .get(`${APIs.RC_PRICE_SETTINGS.UPDATE_PRICE_ANALYSIS_SERVER_SETTING}/${props.data.groupId}`)
            .then((res: any) => {
                if (res.status === 200 && Array.isArray(res.data) && res.data.length > 0) {
                    setData(SortList(res.data, "serverDn"));
                }
            })
            .finally(() => setIsLoading(false));
    }, [props.data]);

    useEffect(() => {
        getServerDetails();
    }, [props.data]);

    return (
        <div className="price-analysis-servers-olli nice-scrollbar">
            {isLoading ? (
                <LoadingComponent />
            ) : data.length > 0 ? (
                <ul>
                    {data.map((x: ServersProps, index: number) => (
                        <li key={`srv-${props.data.groupId}-ulli-${index}`}>
                            {x.serverDn} {x.isBaseServer && <span>Base</span>}
                        </li>
                    ))}
                </ul>
            ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
        </div>
    );
};

export default ShowServerComponents;
