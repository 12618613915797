import React, { createContext } from "react";
import * as signalR from "@microsoft/signalr";
import { getToken } from "./services/localStorage";

let GlobalContext = createContext();

class GlobalProvider extends React.Component {
    constructor(props) {
        super(props);

        // this.state = {
        //     signalRConnected: false,
        //     isConnecting: false,
        // };

        // this.signalrConnection = null;
        // this.buildSignalR = this.buildSignalR.bind(this);
        // this.getActiveSignalR = this.getActiveSignalR.bind(this);
        // this.startSignalR = this.startSignalR.bind(this);
        // this.stopSignalR = this.stopSignalR.bind(this);
    }

    // buildSignalR() {
    //     let url = `/notification?access_token=${getToken()}`;
    //     if (process.env.REACT_APP_API_URL) url = process.env.REACT_APP_API_URL + `/notification?access_token=${getToken()}`;

    //     this.signalrConnection = new signalR.HubConnectionBuilder()
    //         .withUrl(url, {
    //             skipNegotiation: true,
    //             transport: signalR.HttpTransportType.WebSockets,
    //         })
    //         .configureLogging(signalR.LogLevel.None)
    //         .withAutomaticReconnect()
    //         .build();

    //     this.signalrConnection.onclose(() => {
    //         console.log("SignalR Disconnected.");
    //         this.setState({
    //             signalRConnected: false,
    //         });
    //     });

    //     this.signalrConnection.onreconnected(() => {
    //         console.log("SignalR Reconnected.");
    //         this.setState({
    //             isConnecting: false,
    //             signalRConnected: true,
    //         });
    //     });

    //     this.signalrConnection.onreconnecting(() => {
    //         console.log("SignalR Reconnecting.");
    //         this.setState({
    //             signalRConnected: false,
    //             isConnecting: true,
    //         });
    //     });
    // }

    // getActiveSignalR() {
    //     if (this.signalrConnection === null || getToken() === null) this.buildSignalR();

    //     if (this.signalrConnection.state === "Disconnected" || this.signalrConnection.state === undefined)
    //         return this.startSignalR().then(() => {
    //             return this.signalrConnection;
    //         });
    //     else return Promise.resolve(this.signalrConnection);
    // }

    // startSignalR() {
    //     this.setState({
    //         isConnecting: true,
    //     });

    //     try {
    //         return this.signalrConnection.start().then(() => {
    //             console.log("SignalR Connected.");
    //             this.setState({
    //                 signalRConnected: true,
    //                 isConnecting: false,
    //             });
    //             return true;
    //         });
    //     } catch (err) {
    //         console.log(err);
    //         setTimeout(this.startSignalR, 5000);
    //     }
    // }

    // stopSignalR() {
    //     this.setState({
    //         isConnecting: true,
    //     });

    //     try {
    //         return this.signalrConnection.stop().then(() => {
    //             console.log("SignalR Disconnected.");
    //             this.setState({
    //                 signalRConnected: false,
    //                 isConnecting: false,
    //             });
    //             return true;
    //         });
    //     } catch (err) {
    //         console.log(err);
    //         setTimeout(this.startSignalR, 5000);
    //     }
    // }

    render() {
        let value = {
            // getActiveSignalR: this.getActiveSignalR,
            // signalRConnected: this.state.signalRConnected,
            // isConnecting: this.state.isConnecting,
            // stopSignalR: this.stopSignalR,
        };
        return <GlobalContext.Provider value={value}>{this.props.children}</GlobalContext.Provider>;
    }
}

export { GlobalProvider, GlobalContext };
