import { Button, Col, Form, FormInstance, Row } from "antd";
import { ExtraProps, FormComponent } from "../../../components/FormComponent";
import { ComponentType } from "../../../constants";
import { MIN, REQUIRED_FIELD } from "../../../constants/errorMessage";
import { StringFormat, ToObjectWithKey, ToOptionTypeList3 } from "../../../utils/array";
import { NotificationChannel, ThresholdObjectProps } from "../../../constants/type";
import { getAppSettings } from "../../../services/localStorage";
import { useEffect } from "react";
import { Link } from "react-router-dom";

export interface EventRuleCreateEditProps {
    form: FormInstance;
    data: any;
    tagsList: any[];
    isAdministrator: boolean;
    notifChannelsList: NotificationChannel[];
    callback?: () => void;
}

export const EVENT_RULE_THRESHOLD_PREFIX: string = "ev-ru-thres-";

const EventRuleCreateEdit = (props: EventRuleCreateEditProps) => {
    const getInputProps = (type: string = ComponentType.text) => {
        return {
            type: type,
            value: "",
            rules: [{ required: true, message: REQUIRED_FIELD }],
        };
    };

    const getTemplates = (thresholdText: string) => {
        let tmArr: React.ReactNode[] = [];
        try {
            let arr: ThresholdObjectProps[] = JSON.parse(thresholdText);
            if (arr.length > 0) {
                let appSetting = getAppSettings();
                tmArr = arr
                    .filter((x: ThresholdObjectProps) => (x.editable === undefined ? true : x.editable))
                    .filter((x: any) => x.appSettingValueProps === undefined)
                    .map((x: ThresholdObjectProps, idx: number) => {
                        if (x.appSettingProps) {
                            switch (x.appSettingProps) {
                                case "RELATIVE_TIME_RANGE":
                                    let valueItem: ThresholdObjectProps =
                                        arr[
                                        arr.findIndex(
                                            (y: ThresholdObjectProps) => y.appSettingValueProps && y.appSettingValueProps === x.appSettingProps
                                        )
                                        ];
                                    return (
                                        <Row key={`${EVENT_RULE_THRESHOLD_PREFIX}${idx}`}>
                                            <Col span={10} style={{ textAlign: "right" }} className="ant-form-item-label">
                                                <label className="ant-form-item-required">{x.label}</label>
                                            </Col>
                                            <Col span={9}>
                                                <FormComponent
                                                    label={""}
                                                    name={`${EVENT_RULE_THRESHOLD_PREFIX}${x.name}`}
                                                    extra={
                                                        {
                                                            ...getInputProps(x.fieldType),
                                                            value: ToOptionTypeList3(appSetting[x.appSettingProps]),
                                                            itemProps: {
                                                                labelCol: 0,
                                                                wrapperCol: 24,
                                                            },
                                                        } as ExtraProps
                                                    }
                                                />
                                            </Col>
                                            <Col span={5}>
                                                <FormComponent
                                                    label={""}
                                                    name={`${EVENT_RULE_THRESHOLD_PREFIX}${valueItem.name}`}
                                                    extra={
                                                        {
                                                            ...getInputProps(valueItem.fieldType),
                                                            itemProps: {
                                                                labelCol: 0,
                                                                wrapperCol: 24,
                                                            },
                                                        } as ExtraProps
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    );
                                case "SYMBOL_TYPE":
                                    return (
                                        <Row key={`${EVENT_RULE_THRESHOLD_PREFIX}${idx}`}>
                                            <Col span={24}>
                                                <FormComponent
                                                    label={x.label}
                                                    name={`${EVENT_RULE_THRESHOLD_PREFIX}${x.name}`}
                                                    extra={{
                                                        ...getInputProps(x.fieldType),
                                                        value: appSetting.SYMBOL_TYPE,
                                                        inputProps: {
                                                            mode: "multiple",
                                                        },
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    );
                            }
                        }
                        return (
                            <Row key={`${EVENT_RULE_THRESHOLD_PREFIX}${idx}`}>
                                <Col span={24}>
                                    <FormComponent
                                        label={x.label}
                                        name={`${EVENT_RULE_THRESHOLD_PREFIX}${x.name}`}
                                        extra={getInputProps(x.fieldType)}
                                    />
                                </Col>
                            </Row>
                        );
                    });
            }
        } catch (error) { }
        return (
            <>
                <FormComponent label={""} name={"threshold"} extra={{ type: ComponentType.hidden, value: "" }} />
                {tmArr}
            </>
        );
    };

    useEffect(() => {
        if (props.form.getFieldValue("threshold") && props.form.getFieldValue("threshold").length > 0) {
            props.form.setFieldsValue(
                props.isAdministrator
                    ? props.data
                    : ToObjectWithKey(
                        JSON.parse(props.form.getFieldValue("threshold")).map((x: any) => ({
                            ...x,
                            name: `${EVENT_RULE_THRESHOLD_PREFIX}${x.name}`,
                        })),
                        "name",
                        "value"
                    )
            );
        }
        return () => { };
    }, []);

    return (
        <>
            <Form labelCol={{ span: 10 }} wrapperCol={{ span: 14 }} form={props.form} layout="horizontal" initialValues={props.data}>
                <Row>
                    <Col span={20}>
                        <FormComponent label={""} name={"id"} extra={{ type: ComponentType.hidden, value: "" }} />
                        <FormComponent label={""} name={"profileId"} extra={{ type: ComponentType.hidden, value: "" }} />
                        <FormComponent
                            label="Name"
                            name="name"
                            extra={{
                                type: ComponentType.text,
                                value: "",
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={20}>
                        <FormComponent
                            label="Description"
                            name="description"
                            extra={{
                                type: ComponentType.text,
                                value: "",
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={20}>
                        <FormComponent
                            label="Status"
                            name="status"
                            extra={{
                                type: ComponentType.switch,
                                value: ["Inactive", "Active"],
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={20}>
                        <FormComponent
                            label="Refresh Interval (Second)"
                            name="refreshIntervalInSec"
                            extra={{
                                type: ComponentType.number,
                                value: "",
                                rules: [
                                    { required: true, message: REQUIRED_FIELD },
                                    {
                                        type: "number",
                                        min: 1,
                                        message: StringFormat(MIN, `1`),
                                        transform: (value) => (Number(value) ? Number(value) : 0),
                                    },
                                ],
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={20}>
                        <FormComponent
                            label="Tags"
                            name={"tags"}
                            extra={{
                                type: ComponentType.dropdown,
                                value: props.tagsList.map((x) => ({
                                    text: x.name,
                                    value: x.id,
                                })),
                                inputProps: {
                                    mode: "multiple",
                                    allowClear: true,
                                },
                            }}
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: -10, marginBottom: 5 }}>
                    <Col span={16}></Col>
                    <Col span={8}>
                        <Link
                            to={"/siteadmin/appconfig/notificationchannelconfig/create"}
                            target="_blank"
                        >
                            Don't have channel?
                        </Link>
                    </Col>
                </Row>
                <Row>
                    <Col span={20}>
                        <FormComponent
                            label="Notification Channels"
                            name="channelUid"
                            extra={{
                                type: ComponentType.dropdown,
                                value: props.notifChannelsList.map((x) => ({
                                    text: x.name,
                                    value: x.channelUid,
                                })),
                                // inputProps: {
                                //     mode: "tags",
                                // },
                                withSyncBtn: {
                                    enable: true,
                                    callback: () => {
                                        props.callback && props.callback();
                                    },
                                },
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={20}>
                        <FormComponent
                            label="Data Query"
                            name="dataQuery"
                            extra={{
                                type: ComponentType.textarea,
                                value: "",
                                inputProps: {
                                    rows: 10,
                                },
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                            }}
                        />
                    </Col>
                </Row>
                {props.isAdministrator ? (
                    <Row>
                        <Col span={20}>
                            <FormComponent
                                label="Threshold"
                                name="threshold"
                                extra={{
                                    type: ComponentType.textarea,
                                    value: "",
                                    inputProps: {
                                        rows: 10,
                                    },
                                }}
                            />
                        </Col>
                    </Row>
                ) : (
                    getTemplates(props.form.getFieldValue("threshold") as string)
                )}
                {props.isAdministrator ? (
                    <Row>
                        <Col span={20}>
                            <FormComponent
                                label="Noise Control"
                                name="noiseControl"
                                extra={{
                                    type: ComponentType.textarea,
                                    value: "",
                                    inputProps: {
                                        rows: 10,
                                    },
                                }}
                            />
                        </Col>
                    </Row>
                ) : (
                    <FormComponent label={""} name={"noiseControl"} extra={{ type: ComponentType.hidden, value: "" }} />
                )}
            </Form>
        </>
    );
};

export default EventRuleCreateEdit;
