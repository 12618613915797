import { FormComponent } from "@/components/FormComponent";
import { ComponentType } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { KeyValuePair } from "@/constants/type";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Form, Input, Button } from "antd";

export interface BridgeMappingComponentProps {
    bridges: KeyValuePair[];
}

const BridgeMappingComponent = (props: BridgeMappingComponentProps) => {
    return (
        <Form.List name="bridgeServerSettings">
            {(fields, { add, remove }, { errors }) => (
                <>
                    {fields.map((field, index) => (
                        <Input.Group compact key={`bridgeServerSettings-${index}`} className="input-row-container bridge-div">
                            <FormComponent
                                label=""
                                name={[field.name, "bridgeId"]}
                                extra={{
                                    type: ComponentType.dropdown,
                                    value: props.bridges,
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                    itemProps: {
                                        style: { width: "calc(40% - 20px)" },
                                        wrapperCol: { span: 24 },
                                    },
                                    inputProps: {
                                        placeholder: "Please select bridge",
                                        style: {
                                            width: "100%",
                                        },
                                    },
                                }}
                            />
                            <FormComponent
                                label=""
                                name={[field.name, "takerId"]}
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    itemProps: { wrapperCol: { span: 24 } },
                                    inputProps: {
                                        placeholder: "Please fill in taker id",
                                    },
                                }}
                            />
                            <FormComponent
                                label=""
                                name={[field.name, "comment"]}
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    itemProps: { wrapperCol: { span: 24 } },
                                    inputProps: {
                                        placeholder: "Please fill in comment",
                                    },
                                }}
                            />
                            <Button type="text" icon={<DeleteOutlined />} className="btn-remove" onClick={() => remove(field.name)} />
                        </Input.Group>
                    ))}
                    <Button type="dashed" icon={<PlusOutlined />} className="btn-add-new" onClick={() => add()}>
                        Add New Bridge Mapping
                    </Button>
                </>
            )}
        </Form.List>
    );
};

export default BridgeMappingComponent;
