import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import React, { useState } from "react";

export interface CollapsePanelProps {
    title: string;
    children?: React.ReactNode;
    disabledCollapse?: boolean;
    extraElement?: React.ReactNode;
    contentStyle?: React.CSSProperties;
    fixedContent?: React.ReactNode;
}

const CollapsePanel = (props: CollapsePanelProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(props.disabledCollapse !== undefined ? props.disabledCollapse : false);

    return (
        <div className="collapse-box-container">
            <div className="title">
                <div className="left">{props.title}</div>
                <div className="right">
                    {props.extraElement && props.extraElement}
                    {(props.disabledCollapse === undefined || (props.disabledCollapse !== undefined && !props.disabledCollapse)) && (
                        <div className="btn-collapse" onClick={() => setIsOpen(prev => !prev)}>
                            {isOpen ? <CaretUpOutlined /> : <CaretDownOutlined />}
                        </div>
                    )}
                </div>
            </div>
            {isOpen && (
                <div className="content">
                    {props.fixedContent && <div>{props.fixedContent}</div>}
                    <div className="main-content nice-scrollbar" {...(props.contentStyle && { style: props.contentStyle })}>
                        {props.children && props.children}
                    </div>
                </div>
            )}
        </div>
    );
};

export default CollapsePanel;
