import { Tabs, Segmented, Button } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FlexiDataTable from "../../../components/FlexiDataTable";
import { SUCCESS_FAILED, CALLBACK_KEY, ComponentType } from "../../../constants";
import {
    FlexiDataTableOptionsProps,
    FlexiDataTableCallbackProps,
    FlexiDataColumnProps,
    HubFailoverServerList,
    HubFailoverSecuritiesList,
    HubSwitcherHistoricalItemSecurity,
} from "../../../constants/type";
import { apiRequest, APIs } from "../../../services/apiConfig";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";
import "./HubSwitcher.less";
import AuthHelper, { AuthKeys } from "../../../helpers/authHelper";
import HubSwitcherHistorical from "./Historical";
import { GetUniqueListByKey, SortList, ToObjectWithKey } from "../../../utils/array";
import { objectRemoveProps } from "../../../utils/object";

const TabNavList = [{ key: "1", label: "OZ <> PE Manager" }];
const SegmentList = [
    { value: "1", label: "Server List" },
    { value: "2", label: "Historical List" },
];

export const getModeSource = (mode: string) => {
    switch (mode) {
        case "1":
            return { source: "onezero", target: "priceengine" };
        default:
            return {};
    }
};

function HubSwitcher() {
    let navigate = useNavigate();
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.ADMIN_TOOLS_HUB_SWITCHER_EDIT);

    const getColumnsConfig = (serverGroupList: any[], securityGroupList: any[]) => {
        return [
            DTColProps.Middle({
                title: "Server",
                dataIndex: "serverIds",
                key: "serverIds",
                render: (serverIds: any, rowData: HubSwitcherHistoricalItemSecurity) => {
                    return <div>{rowData.server}</div>;
                },
                options: {
                    filter: {
                        type: ComponentType.treeselect,
                        value: serverGroupList,
                    },
                },
            }),
            DTColProps.Small({
                title: "Cluster",
                dataIndex: "clusterName",
                key: "clusterName",
            }),

            {
                title: "OZ & PE Manager Security",
                dataIndex: "security",
                key: "security",
            },

            DTColProps.Status({
                title: "OZ Manager Status",
                dataIndex: "sourceStatus",
                key: "sourceStatus",
                width: "8vw",
            }),

            {
                title: "Security",
                dataIndex: "securities",
                key: "securities",
                options: {
                    filter: {
                        type: ComponentType.treeselect,
                        value: securityGroupList,
                    },
                    visible: false,
                },
            },
            {
                title: "Cluster",
                dataIndex: "clusterName",
                key: "clusterName",
                options: {
                    filter: {
                        type: ComponentType.text,
                        value: "",
                    },
                    visible: false,
                },
            },

            DTColProps.Status({
                title: "PE Manager Status",
                dataIndex: "targetStatus",
                key: "targetStatus",
                width: "8vw",
            }),
        ];
    };

    const [currentActiveTab, setCurrentActiveTab] = useState<string>("1"); //For Top Tab
    const [currentActiveSegment, setCurrentActiveSegment] = useState<string>("1"); // For segment

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [tableCols, setTableCols] = useState<FlexiDataColumnProps[]>(getColumnsConfig([], []));
    const [data, setData] = useState<HubSwitcherHistoricalItemSecurity[]>([]);
    const [filterParams, setFilterParams] = useState<any>({});
    //const [filterParams, setFilterParams] = useState<any>({ securities: defaultSelectedSecurity });

    const [selectedData, setSelectedData] = useState<HubSwitcherHistoricalItemSecurity[]>([]);
    const [filterGroupList, setFilterGroupList] = useState<any>({});

    const options: FlexiDataTableOptionsProps = {
        serverFiltering: false,
        enableRowSelection: true,
        showHideColumns: false,
        hideRowSelectionsSummary: true,
        rowSelectionData: {
            rowSelectionType: "checkbox",
            selectedRowKeys: [],
            options: {
                getCheckboxProps: (record: HubSwitcherHistoricalItemSecurity) => ({
                    disabled: record.sourceStatus === record.targetStatus,
                }),
            },
        },
    };

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                let hlFilterParams: any = {};
                Object.keys(FormData)
                    .filter(x => FormData[x] && (Array.isArray(FormData[x]) ? FormData[x].length > 0 : FormData[x].toString().length > 0))
                    .map(x => {
                        if (x === "serverIds") {
                            hlFilterParams["serverIds"] = FormData[x];
                        }
                        if (x === "securities") {
                            hlFilterParams["securities"] = FormData[x];
                        } else {
                            hlFilterParams[x] = FormData[x];
                        }
                        return x;
                    });
                setFilterParams(hlFilterParams);
                setRunRefetchDataList(true);
                break;

            case CALLBACK_KEY.ROW_SELECTION_CALLBACK:
                setSelectedData(FormData.selectedRows);
                break;
            default:
                break;
        }
    };

    const getList = (tabId: string) => {
        let sourceObj: any = getModeSource(tabId),
            finalFilterParams = objectRemoveProps(filterParams, ["securities", "serverIds"]);
        [
            { sourceKey: "securityGroup", target: "securities", keyString: "" },
            { sourceKey: "serverGroup", target: "serverIds", keyString: "" },
        ].forEach((z: any) => {
            if (filterParams?.hasOwnProperty(z.target) && filterParams[z.target].length > 0) {
                finalFilterParams[z.target] = Object.keys(
                    filterParams[z.target].reduce((obj: any, x: string) => {
                        return {
                            ...obj,
                            ...(x.indexOf("-i-") === -1
                                ? filterGroupList[z.sourceKey]
                                      .filter((y: any) => y.pId === x)
                                      .reduce((iObj: any, y: any) => {
                                          iObj[y.value.substring(y.value.indexOf("-i-") + 3)] = 1;
                                          return iObj;
                                      }, {})
                                : {
                                      [x.substring(x.indexOf("-i-") + 3)]: 1,
                                  }),
                        };
                    }, {})
                );
            }
        });

        apiRequest(APIs.GET_MT_SERVER_MANAGER_SECURITIY_STATUS, {
            ...sourceObj,
            ...finalFilterParams,
        })
            .then((data: any) => {
                if (data && data.managerSecurities.length > 0) {
                    setData(data.managerSecurities.map((x: any) => ({ ...x, key: `${x.serverId}-${x.security}` })));
                } else {
                    setData([]);
                }
            })
            .catch(error => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("manager securities", SUCCESS_FAILED.FAILED_LOAD_DATA, err)))
            .finally(() => setIsLoading(false));
    };

    const generateTreeSelectData = (
        HubFailoverServersList: HubFailoverServerList[] = [],
        HubFailoverSecuritiesList: HubFailoverSecuritiesList[] = [],
        serverGroupList: any[],
        securityGroupList: any[]
    ) => {
        let serverIdWithGroup = Object.keys(
                serverGroupList.reduce((arr: any, x: any) => ({ ...arr, ...ToObjectWithKey(x.servers, "id", "id") }), {})
            ),
            serverTreeData = [
                ...SortList(
                    serverGroupList.reduce((arr: any[], x: any, xIdx: number) => {
                        arr.push({ id: `g-${xIdx}`, pId: "0", value: `g-${xIdx}`, title: x.groupName });
                        return arr.concat(
                            x.servers.map((y: any) => ({
                                id: `g-${xIdx}-i-${y.id}`,
                                pId: `g-${xIdx}`,
                                value: `g-${xIdx}-i-${y.id}`,
                                title: y.server,
                            }))
                        );
                    }, []),
                    "title"
                ),
                ...SortList(
                    HubFailoverServersList.filter(x => !serverIdWithGroup.includes(`${x.id}`)).map(x => ({
                        id: `g-0-i-${x.id}`,
                        pId: "0",
                        value: `g-0-i-${x.id}`,
                        title: x.server,
                    })),
                    "title"
                ),
            ],
            securityNameWithGroup = Object.keys(
                securityGroupList.reduce((arr: any, x: any) => ({ ...arr, ...ToObjectWithKey(x.securities, "name", "name") }), {})
            ),
            securityTreeData = [
                ...SortList(
                    securityGroupList.reduce((arr: any[], x: any, xIdx: number) => {
                        arr.push({ id: `g-${xIdx}`, pId: "0", value: `g-${xIdx}`, title: x.groupName });
                        return arr.concat(
                            x.securities.map((y: any) => ({
                                id: `g-${xIdx}-i-${y.name}`,
                                pId: `g-${xIdx}`,
                                value: `g-${xIdx}-i-${y.name}`,
                                title: y.name,
                            }))
                        );
                    }, []),
                    "title"
                ),
                ...SortList(
                    GetUniqueListByKey(HubFailoverSecuritiesList, "name")
                        .filter(x => !securityNameWithGroup.includes(`${x.name}`))
                        .map(x => ({
                            id: `g-0-i-${x.name}`,
                            pId: "0",
                            value: `g-0-i-${x.name}`,
                            title: x.name,
                        })),
                    "title"
                ),
            ];

        return { serverGroup: serverTreeData, securityGroup: securityTreeData };
    };

    const initLoad = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["hubFailover"] })
            .then((data: any) => {
                let treeData = generateTreeSelectData(
                    data.hubFailover.servers,
                    data.hubFailover.securities,
                    data.hubFailover.serverGroups,
                    data.hubFailover.securityGroups
                );
                setFilterGroupList(treeData);
                setTableCols(getColumnsConfig(treeData["serverGroup"], treeData["securityGroup"]));
                setRunRefetchDataList(false);
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("filter config list", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        setIsLoading(true);
        initLoad();
        return () => {};
    }, []);

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            getList(currentActiveTab);
            setRunRefetchDataList(false);
        }
        return () => {};
    }, [runRefetchDataList]);

    return (
        <>
            <div className="hub-switcher-container">
                <div className="content-panel">
                    <div className="list-panel">
                        <Tabs
                            className="tab-container"
                            defaultActiveKey={currentActiveTab}
                            type="card"
                            size="middle"
                            onChange={(activeKey: string) => setCurrentActiveTab(activeKey)}
                            items={TabNavList}
                        />
                        <div className="hub-switcher-content-navs">
                            <Segmented
                                value={currentActiveSegment}
                                options={SegmentList}
                                onChange={(activeKey: any) => setCurrentActiveSegment(activeKey)}
                            />
                            {enableUpdate && Object.keys(selectedData).length > 0 && (
                                <Button
                                    key="preview"
                                    type="primary"
                                    onClick={() => {
                                        navigate("/admintools/hubSwitcher/preview", {
                                            state: { data: selectedData },
                                        });
                                    }}
                                >
                                    Preview
                                </Button>
                            )}
                        </div>

                        <div className="hub-switcher-content">
                            <div className="content">
                                <div className="major-content">
                                    {currentActiveSegment === "1" && (
                                        <div className="content">
                                            <FlexiDataTable
                                                rowKeyProperty="key"
                                                title=""
                                                columns={tableCols}
                                                options={options}
                                                dataSource={data ?? []}
                                                pagination={{
                                                    defaultPageSize: 50,
                                                }}
                                                callback={componentCallback}
                                                loading={isLoading}
                                                serverSide={false}
                                            />
                                        </div>
                                    )}
                                    {currentActiveSegment === "2" && <HubSwitcherHistorical mode={currentActiveTab} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HubSwitcher;
