import { colorMap } from "@/pages/SystemMonitor/StatusMonitor/statusUtils";

export const ROLES_LABELS = {
    Administrator: {
        id: 1,
        key: "administrator",
        name: "Administrator",
    },
    SiteAdmin: {
        id: 2,
        key: "siteadmin",
        name: "Site Admin",
    },
    Manager: {
        id: 3,
        key: "user",
        name: "User",
    },
};

export const ADHOC_TYPE: { [key: number]: string } = {
    1: "Adhoc",
    2: "Adhoc By Date",
    0: "Scheduled",
};

export const SHARING_RULES: { [key: number]: string } = {
    1: "Private",
    2: "Public",
};

export enum SHARING_RULES_ENUM {
    Private = 1,
    Public = 2,
}

export const STATUS_TYPE: { [key: number]: string } = {
    1: "Active",
    0: "Inactive",
};

export const REPORT_STATUS: { [key: number]: string } = {
    0: "Generating",
    1: "Success",
    2: "Failed",
};

export enum REPORT_STATUS_ENUM {
    Generating = 0,
    Success = 1,
    Failed = 2,
}

export const RC_STATUS: { [key: number]: string } = {
    0: "Failed",
    1: "Success",
    2: "No Need To Send",
};

export const METRIC_TYPES: { [key: number]: string } = {
    1: "Account",
    2: "Symbol",
    3: "Trade",
};

export const DATA_TYPES: { [key: number]: string } = {
    1: "Boolean",
    2: "Double",
    3: "Int",
    4: "DateTime",
    5: "Date",
};

export enum DATA_TYPES_ENUM {
    Empty = 0,
    Boolean = 1,
    Double = 2,
    Int = 3,
    DateTime = 4,
    Date = 5,
}

export const FILTER_OPERATOR_TYPE: { [key: number]: string } = {
    0: "Equal(Boolean)", // boolean only
    1: "Equal", // int, double, datetime, date
    2: "Greater than equal", // int, double, datetime, date
    3: "Less than equal", // int, double, datetime, date
    4: "Range", // int, double, datetime, date
    5: "Greater than", // int, double, datetime, date
    6: "Less than", // int, double, datetime, date
};

export const FILTER_OPERATOR_DISPLAYNAME: { [key: number]: string } = {
    0: "Equal to", // boolean only
    1: "Equal to", // int, double, datetime, date
    2: "Greater than or equal to", // int, double, datetime, date
    3: "Less than or equal to ", // int, double, datetime, date
    4: "Between", // int, double, datetime, date
    5: "Greater than", // int, double, datetime, date
    6: "Less than", // int, double, datetime, date
};

export const RISK_SCORE_TIER_OPERATOR_TYPE: { [key: number]: string } = {
    1: "Greater than",
    2: "Less than",
    3: "Between",
};

export const BOOLEAN_TYPE: { [key: number]: string } = {
    0: "False",
    1: "True",
};

export enum FILTER_OPERATOR_TYPE_ENUM {
    TrueFalse = 0,
    Equal = 1,
    GreaterThanEqual = 2,
    LessThanEqual = 3,
    Range = 4,
    GreaterThan = 5,
    LessThan = 6,
}

export const LOCAL_STORAGE_KEYS = {
    ACCESS_TOKEN: "accessToken",
    REFRESH_TOKEN: "refreshToken",
    PROFILE: "_UrP",
    SYSTEM_SETTING: "sysCon",
    MODULES: "is_m",
    SOURCE_KEY: "sk",
    APP_SETTINGS: "_apS",
    HUB_SWITCHING: "hubS",
};

export const WEEK_DAYS: { [key: string]: string } = {
    MON: "Monday",
    TUE: "Tuesday",
    WED: "Wednesday",
    THU: "Thursday",
    FRI: "Friday",
    SAT: "Saturday",
    SUN: "Sunday",
};

export const SORTING: { [key: string]: string } = {
    ASC: "Ascending",
    DESC: "Descending",
};

export const METRIC_TYPES_NAMES = {
    ACCOUNT: "account",
    ACCOUNT_SYMBOL: "account-symbol",
    SYMBOL: "symbol",
    TRADE: "trade",
};

export const TAGGING_STATUS: { [key: number]: string } = {
    0: "Inactive",
    1: "Active",
    2: "Deleted",
};

export const MONITOR_PROFILE_STATUS: { [key: number]: string } = {
    0: "Inactive",
    1: "Active",
};

export enum TAGGING_EVENT_STATUS_ENUM {
    Abandoned = 0,
    Active = 1,
}

export const MONITORING_FREQUENCY_TYPE = {
    "5s": "0/5 * * ? * * *",
    "10s": "0/10 * * ? * * *",
    "30s": "0/30 * * ? * * *",
    "1m": "0 * * ? * * *",
    "5m": "0 0/5 * ? * * *",
    "15m": "0 0/15 * ? * * *",
    "30m": "0 0/30 * ? * * *",
    "1h": "0 0 0/1 ? * * *",
    "2h": "0 0 0/2 ? * * *",
    "1d": "0 0 0 ? * * *",
};

export const MONITORING_REFRESH_RATE: { [key: string]: number } = {
    "5s": 5,
    "10s": 10,
    "30s": 30,
    "1m": 60,
    "5m": 300,
    "10m": 600,
    "15m": 900,
    "30m": 1800,
    "1h": 3600,
    "2h": 7200,
    "1d": 86400,
};

export const NOTIFICATION_TYPE: { [key: number]: string } = {
    0: "Information",
    1: "Warning",
    2: "Critical",
};

export const NOTIFICATION_LEVEL: { [key: number]: string } = {
    1: "Low",
    2: "Normal",
    3: "High",
    4: "Critical",
};

export enum NOTIFICATION_LEVEL_IDS {
    LOW = 1,
    NORMAL = 2,
    HIGH = 3,
    CRITICAL = 4,
}

export const EVENT_LISTENERS: { [key: number]: string } = {
    0: "None",
    1: "Click",
};

export const DASHBOARD_MODULES = {
    HISTORICAL_EVENTS: "historical_event",
};

export const enum CronType {
    Daily = 1,
    Weekly = 2,
    Monthly = 3,
    Minute = 4,
    Hour = 5,
}

export const ChartType: { [key: number]: string } = {
    0: "Table",
    1: "Bar",
    2: "Line",
    3: "Pie",
};

export enum ColumnFilter {
    TextField = 1,
    DropDown = 2,
}

export enum ColumnFormAction {
    Add = 1,
    Edit = 2,
}

export const ComponentType: { [key: string]: string } = {
    labelOnly: "label",
    text: "text",
    number: "number",
    numberrange: "numberrange",
    numberrange2: "numberrange2",
    hidden: "hidden",
    password: "password",
    textarea: "textarea",
    dropdown: "dropdown",
    treeselect: "treeselect",
    switch: "switch",
    checkbox: "checkbox",
    checkboxgroup: "checkboxgroup",
    radio: "radio",
    radioverticalgroup: "radioverticalgroup",
    date: "date",
    time: "time",
    daterange: "daterange",
    daterange2: "daterange2",
    transfer: "transfer",
    upload: "upload",
    tree: "tree",
    timerange: "timerange",
    slider: "slider",
    multipletimerange: "multipletimerange",
    rebateAccounts: "rebateAccounts",
    messageTemplate: "messageTemplate",
    colorpicker: "colorpicker",
};

export enum CALLBACK_KEY {
    CREATE_NEW = 1,
    CREATE_NEW_FORM_SUBMIT = 2,
    DO_EDIT = 3,
    EDIT_FORM_SUBMIT = 4,
    DO_DELETE = 5,
    DELETE_FORM_SUBMIT = 6,
    FILTER_FORM_SUBMIT = 7,
    FILTER_FORM_SUBMIT_FAILED = 8,
    COLLAPSE = 9,
    CUSTOM_ROW_OPTION_CALLBACK = 10,
    CUSTOM_MODAL_ACTION = 11,
    CUSTOM_PANEL_OPTION_ACTION = 12,
    CUSTOM_PANEL_OPTION_SUBMIT = 13,
    VIEW_RECORD = 14,
    REFRESH = 15,
    HANDLE_PAGINATION_SORTING = 16,
    ROW_SELECTION_CALLBACK = 17,
    RESYNC_JOB_SCHEDULER = 18,
    EXPANDABLE_TABLE = 19,
    REFRESH_RATE_CHANGED = 20,
    OTHERS = 21,
    BACK_TO_PREVIOUS = 22,
    EXPORT_CSV_EXCEL = 23,
    SHOW_HIDE_COLUMNS = 24,
    SHOW_HIDE_COLUMNS_SELECTION = 25,
    FILTER_FORM_RESET = 26,
    UPLOAD_CSV_EXCEL = 27,
    MONITORING_PAGINATION = 28,
    REFRESH_FIXED_TIMER = 21,
    ON_SELECT_TABLE_ROW_CALLBACK = 22,
    CLSE_MODAL_CALLBACK = 23,
    ON_SOLVED = 24,
}

export enum SUCCESS_FAILED {
    OTHERS = 0,
    SUCCESS_CREATE_DATA = 1,
    SUCCESS_UPDATE_DATA = 2,
    SUCCESS_DELETE_DATA = 3,
    SUCCESS_LOAD_DATA = 4,
    SUCCESS_DOWNLOAD_DATA = 5,
    SUCCESS_RESOLVED = 6,
    SUCCESS_UPLOAD_DATA = 7,
    FAILED_CREATE_DATA = -1,
    FAILED_UPDATE_DATA = -2,
    FAILED_DELETE_DATA = -3,
    FAILED_LOAD_DATA = -4,
    FAILED_DUPLICATE_DATA = -5,
    FAILED_DOWNLOAD_DATA = -6,
    FAILED_RESOLVED = -7,
    FAILED_UPLOAD_DATA = -8,
}

export const SUCCESS_FAILED_MSG: { [key: number]: string } = {
    "0": "",
    "1": "has been created successfully!",
    "2": "has been updated successfully!",
    "3": "has been deleted successfully!",
    "4": "has been loaded successfully!",
    "5": "has been downloaded successfully!",
    "6": "has been resolve successfully!",
    "7": "has been uploaded successfully!",
    "-1": "Failed to create",
    "-2": "Failed to update",
    "-3": "Failed to delete",
    "-4": "Failed to load",
    "-5": "Failed to duplicate",
    "-6": "Failed to download",
    "-7": "Failed to resolve",
    "-8": "Failed to upload",
};

export enum METRIC_FORMAT_TYPE_ENUM {
    USD = 0,
    COUNT = 1,
    PERCENTAGE = 2,
    RATIO = 3,
    DATE = 4,
    TIME = 5,
}

export const METRIC_FORMAT_TYPE: { [key: number]: string } = {
    0: "USD",
    1: "Count",
    2: "Percentage",
    3: "Ratio",
    4: "Date",
    5: "Time",
};

export enum symbolConfigModalType {
    none = 0,
    hasDups = 1,
    loading = 2,
    success = 3,
    screeningFailed = 4,
    uploadFailed = 5,
}

export enum SymbolServerType {
    MT4 = 1,
    MT5 = 2,
    VTS = 3,
    LP = 4,
    EXCHANGE = 5,
    OZ_BRIDGE = 6,
    PE_BRIDGE = 7,
}

export const SYMBOL_SERVER_TYPES: { [key: number]: string } = {
    1: "MT4",
    2: "MT5",
    3: "VTS",
    4: "LP",
    5: "Exchange",
    6: "OZ Bridge",
    7: "PE Bridge",
};

export enum UploadModalType {
    none = 0,
    hasDups = 1,
    loading = 2,
    success = 3,
    screeningFailed = 4,
    uploadFailed = 5,
}

export enum PRICE_OUTAGE_STATUS_ENUM {
    Outage = 0,
    OutageRestore = 1,
    SpreadExceed = 2,
    SpreadExceedRestore = 3,
    Expired = 4,
}

export enum MID_PRICE_MONITORING_STATUS_ENUM {
    MidPriceExceedThreshold = 0,
    MidPriceExceedRestore = 1,
    InvalidReferenceSource = 2,
    Expired = 3,
}

export const PRICE_OUTAGE_STATUSES: { [key: number]: string } = {
    0: "Outage",
    1: "Outage Restore",
    2: "Spread Exceed",
    3: "Spread Exceed Restore",
};

export enum PRICE_SOURCE_TYPE {
    Internal = 1,
    External = 2,
}

export const SYMBOL_CONFIG_TYPE: { [key: string]: string } = {
    PRICE_OUTAGE: "priceOutage",
    MID_PRICE_MONITOR: "midPriceMonitor",
};

export enum FILTER_DROPDOWN_FIELDS {
    SYMBOLS = 0,
    LOGINS = 1,
    USER_GROUPS = 2,
}

export const RESTORE_TRADE_GROUP_TYPES: { [key: string]: string } = {
    GROUP_1: "Group 1",
    GROUP_2: "Group 2",
    GROUP_3: "Group 3",
};

export const PROGRESS_TYPES: { [key: number]: string } = {
    0: "Pending Approval",
    1: "Approved, To Be Processed",
    2: "To Be Processed",
    3: "Processing",
    4: "Rejected",
    5: "Abandoned",
    6: "Failed",
    7: "Completed",
};

export enum PROGRESS_TYPES_ENUM {
    PENDING_APPROVAL = 0,
    APPROVED_TO_BE_PROCESSED = 1,
    TO_BE_PROCESSED = 2,
    PROCESSING = 3,
    REJECTED = 4,
    ABANDONED = 5,
    FAILED = 6,
    COMPLETED = 7,
}

export enum InnerPageActionMode {
    CREATE_NEW = 0,
    EDIT = 1,
    BACK = 2,
    OTHERS = 3,
}

export enum DND_ACTION {
    DRAGGABLE_END = 1,
}

export enum MAIN_COMPONENT_CALLBACK_ACTION {
    DELETE = 1,
}

export const MONITORING_PAGINATION: { [key: string]: number } = {
    "5 Rows": 5,
    "10 Rows": 10,
    "20 Rows": 20,
    "50 Rows": 50,
    "100 Rows": 100,
};

export enum DIMENSION_LEVEL {
    ACCOUNT = 1,
    SYMBOL = 2,
    SERVER = 3,
    BRAND = 4,
    COUNTRY = 5,
    CLIENT = 6,
    REGULATOR = 7,
    IB = 8,
    CPA = 9,
    SYMBOLASSETTYPE = 10,
    ACCGROUP = 11,
}

export const DIMENSION_LEVEL_LABEL: { [key: string]: string } = {
    ACCOUNT: "Accounts",
    ACCGROUP: "Account Group",
    SYMBOL: "Symbols",
    SERVER: "Servers",
    BRAND: "Brands",
    COUNTRY: "Countries",
    IB: "IB (Rebate Account)",
    SYMBOLASSETTYPE: "Symbol Asset Types",
};

export const DIMENSION_METRICS_COL_ORDER: { [key: string]: number } = {
    exposure: 1,
    turnover: 2,
    net_volume: 3,
    total_pnl: 4,
    intraday_pnl: 5,
    floating_pnl: 6,
    closed_pnl: 7,
};

export enum INTRADAY_COMPONENTS_BY_ID {
    SUMMARY_STATS = 1,
    USERS_BY_PNL = 2,
    SYMBOLS_BY_EXPOSURE = 3,
    SYMBOL_SUMMARY = 4,
    BRAND_SUMMARY = 5,
    WORLD_MAP = 6,
    PNL_CHART = 7,
}

export const INTRADAY_FILTER_OPTIONS: { [key: number]: string } = {
    1: "Equal to",
    2: "Not equal to",
    3: "Greater than",
    4: "Less than",
    5: "Greater than or equal",
    6: "Less than or equal",
    7: "Between",
    8: "Contains",
    9: "Starts with",
    10: "Ends with",
};

export const INTRADAY_BREACH_SEVERITY: { [key: number]: string } = {
    0: "Normal",
    1: "Low",
    2: "Mid",
    3: "High",
    4: "Critical",
};

export const LP_FILTER_OPTIONS: { [key: number]: string } = {
    1: "Equal to",
    2: "Not equal to",
    3: "Greater than",
    4: "Less than",
    5: "Greater than or equal",
    6: "Less than or equal",
    7: "Between",
    8: "Contains",
    9: "Starts with",
    10: "Ends with",
};

export const LP_BREACH_SEVERITY: { [key: number]: string } = {
    0: "Normal",
    1: "Low",
    2: "Mid",
    3: "High",
    4: "Critical",
};

export const METRIC_DATA_TYPE: { [key: number]: string } = {
    1: "Small Integer",
    2: "Integer",
    3: "Big Integer",
    4: "Double",
    5: "Decimal",
    6: "Boolean",
    7: "Char",
    8: "Varchar",
    9: "Date",
    10: "DateTime",
};

export enum METRIC_DATA_TYPE_ENUM {
    SMALL_INT = 1,
    INTEGER = 2,
    BIG_INT = 3,
    DOUBLE = 4,
    DECIMAL = 5,
    BOOLEAN = 6,
    CHAR = 7,
    VARCHAR = 8,
    DATE = 9,
    DATETIME = 10,
}

export const METRIC_FILTER_OPTIONS: { [key: number]: string } = {
    1: "Equal to",
    2: "Not equal to",
    3: "Greater than",
    4: "Less than",
    5: "Greater than or equal",
    6: "Less than or equal",
    7: "Between",
    8: "Contains",
    9: "Starts with",
    10: "Ends with",
};

export enum METRIC_FILTER_OPTIONS_ENUM {
    EQUAL_TO = 1,
    NOT_EQUAL_TO = 2,
    GREATER_THAN = 3,
    LESS_THAN = 4,
    GREATER_THAN_OR_EQUAL = 5,
    LESS_THAN_OR_EQUAL = 6,
    BETWEEN = 7,
    CONTAINS = 8,
    STARTS_WITH = 9,
    ENDS_WITH = 10,
}

export const ALARM_BREACH_SEVERITY: { [key: number]: string } = {
    0: "Normal",
    1: "Low",
    2: "Mid",
    3: "High",
    4: "Crit",
};

export enum INTRADAY_CHART_TYPE {
    LINE = 1,
    BAR = 2,
}

export enum TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM {
    TextField = 1,
    TextArea = 2,
    IntegerNumericField = 3,
    DecimalNumericField = 4,
    DropdownMenu = 5,
    Checkbox = 6,
    RadioButton = 7,
    ToggleSwitcher = 8,
    DatePicker = 9,
    TimePicker = 10,
    DateTimePicker = 11,
}

export const TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE: { [key: number]: string } = {
    1: "Text field",
    2: "Text area",
    3: "Number",
    4: "Number with decimals",
    5: "Dropdown menu",
    6: "Checkbox",
    7: "Radio button",
    8: "Toggle switcher",
    9: "Date picker",
    10: "Time picker",
    11: "Date time picker",
};

export enum TOXIC_CLIENT_LABEL_UI_OPTION_TYPE_ENUM {
    CUSTOMIZED = 1,
    SYMBOL = 2,
}

export const TOXIC_CLIENT_LABEL_UI_OPTION_TYPE: { [key: number]: string } = {
    1: "Customized",
    // 2: "Symbol",
};

export const TOXIC_CLIENT_CHECK_ENTRY_STATUS: { [key: number]: string } = {
    0: "Following",
    1: "Done",
    2: "Holding",
};

export const LP_MODULE_SECTION_ID: { [key: number]: string } = {
    1: "Top Summary Chart",
    2: "Liquidity Provider Summary By Symbol Listing",
    3: "Liquidity Provider Listing",
};

export const TIMEZONE_FORMATS: { [key: number]: string } = {
    1: "Server",
    2: "UTC",
    3: "Local",
};

export enum TIMEZONE_FORMATS_ENUM {
    Server = 1,
    UTC = 2,
    Local = 3,
}

export const TOXIC_CLIENT_CHECK_ENTRY_PICKUP_TEAM: { [key: number]: string } = {
    1: "Risk",
    2: "Frontend",
};

export enum TOXIC_CLIENT_CHECK_CRM_MAPPING_MODE_ENUM {
    Auto = 0,
    Manual = 1,
}

export const TOXIC_CLIENT_CHECK_ENTRY_CRM_MAPPING_MODE: { [key: number]: string } = {
    0: "Auto",
    1: "Manual",
};

export const TOXIC_CLIENT_CHECK_AUDIT_LOG_CATEGORY: { [key: string]: string } = {
    ENTRY: "Entry",
    RESTRICTION: "Restriction",
};

export const TOXIC_CLIENT_CHECK_ACCOUNT_RESTRICTIONS_CODES: { [key: string]: string } = {
    addingCidTool: "ACT",
    closeOnly: "CO",
    groupChange: "GC",
    readOnly: "RO",
    sBook: "SB",
};

export const TOXIC_CLIENT_CHECK_CLIENT_RESTRICTIONS_CODES: { [key: string]: string } = {
    closeAccount: "CA",
    creditPromotion: "CP",
    kyc: "KYC",
    leverage: "LVRG",
    openAccount: "OA",
    swap: "SWAP",
    warningLetter: "WL",
    withdrawBan: "WB",
};

export const TOXIC_CLIENT_CHECK_RESTRICTIONS_NAMES: { [key: string]: string } = {
    addingCidTool: "Adding CID Tool",
    closeOnly: "Close Only",
    groupChange: "Group Change",
    readOnly: "Read Only",
    sBook: "S Book",
    closeAccount: "Close Account",
    creditPromotion: "Credit Promotion",
    kyc: "KYC",
    leverage: "Leverage",
    openAccount: "Open Account",
    swap: "Swap",
    warningLetter: "Warning Letter",
    withdrawBan: "Withdraw Ban",
};

export enum TOXIC_CLIENT_RESTRICTIONS_TARGET_TYPE_ENUM {
    ACCOUNT = 1,
    CLIENT = 2,
}

export enum TOXIC_CLIENT_RESTRICTIONS_UI_ELEMENT_TYPE {
    SWITCH = 1,
    FREE_TEXT = 2,
}

export const TOXIC_CLIENT_CHECK_IMPORT_AUDIT_LOG_IMPORT_SOURCE: { [key: number]: string } = {
    1: "Insight UI",
    2: "Insight Excel",
};

export const TOXIC_CLIENT_CHECK_IMPORT_AUDIT_LOG_OVERALL_STATUS: { [key: number]: string } = {
    0: "Failed",
    1: "Success",
    2: "Partial Success",
};

export enum ALARM_DASHBOARD_ITEM_CALLBACK_KEY {
    TASK_ASSIGN = 1,
    LEAVE_COMMENT = 2,
    FORWARD_TO = 3,
    FILTER_CHANGED = 4,
    COMMENT_HISTORICAL = 5,
    REFRESH_LIST = 6,
    SEVERITY_PROGRESS = 7,
}

export enum ALARM_DASHBOARD_ITEM_KEY {
    RESET_CALLBACK = 1,
    SOUND_HANDLER = 2,
    MODULES_FILTER = 3,
    DATE_MODE = 4,
    TIMER_REFRESH = 5,
}

export enum ALARM_HISTORICAL_ITEM_CALLBACK_KEY {
    LEAVE_COMMENT = 1,
    FORWARD_TO = 2,
    FILTER_CHANGED = 3,
    REFRESH_LIST = 4,
    COMMENT_HISTORICAL = 5,
    SEVERITY_PROGRESS = 6,
}

export enum ALARM_HISTORICAL_ITEM_KEY {
    RESET_CALLBACK = 1,
    MODULES_FILTER = 2,
    DATE_ASSIGNEE_FILTER = 3,
}

export enum PRICE_ANALYSIS_ALARM_TAB {
    MONITORWALL = 1,
    HISTORY = 2,
}

export enum STEP_INFO {
    STEP1 = 0,
    STEP2 = 1,
    STEP3 = 2,
    STEP4 = 3,
    STEP5 = 4,
    STEP6 = 5,
}

export const TOXIC_CLIENT_CHECK_BREACH_SEVERITY: { [key: number]: string } = {
    1: "Low",
    2: "Mid",
    3: "High",
};
