import { Table, Typography } from "antd";
import "../../../index.less";
import { ProblematicClientPreviewProps } from "../../../constants/type";
import { DTColProps, currencyRender } from "../../../utils/Common";
const { Text } = Typography;

interface ClientBatchUploadResultProps {
    problematicClientPreviewData: ProblematicClientPreviewProps[];
}

const ClientBatchUploadResult = (props: ClientBatchUploadResultProps) => {
    const problematicClientPreviewDataColumns = [
        DTColProps.Small({
            title: "Server",
            dataIndex: "server",
            key: "server",
        }),
        DTColProps.Small({
            title: "Account ID",
            dataIndex: "accountId",
            key: "accountId",
        }),
        DTColProps.XSmall({
            title: "Brand",
            dataIndex: "brand",
            key: "brand",
        }),
        DTColProps.XSmall({
            title: "Regulator",
            dataIndex: "regulator",
            key: "regulator",
        }),
        DTColProps.XSmall({
            title: "Status",
            dataIndex: "status",
            key: "status",
        }),
        DTColProps.XSmall({
            title: "Block Type",
            dataIndex: "blockType",
            key: "blockType",
        }),
        DTColProps.Large({
            title: "Comment",
            dataIndex: "comment",
            key: "comment",
        }),
        {
            title: "Remarks",
            dataIndex: "remarks",
            key: "remarks",
            render: (remarks: string, rowData: ProblematicClientPreviewProps) => {
                return rowData.isSuccess === false ? <Text type="danger">{remarks}</Text> : <Text>{remarks}</Text>;
            },
        },
    ];

    const getRowClassName = (record: ProblematicClientPreviewProps) => {
        return record.isValid === false || record.isSuccess === false ? "bg-warning" : "";
    };

    return (
        <Table
            rowKey={record => record.serverId + " | " + record.accountId + " | " + record.blockType }
            dataSource={props.problematicClientPreviewData}
            columns={problematicClientPreviewDataColumns}
            rowClassName={getRowClassName}
            scroll={{ y: 450 }}
            size="small"
            pagination={{
                showTotal: (total: number, range: number[]) =>
                    `${currencyRender(range[0])} - ${currencyRender(range[1])} of ${currencyRender(total)}`,
                defaultPageSize: 100,
            }}
        />
    );
};

export default ClientBatchUploadResult;
