import { getRefreshToken, getToken } from "@/services/localStorage";
import { useEffect } from "react";

export interface SSOLocalhostMessagerProps {}

const SSOLocalhostMessager = (props: SSOLocalhostMessagerProps) => {
    useEffect(() => {
        window.onload = () => {
            let param = JSON.stringify({
                token: getToken(),
                refreshToken: getRefreshToken(),
            });

            if (window.opener) {
                window.opener.postMessage(param, "*");
            }
            if (window.parent) {
                window.parent.postMessage(param, "*");
            }
        };
    }, []);
    return <div></div>;
};

export default SSOLocalhostMessager;
