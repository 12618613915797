import { Button, Card, Checkbox, Col, Space } from 'antd'
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { memo, useState } from 'react'

export type SpreadGroupFilterBtn = {
  selectAll: boolean;
  label: string;
} | {
  regex: string;
  matchType: 'endsWith' | 'includes'
  label: string;
}
type SpreadGroupCheckboxProps = {
  title: string;
  options: string[];
  filters: SpreadGroupFilterBtn[]
  onCheckboxChange: (value: string[]) => void;
}

const SpreadGroupCheckbox = memo(({ title, options, filters, onCheckboxChange }: SpreadGroupCheckboxProps) => {
  const [selectedLocalOptions, setSelectedLocalOptions] = useState<CheckboxValueType[]>([]);

  function onLocalCheckboxChange(value: CheckboxValueType[]){
    setSelectedLocalOptions(value);
    onCheckboxChange(value as string[]);
  }

  function onOptionFilter(filter: SpreadGroupFilterBtn){
    if('selectAll' in filter){
      onLocalCheckboxChange(options);
      return;
    }
    if('regex' in filter && 'matchType' in filter){
      const currSelected = [...selectedLocalOptions];
      const { regex, matchType } = filter;
      let newSelected: string[] = [];
      if(matchType === 'endsWith'){
        newSelected = options.filter(x => x.endsWith(regex));
      }
      if(matchType === 'includes'){
        newSelected = options.filter(x => x.includes(regex));
      }
      onLocalCheckboxChange([...new Set([...currSelected, ...newSelected])]);
    }
  }

  function onOptionsClear(){
    onLocalCheckboxChange([]);
  }

  return (
    <Col xs={24} lg={8} flex={"auto"} className='spreadOptions-col'>
      <Card
        title={title}
        extra={
          <Space size={'small'}>
          {
            filters.map((x, index) => (
              <Button key={index} type='primary' onClick={() => onOptionFilter(x)}>
                {x.label}
              </Button>
            ))
          }
            <Button
              onClick={onOptionsClear}
            >
              Clear
            </Button>
          </Space>
        }
      >
        <Checkbox.Group
          options={options}
          value={selectedLocalOptions}
          onChange={(value) => onLocalCheckboxChange(value)}
        />
      </Card>
    </Col>
  )
});
SpreadGroupCheckbox.displayName = 'SpreadGroupCheckbox';

export default SpreadGroupCheckbox
