import { Form, Modal, Row, Col } from "antd";
import { FormComponent } from "../../../../../components/FormComponent";
import { ComponentType } from "../../../../../constants";
import { REQUIRED_FIELD } from "../../../../../constants/errorMessage";
import { useEffect } from "react";

export interface HedgeToolDownloadModalProps {
    isModalVisible: boolean;
    modalTitle: string;
    callback: (type: number, data: any) => void;
}

export enum HedgeToolDownloadModalCallbackKey {
    Close = 0,
    Submit = 1,
}

const HedgeToolDownloadModal = (props: HedgeToolDownloadModalProps) => {
    const [executeForm] = Form.useForm();

    useEffect(() => {
        if (props.isModalVisible) {
            executeForm.setFieldValue("reportDate", "");
            executeForm.resetFields();
        }
    }, [props.isModalVisible]);

    return (
        <Modal
            style={{ top: 10 }}
            width={"30vw"}
            title={props.modalTitle}
            open={props.isModalVisible}
            okText="Submit"
            onOk={() => {
                executeForm
                    .validateFields()
                    .then((values: any) => {
                        props.callback(HedgeToolDownloadModalCallbackKey.Submit, values);
                    })
                    .catch((err: any) => console.log(err));
            }}
            onCancel={() => props.callback(HedgeToolDownloadModalCallbackKey.Close, null)}
        >
            <Form form={executeForm} layout="inline">
                <Row>
                    <Col span={24}>
                        <div style={{ fontSize: "1rem", fontWeight: "bold", marginBottom: "2vh", display: "block", color: "#e21818" }}>
                            <span style={{ display: "block" }}>{"注意!! 若下載今日報表，請至 "}</span>
                            <span style={{ display: "block", paddingLeft: "0.6vw", color: "#000000" }}>
                                {"System Monitor -> Status Monitor -> HedgeReportSource"}
                            </span>
                            <span style={{ display: "block" }}>{"確認資料源狀況。"}</span>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Report Date"
                            name="reportDate"
                            extra={{
                                type: ComponentType.date,
                                value: "",
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                                dateFormat: "YYYY-MM-DD",
                            }}
                        />
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default HedgeToolDownloadModal;
