import * as signalR from "@microsoft/signalr";
import { getToken } from "../services/localStorage";

class SignalRHelper {
    private _key: string;
    private _url: string;
    private _retryRate: number;
    private _onMessage: any;
    private _connection: signalR.HubConnection | null = null;
    private _connectedStatus: boolean = false;
    private _disableRetry: boolean = false;

    constructor(key: string, url: string, retryRate: number = 10, handler: any = () => {}) {
        this._key = key;
        this._url = `${process.env.REACT_APP_API_URL}${url}`;
        this._retryRate = retryRate;
        this._onMessage = handler;
        this._initialConnection();
    }

    private _getUrl = () =>
        `${this._url}${this._url.indexOf("?") === -1 ? `?` : this._url.indexOf("?") !== this._url.length - 1 ? "&" : ""}access_token=${getToken()}`;

    private _initialConnection = () => {
        this._connection = new signalR.HubConnectionBuilder()
            .withUrl(this._getUrl(), {
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets,
            })
            .configureLogging(signalR.LogLevel.None)
            .withAutomaticReconnect()
            .build();

        this._connection.onclose(() => {
            this.printMessage("Disconnected");
            this._connectedStatus = false;
            this._processRetry();
        });

        this._connection.onreconnected(() => {
            this.printMessage("Reconnected");
            this._connectedStatus = true;
        });

        this._connection.onreconnecting(() => {
            this.printMessage("Reconnecting");
            this._connectedStatus = false;
        });
    };

    private _startConnection = () => {
        this._connectedStatus = false;

        try {
            return (this._connection as signalR.HubConnection).start().then(() => {
                this.printMessage("Connected");
                this._connectedStatus = true;
                return Promise.resolve();
            });
        } catch (error) {
            this.printMessage(`Error: (${error})`);
            return Promise.reject();
        }
    };

    private _processRetry = () => {
        if (!this._disableRetry) {
            this.printMessage(`Disconnected: Trigger retry after ${this._retryRate} seconds`);
            this._initialConnection();
            setTimeout(() => this.startConnection(), this._retryRate * 1000);
        }
    };

    startConnection = () => {
        this._disableRetry = false;
        this.getConnector().then(this._onMessage);
    };

    getConnector = () => {
        if (this._connection === null) this._initialConnection();

        if ((this._connection as signalR.HubConnection).state === "Disconnected" || (this._connection as signalR.HubConnection).state === undefined)
            return this._startConnection().then(() => {
                return this._connection;
            });

        return Promise.resolve(this._connection);
    };

    getConnectionStatus = () => this._connectedStatus;

    setHandler = (handler: any) => (this._onMessage = handler);

    dispose = () => {
        try {
            this._disableRetry = true;
            return (this._connection as signalR.HubConnection).stop().then(() => {
                this._connectedStatus = false;

                return Promise.resolve();
            });
        } catch (error) {
            this.printMessage(`Error: (${error})`);
            return Promise.reject();
        }
    };

    printMessage = (message: string) => {
        console.log(`SignalR [Key: ${this._key}] => ${message}`);
    };
}

export default SignalRHelper;
