import FlexiDataTable from "@/components/FlexiDataTable";
import { CALLBACK_KEY } from "@/constants";
import { CustomPaginationProps, FlexiDataTableCallbackProps } from "@/constants/type";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { currencyRender, DTColProps } from "@/utils/Common";
import { useCallback, useEffect, useState } from "react";

interface CADividendSeparateProcessGroupDataProps {
    currentTab: string;
    filterParam: any;
    runRefetchDataList: number;
}

const CADividendSeparateProcessGroupData = (props: CADividendSeparateProcessGroupDataProps) => {
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<any[]>([]);
    const [pagination, setPagination] = useState<CustomPaginationProps>({
        current: 1,
        pageSize: 10,
        total: 0,
    });

    const columns = [
        {
            title: "Symbol",
            dataIndex: "cleanSymbol",
            key: "cleanSymbol",
        },
        DTColProps.Currency({
            title: "Net Position",
            dataIndex: "netPosition",
            key: "netPosition",
            render: (text: number) => currencyRender(text.toFixed(2)),
        }),
        DTColProps.Currency({
            title: "Long",
            dataIndex: "swapLong",
            key: "swapLong",
            render: (text: number) => currencyRender(text.toFixed(2)),
        }),
        DTColProps.Currency({
            title: "Short",
            dataIndex: "swapShort",
            key: "swapShort",
            render: (text: number) => currencyRender(text.toFixed(2)),
        }),
        DTColProps.Currency({
            title: "Gross Position",
            dataIndex: "grossPosition",
            key: "grossPosition",
            render: (text: number) => currencyRender(text.toFixed(2)),
        }),
        DTColProps.Small({
            title: "Ex-date",
            dataIndex: "exDate",
            key: "exDate",
        }),
    ];

    const componentCallback: FlexiDataTableCallbackProps = (type, FormData) => {
        switch (type) {
            case CALLBACK_KEY.HANDLE_PAGINATION_SORTING:
                setPagination(prev => ({ ...prev, current: FormData.pagination.current, pageSize: FormData.pagination.pageSize }));
                setRunRefetchDataList(true);
                break;
            default:
                break;
        }
    };

    const getDataList = useCallback(() => {
        plainAxiosInstance
            .post(APIs.RISK_TOOL.GET_CA_DIVIDEND_SEPARATE_PROCESS_GROUP_DATA_LIST, {
                page: pagination.current,
                rows: pagination.pageSize,
                sortBy: [],
                sortDesc: [],
                ...props.filterParam,
            })
            .then((res: any) => {
                if (res.data.resultList.length > 0) {
                    setData(res.data.resultList);
                    setPagination(prev => ({ ...prev, total: res.data.total }));
                } else {
                    setData([]);
                    setPagination(prev => ({ ...prev, total: 0 }));
                }
            })
            .finally(() => setIsLoading(false));
    }, [props.filterParam, pagination]);

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            getDataList();
            setRunRefetchDataList(false);
        }
    }, [runRefetchDataList]);

    useEffect(() => {
        setRunRefetchDataList(true);
    }, [props.runRefetchDataList]);

    return (
        <div style={{ display: props.currentTab === "Group Data" ? "block" : "none" }}>
            <FlexiDataTable
                rowKeyProperty="id"
                title=""
                columns={columns}
                options={{ serverFiltering: true, enableFilter: false }}
                dataSource={data}
                callback={componentCallback}
                loading={isLoading}
                pagination={pagination}
                serverSide={true}
            />
        </div>
    );
};

export default CADividendSeparateProcessGroupData;
