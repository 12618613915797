import { Button, Space, Tag, Tooltip, Typography } from "antd";
import "../../../index.less";
import { CrmProblematicStatus, FlexiDataTableCallbackProps, ProblematicClient, ProblematicClientBlockTypeTeam } from "../../../constants/type";
import { DTColProps, DataTableColumnRender } from "../../../utils/Common";
import { CALLBACK_KEY, ComponentType } from "../../../constants";
import FlexiDataTable from "../../../components/FlexiDataTable";
import { getProfile } from "../../../services/localStorage";
import { isEmptyOrNull } from "../../../utils/string";
import { HistoryOutlined } from "@ant-design/icons";

interface TeamExpandableTableProps {
    record: ProblematicClient;
    enableUpdate: boolean;
    initCrmProblematicStatus: CrmProblematicStatus[];
    doEdit: Function;
    getAuditLog: Function;
}

interface ExpandableTeamProps extends ProblematicClientBlockTypeTeam {
    crmStatus: string;
    crmLastOperator: string;
    crmProceedNote: string;
    clientId: number;
    crmServerId: number;
    regulatorId: number;
}

const TeamExpandableTable = (props: TeamExpandableTableProps) => {
    const uPr: any = getProfile();

    const expandedComponentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.DO_EDIT:
                props.doEdit({ teamId: FormData.teamId, status: FormData.status === 5 ? FormData.status : null, comment: "", editCommentOnly: false, blockType: FormData.blockType });
                break;
            case CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK:
                if (FormData.key === 1) {
                    props.doEdit({
                        teamId: FormData.data.teamId,
                        status: FormData.data.status,
                        comment: FormData.data.comment,
                        editCommentOnly: true,
                    });
                }
                if (FormData.key === 2) {
                    props.getAuditLog({
                        blockType: FormData.data.blockType,
                        clientId: FormData.data.clientId,
                        crmServerId: FormData.data.crmServerId,
                        regulatorId: FormData.data.regulatorId,
                    });
                }
                break;
            default:
                break;
        }
    };

    const allBlockTypeTeams: ExpandableTeamProps[] = props.record.blockTypeTeams.flatMap(blockType => {
        const { crmStatus, teams, crmLastOperator, crmProceedNote } = blockType;
        return teams.map(team => ({
            ...team,
            crmStatus: crmStatus,
            crmLastOperator: crmLastOperator,
            crmProceedNote: crmProceedNote,
            clientId: props.record.clientId,
            crmServerId: props.record.crmServerId,
            regulatorId: props.record.regulatorId,
        }));
    });

    const columns = [
        DTColProps.XSmall({
            title: "Team",
            dataIndex: "team",
            key: "team",
            render: (team: string, dt: any) => <Tag className={`prb-team`}>{team}</Tag>,
        }),

        DTColProps.XSmall(
            {
                title: "CRM Block Type",
                dataIndex: "blockType",
                key: "blockType",
                render: (blockType: string, dt: any) => {
                    return dt.crmStatus.toUpperCase() === "BLOCKED" ? (
                        <Tag className={`prb-blockTypeColor prb-blockColor-active`}>{blockType}</Tag>
                    ) : (
                        <Tag className={`prb-blockTypeColor prb-blockColor-inactive`}>{blockType}</Tag>
                    );
                },
            },
            ["text-center"]
        ),

        DTColProps.Middle(
            {
                title: "Status",
                dataIndex: "statusType",
                key: "statusType",
                render: (statusType: string, dt: any) => <Tag className={`prb-statusTag prb-sts-${dt.status}`}>{statusType}</Tag>,
            },
            ["text-center"]
        ),
        {
            title: "Comment",
            dataIndex: "comment",
            key: "comment",
            render: (comment: any) =>
                comment && comment.length > 0 ? (
                    <Tooltip title={comment}>
                        <Typography.Paragraph ellipsis={{ rows: 2 }} style={{ marginBottom: "0" }}>
                            {comment}
                        </Typography.Paragraph>
                    </Tooltip>
                ) : (
                    "-"
                ),
        },
        DTColProps.Large({
            title: "CRM Notes",
            dataIndex: "crmnotes",
            key: "crmnotes",
            render: (crmnotes: any, dt: ExpandableTeamProps) => {
                return (
                    <div className="problematic-client-blockType-container">
                        {dt.crmLastOperator ? <div className="sm-title">{dt.crmLastOperator}</div> : null}
                        {dt.crmProceedNote ? (
                            <Tooltip title={dt.crmProceedNote}>
                                <div className="sm-desc">{dt.crmProceedNote}</div>
                            </Tooltip>
                        ) : null}
                    </div>
                );
            },
        }),
        DTColProps.DateTime({
            width: "16vw",
            title: "Last Modified (Local)",
            dataIndex: "modifiedDateUtc",
            key: "modifiedDateUtc",
            options: {
                filter: {
                    type: ComponentType.daterange,
                    value: "",
                    inputProps: {
                        showTime: true,
                    },
                },
            },
            render: (modifiedDateUtc: any, dt: any) => (
                <div className="modified-panel">
                    <div className="modified">
                        <div>{DataTableColumnRender.DateTime(dt.modifiedDateUtc)}</div>
                        <div className="modifiedByName">{dt.modifiedByName}</div>
                    </div>
                </div>
            ),
        }),
    ];

    return (
        <div className="problematic-team-expandable-table">
            <FlexiDataTable
                rowKeyProperty="teamId"
                title={false}
                columns={columns}
                options={{
                    enableFilter: false,
                    showHideColumns: false,
                    edit: (record: any, option: any) => (record.status === 5 ? false : option),
                    ...(props.enableUpdate && {
                        rowExtra: (record: any) => (record.status === 5 ? [{ text: "Edit Comment", value: 1 }] : []),
                    }),
                    ...(props.enableUpdate && {
                        customExtraActionButton: [
                            {
                                text: "",
                                value: 2,
                                icon: <HistoryOutlined />,
                            },
                        ],
                    }),
                }}
                dataSource={allBlockTypeTeams}
                callback={expandedComponentCallback}
                loading={false}
                pagination={false}
                scroll={{ y: 280 }}
            />
        </div>
    );
};

export default TeamExpandableTable;
