import React from "react";
import { ServerStatusProps } from "./ServerStatus";
import { Badge, Button, Space } from "antd";
import useRCStatusMonitorStore from "../../../store/useRCStatusMonitorStore";
import { colorMap } from "./statusUtils";

const ServerStatusVendor = ({ data, vendorType }: { data: ServerStatusProps[]; vendorType: "admin" | "ra" | "daily" | "weekend" }) => {
    return (
        <Space size={4} wrap style={{ padding: "1rem" }}>
            {data?.map((item: any, index: number) => (
                <VendorButtons key={index} data={item as ServerStatusProps} vendorType={vendorType} />
            ))}
        </Space>
    );
};

const VendorButtons = ({ data, vendorType }: { data: ServerStatusProps; vendorType: "admin" | "ra" | "daily" | "weekend" }) => {
    const vendor = useRCStatusMonitorStore(state => state.vendor[vendorType]);
    const updateVendor = useRCStatusMonitorStore(state => state.updateVendor);

    const isActive = data.vendor === vendor;
    const handleChangeVendor = (activeKey: string) => {
        updateVendor({ [vendorType]: activeKey });
    };
    return (
        <Button onClick={() => handleChangeVendor(data.vendor)} type={isActive ? "primary" : "default"}>
            <Space size={4}>
                <span style={{ display: "inline-block" }}>{data.vendor}</span>
                <Badge count={data.errorCount} style={{ zIndex: 2 }} overflowCount={9999} />
                {data.warningCount >= 1 && <Badge color={colorMap.warning} count={data.warningCount} style={{ zIndex: 2 }} />}
            </Space>
        </Button>
    );
};

export default ServerStatusVendor;
