import { useState } from "react";
import { DatePicker, Space, Row, Col, Typography, Button, Modal } from "antd";
import moment from "moment";
import { APIs } from "../../../../services/apis";
import { plainAxiosInstance } from "../../../../services/axiosSetup";
import { ErrorCatchValidator, ErrorMessageHandler } from "../../../../utils/Common";
import { SUCCESS_FAILED } from "../../../../constants";

type DownloadModalProps = {
    open: boolean;
    onClose: () => void;
};

const DownloadModal = ({ open, onClose}: DownloadModalProps) => {
    const { RangePicker } = DatePicker;
    const { Text } = Typography;
    const [selectedDateString, setSelectedDateString] = useState<[startDate:string, endDate: string]>(['',''])
    const [selectedDateVal, setSelectedDateVal] = useState<any[]>([])
    const [downloading, setDownloading] = useState<boolean>(false)

    function handleDownload(){
      setDownloading(true)
      plainAxiosInstance
        .get(`${process.env.REACT_APP_API_URL}${APIs.RC_READ_ONLY.POST_DOWNLOAD_READ_ONLY}?startTime=${selectedDateString[0]}&endTime=${selectedDateString[1]}`,
          {
            responseType: "blob",
          }
        )
        .then(res=>{
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          const dateRange = selectedDateString.map(d=> d.split(' ').join('_')).join('-')
          link.setAttribute("download", `read_only_${dateRange}.xlsx`);
          document.body.appendChild(link);
          link.click();
          // Clean up
          window.URL.revokeObjectURL(url);
          link.parentNode?.removeChild(link);
          ErrorMessageHandler("ReadOnly", SUCCESS_FAILED.SUCCESS_DOWNLOAD_DATA)
          setSelectedDateString(['',''])
          setSelectedDateVal([])
          onClose();
        })
        .catch(err=>{
          ErrorCatchValidator(err, (error: any) => ErrorMessageHandler("ReadOnly", SUCCESS_FAILED.FAILED_DOWNLOAD_DATA, error))
        })
        .finally(()=>{
          setDownloading(false)
        })
    }

    return (
        <Modal
            title="Download ReadOnly"
            open={open}
            onOk={() => {}}
            footer={null}
            onCancel={onClose}
        >
            <Space style={{ width: "100%" }} direction="vertical" size="large">
                <Row gutter={[8, 8]}>
                    <Col span={16} xs={{ span: 24 }} lg={{ span: 16 }}>
                        <Text>Date Range *</Text>
                        <RangePicker
                            format={"YYYY-MM-DD HH:mm"}
                            showTime
                            style={{ width: "100%" }}
                            onChange={(dates, dateStrings) => {
                                setSelectedDateString([dateStrings[0], dateStrings[1]])
                                setSelectedDateVal(dates ?? [])
                            }}
                            // disable selecting the day after today, and 20 days before today
                            disabledDate={current => {
                                return current && current > moment().endOf("day");
                            }}
                            // read start and end from store
                            value={[selectedDateVal[0], selectedDateVal[1]]}
                        />
                    </Col>
                </Row>
                <Space style={{ marginTop: "1rem", display: "flex", flexWrap: "wrap" }}>
                    <Button
                        type="primary"
                        onClick={handleDownload}
                        disabled={selectedDateString[0] === '' || selectedDateString[1] === '' || downloading}
                        loading={downloading}
                        style={{ minWidth: 100 }}
                    >
                        Download
                    </Button>
                </Space>
            </Space>
        </Modal>
    );
};

export default DownloadModal;
