import { Tabs } from "antd";
import SpreadAlarmSettingPage from "./SpreadAlarmSetting";
import PriceAlarmColorSettingPage from "./PriceAlarmColorSetting";
import MidPriceSetting from "./MidPriceSetting";
import PriceAlarmSetting from "./PriceAlarmSetting";
import PriceAnalysisSettingPage from "./PriceAnalysisSetting";
import TabRestartServer from "../PriceMonitor/MidBias/TabRestartServer";

export interface PriceSettingPageProps {}

const PriceSettingPage = (props: PriceSettingPageProps) => {
    const tabs: any[] = [
        {
            label: "Spread Alarm Setting",
            key: "1",
            children: <SpreadAlarmSettingPage />,
        },
        {
            label: "Price Alarm Color Setting",
            key: "2",
            children: <PriceAlarmColorSettingPage />,
        },
        {
            label: "Price Alarm Setting",
            key: "3",
            children: <PriceAlarmSetting />,
        },
        {
            label: "Mid Price Setting",
            key: "4",
            children: <MidPriceSetting />,
        },
        {
            label: "Price Analysis Setting",
            key: "5",
            children: <PriceAnalysisSettingPage />,
        },
        {
            label: "Price Backend Opt",
            key: "6",
            children: <TabRestartServer />,
        },
    ];

    return (
        <div className="price-settings-page-container">
            <Tabs destroyInactiveTabPane type="card" defaultActiveKey="1" items={tabs} tabBarStyle={{ marginBottom: "0" }} />
        </div>
    );
};

export default PriceSettingPage;
