import { PlusOutlined, CloseOutlined } from "@ant-design/icons";
import { Badge, Button, Col, Form, FormInstance, Row } from "antd";
import EmptyData from "../../../../components/Common/Empty";
import { FormComponent } from "../../../../components/FormComponent";
import { ComponentType, LP_BREACH_SEVERITY } from "../../../../constants";
import { REQUIRED_FIELD } from "../../../../constants/errorMessage";
import { ToOptionTypeList, GetObjectCountByKey } from "../../../../utils/array";
import LPOrConditionGroupPanel from "./LPOrConditionGroupPanel";
import { LPMetricsBased } from "../../../../constants/type";

export interface LPMetricFilterPanelBasedProps {
    form: FormInstance;
    metrics: LPMetricsBased[];
    componentId: (string | number)[];
}

export interface LPMetricFilterPanelProps extends LPMetricFilterPanelBasedProps {}

const LPMetricFilterPanel = (props: LPMetricFilterPanelProps) => {
    const fieldName = "MetricFilters";
    const breachSeverityOptions = ToOptionTypeList(LP_BREACH_SEVERITY).filter(x => x.value !== 0);
    return (
        <>
            <div className="filter-panel">
                <Form.List name={fieldName}>
                    {(fields, { add, remove }) => (
                        <>
                            <div className="title-panel">
                                <div className="left">
                                    <span>Metric Filter Rule(s):</span>
                                </div>
                                <div className="right">
                                    <Button
                                        type="dashed"
                                        icon={<PlusOutlined style={{ color: "#7e7e7e" }} />}
                                        onClick={() => {
                                            add({
                                                BreachSeverity: "",
                                                MetricOuterFilters: [{ MetricInnerFilters: [{ MetricName: "", FilterOp: "", FilterValue: "" }] }],
                                            });
                                        }}
                                    >
                                        Add Filter Rule
                                    </Button>
                                </div>
                            </div>
                            <div className="content">
                                {fields.length === 0 ? (
                                    <EmptyData />
                                ) : (
                                    fields.map((field, index) => {
                                        return (
                                            <Badge.Ribbon key={`mf-fg-${index}`} text={`Filter Rule ${index + 1}`} placement="start">
                                                <div className="filter-group-panel shadow-light">
                                                    <div className="btn-group">
                                                        <Button
                                                            type="text"
                                                            size="small"
                                                            icon={<CloseOutlined />}
                                                            className="btn-delete"
                                                            onClick={() => remove(field.name)}
                                                        />
                                                    </div>
                                                    <Row className="level-group">
                                                        <Col span={24}>
                                                            <FormComponent
                                                                label="Breach Severity"
                                                                name={[field.name, "BreachSeverity"]}
                                                                extra={{
                                                                    type: ComponentType.dropdown,
                                                                    value: breachSeverityOptions,
                                                                    rules: [
                                                                        { required: true, message: REQUIRED_FIELD },
                                                                        {
                                                                            validator: (_: any, value: any) => {
                                                                                let countArr = GetObjectCountByKey(
                                                                                    props.form.getFieldValue("MetricFilters") || [],
                                                                                    "BreachSeverity"
                                                                                );

                                                                                if (
                                                                                    Object.keys(countArr).some(
                                                                                        x => `${x}` === `${value}` && countArr[x] > 1
                                                                                    )
                                                                                )
                                                                                    return Promise.reject(
                                                                                        `There are multiple same "Breach Severity" detected`
                                                                                    );
                                                                                return Promise.resolve();
                                                                            },
                                                                        },
                                                                    ],
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col span={24}>
                                                            <LPOrConditionGroupPanel
                                                                form={props.form}
                                                                tkey={field.name}
                                                                metrics={props.metrics}
                                                                componentId={[...props.componentId, ...[fieldName, field.name]]}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Badge.Ribbon>
                                        );
                                    })
                                )}
                            </div>
                        </>
                    )}
                </Form.List>
            </div>
        </>
    );
};

export default LPMetricFilterPanel;
