import React from "react";
import useRCPriceOutage, { useRCPriceOutageHistory } from "../../../hooks/useRCPriceOutage";
import useRCPriceOutageStore from "../../../store/useRCPriceOutageStore";
import { notification } from "antd";

const PriceOutageDataFetcher = () => {
    const { rcPriceOutage, rcPriceOutageError } = useRCPriceOutage({ type: "prod" });
    const { rcPriceOutage: rcPriceOutageNonProd, rcPriceOutageError: rcPriceOutageErrorOthers } = useRCPriceOutage({ type: "others" });

    const setHistoryRawDataProd = useRCPriceOutageStore(state => state.setHistoryRawDataProd);
    const setHistoryRawDataNonProd = useRCPriceOutageStore(state => state.setHistoryRawDataNonProd);
    const setCurrentRawDataProd = useRCPriceOutageStore(state => state.setCurrentRawDataProd);
    const setCurrentRawDataNonProd = useRCPriceOutageStore(state => state.setCurrentRawDataNonProd);

    const startDate = new Date();

    // make it 1 hour before gmt+3 timezone
    startDate.setHours(startDate.getHours() + 2);

    // convert startDate to "yyyy-MM-dd HH:mm:ss" string
    const startDateString = startDate.toISOString().slice(0, 19).replace("T", " ");

    const endDate = new Date();
    // make it current time of gmt+3 timezone
    endDate.setHours(endDate.getHours() + 3);
    // extra 5 minutes
    endDate.setMinutes(endDate.getMinutes() + 5);
    // convert endDate to "yyyy-MM-dd HH:mm:ss" string
    const endDateString = endDate.toISOString().slice(0, 19).replace("T", " ");

    const { rcPriceOutageHistory, rcPriceOutageHistoryError } = useRCPriceOutageHistory({ startDate: startDateString, endDate: endDateString });
    const { rcPriceOutageHistory: rcPriceOutageHistoryOthers, rcPriceOutageHistoryError: rcPriceOutageHistoryErrorOthers } = useRCPriceOutageHistory({
        startDate: startDateString,
        endDate: endDateString,
        isProd: false,
    });

    if (rcPriceOutageError || rcPriceOutageErrorOthers || rcPriceOutageHistoryError || rcPriceOutageHistoryErrorOthers) {
        // set unique id for notification, only prompt one
        // const key = `rcPriceOutageError-${Date.now()}`;
        notification.error({
            message: "Error fetching price outage data",
            description: rcPriceOutageError || rcPriceOutageErrorOthers || rcPriceOutageHistoryError || rcPriceOutageHistoryErrorOthers,
            key: "rcPriceOutageError",
            duration: 5,
        });
    }

    const addData = useRCPriceOutageStore(state => state.addData);
    const addDataNonProd = useRCPriceOutageStore(state => state.addDataNonProd);

    React.useEffect(() => {
        let isSub = true;
        if (isSub) {
            if (rcPriceOutage) {
                addData(rcPriceOutage, true);
                setCurrentRawDataProd(rcPriceOutage);
            }
        }
        return () => {
            isSub = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rcPriceOutage]);

    React.useEffect(() => {
        let isSub = true;
        if (isSub) {
            if (rcPriceOutageNonProd) {
                addDataNonProd(rcPriceOutageNonProd, true);
                setCurrentRawDataNonProd(rcPriceOutageNonProd);
            }
        }
        return () => {
            isSub = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rcPriceOutageNonProd]);

    React.useEffect(() => {
        let isSub = true;
        if (isSub) {
            if (rcPriceOutageHistory) {
                // @ts-ignore
                addData(rcPriceOutageHistory);
                setHistoryRawDataProd(rcPriceOutageHistory);
            }
        }
        return () => {
            isSub = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rcPriceOutageHistory]);
    React.useEffect(() => {
        let isSub = true;
        if (isSub) {
            if (rcPriceOutageHistoryOthers) {
                // @ts-ignore
                addDataNonProd(rcPriceOutageHistoryOthers);
                setHistoryRawDataNonProd(rcPriceOutageHistoryOthers);
            }
        }
        return () => {
            isSub = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rcPriceOutageHistoryOthers]);

    return null;
};

export default PriceOutageDataFetcher;
