import { Select } from "antd";
import React from "react";
import useRCServers from "../../../hooks/useRCServers";
import useRCServerPerformanceStore from "../../../store/useRCServerPerformanceStore";
const IgnoredServersButton = () => {
    const { rcServers, isLoading } = useRCServers();
    const options = rcServers?.map((server: any) => {
        const label = server.comment ? `${server.serverName} (${server.comment})` : server.serverName;
        return {
            label,
            value: server.serverName,
        };
    });
    const setIgnoredServers = useRCServerPerformanceStore(state => state.setIgnoredServers);
    const ignoredServers = useRCServerPerformanceStore(state => state.ignoredServers);

    const handleChangeIgnoredServers = (value: string[]) => {
        setIgnoredServers(value);
    };
    return (
        <Select
            allowClear
            style={{ width: "100%" }}
            placeholder="Select Symbol"
            value={ignoredServers}
            onChange={handleChangeIgnoredServers}
            options={options}
            mode="multiple"
            loading={!rcServers && isLoading}
            // allow type to search
            showSearch
        />
    );
};

export default IgnoredServersButton;
