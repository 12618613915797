import { Modal } from "antd";
import { CADividendMapUploadModalCallbackKey } from "../../CADividendMapUpload/components/CADividendMapUploadModal";
import FlexiDataTable from "@/components/FlexiDataTable";
import { useCallback, useEffect, useMemo, useState } from "react";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { APIs } from "@/services/apis";
import { DefaultIfEmpty, objectToQueryString } from "@/utils/object";
import { currencyRender, DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { SUCCESS_FAILED } from "@/constants";
import { WeekendSwapsRecordItemProps } from "..";

export interface WeekendSwapsRecordTradeModalProps {
    isModalVisible: boolean;
    filterParams: WeekendSwapsRecordItemProps | undefined;
    callback: (type: number, data: any) => void;
}

export enum WeekendSwapsRecordTradeModalCallbackKey {
    Close = 0,
}

interface WeekendSwapsRecordTradeProps {
    cleanSymbol: string;
    closePrice: number;
    cmd: number;
    contractSize: number;
    createDate: string;
    eodPrice: number;
    formatCreateDate: null | string;
    group: null | string;
    login: number;
    pointSize: number;
    receiveDate: string;
    server: string;
    serverId: number;
    status: number;
    swap: number;
    swapPoint: number;
    swapType: string;
    symbol: string;
    ticket: number;
    updateDate: null | string;
    volume: number;
}

interface BasicProps {
    title: string;
    isBTC: boolean;
}

const WeekendSwapsRecordTradeModal = (props: WeekendSwapsRecordTradeModalProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<WeekendSwapsRecordTradeProps[]>([]);
    const basicProps: BasicProps = useMemo(() => {
        if (props.filterParams === undefined) return { title: "PNL Weekend Swap Trades", isBTC: false };

        return {
            title: `${DefaultIfEmpty(props.filterParams, "server", "")}-${DefaultIfEmpty(props.filterParams, "receiveDate", "")}-${DefaultIfEmpty(
                props.filterParams,
                "login",
                ""
            )} PNL Weekend Swap Trades`,
            isBTC: `${DefaultIfEmpty(props.filterParams, "group", "")}`.endsWith("_BTC"),
        };
    }, [props.filterParams]);

    const columns = useMemo(
        () => [
            {
                title: "Ticket",
                dataIndex: "ticket",
                key: "ticket",
            },
            {
                title: "Symbol",
                dataIndex: "symbol",
                key: "symbol",
            },
            {
                title: "Clean Symbol",
                dataIndex: "cleanSymbol",
                key: "cleanSymbol",
            },
            DTColProps.XSCurrency({
                title: "Lots",
                dataIndex: "volume",
                key: "volume",
            }),
            DTColProps.XXSmall(
                {
                    width: "4.555vw",
                    title: "Side",
                    dataIndex: "cmd",
                    key: "cmd",
                    render: (text: number) => (text === 0 ? "Buy" : "Sell"),
                },
                ["text-center"]
            ),
            DTColProps.XSCurrency({
                width: "6vw",
                title: "Eod Price",
                dataIndex: "eodPrice",
                key: "eodPrice",
                render: (text: number) => currencyRender(text.toFixed(5)),
            }),
            DTColProps.XSmall(
                {
                    title: "Contract Size",
                    dataIndex: "contractSize",
                    key: "contractSize",
                },
                ["text-right"]
            ),
            DTColProps.XSCurrency({
                width: "6vw",
                title: "Point Size",
                dataIndex: "pointSize",
                key: "pointSize",
                render: (text: number) => currencyRender(text.toFixed(5)),
            }),
            DTColProps.XSCurrency({
                width: "6vw",
                title: "Base Swap",
                dataIndex: "swapPoint",
                key: "swapPoint",
            }),
            DTColProps.SCurrency({
                title: "Close Price",
                dataIndex: "closePrice",
                key: "closePrice",
                render: (text: number) => currencyRender(text.toFixed(5)),
            }),
            DTColProps.SCurrency({
                title: "Swap",
                dataIndex: "swap",
                key: "swap",
                render: (text: any) => currencyRender(parseFloat(`${text}`).toFixed(basicProps.isBTC ? 8 : 2)),
            }),
        ],
        [basicProps]
    );

    const getTradeList = useCallback(() => {
        plainAxiosInstance
            .get(
                `${APIs.RISK_TOOL.GET_WEEKEND_SWAPS_RECORDS_TRADE_LIST}?${objectToQueryString(props.filterParams, [
                    "serverId",
                    "receiveDate",
                    "login",
                ])}`
            )
            .then(response =>
                setData(
                    response.data.length > 0
                        ? response.data.map((x: WeekendSwapsRecordTradeProps) => ({
                              ...x,
                              id: `${x.serverId}-${x.receiveDate}-${x.login}-${x.ticket}`,
                          }))
                        : []
                )
            )
            .catch(error => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("trade record", SUCCESS_FAILED.FAILED_LOAD_DATA, err)))
            .finally(() => setIsLoading(false));
    }, [props.filterParams]);

    useEffect(() => {
        if (props.isModalVisible) {
            setIsLoading(true);
            getTradeList();
        }
    }, [props.isModalVisible]);

    return (
        <Modal
            style={{ top: 10 }}
            width={"80vw"}
            title={basicProps.title}
            open={props.isModalVisible}
            okButtonProps={{ style: { display: "none" } }}
            cancelText="Close"
            onCancel={() => props.callback(CADividendMapUploadModalCallbackKey.Close, null)}
            maskClosable={false}
        >
            <FlexiDataTable
                bordered
                rowKeyProperty="id"
                title={false}
                columns={columns}
                dataSource={data}
                options={{ enableFilter: false, showHideColumns: false }}
                pagination={{
                    hideOnSinglePage: true,
                }}
                loading={isLoading}
            />
        </Modal>
    );
};

export default WeekendSwapsRecordTradeModal;
