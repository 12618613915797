import { DownloadOutlined } from "@ant-design/icons";
import { DatePicker, Form, Typography, notification, Modal, Row, Col } from "antd";

import moment from "moment";
import React from "react";
import { APIs } from "../../../services/apis";
import { plainAxiosInstance } from "../../../services/axiosSetup";
import { useRCDownloadsMutation } from "../../../hooks/useRCDownloads";
const GenerateReportModal = ({ open, handleModalOpen }: { open: boolean; handleModalOpen: (open: boolean) => void }) => {
    const { RangePicker } = DatePicker;
    const [form] = Form.useForm();
    const { Item } = Form;
    const { Text } = Typography;

    const { mutateGenerateReport, isLoadingGenerateReport, errorGenerateReport } = useRCDownloadsMutation();

    const initFormValue = {
        range: null,
    };

    const handleSubmit = async (values: any) => {
        const startDate = moment(values.range[0]).format("YYYY-MM-DD");
        const endDate = moment(values.range[1]).format("YYYY-MM-DD");
        try {
            const resp = await mutateGenerateReport({ startDate, endDate });
            if (resp) {
                notification.success({
                    message: "Success",
                    description: `Preparing your report from ${startDate} to ${endDate}, please come back later`,
                });
                handleModalOpen(false);
            }
        } catch (error) {
            notification.error({
                message: "Error",
                description: `Error while preparing your report: ${error}`,
            });
            console.log("Error while preparing your report", error);
        }
    };

    return (
        <Form
            form={form}
            layout="vertical"
            initialValues={initFormValue}
            /* onValuesChange={handleFormUpdate} */ requiredMark={true}
            onFinish={handleSubmit}
        >
            <Modal
                title="Generate Price Alarm Report"
                okText={"Generate Report"}
                okButtonProps={{
                    icon: <DownloadOutlined />,
                    loading: isLoadingGenerateReport,
                    disabled: isLoadingGenerateReport,
                }}
                open={open}
                onOk={() => form.submit()}
                onCancel={() => handleModalOpen(false)}
            >
                <Row gutter={[8, 8]}>
                    <Col xs={{ span: 24 }} md={{ span: 24 }}>
                        <Text>Date Range *</Text>
                        <Item
                            style={{ margin: 0, padding: 0 }}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            required
                            name="range"
                            rules={[{ required: true, message: "Please select date range" }]}
                        >
                            <RangePicker
                                style={{ width: "100%" }}
                                // disable selecting the day after today, and 20 days before today
                                disabledDate={current => {
                                    return current && (current > moment().endOf("day") || current < moment().subtract(20, "days").startOf("day"));
                                }}
                                onChange={data => {
                                    form.setFieldsValue({ range: data });
                                }}
                            />
                        </Item>
                    </Col>
                </Row>
            </Modal>
        </Form>
    );
};

export default GenerateReportModal;
