import BasicFilter from "@/components/BasicFilter";
import { useRCDividendRecordMutation, useRCDividendUploads } from "@/hooks/useRCDividendRecord";
import MessageCard from "@/pages/SystemMonitor/components/MessageCard";
import { colorMap } from "@/pages/SystemMonitor/StatusMonitor/statusUtils";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Input, Modal, notification, Row, Space, Switch, Table, Typography, Upload } from "antd";
import moment from "moment";
import React, { useState } from "react";

const TabMap = () => {
    // get todayDate in format of "YYYYMMDD"
    const convertDateToYYYYMMDD = (date: Date) => {
        return moment(date).format("YYYYMMDD");
    };
    const todayDate = convertDateToYYYYMMDD(new Date());

    const [uploadDate, setUploadDate] = useState(todayDate);
    const [symbol, setSymbol] = useState("");
    const [isDefault, setIsDefault] = useState(false);
    const { rcDividendUploads } = useRCDividendUploads({ date: isDefault ? "Default" : uploadDate, symbol: symbol });
    const columns = [
        {
            title: "Upload Date",
            dataIndex: "uploadDate",
            key: "uploadDate",
        },
        {
            title: "Clean Symbol",
            dataIndex: "cleanSymbol",
            key: "cleanSymbol",
        },
        {
            title: "Base Dividend",
            dataIndex: "baseDividend",
            key: "baseDividend",
        },
    ];
    const { Text } = Typography;
    const [openUploadModal, setOpenUploadModal] = useState(false);

    return (
        <>
            <div>
                <BasicFilter
                    titleBarChildren={
                        <Button type="ghost" onClick={() => setOpenUploadModal(prev => !prev)} icon={<UploadOutlined />}>
                            Upload Dividend
                        </Button>
                    }
                >
                    <Row gutter={[8, 8]}>
                        <Col span={8} xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 8 }}>
                            <Space>
                                <Switch
                                    size="small"
                                    checked={isDefault}
                                    onClick={(checked: boolean) => {
                                        setIsDefault(prev => !prev);
                                    }}
                                />
                                <Text>Default</Text>
                            </Space>
                        </Col>
                    </Row>
                    {/* {isDefault ? "Default" : `Not Default ${uploadDate}`} */}
                    <Row gutter={[8, 8]}>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                            <Text>Upload Date {isDefault && "(using Default)"}</Text>
                            <DatePicker
                                style={{ width: "100%" }}
                                onChange={date => {
                                    if (date) {
                                        setUploadDate(convertDateToYYYYMMDD(date.toDate()));
                                    } else {
                                        setUploadDate(todayDate);
                                    }
                                }}
                                //max date is today
                                disabledDate={current => current && current > moment().endOf("day")}
                                value={uploadDate ? moment(uploadDate, "YYYYMMDD") : null}
                                disabled={isDefault} // ignore this, a bit cacat
                            />
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                            <Text>Symbol</Text>
                            <Input
                                allowClear
                                value={symbol}
                                onChange={e => {
                                    setSymbol(e.target.value);
                                }}
                                style={{ width: "100%" }}
                            />
                        </Col>
                    </Row>
                </BasicFilter>
                <Table
                    dataSource={rcDividendUploads || []}
                    columns={columns}
                    size="small"
                    pagination={{ defaultPageSize: 50 }}
                    rowKey={record => record.cleanSymbol + record.uploadDate}
                />
            </div>
            <UploadModal open={openUploadModal} callback={() => setOpenUploadModal(prev => !prev)} />
        </>
    );
};

// upload modal
const UploadModal = ({ open, callback }: { open: boolean; callback: () => any }) => {
    const [form] = Form.useForm();
    const [uploadFileBlob, setUploadFileBlob] = useState<File | null>(null);
    const [confirmModalVisible, setConfirmModalVisible] = useState(false);
    const [isDefault, setIsDefault] = useState(false);
    const { Item } = Form;

    const { uploadDividend, isLoadingUploadDividend } = useRCDividendRecordMutation();

    const handleUpload = async (formValue: any) => {
        setConfirmModalVisible(false);
        try {
            const resp = await uploadDividend({ uploadDate: isDefault ? "Default" : formValue.uploadDate, uploadFile: uploadFileBlob });
            if (resp) {
                notification.success({
                    message: "Success",
                    description: "Dividend record uploaded successfully",
                });
                // reset form
                form.resetFields();
                setUploadFileBlob(null);
                setIsDefault(false);
                callback();
            }
        } catch (e) {
            console.log("error", e);
            notification.error({
                message: "Failed to upload dividend record",
                // @ts-ignore
                description: e?.response?.data?.message || e.message,
            });
        }
    };
    const initFormValue = {
        uploadFile: null,
        isDefault: false,
        uploadDate: moment(new Date()),
    };
    const { Text, Title } = Typography;

    const showConfirmModal = () => {
        form.validateFields()
            .then(() => {
                setConfirmModalVisible(true);
            })
            .catch(errorInfo => {
                console.log("Validation failed:", errorInfo);
            });
    };

    const handleConfirm = () => {
        handleUpload(form.getFieldsValue());

        // // clear form state
        // form.resetFields();
        // setUploadFileBlob(null);

        // callback();
    };

    return (
        <Form form={form} layout="vertical" initialValues={initFormValue} requiredMark={true} onFinish={handleUpload}>
            <Modal open={open} title="Upload Dividend" onCancel={callback} onOk={showConfirmModal} okText="Submit" width={800}>
                <Space direction="vertical" size={16}>
                    <MessageCard type="info" icon={false}>
                        <Space direction="vertical" size={16}>
                            <div>
                                <Title level={5}>Main purpose:</Title>
                                <div style={{ fontSize: "0.85rem", lineHeight: 1.25 }}>
                                    <Text>
                                        Upload the file and the system will receive the corresponding divided.{" "}
                                        <span style={{ color: colorMap.error }}>Please upload the file before 12:05 AM (system time)</span>
                                    </Text>
                                </div>
                            </div>
                            <div>
                                <Title level={5}>Operation logic and tool introduction:</Title>
                                <ol style={{ fontSize: "0.85rem", lineHeight: 1.25, marginBottom: 0 }}>
                                    <li>Upload excel sheet with all index's dividends without suffix.</li>
                                    <li>
                                        Beware of the date, ex: if today is 6/23, you should update dividends that will give out on 6/24 and choose
                                        6/23 as upload date.
                                    </li>
                                    <li>Upload the file before EOD everyday.</li>
                                </ol>
                            </div>
                        </Space>
                    </MessageCard>
                    <Row gutter={[8, 8]}>
                        <Col span={8} xs={{ span: 24 }} md={{ span: 8 }}>
                            <Text>Default</Text>
                            <Item
                                style={{ margin: 0, padding: 0 }}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                name="isDefault"
                                valuePropName="checked"
                            >
                                <Switch
                                    onChange={checked => {
                                        // change setIsDefault and also form state
                                        setIsDefault(checked);
                                        form.setFieldsValue({ isDefault: checked });
                                    }}
                                />
                            </Item>
                        </Col>
                        <Col span={16} xs={{ span: 24 }} md={{ span: 16 }}>
                            <Text>Date {isDefault ? "(using Default)" : "*"}</Text>
                            <Item
                                style={{ margin: 0, padding: 0 }}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                required
                                name="uploadDate"
                                rules={[{ required: true, message: "Please select date" }]}
                            >
                                <DatePicker
                                    style={{ width: "100%" }}
                                    disabled={isDefault}
                                    disabledDate={current => {
                                        return current && (current > moment().endOf("day") || current < moment().subtract(60, "days").startOf("day"));
                                    }}
                                    onChange={data => {
                                        if (data) {
                                            form.setFieldsValue({ uploadDate: data });
                                        } else {
                                            form.setFieldsValue({ uploadDate: moment(new Date()) });
                                        }
                                    }}
                                />
                            </Item>
                        </Col>
                        <Col span={24}>
                            <Text>Upload File (.xlsx only) *</Text>
                            <Item
                                style={{ margin: 0, padding: 0 }}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                name="uploadFile"
                                valuePropName="uploadFile"
                                rules={[
                                    { required: true, message: "Please upload file" },
                                    {
                                        validator: async (_, file: any) => {
                                            if (file?.fileList?.[0]?.size > 5 * 1024 * 1024) {
                                                return Promise.reject("File size must be smaller than 5MB!");
                                            }
                                            if (file?.fileList?.[0]?.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                                                return Promise.reject("File type must be .xlsx!");
                                            }
                                            return Promise.resolve();
                                        },
                                    },
                                ]}
                            >
                                <Upload.Dragger
                                    name="file"
                                    multiple={false}
                                    maxCount={1}
                                    beforeUpload={file => {
                                        setUploadFileBlob(file);
                                        return false; // Prevent auto upload
                                    }}
                                    accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                    onRemove={() => {
                                        setUploadFileBlob(null);
                                        form.setFieldsValue({ uploadFile: null });
                                    }}
                                >
                                    <p className="ant-upload-drag-icon">
                                        <UploadOutlined />
                                    </p>
                                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                    <p className="ant-upload-hint">Support for a single XLSX file upload. File size must be less than 5MB.</p>
                                </Upload.Dragger>
                            </Item>
                        </Col>
                    </Row>
                </Space>
            </Modal>
            <Modal title="Confirm Upload" open={confirmModalVisible} onOk={handleConfirm} onCancel={() => setConfirmModalVisible(false)}>
                <p>Are you sure you want to upload this file?</p>
            </Modal>
        </Form>
    );
};

export default TabMap;
