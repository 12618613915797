import { Button } from "antd";
import { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LoadingComponent from "../../../../components/Loading";
import MDEditor from "@uiw/react-md-editor";
import ReportingResultData from "../../../../assets/data/reportingResultData.json";
import ReportingTemplateData from "../../../../assets/data/reportingTemplateData.json";
import RoleAndPermissionData from "../../../../assets/data/roleAndPermissionData.json";
import UserManagementData from "../../../../assets/data/userManagementData.json";
import AppConfigurationData from "../../../../assets/data/appConfigurationData.json";
import AccountListData from "../../../../assets/data/accountListData.json";
import AccountWatchListData from "../../../../assets/data/accountWatchListData.json";
import RiskScoreConfigurationListData from "../../../../assets/data/riskScoreConfigurationListData.json";
import ClientListData from "../../../../assets/data/clientListData.json";
import ServerData from "../../../../assets/data/serverData.json";
import BrandData from "../../../../assets/data/brandData.json";
import ServerBrandMappingData from "../../../../assets/data/serverBrandMappingData.json";
import GroupPurposeData from "../../../../assets/data/groupPurposeData.json";
import TagManagementData from "../../../../assets/data/tagManagementData.json";
import HubSwitcherData from "../../../../assets/data/hubSwitcherData.json";
import ToxicClientCheckData from "../../../../assets/data/toxicClientCheckData.json";
import { replaceDomainNameToZero, replaceZeroToDomainName } from "../../../../utils/array";

const md2json = require("md-2-json");

interface viewUserManualProps {
    modulePath: string;
    onCallBack?: () => void;
}

const EditUserManual = (props: viewUserManualProps) => {
    let navigate = useNavigate();
    let location = useLocation();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    let { id } = useParams();
    // const paramsInfo = JSON.parse(atob(id || ""));
    const [initialData, setInitialData] = useState<any>();
    const [value, setValue] = useState(initialData);

    const onChangeValue = (value?: string) => {
        setValue(value ? value : "");
    };

    const onSave = () => {
        const result = md2json.parse(value);
        const newData = {
            modulePath: props.modulePath,
            content: replaceDomainNameToZero(result, process.env.REACT_APP_AWS_S3_IMG_GALLERY || ""),
        };
        // console.log("result:", JSON.stringify(newData));
    };

    useEffect(() => {
        getInitialData(props.modulePath);
    }, []);

    const massageData = (jsonData: any) => {
        const content = replaceZeroToDomainName(jsonData, "{0}", process.env.REACT_APP_AWS_S3_IMG_GALLERY || "");
        setInitialData(md2json.toMd(content));
        setValue(md2json.toMd(content));
    };

    const onCancel = () => {
        props.onCallBack && props.onCallBack();
    };

    const getInitialData = (pathname: string) => {
        switch (pathname) {
            case "/report/result":
                massageData(ReportingResultData.content);
                break;
            case "/report/template":
                massageData(ReportingTemplateData.content);
                break;
            case "/siteadmin/rolepermission":
                massageData(RoleAndPermissionData.content);
                break;
            case "/siteadmin/user":
                massageData(UserManagementData.content);
                break;
            case "/siteadmin/appconfig":
                massageData(AppConfigurationData.content);
                break;
            case "/analysis/accountprofile":
                massageData(AccountListData.content);
                break;
            case "/analysis/watchlist":
                massageData(AccountWatchListData.content);
                break;
            case "/siteadmin/riskscore/account":
                massageData(RiskScoreConfigurationListData.content);
                break;
            case "/analysis/clientlist":
                massageData(ClientListData.content);
                break;
            case "/siteadmin/serverbrand/server":
                massageData(ServerData.content);
                break;
            case "/siteadmin/serverbrand/brand":
                massageData(BrandData.content);
                break;
            case "/siteadmin/serverbrand/server-brand-mapping":
                massageData(ServerBrandMappingData.content);
                break;
            case "/siteadmin/serverbrand/group-purpose":
                massageData(GroupPurposeData.content);
                break;
            case "/siteadmin/tag":
                massageData(TagManagementData.content);
                break;
            case "/admintools/hubSwitcher":
                massageData(HubSwitcherData.content);
                break;
            case "/analysis/toxicClientCheck":
                massageData(ToxicClientCheckData.content);
                break;
        }
    };

    return isLoading ? (
        <div className="loading-container">
            <LoadingComponent tip="Loading..." />
        </div>
    ) : (
        <div className="container">
            <>
                <div data-color-mode="light">
                    <MDEditor height={600} value={value} onChange={onChangeValue} />
                </div>
                <Button style={{ marginTop: "2.121vh" }} key={"default"} type="default" onClick={onCancel}>
                    Cancel
                </Button>
                <Button style={{ marginTop: "2.121vh", marginLeft: "0.9765vw" }} key={"save"} type="primary" onClick={onSave}>
                    Save
                </Button>
            </>
        </div>
    );
};

export default EditUserManual;
