import { useEffect, useMemo, useRef, useState } from "react";
import { APIs, apiRequest } from "../../../../services/apiConfig";
import { ErrorCatchValidator, ErrorMessageHandler, currencyRender } from "../../../../utils/Common";
import { SUCCESS_FAILED } from "../../../../constants";
import EmptyData from "../../../../components/Common/Empty";
import CustomSkeleton from "../../../../components/Common/Skeleton";
import { Button, Input, Select } from "antd";
import { Treemap } from "@ant-design/charts";
import { cloneDeep } from "lodash";
import { DimemsionFilterType, mapFilterParams } from "..";
import { hasAnyKey } from "../../../../utils/object";
import { MimMetricsList } from "../../../../constants/type";
import { SearchOutlined, SortAscendingOutlined, SortDescendingOutlined } from "@ant-design/icons";

interface SymbolSummaryProps {
    metrics: any[];
    isSearch?: boolean;
    selectedParams?: DimemsionFilterType[] | boolean;
    resetState: number;
}

interface HeatMapDataProps {
    type: string;
    name: string;
    value: number;
    seq: number;
}

const SymbolSummary = (props: SymbolSummaryProps) => {
    // dimension level [2]
    // component number 4
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [oriData, setOriData] = useState<HeatMapDataProps[]>([]);
    const [selectedMetric, setSelectedMetric] = useState<string>("total_pnl");
    const [topBottom, setTopBottom] = useState<boolean>(true);
    const [refetchData, setRefetchData] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>("");

    const ref = useRef<HTMLHeadingElement>(null);

    const getItemTemplate = (items: any[], isLast: boolean = false, totalItem: number = 10): React.ReactNode => {
        let returnTemplate: React.ReactNode[] = [];
        for (let index = 0; index < (items.length > totalItem ? totalItem : items.length); index++) {
            const x = items[isLast ? items.length - 1 - index : index];
            returnTemplate.push(
                <div className="title-value-div" key={`pnl-ts-${index}`}>
                    <div className="title">{x.name}</div>
                    <div className="value">{currencyRender(x.value, 2)}</div>
                </div>
            );
        }

        return returnTemplate;
    };

    const getMetricRanking = () => {
        setIsLoading(true);
        apiRequest(APIs.GET_MIM_METRIC_RANKING, {
            dimensionSelectors: [2],
            ...(props.metrics.length > 0 && {
                metricSelectors: [
                    {
                        metricName: selectedMetric,
                        columnOrder: 1,
                    },
                ],
                metricSorters: [
                    {
                        metricName: selectedMetric,
                        sortOrder: 1,
                        sortDirection: 1,
                    },
                ],
            }),
            ...(hasAnyKey(props.selectedParams) && { dimensionFilters: mapFilterParams(props.selectedParams) }),
        })
            .then((res: any) => {
                if (res && res.length > 0) {
                    let newData: any[] = res.map((x: any, index: number) => {
                        const currMetric = props.metrics.find((y: any) => y.metricName === selectedMetric);
                        if (currMetric && selectedMetric.includes("pnl")) {
                            return {
                                type: currMetric && x[selectedMetric] < 0 ? "Loss" : "Profit",
                                name: x.symbol,
                                value: currMetric && x[selectedMetric],
                                seq: index,
                            };
                        } else
                            return {
                                type: currMetric && x[selectedMetric] < 0 ? "< 0" : ">= 0",
                                name: x.symbol,
                                value: currMetric && x[selectedMetric],
                                seq: index,
                            };
                    });
                    setOriData(newData);
                } else {
                    setOriData([]);
                }
            })
            .catch((error) => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("Symbol Summary", SUCCESS_FAILED.FAILED_LOAD_DATA, err)))
            .finally(() => setIsLoading(false));
    };

    const config: any = {
        data: {
            name: "root",
            children: cloneDeep(oriData).map((x) => {
                x.value = Math.abs(x.value);
                return x;
            }),
        },
        legend: {
            position: "right",
        },
        colorField: "type",
        color: ({ type }: any) => (type === "Loss" || type === "< 0" ? "#1c9898" : "#dc1616"),
        ...(ref.current?.clientWidth !== undefined && {
            height: ref.current?.clientWidth / 2,
        }),
        tooltip: {
            formatter: (data: any) => {
                return { name: data.name, value: currencyRender(data.value * (data.type === "Loss" || data.type === "< 0" ? -1 : 1), 2) };
            },
        },
    };

    const filteredData: HeatMapDataProps[] = useMemo(() => {
        let returnData: any = oriData;
        let lowerSearchText = searchText.toLowerCase();
        if (lowerSearchText.length > 0) {
            let tempData = oriData.filter((currListData: HeatMapDataProps) => currListData.name.toLowerCase().indexOf(lowerSearchText) > -1);

            returnData = tempData.length > 0 ? tempData : [];

            if (topBottom) {
                // desc order = largest first
                return returnData;
            } else {
                // asc order = smallest first
                let sortedData: any[] = [...returnData];
                sortedData.sort((a, b) => a.value - b.value);
                return sortedData;
            }
        }

        if (topBottom) {
            // desc order = largest first
            return returnData;
        } else {
            // asc order = smallest first
            let sortedData: any[] = [...returnData];
            sortedData.sort((a, b) => a.value - b.value);
            return sortedData;
        }
    }, [oriData, searchText, topBottom]);

    useEffect(() => {
        setRefetchData(true);
        return () => {};
    }, [props.resetState]);

    useEffect(() => {
        if (props.isSearch) {
            if (props.selectedParams && hasAnyKey(props.selectedParams)) {
                setRefetchData(true);
            }
        }
        return () => {};
    }, [props.selectedParams]);

    useEffect(() => {
        if (refetchData) {
            getMetricRanking();
            setRefetchData(false);
        }
        return () => {};
    }, [refetchData]);

    return (
        <>
            <div className="intraday-symbols-summary-panel">
                <div className="c-left">
                    <div className="title-box">
                        <span className={`title-box-text ${props.isSearch ? "" : "summary"}`}>Client Symbol Summary</span>
                    </div>
                    {isLoading ? (
                        <CustomSkeleton rows={10} />
                    ) : oriData?.length > 0 ? (
                        // <div className="chart">
                        <Treemap {...config} width={ref.current?.clientWidth} />
                    ) : (
                        // </div>
                        <>
                            <EmptyData />
                        </>
                    )}
                </div>
                {!isLoading && (
                    <div className="c-right">
                        <div className="main-content">
                            <div>
                                <div className="desc-tags">
                                    <div className="tag-div">
                                        <div className="select-filter-div">
                                            <Select
                                                bordered={false}
                                                defaultValue={selectedMetric}
                                                onChange={(value: any) => {
                                                    setSelectedMetric(value);
                                                    setRefetchData(true);
                                                }}
                                                options={props.metrics.map((x: MimMetricsList) => ({
                                                    label: x.metricDisplayName,
                                                    value: x.metricName,
                                                }))}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="filter-div">
                                    <Input
                                        className="search-input-filter"
                                        type="text"
                                        prefix={<SearchOutlined style={{ color: "#c4c3c3" }} />}
                                        placeholder="Search symbol"
                                        allowClear
                                        onChange={(e: any) => setSearchText(e.currentTarget.value)}
                                    />
                                    <Button
                                        icon={topBottom ? <SortDescendingOutlined /> : <SortAscendingOutlined />}
                                        onClick={() => setTopBottom((prev) => !prev)}
                                    />
                                </div>
                                <div className="content">
                                    <div className="tag-summary-desc-panel nice-scrollbar">
                                        {getItemTemplate(filteredData, false, filteredData.length)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default SymbolSummary;
