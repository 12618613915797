import { BarChartOutlined } from "@ant-design/icons";
import { ColumnConfig, Datum } from "@ant-design/charts";
import { Column } from "@ant-design/plots";
import ChartPanel from "../chartPanel";
import { AdvanceAnalysisFilterParams } from "../../advanceAnalysis";
import { currencyRender } from "../../../../../utils/Common";
import EmptyData from "../../../../../components/Common/Empty";
import CustomSkeleton from "../../../../../components/Common/Skeleton";

export interface PNLByTradeDurationProps extends AdvanceAnalysisFilterParams {
    forPrint?: boolean;
    isLoading: boolean;
    data: any[];
}

const PNLByTradeDuration = (props: PNLByTradeDurationProps) => {
    const getConfig = (currentData: any): ColumnConfig => ({
        data: currentData,
        appendPadding: 20,
        xField: "key",
        yField: "value",
        ...(props.forPrint
            ? {
                  xAxis: {
                      label: {
                          autoRotate: true,
                      },
                      tickLine: {
                          alignTick: true,
                      },
                  },
              }
            : {
                  xAxis: {
                      label: {
                          autoRotate: true,
                      },
                      tickLine: {
                          alignTick: true,
                      },
                  },
                  slider: {
                      start: 0,
                      end: 1,
                  },
              }),
        yAxis: {
            label: {
                formatter: (v: any) => currencyRender(v, -1, 0),
            },
        },
        tooltip: {
            formatter: (datum: Datum) => {
                return {
                    name: "Total P&L",
                    value: currencyRender(datum.value, 2),
                };
            },
        },
        color: ({ key }: any) => {
            let x = currentData.find((i: any) => i.key === key);
            if (x.value < 0) {
                return "#F4664A";
            } else return "#63d8a8";
        },
    });

    return (
        <>
            {props.isLoading ? (
                <ChartPanel
                    forPrint={props.forPrint}
                    type={1}
                    icon={<BarChartOutlined />}
                    title={"P/L by Trade Duration"}
                    subTitle={"Overall profit/loss by trade duration"}
                >
                    <CustomSkeleton rows={10} />
                </ChartPanel>
            ) : (
                <ChartPanel
                    forPrint={props.forPrint}
                    type={1}
                    icon={<BarChartOutlined />}
                    title={"P/L by Trade Duration"}
                    subTitle={"Overall profit/loss by trade duration"}
                >
                    <div className="chart">
                        {props.data.length > 0 ? (
                            <Column {...getConfig(props.data)} />
                        ) : (
                            <>
                                <EmptyData />
                            </>
                        )}
                    </div>
                </ChartPanel>
            )}
        </>
    );
};

export default PNLByTradeDuration;
