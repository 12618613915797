import { Badge, Button, Col, Divider, Row, Space, Tag, Typography, notification } from "antd";
import FloatingMenu from "../../SystemMonitor/components/FloatingMenu";
import { FaCheck, FaChevronCircleRight, FaExpand, FaHome, FaRedo } from "react-icons/fa";

import useMeasure from "react-use-measure";
import SoundButton from "../../SystemMonitor/components/SoundButton";
import soundFile from "../../../assets/audios/rc/price-stop.mp3";
import useRCPriceAlarm, { useRCPriceAlarmMiscStop, useRCPriceAlarmServerStop } from "../../../hooks/useRCPriceAlarm";
import useRCPriceAlarmStore from "../../../store/useRCPriceAlarmStore";
import TimeDiff from "../../SystemMonitor/components/TimeDiff";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { colorMap } from "../../SystemMonitor/StatusMonitor/statusUtils";
import Counter from "../../../components/Motion/Counter";
import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import ServerTimeWidget from "../../SystemMonitor/components/ServerTimeWidget";
import GenerateReportModal from "./DownloadPriceAlarmModal";
import DownloadsModal from "../Downloads/DownloadsModal";
import useRCDownloads from "../../../hooks/useRCDownloads";

const PriceAlarmMenuData = [
    {
        label: "Price Alarm",
        key: "PRICE_ALARM",
    },
    {
        label: "LP Price Alarm",
        key: "LP_PRICE_ALARM",
    },
    {
        label: "Server Stop",
        key: "SERVER_STOP",
    },
    {
        label: "Price Connector Stop",
        key: "CONNECTOR",
    },
    {
        label: "Price Calculate Tool Stop",
        key: "CALCULATE_TOOL",
    },
];

const PriceAlarmMenu = () => {
    const { rcPriceAlarmSummary, rcPriceAlarmSummaryLP, refetchRcPriceAlarm, dataUpdatedAt: priceAlarmLastUpdated } = useRCPriceAlarm();
    const {
        rcPriceAlarmMiscStopConnector,
        rcPriceAlarmMiscStopCalculateTool,
        refetchRcPriceAlarmMiscStop,
        dataUpdatedAt: miscStopLastUpdated,
    } = useRCPriceAlarmMiscStop();
    const { rcPriceAlarmServerStop, refetchRcPriceAlarmServerStop, dataUpdatedAt: serverStopLastUpdated } = useRCPriceAlarmServerStop();
    const { rcDownloads } = useRCDownloads();

    const { Title } = Typography;
    const [refreshing, setRefreshing] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const handleRefetchAll = () => {
        setRefreshing(true);
        api.open({
            key: "refresh",
            message: `Refreshing Price Alarm`,
            description: `A moment please...`,
        });

        Promise.all([refetchRcPriceAlarm(), refetchRcPriceAlarmMiscStop(), refetchRcPriceAlarmServerStop()])
            .then(() => {
                api.open({
                    key: "refresh",
                    message: `Success`,
                    description: `All price alarms has been refreshed`,
                });
            })
            .catch(error => {
                api.open({
                    key: "refresh",
                    message: `Error`,
                    description: `An error occurred while refreshing Price Alarm`,
                });
                console.error("An error occurred while refreshing Price Alarm:", error);
            })
            .finally(() => {
                setRefreshing(false);
            });
    };

    const [ref, bounds] = useMeasure();

    const totalWarningCount = {
        PRICE_ALARM: rcPriceAlarmSummary?.length || 0,
        LP_PRICE_ALARM: rcPriceAlarmSummaryLP?.length || 0,
        SERVER_STOP: rcPriceAlarmServerStop?.data?.length || 0,
        CONNECTOR: rcPriceAlarmMiscStopConnector?.length || 0,
        CALCULATE_TOOL: rcPriceAlarmMiscStopCalculateTool?.length || 0,
    };

    const shouldPlaySound = {
        PRICE_ALARM: rcPriceAlarmSummary?.length >= 1,
        LP_PRICE_ALARM: rcPriceAlarmSummaryLP?.length >= 1,
        SERVER_STOP: rcPriceAlarmServerStop?.data?.length || 0 >= 1,
        CONNECTOR: rcPriceAlarmMiscStopConnector?.length >= 1,
        CALCULATE_TOOL: rcPriceAlarmMiscStopCalculateTool?.length >= 1,
    };

    const finalShouldPlaySound =
        // check if any of shouldPlaySound is true
        Object.values(shouldPlaySound).some(Boolean);

    const inView = useRCPriceAlarmStore(state => state.inView);
    const handleHTMLScroll = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
            const elementPosition = element.getBoundingClientRect().top + window?.scrollY;
            const offsetPosition = elementPosition - (bounds.height + 56);
            window?.scrollTo({
                top: offsetPosition,
                behavior: "smooth",
            });
        }
        // scroll to the element with the id, and +200px offset
        // example the id is at 200 Y, i need to scroll to 400Y
        // document.getElementById(id)?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    };
    // const [openSettingsModal, setOpenSettingsModal] = useState(false);
    const { Text } = Typography;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
    const handleModalOpen = (open: boolean) => {
        setIsModalOpen(open);
    };
    const handleDownloadReportModalOpen = (open: boolean) => {
        setIsDownloadModalOpen(open);
    };
    const [isMuted, setIsMuted] = useState(false);
    const handleIsMuted = (value: boolean) => {
        setIsMuted(value);
    };

    const [fullScreen, setFullScreen] = useState(false);
    return (
        <>
            {fullScreen && (
                <motion.div
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: "white",
                        zIndex: 998,
                        height: "100vh",
                        width: "100vw",
                    }}
                />
            )}
            <motion.div
                id="OVERVIEW"
                style={{ background: "white", padding: "1rem", minHeight: "80vh" }}
                animate={{
                    position: fullScreen ? "absolute" : "relative",
                    top: fullScreen ? 0 : "auto",
                    left: fullScreen ? 0 : "auto",
                    right: fullScreen ? 0 : "auto",
                    bottom: fullScreen ? 0 : "auto",
                    zIndex: fullScreen ? 999 : "auto",
                    width: fullScreen ? "100vw" : "auto",
                    height: fullScreen ? "100vh" : "auto",
                }}
            >
                <div style={{ display: "flex", alignItems: "center", marginBottom: "1rem", gap: 16 }}>
                    <Space style={{ alignItems: "center" }} wrap>
                        <Title level={2} style={{ padding: 0, margin: 0 }}>
                            Price Alarm Overview
                        </Title>
                        {/* <SoundButton
                            soundSrc={soundFile}
                            isActive={finalShouldPlaySound}
                            isMutedOutside={isMuted}
                            isMutedOutsideCallback={handleIsMuted}
                        /> */}
                        <Button
                            type="ghost"
                            style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 4 }}
                            onClick={() => {
                                handleRefetchAll();
                            }}
                            disabled={refreshing}
                            shape="circle"
                            icon={
                                <motion.div
                                    animate={{
                                        rotate: refreshing ? 180 : 0,
                                    }}
                                >
                                    <FaRedo style={{ fontSize: "0.75rem" }} />
                                </motion.div>
                            }
                        />
                        <Button
                            type="ghost"
                            style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 4 }}
                            onClick={() => {
                                setFullScreen(!fullScreen);
                            }}
                            shape="circle"
                            icon={
                                <motion.div>
                                    {!fullScreen ? <FaExpand style={{ fontSize: "0.75rem" }} /> : <AiOutlineClose style={{ fontSize: "0.75rem" }} />}
                                </motion.div>
                            }
                        />
                    </Space>
                    <div style={{ marginLeft: "auto" }}>
                        <ServerTimeWidget />
                    </div>
                </div>
                <Row gutter={[4, 4]}>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                        <StatusCard
                            title="Price Alarm"
                            index={1}
                            value={totalWarningCount.PRICE_ALARM}
                            callback={() => handleHTMLScroll("PRICE_ALARM")}
                            // href="/systemmonitor/statusmonitor"
                            lastUpdated={priceAlarmLastUpdated}
                        >
                            <Space wrap style={{ marginTop: "0.5rem", width: "100%" }} size={2}>
                                {rcPriceAlarmSummary
                                    ?.sort((a, b) =>
                                        // sort by each showtime alphabetically, revert to latest
                                        a.showTime.localeCompare(b.showTime)
                                    )
                                    ?.reverse()
                                    .map((each, index) => (
                                        <motion.div
                                            key={index}
                                            style={{
                                                borderRadius: 8,
                                                padding: "0.375rem 0.5rem",
                                                backgroundColor: "rgba(0,0,0,0.5)",
                                            }}
                                        >
                                            <Text style={{ fontSize: 18, color: "white", lineHeight: 1 }}>
                                                <small>{each.brand}</small>
                                                <br />
                                                <strong style={{ fontWeight: 800 }}>{each.group}</strong>
                                                <br />
                                                <Tag>{each.showTime}</Tag>
                                            </Text>
                                        </motion.div>
                                    ))}
                            </Space>
                        </StatusCard>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                        <StatusCard
                            title="LP Price Alarm"
                            lastUpdated={priceAlarmLastUpdated}
                            index={2}
                            value={totalWarningCount.LP_PRICE_ALARM}
                            callback={() => handleHTMLScroll("LP_PRICE_ALARM")}
                            // href="/systemmonitor/rejectorder"
                        >
                            {" "}
                            <Space wrap style={{ marginTop: "0.5rem", width: "100%" }} size={2}>
                                {rcPriceAlarmSummaryLP
                                    ?.sort((a, b) =>
                                        // sort by each group alphabetically
                                        a.showTime.localeCompare(b.showTime)
                                    )
                                    ?.reverse()
                                    .slice(0, 18)
                                    .map((each, index) => (
                                        <motion.div
                                            key={index}
                                            style={{
                                                borderRadius: 8,
                                                padding: "0.375rem 0.5rem",
                                                backgroundColor: "rgba(0,0,0,0.5)",
                                            }}
                                        >
                                            <Text style={{ fontSize: 18, color: "white", lineHeight: 1 }}>
                                                <small>{each.brand}</small>
                                                <br />
                                                <strong style={{ fontWeight: 800 }}>{each.group}</strong>
                                                <br />
                                                <Tag>{each.showTime}</Tag>
                                            </Text>
                                        </motion.div>
                                    ))}
                                {rcPriceAlarmSummaryLP?.length > 18 && (
                                    <motion.div
                                        style={{
                                            borderRadius: 8,
                                            padding: "0.375rem 0.5rem",
                                            backgroundColor: "rgba(0,0,0,0.5)",
                                        }}
                                    >
                                        <Text style={{ fontSize: 18, color: "white" }}>
                                            And{" "}
                                            {
                                                // count how many more
                                                rcPriceAlarmSummaryLP?.length - 18
                                            }{" "}
                                            more...
                                        </Text>
                                    </motion.div>
                                )}
                            </Space>
                        </StatusCard>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 6 }}>
                        <StatusCard
                            title="Server Stop"
                            lastUpdated={serverStopLastUpdated}
                            index={3}
                            value={totalWarningCount.SERVER_STOP}
                            callback={() => handleHTMLScroll("SERVER_STOP")}
                        >
                            {rcPriceAlarmServerStop?.data !== undefined && rcPriceAlarmServerStop?.data?.length >= 1 && (
                                <Space wrap style={{ marginTop: "0.5rem", width: "100%" }} size={2}>
                                    {rcPriceAlarmServerStop?.data?.map((each, index) => (
                                        <motion.div
                                            key={index}
                                            style={{
                                                borderRadius: 8,
                                                padding: "0.375rem 0.5rem",
                                                backgroundColor: "rgba(0,0,0,0.5)",
                                            }}
                                        >
                                            <Text style={{ fontSize: 18, color: "white" }}>
                                                <strong>{each.serverName}</strong> <small>{each.duration}</small>
                                            </Text>
                                        </motion.div>
                                    ))}
                                </Space>
                            )}
                        </StatusCard>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 6 }}>
                        <StatusCard
                            title="Price Connector Stop"
                            lastUpdated={miscStopLastUpdated}
                            index={4}
                            value={totalWarningCount.CONNECTOR}
                            callback={() => handleHTMLScroll("CONNECTOR")}
                            // href="/systemmonitor/serverperformance"
                        />
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 6 }}>
                        <StatusCard
                            title="Price Calculate Tool Stop"
                            lastUpdated={miscStopLastUpdated}
                            index={5}
                            value={totalWarningCount.CALCULATE_TOOL}
                            callback={() => handleHTMLScroll("CALCULATE_TOOL")}
                            // href="/systemmonitor/datacenter"
                        />
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 6 }}>
                        <ToolsCard title="Tools" index={6}>
                            <Space wrap style={{ marginTop: "0.5rem", width: "100%" }} size={2}>
                                <Button type="primary" style={{ borderRadius: 8 }} onClick={() => handleModalOpen(true)}>
                                    Generate Report
                                </Button>

                                <Button
                                    type="primary"
                                    style={{ borderRadius: 8, display: "flex", gap: 6, alignItems: "center" }}
                                    onClick={() => handleDownloadReportModalOpen(true)}
                                >
                                    <span>Download Report</span>
                                    {rcDownloads?.[1] !== undefined &&
                                        rcDownloads?.[1]?.filter(each => each?.status?.toLowerCase() === "completed")?.length >= 1 && (
                                            <Badge
                                                count={rcDownloads?.[1]?.filter(each => each?.status?.toLowerCase() === "completed")?.length}
                                                color={colorMap.success}
                                                size="small"
                                            />
                                        )}
                                </Button>
                            </Space>
                        </ToolsCard>
                    </Col>
                </Row>
            </motion.div>
            <div style={{ height: "1rem" }} />
            {contextHolder}
            <FloatingMenu>
                <div ref={ref} style={{ display: "flex", justifyContent: "flex-start", gap: 4, flexWrap: "wrap", alignItems: "center" }}>
                    <Button style={{ display: "flex", alignItems: "center" }} onClick={() => handleHTMLScroll("OVERVIEW")}>
                        <FaHome />
                    </Button>
                    {PriceAlarmMenuData.map((item, index) => {
                        return (
                            <Button
                                // @ts-ignore
                                type={inView[item.key] ? "primary" : "default"}
                                key={item.key + index}
                                style={{ display: "flex", alignItems: "center", gap: 2 }}
                                onClick={() => handleHTMLScroll(item.key)}
                            >
                                {item.label}
                                {/* @ts-ignore */}
                                {totalWarningCount?.[item.key] !== undefined && (
                                    // @ts-ignore
                                    <Badge count={totalWarningCount?.[item.key]} overflowCount={999999} />
                                )}
                            </Button>
                        );
                    })}
                    <Button style={{ display: "flex", alignItems: "center", gap: 2 }} onClick={() => handleModalOpen(true)}>
                        Generate Report
                    </Button>
                    <Button style={{ display: "flex", alignItems: "center", gap: 2 }} onClick={() => handleDownloadReportModalOpen(true)}>
                        Download Report
                    </Button>

                    <Divider type="vertical" />
                    <SoundButton
                        soundSrc={soundFile}
                        isActive={finalShouldPlaySound}
                        isMutedOutside={isMuted}
                        isMutedOutsideCallback={handleIsMuted}
                    />
                    <Button
                        type="ghost"
                        style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 4 }}
                        onClick={() => {
                            handleRefetchAll();
                        }}
                        disabled={refreshing}
                        shape="circle"
                        icon={
                            <motion.div
                                animate={{
                                    rotate: refreshing ? 180 : 0,
                                }}
                            >
                                <FaRedo style={{ fontSize: "0.75rem" }} />
                            </motion.div>
                        }
                    />
                </div>
            </FloatingMenu>
            {/* <Modal
                title="Settings"
                open={openSettingsModal}
                onOk={() => setOpenSettingsModal(false)}
                onCancel={() => setOpenSettingsModal(false)}
                footer={null}
            >
                <Space direction="vertical" style={{ width: "100%" }}>
                    <Title level={4}>Settings</Title>
                    <MessageCard type="info">Settings will be available soon</MessageCard>
                </Space>
            </Modal> */}
            <GenerateReportModal open={isModalOpen} handleModalOpen={handleModalOpen} />
            <DownloadsModal open={isDownloadModalOpen} handleModalOpen={handleDownloadReportModalOpen} filterName={"PriceAlarmSummary"} />
        </>
    );
};

const ToolsCard = ({ title, children, index = 0 }: { title: string; children: React.ReactNode; index?: number }) => {
    const { Title } = Typography;
    return (
        <motion.div
            style={{ width: "100%", height: "100%", borderRadius: 16, padding: "1.5rem" }}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0, background: "rgba(0,0,0,0.5)", transition: { delay: index * 0.05, type: "spring" } }}
        >
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <Title level={2} style={{ marginBottom: 0, lineHeight: 1, paddingBottom: 0, color: "white" }}>
                    {title}
                </Title>
                {children}
            </div>
        </motion.div>
    );
};
const StatusCard = ({
    title,
    value,
    subSection,
    type = "info",
    href = "",
    callback,
    index = 0,
    lastUpdated = 0,
    children,
}: {
    title: string;
    value: number;
    href?: string;
    callback?: () => void;
    subSection?: {
        title: string;
        value: number;
        href?: string;
    }[];
    type?: "success" | "error" | "warning" | "info" | "disabled";
    index?: number;
    lastUpdated?: number;
    children?: React.ReactNode;
}) => {
    const { Title, Text } = Typography;
    if (value === 0) {
        type = "success";
    } else {
        type = "error";
    }
    // if (value === 0) {
    //     type = "success";
    // } else if (value <= 5) {
    //     type = "warning";
    // } else if (value > 5) {
    //     type = "error";
    // }
    return (
        <motion.div
            style={{ width: "100%", height: "100%", borderRadius: 16, padding: "1.5rem" }}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0, background: colorMap[type], transition: { delay: index * 0.05, type: "spring" } }}
        >
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <div style={{ alignItems: "flex-start", gap: "1rem", display: "flex" }}>
                    <Title level={2} style={{ marginBottom: 0, lineHeight: 1, paddingBottom: 0, color: type === "error" ? "white" : "black" }}>
                        {title}
                    </Title>
                    <motion.div style={{ lineHeight: 1, marginLeft: "auto", opacity: 0.85 }} whileHover={{ opacity: 1, scale: 1.2 }}>
                        {href !== "" ? (
                            <Link to={href} style={{ lineHeight: 1, marginLeft: "auto" }}>
                                <FaChevronCircleRight style={{ fontSize: 30, color: type === "error" ? "white" : "black" }} />
                            </Link>
                        ) : callback ? (
                            <div onClick={callback} style={{ lineHeight: 1, marginLeft: "auto", cursor: "pointer" }}>
                                <FaChevronCircleRight style={{ fontSize: 30, color: type === "error" ? "white" : "black" }} />
                            </div>
                        ) : null}
                    </motion.div>
                </div>
                {value === 0 ? (
                    <div style={{ alignItems: "center", gap: type === "success" ? "1rem" : "0.5rem", display: "flex" }}>
                        <FaCheck style={{ color: "white" }} />
                        <Text style={{ fontSize: 24, color: "white" }}> All Good</Text>
                    </div>
                ) : (
                    <>
                        <Text style={{ fontSize: 24, color: "white" }}>
                            <Counter value={value} digits={value.toString().length} fontSize={84} />
                        </Text>
                    </>
                )}
                {subSection && (
                    <Space wrap style={{ marginTop: "0.5rem", width: "100%" }}>
                        {subSection.map((each, index) => (
                            <Link to={each.href || href} key={index + each.title}>
                                <Badge count={each.value} style={{ backgroundColor: "red", zIndex: 99 }} overflowCount={99999}>
                                    <motion.div
                                        key={index}
                                        style={{
                                            borderRadius: 8,
                                            padding: "0.75rem 1rem",
                                            backgroundColor: "rgba(0,0,0,0.5)",
                                            opacity: each.value === 0 ? 0.25 : 0.85,
                                        }}
                                        whileHover={{ opacity: 1, scale: 1.05 }}
                                    >
                                        <Text style={{ fontSize: 18, color: "white" }}>{each.title}</Text>
                                    </motion.div>
                                </Badge>
                            </Link>
                        ))}
                    </Space>
                )}
                <>{children}</>
                {lastUpdated !== 0 && (
                    <div style={{ marginTop: "auto", paddingTop: "1rem" }}>
                        <TimeDiff
                            timestamp={lastUpdated}
                            styles={{
                                borderRadius: 16,
                                background: "rgba(255,255,255,0.5)",
                                border: "none",
                            }}
                        />
                    </div>
                )}
            </div>
        </motion.div>
    );
};

export default PriceAlarmMenu;
