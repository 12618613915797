import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { APIs } from "../services/apis";
import { plainAxiosInstance } from "../services/axiosSetup";

const useRCStatusMonitorExclude = () => {
    //convert request to form data
    // const token = getToken();
    const { data, refetch, isLoading, error } = useQuery({
        queryKey: ["rcStatusMonitorExclude"],
        queryFn: () =>
            plainAxiosInstance.get(`${APIs.RC_SYSTEM_MONITOR.GET_STATUS_MONITOR_EXCLUDE}`, {
                headers: {
                    Accept: "application/json",
                },
            }),
        // axios.get(`${process.env.REACT_APP_API_URL}${APIs.RC_SYSTEM_MONITOR.GET_STATUS_MONITOR_EXCLUDE}`, {
        //     headers: {
        //         Accept: "application/json",
        //         Authorization: `Bearer ${token}`,
        //     },
        // }),
        staleTime: 60 * 1000 * 5, // refetch after 5 mins
        refetchInterval: 60 * 1000 * 5, // refetch after 5 mins
    });
    const rcStatusMonitorExclude = data?.data?.status ? [] : data?.data || [];
    // @ts-ignore
    const rcStatusMonitorExcludeError = data?.data?.message || error?.message || error || null;

    return {
        rcStatusMonitorExclude,
        rcStatusMonitorExcludeError,
        // rcStatusMonitorExclude: data?.data ?? [],
        // rcStatusMonitorExcludeError: error,
        refetchRcStatusMonitorExclude: refetch,
        isLoading,
    };
};

interface IRCStatusMonitorExcludeRequest {
    appName: string;
    exclude?: number;
    groupId: string;
    vendor: string;
}

export const useRCStatusMonitorAddExclude = () => {
    const queryClient = useQueryClient();
    const { mutateAsync, isLoading, error } = useMutation(
        async ({ appName, exclude = 1, groupId, vendor }: IRCStatusMonitorExcludeRequest) => {
            if (!appName || !groupId || !vendor) {
                return Promise.reject("appName, groupId or vendor is empty");
            }
            return plainAxiosInstance.post(
                `${APIs.RC_SYSTEM_MONITOR.POST_STATUS_MONITOR_EXCLUDE}`,
                { appName, exclude, groupId, vendor },
                {
                    headers: {
                        Accept: "application/json",
                    },
                }
            );
        },
        {
            onSuccess: res => {
                queryClient.invalidateQueries(["rcStatusMonitorExclude"]);
                queryClient.invalidateQueries(["rcStatusMonitor", "RA"]);
                queryClient.invalidateQueries(["rcStatusMonitor", "Admin"]);
                queryClient.invalidateQueries(["rcStatusMonitor", "Weekend"]);

                // console.log(`Add excluded response 200`, res);
                return res;
            },
            onError: error => {
                console.error(`Error on excluding:`, error);
                return error;
            },
        }
    );

    return {
        excludeStatus: mutateAsync,
        isLoading,
        error,
    };
};
export const useRCStatusMonitorRemoveExclude = () => {
    const queryClient = useQueryClient();

    const removeExcludeStatus = async (excludeRequests: IRCStatusMonitorExcludeRequest[]) => {
        return Promise.all(
            excludeRequests.map(({ appName = "", groupId = "", vendor = "" }) => {
                // if (!appName || !groupId || !vendor) {
                //     return Promise.reject("appName, groupId, or vendor is empty");
                // }
                return plainAxiosInstance.post(
                    `${APIs.RC_SYSTEM_MONITOR.POST_STATUS_MONITOR_DELETE_EXCLUDE}`,
                    { appName, groupId, vendor },
                    {
                        headers: {
                            Accept: "application/json",
                            // Authorization: `Bearer ${token}`,
                        },
                    }
                );
            })
        );
    };

    const { mutateAsync, isLoading, error } = useMutation(removeExcludeStatus, {
        onSuccess: res => {
            queryClient.invalidateQueries(["rcStatusMonitorExclude"]);
            queryClient.invalidateQueries(["rcStatusMonitor", "RA"]);
            queryClient.invalidateQueries(["rcStatusMonitor", "Admin"]);
            queryClient.invalidateQueries(["rcStatusMonitor", "Weekend"]);
            // console.log("All exclusions removed successfully");
            return res;
        },
        onError: error => {
            queryClient.invalidateQueries(["rcStatusMonitorExclude"]);
            queryClient.invalidateQueries(["rcStatusMonitor", "RA"]);
            queryClient.invalidateQueries(["rcStatusMonitor", "Admin"]);
            queryClient.invalidateQueries(["rcStatusMonitor", "Weekend"]);
            console.error("Error on removing exclusions:", error);
            return error;
        },
    });

    return {
        removeExcludeStatus: mutateAsync,
        isLoading,
        error,
    };
};

export default useRCStatusMonitorExclude;
