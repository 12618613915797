import { Button, message } from "antd";
//import { publicIpv4 } from "public-ip";
import { useState, useEffect, useRef } from "react";

import bg_image from "../../assets/images/vantage-zoom-bg-D_removed.png";
import logo_jpg from "../../assets/images/vantage-full-logo-CMYK.jpg";
import KeyClockHelper from "../../helpers/keyClockHelper";
import { setRefreshToken, setToken } from "@/services/localStorage";

function SSOLoginPage() {
    //const [, setIpAddress] = useState<string>("");
    const [isLoading] = useState<boolean>(false);
    const kcHelper = new KeyClockHelper();
    const isLocalhost = window.location.origin.indexOf("localhost:3000") > -1;
    const devOrigin = `https://insight-dev.risk-vantagefx.com`;
    const popupChild = useRef<any>(undefined);

    useEffect(() => {
        window.addEventListener(
            "message",
            (e: any) => {
                if (e.origin !== devOrigin && (typeof e.data === "object" || e.data.hasOwnProperty("source") || e.data.hasOwnProperty("payload"))) {
                    return;
                }
                try {
                    let tokenInfo = JSON.parse(e.data);
                    if (tokenInfo.hasOwnProperty("token")) {
                        setToken(tokenInfo.token);
                    }
                    if (tokenInfo.hasOwnProperty("refreshToken")) {
                        setRefreshToken(tokenInfo.refreshToken);
                    }
                    if (popupChild.current !== undefined) {
                        popupChild.current.close();
                    }
                    window.location.href = `${window.location.origin}/ssoredirection`;
                } catch (error) {
                    message.error("Error in receiving message from parent window");
                }
            },
            false
        );

        return () => {};
    }, []);

    return (
        <div
            className="login-container"
            style={{
                backgroundColor: `#024854`,
                backgroundImage: `url(${bg_image})`,
                backgroundPosition: "top right",
                backgroundRepeat: "no-repeat",
            }}
        >
            <div className="login-form">
                <div>
                    <img alt={"logo"} src={logo_jpg} className="img-logo" />
                </div>
                <div className="title-div">
                    Welcome to the <span>Insight</span>
                </div>
                <div className="title-div-2">Please login to continue.</div>
                <Button
                    type="primary"
                    htmlType="submit"
                    className="login-btn"
                    loading={isLoading}
                    onClick={() => {
                        window.location.href = kcHelper.keyClockLoginUrl;
                    }}
                >
                    Login via VIMS
                </Button>
                {isLocalhost && (
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="login-btn"
                        style={{ width: "100%" }}
                        loading={isLoading}
                        onClick={() => {
                            popupChild.current = window.open(`${devOrigin}/ssolocahost?t=${Math.random()}`, "_blank");
                        }}
                    >
                        Log In (For localhost Dev Only)
                    </Button>
                )}
            </div>
        </div>
    );
}

export default SSOLoginPage;
