import { CloseOutlined, ExclamationCircleOutlined, MessageOutlined } from "@ant-design/icons";
import { Empty, Form, Modal, Timeline } from "antd";
import { useEffect, useState } from "react";
import { FormComponent } from "../../../../components/FormComponent";
import LoadingComponent from "../../../../components/Loading";
import { ComponentType, SUCCESS_FAILED } from "../../../../constants";
import { REQUIRED_FIELD } from "../../../../constants/errorMessage";
import { Comment } from "../../../../constants/type";
import "../../../../index.less";
import { apiRequest, APIs } from "../../../../services/apiConfig";
import { DataTableColumnRender, ErrorCatchValidator, ErrorMessageHandler } from "../../../../utils/Common";
import { ProfileInfoProps } from "../viewProfile";

interface CommentsHistoryProps {
    accountData: ProfileInfoProps;
    //loading: boolean;
    forPrint?: boolean;
    disableUpdate?: boolean;
}

const CommentsHistory = (props: CommentsHistoryProps) => {
    const [commentForm] = Form.useForm();
    const [visible, setVisible] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isAddAction, setIsAddAction] = useState<boolean>(false);
    const [data, setData] = useState<Comment[]>([]);
    let initialData = {
        comment: "",
    };
    const [comData] = useState<Comment>(initialData);

    const refetchCommentsHistory = () => {
        setIsLoading(true);
        apiRequest(APIs.GET_COMMENTS_HISTORY, {
            accountId: props?.accountData?.accountId,
            brandId: props?.accountData?.brandId,
            serverId: props?.accountData?.serverId,
            count: 10,
        })
            .then((data: any) => {
                setData(data);
            })
            .catch((error) => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("comments history", SUCCESS_FAILED.FAILED_LOAD_DATA, err)))
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        if ((props?.accountData?.accountId as number) < 0) {
            setIsLoading(false);
        } else {
            refetchCommentsHistory();
        }
        return () => {};
    }, []);

    const deleteModalContent = (values: Comment) => {
        Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            title: "Are you sure you want to delete this comment?",
            width: "50%",
            content: (
                <div className="delete-modal-comment">
                    <div className="delete-modal-comment-box">
                        <div className="delete-modal-comment-title">{values?.comment}</div>
                        <div className="delete-modal-comment-description">
                            {DataTableColumnRender.DateTime(values?.createdDateUtc)} - {values?.name}
                        </div>
                    </div>
                </div>
            ),
            onOk() {
                apiRequest(APIs.DELETE_COMMENT, {
                    accountId: props?.accountData?.accountId,
                    brandId: props?.accountData?.brandId,
                    serverId: props?.accountData?.serverId,
                    id: values.id,
                })
                    .then((data: any) => {
                        ErrorMessageHandler("Comment", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                        refetchCommentsHistory();
                    })
                    .catch((error) =>
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("comment", SUCCESS_FAILED.FAILED_DELETE_DATA, err))
                    );
            },
            onCancel() {},
        });
    };

    return (
        <div className={`comments-history nice-scrollbar ${props.forPrint ? "for-print" : ""}`}>
            <div className="top">
                <h1 className="title">Comments History</h1>
                {!props.forPrint && !props.disableUpdate && (
                    <MessageOutlined
                        style={{ fontSize: 20 }}
                        onClick={() => {
                            setVisible(true);
                            setIsAddAction(true);
                        }}
                    />
                )}
            </div>
            {isLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Loading..." />
                </div>
            ) : data?.length > 0 ? (
                <Timeline>
                    {data.map((currData: any) => {
                        let isLineThrough = currData.isDeleted;
                        return (
                            <Timeline.Item key={currData?.id}>
                                <div className="timeline-box">
                                    <div className="timeline-head">
                                        <div className={`timeline-title ${isLineThrough ? "text-line-throught" : ""}`}>{currData?.comment}</div>
                                        {currData.canModify && (
                                            <CloseOutlined style={{ color: "#8e8e8e" }} onClick={() => deleteModalContent(currData)} />
                                        )}
                                    </div>
                                    <div className={`timeline-description ${isLineThrough ? "text-line-throught" : ""}`}>
                                        {DataTableColumnRender.DateTime(currData?.createdDateUtc)} - {currData?.name}
                                    </div>
                                </div>
                            </Timeline.Item>
                        );
                    })}
                </Timeline>
            ) : (
                <Empty />
            )}

            <Modal
                open={visible}
                width={"50%"}
                title={"Create New Comment"}
                onCancel={() => {
                    commentForm.resetFields();
                    setVisible(false);
                }}
                onOk={() => {
                    commentForm.validateFields().then((values: any) => {
                        commentForm.resetFields();
                        apiRequest(APIs.CREATE_COMMENT, {
                            accountId: props.accountData.accountId,
                            brandId: props.accountData.brandId,
                            serverId: props.accountData.serverId,
                            ...values,
                        })
                            .then((data: any) => {
                                ErrorMessageHandler("New comment", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                                refetchCommentsHistory();
                                setVisible(false);
                            })
                            .catch((error: any) =>
                                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("new comment", SUCCESS_FAILED.FAILED_CREATE_DATA, err))
                            );
                    });
                }}
            >
                <Form form={commentForm} labelCol={{ span: 4.5 }} wrapperCol={{ span: 20 }} layout="horizontal" initialValues={comData}>
                    {!isAddAction && <FormComponent label={""} name={"id"} extra={{ type: ComponentType.hidden, value: "" }} />}
                    <FormComponent
                        label="Comment"
                        name={"comment"}
                        extra={{
                            type: ComponentType.textarea,
                            value: "",
                            rules: [
                                {
                                    required: true,
                                    message: REQUIRED_FIELD,
                                },
                            ],
                            inputProps: {
                                showCount: true,
                                maxLength: 1000,
                                rows: 10,
                            },
                        }}
                    />
                </Form>
            </Modal>
        </div>
    );
};

export default CommentsHistory;
