import { HomeOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FlexiDataTable from "../../../components/FlexiDataTable";
import SitePageHeader from "../../../components/PageHeader";
import { ComponentType, SUCCESS_FAILED } from "../../../constants";
import { TagProfileLogs, FlexiDataTableOptionsProps, FlexiDataTableCallbackProps } from "../../../constants/type";
import { apiRequest, APIs } from "../../../services/apiConfig";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";

interface TagViewLogsPageProps {
    id: string;
    name: string;
}

const getInfo = (viewState: string): TagViewLogsPageProps | undefined => {
    let vInfo = atob(viewState).split("|");
    return vInfo.length === 2
        ? {
              id: vInfo[0],
              name: vInfo[1],
          }
        : undefined;
};

function TagViewLogsPage() {
    let navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [dataSource, setDataSource] = useState<TagProfileLogs[]>([]);
    let { id } = useParams();
    const vInfo = getInfo(id || "") || {
        id: "",
        name: "",
    };

    useEffect(() => {
        setIsLoading(true);
        apiRequest(`${APIs.GET_TAG_PROFILE_LOGS}`, { tagProfileId: vInfo.id })
            .then((data: any) => {
                if (data !== null && data && data.length > 0) {
                    const newData = data.map((currData: TagProfileLogs, index: number) => {
                        return {
                            key: index,
                            ...currData,
                        };
                    });
                    setDataSource(newData);
                } else {
                    setDataSource([]);
                }
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("tag profile logs", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
            })
            .finally(() => setIsLoading(false));
        return () => {};
    }, [id]);

    const columns = [
        {
            title: "Changes",
            dataIndex: "changes",
            key: "changes",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
            render: (changes: any) => {
                const newChanges = changes.split("\r").map((x: any, index: number) => <p key={index}>{x}</p>);
                return newChanges;
            },
        },

        DTColProps.Middle({
            title: "Modified By",
            dataIndex: "user",
            key: "user",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),

        DTColProps.DateTime({
            title: "Modified At (Local)",
            dataIndex: "dateUtc",
            key: "dateUtc",
            defaultSortOrder: "descend",
            options: {
                filter: {
                    type: ComponentType.daterange,
                    value: "",
                    inputProps: {
                        showTime: true,
                    },
                },
            },
        }),
    ];

    const options: FlexiDataTableOptionsProps = {};

    const componentCallback: FlexiDataTableCallbackProps = (type, LogData) => {
        switch (type) {
            default:
                break;
        }
    };

    return (
        <SitePageHeader
            title={`View Change Logs for "${vInfo.name}"`}
            routes={[
                {
                    path: "/siteadmin/tag",
                    breadcrumbName: "Tag Management",
                    icon: <HomeOutlined />,
                },
                {
                    path: "",
                    breadcrumbName: "View Change Logs",
                },
            ]}
            onBack={() => navigate("/siteadmin/tag")}
        >
            <FlexiDataTable
                rowKeyProperty="key"
                title=""
                columns={columns}
                options={options}
                dataSource={dataSource || []}
                callback={componentCallback}
                loading={isLoading}
            />
        </SitePageHeader>
    );
}

export default TagViewLogsPage;
