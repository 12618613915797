import { useState, useEffect } from "react";
import FlexiDataTable from "../../../components/FlexiDataTable";
import { ComponentType, CALLBACK_KEY, SUCCESS_FAILED } from "../../../constants";
import { FlexiDataColumnProps, CustomPaginationProps, FlexiDataTableCallbackProps, HFTTypesList } from "../../../constants/type";
import { apiRequest } from "../../../services/apiConfig";
import { APIs } from "../../../services/apis";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";
import moment from "moment";

interface HFTViewProps {
    HFTTypesList: HFTTypesList[];
    currentViewType: number;
    onViewTypeChanged: (data: number, selectAccountInfo: any) => void;
}

const HFTView = (props: HFTViewProps) => {
    const getColumnsConfig = (HFTTypesList: HFTTypesList[] = []) => {
        return [
            DTColProps.XSmall({
                title: "User ID",
                dataIndex: "userId",
                key: "userId",
                sorter: (a: any, b: any) => a["userId"] - b["userId"],
                options: {
                    filter: {
                        type: ComponentType.text,
                    },
                },
            }),

            DTColProps.XSmall({
                title: "Symbol",
                dataIndex: "symbol",
                key: "symbol",
                sorter: (a: any, b: any) => a["symbol"].localeCompare(b["symbol"]),
                options: {
                    filter: {
                        type: ComponentType.text,
                    },
                },
            }),

            DTColProps.XSmall({
                title: "HFT Type",
                dataIndex: "hftType",
                key: "hftType",
                sorter: (a: any, b: any) => a["hftType"].localeCompare(b["hftType"]),
                render: (hftType: number) => {
                    let fidx: number = HFTTypesList.findIndex((x) => x.id === hftType);
                    if (fidx > -1) return HFTTypesList[fidx].id;
                    return hftType;
                },
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: HFTTypesList.map((x) => ({ text: x.name, value: x.id })),
                        inputProps: {
                            mode: "multiple",
                        },
                    },
                },
            }),

            DTColProps.XSmall(
                {
                    title: "Absolute Sum HFT Volume",
                    dataIndex: "absoluteSumHFTVolume",
                    key: "absoluteSumHFTVolume",
                    sorter: (a: any, b: any) => a["absoluteSumHFTVolume"] - b["absoluteSumHFTVolume"],
                },
                ["text-right"]
            ),

            DTColProps.XSmall(
                {
                    title: "Count",
                    dataIndex: "count",
                    key: "count",
                    sorter: (a: any, b: any) => a["count"] - b["count"],
                },
                ["text-right"]
            ),
        ];
    };

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(true);
    const [tableCols, setTableCols] = useState<FlexiDataColumnProps[]>([...getColumnsConfig(props.HFTTypesList)]);
    const [data, setData] = useState<any[]>([]);
    const [pagination, setPagination] = useState<CustomPaginationProps>({
        current: 1,
        pageSize: 50,
        total: 0,
    });
    const [filterParams, setFilterParams] = useState<any>({});
    const [sorting, setSorting] = useState<string>("");
    const [refreshRate, setRefreshRate] = useState<number>(30);
    const [transactionDateTimeFrom, setTransactionDateTimeFrom] = useState<any>(moment.utc().subtract(24, "hours"));
    const [transactionDateTimeTo, setTransactionDateTimeTo] = useState<any>(moment.utc());

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.VIEW_RECORD:
                const hfttypeObj = props.HFTTypesList.find((x) => x.name === FormData.hftType);
                props.onViewTypeChanged(2, {
                    userId: FormData.userId,
                    hftType: hfttypeObj?.id,
                    symbol: FormData.symbol,
                    transactionDateTimeFrom,
                    transactionDateTimeTo,
                });
                break;
            case CALLBACK_KEY.HANDLE_PAGINATION_SORTING:
                setSorting(
                    FormData.sorter.column && FormData.sorter.order
                        ? `${FormData.sorter.column.key},${FormData.sorter.order === "ascend" ? "asc" : "desc"}`
                        : ""
                );
                setPagination((prev) => ({ ...prev, current: FormData.pagination.current, pageSize: FormData.pagination.pageSize }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                let filterParams: any = {};
                Object.keys(FormData)
                    .filter((x) => FormData[x] && (Array.isArray(FormData[x]) ? FormData[x].length > 0 : FormData[x].toString().length > 0))
                    .map((x) => {
                        if (x === "hftType") {
                            filterParams["hftTypes"] = FormData[x];
                        } else if (x === "openClose") {
                            filterParams["openCloses"] = FormData[x];
                        } else if (x === "side") {
                            filterParams["tradeSides"] = FormData[x];
                        } else if (x === "symbol") {
                            filterParams["symbols"] = FormData[x];
                            // } else if (x === "transactionDateTime") {
                            //     filterParams["transactionDateTimeFrom"] = FormData[x][0];
                            //     filterParams["transactionDateTimeTo"] = FormData[x][1];
                        } else {
                            filterParams[x] = FormData[x];
                        }
                        return x;
                    });
                setFilterParams(filterParams);
                setPagination((prev) => ({ ...prev, current: 1 }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.REFRESH_RATE_CHANGED:
                setRefreshRate(FormData.currentRefreshPeriod);
                break;
            case CALLBACK_KEY.REFRESH:
                getHFTList();
                setTransactionDateTimeFrom(moment.utc().subtract(24, "hours"));
                setTransactionDateTimeTo(moment.utc());
                break;
        }
    };

    const getHFTList = () => {
        apiRequest(APIs.GET_GROUPED_HFT, {
            limit: pagination.pageSize,
            current: pagination.current,
            transactionDateTimeFrom,
            transactionDateTimeTo,
            ...(Object.keys(filterParams).length > 0 && { ...filterParams }),
            ...(sorting.length > 0 && { order: sorting }),
        })
            .then((data: any) => {
                if (data && data.result && data.result.length > 0) {
                    let markupData = data.result.map((x: any) => ({ ...x, Id: `${x.userId}-${x.symbol}-${x.hftType}-${x.count}` }));
                    setData(markupData);
                    setPagination((prev) => ({ ...prev, total: data.total }));
                } else {
                    setData([]);
                    setPagination((prev) => ({ ...prev, total: 0 }));
                }
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("HFT-BITI Grouped List View", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                setData((prev) => [...prev]);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            getHFTList();
            setRunRefetchDataList(false);
        }
        return () => {};
    }, [runRefetchDataList]);

    useEffect(() => {
        setTableCols(getColumnsConfig(props.HFTTypesList));
        return () => {};
    }, [props.HFTTypesList]);

    return (
        <>
            <div style={props.currentViewType === 2 ? { display: "none" } : {}} className="hft-view">
                <FlexiDataTable
                    rowKeyProperty="Id"
                    title=""
                    columns={tableCols}
                    options={{
                        view: true,
                        serverFiltering: true,
                        refresh: {
                            timer: true,
                            refreshSecond: refreshRate,
                            enablePeriodSelection: true,
                        },
                    }}
                    dataSource={data ?? []}
                    callback={componentCallback}
                    loading={isLoading}
                    pagination={pagination}
                    serverSide={true}
                    tableProps={{
                        bordered: true,
                    }}
                />
            </div>
        </>
    );
};

export default HFTView;
