import EmptyData from "../../../../components/Common/Empty";
import AlarmDashboardTimelineItem, { AssigneeProps, TimelineItemProps } from "./alarmDashboardTimelineItem";
import { ALARM_DASHBOARD_ITEM_CALLBACK_KEY, SUCCESS_FAILED } from "../../../../constants";
import { Form, Segmented, Select, Modal } from "antd";
import { SegmentedValue } from "antd/lib/segmented";
import { useCallback, useEffect, useMemo, useState } from "react";
import { KeyValuePair, ProfileProps } from "../../../../constants/type";
import { getAlarmItemFeatureModalProps } from "./alarmDashboardContentComponent";
import { alarmItemDataProps, callbackParams } from "../../../../constants/type";
import { ModalProps } from "../../../../constants/type";
import { apiRequest } from "../../../../services/apiConfig";
import { APIs } from "../../../../services/apis";
import { ErrorCatchValidator, ErrorMessageHandler } from "../../../../utils/Common";
import { DefaultIfEmpty, objectRemoveProps } from "../../../../utils/object";
import { getProfile } from "../../../../services/localStorage";
import { isEmptyOrNull } from "../../../../utils/string";
import { ARRAY_ACTION_TYPE, GetConstraintKeyList, ToObjectWithKey } from "../../../../utils/array";
import moment from "moment";

export interface AlarmDashboardAssignPanelProps {
    data: TimelineItemProps[];
    callback: (type: ALARM_DASHBOARD_ITEM_CALLBACK_KEY, data: any) => void;
    utcOffset: number | undefined;
    isLoading: boolean;
    mappingData: any;
    lpMappingData: any;
    statusObject: any;
    userOptions: KeyValuePair[];
    editable: boolean;
}

const AlarmDashboardAssignPanel = (props: AlarmDashboardAssignPanelProps) => {
    const [selectedSegment, setSelectedSegment] = useState<number>(2);
    const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [callbackParams, setCallbackParams] = useState<callbackParams | undefined>(undefined);
    const [commentForm] = Form.useForm();

    const timelineItemCallback = (type: ALARM_DASHBOARD_ITEM_CALLBACK_KEY, data: TimelineItemProps) => {
        switch (type) {
            case ALARM_DASHBOARD_ITEM_CALLBACK_KEY.COMMENT_HISTORICAL:
                setCallbackParams({ type, data });
                setIsModalVisible(true);
                break;
            case ALARM_DASHBOARD_ITEM_CALLBACK_KEY.SEVERITY_PROGRESS:
                setCallbackParams({ type, data });
                setIsModalVisible(true);
                break;
            case ALARM_DASHBOARD_ITEM_CALLBACK_KEY.LEAVE_COMMENT:
                commentForm.setFieldsValue({
                    clientUsedCommentType: "",
                    mode: isEmptyOrNull(data.correlationHashKey) ? 1 : 3,
                    alarmEventId: data.alarmEventId,
                    correlationHashKey: data.correlationHashKey,
                    moduleCode: data.moduleCode,
                    statusCode: data.eventStatusCode,
                    comment: "",
                });
                setCallbackParams({ type, data });
                setIsModalVisible(true);
                break;
            case ALARM_DASHBOARD_ITEM_CALLBACK_KEY.TASK_ASSIGN:
                commentForm.setFieldsValue({
                    alarmEventId: data.alarmEventId,
                    targetDueDateUtc: isEmptyOrNull(data.targetDueDateUtc) ? "" : moment(data.targetDueDateUtc),
                    assigneeId: isEmptyOrNull(data.assignees) ? "" : (data.assignees as AssigneeProps[])[0].userId,
                });
                setCallbackParams({ type, data });
                setIsModalVisible(true);
                break;
        }
    };

    const modalProps: ModalProps = useMemo(() => {
        let returnParams: ModalProps = getAlarmItemFeatureModalProps(
            props.utcOffset,
            callbackParams,
            props.statusObject,
            props.mappingData,
            props.lpMappingData,
            props.userOptions,
            commentForm
        );
        if (callbackParams !== undefined) {
            switch (callbackParams.type) {
                case ALARM_DASHBOARD_ITEM_CALLBACK_KEY.LEAVE_COMMENT:
                    returnParams.extraModalProps = {
                        ...returnParams.extraModalProps,
                        onOk: () => {
                            commentForm
                                .validateFields()
                                .then(values => {
                                    if (values.mode === 1) {
                                        updateCommentAndStatus(objectRemoveProps(values, ["clientUsedCommentType", "mode", "correlationHashKey"]));
                                    } else if (values.mode === 2) {
                                        updateCommentAndStatus(objectRemoveProps(values, ["clientUsedCommentType", "mode", "alarmEventId"]));
                                    } else if (values.mode === 3) {
                                        updateCommentAndStatus(objectRemoveProps(values, ["clientUsedCommentType", "mode"]));
                                    }
                                    setIsModalVisible(false);
                                    commentForm.resetFields();
                                })
                                .catch(err => console.log(err));
                        },
                    };
                    break;
                case ALARM_DASHBOARD_ITEM_CALLBACK_KEY.TASK_ASSIGN:
                    returnParams.extraModalProps = {
                        ...returnParams.extraModalProps,
                        onOk: () => {
                            commentForm
                                .validateFields()
                                .then(values => {
                                    assignTaskApiRequest(values);
                                    setIsModalVisible(false);
                                    commentForm.resetFields();
                                })
                                .catch(err => console.log(err));
                        },
                    };
                    break;
            }
        }

        return returnParams;
    }, [props.statusObject, props.mappingData, props.userOptions, callbackParams]);

    const alarmItemData: alarmItemDataProps = useMemo(() => {
        if (selectedSegment === 1) {
            let allUserIds = props.data.reduce((ids: number[], x: TimelineItemProps) => {
                if (!isEmptyOrNull(x.assignees)) {
                    ids = ids.concat(x.assignees?.map(y => y.userId) || []);
                }
                return ids;
            }, []);
            return {
                userOptions: props.userOptions
                    .filter(x => allUserIds.includes(x.value as number))
                    .map(x => ({ label: x.text.replace("(Assign to Me)", ""), value: x.value })),
                alarmList: isEmptyOrNull(selectedUsers)
                    ? props.data
                    : props.data.filter(x =>
                          !isEmptyOrNull(x.assignees) ? x.assignees?.some(y => selectedUsers.includes(y.userId)) || false : false
                      ),
            } as alarmItemDataProps;
        }

        let userProfile: ProfileProps | undefined = getProfile();
        if (userProfile !== undefined) {
            let myId = (userProfile as ProfileProps).id;
            return {
                userOptions: props.userOptions.map(x => ({ label: x.text.replace("(Assign to Me)", ""), value: x.value })),
                alarmList: props.data.filter(x => (!isEmptyOrNull(x.assignees) ? x.assignees?.some(y => y.userId === myId) || false : false)),
            };
        }

        return {
            userOptions: props.userOptions.map(x => ({ label: x.text.replace("(Assign to Me)", ""), value: x.value })),
            alarmList: props.data,
        } as alarmItemDataProps;
    }, [props.data, props.userOptions, selectedSegment, selectedUsers]);

    const assignTaskApiRequest = (data: any) => {
        if (isEmptyOrNull(data.targetDueDateUtc)) {
            delete data.targetDueDateUtc;
        }

        apiRequest(APIs.UPDATE_ALARM_ASSIGN_TASK, data)
            .then(res => {
                ErrorMessageHandler("task assign", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
            })
            .catch(error => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("task assign", SUCCESS_FAILED.FAILED_UPDATE_DATA, err)))
            .finally(() => props.callback && props.callback(ALARM_DASHBOARD_ITEM_CALLBACK_KEY.REFRESH_LIST, {}));
    };

    const updateCommentAndStatus = useCallback(
        (data: any) => {
            let statusCodeKeyObj: any = ToObjectWithKey(props.statusObject[data.moduleCode], "value", "isConsiderCaseClosed"),
                refreshNeeded = DefaultIfEmpty(statusCodeKeyObj, data.statusCode, false);
            apiRequest(APIs.UPDATE_ALARM_STATUS, data)
                .then(res => {
                    ErrorMessageHandler("alarm comment", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                })
                .catch(error =>
                    ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("alarm comment", SUCCESS_FAILED.FAILED_UPDATE_DATA, err))
                )
                .finally(() => refreshNeeded && props.callback && props.callback(ALARM_DASHBOARD_ITEM_CALLBACK_KEY.REFRESH_LIST, {}));
        },
        [props.statusObject, props.callback]
    );

    useEffect(() => {
        let allUserIds = props.data.reduce((ids: number[], x: TimelineItemProps) => {
            if (!isEmptyOrNull(x.assignees)) {
                ids = ids.concat(x.assignees?.map(y => y.userId) || []);
            }
            return ids;
        }, []);

        setSelectedUsers(GetConstraintKeyList(selectedUsers, allUserIds, ARRAY_ACTION_TYPE.INNER));
    }, [props.data]);

    return (
        <>
            <div className="assigned-event-panel">
                <div className="header-container">
                    <div className="left">
                        <Segmented
                            value={selectedSegment}
                            options={[
                                { label: "All Assigned Event", value: 1 },
                                { label: "Assigned To Me", value: 2 },
                            ]}
                            onChange={(value: SegmentedValue) => setSelectedSegment(value as number)}
                        />
                    </div>
                    <div className="right">
                        {selectedSegment === 1 && (
                            <Select
                                placeholder="Please select User"
                                options={alarmItemData.userOptions}
                                value={selectedUsers}
                                mode="multiple"
                                className="user-selection"
                                onChange={(value: number[]) => setSelectedUsers(value)}
                            />
                        )}
                    </div>
                </div>
                <div className="content-container nice-scrollbar">
                    {alarmItemData.alarmList.length > 0 ? (
                        alarmItemData.alarmList.map((x: TimelineItemProps, index: number) => (
                            <AlarmDashboardTimelineItem
                                key={`aldsb-pl-i-${index}`}
                                data={x}
                                callback={timelineItemCallback}
                                utcOffset={props.utcOffset}
                                mappingData={props.mappingData}
                                editable={props.editable}
                                lpMappingData={props.lpMappingData}
                            />
                        ))
                    ) : (
                        <EmptyData />
                    )}
                </div>
            </div>
            <Modal
                maskClosable={false}
                open={isModalVisible}
                destroyOnClose={true}
                wrapClassName="alarmdashboard-unassigned-item-modal-popover"
                onCancel={() => {
                    setIsModalVisible(false);
                }}
                {...(modalProps.extraModalProps && modalProps.extraModalProps)}
                style={{ top: 20 }}
            >
                {modalProps.component}
            </Modal>
        </>
    );
};

export default AlarmDashboardAssignPanel;
