import React, { useEffect, useMemo, useState } from "react";
import FlexiDataTable from "../../../../components/FlexiDataTable";
import { BrandsList, CrmBrandList, CustomPaginationProps, FlexiDataTableCallbackProps, RegulatorList, ServersList, ToxicClientCheckHistoricalRestrictionAuditLog, ToxicClientRestrictions, UsersList, accountRestrictionsHistoricalLog, clientRestrictionsHistoricalLog, triggeredByAccounts } from "../../../../constants/type";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "../../../../constants";
import { DateTimeUtil } from "../../../../utils/datetime";
import { APIs, apiRequest } from "../../../../services/apiConfig";
import { DTColProps, DataTableColumnRender, ErrorCatchValidator, ErrorMessageHandler } from "../../../../utils/Common";
import { isEmptyOrNull } from "../../../../utils/string";
import { ArrowRightOutlined, CheckCircleOutlined } from "@ant-design/icons";

const HistoricalRestrictionAuditLog = () => {
    const [data, setData] = useState<ToxicClientCheckHistoricalRestrictionAuditLog[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [sorting, setSorting] = useState<string>("");
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [pagination, setPagination] = useState<CustomPaginationProps>({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [filterParams, setFilterParams] = useState<any>({});
    const [servers, setServers] = useState<ServersList[]>([]);
    const [brands, setBrands] = useState<BrandsList[]>([]);
    const [regulators, setRegulators] = useState<RegulatorList[]>([]);
    const [users, setUsers] = useState<UsersList[]>([]);
    const [crmBrands, setCrmBrands] = useState<CrmBrandList[]>([]);
    const [tcRestrictions, setTcRestrictions] = useState<ToxicClientRestrictions[]>([]);

    const columns = useMemo(() => [
        {
            title: "Account ID",
            dataIndex: "accountId",
            key: "accountId",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: [],
                    inputProps: {
                        mode: "tags",
                        placeholder: "Please input account id",
                        tokenSeparators: [",", "，", " "],
                    },
                },
                visible: false,
            },
        },
        {
            title: "Server",
            dataIndex: "serverId",
            key: "serverId",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: servers.map((x: ServersList) => ({
                        text: x.server,
                        value: x.id
                    })),
                    inputProps: { mode: "multiple" },
                },
                visible: false,
            },
        },
        {
            title: "Brand",
            dataIndex: "brandId",
            key: "brandId",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: brands.map((x: BrandsList) => ({
                        text: x.brand,
                        value: x.id
                    })),
                    inputProps: { mode: "multiple" },
                },
                visible: false,
            },
        },
        {
            title: "Client ID",
            dataIndex: "clientId",
            key: "clientId",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: [],
                    inputProps: {
                        mode: "tags",
                        placeholder: "Please input client id",
                        tokenSeparators: [",", "，", " "],
                    },
                },
                visible: false,
            },
        },
        {
            title: "CRM Brand",
            dataIndex: "crmBrandId",
            key: "crmBrandId",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: crmBrands.map((x: CrmBrandList) => ({
                        text: x.brand,
                        value: x.id
                    })),
                    inputProps: { mode: "multiple" },
                },
                visible: false,
            },
        },
        {
            title: "Regulator",
            dataIndex: "regulatorId",
            key: "regulatorId",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: regulators.map((x: RegulatorList) => ({
                        text: x.name,
                        value: x.id
                    })),
                    inputProps: { mode: "multiple" },
                },
                visible: false,
            },
        },
        {
            title: "Restriction Name",
            dataIndex: "restrictionCode",
            key: "restrictionCode",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: tcRestrictions.map((x: ToxicClientRestrictions) => ({
                        text: x.restrictionName,
                        value: x.restrictionCode
                    })),
                    inputProps: { mode: "multiple" },
                },
                visible: false,
            },
        },
        DTColProps.Middle({
            title: "Created By",
            dataIndex: "createdBy",
            key: "createdBy",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: users.map((x: UsersList) => ({
                        text: `${x.name} | ${x.email}`,
                        value: x.id
                    })),
                    inputProps: { mode: "multiple" },
                },
            },
            render: (createdBy: number) => {
                let thisOriUser: any = users.find((y: UsersList) => y.id === createdBy);
                return <div>{thisOriUser && thisOriUser.name}</div>;
            },
        }),
        DTColProps.Middle({
            title: "Created Date (Local)",
            dataIndex: "createdDateUtc",
            key: "createdDateUtc",
            sorter: true,
            options: {
                filter: {
                    type: ComponentType.daterange,
                    inputProps: {
                        showTime: { format: "HH:mm:ss" },
                    },
                },
            },
            render: (createdDateUtc: string) => DataTableColumnRender.DateTime(createdDateUtc),
        }),
    ], [servers, brands, crmBrands, regulators, users, tcRestrictions]);

    const expandedAccColumns = useMemo(() => [
        DTColProps.XSmall({
            title: "Account ID",
            dataIndex: "accountId",
            key: "accountId",
            render: (accountId: number) => isEmptyOrNull(accountId) ? "-" : accountId,
        }),
        DTColProps.XSmall({
            title: "Server",
            dataIndex: "serverId",
            key: "serverId",
            render: (serverId: number) => {
                let thisServer = servers.find((x: ServersList) => x.id === serverId);
                return isEmptyOrNull(thisServer) ? "-" : thisServer?.server;
            },
        }),
        DTColProps.XSmall({
            title: "Brand",
            dataIndex: "brandId",
            key: "brandId",
            render: (brandId: number) => {
                let thisBrand = brands.find((x: BrandsList) => x.id === brandId);
                return isEmptyOrNull(thisBrand) ? "-" : thisBrand?.brand;
            },
        }),
        DTColProps.Small({
            title: "Restrictions",
            dataIndex: "restrictionCode",
            key: "restrictionCode",
            render: (restrictionCode: string) => {
                let thisRestriction = tcRestrictions.find((x: ToxicClientRestrictions) => x.restrictionCode === restrictionCode);
                return isEmptyOrNull(thisRestriction) ? restrictionCode : thisRestriction?.restrictionName;
            },
        }),
        DTColProps.Middle({
            title: "Changes",
            dataIndex: "changes",
            key: "changes",
            render: (_: any, rowData: accountRestrictionsHistoricalLog) => (
                <>
                    <span className="before">
                        {isEmptyOrNull(rowData.before) ? "No value" : rowData.before} <>&nbsp;</>
                    </span>
                    <ArrowRightOutlined style={{ fontSize: 12 }} /> <>&nbsp;</>
                    {isEmptyOrNull(rowData.after) ? "No value" : rowData.after}
                </>
            ),
        }),
        DTColProps.Small({
            title: "Execution Status",
            dataIndex: "isDownstreamIntegrated",
            render: (isDownstreamIntegrated: boolean) => (
                <CheckCircleOutlined style={{ color: "#0ab76e", fontSize: 14 }} />
            ),
        },
            ["text-center"]
        ),
        // DTColProps.Middle({
        //     title: "Remarks",
        //     dataIndex: "remarks",
        // }),
    ], [servers, brands, tcRestrictions]);

    const expandedCliColumns = useMemo(() => [
        DTColProps.XSmall({
            title: "Client ID",
            dataIndex: "clientId",
            key: "clientId",
            render: (clientId: number) => isEmptyOrNull(clientId) ? "-" : clientId,
        }),
        DTColProps.XSmall({
            title: "CRM Brand",
            dataIndex: "crmBrandId",
            key: "crmBrandId",
            render: (crmBrandId: number) => {
                let thisCrmBrand = crmBrands.find((x: CrmBrandList) => x.id === crmBrandId);
                return isEmptyOrNull(thisCrmBrand) ? "-" : thisCrmBrand?.brand;
            },
        }),
        DTColProps.XSmall({
            title: "Regulator",
            dataIndex: "regulatorId",
            key: "regulatorId",
            render: (regulatorId: number) => {
                let thisRegulator = regulators.find((x: RegulatorList) => x.id === regulatorId);
                return isEmptyOrNull(thisRegulator) ? "-" : thisRegulator?.name;
            },
        }),
        DTColProps.Small({
            title: "Restrictions",
            dataIndex: "restrictionCode",
            key: "restrictionCode",
            render: (restrictionCode: string) => {
                let thisRestriction = tcRestrictions.find((x: ToxicClientRestrictions) => x.restrictionCode === restrictionCode);
                return isEmptyOrNull(thisRestriction) ? restrictionCode : thisRestriction?.restrictionName;
            },
        }),
        DTColProps.Middle({
            title: "Changes",
            dataIndex: "changes",
            key: "changes",
            render: (_: any, rowData: accountRestrictionsHistoricalLog) => (
                <>
                    <span className="before">
                        {isEmptyOrNull(rowData.before) ? "No value" : rowData.before} <>&nbsp;</>
                    </span>
                    <ArrowRightOutlined style={{ fontSize: 12 }} /> <>&nbsp;</>
                    {isEmptyOrNull(rowData.after) ? "No value" : rowData.after}
                </>
            ),
        }),
        DTColProps.Middle({
            title: "Triggered By Accounts",
            dataIndex: "triggeredByAccounts",
            render: (_: any, rowData: clientRestrictionsHistoricalLog) => (
                <div className="triggered-by-accounts-col">
                    {!isEmptyOrNull(rowData.triggeredByAccounts) && (
                        <ul>
                            {rowData.triggeredByAccounts.map((x: triggeredByAccounts, i: number) => {
                                let thisServer = servers.find((y: any) => y.id === x.serverId);
                                let thisBrand = brands.find((y: any) => y.id === x.brandId);
                                return (
                                    <li key={i}>{`Account ID: ${x.accountId}, Server: ${isEmptyOrNull(thisServer) ? "" : thisServer?.server}, Brand:  ${isEmptyOrNull(thisBrand) ? "" : thisBrand?.brand}`}</li>
                                );
                            })}
                        </ul>
                    )}
                </div>
            ),
        }),
        DTColProps.Small({
            title: "Execution Status",
            dataIndex: "isDownstreamIntegrated",
            render: (isDownstreamIntegrated: boolean) => (
                <CheckCircleOutlined style={{ color: "#0ab76e", fontSize: 14 }} />
            ),
        },
            ["text-center"]
        ),
        // DTColProps.Middle({
        //     title: "Remarks",
        //     dataIndex: "remarks",
        // }),
    ], [crmBrands, regulators, tcRestrictions, servers, brands]);

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.HANDLE_PAGINATION_SORTING:
                setSorting(
                    FormData.sorter.column && FormData.sorter.order
                        ? `${FormData.sorter.column.key},${FormData.sorter.order === "ascend" ? "asc" : "desc"}`
                        : ""
                );
                setPagination((prev) => ({ ...prev, current: FormData.pagination.current, pageSize: FormData.pagination.pageSize }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                let fParams: any = {};
                Object.keys(FormData)
                    .filter((x) => FormData[x] !== undefined && FormData[x].toString().length > 0)
                    .map((x) => {
                        if (x === "createdDateUtc") {
                            if (FormData[x] === null) return false;
                            else {
                                fParams["createdDateUtcFrom"] = DateTimeUtil.GetUTC(FormData[x][0]);
                                fParams["createdDateUtcTo"] = DateTimeUtil.GetUTC(FormData[x][1]);
                            }
                        } else if (x === "serverId") {
                            fParams["serverIds"] = FormData[x];
                        } else if (x === "brandId") {
                            fParams["brandIds"] = FormData[x];
                        } else if (x === "crmBrandId") {
                            fParams["crmBrandIds"] = FormData[x];
                        } else if (x === "regulatorId") {
                            fParams["regulatorIds"] = FormData[x];
                        } else if (x === "restrictionCode") {
                            fParams["restrictionCodes"] = FormData[x];
                        } else if (x === "accountId") {
                            let intAccIds: number[] = [];
                            FormData[x].forEach((y: string) => intAccIds.push(Number(y)));
                            fParams["accountIds"] = intAccIds;
                        } else if (x === "clientId") {
                            let intClientIds: number[] = [];
                            FormData[x].forEach((y: string) => intClientIds.push(Number(y)));
                            fParams["clientIds"] = intClientIds;
                        } else {
                            fParams[x] = FormData[x];
                        }
                        return x;
                    });
                setFilterParams(fParams);
                setPagination((prev) => ({ ...prev, current: 1 }));
                setRunRefetchDataList(true);
                break;
            default:
                break;
        };
    };

    const getExpandedRowRender = (rowData: ToxicClientCheckHistoricalRestrictionAuditLog) => {
        return (
            <>
                <div className="tccl-expanded-row-container">
                    {rowData.changes.accountRestrictions.length > 0 && (
                        <div className="tccl-expandable-table" key={"account"}>
                            <FlexiDataTable
                                bordered
                                rowKeyProperty="id"
                                title="Account Restrictions"
                                columns={expandedAccColumns}
                                options={{
                                    enableFilter: false,
                                }}
                                dataSource={rowData.changes.accountRestrictions}
                                callback={() => { }}
                                loading={false}
                                pagination={{
                                    hideOnSinglePage: true,
                                }}
                            />
                        </div>
                    )}
                    {rowData.changes.clientRestrictions.length > 0 && (
                        <div className={`tccl-expandable-table ${rowData.changes.accountRestrictions.length > 0 ? "more-than-one" : ""}`} key={"client"}>
                            <FlexiDataTable
                                bordered
                                rowKeyProperty="id"
                                title="Client Restrictions"
                                columns={expandedCliColumns}
                                options={{
                                    enableFilter: false,
                                }}
                                dataSource={rowData.changes.clientRestrictions}
                                callback={() => { }}
                                loading={false}
                                pagination={{
                                    hideOnSinglePage: true,
                                }}
                            />
                        </div>
                    )}
                </div>
            </>
        );
    };

    const getConfig = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, {
            filterType: ["server", "brand", "regulator", "user", "crmserver", "toxicclientrestriction"],
        })
            .then((data: any) => {
                setServers(data.servers);
                setBrands(data.brands);
                setRegulators(data.regulators);
                setUsers(data.users);
                setCrmBrands(data.crmBrands);
                setTcRestrictions(data.toxicClientRestrictions);
                setRunRefetchDataList(true);
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => console.log("Failed to get filter config list: ", err)));
    };

    const getHistoricalRestrictionAuditLog = () => {
        apiRequest(APIs.GET_HISTORICAL_TOXIC_CLIENT_CHECK_RESTRICTION_AUDIT_LOGS, {
            limit: pagination.pageSize,
            current: pagination.current,
            ...(Object.keys(filterParams).length > 0 && { ...filterParams }),
            ...(sorting.length > 0 && { order: sorting }),
        })
            .then((data: any) => {
                if (!isEmptyOrNull(data)) {
                    if (data.result.length > 0) {
                        setData(data.result);
                        setPagination((prev) => ({ ...prev, total: data.total }));
                    }
                } else {
                    setData([]);
                    setPagination((prev) => ({ ...prev, total: 0 }));
                };
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("historical toxic client check restriction audit logs", SUCCESS_FAILED.FAILED_LOAD_DATA, err)))
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            setRunRefetchDataList(false);
            getHistoricalRestrictionAuditLog();
        }
        return () => { };
    }, [runRefetchDataList]);

    useEffect(() => {
        getConfig();
        return () => { };
    }, []);

    return (
        <div className="toxic-client-check-historical-restriction-audit-log-tab">
            <div className="single-page with-background">
                <div className="active">
                    <FlexiDataTable
                        bordered
                        rowKeyProperty="batchId"
                        title=""
                        columns={columns}
                        options={{
                            serverFiltering: true,
                            // defaultCollapseFilterPanel: true,
                            expandable: {
                                expandedRowRender: getExpandedRowRender,
                            },
                        }}
                        dataSource={data ?? []}
                        callback={componentCallback}
                        loading={isLoading}
                        serverSide={true}
                        pagination={pagination}
                    />
                </div>
            </div>
        </div>
    );
};

export default HistoricalRestrictionAuditLog;