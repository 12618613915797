export const stepInfo = [
    {
        label: "Step 1: Upload Config Excel",
        value: 1,
        step: 1,
        color: "volcano",
        description: "Upload symbol leverage config excel",
    },
    {
        label: "Step 2: Check MarginLevel",
        value: 2,
        step: 2,
        color: "gold",
        description: "Show margin level before update",
    },
    {
        label: "Step 3: Obtain Origin Config",
        value: 3,
        step: 3,
        color: "blue",
        description: "Obtain origin symbol leverage config",
    },
    {
        label: "Step 4: Update Config",
        value: 4,
        step: 4,
        color: "green",
        description: "Update new symbol leverage config",
    },
    {
        label: "Step 5: Check MarginLevel",
        value: 5,
        step: 5,
        color: "yellow",
        description: "Show margin level after update",
    },
    {
        label: "Step 6: Completion",
        value: 6,
        step: 6,
        color: "default",
        description: "Complete and roll back",
    },
];