import { Form } from "antd";
import { FormInstance } from "antd/es/form";

export interface ChartPanelProps {
    type: number;
    icon: React.ReactNode;
    title: string;
    subTitle: string;
    className?: string;
    leftPanelClassName?: string;
    rightPanelClassName?: string;
    children?: React.ReactNode;
    rightExtra?: ChartPanelRightExtraProps[];
    rightFooter?: React.ReactNode;
    titleExtra?: ChartPanelTitleExtraProps;
    forPrint?: boolean;
}

export interface ChartPanelRightExtraProps {
    title: string;
    content: React.ReactNode;
}

export interface ChartPanelTitleExtraProps {
    form: FormInstance;
    content: React.ReactNode;
    onValueChanged: (changedValues: any, allValues: any) => void;
}

const ChartPanel = (props: ChartPanelProps) => {
    const onFormValueChanged = (changedValues: any, allValues: any) => {
        if (props.titleExtra?.onValueChanged) props.titleExtra.onValueChanged(changedValues, allValues);
    };

    return (
        <div className={`chart-panel ${props.className ? props.className : ""} ${props.forPrint ? "for-print" : ""}`}>
            <div className={`c-left ${props.rightExtra && props.rightExtra.length > 0 ? "" : "no-rightExtra"} ${props.leftPanelClassName || ""}`}>
                <div className="title">
                    <div className="title-left-panel">
                        <div className="icon-panel">{props.icon}</div>
                        <div className="title-desc-panel">
                            <span className="title-text">{props.title}</span>
                            <span className="title-desc">{props.subTitle}</span>
                        </div>
                    </div>
                    {!props.forPrint && props.titleExtra && (
                        <div className="titleExtra">
                            <Form form={props.titleExtra.form} onValuesChange={onFormValueChanged}>
                                {props.titleExtra.content}
                            </Form>
                        </div>
                    )}
                </div>
                <div className="content">{props.children}</div>
            </div>
            {props.rightExtra && props.rightExtra.length > 0 && (
                <div className={`c-right ${props.rightPanelClassName || ""}`}>
                    <div className="main-content">
                        {props.rightExtra.map((x, index) => (
                            <div key={`cht-pnl-rr-${index}`}>
                                {x.title && x.title.length > 0 && (
                                    <div className="desc-tags">
                                        <div className="tag-div">
                                            <span>{x.title}</span>
                                        </div>
                                    </div>
                                )}
                                <div className="content">{x.content}</div>
                            </div>
                        ))}
                    </div>

                    {props.rightFooter && <div className="footer">{props.rightFooter}</div>}
                </div>
            )}
        </div>
    );
};

export default ChartPanel;
