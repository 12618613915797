import { SUCCESS_FAILED } from '@/constants';
import useRCServers from '@/hooks/useRCServers';
import { APIs } from '@/services/apis';
import { plainAxiosInstance } from '@/services/axiosSetup';
import { ErrorCatchValidator, ErrorMessageHandler } from '@/utils/Common';
import { DownloadOutlined, FileTextOutlined, ReloadOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Input, Row, Select, Space, Spin, Tooltip, Typography } from 'antd';
import moment from 'moment-timezone';
import React from 'react';
import ResultTable from './component/ResultTable';

type SelectOptionType = {
  label: string;
  value: string;
}
type ChangeHistoryRequestParams = {
  serverCode?: string;
  login?: string;
  changeType?: string;
  startDate: string;
  endDate: string;
}
const OPTIONS = ([
  {
    value: 0,
    label: "All"
  },
  {
    value: 1,
    label: "Scheduled Change"
  },
  {
    value: 2,
    label: "Resign Common Accounts"
  },
  {
    value: 3,
    label: "Resign Personal Accounts"
  }
]);
const ChangeSecurityHistory = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [progress, setProgress] = React.useState<{ percent: number, current: number, total: number }>({ percent: 0, current: 0, total: 0 });
  const [data, setData] = React.useState<any[]>([]);
  const [dataCount, setDataCount] = React.useState<number>(0);
  const [isDownloading, setIsDownloading] = React.useState<boolean>(false);

  const { rcServers, refetchRcServers, isLoading: isLoadingRcServers } = useRCServers();
  const serverList = [{ label: 'All servers', value: 'All' }].concat((rcServers?.map(server => ({ label: server.serverName, value: server.serverId })) || []));

  const initialValue = {
    serverCode: 'All',
    changeType: OPTIONS[0].value,
    date: [moment().startOf('day'), moment().endOf('day')],
    login: ''
  }
  const [searchForm] = Form.useForm();

  function onFinish(values: any) {
    const requiredParam: ChangeHistoryRequestParams = {
      serverCode: values.serverCode !== 'All' ? values.serverCode : undefined,
      changeType: values.changeType !== 0 ? values.changeType : undefined,
      startDate: values.date[0].format('YYYY-MM-DD HH:mm:ss'),
      endDate: values.date[1].format('YYYY-MM-DD HH:mm:ss'),
      login: values.login !== '' ? values.login : undefined
    }
    setIsLoading(true);
    setProgress({ percent: 0, current: 0, total: 0 });
    getCount(requiredParam)
      .then(async count => {
        setDataCount(count);
        if (count === 0) {
          return ErrorMessageHandler("Change Password History", SUCCESS_FAILED.FAILED_LOAD_DATA, { message: "No data found" });
        }
        await getData(count, requiredParam);
      })
      .catch(error => {
        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("Change Password History", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
      })
      .finally(() => {
        setIsLoading(false)
      });
  }

  async function getCount(requiredParam: ChangeHistoryRequestParams): Promise<number> {
    return plainAxiosInstance
      .post(`${APIs.RC_MT_CLIENT_SECURITY.GET_CHANGE_HISTORY_COUNT}`, requiredParam)
      .then(res => {
        if (res.status !== 200) throw res;
        return res.data as number;
      })
      .catch(error => {
        throw error
      })
  }

  async function getData(dataCount: number, requiredParam: ChangeHistoryRequestParams) {
    const limit = 500;
    const outputData: any[] = [];
    let error = false;
    let current: number = 0;
    let total = Math.ceil(dataCount / limit);
    let percent = 0;
    for (let i = 0; i < dataCount; i += limit) {
      if (error) break;
      const _requiredParam = { ...requiredParam, limit: limit, offset: i };
      try {
        const res = await plainAxiosInstance.post(`${APIs.RC_MT_CLIENT_SECURITY.GET_CHANGE_HISTORY}`, _requiredParam);
        outputData.push(...res.data);
      } catch (error) {
        error = true;
        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("Change Password History", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
      }
      //sleep for 0.1 second
      await new Promise(resolve => setTimeout(resolve, 100));
      current++;
      percent = Math.ceil((current / total) * 100);
      setProgress({ percent, current, total });
    }
    setData(outputData);
  }

  function downloadSecurityChangeLogs() {
    searchForm.validateFields()
      .then(values => {
        const requiredParam: ChangeHistoryRequestParams = {
          serverCode: values.serverCode,
          changeType: values.changeType,
          startDate: values.date[0].format('YYYY-MM-DD HH:mm:ss'),
          endDate: values.date[1].format('YYYY-MM-DD HH:mm:ss'),
          login: values.login !== '' ? values.login : undefined
        }
        setIsDownloading(true);
        plainAxiosInstance.post(APIs.RC_MT_CLIENT_SECURITY.DOWNLOAD_CHANGE_HISTORY, requiredParam, { responseType: 'blob' })
          .then((res) => {
            if (res.status !== 200) throw new Error('Failed to download data');
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `PASSWORD_CHANGE_${moment(new Date()).format('YYYY-MM-DD')}.xlsx`);
            link.setAttribute("type", "hidden");

            document.body.appendChild(link);
            link.click();
            //clean up
            window.URL.revokeObjectURL(url);
          })
          .catch((error) => {
            ErrorCatchValidator(error, (err: any) => ErrorMessageHandler('Change Security History', SUCCESS_FAILED.FAILED_DOWNLOAD_DATA, err));
          })
          .finally(() => {
            setIsDownloading(false);
          });
      })
      .catch(e => {
        ErrorMessageHandler("Change Security History", SUCCESS_FAILED.FAILED_DOWNLOAD_DATA, { message: "Please fill in the required fields" });
      })
  }

  return (
    <div className="change-security-history-container">
      <div className="search-form">
        <Typography.Title level={5} children={
          <Space>
            <FileTextOutlined style={{ fontSize: "0.9rem", paddingLeft: '4px' }} />
            Search change password history
          </Space>}
        />
        <Form form={searchForm} onFinish={onFinish} initialValues={initialValue}>
          <Row gutter={8}>
            <Col span={5} style={{ display: 'flex' }}>
              <Form.Item
                name="serverCode"
                style={{ width: "calc(100% - 32px)" }}
                rules={[{ required: true, message: "Please select server" }]}
              >
                <Select<SelectOptionType[]>
                  placeholder="Select server..."
                  options={serverList}
                  disabled={isLoadingRcServers}
                  onChange={(val) => searchForm.setFieldValue("serverCode", val)}
                />
              </Form.Item>
              <Tooltip title="Load server list">
                <Button icon={<ReloadOutlined style={{ fontSize: "0.875rem" }} />}
                  loading={isLoadingRcServers} onClick={() => refetchRcServers()}
                  style={{ width: "32px" }}
                />
              </Tooltip>
            </Col>
            <Col span={5}>
              <Form.Item name="changeType" rules={[{ required: true, message: "Please select change type" }]}>
                <Select<SelectOptionType>
                  placeholder="Select Type"
                  style={{ width: "100%" }}
                  options={OPTIONS}
                />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item name="date" rules={[{ required: true, message: "Please select date range" }]}>
                <DatePicker.RangePicker
                  format={"YYYY-MM-DD HH:mm:ss"}
                  style={{ width: "100%" }}
                  showTime
                  disabledDate={current => {
                    return current && current > moment().endOf("day");
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item name="login">
                <Input prefix={<UserOutlined style={{ fontSize: "0.875rem", marginRight: '8px' }} />} placeholder="Login" />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Space>
                <Button type='primary' icon={<SearchOutlined style={{ fontSize: "0.875rem" }} />}
                  loading={isLoading} htmlType='submit'
                />
                <Button icon={<DownloadOutlined style={{ fontSize: "0.875rem" }} />}
                  loading={isDownloading} onClick={downloadSecurityChangeLogs}
                />
              </Space>
            </Col>
          </Row>
        </Form>
      </div>
      {/* <Spin spinning={isLoading} tip={`Loading ${progress.total !== 0 ? `${progress.current}/${progress.total}` : '...'}`}> */}
      <Spin spinning={isLoading} tip={`Loading ${progress.percent}%`}>
        <ResultTable
          data={data}
          isLoading={isLoading}
        />
      </Spin>
    </div>
  )
}

export default ChangeSecurityHistory
