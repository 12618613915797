 import { createApi } from "@reduxjs/toolkit/query/react";
 import { APIs, defaultBaseQueryProps, ResponseProps } from "../../services/apiConfig";
 import { Brands } from "../../constants/type";
 import { axiosBaseQuery } from "../axiosBaseQuery";
 
 export interface brandsRequest {}
 
 export interface brandsResponse extends ResponseProps<Brands[]> {}
 
 export const brandsApiSlice = createApi({
     reducerPath: "api_8",
     baseQuery: axiosBaseQuery(),
     keepUnusedDataFor: 5, //global caching duration (seconds)
     endpoints: (builder) => ({
         getBrands: builder.query<brandsResponse, brandsRequest | undefined>({
             keepUnusedDataFor: 0, //disable for caching for particular api request
             query: (arg) => ({ ...defaultBaseQueryProps, url: APIs.GET_BRANDS_LIST, method: "POST", params: arg }),
             transformResponse: (returnValue: brandsResponse, meta) => {
                 return returnValue;
             },
         }),
     }),
 });
 
 export const { useGetBrandsQuery } = brandsApiSlice;
 