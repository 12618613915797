import { FormComponent } from "@/components/FormComponent";
import LoadingComponent from "@/components/Loading";
import { ComponentType } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { KeyValuePair } from "@/constants/type";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { defaultIfEmptyOrNull } from "@/utils/string";
import { Form, Modal, Row, Col } from "antd";
import { useEffect, useMemo, useState } from "react";

export interface PriceAnalysisGroupSettingModalProps {
    isModalVisible: boolean;
    modalTitle: string;
    callback: (type: number, data: any) => void;
    data?: any | undefined;
    servers: KeyValuePair[];
}

export enum PriceAnalysisGroupSettingModalCallbackKey {
    Close = 0,
    FormSubmit = 1,
}

const PriceAnalysisGroupSettingModal = (props: PriceAnalysisGroupSettingModalProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [executeForm] = Form.useForm();
    const selectedServers = Form.useWatch("pickupServers", executeForm);
    const baseServerOptions = useMemo(() => {
        if (selectedServers === undefined) return [];

        return (props.servers || []).filter(x => selectedServers.includes(x.value));
    }, [selectedServers, props.servers]);

    const getDetails = (values: any) => {
        plainAxiosInstance
            .get(`${APIs.RC_PRICE_SETTINGS.UPDATE_PRICE_ANALYSIS_SERVER_SETTING}/${values.groupId}`)
            .then((res: any) => {
                let pickupServer = res.data && res.data.length > 0 ? res.data : [],
                    baseServer = defaultIfEmptyOrNull(
                        pickupServer.find((x: any) => x.isBaseServer),
                        { serverUno: "" }
                    ).serverUno,
                    selectedServers = pickupServer.map((x: any) => x.serverUno);
                executeForm.setFieldsValue({ baseServer: baseServer, pickupServers: selectedServers });
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        if (!selectedServers || selectedServers.length === 0) return;

        let selectedBaseServer = executeForm.getFieldValue("baseServer");
        if (selectedBaseServer !== undefined) {
            if (!selectedServers.includes(selectedBaseServer)) {
                executeForm.setFieldsValue({ baseServer: selectedServers[0] });
            }
        } else {
            executeForm.setFieldsValue({ baseServer: selectedServers[0] });
        }
    }, [selectedServers]);

    useEffect(() => {
        if (props.isModalVisible) {
            executeForm.setFieldsValue(props.data !== undefined ? props.data : {});
            getDetails(props.data);
        } else {
            setIsLoading(true);
        }
    }, [props.isModalVisible]);

    return (
        <Modal
            style={{ top: 10 }}
            width={"40vw"}
            title={props.modalTitle}
            open={props.isModalVisible}
            okText="Submit"
            onOk={() => {
                executeForm
                    .validateFields()
                    .then((values: any) => {
                        props.callback(PriceAnalysisGroupSettingModalCallbackKey.FormSubmit, values);
                    })
                    .catch((err: any) => console.log(err));
            }}
            onCancel={() => props.callback(PriceAnalysisGroupSettingModalCallbackKey.Close, null)}
            maskClosable={false}
        >
            <LoadingComponent style={isLoading ? {} : { display: "none" }} />
            <Form form={executeForm} layout="horizontal" labelCol={{ span: 6 }} labelAlign="left" style={isLoading ? { display: "none" } : {}}>
                <FormComponent label="" name="groupId" extra={{ type: ComponentType.hidden, value: "" }} />
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Group Name"
                            name="groupDn"
                            extra={{
                                type: ComponentType.text,
                                value: "",
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Base Server"
                            name="baseServer"
                            extra={{
                                type: ComponentType.dropdown,
                                value: baseServerOptions,
                                inputProps: {
                                    allowClear: false,
                                },
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Pickup Servers"
                            name="pickupServers"
                            extra={{
                                type: ComponentType.dropdown,
                                value: props.servers,
                                inputProps: { mode: "multiple" },
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Status"
                            name="enable"
                            extra={{
                                type: ComponentType.switch,
                                value: ["Inactive", "Active"],
                            }}
                        />
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default PriceAnalysisGroupSettingModal;
