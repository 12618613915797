import { HomeOutlined } from "@ant-design/icons";
import { Form, Popconfirm, Button, Row, Col } from "antd";
import { useState, useEffect } from "react";
import { useNavigate, useParams, NavigateFunction } from "react-router-dom";
import { FormComponent } from "../../../../components/FormComponent";
import LoadingComponent from "../../../../components/Loading";
import SitePageHeader from "../../../../components/PageHeader";
import { SUCCESS_FAILED, ComponentType } from "../../../../constants";
import { REQUIRED_FIELD } from "../../../../constants/errorMessage";
import { apiRequest } from "../../../../services/apiConfig";
import { APIs } from "../../../../services/apis";
import { ErrorMessageHandler, ErrorCatchValidator } from "../../../../utils/Common";
import { ServerState } from "../../ServersPage/ServerCreateEditPage";

export interface SymbolAssetTypeCreateEditPageProps {}

const SymbolAssetTypeCreateEditPage = () => {
    let navigate = useNavigate();
    let { id } = useParams();

    const [currentState] = useState<ServerState>(id ? { action: "edit", data: id } : { action: "add", data: null });
    const [isAddAction, setIsAddAction] = useState<boolean>(currentState.action === "add");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
    const [symbolAssetTypeForm] = Form.useForm();

    const onSubmit = (values: any) => {
        setIsBtnLoading(true);
        if (isAddAction) {
            apiRequest(APIs.CREATE_SYMBOL_ASSET_TYPE, values)
                .then(() => {
                    ErrorMessageHandler("New symbol asset type", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                    navigate("/siteadmin/symbolconfig/assettype");
                })
                .catch(error =>
                    ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("new symbol asset type", SUCCESS_FAILED.FAILED_CREATE_DATA, err))
                )
                .finally(() => setIsBtnLoading(false));
        } else {
            apiRequest(APIs.UPDATE_SYMBOL_ASSET_TYPE, values)
                .then(() => {
                    ErrorMessageHandler("Existing symbol asset type", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                    navigate("/siteadmin/symbolconfig/assettype");
                })
                .catch(error =>
                    ErrorCatchValidator(error, (err: any) =>
                        ErrorMessageHandler("existing symbol asset type", SUCCESS_FAILED.FAILED_UPDATE_DATA, err)
                    )
                )
                .finally(() => setIsBtnLoading(false));
        }
    };

    const getSymbolAssetTypeList = () => {
        setIsLoading(true);
        apiRequest(APIs.GET_SYMBOL_ASSET_TYPE_LISTING, { symbolAssetTypeIds: [currentState.data ? currentState.data : id] })
            .then((data: any) => {
                if (currentState.data || id) {
                    setIsAddAction(false);
                    symbolAssetTypeForm.setFieldsValue(data[0]);
                } else {
                    setIsAddAction(true);
                    symbolAssetTypeForm.resetFields();
                }
                setIsLoading(false);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("symbol asset type", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                navigate("/siteadmin/symbolconfig/assettype");
            });
    };

    const deleteSymbolAssetType = (symbolAssetTypeId: string, navigator: NavigateFunction) => {
        apiRequest(APIs.DELETE_SYMBOL_ASSET_TYPE, { symbolAssetTypeId })
            .then(() => {
                ErrorMessageHandler("The symbol asset type", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                navigator("/siteadmin/symbolconfig/assettype");
            })
            .catch(error =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("symbol asset type", SUCCESS_FAILED.FAILED_DELETE_DATA, err))
            );
    };

    useEffect(() => {
        if (!isAddAction) getSymbolAssetTypeList();
        return () => {};
    }, [isAddAction]);

    return (
        <SitePageHeader
            title={isAddAction ? "Create New Symbol Asset Type" : "Edit Symbol Asset Type"}
            routes={[
                {
                    path: "/siteadmin/symbolconfig/assettype",
                    breadcrumbName: "Symbol Asset Type Listing",
                    icon: <HomeOutlined />,
                },
                {
                    path: "",
                    breadcrumbName: isAddAction ? "Create New Symbol Asset Type" : "Edit Symbol Asset Type",
                },
            ]}
            onBack={() => navigate("/siteadmin/symbolconfig/assettype")}
            extraProps={{
                extra: isAddAction
                    ? []
                    : [
                          <Popconfirm
                              key={"cetmp-del"}
                              title="Confirm to delete?"
                              onConfirm={() => deleteSymbolAssetType(currentState.data ? currentState.data : id, navigate)}
                              okText="Yes"
                              cancelText="No"
                          >
                              <Button type="primary" danger>
                                  Delete Symbol Asset Type
                              </Button>
                          </Popconfirm>,
                      ],
            }}
        >
            {isLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Loading..." />
                </div>
            ) : (
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    form={symbolAssetTypeForm}
                    layout="horizontal"
                    initialValues={currentState.data}
                    onFinish={onSubmit}
                >
                    <Row>
                        <Col span={15}>
                            <FormComponent label={""} name={"symbolAssetTypeId"} extra={{ type: ComponentType.hidden, value: "" }} />
                            <FormComponent
                                label="Symbol Asset Type"
                                name="symbolAssetType"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div className="step-btns-group">
                                <Button type="primary" htmlType="submit" loading={isBtnLoading}>
                                    Submit
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            )}
        </SitePageHeader>
    );
};

export default SymbolAssetTypeCreateEditPage;
