import { useRCDividendExcludedSymbols, useRCDividendRecordMutation } from "@/hooks/useRCDividendRecord";
import MessageCard from "@/pages/SystemMonitor/components/MessageCard";
import { colorMap } from "@/pages/SystemMonitor/StatusMonitor/statusUtils";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Modal, notification, Row, Space, Switch, Table, Typography, Upload } from "antd";
import moment from "moment";
import React, { useState } from "react";
const TabExcludedSymbols = () => {
    const [openUploadModal, setOpenUploadModal] = useState(false);
    const { rcDividendExcludedSymbols, isLoading } = useRCDividendExcludedSymbols();
    // console.log(rcDividendExcludedSymbols);
    const massagedData = rcDividendExcludedSymbols?.map((symbol, index) => ({
        key: index,
        symbol,
    }));
    const columns = [
        {
            title: "Symbol",
            dataIndex: "symbol",
            key: "symbol",
        },
    ];
    return (
        <div>
            <section
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    padding: "0.6rem 1rem",
                    // background: "rgb(250,250,250)",
                    border: "1px solid rgb(240,240,240)",
                    borderTop: "none",
                }}
            >
                <Button type="ghost" onClick={() => setOpenUploadModal(prev => !prev)} icon={<UploadOutlined />}>
                    Upload Dividend
                </Button>
            </section>
            {!isLoading && rcDividendExcludedSymbols && rcDividendExcludedSymbols?.length >= 0 && (
                <Table
                    dataSource={massagedData || []}
                    columns={columns}
                    size="small"
                    rowKey={record => record.symbol}
                    pagination={{ defaultPageSize: 50 }}
                />
            )}
            <UploadModal open={openUploadModal} callback={() => setOpenUploadModal(prev => !prev)} />
        </div>
    );
};

// upload modal
const UploadModal = ({ open, callback }: { open: boolean; callback: () => any }) => {
    const [form] = Form.useForm();
    const [uploadFileBlob, setUploadFileBlob] = useState<File | null>(null);
    const [confirmModalVisible, setConfirmModalVisible] = useState(false);
    const { Item } = Form;

    const { uploadDividendExcludedSymbols } = useRCDividendRecordMutation();

    const handleUpload = async (formValue: any) => {
        setConfirmModalVisible(false);
        try {
            const resp = await uploadDividendExcludedSymbols({ uploadFile: uploadFileBlob });
            if (resp) {
                notification.success({
                    message: "Success",
                    description: "Dividend excluded symbol uploaded successfully",
                });
                // reset form
                form.resetFields();
                setUploadFileBlob(null);
                callback();
            }
        } catch (e) {
            console.log("error", e);
            notification.error({
                message: "Failed to upload dividend excluded symbol",
                // @ts-ignore
                description: e?.response?.data?.message || e.message,
            });
        }
    };
    const initFormValue = {
        uploadFile: null,
    };
    const { Text, Title } = Typography;

    const showConfirmModal = () => {
        form.validateFields()
            .then(() => {
                setConfirmModalVisible(true);
            })
            .catch(errorInfo => {
                console.log("Validation failed:", errorInfo);
            });
    };

    const handleConfirm = () => {
        handleUpload(form.getFieldsValue());
    };

    return (
        <Form form={form} layout="vertical" initialValues={initFormValue} requiredMark={true} onFinish={handleUpload}>
            <Modal open={open} title="Upload Dividend" onCancel={callback} onOk={showConfirmModal} okText="Submit" width={800}>
                <Space direction="vertical" size={16}>
                    <MessageCard type="info" icon={false}>
                        <Space direction="vertical" size={16}>
                            <div>
                                <Title level={5}>Main purpose:</Title>
                                <div style={{ fontSize: "0.85rem", lineHeight: 1.25 }}>
                                    <Text>
                                        Upload the file and the system will receive the corresponding divided.{" "}
                                        <span style={{ color: colorMap.error }}>Please upload the file before 12:05 AM (system time)</span>
                                    </Text>
                                </div>
                            </div>
                            <div>
                                <Title level={5}>Operation logic and tool introduction:</Title>
                                <ol style={{ fontSize: "0.85rem", lineHeight: 1.25, marginBottom: 0 }}>
                                    <li>Upload excel sheet with all index's dividends without suffix.</li>
                                    <li>
                                        Beware of the date, ex: if today is 6/23, you should update dividends that will give out on 6/24 and choose
                                        6/23 as upload date.
                                    </li>
                                    <li>Upload the file before EOD everyday.</li>
                                </ol>
                            </div>
                        </Space>
                    </MessageCard>
                    <Row gutter={[8, 8]}>
                        <Col span={24}>
                            <Text>Upload File (.xlsx only) *</Text>
                            <Item
                                style={{ margin: 0, padding: 0 }}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                name="uploadFile"
                                valuePropName="uploadFile"
                                rules={[
                                    { required: true, message: "Please upload file" },
                                    {
                                        validator: async (_, file: any) => {
                                            if (file?.fileList?.[0]?.size > 5 * 1024 * 1024) {
                                                return Promise.reject("File size must be smaller than 5MB!");
                                            }
                                            if (file?.fileList?.[0]?.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                                                return Promise.reject("File type must be .xlsx!");
                                            }
                                            return Promise.resolve();
                                        },
                                    },
                                ]}
                            >
                                <Upload.Dragger
                                    name="file"
                                    multiple={false}
                                    maxCount={1}
                                    beforeUpload={file => {
                                        setUploadFileBlob(file);
                                        return false; // Prevent auto upload
                                    }}
                                    accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                    onRemove={() => {
                                        setUploadFileBlob(null);
                                        form.setFieldsValue({ uploadFile: null });
                                    }}
                                >
                                    <p className="ant-upload-drag-icon">
                                        <UploadOutlined />
                                    </p>
                                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                    <p className="ant-upload-hint">Support for a single XLSX file upload. File size must be less than 5MB.</p>
                                </Upload.Dragger>
                            </Item>
                        </Col>
                    </Row>
                </Space>
            </Modal>
            <Modal title="Confirm Upload" open={confirmModalVisible} onOk={handleConfirm} onCancel={() => setConfirmModalVisible(false)}>
                <p>Are you sure you want to upload this file?</p>
            </Modal>
        </Form>
    );
};

export default TabExcludedSymbols;
