import { SketchPicker } from "react-color";

export interface CustomColorPickerProps {
    value?: string;
    onChange?: (value: string) => void;
}

const CustomColorPicker = (props: CustomColorPickerProps) => {
    return <SketchPicker color={props.value || "#EB144C"} onChangeComplete={(color: any) => props.onChange && props.onChange(color.hex)} />;
};

export default CustomColorPicker;
