import React from "react";
import useRCStatusMonitorStore from "../../../store/useRCStatusMonitorStore";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { Col, Row, Typography } from "antd";

const ServerStatusFilterWeekend = () => {
    const { Text } = Typography;
    return (
        <Row gutter={[8, 8]}>
            {/* <Col xs={{ span: 24 }} md={{ span: 8 }}>
                <Text>Department</Text>
                <br />
                <MyCheckbox name="ra" label="Risk Admin" />
                <MyCheckbox name="admin" label="Admin" />
            </Col> */}
            <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Text>Status</Text>
                <br />
                <MyCheckbox name="danger" label="Danger" />
                <MyCheckbox name="warning" label="Warning" />
                <MyCheckbox name="success" label="Success" />
                <MyCheckbox name="disabled" label="Disabled" />
            </Col>
            {/* <Col xs={{ span: 24 }} md={{ span: 8 }}>
                <Text>Display</Text>
                <br />
                <MyCheckbox name="summary" label="Show All / by Group" />
            </Col> */}
        </Row>
    );
};

const MyCheckbox = ({ label, name }: { label: string; name: string }) => {
    const display = useRCStatusMonitorStore(state => state.display);
    const updateDisplay = useRCStatusMonitorStore(state => state.updateDisplay);
    return (
        <Checkbox
            // @ts-ignore
            checked={display[name.toLowerCase()]}
            onChange={e => updateDisplay({ [name]: e.target.checked })}
        >
            {label}
        </Checkbox>
    );
};

export default ServerStatusFilterWeekend;
