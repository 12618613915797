import React, { useState } from "react";
import CardBox from "../../../components/Common/CardBox";
import { Segmented } from "antd";
import FailedRecords from "./FailedRecords";
import { NavigationItem } from "../../../constants/type";
import DeletedRecords from "./DeletedRecords";

const CommissionRecord = () => {
    const [currentActiveSegment, setCurrentActiveSegment] = useState<string>("failedRecords"); // For segment

    const SegmentList: NavigationItem[] = [
        { key: "failedRecords", title: "Failed Records" },
        { key: "deletedRecords", title: "Deleted Records" },
    ];

    return (
        <div className="commission-record-container">
            <CardBox title="Commission Record">
                <div className="nav-bar">
                    <Segmented
                        value={currentActiveSegment}
                        options={SegmentList.map((x: NavigationItem) => ({
                            label: x.title,
                            value: x.key,
                        }))}
                        onChange={(activeKey: any) => setCurrentActiveSegment(activeKey)}
                    />
                </div>
                {currentActiveSegment === "failedRecords" && <FailedRecords />}
                {currentActiveSegment === "deletedRecords" && <DeletedRecords />}
            </CardBox>
        </div>
    );
};

export default CommissionRecord;