import React, { ReactElement, ReactPortal, useState } from "react";
import { Button, Space } from "antd";
import { FilterFilled, FilterOutlined } from "@ant-design/icons";
import { motion } from "framer-motion";

type ReactText = string | number;
type ReactChild = ReactElement | ReactText;

interface ReactNodeArray extends Array<ReactNode> {}
type ReactFragment = {} | ReactNodeArray;
type ReactNode = ReactChild | ReactFragment | ReactPortal | boolean | null | undefined;

type Props = {
    children?: ReactNode;
    show?: boolean;
    titleBarChildren?: ReactNode;
};

const BasicFilter = ({ children, show = true, titleBarChildren }: Props) => {
    const [showFilter, setShowFilter] = useState(show);
    return (
        <div
            style={{
                width: "100%",
                backgroundColor: "#fafafa",
                border: "1px solid #f0f0f0",
                borderTop: "none",
            }}
        >
            <div style={{ padding: "0.6rem 1rem", display: "flex", justifyContent: "space-between" }}>
                <Button icon={showFilter ? <FilterOutlined /> : <FilterFilled />} onClick={() => setShowFilter(prev => !prev)}>
                    Filter
                </Button>
                {titleBarChildren && (
                    <div style={{ marginLeft: "auto" }}>
                        <>{titleBarChildren}</>
                    </div>
                )}
            </div>
            <motion.div
                style={{ background: "white", overflow: "hidden", borderTop: "1px solid #f0f0f0" }}
                initial={false}
                animate={{
                    height: showFilter ? "auto" : 0,
                    opacity: showFilter ? 1 : 0,
                }}
            >
                <Space style={{ padding: "1rem", width: "100%" }} direction="vertical">
                    <>{children ?? null}</>
                </Space>
            </motion.div>
        </div>
    );
};

export default BasicFilter;
