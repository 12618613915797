import React from "react";
import SpreadAnalysisFilters from "./SpreadAnalysisFilters";
import SpreadAnalysisRecords from "./SpreadAnalysisRecords";
import { Space } from "antd";
import CommonPaddingWrapper from "../CommonPaddingWrapper";

const SpreadAnalysisPage: React.FC = () => {
    return (
        <Space style={{ width: "100%" }} direction="vertical" size="large">
            <SpreadAnalysisFilters />
            <CommonPaddingWrapper>
                <SpreadAnalysisRecords />
            </CommonPaddingWrapper>
        </Space>
    );
};

export default SpreadAnalysisPage;
