import { Col, Form, FormInstance, Row } from "antd";
import { useState, useEffect, useCallback } from "react";
import { FormComponent } from "../../../components/FormComponent";
import { ComponentType } from "../../../constants";
import "../../../index.less";
import { REQUIRED_FIELD } from "../../../constants/errorMessage";
import { CrmProblematicStatus } from "../../../constants/type";

interface CRMCommentModalProps {
    crmCommentForm: FormInstance;
    initCrmProblematicStatus: CrmProblematicStatus[];
    editCommentOnly: boolean;
}

const CRMCommentModal = (props: CRMCommentModalProps) => {
    const [dropdownCrmProblematicStatus, setDropdownCrmProblematicStatus] = useState<CrmProblematicStatus[]>([]);

    const FILTER_CONDITIONS = {
        RL_MM: (item: CrmProblematicStatus) => ![1, 3, 5].includes(item.id),
        DEFAULT: (item: CrmProblematicStatus) => item.id !== 5,
    };

    const filterStatusOptions = useCallback(() => {
        const blockType = props.crmCommentForm.getFieldValue("blockType");
        
        const filterCondition = blockType === "RL" || blockType === "MM" 
            ? FILTER_CONDITIONS.RL_MM 
            : FILTER_CONDITIONS.DEFAULT;
        return props.initCrmProblematicStatus
            .filter(filterCondition)
            .map(x => ({ text: x.status, value: x.id, commentNote: x.commentNote, id: x.id, status: x.status }));
    }, [props.crmCommentForm, props.initCrmProblematicStatus]);

    const setCommentFieldValue = useCallback((value: number) => {
        const selectedStatus = props.initCrmProblematicStatus.find(x => x.id === value);
        if (selectedStatus) {
            props.crmCommentForm.setFieldsValue({ comment: selectedStatus.commentNote });
        }
    }, [props.crmCommentForm, props.initCrmProblematicStatus]);

    useEffect(() => {
        if (props.crmCommentForm.getFieldValue("status") === 5) {
            setDropdownCrmProblematicStatus(props.initCrmProblematicStatus);
        } else {
            setDropdownCrmProblematicStatus(filterStatusOptions());
        }
        return () => {};
    }, [props.crmCommentForm.getFieldValue("teamId"), filterStatusOptions, props.initCrmProblematicStatus]);

    return (
        <Form form={props.crmCommentForm} layout="horizontal">
            <FormComponent
                label=""
                name="teamId"
                extra={{ type: ComponentType.hidden, value: "" }}
            />
            <FormComponent
                label=""
                name="blockType"
                extra={{ type: ComponentType.hidden, value: "" }}
            />

            {props.editCommentOnly ? (
                <>
                    <Row className="box">
                        <Col span={6} className="label">
                            <span>*</span> Status: 
                        </Col>
                        <Col span={18} className="value">
                            Manual
                        </Col>
                    </Row>
                    <FormComponent
                        label="Status"
                        name="status"
                        extra={{ type: ComponentType.hidden, value: "" }}
                    />
                </>
            ) : (
                <FormComponent
                    label="Status"
                    name="status"
                    extra={{
                        type: ComponentType.dropdown,
                        value: dropdownCrmProblematicStatus,
                        itemProps: { labelCol: { span: 6 }, wrapperCol: { span: 18 } },
                        rules: [{ required: true, message: REQUIRED_FIELD }],
                        inputProps: { onChange: setCommentFieldValue },
                    }}
                />
            )}

            <FormComponent
                label="Comment"
                name="comment"
                extra={{
                    type: ComponentType.textarea,
                    value: "",
                    inputProps: { placeholder: "Enter your comment here..." },
                    itemProps: { labelCol: { span: 6 }, wrapperCol: { span: 19 } },
                    rules: [{ required: true, message: REQUIRED_FIELD }],
                }}
            />
        </Form>
    );
};

export default CRMCommentModal;
