import CardBox from "@/components/Common/CardBox";
import FlexiDataTable from "@/components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "@/constants";
import { FlexiDataTableOptionsProps, FlexiDataTableCallbackProps } from "@/constants/type";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { DefaultIfEmpty } from "@/utils/object";
import { defaultIfEmptyOrNull } from "@/utils/string";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { message, Modal } from "antd";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import CADividendMapUploadModal, { CADividendMapUploadModalCallbackKey } from "./components/CADividendMapUploadModal";

export interface CADividendMapUploadProps {}

interface CADividendMapUploadDataProps {
    uploadDate: string;
    region: string;
    cleanSymbol: string;
    issueName: string;
    exDate: string;
    amountPerShare: number;
    currency: string;
    taxRate: number;
    eventId: number;
}

const CADividendMapUpload = (props: CADividendMapUploadProps) => {
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [uploadModalVisible, setUploadModalVisible] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<CADividendMapUploadDataProps[]>([]);
    const [filterParam, setFilterParam] = useState<any>({ selectedDate: moment().format("YYYYMMDD") });
    const [uploadFile, setUploadFile] = useState<any>(undefined);
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.RISK_TOOLS_CA_DIVIDEND_MAP_UPLOAD_EDIT);

    const processUpload = useCallback(
        (values: any) => {
            try {
                Modal.confirm({
                    icon: <ExclamationCircleOutlined />,
                    title: "Are you sure you want to import CA DividendMap?",
                    width: "30%",
                    onOk() {
                        var formData = new FormData();
                        formData.append("uploadFile", uploadFile);
                        formData.append("uploadDate", values["uploadDate"]);

                        plainAxiosInstance
                            .post(APIs.RISK_TOOL.CA_DIVIDEND_MAP_UPLOAD_UPLOAD_FILE, formData)
                            .then(res => {
                                if (res.status === 200 && res.data === "success") {
                                    message.success("File upload successfully.", 3);
                                } else {
                                    message.error("Import failed, no data found", 3);
                                }
                            })
                            .catch((error: any) => {
                                message.error(`File upload failed`, 3);
                            })
                            .finally(() => setRunRefetchDataList(true));
                    },
                    onCancel() {},
                });
            } catch (error) {
                message.error(`Error during uploading file`, 3);
            }
        },
        [uploadFile]
    );

    const handleSubmit = (file: any) => {
        let fileExtension: string[] = defaultIfEmptyOrNull(/\.[^\.]+/.exec(file.name), [""]),
            isLt5M = file.size / 1024 / 1024 < 5;

        if (fileExtension[0] !== ".xlsx") {
            message.error("Please check file type. Only .xlsx files are allowed.", 3);
            return;
        } else if (!isLt5M) {
            message.error("Please check file size less than 5 MB", 3);
            return;
        }

        setUploadFile(file);
        setUploadModalVisible(true);
    };

    const columns = [
        {
            title: "Upload Date",
            dataIndex: "selectedDate",
            key: "selectedDate",
            options: {
                visible: false,
                filter: {
                    type: ComponentType.date,
                    value: "",
                    dateFormat: "YYYY-MM-DD",
                    itemProps: {
                        rules: [{ required: true, message: "Please select upload date" }],
                    },
                },
            },
        },
        DTColProps.XSmall({
            title: "Region",
            dataIndex: "region",
            key: "region",
        }),
        DTColProps.Small({
            title: "Upload Date",
            dataIndex: "uploadDate",
            key: "uploadDate",
        }),
        DTColProps.Small({
            title: "Clean Symbol",
            dataIndex: "cleanSymbol",
            key: "cleanSymbol",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        {
            title: "Issue Name",
            dataIndex: "issueName",
            key: "issueName",
        },
        DTColProps.Small({
            title: "ExDate",
            dataIndex: "exDate",
            key: "exDate",
        }),
        DTColProps.SCurrency(
            {
                title: "Amount Per Share",
                dataIndex: "amountPerShare",
                key: "amountPerShare",
            },
            [],
            -1
        ),
        DTColProps.XSmall(
            {
                title: "Currency",
                dataIndex: "currency",
                key: "currency",
            },
            ["text-center"]
        ),
        DTColProps.SCurrency(
            {
                title: "Tax Rate",
                dataIndex: "taxRate",
                key: "taxRate",
            },
            [],
            -1
        ),
        DTColProps.XSmall({
            title: "Event ID",
            dataIndex: "eventId",
            key: "eventId",
        }),
    ];

    const options: FlexiDataTableOptionsProps = {
        ...(enableUpdate && {
            upload: {
                name: "file",
                multiple: false,
                accept: ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                showUploadList: false,
                onChange: (info: any) => {
                    if (info.file.status === "error") {
                        message.error(`${info.file.name} file upload failed.`);
                    }
                },
                customRequest: ({ file, onSuccess }: any) =>
                    setTimeout(() => {
                        onSuccess("ok");
                    }, 0),
                beforeUpload: (file: any) => handleSubmit(file),
            },
        }),
    };

    const componentCallback: FlexiDataTableCallbackProps = (type: number, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                let tmpFilterParams: any = {};
                Object.keys(FormData)
                    .filter(x => (FormData[x] === undefined ? false : FormData[x].toString().length > 0))
                    .forEach(x => {
                        if (x === "selectedDate") {
                            tmpFilterParams[x] = FormData[x].format("YYYYMMDD");
                        } else {
                            tmpFilterParams[x] = FormData[x];
                        }
                    });
                setFilterParam(tmpFilterParams);
                setRunRefetchDataList(true);
                break;
            default:
                break;
        }
    };

    const getCADividendData = useCallback(() => {
        plainAxiosInstance
            .get(
                `${APIs.RISK_TOOL.GET_CA_DIVIDEND_MAP_UPLOAD_LIST}?uploadDate=${DefaultIfEmpty(
                    filterParam,
                    "selectedDate",
                    ""
                )}&cleanSymbol=${DefaultIfEmpty(filterParam, "cleanSymbol", "")}`
            )
            .then((res: any) => {
                if (res.data && res.data.length > 0) {
                    setData(res.data);
                } else {
                    setData([]);
                }
            })
            .catch(error =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("CA dividend map upload", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsLoading(false));
    }, [filterParam]);

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            getCADividendData();
            setRunRefetchDataList(false);
        }
    }, [runRefetchDataList]);

    return (
        <>
            <div className="cadividend-map-upload-container">
                <CardBox title={"CA DividendMap Upload"}>
                    <FlexiDataTable
                        bordered
                        rowKeyProperty="id"
                        title={false}
                        columns={columns}
                        options={options}
                        dataSource={data}
                        callback={componentCallback}
                        loading={isLoading}
                        filterInitialValue={{
                            selectedDate: moment(),
                        }}
                    />
                </CardBox>
            </div>
            <CADividendMapUploadModal
                isModalVisible={uploadModalVisible}
                callback={(type: number, data: any) => {
                    switch (type) {
                        case CADividendMapUploadModalCallbackKey.Close:
                            setUploadModalVisible(false);
                            break;
                        case CADividendMapUploadModalCallbackKey.FormSubmit:
                            processUpload(data);
                            setUploadModalVisible(false);
                            break;
                        default:
                            break;
                    }
                }}
            />
        </>
    );
};

export default CADividendMapUpload;
