import { ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";
import { useEffect, useState } from "react";
import { SUCCESS_FAILED } from "../../../constants";
import { apiRequest } from "../../../services/apiConfig";
import { APIs } from "../../../services/apis";
import { HFTOpenClosesList, HFTTradeSidesList, HFTTypesList } from "../../../constants/type";
import CardBox from "../../../components/Common/CardBox";
import HFTView from "./hftView";
import HftDetailView, { SelectedAccountInfoProps } from "./hftDetailView";
import { Breadcrumb } from "antd";
import { HomeOutlined } from "@ant-design/icons";

const HFTBiti = () => {
    const [HFTType, setHFTType] = useState<HFTTypesList[]>([]);
    const [HFTOpenClose, setHFTOpenClose] = useState<HFTOpenClosesList[]>([]);
    const [HFTTradeSide, setHFTTradeSide] = useState<HFTTradeSidesList[]>([]);
    const [viewType, setViewType] = useState<number>(1);
    const [detailRefreshRate, setDetailRefreshRate] = useState<number>(30);
    const [selectedAccountInfo, setSelectedAccountInfo] = useState<SelectedAccountInfoProps | undefined>(undefined);

    useEffect(() => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["hfttype", "hftopenclose", "tradeside"] })
            .then((data: any) => {
                if (data.hftTypes.length > 0) {
                    setHFTType(data.hftTypes);
                }
                if (data.hftOpenCloses.length > 0) {
                    setHFTOpenClose(data.hftOpenCloses);
                }
                if (data.tradeSides.length > 0) {
                    setHFTTradeSide(data.tradeSides);
                }
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("hft", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
            });
        return () => {};
    }, []);

    return (
        <div className="hft-biti-container">
            <CardBox title={"HFT - BITI"}>
                <div className="breadcrumb-panel" style={{ display: viewType > 1 ? "block" : "none" }}>
                    <Breadcrumb>
                        <Breadcrumb.Item
                            href="#"
                            onClick={() => {
                                setViewType(1);
                                setSelectedAccountInfo(undefined);
                            }}
                        >
                            <HomeOutlined />
                            <span>Hft View</span>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item href="#">Hft Detail View</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <HFTView
                    HFTTypesList={HFTType}
                    currentViewType={viewType}
                    onViewTypeChanged={(type: number, selectedAccountInfo: any = undefined) => {
                        setViewType(type);
                        setSelectedAccountInfo(selectedAccountInfo);
                    }}
                />
                {viewType === 2 && (
                    <HftDetailView
                        data={selectedAccountInfo}
                        refreshRate={detailRefreshRate}
                        onRefreshRateCallback={(seconds: number) => setDetailRefreshRate(seconds)}
                        HFTTypesList={HFTType}
                        HFTTradeSidesList={HFTTradeSide}
                        HFTOpenClosesList={HFTOpenClose}
                    />
                )}
            </CardBox>
        </div>
    );
};

export default HFTBiti;
