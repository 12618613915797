import React from "react";
import useRCPriceCompareStore from "../../../store/useRCPriceCompareStore";
import { RightSquareOutlined } from "@ant-design/icons";
const AGSymbolCell = (data: any) => {
    const setSelectedSymbols = useRCPriceCompareStore(state => state.setSelectedSymbols);
    const myValue = data?.value ?? "-";

    return (
        <div
            style={{
                wordBreak: "break-word",
                cursor: "pointer",
            }}
            onClick={() => {
                setSelectedSymbols([myValue]);
            }}
        >
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <span>{myValue || "-"}</span>
                <RightSquareOutlined />
            </div>
        </div>
    );
};
export default AGSymbolCell;
