import { useEffect, useState } from "react";
import CardBox from "@/components/Common/CardBox";
import { KeyValuePair, NavigationItem } from "@/constants/type";
import { Segmented } from "antd";
import CIDSearch from "./CIDSearch";
import LoginSearch from "./LoginSearch";
import History from "./History";
import DailyReport from "./DailyReport";
import WhitelistSetting from "./WhitelistSetting";
import LabelSetting from "./LabelSetting";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { APIs } from "@/services/apis";
import { ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { SUCCESS_FAILED } from "@/constants";
import LoadingComponent from "@/components/Loading";
import { CidLabelSettingsList } from "./components/type";

const SegmentList: NavigationItem[] = [
    { key: "cid", title: "CID Search" },
    { key: "login", title: "Login Search" },
    { key: "history", title: "History" },
    { key: "report", title: "Daily Report" },
    { key: "whitelist", title: "Whitelist Setting" },
    { key: "label", title: "Label Setting" },
];

const CID = () => {
    const [currentActiveSegment, setCurrentActiveSegment] = useState<string>("cid"); // For segment
    const [servers, setServers] = useState<any[]>([]);
    const [isFilterLoaded, setIsFilterLoaded] = useState<boolean>(false);
    const [labels, setLabels] = useState<CidLabelSettingsList[]>([]);

    const getServersList = () => {
        plainAxiosInstance
            .get(`${APIs.RC_CID.GET_SERVERS}`)
            .then((res: any) => {
                if (res.status === 200) {
                    setServers(res.data.map((x: any) => ({ value: x.serverId, text: x.serverName })) as KeyValuePair[]);
                } else setServers([]);
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("server list", SUCCESS_FAILED.FAILED_LOAD_DATA, err)))
            .finally(() => setIsFilterLoaded(true));
    };

    const getLabelSettingList = (isListUpdated?: boolean) => {
        if (isListUpdated) {
            plainAxiosInstance
                .get(`${APIs.RC_CID.GET_LABEL_SETTINGS}`)
                .then((res: any) => {
                    if (res.status === 200) {
                        setLabels(res.data);
                    } else setLabels([]);
                })
                .catch((error: any) => ErrorCatchValidator(error, (err: any) => {
                    ErrorMessageHandler("label settings", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                    setLabels([]);
                }))
        };
    };

    useEffect(() => {
        getServersList();
        getLabelSettingList(true);
        return () => { };
    }, []);

    return (
        <div className="rc-cid-container">
            <CardBox title="CID">
                <div className="top-nav">
                    <Segmented
                        value={currentActiveSegment}
                        options={SegmentList.map((x: NavigationItem) => ({
                            label: x.title,
                            value: x.key,
                        }))}
                        onChange={(activeKey: any) => setCurrentActiveSegment(activeKey)}
                    />
                </div>
                <LoadingComponent tip="Loading filters..." spinning={!isFilterLoaded}>
                    <div className="rc-cid-tabs-content">
                        {currentActiveSegment === "cid" && <CIDSearch servers={servers} />}
                        {currentActiveSegment === "login" && <LoginSearch servers={servers} />}
                        {currentActiveSegment === "history" && <History servers={servers} labels={labels} />}
                        {currentActiveSegment === "report" && <DailyReport servers={servers} labels={labels} />}
                        {currentActiveSegment === "whitelist" && <WhitelistSetting servers={servers} />}
                        {currentActiveSegment === "label" && <LabelSetting refetchLabelsList={getLabelSettingList} labelsList={labels} />}
                    </div>
                </LoadingComponent>
            </CardBox>
        </div>
    );
};

export default CID;