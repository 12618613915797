import FlexiDataTable from "../../../../components/FlexiDataTable";
import { DTColProps, DataTableColumnRender, currencyRender } from "../../../../utils/Common";
import { apiRequest } from "../../../../services/apiConfig";
import { APIs } from "../../../../services/apis";
import { useEffect, useMemo, useState } from "react";
import { FlexiDataTableOptionsProps } from "../../../../constants/type";
import { CALLBACK_KEY, ComponentType } from "../../../../constants";
import { defaultIfEmptyOrNull, isEmptyOrNull } from "../../../../utils/string";
import { ExclamationCircleOutlined, FileSearchOutlined } from "@ant-design/icons";
import { Badge, Empty, Popover, Tooltip } from "antd";

export interface LPSummaryBySymbolProps {
    isLoading: boolean;
    selectedMRGroup: number[];
}

interface LPInformationProps {
    lp: string;
    accountIdNetVolume: LPInformationAccounts[];
}

interface LPInformationAccounts {
    accountId: string;
    netVolume: number;
    scaledNetVolume: number;
}

const LPSummaryBySymbol = (props: LPSummaryBySymbolProps) => {
    const [data, setData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(true);
    const [symbols, setSymbols] = useState<string[]>([]);
    const [unMapSymbols, setUnMapSymbols] = useState<string[]>([]);
    const [serverTimeStamp, setServerTimeStamp] = useState<string>("");

    const highligthExpression = (value: number, rowData: any) =>
        `${rowData["aBookNetVolume"]}` !== `${defaultIfEmptyOrNull(rowData["lpScaledNetVolume"], 0)}`;

    const getLPInformationTemplate = (key: string, lpInformation: LPInformationProps[]) => (
        <div className="diff-dect-popover-content">
            <div className="row row-title">
                <span>Liquidity Provider</span>
                <span>Net Volume</span>
            </div>
            <div className="item-container nice-scrollbar">
                {lpInformation.length === 0 ? (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                ) : (
                    lpInformation.map((x: LPInformationProps, idx: number) =>
                        x.accountIdNetVolume.map((y: LPInformationAccounts, yIdx: number) => (
                            <div key={`${key}-${idx}-${yIdx}`} className="row row-item">
                                <span>{`${x.lp} - ${y.accountId}`}</span>
                                <span>{currencyRender(y.scaledNetVolume)}</span>
                            </div>
                        ))
                    )
                )}
            </div>
        </div>
    );

    const tableColConfig: any = useMemo(() => {
        return [
            {
                title: "Symbol",
                dataIndex: "symbol",
                key: "symbol",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: symbols.map(x => ({ text: x, value: x })),
                        inputProps: {
                            mode: "multiple",
                        },
                        callback: (filterValue: any, rowData: any) => {
                            if (isEmptyOrNull(filterValue as string[])) return true;

                            return (filterValue as string[]).includes(rowData["symbol"] || "");
                        },
                    },
                },
                render: (value: string) => {
                    if (unMapSymbols.includes(value)) {
                        return (
                            <div className="lp-symbol-render-container">
                                <Tooltip title="Please note that the current symbol is not configured at the moment.">
                                    <span>{value}</span>
                                    <ExclamationCircleOutlined />
                                </Tooltip>
                            </div>
                        );
                    }
                    return value;
                },
            },
            DTColProps.Currency(
                {
                    width: "9vw",
                    title: "A Book Net Volume",
                    dataIndex: "aBookNetVolume",
                    key: "aBookNetVolume",
                    sorter: (a: any, b: any, direction: string) => {
                        if (direction === "ascend") {
                            if (a.ranking[1] > b.ranking[1]) return -1;
                            if (a.ranking[1] < b.ranking[1]) return 1;
                        } else if (direction === "descend") {
                            if (a.ranking[1] < b.ranking[1]) return -1;
                            if (a.ranking[1] > b.ranking[1]) return 1;
                        }

                        return a.aBookNetVolume - b.aBookNetVolume;
                    },
                },
                [],
                2,
                highligthExpression,
                undefined,
                (value: number, rowData: any) => currencyRender(rowData["aBookNetVolume"])
            ),
            DTColProps.Currency(
                {
                    width: "9vw",
                    title: "LP Net Volume",
                    dataIndex: "lpScaledNetVolume",
                    key: "lpScaledNetVolume",
                    sorter: (a: any, b: any, direction: string) => {
                        if (direction === "ascend") {
                            if (a.ranking[1] > b.ranking[1]) return -1;
                            if (a.ranking[1] < b.ranking[1]) return 1;
                        } else if (direction === "descend") {
                            if (a.ranking[1] < b.ranking[1]) return -1;
                            if (a.ranking[1] > b.ranking[1]) return 1;
                        }

                        return a.lpScaledNetVolume - b.lpScaledNetVolume;
                    },
                },
                [],
                2,
                highligthExpression,
                undefined,
                (value: number, rowData: any) => {
                    if (rowData["diffValues"] === 0) return currencyRender(rowData["lpScaledNetVolume"]);

                    return rowData["diffValues"] > 0 ? (
                        <>
                            <span>{currencyRender(rowData["lpScaledNetVolume"])}</span>
                            <span className="text-color-success" style={{ marginLeft: "0.2vw" }}>
                                (+{currencyRender(rowData["diffValues"])})
                            </span>
                        </>
                    ) : (
                        <>
                            <span>{currencyRender(rowData["lpScaledNetVolume"])}</span>
                            <span className="text-color-error" style={{ marginLeft: "0.2vw" }}>
                                ({currencyRender(rowData["diffValues"])})
                            </span>
                        </>
                    );
                }
            ),
            DTColProps.Currency({
                width: "7vw",
                title: "LP Exposure",
                dataIndex: "lpExposure",
                key: "lpExposure",
                sorter: (a: any, b: any, direction: string) => {
                    if (direction === "ascend") {
                        if (a.ranking[1] > b.ranking[1]) return -1;
                        if (a.ranking[1] < b.ranking[1]) return 1;
                    } else if (direction === "descend") {
                        if (a.ranking[1] < b.ranking[1]) return -1;
                        if (a.ranking[1] > b.ranking[1]) return 1;
                    }

                    return a.lpExposure - b.lpExposure;
                },
            }),
            DTColProps.Small({
                title: "Different Direction Detected",
                dataIndex: "isDifferentDirection",
                key: "isDifferentDirection",
                render: (detected: boolean, rowData: any) => {
                    return (
                        <div className="diff-dect-col">
                            {detected ? (
                                <Badge status="error" text={<small className="text-color-error">Detected</small>} className="badge-blinking" />
                            ) : (
                                <span></span>
                            )}
                            <Popover
                                content={getLPInformationTemplate(`${rowData["symbol"]}-p`, rowData["lpInformations"])}
                                title={<span className="diff-dect-popover-title">LP Information</span>}
                                className="diff-dect-popover"
                            >
                                <FileSearchOutlined />
                            </Popover>
                        </div>
                    );
                },
            }),
        ];
    }, [symbols, unMapSymbols]);

    const options: FlexiDataTableOptionsProps = {
        defaultCollapseFilterPanel: false,
    };

    const getLPSummaryBySymbol = () => {
        apiRequest(APIs.GET_SYMBOL_METRICS_SUMMARY, {
            marketGroupIds: props.selectedMRGroup,
            limit: 999999,
            current: 1,
            viewExposureByAccountLevel: false,
        })
            .then((res: any) => {
                setServerTimeStamp(res.pkaBookServerTimestamp);
                setSymbols(res.lpSymbols);
                setUnMapSymbols(res.lpUnmappedSymbols);
                if (res && res.result && res.result.length > 0) {
                    let tmpResult = res.result.map((x: any) => ({
                        ...x,
                        aBookNetVolume: defaultIfEmptyOrNull(x["aBookNetVolume"], 0),
                        lpScaledNetVolume: defaultIfEmptyOrNull(x["lpScaledNetVolume"], 0),
                        ranking: [0, 2],
                        diffValues: parseFloat(
                            (defaultIfEmptyOrNull(x["lpScaledNetVolume"], 0) - defaultIfEmptyOrNull(x["aBookNetVolume"], 0)).toFixed(5)
                        ),
                    }));
                    setData(tmpResult);
                } else {
                    setData([]);
                }
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            getLPSummaryBySymbol();
            setRunRefetchDataList(false);
        }
        return () => {};
    }, [runRefetchDataList]);

    useEffect(() => {
        if (props.isLoading && !runRefetchDataList) {
            setRunRefetchDataList(true);
        }
        return () => {};
    }, [props.isLoading]);

    const markupData = useMemo(() => {
        let currentData = data.map((x: any) => {
            if (x["diffValues"] !== 0) {
                x["ranking"][0] = 4;
            } else if (unMapSymbols.includes(x["symbol"])) {
                x["ranking"] = [3, 1];
            } else if (x["aBookNetVolume"] !== 0 || x["lpScaledNetVolume"] !== 0) {
                x["ranking"][0] = 2;
            }

            if (x["aBookNetVolume"] === 0 && x["lpScaledNetVolume"] === 0) {
                x["ranking"][1] = 0;
            }

            return x;
        });
        currentData.sort((a: any, b: any) => {
            if (a.ranking[0] > b.ranking[0]) return -1;
            if (a.ranking[0] < b.ranking[0]) return 1;

            if (a.diffValues > b.diffValues) return -1;
            if (a.diffValues < b.diffValues) return 1;

            if (a.aBookNetVolume > b.aBookNetVolume) return -1;
            if (a.aBookNetVolume < b.aBookNetVolume) return 1;

            return 0;
        });
        return currentData;
    }, [data, unMapSymbols]);

    return (
        <div className="half-view half-view-1">
            <FlexiDataTable
                rowKeyProperty="symbol"
                title={
                    <span style={{ fontWeight: "bold", fontSize: "1.125rem" }}>
                        LP Summary By Symbol{" "}
                        {!isEmptyOrNull(serverTimeStamp) && (
                            <span
                                style={{ marginLeft: "10px", fontSize: "0.875rem", color: "#f00f00" }}
                            >{`(Net Volume Compare Time: ${DataTableColumnRender.DateTime_ServerTime(serverTimeStamp)}[Server])`}</span>
                        )}
                    </span>
                }
                columns={tableColConfig}
                options={options}
                dataSource={markupData ?? []}
                callback={(type: CALLBACK_KEY, FormData: any) => {}}
                loading={isLoading}
                pagination={{
                    defaultPageSize: 20,
                }}
                scroll={{
                    x: true,
                }}
            />
        </div>
    );
};

export default LPSummaryBySymbol;
