import FlexiDataTable from "@/components/FlexiDataTable";
import { CALLBACK_KEY } from "@/constants";
import { FlexiDataTableCallbackProps, FlexiDataTableOptionsProps, KeyValuePair } from "@/constants/type";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { DTColProps, ErrorCatchValidator } from "@/utils/Common";
import { DeleteOutlined, EyeOutlined, PlayCircleOutlined, PlaySquareOutlined, UploadOutlined } from "@ant-design/icons";
import { Tag, Badge, Modal, message, Button } from "antd";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import BalanceUpdateUploadModal, { BalanceUpdateUploadModalCallbackKey } from "./uploadFileModal";
import { BalanceUpdateHistoricalItemProps } from "./history";
import { isEmptyOrNull } from "@/utils/string";
import BalanceUpdatePageSingleModal, { BalanceUpdatePageSingleModalCallbackKey } from "./uploadPageSingleModal";

export interface BalanceUpdateUploadPageProps {}

const BalanceUpdateUploadPage = (props: BalanceUpdateUploadPageProps) => {
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [uploadSingleModalVisible, setUploadSingleModalVisible] = useState<boolean>(false);
    const [uploadModalVisible, setUploadModalVisible] = useState<boolean>(false);
    const [isPreview, setIsPreview] = useState<boolean>(false);
    const [isIntervalEnable, setIsIntervalEnable] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<BalanceUpdateHistoricalItemProps[]>([]);
    const [servers, setServers] = useState<KeyValuePair[]>([]);
    const [serverIdDateTime, setServerIdDateTime] = useState<string | undefined>(undefined);

    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.RISK_TOOLS_BALANCE_UPDATE_EDIT);

    const columns: any[] = [
        DTColProps.Small({
            title: "Login",
            dataIndex: "login",
            key: "login",
        }),
        DTColProps.XSmall(
            {
                title: "Type",
                dataIndex: "updateType",
                key: "updateType",
                render: (text: number) => (text === 0 ? "Balance" : "Credit"),
            },
            ["text-center"]
        ),
        DTColProps.XSmall(
            {
                title: "Upload Currency",
                dataIndex: "uploadCurrency",
                key: "uploadCurrency",
            },
            ["text-center"]
        ),
        DTColProps.SCurrency(
            {
                title: "Upload Amount",
                dataIndex: "uploadAmount",
                key: "uploadAmount",
                sorter: (a: any, b: any) => a.uploadAmount - b.uploadAmount,
            },
            [],
            -1
        ),
        DTColProps.XSmall(
            {
                title: "Currency",
                dataIndex: "currency",
                key: "currency",
            },
            ["text-center"]
        ),
        DTColProps.SCurrency(
            {
                title: "Amount",
                dataIndex: "amount",
                key: "amount",
                sorter: (a: any, b: any) => a.amount - b.amount,
            },
            [],
            -1
        ),
        DTColProps.Middle({
            title: "Comment",
            dataIndex: "comment",
            key: "comment",
        }),
        DTColProps.Small({
            title: "Expiration",
            dataIndex: "expiration",
            key: "expiration",
        }),
        DTColProps.Small(
            {
                title: "Status",
                dataIndex: "updateStatus",
                key: "updateStatus",
                render: (text: number) => {
                    let displayText = "",
                        doneNumbers = [1, 4, 5, 6];

                    switch (text) {
                        case 0:
                            displayText = "Initial";
                            break;
                        case 1:
                            displayText = "Check Fail";
                            break;
                        case 2:
                            displayText = "Check Success";
                            break;
                        case 3:
                            displayText = "Waiting";
                            break;
                        case 4:
                            displayText = "Update Fail";
                            break;
                        case 5:
                            displayText = "Update Success";
                            break;
                        case 6:
                            displayText = "Cancel";
                            break;
                        case 7:
                            displayText = "Processing";
                            break;
                        default:
                            break;
                    }

                    return doneNumbers.includes(text) ? (
                        <Tag color={text === 5 ? "#87d068" : text === 4 || text === 6 || text === 1 ? "#f50" : ""}>{displayText}</Tag>
                    ) : text === 0 ? (
                        displayText
                    ) : (
                        <Badge status="processing" text={displayText} />
                    );
                },
            },
            ["text-center"]
        ),
        DTColProps.Small({
            title: "Update Time",
            dataIndex: "updateTimeStr",
            key: "updateTimeStr",
        }),
        DTColProps.Middle({
            title: "Message",
            dataIndex: "msg",
            key: "msg",
        }),
    ];

    const options: FlexiDataTableOptionsProps = useMemo(
        () => ({
            enableFilter: false,
            ...(enableUpdate && {
                add: true,
                separateActionButton: true,
                extraButtons: [
                    { text: "Upload", icon: <UploadOutlined />, value: "uploadFile" },
                    ...(!isEmptyOrNull(serverIdDateTime)
                        ? isPreview
                            ? [{ text: "Apply All", icon: <PlaySquareOutlined />, value: "executeAll" }]
                            : [{ text: "Preview", icon: <EyeOutlined />, value: "previewMode" }]
                        : []),
                ],
                ...(isIntervalEnable &&
                    data.some(x => [2, 3, 7].includes(x.updateStatus)) && {
                        refresh: {
                            timer: true,
                            refreshSecond: 3,
                        },
                    }),
                ...(isPreview && {
                    customExtraActionButton: (record: BalanceUpdateHistoricalItemProps, callback: FlexiDataTableCallbackProps) => {
                        return (
                            <>
                                {record.updateStatus === 2 && (
                                    <Button
                                        type="text"
                                        icon={<PlayCircleOutlined />}
                                        onClick={e => {
                                            e.preventDefault();
                                            callback(CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK, { key: "individualExecute", data: record });
                                        }}
                                    />
                                )}
                                {record.updateStatus < 3 && (
                                    <Button
                                        type="text"
                                        icon={<DeleteOutlined style={{ fontSize: "1.125rem" }} />}
                                        onClick={e => {
                                            e.preventDefault();
                                            callback(CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK, { key: "remove", data: record });
                                        }}
                                    />
                                )}
                            </>
                        );
                    },
                }),
            }),
        }),
        [serverIdDateTime, enableUpdate, isPreview, isIntervalEnable, data]
    );

    const componentCallback: FlexiDataTableCallbackProps = (type, formData: any) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                setUploadSingleModalVisible(true);
                break;
            case CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK:
                if (formData.key === "individualExecute") {
                    executeAllOrIndividual(false, [formData.data]);
                } else if (formData.key === "remove") {
                    removeItem(formData.data);
                }
                break;
            case CALLBACK_KEY.OTHERS:
                if (formData === "uploadFile") {
                    setUploadModalVisible(true);
                } else if (formData === "executeAll") {
                    executeAllOrIndividual(true);
                } else if (formData === "previewMode") {
                    processPreview();
                }
                break;
            case CALLBACK_KEY.REFRESH:
                setRunRefetchDataList(true);
                break;
            default:
                break;
        }
    };

    const getBalanceUploadList = useCallback(() => {
        if (serverIdDateTime) {
            setIsLoading(true);
            const formData = new FormData();
            formData.append("keyStr", serverIdDateTime);

            plainAxiosInstance
                .post(APIs.RISK_TOOL.GET_BALANCE_UPDATE_HISTORY_LIST, formData)
                .then(res => {
                    if (res.data && res.data.length > 0) {
                        setData(res.data);
                    } else {
                        setData([]);
                    }
                })
                .finally(() => setIsLoading(false));
        }
    }, [serverIdDateTime]);

    const processPreview = useCallback(() => {
        setIsLoading(true);
        plainAxiosInstance
            .post(APIs.RISK_TOOL.GET_BALANCE_UPDATE_UPLOAD_PREVIEW, data)
            .then(res => {
                //Do nothing
            })
            .catch(error => ErrorCatchValidator(error, (err: any) => message.error("Unable to process preview. Please try again later.", 2)))
            .finally(() => {
                setIsPreview(true);
                setRunRefetchDataList(true);
                setIsLoading(false);
            });
    }, [data]);

    const executeAllOrIndividual = useCallback(
        (isApplyAll: boolean, rowDataArr: BalanceUpdateHistoricalItemProps[] = data) => {
            Modal.confirm({
                title: `Are you sure you want to apply ${isApplyAll ? "all checked" : "this"} Balance Upload?`,
                onOk: () => {
                    setIsLoading(true);
                    plainAxiosInstance
                        .post(APIs.RISK_TOOL.GET_BALANCE_UPDATE_UPLOAD_APPLY_INDIVIDUALLY, rowDataArr)
                        .then(res => {
                            //DO NOTHING
                        })
                        .finally(() => {
                            setIsIntervalEnable(true);
                            setRunRefetchDataList(true);
                            setIsLoading(false);
                        });
                },
            });
        },
        [data]
    );

    const removeItem = (rowData: BalanceUpdateHistoricalItemProps) => {
        Modal.confirm({
            title: `Are you sure you want to cancel this Balance Upload?`,
            onOk: () => {
                setIsLoading(true);
                plainAxiosInstance
                    .post(APIs.RISK_TOOL.GET_BALANCE_UPDATE_UPLOAD_CANCEL_INDIVIDUALLY, [rowData])
                    .then(res => {
                        //DO NOTHING
                    })
                    .finally(() => {
                        setRunRefetchDataList(true);
                        setIsLoading(false);
                    });
            },
        });
    };

    const getServerList = () => {
        plainAxiosInstance.get(APIs.RISK_TOOL.GET_BALANCE_UPDATE_UPLOAD_SERVERS).then(res => {
            setServers(res.data.map((x: any) => ({ key: x.serverName, value: x.serverId })));
        });
    };

    useEffect(() => {
        if (runRefetchDataList) {
            getBalanceUploadList();
            setRunRefetchDataList(false);
        }
    }, [runRefetchDataList]);

    useEffect(() => {
        getServerList();

        return () => {};
    }, []);

    return (
        <>
            <div className="upload-container">
                <FlexiDataTable
                    bordered
                    rowKeyProperty="login"
                    title=""
                    columns={columns}
                    options={options}
                    dataSource={data}
                    callback={componentCallback}
                    loading={isLoading}
                />
            </div>
            <BalanceUpdateUploadModal
                isModalVisible={uploadModalVisible}
                callback={(type: number, data: any) => {
                    switch (type) {
                        case BalanceUpdateUploadModalCallbackKey.Close:
                            setUploadModalVisible(false);
                            break;
                        case BalanceUpdateUploadModalCallbackKey.Submitting:
                            setUploadModalVisible(false);
                            setIsPreview(false);
                            setIsLoading(true);
                            break;
                        case BalanceUpdateUploadModalCallbackKey.SubmitSuccess:
                            setServerIdDateTime(data as string);
                            setRunRefetchDataList(true);
                            setIsIntervalEnable(false);
                            break;
                        case BalanceUpdateUploadModalCallbackKey.SubmitFailed:
                            setServerIdDateTime("");
                            break;
                        default:
                            break;
                    }
                }}
                serverList={servers}
            />
            <BalanceUpdatePageSingleModal
                isModalVisible={uploadSingleModalVisible}
                callback={(type: number, data: any) => {
                    switch (type) {
                        case BalanceUpdatePageSingleModalCallbackKey.Close:
                            setUploadSingleModalVisible(false);
                            break;
                        case BalanceUpdatePageSingleModalCallbackKey.Submitting:
                            setUploadSingleModalVisible(false);
                            setIsPreview(false);
                            setIsLoading(true);
                            break;
                        case BalanceUpdatePageSingleModalCallbackKey.SubmitSuccess:
                            setServerIdDateTime(data as string);
                            setRunRefetchDataList(true);
                            setIsIntervalEnable(false);
                            break;
                        case BalanceUpdatePageSingleModalCallbackKey.SubmitFailed:
                            setServerIdDateTime("");
                            break;
                        default:
                            break;
                    }
                }}
                serverList={servers}
            />
        </>
    );
};

export default BalanceUpdateUploadPage;
