import { useCallback, useEffect, useMemo, useState } from "react";
import FlexiDataTable from "../../../../components/FlexiDataTable";
import { ComponentType, CALLBACK_KEY, SUCCESS_FAILED } from "../../../../constants";
import { CustomPaginationProps, FlexiDataTableCallbackProps, LPSymbolMetricsSummary } from "../../../../constants/type";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler, currencyRender } from "../../../../utils/Common";
import { apiRequest } from "../../../../services/apiConfig";
import { APIs } from "../../../../services/apis";
import { Tooltip } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export interface LP360SymbolSummaryProps {
    SelectedLP: string;
}

const LP360SymbolSummary = (props: LP360SymbolSummaryProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [pagination, setPagination] = useState<CustomPaginationProps>({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [sorting, setSorting] = useState<string>("");
    const [filterParams, setFilterParams] = useState<any>({});
    const [refetchData, setRefetchData] = useState<boolean>(false);
    const [lpSymbols, setLpSymbols] = useState<string[]>([]);
    const [LPSymbolMetricsSummaryData, setLPSymbolMetricsSummaryData] = useState<LPSymbolMetricsSummary[]>([]);
    const [unMapSymbols, setUnMapSymbols] = useState<string[]>([]);

    const tableColConfig: any = useMemo(() => {
        return [
            {
                title: "Symbol",
                dataIndex: "symbol",
                key: "symbol",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: lpSymbols.map((x: string) => ({ text: x, value: x })),
                        inputProps: {
                            mode: "multiple",
                        },
                    },
                },
                render: (value: string) => {
                    if (unMapSymbols.includes(value)) {
                        return (
                            <div className="lp-symbol-render-container">
                                <Tooltip title="Please note that the current symbol is not configured at the moment.">
                                    <span>{value}</span>
                                    <ExclamationCircleOutlined />
                                </Tooltip>
                            </div>
                        );
                    }
                    return value;
                },
            },
            DTColProps.Middle({
                title: "LP Symbol",
                dataIndex: "lpOriginalSymbol",
                key: "lpOriginalSymbol",
            }),
            DTColProps.Middle(
                {
                    title: "Net Volume",
                    dataIndex: "lpNetVolume",
                    key: "lpNetVolume",
                    sorter: (a: any, b: any) => a["lpNetVolume"] - b["lpNetVolume"],
                    render: (values: number) => currencyRender(values),
                },
                ["text-right"]
            ),
            DTColProps.Middle(
                {
                    title: "Net Volume (Scaled)",
                    dataIndex: "lpScaledNetVolume",
                    key: "lpScaledNetVolume",
                    sorter: (a: any, b: any) => a["lpScaledNetVolume"] - b["lpScaledNetVolume"],
                    render: (values: number) => currencyRender(values),
                },
                ["text-right"]
            ),
            DTColProps.Middle(
                {
                    title: "Exposure",
                    dataIndex: "lpExposure",
                    key: "lpExposure",
                    sorter: (a: any, b: any) => a["lpExposure"] - b["lpExposure"],
                    render: (values: number) => currencyRender(values, 2),
                },
                ["text-right"]
            ),
        ];
    }, [lpSymbols, unMapSymbols]);

    const componentCallback: FlexiDataTableCallbackProps = (type, FormData) => {
        switch (type) {
            case CALLBACK_KEY.HANDLE_PAGINATION_SORTING:
                setSorting(
                    FormData.sorter.column && FormData.sorter.order
                        ? `${FormData.sorter.column.key},${FormData.sorter.order === "ascend" ? "asc" : "desc"}`
                        : ""
                );
                setPagination(prev => ({ ...prev, current: FormData.pagination.current, pageSize: FormData.pagination.pageSize }));
                setRefetchData(true);
                break;
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                let lp360filterParams: any = {};
                Object.keys(FormData)
                    .filter(x => FormData[x] && FormData[x].toString().length > 0)
                    .map(x => {
                        if (x === "symbol") {
                            lp360filterParams["symbols"] = FormData[x];
                        } else {
                            lp360filterParams[x] = FormData[x];
                        }
                        return x;
                    });
                setFilterParams(lp360filterParams);
                setPagination(prev => ({ ...prev, current: 1 }));
                setRefetchData(true);
                break;
            default:
                break;
        }
    };

    const getLPSymbolMetricsSummaryData = () => {
        setIsLoading(true);
        const [lpValue, accountId, marketGroupId] = props.SelectedLP.split("-");
        apiRequest(APIs.GET_SYMBOL_METRICS_SUMMARY, {
            lPs: [lpValue],
            accountIds: [accountId],
            marketGroupIds: [marketGroupId ? Number(marketGroupId) : marketGroupId],
            limit: pagination.pageSize,
            current: pagination.current,
            viewExposureByAccountLevel: true,
            ...(sorting.length > 0 && { order: sorting }),
            ...(Object.keys(filterParams).length > 0 && { ...filterParams }),
        })
            .then((res: any) => {
                if (res && res.result && res.result.length > 0) {
                    setLpSymbols(res.lpSymbols);
                    setUnMapSymbols(res.lpUnmappedSymbols);
                    setLPSymbolMetricsSummaryData(res.result);
                    setPagination(prev => ({ ...prev, total: res.total }));
                } else {
                    setLpSymbols([]);
                    setUnMapSymbols([]);
                    setLPSymbolMetricsSummaryData([]);
                    setPagination(prev => ({ ...prev, total: 0 }));
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("LP Symbol Metrics list", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        if (refetchData) {
            getLPSymbolMetricsSummaryData();
            setRefetchData(false);
        }
    }, [refetchData]);

    useEffect(() => {
        if (props.SelectedLP !== "") {
            setRefetchData(true);
        } else {
            setIsLoading(false);
        }
    }, [props.SelectedLP]);

    return (
        <FlexiDataTable
            rowKeyProperty="symbol"
            title={<span style={{ fontWeight: "bold", fontSize: "1.25rem" }}>{`Symbol Summary`}</span>}
            columns={tableColConfig}
            dataSource={LPSymbolMetricsSummaryData ?? []}
            pagination={pagination}
            callback={componentCallback}
            loading={isLoading}
            serverSide={true}
            options={{
                serverFiltering: true,
            }}
        />
    );
};

export default LP360SymbolSummary;
