import { HomeOutlined } from "@ant-design/icons";
import { message, Row, Col, Button, Form, Popconfirm } from "antd";
import { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import { FormComponent } from "../../../../components/FormComponent";
import LoadingComponent from "../../../../components/Loading";
import SitePageHeader from "../../../../components/PageHeader";
import { ComponentType, SUCCESS_FAILED } from "../../../../constants";
import { REQUIRED_FIELD } from "../../../../constants/errorMessage";
import { apiRequest } from "../../../../services/apiConfig";
import { APIs } from "../../../../services/apis";
import { ErrorCatchValidator, ErrorMessageHandler } from "../../../../utils/Common";
import { NavigateFunction } from "react-router-dom";

export type CreateEditMetricGroupPageProps = {
    action: string;
    data: any;
};

const CreateEditMetricGroupPage = () => {
    let navigate = useNavigate();
    let location = useLocation();
    let com_state: CreateEditMetricGroupPageProps = location.state as CreateEditMetricGroupPageProps;
    const [currentState] = useState<CreateEditMetricGroupPageProps>(com_state || { action: "add", data: null });
    const [isAddAction, setIsAddAction] = useState<boolean>(currentState.action === "add");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [groupForm] = Form.useForm();
    let { id } = useParams();

    const onSubmit = useCallback(
        (values: any) => {
            setIsLoading(true);

            if (isAddAction) {
                apiRequest(APIs.CREATE_METRIC_GROUP, values)
                    .then(data => {
                        ErrorMessageHandler("metric category", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                        navigate("/siteadmin/appconfig/metriccategory");
                    })
                    .catch(error => {
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("metric category", SUCCESS_FAILED.FAILED_CREATE_DATA, err));
                        setIsLoading(false);
                    });
            } else {
                apiRequest(APIs.UPDATE_METRIC_GROUP, values)
                    .then(data => {
                        ErrorMessageHandler("metric category", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                        navigate("/siteadmin/appconfig/metriccategory");
                    })
                    .catch(error =>
                        ErrorCatchValidator(error, (err: any) => {
                            ErrorMessageHandler("metric category", SUCCESS_FAILED.FAILED_UPDATE_DATA, err);
                            message.error("Error occurred during updating of metric Category. Please try again.");
                        })
                    );
            }
        },
        [isAddAction]
    );

    const getMetricGroupList = useCallback(() => {
        setIsLoading(true);
        apiRequest(APIs.GET_METRIC_GROUP_LIST, { id: currentState.data ? currentState.data.id : id })
            .then((data: any) => {
                if (data.length > 0) {
                    groupForm.setFieldsValue(data[0]);
                } else {
                    groupForm.resetFields();
                }
                setIsLoading(false);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("metric category list", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                navigate("/siteadmin/appconfig/metriccategory");
            });
    }, [currentState]);

    const deleteMetricCategory = (id: string, navigator: NavigateFunction) => {
        apiRequest(APIs.DELETE_METRIC_GROUP, { id })
            .then(data => {
                ErrorMessageHandler("The metric category", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                navigator("/siteadmin/appconfig/metriccategory");
            })
            .catch(error => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("metric category", SUCCESS_FAILED.FAILED_DELETE_DATA, err)));
    };

    useEffect(() => {
        if (!isAddAction) {
            getMetricGroupList();
        }
        return () => {};
    }, [isAddAction, groupForm]);

    return (
        <SitePageHeader
            title={isAddAction ? "Create New Metric Category" : "Edit Metric Category"}
            routes={[
                {
                    path: "/siteadmin/appconfig/metriccategory",
                    breadcrumbName: "Metric Category",
                    icon: <HomeOutlined />,
                },
                {
                    path: "",
                    breadcrumbName: isAddAction ? "Create New Metric Category" : "Edit Metric Category",
                },
            ]}
            onBack={() => navigate("/siteadmin/appconfig/metriccategory")}
            extraProps={{
                extra: isAddAction
                    ? []
                    : [
                          <Popconfirm
                              key={"cetmp-del"}
                              title="Confirm to delete?"
                              onConfirm={() => deleteMetricCategory(currentState.data.id, navigate)}
                              okText="Yes"
                              cancelText="No"
                          >
                              <Button type="primary" danger>
                                  Delete Metric Category
                              </Button>
                          </Popconfirm>,
                      ],
            }}
        >
            {isLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Submitting..." />
                </div>
            ) : (
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    form={groupForm}
                    layout="horizontal"
                    initialValues={currentState.data}
                    onFinish={onSubmit}
                >
                    <Row>
                        <Col span={15}>
                            <FormComponent label={""} name={"id"} extra={{ type: ComponentType.hidden, value: "" }} />
                            <FormComponent
                                label="Category Name"
                                name="name"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Description"
                                name="description"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div className="step-btns-group">
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            )}
        </SitePageHeader>
    );
};

export default CreateEditMetricGroupPage;
