import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { Row, Col, Typography, Tooltip } from "antd";
import { useState } from "react";
import { FormComponent } from "../../../../components/FormComponent";
import { ComponentType } from "../../../../constants";
import { CHECKBOX_KEYS } from "..";

export interface HSGroupContentProps {
    provider?: any;
    gKey: string;
    groupName: string;
    data: HSGroupItemProps[];
    startSwitching?: boolean;
}

export interface HSGroupItemProps {
    index?: number;
    serverId?: number;
    serverCode?: string;
    server: string;
    security: string;
    ozStatus: number;
    peStatus: number;
    clusterName?: string;
}

const HSGroupContent = (props: HSGroupContentProps) => {
    const [isCollapse, setIsCollapse] = useState<boolean>(false);

    const getStatusTemplate = (item: any, key: string) => {
        let isOn = item[key] === 1;
        return <div className={`status ${isOn ? "on" : "off"}`}>{isOn ? "ON" : "OFF"}</div>;
    };

    return (
        <div className="group-item">
            <div className="group-container" {...props.provider.dragHandleProps}>
                <div>
                    {props.startSwitching && (
                        <FormComponent
                            label={""}
                            name={`${CHECKBOX_KEYS.GroupKey}${props.gKey}`}
                            extra={{ type: ComponentType.checkbox, value: "", itemProps: { noStyle: true } }}
                        />
                    )}
                    Group: <span className="g-name">{props.groupName}</span>
                </div>
                {isCollapse ? (
                    <CaretDownOutlined onClick={() => setIsCollapse(prev => !prev)} />
                ) : (
                    <CaretUpOutlined onClick={() => setIsCollapse(prev => !prev)} />
                )}
            </div>
            {!isCollapse && (
                <>
                    <Row className="tbl-header">
                        {props.startSwitching && <Col span={2}></Col>}
                        <Col span={6}>Server</Col>
                        <Col span={8}>Security</Col>
                        <Col span={2}>
                            <Tooltip title="Cluster" color="black">
                                <Typography.Text ellipsis={true}>
                                    Cluster
                                </Typography.Text>
                            </Tooltip>
                        </Col>
                        <Col span={props.startSwitching ? 3 : 4} className="center">
                            OZ
                        </Col>
                        <Col span={props.startSwitching ? 3 : 4} className="center">
                            PE
                        </Col>
                    </Row>
                    {props.data.map((x: HSGroupItemProps, idx: number) => (
                        <Row key={`hs-g-${idx}-${x.server}-${x.security}`} className="item">
                            {props.startSwitching && (
                                <Col span={2}>
                                    {x["ozStatus"] !== x["peStatus"] && (
                                        <FormComponent
                                            label={""}
                                            name={`cb-${props.gKey}-${x.serverCode}-${x.security}`}
                                            extra={{
                                                type: ComponentType.checkbox,
                                                value: "",
                                                itemProps: { noStyle: true },
                                            }}
                                        />
                                    )}
                                </Col>
                            )}
                            <Col span={6}>
                                <Tooltip title={x.server} color="black">
                                    <Typography.Text
                                        style={{ width: 100 }}
                                        ellipsis={true}
                                    >
                                        {x.server}
                                    </Typography.Text>
                                </Tooltip>
                            </Col>
                            <Col span={8}>{x.security}</Col>
                            <Col span={2}>
                                <Tooltip title={x.clusterName} color="black">
                                    <Typography.Text
                                        style={{ width: 50 }}
                                        ellipsis={true}
                                    >
                                        {x.clusterName}
                                    </Typography.Text>
                                </Tooltip>
                            </Col>
                            <Col span={props.startSwitching ? 3 : 4} className="center">
                                {getStatusTemplate(x, "ozStatus")}
                            </Col>
                            <Col span={props.startSwitching ? 3 : 4} className="center">
                                {getStatusTemplate(x, "peStatus")}
                            </Col>
                        </Row>
                    ))}
                </>
            )}
        </div>
    );
};

export default HSGroupContent;
