import { CheckOutlined, LineChartOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler, currencyRender } from "../../../../utils/Common";
import { SUCCESS_FAILED } from "../../../../constants";
import { APIs } from "../../../../services/apis";
import { plainAxiosInstance } from "../../../../services/axiosSetup";
import { FlexiDataTableOptionsProps, PriceMonitorSpreadAlarmDetailsProps, PriceMonitorSpreadAlarmProps } from "../../../../constants/type";
import { useGetSpreadAlarmListQuery } from "../../../../store/apis/pricemonitor/spreadalarm";
import FlexiDataTable from "../../../../components/FlexiDataTable";
import { useMemo } from "react";

export interface UnresolveSpreadAlarmListProps {
    showSpreadLineCallback: (data: PriceMonitorSpreadAlarmDetailsProps) => void;
}

const UnresolveSpreadAlarmList = (props: UnresolveSpreadAlarmListProps) => {
    const { data, isError, isFetching, refetch } = useGetSpreadAlarmListQuery({ url: "/30/1/1440" });

    const markupData = useMemo(() => {
        return isFetching || isError
            ? []
            : data?.data.map(x => ({
                  ...x,
                  detail: x.detail.map(y => ({
                      ...y,
                      rowKey: `${y.server}_${y.symbol}_${y.dateTimeStr}`,
                      showSpread: currencyRender(y.spread.toFixed(0)),
                      showSpreadThreshold: currencyRender(y.spreadThreshold.toFixed(0)),
                  })),
              })) || [];
    }, [isError, isFetching, data]);
    const tableCols: any = [
        {
            title: "Our Symbol",
            dataIndex: "symbol",
            key: "symbol",
        },
        {
            title: "First Appear",
            dataIndex: "firstAppearStr",
            key: "firstAppearStr",
        },
        {
            title: "Our Servers",
            dataIndex: "ourServerListStr",
            key: "ourServerListStr",
        },
        DTColProps.XSmall(
            {
                title: "Count",
                dataIndex: "count",
                key: "count",
            },
            ["text-center"]
        ),
        DTColProps.XSmall(
            {
                title: "Solved",
                dataIndex: "symbol",
                key: "symbol",
                render: (text: string, record: PriceMonitorSpreadAlarmProps) => {
                    return <Button type="primary" icon={<CheckOutlined />} className="shadow-light" onClick={() => processResolveBySymbol(record)} />;
                },
            },
            ["text-center"]
        ),
    ];

    const tableOpts: FlexiDataTableOptionsProps = {
        enableFilter: false,
        expandable: {
            expandedRowRender: (record: PriceMonitorSpreadAlarmProps) => {
                return (
                    <div className="expandable-row-container">
                        <FlexiDataTable
                            bordered
                            title={false}
                            rowKeyProperty="rowKey"
                            columns={[
                                DTColProps.XXSmall(
                                    {
                                        title: "Line",
                                        dataIndex: "Action",
                                        key: "Action",
                                        render: (text: string, record: PriceMonitorSpreadAlarmDetailsProps) => {
                                            return (
                                                <Button
                                                    type="primary"
                                                    icon={<LineChartOutlined />}
                                                    className="shadow-light"
                                                    onClick={() => props.showSpreadLineCallback && props.showSpreadLineCallback(record)}
                                                />
                                            );
                                        },
                                    },
                                    ["text-center"]
                                ),
                                DTColProps.Middle({
                                    width: "11.561vw",
                                    title: "Date Time",
                                    dataIndex: "dateTimeStr",
                                    key: "dateTimeStr",
                                }),
                                {
                                    title: "Server",
                                    dataIndex: "server",
                                    key: "server",
                                },
                                {
                                    title: "Symbol",
                                    dataIndex: "symbol",
                                    key: "symbol",
                                },
                                DTColProps.Small(
                                    {
                                        title: "Spread",
                                        dataIndex: "showSpread",
                                        key: "showSpread",
                                        defaultSortOrder: "descend",
                                        sorter: (a: PriceMonitorSpreadAlarmDetailsProps, b: PriceMonitorSpreadAlarmDetailsProps) =>
                                            a.spread - b.spread,
                                    },
                                    ["text-right"]
                                ),
                                DTColProps.Small(
                                    {
                                        title: "Spread Threshold",
                                        dataIndex: "showSpreadThreshold",
                                        key: "showSpreadThreshold",
                                    },
                                    ["text-right"]
                                ),
                                DTColProps.XSmall(
                                    {
                                        title: "Solved",
                                        dataIndex: "symbol",
                                        key: "symbol",
                                        render: (text: string, record: PriceMonitorSpreadAlarmDetailsProps) => {
                                            return (
                                                <Button
                                                    type="primary"
                                                    icon={<CheckOutlined />}
                                                    className="shadow-light"
                                                    onClick={() => processResolveById(record)}
                                                />
                                            );
                                        },
                                    },
                                    ["text-center"]
                                ),
                            ]}
                            dataSource={record.detail}
                            options={{
                                enableFilter: false,
                                showHideColumns: false,
                            }}
                            loading={false}
                        />
                    </div>
                );
            },
        },
    };

    const processResolveBySymbol = (record: PriceMonitorSpreadAlarmProps) => {
        const formData = new FormData();
        formData.append("keyStr", record.symbol);

        plainAxiosInstance
            .post(`${APIs.RC_PRICE_MONITOR.RESOLVE_SPREAD_ALARM_ITEM}/${300000}/0`, formData)
            .then(resp => {
                if (resp.data > 0) {
                    refetch();
                }
            })
            .catch(error => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("spread alarm", SUCCESS_FAILED.FAILED_RESOLVED, err));
            });
    };

    const processResolveById = (record: PriceMonitorSpreadAlarmDetailsProps) => {
        const formData = new FormData();
        formData.append("keyStr", `${record.dateTimeStr},${record.serverUno},${record.symbol}`);

        plainAxiosInstance
            .post(APIs.RC_PRICE_MONITOR.RESOLVE_SPREAD_ALARM_BY_ID, formData)
            .then(resp => {
                if (resp.data > 0) {
                    refetch();
                }
            })
            .catch(error => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("spread alarm", SUCCESS_FAILED.FAILED_RESOLVED, err));
            });
    };

    return (
        <div className="unresolved-spread-alarm-container">
            <FlexiDataTable
                bordered
                rowKeyProperty="symbol"
                title={false}
                columns={tableCols}
                options={tableOpts}
                dataSource={markupData}
                loading={isFetching}
            />
        </div>
    );
};

export default UnresolveSpreadAlarmList;
