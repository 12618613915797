import { Badge, Skeleton, Tabs } from "antd";
import { useState } from "react";
import NotificationTab from "./NotificationTab";

export interface NotificationPopoverProps {
    notificationList: any;
    notificationTotal: any;
    isLoading: boolean;
    setTotalCallback?: (newTotal: any) => void;
    setListCallback?: (newListData: any) => void;
};

const NotificationPopover = (props: NotificationPopoverProps) => {
    const [currentType, setCurrentType] = useState<string>("3,4");

    return (
        <div className="header-notification-list-container" id="scrollableDiv">
            <div className="main-title-panel">
                <span>Notifications</span>
            </div>
            <div>
                <Tabs
                    type="line"
                    defaultActiveKey={currentType}
                    onChange={(activeKey: string) => setCurrentType(activeKey)}
                    items={[
                        {
                            label: (
                                <span>
                                    Warning
                                    {props.notificationTotal["3,4"].unReadTotal > 0 && (
                                        <Badge count={props.notificationTotal["3,4"].unReadTotal} color="#bcbcbc" style={{ marginLeft: 7 }} />
                                    )}
                                </span>
                            ),
                            key: "3,4",
                            children: (
                                <Skeleton loading={props.isLoading} avatar paragraph={{ rows: 2 }} active>
                                    <NotificationTab
                                        type="3,4"
                                        items={props.notificationList["3,4"]}
                                        notificationTotal={props.notificationTotal["3,4"]}
                                        setListCallback={(newListData: any) => {
                                            props.setListCallback && props.setListCallback(newListData);
                                        }}
                                        setTotalCallback={(newTotal: any) => {
                                            props.setTotalCallback && props.setTotalCallback(newTotal);
                                        }}
                                    />
                                </Skeleton>
                            )
                        },
                        {
                            label: (
                                <span>
                                    Notice
                                    {props.notificationTotal["1,2"].unReadTotal > 0 && (
                                        <Badge count={props.notificationTotal["1,2"].unReadTotal} color="#bcbcbc" style={{ marginLeft: 7 }} />
                                    )}
                                </span>
                            ),
                            key: "1,2",
                            children: (
                                <Skeleton loading={props.isLoading} avatar paragraph={{ rows: 2 }} active>
                                    <NotificationTab
                                        type="1,2"
                                        items={props.notificationList["1,2"]}
                                        notificationTotal={props.notificationTotal["1,2"]}
                                        setListCallback={(newListData: any) => {
                                            props.setListCallback && props.setListCallback(newListData);
                                        }}
                                        setTotalCallback={(newTotal: any) => {
                                            props.setTotalCallback && props.setTotalCallback(newTotal);
                                        }}
                                    />
                                </Skeleton>
                            )
                        },
                    ]}
                />
            </div>
        </div>
    );
};

export default NotificationPopover;
