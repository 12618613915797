import { useEffect, useMemo, useState } from "react";
import CardBox from "../../../../components/Common/CardBox";
import { Segmented, Select } from "antd";
import GroupTransferPage from "./components/grouptransfer";
import { APIs } from "../../../../services/apis";
import { plainAxiosInstance } from "../../../../services/axiosSetup";
import { isEmptyOrNull } from "../../../../utils/string";
import GroupTransferHistoricalPage, { GroupTransferHistoricalPageOption } from "./components/historical";
import GroupTransferSettingsPage, { GroupTransferSettingsPageCallbackKey } from "./components/setting";

export interface GroupTransferToolProps {}

export interface ReasonProps {
    id: number;
    reason: string;
}

const GroupTransferTool = (props: GroupTransferToolProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [currentTab, setCurrentTab] = useState<string>("Group Transfer");
    const [currentSelected, setCurrentSelected] = useState<number>(1);
    const [reasonOpt, setReasonOpt] = useState<ReasonProps[]>([]);

    const reasonList = useMemo(() => {
        return {
            reasonOpt: reasonOpt.map(x => ({ text: x.reason, value: x.reason })),
            list: reasonOpt,
        };
    }, [reasonOpt]);

    const getConfig = () => {
        setIsLoading(true);
        plainAxiosInstance
            .get(APIs.RISK_TOOL.GET_GROUP_TRANSFER_TOOL_CONFIG)
            .then((res: any) => {
                if (!isEmptyOrNull(res.data)) {
                    setReasonOpt(res.data);
                }
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        getConfig();
    }, []);

    return (
        <div className="risk-tool-group-transfer-tool-container">
            <CardBox title={"Group Transfer Tool"}>
                <div className="main-container">
                    <div className="top-container">
                        <div className="left">
                            <Segmented
                                value={currentTab}
                                options={["Group Transfer", "Historical", "Setting"]}
                                onChange={(activeKey: any) => setCurrentTab(activeKey)}
                            />
                        </div>
                        {currentTab === "Historical" && (
                            <div className="right">
                                <Select
                                    value={currentSelected}
                                    dropdownMatchSelectWidth={false}
                                    options={GroupTransferHistoricalPageOption}
                                    onChange={(value: any) => setCurrentSelected(value)}
                                />
                            </div>
                        )}
                    </div>
                    {currentTab === "Group Transfer" && <GroupTransferPage reasonOptions={reasonList.reasonOpt} />}
                    {currentTab === "Historical" && (
                        <GroupTransferHistoricalPage reasonOptions={reasonList.reasonOpt} selectedPage={currentSelected} />
                    )}
                    {currentTab === "Setting" && (
                        <GroupTransferSettingsPage
                            isLoading={isLoading}
                            reasonList={reasonList.list}
                            callback={(type: GroupTransferSettingsPageCallbackKey, FormData: any) => {
                                switch (type) {
                                    case GroupTransferSettingsPageCallbackKey.RefetchData:
                                        getConfig();
                                        break;
                                    default:
                                        break;
                                }
                            }}
                        />
                    )}
                </div>
            </CardBox>
        </div>
    );
};

export default GroupTransferTool;
