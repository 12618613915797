import { SUCCESS_FAILED } from "@/constants";
import { KeyValuePair } from "@/constants/type";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import { Button, Checkbox, Divider, Form, Select } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useEffect, useState } from "react";
import { CompareItem } from "../index";

type FilterByServerProps = {
    servers: KeyValuePair[];
    isLoadingServer: boolean;
    onAddCompareItem: (items: CompareItem[]) => void
};
const FilterByServer = ({ servers, onAddCompareItem, isLoadingServer }: FilterByServerProps) => {
    const [symbols, setSymbols] = useState<KeyValuePair[]>([]);
    const [keyword, setKeyword] = useState<string>("");
    const [selectedServerUno, setSelectedServerUno] = useState<number | null>(null);
    const [selectedSymbols, setSelectedSymbols] = useState<string[]>([]);

    const addBtnDisabled = selectedServerUno == null || !selectedSymbols.length;

    const { data, isLoading, error, isFetching } = useQuery({
        queryKey: [APIs.RC_LP_PRICE_COMPARE.GET_SYMBOLS_BY_SERVER, selectedServerUno],
        queryFn: () =>
            plainAxiosInstance.get(`${APIs.RC_LP_PRICE_COMPARE.GET_SYMBOLS_BY_SERVER}/${selectedServerUno}`, {
                headers: {
                    Accept: "application/json",
                },
            }),
        staleTime: 60 * 1000 * 5,
        enabled: selectedServerUno !== null,
    });

    useEffect(() => {
        const res = data?.data;
        if (res) {
            let selectList: KeyValuePair[] = res.map((item: any) => ({
                text: item.symbol,
                value: item.symbol,
            }));
            if (keyword && keyword !== "") {
                selectList = selectList.filter(item => item.text.toLowerCase().includes(keyword.toLowerCase()));
            }
            setSymbols(selectList);
        }
    }, [data?.data, keyword]);

    useEffect(()=>{
        if(error){
            ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("Symbols", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
        }
    },[error])


    function checkAll(e: CheckboxChangeEvent) {
        const checked = e.target.checked;
        if(checked){
            setSelectedSymbols(prev=>{
                const merged = [...prev, ...symbols.map(item => item.value as string)];
                return Array.from(new Set(merged));
            });
        }else{
            setSelectedSymbols([]);
        }        
    }

    function onAddToList(){
        const serverName = servers.find(item => item.value === selectedServerUno)?.text as string;
        const items: CompareItem[] = selectedSymbols.map(symbol => ({
            compareServer: selectedServerUno as number,
            compareServerName: serverName,
            compareSymbol: symbol
        }));
        onAddCompareItem(items);
    }

    return (
        <Form layout={"horizontal"} labelCol={{ span: 3 }} wrapperCol={{ span: 21 }}>
            <Form.Item label="Server">
                <Select
                    loading={isLoadingServer}
                    options={servers.map(item => ({ label: item.text, value: item.value })) || []}
                    style={{ width: "100%" }}
                    placeholder="Please select Server"
                    value={selectedServerUno}
                    onChange={(value: number) => {
                        setSelectedServerUno(value);
                    }}
                />
            </Form.Item>

            <Form.Item label="Symbols">
                <Select
                    mode="multiple"
                    className="symbols-select"
                    autoClearSearchValue={false}
                    onSearch={value => setKeyword(value)}
                    searchValue={keyword}
                    allowClear
                    options={symbols}
                    style={{ width: "100%" }}
                    disabled={selectedServerUno === null || isLoading || isFetching}
                    loading={selectedServerUno !== null && (isLoading || isFetching)}
                    placeholder="Please select symbols"
                    value={selectedSymbols}
                    onChange={(value: string[]) => {
                        setSelectedSymbols(value);
                    }}
                    dropdownRender={menu => (
                        <>
                            {menu}
                            <Divider style={{ margin: "8px 0" }} />
                            <Checkbox onChange={checkAll} style={{ padding: "0px 8px 4px 8px" }} disabled={isLoading || isFetching}
                                checked={selectedSymbols.length === symbols.length && symbols.length > 0}
                            >
                                Check All
                            </Checkbox>
                        </>
                    )}
                />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 3, span: 21 }}>
                <Button icon={<PlusCircleOutlined />} disabled={addBtnDisabled} onClick={onAddToList}>
                    Add to Overview
                </Button>
            </Form.Item>
        </Form>
    );
};

export default FilterByServer;
