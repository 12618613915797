import { Descriptions, Popover } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { CrmBlockTypes } from "../../../constants/type";

interface CRMBlockTypeInfoProps {
    crmBlockTypes: CrmBlockTypes[];
}

const CRMBlockTypeInfo = (props: CRMBlockTypeInfoProps) => {
    props.crmBlockTypes.sort((a, b) => a.blockType.localeCompare(b.blockType));

    return (
        <Popover
            content={
                <Descriptions bordered title="CRM Block Types" size={"small"}>
                    {props.crmBlockTypes.map(item => (
                        <Descriptions.Item key={item.blockType} label={item.blockType}>
                            {item.blockTypeDesc}
                        </Descriptions.Item>
                    ))}
                </Descriptions>
            }
            trigger="click"
        >
            CRM Block Type <QuestionCircleOutlined style={{ marginLeft: "0.32vw", cursor: "pointer" }} />
        </Popover>
    );
};

export default CRMBlockTypeInfo;
