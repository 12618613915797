 import { createApi } from "@reduxjs/toolkit/query/react";
 import { APIs, defaultBaseQueryProps, ResponseProps } from "../../services/apiConfig";
 import { Servers } from "../../constants/type";
 import { axiosBaseQuery } from "../axiosBaseQuery";
 
 export interface serversRequest {}
 
 export interface serversResponse extends ResponseProps<Servers[]> {}
 
 export const serversApiSlice = createApi({
     reducerPath: "api_7",
     baseQuery: axiosBaseQuery(),
     keepUnusedDataFor: 5, //global caching duration (seconds)
     endpoints: (builder) => ({
         getServers: builder.query<serversResponse, serversRequest | undefined>({
             keepUnusedDataFor: 0, //disable for caching for particular api request
             query: (arg) => ({ ...defaultBaseQueryProps, url: APIs.GET_SERVERS_LIST, method: "POST", params: arg }),
             transformResponse: (returnValue: serversResponse, meta) => {
                 return returnValue;
             },
         }),
     }),
 });
 
 export const { useGetServersQuery } = serversApiSlice;
 