import { FormComponent } from "@/components/FormComponent";
import { ComponentType } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { KeyValuePair } from "@/constants/type";
import { Form, Modal, Row, Col } from "antd";
import { useEffect } from "react";
import { ServerFeatureAccountProps } from ".";

export interface CreateEditServerFeatureAccountModalProps {
    isModalVisible: boolean;
    modalTitle: string;
    callback: (type: number, data: any) => void;
    data: any | undefined;
    servers: KeyValuePair[];
    features: KeyValuePair[];
    dataList: ServerFeatureAccountProps[];
}

export enum CreateEditServerFeatureAccountModalCallbackKey {
    Close = 0,
    Submit = 1,
}

const CreateEditServerFeatureAccountModal = (props: CreateEditServerFeatureAccountModalProps) => {
    const [executeForm] = Form.useForm();

    useEffect(() => {
        if (props.isModalVisible) {
            if (props.data !== undefined) {
                executeForm.setFieldsValue(props.data);
            } else {
                executeForm.resetFields();
            }
        }
    }, [props.isModalVisible, props.data]);

    return (
        <Modal
            style={{ top: 10 }}
            width={"40vw"}
            title={props.modalTitle}
            open={props.isModalVisible}
            okText="Submit"
            onOk={() => {
                executeForm
                    .validateFields()
                    .then((values: any) => {
                        props.callback(CreateEditServerFeatureAccountModalCallbackKey.Submit, values);
                    })
                    .catch((err: any) => console.log(err));
            }}
            onCancel={() => props.callback(CreateEditServerFeatureAccountModalCallbackKey.Close, null)}
            maskClosable={false}
        >
            <Form form={executeForm} layout="horizontal" labelCol={{ span: 6 }} labelAlign="left">
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Server"
                            name="serverId"
                            extra={{
                                type: ComponentType.dropdown,
                                value: props.servers,
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                                ...(props.data !== undefined && { inputProps: { disabled: true } }),
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Feature"
                            name="featureId"
                            extra={{
                                type: ComponentType.dropdown,
                                value: props.features,
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                                ...(props.data !== undefined && { inputProps: { disabled: true } }),
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Login"
                            name="login"
                            extra={{
                                type: ComponentType.text,
                                value: "",
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Password"
                            name="password"
                            extra={{
                                type: ComponentType.password,
                                value: "",
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Comment"
                            name="comment"
                            extra={{
                                type: ComponentType.textarea,
                                value: "",
                                inputProps: {
                                    row: 6,
                                },
                            }}
                        />
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default CreateEditServerFeatureAccountModal;
