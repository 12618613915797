import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import forbiddenImage from "../../assets/images/forbidden.gif";
import { userLogout } from "../../services/api";

const ForbiddenPage = () => {
    let navigate = useNavigate();
    return (
        <div className="forbidden-container ">
            <span>Please contact site administrator to grant access permission</span>
            <img src={forbiddenImage} alt="" />
            <Button
                type="primary"
                onClick={() => {
                    userLogout().then((res) => {
                        navigate("/login", { replace: true });
                    });
                }}
            >
                Back
            </Button>
        </div>
    );
};

export default ForbiddenPage;
