import { useMemo, useEffect, useState } from "react";

const useAudio = (url: any): [boolean, Function] => {
    const audio = useMemo<HTMLAudioElement>(() => new Audio(url), []);
    const [playing, setPlaying] = useState<boolean>(false);

    const playOrStop = (): void => setPlaying(!playing);

    useEffect(() => {
        playing ? audio.play() : audio.pause();
        return () => { };
    }, [playing]);

    useEffect(() => {
        audio.addEventListener("ended", () => setPlaying(false));
        return () => {
            audio.removeEventListener("ended", () => setPlaying(false));
        };
    }, []);

    return [playing, playOrStop];
};

export default useAudio;
