import { SoundOutlined } from "@ant-design/icons";
import { Switch, Segmented } from "antd";
import { KeyValuePair, componentCallbackProps } from "../../../../constants/type";
import { useState, useEffect, useCallback } from "react";
import {
    ALARM_BREACH_SEVERITY,
    ALARM_DASHBOARD_ITEM_CALLBACK_KEY,
    ALARM_DASHBOARD_ITEM_KEY,
    CALLBACK_KEY,
    SUCCESS_FAILED,
} from "../../../../constants";
import { SortList } from "../../../../utils/array";
import { AlarmDashboardFilterParamsProps } from "../../alarmDashboard";
import { APIs, apiRequest } from "../../../../services/apiConfig";
import { ErrorCatchValidator, ErrorMessageHandler } from "../../../../utils/Common";
import { DefaultIfEmpty, hasAnyKey } from "../../../../utils/object";
import { SegmentedValue } from "antd/lib/segmented";
import { TimerButton } from "../../../../components/Common";

export interface AlarmDashboardFilterPanelProps {
    filterOptions: AlarmDashboardFilterOption[];
    filterParams: AlarmDashboardFilterParamsProps;
    isLoading: boolean;
    callback: (callbackKey: ALARM_DASHBOARD_ITEM_CALLBACK_KEY, values: componentCallbackProps) => void;
}

export interface AlarmDashboardFilterOption {
    text: string;
    key: string;
    options: KeyValuePair[];
}

const AlarmDashboardFilterPanel = (props: AlarmDashboardFilterPanelProps) => {
    const [counterData, setCounterData] = useState<KeyValuePair[]>([]);
    const [selectedFilter, setSelectedFilter] = useState<any>({});

    const refreshPeriod: number = 20; //in seconds

    const triggerCallback = (key: ALARM_DASHBOARD_ITEM_CALLBACK_KEY, changeType: ALARM_DASHBOARD_ITEM_KEY, values: any) => {
        props.callback && props.callback(key, { type: changeType, values });
    };

    const toggleFilterParams = (key: string, enabled: boolean) => {
        setSelectedFilter((prev: any) => ({ ...prev, [key]: enabled }));
        props.callback &&
            props.callback(ALARM_DASHBOARD_ITEM_CALLBACK_KEY.FILTER_CHANGED, {
                type: ALARM_DASHBOARD_ITEM_KEY.MODULES_FILTER,
                values: { ...selectedFilter, [key]: enabled },
            });
    };

    const getSeverityStatistics = useCallback(() => {
        apiRequest(APIs.GET_ALARM_DASHBOARD_SEVERITY_SUMMARY, { ...props.filterParams })
            .then(res => {
                if (res && Object.keys(res).length > 0) {
                    setCounterData(
                        SortList(Object.keys(res), undefined, (a: string, b: string) => `${b}`.localeCompare(`${a}`)).map((x: string) => ({
                            text: x,
                            value: res[x],
                        }))
                    );
                }
            })
            .catch(error =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("alarm severity summary", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            );
    }, [props.filterParams]);

    useEffect(() => {
        if (props.filterOptions.length > 0) {
            setSelectedFilter(
                props.filterOptions.reduce((finalObj: any, x: AlarmDashboardFilterOption) => {
                    if (x.options.length > 0) {
                        x.options.forEach(y => {
                            finalObj[`${x.key}|${y.value}`] = true;
                        });
                    }
                    return finalObj;
                }, {})
            );
        } else {
            setSelectedFilter({});
        }
    }, [props.filterOptions]);

    useEffect(() => {
        hasAnyKey(props.filterParams) && getSeverityStatistics();
    }, [props.filterParams]);

    return (
        <>
            <div className="header-container">
                <div className="left">
                    {/* {!props.isLoading && (
                        <Button className="btn-filter shadow-light" type={isOpenFilterPanel ? "primary" : "default"} icon={<FilterOutlined />}>
                            Filter
                        </Button>
                    )} */}
                    <div className="sound-switcher-container">
                        <SoundOutlined />
                        <Switch
                            checkedChildren="On"
                            unCheckedChildren="Off"
                            defaultChecked
                            onChange={(checked: boolean) =>
                                triggerCallback(ALARM_DASHBOARD_ITEM_CALLBACK_KEY.FILTER_CHANGED, ALARM_DASHBOARD_ITEM_KEY.SOUND_HANDLER, checked)
                            }
                        />
                    </div>
                </div>
                <div className="right">
                    <div className="extra-item-container">
                        <TimerButton
                            key={`alrm-dh-aurf-${Math.random()}`}
                            period={refreshPeriod}
                            enablePeriodSelection={false}
                            onFinish={(type: CALLBACK_KEY, period: number) =>
                                triggerCallback(ALARM_DASHBOARD_ITEM_CALLBACK_KEY.REFRESH_LIST, ALARM_DASHBOARD_ITEM_KEY.TIMER_REFRESH, 1)
                            }
                        />
                        <Segmented
                            options={[
                                { label: "Today", value: 1 },
                                { label: "Past 24 hours", value: 2 },
                            ]}
                            className={`padding-size-${counterData.length}`}
                            onChange={(value: SegmentedValue) => {
                                triggerCallback(ALARM_DASHBOARD_ITEM_CALLBACK_KEY.FILTER_CHANGED, ALARM_DASHBOARD_ITEM_KEY.DATE_MODE, value);
                            }}
                        />
                    </div>
                    {counterData.length > 0 &&
                        counterData.map((x: KeyValuePair, idx: number) => (
                            <div key={`ctr-cont-${idx}`} className={`counter-container shadow-light priority-color-${x.text}`}>
                                <div className="flag-container">
                                    <span className="label">{ALARM_BREACH_SEVERITY[parseInt(x.text)] ?? ""}</span>
                                    <span>{x.value}</span>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
            {!props.isLoading && props.filterOptions.length > 0 && (
                <div className="extra-filter-panel">
                    {props.filterOptions.map((x: AlarmDashboardFilterOption) => (
                        <div key={`aldshflpl-flopt-${x.key}`} className="options-item">
                            <div className="label">{x.text}:</div>
                            <div className="options-container">
                                {x.options.map((y: KeyValuePair) => (
                                    <div
                                        key={`aldshflpl-flopt-${x.key}-v-${y.value}`}
                                        className={`item shadow-light ${
                                            DefaultIfEmpty(selectedFilter, `${x.key}|${y.value}`, false) ? "active" : ""
                                        }`}
                                        onClick={() =>
                                            toggleFilterParams(`${x.key}|${y.value}`, !DefaultIfEmpty(selectedFilter, `${x.key}|${y.value}`, false))
                                        }
                                    >
                                        {y.text}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};

export default AlarmDashboardFilterPanel;
