import { Button, Form } from "antd";
import { useNavigate } from "react-router-dom";
import { FormComponent } from "../../components/FormComponent";
import { ComponentType, SUCCESS_FAILED } from "../../constants";
import { REQUIRED_FIELD } from "../../constants/errorMessage";
import { apiRequest, APIs } from "../../services/apiConfig";
import { ErrorCatchValidator, ErrorMessageHandler } from "../../utils/Common";

const ResetPasswordPage = () => {
    const [resetPwdForm] = Form.useForm();
    let navigate = useNavigate();

    const onResetButtonClick = () => {
        resetPwdForm
            .validateFields()
            .then((values) => {
                apiRequest(APIs.USER_RESET_PASSWORD, {
                    password: values.password,
                    confirmPassword: values.confirmPassword,
                })
                    .then((res) => {
                        ErrorMessageHandler("Account password reset", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                        navigate("/login");
                    })
                    .catch((error) =>
                        ErrorCatchValidator(error, (err: any) =>
                            ErrorMessageHandler("account password reset", SUCCESS_FAILED.FAILED_UPDATE_DATA, err)
                        )
                    );
            })
            .catch((err) => {});
    };
    return (
        <div className="reset-password-container">
            <div className="reset-password-panel">
                <div className="header-container">
                    <div className="title">Reset Password</div>
                    <div className="description">
                        In order to protect your account, make sure your password:
                        <ul>
                            <li>At least 8 characters.</li>
                            <li>Must contains at least 1 uppercase letter.</li>
                            <li>Must contains at least 1 lowercase letter.</li>
                            <li>Must contains at least 1 number.</li>
                        </ul>
                    </div>
                </div>
                <Form form={resetPwdForm} labelCol={{ span: 8 }} layout="vertical">
                    <FormComponent
                        label="New Password"
                        name={"password"}
                        extra={{
                            type: ComponentType.password,
                            value: "",
                            rules: [
                                {
                                    required: true,
                                    message: REQUIRED_FIELD,
                                },
                            ],
                        }}
                    />
                    <FormComponent
                        label="Confirm Password"
                        name={"confirmPassword"}
                        extra={{
                            type: ComponentType.password,
                            value: "",
                            rules: [
                                {
                                    required: true,
                                    message: REQUIRED_FIELD,
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue("password") === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error("Password is not matched, please try again"));
                                    },
                                }),
                            ],
                        }}
                    />
                    <Button type="primary" onClick={() => onResetButtonClick()} style={{ width: "100%" }}>
                        Reset Password
                    </Button>
                </Form>
            </div>
        </div>
    );
};

export default ResetPasswordPage;
