import { useMemo, useRef } from "react";
import CanvasJSReact from "@canvasjs/react-charts";

type LPPriceCompareLineChartProps = {
    compareData?: CompareData
}
export type CompareData = {
    serverName: string[];
    sline: DataProps[][];
    symbol: string[];
};
type DataProps = {
    x: number;
    y: number;
    mark: boolean;
};

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const LPPriceCompareLineChart = ({ compareData }: LPPriceCompareLineChartProps) => {
    const cRef = useRef<any[]>([]);

    const markupData = useMemo(() => {
		const datas: any[] = []; // Initialize the datas array
        if (compareData === undefined) {
            return [];
        }
        if(!compareData.sline || compareData.sline.length === 0){
            return [];
        }
        compareData.sline.forEach((serverSeries,index) => {
            const dataPoints: any[] = [];
            // Iterate over each data point in the current TickLineGraph array
            serverSeries.forEach(dataPoint => {
                // Convert the x value to a Date object using the provided format
                const xDate = new Date(dataPoint.x.toString().replace(/^(\d{4})(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)$/, "$4:$5:$6 $2/$3/$1"));

                // Conditionally add index labels if 'mark' is present
                if (dataPoint.mark) {
                    dataPoints.push({
                        x: xDate,
                        y: dataPoint.y,
                        indexLabel: "*",
                        indexLabelFontWeight: "bolder",
                        indexLabelFontSize: 30,
                        indexLabelFontColor: "red",
                    });
                } else {
                    dataPoints.push({
                        x: xDate,
                        y: dataPoint.y,
                    });
                }
            });
            datas.push({
                type: "line",
                name: `${compareData.serverName[index]} ${compareData.symbol[index]}`,
                showInLegend: true,
                xValueFormatString: "hh:mm:ss",
                dataPoints,
            });
        });

        return datas;
    }, [compareData]);

    const options = useMemo(() => {
        return {
            height: 600,
            exportEnabled: true,
            zoomEnabled: true,
            title: {
                text: "LP報價速度對比",
            },
            toolTip: {
                shared: true,
            },
            axisX: {
                // labelFormatter: function (e) {
                //     return CanvasJS.formatDate(e.value, "HH:mm");
                // },
            },
            axisY: {
                valueFormatString: " ",
            },
            legend: {
				cursor: "pointer",
				itemclick: function (e: any) {
					if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
						e.dataSeries.visible = false;
					} else {
						e.dataSeries.visible = true;
					}
	
					e.chart.render();
				}
			},
            data: markupData,
        };
    }, [markupData]);

    return (
        <div className="line-chart-container">
            {compareData === undefined ? (
                <></>
            ) : (
                <CanvasJSChart
                    options={options}
                    onRef={(ref: any) => {
                        cRef.current.push(ref);
                    }}
                />
            )}
        </div>
    );
};

export default LPPriceCompareLineChart;
