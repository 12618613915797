import React from "react";
import DataCenterSettingsTable from "./DataCenterSettingsTable";
import TabContent from "../components/TabContent";
const DataCenterSettings = () => {
    return (
        <TabContent>
            <DataCenterSettingsTable />
        </TabContent>
    );
};

export default DataCenterSettings;
