import { CheckOutlined, ClearOutlined, FilterOutlined } from "@ant-design/icons";
import { Button, Col, Row, Space, Tag } from "antd";
import { FormInstance } from "antd/es/form";
import { useState, useMemo, useEffect } from "react";
import FilterOuterComponent from "./filterOuterComponent";
import { resetObjectValues } from "../../../../../utils/object";
import { DIMENSION_LEVEL_LABEL } from "../../../../../constants";

export interface FilterComponentProps {
    form: FormInstance;
    filterOptions: any;
    onFilterSubmit: Function;
    getAccountList: any;
    filterParams: any;
}

const IntradayFilterComponent = (props: FilterComponentProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [filterParams, setFilterParams] = useState<any>(props.filterParams ? props.filterParams : {});

    useEffect(() => {
        setFilterParams(props.filterParams);
    }, [props.filterParams]);

    const submitFilterParams = (filterType: string, updatedValues: any) => {
        setFilterParams({
            ...filterParams,
            [filterType]: updatedValues,
        });
        props.onFilterSubmit({
            ...filterParams,
            [filterType]: updatedValues,
        });
    };

    const renderFilterSelection = (filterType: string, label: string, color: string) => {
        const selectedValues = props.filterParams ? props.filterParams[filterType] : filterParams[filterType];
        if (selectedValues && selectedValues.length > 0) {
            return (
                <div className="filter-selection-panel">
                    <div className="label">{label}:</div>
                    <div className="tag-container">
                        {filterType === "IB" || filterType === "Account"
                            ? selectedValues.map((selectedOption: any, idx: number) => (
                                  <Tag
                                      key={`tag-${filterType}-${selectedOption}`}
                                      color={color}
                                      className="value"
                                      closable
                                      onClose={() => {
                                          const updatedValues = selectedValues.filter((value: any) => value !== selectedOption);
                                          submitFilterParams(filterType, updatedValues);
                                      }}
                                  >
                                      {selectedOption.split("|")[0] + " | " + selectedOption.split("|")[2]}
                                  </Tag>
                              ))
                            : filterType === "AccGroup"
                            ? selectedValues.map((selectedOption: any, idx: number) => (
                                  <Tag
                                      key={`tag-${filterType}-${selectedOption}`}
                                      color={color}
                                      className="value"
                                      closable
                                      onClose={() => {
                                          const updatedValues = selectedValues.filter((value: any) => value !== selectedOption);
                                          console.log("updatedValues", filterType, updatedValues);
                                          submitFilterParams(filterType, updatedValues);
                                      }}
                                  >
                                      {selectedOption}
                                  </Tag>
                              ))
                            : props.filterOptions[filterType]
                                  .filter((option: any) => selectedValues.includes(option.value))
                                  .map((selectedOption: any, idx: number) => (
                                      <Tag
                                          key={`tag-${filterType}-${selectedOption.value}`}
                                          color={color}
                                          className="value"
                                          closable
                                          onClose={() => {
                                              const updatedValues = selectedValues.filter((value: any) => value !== selectedOption.value);
                                              submitFilterParams(filterType, updatedValues);
                                          }}
                                      >
                                          {selectedOption.text}
                                      </Tag>
                                  ))}
                    </div>
                </div>
            );
        }

        return null;
    };

    const memoizedFilterPanels = useMemo(
        () => (
            <>
                {renderFilterSelection("Account", DIMENSION_LEVEL_LABEL.ACCOUNT, "#f00f00")}
                {renderFilterSelection("AccGroup", DIMENSION_LEVEL_LABEL.ACCGROUP, "#000080")}
                {renderFilterSelection("Symbol", DIMENSION_LEVEL_LABEL.SYMBOL, "#531DAB")}
                {renderFilterSelection("SymbolAssetType", DIMENSION_LEVEL_LABEL.SYMBOLASSETTYPE, "#08979C")}
                {renderFilterSelection("Server", DIMENSION_LEVEL_LABEL.SERVER, "#096DD9")}
                {renderFilterSelection("Brand", DIMENSION_LEVEL_LABEL.BRAND, "#CF1322")}
                {renderFilterSelection("Country", DIMENSION_LEVEL_LABEL.COUNTRY, "#D46B08")}
                {renderFilterSelection("IB", DIMENSION_LEVEL_LABEL.IB, "#389E0D")}
            </>
        ),
        [filterParams, props.filterParams, props.filterOptions]
    );

    return (
        <>
            <div className="filter-panel">
                <FilterOutlined style={{ fontSize: "1.125rem" }} onClick={prev => setIsOpen(!isOpen)} />
                {memoizedFilterPanels}
            </div>
            {isOpen && (
                <div className="filter-dropdown-header-panel">
                    <FilterOuterComponent form={props.form} filterOptions={props.filterOptions} getAccountList={props.getAccountList} />
                    <div className="buttons-panel">
                        <Space className="buttons">
                            <Button
                                icon={<ClearOutlined />}
                                onClick={() => {
                                    setFilterParams([]);
                                    props.form.setFieldsValue(resetObjectValues(props.filterOptions, []));
                                }}
                            >
                                Clear All
                            </Button>
                            <Button
                                type="primary"
                                icon={<CheckOutlined />}
                                onClick={() => {
                                    props.form
                                        .validateFields()
                                        .then((values: any) => {
                                            setFilterParams(values);
                                            props.onFilterSubmit && props.onFilterSubmit(values);
                                        })
                                        .catch(err => {})
                                        .finally(() => setIsOpen(false));
                                }}
                            >
                                Filter
                            </Button>
                        </Space>
                    </div>
                </div>
            )}
        </>
    );
};

export default IntradayFilterComponent;
