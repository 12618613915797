import { Link, useLocation } from "react-router-dom";
import AccountSummaryPanel from "./Components/accountSummaryPanel";
import AccountRiskScore from "./Components/accountRiskScore";
import TaggingSummary from "./Components/taggingSummary";
import CommentsHistory from "./Components/commentsHistory";
import PNLSymbolSummary from "./Components/pnlSymbolSummary";
import PNLSummaryChart from "./Components/pnlSummaryChart";
import { enabledCheckingByLocation, ProfileInfoProps } from "./viewProfile";
import DailyPnlSummaryChart from "./Components/dailyPnlSummary";
import BalanceEquityChart from "./Components/balanceEquity";
import { getAppSettings, getSource } from "../../../services/localStorage";
import { Row, Col, Typography, Descriptions, Form, Tag, message } from "antd";
import MarginLevelChart from "./Components/marginLevel";
import ActualLeverageChart from "./Components/actualLeverage";
import { DateRangeFilter, KeyValuePair, MetricGroup, TagLabel, WatchListGroup } from "../../../constants/type";
import { useEffect, useState } from "react";
import { FormComponent } from "../../../components/FormComponent";
import { ComponentType, SUCCESS_FAILED } from "../../../constants";
import { APIs } from "../../../services/apis";
import { apiRequest } from "../../../services/apiConfig";
import { ErrorCatchValidator, ErrorMessageHandler, currencyRender } from "../../../utils/Common";
import RelativeDateRange from "../../../components/RelativeDateRange";
import { hasAnyKey } from "../../../utils/object";
import { getAppConfig } from "../../../services/api";
import moment from "moment";

export interface AccountOverviewPageProps extends ProfileInfoProps {
    metricCategories: MetricGroup[];
    setSymbolCallback?: (symbols: string[]) => void;
    setAccountInfoCallback: (accountInfo: ProfileInfoProps) => void;
    groupsList: WatchListGroup[];
}

interface expandedProps {
    isExpand: boolean;
    counter: number;
}

const AccountOverviewPage = (props: AccountOverviewPageProps) => {
    let location = useLocation();
    //const authHp = new AuthHelper();

    const [eState, setEState] = useState<expandedProps>({
        isExpand: false,
        counter: 0,
    });

    const [showTags, setShowTags] = useState<boolean>(true);
    const [labelsForm] = Form.useForm();
    const [currentState, setCurrentState] = useState<any>(props || {});
    const [dateFilter, setDateFilter] = useState<DateRangeFilter>({});
    const [labels, setLabels] = useState<string[]>(props.labels || []);
    const [tagLabelList, setTagLabelList] = useState<TagLabel[]>([]);
    const quickRangeList: KeyValuePair[] = getAppSettings().RELATIVE_DATE_RANGE;
    const [optionList, setOptionList] = useState<KeyValuePair[]>([]);

    const whenExpanded = () => {
        setEState({
            isExpand: true,
            counter: !eState.isExpand ? eState.counter + 0 : eState.counter + 1,
        });
    };

    const showClickableTags = () => {
        apiRequest(APIs.UPDATE_ACCOUNT_LABELS, {
            labels: labels,
            accountId: props.accountId,
            serverId: props.serverId,
        })
            .then((data: any) => {
                apiRequest(APIs.GET_ACCOUNT_PROFILE_LIST, {
                    serverId: props.serverId,
                    accountId: props.accountId,
                    isOneOnly: true,
                }).then((data: any) => {
                    setCurrentState(data.result[0]);
                    setLabels(data.result[0].labels);
                    props.setAccountInfoCallback(data.result[0]);
                });
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("account remarks labels", SUCCESS_FAILED.FAILED_UPDATE_DATA, err))
            )
            .finally(() => setShowTags(true));
    };

    const combineProps = (defProps: any, dateProps: any) => ({ ...defProps, ...{ dateRangeFilter: dateProps } });

    const replaceNValues = (quickRange: any, nValueObj: any) => {
        let newArray: any[] = [];
        if (nValueObj.id === quickRange.value) {
            newArray = nValueObj.values.map((n: number) => ({
                text: quickRange.text.replace(" N ", ` ${n} `),
                value: `${nValueObj.id},${n}`,
            }));
        }
        return newArray;
    };

    useEffect(() => {
        setCurrentState(props);
        setLabels(props.labels || []);

        return () => {};
    }, [props]);

    useEffect(() => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["taglabel"] })
            .then((data: any) => {
                setTagLabelList(data.tagLabels);
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => {}));

        getAppConfig(["RelativeDateRangeNValueConfig"]).then((d: any) => {
            if (d && d.length > 0) {
                let findIdx: number = d.findIndex((x: any) => x.key === "RelativeDateRangeNValueConfig");
                let newArr: any = [];
                let tempArr: any = [];
                let filteredArr: any = [];
                if (findIdx > -1) {
                    let relativeDateRangeNValues = JSON.parse(d[findIdx].value);
                    quickRangeList.forEach((x: any) => {
                        if (x.text.includes(" N ")) {
                            newArr = relativeDateRangeNValues.map((n: any) => {
                                let replacedArr = replaceNValues(x, n);
                                return replacedArr;
                            });
                            tempArr.push(...newArr);
                        } else {
                            tempArr.push(x);
                        }
                    });
                    filteredArr = tempArr.flat();
                    setOptionList(filteredArr);
                }
            }
        });
        return () => {};
    }, []);

    return (
        <>
            <div className="main-panel">
                {/* <div className="tagging-summary-chart" >
                    <AccountRiskScore {...props} />
                </div> */}
                <div className="tagging-summary-chart">
                    <TaggingSummary {...combineProps(props, dateFilter)} />
                </div>
                {/**
                 *  29/02/2024 - Xin
                 *  commenting out PNL Symbol Summary chart
                 *  because inaccurate data
                 */}
                {/* <div className="treemap-chart padding-20px">
                    <PNLSymbolSummary {...combineProps(props, dateFilter)} setSymbolCallback={props.setSymbolCallback} />
                </div> */}
                <div className="line-chart padding-20px">
                    <PNLSummaryChart {...combineProps(props, dateFilter)} />
                </div>
                <div className="line-chart padding-20px">
                    <DailyPnlSummaryChart {...combineProps(props, dateFilter)} />
                </div>
                {/**
                 *  28/02/2024 - Xin
                 *  commenting out Margin Level and Actual Leverage charts
                 *  because pipeline not running temp for now
                 */}
                {/* <div className="line-chart padding-20px">
                    <MarginLevelChart {...combineProps(props, dateFilter)} />
                </div> */}
                {/* <div className="line-chart padding-20px">
                    <ActualLeverageChart {...combineProps(props, dateFilter)} />
                </div> */}
                <div className="line-chart padding-20px">
                    <BalanceEquityChart {...combineProps(props, dateFilter)} />
                </div>
            </div>
            <div className="sub-panel">
                <div className="filter-date-range-container">
                    <RelativeDateRange
                        withForm
                        valuesChangedCallback={(values: any) => {
                            setDateFilter(hasAnyKey(values) ? values : {});
                            props.setAccountInfoCallback({ ...currentState, dateRangeFilter: hasAnyKey(values) ? values : {} });
                        }}
                        optionsList={optionList}
                    />
                </div>
                <div className="additional-info-container">
                    <Row className="remarks-container">
                        <Col span={6} className="remarks-label">
                            Remark(s)
                        </Col>
                        <Col span={17} style={{ padding: "0.4rem 0" }}>
                            {showTags ? (
                                <div
                                    className={`free-text-container ${currentState.labels?.length === 0 ? "empty-list" : ""} ${
                                        !enabledCheckingByLocation(getSource(location)) ? "view-only" : ""
                                    }`}
                                    onClick={() => setShowTags(false)}
                                >
                                    <div className="tag-container">
                                        {currentState.labels &&
                                            currentState.labels?.length > 0 &&
                                            currentState.labels?.map((x: any) => {
                                                return <Tag key={x}>{x}</Tag>;
                                            })}
                                        {currentState.labels && currentState.labels?.length === 0 && <p>None</p>}
                                    </div>
                                </div>
                            ) : (
                                <Form
                                    form={labelsForm}
                                    onValuesChange={(values: any) => {
                                        if (values.length === 0) return labels;
                                        else setLabels(values.labels);
                                    }}
                                    initialValues={currentState || {}}
                                >
                                    <Row>
                                        <Col span={24}>
                                            <FormComponent
                                                label=""
                                                name={"labels"}
                                                extra={{
                                                    type: ComponentType.dropdown,
                                                    value: tagLabelList.map((x: any) => ({ text: x.name, value: x.name })),
                                                    inputProps: {
                                                        mode: "tags",
                                                        onBlur: showClickableTags,
                                                        autoFocus: true,
                                                    },
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        </Col>
                    </Row>
                    <div className="flex-column">
                        <Row>
                            <Col span={8} className="flex-column">
                                <p className="subtitle">Client ID</p>
                                {props.clientId ? (
                                    <Link
                                        to={`/analysis/clientlist/launch?clientId=${props.clientId}&regulatorId=${props.regulatorId}&crmServerId=${props.crmServerId}`}
                                        style={{ fontWeight: "bold", fontSize: "0.946vw" }}
                                    >
                                        {props.clientId}
                                    </Link>
                                ) : (
                                    "-"
                                )}
                            </Col>
                            <Col span={16} className="flex-column" style={{ paddingLeft: "0.9765vw" }}>
                                <p className="subtitle">Client Name</p>
                                <p>{props.clientName || "-"}</p>
                            </Col>
                        </Row>
                    </div>
                    <div className={`flex-column ${props.isWatch ? "bordered-bottom" : ""}`}>
                        <Row>
                            <Col span={24} className="flex-column">
                                <p className="subtitle">Source</p>
                                <p>{`${props.crmServer || "-"}`}</p>
                            </Col>
                        </Row>
                    </div>
                    <div className={`flex-column ${props.isWatch ? "bordered-bottom" : ""}`}>
                        <Row>
                            <Col span={24} className="flex-column">
                                <p className="subtitle">Regulator</p>
                                <p>{`${props.regulatorName || "-"}`}</p>
                            </Col>
                        </Row>
                    </div>
                    {props.isWatch ? (
                        <>
                            <div className="flex-column">
                                <Row>
                                    <Col span={24} className="flex-column">
                                        <p className="subtitle">Watch List Info</p>
                                        <Descriptions
                                            column={1}
                                            size="small"
                                            className="tiny-description"
                                            labelStyle={{ minWidth: "6.306vw" }}
                                            contentStyle={{ textAlign: "right", minWidth: "6.306vw", display: "block" }}
                                        >
                                            <Descriptions.Item label="Action Date (Server)">
                                                {props?.accountWatchDetails?.actionDate
                                                    ? moment(props.accountWatchDetails.actionDate).format("YYYY-MM-DD HH:mm:ss")
                                                    : "-"}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="PNL Captured">
                                                {currencyRender(`${props?.accountWatchDetails?.pnlCaptured}`)}
                                            </Descriptions.Item>

                                            <Descriptions.Item label="Current Historical PNL">
                                                {props?.accountWatchDetails?.pnlHistorical
                                                    ? currencyRender(`${props?.accountWatchDetails?.pnlHistorical}`)
                                                    : "-"}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="PNL After Action">
                                                {props?.accountWatchDetails?.pnlDifference
                                                    ? currencyRender(`${props?.accountWatchDetails?.pnlDifference}`)
                                                    : "-"}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Past 7 days Cum PNL">
                                                {props?.accountWatchDetails?.pnlPass7Days
                                                    ? currencyRender(`${props?.accountWatchDetails?.pnlPass7Days}`)
                                                    : "-"}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Deposit Captured">
                                                {props?.accountWatchDetails?.depositCaptured
                                                    ? currencyRender(`${props?.accountWatchDetails?.depositCaptured}`)
                                                    : "-"}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Current Deposit">
                                                {props?.accountWatchDetails?.depositCurrent
                                                    ? currencyRender(`${props?.accountWatchDetails?.depositCurrent}`)
                                                    : "-"}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Withdrawal Captured">
                                                {props?.accountWatchDetails?.withdrawalCaptured
                                                    ? currencyRender(`${props?.accountWatchDetails?.withdrawalCaptured}`)
                                                    : "-"}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Current Withdrawal">
                                                {props?.accountWatchDetails?.withdrawalCurrent
                                                    ? currencyRender(`${props?.accountWatchDetails?.withdrawalCurrent}`)
                                                    : "-"}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="PNL Breach (USD)">
                                                {props?.accountWatchDetails?.pnlBreach
                                                    ? currencyRender(`${props?.accountWatchDetails?.pnlBreach}`)
                                                    : "-"}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Inspector">
                                                {props?.accountWatchDetails?.assignee
                                                    ? currencyRender(`${props?.accountWatchDetails?.assignee}`)
                                                    : "-"}
                                            </Descriptions.Item>

                                            <Descriptions.Item label=""> </Descriptions.Item>
                                        </Descriptions>
                                    </Col>
                                </Row>
                            </div>
                            <div className="flex-column">
                                <Row>
                                    <Col span={24} className="flex-column">
                                        <p className="subtitle">Watch List Comment</p>
                                        <div key={eState.counter}>
                                            <Typography.Paragraph
                                                ellipsis={{ rows: 2, expandable: true, symbol: "more", onExpand: whenExpanded }}
                                            >{`${props.accountWatchDetails?.comment || "-"}`}</Typography.Paragraph>
                                            {eState.isExpand && (
                                                <Typography.Link
                                                    onClick={() =>
                                                        setEState({
                                                            isExpand: false,
                                                            counter: !eState.isExpand ? eState.counter + 0 : eState.counter + 1,
                                                        })
                                                    }
                                                >
                                                    less
                                                </Typography.Link>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </>
                    ) : null}
                </div>
                <div className="comments-history-container">
                    <CommentsHistory accountData={{ ...props }} disableUpdate={!enabledCheckingByLocation(getSource(location))} />
                </div>
                <div style={{ marginTop: "2.828vh" }}>
                    <AccountSummaryPanel accountData={{ ...props }} metricCategories={props.metricCategories} currency={props.currency as string} />
                </div>
            </div>
        </>
    );
};

export default AccountOverviewPage;
