import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CardBox from "../../../components/Common/CardBox";
import FlexiDataTable from "../../../components/FlexiDataTable";
import { STATUS_TYPE, CALLBACK_KEY, SUCCESS_FAILED, ComponentType } from "../../../constants";
import { Brands2, RoleState, FlexiDataTableOptionsProps, FlexiDataTableCallbackProps, KeyValuePair } from "../../../constants/type";
import AuthHelper, { AuthKeys } from "../../../helpers/authHelper";
import { apiRequest } from "../../../services/apiConfig";
import { APIs } from "../../../services/apis";
import { useGetRoleMenusQuery } from "../../../store/apis/role";
import { ToOptionTypeList } from "../../../utils/array";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";
import { objectToArray } from "../../../utils/object";

const RolePermissionPage = () => {
    const { isLoading, data, refetch } = useGetRoleMenusQuery({ withModules: false });
    const [manualLoading, setManualLoading] = useState<boolean>(false);

    let navigate = useNavigate();

    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.ADMIN_ROLE_PERMISSION_EDIT);

    const brandList: KeyValuePair[] = useMemo(
        () =>
            objectToArray(
                (data?.data || []).reduce((bList: any, x: any) => {
                    if (x["brands"] && x["brands"].length > 0) {
                        x.brands.forEach((y: any) => {
                            if (bList[`${y.brandId}`] === undefined) {
                                bList[`${y.brandId}`] = { text: y.brandName, value: y.brandId };
                            }
                        });
                    }
                    return bList;
                }, {})
            ),
        [data]
    );

    const columns = useMemo(
        () => [
            DTColProps.XLarge({
                title: "Role Name",
                dataIndex: "role",
                key: "role",
                options: {
                    filter: {
                        type: ComponentType.text,
                    },
                },
            }),

            {
                title: "Description",
                dataIndex: "description",
                key: "description",
            },

            DTColProps.Middle({
                title: "Brands",
                dataIndex: "brands",
                key: "brands",
                render: (brands: Brands2[]) => (brands.length > 0 ? brands.map(x => x.brandName).join(", ") : "-"),
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: brandList,
                        callback: (filterValue: any, rowData: any) => rowData["brands"].some((x: any) => `${x.brandId}` === `${filterValue}`),
                    },
                },
            }),

            DTColProps.Status({
                title: "Status",
                dataIndex: "active",
                key: "active",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: ToOptionTypeList(STATUS_TYPE),
                    },
                },
            }),
        ],
        [brandList]
    );

    const options: FlexiDataTableOptionsProps = {
        view: (record: any, option: any) => {
            let newProps = { ...option };
            newProps = <Link to={`/siteadmin/rolepermission/view/${record.roleId}`}>{newProps}</Link>;
            return newProps;
        },
        add: enableUpdate,
        edit: enableUpdate,
        delete: enableUpdate,
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, data) => {
        switch (type) {
            case CALLBACK_KEY.VIEW_RECORD:
                navigate(`/siteadmin/rolepermission/view/${data.roleId}`, {
                    state: { roleId: data.roleId },
                });
                break;
            case CALLBACK_KEY.CREATE_NEW:
                navigate("/siteadmin/rolepermission/create", { state: { action: "add" } });
                break;
            case CALLBACK_KEY.DO_EDIT:
                let state: RoleState = {
                    action: "edit",
                    roleId: data.roleId,
                };
                navigate("/siteadmin/rolepermission/edit", { state: state });
                break;
            case CALLBACK_KEY.DO_DELETE:
                setManualLoading(true);
                apiRequest(APIs.DELETE_ROLE_MENU, { roleId: data.roleId })
                    .then(data => {
                        ErrorMessageHandler("The role", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                        setManualLoading(false);
                        refetch();
                    })
                    .catch(error => {
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("role", SUCCESS_FAILED.FAILED_DELETE_DATA, err));
                        setManualLoading(false);
                    });
                break;
        }
    };

    useEffect(() => {
        refetch();
        return () => {};
    }, []);

    return (
        <div>
            <CardBox title={"Role & Permission"}>
                <FlexiDataTable
                    rowKeyProperty="roleId"
                    title=""
                    columns={columns}
                    options={options}
                    dataSource={data?.data || []}
                    callback={componentCallback}
                    loading={isLoading || manualLoading}
                />
            </CardBox>
        </div>
    );
};

export default RolePermissionPage;
