import { useCallback, useEffect, useMemo, useState } from "react";
import CardBox from "../../../../components/Common/CardBox";
import FlexiDataTable from "../../../../components/FlexiDataTable";
import { CustomPaginationProps, FlexiDataTableCallbackProps, FlexiDataTableOptionsProps, KeyValuePair } from "../../../../constants/type";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "../../../../constants";
import { APIs } from "../../../../services/apis";
import { plainAxiosInstance } from "../../../../services/axiosSetup";
import { currencyRender, DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../../../utils/Common";
import { isEmptyOrNull } from "../../../../utils/string";
import { Button, message, Modal } from "antd";
import moment from "moment";
import { CheckCircleOutlined, CloseCircleOutlined, ContainerOutlined, FileSearchOutlined } from "@ant-design/icons";
import ReportBatchModal, { ReportBatchModalCallbackKey } from "../../../ReportingModule/ReportBatch";
import { REQUIRED_FIELD } from "@/constants/errorMessage";

export interface SwapChargingToolProcessProps {}

interface SwapChargingToolProcessItemProps {
    serverId: string;
    receiveDate: string;
    login: number;
    group: string;
    subAccount: number;
    status: number;
    swap: number;
    createDate: string;
    updateDate: string | null;
}

const SwapChargingToolProcess = (props: SwapChargingToolProcessProps) => {
    const [servers, setServers] = useState<KeyValuePair[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [isReportModalVisible, setIsReportModalVisible] = useState<boolean>(false);
    const [data, setData] = useState<SwapChargingToolProcessItemProps[]>([]);
    const [tradeDetails, setTradeDetails] = useState<any>([]);
    const [currentData, setCurrentData] = useState<SwapChargingToolProcessItemProps | undefined>(undefined);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [pagination, setPagination] = useState<CustomPaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0,
    });
    const [sorting, setSorting] = useState<string>("");
    const [filterParams, setFilterParams] = useState<any>({
        receiveDate: moment().format("YYYYMMDD"),
    });

    const modalProps = useMemo(() => {
        return isEmptyOrNull(currentData)
            ? { title: "", datas: [] }
            : {
                  title: `(${currentData?.serverId}-${currentData?.receiveDate}-${currentData?.login}) Swap Charging Tool Trades`,
                  datas: tradeDetails,
              };
    }, [currentData, tradeDetails]);

    const columns: any[] = useMemo(
        () => [
            DTColProps.Small({
                title: "Server",
                dataIndex: "serverId",
                key: "serverId",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: servers,
                    },
                },
            }),
            DTColProps.Small({
                title: "Received Date",
                dataIndex: "receiveDate",
                key: "receiveDate",
                options: {
                    filter: {
                        type: ComponentType.date,
                        value: "",
                        dateFormat: "YYYY-MM-DD",
                        rules: [{ required: true, message: REQUIRED_FIELD }],
                    },
                },
            }),
            {
                title: "Login",
                dataIndex: "login",
                key: "login",
                options: {
                    filter: {
                        type: ComponentType.text,
                        value: "",
                    },
                },
            },
            {
                title: "Group",
                dataIndex: "group",
                key: "group",
            },
            DTColProps.Status(
                {
                    title: "SubAccount",
                    dataIndex: "subAccount",
                    key: "subAccount",
                    render: (subAccount: number) => {
                        return (
                            <span>
                                {subAccount === 1 ? (
                                    <CheckCircleOutlined style={{ color: "#0ab76e", fontSize: "1.375rem" }} />
                                ) : (
                                    <CloseCircleOutlined style={{ color: "#f00f00", fontSize: "1.375rem" }} />
                                )}
                            </span>
                        );
                    },
                },
                ["text-center"]
            ),
            DTColProps.XSmall({
                width: "6vw",
                title: "Status",
                dataIndex: "status",
                key: "status",
                sorter: (a: any, b: any) => a.status - b.status,
                render: (status: number) => {
                    return <span>{status === 1 ? "Received" : status === 2 ? "Unnecessary" : "Unreceived"}</span>;
                },
            }),
            DTColProps.SCurrency({
                title: "Swap",
                dataIndex: "swap",
                key: "swap",
            }),
            DTColProps.Small({
                width: "8.5vw",
                title: "Create Date",
                dataIndex: "createDate",
                key: "createDate",
            }),
        ],
        [servers]
    );

    const detailColumns: any[] = useMemo(
        () => [
            {
                title: "Ticket",
                dataIndex: "ticket",
                key: "ticket",
            },
            {
                title: "Symbol",
                dataIndex: "symbol",
                key: "symbol",
            },
            {
                title: "Clean Symbol",
                dataIndex: "cleanSymbol",
                key: "cleanSymbol",
            },
            DTColProps.XSCurrency({
                title: "Volume",
                dataIndex: "volume",
                key: "volume",
                render: (volume: number) => currencyRender(volume.toFixed(2), 2),
            }),
            DTColProps.XXSmall({
                title: "CMD",
                dataIndex: "cmd",
                key: "cmd",
                render: (cmd: number) => (cmd === 0 ? "Buy" : "Sell"),
            }),
            DTColProps.SCurrency({
                title: "Eod Price",
                dataIndex: "eodPrice",
                key: "eodPrice",
                render: (eodPrice: number) => currencyRender(eodPrice.toFixed(5), 5),
            }),
            DTColProps.SCurrency(
                {
                    width: "6vw",
                    title: "Contract Size",
                    dataIndex: "contractSize",
                    key: "contractSize",
                },
                [],
                -1
            ),
            DTColProps.XSCurrency({
                title: "Point Size",
                dataIndex: "pointSize",
                key: "pointSize",
                render: (closePrice: number) => currencyRender(closePrice.toFixed(5), 5),
            }),
            DTColProps.XSCurrency({
                title: "Swap Point",
                dataIndex: "swapPoint",
                key: "swapPoint",
            }),
            DTColProps.SCurrency({
                title: "Close Price",
                dataIndex: "closePrice",
                key: "closePrice",
                render: (closePrice: number) => currencyRender(closePrice.toFixed(5), 5),
            }),
            DTColProps.XSCurrency({
                width: "6vw",
                title: "Swap",
                dataIndex: "swap",
                key: "swap",
                render: (swap: number) => currencyRender(swap.toFixed(`${currentData?.group || ""}`.endsWith("_BTC") ? 8 : 2)),
            }),
        ],
        [currentData]
    );

    const options: FlexiDataTableOptionsProps = {
        serverFiltering: true,
        view: (record: SwapChargingToolProcessItemProps) => {
            return <Button type="text" icon={<FileSearchOutlined style={{ fontSize: "1.125rem" }} />} onClick={() => getDetails(record)} />;
        },
        export: {
            text: "Download Report",
        },
        extraButtons: [{ text: "Report Listing", value: "reportlist", icon: <ContainerOutlined /> }],
    };

    const downloadReport = useCallback(() => {
        plainAxiosInstance
            .post(APIs.RISK_TOOL.GET_SWAP_CHARGING_TOOL_PROCESS_DOWNLOAD, filterParams)
            .then((res: any) => {
                if (res.data === 0) {
                    message.success("Add to batch successed. Please download from【Report Listing】", 3);
                } else {
                    message.error(`Add to batch failed`, 3);
                }
            })
            .catch((error: any) => message.error(`Add to batch failed: "${error.message}"`, 3));
    }, [filterParams]);

    const componentCallback: FlexiDataTableCallbackProps = (type, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.HANDLE_PAGINATION_SORTING:
                FormData.sorter.order === undefined
                    ? setSorting("")
                    : setSorting(
                          FormData.sorter.column && FormData.sorter.order
                              ? `${FormData.sorter.column.key},${FormData.sorter.order === "ascend" ? "asc" : "desc"}`
                              : ""
                      );
                setPagination(prev => ({ ...prev, current: FormData.pagination.current, pageSize: FormData.pagination.pageSize }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                let tmpfilterParams: any = {};
                Object.keys(FormData)
                    .filter(x => FormData[x] && FormData[x].toString().length > 0)
                    .map(x => {
                        if (x === "receiveDate") {
                            tmpfilterParams["receiveDate"] = moment(FormData[x]).format("YYYYMMDD");
                        } else {
                            tmpfilterParams[x] = FormData[x];
                        }
                        return x;
                    });
                setFilterParams(tmpfilterParams);
                setSorting("");
                setPagination(prev => ({ ...prev, current: 1 }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.EXPORT_CSV_EXCEL:
                downloadReport();
                break;
            case CALLBACK_KEY.OTHERS:
                switch (FormData) {
                    case "reportlist":
                        setIsReportModalVisible(true);
                        break;
                    default:
                        break;
                }
                break;
            default:
                break;
        }
    };

    const getDetails = (selectedTradeData: SwapChargingToolProcessItemProps) => {
        setCurrentData(selectedTradeData);
        setTradeDetails([]);
        setIsModalVisible(true);
        plainAxiosInstance
            .get(
                `${APIs.RISK_TOOL.GET_SWAP_CHARGING_TOOL_PROCESS_DETAILS}?serverId=${selectedTradeData.serverId}&receiveDate=${selectedTradeData.receiveDate}&login=${selectedTradeData.login}`
            )
            .then((res: any) => {
                if (res.data) {
                    setTradeDetails(res.data);
                } else {
                    setTradeDetails([]);
                    message.error("Request failed.", 3);
                }
            });
    };

    const getMappingData = useCallback(() => {
        let params = {
            page: pagination.current,
            rows: pagination.pageSize,
            ...filterParams,
            ...(!isEmptyOrNull(sorting) &&
                sorting.split(",").reduce((sortObj: any, x: string, idx: number) => {
                    if (idx === 0) {
                        sortObj["sortBy"] = [x];
                    } else {
                        sortObj["sortDesc"] = [x === "asc" ? false : true];
                    }
                    return sortObj;
                }, {})),
        };

        plainAxiosInstance
            .post(APIs.RISK_TOOL.GET_SWAP_CHARGING_TOOL_PROCESS, params)
            .then(res => {
                if (res.data && res.data.resultList && res.data.resultList.length > 0) {
                    setData(res.data.resultList.map((x: any) => ({ ...x, key: `${x.login}-${x.createDate}` })));
                    setPagination(prev => ({ ...prev, total: res.data.total }));
                } else {
                    setData([]);
                    setPagination(prev => ({ ...prev, total: 0 }));
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("swap charging tool process", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsLoading(false));
    }, [sorting, filterParams, pagination]);

    const getConfig = () => {
        plainAxiosInstance
            .get(APIs.RC_ROOT.GET_SERVERS)
            .then((res: any) => {
                setServers(res.data.map((x: any) => ({ text: x.serverName, value: x.serverId })));
            })
            .finally(() => setRunRefetchDataList(true));
    };

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            getMappingData();
            setRunRefetchDataList(false);
        }
        return () => {};
    }, [runRefetchDataList]);

    useEffect(() => {
        getConfig();
    }, []);

    return (
        <>
            <div className="swap-charging-tool-process-container">
                <CardBox title={"Swap Charging Tool Process"}>
                    <div className="main-container">
                        <FlexiDataTable
                            bordered
                            rowKeyProperty="key"
                            title=""
                            columns={columns}
                            options={options}
                            dataSource={data || []}
                            callback={componentCallback}
                            loading={isLoading}
                            pagination={pagination}
                            serverSide={true}
                            filterInitialValue={{ receiveDate: moment() }}
                        />
                    </div>
                </CardBox>
            </div>
            <Modal
                style={{ top: 10 }}
                width={"70vw"}
                title={modalProps.title}
                open={isModalVisible}
                okButtonProps={{ style: { display: "none" } }}
                cancelText="Close"
                onCancel={() => setIsModalVisible(false)}
                footer={null}
            >
                <FlexiDataTable
                    bordered
                    title={false}
                    loading={false}
                    columns={detailColumns}
                    options={{
                        enableFilter: false,
                        showHideColumns: false,
                    }}
                    dataSource={modalProps.datas}
                />
            </Modal>
            <ReportBatchModal
                isModalVisible={isReportModalVisible}
                modalTitle={"Download Report"}
                callback={(type: number) => {
                    switch (type) {
                        case ReportBatchModalCallbackKey.Close:
                            setIsReportModalVisible(false);
                            break;
                        default:
                            break;
                    }
                }}
                filterCallback={(record: any) => record.batchName === "SwapChargingToolTrades"}
            />
        </>
    );
};

export default SwapChargingToolProcess;
