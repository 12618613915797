import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CardBox from "../../../../components/Common/CardBox";
import FlexiDataTable from "../../../../components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "../../../../constants";
import { FlexiDataTableCallbackProps, MetricTypes, OptionType, Metric } from "../../../../constants/type";
import AuthHelper, { AuthKeys } from "../../../../helpers/authHelper";
import { apiRequest } from "../../../../services/apiConfig";
import { APIs } from "../../../../services/apis";
import { ToObjectWithKey } from "../../../../utils/array";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../../../utils/Common";

const MetricGlossary = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [metricTypes, setMetricTypes] = useState<MetricTypes[]>([]);
    const [metrics, setMetrics] = useState<Metric[]>([]);
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.APP_CONFIGURATION_EDIT);

    let navigate = useNavigate();

    const getTableColumnConfig = () => [
        DTColProps.Small({
            title: "Metric Type",
            dataIndex: "metricTypeId",
            key: "metricTypeId",
            render: (text: number) => {
                let tmp = ToObjectWithKey(metricTypes, "typeId");
                return tmp ? tmp[`${text}`].name : text;
            },
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: metricTypes.map((x) => ({ text: x.name, value: x.typeId } as OptionType)),
                },
            },
        }),
        DTColProps.Large({
            title: "Metric Name",
            dataIndex: "displayName",
            key: "displayName",
            options: {
                filter: {
                    type: ComponentType.text,
                },
            },
        }),
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
        },
    ];

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, data: any) => {
        switch (type) {
            case CALLBACK_KEY.DO_EDIT:
                navigate("/siteadmin/appconfig/metricglossary/edit", { state: { data: data, action: "edit" } });
                break;
            default:
                break;
        }
    };

    const getMetricGlossaryListing = () => {
        setIsLoading(true);
        apiRequest(APIs.GET_METRIC_GLOSSARY_LIST, {})
            .then((res: any) => {
                setMetrics(res);
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("metric glossary", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsLoading(false));
    };

    const initRequest = () => {
        apiRequest(APIs.GET_COMMON_METRIC_LIST, {})
            .then((res: any) => {
                if (res && res.metricType) {
                    setMetricTypes(res.metricType);
                    getMetricGlossaryListing();
                }
            })
            .catch((error) => ErrorCatchValidator(error, (err: any) => {}));
    };

    useEffect(() => {
        initRequest();
        return () => {};
    }, []);

    return (
        <>
            <CardBox title={"Metric Glossary"}>
                <FlexiDataTable
                    rowKeyProperty="metricId"
                    title=""
                    columns={getTableColumnConfig()}
                    options={{
                        edit: enableUpdate,
                    }}
                    dataSource={metrics || []}
                    callback={componentCallback}
                    loading={isLoading}
                />
            </CardBox>
        </>
    );
};

export default MetricGlossary;
