import CardBox from "@/components/Common/CardBox";
import { Segmented } from "antd";
import { useState } from "react";
import BalanceUpdateUploadPage from "./components/uploadPage";
import BalanceUpdateHistoricalPage from "./components/history";

export interface BalanceUpdatePageProps {}

const BalanceUpdatePage = (props: BalanceUpdatePageProps) => {
    const [currentTab, setCurrentTab] = useState<string>("Upload");

    return (
        <div className="risk-tool-balance-update-container">
            <CardBox title={"Balance Update"}>
                <div className="main-container">
                    <Segmented value={currentTab} options={["Upload", "History"]} onChange={(activeKey: any) => setCurrentTab(activeKey)} />
                    {currentTab === "Upload" && <BalanceUpdateUploadPage />}
                    {currentTab === "History" && <BalanceUpdateHistoricalPage />}
                </div>
            </CardBox>
        </div>
    );
};

export default BalanceUpdatePage;
