import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, Input, InputRef, Modal, Row, Select, Space } from "antd";
import { useEffect, useRef, useState } from "react";
import { FormComponent } from "../../../../components/FormComponent";
import { ComponentType, SUCCESS_FAILED } from "../../../../constants";
import { REQUIRED_FIELD } from "../../../../constants/errorMessage";
import { APIs } from "../../../../services/apis";
import { plainAxiosInstance } from "../../../../services/axiosSetup";
import { ErrorCatchValidator, ErrorMessageHandler } from "../../../../utils/Common";

type ReadOnlyForm = {
    server?: string;
    login?: string;
    reason?: string;
};
type CreateSettingsModalProps = {
    open: boolean;
    onModalCancel: () => void;
    onModalOk: () => void;
    serversOptions: {
        text: string;
        value: number;
    }[];
};
type GroupReason = {
    label: string;
    value: string;
};

const SetReadOnlyModal = ({ open, onModalCancel, onModalOk, serversOptions }: CreateSettingsModalProps) => {
    const [readOnlyForm] = Form.useForm<ReadOnlyForm>();
    const [groupChangeReason, setGroupChangeReason] = useState<GroupReason[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        getReasons();
    }, []);

    function onCancelForm() {
        readOnlyForm.resetFields();
        onModalCancel();
    }

    async function onSubmitForm() {
        return new Promise<void>((resolve, reject) => {
            readOnlyForm
                .validateFields()
                .then(values => {
                    plainAxiosInstance
                        .put(APIs.RC_LINKED_ACCOUNT_RECORD.POST_UPDATE_GROUP_SETTINGS, values)
                        .then((res: any) => {
                            ErrorMessageHandler("ReadOnly", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                            resolve();
                            onModalOk();
                            readOnlyForm.resetFields();
                        })
                        .catch((error: any) => {
                            reject(error);
                        });
                })
                .catch(error => {
                    const err = { message: "Invalid form data" };
                    reject(err);
                });
        }).catch((error) => {
          ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("ReadOnly", SUCCESS_FAILED.FAILED_CREATE_DATA, err));
        });
    }

    function onOkConfirm() {
        Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            title: `Are you sure you want to set ReadOnly?`,
            width: "30%",
            onOk() {
                return onSubmitForm();
            },
            onCancel() {},
        });
    }

    function getReasons() {
        setIsLoading(true);
        plainAxiosInstance
            .get(`${APIs.RC_READ_ONLY.GET_READ_ONLY_GROUP_CHANGE_REASON}`)
            .then(res => {
                if (res.data) {
                    setGroupChangeReason(res.data.map((x: any) => ({ label: x.reason, value: x.id })));
                }
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("ReadOnly reasons", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
            })
            .finally(() => setIsLoading(false));
    }

    return (
        <Modal width="50vw" destroyOnClose maskClosable={false} title={"Add ReadOnly"} open={open} onCancel={onCancelForm} onOk={onOkConfirm}>
            <Form form={readOnlyForm} layout="horizontal" labelCol={{ span: 7 }} wrapperCol={{ span: 14 }}>
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Server"
                            name="server"
                            extra={{
                                type: ComponentType.dropdown,
                                value: serversOptions,
                                inputProps: {
                                    allowClear: false,
                                    showSearch: false,
                                },
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Login"
                            name="login"
                            extra={{
                                type: ComponentType.text,
                                value: "",
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <CustomSelectGroupReasons groupReasons={groupChangeReason} isLoading={isLoading} onReasonAdd={getReasons} />
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

type CustomSelectGroupReasonsProps = {
    groupReasons: GroupReason[];
    isLoading: boolean;
    onReasonAdd: () => void;
};
const CustomSelectGroupReasons = ({ groupReasons, isLoading, onReasonAdd }: CustomSelectGroupReasonsProps) => {
    const [inputReason, setInputReason] = useState<string>("");
    const [isAddingReason, setIsAddingReason] = useState<boolean>(false);
    const inputRef = useRef<InputRef | null>(null);

    const onInputReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputReason(event.target.value);
    };

    const addReason = () => {
        setIsAddingReason(true);
        plainAxiosInstance
            .get(`${APIs.RC_READ_ONLY.GET_READ_ONLY_GROUP_ADD_REASON}?reason=${inputReason}`)
            .then(res => {
                onReasonAdd();
                setInputReason("");
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("new ReadOnly reason", SUCCESS_FAILED.FAILED_CREATE_DATA, err));
            })
            .finally(() => setIsAddingReason(false));
    };

    return (
        <>
            <Form.Item label={"ReadOnly Reason"} className="form-select" name={"reason"} rules={[{ required: true, message: REQUIRED_FIELD }]}>
                <Select
                    loading={isLoading}
                    placeholder="Please select a reason"
                    dropdownRender={menu => (
                        <>
                            {menu}
                            <Divider style={{ margin: "8px 0" }} />
                            <Space style={{ padding: "0 8px 4px" }}>
                                <Input
                                    placeholder="Add new reason"
                                    ref={inputRef}
                                    value={inputReason}
                                    onChange={onInputReasonChange}
                                    disabled={isAddingReason}
                                />
                                <Button type="text" icon={<PlusOutlined />} onClick={addReason} disabled={isAddingReason}>
                                    Add Reason
                                </Button>
                            </Space>
                        </>
                    )}
                    options={groupReasons}
                />
            </Form.Item>
        </>
    );
};

export default SetReadOnlyModal;
