import { ReportBatchResponse, useGetReportBatchListQuery } from "../../../store/apis/report/reportbatch";
import { Button, message, Modal, Spin, Tag } from "antd";
import FlexiDataTable from "../../../components/FlexiDataTable";
import { DataTableColumnRender, DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";
import { defaultIfEmptyOrNull, isEmptyOrNull } from "../../../utils/string";
import { useEffect, useMemo } from "react";
import { DeleteOutlined, DownloadOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { CALLBACK_KEY, SUCCESS_FAILED } from "../../../constants";
import { FlexiDataTableCallbackProps } from "../../../constants/type";
import { plainAxiosInstance } from "../../../services/axiosSetup";
import { APIs } from "../../../services/apis";

export enum ReportBatchModalCallbackKey {
    Close = 0,
}

export interface ReportBatchModalProps {
    isModalVisible: boolean;
    modalTitle: string;
    callback: (type: number, data: any) => void;
    filterCallback?: (data: any) => boolean;
}

const ReportBatchModal = (props: ReportBatchModalProps) => {
    const { data, isLoading, refetch } = useGetReportBatchListQuery({}, { pollingInterval: 10000 });

    const markupData = useMemo(() => {
        return props.filterCallback
            ? defaultIfEmptyOrNull(data, []).filter((x: any) => props.filterCallback && props.filterCallback(x))
            : defaultIfEmptyOrNull(data, []);
    }, [data, props.filterCallback]);

    const columns = [
        DTColProps.Middle({
            title: "Batch Name",
            dataIndex: "batchName",
            key: "batchName",
        }),
        DTColProps.Small({
            title: "Start Time (Local)",
            dataIndex: "startTime",
            key: "startTime",
            render: (text: string | null) => {
                if (text === null) return "-";
                else {
                    let formattedDate = DataTableColumnRender.DateTime(text);
                    let idx = formattedDate.indexOf("+");
                    return formattedDate.slice(0, idx);
                };
            },
        }),
        DTColProps.Small({
            title: "End Time (Local)",
            dataIndex: "endTime",
            key: "endTime",
            render: (text: string | null) => {
                if (text === null) return "-";
                else {
                    let formattedDate = DataTableColumnRender.DateTime(text);
                    let idx = formattedDate.indexOf("+");
                    return formattedDate.slice(0, idx);
                };
            },
        }),
        {
            title: "Params",
            dataIndex: "params",
            key: "params",
            render: (text: string) =>
                isEmptyOrNull(text)
                    ? "-"
                    : text.split("&").map((item: string, index: number) => (
                        <Tag key={`mdl-rpb-${index}`} style={{ marginRight: "2px" }}>
                            {item}
                        </Tag>
                    )),
        },
        DTColProps.XSmall(
            {
                title: "Status",
                dataIndex: "status",
                key: "status",
                render: (text: string) => <Tag color={
                    text.toLowerCase() === "waiting"
                        ? "orange"
                        : text.toLowerCase() === "processing"
                            ? "blue"
                            : "green"
                }>{text}</Tag>,
            },
            ["text-center"]
        ),
    ];

    const removeFile = (id: number) => {
        plainAxiosInstance
            .delete(`${APIs.RC_ROOT.DELETE_DOWNLOAD}?id=${id}`)
            .then((res: any) => {
                if (res.data === 0) {
                    message.success("File deleted successfully.");
                    setTimeout(refetch, 400);
                } else {
                    message.error("File deleted failed!");
                }
            })
            .catch((err: any) => message.error(`File deleted failed. Error: ${err.message}`));
    };

    const downloadFile = (record: ReportBatchResponse) => {
        plainAxiosInstance
            .get(`${APIs.RC_ROOT.GET_VERIFY_DOWNLOAD}?id=${record.id}`)
            .then((res: any) => {
                if (typeof res.data === "boolean" && res.data) {
                    plainAxiosInstance
                        .get(`${APIs.RC_ROOT.GET_DOWNLOAD_FILE}?id=${record.id}`, {
                            headers: {
                                Accept: "application/octet-stream, application/zip, */*",
                            },
                            responseType: "blob",
                        })
                        .then(response => {
                            const contentType = response.headers["content-type"];
                            if (
                                contentType === "application/octet-stream" ||
                                contentType === "application/zip" ||
                                contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            ) {
                                // Handle the file download response
                                const url = window.URL.createObjectURL(new Blob([response.data]));
                                const link = document.createElement("a");
                                link.href = url;
                                if (contentType === "application/zip") {
                                    link.setAttribute("download", `${record.batchName}.zip`);
                                } else {
                                    link.setAttribute("download", `${record.batchName}.csv`);
                                }
                                document.body.appendChild(link);
                                link.click();
                                // Clean up
                                window.URL.revokeObjectURL(url);
                                message.success("File downloaded successfully.");
                            } else {
                                message.error("Received non-file response. Error: " + response);
                            }
                        });
                } else {
                    ErrorMessageHandler("the file", SUCCESS_FAILED.FAILED_DOWNLOAD_DATA, { message: "" });
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("the file", SUCCESS_FAILED.FAILED_DOWNLOAD_DATA, err))
            );
    };

    const componentCallback: FlexiDataTableCallbackProps = (type: number, formData: any) => {
        switch (type) {
            case CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK:
                if (formData.key === "delete") {
                    Modal.confirm({
                        icon: <ExclamationCircleOutlined />,
                        title: "Are you sure you want to delete?",
                        width: "30%",
                        onOk() {
                            removeFile(formData.data.id);
                        },
                        onCancel() { },
                    });
                } else if (formData.key === "download") {
                    downloadFile(formData.data);
                }
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        if (props.isModalVisible) refetch && refetch();
    }, [props.isModalVisible, refetch]);

    return (
        <Modal
            style={{ top: 10 }}
            width={"60vw"}
            title={props.modalTitle}
            open={props.isModalVisible}
            okButtonProps={{ style: { display: "none" } }}
            cancelText="Close"
            onCancel={() => props.callback(ReportBatchModalCallbackKey.Close, null)}
            footer={null}
        >
            <FlexiDataTable
                rowKeyProperty="id"
                title={false}
                loading={isLoading}
                columns={columns}
                options={{
                    enableFilter: false,
                    showHideColumns: false,
                    customExtraActionButton: (record: ReportBatchResponse, callback: (key: any, record: any) => void) => {
                        return record.status.toLowerCase() === "completed" ? (
                            <Button
                                type="text"
                                icon={<DownloadOutlined />}
                                onClick={e => {
                                    e.preventDefault();
                                    callback(CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK, { key: "download", data: record });
                                }}
                            />
                        ) : (
                            <Button
                                type="text"
                                icon={<DeleteOutlined />}
                                onClick={e => {
                                    e.preventDefault();
                                    callback(CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK, { key: "delete", data: record });
                                }}
                            />
                        );
                    },
                }}
                dataSource={markupData}
                callback={componentCallback}
            />
        </Modal>
    );
};

export default ReportBatchModal;
