import { createApi } from "@reduxjs/toolkit/query/react";
import { APIs, defaultBaseQueryProps, ResponseProps } from "../../services/apiConfig";
import { TagSummary } from "../../constants/type";
import { axiosBaseQuery } from "../axiosBaseQuery";

export interface tagSummaryRequest {}

export interface tagSummaryResponse extends ResponseProps<TagSummary[]> {}

export const tagSummaryApiSlice = createApi({
    reducerPath: "api_ts_1",
    baseQuery: axiosBaseQuery(),
    keepUnusedDataFor: 5, //global caching duration (seconds)
    endpoints: (builder) => ({
        getTagSummary: builder.query<tagSummaryResponse, tagSummaryRequest | undefined>({
            keepUnusedDataFor: 0, //disable for caching for particular api request
            query: (arg) => ({ ...defaultBaseQueryProps, url: APIs.GET_TAGGING_SUMMARY, method: "POST", params: arg }),
            transformResponse: (returnValue: tagSummaryResponse, meta) => {
                return returnValue;
            },
        }),
    }),
});

export const { useGetTagSummaryQuery } = tagSummaryApiSlice;
