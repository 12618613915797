import React, { useEffect } from "react";
import useRCStatusMonitor from "../../../hooks/useRCStatusMonitor";
import { massageData } from "./statusUtils";
import BasicFilter from "../../../components/BasicFilter";
import ServerStatus, { ServerStatusProps } from "./ServerStatus";
import ServerStatusFilterWeekend from "./ServerStatusFilterWeekend";
import TabContent from "../components/TabContent";
import ServerStatusVendor from "./ServerStatusVendor";
import useRCStatusMonitorStore from "../../../store/useRCStatusMonitorStore";
import Loader from "../components/Loader";
import MessageCard from "../components/MessageCard";

const TabWeekend = () => {
    const { rcStatusMonitor, isLoading } = useRCStatusMonitor({ department: "Weekend" });
    const dataWeekend = rcStatusMonitor ? massageData(rcStatusMonitor, "Weekend") : [];

    const vendor = useRCStatusMonitorStore(state => state.vendor.weekend);
    const filterDataWeekend = vendor ? dataWeekend?.filter((item: any) => item.vendor === vendor) : [];

    return (
        <TabContent>
            <BasicFilter show={false}>
                <ServerStatusFilterWeekend />
            </BasicFilter>

            {isLoading && rcStatusMonitor.length === 0 ? (
                <Loader />
            ) : (
                <>
                    <ServerStatusVendor data={dataWeekend as ServerStatusProps[]} vendorType="weekend" />
                    {!vendor && (
                        <div style={{ padding: "0 1rem" }}>
                            <MessageCard type="info">Please select a group from above</MessageCard>
                        </div>
                    )}
                    {filterDataWeekend?.map((item: any, index: number) => (
                        <ServerStatus key={index} data={item as ServerStatusProps} />
                    ))}
                </>
            )}
        </TabContent>
    );
};

export default TabWeekend;
