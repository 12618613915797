import React, { useCallback, useEffect, useMemo, useState } from "react";
import FlexiDataTable from "../../../components/FlexiDataTable";
import { CustomPaginationProps, FlexiDataTableCallbackProps, FlexiDataTableOptionsProps } from "../../../constants/type";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "../../../constants";
import moment from "moment";
import { plainAxiosInstance } from "../../../services/axiosSetup";
import { APIs } from "../../../services/apis";
import { CommissionRecordDataRow } from "./type";
import { Button, message } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { REQUIRED_FIELD } from "@/constants/errorMessage";

const DeletedRecords = () => {
    const [data, setData] = useState<CommissionRecordDataRow[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(true);
    const [filterParams, setFilterParams] = useState<any>({
        lastTime: moment().format("YYYY-MM-DD"),
    });
    const [pagination, setPagination] = useState<CustomPaginationProps>({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [isExporting, setIsExporting] = useState<boolean>(false);

    const columns = useMemo(() => [
        DTColProps.XSmall({
            title: "Server",
            dataIndex: "serverName",
            key: "serverName",
            fixed: "left",
        }),
        DTColProps.XSmall({
            title: "Ticket",
            dataIndex: "ticket",
            key: "ticket",
            fixed: "left",
        }),
        DTColProps.XSmall({
            title: "Login",
            dataIndex: "login",
            key: "login",
            fixed: "left",
        }),
        DTColProps.Small({
            title: "Group",
            dataIndex: "group",
            key: "group",
        }),
        DTColProps.Middle({
            title: "Open Time",
            dataIndex: "openTimeStr",
            key: "openTimeStr",
            width: "10vw",
        }),
        DTColProps.Middle({
            title: "Close Time",
            dataIndex: "closeTimeStr",
            key: "closeTimeStr",
            width: "10vw",
        }),
        DTColProps.XSmall({
            title: "Side",
            dataIndex: "typeStr",
            key: "typeStr",
        }),
        DTColProps.Small({
            title: "Symbol",
            dataIndex: "symbol",
            key: "symbol",
        }),
        DTColProps.XSmall({
            title: "Lots",
            dataIndex: "volume",
            key: "volume",
        }),
        DTColProps.Small({
            title: "Comment",
            dataIndex: "comment",
            key: "comment",
        }),
        DTColProps.Small({
            title: "Commission",
            dataIndex: "commission",
            key: "commission",
        }),
        DTColProps.Middle({
            title: "Delete Time",
            dataIndex: "lastTimeStr",
            key: "lastTimeStr",
            options: {
                filter: {
                    type: ComponentType.date,
                    value: "",
                    dateFormat: "YYYY-MM-DD",
                    text: "Date",
                    rules: [{ required: true, message: REQUIRED_FIELD }],
                },
            },
            width: "10vw",
        }),
        DTColProps.Small({
            title: "Operator",
            dataIndex: "operatorName",
            key: "operatorName",
        }),
    ], []);

    const options: FlexiDataTableOptionsProps = useMemo(() => ({
        export: {
            text: "",
            Element: (
                <Button
                    key={"export"}
                    icon={<DownloadOutlined />}
                    style={{ marginLeft: 10 }}
                    loading={isExporting}
                    onClick={() => downloadDeletedRecords(filterParams)}
                >
                    Export as CSV
                </Button>
            )
        },
        serverFiltering: true,
    }), [isExporting, filterParams]);

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.HANDLE_PAGINATION_SORTING:
                setPagination((prev) => ({ ...prev, current: FormData.pagination.current, pageSize: FormData.pagination.pageSize }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                let fParams: any = {};
                Object.keys(FormData)
                    .filter((x) => FormData[x] !== undefined && FormData[x].toString().length > 0)
                    .map((x) => {
                        if (x === "lastTimeStr") {
                            fParams["lastTime"] = moment(FormData[x]).format("YYYY-MM-DD");
                        } else {
                            fParams[x] = FormData[x];
                        }
                        return x;
                    });
                setFilterParams(fParams);
                setPagination((prev) => ({ ...prev, current: 1 }));
                setRunRefetchDataList(true);
                break;
            default:
                break;
        };
    };

    const getDeletedRecords = useCallback(() => {
        let current = pagination.current === undefined ? 1 : pagination.current;
        let pageSize = pagination.pageSize === undefined ? 10 : pagination.pageSize;

        plainAxiosInstance
            .post(`${APIs.RC_COMMISSION_RECORDS.GET_REMOVED_DETAILED_COMMISSION_DATA}`, {
                limit: pagination.pageSize,
                offset: (current - 1) * pageSize,
                ...(Object.keys(filterParams).length > 0 && { ...filterParams }),
            })
            .then((res: any) => {
                if (res.status === 200) {
                    setData(res.data.rows);
                    setPagination((prev) => ({ ...prev, total: res.data.total }));
                } else {
                    setData([]);
                    setPagination((prev) => ({ ...prev, total: 0 }));
                };
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => {
                ErrorMessageHandler("deleted records", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                setData([]);
                setPagination((prev) => ({ ...prev, total: 0 }));
            }))
            .finally(() => setIsLoading(false));
    }, [pagination, filterParams]);

    const downloadDeletedRecords = (fParams: any) => {
        setIsExporting(true);
        plainAxiosInstance
            .get(`${APIs.RC_COMMISSION_RECORDS.GET_DOWNLOAD_DELETED_REPORT_CSV}?lastTime=${fParams.lastTime}`, {
                headers: {
                    Accept: "application/octet-stream, */*",
                },
                responseType: "blob",
            })
            .then(response => {
                const fileName = `CommissionRemovedRecord_${fParams.lastTime}.csv`;
                const contentType = response.headers["content-type"];
                if (
                    contentType === "application/octet-stream" ||
                    contentType === "text/csv" ||
                    contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ) {
                    // Handle the file download response
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", (`${fileName}`)); // or any other extension
                    link.setAttribute("type", "hidden");
                    document.body.appendChild(link);
                    link.click();
                    if (link.parentNode) {
                        link.parentNode.removeChild(link); // Clean up and remove the link
                    } else document.body.removeChild(link);
                    // Clean up
                    window.URL.revokeObjectURL(url);
                } else {
                    message.error(`Received non-file response. Error: ${response}`, 3);
                }
            })
            .catch(err => {
                message.error(`Download error: ${err}`, 3);
            })
            .finally(() => setIsExporting(false));
    };

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            getDeletedRecords();
            setRunRefetchDataList(false);
        };
    }, [runRefetchDataList]);

    return (
        <div className="deleted-records-tab">
            <FlexiDataTable
                bordered
                rowKeyProperty="id"
                title=""
                columns={columns}
                options={options}
                dataSource={data || []}
                callback={componentCallback}
                loading={isLoading}
                filterInitialValue={{ lastTimeStr: moment() }}
                serverSide={true}
                pagination={pagination}
            />
        </div>
    );
};

export default DeletedRecords;