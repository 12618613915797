import { useEffect, useState } from "react";
import CardBox from "@/components/Common/CardBox";
import { KeyValuePair, NavigationItem } from "@/constants/type";
import { Segmented } from "antd";
import DataTab from "./DataTab";
import ReportTab from "./ReportTab";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { APIs } from "@/services/apis";
import { ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { SUCCESS_FAILED } from "@/constants";

const PEOrderCompare = () => {
    const [currentActiveSegment, setCurrentActiveSegment] = useState<string>("data"); // For segment
    const [servers, setServers] = useState<any[]>([]);
    const [isFilterLoaded, setIsFilterLoaded] = useState<boolean>(false);

    const SegmentList: NavigationItem[] = [
        { key: "data", title: "Data" },
        { key: "report", title: "Report" },
    ];

    useEffect(() => {
        plainAxiosInstance
            .get(`${APIs.RC_PE_ORDER_COMPARE.GET_SERVERS}`)
            .then((res: any) => {
                if (res.status === 200) {
                    setServers(res.data.map((x: any) => ({ value: x.value, text: x.name })) as KeyValuePair[]);
                } else setServers([]);
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => {
                ErrorMessageHandler("server list", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                setServers([]);
            }))
            .finally(() => setIsFilterLoaded(true));
    }, []);

    return (
        <div className="pe-order-compare-container">
            <CardBox title="PE Order Compare">
                <div className="nav-bar">
                    <Segmented
                        value={currentActiveSegment}
                        options={SegmentList.map((x: NavigationItem) => ({
                            label: x.title,
                            value: x.key,
                        }))}
                        onChange={(activeKey: any) => setCurrentActiveSegment(activeKey)}
                    />
                </div>
                {
                    currentActiveSegment === "data"
                        ? <DataTab />
                        : currentActiveSegment === "report"
                            ? <ReportTab servers={servers} isFilterReady={isFilterLoaded} />
                            : null
                }
            </CardBox>
        </div>
    );
};

export default PEOrderCompare;