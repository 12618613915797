import { createApi } from "@reduxjs/toolkit/query/react";
import { APIs, defaultBaseQueryProps, ResponseProps } from "../../services/apiConfig";
import { BrandRiskScore, RiskScoreProfile } from "../../constants/type";
import { axiosBaseQuery } from "../axiosBaseQuery";

export interface riskScoreRequest {
    profileId: number;
}
export interface brandRiskScoreRequest {
    brandId?: number;
}

export interface riskScoreResponse extends ResponseProps<RiskScoreProfile[]> {}
export interface brandRiskScoreResponse extends ResponseProps<BrandRiskScore[]> {}

export const riskScoreApiSlice = createApi({
    reducerPath: "api_pf_6",
    baseQuery: axiosBaseQuery(),
    keepUnusedDataFor: 5, //global caching duration (seconds)
    endpoints: (builder) => ({
        getRiskScoreConfigurations: builder.query<riskScoreResponse, riskScoreRequest | undefined>({
            keepUnusedDataFor: 0, //disable for caching for particular api request
            query: (arg) => {
                return { ...defaultBaseQueryProps, url: APIs.GET_RISK_SCORE_LIST, method: "POST", data: arg };
            },
            transformResponse: (returnValue: riskScoreResponse, meta) => {
                return returnValue;
            },
        }),
        getBrandRiskScoreConfigurations: builder.query<brandRiskScoreResponse, brandRiskScoreRequest | undefined>({
            keepUnusedDataFor: 0, //disable for caching for particular api request
            query: (arg) => {
                return { ...defaultBaseQueryProps, url: APIs.GET_BRAND_RISK_SCORE_LIST, method: "POST", data: arg };
            },
            transformResponse: (returnValue: brandRiskScoreResponse, meta) => {
                return returnValue;
            },
        }),
    }),
});

export const { useGetRiskScoreConfigurationsQuery, useGetBrandRiskScoreConfigurationsQuery } = riskScoreApiSlice;
