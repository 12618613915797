import { Button, Col, Form, Row } from "antd";
import React from "react";
import { FormComponent } from "../../../../components/FormComponent";
import { ComponentType, SUCCESS_FAILED } from "../../../../constants";
import { REQUIRED_FIELD } from "../../../../constants/errorMessage";
import { DownloadOutlined } from "@ant-design/icons";
import { KeyValuePair } from "../../../../constants/type";
import { plainAxiosInstance } from "../../../../services/axiosSetup";
import { APIs } from "../../../../services/apis";
import { ErrorCatchValidator, ErrorMessageHandler } from "../../../../utils/Common";
import moment from "moment";

interface DownloadFormProps {
    servers: KeyValuePair[];
};

interface ConfigDateData {
    server: string;
    time: string;
};

const DownloadForm = (props: DownloadFormProps) => {
    const [downloadForm] = Form.useForm();
    const [isLoading, setIsLoading] = React.useState(false);
    const [isBtnLoading, setIsBtnLoading] = React.useState(false);
    const servers = React.useMemo(() => props.servers, [props.servers]);
    const [configDateOpt, setConfigDateOpt] = React.useState<any[]>([]);

    const onFormSubmit = (values: any) => {
        setIsBtnLoading(true);
        plainAxiosInstance
            .get(`${APIs.RC_CONFIG_DIFF.GET_CD_DOWNLOAD}?serverId=${values.mainServer}&baseDate=${values.baseDate}&compareDate=${values.compareDate}`,
                { responseType: "blob" }
            )
            .then((res: any) => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `configDiff_${values.mainServer}_${values.baseDate}_${values.compareDate}_${moment().format('x')}.xlsx`);
                document.body.appendChild(link);
                link.click();
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("config diff excel", SUCCESS_FAILED.FAILED_DOWNLOAD_DATA, err))
            )
            .finally(() => setIsBtnLoading(false));
    };

    const onServerChange = React.useCallback((serverId: string | number) => {
        downloadForm.setFieldsValue({ baseDate: undefined, compareDate: undefined });
        setIsLoading(true);
        plainAxiosInstance
            .get(`${APIs.RC_CONFIG_DIFF.GET_CD_SERVER_HISTORY_BY_SERVER}/${serverId}`)
            .then((res: any) => {
                if (res.status === 200 && res.data.length > 0) {
                    const data = res.data as ConfigDateData[];
                    setConfigDateOpt(data.map((x: any) => ({ text: x.time, value: x.time })));
                } else setConfigDateOpt([]);
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("config date list", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsLoading(false));
    }, [downloadForm]);

    return (
        <div className="download-cd-form-div">
            <Form
                form={downloadForm}
                layout="horizontal"
                initialValues={{}}
                onFinish={onFormSubmit}
            >
                <Row gutter={[12, 12]}>
                    <Col span={6}>
                        <FormComponent
                            label="Main Server"
                            name="mainServer"
                            extra={{
                                type: ComponentType.dropdown,
                                value: servers,
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                                inputProps: { onChange: onServerChange, allowClear: false },
                            }}
                        />
                    </Col>
                    <Col span={6}>
                        <FormComponent
                            label="Base Date"
                            name="baseDate"
                            extra={{
                                type: ComponentType.dropdown,
                                value: configDateOpt,
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                                inputProps: { allowClear: false, disabled: isLoading },
                            }}
                        />
                    </Col>
                    <Col span={6}>
                        <FormComponent
                            label="Compare Date"
                            name="compareDate"
                            extra={{
                                type: ComponentType.dropdown,
                                value: configDateOpt,
                                rules: [
                                    { required: true, message: REQUIRED_FIELD },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue("baseDate") !== value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error("Please choose a different date!"));
                                        },
                                    }),
                                ],
                                inputProps: { allowClear: false, disabled: isLoading },
                            }}
                        />
                    </Col>
                    <Col>
                        <Form.Item>
                            <Button
                                icon={<DownloadOutlined />}
                                type="primary"
                                loading={isBtnLoading}
                                htmlType="submit"
                            >
                                Download
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default DownloadForm;