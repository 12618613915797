import { HomeOutlined } from "@ant-design/icons";
import { Button, Col, Form, Popconfirm, Row } from "antd";
import { useEffect, useState } from "react";
import { NavigateFunction, useLocation, useNavigate, useParams } from "react-router-dom";
import { FormComponent } from "../../../components/FormComponent";
import LoadingComponent from "../../../components/Loading";
import SitePageHeader from "../../../components/PageHeader";
import { ComponentType, SUCCESS_FAILED } from "../../../constants";
import { REQUIRED_FIELD } from "../../../constants/errorMessage";
import { Brands, Servers } from "../../../constants/type";
import { apiRequest, APIs } from "../../../services/apiConfig";
import { ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";

export type GroupPurposeState = {
    action: string;
    data: any;
};

function GroupPurposeCreateEditPage() {
    let navigate = useNavigate();
    let location = useLocation();
    let com_state: GroupPurposeState = location.state as GroupPurposeState;
    const [currentState] = useState<GroupPurposeState>(com_state || { action: "add", data: null });
    const [isAddAction, setIsAddAction] = useState<boolean>(currentState.action === "add");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [groupPurposeForm] = Form.useForm();
    const [serverList, setServerList] = useState<any[]>([]);
    const [brandList, setBrandList] = useState<any[]>([]);
    let { id } = useParams();

    useEffect(() => {
        getConfigInfo();
        getGroupPurposeList();
        return () => {};
    }, [isAddAction, groupPurposeForm]);

    const onSubmit = (values: any) => {
        const newValues = {
            groupPurposeId: values.id,
            groupPurposeName: values.groupPurpose,
            groupPurposePattern: values.groupPurposePattern,
            serverId: values.serverId,
            brandId: values.brandId,
            includeReports: values.includeInReports,
            isLive: values.isLive,
        };

        setIsLoading(true);

        if (isAddAction) {
            apiRequest(APIs.CREATE_GROUP_PURPOSE, newValues)
                .then((data) => {
                    ErrorMessageHandler("New group purpose", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                    navigate("/siteadmin/serverbrand/group-purpose");
                })
                .catch((error) => {
                    ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("new group purpose", SUCCESS_FAILED.FAILED_CREATE_DATA, err));
                    setIsLoading(false);
                });
        } else {
            apiRequest(APIs.UPDATE_GROUP_PURPOSE, newValues)
                .then((data) => {
                    ErrorMessageHandler("Existing group purpose", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                    navigate("/siteadmin/serverbrand/group-purpose");
                })
                .catch((error) => {
                    ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("existing group purpose", SUCCESS_FAILED.FAILED_UPDATE_DATA, err));
                    setIsLoading(false);
                });
        }
    };

    const getConfigInfo = () => {
        setIsLoading(true);
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["server", "brand"] })
            .then((data: any) => {
                setServerList(data.servers);
                setBrandList(data.brands);
                setIsLoading(false);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("config info", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                navigate("/siteadmin/serverbrand/group-purpose");
            });
    };

    const getGroupPurposeList = () => {
        setIsLoading(true);
        apiRequest(APIs.GET_GROUP_PURPOSES_LIST, { groupPurposeId: currentState.data ? currentState.data : id })
            .then((data: any) => {
                if (currentState.data || id) {
                    setIsAddAction(false);
                    groupPurposeForm.setFieldsValue(data);
                } else {
                    setIsAddAction(true);
                    groupPurposeForm.resetFields();
                }
                setIsLoading(false);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("group purpose list", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                navigate("/siteadmin/serverbrand/group-purpose");
            });
    };

    const deleteGroupPurpose = (groupPurposeId: string, navigator: NavigateFunction) => {
        apiRequest(APIs.DELETE_GROUP_PURPOSE, { groupPurposeId })
            .then((data) => {
                ErrorMessageHandler("The group purpose", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                navigator("/siteadmin/serverbrand/group-purpose");
            })
            .catch((error) => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("group purpose", SUCCESS_FAILED.FAILED_DELETE_DATA, err)));
    };

    return (
        <SitePageHeader
            title={isAddAction ? "Create New Group Purpose" : "Edit Group Purpose"}
            routes={[
                {
                    path: "/siteadmin/serverbrand/group-purpose",
                    breadcrumbName: "Group Purpose Listing",
                    icon: <HomeOutlined />,
                },
                {
                    path: "",
                    breadcrumbName: isAddAction ? "Create New Group Purpose" : "Edit Group Purpose",
                },
            ]}
            onBack={() => navigate("/siteadmin/serverbrand/group-purpose")}
            extraProps={{
                extra: isAddAction
                    ? []
                    : [
                          <Popconfirm
                              key={"cetmp-del"}
                              title="Confirm to delete?"
                              onConfirm={() => deleteGroupPurpose(currentState.data ? currentState.data : id, navigate)}
                              okText="Yes"
                              cancelText="No"
                          >
                              <Button type="primary" danger>
                                  Delete Group Purpose
                              </Button>
                          </Popconfirm>,
                      ],
            }}
        >
            {isLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Submitting..." />
                </div>
            ) : (
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    form={groupPurposeForm}
                    layout="horizontal"
                    initialValues={currentState.data}
                    onFinish={onSubmit}
                >
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Server"
                                name={"serverId"}
                                extra={{
                                    type: ComponentType.dropdown,
                                    inputProps: {
                                        style: { width: "100%" },
                                    },
                                    value: serverList.map((sv) => {
                                        return {
                                            text: sv.server,
                                            value: sv.id,
                                        };
                                    }),
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Brand"
                                name={"brandId"}
                                extra={{
                                    type: ComponentType.dropdown,
                                    inputProps: {
                                        style: { width: "100%" },
                                    },
                                    value: brandList.map((bd) => {
                                        return {
                                            text: bd.brand,
                                            value: bd.id,
                                        };
                                    }),
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent label={""} name={"id"} extra={{ type: ComponentType.hidden, value: "" }} />
                            <FormComponent
                                label="Group Purpose Name"
                                name="groupPurpose"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Group Purpose Pattern"
                                name="groupPurposePattern"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={5} />
                        <Col span={5}>
                            <FormComponent
                                label={""}
                                name={"includeInReports"}
                                extra={{ type: ComponentType.checkbox, value: "Include in Reports" }}
                            />
                        </Col>
                        <Col span={5}>
                            <FormComponent label={""} name={"isLive"} extra={{ type: ComponentType.checkbox, value: "Is Live" }} />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div className="step-btns-group">
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            )}
        </SitePageHeader>
    );
}

export default GroupPurposeCreateEditPage;
