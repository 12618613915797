 import { createApi } from "@reduxjs/toolkit/query/react";
 import { APIs, defaultBaseQueryProps, ResponseProps } from "../../services/apiConfig";
 import { ServersBrandsMapping } from "../../constants/type";
 import { axiosBaseQuery } from "../axiosBaseQuery";
 
 export interface serverBrandMappingRequest {}
 
 export interface serverBrandMappingResponse extends ResponseProps<ServersBrandsMapping[]> {}
 
 export const serverBrandMappingApiSlice = createApi({
     reducerPath: "api_9",
     baseQuery: axiosBaseQuery(),
     keepUnusedDataFor: 5, //global caching duration (seconds)
     endpoints: (builder) => ({
         getServerBrandMapping: builder.query<serverBrandMappingResponse, serverBrandMappingRequest | undefined>({
             keepUnusedDataFor: 0, //disable for caching for particular api request
             query: (arg) => ({ ...defaultBaseQueryProps, url: APIs.GET_SERVER_BRAND_MAPPING_LIST, method: "POST", params: arg }),
             transformResponse: (returnValue: serverBrandMappingResponse, meta) => {
                 return returnValue;
             },
         }),
     }),
 });
 
 export const { useGetServerBrandMappingQuery } = serverBrandMappingApiSlice;
 