import { Badge, Button, Divider, Space } from "antd";
import React, { useState } from "react";
import DownloadsModal from "../Downloads/DownloadsModal";
import GenerateReportModal from "../PriceAlarm/DownloadPriceAlarmModal";
import useRCDownloads from "../../../hooks/useRCDownloads";
import { colorMap } from "../../SystemMonitor/StatusMonitor/statusUtils";
import MessageCard from "../../SystemMonitor/components/MessageCard";
import PriceAlarmReportVisualize from "./PriceAlarmReportVisualize";

const TabPriceOutageReports = () => {
    const { rcDownloads, isLoading: isLoadingRCDownloads } = useRCDownloads();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
    const handleModalOpen = (open: boolean) => {
        setIsModalOpen(open);
    };
    const handleDownloadReportModalOpen = (open: boolean) => {
        setIsDownloadModalOpen(open);
    };

    if (isLoadingRCDownloads) {
        return <MessageCard type="info">Loading...</MessageCard>;
    }

    return (
        <>
            <Space wrap>
                <Button type="primary" onClick={() => handleModalOpen(true)}>
                    Generate Report
                </Button>

                <Button type="ghost" style={{ display: "flex", gap: 6, alignItems: "center" }} onClick={() => handleDownloadReportModalOpen(true)}>
                    <span>Download Report</span>
                    {rcDownloads?.[1] !== undefined && rcDownloads?.[1]?.filter(each => each?.status?.toLowerCase() === "completed")?.length >= 1 && (
                        <Badge
                            count={rcDownloads?.[1]?.filter(each => each?.status?.toLowerCase() === "completed")?.length}
                            color={colorMap.success}
                            size="small"
                        />
                    )}
                </Button>
            </Space>
            <Divider />
            <PriceAlarmReportVisualize />
            <GenerateReportModal open={isModalOpen} handleModalOpen={handleModalOpen} />
            <DownloadsModal open={isDownloadModalOpen} handleModalOpen={handleDownloadReportModalOpen} filterName={"PriceAlarmSummary"} />
        </>
    );
};

export default TabPriceOutageReports;
