import React from "react";
import TabContent from "../components/TabContent";
import ServerPerformanceSettingsTable from "./ServerPerformanceSettingsTable";
const TabServerPerformanceSettings = () => {
    return (
        <TabContent>
            <ServerPerformanceSettingsTable />
        </TabContent>
    );
};

export default TabServerPerformanceSettings;
