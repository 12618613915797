import { Button, Collapse } from "antd";
import { useEffect, useMemo } from "react";
import HFTReportDataTable from "./HFTReportDataTable";
import { HFTResultFormatted, HFTThreshold } from "./index";
import scream from "../../../assets/audios/rc/scream.mp3";
import useAudioPlayer from "../../../hooks/useAudioPlayback";
const { Panel } = Collapse;

type DataTabProps = {
    hftResults: HFTResultFormatted[];
    hftThreshold: HFTThreshold;
    isLoading: boolean;
    refreshData: () => void;
};
type DataTableColumns = HFTResultFormatted & {
    alarmSort: boolean;
};

const DataTab = ({ hftResults, hftThreshold, isLoading, refreshData }: DataTabProps) => {
    const {playAudio, isPlaying, restartAudio, stopAudio} = useAudioPlayer(scream);

    const [dataLessThan2MinOverThreshold, dataBetween2to10MinOverThreshold, dataBetween10to30MinOverThreshold] = useMemo(() => {
        const lessThan2Min: DataTableColumns[] = [];
        const between2to10Min: DataTableColumns[] = [];
        const between10to30Min: DataTableColumns[] = [];
        const t = hftThreshold;

        hftResults.forEach(item => {
            if (item.count! <= 0) return;
            let todayProfitCheck = false;
            if (item.todayProfitThreshold !== null) {
                todayProfitCheck = item.todayProfit > (item.todayProfitThreshold * (100 + t.threshold1)) / 100;
            }
            if (item.type === 0) {
                lessThan2Min.push({
                    ...item,
                    alarmSort: checkAlarmSort(item, todayProfitCheck, t.newprofit2minLevel1, t.newprofit2minLevel2, t.newprofitpnl2min, t.profit2min),
                });
            }
            if (item.type === 1) {
                between2to10Min.push(({
                    ...item,
                    alarmSort: checkAlarmSort(item, todayProfitCheck, t.newprofit10minLevel1, t.newprofit10minLevel2, t.newprofitpnl10min, t.profit10min),
                }));
            }
            if (item.type === 2) {
                between10to30Min.push(({
                    ...item,
                    alarmSort: checkAlarmSort(item, todayProfitCheck, t.newprofit30minLevel1, t.newprofit30minLevel2, t.newprofitpnl30min, t.profit30min),
                }));
            }
        });

        return [
            lessThan2Min.filter(x=> x.todayProfit > t.profit2min), 
            between2to10Min.filter(x=> x.todayProfit > t.profit10min), 
            between10to30Min.filter(x=> x.todayProfit > t.profit30min)
        ];
    }, [hftResults, hftThreshold]);

    useEffect(()=>{
        const alldata = [...dataLessThan2MinOverThreshold, ...dataBetween2to10MinOverThreshold, ...dataBetween10to30MinOverThreshold];
        const alarmIsFound = alldata.findIndex(x=> x.alarmSort === true);
        if(alarmIsFound !== -1 ){
            autoPlayScream();
        }else{
            stopAudio();
        }
    },[dataLessThan2MinOverThreshold, dataBetween2to10MinOverThreshold, dataBetween10to30MinOverThreshold])

    function autoPlayScream(){
        if(isPlaying){
            restartAudio();
        }
        else{
            playAudio();
        }
    }

    return (
        <div className="hft-report-data">
            <Collapse defaultActiveKey={['1']}>
                <Panel
                    header="Holding time less than 2 minutes on today profit."
                    key="1"
                >
                    <HFTReportDataTable 
                        data={dataLessThan2MinOverThreshold} 
                        rowKeyProperty={"login"} 
                        hftThreshold={hftThreshold}
                        min={2}
                        showAlarmColumn
                        isLoading={isLoading}
                        refreshData={refreshData}
                    />
                </Panel>
            </Collapse>
            <Collapse defaultActiveKey={['1']}>
                <Panel
                    header="Holding time between 2 minutes and 10 minutes on today profit."
                    key="1"
                >
                    <HFTReportDataTable 
                        data={dataBetween2to10MinOverThreshold} 
                        rowKeyProperty={"login"} 
                        hftThreshold={hftThreshold}
                        min={10}
                        showAlarmColumn
                        isLoading={isLoading}
                        refreshData={refreshData}
                    />
                </Panel>
            </Collapse>
            <Collapse>
                <Panel
                    header="Holding time between 10 minutes and 30 minutes on today profit."
                    key="1"
                >
                    <HFTReportDataTable 
                        data={dataBetween10to30MinOverThreshold} 
                        rowKeyProperty={"login"} 
                        hftThreshold={hftThreshold}
                        min={30}
                        showAlarmColumn
                        isLoading={isLoading}
                        refreshData={refreshData}
                    />
                </Panel>
            </Collapse>  
        </div>
    );
};

export default DataTab;


function checkAlarmSort( x: HFTResultFormatted, todayProfitCheck: boolean, newprofitLevel1: number, newprofitlevel2: number, newprofitpnl: number, profit: number){
    return (
        (x.todayProfitThreshold == null &&
            (x.todayProfit > newprofitlevel2 
                || (x.todayProfit > newprofitLevel1 && (x.historicalPnl! / x.todayProfit) > newprofitpnl)
            )
        ) || todayProfitCheck) && x.todayProfit > profit
};
