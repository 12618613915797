import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";

import { ColDef, ValueFormatterParams } from "@ag-grid-community/core";
import { plainAxiosInstance } from "../../../services/axiosSetup";
import { APIs } from "../../../services/apis";
import useRCPriceCompareStore from "../../../store/useRCPriceCompareStore";
import { useQueries } from "@tanstack/react-query";

function numberCellFormatter(params: ValueFormatterParams) {
    return params.value.toFixed(5);
}

const PriceCompareAGGrid = ({
    columnDefs = [],
    data = [],
    rowID = "symbol",
    height = 600,
}: {
    columnDefs: any[];
    data: any[];
    rowID?: string;
    height?: number;
}) => {
    const gridRef = useRef<AgGridReact>(null);
    const [rowData, setRowData] = useState<any[]>([]);
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        setIsMounted(true);
        return () => {
            setIsMounted(false);
        };
    }, []);
    useEffect(() => {
        if (isMounted) {
            if (rowData.length === 0) {
                setRowData(data);
            } else {
                gridRef.current?.api?.applyTransactionAsync({ update: data });
            }
        }
    }, [data, isMounted, rowData.length]);

    const getRowId = useCallback((params: any) => params.data[rowID], []);

    const defaultColDef = useMemo(() => {
        return {
            width: 100,
            sortable: false,
            filter: false,
            resizable: false,
            // disable all reorder
            suppressMovable: true,
        };
    }, []);

    if (!isMounted || columnDefs.length === 0) return null;

    return (
        <div className="ag-theme-balham" style={{ height: height, width: "100%" }}>
            <AgGridReact
                ref={gridRef}
                columnDefs={columnDefs}
                rowData={data}
                getRowId={getRowId}
                defaultColDef={defaultColDef}
                groupHeaderHeight={50}
                headerHeight={50}
            />
        </div>
    );
};

export default PriceCompareAGGrid;
