import moment from "moment";
import { create } from "zustand";

interface IInView {
    NO_MONEY: boolean;
    REQUEST_TIMEOUT: boolean;
    CLOSED_ORDER: boolean;
    MODIFY_OPEN_ORDER: boolean;
    MANAGER_ACCOUNT_INTERCEPTED: boolean;
    MT5_ORDER_TYPE: boolean;
    ACCOUNT_MONITOR: boolean;
    RESTORE_ORDER: boolean;
    UPDATE_ORDER_URGENT: boolean;
    BALANCE_ADJUSTMENT_MONITOR: boolean;
    SETTINGS: boolean;
}

type RCIssuesLogStore = {
    inView: IInView;
    noMoney: {
        mailFilter: {
            startTime: string;
            endTime: string;
            page: number;
            rows: number;
        };
    };
    /* 
    allow historyStore to be 
    {
        [type]: {
            start: string;
            end: string;
        }
    }
    */
    historyStore: Record<string, { start: string; end: string }>;
    filterStore: Record<string, any>;
};
type RCIssuesLogStoreActions = {
    updateInView: (newInView: Partial<IInView>) => void;
    updateNoMoneyMailFilter: (newFilter: Partial<RCIssuesLogStore["noMoney"]["mailFilter"]>) => void;
    updateHistoryStore: (newHistoryStore: Record<string, { start: string; end: string }>) => void;
    updateFilterStore: (newFilterStore: Record<string, any>) => void;
};

const useRCIssuesLogStore = create<RCIssuesLogStore & RCIssuesLogStoreActions>(set => ({
    inView: {
        NO_MONEY: true,
        REQUEST_TIMEOUT: false,
        CLOSED_ORDER: false,
        MODIFY_OPEN_ORDER: false,
        MANAGER_ACCOUNT_INTERCEPTED: false,
        MT5_ORDER_TYPE: false,
        ACCOUNT_MONITOR: false,
        RESTORE_ORDER: false,
        UPDATE_ORDER_URGENT: false,
        SETTINGS: false,
        BALANCE_ADJUSTMENT_MONITOR: false,
    },
    noMoney: {
        mailFilter: {
            startTime: moment(moment().subtract(1, "days").startOf("day")).format("YYYY-MM-DD"),
            endTime: moment().format("YYYY-MM-DD"),
            page: 1,
            rows: 10,
        },
    },
    historyStore: {},
    filterStore: {},
    updateInView: newInView =>
        set(state => ({
            inView: { ...state.inView, ...newInView },
        })),
    updateNoMoneyMailFilter: newFilter =>
        set(state => ({
            noMoney: { ...state.noMoney, mailFilter: { ...state.noMoney.mailFilter, ...newFilter } },
        })),
    updateHistoryStore: newHistoryStore =>
        set(state => ({
            historyStore: { ...state.historyStore, ...newHistoryStore },
        })),
    updateFilterStore: newFilterStore =>
        set(state => ({
            filterStore: { ...state.filterStore, ...newFilterStore },
        })),
}));

export default useRCIssuesLogStore;
