import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { APIs } from "../services/apis";
import { plainAxiosInstance } from "../services/axiosSetup";

const dummy = [
    {
        serverUno: 2,
        type: 1,
        serverDn: "AU",
    },
    {
        serverUno: 3,
        type: 1,
        serverDn: "UK",
    },
    {
        serverUno: 6,
        type: 1,
        serverDn: "IUK",
    },
    {
        serverUno: 13,
        type: 1,
        serverDn: "AU-DEMO",
    },
    {
        serverUno: 16,
        type: 1,
        serverDn: "MT5-AU",
    },
    {
        serverUno: 19,
        type: 1,
        serverDn: "VUK",
    },
    {
        serverUno: 20,
        type: 1,
        serverDn: "BHS",
    },
    {
        serverUno: 21,
        type: 1,
        serverDn: "VT",
    },
    {
        serverUno: 30,
        type: 1,
        serverDn: "AU2",
    },
    {
        serverUno: 31,
        type: 1,
        serverDn: "UK2",
    },
    {
        serverUno: 33,
        type: 1,
        serverDn: "BHS2",
    },
    {
        serverUno: 34,
        type: 1,
        serverDn: "MT5-IUK",
    },
    {
        serverUno: 36,
        type: 1,
        serverDn: "AU3",
    },
    {
        serverUno: 37,
        type: 1,
        serverDn: "UK3",
    },
    {
        serverUno: 39,
        type: 1,
        serverDn: "VT2",
    },
    {
        serverUno: 40,
        type: 1,
        serverDn: "PUI",
    },
    {
        serverUno: 43,
        type: 1,
        serverDn: "PUG-DEMO",
    },
    {
        serverUno: 45,
        type: 1,
        serverDn: "IV",
    },
    {
        serverUno: 48,
        type: 1,
        serverDn: "MT5-PUG",
    },
    {
        serverUno: 51,
        type: 1,
        serverDn: "AU4",
    },
    {
        serverUno: 52,
        type: 1,
        serverDn: "UK4",
    },
    {
        serverUno: 54,
        type: 1,
        serverDn: "BHS3",
    },
    {
        serverUno: 57,
        type: 1,
        serverDn: "UM",
    },
    {
        serverUno: 59,
        type: 1,
        serverDn: "UM-DEMO",
    },
    {
        serverUno: 60,
        type: 1,
        serverDn: "INF06",
    },
    {
        serverUno: 61,
        type: 1,
        serverDn: "AU-Tick-Server",
    },
    {
        serverUno: 62,
        type: 1,
        serverDn: "UK-Tick-Server",
    },
    {
        serverUno: 63,
        type: 1,
        serverDn: "PUI2",
    },
    {
        serverUno: 64,
        type: 1,
        serverDn: "VT3",
    },
    {
        serverUno: 65,
        type: 1,
        serverDn: "UK5",
    },
    {
        serverUno: 67,
        type: 1,
        serverDn: "INFTick",
    },
    {
        serverUno: 68,
        type: 1,
        serverDn: "VT4",
    },
    {
        serverUno: 70,
        type: 1,
        serverDn: "VIDA08",
    },
    {
        serverUno: 71,
        type: 1,
        serverDn: "MT5-UK",
    },
    {
        serverUno: 72,
        type: 1,
        serverDn: "MT5-StarTrader",
    },
    {
        serverUno: 73,
        type: 1,
        serverDn: "MT5-VIDA",
    },
    {
        serverUno: 75,
        type: 1,
        serverDn: "AU5",
    },
    {
        serverUno: 76,
        type: 1,
        serverDn: "UK6",
    },
    {
        serverUno: 77,
        type: 1,
        serverDn: "StarTrader2",
    },
    {
        serverUno: 78,
        type: 1,
        serverDn: "PU3",
    },
    {
        serverUno: 79,
        type: 1,
        serverDn: "MT5-AU-Tick-Server",
    },
    {
        serverUno: 80,
        type: 1,
        serverDn: "UK-DEMO",
    },
    {
        serverUno: 81,
        type: 1,
        serverDn: "VT5",
    },
    {
        serverUno: 82,
        type: 1,
        serverDn: "AU6",
    },
    {
        serverUno: 83,
        type: 1,
        serverDn: "UK7",
    },
    {
        serverUno: 84,
        type: 1,
        serverDn: "PU4",
    },
    {
        serverUno: 86,
        type: 1,
        serverDn: "RockFort",
    },
    {
        serverUno: 87,
        type: 1,
        serverDn: "UK8",
    },
    {
        serverUno: 88,
        type: 1,
        serverDn: "MONETA1",
    },
    {
        serverUno: 89,
        type: 1,
        serverDn: "MT5-AT",
    },
    {
        serverUno: 90,
        type: 1,
        serverDn: "VT6",
    },
    {
        serverUno: 92,
        type: 1,
        serverDn: "PU5",
    },
    {
        serverUno: 93,
        type: 1,
        serverDn: "MT5-Moneta1",
    },
    {
        serverUno: 94,
        type: 1,
        serverDn: "UK9",
    },
    {
        serverUno: 95,
        type: 1,
        serverDn: "VT7",
    },
    {
        serverUno: 97,
        type: 1,
        serverDn: "MT5-VJP",
    },
    {
        serverUno: 98,
        type: 1,
        serverDn: "UK10",
    },
    {
        serverUno: 99,
        type: 1,
        serverDn: "VJP",
    },
    {
        serverUno: 100,
        type: 1,
        serverDn: "StarTrader3",
    },
    {
        serverUno: 101,
        type: 1,
        serverDn: "MT5-StarTrader2",
    },
    {
        serverUno: 102,
        type: 1,
        serverDn: "MT5-VT",
    },
    {
        serverUno: 105,
        type: 1,
        serverDn: "PU6",
    },
    {
        serverUno: 106,
        type: 1,
        serverDn: "PU7",
    },
    {
        serverUno: 107,
        type: 1,
        serverDn: "VT8",
    },
    {
        serverUno: 108,
        type: 1,
        serverDn: "VT9",
    },
    {
        serverUno: 109,
        type: 1,
        serverDn: "MT5-UK2",
    },
    {
        serverUno: 110,
        type: 1,
        serverDn: "M4",
    },
    {
        serverUno: 111,
        type: 1,
        serverDn: "MT5-M4",
    },
    {
        serverUno: 300,
        type: 1,
        serverDn: "ISPRIME",
    },
    {
        serverUno: 301,
        type: 1,
        serverDn: "CMC",
    },
    {
        serverUno: 303,
        type: 1,
        serverDn: "CFH",
    },
    {
        serverUno: 304,
        type: 1,
        serverDn: "CMC(PXM)",
    },
    {
        serverUno: 514,
        type: 1,
        serverDn: "Coinbase",
    },
    {
        serverUno: 515,
        type: 1,
        serverDn: "Active",
    },
    {
        serverUno: 516,
        type: 1,
        serverDn: "Morningstar",
    },
    {
        serverUno: 517,
        type: 1,
        serverDn: "Broctagon",
    },
    {
        serverUno: 518,
        type: 1,
        serverDn: "Velocity",
    },
    {
        serverUno: 522,
        type: 1,
        serverDn: "Binance",
    },
    {
        serverUno: 700,
        type: 1,
        serverDn: "MTS-AU",
    },
    {
        serverUno: 701,
        type: 1,
        serverDn: "MTS-PU",
    },
    {
        serverUno: 900,
        type: 1,
        serverDn: "MT4Test",
    },
    {
        serverUno: 902,
        type: 1,
        serverDn: "MT4Test-C",
    },
];

/*
sample response from the API
[{
  "serverUno": 2,
  "type": 1,
  "serverDn": "AU"
}]
*/

export interface IRCPriceBackendOpt {
    serverUno: number;
    type: number;
    serverDn: string;
}

type TPriceBackendOptType = "Price Calculate" | "Price Connector" | "Price Analysis" | "Tick Receiver";

const useRCPriceBackendOpt = ({ type }: { type: TPriceBackendOptType }) => {
    const returnType = (type: TPriceBackendOptType) => {
        switch (type) {
            case "Price Calculate":
                return 1;
            case "Price Connector":
                return 2;
            case "Price Analysis":
                return 3;
            case "Tick Receiver":
                return 4;
            default:
                return 1;
        }
    };
    const { data, refetch, isLoading, error, isFetching } = useQuery({
        queryKey: ["rcPriceBackendOpt", type],
        queryFn: () =>
            plainAxiosInstance.get(`${APIs.RC_PRICE_SETTINGS.GET_PRICE_BACKEND_OPT}?type=${returnType(type)}`, {
                headers: {
                    Accept: "application/json",
                },
            }),
        staleTime: 60 * 1000, //(10 x 1000ms = 10 seconds)
    });

    const rcPriceBackendOpt: IRCPriceBackendOpt[] = data?.data?.status ? [] : data?.data || [];
    // @ts-ignore
    const rcPriceBackendOptError: string | null = data?.data?.message || error?.message || error || null;

    return {
        rcPriceBackendOpt,
        rcPriceBackendOptError,
        dummy,
        refetchRcPriceBackendOptError: refetch,
        isFetching,
        isLoading,
    };
};

export const useRCPriceBackendOptDetails = ({ param, isTickReciverTools = false }: { param: string; isTickReciverTools?: boolean }) => {
    const formData = new FormData();
    formData.append("keyStr", param);
    const { data, refetch, isLoading, error, isFetching } = useQuery({
        queryKey: ["rcPriceBackendOptDetails", param],
        queryFn: () =>
            plainAxiosInstance.post(
                isTickReciverTools
                    ? APIs.RC_PRICE_SETTINGS.POST_PRICE_BACKEND_OPT_DETAILS_TICK_RECEIVER
                    : APIs.RC_PRICE_SETTINGS.POST_PRICE_BACKEND_OPT_DETAILS,
                formData,
                {
                    headers: {
                        Accept: "application/json, text/plain, */*",
                    },
                }
            ),
        staleTime: 60 * 1000, //(10 x 1000ms = 10 seconds)
    });

    // @ts-ignore
    const rcPriceBackendOptDetails: string | null = data?.data?.status ? null : data?.data || null;
    // @ts-ignore
    const rcPriceBackendOptDetailsError: string | null = data?.data?.message || error?.message || error || null;

    return {
        rcPriceBackendOptDetails,
        rcPriceBackendOptDetailsError,
        refetchRcPriceBackendOptDetails: refetch,
        isFetching,
        isLoading,
    };
};

export const useRCPriceBackendOptMutations = () => {
    // restart servers
    const { mutateAsync, isLoading, error } = useMutation(
        ({ ids }: { ids: number[] }) =>
            plainAxiosInstance.post(APIs.RC_PRICE_SETTINGS.POST_PRICE_BACKEND_OPT_RESTART, ids, {
                headers: {
                    Accept: "application/json, text/plain, */*",
                },
            }),
        {
            onSuccess: res => {
                return res;
            },
            onError: err => {
                return err;
            },
        }
    );

    return {
        restartServers: mutateAsync,
        isLoadingRestartServers: isLoading,
        restartServersError: error,
    };
};

export default useRCPriceBackendOpt;
