import React, { useMemo, useState } from "react";
import { DownOutlined, RightOutlined, SearchOutlined } from "@ant-design/icons";
import { KeyValuePair } from "../../../../../constants/type";
import { FormComponent } from "../../../../../components/FormComponent";
import { ComponentType } from "../../../../../constants";
import { Checkbox, FormInstance, Input, Space } from "antd";

export interface FilterOptionsComponentProps {
    isOpen: boolean;
    label: string;
    options: KeyValuePair[];
    showMe: (key: string) => void;
    form: FormInstance;
    filterType: string;
}

export const FilterOptionsComponent: React.FC<FilterOptionsComponentProps> = (props) => {
    const [searchInput, setSearchInput] = useState<string>("");
    const [selectAll, setSelectAll] = useState<boolean>(false);

    const filteredOptions = useMemo(() => {
        return props.options.map((option) => ({
            ...option,
            style: {
                display: searchInput && option.text.toLowerCase().includes(searchInput.toLowerCase()) ? "flex" : "none"
            },
        }));
    }, [props.options, searchInput]);

    const onCheckAllChange = (e: any) => {
        setSelectAll((prev) => !prev);
        props.form.setFieldValue(props.filterType, e.target.checked ? props.options.map((option) => option.value) : []);
    };

    const selectionOptions = useMemo(
        () => (
            <div className="filter-keyword-search">
                {/* <Space className="keyword-search-box">
                    <Input
                        type="text"
                        className="keyword-search-input"
                        prefix={<SearchOutlined />}
                        placeholder="Search keyword"
                        onChange={(e) => setSearchInput(e.target.value)}
                        allowClear
                    />
                    <div className="select-all">
                        <Checkbox onChange={onCheckAllChange} checked={selectAll}>
                            Select All
                        </Checkbox>
                    </div>
                </Space> */}
                <FormComponent
                    label=""
                    name={props.filterType}
                    extra={{
                        type: ComponentType.dropdown,
                        value: filteredOptions,
                        inputProps: {
                            mode: props.filterType === "AccGroup" ? "tags" : "multiple",
                            placeholder: `Please select ${props.label}`,
                            tokenSeparators: [",", "，", " "],
                        },
                    }}
                />
            </div>
        ),
        [filteredOptions, props.filterType, searchInput, selectAll]
    );

    return (
        <div className="options-container">
            <div className="title">
                <b>{props.label}</b>
            </div>
            <div className={`content nice-scrollbar`}>{selectionOptions}</div>
        </div>
    );
};

export default FilterOptionsComponent;
