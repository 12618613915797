import { useState } from "react";
import { Button, Col, Form, message, Row } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { User } from "../../constants/type";
import SitePageHeader from "../../components/PageHeader";
import { UserOutlined } from "@ant-design/icons";
import { userInfo } from "../../services/api";
import LoadingComponent from "../../components/Loading";
import { FormComponent } from "../../components/FormComponent";
import { DataTableColumnRender, ErrorCatchValidator, ErrorMessageHandler } from "../../utils/Common";
import { ComponentType } from "../../constants";
import { apiRequest, APIs } from "../../services/apiConfig";
import { SUCCESS_FAILED } from "../../constants";
import { REQUIRED_FIELD } from "../../constants/errorMessage";

function MyAccountEditPage() {
    let navigate = useNavigate();
    let location = useLocation();
    let myProfile = location.state as User;

    const initialMarkUpData = (data: User): User => {
        let tData = { ...data };
        tData.createdDateUtc = DataTableColumnRender.DateTime(tData.createdDateUtc);
        tData.modifiedDateUtc = DataTableColumnRender.DateTime(tData.modifiedDateUtc);
        return tData;
    };

    const [profileForm] = Form.useForm();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [comData] = useState<User>(initialMarkUpData(myProfile));
    const currentLoggedInUser = userInfo();

    const onSubmit = (values: any) => {
        try {
            const uuid = currentLoggedInUser?.uuid;
            setIsLoading(true);
            apiRequest(APIs.UPDATE_PROFILE, {
                ...values,
                uuid,
            })
                .then(() => {
                    ErrorMessageHandler("User profile", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                    navigate("/myaccount");
                })
                .catch((error) =>
                    ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("user profile", SUCCESS_FAILED.FAILED_UPDATE_DATA, err))
                )
                .finally(() => setIsLoading(false));
        } catch (err: any) {
            message.error(err.toString());
        }
    };

    return (
        <SitePageHeader
            title={"Update Profile"}
            routes={[
                { path: "/myaccount", breadcrumbName: "My Profile", icon: <UserOutlined /> },
                { path: "", breadcrumbName: "Update Profile" },
            ]}
            onBack={() => navigate("/myaccount", {})}
        >
            {isLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Submitting..." />
                </div>
            ) : (
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    form={profileForm}
                    layout="horizontal"
                    initialValues={comData}
                    onFinish={onSubmit}
                >
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Username"
                                name="username"
                                extra={{
                                    type: ComponentType.labelOnly,
                                    value: "",
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="First Name"
                                name="firstName"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [
                                        {
                                            required: true,
                                            message: REQUIRED_FIELD,
                                        }
                                    ]
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Last Name"
                                name="lastName"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [
                                        {
                                            required: true,
                                            message: REQUIRED_FIELD,
                                        }
                                    ]
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Email"
                                name="email"
                                extra={{
                                    type: ComponentType.labelOnly,
                                    value: "",
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="User Role"
                                name="roles"
                                extra={{
                                    type: ComponentType.labelOnly,
                                    value: "",
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="User Team"
                                name="team"
                                extra={{
                                    type: ComponentType.labelOnly,
                                    value: "",
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Create at (Local)"
                                name="createdDateUtc"
                                extra={{
                                    type: ComponentType.labelOnly,
                                    value: "",
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Last update at (Local)"
                                name="modifiedDateUtc"
                                extra={{
                                    type: ComponentType.labelOnly,
                                    value: "",
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div className="step-btns-group">
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            )}
        </SitePageHeader>
    );
}

export default MyAccountEditPage;
