import { HomeOutlined } from "@ant-design/icons";
import { Button, Col, Form, Popconfirm, Row } from "antd";
import { useEffect, useState } from "react";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { FormComponent } from "../../../components/FormComponent";
import LoadingComponent from "../../../components/Loading";
import SitePageHeader from "../../../components/PageHeader";
import { ComponentType, SUCCESS_FAILED } from "../../../constants";
import { REQUIRED_FIELD } from "../../../constants/errorMessage";
import { Brands2, BrandsList, Module, RoleState, TreeItemProps } from "../../../constants/type";
import { apiRequest, APIs } from "../../../services/apiConfig";
import { ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";
import { getTreeList } from "./ViewRolePage";
import Permission from "./Components/permissions";
import { objectRemoveProps } from "../../../utils/object";

interface CreateRoleMenuProps {
    roleId: number;
    roleName: string;
    description: string;
    modules: number[];
}

const initialValues = (): CreateRoleMenuProps => ({ roleId: 0, roleName: "", description: "", modules: [] });
const moduleKeyPrefix: string = "rce-mcb-";

function RoleCreateEditPage() {
    let navigate = useNavigate();
    let location = useLocation();
    let com_state: RoleState = location.state as RoleState;
    const [currentState] = useState<RoleState>(com_state || { action: "add", roleId: 0 });
    const [isAddAction] = useState<boolean>(currentState.action === "add");
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [modules, setModules] = useState<TreeItemProps[]>([]);
    const [oriMenulist, setOriMenulist] = useState<Module[]>([]);
    const [brands, setBrands] = useState<BrandsList[]>([]);
    const [roleForm] = Form.useForm();

    const onSubmit = (values: any) => {
        setIsLoading(true);

        let submitData = objectRemoveProps(
            { ...values },
            Object.keys(values).filter((x) => x.indexOf(moduleKeyPrefix) > -1)
        );
        submitData["modules"] = Object.keys(values)
            .filter((x) => x.indexOf(moduleKeyPrefix) > -1 && values[x])
            .map((x) => x.replace(moduleKeyPrefix, ""));
        // let menuOnly = oriMenulist.filter((x) => x.type === 1).map((x) => x.id);
        // submitData.modules = submitData.modules.map((x: string) => parseInt(x)).filter((x: number) => !menuOnly.includes(x));
        if (isAddAction) {
            apiRequest(APIs.CREATE_ROLE_MENU, submitData)
                .then(() => {
                    ErrorMessageHandler("New role", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                    navigate("/siteadmin/rolepermission");
                })
                .catch((error) => {
                    ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("new role", SUCCESS_FAILED.FAILED_CREATE_DATA, err));
                    setIsLoading(false);
                });
        } else {
            apiRequest(APIs.UPDATE_ROLE_MENU, submitData)
                .then((data) => {
                    ErrorMessageHandler("Existing role", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                    navigate("/siteadmin/rolepermission");
                })
                .catch((error) =>
                    ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("existing role", SUCCESS_FAILED.FAILED_UPDATE_DATA, err))
                );
        }
    };

    const getConfigInfo = () => {
        // apiRequest(APIs.GET_BRAND_SERVER_LIST, {})
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["brand"] })
            .then((data: any) => {
                setBrands(data.brands);

                apiRequest(APIs.GET_MENU_LISTING, {})
                    .then((data: any) => {
                        let sortedData: Module[] = data as Module[];
                        sortedData.sort((a: Module, b: Module) => a.order - b.order);

                        setModules(getTreeList(sortedData));
                        setOriMenulist(sortedData);

                        if (!isAddAction) {
                            apiRequest(APIs.GET_ROLE_MENU_LISTING, { roleId: currentState.roleId, withModules: true })
                                .then((data: any) => {
                                    let tmp = data[0];
                                    let moduleObj: any = tmp.modules
                                        .filter((x: any) => (x.status as number) === 1)
                                        .map((x: any) => `${moduleKeyPrefix}${x.menuId}`)
                                        .reduce((obj: any, x: string) => {
                                            obj[x] = true;
                                            return obj;
                                        }, {});

                                    let objValue = Object.assign(
                                        {},
                                        initialValues(),
                                        {
                                            roleId: tmp.roleId,
                                            roleName: tmp.role,
                                            description: tmp.description,
                                            brands: tmp.brands.map((x: Brands2) => x.brandId),
                                            //modules: tmp.modules.filter((x: any) => (x.status as number) === 1).map((x: any) => x.menuId.toString()),
                                        },
                                        moduleObj
                                    );

                                    roleForm.setFieldsValue(objValue);
                                    setIsLoading(false);
                                })
                                .catch((error: any) => {
                                    ErrorCatchValidator(error, (err: any) =>
                                        ErrorMessageHandler("role details", SUCCESS_FAILED.FAILED_LOAD_DATA, err)
                                    );
                                    navigate("/siteadmin/rolepermission");
                                });
                        } else {
                            setIsLoading(false);
                        }
                    })
                    .catch((error: any) => {
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("role listing", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                        navigate("/siteadmin/rolepermission");
                    });
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("brand listing", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                navigate("/siteadmin/rolepermission");
            });
    };

    const deleteProfile = (roleId: string, navigator: NavigateFunction) => {
        apiRequest(APIs.DELETE_ROLE_MENU, { roleId })
            .then((data) => {
                ErrorMessageHandler("The role detail", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                navigator("/siteadmin/rolepermission");
            })
            .catch((error) => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("role detail", SUCCESS_FAILED.FAILED_DELETE_DATA, err)));
    };

    useEffect(() => {
        getConfigInfo();
        return () => {};
    }, []);

    return (
        <SitePageHeader
            title={isAddAction ? "Create New Role" : "Edit Role"}
            routes={[
                {
                    path: "/siteadmin/rolepermission",
                    breadcrumbName: "Role & Permission",
                    icon: <HomeOutlined />,
                },
                {
                    path: "",
                    breadcrumbName: isAddAction ? "Create New Role" : "Edit Role",
                },
            ]}
            onBack={() => navigate("/siteadmin/rolepermission")}
            extraProps={{
                extra: isAddAction
                    ? []
                    : [
                          <Popconfirm
                              key={"cetmp-del"}
                              title="Confirm to delete?"
                              onConfirm={() => deleteProfile(currentState.roleId.toString(), navigate)}
                              okText="Yes"
                              cancelText="No"
                          >
                              <Button type="primary" danger>
                                  Delete Role
                              </Button>
                          </Popconfirm>,
                      ],
            }}
        >
            {isLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Submitting..." />
                </div>
            ) : (
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    form={roleForm}
                    layout="horizontal"
                    initialValues={currentState.data}
                    onFinish={onSubmit}
                >
                    <Row>
                        <Col span={15}>
                            <FormComponent label={""} name={"roleId"} extra={{ type: ComponentType.hidden, value: "" }} />
                            <FormComponent
                                label="Role Name"
                                name="roleName"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Description"
                                name="description"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Brands"
                                name="brands"
                                extra={{
                                    type: ComponentType.dropdown,
                                    value: brands.map((x) => ({ text: x.brand, value: x.id })),
                                    inputProps: {
                                        mode: "multiple",
                                        allowClear: true,
                                    },
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={5} style={{ textAlign: "right", paddingRight: "0.651vw" }}>
                            Modules :
                        </Col>
                        <Col span={10}>
                            {/* <FormComponent
                                label="Modules"
                                name="modules"
                                extra={{
                                    type: ComponentType.tree,
                                    value: modules,
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            /> */}
                            <Permission moduleList={modules} selectedList={[]} keyPrefix={moduleKeyPrefix} />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ paddingTop: "2.828vh" }}>
                            <div className="step-btns-group">
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            )}
        </SitePageHeader>
    );
}

export default RoleCreateEditPage;
