import { Row, Col, FormInstance, Form } from "antd";
import ChartBuilder from "../../../components/Charts/Builder";
import { FormComponent } from "../../../components/FormComponent";
import { ComponentType } from "../../../constants";
import { REQUIRED_FIELD } from "../../../constants/errorMessage";

export interface ViewCreateEditProps {
    form: FormInstance;
    data: any;
}

const ViewCreateEdit = (props: ViewCreateEditProps) => {
    return (
        <div className="data-visual-creat-container">
            <Form
                labelCol={{ span: 5 }}
                labelAlign={"left"}
                wrapperCol={{ span: 19 }}
                form={props.form}
                layout="horizontal"
                //initialValues={props.data}
            >
                <Row>
                    <Col span={24}>
                        <FormComponent label={""} name={"id"} extra={{ type: ComponentType.hidden, value: "" }} />
                        <FormComponent label={""} name={"profileId"} extra={{ type: ComponentType.hidden, value: "" }} />
                        <FormComponent
                            label="Name"
                            name="name"
                            extra={{
                                type: ComponentType.text,
                                value: "",
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Description"
                            name="description"
                            extra={{
                                type: ComponentType.text,
                                value: "",
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Level"
                            name="level"
                            extra={{
                                type: ComponentType.number,
                                value: "",
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Data Query"
                            name="dataQuery"
                            extra={{
                                type: ComponentType.textarea,
                                value: "",
                                itemProps: {
                                    labelAlign: "left",
                                },
                                inputProps: {
                                    rows: 10,
                                },
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <FormComponent
                            label=" "
                            name="isAccLevel"
                            extra={{
                                type: ComponentType.checkbox,
                                value: "Link to Account 360",
                                itemProps: {
                                    colon: false,
                                    labelCol: {
                                        span: 10,
                                    },
                                    style: {
                                        marginBottom: "0px",
                                    },
                                },
                            }}
                        />
                    </Col>
                    <Col span={12}>
                        <FormComponent
                            label=" "
                            name="isEventRuleView"
                            extra={{
                                type: ComponentType.checkbox,
                                value: "Link to Event Rule's View",
                                itemProps: {
                                    colon: false,
                                    style: {
                                        marginBottom: "0px",
                                    },
                                },
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <FormComponent
                            label=" "
                            name="isViewTicket"
                            extra={{
                                type: ComponentType.checkbox,
                                value: "Link to Ticket's View",
                                itemProps: {
                                    colon: false,
                                    labelCol: {
                                        span: 10,
                                    },
                                },
                            }}
                        />
                    </Col>
                    <Col span={12}></Col>
                </Row>
                <Row>
                    <Col span={24} className="mg-btm-title custom-subtitle">
                        Data Visualization
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <ChartBuilder form={props.form} />
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default ViewCreateEdit;
