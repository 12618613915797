import { useCallback, useEffect, useState } from "react";
import FlexiDataTable from "@/components/FlexiDataTable";
import { FlexiDataTableCallbackProps, FlexiDataTableOptionsProps } from "@/constants/type";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { Button, Col, Form, message, Modal, Row, Select, Tooltip, Upload } from "antd";
import { CALLBACK_KEY, ComponentType, InnerPageActionMode, SUCCESS_FAILED } from "@/constants";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { DownloadOutlined, ExclamationCircleOutlined, SaveOutlined, UndoOutlined, UploadOutlined } from "@ant-design/icons";
import useRCPriceCompareServers from "@/hooks/useRCPriceCompareServers";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { APIs } from "@/services/apis";
import TimeDiff from "@/pages/SystemMonitor/components/TimeDiff";
import { FormComponent } from "@/components/FormComponent";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { defaultIfEmptyOrNull, isEmptyOrNull } from "@/utils/string";
import { differenceWith, isEqual } from "lodash";
import moment from "moment";

interface MidPriceSettingOtherComparedData {
    compServerName?: string;
    compServerUno: number;
    compSymbol: string;
    exSpit: number;
    midPriceTimes: number;
    otherSymbolId?: any;
    primarySymbolId?: any;
    serverName?: string;
    serverUno?: number;
    symbol: string;
    isCreateEdit?: InnerPageActionMode.CREATE_NEW | InnerPageActionMode.EDIT | undefined;
    newKey?: string;
    isDeleted?: boolean;
};

const initialSetting = {
    symbol: "",
    compServerUno: null,
    compSymbol: "",
    exSpit: 1,
    midPriceTimes: 30,
};

const MidPriceSetting = () => {
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.PRICE_SETTING_SPREAD_ALARM_EDIT);

    const [oriData, setOriData] = useState<MidPriceSettingOtherComparedData[]>([]);
    const [data, setData] = useState<MidPriceSettingOtherComparedData[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [currSelectedServer, setCurrSelectedServer] = useState<any>("");
    const [currEdit, setCurrEdit] = useState<InnerPageActionMode.CREATE_NEW | MidPriceSettingOtherComparedData | null>(null);
    const [midPriceSettingForm] = Form.useForm();
    const [ableToResetSave, setAbleToResetSave] = useState<boolean>(false);
    const [isResetting, setIsResetting] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isExporting, setIsExporting] = useState<boolean>(false);
    const [isUploading, setIsUploading] = useState<boolean>(false);

    const { rcPriceCompareOwnServers, refetchRcPriceCompareServers, isFetching, dataUpdatedAt, rcPriceCompareCompetitorServers } = useRCPriceCompareServers();

    const columns = [
        DTColProps.Small({
            title: "Server",
            dataIndex: "serverName",
            key: "serverName",
            sorter: (a: any, b: any) => a.serverName.localeCompare(b.serverName),
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.Small({
            title: "Symbol",
            dataIndex: "symbol",
            key: "symbol",
            sorter: (a: any, b: any) => a.symbol.localeCompare(b.symbol),
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.Small({
            title: "Competitor Server",
            dataIndex: "compServerName",
            key: "compServerName",
            sorter: (a: any, b: any) => a.compServerName.localeCompare(b.compServerName),
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.Small({
            title: "Competitor Symbol",
            dataIndex: "compSymbol",
            key: "compSymbol",
            sorter: (a: any, b: any) => a.compSymbol.localeCompare(b.compSymbol),
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.XSmall({
            title: "Expire Spread",
            dataIndex: "exSpit",
            key: "exSpit",
            sorter: (a: any, b: any) => a.exSpit - b.exSpit,
        }),
        DTColProps.XSmall({
            title: "Mid Price Times",
            dataIndex: "midPriceTimes",
            key: "midPriceTimes",
            sorter: (a: any, b: any) => a.midPriceTimes - b.midPriceTimes,
        }),
    ];

    const options: FlexiDataTableOptionsProps = {
        add: enableUpdate,
        edit: enableUpdate,
        delete: enableUpdate,
        separateActionButton: true,
        extraButtons: () => (
            <div className="extra-header-buttons" key={"ps-mps-extra-buttons"}>
                <Button icon={<DownloadOutlined />} loading={isExporting} disabled={isUploading} onClick={() => componentCallback(CALLBACK_KEY.EXPORT_CSV_EXCEL, {})}>Download</Button>
                <Button icon={<DownloadOutlined />} loading={isExporting} disabled={isUploading} onClick={() => componentCallback(CALLBACK_KEY.OTHERS, "export-all")}>Download All</Button>
                <Upload key={`btn-upload-${Math.random()}`}
                    name="file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    multiple={false}
                    showUploadList={false}
                    onChange={(info: any) => {
                        if (info.file.status === "error") {
                            message.error(`${info.file.name} file upload failed.`);
                        }
                    }}
                    customRequest={({ file, onSuccess }: any) =>
                        setTimeout(() => {
                            onSuccess("ok");
                        }, 0)}
                    beforeUpload={(file: any) => handleUpload(file)}
                >
                    <Button
                        htmlType="button"
                        icon={<UploadOutlined style={{ fontSize: "0.875rem" }} />}
                        loading={isUploading}
                        disabled={isExporting}
                    >
                        Upload
                    </Button>
                </Upload>
            </div >
        ),
        recordRowClassName: (record: any) => {
            return record.isCreateEdit === InnerPageActionMode.CREATE_NEW
                ? "new-row"
                : record.isCreateEdit === InnerPageActionMode.EDIT
                    ? "modified-row"
                    : "";
        },
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, FormData) => {
        let copyData = JSON.parse(JSON.stringify(data));
        switch (type) {
            case CALLBACK_KEY.EXPORT_CSV_EXCEL:
                handleExport();
                break;
            case CALLBACK_KEY.CREATE_NEW:
                midPriceSettingForm.setFieldsValue(initialSetting);
                setCurrEdit(InnerPageActionMode.CREATE_NEW);
                break;
            case CALLBACK_KEY.DO_EDIT:
                midPriceSettingForm.setFieldsValue(FormData);
                setCurrEdit(FormData);
                break;
            case CALLBACK_KEY.DO_DELETE:
                let updatedData = copyData.map((x: MidPriceSettingOtherComparedData) => {
                    let tmp = FormData && x.newKey === FormData.newKey
                        ? {
                            ...FormData,
                            isCreateEdit: x.isCreateEdit === InnerPageActionMode.CREATE_NEW ? InnerPageActionMode.CREATE_NEW : InnerPageActionMode.EDIT,
                            isDeleted: true,
                        } : { ...x };
                    return tmp;
                })
                    .filter((x: MidPriceSettingOtherComparedData) => !x.isDeleted);
                setData(updatedData);
                break;
            case CALLBACK_KEY.OTHERS:
                if (FormData === "export-all") {
                    handleExport(true);
                };
                break;
            default:
                break;
        }
    };

    const handleExport = (downloadAll: boolean = false) => {
        setIsExporting(true);
        plainAxiosInstance
            .get(`${APIs.RC_PRICE_SETTINGS.GET_MID_PRICE_SETTINGS_DOWNLOAD}${downloadAll ? "" : `?serverUno=${currSelectedServer}`}`, {
                headers: { Accept: "application/octet-stream, */*" },
                responseType: "blob",
            })
            .then(res => {
                const fileName = `Mid_price_setting${downloadAll ? "_all" : `_${currSelectedServer}`}-${moment().format("YYYYMMDD_HHmmss")}.xlsx`;
                const contentType = res.headers["content-type"];
                if (
                    contentType === "application/octet-stream" ||
                    contentType === "text/csv" ||
                    contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ) {
                    // Handle the file download response
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", (`${fileName}`)); // or any other extension
                    link.setAttribute("type", "hidden");
                    document.body.appendChild(link);
                    link.click();
                    if (link.parentNode) {
                        link.parentNode.removeChild(link); // Clean up and remove the link
                    } else document.body.removeChild(link);
                    // Clean up
                    window.URL.revokeObjectURL(url);
                } else {
                    message.error(`Received non-file response. Error: ${res}`, 3);
                }
            })
            .catch(err => {
                message.error(`Download error: ${err}`, 3);
            })
            .finally(() => setIsExporting(false));
    };

    const handleUpload = (fileToUpload: any) => {
        try {
            let fileExtension: string[] = defaultIfEmptyOrNull(/\.[^\.]+/.exec(fileToUpload.name), [""]),
                isLt5M = fileToUpload.size / 1024 / 1024 < 10;

            if (fileExtension[0] !== ".xlsx") {
                message.error("Please check file type. Only .xlsx files are allowed.", 3);
                return;
            } else if (!isLt5M) {
                message.error("Please check file size less than 10 MB", 3);
                return;
            }

            Modal.confirm({
                icon: <ExclamationCircleOutlined />,
                title: "Are you sure you want to upload this file?",
                width: "30%",
                onOk() {
                    var formData = new FormData();
                    formData.append("file", fileToUpload);

                    setIsUploading(true);
                    setIsLoading(true);
                    plainAxiosInstance
                        .post(APIs.RC_PRICE_SETTINGS.POST_MID_PRICE_SETTINGS_UPLOAD, formData)
                        .then(res => {
                            if (res.status === 200) {
                                ErrorMessageHandler("Mid price setting(s)", SUCCESS_FAILED.SUCCESS_UPLOAD_DATA);
                                setRunRefetchDataList(true);
                            } else {
                                message.error(res.data, 3);
                                setIsLoading(false);
                            }
                        })
                        .catch((error: any) => {
                            message.error(`File upload failed: (${error.response.data.message})`, 3);
                            setIsLoading(false);
                        })
                        .finally(() => setIsUploading(false));
                },
                onCancel() { },
            });
        } catch (error) {
            message.error(`Error during uploading file`, 3);
        }
    };

    const onFormSubmit = () => {
        midPriceSettingForm
            .validateFields()
            .then((values) => {
                setIsLoading(true);
                if (currEdit === InnerPageActionMode.CREATE_NEW) {
                    let currServer = rcPriceCompareOwnServers.find((x: any) => x.serverUno === currSelectedServer);
                    let currCompServer = rcPriceCompareCompetitorServers.find((x: any) => x.serverUno === values.compServerUno);
                    let copyData = JSON.parse(JSON.stringify(data)), addedData = [];
                    addedData.push(
                        {
                            ...values,
                            serverUno: currSelectedServer,
                            serverName: currServer?.name || "",
                            compServerName: isEmptyOrNull(currCompServer) ? "Unknown" : currCompServer?.name,
                            isCreateEdit: InnerPageActionMode.CREATE_NEW,
                            newKey: `${currSelectedServer}|${values.symbol}|${values.compServerUno}|${values.compSymbol}`,
                        },
                        ...copyData
                    )
                    let filteredData = addedData.filter((x: MidPriceSettingOtherComparedData) => !x.isDeleted);
                    setData(filteredData);
                    setIsLoading(false);
                    midPriceSettingForm.resetFields();
                    setCurrEdit(null);
                } else {
                    let copyData = JSON.parse(JSON.stringify(data));
                    let newData = copyData.map((x: MidPriceSettingOtherComparedData) => {
                        let editedData = currEdit && x.newKey === currEdit.newKey
                            ? {
                                ...x,
                                ...values,
                                isCreateEdit: x.isCreateEdit === InnerPageActionMode.CREATE_NEW ? InnerPageActionMode.CREATE_NEW : InnerPageActionMode.EDIT,
                            } : { ...x };
                        return editedData;
                    })
                        .filter((x: MidPriceSettingOtherComparedData) => !x.isDeleted);
                    setData(newData);
                    setIsLoading(false);
                    midPriceSettingForm.resetFields();
                    setCurrEdit(null);
                };
            })
            .catch((errorInfo) => {
                console.error("Failed to submit form: ", errorInfo);
            });
    };

    const onSaveAllData = useCallback(() => {
        let tmpAddedData: any[] = [], tmpEditedData: any[] = [], tmpDeletedData: any[] = [];
        data.forEach((currRow: MidPriceSettingOtherComparedData) => {
            if (currRow.isCreateEdit === InnerPageActionMode.CREATE_NEW) {
                if (currRow.isDeleted === undefined || currRow.isDeleted === false) {
                    tmpAddedData.push(currRow);
                };
            } else if (currRow.isCreateEdit === InnerPageActionMode.EDIT) {
                if (currRow.isDeleted === true) {
                    tmpDeletedData.push(currRow);
                } else {
                    tmpEditedData.push(currRow);
                };
            };
        });
        const addedData = tmpAddedData.map(({ isCreateEdit, newKey, ...rest }) => rest);
        const editedData = tmpEditedData.map(({ isCreateEdit, newKey, ...rest }) => rest);
        const deletedData = tmpDeletedData.map(({ isCreateEdit, newKey, isDeleted, ...rest }) => rest);

        Modal.confirm({
            title: "Are you sure you want to save all of these modified settings ?",
            icon: <ExclamationCircleOutlined />,
            content: <div>
                <ul>
                    {addedData.length > 0 && <li key={"create"}>Creating {addedData.length} new setting(s).</li>}
                    {editedData.length > 0 && <li key={"update"}>Updating {editedData.length} existing setting(s).</li>}
                    {deletedData.length > 0 && <li key={"delete"}>Deleting {deletedData.length} existing setting(s).</li>}
                </ul>
                <p>Note: All your changes will affect the price alarm outage once saved.</p>
            </div>,
            okText: "Confirm",
            onOk: () => {
                let promises: any[] = [];
                if ((addedData.length + editedData.length) > 0) promises.push(plainAxiosInstance.post(`${APIs.RC_PRICE_SETTINGS.POST_SAVE_UPDATE_ADD_MID_PRICE_SETTINGS}`, [...addedData, ...editedData]));
                if (deletedData.length > 0) promises.push(plainAxiosInstance.delete(`${APIs.RC_PRICE_SETTINGS.DELETE_MID_PRICE_SETTINGS}`, { data: deletedData }));
                setIsLoading(true);
                setIsSubmitting(true);
                Promise.all(promises)
                    .then(() => {
                        if ((addedData.length + editedData.length) > 0) ErrorMessageHandler("Mid price setting(s)", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                        if (deletedData.length > 0) ErrorMessageHandler("Mid price setting(s)", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                        setRunRefetchDataList(true);
                    })
                    .catch((error: any) => ErrorCatchValidator(error, (err: any) => {
                        ErrorMessageHandler("mid price setting(s) list", SUCCESS_FAILED.FAILED_UPDATE_DATA, err);
                        console.log("Failed to update mid price settings list: ", err);
                        setIsLoading(false);
                    }))
                    .finally(() => setIsSubmitting(false));
            },
            onCancel: () => { },
        });
    }, [data]);

    const getOtherComparedDataTable = () => {
        plainAxiosInstance
            .get(`${APIs.RC_PRICE_SETTINGS.GET_MID_PRICE_SETTING_LIST}?serverUno=${currSelectedServer}`)
            .then((res: any) => {
                if (res.status === 200) {
                    let newData: any = res.data.map((x: MidPriceSettingOtherComparedData) => ({
                        ...x,
                        newKey: `${x.serverUno}|${x.symbol}|${x.compServerUno}|${x.compSymbol}`,
                    }));
                    let tableData = newData.filter((x: MidPriceSettingOtherComparedData) => !x.isDeleted);
                    setOriData(newData);
                    setData(tableData);
                } else setData([]);
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => {
                ErrorMessageHandler("mid price setting - other compared data table", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                setOriData([]);
                setData([]);
            }))
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            getOtherComparedDataTable();
            setRunRefetchDataList(false);
        };
        return () => { };
    }, [runRefetchDataList]);

    useEffect(() => {
        if (rcPriceCompareOwnServers?.length > 0) {
            setCurrSelectedServer(rcPriceCompareOwnServers[0].serverUno);
            setRunRefetchDataList(true);
        };
        return () => { };
    }, []);

    useEffect(() => {
        if ((data.length !== oriData.length) || (differenceWith(oriData, data, isEqual).length !== 0)) {
            setAbleToResetSave(true);
        };
        return () => {
            setAbleToResetSave(false);
        };
    }, [data, oriData]);

    return (
        <div className="mid-price-setting-container">
            <div className="top-nav">
                <div className="left-filter">
                    <Select
                        showSearch
                        placeholder="Please select server"
                        defaultValue={rcPriceCompareOwnServers[0]?.serverUno || ""}
                        options={rcPriceCompareOwnServers?.map((x: any) => ({ label: x.name, value: x.serverUno })) || []}
                        filterOption={(input, option) => (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())}
                        style={{ width: 200 }}
                        onChange={(value: any) => {
                            setRunRefetchDataList(true);
                            setCurrSelectedServer(value);
                        }}
                        disabled={isLoading || isFetching}
                    />
                    <Tooltip title="Refresh servers">
                        <TimeDiff
                            timestamp={dataUpdatedAt}
                            refetch={refetchRcPriceCompareServers}
                            isFetching={isFetching}
                            styles={{
                                borderRadius: 16, marginLeft: "0.651vw"
                            }}
                        />
                    </Tooltip>
                </div>
                <div className="right-action" style={ableToResetSave ? {} : { display: "none" }}>
                    <Button icon={<UndoOutlined />} disabled={!ableToResetSave} loading={isResetting} onClick={() => {
                        setIsLoading(true);
                        setIsResetting(true);
                        setTimeout(() => {
                            setIsLoading(false);
                            setIsResetting(false);
                            setData(oriData);
                            setAbleToResetSave(false);
                        }, 1000);
                    }}>Reset Changes</Button>
                    <Button icon={<SaveOutlined />} type="primary" disabled={!ableToResetSave} loading={isSubmitting} onClick={() => onSaveAllData()}>Save</Button>
                </div>
            </div>
            <FlexiDataTable
                bordered
                rowKeyProperty="newKey"
                title={""}
                columns={columns}
                options={options}
                dataSource={data}
                callback={componentCallback}
                loading={isLoading}
            />
            <Modal
                width={700}
                title={`${currEdit === InnerPageActionMode.CREATE_NEW ? "Create New" : "Edit"} Mid Price Setting`}
                open={currEdit !== null}
                onOk={onFormSubmit}
                onCancel={() => {
                    setCurrEdit(null);
                    midPriceSettingForm.resetFields();
                }}
            >
                <Form
                    form={midPriceSettingForm}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    layout="horizontal"
                    initialValues={initialSetting}
                >
                    <Row>
                        <Col span={22}>
                            <FormComponent
                                label="Symbol"
                                name={"symbol"}
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                    inputProps: { disabled: currEdit !== InnerPageActionMode.CREATE_NEW },
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={22}>
                            <FormComponent
                                label="Competitor Server"
                                name={"compServerUno"}
                                extra={{
                                    type: ComponentType.dropdown,
                                    value: rcPriceCompareCompetitorServers
                                        ? rcPriceCompareCompetitorServers?.map((x: any) => ({
                                            text: x.name,
                                            value: x.serverUno
                                        }))
                                        : [],
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                    inputProps: { disabled: currEdit !== InnerPageActionMode.CREATE_NEW },
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={22}>
                            <FormComponent
                                label="Competitor Symbol"
                                name={"compSymbol"}
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                    inputProps: { disabled: currEdit !== InnerPageActionMode.CREATE_NEW },
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={22}>
                            <FormComponent
                                label="Expire Spread"
                                name={"exSpit"}
                                extra={{
                                    type: ComponentType.number,
                                    value: "",
                                    inputProps: { precision: 0 },
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={22}>
                            <FormComponent
                                label="Mid Price Times"
                                name={"midPriceTimes"}
                                extra={{
                                    type: ComponentType.number,
                                    value: "",
                                    inputProps: { precision: 0 },
                                }}
                            />
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    );
};
export default MidPriceSetting;