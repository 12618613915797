import { Button, Col, Descriptions, Form, Modal, Row, Upload } from "antd";
import { useParams } from "react-router-dom";
import TaggingSummary from "./AccountProfiles/Components/taggingSummary";
import PNLSymbolSummary from "./AccountProfiles/Components/pnlSymbolSummary";
import ExportToExcel, { ExportItem } from "../../components/ExportExcel";
import PNLSummaryChart from "./AccountProfiles/Components/pnlSummaryChart";
import CommentsHistory from "./AccountProfiles/Components/commentsHistory";
import AccountSummaryPanel from "./AccountProfiles/Components/accountSummaryPanel";
import AccountRiskScore from "./AccountProfiles/Components/accountRiskScore";
import DailyPnlSummaryChart from "./AccountProfiles/Components/dailyPnlSummary";
import BalanceEquityChart from "./AccountProfiles/Components/balanceEquity";
import {
    CloseOutlined,
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    FileAddOutlined,
    InfoCircleOutlined,
    MessageOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import { FormComponent } from "../../components/FormComponent";
import type { RcFile, UploadFile } from "antd/es/upload/interface";
import confirm from "antd/lib/modal/confirm";
import { ErrorCatchValidator, currencyRender, getGUID } from "../../utils/Common";
import { getAccountInfo } from "./AccountProfileHelper";
import { initialValues, ProfileInfoProps } from "./AccountProfiles/viewProfile";
import { apiRequest, APIs } from "../../services/apiConfig";
import LoadingComponent from "../../components/Loading";
import { ExportButtonProps, MetricGroup } from "../../constants/type";
import { ComponentType } from "../../constants";
import MarginLevelChart from "./AccountProfiles/Components/marginLevel";
import ActualLeverageChart from "./AccountProfiles/Components/actualLeverage";
import moment from "moment";

export interface canvasProps {
    xValue: number;
    yValue: number;
    width: number;
    height: number;
    currentPage: number;
    useHeight: number;
}

interface modalProps {
    isShow: boolean;
}

interface attachmentProps {
    id: string;
    src: string;
    description?: string;
}

const AccountProfileExportToPDF = () => {
    let { id } = useParams();

    const paramsInfo = getAccountInfo(id || "");

    const bottomElement = useRef(null);
    const [summary, setSummary] = useState<string>("");
    const [currentAttachment, setCurrentAttachment] = useState<attachmentProps | undefined>(undefined);
    const [attachments, setAttachments] = useState<attachmentProps[]>([]);
    const [commentModal, setCommentModal] = useState<modalProps>({ isShow: false });
    const [attachFileModal, setAttachFileModal] = useState<modalProps>({ isShow: false });
    const [commentForm] = Form.useForm();
    const [attachmentForm] = Form.useForm();
    const [accountInfo, setAccountInfo] = useState<ProfileInfoProps>(initialValues);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const extraButtons: ExportButtonProps[] = [
        {
            key: "attach",
            text: "Attach",
            render: function (config: any) {
                return (
                    <Button {...config} icon={<FileAddOutlined />} onClick={() => onButtonClickCallback("attach")}>
                        Attach
                    </Button>
                );
            },
        },
        { key: "summary", text: "Summary", icon: <MessageOutlined /> },
    ];

    const onButtonClickCallback = (key: string, data: any = "") => {
        switch (key) {
            case "summary":
                setCommentModal({ isShow: true });
                break;
            case "attach":
                setCurrentAttachment(undefined);
                attachmentForm.setFieldsValue({ id: "0", description: "" });
                setAttachFileModal({ isShow: true });
                break;
            case "edit-attachment":
                let foundIdx = attachments.findIndex((x) => x.id === (data as string));
                if (foundIdx > -1) {
                    const aItem = attachments[foundIdx];
                    setCurrentAttachment({ ...aItem });
                    attachmentForm.setFieldsValue({ id: aItem.id, description: aItem.description });
                    setAttachFileModal({ isShow: true });
                }
                break;
            case "remove-attachment":
                setAttachments([...attachments.filter((x) => x.id !== data)]);
                break;
        }
    };

    useEffect(() => {
        apiRequest(APIs.GET_ACCOUNT_PROFILE_LIST, {
            serverId: paramsInfo.serverId,
            accountId: paramsInfo.accountId,
            isOneOnly: true,
        })
            .then((data: any) => {
                setAccountInfo({ ...data.result[0], dateRangeFilter: paramsInfo.dateRangeFilter });
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => console.log("Failed to get account profile details: ", err));
            })
            .finally(() => setIsLoading(false));
        return () => {};
    }, []);

    return (
        <>
            <ExportToExcel
                extraButtons={extraButtons}
                onComponentCallback={onButtonClickCallback}
                exportFileName={() => `account analysis report - Server(${accountInfo.server}), Account(${accountInfo.accountId}).pdf`}
            >
                <ExportItem>
                    <div className="print-account-overview-container version-2">
                        <div>
                            <span className="title">Brand</span>
                            <span>{accountInfo.brand}</span>
                        </div>
                        <div>
                            <span className="title">Server</span>
                            <span>{accountInfo.server}</span>
                        </div>
                        <div>
                            <span className="title">Account ID</span>
                            <span>{accountInfo.accountId}</span>
                        </div>
                        {/* <div>
                            <span className="title">Account Type</span>
                            <span>{accountInfo.accountType}</span>
                        </div> */}
                        <div>
                            <span className="title">Group Name</span>
                            <span>{accountInfo.groupName}</span>
                        </div>
                        {/* {accountInfo.clientId !== "" && (
                            <div>
                                <span className="title">Client ID</span>
                                <span>{accountInfo.clientId}</span>
                            </div>
                        )} */}
                        <div>
                            <span className="title">Currency</span>
                            <span>{accountInfo.currency}</span>
                        </div>
                        <div>
                            <span className="title"></span>
                            <span></span>
                        </div>
                    </div>
                </ExportItem>
                {isLoading ? (
                    <div className="loading-container">
                        <LoadingComponent tip="Loading..." />
                    </div>
                ) : (
                    <>
                        {/* <ExportItem>
                            <AccountRiskScore {...accountInfo} forPrint />
                        </ExportItem> */}
                        <ExportItem>
                            <div className="print-additional-info-container">
                                <div>
                                    <span className="title">Information</span>
                                </div>
                                <Row>
                                    <Col span={accountInfo.isWatch ? 12 : 24} className={`left-section ${accountInfo.isWatch ? "watchlisted" : ""}`}>
                                        <div className="middle-div">
                                            <Row>
                                                <Col span={24} className="flex-column">
                                                    <span className="subtitle">Remark(s)</span>
                                                    <span>{accountInfo.labels?.join(", ") || "-"}</span>
                                                </Col>
                                            </Row>
                                        </div>
                                        {/* <div className="middle-div">
                                            <Row>
                                                <Col span={24} className="flex-column">
                                                    <span className="subtitle">Group Name</span>
                                                    <span>{accountInfo.groupName}</span>
                                                </Col>
                                            </Row>
                                        </div> */}
                                        <div className="flex-column">
                                            <Row>
                                                <Col span={8} className="flex-column">
                                                    <span className="subtitle">Client ID</span>
                                                    {accountInfo.clientId || "-"}
                                                </Col>
                                                <Col span={16} className="flex-column" style={{ paddingLeft: "0.9765vw" }}>
                                                    <span className="subtitle">Client Name</span>
                                                    <span>{accountInfo.clientName || "-"}</span>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="flex-column">
                                            <div className={`${accountInfo.isWatch ? "middle-div" : ""}`}>
                                                <Row>
                                                    <Col span={24} className="flex-column">
                                                        <span className="subtitle">Source</span>
                                                        <span>{`${accountInfo.crmServer || "-"}`}</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <div className="flex-column">
                                            <div className={`${accountInfo.isWatch ? "middle-div" : ""}`}>
                                                <Row>
                                                    <Col span={24} className="flex-column">
                                                        <span className="subtitle">Regulator</span>
                                                        <span>{`${accountInfo.regulatorName || "-"}`}</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </Col>
                                    {accountInfo.isWatch ? (
                                        <Col span={12} className="right-section">
                                            <div className="flex-column">
                                                <Row>
                                                    <Col span={24} className="flex-column">
                                                        <span className="subtitle">Watch List Group Name</span>
                                                        <span>{accountInfo.watchGroupName || "Uncategorized"}</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div className="flex-column">
                                                <Row style={{ marginTop: "0.707vh" }}>
                                                    <Col span={24} className="flex-column">
                                                        <span className="subtitle">Watch List Info</span>
                                                        <Descriptions
                                                            column={1}
                                                            size="small"
                                                            className="tiny-description"
                                                            labelStyle={{ minWidth: "6.306vw" }}
                                                            contentStyle={{ textAlign: "right", minWidth: "6.306vw", display: "block" }}
                                                        >
                                                            <Descriptions.Item label="Action Date">
                                                                {accountInfo?.accountWatchDetails?.actionDate
                                                                    ? moment(accountInfo.accountWatchDetails.actionDate).format("YYYY-MM-DD HH:mm:ss")
                                                                    : "-"}
                                                            </Descriptions.Item>
                                                            <Descriptions.Item label="PNL Captured">
                                                                {currencyRender(`${accountInfo?.accountWatchDetails?.pnlCaptured}`)}
                                                            </Descriptions.Item>
                                                            {/* <Descriptions.Item label="Past 7 days Cum PNL">
                                                {currencyRender(`${accountInfo?.accountWatchDetails?.pnlPass7Days}`)}
                                            </Descriptions.Item> */}
                                                            <Descriptions.Item label="Current Historical PNL">
                                                                {accountInfo?.accountWatchDetails?.pnlHistorical
                                                                    ? currencyRender(`${accountInfo?.accountWatchDetails?.pnlHistorical}`)
                                                                    : "-"}
                                                            </Descriptions.Item>
                                                            <Descriptions.Item label="PNL After Action">
                                                                {accountInfo?.accountWatchDetails?.pnlDifference
                                                                    ? currencyRender(`${accountInfo?.accountWatchDetails?.pnlDifference}`)
                                                                    : "-"}
                                                            </Descriptions.Item>
                                                            <Descriptions.Item label="Past 7 days Cum PNL">
                                                                {accountInfo?.accountWatchDetails?.pnlPass7Days
                                                                    ? currencyRender(`${accountInfo?.accountWatchDetails?.pnlPass7Days}`)
                                                                    : "-"}
                                                            </Descriptions.Item>
                                                            <Descriptions.Item label="Deposit Captured">
                                                                {accountInfo?.accountWatchDetails?.depositCaptured
                                                                    ? currencyRender(`${accountInfo?.accountWatchDetails?.depositCaptured}`)
                                                                    : "-"}
                                                            </Descriptions.Item>
                                                            <Descriptions.Item label="Current Deposit">
                                                                {accountInfo?.accountWatchDetails?.depositCurrent
                                                                    ? currencyRender(`${accountInfo?.accountWatchDetails?.depositCurrent}`)
                                                                    : "-"}
                                                            </Descriptions.Item>
                                                            <Descriptions.Item label="Withdrawal Captured">
                                                                {accountInfo?.accountWatchDetails?.withdrawalCaptured
                                                                    ? currencyRender(`${accountInfo?.accountWatchDetails?.withdrawalCaptured}`)
                                                                    : "-"}
                                                            </Descriptions.Item>
                                                            <Descriptions.Item label="Current Withdrawal">
                                                                {accountInfo?.accountWatchDetails?.withdrawalCurrent
                                                                    ? currencyRender(`${accountInfo?.accountWatchDetails?.withdrawalCurrent}`)
                                                                    : "-"}
                                                            </Descriptions.Item>
                                                            <Descriptions.Item label="PNL Breach (USD)">
                                                                {accountInfo?.accountWatchDetails?.pnlBreach
                                                                    ? currencyRender(`${accountInfo?.accountWatchDetails?.pnlBreach}`)
                                                                    : "-"}
                                                            </Descriptions.Item>
                                                            <Descriptions.Item label="Inspector">
                                                                {accountInfo?.accountWatchDetails?.assignee
                                                                    ? currencyRender(`${accountInfo?.accountWatchDetails?.assignee}`)
                                                                    : "-"}
                                                            </Descriptions.Item>
                                                        </Descriptions>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    ) : null}
                                </Row>
                                {accountInfo.isWatch ? (
                                    <Row>
                                        <Col span={24}>
                                            <div className="flex-column">
                                                <Row>
                                                    <Col span={24} className="flex-column">
                                                        <span className="subtitle">Watch List Comment</span>
                                                        <span>{`${accountInfo.accountWatchDetails?.comment || "-"}`}</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                ) : null}
                            </div>
                        </ExportItem>
                        {summary && summary.length > 0 && (
                            <ExportItem>
                                <div className="summary-panel">
                                    <div className="title">Summary</div>
                                    <div className="content" dangerouslySetInnerHTML={{ __html: summary }}></div>
                                </div>
                            </ExportItem>
                        )}
                        <ExportItem>
                            <CommentsHistory accountData={{ ...accountInfo }} forPrint />
                        </ExportItem>
                        <ExportItem>
                            <AccountSummaryPanel
                                accountData={{ ...accountInfo }}
                                metricCategories={[]}
                                currency={accountInfo.currency as string}
                                forPrint
                            />
                        </ExportItem>
                        <ExportItem>
                            <TaggingSummary {...accountInfo} forPrint />
                        </ExportItem>
                        <ExportItem>
                            <PNLSymbolSummary {...accountInfo} forPrint />
                        </ExportItem>
                        <PNLSymbolSummary
                            {...accountInfo}
                            pNLSymbolChartOnly
                            pNLSymbolChartOnlyOption={{
                                getIndex: [0, 1, 2],
                                customTemplate: (chart: React.ReactElement, index: number) => (
                                    <ExportItem key={`exp-pnl-line-c-${index}`}>{chart}</ExportItem>
                                ),
                            }}
                            forPrint
                        />

                        <ExportItem>
                            <PNLSummaryChart {...accountInfo} forPrint />
                        </ExportItem>
                        <ExportItem>
                            <DailyPnlSummaryChart {...accountInfo} forPrint />
                        </ExportItem>
                        <ExportItem>
                            <MarginLevelChart {...accountInfo} forPrint />
                        </ExportItem>
                        <ExportItem>
                            <ActualLeverageChart {...accountInfo} forPrint />
                        </ExportItem>
                        <ExportItem>
                            <BalanceEquityChart {...accountInfo} forPrint />
                        </ExportItem>
                        {attachments &&
                            attachments.length > 0 &&
                            attachments.map((x) => (
                                <ExportItem key={`attc-pnl-${x.id}`}>
                                    <div className="attachment-container">
                                        <div className="attachments-item-panel">
                                            <div className="image-panel">
                                                <img src={x.src} style={{ maxWidth: "100%" }} />
                                            </div>
                                            <div className="desc-panel">{x.description}</div>
                                        </div>
                                        <div className="remove-panel">
                                            <Button
                                                type="link"
                                                icon={<CloseOutlined style={{ fontSize: "1.5rem", color: "#ffffff" }} />}
                                                className="btn-remove"
                                                onClick={() => {
                                                    confirm({
                                                        icon: <ExclamationCircleOutlined />,
                                                        content: `Confirm to delete attachment?`,
                                                        onOk() {
                                                            onButtonClickCallback("remove-attachment", x.id);
                                                        },
                                                        onCancel() {
                                                            console.log("Cancel");
                                                        },
                                                    });
                                                }}
                                            />

                                            <Button
                                                type="link"
                                                icon={<EditOutlined style={{ fontSize: "1.5rem", color: "#ffffff" }} />}
                                                className="btn-edit"
                                                onClick={() => onButtonClickCallback("edit-attachment", x.id)}
                                            />
                                        </div>
                                    </div>
                                </ExportItem>
                            ))}
                        <div ref={bottomElement}></div>
                    </>
                )}
            </ExportToExcel>
            <Modal
                width={700}
                open={commentModal.isShow}
                title={"Add Summary"}
                onCancel={() => setCommentModal({ isShow: false })}
                onOk={() => {
                    commentForm
                        .validateFields()
                        .then((values: any) => {
                            let summary_arr: string[] = values.summary.split("\n");
                            setSummary(summary_arr.join("<br />"));
                            setCommentModal({ isShow: false });
                        })
                        .catch((info: any) => {
                            console.log("Validate Failed:", info);
                        });
                }}
            >
                <Form form={commentForm} layout="vertical">
                    <FormComponent
                        label={""}
                        name={"summary"}
                        extra={{
                            type: ComponentType.textarea,
                            value: "",
                            inputProps: {
                                rows: 10,
                            },
                        }}
                    />
                </Form>
            </Modal>
            <Modal
                width={"32.55vw"}
                open={attachFileModal.isShow}
                title={"Add Attachments"}
                onCancel={() => setAttachFileModal({ isShow: false })}
                onOk={() => {
                    attachmentForm
                        .validateFields()
                        .then((values: any) => {
                            if (values.id === "0") {
                                setAttachments([
                                    ...attachments,
                                    { id: currentAttachment?.id, src: currentAttachment?.src, description: values.description } as attachmentProps,
                                ]);
                            } else {
                                let list = [...attachments];
                                let foundIdx = list.findIndex((x) => x.id === values.id.toString());
                                if (foundIdx > -1) {
                                    list[foundIdx] = {
                                        id: values.id,
                                        src: currentAttachment?.src,
                                        description: values.description,
                                    } as attachmentProps;
                                    setAttachments(list);
                                }
                            }
                            setAttachFileModal({ isShow: false });
                            setTimeout(
                                () =>
                                    bottomElement !== null &&
                                    (bottomElement.current as unknown as HTMLElement).scrollIntoView({ behavior: "smooth" }),
                                800
                            );
                        })
                        .catch((info: any) => {
                            console.log("Validate Failed:", info);
                        });
                }}
            >
                <div className="uploaded-panel">
                    {!currentAttachment && (
                        <Upload
                            style={{ textAlign: "center" }}
                            showUploadList={false}
                            listType="picture-card"
                            beforeUpload={async (file: UploadFile) => {
                                let src: string = await new Promise((resolve) => {
                                    let tmpFile = file as RcFile;
                                    let url = URL.createObjectURL(tmpFile.slice(0, tmpFile.size, tmpFile.type));
                                    resolve(url);
                                });
                                setCurrentAttachment({ id: `${getGUID()}`, src: src as string, description: "" });
                                return false;
                            }}
                        >
                            <div>
                                <PlusOutlined />
                                <div style={{ marginTop: 8 }}>Upload</div>
                            </div>
                        </Upload>
                    )}
                    {currentAttachment && (
                        <>
                            <div className="temp-image-panel">
                                <div className="image-panel">
                                    <img src={currentAttachment.src} className="img-clss" />
                                </div>
                                <div className="remove-overlay">
                                    <Button
                                        type="link"
                                        icon={<DeleteOutlined style={{ fontSize: "2.5rem", color: "#ffffff" }} />}
                                        className="btn-remove"
                                        onClick={() => {
                                            setCurrentAttachment(undefined);
                                        }}
                                    />
                                </div>
                            </div>
                            <Form form={attachmentForm} layout="vertical" style={{ width: "100%" }}>
                                <FormComponent label={""} name={"id"} extra={{ type: ComponentType.hidden, value: "" }} />
                                <FormComponent
                                    label={""}
                                    name={"description"}
                                    extra={{
                                        type: ComponentType.textarea,
                                        value: "",
                                        inputProps: {
                                            rows: 4,
                                            placeholder: "Leave your comment",
                                        },
                                    }}
                                />
                            </Form>
                        </>
                    )}
                </div>
            </Modal>
        </>
    );
};

export default AccountProfileExportToPDF;
