import { currencyRender } from "./Common";

export const isEmptyOrNull = (value: any) => {
    return value === undefined || value === null || value === "" || (Array.isArray(value) ? value.length === 0 : false);
};

export const defaultIfEmptyOrNull = (value: any, defaultValue: any) => {
    return isEmptyOrNull(value) ? defaultValue : value;
};

export const caseInsensitiveCompare = (value: any, target: any, contains: boolean = false) =>
    contains ? `${value}`.toLowerCase().indexOf(`${target}`.toLowerCase()) > -1 : `${value}`.toLowerCase() === `${target}`.toLowerCase();

export const isNumeric = (value: string): boolean => !isNaN(parseFloat(value)) && isFinite(Number(value));

export interface getDisplayTextNumberProps {
    decimalPoint: number;
}
export interface getDisplayTextBooleanProps {
    booleanText: string[]; // 0 - False, 1 - True
}

export interface getDisplayTextStringProps {
    seperator: string | undefined;
}

export const getStandardMarkupDisplayText = (
    value: any,
    numberProps: getDisplayTextNumberProps = { decimalPoint: -1 },
    booleanProps: getDisplayTextBooleanProps = { booleanText: ["False", "True"] }
) => {
    let tmpValue = (typeof value !== "string" ? `${value}` : value).toLowerCase();
    if (["true", "false"].includes(tmpValue)) {
        return ["true", "1"].includes(tmpValue) ? booleanProps.booleanText[1] : booleanProps.booleanText[0];
    } else if (!isNaN(parseFloat(tmpValue)) && isFinite(parseFloat(tmpValue))) {
        return currencyRender(tmpValue, numberProps.decimalPoint);
    }
    return tmpValue;
};

export const convertValueBasedOnDataType = (dataType: number, value: any): number | string | boolean => {
    switch (dataType) {
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
            //number
            try {
                return parseFloat(value);
            } catch (error) {}
            break;
        case 6:
            //boolean
            let tmp = `${value}`.toLowerCase();
            if (["true", "false", "1", "0"].includes(tmp)) {
                return ["true", "1"].includes(tmp) ? true : false;
            }
            break;
        default:
            //string;
            return isEmptyOrNull(value) ? "" : `${value}`;
    }

    return value;
};
