import React from "react";
import { mapIssueLogBackToEnum, useRCIssuesLogHistory, useRCIssuesLogMainServers, useRCIssuesLogMT5History } from "@/hooks/useRCIssuesLog";
import type { RCIssueLogType } from "../../../hooks/useRCIssuesLog";
import { Button, Col, DatePicker, Row, Space, Table, Tag, Typography, notification } from "antd";
import MessageCard from "../components/MessageCard";
import moment from "moment";
import { plainAxiosInstance } from "../../../services/axiosSetup";
import { APIs } from "../../../services/apis";
import { capsToTitleCase, colorMap } from "../StatusMonitor/statusUtils";
import BasicFilter from "../../../components/BasicFilter";
import useRCIssuesLogStore from "../../../store/useRCIssuesLogStore";

const columns = [
    {
        title: "Server",
        dataIndex: "server",
        sorter: (a: any, b: any) => {
            // sort alphabetically and length
            if (a.server?.toLowerCase() < b.server?.toLowerCase()) {
                return -1;
            }
            if (a.server?.toLowerCase() > b.server?.toLowerCase()) {
                return 1;
            }
            return 0;
        },
    },
    {
        title: "Date Time",
        dataIndex: "time",
        // data example "2024-05-23 14:22:58.835000"
        sorter: (a: any, b: any) => {
            return moment(a.time).unix() - moment(b.time).unix();
        },
        render: (text: any) => {
            const date = moment(text).format("YYYY-MM-DD");
            const time = moment(text).format("HH:mm:ss.SSSSSS");
            return (
                <Space direction="vertical" size={2}>
                    <Tag color="blue">{date}</Tag>
                    <Tag>{time}</Tag>
                </Space>
            );
        },
    },
    {
        title: "IP",
        dataIndex: "ip",
        sorter: (a: any, b: any) => {
            // sort alphabetically and length
            if (a.ip?.toLowerCase() < b.ip?.toLowerCase()) {
                return -1;
            }
            if (a.ip?.toLowerCase() > b.ip?.toLowerCase()) {
                return 1;
            }
            return 0;
        },
    },
    {
        title: "Message",
        dataIndex: "msg",
        sorter: (a: any, b: any) => {
            // sort alphabetically and length
            if (a.msg?.toLowerCase() < b.msg?.toLowerCase()) {
                return -1;
            }
            if (a.msg?.toLowerCase() > b.msg?.toLowerCase()) {
                return 1;
            }
            return 0;
        },
    },
    {
        title: "Checked User",
        dataIndex: "checkUser",
        sorter: (a: any, b: any) => {
            // sort alphabetically and length
            if (a.checkUser?.toLowerCase() < b.checkUser?.toLowerCase()) {
                return -1;
            }
            if (a.checkUser?.toLowerCase() > b.checkUser?.toLowerCase()) {
                return 1;
            }
            return 0;
        },
    },
    {
        title: "Checked Date Time",
        dataIndex: "checkTime",
        // data example 2024-05-23T14:31:54
        sorter: (a: any, b: any) => {
            return moment(a.checkTime).unix() - moment(b.checkTime).unix();
        },
        render: (text: any) => {
            const date = moment(text).format("YYYY-MM-DD");
            const time = moment(text).format("HH:mm:ss");
            return (
                <Space direction="vertical" size={2}>
                    <Tag color="blue">{date}</Tag>
                    <Tag>{time}</Tag>
                </Space>
            );
        },
    },
    {
        title: "Comments",
        dataIndex: "comment",
        sorter: (a: any, b: any) => {
            // sort alphabetically and length
            if (a.comment?.toLowerCase() < b.comment?.toLowerCase()) {
                return -1;
            }
            if (a.comment?.toLowerCase() > b.comment?.toLowerCase()) {
                return 1;
            }
            return 0;
        },
    },
];

const CommonIssuesLogHistoryTable = ({ type }: { type: RCIssueLogType }) => {
    const typeNumber = mapIssueLogBackToEnum(type);

    const { rcIssuesLogMainServers, isLoading: isLoadingMainServers } = useRCIssuesLogMainServers();

    const columnsMT5_ORDER_TYPE = [
        {
            title: "Server",
            dataIndex: "serverUno",
            render: (serverUno: any) => {
                const server = rcIssuesLogMainServers?.find(x => String(x.oldServerId) === String(serverUno));
                return server ? server.serverName : serverUno;
            },
        },
        {
            title: "Order ID",
            dataIndex: "orderId",
        },
        {
            title: "Symbol",
            dataIndex: "symbol",
        },
        {
            title: "Type",
            dataIndex: "type",
            render: (text: any, checkState: any) => {
                const { type, state } = checkState;
                if (state === "1") {
                    if (type === "0") {
                        return "Buy";
                    } else if (type === "1") {
                        return "Sell";
                    }
                }
                return "Any";
            },
        },
        {
            title: "Open Time",
            dataIndex: "openTime",
            render: (text: any) => moment(text).format("YYYY-MM-DD HH:mm:ss"),
        },
        {
            title: "Volume",
            dataIndex: "volume",
        },
        {
            title: "State",
            dataIndex: "state",
            render: (state: any) => {
                switch (state) {
                    case "0":
                        return "ORDER_STATE_STARTED";
                    case "1":
                        return "ORDER_STATE_PLACED";
                    case "7":
                        return "ORDER_STATE_REQUEST_ADD";
                    case "8":
                        return "ORDER_STATE_REQUEST_MODIFY";
                    case "9":
                        return "ORDER_STATE_REQUEST_CANCEL";
                    default:
                        return "UNKNOWN_STATE";
                }
            },
        },
        {
            title: "First Time",
            dataIndex: "firstTime",
            render: (text: any) => moment(text).format("YYYY-MM-DD HH:mm:ss"),
        },
        {
            title: "Last Time",
            dataIndex: "lastTime",
            render: (dateTime: any) => (dateTime ? moment(dateTime).format("YYYY-MM-DD HH:mm:ss") : "-"),
        },
    ];

    const { RangePicker } = DatePicker;
    // store filter to global state
    const filter = useRCIssuesLogStore(
        state =>
            state.historyStore[type] ?? {
                start: moment(moment().subtract(1, "days").startOf("day")).format("YYYY-MM-DD"),
                end: moment().format("YYYY-MM-DD"),
            }
    );
    const setFilter = useRCIssuesLogStore(state => state.updateHistoryStore);
    const { rcIssuesLogHistory, rcIssuesLogHistoryError, isLoading } = useRCIssuesLogHistory({
        logType: type,
        startDate: filter.start ?? moment().subtract(1, "days").startOf("day").format("YYYY-MM-DD"),
        endDate: filter.end ?? moment().format("YYYY-MM-DD"),
    });
    const {
        rcIssuesLogMT5,
        rcIssuesLogMT5Error,
        isLoading: isLoadingMT5,
    } = useRCIssuesLogMT5History({
        start: type === "MT5_ORDER_TYPE" ? filter.start ?? moment().subtract(1, "days").startOf("day").format("YYYY-MM-DD") : null,
        end: type === "MT5_ORDER_TYPE" ? filter.end ?? moment().subtract(1, "days").startOf("day").format("YYYY-MM-DD") : null,
    });

    const { Text, Title } = Typography;

    const massagedData =
        type === "MT5_ORDER_TYPE"
            ? rcIssuesLogMT5 && rcIssuesLogMT5?.length >= 1
                ? rcIssuesLogMT5?.map((item: any, index: number) => {
                    return {
                        ...item,
                        key: item.id,
                    };
                })
                : []
            : rcIssuesLogHistory && rcIssuesLogHistory?.length >= 1
                ? rcIssuesLogHistory?.map((item: any, index: number) => {
                    return {
                        ...item,
                        key: item.id,
                    };
                })
                : [];

    const handleDownload = async () => {
        if (type === "MT5_ORDER_TYPE") {
            plainAxiosInstance
                .post(
                    `${APIs.RC_ISSUES_LOG.POST_ISSUES_LOG_MT5_DOWNLOAD}`,
                    {
                        startTime: filter.start,
                        endTime: filter.end,
                    },
                    {
                        headers: {
                            Accept: "application/octet-stream,text/csv, */*",
                        },
                        responseType: "blob",
                    }
                )
                .then(response => {
                    // console.log(response);
                    // Check if the response content type is 'application/octet-stream'
                    const contentType = response.headers["content-type"];
                    if (
                        contentType === "application/octet-stream" ||
                        contentType === "text/csv" ||
                        contentType === "text/csv;charset=UTF-8" ||
                        contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    ) {
                        // Handle the file download response
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", `issuelog_history_${type}.csv`);
                        document.body.appendChild(link);
                        link.click();
                        // Clean up
                        window.URL.revokeObjectURL(url);
                        notification.success({
                            message: "Downloaded",
                            description: `${type} History downloaded successfully`,
                        });
                    } else {
                        notification.error({
                            message: "Error",
                            description: `Received non-file response. Error: ${response}`,
                        });
                        console.log("Received non-file response:", response);
                    }
                })
                .catch(err => {
                    notification.error({
                        message: "Error",
                        description: `Download error: ${err}`,
                    });
                    console.log("download error", err);
                });
        } else {
            plainAxiosInstance
                .get(`${APIs.RC_ISSUES_LOG.GET_DOWNLOAD_ISSUES_LOG}/${typeNumber}?startDate=${filter.start}&endDate=${filter.end}`, {
                    headers: {
                        Accept: "application/octet-stream,text/csv, */*",
                    },
                    responseType: "blob",
                })
                .then(response => {
                    // console.log(response);
                    // Check if the response content type is 'application/octet-stream'
                    const contentType = response.headers["content-type"];
                    if (
                        contentType === "application/octet-stream" ||
                        contentType === "text/csv" ||
                        contentType === "text/csv;charset=UTF-8" ||
                        contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    ) {
                        // Handle the file download response
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", `issuelog_history_${type}.csv`);
                        document.body.appendChild(link);
                        link.click();
                        // Clean up
                        window.URL.revokeObjectURL(url);
                        notification.success({
                            message: "Downloaded",
                            description: `${type} History downloaded successfully`,
                        });
                    } else {
                        notification.error({
                            message: "Error",
                            description: `Received non-file response. Error: ${response}`,
                        });
                        console.log("Received non-file response:", response);
                    }
                })
                .catch(err => {
                    notification.error({
                        message: "Error",
                        description: `Download error: ${err}`,
                    });
                    console.log("download error", err);
                });
        }
    };

    return (
        <>
            <BasicFilter>
                <Row gutter={[8, 8]}>
                    <Col span={16} xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 8 }}>
                        <Text>Date Range *</Text>
                        <RangePicker
                            style={{ width: "100%" }}
                            format="YYYY-MM-DD"
                            onChange={(dates, dateStrings) => {
                                // console.log(dateStrings);
                                // console.log("updated");
                                if (dateStrings?.[0] && dateStrings?.[1]) {
                                    setFilter({ [type]: { start: dateStrings[0], end: dateStrings[1] } });
                                }
                            }}
                            // disallow user to clear date
                            allowClear={false}
                            // disable selecting the day after today, and 20 days before today
                            disabledDate={current => {
                                return (
                                    current && current > moment().endOf("day")
                                    //    || current < moment().subtract(20, "days").startOf("day")
                                );
                            }}
                            // read start and end from store
                            value={[moment(filter.start), moment(filter.end)]}
                        />
                    </Col>
                </Row>
            </BasicFilter>
            <Space direction="vertical" style={{ width: "100%", padding: "1rem" }}>
                {type === "MT5_ORDER_TYPE" && (
                    <>
                        {isLoadingMT5 ? (
                            <MessageCard type="info">Loading...</MessageCard>
                        ) : rcIssuesLogMT5Error ? (
                            <MessageCard type="error">{rcIssuesLogMT5Error}</MessageCard>
                        ) : massagedData?.length === 0 ? (
                            <MessageCard type="info">
                                No record from <span style={{ color: colorMap.primary }}>{filter.start}</span> to{" "}
                                <span style={{ color: colorMap.primary }}>{filter.end}</span>
                            </MessageCard>
                        ) : (
                            <>
                                <Title level={5}>{capsToTitleCase(type)} History</Title>
                                <MessageCard type={"info"}>
                                    {massagedData?.length} record{massagedData?.length >= 2 ? "s" : ""}
                                </MessageCard>
                                <Space style={{ width: "100%" }}>
                                    <Button disabled={massagedData?.length <= 0} onClick={() => handleDownload()}>
                                        Download
                                    </Button>
                                </Space>
                                <Table
                                    columns={columnsMT5_ORDER_TYPE}
                                    dataSource={massagedData}
                                    size="small"
                                    pagination={{ defaultPageSize: 50, pageSizeOptions: ["10", "20", "50", "100", "500", "1000"] }}
                                // scroll={{ y: 500 }}
                                />
                            </>
                        )}
                    </>
                )}
                {type !== "MT5_ORDER_TYPE" && (
                    <>
                        {isLoading || isLoadingMainServers ? (
                            <MessageCard type="info">Loading...</MessageCard>
                        ) : rcIssuesLogHistoryError ? (
                            <MessageCard type="error">{rcIssuesLogHistoryError}</MessageCard>
                        ) : massagedData?.length === 0 ? (
                            <MessageCard type="info">
                                No record from <span style={{ color: colorMap.primary }}>{filter.start}</span> to{" "}
                                <span style={{ color: colorMap.primary }}>{filter.end}</span>
                            </MessageCard>
                        ) : (
                            <>
                                <Title level={5}>{capsToTitleCase(type)} History</Title>
                                <MessageCard type={"info"}>
                                    {massagedData?.length} record{massagedData?.length >= 2 ? "s" : ""}
                                </MessageCard>
                                <Space style={{ width: "100%" }}>
                                    <Button disabled={massagedData?.length <= 0} onClick={() => handleDownload()}>
                                        Download
                                    </Button>
                                </Space>
                                <Table
                                    columns={columns}
                                    dataSource={massagedData}
                                    size="small"
                                    pagination={{ defaultPageSize: 50 }}
                                // scroll={{ y: 500 }}
                                />
                            </>
                        )}
                    </>
                )}
            </Space>
        </>
    );
};

export default CommonIssuesLogHistoryTable;
