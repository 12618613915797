import { FormComponent } from "@/components/FormComponent";
import { ComponentType, SUCCESS_FAILED } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Col, Form, Modal, Row, Tag } from "antd";
import { CompareItem } from "../index";

type CompareItemsProps = {
    open: boolean;
    onModalCancel: () => void;
    onModalOk: () => void;
    compareItems: CompareItem[];
};

const SaveCompareSetModal = ({ open, onModalCancel, onModalOk, compareItems }: CompareItemsProps) => {
    const [readOnlyForm] = Form.useForm();

    function onCancelForm() {
        readOnlyForm.resetFields();
        onModalCancel();
    }

    async function onSubmitForm() {
        return new Promise<void>((resolve, reject) => {
            readOnlyForm
                .validateFields()
                .then(values => {
                    const setName = values.setName;
                    const param = compareItems.map((item: CompareItem) => {
                        return {
                            compareSymbol: item.compareSymbol,
                            compareServerName: item.compareServerName,
                            compareServerUno: item.compareServer,
                            setName: setName,
                        };
                    });
                    plainAxiosInstance
                        .post(APIs.RC_LP_PRICE_COMPARE.POST_SAVE_COMPARE_SET, param)
                        .then((res: any) => {
                            ErrorMessageHandler("Compare Set", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                            resolve();
                            onModalOk();
                            readOnlyForm.resetFields();
                        })
                        .catch((error: any) => {
                            reject(error);
                        });
                })
                .catch(error => {
                    const err = { message: "Invalid form data" };
                    reject(err);
                });
        }).catch((error) => {
          ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("Compare Set", SUCCESS_FAILED.FAILED_CREATE_DATA, err));
        });
    }

    function onOkConfirm() {
      Modal.confirm({
          icon: <ExclamationCircleOutlined />,
          title: `Are you sure you want to create Compare Set?`,
          width: "30%",
          onOk() {
              return onSubmitForm();
          },
          onCancel() {},
      });
  }

    return (
        <Modal width="50vw" destroyOnClose maskClosable={false} title={"Create Compare Set"} open={open} onCancel={onCancelForm} onOk={onOkConfirm}>
            <Form form={readOnlyForm} layout="horizontal" labelCol={{ span: 7 }} wrapperCol={{ span: 14 }}>
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Compare Set Name"
                            name="setName"
                            extra={{
                                type: ComponentType.text,
                                value: "",
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                  <Col style={{padding: '16px', display: 'flex', flexWrap: 'wrap', gap: '6px 4px'}}>
                    {
                      compareItems.map((item, index) => (
                        <Tag key={index}>{`${item.compareServerName}-${item.compareSymbol}`}</Tag>
                      ))
                    }
                  </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default SaveCompareSetModal;
