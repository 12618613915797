import { useCallback, useEffect, useMemo, useState } from "react";
import FlexiDataTable from "@/components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "@/constants";
import { FlexiDataTableCallbackProps, FlexiDataTableOptionsProps } from "@/constants/type";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { Button, Col, Form, message, Modal, Row, Table, Upload } from "antd";
import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import { FormComponent } from "@/components/FormComponent";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { APIs } from "@/services/apis";
import { CidLabelSettingsList } from "./components/type";
import moment from "moment";

interface LabelSettingProps {
    refetchLabelsList: (isListUpdated: boolean) => void;
    labelsList: CidLabelSettingsList[];
};

const LabelSetting = (props: LabelSettingProps) => {
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.CID_EDIT);

    const [data, setData] = useState<CidLabelSettingsList[]>(props.labelsList);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isExporting, setIsExporting] = useState<boolean>(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [labelSettingForm] = Form.useForm();
    const [isAddAction, setIsAddAction] = useState<boolean>(true);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
    const [selectedLabels, setSelectedLabels] = useState<CidLabelSettingsList[]>([]);

    const columns = [
        DTColProps.Middle({
            title: "Label ID",
            dataIndex: "labelId",
            key: "labelId",
            sorter: (a: any, b: any) => Number(a.labelId) - Number(b.labelId),
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.Large({
            title: "Label Name",
            dataIndex: "labelName",
            key: "labelName",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
    ];

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                setIsModalOpen(true);
                setIsAddAction(true);
                break;
            case CALLBACK_KEY.DO_EDIT:
                setIsModalOpen(true);
                setIsAddAction(false);
                labelSettingForm.setFieldsValue(FormData);
                break;
            case CALLBACK_KEY.EXPORT_CSV_EXCEL:
                downloadLabelSetting();
                break;
            case CALLBACK_KEY.ROW_SELECTION_CALLBACK:
                setSelectedRowKeys(FormData.selectedRowKeys);
                break;
            case CALLBACK_KEY.ON_SELECT_TABLE_ROW_CALLBACK:
                setSelectedLabels(FormData.selectedRows);
                break;
            case CALLBACK_KEY.OTHERS:
                if (FormData === "batch-delete") {
                    batchDeleteLabelSettings();
                };
                break;
            case CALLBACK_KEY.DO_DELETE:
                setIsLoading(true);
                plainAxiosInstance
                    .delete(`${APIs.RC_CID.DELETE_BATCH_LABEL_SETTING}?id=${FormData.labelId}`)
                    .then((res: any) => {
                        if (res.data === 0) {
                            ErrorMessageHandler("Label setting", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                            props.refetchLabelsList(true);
                            setRunRefetchDataList(true);
                            setSelectedRowKeys([]);
                            setSelectedLabels([]);
                        } else {
                            ErrorMessageHandler("label setting", SUCCESS_FAILED.FAILED_DELETE_DATA);
                            setIsLoading(false);
                        };
                    })
                    .catch((error: any) => ErrorCatchValidator(error, (err: any) => {
                        ErrorMessageHandler("label setting", SUCCESS_FAILED.FAILED_DELETE_DATA, err);
                        setIsLoading(false);
                    }));
                break;
            default:
                break;
        };
    };

    const options: FlexiDataTableOptionsProps = useMemo(() => {
        return {
            export: { text: "Download" },
            add: enableUpdate,
            edit: enableUpdate,
            delete: enableUpdate,
            separateActionButton: true,
            enableRowSelection: true,
            hideRowSelectionsSummary: true,
            rowSelectionData: {
                rowSelectionType: "checkbox",
                selectedRowKeys: selectedRowKeys,
                options: {
                    fixed: "left",
                    selections: [
                        Table.SELECTION_ALL,
                        Table.SELECTION_NONE,
                    ],
                    preserveSelectedRowKeys: true,
                },
            },
            extraButtons: () => {
                if (enableUpdate) return (
                    <div className="extra-header-buttons" key={"cid-ls-extra-buttons"}>
                        <Button
                            key={"cid-ls-batch-delete"}
                            icon={<DeleteOutlined />}
                            onClick={() => componentCallback(CALLBACK_KEY.OTHERS, "batch-delete")}
                            disabled={selectedRowKeys.length === 0}
                        >
                            Batch Delete
                        </Button>
                        <Upload
                            key={"cid-ls-batch-upload"}
                            showUploadList={false}
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            beforeUpload={(file => {
                                return new Promise((resolve, reject) => {
                                    uploadLabelSettings(file);
                                    reject();
                                })
                            })}
                        >
                            <Button icon={<UploadOutlined />}>Upload Excel</Button>
                        </Upload>
                    </div >
                )
                else return null;
            },
        }
    }, [enableUpdate, selectedRowKeys]);

    const onFormSubmit = (obj: any) => {
        setIsBtnLoading(true);
        if (isAddAction) {
            plainAxiosInstance
                .post(`${APIs.RC_CID.POST_CREATE_LABEL_SETTING}`, obj)
                .then((res: any) => {
                    if (res.data > 0) {
                        ErrorMessageHandler("Label setting", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                        props.refetchLabelsList(true);
                        setIsModalOpen(false);
                        setRunRefetchDataList(true);
                        labelSettingForm.resetFields();
                    } else {
                        message.error("Failed to create label setting due to duplication of existing data.", 3);
                    };
                })
                .catch((error: any) => ErrorCatchValidator(error, (err: any) => {
                    ErrorMessageHandler("label setting", SUCCESS_FAILED.FAILED_CREATE_DATA, err);
                }))
                .finally(() => setIsBtnLoading(false));
        } else {
            plainAxiosInstance
                .post(`${APIs.RC_CID.POST_UPDATE_LABEL_SETTING}`, obj)
                .then((res: any) => {
                    if (res.data > 0) {
                        ErrorMessageHandler("Label setting", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                        props.refetchLabelsList(true);
                        setIsModalOpen(false);
                        setRunRefetchDataList(true);
                        labelSettingForm.resetFields();
                    } else {
                        message.error("Failed to update label setting due to duplication of existing label name.", 3);
                    };
                })
                .catch((error: any) => ErrorCatchValidator(error, (err: any) => {
                    ErrorMessageHandler("label setting", SUCCESS_FAILED.FAILED_UPDATE_DATA, err);
                }))
                .finally(() => setIsBtnLoading(false));
        };
    };

    const downloadLabelSetting = () => {
        setIsExporting(true);
        plainAxiosInstance
            .get(`${APIs.RC_CID.GET_LABEL_SETTINGS_DOWNLOAD}`, {
                headers: { Accept: "application/octet-stream, */*" },
                responseType: "blob",
            })
            .then(res => {
                const fileName = `Cid_label_setting-${moment().format("YYYYMMDD_HHmmss")}.xlsx`;
                const contentType = res.headers["content-type"];
                if (
                    contentType === "application/octet-stream" ||
                    contentType === "text/csv" ||
                    contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ) {
                    // Handle the file download response
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", (`${fileName}`)); // or any other extension
                    link.setAttribute("type", "hidden");
                    document.body.appendChild(link);
                    link.click();
                    if (link.parentNode) {
                        link.parentNode.removeChild(link); // Clean up and remove the link
                    } else document.body.removeChild(link);
                    // Clean up
                    window.URL.revokeObjectURL(url);
                } else {
                    message.error(`Received non-file response. Error: ${res}`, 3);
                }
            })
            .catch(err => {
                message.error(`Download error: ${err}`, 3);
            })
            .finally(() => setIsExporting(false));
    };

    const getLabelSettingList = () => {
        plainAxiosInstance
            .get(`${APIs.RC_CID.GET_LABEL_SETTINGS}`)
            .then((res: any) => {
                if (res.status === 200) {
                    setData(res.data);
                } else setData([]);
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => {
                ErrorMessageHandler("label settings", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                setData([]);
            }))
            .finally(() => setIsLoading(false));
    };

    const batchDeleteLabelSettings = useCallback(() => {
        Modal.confirm({
            title: "Are you sure you want to delete these label settings?",
            content: <ul>
                {selectedLabels.map((x: CidLabelSettingsList) => (
                    <li key={x.labelId}>{x.labelName}</li>
                ))}
            </ul>,
            width: "30%",
            okText: "Confirm",
            onOk: () => {
                setIsLoading(true);
                plainAxiosInstance
                    .delete(`${APIs.RC_CID.DELETE_BATCH_LABEL_SETTING}?id=${selectedRowKeys.join(",")}`)
                    .then((res: any) => {
                        if (res.data === 0) {
                            ErrorMessageHandler("Label setting", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                            props.refetchLabelsList(true);
                            setRunRefetchDataList(true);
                            setSelectedRowKeys([]);
                            setSelectedLabels([]);
                        } else {
                            ErrorMessageHandler("label settings", SUCCESS_FAILED.FAILED_DELETE_DATA);
                            setIsLoading(false);
                        };
                    })
                    .catch((error: any) => ErrorCatchValidator(error, (err: any) => {
                        ErrorMessageHandler("label settings", SUCCESS_FAILED.FAILED_DELETE_DATA, err);
                        setIsLoading(false);
                    }));
            },
        });
    }, [selectedLabels, selectedRowKeys]);

    const uploadLabelSettings = (file: any) => {
        Modal.confirm({
            title: "Are you sure you want to upload?",
            width: "30%",
            okText: "Confirm",
            onOk: () => {
                setIsLoading(true);
                const formData = new FormData();
                formData.append("uploadFile", file);
                plainAxiosInstance
                    .post(`${APIs.RC_CID.POST_UPLOAD_LABEL_SETTING}`, formData)
                    .then((res: any) => {
                        if (res.status === 200) {
                            if (res.data === 0) {
                                ErrorMessageHandler("Label setting", SUCCESS_FAILED.SUCCESS_UPLOAD_DATA);
                                props.refetchLabelsList(true);
                                setRunRefetchDataList(true);
                            } else {
                                message.error("Failed to upload label setting: " + res.data, 3);
                                setIsLoading(false);
                            };
                        };
                    })
                    .catch((error: any) => ErrorCatchValidator(error, (err: any) => {
                        ErrorMessageHandler("label settings", SUCCESS_FAILED.FAILED_UPLOAD_DATA, err);
                        setIsLoading(false);
                    }));
            },
        });
    };

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            getLabelSettingList();
            setRunRefetchDataList(false);
        };
        return () => { };
    }, [runRefetchDataList]);

    return (
        <div className="label-setting-tab">
            <FlexiDataTable
                rowKeyProperty={"labelId"}
                title={false}
                columns={columns}
                options={options}
                callback={componentCallback}
                dataSource={data}
                loading={isLoading}
                exporting={isExporting}
            />
            <Modal
                width={700}
                title={`${isAddAction ? "Create New" : "Edit"} Label Setting`}
                open={isModalOpen}
                maskClosable={false}
                onCancel={() => setIsModalOpen(false)}
                footer={[
                    <Button key={"cancel"} onClick={() => {
                        labelSettingForm.resetFields();
                        setIsModalOpen(false);
                    }}>
                        Cancel
                    </Button>,
                    <Button key={"regular-submit"} type="primary" htmlType="submit" loading={isBtnLoading} onClick={() => {
                        labelSettingForm
                            .validateFields()
                            .then((res) => {
                                onFormSubmit(res);
                            })
                            .catch((err) => {
                                message.error("Failed to submit form. Please check the form for errors.");
                                // console.log("form-error:", err);
                            });
                    }}>
                        Submit
                    </Button>,
                ]}
            >
                <Form
                    form={labelSettingForm}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    layout="horizontal"
                    initialValues={{}}
                >
                    <Row>
                        <Col span={22}>
                            <FormComponent
                                label="Label ID"
                                name={"labelId"}
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                    inputProps: { disabled: !isAddAction },
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={22}>
                            <FormComponent
                                label="Label Name"
                                name={"labelName"}
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    );
};

export default LabelSetting;