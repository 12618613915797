import { createApi } from "@reduxjs/toolkit/query/react";
import { APIs, defaultBaseQueryProps, ResponseProps } from "../../services/apiConfig";
import { History } from "../../constants/type";
import { axiosBaseQuery } from "../axiosBaseQuery";

export interface reportHistoryRequest { }

export interface reportHistoryResponse extends ResponseProps<History[]> { }

export const reportHistoryApiSlice = createApi({
    reducerPath: "api_3",
    baseQuery: axiosBaseQuery(),
    keepUnusedDataFor: 5, //global caching duration (seconds)
    endpoints: (builder) => ({
        getHistory: builder.query<reportHistoryResponse, reportHistoryRequest | undefined>({
            keepUnusedDataFor: 0, //disable for caching for particular api request
            query: (arg) => ({ ...defaultBaseQueryProps, url: APIs.GET_REPORT_HISTORY_LIST, method: "POST", params: arg }),
            transformResponse: (returnValue: reportHistoryResponse, meta) => {
                return returnValue;
            },
        }),
    }),
});

export const { useGetHistoryQuery } = reportHistoryApiSlice;
