import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import FlexiDataTable from "../../../components/FlexiDataTable";
import { useGetServersQuery } from "../../../store/apis/server";
import { apiRequest, APIs } from "../../../services/apiConfig";
import CardBox from "../../../components/Common/CardBox";
import AuthHelper, { AuthKeys } from "../../../helpers/authHelper";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "../../../constants";
import { FlexiDataTableCallbackProps, FlexiDataTableOptionsProps } from "../../../constants/type";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";

const ServerListPage = () => {
    const { isLoading, data, refetch } = useGetServersQuery({});
    const [manualLoading, setManualLoading] = useState<boolean>(false);
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.ADMIN_SERVER_EDIT);
    // const [servers, setServers] = useState<ServersList[]>([]);

    let navigate = useNavigate();

    useEffect(() => {
        // getFilterConfigList();
        refetch();
        return () => {};
    }, []);

    // const getFilterConfigList = () => {
    //     apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["server"] })
    //         .then((data: any) => {
    //             setServers(data.servers);
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         })
    //         .finally(() => {
    //             setManualLoading(false);
    //         });
    // };

    const columns: any[] = [
        {
            title: "Server Name",
            dataIndex: "server",
            key: "server",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
            // dataIndex: "serverId",
            // key: "serverId",
            // render: (serverId: number) => {
            //     let idx = servers.findIndex((x) => x.id === serverId);
            //     return idx > -1 ? servers[idx].server : serverId;
            // },
            // options: {
            //     filter: {
            //         type: ComponentType.dropdown,
            //         value: servers.map((x) => ({ text: x.server, value: x.id })),
            //     },
            // },
        },
        {
            title: "Server Display Name",
            dataIndex: "display_name",
            key: "display_name",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        },
        DTColProps.XSmall({
            title: "Priority Level",
            dataIndex: "sourcePriorityLevel",
            key: "sourcePriorityLevel",
        }),
    ];

    const options: FlexiDataTableOptionsProps = {
        add: () => {
            if (enableUpdate) {
                return "/siteadmin/serverbrand/server/create";
            } else return enableUpdate;
        },
        edit: (record: any, overwriteProps: any) => {
            if (enableUpdate) {
                let newProps = { ...overwriteProps };
                newProps.label = <Link to={`/siteadmin/serverbrand/server/edit/${record.serverId}`}>{newProps.label}</Link>;
                return newProps;
            } else return enableUpdate;
        },
        delete: enableUpdate,
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, ServerData) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                navigate("/siteadmin/serverbrand/server/create", { state: { data: null, action: "add" } });
                break;
            case CALLBACK_KEY.DO_EDIT:
                navigate(`/siteadmin/serverbrand/server/edit/${ServerData.serverId}`, { state: { data: ServerData.serverId, action: "edit" } });
                break;
            case CALLBACK_KEY.DO_DELETE:
                setManualLoading(true);
                apiRequest(APIs.DELETE_SERVER, {
                    serverId: ServerData.serverId,
                })
                    .then((data) => ErrorMessageHandler(ServerData.server, SUCCESS_FAILED.SUCCESS_DELETE_DATA))
                    .catch((error) => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("server", SUCCESS_FAILED.FAILED_DELETE_DATA, err)))
                    .finally(() => {
                        setManualLoading(false);
                        refetch();
                    });
                break;
            default:
                break;
        }
    };

    return (
        <>
            <CardBox title={"Server Listing"}>
                <FlexiDataTable
                    rowKeyProperty="serverId"
                    title=""
                    columns={columns}
                    options={options}
                    dataSource={data?.data || []}
                    callback={componentCallback}
                    loading={isLoading || manualLoading}
                />
            </CardBox>
        </>
    );
};

export default ServerListPage;
