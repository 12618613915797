import React, { useState, useMemo } from "react";
import { Upload, Button, message, Table, AutoComplete, Space, Card, Typography, Input } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import Papa from "papaparse";

interface CSVData {
    Server: string;
    Symbol: string;
    Bid: number;
    Ask: number;
    "IC Last Tick Time": string | null;
    "VS IC": string | null;
    "IG Last Tick Time": string | null;
    "VS IG": string | null;
    "Last Tick": string;
    "End Time": string;
    Duration: string;
}

interface DowntimeRecord {
    start: string;
    end: string;
    duration: string;
    bid: number;
    ask: number;
}

interface SummarizedDowntime {
    Server: string;
    Symbol: string;
    TotalDowntime: string;
    Records: DowntimeRecord[];
}

type TableRowData = {
    Server: string;
    Symbol: string;
    TotalDowntime: string;
};

function summarizeDowntime(data: CSVData[]): SummarizedDowntime[] {
    console.log("Starting summarizeDowntime function");
    console.log("Input data:", data);

    if (!Array.isArray(data) || data.length === 0) {
        console.error("Invalid input data for summarizeDowntime");
        return [];
    }

    const summary: Record<string, SummarizedDowntime> = {};

    data.forEach((record, index) => {
        if (!record.Server || !record.Symbol || !record.Duration || !record["Last Tick"] || !record["End Time"]) {
            console.warn(`Skipping invalid record at index ${index}:`, record);
            return;
        }

        const { Server, Symbol, Duration, "Last Tick": start, "End Time": end } = record;
        const key = `${Server}-${Symbol}`;

        if (!summary[key]) {
            summary[key] = {
                Server,
                Symbol,
                TotalDowntime: "00:00:00",
                Records: [],
            };
        }

        summary[key].Records.push({ start, end, duration: Duration, bid: record.Bid, ask: record.Ask });

        const [oldHours, oldMinutes, oldSeconds] = summary[key].TotalDowntime.split(":").map(Number);
        const [newHours, newMinutes, newSeconds] = Duration.split(":").map(Number);

        let totalSeconds = oldHours * 3600 + oldMinutes * 60 + oldSeconds + (newHours * 3600 + newMinutes * 60 + newSeconds);

        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        summary[key].TotalDowntime = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
            .toString()
            .padStart(2, "0")}`;
    });

    const result = Object.values(summary);
    console.log("Summarized data:", result);
    return result;
}

const PriceAlarmReportVisualize: React.FC = () => {
    const [summarizedData, setSummarizedData] = useState<SummarizedDowntime[]>([]);
    const [filteredData, setFilteredData] = useState<SummarizedDowntime[]>([]);
    const [serverFilter, setServerFilter] = useState<string>("");
    const [symbolFilter, setSymbolFilter] = useState<string>("");

    const handleUpload = (file: File): void => {
        console.log("Starting file upload");
        const reader = new FileReader();
        reader.onload = (event: ProgressEvent<FileReader>) => {
            console.log("File read complete");
            const csvData = Papa.parse<CSVData>(event.target?.result as string, {
                header: true,
                dynamicTyping: true,
                complete: result => {
                    console.log("CSV parsing complete");
                    console.log("Parsed CSV data:", result.data);
                    if (result.data && result.data.length > 0) {
                        try {
                            const processedData = summarizeDowntime(result.data);
                            console.log("Summarized downtime data:", processedData);
                            setSummarizedData(processedData);
                            setFilteredData(processedData);
                            message.success(`${file.name} file uploaded and processed successfully`);
                        } catch (error) {
                            console.error("Error in summarizeDowntime:", error);
                            message.error(`Error processing data: ${error}`);
                        }
                    } else {
                        console.error("No data found in CSV");
                        message.error("No data found in the uploaded file");
                    }
                },
                error: (error: any) => {
                    console.error("Error parsing CSV:", error);
                    message.error(`Error parsing CSV file: ${error.message}`);
                },
            });
        };
        reader.onerror = error => {
            console.error("Error reading file:", error);
            message.error(`Error reading file: ${error}`);
        };
        reader.readAsText(file);
    };

    const handleBeforeUpload = (file: File): boolean => {
        handleUpload(file);
        return false; // Prevent automatic upload
    };

    const columns = [
        {
            title: "Server",
            dataIndex: "Server",
            key: "Server",
            sorter: (a: TableRowData, b: TableRowData) => a.Server.localeCompare(b.Server),
        },
        {
            title: "Symbol",
            dataIndex: "Symbol",
            key: "Symbol",
            sorter: (a: TableRowData, b: TableRowData) => a.Symbol.localeCompare(b.Symbol),
        },
        {
            title: "Total Downtime",
            dataIndex: "TotalDowntime",
            key: "TotalDowntime",
            sorter: (a: TableRowData, b: TableRowData) => {
                const timeToSeconds = (time: string) => {
                    const [hours, minutes, seconds] = time.split(":").map(Number);
                    return hours * 3600 + minutes * 60 + seconds;
                };
                return timeToSeconds(a.TotalDowntime) - timeToSeconds(b.TotalDowntime);
            },
        },
    ];

    //@ts-ignore
    const uniqueServers = useMemo(() => [...new Set(summarizedData.map(item => item.Server))], [summarizedData]);
    //@ts-ignore
    const uniqueSymbols = useMemo(() => [...new Set(summarizedData.map(item => item.Symbol))], [summarizedData]);

    const handleFilterChange = () => {
        let filtered = summarizedData;
        if (serverFilter) {
            filtered = filtered.filter(item => item.Server.toLowerCase().includes(serverFilter.toLowerCase()));
        }
        if (symbolFilter) {
            filtered = filtered.filter(item => item.Symbol.toLowerCase().includes(symbolFilter.toLowerCase()));
        }
        setFilteredData(filtered);
    };

    useMemo(handleFilterChange, [summarizedData, serverFilter, symbolFilter]);

    const totalDowntime = useMemo(() => {
        return filteredData.reduce((acc, curr) => {
            const [hours, minutes, seconds] = curr.TotalDowntime.split(":").map(Number);
            return acc + hours * 3600 + minutes * 60 + seconds;
        }, 0);
    }, [filteredData]);

    const formatTotalDowntime = (totalSeconds: number) => {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;
        return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    };

    const { Title } = Typography;
    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            <Title level={5}>Price Outage Report Summary</Title>
            <Upload beforeUpload={handleBeforeUpload} accept=".csv" multiple={false}>
                <Button icon={<UploadOutlined />}>Upload CSV</Button>
            </Upload>
            {summarizedData.length > 0 && (
                <>
                    <Space style={{ marginBottom: 16 }}>
                        <AutoComplete
                            style={{ width: 200 }}
                            options={uniqueServers.map(server => ({ value: server }))}
                            // placeholder="Filter by Server"
                            filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                            onChange={value => setServerFilter(value)}
                        >
                            <Input.Search placeholder="Server" />
                        </AutoComplete>
                        <AutoComplete
                            style={{ width: 200 }}
                            options={uniqueSymbols.map(symbol => ({ value: symbol }))}
                            // placeholder="Filter by Symbol"
                            filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                            onChange={value => setSymbolFilter(value)}
                        >
                            <Input.Search placeholder="Symbol" />
                        </AutoComplete>
                    </Space>
                    <Card style={{ marginBottom: 16 }}>
                        <h3>Summary</h3>
                        <p>Total Records: {filteredData.length}</p>
                        <p>Total Downtime: {formatTotalDowntime(totalDowntime)}</p>
                    </Card>
                    <Table<TableRowData>
                        dataSource={filteredData}
                        columns={columns}
                        size="small"
                        pagination={{
                            pageSizeOptions: ["10", "20", "50", "100", "500", "1000"],
                        }}
                        expandable={{
                            // @ts-ignore
                            expandedRowRender: (record: SummarizedDowntime) => (
                                <Table
                                    dataSource={record.Records}
                                    size="small"
                                    columns={[
                                        { title: "Bid", dataIndex: "bid", key: "bid", width: 100 },
                                        { title: "Ask", dataIndex: "ask", key: "ask", width: 100 },
                                        { title: "Start", dataIndex: "start", key: "start", width: 120 },
                                        { title: "End", dataIndex: "end", key: "end", width: 120 },
                                        { title: "Duration", dataIndex: "duration", key: "duration", width: 100 },
                                    ]}
                                    rowKey={record => record.start + record.end + record.duration}
                                    pagination={false}
                                />
                            ),
                        }}
                        rowKey={record => record.Server + record.Symbol}
                    />
                </>
            )}
        </Space>
    );
};

export default PriceAlarmReportVisualize;
