import React from "react";
import useRCDownloads, { IRCDownloadItem, useRCDownloadsMutation } from "../../../hooks/useRCDownloads";
import { Button, Modal, Space, Spin, Table, Tag, notification } from "antd";
import { DeleteOutlined, DownloadOutlined } from "@ant-design/icons";
import moment from "moment";
import { colorMap } from "../../SystemMonitor/StatusMonitor/statusUtils";
import { plainAxiosInstance } from "../../../services/axiosSetup";
import { APIs } from "../../../services/apis";

const dateFormat = "YYYY-MM-DD HH:mm:ss";

const DownloadsModal = ({
    open,
    handleModalOpen,
    filterName = null,
}: {
    open: boolean;
    handleModalOpen: (open: boolean) => void;
    filterName?: string | null;
}) => {
    const { rcDownloads } = useRCDownloads();
    // sort by createTime
    const sortedRcDownloads = rcDownloads?.[1]
        ? rcDownloads?.[1]
              ?.sort((a: IRCDownloadItem, b: IRCDownloadItem) => {
                  return moment(b.createTime).diff(moment(a.createTime));
              })
              .filter((item: IRCDownloadItem) => {
                  if (filterName) {
                      return item.batchName === filterName;
                  }
                  return true;
              })
        : [];

    // console.log(rcDownloads);
    const { mutateDeleteDownload } = useRCDownloadsMutation();

    const handleDownload = ({ fileName, extension, id }: { fileName: string; extension: string; id: number }) => {
        plainAxiosInstance
            .get(`${APIs.RC_ROOT.GET_VERIFY_DOWNLOAD}?id=${id}`)
            .then(response => {
                // Check if the response content type is 'application/octet-stream'
                console.log(response);
                if (typeof response.data === "boolean" && response.data) {
                    plainAxiosInstance
                        .get(`${APIs.RC_ROOT.GET_DOWNLOAD_FILE}?id=${id}`, {
                            headers: {
                                Accept: "application/octet-stream, application/zip, */*",
                            },
                            responseType: "blob",
                        })
                        .then(response => {
                            const contentType = response.headers["content-type"];
                            if (
                                contentType === "application/octet-stream" ||
                                contentType === "application/zip" ||
                                contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            ) {
                                // Handle the file download response
                                const url = window.URL.createObjectURL(new Blob([response.data]));
                                const link = document.createElement("a");
                                link.href = url;
                                if (contentType === "application/zip") {
                                    link.setAttribute("download", `${fileName}.zip`);
                                } else {
                                    link.setAttribute("download", `${fileName}.${extension}`);
                                }
                                document.body.appendChild(link);
                                link.click();
                                // Clean up
                                window.URL.revokeObjectURL(url);
                                notification.success({
                                    message: "Downloaded",
                                    description: `Downloaded ${fileName} successfully`,
                                });
                                handleModalOpen(false);
                            } else {
                                notification.error({
                                    message: "Error",
                                    description: `Received non-file response. Error: ${response}`,
                                });
                                console.log("Received non-file response:", response);
                            }
                        });
                }
            })
            .catch(err => {
                notification.error({
                    message: "Error",
                    description: `Download error: ${err}`,
                });
                console.log("download error", err);
            });
    };
    const columns = [
        {
            title: "Batch Name",
            dataIndex: "batchName",
        },
        {
            title: "Start Time",
            dataIndex: "startTime",
            render: (startTime: string) => {
                if (startTime) {
                    return moment(startTime).format(dateFormat);
                } else {
                    return <Tag color={colorMap.warning}>Not started yet</Tag>;
                }
            },
        },
        {
            title: "End Time",
            dataIndex: "endTime",
            render: (endTime: string) => {
                if (endTime) {
                    return moment(endTime).format(dateFormat);
                } else {
                    return "-";
                }
            },
        },
        {
            title: "Params",
            dataIndex: "params",
            render: (param: string) => {
                const paramArr = param.split("&");
                return (
                    <Space wrap size={2}>
                        {paramArr.map((p, index) => (
                            <Tag style={{ margin: 0 }} key={index}>
                                {p}
                            </Tag>
                        ))}
                    </Space>
                );
            },
        },
        {
            title: "Status",
            dataIndex: "status",
            render: (status: string) => {
                return (
                    <Tag color={status === "COMPLETED" ? colorMap.success : status === "ERROR" ? colorMap.error : colorMap.warning}>
                        {status?.toLowerCase()}
                    </Tag>
                );
            },
        },
        {
            title: "Actions",
            dataIndex: "",
            render: (record: any, data: IRCDownloadItem) => {
                return (
                    <Space>
                        <Button
                            type="ghost"
                            size="small"
                            disabled={data.status !== "COMPLETED"}
                            onClick={() => {
                                handleDownload({ fileName: data.batchName, extension: "csv", id: data.id });
                            }}
                        >
                            <DownloadOutlined />
                        </Button>
                        <Button
                            danger
                            key="delete"
                            size="small"
                            type="text"
                            disabled={data.status === "PROCESSING"}
                            icon={<DeleteOutlined />}
                            onClick={() => {
                                // remove(record);
                                mutateDeleteDownload({ id: data.id })
                                    .then(resp => {
                                        // console.log(resp);
                                        if (resp.data === 0) {
                                            notification.success({
                                                message: "Success",
                                                description: "Report removed successfully",
                                            });
                                        }
                                    })
                                    .catch(err => {
                                        notification.error({
                                            message: "Error",
                                            description: `Error while removing the report: ${err}`,
                                        });
                                        console.log("Error while removing the report", err);
                                    });
                            }}
                        />
                    </Space>
                );
            },
        },
        {
            title: "Note",
            dataIndex: "note",
        },
    ];

    return (
        <Modal width={1000} title="Downloads" open={open} onOk={() => handleModalOpen(false)} onCancel={() => handleModalOpen(false)}>
            <Table columns={columns} dataSource={sortedRcDownloads} rowKey="id" pagination={false} size="small" />
        </Modal>
    );
};

export default DownloadsModal;
