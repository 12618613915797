import { useEffect, useMemo, useState } from "react";
import CardBox from "@/components/Common/CardBox";
import FlexiDataTable from "@/components/FlexiDataTable";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "@/constants";
import { FlexiDataTableCallbackProps, FlexiDataTableOptionsProps } from "@/constants/type";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { Button, Modal, Table, Tag } from "antd";
import { ExclamationCircleOutlined, RedoOutlined, SyncOutlined } from "@ant-design/icons";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { APIs } from "@/services/apis";

interface StatusConfigData {
    serverId: string;
    serverName: string;
    restartDatetime: string;
};

const StatusConfigurationRestart = () => {
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.RC.RC_STATUS_CONFIG_RESTART_EDIT);

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<StatusConfigData[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [configStatus, setConfigStatus] = useState<any>(0);

    const bgColor = (status: number) => {
        switch (status) {
            case -1:
                return "default";
            case 0:
                return "warning";
            case 1:
                return "success";
            case 2:
                return "error";
        };
    };

    const columns = [
        DTColProps.Middle({
            title: "Server",
            dataIndex: "serverName",
            key: "serverName",
            sorter: (a: any, b: any) => a.serverName.localeCompare(b.serverName),
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.Small({
            title: "Restart Date Time",
            dataIndex: "restartDatetime",
            key: "restartDatetime",
            sorter: (a: any, b: any) => a.restartDatetime.localeCompare(b.restartDatetime),
        }),
    ];

    const options: FlexiDataTableOptionsProps = useMemo(() => ({
        ...(enableUpdate && {
            extraButtons: () => {
                return (
                    <div className="extra-table-header-buttons" key={"rcscr-extra-buttons"} style={isProcessing ? { display: "none" } : {}}>
                        <Button
                            key={"rcscr-reload-selected"}
                            icon={<RedoOutlined />}
                            loading={isLoading}
                            disabled={selectedRowKeys.length === 0 || configStatus === 0 || isProcessing}
                            onClick={() => reloadServers(selectedRowKeys)}
                        >
                            Reload Selected Servers
                        </Button>
                        <Button
                            key={"rcscr-reload-all"}
                            icon={<RedoOutlined />}
                            onClick={() => reloadServers()}
                            loading={isLoading}
                            disabled={configStatus === 0 || isProcessing}
                        >
                            Reload All Servers
                        </Button>
                    </div>
                );
            },
            enableRowSelection: true,
            hideRowSelectionsSummary: true,
            rowSelectionData: {
                rowSelectionType: "checkbox",
                selectedRowKeys: selectedRowKeys,
                options: {
                    fixed: "left",
                    selections: [
                        Table.SELECTION_ALL,
                        Table.SELECTION_NONE,
                    ],
                    preserveSelectedRowKeys: true,
                    disabled: configStatus === 0 || isProcessing,
                },
            },
        }),
    }), [enableUpdate, selectedRowKeys, isProcessing, isLoading, configStatus]);

    const componentCallback: FlexiDataTableCallbackProps = (type, FormData) => {
        switch (type) {
            case CALLBACK_KEY.ROW_SELECTION_CALLBACK:
                setSelectedRowKeys(FormData.selectedRowKeys);
                break;
            default:
                break;
        };
    };

    const reloadServers = (selectedKeys: string[] = []) => {
        Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            title: `Are you sure you want to reload ${selectedKeys.length > 0 ? "these" : "all"} servers?`,
            content: selectedKeys.length === 0
                ? <></>
                : <ul>
                    {selectedRowKeys.map((x: string) => {
                        return (
                            <li key={x}>
                                {x}
                            </li>
                        )
                    })}
                </ul>,
            width: "30%",
            okText: "Confirm",
            onOk: () => {
                setIsProcessing(true);
                setTimeout(() => setIsProcessing(false), 20000);
                plainAxiosInstance
                    .post(`${APIs.RC_STATUS_CONFIG_RESTART.POST_RELOAD_SERVERS}`, {
                        serverId: selectedKeys,
                        type: selectedKeys.length === 0 ? "all" : "selected",
                    })
                    .then(() => {
                        setSelectedRowKeys([]);
                        getConfigDiffData();
                    })
                    .catch((error: any) => ErrorCatchValidator(error, (err: any) => {
                        ErrorMessageHandler(`Failed to reload ${selectedKeys.length > 0 ? "selected" : "all"} servers`, SUCCESS_FAILED.OTHERS, err);
                    }));
            },
            onCancel: () => { },
        });
    };

    const getConfigDiffData = () => {
        setIsLoading(true);
        plainAxiosInstance
            .get(`${APIs.RC_STATUS_CONFIG_RESTART.GET_CONFIG_DIFF}`)
            .then((res: any) => {
                setData(res.data.length > 0 ? res.data : []);
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => {
                ErrorMessageHandler("config diff data", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                setData([]);
            }))
            .finally(() => setIsLoading(false));
    };

    const getSymbolConfigStatusData = () => {
        setIsLoading(true);
        plainAxiosInstance
            .get(`${APIs.RC_STATUS_CONFIG_RESTART.GET_SYMBOL_CONFIG_STATUS_DATA}`)
            .then((res: any) => {
                if (res.data?.hasOwnProperty("status")) setConfigStatus(res.data.status);
                else setConfigStatus(0);
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => {
                ErrorMessageHandler("config status", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                setConfigStatus(0);
            }))
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        getConfigDiffData();
        getSymbolConfigStatusData();
        const timer = setInterval(() => {
            getSymbolConfigStatusData();
        }, 5000);

        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <div className="status-config-restart-container">
            <CardBox title="Status Configuration Restart">
                <FlexiDataTable
                    rowKeyProperty="serverId"
                    title={configStatus === 0 || isProcessing
                        ? (
                            <Tag icon={<SyncOutlined spin />} color={bgColor(configStatus)} className="table-title-status-tag">
                                Processes running...
                            </Tag>
                        ) : ""
                    }
                    columns={columns}
                    options={options}
                    dataSource={data}
                    callback={componentCallback}
                    loading={isLoading}
                />
            </CardBox>
        </div>
    );
};

export default StatusConfigurationRestart;