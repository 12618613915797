import { Row, Col, FormInstance } from "antd";
import { ComponentType } from "../../../constants";
import { REQUIRED_FIELD } from "../../../constants/errorMessage";
import { FormComponent } from "../../FormComponent";

export interface BarTemplateProps {
    prefix: string;
    form: FormInstance;
}

const BarTemplate = (props: BarTemplateProps) => {
    return (
        <>
            <Row>
                <Col span={24}>
                    <FormComponent
                        label={"Chart Type"}
                        name={`${props.prefix}_chartType`}
                        extra={{
                            type: ComponentType.radio,
                            value: [
                                { text: "Vertical", value: "column" },
                                { text: "Horizontal", value: "bar" },
                            ],
                            itemProps: {
                                labelAlign: "left",
                                labelCol: { span: 5 },
                                wrapperCol: { span: 11 },
                            },
                            rules: [
                                {
                                    required: true,
                                    message: REQUIRED_FIELD,
                                },
                            ],
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <FormComponent
                        label={"X Axis"}
                        name={`${props.prefix}_xField`}
                        extra={{
                            type: ComponentType.text,
                            value: "",
                            itemProps: {
                                labelAlign: "left",
                                labelCol: { span: 5 },
                                wrapperCol: { span: 11 },
                            },
                            rules: [
                                {
                                    required: true,
                                    message: REQUIRED_FIELD,
                                },
                            ],
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <FormComponent
                        label={"Y Axis"}
                        name={`${props.prefix}_yField`}
                        extra={{
                            type: ComponentType.text,
                            value: "",
                            itemProps: {
                                labelAlign: "left",
                                labelCol: { span: 5 },
                                wrapperCol: { span: 11 },
                            },
                            rules: [
                                {
                                    required: true,
                                    message: REQUIRED_FIELD,
                                },
                            ],
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <FormComponent
                        label={"Series Field"}
                        name={`${props.prefix}_seriesField`}
                        extra={{
                            type: ComponentType.text,
                            value: "",
                            itemProps: {
                                labelAlign: "left",
                                labelCol: { span: 5 },
                                wrapperCol: { span: 11 },
                            },
                        }}
                    />
                    <FormComponent
                        label={"Group Field"}
                        name={`${props.prefix}_groupField`}
                        extra={{
                            type: ComponentType.text,
                            value: "",
                            itemProps: {
                                labelAlign: "left",
                                labelCol: { span: 5 },
                                wrapperCol: { span: 11 },
                            },
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <FormComponent
                        label=" "
                        name={`${props.prefix}_fillEmpty`}
                        extra={{
                            type: ComponentType.checkbox,
                            value: "Fill Empty Data",
                            itemProps: {
                                colon: false,
                            },
                        }}
                    />
                </Col>
            </Row>
        </>
    );
};

export default BarTemplate;
