import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CardBox from "../../../components/Common/CardBox";
import FlexiDataTable from "../../../components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType, MONITOR_PROFILE_STATUS, SUCCESS_FAILED } from "../../../constants";
import {
    APPSETTINGS,
    FlexiDataTableCallbackProps,
    FlexiDataTableOptionsProps,
    KeyValuePair,
    MonitorProfiles,
    ThresholdObjectProps,
} from "../../../constants/type";
import AuthHelper, { AuthKeys } from "../../../helpers/authHelper";
import { apiRequest } from "../../../services/apiConfig";
import { APIs } from "../../../services/apis";
import { useGetMonitorProfilesQuery } from "../../../store/apis/realtime";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler, currencyRender } from "../../../utils/Common";
import { cloneDeep } from "lodash";
import { getAppSettings } from "../../../services/localStorage";
import { Switch } from "antd";
import { ToOptionTypeList } from "../../../utils/array";
import moment from "moment";

export const getThresholdMarkupValue = (appSetting: any, items: ThresholdObjectProps[], item: ThresholdObjectProps) => {
    let finalValue: any = cloneDeep(item.value);
    if (item.appSettingProps && item.appSettingProps.length > 0) {
        let fIdx = appSetting[item.appSettingProps].findIndex((x: KeyValuePair) => `${x.value}` === `${finalValue}`);
        if (fIdx > -1) {
            finalValue = appSetting[item.appSettingProps][fIdx].text;

            fIdx = items.findIndex(x => x.appSettingValueProps && x.appSettingValueProps === item.appSettingProps);
            if (fIdx > -1) {
                finalValue = finalValue.replace(" N ", ` ${items[fIdx].value} `);
            }
        }
    }

    switch (item.dataType) {
        case 3:
            return currencyRender(`${finalValue}`);
        case 4:
            if (Array.isArray(finalValue)) {
                return (finalValue as string[]).join(", ");
            }
            return finalValue;
        default:
            return finalValue;
    }
};

export const getThresholdTemplate = (appSetting: APPSETTINGS, threshold: string, prefix: string = "", rowMode: boolean = false) => {
    if (`${threshold}` !== "null" && threshold.length > 0) {
        try {
            let tmpObj: any = JSON.parse(threshold);
            return (
                <div className={rowMode ? "chart-threshold-container" : ""}>
                    {tmpObj
                        .filter((x: ThresholdObjectProps) => (x.editable === undefined ? true : x.editable))
                        .filter((x: ThresholdObjectProps) => x.appSettingValueProps === undefined)
                        .map((x: ThresholdObjectProps, idx: number) => (
                            <div key={`pevevk-${prefix}-${idx}`} className="chart-threshold-item">
                                <span>{`${x["label"]} : `}</span>
                                <span>{getThresholdMarkupValue(appSetting, tmpObj, x)}</span>
                            </div>
                        ))}
                </div>
            );
        } catch (error) {
            //do nothing
        }
    }
    return <></>;
};

export const getMonitorProfilePrefetchList = () => {
    return new Promise<any>((resolve, reject) => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["relativetimerange", "relativedaterange", "symbolassettype"] })
            .then((res: any) => {
                let returnObj: any = getAppSettings();
                if (res.relativeTimeRanges && res.relativeTimeRanges.length > 0) {
                    returnObj["RELATIVE_TIME_RANGE"] = res.relativeTimeRanges.map((x: any) => ({ text: x.name, value: x.id } as KeyValuePair));
                }
                if (res.relativeDateRanges && res.relativeDateRanges.length > 0) {
                    returnObj["RELATIVE_DATE_RANGE"] = res.relativeDateRanges.map((x: any) => ({ text: x.name, value: x.id } as KeyValuePair));
                }
                if (res.symbolAssetTypes && res.symbolAssetTypes.length > 0) {
                    returnObj["SYMBOL_TYPE"] = res.symbolAssetTypes.map((x: any) => ({ text: x.name, value: x.name } as KeyValuePair));
                }
                resolve(returnObj);
            })
            .catch(error => ErrorCatchValidator(error, (err: any) => resolve(getAppSettings())));
    });
};

const TradeMonitoringPage = () => {
    const { isLoading, data, refetch } = useGetMonitorProfilesQuery({});
    const [manualLoading, setManualLoading] = useState<boolean>(false);

    let navigate = useNavigate();
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.MONITOR_TRADE_EDIT);
    const appSettingObj = getAppSettings();

    const getColumns = (appSetting: APPSETTINGS) => {
        return [
            {
                title: "Name",
                dataIndex: "name",
                key: "name",
                render: (name: string, rowData: MonitorProfiles) => {
                    return (
                        <Link
                            to={rowData.status === 0 ? "#" : `/realtime/trademonitor/launch/${btoa(rowData.id.toString())}`}
                            style={rowData.status === 0 ? { color: "#999999", pointerEvents: "none" } : { color: "#0e65cc" }}
                        >
                            {name}
                        </Link>
                    );
                },
                options: {
                    filter: {
                        type: ComponentType.text,
                    },
                },
            },
            DTColProps.Small({
                title: "Status",
                dataIndex: "status",
                key: "status",
                sorter: (a: any, b: any) => a.status - b.status,
                defaultSortOrder: "descend",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: ToOptionTypeList(MONITOR_PROFILE_STATUS),
                    },
                },
                render: enableUpdate
                    ? (status: number, rowData: any) => {
                          if (rowData.status !== 2) {
                              const switchValue = status === 1 ? true : false;

                              return (
                                  <Switch
                                      onChange={checked => {
                                          handleStatusChange(rowData, checked);
                                      }}
                                      checkedChildren="Active"
                                      unCheckedChildren="Inactive"
                                      defaultChecked={switchValue}
                                  />
                              );
                          } else return "Deleted";
                      }
                    : (text: number, record: any) => MONITOR_PROFILE_STATUS[text] ?? "",
            }),

            DTColProps.Large({
                title: "Threshold",
                dataIndex: "threshold",
                key: "threshold",
                render: (name: string, rowData: MonitorProfiles) => getThresholdTemplate(appSetting, name, `${rowData["id"]}`),
            }),
            // DTColProps.Small(
            //     {
            //         title: "View In Dashboard",
            //         dataIndex: "isViewInDashboard",
            //         key: "isViewInDashboard",
            //         render: (name: boolean, rowData: MonitorProfiles) => {
            //             return (
            //                 <div style={{ textAlign: "center" }}>
            //                     {name ? (
            //                         <CheckCircleOutlined style={{ color: "#49aa19", fontSize: "22px" }} />
            //                     ) : (
            //                         <CloseCircleOutlined style={{ color: "#f00f00", fontSize: "22px" }} />
            //                     )}
            //                 </div>
            //             );
            //         },
            //     },
            //     ["text-center"]
            // ),
            // DTColProps.Middle({
            //     title: "Created By",
            //     dataIndex: "createdBy",
            //     key: "createdBy",
            // }),

            // DTColProps.DateTime({
            //     title: "Created At",
            //     dataIndex: "createdDateUtc",
            //     key: "createdDateUtc",
            //     defaultSortOrder: "descend",
            // }),
        ];
    };

    const handleStatusChange = (rowData: MonitorProfiles, checked: boolean) => {
        setManualLoading(true);
        const status = checked === true ? 1 : 0;

        apiRequest(APIs.UPDATE_MONITOR_PROFILE_STATUS, {
            profileId: rowData.id,
            isActivate: checked,
        })
            .then(data => {
                ErrorMessageHandler("Existing monitor profile's status", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
            })
            .catch(error =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("existing monitor profile", SUCCESS_FAILED.FAILED_UPDATE_DATA, err))
            )
            .finally(() => {
                setManualLoading(false);
                refetch();
            });
    };

    const options: FlexiDataTableOptionsProps = {
        add: enableUpdate,
        edit: enableUpdate,
        delete: enableUpdate,
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, FormData: MonitorProfiles) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                navigate("/realtime/trademonitor/create", { state: { action: "add", data: null } });
                break;
            case CALLBACK_KEY.DO_EDIT:
                navigate("/realtime/trademonitor/edit", { state: { action: "edit", data: FormData.id } });
                break;
            case CALLBACK_KEY.DO_DELETE:
                setManualLoading(true);
                apiRequest(`${APIs.DELETE_MONITOR_PROFILE}`, { profileId: FormData.id })
                    .then(res => {
                        ErrorMessageHandler("The monitor profile", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                        setManualLoading(false);
                        refetch();
                    })
                    .catch(error => {
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("monitor profile", SUCCESS_FAILED.FAILED_DELETE_DATA, err));
                        setManualLoading(false);
                    });
                break;
        }
    };

    useEffect(() => {
        refetch();
        return () => {};
    }, []);

    return (
        <>
            <CardBox title={"Monitor Profile List"}>
                <FlexiDataTable
                    rowKeyProperty="id"
                    title=""
                    columns={getColumns(appSettingObj)}
                    options={options}
                    dataSource={data?.data || []}
                    callback={componentCallback}
                    loading={isLoading || manualLoading}
                />
            </CardBox>
        </>
    );
};

export default TradeMonitoringPage;
