export const stepInfo = [
    {
        label: "Step1: Adjustment",
        value: 0,
        color: "volcano",
        description: "Upload And Calculate Adjustment",
    },
    {
        label: "Step2: Open Position",
        value: 1,
        color: "yellow",
        description: "Calculate Open Position Adjustment",
    },
    {
        label: "Step3: Supplementary",
        value: 2,
        color: "blue",
        description: "Execute Supplementary",
    },
    {
        label: "Step4: Deduction",
        value: 3,
        color: "green",
        description: "Execute Deduction",
    },
    {
        label: "Step5: Completion",
        value: 4,
        color: "default",
        description: "Result And Stop Out Order",
    },
];