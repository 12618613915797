import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { APIs } from "../services/apis";
import { plainAxiosInstance } from "../services/axiosSetup";
//sample resp
/*
[{
  "serverId": "IVdemo",
  "server": "IVdemo",
  "name": "!journal&synchronization thread shutdown",
  "type": "Backup",
  "sendTime": "2024-02-22 04:27:09",
  "status": 1
}]
*/
interface IRCMetaTradersResponse {
    serverId: string;
    server: string;
    name: string;
    type: string;
    sendTime: string;
    status: number;
}

const useRCMetaTraders = () => {
    // const token = getToken();
    const { data, refetch, isLoading, error, isFetching, dataUpdatedAt } = useQuery({
        queryKey: ["rcMetaTraders"],
        queryFn: () =>
            plainAxiosInstance.get(`${APIs.RC_META_TRADER.GET_SERVERS}`, {
                headers: {
                    Accept: "application/json",
                },
            }),
        // axios.get(`${process.env.REACT_APP_API_URL}${APIs.RC_META_TRADER.GET_SERVERS}`, {
        //     headers: {
        //         Accept: "application/json",
        //         Authorization: `Bearer ${token}`,
        //     },
        // }),
        staleTime: 60 * 1000, //(10 x 1000ms = 10 seconds)
        refetchInterval: 60 * 1000, //(10 x 1000ms = 10 seconds)
    });

    const rcMetaTraders: IRCMetaTradersResponse[] | null = data?.data?.status ? null : data?.data || null;
    // @ts-ignore
    const rcMetaTradersError: string | null = data?.data?.message || error?.message || error || null;

    return {
        rcMetaTraders,
        rcMetaTradersError,
        refetchRcMetaTraders: refetch,
        isFetching,
        dataUpdatedAt,
        isLoading,
    };
};

export const useRCMetaTraderServerTime = () => {
    // const token = getToken();
    const { data, refetch, isLoading, error, isFetching } = useQuery({
        queryKey: ["rcMetaTradersServerTime"],
        queryFn: () =>
            plainAxiosInstance.get(`${APIs.RC_META_TRADER.GET_SERVER_TIME}`, {
                headers: {
                    // Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                },
            }),
        // axios.get(`${process.env.REACT_APP_API_URL}${APIs.RC_META_TRADER.GET_SERVER_TIME}`, {
        //     headers: {
        //         Accept: "application/json",
        //         Authorization: `Bearer ${token}`,
        //     },
        // }),
        staleTime: 60 * 1000, //(10 x 1000ms = 10 seconds)
        // refetchInterval: 60 * 1000, //(10 x 1000ms = 10 seconds)
    });

    /* sample resp
    data.data {
        2024-05-13 00:48:23(GMT+0300)
    }
    return with GMT+0300 so i can use moment to get the time, and update every second
    */
    const rcMetaTraderServerTime: string | null = data?.data || null;
    // extract the part () to get the +0300
    const gmt = rcMetaTraderServerTime?.split("GMT")[1].split(")")[0];

    // const rcMetaTraderServerTime = data?.data?.status ? null : data?.data || null;

    // @ts-ignore
    const rcMetaTraderServerTimeError: string | null = data?.data?.message || error?.message || error || null;

    return {
        rcMetaTraderServerTime,
        gmt,
        rcMetaTraderServerTimeError,
        refetchRcMetaTraderServerTime: refetch,
        isFetching,
        isLoading,
    };
};

// export const useRCRejectOrderSolved = () => {
//     const token = getToken();
//     const queryClient = useQueryClient();
//     const { mutateAsync, isLoading, error } = useMutation(
//         async ({ server }: { server: string }): Promise<any> => {
//             // Add return type annotation
//             if (!server) {
//                 return Promise.reject("server name shouldn't be empty");
//             }
//             return axios.put(
//                 `${process.env.REACT_APP_API_URL}${APIs.RC_REJECT_ORDER.PUT_REJECT_ORDER_SOLVED}/${server}`,
//                 {},
//                 {
//                     headers: {
//                         Accept: "application/json",
//                         Authorization: `Bearer ${token}`,
//                     },
//                 }
//             );
//         },
//         {
//             onSuccess: res => {
//                 queryClient.invalidateQueries(["rcRejectOrders"]);
//                 // console.log(`Solving reject orders response 200`, res);
//                 return res;
//             },
//             onError: error => {
//                 queryClient.invalidateQueries(["rcRejectOrders"]);
//                 console.error(`Error on solving reject orders:`, error);
//                 return error;
//             },
//         }
//     );

//     return {
//         rejectOrderSolved: mutateAsync,
//         isLoading,
//         error,
//     };
// };

export default useRCMetaTraders;
