import { Segmented, Select } from "antd";
import CardBox from "../../../../components/Common/CardBox";
import { useMemo, useState } from "react";
import { KeyValuePair } from "../../../../constants/type";
import { DefaultIfEmpty } from "../../../../utils/object";
import DataTableComponent from "./components/dataTableComponent";
import { ConfigDiffRecordJSON } from "./components/tabConfigDataJSON";

export interface ConfigDiffRecordPageProps {}

interface DropdownSelectionProps {
    segmentOpts: string[];
    subOption: KeyValuePair[];
    KeyStr: string;
    columns: string[];
    excludeCompareColumns: string[];
}

const ConfigDiffRecordPage = (props: ConfigDiffRecordPageProps) => {
    const [currentTab, setCurrentTab] = useState<string>("MT4");
    const [currentSelected, setCurrentSelected] = useState<string>("MT4 Group");
    const [resetCount, setResetCount] = useState<number>(0);

    const dropdownSelection: DropdownSelectionProps = useMemo(() => {
        let configObj = DefaultIfEmpty(ConfigDiffRecordJSON, currentTab, {}),
            configProps = DefaultIfEmpty(configObj, currentSelected, {});
        return {
            segmentOpts: Object.keys(ConfigDiffRecordJSON),
            subOption: DefaultIfEmpty(configObj, "SubOptions", []),
            KeyStr: DefaultIfEmpty(configProps, "KeyStr", ""),
            columns: DefaultIfEmpty(configProps, "Columns", []),
            excludeCompareColumns: DefaultIfEmpty(configProps, "ExcludeCompare", []),
        };
    }, [currentTab, currentSelected]);

    return (
        <div className="config-diff-record-container">
            <CardBox title={"Config Diff Record"}>
                <div className="main-container">
                    <div className="top-container">
                        <div className="left">
                            <Segmented
                                value={currentTab}
                                options={dropdownSelection.segmentOpts}
                                onChange={(activeKey: any) => {
                                    setResetCount(prev => prev + 1);
                                    setCurrentTab(activeKey);
                                    setCurrentSelected(
                                        `${
                                            DefaultIfEmpty(ConfigDiffRecordJSON, activeKey, { SubOptions: [] }).SubOptions.length > 0
                                                ? (ConfigDiffRecordJSON[activeKey]["SubOptions"] as KeyValuePair[])[0].value
                                                : ""
                                        }`
                                    );
                                }}
                            />
                        </div>
                        <div className="right">
                            <Select
                                value={currentSelected}
                                dropdownMatchSelectWidth={false}
                                options={dropdownSelection.subOption}
                                onChange={(value: any) => {
                                    setResetCount(prev => prev + 1);
                                    setCurrentSelected(value);
                                }}
                            />
                        </div>
                    </div>
                    <DataTableComponent
                        KeyStr={dropdownSelection.KeyStr}
                        currentTabKey={currentTab}
                        title={currentSelected}
                        columns={dropdownSelection.columns}
                        excludeCompareColumns={dropdownSelection.excludeCompareColumns}
                        resetFirstLoad={resetCount}
                    />
                </div>
            </CardBox>
        </div>
    );
};

export default ConfigDiffRecordPage;
