import React from "react";
import BasicFilter from "../../../components/BasicFilter";
import MetaTraderFilter from "./MetaTraderFilter";
import SystemBreadCrumbs from "../components/SystemBreadCrumbs";
import MetaTraderMonitorTable from "./MetaTraderMonitorTable";
import MetaTraders from "./MetaTraders";
import DownloadMetaTraderButton from "./DownloadMetaTraderButton";
import SoundButton from "../components/SoundButton";
import soundMetaTrader from "../../../assets/audios/rc/machineCall.mp3"; // RC original sound
import useRCMetaTraders from "../../../hooks/useRCMetaTraders";

export const massageData = (res: any) => {
    const nowRes = res;

    const uniqType = Array.from(new Set(nowRes.map((item: any) => item.type)));

    // 依據Type塞資料
    const typeData = uniqType.map(type => {
        const tempData = nowRes.filter((data2: any) => data2.type === type);

        let danger = 0,
            success = 0;

        tempData.forEach((data: any) => {
            if (data.status === 0) success++;
            if (data.status === 1) danger++;
        });

        return {
            type: type,
            data: tempData,
            danger: danger,
            success: success,
        };
    });
    return typeData;
};
const MetaTrader = () => {
    const { rcMetaTraders } = useRCMetaTraders();
    // @ts-ignore
    const data = rcMetaTraders ? massageData(rcMetaTraders) : [];
    const totalDanger = data.reduce((acc: number, curr: any) => acc + curr.danger, 0);
    // console.log(totalDanger);

    return (
        <>
            <SystemBreadCrumbs currMenu="Meta Trader" />
            <div style={{ paddingBottom: "1rem", background: "white" }}>
                <BasicFilter
                    titleBarChildren={
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                flexWrap: "wrap",
                                gap: "4px",
                                alignItems: "center",
                            }}
                        >
                            <SoundButton soundSrc={soundMetaTrader} isActive={totalDanger >= 1} />
                            <DownloadMetaTraderButton />
                        </div>
                    }
                >
                    <MetaTraderFilter />
                </BasicFilter>
                <MetaTraders />
                <MetaTraderMonitorTable />
            </div>
        </>
    );
};

export default MetaTrader;
