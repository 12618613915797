import { useEffect, useState } from "react";
import moment from "moment";
import { DateTimeUtil } from "../../../utils/datetime";
import { ClockCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

export interface FixedTimeCountDownProps {
    onFinish: () => void;
}

const FixedTimeCountDown = (props: FixedTimeCountDownProps) => {
    const [dummySet, setDummySet] = useState<boolean>(false);

    useEffect(() => {
        let currentTime = moment().format("mm:ss");
        if (`${currentTime[0]}5:00` === currentTime) props.onFinish && props.onFinish();
    }, [dummySet]);

    useEffect(() => {
        const secondInterval = setInterval(() => setDummySet(prev => !prev), 1000);

        return () => {
            clearInterval(secondInterval);
        };
    }, []);

    const displayCountDown = () => {
        let currentTime = moment().format("YYYY-MM-DD HH:mm:ss"),
            currentTimeArr = currentTime.split(":"),
            closestTime = "";
        if (currentTimeArr[1][1] === "5" && currentTimeArr[2] === "00") return "00:00";

        if (parseInt(currentTimeArr[1][1]) < 5) {
            closestTime = `${currentTimeArr[0]}:${currentTimeArr[1][0]}5:00`;
        } else {
            let tmpArr = moment().add(10, "minutes").format("YYYY-MM-DD HH:mm").split(":");
            closestTime = `${tmpArr[0]}:${tmpArr[1][0]}5:00`;
        }

        return DateTimeUtil.getDiffBetween2Date(currentTime, closestTime, 3);
    };

    return (
        <Tooltip title="Next resync data remaining time">
            <div className="fixed-time-count-down">
                <ClockCircleOutlined />
                {`${displayCountDown()}`}
            </div>
        </Tooltip>
    );
};

export default FixedTimeCountDown;
