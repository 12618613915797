import React, { useState } from "react";
import useRCServerPerformanceStore from "../../../store/useRCServerPerformanceStore";
import useRCServers from "../../../hooks/useRCServers";
import { Button, Input, Space } from "antd";
import { matchSorter } from "match-sorter";
import MessageCard from "../components/MessageCard";
const ServerSelector = () => {
    const { rcServers, isLoading } = useRCServers();
    const currentFocusServer = useRCServerPerformanceStore(state => state.currentFocusServer);
    const setCurrentFocusServer = useRCServerPerformanceStore(state => state.setCurrentFocusServer);

    const [searchQuery, setSearchQuery] = useState("");
    // console.log(rcIssuesLogNoMoneySettings);
    const result = matchSorter(rcServers ?? [], searchQuery ?? "", {
        keys: ["serverId", "serverName"],
    });
    if (isLoading) return <MessageCard type="info">Loading servers...</MessageCard>;
    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            <Input
                placeholder="Search Server"
                onChange={e => {
                    setSearchQuery(e.target.value);
                }}
            />
            <Space size={4} wrap>
                {result?.map(server => (
                    <Button
                        key={server.serverName}
                        onClick={() => setCurrentFocusServer(server.serverName)}
                        type={server.serverName === currentFocusServer ? "primary" : "ghost"}
                    >
                        {server.serverName}
                    </Button>
                ))}
            </Space>
        </Space>
    );
};

export default ServerSelector;
