import { DeleteOutlined, ExclamationCircleOutlined, MessageOutlined, UndoOutlined } from "@ant-design/icons";
import { Modal, Form } from "antd";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import CardBox from "../../../components/Common/CardBox";
import FlexiDataTable from "../../../components/FlexiDataTable";
import { FormComponent } from "../../../components/FormComponent";
import { SUCCESS_FAILED, CALLBACK_KEY, ComponentType, TAGGING_EVENT_STATUS_ENUM } from "../../../constants";
import { REQUIRED_FIELD } from "../../../constants/errorMessage";
import {
    CustomPaginationProps,
    TagEvents,
    TagProfiles,
    Tags,
    FlexiDataTableOptionsProps,
    FlexiDataTableCallbackProps,
} from "../../../constants/type";
import { apiRequest } from "../../../services/apiConfig";
import { APIs } from "../../../services/apis";
import { getSource } from "../../../services/localStorage";
import { DataTableColumnRender, DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";
import { enabledCheckingByLocation, ProfileInfoProps } from "./viewProfile";

const { confirm } = Modal;

export interface TaggingListPageProps extends ProfileInfoProps {}

export type ModalProps = {
    visible: boolean;
    isEdit: boolean;
};

const TaggingListPage = (props: TaggingListPageProps) => {
    let location = useLocation();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [data, setData] = useState<TagEvents[]>([]);
    const [tags, setTags] = useState<Tags[]>([]);
    const [visibleModal, setVisibleModal] = useState<ModalProps>({ visible: false, isEdit: false });
    const [pagination, setPagination] = useState<CustomPaginationProps>({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [sorting, setSorting] = useState<string>("createdDateUtc,desc");
    const [commentForm] = Form.useForm();

    const enableUpdate = enabledCheckingByLocation(getSource(location));

    const createComment = (values: any) => {
        setIsLoading(true);
        apiRequest(APIs.CREATE_NEW_TAG_EVENT, {
            ...props,
            ...values,
        })
            .then((data) => {
                ErrorMessageHandler("New tag event", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                setRunRefetchDataList(true);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("new tag event", SUCCESS_FAILED.FAILED_CREATE_DATA, err));
            })
            .finally(() => setIsLoading(false));
    };

    const updateComment = (values: any) => {
        setIsLoading(true);
        apiRequest(APIs.EDIT_TAG_EVENT, {
            ...values,
        })
            .then((data) => {
                ErrorMessageHandler("Tag event", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                setRunRefetchDataList(true);
            })
            .catch((error) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("tag event", SUCCESS_FAILED.FAILED_UPDATE_DATA, err));
            })
            .finally(() => setIsLoading(false));
    };

    const get_comment_list = () => {
        apiRequest(APIs.GET_TAG_EVENT_LIST, {
            serverId: props.serverId,
            accountId: props.accountId,
            limit: pagination.pageSize,
            current: pagination.current,
            ...(sorting.length > 0 && { order: sorting }),
        })
            .then((data: any) => {
                setData(data.result);
                setPagination((prev) => ({ ...prev, total: data.total }));
            })
            .catch((error) => {
                ErrorCatchValidator(error, (err: any) => console.log(err));
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const columns = [
        DTColProps.DateTime({
            title: "Date Time (Local)",
            dataIndex: "createdDateUtc",
            key: "createdDateUtc",
            render: (text: any, record: TagEvents) => {
                return (
                    <span className={record.status === TAGGING_EVENT_STATUS_ENUM.Abandoned ? "text-line-throught" : ""}>
                        {DataTableColumnRender.DateTime(`${text}+00:00`)}
                    </span>
                );
            },
            sorter: true,
        }),

        DTColProps.Middle({
            title: "Tag",
            dataIndex: "tag",
            key: "tag",
            render: (text: any, record: TagEvents) => {
                return <span className={record.status === TAGGING_EVENT_STATUS_ENUM.Abandoned ? "text-line-throught" : ""}>{text}</span>;
            },
        }),
        {
            title: "Event & Comment(s)",
            dataIndex: "event",
            key: "event",
            render: (text: any, record: TagEvents, index: number) => {
                let isLineThrough = record.status === TAGGING_EVENT_STATUS_ENUM.Abandoned;
                return (
                    <div className="tagging-event-comment-col">
                        <div className={`event-line ${isLineThrough ? "text-line-throught" : ""}`}>{text}</div>
                        <div>
                            <span className={`comment-line ${isLineThrough ? "text-line-throught" : ""}`}>Comment: {record.comment}</span>
                        </div>
                    </div>
                );
            },
        },
    ];

    const options: FlexiDataTableOptionsProps = {
        enableFilter: false,
        add: enableUpdate,
        separateActionButton: true,
        ...(enableUpdate && {
            rowExtra: (record: TagEvents) => {
                return [
                    { text: "", icon: <MessageOutlined />, value: "comment" },
                    {
                        text: "",
                        icon: record.status === TAGGING_EVENT_STATUS_ENUM.Abandoned ? <UndoOutlined /> : <DeleteOutlined />,
                        value: "delete_reset",
                    },
                ];
            },
        }),
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, FormData) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                commentForm.setFieldsValue({ tagEventId: "", tagProfileId: "", event: "", comment: "" });
                setVisibleModal({ visible: true, isEdit: false });
                break;
            case CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK:
                let tmp: TagEvents = FormData.data;
                if (FormData.key === "comment") {
                    commentForm.setFieldsValue({ tagEventId: tmp.id, tagProfileId: "", event: "", comment: "" });
                    setVisibleModal({ visible: true, isEdit: true });
                } else if (FormData.key === "delete_reset") {
                    confirm({
                        icon: <ExclamationCircleOutlined />,
                        content: `Confirm to ${tmp.status === TAGGING_EVENT_STATUS_ENUM.Abandoned ? "undo current" : "delete the"} tag?`,
                        onOk() {
                            updateComment({
                                tagEventId: tmp.id,
                                status:
                                    tmp.status === TAGGING_EVENT_STATUS_ENUM.Abandoned
                                        ? TAGGING_EVENT_STATUS_ENUM.Active
                                        : TAGGING_EVENT_STATUS_ENUM.Abandoned,
                                comment: "",
                            });
                        },
                        onCancel() {
                            console.log("Cancel");
                        },
                    });
                }
                break;
            case CALLBACK_KEY.HANDLE_PAGINATION_SORTING:
                FormData.sorter.order === undefined
                    ? setSorting("createdDateUtc,desc")
                    : setSorting(
                          FormData.sorter.column && FormData.sorter.order
                              ? `${FormData.sorter.column.key},${FormData.sorter.order === "ascend" ? "asc" : "desc"}`
                              : ""
                      );
                setPagination((prev) => ({ ...prev, current: FormData.pagination.current, pageSize: FormData.pagination.pageSize }));
                setRunRefetchDataList(true);
                break;
        }
    };

    useEffect(() => {
        apiRequest(APIs.GET_TAG_PROFILE_LISTING, {})
            .then((data: any) => {
                setTags(data.filter((x: TagProfiles) => x.status === 1).map((x: TagProfiles) => ({ id: x.id, name: x.name } as Tags)));
            })
            .catch((error) => {
                ErrorCatchValidator(error, (err: any) => console.log(err));
            })
            .finally(() => setRunRefetchDataList(true));

        return () => {};
    }, []);

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            get_comment_list();
            setRunRefetchDataList(false);
        }
        return () => {};
    }, [runRefetchDataList]);

    return (
        <div className="tagging-container">
            <CardBox title={"Tagging"}>
                <FlexiDataTable
                    rowKeyProperty="id"
                    title=""
                    columns={columns}
                    options={options}
                    dataSource={data || []}
                    callback={componentCallback}
                    loading={isLoading}
                    pagination={pagination}
                    serverSide={true}
                />
            </CardBox>

            <Modal
                open={visibleModal.visible}
                title={visibleModal.isEdit ? "Add comment" : "Create New Tag"}
                onCancel={() => setVisibleModal({ visible: false, isEdit: false })}
                onOk={() => {
                    commentForm
                        .validateFields()
                        .then((values: any) => {
                            if (visibleModal.isEdit) {
                                updateComment(values);
                            } else {
                                createComment(values);
                            }
                            setVisibleModal({ visible: false, isEdit: false });
                        })
                        .catch((info: any) => {
                            console.log("Validate Failed:", info);
                        });
                }}
            >
                <Form form={commentForm} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }} layout="horizontal">
                    {visibleModal.isEdit && (
                        <>
                            <FormComponent label={""} name={"tagEventId"} extra={{ type: ComponentType.hidden, value: "" }} />
                        </>
                    )}
                    {!visibleModal.isEdit && (
                        <>
                            <FormComponent
                                label={"Tag"}
                                name={"tagProfileId"}
                                extra={{
                                    type: ComponentType.dropdown,
                                    value: tags.map((x) => ({ text: x.name, value: x.id })),
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                            <FormComponent
                                label={"Event"}
                                name={"event"}
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [
                                        {
                                            required: true,
                                            message: REQUIRED_FIELD,
                                        },
                                    ],
                                }}
                            />
                        </>
                    )}
                    <FormComponent
                        label="Comment"
                        name={"comment"}
                        extra={{
                            type: ComponentType.textarea,
                            value: "",
                        }}
                    />
                </Form>
            </Modal>
        </div>
    );
};

export default TaggingListPage;
