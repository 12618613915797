import { createApi } from "@reduxjs/toolkit/query/react";
import { APIs, defaultBaseQueryProps, ResponseProps } from "../../services/apiConfig";
import { axiosBaseQuery } from "../axiosBaseQuery";

export interface metricGroupRequest {}

export interface metricGroupResponse extends ResponseProps<any[]> {}

export const metricGroupApiSlice = createApi({
    reducerPath: "api_13",
    baseQuery: axiosBaseQuery(),
    keepUnusedDataFor: 5, //global caching duration (seconds)
    endpoints: (builder) => ({
        getMetricGroup: builder.query<metricGroupResponse, metricGroupRequest | undefined>({
            keepUnusedDataFor: 0, //disable for caching for particular api request
            query: (arg) => ({ ...defaultBaseQueryProps, url: APIs.GET_METRIC_GROUP_LIST, method: "POST", params: arg }),
            transformResponse: (returnValue: metricGroupResponse, meta) => {
                return returnValue;
            },
        }),
    }),
});

export const { useGetMetricGroupQuery } = metricGroupApiSlice;
