import React, { useState } from "react";
import FloatingTitleBox from "../../components/FloatingTitleBox";
import useRCIssuesLogStore from "../../../../store/useRCIssuesLogStore";
import CommonIssuesLogTable from "../CommonIssuesLogTable";
import { Tabs } from "antd";
import CommonIssuesLogHistoryTable from "../CommonIssuesLogHistoryTable";
import IssueMT5OrderTypeMonitorTable from "./IssueMT5OrderTypeMonitorTable";

const IssueMT5OrderType = () => {
    const updateInView = useRCIssuesLogStore(state => state.updateInView);
    const [currTab, setCurrTab] = useState<string>("monitoring");
    const handleChangeTab = (activeKey: string) => {
        setCurrTab(activeKey);
    };
    return (
        <FloatingTitleBox
            title="MT5 Order State"
            inViewCallback={(isInView: boolean) => {
                updateInView({ MT5_ORDER_TYPE: isInView });
            }}
            titleBarTabs={
                <Tabs
                    activeKey={currTab}
                    onChange={handleChangeTab}
                    type="card"
                    style={{
                        padding: "0.5rem 0.5rem 0 0.5rem",
                    }}
                    tabBarStyle={{
                        marginBottom: 0,
                    }}
                    items={[
                        {
                            label: "Monitoring",
                            key: "monitoring",
                        },
                        {
                            label: "History",
                            key: "history",
                        },
                    ]}
                />
            }
        >
            {currTab === "monitoring" && <IssueMT5OrderTypeMonitorTable />}
            {currTab === "history" && <CommonIssuesLogHistoryTable type="MT5_ORDER_TYPE" />}
        </FloatingTitleBox>
    );
};

export default IssueMT5OrderType;
