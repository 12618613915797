import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import { Empty, Form, Modal } from "antd";
import { useEffect, useState } from "react";
import SummaryBar from "../../../../../components/Common/SummaryBar";
import { FormComponent } from "../../../../../components/FormComponent";
import LoadingComponent from "../../../../../components/Loading";
import { ComponentType, SUCCESS_FAILED } from "../../../../../constants";
import { AccountSymbolSummaryData, AsOfNowAccSymGroupedData, MetricGroup, SymbolAnalysisFilterParams } from "../../../../../constants/type";
import { apiRequest, APIs } from "../../../../../services/apiConfig";
import { ErrorCatchValidator, ErrorMessageHandler } from "../../../../../utils/Common";
import { ProfileInfoProps } from "../../viewProfile";
import EmptyData from "../../../../../components/Common/Empty";
import CustomSkeleton from "../../../../../components/Common/Skeleton";

export interface AccountSymbolSummaryPanelProps extends ProfileInfoProps {
    forPrint?: boolean;
    params: SymbolAnalysisFilterParams;
    selectedSymbol: string;
    metricCategories: MetricGroup[];
}

const AccountSymbolSummaryPanel = (props: AccountSymbolSummaryPanelProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const [summaryDatas, setSummaryDatas] = useState<AccountSymbolSummaryData[]>([
        {
            symbol: "",
            groupedData: [],
            seq: 0,
        },
    ]);
    const [searchKey, setSearchKey] = useState<string>("");
    const [metricfilterForm] = Form.useForm();

    useEffect(() => {
        if (props.selectedSymbol) {
            setIsLoading(true);
            delete props.params.dateFrom;
            delete props.params.dateTo;

            apiRequest(APIs.GET_ACCOUNT_SUMMARY_PANEL, {
                ...props.params,
                metricType: 2,
                dataGroupingMode: 1,
                isMetricSummary: true,
            })
                .then((data: any) => {
                    if (data?.length > 0) {
                        let newData: any[] = data.filter((currStat: any) => currStat.symbol === props.selectedSymbol);
                        setSummaryDatas(newData);
                    } else {
                        setSummaryDatas([
                            {
                                symbol: "",
                                groupedData: [],
                                seq: 0,
                            },
                        ]);
                    }
                })
                .catch((error: any) =>
                    ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("account symbol summary data", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
                )
                .finally(() => setIsLoading(false));
        }
        return () => {};
    }, [props.selectedSymbol]);

    const getFilteredMetric = (searchKey: string, metrics: AsOfNowAccSymGroupedData[]) => {
        let lowerSearchText = searchKey.toLowerCase();
        return searchKey.length > 0 ? metrics.filter((x) => x.metricName.toLowerCase().indexOf(lowerSearchText) > -1) : metrics;
    };

    const viewAllMetrics = (metrics: AsOfNowAccSymGroupedData[], groups: MetricGroup[], sKey: string) => {
        let filteredMetric = getFilteredMetric(sKey, metrics);
        let availableGroupList = filteredMetric.reduce((gList: number[], m: AsOfNowAccSymGroupedData) => {
            if (m.categoryIds && m.categoryIds.length > 0) {
                m.categoryIds.forEach((c) => {
                    if (!gList.includes(c)) {
                        gList.push(c);
                    }
                });
            }

            return gList;
        }, []);

        let groupWithNewMetric = [...groups, { id: 0, name: "New Metric", description: "New Metric", orderSeq: 0 } as MetricGroup];
        let finalGroup = groupWithNewMetric.filter((x) => availableGroupList.includes(x.id));
        finalGroup.sort((a: MetricGroup, b: MetricGroup) => a.orderSeq - b.orderSeq);

        return finalGroup.map((grp: MetricGroup) => (
            <div className="account-summary-group" key={`asmgpop-${grp.id}`}>
                <div className="group-title-container">
                    <span>{grp.name}</span>
                </div>
                <div className="metric-container">
                    {filteredMetric
                        .filter((y) => y.categoryIds.includes(grp.id))
                        .map((currData: any) => (
                            <SummaryBar
                                key={`ao-as-${currData.metricId}`}
                                iconType={currData.formatType}
                                customIcon={props.currency}
                                name={currData.metricName}
                                value={currData.value}
                                forPrint={props.forPrint}
                                desc={currData.metricDesc}
                            />
                        ))}
                </div>
            </div>
        ));
    };

    const isViewSummaryOnly = (metrics: AsOfNowAccSymGroupedData[]) => {
        let finalMetrics = metrics.filter((x) => x.isView === true);
        finalMetrics.sort((a: any, b: any) => a.orderSeq - b.orderSeq);
        return finalMetrics;
    };

    const onFilterValueChanged = (values: any) => setSearchKey(values["searchKey"]);

    useEffect(() => {
        if (props.params.symbols === null) {
            setSummaryDatas([
                {
                    symbol: "",
                    groupedData: [],
                    seq: 0,
                },
            ]);
        }
        return () => {};
    }, []);

    return (
        <>
            <div className={`account-summary-panel mt-10 ${props.forPrint ? "for-print" : ""}`}>
                <div className="top">
                    <h1 className="title">Account Symbol Summary</h1>
                    {!props.forPrint && summaryDatas[0]?.groupedData?.length > 0 && (
                        <EyeOutlined
                            style={{ fontSize: 20 }}
                            onClick={() => {
                                setSearchKey("");
                                metricfilterForm.setFieldsValue({ searchKey: "" });
                                setOpen(true);
                            }}
                        />
                    )}
                </div>
                {isLoading ? (
                    <div className={`metric-items-container ${props.forPrint ? "repeat-4" : ""}`}>
                        {Array.from({ length: 10 }, () => (
                            <CustomSkeleton key={`sk-${Math.random()}`} type="square" size="large" />
                        ))}
                    </div>
                ) : summaryDatas[0]?.groupedData?.length > 0 ? (
                    <div className={`metric-items-container ${props.forPrint ? "repeat-4" : ""}`}>
                        {isViewSummaryOnly(summaryDatas[0]?.groupedData).map((currData: any) => (
                            <SummaryBar
                                key={`ao-as-${currData.metricId}`}
                                iconType={currData.formatType}
                                customIcon={props.currency}
                                name={currData.metricName}
                                value={currData.value}
                                forPrint={props.forPrint}
                                desc={currData.metricDesc}
                            />
                        ))}
                    </div>
                ) : (
                    <EmptyData />
                )}
            </div>
            <Modal
                style={{ top: 20 }}
                open={open}
                width={"55%"}
                title={"Account Symbol Summary (All)"}
                okButtonProps={{
                    style: {
                        display: "none",
                    },
                }}
                cancelText="Close"
                onCancel={() => {
                    setOpen(false);
                }}
            >
                <Form
                    form={metricfilterForm}
                    colon={false}
                    labelCol={{ span: 16 }}
                    wrapperCol={{ span: 8 }}
                    layout="horizontal"
                    onValuesChange={onFilterValueChanged}
                >
                    <div className="search-panel">
                        <FormComponent
                            label={" "}
                            name={"searchKey"}
                            extra={{
                                type: ComponentType.text,
                                value: "",
                                itemProps: {
                                    style: {
                                        marginBottom: "0px",
                                    },
                                },
                                inputProps: {
                                    prefix: <SearchOutlined />,
                                    allowClear: true,
                                    placeholder: "search text",
                                },
                            }}
                        />
                    </div>
                </Form>
                <div className={`account-summary-panel ${props.forPrint ? "for-print" : ""}`}>
                    <div className={`metric-items-container pop-up-grid ${props.forPrint ? "repeat-4" : ""}`}>
                        {viewAllMetrics(summaryDatas[0]?.groupedData || [], props.metricCategories, searchKey)}
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default AccountSymbolSummaryPanel;
