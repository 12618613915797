import FlexiDataTable from "@/components/FlexiDataTable";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler, currencyRender } from "@/utils/Common";
import { Modal } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FundingProcessProps } from ".";
import { SUCCESS_FAILED } from "@/constants";
import { DefaultIfEmpty } from "@/utils/object";
import { KeyValuePair } from "@/constants/type";

export interface FundingFeeProcessTradeDataModalProps {
    isModalVisible: boolean;
    callback: (type: number, data: any) => void;
    data: FundingProcessProps | any;
    servers: KeyValuePair[];
}

export enum FundingFeeProcessTradeDataModalCallbackKey {
    Close = 0,
}

interface BasicProps {
    title: string;
    isBTC: boolean;
}

const FundingFeeProcessTradeDataModal = (props: FundingFeeProcessTradeDataModalProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<any[]>([]);

    const basicProps: BasicProps = useMemo(() => {
        if (props.data === undefined) return { title: "Funding Fee Trades", isBTC: false };

        let serverNo = DefaultIfEmpty(props.data, "serverUno", ""),
            foundServer = props.servers.find((x: KeyValuePair) => `${x.value}` === `${serverNo}`);

        return {
            title: `${foundServer ? foundServer.text : serverNo}-${DefaultIfEmpty(props.data, "receiveDate", "")}-${DefaultIfEmpty(
                props.data,
                "login",
                ""
            )} Funding Fee Trades`,
            isBTC: `${DefaultIfEmpty(props.data, "group", "")}`.endsWith("_BTC"),
        };
    }, [props.data, props.servers]);

    const detailColumns: any[] = useMemo(
        () => [
            {
                title: "Ticket",
                dataIndex: "ticket",
                key: "ticket",
            },
            {
                title: "Symbol",
                dataIndex: "symbol",
                key: "symbol",
            },
            {
                title: "Clean Symbol",
                dataIndex: "cleanSymbol",
                key: "cleanSymbol",
            },
            DTColProps.XSCurrency({
                title: "Volume",
                dataIndex: "volume",
                key: "volume",
                render: (volume: number) => currencyRender(volume.toFixed(2), 2),
            }),
            DTColProps.XXSmall({
                title: "CMD",
                dataIndex: "cmd",
                key: "cmd",
                render: (cmd: number) => (cmd === 0 ? "Buy" : "Sell"),
            }),
            DTColProps.SCurrency({
                title: "Exchange Rate",
                dataIndex: "exchangeRate",
                key: "exchangeRate",
                render: (exchangeRate: number) => currencyRender(exchangeRate.toFixed(8), 8),
            }),
            DTColProps.SCurrency(
                {
                    width: "6vw",
                    title: "Contract Size",
                    dataIndex: "contractSize",
                    key: "contractSize",
                },
                [],
                -1
            ),
            DTColProps.XSCurrency({
                title: "Point Size",
                dataIndex: "pointSize",
                key: "pointSize",
                render: (closePrice: number) => currencyRender(closePrice.toFixed(5), 5),
            }),
            DTColProps.SCurrency({
                title: "Close Price",
                dataIndex: "closePrice",
                key: "closePrice",
                render: (closePrice: number) => currencyRender(closePrice.toFixed(5), 5),
            }),
            DTColProps.SCurrency({
                title: "Funding Rate",
                dataIndex: "fundingRate",
                key: "fundingRate",
                render: (fundingRate: number) => currencyRender(fundingRate.toFixed(8), 8),
            }),

            DTColProps.SCurrency({
                width: "6vw",
                title: "Swap",
                dataIndex: "swap",
                key: "swap",
                // render: (swap: number) => currencyRender(swap.toFixed(basicProps.isBTC ? 8 : 2)),
                render: (swap: number) => currencyRender(swap.toFixed(8), 8),
            }),
        ],
        [basicProps]
    );

    const getTradesData = useCallback(() => {
        setIsLoading(true);
        let params = {
            serverUno: props.data.serverUno,
            receiveDate: props.data.receiveDate,
            login: props.data.login,
            receiveHour: props.data.receiveHour,
        };
        plainAxiosInstance
            .get(APIs.RISK_TOOL.GET_FUNDING_FEE_PROCESS_TRADES, { params })
            .then((res: any) => {
                if (res.data && res.data.length > 0) {
                    setData(res.data);
                } else {
                    setData([]);
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("funding fee process trade", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsLoading(false));
    }, [props.data]);

    useEffect(() => {
        if (props.isModalVisible) {
            setData([]);
            getTradesData();
        }
    }, [props.isModalVisible]);

    return (
        <Modal
            style={{ top: 10 }}
            width={"80vw"}
            title={basicProps.title}
            open={props.isModalVisible}
            okButtonProps={{ style: { display: "none" } }}
            onCancel={() => props.callback(FundingFeeProcessTradeDataModalCallbackKey.Close, null)}
            maskClosable={false}
        >
            <FlexiDataTable
                bordered
                title={false}
                loading={isLoading}
                columns={detailColumns}
                options={{
                    enableFilter: false,
                    showHideColumns: false,
                }}
                dataSource={data}
            />
        </Modal>
    );
};

export default FundingFeeProcessTradeDataModal;
