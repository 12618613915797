import FlexiDataTable from "../../../components/FlexiDataTable";
import { useEffect, useState } from "react";
import {
    FlexiDataTableCallbackProps,
    HFTOpenClosesList,
    HFTTradeSidesList,
    HFTTypesList,
    FlexiDataColumnProps,
    CustomPaginationProps,
} from "../../../constants/type";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "../../../constants";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";
import { apiRequest } from "../../../services/apiConfig";
import { APIs } from "../../../services/apis";
import { Tag } from "antd";
import moment from "moment-timezone";

export interface HftDetailViewProps {
    data?: SelectedAccountInfoProps;
    refreshRate: number;
    onRefreshRateCallback: (seconds: number) => void;

    HFTTypesList: HFTTypesList[];
    HFTTradeSidesList: HFTTradeSidesList[];
    HFTOpenClosesList: HFTOpenClosesList[];
}

export interface SelectedAccountInfoProps {
    userId: number;
    hftType: number;
    symbol: string;
    transactionDateTimeFrom: string;
    transactionDateTimeTo: string;
}

const HftDetailView = (props: HftDetailViewProps) => {
    const columnsConfig = (
        HFTTypesList: HFTTypesList[] = [],
        HFTTradeSidesList: HFTTradeSidesList[] = [],
        HFTOpenClosesList: HFTOpenClosesList[] = []
    ) => {
        return [
            DTColProps.XSmall({
                title: "User ID",
                dataIndex: "userId",
                key: "userId",
            }),

            DTColProps.Middle({
                title: "Order ID",
                dataIndex: "orderId",
                key: "orderId",
                sorter: (a: any, b: any) => a["orderId"] - b["orderId"],
            }),

            DTColProps.XSmall({
                title: "Trade ID",
                dataIndex: "tradeId",
                key: "tradeId",
                sorter: (a: any, b: any) => a["tradeId"] - b["tradeId"],
            }),

            DTColProps.XSmall({
                title: "Symbol",
                dataIndex: "symbol",
                key: "symbol",
                sorter: (a: any, b: any) => a["side"].localeCompare(b["side"]),
            }),

            DTColProps.XSmall({
                title: "HFT Type",
                dataIndex: "hftType",
                key: "hftType",
                sorter: (a: any, b: any) => a["hftType"].localeCompare(b["hftType"]),
                render: (hftType: number) => {
                    let fidx: number = HFTTypesList.findIndex((x) => x.id === hftType);
                    if (fidx > -1) return HFTTypesList[fidx].name;
                    return hftType;
                },
                // options: {
                //     filter: {
                //         type: ComponentType.dropdown,
                //         value: HFTTypesList.map((x) => ({ text: x.name, value: x.id })),
                //         inputProps: {
                //             mode: "multiple",
                //         },
                //     },
                // },
            }),

            DTColProps.XSmall({
                title: "Type",
                dataIndex: "type",
                key: "type",
                sorter: (a: any, b: any) => a["type"].localeCompare(b["type"]),
            }),

            DTColProps.XSmall({
                title: "Side",
                dataIndex: "side",
                key: "side",
                sorter: (a: any, b: any) => a["side"].localeCompare(b["side"]),
                render: (side: number) => {
                    let fidx: number = HFTTradeSidesList.findIndex((x) => x.id === side);
                    if (fidx > -1)
                        return (
                            <Tag color={HFTTradeSidesList[fidx].name.toUpperCase() === "BUY" ? "success" : "error"}>
                                {HFTTradeSidesList[fidx].name}
                            </Tag>
                        );
                    return <Tag color={side.toString().toUpperCase() === "BUY" ? "success" : "error"}>{side}</Tag>;
                },
                // options: {
                //     filter: {
                //         type: ComponentType.dropdown,
                //         value: HFTTradeSidesList.map((x) => ({ text: x.name, value: x.value })),
                //         inputProps: {
                //             mode: "multiple",
                //         },
                //     },
                // },
            }),

            DTColProps.XSmall({
                title: "Open Close",
                dataIndex: "openClose",
                key: "openClose",
                sorter: (a: any, b: any) => a["openClose"].localeCompare(b["openClose"]),
                render: (openClose: number) => {
                    let fidx: number = HFTOpenClosesList.findIndex((x) => x.id === openClose);
                    if (fidx > -1) return HFTOpenClosesList[fidx].name;
                    return openClose;
                },
                // options: {
                //     filter: {
                //         type: ComponentType.dropdown,
                //         value: HFTOpenClosesList.map((x) => ({ text: x.name, value: x.value })),
                //         inputProps: {
                //             mode: "multiple",
                //         },
                //     },
                // },
            }),

            DTColProps.SCurrency(
                {
                    title: "Price",
                    dataIndex: "price",
                    key: "price",
                    sorter: (a: any, b: any) => a["price"] - b["price"],
                },
                ["text-right"],
                -1
            ),

            DTColProps.XSmall(
                {
                    title: "HFT Volume",
                    dataIndex: "hftVolume",
                    key: "hftVolume",
                    sorter: (a: any, b: any) => a["hftVolume"] - b["hftVolume"],
                },
                ["text-right"]
            ),

            DTColProps.XSmall(
                {
                    title: "Volume",
                    dataIndex: "volume",
                    key: "volume",
                    sorter: (a: any, b: any) => a["volume"] - b["volume"],
                },
                ["text-right"]
            ),

            DTColProps.DateTime_ServerTime({
                title: "Transaction Date Time (UTC)",
                dataIndex: "transactionDateTime",
                key: "transactionDateTime",
                sorter: true,
                options: {
                    filter: {
                        type: ComponentType.daterange,
                        value: "",
                        inputProps: {
                            showTime: { format: "HH:mm:ss" },
                        },
                    },
                },
            }),
        ];
    };

    const [data, setData] = useState<any[]>([]);
    const [tableCols, setTableCols] = useState<FlexiDataColumnProps[]>([
        ...columnsConfig(props.HFTTypesList, props.HFTTradeSidesList, props.HFTOpenClosesList),
    ]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [pagination, setPagination] = useState<CustomPaginationProps>({
        current: 1,
        pageSize: 50,
        total: 0,
    });
    const [filterParams, setFilterParams] = useState<any>({
        transactionDateTimeFrom: props.data?.transactionDateTimeFrom,
        transactionDateTimeTo: props.data?.transactionDateTimeTo,
    });
    const [sorting, setSorting] = useState<string>("");
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(true);

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.REFRESH_RATE_CHANGED:
                props.onRefreshRateCallback && props.onRefreshRateCallback(FormData.currentRefreshPeriod);
                break;
            case CALLBACK_KEY.REFRESH:
                props.data && getDetailsList(props.data);
                break;
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                let filterParams: any = {};
                Object.keys(FormData)
                    .filter((x) => FormData[x] && (Array.isArray(FormData[x]) ? FormData[x].length > 0 : FormData[x].toString().length > 0))
                    .map((x) => {
                        if (x === "transactionDateTime") {
                            filterParams["transactionDateTimeFrom"] = FormData[x][0];
                            filterParams["transactionDateTimeTo"] = FormData[x][1];
                        } else {
                            filterParams[x] = FormData[x];
                        }
                        return x;
                    });
                setFilterParams(filterParams);
                setPagination((prev) => ({ ...prev, current: 1 }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.HANDLE_PAGINATION_SORTING:
                setSorting(
                    FormData.sorter.column && FormData.sorter.order
                        ? `${FormData.sorter.column.key},${FormData.sorter.order === "ascend" ? "asc" : "desc"}`
                        : ""
                );
                setPagination((prev) => ({ ...prev, current: FormData.pagination.current, pageSize: FormData.pagination.pageSize }));
                setRunRefetchDataList(true);
                break;
        }
    };

    const getDetailsList = (param: SelectedAccountInfoProps) => {
        apiRequest(APIs.GET_HFT, {
            userId: param.userId,
            hftType: param.hftType,
            symbol: param.symbol,
            limit: pagination.pageSize,
            current: pagination.current,
            ...(Object.keys(filterParams).length > 0 && { ...filterParams }),
            ...(sorting.length > 0 && { order: sorting }),
        })
            .then((data: any) => {
                setData(data.result);
                setPagination((prev) => ({ ...prev, total: data.total }));
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("HFT-BITI detail view", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                setData([]);
                setPagination((prev) => ({ ...prev, total: 0 }));
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            props.data && getDetailsList(props.data);
            setRunRefetchDataList(false);
        }
        return () => {};
    }, [runRefetchDataList]);

    useEffect(() => {
        setTableCols(columnsConfig(props.HFTTypesList, props.HFTTradeSidesList, props.HFTOpenClosesList));
        return () => {};
    }, [props.HFTTypesList, props.HFTTradeSidesList, props.HFTOpenClosesList]);

    return (
        <>
            <FlexiDataTable
                rowKeyProperty="id"
                title={""}
                columns={tableCols}
                options={{
                    refresh: {
                        timer: true,
                        refreshSecond: props.refreshRate,
                        enablePeriodSelection: true,
                    },
                    serverFiltering: true,
                }}
                dataSource={data ?? []}
                callback={componentCallback}
                loading={isLoading}
                tableProps={{
                    bordered: true,
                }}
                pagination={pagination}
                serverSide={true}
                filterInitialValue={{
                    transactionDateTime: [moment(props.data?.transactionDateTimeFrom), moment(props.data?.transactionDateTimeTo)],
                }}
            />
        </>
    );
};

export default HftDetailView;
