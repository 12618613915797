import React from "react";
import TabContent from "./../components/TabContent";
import SoundButton from "./../components/SoundButton";
import soundRejectOrder from "../../../assets/audios/rc/rejectOrder.mp3"; // RC original sound
import Loader from "./../components/Loader";
import DownloadRejectOrderButton from "./DownloadRejectOrderButton";
import moment from "moment-timezone";
import { Alert, Button, Space, Table, Tag, notification } from "antd";
import useRCRejectOrders, { useRCRejectOrderSolved } from "../../../hooks/useRCRejectOrders";
import CommonPaddingWrapper from "../../SpreadReportPage/CommonPaddingWrapper";
import { isArray } from "lodash";
import { CheckCircleOutlined } from "@ant-design/icons";
import MessageCard from "../components/MessageCard";

const TabRejectOrder = () => {
    const { rcRejectOrders, isLoading } = useRCRejectOrders();
    const MIN_ALARM_COUNT_TO_ALERT = 10;
    const statusError = !isLoading && isArray(rcRejectOrders) ? rcRejectOrders?.some(item => item.status === 1) : false;
    const statusAlarmCount = !isLoading && isArray(rcRejectOrders) ? rcRejectOrders?.some(item => item.count >= MIN_ALARM_COUNT_TO_ALERT) : false;

    return (
        <TabContent>
            <div style={{ display: "flex", justifyContent: "flex-end", flexWrap: "wrap", gap: "4px", alignItems: "center", padding: "1rem" }}>
                <SoundButton soundSrc={soundRejectOrder} isActive={statusError || statusAlarmCount} />
                <DownloadRejectOrderButton />
            </div>
            <RejectOrderTable />
        </TabContent>
    );
};

const RejectOrderTable = () => {
    const { rcRejectOrders, rcRejectOrdersError, refetchRcRejectOrders, isLoading } = useRCRejectOrders();
    const { rejectOrderSolved } = useRCRejectOrderSolved();

    const handleResolve = async (server: string) => {
        try {
            const resp = await rejectOrderSolved({ server });

            console.log(resp);
            if (resp?.data === 0) {
                notification.success({
                    message: "Solved success",
                    description: `Solved reject orders of server ${server} successfully.`,
                });
            } else {
                const msg =
                    resp.data === 1
                        ? "Reject order data of server not found."
                        : resp.data === 2
                        ? "Save record data error."
                        : resp.data === 3
                        ? "Reset reject order data error."
                        : "Unknown error";
                notification.error({
                    message: "Solved failed",
                    description: `Solved reject orders of server ${server} failed. ${msg}`,
                });
            }
        } catch (error) {
            console.error("Error on solving reject orders:", error);
            notification.error({
                message: "Solved failed",
                // @ts-ignore
                description: `Solved reject orders of server ${server} failed. ${error?.message ?? error}`,
            });
        }
    };

    const columns = [
        {
            title: "Server",
            dataIndex: "mainServerId",
            sorter: (a: any, b: any) => {
                // sort alphabetically and length
                if (a.mainServerId?.toLowerCase() < b.mainServerId?.toLowerCase()) {
                    return -1;
                }
                if (a.mainServerId?.toLowerCase() > b.mainServerId?.toLowerCase()) {
                    return 1;
                }
                return 0;
            },
        },
        {
            title: "Status",
            dataIndex: "status",
            render: (status: number) => <Tag color={status === 0 ? "green" : "red"}>{status === 0 ? "normal" : "warning"}</Tag>,
            sorter: (a: any, b: any) => a.status - b.status,
        },
        {
            title: "Reject Count",
            dataIndex: "count",

            render: (count: number) => (count > 0 ? count : "-"),
            sorter: (a: any, b: any) => a.count - b.count,
        },
        {
            title: "Update Time",
            dataIndex: "lastUpdate",

            render: (dateTime: Date) => moment(dateTime).format("YYYY-MM-DD HH:mm:ss"),
            sorter: (a: any, b: any) => moment(a.lastUpdate).valueOf() - moment(b.lastUpdate).valueOf(),
        },
        {
            title: "Action",

            dataIndex: "Action",
            render: (text: any, data: any, idx: any) => (
                <>
                    {/* button to solved */}
                    <Button
                        type="primary"
                        size="small"
                        // icon={<CheckCircleOutlined />}
                        onClick={e => handleResolve(data.mainServerId)}
                        disabled={data.count < 1 || isLoading}
                        loading={isLoading}
                    >
                        Solved
                    </Button>
                </>
            ),
        },
    ];

    const transformedData = rcRejectOrders?.map((item: any, index: number) => {
        return { ...item, key: index };
    });

    const totalWarning = rcRejectOrders?.filter((item: any) => item.status === 1).length;
    const totalCount = rcRejectOrders?.reduce((acc: number, item: { count: number }) => acc + item.count, 0);

    if (isLoading) {
        return <Loader />;
    }
    if (rcRejectOrdersError) {
        return (
            <CommonPaddingWrapper>
                <Alert
                    message={`Having some error while loading reject orders. Error: ${rcRejectOrdersError}`}
                    type="warning"
                    action={
                        <Button size="small" type="text" onClick={() => refetchRcRejectOrders()} disabled={isLoading}>
                            Retry
                        </Button>
                    }
                />
            </CommonPaddingWrapper>
        );
    }
    if (rcRejectOrders) {
        return (
            <CommonPaddingWrapper>
                {!rcRejectOrders && isLoading ? (
                    <Loader />
                ) : (
                    <Space direction="vertical">
                        <MessageCard type={totalWarning === 0 && totalCount === 0 ? "success" : "error"}>
                            {totalWarning === 0 && totalCount === 0 ? (
                                "All good"
                            ) : (
                                <>
                                    {totalWarning} warning{totalWarning >= 2 && "s"}
                                    <br />
                                    {totalCount} reject order{totalCount >= 2 && "s"}
                                </>
                            )}
                        </MessageCard>
                        <Table
                            columns={columns}
                            dataSource={transformedData}
                            loading={isLoading}
                            pagination={false}
                            size="small"
                            scroll={{ x: "max-content", y: 500 }}
                            // scroll={{ y: 500 }}
                            // add footer to show total count
                            footer={() => (
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <b>{rcRejectOrders?.length} servers</b>
                                    <b>
                                        Total reject orders: {rcRejectOrders?.reduce((acc: number, item: { count: number }) => acc + item.count, 0)}
                                    </b>
                                    <b>
                                        Total server with problems:
                                        {rcRejectOrders?.filter((item: { status: number }) => item.status === 1).length}
                                    </b>
                                </div>
                            )}
                        />
                    </Space>
                )}
            </CommonPaddingWrapper>
        );
    }
    return <>??</>;
};

export default TabRejectOrder;
