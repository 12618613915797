import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { APIs } from "../services/apis";
import { plainAxiosInstance } from "../services/axiosSetup";

/*
sample api resp
[{
    "serverUno": 519,
    "serverDn": null,
    "brandId": null,
    "name": "Exness-Pro",
    "isDel": null,
    "timeZone": null,
    "ip": null,
    "port": null,
    "type": 2,
    "startDate": null,
    "endDate": null,
    "nowTime": null,
    "ids": null,
    "brandName": null,
    "zoneId": null,
    "order": null,
    "serverAttributes": null
}]
 */
interface IRCPriceAlarmSettingServer {
    serverUno: number;
    serverDn: string | null;
    brandId: string | null;
    name: string;
    isDel: number | null;
    timeZone: string | null;
    ip: string | null;
    port: number | null;
    type: number;
    startDate: string | null;
    endDate: string | null;
    nowTime: string | null;
    ids: string | null;
    brandName: string | null;
    zoneId: string | null;
    order: number | null;
    serverAttributes: number | null;
}

export const useRCPriceAlarmSettingServers = () => {
    const { data, refetch, isLoading, error, isFetching, dataUpdatedAt } = useQuery({
        queryKey: ["rcPriceAlarmSettingServers"],
        queryFn: () =>
            plainAxiosInstance.get(`${APIs.RC_PRICE_SETTINGS.GET_PRICE_ALARM_SETTINGS_SERVERS}`, {
                headers: {
                    Accept: "application/json",
                },
            }),
        staleTime: 300 * 1000, //(10 x 1000ms = 10 seconds)
        // refetchInterval: 300 * 1000, //(60 x 1000ms = 60 seconds)
    });

    const rcPriceAlarmSettingServers: IRCPriceAlarmSettingServer[] = data?.data?.status ? [] : data?.data || [];

    // @ts-ignore
    const rcPriceAlarmSettingServersError: string | null = data?.data?.message || error?.message || error || null;

    return {
        rcPriceAlarmSettingServers,
        rcPriceAlarmSettingServersError,
        refetchRcPriceAlarmSettingServers: refetch,
        isFetching,
        isLoading,
        dataUpdatedAt,
    };
};

/* sample resp for symbol types 
[{
  "id": 1,
  "name": "Forex",
  "sort": 3,
  "isDel": 0,
  "value": null
}]
*/

interface IRCPriceAlarmSettingSymbolType {
    id: number;
    name: string;
    sort: number;
    isDel: number;
    value: string | null;
}

export const useRCPriceAlarmSettingSymbolTypes = () => {
    const { data, refetch, isLoading, error, isFetching, dataUpdatedAt } = useQuery({
        queryKey: ["rcPriceAlarmSettingSymbolTypes"],
        queryFn: () =>
            plainAxiosInstance.get(`${APIs.RC_PRICE_SETTINGS.GET_PRICE_ALARM_SETTINGS_SYMBOL_TYPES}`, {
                headers: {
                    Accept: "application/json",
                },
            }),
        staleTime: 300 * 1000, //(10 x 1000ms = 10 seconds)
        // refetchInterval: 300 * 1000, //(60 x 1000ms = 60 seconds)
    });

    const rcPriceAlarmSettingSymbolTypes: IRCPriceAlarmSettingSymbolType[] = data?.data?.status ? [] : data?.data || [];

    // @ts-ignore
    const rcPriceAlarmSettingSymbolTypesError: string | null = data?.data?.message || error?.message || error || null;

    return {
        rcPriceAlarmSettingSymbolTypes,
        rcPriceAlarmSettingSymbolTypesError,
        refetchRcPriceAlarmSettingSymbolTypes: refetch,
        isFetching,
        isLoading,
        dataUpdatedAt,
    };
};

/*
sample resp for alarm setting details
[{
    "id": 0,
    "serverUno": 3,
    "symbol": "Cotton-C",
    "cleanSymbol": "Cotton",
    "noPriceTime": 900000,
    "openNoPrice": 900,
    "monday": "04:00-21:20",
    "tuesday": "04:00-21:20",
    "wednesday": "04:00-21:20",
    "thursday": "04:00-21:20",
    "friday": "04:00-21:20",
    "saturday": "00:00-00:00",
    "sunday": "00:00-00:00",
    "digit": 5,
    "showStatus": 0,
    "alertStatus": 0,
    "forceSession": 0,
    "pointSite": 0,
    "modifyTime": "2024-09-09T21:00:00.000+00:00",
    "isNew": 0,
    "serverName": "UK",
    "serverType": 0,
    "typeName": "Commodity"
}]
 */

export interface IRCPriceAlarmSettingDetails {
    id: number;
    serverUno: number;
    symbol: string;
    cleanSymbol: string;
    noPriceTime: number;
    openNoPrice: number;
    monday: string;
    tuesday: string;
    wednesday: string;
    thursday: string;
    friday: string;
    saturday: string;
    sunday: string;
    digit: number;
    showStatus: number;
    alertStatus: number;
    forceSession: number;
    pointSite: number;
    modifyTime: string;
    isNew: number;
    serverName: string;
    serverType: number;
    typeName: string;
}

export const useRCPriceAlarmSettingDetails = ({ server }: { server: string | number | null }) => {
    const { data, refetch, isLoading, error, isFetching, dataUpdatedAt } = useQuery({
        queryKey: ["rcPriceAlarmSettingDetails", server],
        queryFn: () =>
            plainAxiosInstance.get(`${APIs.RC_PRICE_SETTINGS.GET_PRICE_ALARM_SETTINGS_DETAILS}/${server}`, {
                headers: {
                    Accept: "application/json",
                },
            }),
        staleTime: 60 * 1000, //(10 x 1000ms = 10 seconds)
        refetchInterval: 60 * 1000, //(60 x 1000ms = 60 seconds)
        enabled: !!server,
    });

    const rcPriceAlarmSettingDetails: IRCPriceAlarmSettingDetails[] = data?.data?.status ? [] : data?.data || [];

    // @ts-ignore
    const rcPriceAlarmSettingDetailsError: string | null = data?.data?.message || error?.message || error || null;

    return {
        rcPriceAlarmSettingDetails,
        rcPriceAlarmSettingDetailsError,
        refetchRcPriceAlarmSettingDetails: refetch,
        isFetching,
        isLoading,
        dataUpdatedAt,
    };
};
// mutation
export const useRCPriceAlarmSettingMutation = ({ server }: { server: string | number | null }) => {
    const queryClient = useQueryClient();

    // // Delete No Money Log
    // sample
    // const {
    //     mutateAsync: deleteIssueLogNoMoney,
    //     isLoading: isLoadingDeleteIssueLogNoMoney,
    //     error: deleteIssueLogNoMoneyError,
    // } = useMutation(
    //     async (uniqueKeys: string[]): Promise<any> => {
    //         const formData = new FormData();
    //         formData.append("uniqueKeys", uniqueKeys.join(","));
    //         if (uniqueKeys.length === 0) {
    //             return Promise.reject("select at least 1 server");
    //         }
    //         return plainAxiosInstance.post(APIs.RC_ISSUES_LOG.POST_ISSUES_LOG_DELETE_NOMONEY, formData, {
    //             headers: {
    //                 Accept: "application/json",
    //             },
    //         });
    //     },
    //     {
    //         onSuccess: res => {
    //             queryClient.invalidateQueries(["rcIssuesLog", "NO_MONEY"]);
    //             // console.log(`Solving reject orders response 200`, res);
    //             return res;
    //         },
    //         onError: error => {
    //             queryClient.invalidateQueries(["rcIssuesLog", "NO_MONEY"]);
    //             console.error(`Error on deleting No Money Log:`, error);
    //             return error;
    //         },
    //     }
    // );

    // post to update details, follow above sample
    const {
        mutateAsync: updatePriceAlarmSetting,
        isLoading: isLoadingUpdatePriceAlarmSetting,
        error: updatePriceAlarmSettingError,
    } = useMutation(
        async (data: IRCPriceAlarmSettingDetails[]): Promise<any> => {
            return plainAxiosInstance.post(APIs.RC_PRICE_SETTINGS.GET_PRICE_ALARM_SETTINGS_DETAILS, data, {
                headers: {
                    Accept: "application/json",
                },
            });
        },
        {
            onSuccess: res => {
                queryClient.invalidateQueries(["rcPriceAlarmSettingDetails", server]);
                return res;
            },
            onError: error => {
                queryClient.invalidateQueries(["rcPriceAlarmSettingDetails", server]);
                console.error(`Error on updating Price Alarm Setting:`, error);
                return error;
            },
        }
    );

    // delete details, follow above sample, using .delete

    const {
        mutateAsync: deletePriceAlarmSetting,
        isLoading: isLoadingDeletePriceAlarmSetting,
        error: deletePriceAlarmSettingError,
    } = useMutation(
        async (data: IRCPriceAlarmSettingDetails[]): Promise<any> => {
            return plainAxiosInstance.delete(APIs.RC_PRICE_SETTINGS.GET_PRICE_ALARM_SETTINGS_DETAILS, {
                data,
                headers: {
                    Accept: "application/json",
                },
            });
        },
        {
            onSuccess: res => {
                queryClient.invalidateQueries(["rcPriceAlarmSettingDetails", server]);
                return res;
            },
            onError: error => {
                queryClient.invalidateQueries(["rcPriceAlarmSettingDetails", server]);
                console.error(`Error on deleting Price Alarm Setting:`, error);
                return error;
            },
        }
    );

    return {
        updatePriceAlarmSetting,
        isLoadingUpdatePriceAlarmSetting,
        updatePriceAlarmSettingError,

        deletePriceAlarmSetting,
        isLoadingDeletePriceAlarmSetting,
        deletePriceAlarmSettingError,
    };
};
