import React, { useState } from "react";
import { useRCBalanceAdjustmentMutate, useRCBalanceAdjustmentSettings } from "@/hooks/useRCBalanceAdjustment";
import { Upload, Modal, message, Table, Input, Space } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { matchSorter } from "match-sorter";
const { Dragger } = Upload;

const BalanceAdjustmentServers = () => {
    const [fileList, setFileList] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentFile, setCurrentFile] = useState(null);
    const { rcBalanceAdjustmentSettings, isLoading } = useRCBalanceAdjustmentSettings();
    const [search, setSearch] = useState("");
    const result = matchSorter(rcBalanceAdjustmentSettings, search, {
        keys: ["serverName", "login", "ip", "name"],
    });
    const { mutateUploadBalanceAdjustmentSettings, isLoadingUploadBalanceAdjustmentSettings } = useRCBalanceAdjustmentMutate();

    const handleUpload = async () => {
        if (!currentFile) return;

        const formData = new FormData();
        formData.append("uploadFile", currentFile);

        try {
            const resp = await mutateUploadBalanceAdjustmentSettings(formData);
            if (resp.data === 0) {
                message.success("File uploaded successfully");
            } else {
                message.error("Upload failed");
            }
        } catch (error) {
            message.error("Upload failed");
        } finally {
            setFileList([]);
            setCurrentFile(null);
        }
        setIsModalVisible(false);
    };

    const validateFile = (file: File) => {
        const isXlsx = file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        if (!isXlsx) {
            message.error("You can only upload XLSX files!");
            return false;
        }

        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
            message.error("File must be smaller than 5MB!");
            return false;
        }

        return true;
    };

    const props = {
        name: "file",
        multiple: false,
        fileList,
        beforeUpload: (file: any) => {
            // console.log(file);
            // console.log(validateFile(file));
            if (validateFile(file)) {
                setCurrentFile(file);
                setIsModalVisible(true);
                return true;
            }
            setFileList([]);
            setCurrentFile(null);
            return false;
        },
        onChange: (info: any) => {
            setFileList(info.fileList.slice(-1));
        },
    };

    return (
        <Space style={{ width: "100%" }} direction="vertical">
            <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">extension support: .xlsx</p>
                <p className="ant-upload-hint">maximum file size: 5MB</p>
            </Dragger>
            <Input placeholder="Search" value={search} onChange={e => setSearch(e.target.value)} allowClear />
            <Table
                dataSource={result}
                columns={[
                    {
                        title: "Server Name",
                        dataIndex: "serverName",
                        key: "serverName",
                    },
                    {
                        title: "Login",
                        dataIndex: "login",
                        key: "login",
                    },
                    {
                        title: "Name",
                        dataIndex: "name",
                        key: "name",
                    },
                    {
                        title: "IP",
                        dataIndex: "ip",
                        key: "ip",
                    },
                ]}
                pagination={{
                    defaultPageSize: 50,
                    pageSizeOptions: ["10", "20", "50", "100", "500", "1000"],
                }}
                rowKey="serverUno"
                size="small"
                loading={isLoading || isLoadingUploadBalanceAdjustmentSettings}
            />

            <Modal
                title="Confirm Upload"
                open={isModalVisible}
                onOk={handleUpload}
                onCancel={() => {
                    setIsModalVisible(false);
                    setFileList([]);
                    setCurrentFile(null);
                }}
                confirmLoading={isLoadingUploadBalanceAdjustmentSettings}
            >
                <p>Are you sure you want to upload this file?</p>
                {/* @ts-ignore */}
                <p>{currentFile?.name}</p>
            </Modal>
        </Space>
    );
};

export default BalanceAdjustmentServers;
