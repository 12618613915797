import React, { useEffect, useState } from "react";
import { HFTThreshold, HFTThresholdRes } from "./index";
import { Button, Card, Col, Form, Row, Space, Typography } from "antd";
import { FormComponent } from "../../../components/FormComponent";
import { ComponentType, SUCCESS_FAILED } from "../../../constants";
import { REQUIRED_FIELD } from "../../../constants/errorMessage";
import { plainAxiosInstance } from "../../../services/axiosSetup";
import { APIs } from "../../../services/apis";
import { ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";

const { Title } = Typography;

type SettingsTabProps = {
    hftThreshold: HFTThreshold;
    refreshData: () => void;
};

const dataRows: {
  section: string;
  data: { label: string; name: keyof HFTThreshold }[];
}[] = [
  {
    section: 'Scream Threshold(Red/Orange)',
    data: [
      { label: "Less than 2 min", name: "profit2min" },
      { label: "Between 2 and 10 min", name: "profit10min" },
      { label: "Between 10 and 30 min", name: "profit30min" }
    ]
  },
  {
    section: 'Profit Threshold Setting(Red)',
    data: [
      { label: "Level 1", name: "threshold1" },
      { label: "Level 2", name: "threshold2" },
      { label: "Level 3", name: "threshold3" }
    ]
  },
  {
    section: '2 Min New Profit Threshold Setting(Orange)',
    data: [
      { label: "Level 1", name: "newprofit2minLevel1" },
      { label: "Level 1 HISTORICAL PNL / TODAY PROFIT", name: "newprofitpnl2min" },
      { label: "Level 2", name: "newprofit2minLevel2" }
    ]
  },
  {
    section: '10 Min New Profit Threshold Setting(Orange)',
    data: [
      { label: "Level 1", name: "newprofit10minLevel1" },
      { label: "Level 1 HISTORICAL PNL / TODAY PROFIT", name: "newprofitpnl10min" },
      { label: "Level 2", name: "newprofit10minLevel2" }
    ]
  },
  {
    section: '30 Min New Profit Threshold Setting(Orange)',
    data: [
      { label: "Level 1", name: "newprofit30minLevel1" },
      { label: "Level 1 HISTORICAL PNL / TODAY PROFIT", name: "newprofitpnl30min" },
      { label: "Level 2", name: "newprofit30minLevel2" }
    ]
  },
]
const SettingsTab = ({ hftThreshold, refreshData }: SettingsTabProps) => {
    const [settingsForm] = Form.useForm<HFTThreshold>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isTouched, setIsTouched] = useState<boolean>(false);

    useEffect(() => {
        if(isTouched) return;
        settingsForm.setFieldsValue(hftThreshold);
    }, [hftThreshold]);


    function onFormSubmit(values: HFTThreshold) {
      setIsTouched(false);
      setIsSubmitting(true);
      plainAxiosInstance
            .post(`${process.env.REACT_APP_API_URL}${APIs.RC_HFT_REPORT.POST_UPDATE_SETTINGS}`, prepareSettingsForm(values))
            .then(res => {
                if (res.data === 0) {
                    ErrorMessageHandler("HFT Threshold Settings", SUCCESS_FAILED.SUCCESS_UPDATE_DATA)
                    refreshData();
                } else {
                    ErrorMessageHandler("HFT Threshold Settings", SUCCESS_FAILED.FAILED_UPDATE_DATA)
                }
            })
            .catch(err => {
                ErrorCatchValidator(err, (error: any) => ErrorMessageHandler("HFT Threshold Settings", SUCCESS_FAILED.FAILED_UPDATE_DATA, error));
            })
            .finally(() => {
              setIsSubmitting(false);
            });
    }

    return (
        <Card>
            <Form 
              form={settingsForm} 
              layout="vertical"
              initialValues={hftThreshold}
              onFinish={onFormSubmit}
              onFieldsChange={()=> { if(!isTouched) setIsTouched(true)}}
            >
              <Space direction="vertical" size="large">
              {
                dataRows.map((row) => (
                  <Row key={row.section}>
                    <Col span={16}>
                        <Title level={3}>{row.section}</Title>
                    </Col>
                    <Row gutter={16}>
                      {
                        row.data.map((data) => (
                          <Col span={8} key={data.name}>
                              <FormComponent
                                  label={data.label}
                                  name={data.name}
                                  extra={{
                                      type: ComponentType.number,
                                      value: "",
                                      rules: [{ required: true, message: REQUIRED_FIELD }],
                                  }}
                              />
                          </Col>
                        ))
                      }
                    </Row>
                  </Row>
                ))
              }
                <Row>
                  <Button type="primary" htmlType="submit" loading={isSubmitting} disabled={!isTouched}>
                      Update
                  </Button>
                </Row>
              </Space>
            </Form>
        </Card>
    );
};

export default SettingsTab;

function prepareSettingsForm(t: HFTThreshold): HFTThresholdRes[] {
    const data: HFTThresholdRes[] = [];

    if (t.newprofit10minLevel1 !== undefined) {
        data.push({ type: "newprofit10min", level: 1, value: t.newprofit10minLevel1 });
    }
    if (t.newprofit10minLevel2 !== undefined) {
        data.push({ type: "newprofit10min", level: 2, value: t.newprofit10minLevel2 });
    }
    if (t.newprofit2minLevel1 !== undefined) {
        data.push({ type: "newprofit2min", level: 1, value: t.newprofit2minLevel1 });
    }
    if (t.newprofit2minLevel2 !== undefined) {
        data.push({ type: "newprofit2min", level: 2, value: t.newprofit2minLevel2 });
    }
    if (t.newprofit30minLevel1 !== undefined) {
        data.push({ type: "newprofit30min", level: 1, value: t.newprofit30minLevel1 });
    }
    if (t.newprofit30minLevel2 !== undefined) {
        data.push({ type: "newprofit30min", level: 2, value: t.newprofit30minLevel2 });
    }
    if (t.newprofitpnl10min !== undefined) {
        data.push({ type: "newprofitpnl10min", level: 1, value: t.newprofitpnl10min });
    }
    if (t.newprofitpnl2min !== undefined) {
        data.push({ type: "newprofitpnl2min", level: 1, value: t.newprofitpnl2min });
    }
    if (t.newprofitpnl30min !== undefined) {
        data.push({ type: "newprofitpnl30min", level: 1, value: t.newprofitpnl30min });
    }
    if (t.threshold1 !== undefined) {
        data.push({ type: "profit", level: 1, value: t.threshold1 });
    }
    if (t.threshold2 !== undefined) {
        data.push({ type: "profit", level: 2, value: t.threshold2 });
    }
    if (t.threshold3 !== undefined) {
        data.push({ type: "profit", level: 3, value: t.threshold3 });
    }
    if (t.profit10min !== undefined) {
        data.push({ type: "profit10min", level: 1, value: t.profit10min });
    }
    if (t.profit2min !== undefined) {
        data.push({ type: "profit2min", level: 1, value: t.profit2min });
    }
    if (t.profit30min !== undefined) {
        data.push({ type: "profit30min", level: 1, value: t.profit30min });
    }
    if (t.threshold4 !== undefined) {
        data.push({ type: "symbol", level: 1, value: t.threshold4 });
    }
    if (t.threshold5 !== undefined) {
        data.push({ type: "symbol", level: 2, value: t.threshold5 });
    }

    return data;
}
