import { Col, Form, FormInstance, Row } from "antd";
import React from "react";
import { FormComponent } from "../../../../../components/FormComponent";
import { ComponentType } from "../../../../../constants";

interface ClientRestrictionsProps {
    form: FormInstance;
};

const ClientRestrictions = (props: ClientRestrictionsProps) => {
    return (
        <div className="toxic-client-restriction-modal-form-container">
            <Form
                form={props.form}
                layout="horizontal"
                labelCol={{ span: 15 }}
                wrapperCol={{ span: 9 }}
                initialValues={{
                    closeAccount: false,
                    creditPromotion: false,
                    kyc: false,
                    leverage: false,
                    openAccount: false,
                    swap: false,
                    warningLetter: false,
                    withdrawBan: false,
                }}
            >
                <Row>
                    <Col span={9}>
                        <Row>
                            <Col span={24}>
                                <FormComponent label="" name="clientId" extra={{ type: "hidden", value: "" }} />
                                <FormComponent label="" name="crmServerId" extra={{ type: "hidden", value: "" }} />
                                <FormComponent label="" name="crmBrandId" extra={{ type: "hidden", value: "" }} />
                                <FormComponent label="" name="regulatorId" extra={{ type: "hidden", value: "" }} />
                                <FormComponent
                                    label="Close Account"
                                    name="closeAccount"
                                    extra={{
                                        type: ComponentType.switch,
                                        value: ["No", "Yes"],
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <FormComponent
                                    label="Warning Letter"
                                    name="warningLetter"
                                    extra={{
                                        type: ComponentType.switch,
                                        value: ["No", "Yes"],
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col span={7}>
                        <Row>
                            <Col span={24}>
                                <FormComponent
                                    label="Leverage"
                                    name="leverage"
                                    extra={{
                                        type: ComponentType.switch,
                                        value: ["No", "Yes"],
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <FormComponent
                                    label="KYC"
                                    name="kyc"
                                    extra={{
                                        type: ComponentType.switch,
                                        value: ["No", "Yes"],
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col span={8}>
                        <Row>
                            <Col span={24}>
                                <FormComponent
                                    label="Withdraw Ban"
                                    name="withdrawBan"
                                    extra={{
                                        type: ComponentType.switch,
                                        value: ["No", "Yes"],
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Row className="client-restriction-notes">
                            <Col span={24}>
                                <div className="section-title">Blacklist</div>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={9}>
                        <Row>
                            <Col span={24}>
                                <FormComponent
                                    label="Credit & Promotion"
                                    name="creditPromotion"
                                    extra={{
                                        type: ComponentType.switch,
                                        value: ["No", "Yes"],
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col span={7}>
                        <Row>
                            <Col span={24}>
                                <FormComponent
                                    label="Open Account"
                                    name="openAccount"
                                    extra={{
                                        type: ComponentType.switch,
                                        value: ["No", "Yes"],
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col span={8}>
                        <Row>
                            <Col span={24}>
                                <FormComponent
                                    label="Swap"
                                    name="swap"
                                    extra={{
                                        type: ComponentType.switch,
                                        value: ["No", "Yes"],
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default ClientRestrictions;