import { DualAxes, Datum } from "@ant-design/charts";
import { Breadcrumb, Modal } from "antd";
import LoadingComponent from "../../../components/Loading";
import { ErrorCatchValidator, ErrorMessageHandler, currencyRender } from "../../../utils/Common";
import { DateTimeUtil } from "../../../utils/datetime";
import { findObjectKeys } from "../../../utils/object";
import { useEffect, useState } from "react";
import moment from "moment";
import { SUCCESS_FAILED } from "../../../constants";
import { apiRequest } from "../../../services/apiConfig";
import { APIs } from "../../../services/apis";
import { ServersList } from "../../../constants/type";
import { HomeOutlined } from "@ant-design/icons";
import CardBox from "../../../components/Common/CardBox";
import ClosedDecayAccountView from "./accountView";
import ClosedDecayTicketView from "./ticketView";
import { TimeSeriesProps, SelectedAccountInfoProps } from "../DecayChart/ticketView";

export interface DecayChartSummaryProps {}

const ClosedDecayChartSummary = () => {
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [isLoadingChart, setIsLoadingChart] = useState<boolean>(true);
    const [chartData, setChartData] = useState<TimeSeriesProps[]>([]);
    const [viewType, setViewType] = useState<number>(1);
    const [ticketRefreshRate, setTicketRefreshRate] = useState<number>(30);
    const [servers, setServers] = useState<ServersList[]>([]);
    const [selectedAccountInfo, setSelectedAccountInfo] = useState<SelectedAccountInfoProps | undefined>(undefined);

    const getPNLSymbolChart = (data: TimeSeriesProps[]) => {
        let markupData: any[] = data
            .filter((x) => x.decaySecond > -1)
            .map((x) => ({
                date: x.decaySecond,
                uDate: DateTimeUtil.GetOrigin(moment(x.decayTime), "YYYY-MM-DD HH:mm:ss"),
                Profit: x.profit,
                DPM: x.dpm,
            }));
        markupData.sort((a: any, b: any) => a.date - b.date);
        return (
            <DualAxes
                {...{
                    data: [markupData, markupData],
                    padding: [30, 30, 20, 40],
                    xField: "uDate",
                    yField: ["Profit", "DPM"],
                    xAxis: {
                        tickLine: {
                            alignTick: true,
                        },
                        label: {
                            formatter: (_v: any, _d: any, i: number) => {
                                let fidx: number = markupData.findIndex((x) => x.uDate === _v);
                                return fidx > -1 ? markupData[fidx]["date"] : "";
                            },
                        },
                    },
                    slider: {
                        start: 0,
                        end: 1,
                    },
                    geometryOptions: [
                        {
                            smooth: true,
                        },
                        {
                            smooth: true,
                        },
                    ],
                    yAxis: {
                        Profit: {
                            nice: true,
                            title: {
                                text: "Profit",
                            },
                            label: {
                                formatter: (v: any) => currencyRender(v),
                            },
                        },
                        DPM: {
                            nice: true,
                            title: {
                                text: "DPM",
                            },
                            label: {
                                formatter: (v: any) => currencyRender(v),
                            },
                        },
                    },
                    tooltip: {
                        title: (_title: string, datum: Datum) => {
                            let spaces: string = Array(3).fill("\xa0").join("");
                            return `Server Time: ${spaces}${datum.uDate}\n\nSecond(s): ${spaces}${datum.date}`;
                        },
                        formatter: (datum: Datum) => {
                            let isPNL = findObjectKeys(datum, ["Profit"]);
                            return { name: isPNL ? "Profit" : "DPM", value: currencyRender(isPNL ? datum["Profit"] : datum["DPM"], 2) };
                        },
                    },
                }}
            />
        );
    };

    const viewChartData = (data: TimeSeriesProps[]) => {
        setChartData(data);
        setIsVisible(true);
        setIsLoadingChart(false);
    };

    useEffect(() => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["server"] })
            .then((data: any) => {
                if (data.servers.length > 0) {
                    setServers(data.servers);
                }
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("decay analysis", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
            });
        return () => {};
    }, []);

    return (
        <div className="decay-analysis-container">
            <CardBox title={"Closed Decay Analysis"}>
                <div className="breadcrumb-panel" style={{ display: viewType > 1 ? "block" : "none" }}>
                    <Breadcrumb>
                        <Breadcrumb.Item
                            href="#"
                            onClick={() => {
                                setViewType(1);
                                setSelectedAccountInfo(undefined);
                            }}
                        >
                            <HomeOutlined />
                            <span>Account View</span>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item href="#">Ticket View</Breadcrumb.Item>
                    </Breadcrumb>
                </div>

                <ClosedDecayAccountView
                    currentViewType={viewType}
                    onModalCallback={viewChartData}
                    onViewTypeChanged={(type: number, selectAccountInfo: any = undefined) => {
                        setViewType(type);
                        setSelectedAccountInfo(selectAccountInfo);
                    }}
                    servers={servers}
                />

                {viewType === 2 && (
                    <ClosedDecayTicketView
                        servers={servers}
                        data={selectedAccountInfo}
                        refreshRate={ticketRefreshRate}
                        onRefreshRateCallback={(seconds: number) => setTicketRefreshRate(seconds)}
                        onModalCallback={viewChartData}
                    />
                )}

                <Modal
                    width={"80vw"}
                    style={{ top: 20 }}
                    open={isVisible}
                    maskClosable={false}
                    title={`Profit & DPM Trend`}
                    cancelText="Close"
                    onCancel={() => setIsVisible(false)}
                    okButtonProps={{ style: { display: "none" } }}
                    bodyStyle={{ padding: "2.828vh 1.953vw" }}
                >
                    {isLoadingChart ? (
                        <div className="loading-container">
                            <LoadingComponent tip="Loading..." />
                        </div>
                    ) : (
                        <>{getPNLSymbolChart(chartData)}</>
                    )}
                </Modal>
            </CardBox>
        </div>
    );
};

export default ClosedDecayChartSummary;
