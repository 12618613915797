import { DownOutlined, SendOutlined } from "@ant-design/icons";
import { Alert, Dropdown, Menu, Space, Spin, Tooltip } from "antd";
import { formatDistanceToNow } from "date-fns";

export interface ReportDropdownMenuProps {
    isSendHourlyReportBtnLoading: boolean;
    isSendEODReportBtnLoading: boolean;
    hourlyLastUpdatedAt: string | null;
    eodlastUpdatedAt: string | null;
    sendHourlyReport: () => void;
    sendEODReport: () => void;
}

const ReportDropdownMenu = (props: ReportDropdownMenuProps) => {
    const menu = (
        <Menu className="lp-monitor-summary-container-report-menu">
            <Menu.Item key="hourly-report" onClick={() => props.sendHourlyReport()} disabled={props.isSendHourlyReportBtnLoading}>
                <Space className="report-item">
                    <div className="report-info">
                        <div className="sending-text-title">Hourly Report</div>
                        {props.hourlyLastUpdatedAt && (
                            <div className="sending-text-desc">{formatDistanceToNow(new Date(props.hourlyLastUpdatedAt), { addSuffix: true })}</div>
                        )}
                    </div>
                    <SendOutlined style={{ marginLeft: 8 }} />
                </Space>
            </Menu.Item>
            <Menu.Item key="eod-report" onClick={() => props.sendEODReport()} disabled={props.isSendEODReportBtnLoading}>
                <Space className="report-item">
                    <div className="report-info">
                        <div className="sending-text-title">End Of Day Report</div>
                        {props.eodlastUpdatedAt && (
                            <div className="sending-text-desc">{formatDistanceToNow(new Date(props.eodlastUpdatedAt), { addSuffix: true })}</div>
                        )}
                    </div>
                    <SendOutlined style={{ marginLeft: 8 }} />
                </Space>
            </Menu.Item>
        </Menu>
    );

    // Determine which report was sent more recently
    const latestReport =
        props.hourlyLastUpdatedAt && props.eodlastUpdatedAt
            ? new Date(props.hourlyLastUpdatedAt) > new Date(props.eodlastUpdatedAt)
                ? "Hourly"
                : "End Of Day"
            : props.hourlyLastUpdatedAt
            ? "Hourly"
            : "End Of Day";

    const latestTime =
        props.hourlyLastUpdatedAt && props.eodlastUpdatedAt
            ? new Date(props.hourlyLastUpdatedAt) > new Date(props.eodlastUpdatedAt)
                ? props.hourlyLastUpdatedAt
                : props.eodlastUpdatedAt
            : props.hourlyLastUpdatedAt || props.eodlastUpdatedAt;

    return (
        <Dropdown overlay={menu} trigger={["click"]}>
            <div className="send-report-dropdown">
                <Tooltip title="Click to select a report to send">
                    {props.isSendHourlyReportBtnLoading || props.isSendEODReportBtnLoading ? (
                        <Alert
                            message={
                                <Space size="large">
                                    <Spin size="large" />
                                    <div>
                                        <div className="sending-text-title">
                                            Sending{" "}
                                            {props.isSendEODReportBtnLoading && props.isSendHourlyReportBtnLoading
                                                ? "Hourly and EOD"
                                                : props.isSendEODReportBtnLoading
                                                ? "EOD"
                                                : props.isSendHourlyReportBtnLoading
                                                ? "Hourly"
                                                : ""}{" "}
                                            report...
                                        </div>
                                        <div className="sending-text-desc">This may take a moment.</div>
                                    </div>
                                    <DownOutlined />
                                </Space>
                            }
                            type="warning"
                            style={{ height: "100%" }}
                        />
                    ) : (
                        <div className="send-hourly-report">
                            <Space>
                                {latestTime ? (
                                    <div className="report-info">
                                        <div className="sending-text-desc">Latest report sent: </div>
                                        <div className="sending-text-title">
                                            {latestReport} Report - {formatDistanceToNow(new Date(latestTime), { addSuffix: true })}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="report-info">Select Report</div>
                                )}
                                <DownOutlined />
                            </Space>
                        </div>
                    )}
                </Tooltip>
            </div>
        </Dropdown>
    );
};

export default ReportDropdownMenu;
