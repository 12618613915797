import { Col, Form, FormInstance, Row, Typography } from "antd";
import { FormComponent } from "../../../../components/FormComponent";
import { ComponentType } from "../../../../constants";
const { Text, Title } = Typography;

interface MRBrandsPKServerPanelProps {
    assignUserForm: FormInstance;
    configData: any;
}

const AssignUserModal = (props: MRBrandsPKServerPanelProps) => {
    const { assignUserForm, configData } = props;

    return (
        <div className="toxic-client-assign-user-container">
            <Form form={assignUserForm} layout="vertical">
                <Row>
                    <Col span={24}>
                        <div className="list-item-div">
                            <Form.List name={"alarmBrandPics"}>
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(field => {
                                            const brandId = assignUserForm.getFieldValue(["alarmBrandPics", field.name, "brandId"]);
                                            const brand = configData.brands.find((b: { id: number }) => b.id === brandId);

                                            return (
                                                <div key={field.key} className="item-container">
                                                    <Title level={5}>{brand ? brand.brand : "Unknown Brand"}</Title>
                                                    <FormComponent
                                                        label="Brand Name"
                                                        name={[field.name, "brandId"]}
                                                        extra={{
                                                            type: ComponentType.hidden,
                                                            value: "",
                                                        }}
                                                    />
                                                    <FormComponent
                                                        label=""
                                                        name={[field.name, "userIds"]}
                                                        extra={{
                                                            type: ComponentType.dropdown,
                                                            value: configData.users.map((x: { name: string; email: string; id: any }) => ({
                                                                text: x.name + "  |  " + x.email,
                                                                value: x.id,
                                                            })),
                                                            inputProps: {
                                                                allowClear: true,
                                                                mode: "multiple",
                                                            },
                                                        }}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </>
                                )}
                            </Form.List>
                        </div>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default AssignUserModal;
