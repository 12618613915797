import { useEffect, useState } from "react";
import CardBox from "@/components/Common/CardBox";
import FlexiDataTable from "@/components/FlexiDataTable";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { FlexiDataTableCallbackProps, FlexiDataTableOptionsProps } from "@/constants/type";
import { CALLBACK_KEY, ComponentType, InnerPageActionMode, SUCCESS_FAILED } from "@/constants";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { Col, Form, Modal, Row } from "antd";
import { FormComponent } from "@/components/FormComponent";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { APIs } from "@/services/apis";

interface FeatureSettingData {
    comment: string | null;
    featureId: string;
    featureName: string;
};

const FeatureSetting = () => {
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.RC.RC_FEATURE_SETTING_EDIT);

    const [data, setData] = useState<FeatureSettingData[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
    const [currEdit, setCurrEdit] = useState<InnerPageActionMode.CREATE_NEW | FeatureSettingData | null>(null);
    const [featureSettingForm] = Form.useForm();

    const columns = [
        DTColProps.Small({
            title: "Feature ID",
            dataIndex: "featureId",
            key: "featureId",
            sorter: (a: any, b: any) => a.featureId.localeCompare(b.featureId),
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.Large({
            title: "Feature Name",
            dataIndex: "featureName",
            key: "featureName",
            sorter: (a: any, b: any) => a.featureName.localeCompare(b.featureName),
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.Middle({
            title: "Comment",
            dataIndex: "comment",
            key: "comment",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
    ];

    const options: FlexiDataTableOptionsProps = {
        add: enableUpdate,
        edit: enableUpdate,
        delete: enableUpdate,
        separateActionButton: true,
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, FormData) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                setCurrEdit(InnerPageActionMode.CREATE_NEW);
                break;
            case CALLBACK_KEY.DO_EDIT:
                setCurrEdit(FormData);
                featureSettingForm.setFieldsValue(FormData);
                break;
            case CALLBACK_KEY.DO_DELETE:
                setIsLoading(true);
                plainAxiosInstance
                    .delete(`${APIs.RC_FEATURE_SETTING.DELETE_FEATURE_SETTING}?id=${FormData.featureId}`)
                    .then((res: any) => {
                        if (res.data === 0) {
                            ErrorMessageHandler(`Existing feature setting (${FormData.featureId})`, SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                            getFeatureSettings();
                        } else {
                            ErrorMessageHandler(`existing feature setting (${FormData.featureId})`, SUCCESS_FAILED.FAILED_DELETE_DATA, res);
                            setIsLoading(false);
                        };
                    })
                    .catch((error: any) => ErrorCatchValidator(error, (err: any) => {
                        ErrorMessageHandler(`existing feature setting (${FormData.featureId})`, SUCCESS_FAILED.FAILED_DELETE_DATA, err);
                        setIsLoading(false);
                    }));
                break;
            default:
                break;
        };
    };

    const onFormSubmit = () => {
        featureSettingForm
            .validateFields()
            .then((values) => {
                setIsBtnLoading(true);
                if (currEdit === InnerPageActionMode.CREATE_NEW) {
                    plainAxiosInstance
                        .post(APIs.RC_FEATURE_SETTING.POST_CREATE_FEATURE_SETTING, values)
                        .then((res: any) => {
                            if (res.data === 0) {
                                ErrorMessageHandler("New feature setting", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                                getFeatureSettings();
                            } else {
                                ErrorMessageHandler("feature setting", SUCCESS_FAILED.FAILED_CREATE_DATA, res);
                            };
                        })
                        .catch((error: any) =>
                            ErrorCatchValidator(error, (err: any) =>
                                ErrorMessageHandler("feature setting", SUCCESS_FAILED.FAILED_CREATE_DATA, err)
                            )
                        )
                        .finally(() => {
                            setIsBtnLoading(false);
                            featureSettingForm.resetFields();
                            setCurrEdit(null);
                        });
                } else {
                    plainAxiosInstance
                        .put(APIs.RC_FEATURE_SETTING.PUT_UPDATE_FEATURE_SETTING, values)
                        .then((res: any) => {
                            if (res.data === 0) {
                                ErrorMessageHandler(`Existing feature setting (${currEdit?.featureId})`, SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                                getFeatureSettings();
                            } else {
                                ErrorMessageHandler(`existing feature setting (${currEdit?.featureId})`, SUCCESS_FAILED.FAILED_UPDATE_DATA, res);
                            };
                        })
                        .catch((error: any) =>
                            ErrorCatchValidator(error, (err: any) =>
                                ErrorMessageHandler(`existing feature setting (${currEdit?.featureId})`, SUCCESS_FAILED.FAILED_UPDATE_DATA, err)
                            )
                        )
                        .finally(() => {
                            setIsBtnLoading(false);
                            featureSettingForm.resetFields();
                            setCurrEdit(null);
                        });
                };
            })
            .catch((errorInfo) => {
                console.error("Failed to submit form: ", errorInfo);
            });
    };

    const getFeatureSettings = () => {
        setIsLoading(true);
        plainAxiosInstance
            .get(`${APIs.RC_FEATURE_SETTING.GET_FEATURE_SETTINGS_DATA}`)
            .then((res: any) => {
                setData(res.data.length > 0 ? res.data : []);
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => {
                ErrorMessageHandler("feature settings", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                setData([]);
            }))
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        getFeatureSettings();
        return () => { };
    }, []);

    return (
        <div className="feature-setting-container">
            <CardBox title={"Feature Setting"}>
                <FlexiDataTable
                    rowKeyProperty="featureId"
                    title=""
                    columns={columns}
                    options={options}
                    dataSource={data}
                    callback={componentCallback}
                    loading={isLoading}
                />
            </CardBox>
            <Modal
                width={700}
                title={`${currEdit === InnerPageActionMode.CREATE_NEW ? "Create New" : "Edit"} Feature Setting`}
                open={currEdit !== null}
                okText="Submit"
                onOk={onFormSubmit}
                okButtonProps={{ loading: isBtnLoading }}
                onCancel={() => {
                    setCurrEdit(null);
                    featureSettingForm.resetFields();
                }}
            >
                <Form
                    form={featureSettingForm}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    layout="horizontal"
                    initialValues={{}}
                    onFinish={onFormSubmit}
                >
                    <Row>
                        <Col span={22}>
                            <FormComponent
                                label="Feature ID"
                                name={"featureId"}
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                    inputProps: { disabled: currEdit !== InnerPageActionMode.CREATE_NEW },
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={22}>
                            <FormComponent
                                label="Feature Name"
                                name={"featureName"}
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={22}>
                            <FormComponent
                                label="Comment"
                                name={"comment"}
                                extra={{
                                    type: ComponentType.textarea,
                                    value: "",
                                }}
                            />
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    );
};

export default FeatureSetting;