import { ContainerOutlined, EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { Button, Modal, Switch, Tooltip } from "antd";
import moment from "moment-timezone";
import { useCallback, useEffect, useMemo, useState } from "react";
import FlexiDataTable from "../../../components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "../../../constants";
import { FlexiDataColumnProps, FlexiDataTableCallbackProps, FlexiDataTableOptionsProps } from "../../../constants/type";
import { APIs } from "../../../services/apis";
import { plainAxiosInstance } from "../../../services/axiosSetup";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";
import ReportBatchModal, { ReportBatchModalCallbackKey } from "../ReportBatch";

const DataTab = () => {
    const dateformat = "YYYY-MM-DD";
    const initialFilterParams = {
        regDate: moment(new Date(), dateformat).startOf("day"),
    }
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isDownloadLoading, setIsDownloadLoading] = useState<boolean>(false);
    const [datalist, setDatalist] = useState<any[]>([]);
    const [calledShowSentiveCount, setCalledShowSensitiveCount] = useState<number>(0);
    const [isShowHiddenCols, setIsShowHiddenCols] = useState<boolean>(false);
    const [auditKey, setAuditKey] = useState<string>("");
    const [filterParams, setFilterParams] = useState<any>(initialFilterParams);
    const [isReportModalVisible, setIsReportModalVisible] = useState<boolean>(false);

    const columns: FlexiDataColumnProps[] = useMemo(
        () => [
            DTColProps.Small({
                title: "Server",
                dataIndex: "server",
                key: "server",
            }),
            DTColProps.Small({
                title: "Login",
                dataIndex: "login",
                key: "login",
            }),
            ...(isShowHiddenCols ?
                [
                    DTColProps.Small({
                        title: "Name",
                        dataIndex: "name",
                        key: "name",
                    }),
                    DTColProps.Small({
                        title: "Email",
                        dataIndex: "email",
                        key: "email",
                    }),
                    DTColProps.Small({
                        title: "Phone",
                        dataIndex: "phone",
                        key: "phone",
                    }),
                ]
                : []
            ),
            DTColProps.Middle({
                title: "Reg Date",
                dataIndex: "regDate",
                key: "regDate",
                options: {
                    filter: {
                        type: ComponentType.date,
                        value: "",
                        dateFormat: dateformat,
                        inputProps: {
                            showTime: false,
                        },
                        callback: (filterValue: any, rowData: any) => {
                            const _filterValue = moment(filterValue as moment.Moment).startOf("day").format(dateformat);
                            return _filterValue === rowData["regDate"];
                        }
                    },
                },
            }),
            DTColProps.Small({
                title: "Group",
                dataIndex: "group",
                key: "group",
            }),
            DTColProps.Small({
                title: "Leverage",
                dataIndex: "leverage",
                key: "leverage",
            }),
            DTColProps.Small({
                title: "Balance",
                dataIndex: "balance",
                key: "balance",
            }),
            DTColProps.Small({
                title: "Equity",
                dataIndex: "equity",
                key: "equity",
            }),
            ...(isShowHiddenCols ?
                [
                    DTColProps.Small({
                        title: "City",
                        dataIndex: "city",
                        key: "city",
                    }),
                    DTColProps.Middle({
                        title: "Address",
                        dataIndex: "address",
                        key: "address",
                    }),
                ]
                : []
            ),
            DTColProps.Middle({
                title: "Change Reason",
                dataIndex: "reason",
                key: "reason",
            }),
            DTColProps.Small({
                title: "Label",
                dataIndex: "label",
                key: "label",
            }),
        ],
        [isShowHiddenCols]);

    useEffect(() => {
        getDataList();

        const timer = setInterval(() => {
            getDataList();
        }, 10 * 1000 * 60);

        return () => {
            clearInterval(timer);
        };
    }, []);

    function getDataList(_filterParams?: any) {
        const param = _filterParams ? _filterParams : { ...filterParams };
        const formData = new FormData();
        formData.append("queryDate", param["regDate"] ? param["regDate"].format(dateformat).toString() : "");

        setIsLoading(true);
        plainAxiosInstance
            .post(`${APIs.RC_LINKED_ACCOUNT_RECORD.GET_MS_DATA}`, formData)
            .then((res: any) => {
                if (res.data) {
                    Object.keys(res.data).forEach((key) => {
                        setAuditKey(key);
                        if (res.data[key]) {
                            setDatalist(res.data[key]);
                        }
                    });
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("data", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsLoading(false));
    }

    const getSensitiveData = useCallback((checked: boolean) => {
        if (checked) {
            setIsShowHiddenCols(true);
            if (calledShowSentiveCount > 0) return
            setIsLoading(true);
            plainAxiosInstance
                .get(`${APIs.RC_LINKED_ACCOUNT_RECORD.GET_LOG_SENSITIVE_DATA}?auditKey=${auditKey}`)
                .then(() => setCalledShowSensitiveCount(prev => prev + 1))
                .catch((error: any) => {
                    setCalledShowSensitiveCount(prev => prev + 1)
                    return ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("sensitive data", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
                })
                .finally(() => setIsLoading(false));
        } else {
            setIsShowHiddenCols(false);
        }
    }, [calledShowSentiveCount, auditKey]);

    function exportReportBatch() {
        setIsDownloadLoading(true);
        const formData = new FormData();
        formData.append("date", filterParams["regDate"]?.format(dateformat).toString());

        plainAxiosInstance
            .post(APIs.RC_LINKED_ACCOUNT_RECORD.POST_DOWNLOAD_REPORT, formData)
            .then(() => {
                Modal.success({
                    width: "30%",
                    title: "Successfully added batch",
                    content: "Please download the report from 【Report Listing】 once it is completed",
                });
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("report", SUCCESS_FAILED.FAILED_DOWNLOAD_DATA, err))
            )
            .finally(() => setIsDownloadLoading(false));
    }

    const options: FlexiDataTableOptionsProps = {
        export: { text: "Download" },
        serverFiltering: true,
        extraButtons: () => {
            return (
                <div className="lar-extra-buttons" key={"lar-d-eb"}>
                    <div key={"switch_sensitive_data"} className="lar-s">
                        <Tooltip title={`${isShowHiddenCols ? 'Hide' : 'Show'} Sensitive Data`} placement="topRight">
                            <Switch
                                checkedChildren={<EyeOutlined />}
                                unCheckedChildren={<EyeInvisibleOutlined />}
                                onChange={(checked: boolean) => getSensitiveData(checked)}
                                checked={isShowHiddenCols}
                                disabled={auditKey === ""}
                            />
                        </Tooltip>
                    </div>
                    <div key={"report-list-btn"} className="extra-table-header-buttons">
                        <Button icon={<ContainerOutlined />} onClick={event => {
                            event.preventDefault();
                            event.stopPropagation();
                            componentCallback(CALLBACK_KEY.OTHERS, "reportlist");
                        }}>Report Listing</Button>
                    </div>
                </div>
            );
        },

    };

    const componentCallback: FlexiDataTableCallbackProps = (type, FormData) => {
        switch (type) {
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                let fParams: any = {};
                Object.keys(FormData)
                    .filter((x) => FormData[x] !== undefined && FormData[x].toString().length > 0)
                    .map((x) => {
                        if (x === "regDate") {
                            fParams["regDate"] = FormData[x];
                        }
                        return x;
                    });
                setFilterParams(fParams);
                getDataList(fParams);
                break;
            case CALLBACK_KEY.EXPORT_CSV_EXCEL:
                if (!filterParams["regDate"]) {
                    Modal.error({
                        title: "Error",
                        content: "Please select a date to download report.",
                    });
                    return;
                }
                exportReportBatch();
                break;
            case CALLBACK_KEY.OTHERS:
                switch (FormData) {
                    case "reportlist":
                        setIsReportModalVisible(true);
                        break;
                    default:
                        break;
                }
                break;
            default:
                break;
        }
    };

    return (
        <div className="account-record">
            <FlexiDataTable
                rowKeyProperty="login"
                title="Linked account data exclude test group."
                columns={columns}
                options={options}
                dataSource={datalist}
                callback={componentCallback}
                loading={isLoading}
                filterInitialValue={initialFilterParams}
                exporting={isDownloadLoading}
                bordered
            />
            <ReportBatchModal
                isModalVisible={isReportModalVisible}
                modalTitle={"Download Report"}
                callback={(type: number) => {
                    switch (type) {
                        case ReportBatchModalCallbackKey.Close:
                            setIsReportModalVisible(false);
                            break;
                        default:
                            break;
                    }
                }}
                filterCallback={(record: any) => record.batchName === "LinkedAccountRecord"}
            />
        </div>
    );
};

export default DataTab;
