import moment from "moment";
import { useEffect, useState } from "react";
import { TimeProps } from ".";

export interface TinyTimeProps {
    showTime: TimeProps;
    stopInterval?: boolean;
}

const TinyTime = (props: TinyTimeProps) => {
    const [dummySet, setDummySet] = useState<boolean>(false);

    useEffect(() => {
        const secondInterval = setInterval(() => setDummySet(prev => !prev), 1000);

        return () => {
            clearInterval(secondInterval);
        };
    }, []);

    return (
        <>
            <div className="tinyTime">
                <span>
                    {props.showTime.name} Time :{" "}
                    {(props.showTime?.hasOwnProperty("utcOffset")
                        ? moment().utcOffset(props.showTime.utcOffset as number)
                        : moment().tz(props.showTime.tz)
                    ).format("HH:mm:ss")}
                </span>
            </div>
            <span className="hide">{`${dummySet}`}</span>
        </>
    );
};

export default TinyTime;
