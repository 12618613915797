import { useMemo } from "react";
import { Button, Divider, message, Tooltip } from "antd";
import FlexiDataTable from "@/components/FlexiDataTable";
import { DTColProps } from "@/utils/Common";
import {
    FlexiDataTableCallbackProps,
    FlexiDataTableOptionsProps,
    KeyValuePair,
    RollOverBatchRecordList,
    RollOverOpenPositionList,
} from "@/constants/type";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { APIs } from "@/services/apis";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { CALLBACK_KEY, ComponentType } from "@/constants";
import moment from "moment";
import { InfoCircleOutlined, PlayCircleOutlined } from "@ant-design/icons";

export interface RollOverOpenPositionTableProps {
    data: RollOverOpenPositionList[];
    currentState: RollOverBatchRecordList;
    tbTitle: string;
    loading: boolean;
    serverDetailList: KeyValuePair[];
    calcOpenPosition: () => void;
    callDataBasedOnID: (secID: string, sec: number) => void;
}

const RollOverOpenPositionTable = (props: RollOverOpenPositionTableProps) => {
    const columns = [
        DTColProps.XSmall({
            title: "Server",
            dataIndex: "server",
            key: "server",
            sorter: (a: any, b: any) => a.server - b.server,
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: props.serverDetailList,
                },
            },
        }),
        DTColProps.XSmall({
            title: "Ticket/Position",
            dataIndex: "ticket",
            key: "ticket",
        }),
        DTColProps.XSmall({
            title: "Login",
            dataIndex: "login",
            key: "login",
        }),
        DTColProps.XSmall({
            title: "Group",
            dataIndex: "accountGroup",
            key: "accountGroup",
        }),
        DTColProps.XSmall({
            title: "Type",
            dataIndex: "type",
            key: "type",
            render: (type: number) => <div>{type === 0 ? "buy" : "sell"}</div>,
        }),
        DTColProps.XSmall({
            title: "Symbol",
            dataIndex: "symbol",
            key: "symbol",
        }),
        DTColProps.XSmall({
            title: "Volume",
            dataIndex: "volume",
            key: "volume",
        }),
        DTColProps.XSmall({
            title: "Open price",
            dataIndex: "openPrice",
            key: "openPrice",
        }),
        DTColProps.XSmall({
            title: "Open time",
            dataIndex: "openTime",
            key: "openTime",
            render: (openTime: string) => <span>{openTime ? moment(openTime).format("YYYY-MM-DD HH:mm:ss") : ""}</span>,
        }),
        DTColProps.XSmall({
            title: "Close price",
            dataIndex: "closePrice",
            key: "closePrice",
        }),
        DTColProps.XSmall({
            title: "Close time",
            dataIndex: "closeTime",
            key: "closeTime",
            render: (closeTime: string) => <span>{closeTime ? moment(closeTime).format("YYYY-MM-DD HH:mm:ss") : ""}</span>,
        }),
        DTColProps.XSmall({
            title: "Sl",
            dataIndex: "sl",
            key: "sl",
        }),
        DTColProps.XSmall({
            title: "Tp",
            dataIndex: "tp",
            key: "tp",
        }),
        DTColProps.XSmall({
            title: "Swaps",
            dataIndex: "swaps",
            key: "swaps",
        }),
        DTColProps.XSmall({
            title: "Commission",
            dataIndex: "commission",
            key: "commission",
        }),
        DTColProps.XSmall({
            title: "Profit",
            dataIndex: "profit",
            key: "profit",
        }),
        DTColProps.XSmall({
            title: "Currency",
            dataIndex: "accountCurrency",
            key: "accountCurrency",
        }),
        DTColProps.XSmall({
            title: "Contract Size",
            dataIndex: "contractSize",
            key: "contractSize",
        }),
        DTColProps.XSmall({
            title: "Adj By Order",
            dataIndex: "adjByOrder",
            key: "adjByOrder",
        }),
    ];

    const options: FlexiDataTableOptionsProps = useMemo(
        () => ({
            showHideColumns: false,
            enableFilter: true,
            export: {
                text: "Download Excel",
            },
            extraButtons: () => (
                <>
                    <Button
                        key={`btn-calculate-${Math.random()}`}
                        style={{ marginLeft: "0.651vw", width: "auto" }}
                        icon={<PlayCircleOutlined />}
                        disabled={props.currentState.currentStep == 0 || props.currentState.currentStep == 4 || props.loading}
                        onClick={event => {
                            event.preventDefault();
                            event.stopPropagation();
                            props.calcOpenPosition();
                        }}
                    >
                        Click calculate open position order adjustment
                    </Button>
                    <Tooltip title="Calculate open position order adjustment" placement="topLeft">
                        <InfoCircleOutlined style={{ fontSize: "1.125rem", cursor: "pointer", marginLeft: "0.651vw" }} />
                    </Tooltip>
                </>
            ),
        }),
        [props.data, props.loading, props.currentState.currentStep]
    );

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.EXPORT_CSV_EXCEL:
                try {
                    plainAxiosInstance
                        .get(`${APIs.RISK_TOOL.GET_ROLLOVER_DOWNLOAD_OPEN_POSITION}?batchId=${FormData.id}&serverId=${FormData.server}`, {
                            headers: {
                                Accept: "application/octet-stream, */*",
                            },
                            responseType: "blob",
                        })
                        .then(response => {
                            const contentType = response.headers["content-type"];
                            if (
                                contentType === "application/octet-stream" ||
                                contentType === "text/csv;charset=UTF-8" ||
                                contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            ) {
                                const url = window.URL.createObjectURL(new Blob([response.data]));
                                const link = document.createElement("a");
                                link.href = url;
                                link.setAttribute("download", `OpenPosition_${moment().format("YYYYMMDDHHmmss")}.xlsx`);
                                document.body.appendChild(link);
                                link.click();
                                // Clean up
                                window.URL.revokeObjectURL(url);
                            } else {
                                message.error(`Received non-file response. Error: ${response}`, 3);
                            }
                        })
                        .catch((error: any) => message.error(`Error occured during download: "${error.message}"`, 3));
                } catch (e: any) {
                    message.error(`Error occured during download: "${e.message}"`, 3);
                }
                break;
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                props.callDataBasedOnID(`${FormData["server"]}`, 1);
                break;
            default:
                break;
        }
    };

    return (
        <>
            <br />
            <Divider style={{ borderColor: "#FFA500" }}>{props.tbTitle}</Divider>
            <FlexiDataTable
                rowKeyProperty="uniqueKey"
                title={false}
                columns={columns}
                options={options}
                dataSource={props.data}
                loading={props.loading}
                callback={componentCallback}
                pagination={{ defaultPageSize: 10 }}
                scroll={{ x: true }}
            />
        </>
    );
};
export default RollOverOpenPositionTable;
