import { createApi } from "@reduxjs/toolkit/query/react";
import { HistoricalEvent, MonitorProfile } from "../../constants/type";
import { APIs, defaultBaseQueryProps, ResponseProps } from "../../services/apiConfig";
import { axiosBaseQuery } from "../axiosBaseQuery";

export interface monitorProfileRequest {}

export interface monitorProfileResponse extends ResponseProps<MonitorProfile[]> {}

export interface historicalEventRequest {}

export interface historicalEventResponse extends ResponseProps<HistoricalEvent[]> {}

export const monitorProfileApiSlice = createApi({
    reducerPath: "api_12",
    baseQuery: axiosBaseQuery(),
    keepUnusedDataFor: 5, //global caching duration (seconds)
    endpoints: (builder) => ({
        getMonitorProfiles: builder.query<monitorProfileResponse, monitorProfileRequest | undefined>({
            keepUnusedDataFor: 0, //disable for caching for particular api request
            query: (arg) => ({ ...defaultBaseQueryProps, url: APIs.GET_MONITOR_PROFILE, method: "POST", params: arg }),
            transformResponse: (returnValue: monitorProfileResponse, meta) => {
                returnValue.data?.sort((a: any, b: any) => (a.createdDateUtc > b.createdDateUtc ? -1 : a.createdDateUtc < b.createdDateUtc ? 1 : 0));
                return returnValue;
            },
        }),
        getHistoricalEvents: builder.query<historicalEventResponse, historicalEventRequest | undefined>({
            keepUnusedDataFor: 0, //disable for caching for particular api request
            query: (arg) => ({ ...defaultBaseQueryProps, url: APIs.GET_PROFILE_EVENT_LIST, method: "POST", params: arg }),
            transformResponse: (returnValue: historicalEventResponse, meta) => {
                return returnValue;
            },
        }),
    }),
});

export const { useGetMonitorProfilesQuery, useGetHistoricalEventsQuery } = monitorProfileApiSlice;
