import { useQuery } from "@tanstack/react-query";
import { APIs } from "../services/apis";
import { plainAxiosInstance } from "../services/axiosSetup";
const useRCSymbols = () => {
    //convert request to form data
    const { data, refetch, isLoading, error, isFetching } = useQuery({
        queryKey: ["rcSymbols"],
        queryFn: () =>
            plainAxiosInstance.get(`${APIs.RC_SPREAD.GET_SPREAD_SYMBOLS}`, {
                headers: {
                    Accept: "application/json",
                },
            }),
        staleTime: 60 * 1000, //(60 x 1000ms = 1minute)
    });

    const rcSymbols = data?.data?.status ? null : data?.data || null;
    // @ts-ignore
    const rcSymbolsError = data?.data?.message || error?.message || error || null;

    return {
        rcSymbols,
        rcSymbolsError,
        refetchRcSymbols: refetch,
        isFetching,
        isLoading,
    };
};

export default useRCSymbols;
