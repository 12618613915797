import React from "react";
import { KBarAnimator, KBarPortal, KBarPositioner, KBarResults, KBarSearch, useMatches } from "kbar";
import "./kbar.css";
const StyledKbar = () => {
    return (
        <KBarPortal>
            <KBarPositioner className="kbar-overlay">
                <KBarAnimator className="kbar-container">
                    <KBarSearch className="kbar-search" />
                    <RenderResults />
                </KBarAnimator>
            </KBarPositioner>
        </KBarPortal>
    );
};
function RenderResults() {
    const { results } = useMatches();

    return (
        <KBarResults
            items={results}
            onRender={({ item, active }) =>
                typeof item === "string" ? (
                    <div className="kbar-section-header">{item}</div>
                ) : (
                    <div className={`kbar-result-item ${active ? "kbar-result-item-active" : ""}`}>{item.name}</div>
                )
            }
        />
    );
}
export default StyledKbar;
