import { HomeOutlined } from "@ant-design/icons";
import { Button, Col, Form, Popconfirm, Row } from "antd";
import { useEffect, useState } from "react";
import { NavigateFunction, useLocation, useNavigate, useParams } from "react-router-dom";
import { FormComponent } from "../../../components/FormComponent";
import LoadingComponent from "../../../components/Loading";
import SitePageHeader from "../../../components/PageHeader";
import { ComponentType, SUCCESS_FAILED } from "../../../constants";
import { REQUIRED_FIELD } from "../../../constants/errorMessage";
import { Brands, Servers } from "../../../constants/type";
import { apiRequest, APIs } from "../../../services/apiConfig";
import { ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";

export type SBMState = {
    action: string;
    data: any;
};

function SBMCreateEditPage() {
    let navigate = useNavigate();
    let location = useLocation();
    let com_state: SBMState = location.state as SBMState;
    const [currentState] = useState<SBMState>(com_state || { action: "add", data: null });
    const [isAddAction, setIsAddAction] = useState<boolean>(currentState.action === "add");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [serverBrandMappingForm] = Form.useForm();
    const [serverList, setServerList] = useState<any[]>([]);
    const [brandList, setBrandList] = useState<any[]>([]);
    let { id } = useParams();

    const onSubmit = (values: any) => {
        const newValues = {
            sbmId: values.sbmId,
            serverId: values.serverId,
            brandId: values.brandId,
            groupPattern: values.groupPattern,
        };

        setIsLoading(true);

        if (isAddAction) {
            apiRequest(APIs.CREATE_SERVER_BRAND_MAPPING, newValues)
                .then((data) => {
                    ErrorMessageHandler("New server brand mapping", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                    navigate("/siteadmin/serverbrand/server-brand-mapping");
                })
                .catch((error) => {
                    ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("new server brand mapping", SUCCESS_FAILED.FAILED_CREATE_DATA, err));
                    setIsLoading(false);
                });
        } else {
            apiRequest(APIs.UPDATE_SERVER_BRAND_MAPPING, newValues)
                .then((data) => {
                    ErrorMessageHandler("Existing server brand mapping", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                    navigate("/siteadmin/serverbrand/server-brand-mapping");
                })
                .catch((error) => {
                    ErrorCatchValidator(error, (err: any) =>
                        ErrorMessageHandler("existing server brand mapping", SUCCESS_FAILED.FAILED_UPDATE_DATA, err)
                    );
                    setIsLoading(false);
                });
        }
    };

    const getConfigInfo = () => {
        setIsLoading(true);
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["server", "brand"] })
            .then((data: any) => {
                setServerList(data.servers);
                setBrandList(data.brands);
                setIsLoading(false);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("config info", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                navigate("/siteadmin/serverbrand/server-brand-mapping");
            });
    };

    const getServerBrandMappingList = () => {
        setIsLoading(true);
        apiRequest(APIs.GET_SERVER_BRAND_MAPPING_LIST, { sbmId: currentState.data ? currentState.data : id })
            .then((data: any) => {
                if (currentState.data || id) {
                    setIsAddAction(false);
                    serverBrandMappingForm.setFieldsValue(data);
                } else {
                    setIsAddAction(true);
                    serverBrandMappingForm.resetFields();
                }
                setIsLoading(false);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("server brand mapping list", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                navigate("/siteadmin/serverbrand/server-brand-mapping");
            });
    };

    const deleteServerBrandMapping = (sbmId: string, navigator: NavigateFunction) => {
        apiRequest(APIs.DELETE_SERVER_BRAND_MAPPING, { sbmId })
            .then((data) => {
                ErrorMessageHandler("The server brand mapping", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                navigator("/siteadmin/serverbrand/server-brand-mapping");
            })
            .catch((error) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("server brand mapping", SUCCESS_FAILED.FAILED_DELETE_DATA, err))
            );
    };

    useEffect(() => {
        getConfigInfo();
        getServerBrandMappingList();
        return () => {};
    }, [isAddAction, serverBrandMappingForm]);

    return (
        <SitePageHeader
            title={isAddAction ? "Create New Server Brand Mapping" : "Edit Server Brand Mapping"}
            routes={[
                {
                    path: "/siteadmin/serverbrand/server-brand-mapping",
                    breadcrumbName: "Server Brand Map Listing",
                    icon: <HomeOutlined />,
                },
                {
                    path: "",
                    breadcrumbName: isAddAction ? "Create New Server Brand Mapping" : "Edit Server Brand Mapping",
                },
            ]}
            onBack={() => navigate("/siteadmin/serverbrand/server-brand-mapping")}
            extraProps={{
                extra: isAddAction
                    ? []
                    : [
                          <Popconfirm
                              key={"cetmp-del"}
                              title="Confirm to delete?"
                              onConfirm={() => deleteServerBrandMapping(currentState.data ? currentState.data : id, navigate)}
                              okText="Yes"
                              cancelText="No"
                          >
                              <Button type="primary" danger>
                                  Delete Server Brand Mapping
                              </Button>
                          </Popconfirm>,
                      ],
            }}
        >
            {isLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Submitting..." />
                </div>
            ) : (
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    form={serverBrandMappingForm}
                    layout="horizontal"
                    initialValues={currentState.data}
                    onFinish={onSubmit}
                >
                    <Row>
                        <Col span={15}>
                            <FormComponent label={""} name={"sbmId"} extra={{ type: ComponentType.hidden, value: "" }} />
                            <FormComponent
                                label="Server"
                                name={"serverId"}
                                extra={{
                                    type: ComponentType.dropdown,
                                    inputProps: {
                                        style: { width: "100%" },
                                    },
                                    value: serverList.map((sv) => {
                                        return {
                                            text: sv.server,
                                            value: sv.id,
                                        };
                                    }),
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Brand"
                                name={"brandId"}
                                extra={{
                                    type: ComponentType.dropdown,
                                    inputProps: {
                                        style: { width: "100%" },
                                    },
                                    value: brandList.map((bd) => {
                                        return {
                                            text: bd.brand,
                                            value: bd.id,
                                        };
                                    }),
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Group Pattern"
                                name="groupPattern"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div className="step-btns-group">
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            )}
        </SitePageHeader>
    );
}

export default SBMCreateEditPage;
