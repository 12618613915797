import { FormComponent } from "@/components/FormComponent";
import { ComponentType } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { KeyValuePair } from "@/constants/type";
import { Col, Form, FormInstance, Row } from "antd";
import { CidLabelSettingsList } from "./type";

interface ViewHistoryModalProps {
    form: FormInstance;
    isAddAction: boolean;
    servers: KeyValuePair[];
    labels: CidLabelSettingsList[];
};

const ViewHistoryModal = (props: ViewHistoryModalProps) => {
    return (
        <div>
            <Form
                form={props.form}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                layout="horizontal"
                initialValues={{}}
            >
                {!props.isAddAction && (
                    <>
                        <FormComponent label={""} name={"index"} extra={{ type: ComponentType.hidden, value: "" }} />
                        <FormComponent label={""} name={"reportDate"} extra={{ type: ComponentType.hidden, value: "" }} />
                    </>
                )}
                <Row>
                    <Col span={22}>
                        <FormComponent
                            label="Date"
                            name={"updateDate"}
                            extra={{
                                type: ComponentType.date,
                                value: "",
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                                dateFormat: "YYYY-MM-DD",
                                inputProps: {
                                    showToday: true,
                                },
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={22}>
                        <FormComponent
                            label="Server"
                            name={"mainServerId"}
                            extra={{
                                type: ComponentType.dropdown,
                                value: props.servers,
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                                inputProps: {
                                    disabled: !props.isAddAction,
                                },
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={22}>
                        <FormComponent
                            label="Login"
                            name={"login"}
                            extra={{
                                type: ComponentType.text,
                                value: "",
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                                inputProps: {
                                    disabled: !props.isAddAction,
                                },
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={22}>
                        <FormComponent
                            label="CID"
                            name={"cid"}
                            extra={{
                                type: ComponentType.text,
                                value: "",
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                                inputProps: {
                                    disabled: !props.isAddAction,
                                },
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={22}>
                        <FormComponent
                            label="Label"
                            name={"labelId"}
                            extra={{
                                type: ComponentType.dropdown,
                                value: props.labels.map((x: CidLabelSettingsList) => ({
                                    value: x.labelId,
                                    text: x.labelName,
                                })),
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                                inputProps: {
                                    mode: "multiple",
                                },
                            }}
                        />
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default ViewHistoryModal;