import { message, Modal, Tag } from "antd";
import { useState, useEffect } from "react";
import FlexiDataTable from "../../../../../components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "../../../../../constants";
import { FlexiDataTableOptionsProps, FlexiDataTableCallbackProps } from "../../../../../constants/type";
import AuthHelper, { AuthKeys } from "../../../../../helpers/authHelper";
import { APIs } from "../../../../../services/apis";
import { plainAxiosInstance } from "../../../../../services/axiosSetup";
import { currencyRender, DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../../../../utils/Common";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { defaultIfEmptyOrNull } from "../../../../../utils/string";
import { render } from "react-dom";
import moment from "moment";

export interface RecordHistoryProps {}

interface RecordHistoryDataProps {
    bridge: string;
    dateTime: string;
    downloadUser: string;
    login: number;
    modifyTime: string;
    server: string;
    solvedUser: string;
    status: number;
}

export const statusMap: { [key: string]: { text: string; color: string } } = {
    0: { text: "Pending", color: "#FFD700" }, // Gold
    1: { text: "In order routing", color: "#1E90FF" }, // Dodger Blue
    2: { text: "Out of order routing", color: "#FF8C00" }, // Dark Orange
    3: { text: "Process Error", color: "#DC143C" }, // Crimson
};

const RecordHistory = (props: RecordHistoryProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<RecordHistoryDataProps[]>([]);

    const columns = [
        DTColProps.DateTime({
            title: "Date Time",
            dataIndex: "dateTime",
            key: "dateTime",
            sorter: (a: number, b: number) => (moment(a) > moment(b) ? -1 : 1),
            render: (dateTime: string, rowData: RecordHistoryDataProps) => {
                return <span>{dateTime ? moment(dateTime).format("YYYY-MM-DD HH:mm:ss") : ""}</span>;
            },
        }),
        DTColProps.Small({
            title: "Bridge",
            dataIndex: "bridge",
            key: "bridge",
            sorter: (a: any, b: any) => a.bridge - b.bridge,
            options: {
                filter: {
                    type: ComponentType.text,
                },
            },
        }),
        DTColProps.Small({
            title: "Server",
            dataIndex: "server",
            key: "server",
            sorter: (a: any, b: any) => a.server - b.server,
            options: {
                filter: {
                    type: ComponentType.text,
                },
            },
        }),
        DTColProps.Small({
            title: "Login",
            dataIndex: "login",
            key: "login",
            sorter: (a: any, b: any) => a.login - b.login,
            options: {
                filter: {
                    type: ComponentType.text,
                },
            },
        }),
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            sorter: (a: any, b: any) => a.status - b.status,
            render: (status: string | number) => {
                const { text, color } = statusMap[status] || { text: "Unknown", color: "default" };
                return <Tag color={color}>{text}</Tag>;
            },
            options: {
                filter: {
                    type: ComponentType.text,
                },
            },
        },
        DTColProps.Middle({
            title: "Download User",
            dataIndex: "downloadUser",
            key: "downloadUser",
            render: (downloadUser: string) => (downloadUser ? downloadUser : "-"),
            options: {
                filter: {
                    type: ComponentType.text,
                },
            },
        }),
        DTColProps.Middle({
            title: "Solved User",
            dataIndex: "solvedUser",
            key: "solvedUser",
            render: (solvedUser: string) => (solvedUser ? solvedUser : "-"),
            options: {
                filter: {
                    type: ComponentType.text,
                },
            },
        }),
        DTColProps.DateTime({
            title: "Last Modify Time",
            dataIndex: "modifyTime",
            key: "modifyTime",
            sorter: (a: number, b: number) => (moment(a) > moment(b) ? -1 : 1),
            render: (text: any, rowData: RecordHistoryDataProps) => {
                return <span>{rowData.modifyTime ? moment(rowData.modifyTime).format("YYYY-MM-DD HH:mm:ss") : ""}</span>;
            },
        }),
    ];

    const getOrderRoutingRecordHistory = () => {
        setIsLoading(true);
        plainAxiosInstance
            .get(APIs.RISK_TOOL.GET_ORDER_ROUTING_RECORD_HISTORY)
            .then((res: any) => {
                setData(
                    res.data.map((item: RecordHistoryDataProps) => ({
                        ...item,
                        uniqueKey: `${item.dateTime}_${item.bridge}_${item.server}_${item.login}`,
                    }))
                );
                setIsLoading(false);
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("order routing record history", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            );
    };

    useEffect(() => {
        getOrderRoutingRecordHistory();
    }, []);

    return (
        <div className="order-routing-record-history-container">
            <FlexiDataTable
                rowKeyProperty="uniqueKey"
                title={""}
                columns={columns}
                options={{}}
                dataSource={data}
                callback={(type: CALLBACK_KEY, FormData: any) => {}}
                loading={isLoading}
            />
        </div>
    );
};

export default RecordHistory;
