import { Col, Divider, Form, FormInstance, Row } from "antd";
import React from "react";
import { FormComponent } from "../../../../../components/FormComponent";
import { ComponentType } from "../../../../../constants";

interface AccountRestrictionsProps {
    accForm: FormInstance;
};

const AccountRestrictions = (props: AccountRestrictionsProps) => {
    return (
        <div className="toxic-client-check-acc-restriction-modal-form-container">
            <Form
                form={props.accForm}
                layout="horizontal"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={{
                    addingCidTool: false,
                    closeOnly: false,
                    readOnly: false,
                    groupChange: "",
                    sBook: false,
                    closeAccount: false,
                    creditPromotion: false,
                    kyc: false,
                    leverage: false,
                    openAccount: false,
                    swap: false,
                    warningLetter: false,
                    withdrawBan: false,
                }}
            >
                <Row>
                    <Col span={12}>
                        <div className="tcc-restriction-title">
                            <span>Account Restrictions</span>
                        </div>
                        <Row>
                            <Col span={22}>
                                <FormComponent label="" name="accountId" extra={{ type: "hidden", value: "" }} />
                                <FormComponent label="" name="serverId" extra={{ type: "hidden", value: "" }} />
                                <FormComponent label="" name="brandId" extra={{ type: "hidden", value: "" }} />
                                <FormComponent
                                    label="Adding CID Tool"
                                    name="addingCidTool"
                                    extra={{
                                        type: ComponentType.switch,
                                        value: ["No", "Yes"],
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={22}>
                                <FormComponent
                                    label="Close Only"
                                    name="closeOnly"
                                    extra={{
                                        type: ComponentType.switch,
                                        value: ["No", "Yes"],
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={22}>
                                <FormComponent
                                    label="Read Only"
                                    name="readOnly"
                                    extra={{
                                        type: ComponentType.switch,
                                        value: ["No", "Yes"],
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={22}>
                                <FormComponent
                                    label="S Book"
                                    name="sBook"
                                    extra={{
                                        type: ComponentType.switch,
                                        value: ["No", "Yes"],
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={22}>
                                <FormComponent
                                    label="Group Change"
                                    name="groupChange"
                                    extra={{
                                        type: ComponentType.textarea,
                                        value: "",
                                        inputProps: {
                                            rows: 3,
                                        },
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col span={1}>
                        <Divider type="vertical" orientationMargin={10} style={{ height: "calc(100%)" }} />
                    </Col>
                    <Col span={11}>
                        <div className="tcc-restriction-title">
                            <span>Client Restrictions</span>
                        </div>
                        <div className="toxic-client-restriction-modal-form-container">
                            <Row>
                                <Col span={12}>
                                    <Row>
                                        <Col span={24}>
                                            <FormComponent label="" name="clientId" extra={{ type: "hidden", value: "" }} />
                                            <FormComponent label="" name="crmServerId" extra={{ type: "hidden", value: "" }} />
                                            <FormComponent label="" name="crmBrandId" extra={{ type: "hidden", value: "" }} />
                                            <FormComponent label="" name="regulatorId" extra={{ type: "hidden", value: "" }} />
                                            <FormComponent
                                                label="Close Account"
                                                name="closeAccount"
                                                extra={{
                                                    type: ComponentType.switch,
                                                    value: ["No", "Yes"],
                                                    itemProps: {
                                                        labelCol: { span: 15 },
                                                        wrapperCol: { span: 9 },
                                                    },
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <FormComponent
                                                label="KYC"
                                                name="kyc"
                                                extra={{
                                                    type: ComponentType.switch,
                                                    value: ["No", "Yes"],
                                                    itemProps: {
                                                        labelCol: { span: 15 },
                                                        wrapperCol: { span: 9 },
                                                    },
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <FormComponent
                                                label="Leverage"
                                                name="leverage"
                                                extra={{
                                                    type: ComponentType.switch,
                                                    value: ["No", "Yes"],
                                                    itemProps: {
                                                        labelCol: { span: 15 },
                                                        wrapperCol: { span: 9 },
                                                    },
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={12} pull={1}>
                                    <Row>
                                        <Col span={24}>
                                            <FormComponent
                                                label="Warning Letter"
                                                name="warningLetter"
                                                extra={{
                                                    type: ComponentType.switch,
                                                    value: ["No", "Yes"],
                                                    itemProps: {
                                                        labelCol: { span: 15 },
                                                        wrapperCol: { span: 9 },
                                                    },
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <FormComponent
                                                label="Withdraw Ban"
                                                name="withdrawBan"
                                                extra={{
                                                    type: ComponentType.switch,
                                                    value: ["No", "Yes"],
                                                    itemProps: {
                                                        labelCol: { span: 15 },
                                                        wrapperCol: { span: 9 },
                                                    },
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Row className="client-restriction-notes">
                                        <Col span={24}>
                                            <div className="section-title">Blacklist</div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={12}>
                                    <Row>
                                        <Col span={24}>
                                            <FormComponent
                                                label="Credit & Promotion"
                                                name="creditPromotion"
                                                extra={{
                                                    type: ComponentType.switch,
                                                    value: ["No", "Yes"],
                                                    itemProps: {
                                                        labelCol: { span: 15 },
                                                        wrapperCol: { span: 9 },
                                                    },
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <FormComponent
                                                label="Open Account"
                                                name="openAccount"
                                                extra={{
                                                    type: ComponentType.switch,
                                                    value: ["No", "Yes"],
                                                    itemProps: {
                                                        labelCol: { span: 15 },
                                                        wrapperCol: { span: 9 },
                                                    },
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={12} pull={1}>
                                    <Row>
                                        <Col span={24}>
                                            <FormComponent
                                                label="Swap"
                                                name="swap"
                                                extra={{
                                                    type: ComponentType.switch,
                                                    value: ["No", "Yes"],
                                                    itemProps: {
                                                        labelCol: { span: 15 },
                                                        wrapperCol: { span: 9 },
                                                    },
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default AccountRestrictions;