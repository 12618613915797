import React, { useRef, useState } from "react";
import { Column } from "@ant-design/plots";
import { Alert, Typography, Space, Tag, Tooltip, Button } from "antd";
import { motion } from "framer-motion";
import { isEqual } from "lodash";
import useRCSpreadReportStore from "../../../store/useRCSpreadReportStore";
import useRCSpreadCompare from "../../../hooks/useRCSpreadCompare";
import { DeleteOutlined } from "@ant-design/icons";
import moment from "moment";

const AverageSpreadAnalysisRecords: React.FC = () => {
    const { Title, Text } = Typography;
    // console.log("window.location.href", window?.location);
    const averageQueryRecords = useRCSpreadReportStore(state => state.averageSpreadQueryRecords);
    const averageSpreadAnalysisQuery = useRCSpreadReportStore(state => state.averageSpreadAnalysisQuery);

    const [applyDummy, setApplyDummy] = useState(false);

    // console.log("averageQueryRecords", averageQueryRecords);
    const { spreadCompare, spreadCompareError, refetchSpreadCompare, isLoading } = useRCSpreadCompare(
        !averageSpreadAnalysisQuery
            ? { symbol: null, start: "", end: "", groups: [], useDummy: 2 }
            : {
                  ...averageSpreadAnalysisQuery,
                  useDummy: applyDummy ? 2 : null,
                  // symbol: "XAUUSD",
                  // start: "2024-04-03",
                  // end: "2024-04-03",
                  // groups: ["MT5_FCA_ECN", "MT5_FCA_INST_ECN", "Exness-MT5-STP", "LP_AU_OKCOIN"],
              }
    );

    // console.log("spreadCompare", spreadCompare);
    function reformatData(data: { name: string; time: string; spread: number }[]) {
        const uniqueNames = Array.from(new Set(data.map(item => item.name)));
        const reformattedData: { name: string; spread: string; total: number }[] = [];

        const targetStartHour = averageSpreadAnalysisQuery?.startTime ? parseInt(averageSpreadAnalysisQuery.startTime.split(":")[0]) : 0;
        const targetEndHour = averageSpreadAnalysisQuery?.endTime ? parseInt(averageSpreadAnalysisQuery.endTime.split(":")[0]) : 0;
        const targetStartMinute = averageSpreadAnalysisQuery?.startTime ? parseInt(averageSpreadAnalysisQuery.startTime.split(":")[1]) : 0;
        const targetEndMinute = averageSpreadAnalysisQuery?.endTime ? parseInt(averageSpreadAnalysisQuery.endTime.split(":")[1]) : 0;

        uniqueNames?.forEach(name => {
            const spreadCounts: { [key: number]: number } = {};
            const filteredData = data.filter(item => item.name === name);

            filteredData.forEach(item => {
                const hour = new Date(item.time).getHours();
                const minute = new Date(item.time).getMinutes();

                // Ensure the current item falls within the specified time range
                if (
                    (targetEndHour < targetStartHour && (hour >= targetStartHour || hour <= targetEndHour)) ||
                    (targetStartHour === targetEndHour &&
                        targetStartMinute > targetEndMinute &&
                        (hour === targetStartHour || hour === targetEndHour)) ||
                    (hour >= targetStartHour && hour <= targetEndHour && minute >= targetStartMinute && minute <= targetEndMinute)
                ) {
                    const spreadFloor = item.spread % 1 >= 0.5 ? Math.ceil(item.spread) : Math.floor(item.spread);
                    spreadCounts[spreadFloor] = spreadCounts[spreadFloor] ? spreadCounts[spreadFloor] + 1 : 1;
                }
            });

            const formattedSpreadData = Object.keys(spreadCounts).map(spread => ({
                name: name,
                spread: spread,
                spreadNumber: parseInt(spread),
                total: spreadCounts[parseInt(spread)],
            }));

            reformattedData.push(...formattedSpreadData);
        });

        return reformattedData;
    }

    const reformattedData = reformatData(spreadCompare);
    const totalTicks = reformattedData?.reduce((acc, curr) => acc + curr.total, 0);
    // console.log(reformattedData, totalTicks);

    const config = {
        data: [...reformattedData],
        isGroup: true,
        xField: "spread",
        yField: "total",
        seriesField: "name",
        // 分组柱状图 组内柱子间的间距 (像素级别)
        dodgePadding: 2,
        // 分组柱状图 组间的间距 (像素级别)
        intervalPadding: 20,
        label: {
            // 可手动配置 label 数据标签位置
            position: "middle",
            // 'top', 'middle', 'bottom'
            // 可配置附加的布局方法
            layout: [
                // 柱形图数据标签位置自动调整
                {
                    type: "interval-adjust-position",
                }, // 数据标签防遮挡
                {
                    type: "interval-hide-overlap",
                }, // 数据标签文颜色自动调整
                {
                    type: "adjust-color",
                },
            ],
        },
        slider: {
            start: 0,
            end: 1,
        },
    };
    // backup
    // const config = {
    //     data: [...reformattedData],
    //     // isGroup: true,
    //     xField: "spread",
    //     yField: "total",
    //     // label: {
    //     //     // 可手动配置 label 数据标签位置
    //     //     position: "middle",
    //     //     // 'top', 'bottom', 'middle',
    //     //     // 配置样式
    //     //     style: {
    //     //         fill: "#FFFFFF",
    //     //         opacity: 0.6,
    //     //     },
    //     // },
    //     // xAxis: {
    //     //     label: {
    //     //         autoHide: true,
    //     //         autoRotate: false,
    //     //     },
    //     // },
    //     // meta: {
    //     //     type: {
    //     //         alias: "Spread",
    //     //     },
    //     //     sales: {
    //     //         alias: "Total",
    //     //     },
    //     // },
    //     slider: {
    //         start: 0,
    //         end: 1,
    //     },
    // };

    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            {/* toggle use dummy data */}
            {/* <Button
                type="dashed"
                onClick={() => {
                    setApplyDummy(prev => !prev);
                }}
            >
                Dummy Data ({applyDummy ? "ON" : "OFF"})
            </Button> */}
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Title level={5}>Analysis</Title>
            </div>
            {averageQueryRecords.length >= 1 && (
                <DraggableContainer>
                    {averageQueryRecords.map((record, index) => {
                        const isHighlight = isEqual(record, averageSpreadAnalysisQuery);
                        return (
                            <RecordCard
                                key={record.start + record.end + record.groups.join("") + record.symbol + index + isHighlight}
                                isHighlight={isHighlight}
                                record={record}
                            />
                        );
                    })}
                </DraggableContainer>
            )}
            {totalTicks > 0 && (
                <Text>
                    Results from{" "}
                    {
                        // convert to thousand separator
                        totalTicks.toLocaleString("en-US", {
                            maximumFractionDigits: 0,
                        })
                    }{" "}
                    data points
                </Text>
            )}
            {!averageSpreadAnalysisQuery ? (
                <Alert message="Please generate a report first" type="warning" />
            ) : spreadCompare ? (
                <div id="chartContainer">
                    <div style={{ width: "100%", backgroundColor: "rgba(240,242,245,0.5)", padding: "1rem", borderRadius: "5px" }}>
                        {spreadCompareError && (
                            <Alert
                                message="Failed to load data"
                                description={"Error Message : " + spreadCompareError}
                                type="error"
                                action={
                                    <Button size="small" type="text" onClick={() => refetchSpreadCompare()}>
                                        Retry
                                    </Button>
                                }
                            />
                        )}
                        {/* @ts-ignore   */}
                        <Column {...config} />
                    </div>
                </div>
            ) : isLoading ? (
                "Loading..."
            ) : (
                "no data"
            )}
        </Space>
    );
};

const DraggableContainer = ({ children }: { children: any }) => {
    const ref = useRef(null);
    return (
        <div className="container" ref={ref} style={{ overflow: "hidden" }}>
            <motion.div
                className="scroller"
                drag="x"
                dragConstraints={ref}
                onMeasureDragConstraints={console.log}
                style={{
                    display: "flex",
                    flexWrap: "nowrap",
                    minWidth: "100%",
                    // fit the width of the children
                    width: "fit-content",
                }}
            >
                {children}
            </motion.div>
        </div>
    );
};

const RecordCard = ({ isHighlight = false, record }: { isHighlight: boolean; record: any }) => {
    const manuallyUpdateAverageSpreadAnalysisQuery = useRCSpreadReportStore(state => state.manuallyUpdateAverageSpreadAnalysisQuery);
    const removeAverageSpreadAnalysisQueryRecord = useRCSpreadReportStore(state => state.removeAverageSpreadAnalysisQueryRecord);

    const { Title, Text } = Typography;
    const color = { color: isHighlight ? "#fff" : "#000" };

    const recordStart = // check if it's time stamp with T, convert it to YYYY-MM-DD HH:mm
        record?.start?.includes("T") ? moment(record.start).format("YYYY-MM-DD HH:mm") : record?.start ?? null;
    const recordEnd = // check if it's time stamp with T, convert it to YYYY-MM-DD HH:mm
        record?.end?.includes("T") ? moment(record.end).format("YYYY-MM-DD HH:mm") : record?.end ?? null;
    const timeRange = `${record?.startTime} - ${record?.endTime}`;
    return (
        <motion.div
            initial={{ scale: 0.98 }}
            animate={{ scale: 0.98 }}
            style={{
                scale: 0.98,
                flex: "0 0 auto",
                width: "200px",
                height: "200px",
                padding: "0.5rem",
                marginRight: "10px",
                backgroundColor: isHighlight ? "#00615D" : "#f0f0f0",
                color: isHighlight ? "#fff" : "#000",
                // border: isHighlight ? "1px solid transparent" : "1px solid transparent",
                borderRadius: "4px",
                overflowY: "auto",
                cursor: "pointer",
            }}
            whileHover={{ scale: 1 }}
            onClick={() => {
                manuallyUpdateAverageSpreadAnalysisQuery(record);
            }}
        >
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Title level={5} style={{ ...color, marginBottom: 0 }}>
                    {record?.symbol}
                </Title>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "0.25rem" }}>
                    {!isHighlight && (
                        <Tooltip title="remove">
                            <Button
                                size="small"
                                type="default"
                                shape="default"
                                icon={<DeleteOutlined />}
                                disabled={isHighlight}
                                onClick={e => {
                                    e.stopPropagation();
                                    removeAverageSpreadAnalysisQueryRecord(record);
                                }}
                            />
                        </Tooltip>
                    )}
                </div>
            </div>
            <Text style={{ ...color }}>
                <strong>Start:</strong> {recordStart}
            </Text>
            <br />
            <Text style={{ ...color }}>
                <strong>End:</strong> {recordEnd}
            </Text>
            <br />
            <Text style={{ ...color }}>
                <strong>Time:</strong> {timeRange}
            </Text>
            <br />
            <div style={{ display: "flex", gap: "2px", flexWrap: "wrap" }}>
                {["2", "4", "1"].map((group: string) => {
                    return (
                        record?.originalGroups?.[group]?.map((each: string, index: number) => {
                            const tagColor: { [key: string]: string } = {
                                "1": "blue",
                                "2": "green",
                                "4": "red",
                            };
                            const groupNaming: { [key: string]: string } = {
                                "1": "Liquidity Provider",
                                "2": "Our Servers",
                                "4": "Outsider Brokers",
                            };
                            const prefix: { [key: string]: string } = {
                                "1": "LP",
                                "2": "OS",
                                "4": "OB",
                            };

                            return (
                                <Tooltip title={groupNaming[group]} key={each + index}>
                                    <Tag key={each + index} color={tagColor[group]} style={{ margin: 0 }}>
                                        <strong style={{ color: tagColor[group], padding: "0 2px" }}>{prefix[group]}</strong> {each}
                                    </Tag>
                                </Tooltip>
                            );
                        }) ?? []
                    ); // Use an empty array if the group doesn't exist
                })}
            </div>
        </motion.div>
    );
};

// export default SpreadAnalysisRecords;
export default React.memo(AverageSpreadAnalysisRecords);
