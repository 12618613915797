import React from "react";
import { colorMap, getContrastColor } from "../StatusMonitor/statusUtils";
import { FaCheck, FaInfoCircle } from "react-icons/fa";
import { LuAlertTriangle } from "react-icons/lu";
import { Space } from "antd";
const MessageCard = ({
    children,
    type = "success",
    icon = true,
    size = "default",
}: {
    children: React.ReactNode;
    type?: "success" | "warning" | "error" | "info";
    icon?: boolean | React.ReactNode;
    size?: "default" | "small";
}) => {
    // get contrast
    return (
        <div
            style={{
                backgroundColor: colorMap[type],
                color: getContrastColor({ hexColor: colorMap[type] }),
                borderRadius: "0.5rem",
                padding: type === "success" && size === "default" ? "2rem" : "1rem",
                display: "inline-block",
                fontSize: type === "success" && size === "default" ? "2rem" : "1rem",
            }}
        >
            <div
                style={{
                    justifyContent: "center",
                    alignItems: "center",
                    gap: type === "success" && size === "default" ? "1rem" : "0.5rem",
                    display: "flex",
                }}
            >
                {icon === false ? null : icon === true ? (
                    <>
                        {type === "success" && <FaCheck />}
                        {type === "warning" && <LuAlertTriangle />}
                        {type === "error" && <LuAlertTriangle />}
                        {type === "info" && <FaInfoCircle />}
                    </>
                ) : (
                    icon
                )}
                <div>
                    <>{children}</>
                </div>
            </div>
        </div>
    );
};

export default MessageCard;
