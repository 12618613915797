import { useState, useEffect } from "react";
import FlexiDataTable from "../../../components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType } from "../../../constants";
import {
    CustomPaginationProps,
    FlexiDataTableOptionsProps,
    FlexiDataTableCallbackProps,
    ProblematicUploadAccountResponse,
    CrmBlockTypes,
} from "../../../constants/type";
import { apiRequest } from "../../../services/apiConfig";
import { APIs } from "../../../services/apis";
import { DTColProps, DataTableColumnRender, ErrorCatchValidator } from "../../../utils/Common";
import { Tag, Typography, Tooltip } from "antd";
import { DateTimeUtil } from "../../../utils/datetime";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

interface HistoricalAccountUploadListProps {
    blockTypeList: CrmBlockTypes[]
}

const HistoricalAccountUploadList = (props: HistoricalAccountUploadListProps) => {
    const columns = [
        DTColProps.Small({
            title: "Account ID",
            dataIndex: "accountId",
            key: "accountId",
            options: {
                filter: {
                    type: ComponentType.number,
                },
            },
        }),
        DTColProps.XSmall({
            title: "Server",
            dataIndex: "server",
            key: "server",
            options: {
                filter: {
                    type: ComponentType.text,
                },
            },
        }),
        DTColProps.XSmall({
            title: "Brand",
            dataIndex: "brand",
            key: "brand",
            options: {
                filter: {
                    type: ComponentType.text,
                },
            },
        }),
        DTColProps.XSmall({
            title: "Regulator",
            dataIndex: "regulator",
            key: "regulator",
            options: {
                filter: {
                    type: ComponentType.text,
                },
            },
        }),
        DTColProps.Small({
            title: "Status",
            dataIndex: "status",
            key: "status",
            align: "center",
            options: {
                filter: {
                    type: ComponentType.text,
                },
            },
        }),

        DTColProps.Small({
            title: "Source Type",
            dataIndex: "sourceType",
            key: "sourceType",
            options: {
                filter: {
                    type: ComponentType.text,
                },
            },
        }),

        {
            title: "Comment",
            dataIndex: "comment",
            key: "comment",
            options: {
                filter: {
                    type: ComponentType.text,
                },
            },
            render: (comment: any) =>
                comment && comment.length > 0 ? (
                    <Tooltip title={comment}>
                        <Typography.Paragraph ellipsis={{ rows: 2 }} style={{ marginBottom: "0" }}>
                            {comment}
                        </Typography.Paragraph>
                    </Tooltip>
                ) : (
                    "-"
                ),
        },

        DTColProps.Middle({
            title: "Remarks",
            dataIndex: "remarks",
            key: "remarks",
            render: (remarks: any, record: ProblematicUploadAccountResponse) =>
                remarks && remarks.length > 0 ? (
                    <Tooltip title={remarks}>
                        <Typography.Paragraph ellipsis={{ rows: 2 }} style={{ marginBottom: "0" }}>
                            {record.hasError && <Tag color="red">Error</Tag>}
                            {remarks}
                        </Typography.Paragraph>
                    </Tooltip>
                ) : (
                    "-"
                ),
        }),
        DTColProps.XSmall({
            title: "Has Error",
            dataIndex: "hasError",
            key: "hasError",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: [
                        { text: "Yes", value: true },
                        { text: "No", value: false },
                    ],
                },
                visible: false,
            },
        }),
        DTColProps.XSmall({
            title: "Creator",
            dataIndex: "creator",
            key: "creator",
            options: {
                filter: {
                    type: ComponentType.text,
                },
                visible: false,
            },
        }),
        DTColProps.XSmall({
            title: "CRM Block Type",
            dataIndex: "blockType",
            key: "blockType",
            align: "center",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: props.blockTypeList.map(x => ({ text: x.blockType, value: x.blockType })),
                },
            },
        }),
        DTColProps.XSmall({
            title: "Success Update CRM",
            dataIndex: "isSuccessUpdateCrm",
            key: "isSuccessUpdateCrm",
            align: "center",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: [
                        { text: "Yes", value: true },
                        { text: "No", value: false },
                    ],
                },
            },
            render: (isSuccessUpdateCrm: boolean) => {
                return isSuccessUpdateCrm ? (
                    <CheckCircleOutlined style={{ color: "#52C41A", fontSize: "1.25rem" }} />
                ) : (
                    <CloseCircleOutlined style={{ color: "#ff4d4f", fontSize: "1.25rem" }} />
                );
            },
        }),

        DTColProps.DateTime({
            width: "15vw",
            title: "Created On (Local)",
            dataIndex: "createdDateUtc",
            key: "createdDateUtc",
            options: {
                filter: {
                    type: ComponentType.daterange,
                    value: "",
                    inputProps: {
                        showTime: true,
                    },
                },
            },
            render: (createdDateUtc: any, record: ProblematicUploadAccountResponse) => (
                <div className="title-value-box">
                    <div className="title">By {record.creator}</div>
                    <div className="value">{DataTableColumnRender.DateTime(record.createdDateUtc)}</div>
                </div>
            ),
        }),
    ];

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [data, setData] = useState<ProblematicUploadAccountResponse[]>([]);
    const [pagination, setPagination] = useState<CustomPaginationProps>({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [sorting, setSorting] = useState<string>("createdDateUtc,desc");
    const [filterParams, setFilterParams] = useState<any>({});

    const options: FlexiDataTableOptionsProps = {
        serverFiltering: true,
    };

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.HANDLE_PAGINATION_SORTING:
                FormData.sorter.order === undefined
                    ? setSorting("createdDateUtc,desc")
                    : setSorting(
                          FormData.sorter.column && FormData.sorter.order
                              ? `${FormData.sorter.column.key},${FormData.sorter.order === "ascend" ? "asc" : "desc"}`
                              : ""
                      );
                setPagination((prev) => ({
                    ...prev,
                    current: FormData.pagination.current,
                    pageSize: FormData.pagination.pageSize,
                }));

                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                let hpcFilterParams: any = {};
                Object.keys(FormData)
                    .filter((x) => FormData[x] !== undefined && FormData[x].toString().length > 0)
                    .map((x) => {
                        if (x === "createdDateUtc") {
                            hpcFilterParams["createdDateUtcFrom"] = DateTimeUtil.GetUTC(FormData[x][0]);
                            hpcFilterParams["createdDateUtcTo"] = DateTimeUtil.GetUTC(FormData[x][1]);
                        } else {
                            hpcFilterParams[x] = FormData[x];
                        }
                        return x;
                    });
                setFilterParams(hpcFilterParams);
                setSorting("createdDateUtc,desc");
                setPagination((prev) => ({ ...prev, current: 1 }));
                setRunRefetchDataList(true);
                break;
            default:
                break;
        }
    };

    const getAccountAuditLog = () => {
        apiRequest(APIs.GET_AUDIT_LOG_ACCOUNT_PROBLEMATIC_CLIENT, {
            limit: pagination.pageSize,
            current: pagination.current,
            ...(Object.keys(filterParams).length > 0 && { ...filterParams }),
            ...(sorting.length > 0 && { order: sorting }),
        })
            .then((data: any) => {
                if (data && data.result && data.result.length > 0) {
                    let markupData = data.result.map((x: any) => ({
                        ...x,
                        key: `${Math.random()}`,
                    }));
                    setData(markupData);
                    setPagination((prev) => ({ ...prev, total: data.total }));
                } else {
                    setData([]);
                    setPagination((prev) => ({ ...prev, total: 0 }));
                }
            })
            .catch((error) => {
                ErrorCatchValidator(error, (err: any) => console.log(err));
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getAccountAuditLog();
        return () => {};
    }, []);

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            getAccountAuditLog();
            setRunRefetchDataList(false);
        }
        return () => {};
    }, [runRefetchDataList]);

    return (
        <FlexiDataTable
            rowKeyProperty="key"
            title={false}
            columns={columns}
            options={options}
            dataSource={data ?? []}
            callback={componentCallback}
            loading={isLoading}
            pagination={pagination}
            serverSide={true}
        />
    );
};

export default HistoricalAccountUploadList;
