import { Link, useNavigate } from "react-router-dom";
import { Switch, Tooltip } from "antd";
import { useGetHistoryQuery } from "../../store/apis/history";
import { FlexiDataTableCallbackProps, FlexiDataTableOptionsProps, History } from "../../constants/type";
import FlexiDataTable from "../../components/FlexiDataTable";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../utils/Common";
import CardBox from "../../components/Common/CardBox";
import { REPORT_STATUS_ENUM, CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "../../constants";
import { CheckCircleOutlined, CloseCircleOutlined, SyncOutlined } from "@ant-design/icons";
import { userInfo } from "../../services/api";
import { apiRequest, APIs } from "../../services/apiConfig";
import { useState } from "react";
import AuthHelper, { AuthKeys } from "../../helpers/authHelper";

function ReportHistoryTab() {
    let navigate = useNavigate();
    const currentLoggedInUser = userInfo();
    const { data, isLoading, refetch } = useGetHistoryQuery({});
    const [manualLoading, setManualLoading] = useState<boolean>(false);
    const authHp = new AuthHelper();
    const authEditEnabled = authHp.isAuthorized(AuthKeys.REPORT_HISTORY_EDIT);

    const handleChange = (rowData: History, checked: boolean) => {
        const sharingRules = checked === true ? 2 : 1;

        apiRequest(APIs.UPDATE_REPORT_RULES, {
            reportHistoryId: rowData.reportHistoryId,
            sharingRules,
        })
            .then((res) => {
                ErrorMessageHandler("Report sharing rule", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
            })
            .catch((error) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("report sharing rule", SUCCESS_FAILED.FAILED_UPDATE_DATA, err))
            );
    };

    const columns = [
        DTColProps.Large({
            title: "Template Name",
            dataIndex: "templateName",
            key: "templateName",
            fixed: "left",
            render: (templateName: string) => (templateName ? templateName : "-"),
            options: {
                filter: {
                    type: ComponentType.text,
                },
            },
        }),

        DTColProps.Large({
            title: "Report Name",
            dataIndex: "reportName",
            key: "reportName",
            render: (reportName: string) => (reportName ? reportName : ""),
            options: {
                filter: {
                    type: ComponentType.text,
                },
            },
        }),

        DTColProps.XSmall({
            title: "Status",
            dataIndex: "status",
            key: "status",
            align: "center",
            render: (status: number, record: any) => {
                if (status < 0) return "";
                switch (status) {
                    case REPORT_STATUS_ENUM.Generating:
                        return <SyncOutlined spin style={{ color: "#00abfd", fontSize: "1.375rem" }} />;
                    case REPORT_STATUS_ENUM.Success:
                        return <CheckCircleOutlined style={{ color: "#0ab76e", fontSize: "1.375rem" }} />;
                    default:
                        return (
                            <Tooltip title={record.remarks || ""}>
                                <CloseCircleOutlined style={{ color: "#f00f00", fontSize: "1.375rem" }} />
                            </Tooltip>
                        );
                }
            },
        }),

        DTColProps.Small({
            title: "Created By",
            dataIndex: "userName",
            key: "userName",
            render: (userName: string) => (userName ? userName : ""),
            options: {
                filter: {
                    type: ComponentType.text,
                },
            },
        }),

        DTColProps.DateTime({
            title: "Created At (Local)",
            dataIndex: "createdDateUtc",
            key: "createdDateUtc",
            sorter: false,
            //render: (datetime: string) => (datetime ? datetime : ""),
            options: {
                filter: {
                    type: ComponentType.daterange,
                    value: "",
                    inputProps: {
                        showTime: true,
                    },
                },
            },
        }),

        DTColProps.XSmall({
            title: "Sharing",
            dataIndex: "sharingRules",
            key: "sharingRules",
            render: (sharingRules: number, rowData: any) => {
                if (sharingRules < 0 || rowData.status !== 1) return "";
                if (rowData.editable === true) {
                    const switchValue = sharingRules === 1 ? false : true;

                    return (
                        <Switch
                            onChange={(checked) => {
                                handleChange(rowData, checked);
                            }}
                            checkedChildren="Public"
                            unCheckedChildren="Private"
                            defaultChecked={switchValue}
                        />
                    );
                } else return "Public";
            },
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: [
                        {
                            text: "Private",
                            value: 1,
                        },
                        {
                            text: "Public",
                            value: 2,
                        },
                    ],
                },
            },
        }),
    ];

    const options: FlexiDataTableOptionsProps = {
        view: (record: History, option: any) => {
            if (record.status === 1) {
                let newProps = { ...option };
                let viewState = `${record.reportHistoryId}|${record.reportName}|${record.templateName}|${record.userName}|${record.sharingRules}|${record.createdDateUtc}`;
                newProps = <Link to={`/report/result/view/${btoa(viewState)}`}>{newProps}</Link>;
                return newProps;
            }
        },
        add: false,
        edit: false,
        delete: authEditEnabled ? (record: History, option: any) => (record.editable ? option : undefined) : false,
        refresh: {
            timer: true,
            refreshSecond: 60,
        },
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, data) => {
        switch (type) {
            case CALLBACK_KEY.VIEW_RECORD:
                let viewState = `${data.reportHistoryId}|${data.reportName}|${data.templateName}|${data.userName}|${data.sharingRules}|${data.createdDateUtc}`;
                navigate(`/report/result/view/${btoa(viewState)}`);
                break;
            case CALLBACK_KEY.REFRESH:
                refetch();
                break;
            case CALLBACK_KEY.DO_DELETE:
                setManualLoading(true);
                apiRequest(APIs.DELETE_REPORT_HISTORY, { reportHistoryId: data.reportHistoryId })
                    .then((data) => {
                        ErrorMessageHandler("The report history", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                        setManualLoading(false);
                        refetch();
                    })
                    .catch((error) => {
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("report history", SUCCESS_FAILED.FAILED_DELETE_DATA, err));
                        setManualLoading(false);
                    });
                break;
        }
    };

    // const dataMarkup = (data: History[]): any => {
    //     if (data && data.length > 0) {
    //         let newData: any = [];
    //         let current_user = ((currentLoggedInUser?.username || "") as string).toLowerCase();
    //         newData = data.map((x: History) => {
    //             return {
    //                 ...x,
    //                 priority: current_user === (x.userName || "").toLowerCase() ? 2 : 1,
    //             };
    //         });
    //         newData.sort((a: any, b: any) => (b.priority === a.priority ? b.reportHistoryId - a.reportHistoryId : b.priority - a.priority));
    //         return newData;
    //     }
    //     return data;
    // };

    return (
        <CardBox title={"Report Result"}>
            <FlexiDataTable
                title=""
                columns={columns}
                options={options}
                dataSource={data?.data ?? []}
                callback={componentCallback}
                rowKeyProperty={"reportHistoryId"}
                loading={isLoading || manualLoading}
            />
        </CardBox>
    );
}

export default ReportHistoryTab;
