import { Form, FormInstance } from "antd";
import { FormComponent } from "../../../../components/FormComponent";
import { ComponentType } from "../../../../constants";
import { MTConfigServerProps } from ".";
import { useMemo } from "react";
import { KeyValuePair } from "../../../../constants/type";
import { REQUIRED_FIELD } from "../../../../constants/errorMessage";

export interface EditHubFailOverFormProps {
    form: FormInstance;
    editingFormObj: MTConfigServerProps | undefined;
    clusterOptions: KeyValuePair[];
}

const EditHubFailOverForm = (props: EditHubFailOverFormProps) => {
    const isMT4 = useMemo(() => {
        return props.editingFormObj && props.editingFormObj.serverCode && props.editingFormObj.serverCode.toLowerCase().indexOf("mt4") === 0;
    }, [props.editingFormObj?.serverCode]);

    const isEnableOZ = Form.useWatch("ozc_enable", props.form);
    const isEnablePE = Form.useWatch("pec_enable", props.form);
    const isEnablePXM = Form.useWatch("pxmc_enable", props.form);

    const disabledInputProps: any = {
        inputProps: {
            disabled: true,
        },
    };

    return (
        <div className="mtserverhub-config-modal-container">
            <Form form={props.form} labelCol={{ span: 7 }} wrapperCol={{ span: 16 }} layout="horizontal">
                <FormComponent label="" name={"serverId"} extra={{ type: ComponentType.hidden, value: "" }} />
                <div className="main-details">
                    <div>
                        <span className="label">Server: </span>
                        <span>{props.editingFormObj && props.editingFormObj.server}</span>
                    </div>
                    <div>
                        <FormComponent
                            label=" "
                            name={"isEnable"}
                            extra={{
                                type: ComponentType.checkbox,
                                value: "Enable",
                                itemProps: {
                                    colon: false,
                                },
                            }}
                        />
                    </div>
                </div>
                <div className="config-container">
                    <div className="item">
                        <div className="title">One Zero Configuration</div>
                        <div className="config-panel">
                            {!isMT4 && (
                                <FormComponent
                                    label="Gateway Name"
                                    name={"ozc_gatewayName"}
                                    extra={{
                                        type: ComponentType.text,
                                        value: "",
                                        ...(!isEnableOZ && disabledInputProps),
                                    }}
                                />
                            )}
                            {isMT4 && (
                                <FormComponent
                                    label="Manager Login"
                                    name={"ozc_managerLogin"}
                                    extra={{ type: ComponentType.number, value: "", ...(!isEnableOZ && disabledInputProps) }}
                                />
                            )}
                            <FormComponent
                                label=" "
                                name={"ozc_enable"}
                                extra={{
                                    type: ComponentType.checkbox,
                                    value: "Enable",
                                    itemProps: {
                                        colon: false,
                                    },
                                }}
                            />
                        </div>
                    </div>
                    <div className="item">
                        <div className="title">Price Engine Configuration</div>
                        <div className="config-panel">
                            {isMT4 && (
                                <FormComponent
                                    label="Manager Login"
                                    name={"pec_managerLogin"}
                                    extra={{ type: ComponentType.number, value: "", ...(!isEnablePE && disabledInputProps) }}
                                />
                            )}
                            {!isMT4 && (
                                <FormComponent
                                    label="Routing Name Pattern"
                                    name={"pec_routingNamePrefix"}
                                    extra={{ type: ComponentType.text, value: "", ...(!isEnablePE && disabledInputProps) }}
                                />
                            )}
                            <FormComponent
                                label="Data Feeder Name"
                                name={"pec_dataFeederName"}
                                extra={{ type: ComponentType.text, value: "", ...(!isEnablePE && disabledInputProps) }}
                            />
                            <FormComponent
                                label="Cluster"
                                name={"pec_clusterId"}
                                extra={{
                                    type: ComponentType.dropdown,
                                    value: props.clusterOptions,
                                    ...(isEnablePE ? { rules: [{ required: true, message: REQUIRED_FIELD }] } : disabledInputProps),
                                }}
                            />
                            <FormComponent
                                label=" "
                                name={"pec_enable"}
                                extra={{
                                    type: ComponentType.checkbox,
                                    value: "Enable",
                                    itemProps: {
                                        colon: false,
                                    },
                                }}
                            />
                        </div>
                    </div>
                    {/* <div className="item">
                        <div className="title">PrimeXM Configuration</div>
                        <div className="config-panel">
                            {!isMT4 && (
                                <FormComponent
                                    label="Gateway Name"
                                    name={"pxmc_gatewayName"}
                                    extra={{ type: ComponentType.text, value: "", ...(!isEnablePXM && disabledInputProps) }}
                                />
                            )}
                            {isMT4 && (
                                <FormComponent
                                    label="Manager Login"
                                    name={"pxmc_managerLogin"}
                                    extra={{ type: ComponentType.number, value: "", ...(!isEnablePXM && disabledInputProps) }}
                                />
                            )}
                            <FormComponent
                                label=" "
                                name={"pxmc_enable"}
                                extra={{
                                    type: ComponentType.checkbox,
                                    value: "Enable",
                                    itemProps: {
                                        colon: false,
                                    },
                                }}
                            />
                        </div>
                    </div> */}
                </div>
            </Form>
        </div>
    );
};

export default EditHubFailOverForm;
