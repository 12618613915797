import { KeyValuePair } from "../../../../../constants/type";
import HistoricalGroupTransferRecord from "./historicalGroupTransferRecord";
import HistoricalMarketRisk from "./historicalMarketRisk";
import HistoricalStatistics from "./historicalStatistics";

export interface GroupTransferHistoricalPageProps {
    reasonOptions: KeyValuePair[];
    selectedPage: number;
}

export const GroupTransferHistoricalPageOption = [
    { value: 1, label: "Group Transfer Tool Record" },
    { value: 2, label: "Statistics" },
    { value: 3, label: "Market Risk" },
];

const GroupTransferHistoricalPage = (props: GroupTransferHistoricalPageProps) => {
    return (
        <div className="historical-container">
            {props.selectedPage === 1 && <HistoricalGroupTransferRecord reasonOptions={props.reasonOptions} />}
            {props.selectedPage === 2 && <HistoricalStatistics />}
            {props.selectedPage === 3 && <HistoricalMarketRisk />}
        </div>
    );
};

export default GroupTransferHistoricalPage;
