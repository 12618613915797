export const serverData = [
    {
        serverUno: 519,
        serverDn: null,
        brandId: null,
        name: "Exness-Pro",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 2,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: null,
        serverAttributes: null,
    },
    {
        serverUno: 523,
        serverDn: null,
        brandId: null,
        name: "Gomarket-STP",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 2,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: null,
        serverAttributes: null,
    },
    {
        serverUno: 3,
        serverDn: null,
        brandId: null,
        name: "UK",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 1,
        serverAttributes: null,
    },
    {
        serverUno: 97,
        serverDn: null,
        brandId: null,
        name: "MT5-VJP",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 1,
        serverAttributes: null,
    },
    {
        serverUno: 21,
        serverDn: null,
        brandId: null,
        name: "VT",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 1,
        serverAttributes: null,
    },
    {
        serverUno: 31,
        serverDn: null,
        brandId: null,
        name: "UK2",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 2,
        serverAttributes: null,
    },
    {
        serverUno: 39,
        serverDn: null,
        brandId: null,
        name: "VT2",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 2,
        serverAttributes: null,
    },
    {
        serverUno: 37,
        serverDn: null,
        brandId: null,
        name: "UK3",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 3,
        serverAttributes: null,
    },
    {
        serverUno: 52,
        serverDn: null,
        brandId: null,
        name: "UK4",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 4,
        serverAttributes: null,
    },
    {
        serverUno: 65,
        serverDn: null,
        brandId: null,
        name: "UK5",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 5,
        serverAttributes: null,
    },
    {
        serverUno: 76,
        serverDn: null,
        brandId: null,
        name: "UK6",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 6,
        serverAttributes: null,
    },
    {
        serverUno: 83,
        serverDn: null,
        brandId: null,
        name: "UK7",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 7,
        serverAttributes: null,
    },
    {
        serverUno: 87,
        serverDn: null,
        brandId: null,
        name: "UK8",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 8,
        serverAttributes: null,
    },
    {
        serverUno: 94,
        serverDn: null,
        brandId: null,
        name: "UK9",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 9,
        serverAttributes: null,
    },
    {
        serverUno: 16,
        serverDn: null,
        brandId: null,
        name: "MT5-AU",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 10,
        serverAttributes: null,
    },
    {
        serverUno: 98,
        serverDn: null,
        brandId: null,
        name: "UK10",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 10,
        serverAttributes: null,
    },
    {
        serverUno: 71,
        serverDn: null,
        brandId: null,
        name: "MT5-UK",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 11,
        serverAttributes: null,
    },
    {
        serverUno: 99,
        serverDn: null,
        brandId: null,
        name: "VJP",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 11,
        serverAttributes: null,
    },
    {
        serverUno: 2,
        serverDn: null,
        brandId: null,
        name: "AU",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 12,
        serverAttributes: null,
    },
    {
        serverUno: 100,
        serverDn: null,
        brandId: null,
        name: "StarTrader3",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 12,
        serverAttributes: null,
    },
    {
        serverUno: 101,
        serverDn: null,
        brandId: null,
        name: "MT5-StarTrader2",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 13,
        serverAttributes: null,
    },
    {
        serverUno: 30,
        serverDn: null,
        brandId: null,
        name: "AU2",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 13,
        serverAttributes: null,
    },
    {
        serverUno: 102,
        serverDn: null,
        brandId: null,
        name: "MT5-VT",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 14,
        serverAttributes: null,
    },
    {
        serverUno: 36,
        serverDn: null,
        brandId: null,
        name: "AU3",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 14,
        serverAttributes: null,
    },
    {
        serverUno: 103,
        serverDn: null,
        brandId: null,
        name: "MT5-VT-DEMO",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 15,
        serverAttributes: null,
    },
    {
        serverUno: 51,
        serverDn: null,
        brandId: null,
        name: "AU4",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 15,
        serverAttributes: null,
    },
    {
        serverUno: 104,
        serverDn: null,
        brandId: null,
        name: "MT5-VJP-DEMO",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 16,
        serverAttributes: null,
    },
    {
        serverUno: 75,
        serverDn: null,
        brandId: null,
        name: "AU5",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 16,
        serverAttributes: null,
    },
    {
        serverUno: 82,
        serverDn: null,
        brandId: null,
        name: "AU6",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 17,
        serverAttributes: null,
    },
    {
        serverUno: 105,
        serverDn: null,
        brandId: null,
        name: "PU6",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 17,
        serverAttributes: null,
    },
    {
        serverUno: 106,
        serverDn: null,
        brandId: null,
        name: "PU7",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 18,
        serverAttributes: null,
    },
    {
        serverUno: 107,
        serverDn: null,
        brandId: null,
        name: "VT8",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 19,
        serverAttributes: null,
    },
    {
        serverUno: 108,
        serverDn: null,
        brandId: null,
        name: "VT9",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 20,
        serverAttributes: null,
    },
    {
        serverUno: 64,
        serverDn: null,
        brandId: null,
        name: "VT3",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 20,
        serverAttributes: null,
    },
    {
        serverUno: 68,
        serverDn: null,
        brandId: null,
        name: "VT4",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 21,
        serverAttributes: null,
    },
    {
        serverUno: 81,
        serverDn: null,
        brandId: null,
        name: "VT5",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 22,
        serverAttributes: null,
    },
    {
        serverUno: 90,
        serverDn: null,
        brandId: null,
        name: "VT6",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 23,
        serverAttributes: null,
    },
    {
        serverUno: 300,
        serverDn: null,
        brandId: null,
        name: "ISPRIME",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 4,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 24,
        serverAttributes: null,
    },
    {
        serverUno: 34,
        serverDn: null,
        brandId: null,
        name: "MT5-IUK",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 24,
        serverAttributes: null,
    },
    {
        serverUno: 6,
        serverDn: null,
        brandId: null,
        name: "IUK",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 25,
        serverAttributes: null,
    },
    {
        serverUno: 304,
        serverDn: null,
        brandId: null,
        name: "CMC(PXM)",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 4,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 25,
        serverAttributes: null,
    },
    {
        serverUno: 303,
        serverDn: null,
        brandId: null,
        name: "CFH",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 4,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 26,
        serverAttributes: null,
    },
    {
        serverUno: 54,
        serverDn: null,
        brandId: null,
        name: "BHS3",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 26,
        serverAttributes: null,
    },
    {
        serverUno: 501,
        serverDn: null,
        brandId: null,
        name: "IC-ECN",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 2,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 27,
        serverAttributes: null,
    },
    {
        serverUno: 33,
        serverDn: null,
        brandId: null,
        name: "BHS2",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 27,
        serverAttributes: null,
    },
    {
        serverUno: 302,
        serverDn: null,
        brandId: null,
        name: "Infinox_Ticket_Server",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 4,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 27,
        serverAttributes: null,
    },
    {
        serverUno: 20,
        serverDn: null,
        brandId: null,
        name: "BHS",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 28,
        serverAttributes: null,
    },
    {
        serverUno: 500,
        serverDn: null,
        brandId: null,
        name: "IG",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 2,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 28,
        serverAttributes: null,
    },
    {
        serverUno: 60,
        serverDn: null,
        brandId: null,
        name: "INF06",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 29,
        serverAttributes: null,
    },
    {
        serverUno: 514,
        serverDn: null,
        brandId: null,
        name: "Coinbase",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 2,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 29,
        serverAttributes: null,
    },
    {
        serverUno: 70,
        serverDn: null,
        brandId: null,
        name: "VIDA08",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 30,
        serverAttributes: null,
    },
    {
        serverUno: 515,
        serverDn: null,
        brandId: null,
        name: "Active",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 2,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 30,
        serverAttributes: null,
    },
    {
        serverUno: 516,
        serverDn: null,
        brandId: null,
        name: "Morningstar",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 2,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 31,
        serverAttributes: null,
    },
    {
        serverUno: 701,
        serverDn: null,
        brandId: null,
        name: "MTS-PU",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 31,
        serverAttributes: null,
    },
    {
        serverUno: 88,
        serverDn: null,
        brandId: null,
        name: "MONETA1",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 31,
        serverAttributes: null,
    },
    {
        serverUno: 93,
        serverDn: null,
        brandId: null,
        name: "MT5-Moneta1",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 32,
        serverAttributes: null,
    },
    {
        serverUno: 40,
        serverDn: null,
        brandId: null,
        name: "PUI",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 33,
        serverAttributes: null,
    },
    {
        serverUno: 63,
        serverDn: null,
        brandId: null,
        name: "PUI2",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 34,
        serverAttributes: null,
    },
    {
        serverUno: 78,
        serverDn: null,
        brandId: null,
        name: "PU3",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 35,
        serverAttributes: null,
    },
    {
        serverUno: 84,
        serverDn: null,
        brandId: null,
        name: "PU4",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 36,
        serverAttributes: null,
    },
    {
        serverUno: 92,
        serverDn: null,
        brandId: null,
        name: "PU5",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 37,
        serverAttributes: null,
    },
    {
        serverUno: 518,
        serverDn: null,
        brandId: null,
        name: "Velocity",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 2,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 38,
        serverAttributes: null,
    },
    {
        serverUno: 48,
        serverDn: null,
        brandId: null,
        name: "MT5-PUG",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 38,
        serverAttributes: null,
    },
    {
        serverUno: 517,
        serverDn: null,
        brandId: null,
        name: "Broctagon",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 2,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 39,
        serverAttributes: null,
    },
    {
        serverUno: 45,
        serverDn: null,
        brandId: null,
        name: "IV",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 39,
        serverAttributes: null,
    },
    {
        serverUno: 902,
        serverDn: null,
        brandId: null,
        name: "MT4Test-C",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 16,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 40,
        serverAttributes: null,
    },
    {
        serverUno: 700,
        serverDn: null,
        brandId: null,
        name: "MTS-AU",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 40,
        serverAttributes: null,
    },
    {
        serverUno: 77,
        serverDn: null,
        brandId: null,
        name: "StarTrader2",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 40,
        serverAttributes: null,
    },
    {
        serverUno: 72,
        serverDn: null,
        brandId: null,
        name: "MT5-StarTrader",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 41,
        serverAttributes: null,
    },
    {
        serverUno: 57,
        serverDn: null,
        brandId: null,
        name: "UM",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 42,
        serverAttributes: null,
    },
    {
        serverUno: 73,
        serverDn: null,
        brandId: null,
        name: "MT5-VIDA",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 43,
        serverAttributes: null,
    },
    {
        serverUno: 19,
        serverDn: null,
        brandId: null,
        name: "VUK",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 44,
        serverAttributes: null,
    },
    {
        serverUno: 86,
        serverDn: null,
        brandId: null,
        name: "RockFort",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 45,
        serverAttributes: null,
    },
    {
        serverUno: 89,
        serverDn: null,
        brandId: null,
        name: "MT5-AT",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 46,
        serverAttributes: null,
    },
    {
        serverUno: 512,
        serverDn: null,
        brandId: null,
        name: "Exness-STP",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 2,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 48,
        serverAttributes: null,
    },
    {
        serverUno: 95,
        serverDn: null,
        brandId: null,
        name: "VT7",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 65,
        serverAttributes: null,
    },
    {
        serverUno: 96,
        serverDn: null,
        brandId: null,
        name: "MT5-MON-DEMO",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 8,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 66,
        serverAttributes: null,
    },
    {
        serverUno: 13,
        serverDn: null,
        brandId: null,
        name: "AU-DEMO",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 8,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 67,
        serverAttributes: null,
    },
    {
        serverUno: 80,
        serverDn: null,
        brandId: null,
        name: "UK-DEMO",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 8,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 68,
        serverAttributes: null,
    },
    {
        serverUno: 43,
        serverDn: null,
        brandId: null,
        name: "PUG-DEMO",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 8,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 69,
        serverAttributes: null,
    },
    {
        serverUno: 59,
        serverDn: null,
        brandId: null,
        name: "UM-DEMO",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 8,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 70,
        serverAttributes: null,
    },
    {
        serverUno: 301,
        serverDn: null,
        brandId: null,
        name: "CMC",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 4,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 71,
        serverAttributes: null,
    },
    {
        serverUno: 61,
        serverDn: null,
        brandId: null,
        name: "AU-Tick-Server",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 72,
        serverAttributes: null,
    },
    {
        serverUno: 62,
        serverDn: null,
        brandId: null,
        name: "UK-Tick-Server",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 73,
        serverAttributes: null,
    },
    {
        serverUno: 67,
        serverDn: null,
        brandId: null,
        name: "INFTick",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 74,
        serverAttributes: null,
    },
    {
        serverUno: 79,
        serverDn: null,
        brandId: null,
        name: "MT5-AU-Tick-Server",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 75,
        serverAttributes: null,
    },
    {
        serverUno: 900,
        serverDn: null,
        brandId: null,
        name: "MT4Test",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 16,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 76,
        serverAttributes: null,
    },
    {
        serverUno: 525,
        serverDn: null,
        brandId: null,
        name: "XMTrading-STP",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 2,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 525,
        serverAttributes: null,
    },
    {
        serverUno: 526,
        serverDn: null,
        brandId: null,
        name: "XMTrading-ECN",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 2,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 526,
        serverAttributes: null,
    },
    {
        serverUno: 527,
        serverDn: null,
        brandId: null,
        name: "XMTrading-KIWAMI",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 2,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 527,
        serverAttributes: null,
    },
    {
        serverUno: 528,
        serverDn: null,
        brandId: null,
        name: "Exness-MT5-STP",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 2,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 528,
        serverAttributes: null,
    },
    {
        serverUno: 529,
        serverDn: null,
        brandId: null,
        name: "FXGT-MT5-STP",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 2,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 529,
        serverAttributes: null,
    },
    {
        serverUno: 530,
        serverDn: null,
        brandId: null,
        name: "FXGT-MT5-ECN",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 2,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 530,
        serverAttributes: null,
    },
    {
        serverUno: 531,
        serverDn: null,
        brandId: null,
        name: "FXGT-MT5-Pro",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 2,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 531,
        serverAttributes: null,
    },
    {
        serverUno: 532,
        serverDn: null,
        brandId: null,
        name: "XMTrading-MT5-ECN",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 2,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 532,
        serverAttributes: null,
    },
    {
        serverUno: 533,
        serverDn: null,
        brandId: null,
        name: "XMTrading-MT5-KIWAMI",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 2,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 533,
        serverAttributes: null,
    },
    {
        serverUno: 777,
        serverDn: null,
        brandId: null,
        name: "UMTest",
        isDel: null,
        timeZone: null,
        ip: null,
        port: null,
        type: 1,
        startDate: null,
        endDate: null,
        nowTime: null,
        ids: null,
        brandName: null,
        zoneId: null,
        order: 777,
        serverAttributes: null,
    },
];

export const dummySymbols = [
    {
        id: 0,
        serverUno: 3,
        symbol: "AALG",
        cleanSymbol: "AALG",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "AAPL",
        cleanSymbol: "AAPL",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "ABBVIE",
        cleanSymbol: "ABBVIE",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "ABNB",
        cleanSymbol: "ABNB",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "ADAUSD",
        cleanSymbol: "ADAUSD",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "ALGUSD",
        cleanSymbol: "ALGUSD",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "ALIBABA",
        cleanSymbol: "ALIBABA",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "AMAT",
        cleanSymbol: "AMAT",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "AMAZON",
        cleanSymbol: "AMAZON",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "AML",
        cleanSymbol: "AML",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "ARKB",
        cleanSymbol: "ARKB",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "AT&T",
        cleanSymbol: "AT&T",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "ATMUSD",
        cleanSymbol: "ATMUSD",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "AUDCAD",
        cleanSymbol: "AUDCAD",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "AUDCAD+",
        cleanSymbol: "AUDCAD",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "AUDCAD.Inst",
        cleanSymbol: "AUDCAD",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "AUDCHF",
        cleanSymbol: "AUDCHF",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "AUDCHF+",
        cleanSymbol: "AUDCHF",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "AUDCHF.Inst",
        cleanSymbol: "AUDCHF",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "AUDCNH",
        cleanSymbol: "AUDCNH",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "AUDCNH+",
        cleanSymbol: "AUDCNH",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "AUDCNH.Inst",
        cleanSymbol: "AUDCNH",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "AUDJPY",
        cleanSymbol: "AUDJPY",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "AUDJPY+",
        cleanSymbol: "AUDJPY",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "AUDJPY.Inst",
        cleanSymbol: "AUDJPY",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "AUDNZD",
        cleanSymbol: "AUDNZD",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "AUDNZD+",
        cleanSymbol: "AUDNZD",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "AUDNZD.Inst",
        cleanSymbol: "AUDNZD",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "AUDSGD",
        cleanSymbol: "AUDSGD",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "AUDSGD+",
        cleanSymbol: "AUDSGD",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "AUDSGD.Inst",
        cleanSymbol: "AUDSGD",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "AUDUSD",
        cleanSymbol: "AUDUSD",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "AUDUSD+",
        cleanSymbol: "AUDUSD",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "AUDUSD.Inst",
        cleanSymbol: "AUDUSD",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "AVAUSD",
        cleanSymbol: "AVAUSD",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "AVGO",
        cleanSymbol: "AVGO",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "AXP",
        cleanSymbol: "AXP",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "AXSUSD",
        cleanSymbol: "AXSUSD",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "BAC",
        cleanSymbol: "BAC",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "BAIDU",
        cleanSymbol: "BAIDU",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "BATUSD",
        cleanSymbol: "BATUSD",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "BCHUSD",
        cleanSymbol: "BCHUSD",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "BITB",
        cleanSymbol: "BITB",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "BITO",
        cleanSymbol: "BITO",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "BNBUSD",
        cleanSymbol: "BNBUSD",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "BNTX",
        cleanSymbol: "BNTX",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "BOEING",
        cleanSymbol: "BOEING",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "BTCBCH",
        cleanSymbol: "BTCBCH",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "BTCETH",
        cleanSymbol: "BTCETH",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "BTCEUR",
        cleanSymbol: "BTCEUR",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "BTCJPY",
        cleanSymbol: "BTCJPY",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "BTCLTC",
        cleanSymbol: "BTCLTC",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "BTCO",
        cleanSymbol: "BTCO",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "BTCUSD",
        cleanSymbol: "BTCUSD",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "BUD",
        cleanSymbol: "BUD",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "BVSPX",
        cleanSymbol: "BVSPX",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "BVSPX.r",
        cleanSymbol: "BVSPX",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "BVSPX.v",
        cleanSymbol: "BVSPX",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "CADCHF",
        cleanSymbol: "CADCHF",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "CADCHF+",
        cleanSymbol: "CADCHF",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "CADCHF.Inst",
        cleanSymbol: "CADCHF",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "CADJPY",
        cleanSymbol: "CADJPY",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "CADJPY+",
        cleanSymbol: "CADJPY",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "CADJPY.Inst",
        cleanSymbol: "CADJPY",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "CAT",
        cleanSymbol: "CAT",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "CHFJPY",
        cleanSymbol: "CHFJPY",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "CHFJPY+",
        cleanSymbol: "CHFJPY",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "CHFJPY.Inst",
        cleanSymbol: "CHFJPY",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "CHFSGD",
        cleanSymbol: "CHFSGD",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "CHFSGD+",
        cleanSymbol: "CHFSGD",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "CHFSGD.Inst",
        cleanSymbol: "CHFSGD",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "CHINA50",
        cleanSymbol: "CHINA50",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "CHINA50.r",
        cleanSymbol: "CHINA50",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "CHINA50.v",
        cleanSymbol: "CHINA50",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "CHINA50ft",
        cleanSymbol: "CHINA50ft",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "CHINA50ft.r",
        cleanSymbol: "CHINA50ft",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "CHINA50ft.v",
        cleanSymbol: "CHINA50ft",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "CISCO",
        cleanSymbol: "CISCO",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "CITI",
        cleanSymbol: "CITI",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "CL-OIL",
        cleanSymbol: "CL-OIL",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "CMCSA",
        cleanSymbol: "CMCSA",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "Cocoa-C",
        cleanSymbol: "Cocoa",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "Cocoa-Cr",
        cleanSymbol: "Cocoa",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "Coffee-C",
        cleanSymbol: "Coffee",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "Coffee-Cr",
        cleanSymbol: "Coffee",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
    {
        id: 0,
        serverUno: 3,
        symbol: "COIN",
        cleanSymbol: "COIN",
        noPriceTime: 0,
        openNoPrice: 0,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        digit: 0,
        showStatus: 0,
        alertStatus: 0,
        forceSession: 0,
        pointSite: 0,
        modifyTime: null,
        isNew: 0,
        serverName: null,
        serverType: 0,
        typeName: null,
    },
];
export const dummyUK2data = {
    d_idx: {
        sign: 0,
        bid: 1,
        ask: 2,
        mid: 3,
        spread: 4,
        ticktime: 5,
    },
    data: {
        AALG: [6, 11.11, 11.18, 11.145, 7, 20240710225732],
        AAPL: [6, 232.87, 232.99, 232.93, 12, 20240710225958],
        ABBVIE: [6, 167.99, 168.27, 168.13, 28, 20240710225958],
        ADAUSD: [0, 0.3866, 0.3929, 0.38975, 63, 20240711041421],
        ALGUSD: [0, 0.1371, 0.1399, 0.1385, 28, 20240711041359],
        ALIBABA: [6, 75.97, 76, 75.985, 3, 20240710225958],
        AMAZON: [6, 199.75, 199.81, 199.78, 6, 20240710225957],
        ARKB: [6, 57.31, 57.36, 57.335, 5, 20240710225958],
        "AT&T": [6, 18.7, 18.77, 18.735, 7, 20240710225950],
        ATMUSD: [0, 6.017, 6.081, 6.049, 64, 20240711041418],
        AUDCAD: [0, 0.91946, 0.9197, 0.91958, 24, 20240711041421],
        "AUDCAD+": [0, 0.91952, 0.91963, 0.919575, 11, 20240711041421],
        "AUDCAD.Inst": [0, 0.91952, 0.91963, 0.919575, 11, 20240711041421],
        AUDCHF: [0, 0.60693, 0.60712, 0.607025, 19, 20240711041422],
        "AUDCHF+": [0, 0.60699, 0.60706, 0.607025, 7, 20240711041422],
        "AUDCHF.Inst": [0, 0.60699, 0.60706, 0.607025, 7, 20240711041422],
        AUDCNH: [0, 4.92138, 4.92221, 4.921795, 83, 20240711041422],
        "AUDCNH+": [0, 4.92154, 4.92205, 4.921795, 51, 20240711041422],
        "AUDCNH.Inst": [0, 4.92154, 4.92205, 4.921795, 51, 20240711041422],
        AUDJPY: [0, 109.111, 109.134, 109.1225, 23, 20240711041422],
        "AUDJPY+": [0, 109.117, 109.128, 109.1225, 11, 20240711041422],
        "AUDJPY.Inst": [0, 109.117, 109.128, 109.1225, 11, 20240711041422],
        AUDNZD: [0, 1.1088, 1.10909, 1.108945, 29, 20240711041422],
        "AUDNZD+": [0, 1.10886, 1.10903, 1.108945, 17, 20240711041422],
        "AUDNZD.Inst": [0, 1.10886, 1.10903, 1.108945, 17, 20240711041422],
        AUDSGD: [0, 0.91036, 0.9106, 0.91048, 24, 20240711041422],
        "AUDSGD+": [0, 0.91042, 0.91054, 0.91048, 12, 20240711041422],
        "AUDSGD.Inst": [0, 0.91042, 0.91054, 0.91048, 12, 20240711041422],
        AUDUSD: [0, 0.67503, 0.6752, 0.675115, 17, 20240711041421],
        "AUDUSD+": [0, 0.67509, 0.67514, 0.675115, 5, 20240711041421],
        "AUDUSD.Inst": [0, 0.67509, 0.67514, 0.675115, 5, 20240711041421],
        AVAUSD: [0, 25.25, 25.68, 25.465, 43, 20240711041411],
        AXSUSD: [0, 5.32, 5.39, 5.355, 7, 20240711041406],
        BAC: [6, 41.69, 41.77, 41.73, 8, 20240710225957],
        BAIDU: [6, 97.83, 97.98, 97.905, 15, 20240710225954],
        BATUSD: [0, 0.1771, 0.1814, 0.17925, 43, 20240711041400],
        BCHUSD: [0, 337.59, 340.35, 338.97, 276, 20240711041422],
        BITB: [6, 31.26, 31.3, 31.28, 4, 20240710225958],
        BITO: [6, 20.09, 20.11, 20.1, 2, 20240710225955],
        BNBUSD: [0, 521.5, 524.15, 522.825, 265, 20240711041422],
        BNTX: [6, 81.02, 81.42, 81.22, 40, 20240710225906],
        BOEING: [6, 183.5, 183.91, 183.705, 41, 20240710225958],
        BTCBCH: [0, 169.687, 169.965, 169.826, 278, 20240711041422],
        BTCETH: [0, 18.5985, 18.6042, 18.60135, 57, 20240711041422],
        BTCEUR: [0, 53280.49, 53355.72, 53318.105, 7523, 20240711041422],
        BTCJPY: [0, 9303187, 9305518, 9304352.5, 2331, 20240711041421],
        BTCLTC: [0, 858.032, 859.068, 858.55, 1036, 20240711041421],
        BTCO: [6, 57.34, 57.45, 57.395, 11, 20240710225955],
        BTCUSD: [0, 57559.54, 57571.96, 57565.75, 1242, 20240711041418],
        BUD: [6, 60.24, 60.34, 60.29, 10, 20240710225958],
        BVSPX: [6, 127126.38, 127152.03, 127139.205, 2565, 20240710235457],
        "BVSPX.r": [6, 127123.38, 127155.03, 127139.205, 3165, 20240710235457],
        "BVSPX.v": [6, 127121.38, 127157.03, 127139.205, 3565, 20240710235457],
        CADCHF: [0, 0.66002, 0.6602, 0.66011, 18, 20240711041422],
        "CADCHF+": [0, 0.66008, 0.66014, 0.66011, 6, 20240711041422],
        "CADCHF.Inst": [0, 0.66008, 0.66014, 0.66011, 6, 20240711041422],
        CADJPY: [0, 118.654, 118.673, 118.6635, 19, 20240711041422],
        "CADJPY+": [0, 118.66, 118.667, 118.6635, 7, 20240711041422],
        "CADJPY.Inst": [0, 118.66, 118.667, 118.6635, 7, 20240711041422],
        CHFJPY: [0, 179.77, 179.792, 179.781, 22, 20240711041420],
        "CHFJPY+": [0, 179.776, 179.786, 179.781, 10, 20240711041420],
        "CHFJPY.Inst": [0, 179.776, 179.786, 179.781, 10, 20240711041420],
        CHFSGD: [0, 1.49974, 1.50007, 1.499905, 33, 20240711041422],
        "CHFSGD+": [0, 1.49981, 1.5, 1.499905, 19, 20240711041422],
        "CHFSGD.Inst": [0, 1.49981, 1.5, 1.499905, 19, 20240711041422],
        CHINA50: [0, 12009, 12015, 12012, 6, 20240711041421],
        "CHINA50.r": [0, 12009, 12016, 12012.5, 7, 20240711041421],
        "CHINA50.v": [0, 12006, 12019, 12012.5, 13, 20240711041421],
        CHINA50ft: [0, 11945.9, 11953, 11949.45, 71, 20240711041421],
        "CHINA50ft.r": [0, 11945.9, 11953.1, 11949.5, 72, 20240711041421],
        "CHINA50ft.v": [0, 11945.6, 11953.4, 11949.5, 78, 20240711041421],
        CISCO: [6, 46.22, 46.3, 46.26, 8, 20240710225959],
        CITI: [6, 66.92, 67.01, 66.965, 9, 20240710225958],
        "CL-OIL": [0, 82.728, 82.763, 82.7455, 35, 20240711041412],
        CMCSA: [6, 37.39, 37.48, 37.435, 9, 20240710225959],
        "Cocoa-C": [6, 8684.2, 8694.2, 8689.2, 100, 20240710202959],
        "Cocoa-Cr": [6, 8684.2, 8694.2, 8689.2, 100, 20240710202959],
        "Coffee-C": [6, 2.4708, 2.4737, 2.47225, 29, 20240710202957],
        "Coffee-Cr": [6, 2.4708, 2.4738, 2.4723, 30, 20240710202957],
        COIN: [6, 218.79, 219.97, 219.38, 118, 20240710225950],
        "COPPER-C": [0, 4.5798, 4.5833, 4.58155, 35, 20240711041421],
        "COPPER-Cr": [0, 4.5797, 4.5834, 4.58155, 37, 20240711041421],
        CRM: [6, 252.11, 252.92, 252.515, 81, 20240710225952],
        CRVUSD: [0, 0.286, 0.298, 0.292, 12, 20240711040500],
        CVX: [6, 154.85, 155.28, 155.065, 43, 20240710225959],
        DISNEY: [6, 96.9, 96.95, 96.925, 5, 20240710225958],
        DJ30: [0, 39746.85, 39748.15, 39747.5, 130, 20240711041421],
        "DJ30.l": [0, 39746.85, 39748.15, 39747.5, 130, 20240711041421],
        "DJ30.r": [0, 39746.1, 39748.9, 39747.5, 280, 20240711041421],
        "DJ30.v": [0, 39745.7, 39749.3, 39747.5, 360, 20240711041421],
        DJ30ft: [0, 40001, 40006, 40003.5, 500, 20240711041421],
        "DJ30ft.r": [0, 40000.88, 40006.11, 40003.495, 523, 20240711041421],
        "DJ30ft.v": [0, 39999.85, 40007.15, 40003.5, 730, 20240711041421],
        DOGUSD: [0, 0.1071, 0.1081, 0.1076, 10, 20240711041411],
        DOTUSD: [0, 6.026, 6.111, 6.0685, 85, 20240711041421],
        EOSUSD: [0, 0.5199, 0.5291, 0.5245, 92, 20240711041040],
        ES35: [6, 11074.85, 11077.47, 11076.16, 262, 20240710205959],
        "ES35.r": [6, 11074.59, 11077.74, 11076.165, 315, 20240710205959],
        "ES35.v": [6, 11072.99, 11079.34, 11076.165, 635, 20240710205959],
        ETCUSD: [0, 20.852, 21.056, 20.954, 204, 20240711041422],
        ETHBCH: [0, 9.1228, 9.1369, 9.12985, 141, 20240711041422],
        ETHEUR: [0, 2850.26, 2858.36, 2854.31, 810, 20240711041420],
        ETHJPY: [0, 499975, 500428, 500201.5, 453, 20240711041422],
        ETHLTC: [0, 46.1305, 46.1808, 46.15565, 503, 20240711041422],
        ETHUSD: [0, 3093.44, 3096.02, 3094.73, 258, 20240711041422],
        EU50: [0, 4966.45, 4967.75, 4967.1, 130, 20240711041027],
        "EU50.r": [0, 4966.33, 4967.88, 4967.105, 155, 20240711041027],
        "EU50.v": [0, 4965.81, 4968.39, 4967.1, 258, 20240711041027],
        EUB10Y: [0, 131.25, 131.28, 131.265, 3, 20240711041220],
        EUB2Y: [0, 105.5, 105.53, 105.515, 3, 20240711031523],
        EUB30Y: [0, 129.84, 129.87, 129.855, 3, 20240711041219],
        EUB5Y: [0, 116.06, 116.09, 116.075, 3, 20240711034528],
        EURAUD: [0, 1.60477, 1.60499, 1.60488, 22, 20240711041422],
        "EURAUD+": [0, 1.60483, 1.60492, 1.604875, 9, 20240711041422],
        "EURAUD.Inst": [0, 1.60483, 1.60492, 1.604875, 9, 20240711041422],
        EURCAD: [0, 1.47571, 1.47593, 1.47582, 22, 20240711041422],
        "EURCAD+": [0, 1.47577, 1.47587, 1.47582, 10, 20240711041422],
        "EURCAD.Inst": [0, 1.47577, 1.47587, 1.47582, 10, 20240711041422],
        EURCHF: [0, 0.97411, 0.97431, 0.97421, 20, 20240711041420],
        "EURCHF+": [0, 0.97417, 0.97425, 0.97421, 8, 20240711041420],
        "EURCHF.Inst": [0, 0.97417, 0.97425, 0.97421, 8, 20240711041420],
        EURCZK: [0, 25.3816, 25.4037, 25.39265, 221, 20240711041409],
        "EURCZK+": [0, 25.3826, 25.4027, 25.39265, 201, 20240711041409],
        EURDKK: [0, 7.45775, 7.46148, 7.459615, 373, 20240711041354],
        "EURDKK+": [0, 7.4585, 7.46073, 7.459615, 223, 20240711041354],
        EURGBP: [0, 0.84292, 0.84307, 0.842995, 15, 20240711041421],
        "EURGBP+": [0, 0.84297, 0.843, 0.842985, 3, 20240711041421],
        "EURGBP.Inst": [0, 0.84297, 0.843, 0.842985, 3, 20240711041421],
        EURHUF: [0, 393.329, 393.866, 393.5975, 537, 20240711041419],
        "EURHUF+": [0, 393.389, 393.806, 393.5975, 417, 20240711041419],
        EURIBOR3M: [0, 96.463, 96.483, 96.473, 20, 0],
        EURJPY: [0, 175.122, 175.139, 175.1305, 17, 20240711041421],
        "EURJPY+": [0, 175.128, 175.133, 175.1305, 5, 20240711041421],
        "EURJPY.Inst": [0, 175.128, 175.133, 175.1305, 5, 20240711041421],
        EURNOK: [0, 11.60665, 11.61379, 11.61022, 714, 20240711041414],
        "EURNOK+": [0, 11.60693, 11.61351, 11.61022, 658, 20240711041414],
        "EURNOK.Inst": [0, 11.60693, 11.61351, 11.61022, 658, 20240711041414],
        EURNZD: [0, 1.77957, 1.77988, 1.779725, 31, 20240711041422],
        "EURNZD+": [0, 1.77963, 1.77982, 1.779725, 19, 20240711041422],
        "EURNZD.Inst": [0, 1.77963, 1.77982, 1.779725, 19, 20240711041422],
        EURPLN: [0, 4.26192, 4.26429, 4.263105, 237, 20240711041421],
        "EURPLN+": [0, 4.26206, 4.26415, 4.263105, 209, 20240711041421],
        "EURPLN.Inst": [0, 4.26206, 4.26415, 4.263105, 209, 20240711041421],
        EURSEK: [0, 11.42046, 11.42691, 11.423685, 645, 20240711041420],
        "EURSEK+": [0, 11.42077, 11.42661, 11.42369, 584, 20240711041420],
        "EURSEK.Inst": [0, 11.42077, 11.42661, 11.42369, 584, 20240711041420],
        EURSGD: [0, 1.46109, 1.46133, 1.46121, 24, 20240711041422],
        "EURSGD+": [0, 1.46115, 1.46127, 1.46121, 12, 20240711041422],
        "EURSGD.Inst": [0, 1.46115, 1.46127, 1.46121, 12, 20240711041422],
        EURTRY: [0, 35.74392, 35.84351, 35.793715, 9959, 20240711041421],
        "EURTRY+": [0, 35.74399, 35.84344, 35.793715, 9945, 20240711041421],
        EURUSD: [0, 1.08341, 1.08355, 1.08348, 14, 20240711041420],
        "EURUSD+": [0, 1.08347, 1.08349, 1.08348, 2, 20240711041420],
        "EURUSD.Inst": [0, 1.08347, 1.08349, 1.08348, 2, 20240711041420],
        EXXON: [6, 111.87, 111.91, 111.89, 4, 20240710225959],
        FILUSD: [0, 3.92, 4.03, 3.975, 11, 20240711041158],
        FRA40: [0, 7578.9, 7586.3, 7582.6, 740, 20240711041408],
        "FRA40.r": [0, 7578.65, 7586.55, 7582.6, 790, 20240711041408],
        "FRA40.v": [0, 7578.15, 7587.05, 7582.6, 890, 20240711041408],
        FRA40ft: [6, 7594.18, 7602.53, 7598.355, 835, 20240710225959],
        "FRA40ft.r": [6, 7593.78, 7602.93, 7598.355, 915, 20240710225959],
        "FRA40ft.v": [6, 7592.85, 7603.85, 7598.35, 1100, 20240710225959],
        "GAS-C": [0, 2.5263, 2.5288, 2.52755, 25, 20240711041415],
        "GAS-Cr": [0, 2.5263, 2.5289, 2.5276, 26, 20240711041415],
        "GASOIL-C": [0, 777.41, 778.19, 777.8, 78, 20240711041412],
        "GASOIL-Cr": [0, 777.39, 778.2, 777.795, 81, 20240711041412],
        GBPAUD: [0, 1.90365, 1.90396, 1.903805, 31, 20240711041422],
        "GBPAUD+": [0, 1.90371, 1.9039, 1.903805, 19, 20240711041422],
        "GBPAUD.Inst": [0, 1.90371, 1.9039, 1.903805, 19, 20240711041422],
        GBPCAD: [0, 1.75057, 1.75083, 1.7507, 26, 20240711041422],
        "GBPCAD+": [0, 1.75063, 1.75077, 1.7507, 14, 20240711041422],
        "GBPCAD.Inst": [0, 1.75063, 1.75077, 1.7507, 14, 20240711041422],
        GBPCHF: [0, 1.15559, 1.15573, 1.15566, 14, 20240711041420],
        "GBPCHF+": [0, 1.15564, 1.15566, 1.15565, 2, 20240711041420],
        "GBPCHF.Inst": [0, 1.15564, 1.15566, 1.15565, 2, 20240711041420],
        GBPJPY: [0, 207.731, 207.751, 207.741, 20, 20240711041421],
        "GBPJPY+": [0, 207.737, 207.745, 207.741, 8, 20240711041421],
        "GBPJPY.Inst": [0, 207.737, 207.745, 207.741, 8, 20240711041421],
        GBPNZD: [0, 2.11104, 2.11141, 2.111225, 37, 20240711041422],
        "GBPNZD+": [0, 2.1111, 2.11135, 2.111225, 25, 20240711041422],
        "GBPNZD.Inst": [0, 2.1111, 2.11135, 2.111225, 25, 20240711041422],
        GBPSGD: [0, 1.73324, 1.73351, 1.733375, 27, 20240711041422],
        "GBPSGD+": [0, 1.7333, 1.73345, 1.733375, 15, 20240711041422],
        "GBPSGD.Inst": [0, 1.7333, 1.73345, 1.733375, 15, 20240711041422],
        GBPUSD: [0, 1.28522, 1.28537, 1.285295, 15, 20240711041421],
        "GBPUSD+": [0, 1.28528, 1.2853, 1.28529, 2, 20240711041421],
        "GBPUSD.Inst": [0, 1.28528, 1.2853, 1.28529, 2, 20240711041421],
        GBTC: [6, 50.88, 50.93, 50.905, 5, 20240710225959],
        GBXUSD: [0, 0.01306, 0.01306, 0.01306, 0, 20200813081100],
        GER40: [0, 18443.45, 18445.55, 18444.5, 210, 20240711041416],
        "GER40.Inst": [0, 18435.2, 18438.8, 18437, 360, 20240711041416],
        "GER40.l": [0, 18443.45, 18445.55, 18444.5, 210, 20240711041416],
        "GER40.r": [0, 18443.03, 18445.98, 18444.505, 295, 20240711041416],
        "GER40.v": [0, 18442.41, 18446.59, 18444.5, 418, 20240711041416],
        GER40ft: [0, 18577.75, 18586.25, 18582, 850, 20240711041416],
        "GER40ft.r": [0, 18578.55, 18585.45, 18582, 690, 20240711041416],
        "GER40ft.v": [0, 18577, 18587, 18582, 1000, 20240711041416],
        GOOG: [6, 192.64, 192.7, 192.67, 6, 20240710225959],
        GRTUSD: [0, 0.1855, 0.1871, 0.1863, 16, 20240711041406],
        HD: [6, 343.46, 344.76, 344.11, 130, 20240710225958],
        HK50: [6, 17561, 17567, 17564, 6, 20240710215956],
        "HK50.r": [6, 17561, 17567, 17564, 6, 20240710215956],
        "HK50.v": [6, 17559, 17569, 17564, 10, 20240710215956],
        HK50ft: [6, 17610, 17620, 17615, 1000, 20240710215958],
        "HK50ft.r": [6, 17611, 17619, 17615, 800, 20240710215958],
        "HK50ft.v": [6, 17608, 17622, 17615, 1400, 20240710215958],
        HON: [6, 214.23, 214.54, 214.385, 31, 20240710225959],
        HOOD: [6, 22.27, 22.34, 22.305, 7, 20240710225955],
        HSBCn: [6, 43.15, 43.24, 43.195, 9, 20240710225955],
        IBIT: [6, 32.69, 32.71, 32.7, 2, 20240710225950],
        IBM: [6, 177.62, 177.95, 177.785, 33, 20240710225955],
        INCUSD: [0, 0.4074, 0.4115, 0.40945, 41, 20240711041421],
        INTEL: [6, 34.85, 34.88, 34.865, 3, 20240710225958],
        IOTUSD: [0, 0.1531, 0.157, 0.15505, 39, 20240711041421],
        JD: [6, 26.7, 26.79, 26.745, 9, 20240710225938],
        JNJ: [6, 149.17, 149.57, 149.37, 40, 20240710225959],
        JPM: [6, 207.59, 207.94, 207.765, 35, 20240710225955],
        JPN225ft: [0, 42150.48, 42164.53, 42157.505, 1405, 20240711041421],
        KO: [6, 62.75, 62.87, 62.81, 12, 20240710225959],
        LI: [6, 20.7, 20.77, 20.735, 7, 20240710225956],
        LNKUSD: [0, 12.739, 12.824, 12.7815, 85, 20240711041422],
        LongGilt: [6, 98.06, 98.09, 98.075, 3, 20240710195959],
        LRCUSD: [0, 0.1445, 0.1465, 0.1455, 20, 20240711041416],
        LTCUSD: [0, 66.62, 67.48, 67.05, 86, 20240711041421],
        LULU: [6, 287.46, 288.29, 287.875, 83, 20240710225950],
        MA: [6, 432.97, 434.21, 433.59, 124, 20240710225958],
        MCD: [6, 250.07, 250.62, 250.345, 55, 20240710225955],
        META: [6, 534.22, 535.33, 534.775, 111, 20240710225959],
        MKRUSD: [0, 2258.69, 2280.38, 2269.535, 2169, 20240711041421],
        MMM: [6, 101.6, 102.06, 101.83, 46, 20240710225955],
        MRNA: [6, 117.18, 117.8, 117.49, 62, 20240710225948],
        MSFT: [6, 465.84, 466.55, 466.195, 71, 20240710225952],
        MTCUSD: [0, 0.5073, 0.5159, 0.5116, 86, 20240711041422],
        NAS100: [0, 20669.15, 20669.85, 20669.5, 70, 20240711041421],
        "NAS100.l": [0, 20669.15, 20669.85, 20669.5, 70, 20240711041421],
        "NAS100.r": [0, 20668.75, 20670.25, 20669.5, 150, 20240711041421],
        "NAS100.v": [0, 20668.58, 20670.43, 20669.505, 185, 20240711041421],
        NAS100ft: [0, 20856.25, 20858.75, 20857.5, 250, 20240711041421],
        "NAS100ft.r": [0, 20856.05, 20858.95, 20857.5, 290, 20240711041421],
        "NAS100ft.v": [0, 20855.65, 20859.35, 20857.5, 370, 20240711041421],
        NEOUSD: [0, 9.74, 9.89, 9.815, 15, 20240711041421],
        NERUSD: [0, 4.592, 4.627, 4.6095, 35, 20240711041421],
        NFLX: [6, 676.64, 678.81, 677.725, 217, 20240710225955],
        "NG-C": [0, 2.277, 2.287, 2.282, 10, 20240711041315],
        "NG-Cr": [0, 2.277, 2.288, 2.2825, 11, 20240711041315],
        Nikkei225: [0, 42169.5, 42176.5, 42173, 700, 20240711041416],
        NIO: [6, 4.58, 4.65, 4.615, 7, 20240710225833],
        NTES: [6, 89.97, 90.17, 90.07, 20, 20240710225957],
        NVIDIA: [6, 134.85, 135.01, 134.93, 16, 20240710225958],
        NVS: [6, 109.86, 110, 109.93, 14, 20240710225959],
        NZDCAD: [0, 0.82911, 0.82936, 0.829235, 25, 20240711041421],
        "NZDCAD+": [0, 0.82918, 0.8293, 0.82924, 12, 20240711041421],
        "NZDCAD.Inst": [0, 0.82918, 0.8293, 0.82924, 12, 20240711041421],
        NZDCHF: [0, 0.54729, 0.54749, 0.54739, 20, 20240711041421],
        "NZDCHF+": [0, 0.54735, 0.54743, 0.54739, 8, 20240711041422],
        "NZDCHF.Inst": [0, 0.54735, 0.54743, 0.54739, 8, 20240711041422],
        NZDJPY: [0, 98.387, 98.41, 98.3985, 23, 20240711041422],
        "NZDJPY+": [0, 98.393, 98.404, 98.3985, 11, 20240711041422],
        "NZDJPY.Inst": [0, 98.393, 98.404, 98.3985, 11, 20240711041422],
        NZDSGD: [0, 0.82091, 0.82115, 0.82103, 24, 20240711041422],
        "NZDSGD+": [0, 0.82097, 0.82109, 0.82103, 12, 20240711041422],
        "NZDSGD.Inst": [0, 0.82097, 0.82109, 0.82103, 12, 20240711041422],
        NZDUSD: [0, 0.6087, 0.60888, 0.60879, 18, 20240711041421],
        "NZDUSD+": [0, 0.60877, 0.60882, 0.608795, 5, 20240711041421],
        "NZDUSD.Inst": [0, 0.60877, 0.60882, 0.608795, 5, 20240711041421],
        "OJ-C": [6, 4.6377, 4.6551, 4.6464, 174, 20240710205950],
        "OJ-Cr": [6, 4.6377, 4.6552, 4.64645, 175, 20240710205950],
        ONEUSD: [0, 0.01304, 0.01332, 0.01318, 28, 20240711041422],
        ORCL: [6, 141.89, 142.21, 142.05, 32, 20240710225959],
        PEP: [6, 163.33, 163.78, 163.555, 45, 20240710225959],
        PFIZER: [6, 28.29, 28.39, 28.34, 10, 20240710225959],
        PG: [6, 166.67, 166.84, 166.755, 17, 20240710225958],
        PM: [6, 102.69, 102.84, 102.765, 15, 20240710225958],
        SA40: [6, 73595.4, 73632.2, 73613.8, 3680, 20240710182958],
        "SA40.r": [6, 73593.9, 73633.7, 73613.8, 3980, 20240710182958],
        "SA40.v": [6, 73592.4, 73635.2, 73613.8, 4280, 20240710182958],
        SANUSD: [0, 0.2952, 0.3042, 0.2997, 90, 20240711041421],
        SBUX: [6, 72.42, 72.56, 72.49, 14, 20240710225959],
        SE: [6, 74.52, 74.83, 74.675, 31, 20240710225952],
        SGDJPY: [0, 119.843, 119.865, 119.854, 22, 20240711041422],
        "SGDJPY+": [0, 119.849, 119.859, 119.854, 10, 20240711041422],
        "SGDJPY.Inst": [0, 119.849, 119.859, 119.854, 10, 20240711041422],
        SGP20: [0, 329.42, 329.66, 329.54, 24, 20240711041420],
        "SGP20.r": [0, 329.4, 329.68, 329.54, 28, 20240711041420],
        "SGP20.v": [0, 329.33, 329.76, 329.545, 43, 20240711041420],
        SHBUSD: [0, 0.1614, 0.1655, 0.16345, 41, 20240711041421],
        SHOP: [6, 65.55, 65.7, 65.625, 15, 20240710225959],
        SNOW: [6, 137.37, 137.59, 137.48, 22, 20240710225959],
        SOLUSD: [0, 141.32, 141.83, 141.575, 51, 20240711041416],
        SP500: [0, 5632.64, 5632.95, 5632.795, 31, 20240711041417],
        "SP500.r": [0, 5632.52, 5633.08, 5632.8, 56, 20240711041417],
        "SP500.v": [0, 5632.45, 5633.15, 5632.8, 70, 20240711041417],
        SP500ft: [0, 5679.46, 5680.8, 5680.13, 134, 20240711041419],
        "SP500ft.r": [0, 5679.56, 5680.7, 5680.13, 114, 20240711041419],
        "SP500ft.v": [0, 5679.26, 5681, 5680.13, 174, 20240711041419],
        SPCE: [6, 7.02, 7.09, 7.055, 7, 20240710225952],
        SPI200: [0, 7901.78, 7903.03, 7902.405, 125, 20240711041400],
        "SPI200.r": [0, 7901.6, 7903.2, 7902.4, 160, 20240711041400],
        "SPI200.v": [0, 7900.9, 7903.9, 7902.4, 300, 20240711041400],
        SPOT: [6, 304.88, 305.21, 305.045, 33, 20240710225959],
        SQ: [6, 64.2, 64.39, 64.295, 19, 20240710225957],
        "Sugar-C": [6, 0.19863, 0.19913, 0.19888, 50, 20240710195958],
        "Sugar-Cr": [6, 0.19863, 0.19914, 0.198885, 51, 20240710195958],
        SUSUSD: [0, 0.689, 0.697, 0.693, 8, 20240711041311],
        TCOM: [6, 48.93, 49.03, 48.98, 10, 20240710225959],
        TOYOTA: [6, 205.59, 205.96, 205.775, 37, 20240710225957],
        TRMB: [6, 55.29, 55.41, 55.35, 12, 20240710225955],
        TRXUSD: [0, 0.13144, 0.13235, 0.131895, 91, 20240711041419],
        TSLA: [6, 263.14, 263.46, 263.3, 32, 20240710225958],
        TSM: [6, 190.99, 191.06, 191.025, 7, 20240710225958],
        TWINDEX: [0, 2024.82, 2025.82, 2025.32, 100, 20240711041420],
        "TWINDEX.r": [0, 2024.82, 2025.82, 2025.32, 100, 20240711041420],
        "TWINDEX.v": [0, 2024.32, 2026.32, 2025.32, 200, 20240711041420],
        U: [6, 15.33, 15.42, 15.375, 9, 20240710225957],
        UK100: [0, 8203.95, 8206.05, 8205, 210, 20240711041402],
        "UK100.r": [0, 8203.74, 8206.26, 8205, 252, 20240711041402],
        "UK100.v": [0, 8203.05, 8206.95, 8205, 390, 20240711041402],
        UK100ft: [0, 8219.6, 8226.4, 8223, 680, 20240711041402],
        "UK100ft.r": [0, 8219.35, 8226.65, 8223, 730, 20240711041402],
        "UK100ft.v": [0, 8218.95, 8227.05, 8223, 810, 20240711041402],
        UKOUSD: [0, 86.163, 86.203, 86.183, 40, 20240711041413],
        UKOUSDft: [0, 85.73, 85.772, 85.751, 42, 20240711041412],
        UL: [6, 56.5, 56.59, 56.545, 9, 20240710225959],
        UNH: [6, 499.79, 501.37, 500.58, 158, 20240710225956],
        UNIUSD: [0, 7.911, 7.985, 7.948, 74, 20240711041421],
        US2000: [0, 2049.16, 2049.64, 2049.4, 48, 20240711041354],
        "US2000.r": [0, 2049.1, 2049.7, 2049.4, 60, 20240711041354],
        "US2000.v": [0, 2049.05, 2049.76, 2049.405, 71, 20240711041354],
        USDBRL: [6, 5.42849, 5.43015, 5.42932, 166, 20240710225957],
        "USDBRL+": [6, 5.42855, 5.43009, 5.42932, 154, 20240710225957],
        USDCAD: [0, 1.36213, 1.36227, 1.3622, 14, 20240711041421],
        "USDCAD+": [0, 1.36219, 1.3622, 1.362195, 1, 20240711041421],
        "USDCAD.Inst": [0, 1.36219, 1.3622, 1.362195, 1, 20240711041421],
        USDCHF: [0, 0.89907, 0.89922, 0.899145, 15, 20240711041420],
        "USDCHF+": [0, 0.89913, 0.89915, 0.89914, 2, 20240711041420],
        "USDCHF.Inst": [0, 0.89913, 0.89915, 0.89914, 2, 20240711041420],
        USDCLP: [6, 914.31, 915.16, 914.735, 85, 20240710190946],
        "USDCLP+": [6, 914.38, 915.09, 914.735, 71, 20240710190946],
        USDCNH: [0, 7.28998, 7.29062, 7.2903, 64, 20240711041422],
        "USDCNH+": [0, 7.2902, 7.29041, 7.290305, 21, 20240711041422],
        "USDCNH.Inst": [0, 7.2902, 7.29041, 7.290305, 21, 20240711041422],
        USDCOP: [6, 3987.53, 3995.23, 3991.38, 770, 20240710204923],
        "USDCOP+": [6, 3987.65, 3995.11, 3991.38, 746, 20240710204923],
        USDCZK: [0, 23.426, 23.4469, 23.43645, 2090, 20240711041421],
        "USDCZK+": [0, 23.42655, 23.44635, 23.43645, 1980, 20240711041421],
        USDDKK: [0, 6.88306, 6.88667, 6.884865, 361, 20240711041421],
        "USDDKK+": [0, 6.88376, 6.88597, 6.884865, 221, 20240711041421],
        USDHKD: [0, 7.8101, 7.81015, 7.810125, 5, 20240711041412],
        "USDHKD+": [0, 7.81009, 7.81016, 7.810125, 7, 20240711041412],
        USDHUF: [0, 362.987, 363.556, 363.2715, 569, 20240711041421],
        "USDHUF+": [0, 363.052, 363.491, 363.2715, 439, 20240711041421],
        USDIDR: [0, 16216.6, 16224.3, 16220.45, 77, 20240711041359],
        "USDIDR+": [0, 16217.5, 16223.5, 16220.5, 60, 20240711041359],
        USDINR: [6, 83.56, 83.589, 83.5745, 29, 20240710225811],
        "USDINR+": [6, 83.566, 83.583, 83.5745, 17, 20240710225811],
        USDJPY: [0, 161.623, 161.637, 161.63, 14, 20240711041419],
        "USDJPY+": [0, 161.629, 161.63, 161.6295, 1, 20240711041419],
        "USDJPY.Inst": [0, 161.629, 161.63, 161.6295, 1, 20240711041419],
        USDKRW: [0, 1379.83, 1380.29, 1380.06, 46, 20240711041421],
        "USDKRW+": [0, 1379.88, 1380.24, 1380.06, 36, 20240711041421],
        USDMXN: [0, 17.82855, 17.83576, 17.832155, 721, 20240711041418],
        "USDMXN+": [0, 17.82917, 17.83514, 17.832155, 597, 20240711041418],
        "USDMXN.Inst": [0, 17.82917, 17.83514, 17.832155, 597, 20240711041418],
        USDNOK: [0, 10.71199, 10.71938, 10.715685, 739, 20240711041421],
        "USDNOK+": [0, 10.71228, 10.7191, 10.71569, 682, 20240711041421],
        "USDNOK.Inst": [0, 10.71265, 10.71872, 10.715685, 607, 20240711041421],
        USDPLN: [0, 3.93407, 3.9352, 3.934635, 113, 20240711041420],
        "USDPLN+": [0, 3.93421, 3.93506, 3.934635, 85, 20240711041420],
        "USDPLN.Inst": [0, 3.93421, 3.93506, 3.934635, 85, 20240711041420],
        USDSEK: [0, 10.54056, 10.54646, 10.54351, 590, 20240711041421],
        "USDSEK+": [0, 10.54085, 10.54618, 10.543515, 533, 20240711041421],
        "USDSEK.Inst": [0, 10.54085, 10.54618, 10.543515, 533, 20240711041421],
        USDSGD: [0, 1.34854, 1.34872, 1.34863, 18, 20240711041422],
        "USDSGD+": [0, 1.3486, 1.34866, 1.34863, 6, 20240711041422],
        "USDSGD.Inst": [0, 1.3486, 1.34866, 1.34863, 6, 20240711041422],
        USDTHB: [0, 36.276, 36.285, 36.2805, 900, 20240711041420],
        "USDTHB+": [0, 36.277, 36.284, 36.2805, 700, 20240711041420],
        USDTJPY: [0, 161.588, 161.61, 161.599, 22, 20240711041421],
        USDTRY: [0, 32.8709, 32.92714, 32.89902, 5624, 20240711041421],
        "USDTRY+": [0, 32.87115, 32.92689, 32.89902, 5574, 20240711041421],
        USDTWD: [0, 32.559, 32.574, 32.5665, 15, 20240711041418],
        "USDTWD+": [0, 32.562, 32.571, 32.5665, 9, 20240711041418],
        USDUSC: [0, 100, 100, 100, 0, 20220620112622],
        USDX: [0, 104.656, 104.691, 104.6735, 35, 20240711041403],
        "USDX.r": [0, 104.653, 104.693, 104.673, 40, 20240711041403],
        "USDX.v": [0, 104.647, 104.7, 104.6735, 53, 20240711041403],
        USDZAR: [0, 18.12031, 18.1306, 18.125455, 1029, 20240711041420],
        "USDZAR+": [0, 18.12077, 18.13013, 18.12545, 936, 20240711041420],
        "USDZAR.Inst": [0, 18.12077, 18.13013, 18.12545, 936, 20240711041420],
        USNote10Y: [0, 110.43, 110.5, 110.465, 7, 20240711041220],
        USOUSD: [0, 82.817, 82.847, 82.832, 30, 20240711041412],
        VISA: [6, 262.65, 263.27, 262.96, 62, 20240710225959],
        VIX: [0, 12.985, 13.066, 13.0255, 81, 20240711033153],
        "VIX.r": [0, 12.984, 13.067, 13.0255, 83, 20240711033153],
        "VIX.v": [0, 12.947, 13.104, 13.0255, 157, 20240711033153],
        VZ: [6, 41.03, 41.12, 41.075, 9, 20240710225959],
        WFC: [6, 59.65, 59.75, 59.7, 10, 20240710225959],
        WMT: [6, 70.32, 70.46, 70.39, 14, 20240710225959],
        XAGAUD: [0, 45.788, 45.832, 45.81, 44, 20240711041422],
        XAGUSD: [0, 30.913, 30.94, 30.9265, 27, 20240711041422],
        XAUAUD: [0, 3517.59, 3518.48, 3518.035, 89, 20240711041422],
        "XAUAUD+": [0, 3517.69, 3518.37, 3518.03, 68, 20240711041422],
        "XAUAUD.Inst": [0, 3517.69, 3518.37, 3518.03, 68, 20240711041422],
        XAUEUR: [0, 2191.79, 2192.37, 2192.08, 58, 20240711041422],
        "XAUEUR+": [0, 2191.85, 2192.32, 2192.085, 47, 20240711041422],
        "XAUEUR.Inst": [0, 2191.85, 2192.32, 2192.085, 47, 20240711041422],
        XAUJPY: [0, 383906, 383975, 383940.5, 69, 20240711041422],
        "XAUJPY+": [0, 383911, 383970, 383940.5, 59, 20240711041422],
        "XAUJPY.Inst": [0, 383911, 383970, 383940.5, 59, 20240711041422],
        XAUUSD: [0, 2374.98, 2375.17, 2375.075, 19, 20240711041422],
        "XAUUSD+": [0, 2375.03, 2375.11, 2375.07, 8, 20240711041422],
        "XAUUSD.Inst": [0, 2375.03, 2375.11, 2375.07, 8, 20240711041422],
        XLMUSD: [0, 0.0864, 0.0888, 0.0876, 24, 20240711041421],
        XPDUSD: [0, 991.87, 995.29, 993.58, 342, 20240711041418],
        "XPDUSD.r": [0, 991.74, 995.41, 993.575, 367, 20240711041418],
        XPEV: [6, 8.03, 8.1, 8.065, 7, 20240710225945],
        XPTUSD: [0, 994.43, 997.36, 995.895, 293, 20240711041413],
        "XPTUSD.r": [0, 993.43, 998.36, 995.895, 493, 20240711041413],
        XRPUSD: [0, 0.4345, 0.4424, 0.43845, 79, 20240711041226],
        XTZUSD: [0, 0.7425, 0.7495, 0.746, 70, 20240711041422],
        ZECUSD: [0, 23.06, 23.64, 23.35, 58, 20240711041359],
        ZM: [6, 55.84, 56.11, 55.975, 27, 20240710225958],
    },
};
export const dummyCoinbaseUK2 = {
    c_idx: {
        Coinbase: 0,
    },
    d_idx: {
        sign: 0,
        mid: 1,
        spread: 2,
        ticktime: 3,
    },
    data: {
        AALG: [[0, 0, 0, 0]],
        AAPL: [[0, 0, 0, 0]],
        ABBVIE: [[0, 0, 0, 0]],
        ADAUSD: [[0, 0.38965, -3, 20240711011429]],
        ALGUSD: [[0, 0.14165, -32.5, 0]],
        ALIBABA: [[0, 0, 0, 0]],
        AMAZON: [[0, 0, 0, 0]],
        ARKB: [[0, 0, 0, 0]],
        "AT&T": [[0, 0, 0, 0]],
        ATMUSD: [[0, 6.9715, -927.5, 0]],
        AUDCAD: [[0, 0, 0, 0]],
        "AUDCAD+": [[0, 0, 0, 0]],
        "AUDCAD.Inst": [[0, 0, 0, 0]],
        AUDCHF: [[0, 0, 0, 0]],
        "AUDCHF+": [[0, 0, 0, 0]],
        "AUDCHF.Inst": [[0, 0, 0, 0]],
        AUDCNH: [[0, 0, 0, 0]],
        "AUDCNH+": [[0, 0, 0, 0]],
        "AUDCNH.Inst": [[0, 0, 0, 0]],
        AUDJPY: [[0, 0, 0, 0]],
        "AUDJPY+": [[0, 0, 0, 0]],
        "AUDJPY.Inst": [[0, 0, 0, 0]],
        AUDNZD: [[0, 0, 0, 0]],
        "AUDNZD+": [[0, 0, 0, 0]],
        "AUDNZD.Inst": [[0, 0, 0, 0]],
        AUDSGD: [[0, 0, 0, 0]],
        "AUDSGD+": [[0, 0, 0, 0]],
        "AUDSGD.Inst": [[0, 0, 0, 0]],
        AUDUSD: [[0, 0, 0, 0]],
        "AUDUSD+": [[0, 0, 0, 0]],
        "AUDUSD.Inst": [[0, 0, 0, 0]],
        AVAUSD: [[0, 25.47, -1.5, 20240711011429]],
        AXSUSD: [[0, 6.26, -91, 0]],
        BAC: [[0, 0, 0, 0]],
        BAIDU: [[0, 0, 0, 0]],
        BATUSD: [[0, 0.19603, -168.8, 0]],
        BCHUSD: [[0, 385.065, -4618, 0]],
        BITB: [[0, 0, 0, 0]],
        BITO: [[0, 0, 0, 0]],
        BNBUSD: [[0, 0, 0, 0]],
        BNTX: [[0, 0, 0, 0]],
        BOEING: [[0, 0, 0, 0]],
        BTCBCH: [[0, 0, 0, 0]],
        BTCETH: [[0, 0, 0, 0]],
        BTCEUR: [[0, 60009.69, -669995.5, 0]],
        BTCJPY: [[0, 0, 0, 0]],
        BTCLTC: [[0, 0, 0, 0]],
        BTCO: [[0, 0, 0, 0]],
        BTCUSD: [[0, 64158.48, -660623, 0]],
        BUD: [[0, 0, 0, 0]],
        BVSPX: [[0, 0, 0, 0]],
        "BVSPX.r": [[0, 0, 0, 0]],
        "BVSPX.v": [[0, 0, 0, 0]],
        CADCHF: [[0, 0, 0, 0]],
        "CADCHF+": [[0, 0, 0, 0]],
        "CADCHF.Inst": [[0, 0, 0, 0]],
        CADJPY: [[0, 0, 0, 0]],
        "CADJPY+": [[0, 0, 0, 0]],
        "CADJPY.Inst": [[0, 0, 0, 0]],
        CHFJPY: [[0, 0, 0, 0]],
        "CHFJPY+": [[0, 0, 0, 0]],
        "CHFJPY.Inst": [[0, 0, 0, 0]],
        CHFSGD: [[0, 0, 0, 0]],
        "CHFSGD+": [[0, 0, 0, 0]],
        "CHFSGD.Inst": [[0, 0, 0, 0]],
        CHINA50: [[0, 0, 0, 0]],
        "CHINA50.r": [[0, 0, 0, 0]],
        "CHINA50.v": [[0, 0, 0, 0]],
        CHINA50ft: [[0, 0, 0, 0]],
        "CHINA50ft.r": [[0, 0, 0, 0]],
        "CHINA50ft.v": [[0, 0, 0, 0]],
        CISCO: [[0, 0, 0, 0]],
        CITI: [[0, 0, 0, 0]],
        "CL-OIL": [[0, 0, 0, 0]],
        CMCSA: [[0, 0, 0, 0]],
        "Cocoa-C": [[0, 0, 0, 0]],
        "Cocoa-Cr": [[0, 0, 0, 0]],
        "Coffee-C": [[0, 0, 0, 0]],
        "Coffee-Cr": [[0, 0, 0, 0]],
        COIN: [[0, 0, 0, 0]],
        "COPPER-C": [[0, 0, 0, 0]],
        "COPPER-Cr": [[0, 0, 0, 0]],
        CRM: [[0, 0, 0, 0]],
        CRVUSD: [[0, 0.3324, -40.4, 0]],
        CVX: [[0, 0, 0, 0]],
        DISNEY: [[0, 0, 0, 0]],
        DJ30: [[0, 0, 0, 0]],
        "DJ30.l": [[0, 0, 0, 0]],
        "DJ30.r": [[0, 0, 0, 0]],
        "DJ30.v": [[0, 0, 0, 0]],
        DJ30ft: [[0, 0, 0, 0]],
        "DJ30ft.r": [[0, 0, 0, 0]],
        "DJ30ft.v": [[0, 0, 0, 0]],
        DOGUSD: [[0, 0.12716, -196.1, 0]],
        DOTUSD: [[0, 5.6315, 434, 0]],
        EOSUSD: [[0, 0.572, -475, 0]],
        ES35: [[0, 0, 0, 0]],
        "ES35.r": [[0, 0, 0, 0]],
        "ES35.v": [[0, 0, 0, 0]],
        ETCUSD: [[0, 20.97, -24, 20240711011408]],
        ETHBCH: [[0, 0, 0, 0]],
        ETHEUR: [[0, 3303.005, -44986, 0]],
        ETHJPY: [[0, 0, 0, 0]],
        ETHLTC: [[0, 0, 0, 0]],
        ETHUSD: [[0, 3532.81, -43880, 0]],
        EU50: [[0, 0, 0, 0]],
        "EU50.r": [[0, 0, 0, 0]],
        "EU50.v": [[0, 0, 0, 0]],
        EUB10Y: [[0, 0, 0, 0]],
        EUB2Y: [[0, 0, 0, 0]],
        EUB30Y: [[0, 0, 0, 0]],
        EUB5Y: [[0, 0, 0, 0]],
        EURAUD: [[0, 0, 0, 0]],
        "EURAUD+": [[0, 0, 0, 0]],
        "EURAUD.Inst": [[0, 0, 0, 0]],
        EURCAD: [[0, 0, 0, 0]],
        "EURCAD+": [[0, 0, 0, 0]],
        "EURCAD.Inst": [[0, 0, 0, 0]],
        EURCHF: [[0, 0, 0, 0]],
        "EURCHF+": [[0, 0, 0, 0]],
        "EURCHF.Inst": [[0, 0, 0, 0]],
        EURCZK: [[0, 0, 0, 0]],
        "EURCZK+": [[0, 0, 0, 0]],
        EURDKK: [[0, 0, 0, 0]],
        "EURDKK+": [[0, 0, 0, 0]],
        EURGBP: [[0, 0, 0, 0]],
        "EURGBP+": [[0, 0, 0, 0]],
        "EURGBP.Inst": [[0, 0, 0, 0]],
        EURHUF: [[0, 0, 0, 0]],
        "EURHUF+": [[0, 0, 0, 0]],
        EURIBOR3M: [[0, 0, 0, 0]],
        EURJPY: [[0, 0, 0, 0]],
        "EURJPY+": [[0, 0, 0, 0]],
        "EURJPY.Inst": [[0, 0, 0, 0]],
        EURNOK: [[0, 0, 0, 0]],
        "EURNOK+": [[0, 0, 0, 0]],
        "EURNOK.Inst": [[0, 0, 0, 0]],
        EURNZD: [[0, 0, 0, 0]],
        "EURNZD+": [[0, 0, 0, 0]],
        "EURNZD.Inst": [[0, 0, 0, 0]],
        EURPLN: [[0, 0, 0, 0]],
        "EURPLN+": [[0, 0, 0, 0]],
        "EURPLN.Inst": [[0, 0, 0, 0]],
        EURSEK: [[0, 0, 0, 0]],
        "EURSEK+": [[0, 0, 0, 0]],
        "EURSEK.Inst": [[0, 0, 0, 0]],
        EURSGD: [[0, 0, 0, 0]],
        "EURSGD+": [[0, 0, 0, 0]],
        "EURSGD.Inst": [[0, 0, 0, 0]],
        EURTRY: [[0, 0, 0, 0]],
        "EURTRY+": [[0, 0, 0, 0]],
        EURUSD: [[0, 0, 0, 0]],
        "EURUSD+": [[0, 0, 0, 0]],
        "EURUSD.Inst": [[0, 0, 0, 0]],
        EXXON: [[0, 0, 0, 0]],
        FILUSD: [[0, 4.423, -44.8, 0]],
        FRA40: [[0, 0, 0, 0]],
        "FRA40.r": [[0, 0, 0, 0]],
        "FRA40.v": [[0, 0, 0, 0]],
        FRA40ft: [[0, 0, 0, 0]],
        "FRA40ft.r": [[0, 0, 0, 0]],
        "FRA40ft.v": [[0, 0, 0, 0]],
        "GAS-C": [[0, 0, 0, 0]],
        "GAS-Cr": [[0, 0, 0, 0]],
        "GASOIL-C": [[0, 0, 0, 0]],
        "GASOIL-Cr": [[0, 0, 0, 0]],
        GBPAUD: [[0, 0, 0, 0]],
        "GBPAUD+": [[0, 0, 0, 0]],
        "GBPAUD.Inst": [[0, 0, 0, 0]],
        GBPCAD: [[0, 0, 0, 0]],
        "GBPCAD+": [[0, 0, 0, 0]],
        "GBPCAD.Inst": [[0, 0, 0, 0]],
        GBPCHF: [[0, 0, 0, 0]],
        "GBPCHF+": [[0, 0, 0, 0]],
        "GBPCHF.Inst": [[0, 0, 0, 0]],
        GBPJPY: [[0, 0, 0, 0]],
        "GBPJPY+": [[0, 0, 0, 0]],
        "GBPJPY.Inst": [[0, 0, 0, 0]],
        GBPNZD: [[0, 0, 0, 0]],
        "GBPNZD+": [[0, 0, 0, 0]],
        "GBPNZD.Inst": [[0, 0, 0, 0]],
        GBPSGD: [[0, 0, 0, 0]],
        "GBPSGD+": [[0, 0, 0, 0]],
        "GBPSGD.Inst": [[0, 0, 0, 0]],
        GBPUSD: [[0, 0, 0, 0]],
        "GBPUSD+": [[0, 0, 0, 0]],
        "GBPUSD.Inst": [[0, 0, 0, 0]],
        GBTC: [[0, 0, 0, 0]],
        GBXUSD: [[0, 0, 0, 0]],
        GER40: [[0, 0, 0, 0]],
        "GER40.Inst": [[0, 0, 0, 0]],
        "GER40.l": [[0, 0, 0, 0]],
        "GER40.r": [[0, 0, 0, 0]],
        "GER40.v": [[0, 0, 0, 0]],
        GER40ft: [[0, 0, 0, 0]],
        "GER40ft.r": [[0, 0, 0, 0]],
        "GER40ft.v": [[0, 0, 0, 0]],
        GOOG: [[0, 0, 0, 0]],
        GRTUSD: [[0, 0, 0, 0]],
        HD: [[0, 0, 0, 0]],
        HK50: [[0, 0, 0, 0]],
        "HK50.r": [[0, 0, 0, 0]],
        "HK50.v": [[0, 0, 0, 0]],
        HK50ft: [[0, 0, 0, 0]],
        "HK50ft.r": [[0, 0, 0, 0]],
        "HK50ft.v": [[0, 0, 0, 0]],
        HON: [[0, 0, 0, 0]],
        HOOD: [[0, 0, 0, 0]],
        HSBCn: [[0, 0, 0, 0]],
        IBIT: [[0, 0, 0, 0]],
        IBM: [[0, 0, 0, 0]],
        INCUSD: [[0, 0.4035, 59, 0]],
        INTEL: [[0, 0, 0, 0]],
        IOTUSD: [[0, 0, 0, 0]],
        JD: [[0, 0, 0, 0]],
        JNJ: [[0, 0, 0, 0]],
        JPM: [[0, 0, 0, 0]],
        JPN225ft: [[0, 0, 0, 0]],
        KO: [[0, 0, 0, 0]],
        LI: [[0, 0, 0, 0]],
        LNKUSD: [[0, 12.776, -2.5, 20240711011342]],
        LongGilt: [[0, 0, 0, 0]],
        LRCUSD: [[0, 0.1725, -271, 0]],
        LTCUSD: [[0, 67.065, -3, 20240711011438]],
        LULU: [[0, 0, 0, 0]],
        MA: [[0, 0, 0, 0]],
        MCD: [[0, 0, 0, 0]],
        META: [[0, 0, 0, 0]],
        MKRUSD: [[0, 2462.805, -19433.5, 0]],
        MMM: [[0, 0, 0, 0]],
        MRNA: [[0, 0, 0, 0]],
        MSFT: [[0, 0, 0, 0]],
        MTCUSD: [[0, 0.5119, -5.5, 20240711011401]],
        NAS100: [[0, 0, 0, 0]],
        "NAS100.l": [[0, 0, 0, 0]],
        "NAS100.r": [[0, 0, 0, 0]],
        "NAS100.v": [[0, 0, 0, 0]],
        NAS100ft: [[0, 0, 0, 0]],
        "NAS100ft.r": [[0, 0, 0, 0]],
        "NAS100ft.v": [[0, 0, 0, 0]],
        NEOUSD: [[0, 0, 0, 0]],
        NERUSD: [[0, 0, 0, 0]],
        NFLX: [[0, 0, 0, 0]],
        "NG-C": [[0, 0, 0, 0]],
        "NG-Cr": [[0, 0, 0, 0]],
        Nikkei225: [[0, 0, 0, 0]],
        NIO: [[0, 0, 0, 0]],
        NTES: [[0, 0, 0, 0]],
        NVIDIA: [[0, 0, 0, 0]],
        NVS: [[0, 0, 0, 0]],
        NZDCAD: [[0, 0, 0, 0]],
        "NZDCAD+": [[0, 0, 0, 0]],
        "NZDCAD.Inst": [[0, 0, 0, 0]],
        NZDCHF: [[0, 0, 0, 0]],
        "NZDCHF+": [[0, 0, 0, 0]],
        "NZDCHF.Inst": [[0, 0, 0, 0]],
        NZDJPY: [[0, 0, 0, 0]],
        "NZDJPY+": [[0, 0, 0, 0]],
        "NZDJPY.Inst": [[0, 0, 0, 0]],
        NZDSGD: [[0, 0, 0, 0]],
        "NZDSGD+": [[0, 0, 0, 0]],
        "NZDSGD.Inst": [[0, 0, 0, 0]],
        NZDUSD: [[0, 0, 0, 0]],
        "NZDUSD+": [[0, 0, 0, 0]],
        "NZDUSD.Inst": [[0, 0, 0, 0]],
        "OJ-C": [[0, 0, 0, 0]],
        "OJ-Cr": [[0, 0, 0, 0]],
        ONEUSD: [[0, 0, 0, 0]],
        ORCL: [[0, 0, 0, 0]],
        PEP: [[0, 0, 0, 0]],
        PFIZER: [[0, 0, 0, 0]],
        PG: [[0, 0, 0, 0]],
        PM: [[0, 0, 0, 0]],
        SA40: [[0, 0, 0, 0]],
        "SA40.r": [[0, 0, 0, 0]],
        "SA40.v": [[0, 0, 0, 0]],
        SANUSD: [[0, 0.33375, -341.5, 0]],
        SBUX: [[0, 0, 0, 0]],
        SE: [[0, 0, 0, 0]],
        SGDJPY: [[0, 0, 0, 0]],
        "SGDJPY+": [[0, 0, 0, 0]],
        "SGDJPY.Inst": [[0, 0, 0, 0]],
        SGP20: [[0, 0, 0, 0]],
        "SGP20.r": [[0, 0, 0, 0]],
        "SGP20.v": [[0, 0, 0, 0]],
        SHBUSD: [[0, 0.17915, -158.5, 0]],
        SHOP: [[0, 0, 0, 0]],
        SNOW: [[0, 0, 0, 0]],
        SOLUSD: [[0, 141.42, 2.5, 20240711011442]],
        SP500: [[0, 0, 0, 0]],
        "SP500.r": [[0, 0, 0, 0]],
        "SP500.v": [[0, 0, 0, 0]],
        SP500ft: [[0, 0, 0, 0]],
        "SP500ft.r": [[0, 0, 0, 0]],
        "SP500ft.v": [[0, 0, 0, 0]],
        SPCE: [[0, 0, 0, 0]],
        SPI200: [[0, 0, 0, 0]],
        "SPI200.r": [[0, 0, 0, 0]],
        "SPI200.v": [[0, 0, 0, 0]],
        SPOT: [[0, 0, 0, 0]],
        SQ: [[0, 0, 0, 0]],
        "Sugar-C": [[0, 0, 0, 0]],
        "Sugar-Cr": [[0, 0, 0, 0]],
        SUSUSD: [[0, 0.8162, -123.2, 0]],
        TCOM: [[0, 0, 0, 0]],
        TOYOTA: [[0, 0, 0, 0]],
        TRMB: [[0, 0, 0, 0]],
        TRXUSD: [[0, 0, 0, 0]],
        TSLA: [[0, 0, 0, 0]],
        TSM: [[0, 0, 0, 0]],
        TWINDEX: [[0, 0, 0, 0]],
        "TWINDEX.r": [[0, 0, 0, 0]],
        "TWINDEX.v": [[0, 0, 0, 0]],
        U: [[0, 0, 0, 0]],
        UK100: [[0, 0, 0, 0]],
        "UK100.r": [[0, 0, 0, 0]],
        "UK100.v": [[0, 0, 0, 0]],
        UK100ft: [[0, 0, 0, 0]],
        "UK100ft.r": [[0, 0, 0, 0]],
        "UK100ft.v": [[0, 0, 0, 0]],
        UKOUSD: [[0, 0, 0, 0]],
        UKOUSDft: [[0, 0, 0, 0]],
        UL: [[0, 0, 0, 0]],
        UNH: [[0, 0, 0, 0]],
        UNIUSD: [[0, 9.867, -1923, 0]],
        US2000: [[0, 0, 0, 0]],
        "US2000.r": [[0, 0, 0, 0]],
        "US2000.v": [[0, 0, 0, 0]],
        USDBRL: [[0, 0, 0, 0]],
        "USDBRL+": [[0, 0, 0, 0]],
        USDCAD: [[0, 0, 0, 0]],
        "USDCAD+": [[0, 0, 0, 0]],
        "USDCAD.Inst": [[0, 0, 0, 0]],
        USDCHF: [[0, 0, 0, 0]],
        "USDCHF+": [[0, 0, 0, 0]],
        "USDCHF.Inst": [[0, 0, 0, 0]],
        USDCLP: [[0, 0, 0, 0]],
        "USDCLP+": [[0, 0, 0, 0]],
        USDCNH: [[0, 0, 0, 0]],
        "USDCNH+": [[0, 0, 0, 0]],
        "USDCNH.Inst": [[0, 0, 0, 0]],
        USDCOP: [[0, 0, 0, 0]],
        "USDCOP+": [[0, 0, 0, 0]],
        USDCZK: [[0, 0, 0, 0]],
        "USDCZK+": [[0, 0, 0, 0]],
        USDDKK: [[0, 0, 0, 0]],
        "USDDKK+": [[0, 0, 0, 0]],
        USDHKD: [[0, 0, 0, 0]],
        "USDHKD+": [[0, 0, 0, 0]],
        USDHUF: [[0, 0, 0, 0]],
        "USDHUF+": [[0, 0, 0, 0]],
        USDIDR: [[0, 0, 0, 0]],
        "USDIDR+": [[0, 0, 0, 0]],
        USDINR: [[0, 0, 0, 0]],
        "USDINR+": [[0, 0, 0, 0]],
        USDJPY: [[0, 0, 0, 0]],
        "USDJPY+": [[0, 0, 0, 0]],
        "USDJPY.Inst": [[0, 0, 0, 0]],
        USDKRW: [[0, 0, 0, 0]],
        "USDKRW+": [[0, 0, 0, 0]],
        USDMXN: [[0, 0, 0, 0]],
        "USDMXN+": [[0, 0, 0, 0]],
        "USDMXN.Inst": [[0, 0, 0, 0]],
        USDNOK: [[0, 0, 0, 0]],
        "USDNOK+": [[0, 0, 0, 0]],
        "USDNOK.Inst": [[0, 0, 0, 0]],
        USDPLN: [[0, 0, 0, 0]],
        "USDPLN+": [[0, 0, 0, 0]],
        "USDPLN.Inst": [[0, 0, 0, 0]],
        USDSEK: [[0, 0, 0, 0]],
        "USDSEK+": [[0, 0, 0, 0]],
        "USDSEK.Inst": [[0, 0, 0, 0]],
        USDSGD: [[0, 0, 0, 0]],
        "USDSGD+": [[0, 0, 0, 0]],
        "USDSGD.Inst": [[0, 0, 0, 0]],
        USDTHB: [[0, 0, 0, 0]],
        "USDTHB+": [[0, 0, 0, 0]],
        USDTJPY: [[0, 0, 0, 0]],
        USDTRY: [[0, 0, 0, 0]],
        "USDTRY+": [[0, 0, 0, 0]],
        USDTWD: [[0, 0, 0, 0]],
        "USDTWD+": [[0, 0, 0, 0]],
        USDUSC: [[0, 0, 0, 0]],
        USDX: [[0, 0, 0, 0]],
        "USDX.r": [[0, 0, 0, 0]],
        "USDX.v": [[0, 0, 0, 0]],
        USDZAR: [[0, 0, 0, 0]],
        "USDZAR+": [[0, 0, 0, 0]],
        "USDZAR.Inst": [[0, 0, 0, 0]],
        USNote10Y: [[0, 0, 0, 0]],
        USOUSD: [[0, 0, 0, 0]],
        VISA: [[0, 0, 0, 0]],
        VIX: [[0, 0, 0, 0]],
        "VIX.r": [[0, 0, 0, 0]],
        "VIX.v": [[0, 0, 0, 0]],
        VZ: [[0, 0, 0, 0]],
        WFC: [[0, 0, 0, 0]],
        WMT: [[0, 0, 0, 0]],
        XAGAUD: [[0, 0, 0, 0]],
        XAGUSD: [[0, 0, 0, 0]],
        XAUAUD: [[0, 0, 0, 0]],
        "XAUAUD+": [[0, 0, 0, 0]],
        "XAUAUD.Inst": [[0, 0, 0, 0]],
        XAUEUR: [[0, 0, 0, 0]],
        "XAUEUR+": [[0, 0, 0, 0]],
        "XAUEUR.Inst": [[0, 0, 0, 0]],
        XAUJPY: [[0, 0, 0, 0]],
        "XAUJPY+": [[0, 0, 0, 0]],
        "XAUJPY.Inst": [[0, 0, 0, 0]],
        XAUUSD: [[0, 0, 0, 0]],
        "XAUUSD+": [[0, 0, 0, 0]],
        "XAUUSD.Inst": [[0, 0, 0, 0]],
        XLMUSD: [[0, 0.092098, -45.48, 0]],
        XPDUSD: [[0, 0, 0, 0]],
        "XPDUSD.r": [[0, 0, 0, 0]],
        XPEV: [[0, 0, 0, 0]],
        XPTUSD: [[0, 0, 0, 0]],
        "XPTUSD.r": [[0, 0, 0, 0]],
        XRPUSD: [[0, 0, 0, 0]],
        XTZUSD: [[0, 0.7795, -335, 0]],
        ZECUSD: [[0, 20.235, 310.5, 0]],
        ZM: [[0, 0, 0, 0]],
    },
};
