import { Modal } from "antd";
import { ExecuteSymbolSwapUpdateModalCallbackKey } from "../../SymbolSwapUpload/components/executeSymbolSwapUpdateModal";
import FlexiDataTable from "../../../../../components/FlexiDataTable";
import { DTColProps } from "../../../../../utils/Common";
import { useMemo } from "react";

export interface NoteEntitiesModalProps {
    isModalVisible: boolean;
    noteEntities: NoteEntitiesProps[];
    callback: (type: number, data: any) => void;
}

export enum NoteEntitiesModalCallbackKey {
    Close = 0,
}

export interface NoteEntitiesProps {
    KeyStr?: string;
    diffId: number;
    insertDate: string;
    note: string;
    pid: number;
    sid: number;
    validator: number;
}

const NoteEntitiesModal = (props: NoteEntitiesModalProps) => {
    const markupData = useMemo(() => props.noteEntities.map(x => ({ ...x, KeyStr: `${x.diffId}-${x.insertDate}` })), [props.noteEntities]);

    const columns = [
        DTColProps.Small({
            title: "Config Diff ID",
            dataIndex: "diffId",
            key: "diffId",
        }),
        {
            title: "Notes",
            dataIndex: "note",
            key: "note",
        },
        DTColProps.Small({
            title: "Validator",
            dataIndex: "validator",
            key: "validator",
        }),
        DTColProps.Small({
            title: "Insert Date",
            dataIndex: "insertDate",
            key: "insertDate",
        }),
    ];

    return (
        <Modal
            style={{ top: 10 }}
            width={"50vw"}
            title="Configuration Difference Notes"
            open={props.isModalVisible}
            okButtonProps={{ style: { display: "none" } }}
            cancelText="Close"
            onCancel={() => props.callback(ExecuteSymbolSwapUpdateModalCallbackKey.Close, null)}
            maskClosable={false}
        >
            <FlexiDataTable
                bordered
                rowKeyProperty="KeyStr"
                title={false}
                loading={false}
                columns={columns}
                options={{
                    enableFilter: false,
                    showHideColumns: false,
                }}
                dataSource={markupData}
                pagination={{
                    hideOnSinglePage: true,
                }}
            />
        </Modal>
    );
};

export default NoteEntitiesModal;
