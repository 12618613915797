import { Modal, Form } from "antd";
import { FormComponent } from "../../../../../components/FormComponent";
import { ComponentType } from "../../../../../constants";
import { GroupTransferToolUserInfoProps, KeyValuePair } from "../../../../../constants/type";
import { useEffect } from "react";

export interface HistoricalGroupTransferRecordModalProps {
    isModalVisible: boolean;
    modalTitle: string;
    callback: (type: number, data: any) => void;
    reasonOptions: KeyValuePair[];
    data: GroupTransferToolUserInfoProps | undefined;
}

export enum HistoricalGroupTransferRecordModalCallbackKey {
    Close = 0,
    FormSubmit = 1,
}

const HistoricalGroupTransferRecordModal = (props: HistoricalGroupTransferRecordModalProps) => {
    const [executeForm] = Form.useForm();

    useEffect(() => {
        if (props.data) {
            executeForm.setFieldsValue(props.data);
        }
    }, [props.data]);

    return (
        <Modal
            style={{ top: 10 }}
            width={"40vw"}
            title={props.modalTitle}
            open={props.isModalVisible}
            okText="Execute"
            onOk={() => {
                executeForm
                    .validateFields()
                    .then((values: any) => {
                        props.callback(HistoricalGroupTransferRecordModalCallbackKey.FormSubmit, values);
                    })
                    .catch((err: any) => console.log(err));
            }}
            onCancel={() => props.callback(HistoricalGroupTransferRecordModalCallbackKey.Close, null)}
            maskClosable={false}
        >
            <Form form={executeForm} layout="horizontal" labelCol={{ span: 7 }} wrapperCol={{ span: 14 }}>
                <FormComponent
                    label="Server"
                    name="server"
                    extra={{
                        type: ComponentType.text,
                        value: "",
                        disabled: true,
                    }}
                />
                <FormComponent
                    label="Login"
                    name="login"
                    extra={{
                        type: ComponentType.text,
                        value: "",
                        disabled: true,
                    }}
                />
                <FormComponent
                    label="Operation Time"
                    name="operationTime"
                    extra={{
                        type: ComponentType.text,
                        value: "",
                        disabled: true,
                    }}
                />
                <FormComponent
                    label="Change Reason"
                    name="changeReason"
                    extra={{
                        type: ComponentType.text,
                        value: "",
                    }}
                />
                <FormComponent
                    label="Front Desk"
                    name="changeReasonFrontDesk"
                    extra={{
                        type: ComponentType.dropdown,
                        value: props.reasonOptions,
                    }}
                />
            </Form>
        </Modal>
    );
};

export default HistoricalGroupTransferRecordModal;
