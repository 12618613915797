import { useRCIssuesLogMainServers, useRCIssuesLogMT5Monitor } from "@/hooks/useRCIssuesLog";
import { Input, Space, Table } from "antd";
import moment from "moment";
import { matchSorter } from "match-sorter";
import React, { useState } from "react";
import MessageCard from "../../components/MessageCard";
const IssueMT5OrderTypeMonitorTable = () => {
    const { rcIssuesLogMainServers, isLoading: isLoadingMainServers } = useRCIssuesLogMainServers();
    const { rcIssuesLogMT5Monitor, isLoading } = useRCIssuesLogMT5Monitor();

    const columns = [
        {
            title: "Server",
            dataIndex: "serverUno",
            sorter: (a: any, b: any) => a.serverUno - b.serverUno,
            render: (serverUno: any) => {
                const server = rcIssuesLogMainServers?.find(x => String(x.oldServerId) === String(serverUno));
                return server ? server.serverName : serverUno;
            },
        },
        {
            title: "Order ID",
            dataIndex: "orderId",
            sorter: (a: any, b: any) => a.orderId - b.orderId,
        },
        {
            title: "Symbol",
            dataIndex: "symbol",
            sorter: (a: any, b: any) => a.symbol.localeCompare(b.symbol),
        },
        {
            title: "Type",
            dataIndex: "type",
            sorter: (a: any, b: any) => a.type - b.type,
            render: (text: any, checkState: any) => {
                const { type, state } = checkState;
                if (state === "1") {
                    if (type === "0") {
                        return "Buy";
                    } else if (type === "1") {
                        return "Sell";
                    }
                }
                return "Any";
            },
        },
        {
            title: "Open Time",
            dataIndex: "openTime",
            sorter: (a: any, b: any) => a.openTime - b.openTime,
            render: (dateTime: any) => (dateTime ? moment(dateTime).format("YYYY-MM-DD HH:mm:ss") : ""),
        },
        {
            title: "Volume",
            dataIndex: "volume",
            sorter: (a: any, b: any) => a.volume - b.volume,
        },
        {
            title: "State",
            dataIndex: "state",
            sorter: (a: any, b: any) => a.state - b.state,
            render: (state: any) => {
                switch (state) {
                    case "0":
                        return "ORDER_STATE_STARTED";
                    case "1":
                        return "ORDER_STATE_PLACED";
                    case "7":
                        return "ORDER_STATE_REQUEST_ADD";
                    case "8":
                        return "ORDER_STATE_REQUEST_MODIFY";
                    case "9":
                        return "ORDER_STATE_REQUEST_CANCEL";
                    default:
                        return "UNKNOWN_STATE";
                }
            },
        },
    ];

    const [searchText, setSearchText] = useState("");

    const result = matchSorter(rcIssuesLogMT5Monitor, searchText, {
        keys: ["serverUno", "orderId", "symbol", "type", "openTime", "volume", "state"],
    });

    return (
        <div>
            <Space direction="vertical" style={{ width: "100%", padding: "1rem" }}>
                {isLoading || isLoadingMainServers ? (
                    <MessageCard type="info">Loading...</MessageCard>
                ) : rcIssuesLogMT5Monitor?.length > 0 ? (
                    <MessageCard type="warning">{`Total ${rcIssuesLogMT5Monitor?.length || 0} records`}</MessageCard>
                ) : (
                    <MessageCard type="success">All Good</MessageCard>
                )}
                <Input placeholder="Search" onChange={e => setSearchText(e.target.value)} allowClear />
                <Table
                    columns={columns}
                    loading={isLoading || isLoadingMainServers}
                    dataSource={result || []}
                    rowKey="orderId"
                    pagination={{
                        defaultPageSize: 20,
                        // page size changer
                        // showSizeChanger: true,
                        pageSizeOptions: ["10", "20", "50", "100", "500", "1000"],
                    }}
                    // scroll={{ x: 1000 }}
                    size="small"
                />
            </Space>
        </div>
    );
};

export default IssueMT5OrderTypeMonitorTable;
