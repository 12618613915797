import { Form } from "antd";
import FilterOptionsComponent from "./filterOptionsContainer";
import { FormInstance } from "antd/es/form";
import { useState } from "react";
import FilterTextFieldComponent from "./filterTextFieldContainer";
import { DIMENSION_LEVEL_LABEL } from "../../../../../constants";

export interface FilterOuterComponentProps {
    form: FormInstance;
    filterOptions: any;
    getAccountList: any;
}

export const FilterOuterComponent = (props: FilterOuterComponentProps) => {
    const [currentShowFilter, setCurrentShowFilter] = useState<string>("");

    return (
        <Form form={props.form} layout="vertical">
            <div className="outer-main-container">
                {Object.keys(props.filterOptions).map((x: string) =>
                    x === "IB" || x === "Account" ? (
                        <FilterTextFieldComponent
                            key={`ftfc-${x}`}
                            isOpen={currentShowFilter === x}
                            filterType={x}
                            label={DIMENSION_LEVEL_LABEL[x.toUpperCase()]}
                            showMe={(key: string) => {
                                setCurrentShowFilter(key);
                            }}
                            getAccountList={props.getAccountList}
                            form={props.form}
                        />
                    ) : (
                        <FilterOptionsComponent
                            key={`foc-${x}`}
                            isOpen={currentShowFilter === x}
                            filterType={x}
                            label={DIMENSION_LEVEL_LABEL[x.toUpperCase()]}
                            options={props.filterOptions[x]}
                            showMe={(key: string) => {
                                setCurrentShowFilter(key);
                            }}
                            form={props.form}
                        />
                    )
                )}
            </div>
        </Form>
    );
};

export default FilterOuterComponent;
