import React, { useMemo } from "react";
import WorldMapData from "../../assets/data/worldmap.json";
import { currencyRender, roundUpValue } from "../../utils/Common";
import { ToObjectWithKey } from "../../utils/array";
import { isEmptyOrNull } from "../../utils/string";
import { CustomControl, LarkMap, LayerPopup, LegendCategories, LineLayer, PolygonLayer, ZoomControl } from "@antv/larkmap";

export interface WorldMapProps {
    apiReturnData: any[];
    legendConfig?: string;
}

export interface WorldMapRanges {
    start: number | string;
    end: number | string;
    color: string;
}

interface WorldMapLegendConfig {
    level: number;
    hexcode: string;
    range: number[];
}

const WorldMap = (props: WorldMapProps) => {
    const dataMarkup = (data: any) => {
        let valueKey = Object.keys(data[0]).filter((x: string) => x !== "country")[0],
            keyObj = ToObjectWithKey(data, "country", valueKey);
        return WorldMapData.features.map((currCountry: any) => {
            return {
                ...currCountry,
                properties: {
                    ...currCountry.properties,
                    value: keyObj?.hasOwnProperty(currCountry.properties.iso_alpha_2_code) ? keyObj[currCountry.properties.iso_alpha_2_code] : 0,
                },
            };
        });
    };

    const sourceProps = useMemo(() => {
        if (props.apiReturnData.length > 0) {
            return {
                data: { type: "FeatureCollection", features: [...dataMarkup(props.apiReturnData)] },
                parser: {
                    type: "geojson",
                },
            };
        }
        return {
            data: WorldMapData,
            parser: {
                type: "geojson",
            },
        };
    }, [props.apiReturnData]);

    const customRanges = useMemo(() => {
        if (props.legendConfig) {
            try {
                const legendConfigArr: WorldMapLegendConfig[] = JSON.parse(props.legendConfig);
                return legendConfigArr.map(
                    (x: WorldMapLegendConfig) =>
                        ({
                            start: x.range[0],
                            end: x.range[1],
                            color: x.hexcode,
                        } as WorldMapRanges)
                );
            } catch (error) {
                console.log("JSON parse failed");
            }
        }
        return [];
    }, [props.legendConfig]);

    const legendElement: React.ReactNode = useMemo(() => {
        let legendItems = customRanges.map((currRange: WorldMapRanges) => {
            let isStartEmpty = isEmptyOrNull(currRange.start),
                isEndEmpty = isEmptyOrNull(currRange.end),
                eitherUndefined = isStartEmpty || isEndEmpty;
            return {
                value: eitherUndefined
                    ? `${isStartEmpty ? ` >= ${roundUpValue(currRange.end as number, 0)}` : ` < ${roundUpValue(currRange.start as number, 0)} `}`
                    : `${roundUpValue(currRange.start as number, 0)} ~ ${roundUpValue(currRange.end as number, 0)}`,
                color: currRange.color,
            };
        });
        return (
            <LegendCategories
                style={{ background: "#ffffff", padding: 8 }}
                colors={legendItems.map((x: any) => x.color)}
                labels={legendItems.map((x: any) => x.value)}
            />
        );
    }, [customRanges]);

    const polygonLayerProps: any = useMemo(() => {
        return {
            color: {
                field: "value",
                value: ({ value }: any) => {
                    if (customRanges.length > 0) {
                        let thisRange = customRanges.find((x: any) => {
                            if (isEmptyOrNull(x.start) || isEmptyOrNull(x.end)) {
                                if (isEmptyOrNull(x.start)) {
                                    return value >= x.end;
                                }
                                return value < x.start;
                            }
                            return value >= x.start && value < x.end;
                        });

                        if (thisRange) return thisRange.color;
                    }

                    return "white";
                },
                scale: {
                    type: "quantile",
                },
            },
            state: {
                active: {
                    color: "#ffffff33",
                },
                select: false,
            },
            // tooltip: {
            //     items: [
            //         {
            //             field: "value",
            //             customValue: (value: any) => (value === 0 ? 0 : currencyRender(value, 2)),
            //         },
            //     ],
            // },
        };
    }, [customRanges]);

    return (
        <LarkMap
            logoVisible={false}
            mapType="Map"
            mapOptions={{
                style: "normal",
                center: [20.19382669582967, 30.258134],
                zoom: 0.2,
            }}
            style={{ minHeight: "55vh", background: "#fafafa" }}
        >
            <PolygonLayer source={sourceProps} {...polygonLayerProps} id="wmplayer2024" />
            <LineLayer source={sourceProps} shape="line" color="#878787" size={0.5} />
            <LayerPopup
                closeButton={false}
                closeOnClick={false}
                anchor="bottom-left"
                trigger="hover"
                items={[
                    {
                        layer: "wmplayer2024",
                        customContent: (feature: any) => {
                            return (
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <span style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>{`${feature["name"]} : `}</span>
                                    <span style={{ whiteSpace: "nowrap", marginLeft: "0.1302vw" }}>
                                        {feature["value"] === 0 ? 0 : currencyRender(feature["value"], 2)}
                                    </span>
                                </div>
                            );
                        },
                    },
                ]}
            />
            <ZoomControl position="bottomright" />
            <CustomControl position="bottomleft">{legendElement}</CustomControl>
        </LarkMap>
    );
};

export default WorldMap;
