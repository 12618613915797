import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { APIs } from "../services/apis";
import { plainAxiosInstance } from "../services/axiosSetup";

/*
sample response from the API
{
  "serverTime": "2024-06-27 03:56:18",
  "data": [
    {
      "batchId": 29157,
      "login": 1215,
      "userLogin": 742012,
      "userLoginTestGroup": 0,
      "amount": 5600,
      "currency": "USD",
      "dateTime": "2024-06-27T03:50:40",
      "dateTimeStr": "2024/06/27 03:50:40",
      "serverId": "Moneta1",
      "serverUno": 88,
      "msg": "'1215': changed balance #2615484 - 5600.00 for '742012' - 'Deposit'",
      "ip": "13.214.13.190",
      "solved": 0,
      "solvedTime": "1969-12-31T21:00:00.000+00:00",
      "solvedTimeStr": "1970/01/01 00:00:00",
      "solvedUser": "",
      "comment": "",
      "commentTime": "1969-12-31T21:00:00.000+00:00",
      "commentTimeStr": "1970/01/01 00:00:00",
      "commentUser": "",
      "smsCheck": 1,
      "smsLevel": 2,
      "smsTime": "2024-06-27T00:56:01.000+00:00",
      "adjustType": "balance",
      "errorCount": 0,
      "orderLock": "Fail"
    }
  ]
}
*/

interface IRCBalanceAdjustmentResponse {
    serverTime: string;
    data: IRCBalanceAdjustment[];
}

interface IRCBalanceAdjustment {
    serverId: string;
    serverName: string;
    serverType: number;
    comment: string | null;
    serverAttributes: number;
    oldServerId: string | null;
    login: string | null;
    password: string | null;
}

type Ttype = "unsolved" | "solved" | "pendingVerify";

const useRCBalanceAdjustment = ({ type, duration = 1440 }: { type: Ttype; duration?: number }) => {
    const convertTypeToNumber = (type: Ttype) => {
        switch (type) {
            case "unsolved":
                return 0;
            case "solved":
                return 1;
            case "pendingVerify":
                return 2;
            default:
                return 0;
        }
    };

    const finalType = convertTypeToNumber(type);

    const { data, refetch, isLoading, error, isFetching } = useQuery({
        queryKey: ["rcBalanceAdjustment", type, duration],
        queryFn: () =>
            plainAxiosInstance.get(`${APIs.RC_ISSUES_LOG.GET_BALANCE_ADJUSTMENT_MONITOR}/${duration}/${finalType}`, {
                headers: {
                    Accept: "application/json",
                },
            }),
        staleTime: 60 * 1000, //(10 x 1000ms = 10 seconds)
        refetchInterval: 60 * 1000, //(10 x 1000ms = 10 seconds)
    });

    const rcBalanceAdjustment: IRCBalanceAdjustmentResponse | null = data?.data?.status ? null : data?.data || null;
    // @ts-ignore
    const rcBalanceAdjustmentError: string | null = data?.data?.message || error?.message || error || null;

    return {
        rcBalanceAdjustment,
        rcBalanceAdjustmentError,
        refetchRcBalanceAdjustment: refetch,
        isFetching,
        isLoading,
    };
};

/* sample api resp for get balance adjustment settings 
[{
    "serverUno": 2,
    "serverName": "AU1",
    "login": 297,
    "name": "RC-CA Dividend",
    "ip": "44.209.80.238"
}]
*/

interface IRCBalanceAdjustmentSettings {
    serverUno: number;
    serverName: string;
    login: number;
    name: string;
    ip: string;
}

export const useRCBalanceAdjustmentSettings = () => {
    const { data, refetch, isLoading, error, isFetching } = useQuery({
        queryKey: ["rcBalanceAdjustmentSettings"],
        queryFn: () =>
            plainAxiosInstance.get(APIs.RC_ISSUES_LOG.GET_BALANCE_ADJUSTMENT_SETTINGS, {
                headers: {
                    Accept: "application/json",
                },
            }),
        staleTime: 60 * 1000, //(10 x 1000ms = 10 seconds)
        refetchInterval: 60 * 1000, //(10 x 1000ms = 10 seconds)
    });

    const rcBalanceAdjustmentSettings: IRCBalanceAdjustmentSettings[] = data?.data || [];
    // @ts-ignore
    const rcBalanceAdjustmentSettingsError: string | null = data?.data?.message || error?.message || error || null;

    return {
        rcBalanceAdjustmentSettings,
        rcBalanceAdjustmentSettingsError,
        refetchRcBalanceAdjustmentSettings: refetch,
        isLoading,
    };
};

/* sample api resp for get balance adjustment sms settings
[{
  "level": 1,
  "threshold": 2000,
  "mobile": "+886978705668"
}]
*/

interface IRCBalanceAdjustmentSMSSettings {
    level: number;
    threshold: number;
    mobile: string;
}

export const useRCBalanceAdjustmentSMSSettings = () => {
    const { data, refetch, isLoading, error, isFetching } = useQuery({
        queryKey: ["rcBalanceAdjustmentSMSSettings"],
        queryFn: () =>
            plainAxiosInstance.get(APIs.RC_ISSUES_LOG.GET_BALANCE_ADJUSTMENT_SMS_SETTINGS, {
                headers: {
                    Accept: "application/json",
                },
            }),
        staleTime: 60 * 1000, //(10 x 1000ms = 10 seconds)
        refetchInterval: 60 * 1000, //(10 x 1000ms = 10 seconds)
    });

    const rcBalanceAdjustmentSMSSettings: IRCBalanceAdjustmentSMSSettings[] = data?.data || [];
    // @ts-ignore
    const rcBalanceAdjustmentSMSSettingsError: string | null = data?.data?.message || error?.message || error || null;

    return {
        rcBalanceAdjustmentSMSSettings,
        rcBalanceAdjustmentSMSSettingsError,
        refetchRcBalanceAdjustmentSMSSettings: refetch,
        isLoading,
    };
};

export const useRCBalanceAdjustmentMutate = () => {
    const queryClient = useQueryClient();

    const {
        mutateAsync: mutateAddComment,
        isLoading: isLoadingAddComment,
        error: addCommentError,
    } = useMutation(
        async ({ comment, id }: { comment: string; id: number[] }): Promise<any> => {
            const requestStr = id.join(",") + ";" + comment;
            const formData = new FormData();
            formData.append("keyStr", requestStr);
            return plainAxiosInstance.post(APIs.RC_ISSUES_LOG.POST_BALANCE_ADJUSTMENT_COMMENT, formData, {
                headers: {
                    Accept: "application/json",
                },
            });
        },
        {
            onSuccess: resp => {
                return resp;
            },
            onError: error => {
                console.error(`Error on updating Comments:`, error);
                return error;
            },
        }
    );

    const {
        mutateAsync: mutateSolveIssue,
        isLoading: isLoadingSolveIssue,
        error: solveIssueError,
    } = useMutation(
        async ({ comment = " ", id }: { comment?: string; id: number[] }) => {
            const requestStr = id.join(",") + ";" + comment;
            const formData = new FormData();
            formData.append("keyStr", requestStr);
            return plainAxiosInstance.post(APIs.RC_ISSUES_LOG.POST_BALANCE_ADJUSTMENT_SOLVED, formData, {
                headers: {
                    Accept: "application/json",
                },
            });
        },
        {
            onSuccess: resp => {
                return resp;
            },
            onError: error => {
                console.error(`Error on updating Comments:`, error);
                return error;
            },
        }
    );

    /* sample post for updateSmsSetting
    {
  "level": 3,
  "threshold": 10000,
  "mobile": ""
}
    */
    const {
        mutateAsync: mutateUpdateSMSSetting,
        isLoading: isLoadingUpdateSMSSetting,
        error: updateSMSSettingError,
    } = useMutation(
        async ({ level, threshold, mobile }: { level: number; threshold: number; mobile: string }) => {
            return plainAxiosInstance.post(
                APIs.RC_ISSUES_LOG.POST_BALANCE_ADJUSTMENT_SMS_SETTINGS,
                {
                    level,
                    mobile,
                    threshold,
                },
                {
                    headers: {
                        Accept: "application/json",
                    },
                }
            );
        },
        {
            onSuccess: resp => {
                queryClient.invalidateQueries(["rcBalanceAdjustmentSMSSettings"]);
                return resp;
            },
            onError: error => {
                console.error(`Error on updating SMS Settings:`, error);
                queryClient.invalidateQueries(["rcBalanceAdjustmentSMSSettings"]);
                return error;
            },
        }
    );

    // upload balance adjustment settings, using form data
    const {
        mutateAsync: mutateUploadBalanceAdjustmentSettings,
        isLoading: isLoadingUploadBalanceAdjustmentSettings,
        error: uploadBalanceAdjustmentSettingsError,
    } = useMutation(
        async (formData: FormData) => {
            return plainAxiosInstance.post(APIs.RC_ISSUES_LOG.POST_BALANCE_ADJUSTMENT_SETTINGS_UPLOAD, formData, {
                headers: {
                    Accept: "application/json",
                },
            });
        },
        {
            onSuccess: resp => {
                queryClient.invalidateQueries(["rcBalanceAdjustmentSettings"]);
                return resp;
            },
            onError: error => {
                queryClient.invalidateQueries(["rcBalanceAdjustmentSettings"]);
                console.error(`Error on updating Balance Adjustment Settings:`, error);
                return error;
            },
        }
    );

    return {
        mutateAddComment,
        isLoadingAddComment,
        addCommentError,

        mutateSolveIssue,
        isLoadingSolveIssue,
        solveIssueError,

        mutateUpdateSMSSetting,
        isLoadingUpdateSMSSetting,
        updateSMSSettingError,

        mutateUploadBalanceAdjustmentSettings,
        isLoadingUploadBalanceAdjustmentSettings,
        uploadBalanceAdjustmentSettingsError,
    };
};

export default useRCBalanceAdjustment;
