import { Breadcrumb, Form } from "antd";
import { FormComponent } from "../../components/FormComponent";
import { ComponentType } from "../../constants";
import { useEffect, useState } from "react";
import { HomeOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { APIs, apiRequest } from "../../services/apiConfig";
import LP360SymbolSummary from "./components/LP360/LP360SymbolSummary";
import LP360TrendIntervalData from "./components/LP360/LP360TrendIntervalData";

type LPAccountIdsProps = {
    lp: string;
    accountId: string;
    marketGroupId: number;
    marketGroup: string;
};

const LP360Profile = () => {
    let navigate = useNavigate();
    let { id } = useParams();
    const LPAccIdInfo = id === undefined ? undefined : JSON.parse(atob(id));

    const [lp360Form] = Form.useForm();
    const [MRLPAccount, setMRLPAccount] = useState<LPAccountIdsProps[]>([]);
    const [selectedLP, setSelectedLP] = useState<string>("");

    const getConfigList = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["marketrisklpaccount"] }).then((res: any) => {
            setMRLPAccount(
                res.mrLPAccount.map((v: LPAccountIdsProps) => ({
                    text: `${v.lp}-${v.accountId} ${v.marketGroup ? `(${v.marketGroup})` : ""}`,
                    value: `${v.lp}-${v.accountId}-${v.marketGroupId}`,
                }))
            );

            if (LPAccIdInfo) {
                let foundIdx: number = res.mrLPAccount.findIndex((x: LPAccountIdsProps) => x.lp === LPAccIdInfo.lp && x.accountId === LPAccIdInfo.accountId && x.marketGroupId === LPAccIdInfo.marketGroupId);
                if (foundIdx > -1) {
                    const lpValue = `${LPAccIdInfo.lp}-${LPAccIdInfo.accountId}-${LPAccIdInfo.marketGroupId}`;
                    lp360Form.setFieldsValue({
                        selectedLP: { text: lpValue, value: lpValue },
                    });
                    setSelectedLP(lpValue);
                }
            }
        });
    };

    useEffect(() => {
        getConfigList();
    }, []);

    return (
        <div className="lp-monitor-360-container">
            {LPAccIdInfo === undefined ? (
                <div className="main-title">Liquidity Provider 360</div>
            ) : (
                <div className="breadcrumb-panel">
                    <Breadcrumb>
                        <Breadcrumb.Item href="#" onClick={() => navigate("/lpmonitor/summary")}>
                            <HomeOutlined />
                            <span>Liquidity Provider Monitor - Summary</span>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>Liquidity Provider 360</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            )}
            <div className="content-panel">
                <div className="left-panel">
                    <Form form={lp360Form} layout="horizontal">
                        <div className="top-panel">
                            <FormComponent
                                label={"Selected LP"}
                                name={"selectedLP"}
                                extra={{
                                    type: ComponentType.dropdown,
                                    value: MRLPAccount,
                                    itemProps: { labelAlign: "left" },
                                    inputProps: {
                                        onChange: (value: any) => setSelectedLP(value),
                                        allowClear: false,
                                    },
                                }}
                            />
                        </div>
                    </Form>
                    <LP360TrendIntervalData SelectedLP={selectedLP} />
                </div>
                <div className="right-panel">
                    <LP360SymbolSummary SelectedLP={selectedLP} />
                </div>
            </div>
        </div>
    );
};

export default LP360Profile;
