import { Col, Form, Row } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormComponent } from "../../../../components/FormComponent";
import LoadingComponent from "../../../../components/Loading";
import { ComponentType, InnerPageActionMode, SUCCESS_FAILED } from "../../../../constants";
import { REQUIRED_FIELD } from "../../../../constants/errorMessage";
import { apiRequest, APIs } from "../../../../services/apiConfig";
import { ErrorCatchValidator, ErrorMessageHandler } from "../../../../utils/Common";
import { ARRAY_ACTION_TYPE, GetConstraintKeyList } from "../../../../utils/array";
import { KeyValuePair } from "../../../../constants/type";
import SitePageHeaderInline, { SitePageHeaderInlineActionType } from "../../../../components/PageHeader/inlineIndex";

export interface ServerGroupCreateEditPageProps {
    mode: InnerPageActionMode;
    groupId?: number;
    callback: (action: InnerPageActionMode, value?: any) => void;
    resetState: number;
}

interface ServerGroupProps {
    id?: number;
    groupType: number;
    groupName: string;
    sourceGroups: ServerProps[];
    isDeleted?: boolean;
}

interface ServerProps {
    id?: number;
    sourceId: number;
    isDeleted?: boolean;
}

const defaultValue = {
    id: "",
    groupName: "",
    sourceGroups: [],
};

const ServerGroupCreateEditPage = (props: ServerGroupCreateEditPageProps) => {
    const isAddAction = useMemo(() => props.mode === InnerPageActionMode.CREATE_NEW, [props.mode]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [servers, setServers] = useState<KeyValuePair[]>([]);
    const [oldValue, setOldValue] = useState<ServerGroupProps | undefined>(undefined);
    const [serverForm] = Form.useForm();

    const onSubmit = useCallback(
        (values: any) => {
            if (isAddAction) {
                apiRequest(APIs.CREATE_HUB_FAILOVER_SERVER_SECURITY_GROUP_CONFIG, {
                    groupType: 0,
                    groupName: values.groupName,
                    sourceGroups: values.sourceGroups.map((x: number) => ({ sourceId: x })),
                })
                    .then(res => {
                        ErrorMessageHandler("server group", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                        setTimeout(() => props.callback && props.callback(InnerPageActionMode.BACK, { refreshMainList: true }), 800);
                    })
                    .catch(error =>
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("server group", SUCCESS_FAILED.FAILED_UPDATE_DATA, err))
                    );
            } else {
                let submitParams = { ...oldValue, groupName: values.groupName, isDeleted: false } as ServerGroupProps;

                if (values.sourceGroups.length > 0) {
                    submitParams.sourceGroups = GetConstraintKeyList(
                        values.sourceGroups,
                        submitParams.sourceGroups.map(x => x.sourceId),
                        ARRAY_ACTION_TYPE.LEFT_OUTER
                    ).reduce(
                        (finalArr: ServerProps[], x: number) => {
                            finalArr.push({ sourceId: x });
                            return finalArr;
                        },
                        submitParams.sourceGroups.map((x: ServerProps) => ({
                            ...x,
                            ...(!values.sourceGroups.includes(x.sourceId) && { isDeleted: true }),
                        }))
                    );
                } else if (submitParams.sourceGroups && submitParams.sourceGroups.length > 0) {
                    submitParams.sourceGroups = submitParams.sourceGroups.map((x: ServerProps) => ({ ...x, isDeleted: true }));
                }
                apiRequest(APIs.UPDATE_HUB_FAILOVER_SERVER_SECURITY_GROUP_CONFIG, submitParams)
                    .then(res => {
                        ErrorMessageHandler("server group", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                        setTimeout(() => props.callback && props.callback(InnerPageActionMode.BACK, { refreshMainList: true }), 800);
                    })
                    .catch(error =>
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("server group", SUCCESS_FAILED.FAILED_UPDATE_DATA, err))
                    );
            }
        },
        [oldValue, isAddAction]
    );

    const getServerGroupDetails = (groupId?: number) => {
        if (props.groupId) {
            setIsLoading(true);
            apiRequest(APIs.GET_HUB_FAILOVER_SERVER_SECURITY_GROUP_DETAIL, { groupType: 0, groupId })
                .then(res => {
                    if (res?.hasOwnProperty("groupId")) {
                        serverForm.setFieldsValue({
                            ...defaultValue,
                            id: res.groupId,
                            groupName: res.groupName,
                            sourceGroups: res.sourceGroupDetails.map((x: any) => x.sourceId),
                        });
                        let cObj = {
                            id: res.groupId,
                            groupType: 0,
                            groupName: res.groupName,
                            sourceGroups: res.sourceGroupDetails.map((x: any) => ({ ...x, isDeleted: false })),
                        };
                        setOldValue(cObj);
                    } else {
                        setOldValue(undefined);
                    }
                })
                .catch(error => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("server", SUCCESS_FAILED.FAILED_LOAD_DATA, err)))
                .finally(() => setIsLoading(false));
        } else {
            serverForm.setFieldsValue(defaultValue);
            setIsLoading(false);
        }
    };

    const getConfig = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["hubFailover"] })
            .then(res => {
                if (res.hubFailover.servers && res.hubFailover.servers.length > 0) {
                    setServers(res.hubFailover.servers.map((x: any) => ({ text: x.serverCode, value: x.sourceId })));
                }
            })
            .catch(error => ErrorCatchValidator(error, (err: any) => console.log("Error occured during get servers.")));
    };

    useEffect(() => getServerGroupDetails(props.groupId), [props.resetState]);
    useEffect(() => getConfig(), []);

    return (
        <SitePageHeaderInline
            enableSubmit
            title={isAddAction ? "Create New Server Group" : "Edit Server Group"}
            callback={(action: SitePageHeaderInlineActionType) => {
                switch (action) {
                    case SitePageHeaderInlineActionType.OnBack:
                    case SitePageHeaderInlineActionType.OnCancel:
                        props.callback && props.callback(InnerPageActionMode.BACK);
                        return;
                    case SitePageHeaderInlineActionType.OnSubmit:
                        serverForm
                            .validateFields()
                            .then(res => onSubmit(res))
                            .catch(err => console.log("form-error:", err));
                        return;
                    default:
                        break;
                }
            }}
        >
            {isLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Loading..." />
                </div>
            ) : (
                <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} form={serverForm} layout="horizontal" initialValues={{}} onFinish={onSubmit}>
                    <Row>
                        <Col span={15}>
                            <FormComponent label="" name="id" extra={{ type: ComponentType.hidden, value: "" }} />
                            <FormComponent
                                label="Group Name"
                                name="groupName"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Servers"
                                name="sourceGroups"
                                extra={{
                                    type: ComponentType.dropdown,
                                    value: servers,
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                    inputProps: {
                                        mode: "multiple",
                                    },
                                }}
                            />
                        </Col>
                    </Row>
                </Form>
            )}
        </SitePageHeaderInline>
    );
};

export default ServerGroupCreateEditPage;
