import React, { useState, useEffect, useMemo } from "react";
import { Button, Col, Input, Row, Space, Table, Tree, TreeProps, Typography } from "antd";
import { DownOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { matchSorter } from "match-sorter";
import { IPriceOutage } from "@/hooks/useRCPriceOutage";
import useRCBrands from "@/hooks/useRCBrands";
import useRCServerBrands from "@/hooks/useRCServerBrands";
import useRCBridges from "@/hooks/useRCBridges";
import useRCServerBridges from "@/hooks/useRCServerBridges";
import MessageCard from "@/pages/SystemMonitor/components/MessageCard";
import useRCServers from "@/hooks/useRCServers";

import "./PriceOutageTableStyles.css";

interface IPriceOutageExtended extends IPriceOutage {
    brand: string;
    bridge: string;
}

interface RawData {
    serverId: string;
    symbol: string;
    cleanSymbol: string;
    level: number;
    brand: string;
    bridge: string;
}

interface DataNode {
    title: string;
    key: string;
    children?: DataNode[];
}

interface GroupedData {
    brands: string[];
    cleanSymbol: string;
    level: number;
    count: number;
    duration: number;
    childData: BrandGroupedData[];
}

interface BrandGroupedData {
    brand: string;
    count: number;
    duration: number;
    items: IPriceOutageExtended[];
}

const PriceOutageTable = ({ data = [] }: { data: IPriceOutage[] }) => {
    const { rcServers, isLoading: isLoadingRCServers } = useRCServers();
    const { rcBrands, isLoading: isLoadingRCBrands } = useRCBrands();
    const { rcServerBrands, isLoading: isLoadingRCServerBrands } = useRCServerBrands();
    const { rcBridges, isLoading: isLoadingRCBridges } = useRCBridges();
    const { rcServerBridges, isLoading: isLoadingRCServerBridges } = useRCServerBridges();

    const dateColumns = [
        {
            title: "Brands",
            dataIndex: "brands",
            render: (brands: string[]) => (brands.length > 1 ? "All" : brands[0]),
            sorter: (a: GroupedData, b: GroupedData) => a.brands[0].localeCompare(b.brands[0]),
        },
        {
            title: "Clean Symbol",
            dataIndex: "cleanSymbol",
            sorter: (a: GroupedData, b: GroupedData) => a.cleanSymbol.localeCompare(b.cleanSymbol),
        },
        {
            title: "Level",
            dataIndex: "level",
            width: 60,
            sorter: (a: GroupedData, b: GroupedData) => a.level - b.level,
        },
        {
            title: "Count",
            dataIndex: "count",
            sorter: (a: GroupedData, b: GroupedData) => a.count - b.count,
        },
        {
            title: "Duration",
            dataIndex: "duration",
            render: (text: number) => {
                const convertDuration = (ms: number) => {
                    const seconds = Math.floor(ms / 1000);
                    const minutes = Math.floor(seconds / 60);
                    const hours = Math.floor(minutes / 60);
                    return `${hours % 24}:${String(minutes % 60).padStart(2, "0")}:${String(seconds % 60).padStart(2, "0")}`;
                };
                return convertDuration(text);
            },
            sorter: (a: GroupedData, b: GroupedData) => a.duration - b.duration,
        },
    ];

    const brandColumns = [
        {
            title: "Brand",
            dataIndex: "brand",
            sorter: (a: BrandGroupedData, b: BrandGroupedData) => a.brand.localeCompare(b.brand),
        },
        {
            title: "Count",
            dataIndex: "count",
            sorter: (a: BrandGroupedData, b: BrandGroupedData) => a.count - b.count,
        },
        {
            title: "Duration",
            dataIndex: "duration",
            render: (text: number) => {
                const convertDuration = (ms: number) => {
                    const seconds = Math.floor(ms / 1000);
                    const minutes = Math.floor(seconds / 60);
                    const hours = Math.floor(minutes / 60);
                    return `${hours % 24}:${String(minutes % 60).padStart(2, "0")}:${String(seconds % 60).padStart(2, "0")}`;
                };
                return convertDuration(text);
            },
            sorter: (a: BrandGroupedData, b: BrandGroupedData) => a.duration - b.duration,
        },
    ];

    const dateColumnsChild = [
        {
            title: "Server",
            dataIndex: "serverId",
            sorter: (a: any, b: any) => a.serverId.localeCompare(b.serverId),
            render: (text: string) => {
                const server = rcServerBrands?.find(server => server.serverId === text);
                return <div style={{ minWidth: 100 }}>{server ? server.serverId : text}</div>;
            },
        },
        {
            title: "Symbol",
            dataIndex: "symbol",
            sorter: (a: any, b: any) => a.symbol.localeCompare(b.symbol),
        },
        {
            title: "Level",
            dataIndex: "level",
            sorter: (a: any, b: any) => a.level - b.level,
        },
        {
            title: "Start Time",
            dataIndex: "startDate",
            render: (text: string) => new Date(text).toLocaleString(),
            sorter: (a: any, b: any) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime(),
        },
        {
            title: "Duration",
            dataIndex: "duration",
            render: (text: number) => {
                const date = new Date(text);
                const convertDuration = (ms: number) => {
                    const seconds = Math.floor(ms / 1000);
                    const minutes = Math.floor(seconds / 60);
                    const hours = Math.floor(minutes / 60);
                    return `${hours % 24}:${String(minutes % 60).padStart(2, "0")}:${String(seconds % 60).padStart(2, "0")}`;
                };
                return convertDuration(date.getTime());
            },
            sorter: (a: any, b: any) => a.duration - b.duration,
        },
        {
            title: "Bid",
            dataIndex: "bid",
        },
        {
            title: "Ask",
            dataIndex: "ask",
        },
        {
            title: "Brand",
            dataIndex: "brand",
            sorter: (a: any, b: any) => a.brand.localeCompare(b.brand),
        },
        {
            title: "Bridge",
            dataIndex: "bridge",
            sorter: (a: any, b: any) => a.bridge.localeCompare(b.bridge),
        },
    ];

    const { Title } = Typography;

    const dataExtended: IPriceOutageExtended[] = useMemo(() => {
        return data?.length >= 1
            ? data?.map(item => {
                  const useThisServerID = rcServers?.find(server => server.oldServerId === (item.serverUno || item.serverId))?.serverId;
                  const brandId = rcServerBrands?.find(server => server.serverId === useThisServerID)?.brandId;
                  const brand = rcBrands?.find(brand => brand.brandId === brandId)?.brandName;
                  const bridgeId = rcServerBridges?.find(server => server.serverId === useThisServerID)?.bridgeId;
                  const bridge = rcBridges?.find(bridge => bridge.bridgeId === bridgeId)?.bridgeName;
                  return {
                      ...item,
                      // special rules : added MT5AU to use Vantage brand
                      // if useThisServerID is "MT5AU" or ”MT5-AU" then use brand "Vantage"
                      brand: useThisServerID === "MT5AU" || useThisServerID === "MT5-AU" ? "Vantage" : brand ?? "Not Grouped",
                      bridge: bridge ?? "Not Grouped",
                  };
              })
            : [];
    }, [data, rcServers, rcServerBrands, rcBrands, rcServerBridges, rcBridges]);

    // console.log("dataExtended", dataExtended);

    function convertToTreeData(rawData: RawData[]): DataNode[] {
        const treeData: DataNode[] = [
            {
                title: `By Level (${Array.from(new Set(rawData.map(item => item.level))).length})`,
                key: "level",
                // @ts-ignore
                selectable: false,
                children: [],
            },
            {
                title: `By Symbol (${Array.from(new Set(rawData.map(item => item.cleanSymbol))).length})`,
                key: "symbol",
                // @ts-ignore
                selectable: false,
                children: [],
            },
            {
                title: `By Server (${Array.from(new Set(rawData.map(item => item.serverId))).length})`,
                key: "server",
                // @ts-ignore
                selectable: false,
                children: [],
            },
            {
                title: `By Brand (${Array.from(new Set(rawData.map(item => item.brand))).length})`,
                key: "brand",
                // @ts-ignore
                selectable: false,
                children: [],
            },
            {
                title: `By Bridge (${Array.from(new Set(rawData.map(item => item.bridge))).length})`,
                key: "bridge",
                // @ts-ignore
                selectable: false,
                children: [],
            },
        ];

        const levelMap = new Map<number, Map<string, Set<string>>>();
        const symbolMap = new Map<string, Set<string>>();
        const serverMap = new Map<string, Set<string>>();
        const brandMap = new Map<string, Set<string>>();
        const bridgeMap = new Map<string, Set<string>>();

        rawData.forEach(item => {
            if (!levelMap.has(item.level)) {
                levelMap.set(item.level, new Map());
            }
            if (!levelMap.get(item.level)!.has(item.serverId)) {
                levelMap.get(item.level)!.set(item.serverId, new Set());
            }
            levelMap.get(item.level)!.get(item.serverId)!.add(item.cleanSymbol);

            if (!symbolMap.has(item.cleanSymbol)) {
                symbolMap.set(item.cleanSymbol, new Set());
            }
            symbolMap.get(item.cleanSymbol)!.add(item.serverId);

            if (!serverMap.has(item.serverId)) {
                serverMap.set(item.serverId, new Set());
            }
            serverMap.get(item.serverId)!.add(item.cleanSymbol);

            if (!brandMap.has(item.brand)) {
                brandMap.set(item.brand, new Set());
            }
            brandMap.get(item.brand)!.add(item.serverId);

            if (!bridgeMap.has(item.bridge)) {
                bridgeMap.set(item.bridge, new Set());
            }
            bridgeMap.get(item.bridge)!.add(item.serverId);
        });

        Array.from(levelMap.entries())
            .sort((a, b) => a[0] - b[0])
            .forEach(([level, serverMap]) => {
                const cleanSymbolSet = new Set<string>();
                serverMap.forEach(symbols => {
                    symbols.forEach(cleanSymbol => cleanSymbolSet.add(cleanSymbol));
                });

                const levelNode: DataNode = {
                    title: `Level ${level} (${cleanSymbolSet.size} clean symbol${cleanSymbolSet.size > 1 ? "s" : ""})`,
                    key: `level___${level}`,
                    children: [],
                };

                Array.from(cleanSymbolSet)
                    .sort()
                    .forEach(cleanSymbol => {
                        const serversWithCleanSymbol = Array.from(serverMap.entries())
                            .filter(([_, symbols]) => symbols.has(cleanSymbol))
                            .map(([server, _]) => server);

                        levelNode.children!.push({
                            title: `${cleanSymbol} (${serversWithCleanSymbol.length} server${serversWithCleanSymbol.length > 1 ? "s" : ""})`,
                            key: `level___${level}___${cleanSymbol}`,
                        });
                    });

                treeData[0].children!.push(levelNode);
            });

        Array.from(symbolMap.entries())
            .sort((a, b) => a[0].localeCompare(b[0]))
            .forEach(([symbol, servers]) => {
                treeData[1].children!.push({
                    title: `${symbol} (${servers.size} server${servers.size > 1 ? "s" : ""})`,
                    key: `symbol___${symbol}`,
                    children: Array.from(servers)
                        .sort()
                        .map(server => ({ title: server, key: `symbol___${symbol}___${server}` })),
                });
            });

        Array.from(serverMap.entries())
            .sort((a, b) => a[0].localeCompare(b[0]))
            .forEach(([server, symbols]) => {
                treeData[2].children!.push({
                    title: `${server} (${symbols.size} symbol${symbols.size > 1 ? "s" : ""})`,
                    key: `server___${server}`,
                    children: Array.from(symbols)
                        .sort()
                        .map(symbol => ({ title: symbol, key: `server___${server}___${symbol}` })),
                });
            });

        Array.from(brandMap.entries())
            .sort((a, b) => a[0].localeCompare(b[0]))
            .forEach(([brand, servers]) => {
                treeData[3].children!.push({
                    title: `${brand} (${servers.size} server${servers.size > 1 ? "s" : ""})`,
                    key: `brand___${brand}`,
                    children: Array.from(servers)
                        .sort()
                        .map(server => ({ title: server, key: `brand___${brand}___${server}` })),
                });
            });

        Array.from(bridgeMap.entries())
            .sort((a, b) => a[0].localeCompare(b[0]))
            .forEach(([bridge, servers]) => {
                treeData[4].children!.push({
                    title: `${bridge} (${servers.size} server${servers.size > 1 ? "s" : ""})`,
                    key: `bridge___${bridge}`,
                    children: Array.from(servers)
                        .sort()
                        .map(server => ({ title: server, key: `bridge___${bridge}___${server}` })),
                });
            });

        // console.log(treeData);
        return treeData;
    }

    const treeData = convertToTreeData(dataExtended);

    const [selectedKeys, setSelectedKeys] = React.useState<string[]>([]);
    const onSelect: TreeProps["onSelect"] = (selectedKeys: any, info: any) => {
        setSelectedKeys(selectedKeys);
    };

    const filteredData = useMemo(() => {
        return dataExtended?.filter(item => {
            if (selectedKeys.length === 0) return true;

            const [category, type, leaf] = selectedKeys[0].split("___");

            if (category === "level") {
                if (!type) return true;
                if (!leaf) {
                    return item.level === Number(type);
                }
                return item.level === Number(type) && item.cleanSymbol === leaf;
            } else if (category === "symbol") {
                if (!leaf) {
                    return item.cleanSymbol === type;
                }
                return item.cleanSymbol === type && item.serverId === leaf;
            } else if (category === "server") {
                if (!leaf) {
                    return item.serverId === type;
                }
                return item.serverId === type && item.cleanSymbol === leaf;
            } else if (category === "brand") {
                if (!leaf) {
                    return item.brand === type;
                }
                return item.brand === type && item.serverId === leaf;
            } else if (category === "bridge") {
                if (!leaf) {
                    return item.bridge === type;
                }
                return item.bridge === type && item.serverId === leaf;
            }
            return false;
        });
    }, [dataExtended, selectedKeys]);

    const [searchText, setSearchText] = useState("");

    const result = useMemo(() => {
        return matchSorter(filteredData, searchText, {
            keys: ["serverId", "symbol", "cleanSymbol", "brand"],
        });
    }, [filteredData, searchText]);

    const groupedData: GroupedData[] = useMemo(() => {
        return Object.values(
            result.reduce((acc: { [key: string]: GroupedData }, item: IPriceOutageExtended) => {
                const key = item.cleanSymbol;
                if (!acc[key]) {
                    acc[key] = {
                        brands: [item.brand],
                        cleanSymbol: item.cleanSymbol,
                        level: item.level,
                        count: 1,
                        duration: item.duration,
                        childData: [
                            {
                                brand: item.brand,
                                count: 1,
                                duration: item.duration,
                                items: [item],
                            },
                        ],
                    };
                } else {
                    acc[key].count += 1;
                    acc[key].duration = Math.max(acc[key].duration, item.duration);

                    if (!acc[key].brands.includes(item.brand)) {
                        acc[key].brands.push(item.brand);
                    }

                    const brandIndex = acc[key].childData.findIndex(child => child.brand === item.brand);
                    if (brandIndex === -1) {
                        acc[key].childData.push({
                            brand: item.brand,
                            count: 1,
                            duration: item.duration,
                            items: [item],
                        });
                    } else {
                        acc[key].childData[brandIndex].count += 1;
                        acc[key].childData[brandIndex].duration = Math.max(acc[key].childData[brandIndex].duration, item.duration);
                        acc[key].childData[brandIndex].items.push(item);
                    }
                }
                return acc;
            }, {})
        );
    }, [result]);

    const [showTreemenu, setShowTreemenu] = useState(true);
    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
    const [expandedBrandKeys, setExpandedBrandKeys] = useState<string[]>([]);

    useEffect(() => {
        const newExpandedRowKeys = expandedRowKeys.filter(key => groupedData.some(item => item.cleanSymbol === key));
        if (newExpandedRowKeys.length !== expandedRowKeys.length) {
            setExpandedRowKeys(newExpandedRowKeys);
        }
    }, [groupedData, expandedRowKeys]);

    if (isLoadingRCBrands || isLoadingRCServerBrands || isLoadingRCBridges || isLoadingRCServerBridges || isLoadingRCServers) {
        return <MessageCard type="info">Loading...</MessageCard>;
    }

    return (
        <div key={data.length + data.map(item => item.serverId + item.symbol).join("")}>
            <Space style={{ marginBottom: "1rem" }}>
                <Button
                    size="small"
                    icon={showTreemenu ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
                    onClick={() => setShowTreemenu(prev => !prev)}
                />
                <Title level={5} style={{ margin: 0, padding: 0 }}>
                    Price Outage Table
                </Title>
            </Space>
            <Row gutter={[4, 4]}>
                {showTreemenu && (
                    <Col xs={{ span: 24 }} md={{ span: 8 }}>
                        <div
                            style={{ position: "sticky", top: 0, maxHeight: "100vh", overflowY: "auto", height: "100%", border: "1px solid #f0f0f0" }}
                        >
                            <Space direction="vertical" size={4} style={{ width: "100%" }}>
                                <Button
                                    onClick={() => setSelectedKeys([])}
                                    size="small"
                                    disabled={selectedKeys.length === 0}
                                    type={selectedKeys.length === 0 ? "link" : "default"}
                                    style={{ margin: "2px" }}
                                >
                                    Clear Selection
                                </Button>
                                <Tree showLine switcherIcon={<DownOutlined />} selectedKeys={selectedKeys} onSelect={onSelect} treeData={treeData} />
                            </Space>
                        </div>
                    </Col>
                )}
                <Col xs={{ span: 24 }} md={{ span: showTreemenu ? 16 : 24 }}>
                    <Space direction="vertical" size={4} style={{ width: "100%" }}>
                        <Input
                            placeholder="Search by server, symbol, clean symbol, brand"
                            value={searchText}
                            onChange={e => setSearchText(e.target.value)}
                            allowClear
                        />
                        <Table
                            className="priceOutageTable"
                            dataSource={groupedData}
                            columns={dateColumns}
                            size="small"
                            rowKey={(record: GroupedData) => record.cleanSymbol}
                            pagination={false}
                            expandable={{
                                expandedRowKeys,
                                onExpand: (expanded, record) => {
                                    const key = record.cleanSymbol;
                                    setExpandedRowKeys(prevKeys => (expanded ? [...prevKeys, key] : prevKeys.filter(k => k !== key)));
                                },
                                expandedRowRender: (record: GroupedData) => (
                                    <div style={{ padding: "0.25rem 0" }}>
                                        <Table
                                            dataSource={record.childData}
                                            columns={brandColumns}
                                            size="small"
                                            rowKey={brandRecord => `${record.cleanSymbol}_${brandRecord.brand}`}
                                            pagination={false}
                                            expandable={{
                                                expandedRowKeys: expandedBrandKeys,
                                                onExpand: (expanded, brandRecord) => {
                                                    const key = `${record.cleanSymbol}_${brandRecord.brand}`;
                                                    setExpandedBrandKeys(prevKeys =>
                                                        expanded ? [...prevKeys, key] : prevKeys.filter(k => k !== key)
                                                    );
                                                },
                                                expandedRowRender: (brandRecord: BrandGroupedData) => (
                                                    <div style={{ padding: "0.25rem 0" }}>
                                                        <Table
                                                            dataSource={brandRecord.items}
                                                            columns={dateColumnsChild}
                                                            size="small"
                                                            rowKey={item =>
                                                                `${record.cleanSymbol}_${brandRecord.brand}_${item.serverId}_${item.symbol}`
                                                            }
                                                            pagination={false}
                                                            scroll={{ x: "max-content" }}
                                                        />
                                                    </div>
                                                ),
                                                rowExpandable: (brandRecord: BrandGroupedData) => brandRecord.items.length > 0,
                                            }}
                                            scroll={{ x: "max-content" }}
                                        />
                                    </div>
                                ),
                                rowExpandable: (record: GroupedData) => record.childData.length > 0,
                            }}
                            scroll={{ x: "max-content" }}
                        />
                    </Space>
                </Col>
            </Row>
        </div>
    );
};

export default PriceOutageTable;
