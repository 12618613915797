import { KeyValuePair } from "../../../../../constants/type";

export interface ConfigDiffRecordJSONProps {
    KeyStr: string;
    Columns: string[];
    ExcludeCompare: string[];
}

/// Columns: string => "{ColumnName}||{ColumnCellType}||{textAlign}"
export const ConfigDiffRecordJSON: { [key: string]: { [key: string]: KeyValuePair[] | ConfigDiffRecordJSONProps } } = {
    MT4: {
        SubOptions: [
            { text: "MT4 Group", value: "MT4 Group" },
            { text: "MT4 Group Margin", value: "MT4 Group Margin" },
            { text: "MT4 Group Security", value: "MT4 Group Security" },
            { text: "MT4 Plugin", value: "MT4 Plugin" },
            { text: "MT4 Symbol", value: "MT4 Symbol" },
            { text: "MT4 Symbol Group", value: "MT4 Symbol Group" },
            { text: "MT4 Symbol Sessions", value: "MT4 Symbol Sessions" },
        ],
        "MT4 Group": {
            KeyStr: "MT4_GROUP",
            Columns: [
                "Server",
                "Time",
                "Name",
                "Enable||xsm||center",
                "Timeout||xsm",
                "OtpMode||xsm||center",
                "Company||m",
                "Signature||l",
                "SupportPage||l",
                "SmtpServer||m",
                "SmtpLogin",
                "SmtpPassword",
                "SupportEmail||m",
                "Templates",
                "Copies||xsm||center",
                "Reports||xsm||center",
                "DefaultLeverage||sc",
                "DefaultDeposit||sc",
                "MaxSecurities||sc",
                "SecMarginsTotal||sc",
                "Currency||xsm",
                "Credit||sc",
                "MarginCall||sc",
                "MarginMode||center",
                "MarginStopout||sc",
                "InterestRate||sc",
                "UseSwap",
                "News",
                "Rights",
                "CheckIePrices",
                "MaxPositions||sc",
                "CloseReopen",
                "HedgeProhibited",
                "CloseFifo",
                "HedgeLargeLeg",
                "MarginType||xsm||center",
                "ArchivePeriod||||right",
                "ArchiveMaxBalance||||right",
                "StopOutSkipHedged||||right",
                "ArchivePendingPeriod||||right",
            ],
            ExcludeCompare: ["Server", "Time", "Name"],
        },
        "MT4 Group Margin": {
            KeyStr: "MT4_GROUP_MARGIN",
            Columns: ["Server", "Time", "Group||m", "Id||xsm", "Symbol", "SwapLong||c", "SwapShort||c", "MarginDivider||c"],
            ExcludeCompare: ["Server", "Time", "Name", "Group", "Id"],
        },
        "MT4 Group Security": {
            KeyStr: "MT4_GROUP_SECURITY",
            Columns: [
                "Server",
                "Time",
                "Group||m",
                "Id||xsm",
                "Show||xsm||center",
                "Trade||xsm||center",
                "Execution||xsm||center",
                "CommBase",
                "CommType",
                "CommLots",
                "CommAgent",
                "CommAgentType",
                "SpreadDiff||sc",
                "LotMin||sc",
                "LotMax||sc",
                "LotStep",
                "IeDeviation",
                "Confirmation||||center",
                "TradeRights",
                "IeQuickMode||||center",
                "AutoCloseOutMode||||center",
                "CommTax||sc",
                "CommAgentLots||sc",
                "FreeMarginMode||||center",
            ],
            ExcludeCompare: ["Server", "Time", "Group", "Id"],
        },
        "MT4 Plugin": {
            KeyStr: "MT4_PLUGIN",
            Columns: [
                "Server",
                "Time",
                "Name",
                "Id||xsm",
                "ParameterName",
                "ParameterValue",
                "Copyright",
                "Version||xsm||center",
                "File",
                "Enabled||xsm||center",
                "Configurable||||center",
                "ManagerAccess",
                "OrderId",
            ],
            ExcludeCompare: ["Server", "Time", "Name", "Id", "ParameterName"],
        },
        "MT4 Symbol": {
            KeyStr: "MT4_SYMBOL",
            Columns: [
                "Server",
                "Time",
                "Name",
                "Description",
                "Source",
                "Currency",
                "Type||xsm||center",
                "Digits||xsm||center",
                "Trade||xsm||center",
                "BackgroundColor",
                "Count||xsm||center",
                "CountOriginal||||center",
                "RealTime",
                "Starting",
                "Expiration",
                "ProfitMode||xsm||center",
                "Filter",
                "FilterCounter",
                "FilterLimit",
                "FilterSmoothing",
                "Logging",
                "Spread||sc",
                "SpreadBalance||sc",
                "Exemode",
                "SwapEnable",
                "SwapType",
                "SwapLong||sc",
                "SwapShort||sc",
                "SwapRollover3Days",
                "ContractSize",
                "TickValue||sc",
                "TickSize||sc",
                "StopsLevel",
                "GtcPendings",
                "MarginMode||||center",
                "MarginInitial",
                "MarginMaintenance",
                "MarginHedged",
                "MarginDivider",
                "Point||sc",
                "Multiply",
                "BidTickValue||sc",
                "AskTickValue||sc",
                "LongOnly",
                "InstantMaxVolume",
                "MarginCurrency",
                "FreezeLevel",
                "MarginHedgedStrong",
                "ValueDate",
                "QuotesDelay",
                "SwapOpenPrice||sc",
                "SwapVariationMargin||c",
            ],
            ExcludeCompare: ["Server", "Time", "Name"],
        },
        "MT4 Symbol Group": {
            KeyStr: "MT4_SYMBOL_GROUP",
            Columns: ["Server", "Time", "Name", "index", "Description||m"],
            ExcludeCompare: ["Server", "Time"],
        },
        "MT4 Symbol Sessions": {
            KeyStr: "MT4_SYMBOL_SESSIONS",
            Columns: ["Server", "Time", "Name||m", "Day||xsm||center", "Quote", "Trade"],
            ExcludeCompare: ["Server", "Time", "Name", "Day"],
        },
    },
    MT5: {
        SubOptions: [
            { text: "MT5 Commission", value: "MT5 Commission" },
            { text: "MT5 Gateway", value: "MT5 Gateway" },
            { text: "MT5 Gateway Group", value: "MT5 Gateway Group" },
            { text: "MT5 Gateway Parameter", value: "MT5 Gateway Parameter" },
            { text: "MT5 Gateway Symbol", value: "MT5 Gateway Symbol" },
            { text: "MT5 Group", value: "MT5 Group" },
            { text: "MT5 Group Symbol", value: "MT5 Group Symbol" },
            { text: "MT5 Plugin Params", value: "MT5 Plugin Params" },
            { text: "MT5 Symbol", value: "MT5 Symbol" },
            { text: "MT5 Symbol Session", value: "MT5  Symbol Session" },
        ],
        "MT5 Commission": {
            KeyStr: "MT5_COMMISSION",
            Columns: [
                "Server",
                "Time",
                "GroupName||m",
                "Path||m",
                "RangeMode||m",
                "ChargeMode||m",
                "EntryMode",
                "Mode",
                "TierFrom||sc",
                "TierTo||sc",
                "TierCommission||sc",
                "Minimal||sc",
                "Maximal||sc",
                "TierMode||m",
                "TierCurrency",
                "TierType||m",
            ],
            ExcludeCompare: ["Server", "Time", "GroupName", "Path", "TierFrom", "TierTo"],
        },
        "MT5 Gateway": {
            KeyStr: "MT5_GATEWAY",
            Columns: [
                "Server",
                "Time",
                "Name||m",
                "Mode||xsm||center",
                "Module||m",
                "Id||xsm",
                "TradingServer||l",
                "TradingLogin||m",
                "TradingPassword||m",
            ],
            ExcludeCompare: ["Server", "Time", "Name"],
        },
        "MT5 Gateway Group": {
            KeyStr: "MT5_GATEWAY_GROUP",
            Columns: ["Server", "Time", "Value||m", "Name||m"],
            ExcludeCompare: ["Server", "Time", "Value"],
        },
        "MT5 Gateway Parameter": {
            KeyStr: "MT5_GATEWAY_PARAMETER",
            Columns: ["Server", "Time", "Parameter||m", "Name||m", "Value||xl"],
            ExcludeCompare: ["Server", "Time", "Parameter"],
        },
        "MT5 Gateway Symbol": {
            KeyStr: "MT5_GATEWAY_SYMBOL",
            Columns: ["Server", "Time", "Id||xsm", "Name||m", "Value||l"],
            ExcludeCompare: ["Server", "Time", "Id"],
        },
        "MT5 Group": {
            KeyStr: "MT5_GROUP",
            Columns: [
                "Server",
                "Time",
                "Group||m",
                "ServerId||xsm||center",
                "PermissionsFlags",
                "AuthMode||||center",
                "AuthPasswordMin||||center",
                "AuthOTPMode||||center",
                "Company||m",
                "CompanyPage||m",
                "CompanyEmail||m",
                "CompanySupportPage||l",
                "CompanySupportEmail||m",
                "CompanyCatalog",
                "Currency",
                "CurrencyDigits||||center",
                "ReportsMode||||center",
                "ReportsFlags||||center",
                "NewsMode||||center",
                "NewsCategory",
                "MailMode||||center",
                "TradeFlags",
                "TransferMode||||center",
                "TradeInterestrate||sc",
                "TradeVirtualCredit||sc",
                "MarginMode||||center",
                "MarginSOMode||||center",
                "MarginFreeMode||||center",
                "MarginCall||sc",
                "MarginStopOut||sc",
                "MarginFreeProfitMode||||center",
                "DemoLeverage||sc",
                "DemoDeposit||sc",
                "LimitHistory||sc",
                "LimitOrders||sc",
                "LimitSymbols",
                "LimitPositions||sc",
                "CompanyDepositURL||l",
                "CompanyWithdrawalURL||l",
                "ReportsEmail||l",
                "DemoTradesClean||sc",
            ],
            ExcludeCompare: ["Server", "Time", "Group"],
        },
        "MT5 Group Symbol": {
            KeyStr: "MT5_GROUP_SYMBOL",
            Columns: [
                "Server",
                "Time",
                "Group||m",
                "Path||m",
                "TradeMode||||center",
                "ExecMode||||center",
                "FillFlags",
                "ExpirFlags",
                "OrderFlags",
                "SpreadDiff",
                "SpreadDiffBalance",
                "StopsLevel",
                "FreezeLevel",
                "VolumeMin||sc",
                "VolumeMax||sc",
                "VolumeStep||sc",
                "VolumeLimit||sc",
                "MarginFlags",
                "MarginInitial",
                "MarginInitialBuy||c",
                "MarginInitialSell||c",
                "MarginInitialBuyLimit||c",
                "MarginInitialSellLimit||c",
                "MarginInitialBuyStop||c",
                "MarginInitialSellStop||c",
                "MarginInitialBuyStopLimit||c",
                "MarginInitialSellStopLimit||c",
                "MarginMaintenance",
                "MarginMaintenanceBuy||c",
                "MarginMaintenanceSell||c",
                "MarginMaintenanceBuyLimit||c",
                "MarginMaintenanceSellLimit||c",
                "MarginMaintenanceBuyStop||c",
                "MarginMaintenanceSellStop||c",
                "MarginMaintenanceBuyStopLimit||c",
                "MarginMaintenanceSellStopLimit||c",
                "MarginLiquidity||c",
                "MarginHedged||c",
                "MarginCurrency",
                "SwapMode||||center",
                "SwapLong||c",
                "SwapShort||c",
                "Swap3Day",
                "REFlags",
                "RETimeout",
                "IEFlags",
                "IECheckMode",
                "IETimeout",
                "IESlipProfit||c",
                "IESlipLosing||c",
                "IEVolumeMax||c",
                "PermissionsFlags",
                "VolumeMinExt||c",
                "VolumeMaxExt||c",
                "VolumeStepExt||c",
                "VolumeLimitExt||c",
                "IEVolumeMaxExt||c",
                "PermissionsBookdepth||c",
            ],
            ExcludeCompare: ["Server", "Time", "Group", "Path"],
        },
        "MT5 Plugin Params": {
            KeyStr: "MT5_PLUGIN_PARAMS",
            Columns: ["Server", "Time", "Plugin||m", "ServerId", "Name||m", "Type||xsm", "Value||m"],
            ExcludeCompare: ["Server", "Time", "Plugin", "ServerId", "Name"],
        },
        "MT5 Symbol": {
            KeyStr: "MT5_SYMBOL",
            Columns: [
                "Server",
                "Time",
                "Symbol",
                "Path",
                "ISIN",
                "Description",
                "International",
                "Basis",
                "Source",
                "Page",
                "CurrencyBase",
                "CurrencyProfit",
                "CurrencyMargin",
                "Color",
                "ColorBackground",
                "Digits||xsm||center",
                "Point||sc",
                "Multiply||sc",
                "TickFlags",
                "TickBookDepth",
                "TickChartMode",
                "FilterSoft",
                "FilterSoftTicks",
                "FilterHard",
                "FilterHardTicks",
                "FilterDiscard",
                "FilterSpreadMax",
                "FilterSpreadMin",
                "FilterGap",
                "FilterGapTicks",
                "TradeMode",
                "TradeFlags",
                "CalcMode",
                "ExecMode",
                "GTCMode",
                "FillFlags",
                "ExpirFlags",
                "OrderFlags",
                "Spread",
                "SpreadBalance",
                "SpreadDiff",
                "SpreadDiffBalance",
                "TickValue",
                "TickSize",
                "ContractSize",
                "StopsLevel",
                "FreezeLevel",
                "QuotesTimeout",
                "VolumeMin||c",
                "VolumeMax||c",
                "VolumeStep||sc",
                "VolumeLimit||sc",
                "MarginFlags||||center",
                "MarginInitial||c",
                "MarginInitialBuy||c",
                "MarginInitialSell||c",
                "MarginInitialBuyLimit||c",
                "MarginInitialSellLimit||c",
                "MarginInitialBuyStop||c",
                "MarginInitialSellStop||c",
                "MarginInitialBuyStopLimit||c",
                "MarginInitialSellStopLimit||c",
                "MarginMaintenance||c",
                "MarginMaintenanceBuy||c",
                "MarginMaintenanceSell||c",
                "MarginMaintenanceBuyLimit||c",
                "MarginMaintenanceSellLimit||c",
                "MarginMaintenanceBuyStop||c",
                "MarginMaintenanceSellStop||c",
                "MarginMaintenanceBuyStopLimit||c",
                "MarginMaintenanceSellStopLimit||c",
                "SwapMode||||center",
                "SwapLong||sc",
                "SwapShort||sc",
                "Swap3Day",
                "TimeStart",
                "TimeExpiration",
                "REFlags",
                "RETimeout",
                "IECheckMode||||center",
                "IETimeout",
                "IESlipProfit||sc",
                "IESlipLosing||sc",
                "IEVolumeMax||sc",
                "PriceSettle||sc",
                "PriceLimitMax||sc",
                "PriceLimitMin||sc",
                "OptionMode||||center",
                "FaceValue",
                "AccruedInterest||c",
                "SpliceType",
                "SpliceTimeType",
                "SpliceTimeDays",
                "CurrencyBaseDigits||||center",
                "CurrencyProfitDigits||||center",
                "CurrencyMarginDigits||||center",
                "VolumeMinExt||c",
                "VolumeMaxExt||c",
                "VolumeStepExt||c",
                "VolumeLimitExt||c",
                "MarginRateLiquidity||c",
                "MarginHedged",
                "MarginRateCurrency",
                "IEVolumeMaxExt",
                "PriceStrike",
                "CFI",
                "Category",
                "Exchange",
                "Sector",
                "Industry",
                "Country",
            ],
            ExcludeCompare: ["Server", "Time", "Symbol"],
        },
        "MT5 Symbol Session": {
            KeyStr: "MT5_SYMBOL_SESSION",
            Columns: ["Server", "Time", "Symbol||m", "Day||xsm", "Quote", "Trade"],
            ExcludeCompare: ["Server", "Time", "Symbol", "Day"],
        },
    },
    "One Zero": {
        SubOptions: [
            { text: "Maker Pools", value: "Maker Pools" },
            { text: "Maker Pools IDs", value: "Maker Pools IDs" },
            { text: "Maker Rules", value: "Maker Rules" },
            { text: "Maker Quote Rules", value: "Maker Quote Rules" },
            { text: "Maker Spread Rules", value: "Maker Spread Rules" },
            { text: "Order Routing Mappings", value: "Order Routing Mappings" },
            { text: "Order Routing Profiles", value: "Order Routing Profiles" },
            { text: "Price Channels", value: "Price Channels" },
            { text: "Quote Filtering Settings", value: "Quote Filtering Settings" },
            { text: "Settings Rules", value: "Settings Rules" },
            { text: "Symbol", value: "Symbol" },
            { text: "Taker Stream Routing", value: "Taker Stream Routing" },
            { text: "Volume Band Profiles", value: "Volume Band Profiles" },
            { text: "Volume Band Profiles Layers", value: "Volume Band Profiles Layers" },
        ],
        "Maker Pools": {
            KeyStr: "OZ_MAKERPOOLS",
            Columns: [
                "BridgeId",
                "Time",
                "BridgeName",
                "Id||m",
                "MakerPoolName",
                "Type",
                "AggregationPriority",
                "IncludeIndicativeQuotes||l",
                "LiquidityTypeFilter",
                "AllowTakersToReceiveAxeStreams||l",
                "IncludeAxeStreams",
                "AdditionalHedgeSymbolsEnabled||l",
                "Symbol",
                "SymbolGroupPath",
            ],
            ExcludeCompare: ["BridgeId", "Time", "Id"],
        },
        "Maker Pools IDs": {
            KeyStr: "OZ_MAKERPOOLS_MAKERIDS",
            Columns: ["BridgeId", "Time", "BridgeName", "MakerPoolName", "MakerId", "EntityId||m"],
            ExcludeCompare: ["BridgeId", "Time", "MakerPoolName", "MakerId"],
        },
        "Maker Rules": {
            KeyStr: "OZ_MAKERRULES",
            Columns: ["BridgeId", "Time", "BridgeName", "Id||m", "MakerId", "Symbol", "SymbolGroupPath", "MakerSymbol", "SessionProfileName"],
            ExcludeCompare: ["BridgeId", "Time", "Id"],
        },
        "Maker Quote Rules": {
            KeyStr: "OZ_MAKERQUOTERULES",
            Columns: [
                "BridgeId",
                "Time",
                "BridgeName||m",
                "MakerSymbol",
                "Provider",
                "TradeVolumeStepSize||m",
                "TradeVolumeMinQuantity||m",
                "MakerId",
            ],
            ExcludeCompare: ["BridgeId", "Time", "MakerSymbol", "MakerId"],
        },
        "Maker Spread Rules": {
            KeyStr: "OZ_MAKERSPREADRULES",
            Columns: ["BridgeId", "Time", "BridgeName", "MakerId", "MakerSymbol", "Provider", "BidSpreadPoints||sc", "AskSpreadPoints||sc"],
            ExcludeCompare: ["BridgeId", "Time", "MakerSymbol", "MakerId"],
        },
        "Order Routing Mappings": {
            KeyStr: "OZ_ORDERROUTINGMAPPINGS",
            Columns: [
                "BridgeId",
                "Time",
                "BridgeName",
                "ConfigType",
                "Id||m",
                "SettingsId||m",
                "Name",
                "TakerEntityId||l",
                "TakerId",
                "TakerLogin",
                "TakerGroup",
                "Symbol",
                "SymbolGroupPath",
                "InputOrderType",
                "InputTimeInForce",
                "MTOrderTypeRestriction||m",
                "OrderRoutingProfileName||m",
                "PortfolioName",
            ],
            ExcludeCompare: ["BridgeId", "Time", "SettingsId"],
        },
        "Order Routing Profiles": {
            KeyStr: "OZ_ORDERROUTINGPROFILES",
            Columns: [
                "BridgeId",
                "Time",
                "BridgeName",
                "Id||m",
                "ProfileName",
                "OrderRoutingMode",
                "SynchronousHedgeTradeMakerPool||l",
                "SystematicHedgeGroup||m",
                "MaxOrderQuantity||sc",
                "EnforceLiquidityType",
                "PercentForHedge||sc",
                "MinOrderHedgeQuantity||c",
                "OrderHedgeStepSize",
                "MaxOrderHedgeQuantityDecimalPlaces||l||right",
                "WarehouseLiquidityType||m",
                "WarehouseMinDelayInMilliseconds||l",
                "WarehouseMaxDelayInMilliseconds||l",
                "PriceChannelRoutingForAsyncHedge||l",
            ],
            ExcludeCompare: ["BridgeId", "Time", "Id"],
        },
        "Price Channels": {
            KeyStr: "OZ_PRICECHANNELS",
            Columns: [
                "BridgeId",
                "Time",
                "BridgeName",
                "PriceChannelID",
                "TakerStreamName",
                "PriceChannelCharacteristic||m",
                "SymbolSuffix",
                "InternalTradesOnly",
                "InstitutionalTrade",
            ],
            ExcludeCompare: ["BridgeId", "Time", "PriceChannelID"],
        },
        "Quote Filtering Settings": {
            KeyStr: "OZ_QUOTEFILTERINGSETTINGS",
            Columns: [
                "BridgeId",
                "Time",
                "BridgeName",
                "ConfigType",
                "Id||m",
                "Name",
                "MakerID",
                "Symbol",
                "SymbolGroupPath",
                "FilterName",
                "IsEnabled",
                "FilterStyle",
                "FilterValue||sc",
                "QuoteWindow",
                "MaxAgeOfQuote",
                "DeactivationAlertThreshold||m",
                "m_filterOneSidedQuotes||m",
                "m_filterMaxSpreadPoints||m",
                "m_filterMaxSpreadShouldTestAllLayers||l",
            ],
            ExcludeCompare: ["BridgeId", "Time", "Id", "MakerID", "Symbol"],
        },
        "Settings Rules": {
            KeyStr: "OZ_SETTINGSRULES",
            Columns: [
                "BridgeId",
                "Time",
                "BridgeName",
                "ConfigType",
                "Id||m",
                "Name",
                "SettingsId||m",
                "Symbol",
                "SymbolGroupPath",
                "PriceChannelID",
                "FeedToTakers",
                "BidSpreadPoints||sc",
                "AskSpreadPoints||sc",
                "VolumeAndPriceConsolidationProfile",
                "MinSpreadPoints||sc",
                "VolumeConsolidatedAtPriceProfile",
                "VolumeBandProfile",
                "EnableWarehouseLiquidityDepletion||m",
                "WarehouseLiquidityReplenishmentInterval||m",
                "MarketOrderPriceReporting||m",
                "InstantOrderPriceReporting||m",
                "LimitOrderPriceReporting||m",
                "StopOrderPriceReporting||m",
                "StopLimitOrderPriceReporting||m",
                "PreviouslyQuotedPriceReporting||m",
                "ExchangeOrderPriceReporting||m",
                "AlertThresholdForPercentOfFullRefreshTransactions||l",
                "MakerPool||m",
            ],
            ExcludeCompare: ["BridgeId", "Time", "SettingsId"],
        },
        Symbols: {
            KeyStr: "OZ_SETTINGSRULES",
            Columns: [
                "BridgeId",
                "Time",
                "BridgeName",
                "CoreSymbol",
                "TakerSymbol",
                "InstrumentDefinition",
                "BaseCurrency",
                "Denomination",
                "SymbolGroupPath",
                "Digits||||center",
                "MaxVolumePrecisionDecimalPlaces||l||center",
                "CreditMultiplier||||center",
                "SessionProfileName||m",
                "QuoteBookHedgeVolumeManagement||l",
                "ReferenceCurrency",
            ],
            ExcludeCompare: ["BridgeId", "Time", "CoreSymbol"],
        },
        "Taker stream routing": {
            KeyStr: "OZ_TAKERSTREAMROUTING",
            Columns: ["BridgeId", "Time", "BridgeName", "EntityId||l", "TakerId", "PriceChannelId||m", "ValuationStream"],
            ExcludeCompare: ["BridgeId", "Time", "TakerId", "PriceChannelId"],
        },
        "Volume Band Profiles": {
            KeyStr: "OZ_VOLUMEBANDPROFILES",
            Columns: [
                "BridgeId",
                "Time",
                "BridgeName",
                "Name",
                "ConsolidationType",
                "NotionalTermCCYBand",
                "MakerToSetForResultingQuotes||m",
                "ProviderToSetForResultingQuotes||m",
                "InstantOrderDurationsOnly||m",
                "AllowLimitOrdersForVwapConsolidation||l",
                "AllowFOKOrdersForVwapConsolidation||l",
                "AlwaysRecalculateSuccessiveBands||l",
                "BidSpreadPoints||sc",
                "AskSpreadPoints||sc",
                "LiquidityStreamFilterType||m",
                "Enabled||xsm",
            ],
            ExcludeCompare: ["BridgeId", "Time", "Name"],
        },
        "Volume Band Profiles Layers": {
            KeyStr: "OZ_VOLUMEBANDPROFILES_LAYERS",
            Columns: [
                "BridgeId",
                "Time",
                "BridgeName",
                "Name||m",
                "Layers||xsm||center",
                "BidSpreadPoints||sc",
                "AskSpreadPoints||sc",
                "LiquidityStreamFilterType||m",
                "Volume||sc",
                "AllowPartialVolume||m",
            ],
            ExcludeCompare: ["BridgeId", "Time", "Name", "Layers"],
        },
    },
    PXM: {
        SubOptions: [
            { text: "Connector Account", value: "Connector Account" },
            { text: "Connector Account Symbol", value: "Connector Account Symbol" },
            { text: "Connector Stream", value: "Connector Stream" },
            { text: "Connector Stream Symbol", value: "Connector Stream Symbol" },
            { text: "Pool Symbol", value: "Pool Symbol" },
            { text: "Profile Symbol", value: "Profile Symbol" },
            { text: "Provider Stream Setting", value: "Provider Stream Setting" },
            { text: "Provider Trade Setting", value: "Provider Trade Setting" },
            { text: "Symbol", value: "Symbol" },
        ],
        "Connector Account": {
            KeyStr: "PXM_CONNECTOR_ACCOUNT",
            Columns: [
                "BridgeId",
                "Time",
                "BridgeName",
                "Id||xsm",
                "Connector||m",
                "Name||m",
                "Description||m",
                "Enabled||xsm",
                "Currency||xsm",
                "TradeLimit||sc",
                "TradeSpan||sc",
                "TradeTimeout||sc",
                "Margin||sc",
                "Risk||xsm||center",
            ],
            ExcludeCompare: ["BridgeId", "Time", "Id", "Connector", "Name"],
        },
        "Connector Account Symbol": {
            KeyStr: "PXM_CONNECTOR_ACCOUNT_SYMBOL",
            Columns: [
                "BridgeId",
                "Time",
                "BridgeName",
                "Id||xsm",
                "Connector||m",
                "Account||m",
                "Security",
                "Enabled||xsm||center",
                "Name",
                "LqPool",
                "LqProfile",
                "Layers",
                "ExecMode||xsm||center",
                "MinSize||sc",
                "MaxSize||sc",
                "Step||sc",
                "Fok||sc",
                "TimeToLive||sc",
                "BBook",
                "BLock",
                "BBoost",
                "BDelay",
                "BJitter",
                "AMin||sc",
                "AStep||sc",
                "Improvements||||center",
                "Sessions||l",
            ],
            ExcludeCompare: ["BridgeId", "Time", "Id", "Connector", "Account"],
        },
        "Connector Stream": {
            KeyStr: "PXM_CONNECTOR_STREAM",
            Columns: ["BridgeId", "Time", "BridgeName||m", "Id||xsm", "Connector||m", "Name||m", "Description||m", "Enabled||||center"],
            ExcludeCompare: ["BridgeId", "Time", "Id", "Connector", "Name"],
        },
        "Connector Stream Symbol": {
            KeyStr: "PXM_CONNECTOR_STREAM_SYMBOL",
            Columns: [
                "BridgeId",
                "Time",
                "BridgeName||m",
                "Id||xsm",
                "Connector||m",
                "Stream",
                "Security",
                "Enabled||xsm||center",
                "Name",
                "Instrument",
                "Base",
                "Quote",
                "LqPool",
                "LqProfile||m",
                "Mode||xsm||center",
                "Layers",
                "VolumeDouble||sc",
                "Depth",
                "LqSource",
                "LqLock",
                "LqBoost",
                "Min||sc",
                "Subscribe||||center",
                "Sessions||l",
            ],
            ExcludeCompare: ["BridgeId", "Time", "Id", "Connector", "Stream"],
        },
        "Pool Symbol": {
            KeyStr: "PXM_POOL_SYMBOL",
            Columns: [
                "BridgeId",
                "Time",
                "BridgeName||m",
                "Id||xsm",
                "Pool",
                "Security",
                "Name",
                "Enabled||xsm||center",
                "Providers",
                "Mode||xsm||center",
                "Boost||xsm||center",
                "FilterLimit",
                "FilterFactor",
                "Failover",
                "Timeout",
                "Sessions||l",
            ],
            ExcludeCompare: ["BridgeId", "Time", "Id", "Pool"],
        },
        "Profile Symbol": {
            KeyStr: "PXM_PROFILE_SYMBOL",
            Columns: [
                "BridgeId",
                "Time",
                "BridgeName||m",
                "Id||xsm||center",
                "Profile||m",
                "Security",
                "Name||m",
                "BidMarkup||sc",
                "AskMarkup||sc",
                "MinSpread||sc",
                "MaxSpread||sc",
                "Spread||sc",
                "Layer",
                "Precision||sc",
                "Enabled||xsm||center",
                "Sessions||l",
            ],
            ExcludeCompare: ["BridgeId", "Time", "Id", "Profile", "Name", "Layer"],
        },
        "Provider Stream Setting": {
            KeyStr: "PXM_PROVIDER_STREAM_SETTING",
            Columns: [
                "BridgeId",
                "Time",
                "BridgeName||m",
                "Id||xsm||center",
                "Provider",
                "Stream",
                "Symbol",
                "Delay",
                "Depth",
                "Enable||xsm||center",
                "Instrument",
                "MarkupAsk||sc",
                "MarkupBid||sc",
                "Priority||||center",
                "Session||l",
                "Values",
                "Volumes",
            ],
            ExcludeCompare: ["BridgeId", "Time", "Id", "Provider", "Stream", "Symbol"],
        },
        "Provider Trade Setting": {
            KeyStr: "PXM_PROVIDER_TRADE_SETTING",
            Columns: [
                "BridgeId",
                "Time",
                "BridgeName||m",
                "Id||xsm||center",
                "Provider",
                "Trade",
                "Symbol",
                "Consume",
                "Enable||xsm||center",
                "Min||sc",
                "PendingError",
                "PendingKill",
                "PendingWarn",
                "Precision",
                "RejectKill",
                "Step||sc",
                "Sweep||xsm||center",
                "Values",
            ],
            ExcludeCompare: ["BridgeId", "Time", "Id", "Provider", "Trade", "Symbol"],
        },
        Symbol: {
            KeyStr: "PXM_SYMBOL",
            Columns: [
                "BridgeId",
                "Time",
                "BridgeName||m",
                "Id||xsm||center",
                "Security",
                "Base",
                "Quote",
                "Digits||xsm||center",
                "Min||sc",
                "Enable||xsm||center",
                "Description||m",
            ],
            ExcludeCompare: ["BridgeId", "Time", "Id"],
        },
    },
};
