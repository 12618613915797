import { Form, FormInstance } from "antd";
import { KeyValuePair } from "../../../../constants/type";
import { FormComponent } from "../../../../components/FormComponent";
import { ComponentType } from "../../../../constants";
import { REQUIRED_FIELD } from "../../../../constants/errorMessage";
import AlarmModalWrapperComponent from "./wrapperComponent";
import moment from "moment";

export interface AlarmDashboardTaskAssignProps {
    form: FormInstance;
    userOptions: KeyValuePair[];
}

const AlarmDashboardTaskAssign = (props: AlarmDashboardTaskAssignProps) => {
    return (
        <AlarmModalWrapperComponent>
            <div className="task-assigning">
                <Form form={props.form} labelCol={{ span: 6 }} labelAlign="left" wrapperCol={{ span: 18 }} layout="horizontal">
                    <FormComponent label="" name="alarmEventId" extra={{ type: ComponentType.hidden, value: "" }} />
                    <FormComponent
                        label="Assignee"
                        name="assigneeId"
                        extra={{ type: ComponentType.dropdown, value: props.userOptions, rules: [{ required: true, message: REQUIRED_FIELD }] }}
                    />
                    <FormComponent
                        label="Due Date (Local)"
                        name="targetDueDateUtc"
                        extra={{
                            type: ComponentType.date,
                            value: "",
                            inputProps: {
                                showTime: { format: "HH:mm:ss" },
                                disabledDate: (current: any) => {
                                    return current && current < moment().subtract(1, "day").endOf("day");
                                },
                            },
                        }}
                    />
                </Form>
            </div>
        </AlarmModalWrapperComponent>
    );
};

export default AlarmDashboardTaskAssign;
