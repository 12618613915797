import { APIs } from '@/services/apis';
import { Card, Col, Row } from 'antd';
import UploadDownloadComponent from './component/uploadDownloadComponent';

const ResignForChangeSecurity = () => {
  return (
    <div className='resign-for-change-security-container'>
      <Card>
        <Row gutter={16}>
          <Col span={12}>
            <UploadDownloadComponent
              upload={{
                apiUrl: APIs.RC_MT_CLIENT_SECURITY.POST_UPLOAD_COMMON,
                className: 'resign-common',
                title: 'Upload Resign Common Accounts.',
              }}
              download={{
                apiUrl: APIs.RC_MT_CLIENT_SECURITY.DOWNLOAD_RESIGN_COMMON_ACCOUNT_LIST,
                msgPrefix: 'Resign Common Account List',
                title: 'Download Resign Common Account List',
              }}
            />
          </Col>
          <Col span={12}>
            <UploadDownloadComponent
              upload={{
                apiUrl: APIs.RC_MT_CLIENT_SECURITY.POST_UPLOAD_RESIGN,
                className: 'resign-personal',
                title: 'Upload Resign Personal Accounts.',
              }}
              download={{
                apiUrl: APIs.RC_MT_CLIENT_SECURITY.DOWNLOAD_RESIGN_PERSONAL_ACCOUNT_LIST,
                msgPrefix: 'Resign Personal Account List',
                title: 'Download Resign Personal Account List',
              }}
            />
          </Col>
        </Row>
      </Card>
    </div>
  )
}

export default ResignForChangeSecurity
