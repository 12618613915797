import { HomeOutlined } from "@ant-design/icons";
import { Badge, Button, Card, Col, Empty, Popconfirm, Row, Tag } from "antd";
import cronstrue from "cronstrue";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LoadingComponent from "../../../components/Loading";
import SitePageHeader from "../../../components/PageHeader";
import {
    FILTER_OPERATOR_DISPLAYNAME,
    FILTER_OPERATOR_TYPE_ENUM,
    SHARING_RULES,
    SHARING_RULES_ENUM,
    SORTING,
    STATUS_TYPE,
    SUCCESS_FAILED,
} from "../../../constants";
import { AccountTypes, Brands3, CreateTemplate, SchedulerInfo, Tags } from "../../../constants/type";
import AuthHelper, { AuthKeys } from "../../../helpers/authHelper";
import { apiRequest, APIs } from "../../../services/apiConfig";
import { DataTableColumnRender, ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";
import "../Templates/createEditTemplate.less";
import { deleteTemplate } from "./createEditTemplate";

export interface ViewTemplateState {
    reportId: string;
    reportName: string;
    prevPath: string;
    reportInstancePath?: string;
}

const getReportInfo = (viewState: string): ViewTemplateState | undefined => {
    let rInfo = atob(viewState).split("|");
    return rInfo.length === 4
        ? ({
              reportId: rInfo[0],
              reportName: rInfo[1],
              prevPath: rInfo[2],
              reportInstancePath: rInfo[3] || "",
          } as ViewTemplateState)
        : undefined;
};

const ViewTemplate = () => {
    let navigate = useNavigate();

    let { id } = useParams();
    const reportInfo = getReportInfo(id || "") || {
        reportId: "0",
        reportName: "",
        prevPath: "",
        reportInstancePath: "",
    };

    const [Loading, setLoading] = useState<boolean>(true);
    //const [templateInfo, setTemplateInfo] = React.useState<CreateTemplate>({} as CreateTemplate);
    const [data, setData] = React.useState<CreateTemplate>({} as CreateTemplate);
    const [accountTypes, setAccountTypes] = useState<AccountTypes[]>([]);

    const getFilterConfigList = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["brand", "accounttype"] })
            .then((data: any) => {
                setAccountTypes(data.accountTypes);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => console.log(err));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (parseInt(reportInfo.reportId) < 0) {
            navigate("/report/template");
        } else {
            setLoading(true);
            getFilterConfigList();
            apiRequest(APIs.GET_REPORT_TEMPLATE_BY_ID, {
                reportId: reportInfo.reportId,
                reportName: reportInfo.reportName,
            })
                .then((data: any) => {
                    let current_data: CreateTemplate = data as CreateTemplate;
                    (current_data.tags as Tags[]).sort((a: Tags, b: Tags) => b.name.length - a.name.length);
                    setData(
                        Object.assign(data, current_data, {
                            metricType: {
                                typeId: current_data.metricType.typeId,
                                name: current_data.metricType.name,
                            },
                            sharingRules: current_data.sharingRules,
                            adHocRun: current_data.schedulers.length === 0,
                        })
                    );
                })
                .catch((error: any) => {
                    ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("report template", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                    navigate("/report/template");
                })
                .finally(() => {
                    setLoading(false);
                });
        }
        return () => {};
    }, [reportInfo.reportId]);

    const gridStyle = { width: "32%" };
    const titleStyle = { backgroundColor: "#fafafa", width: "18%" };
    const authHp = new AuthHelper();

    const duplicate_template = (reportId: string | number) => {
        setLoading(true);
        apiRequest(APIs.DUPLICATE_TEMPLATE_BY_ID, {
            reportId: reportId,
            reportName: reportInfo.reportName,
        })
            .then((data: any) => {
                let state = `${data.reportId}|${""}|duplicate`;
                navigate(`/report/template/edit/${btoa(state)}`);
            })
            .catch((error) => {
                ErrorCatchValidator(error, (res: any) => ErrorMessageHandler("report template", SUCCESS_FAILED.FAILED_DUPLICATE_DATA, error));
                navigate("/report/template");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <SitePageHeader
            title="View Template"
            routes={
                reportInfo.prevPath === "result"
                    ? [
                          {
                              path: "/report/result",
                              breadcrumbName: "Report Result",
                              icon: <HomeOutlined />,
                          },
                          {
                              path: `/report/result/view/${reportInfo.reportInstancePath}`,
                              breadcrumbName: "View Report Instances",
                          },
                          { path: "", breadcrumbName: "View Template" },
                      ]
                    : [
                          {
                              path: "/report/template",
                              breadcrumbName: "Report Template",
                              icon: <HomeOutlined />,
                          },
                          { path: "", breadcrumbName: "View Template" },
                      ]
            }
            onBack={() => {
                if (reportInfo.prevPath === "result") navigate(`/report/result/view/${reportInfo.reportInstancePath}`);
                else navigate("/report/template");
            }}
            {...authHp.isAuthorized(reportInfo.prevPath === "result" ? AuthKeys.REPORT_HISTORY_EDIT : AuthKeys.TEMPLATE_EDIT, {
                extraProps: {
                    extra: [
                        data.editable && (
                            <Popconfirm
                                key={"cetmp-del"}
                                title="Confirm to delete?"
                                onConfirm={() => deleteTemplate(reportInfo.reportId, navigate)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button type="primary" danger>
                                    Delete Template
                                </Button>
                            </Popconfirm>
                        ),
                        data.sharingRules === SHARING_RULES_ENUM.Public && (
                            <Button key={"vt-dup"} type="primary" onClick={() => duplicate_template(reportInfo.reportId)}>
                                Duplicate Template
                            </Button>
                        ),
                    ],
                },
            })}
        >
            <div className="ViewTemplateContainerV2">
                {Loading ? (
                    <div className="loading-container">
                        <LoadingComponent tip="Loading..." />
                    </div>
                ) : (
                    <>
                        <Card.Grid style={{ ...gridStyle, ...{ width: "100%", boxShadow: "none" } }} hoverable={false}>
                            <Card bordered title="Basic" className="inline-card-style">
                                <Card.Grid style={{ ...gridStyle, ...titleStyle }} hoverable={false}>
                                    Template Name
                                </Card.Grid>
                                <Card.Grid style={gridStyle} hoverable={false}>
                                    {data.name || "-"}
                                </Card.Grid>
                                <Card.Grid style={{ ...gridStyle, ...titleStyle }} hoverable={false}>
                                    Description
                                </Card.Grid>
                                <Card.Grid style={gridStyle} hoverable={false}>
                                    {data.description || "-"}
                                </Card.Grid>
                                <Card.Grid style={{ ...gridStyle, ...titleStyle }} hoverable={false}>
                                    Sharing
                                </Card.Grid>
                                <Card.Grid style={gridStyle} hoverable={false}>
                                    {SHARING_RULES[data.sharingRules]}
                                </Card.Grid>
                                <Card.Grid style={{ ...gridStyle, ...titleStyle }} hoverable={false}>
                                    Status
                                </Card.Grid>
                                <Card.Grid style={gridStyle} hoverable={false}>
                                    {STATUS_TYPE[data.status]}
                                </Card.Grid>
                                <Card.Grid style={{ ...gridStyle, ...titleStyle }} hoverable={false}>
                                    Created By
                                </Card.Grid>
                                <Card.Grid style={gridStyle} hoverable={false}>
                                    {data.ownerName || "-"}
                                </Card.Grid>
                                <Card.Grid style={{ ...gridStyle, ...titleStyle }} hoverable={false}>
                                    Created At (Local)
                                </Card.Grid>
                                <Card.Grid style={gridStyle} hoverable={false}>
                                    {DataTableColumnRender.DateTime(data.createdDateUtc)}
                                </Card.Grid>
                            </Card>
                        </Card.Grid>

                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{ width: "50%", paddingRight: "0.7vw" }}>
                                <Card bordered title="Metric Information" className="inline-card-style">
                                    <Row className="mb-2">
                                        <Col span={3}>Metric Type:</Col>
                                        <Col span={8}>{data?.metricType?.name}</Col>
                                    </Row>
                                    <Row className="metric-content">
                                        <Col span={24} className="selected-metric-content">
                                            <h4>Selected Metrics:</h4>
                                            {data.metricSelected &&
                                                data.metricSelected.map((x) => (
                                                    <div key={"sm-" + x.metricId}>
                                                        <Badge status="default" text={x.displayName} />
                                                        <br />
                                                    </div>
                                                ))}
                                        </Col>
                                    </Row>
                                </Card>
                                <Card
                                    bordered
                                    title={
                                        <>
                                            Schedular Configuration <span style={{ color: "#f00f00" }}>(Based on Server Time Zone)</span>
                                        </>
                                    }
                                    className="inline-card-style"
                                >
                                    <Row className="scheduler-content">
                                        <Col span={24} className="schedular-list">
                                            <h4>Scheduler Setting:</h4>
                                            {data.schedulers && data.schedulers.length > 0 ? (
                                                (data.schedulers as SchedulerInfo[]).map((x, index) => (
                                                    <div key={"sd-" + index}>
                                                        <span>
                                                            {cronstrue.toString(x.cronExpression, {
                                                                throwExceptionOnParseError: false,
                                                                use24HourTimeFormat: true,
                                                            })}
                                                            {x.snapshot && (
                                                                <Tag key={`sc-tg-${index}`} style={{ padding: "0 0.651vw", marginLeft: "0.651vw" }}>
                                                                    T-1 snapshot
                                                                </Tag>
                                                            )}
                                                        </span>
                                                        <br />
                                                    </div>
                                                ))
                                            ) : (
                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                            )}
                                        </Col>
                                    </Row>
                                </Card>
                            </div>
                            <div style={{ width: "50%", paddingLeft: "0.7vw" }}>
                                <Card bordered title="Filter and Sorting Rules" className="inline-card-style">
                                    {/* <Row className="mb-2">
                                        <Col span={5}>Account Type(s):</Col>
                                        <Col span={18}>
                                            {data.accountTypes && data.accountTypes.length > 0
                                                ? data.accountTypes
                                                      .map((x) => {
                                                          let find_any: any = accountTypes.find((y) => y.id === x);
                                                          return find_any?.name;
                                                      })
                                                      .join(" , ")
                                                : "-"}
                                        </Col>
                                    </Row> */}
                                    <Row className="mb-2">
                                        <Col span={5}>Account ID(s):</Col>
                                        <Col span={18}>{data.accountIds && data.accountIds.length > 0 ? data.accountIds.join(" , ") : "-"}</Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col span={5}>Symbol(s):</Col>
                                        <Col span={18}>{data.symbols && data.symbols.length > 0 ? data.symbols.join(" , ") : "-"}</Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col span={5}>Filter by Brand(s):</Col>
                                        <Col span={18}>
                                            {data.brands && data.brands.length > 0
                                                ? (data?.brands as Brands3[]).map((x) => x.brand).join(" , ") || "-"
                                                : "-"}
                                        </Col>
                                    </Row>
                                    {data.limitRecords && (
                                        <Row className="mb-2">
                                            <Col span={5}>Limit Record(s):</Col>
                                            <Col span={18} style={{ fontWeight: "bold" }}>
                                                <span style={{ color: "#f00f00" }}>*</span>[ Select first {data.limitRecords ? data.limitRecords : 0}{" "}
                                                ]
                                            </Col>
                                        </Row>
                                    )}
                                    <Row className="filter-sorter-content">
                                        <Col span={12} className="with-border selected-metric-content">
                                            <h4>Custom Filter:</h4>
                                            {data.metricFilters && data.metricFilters.length > 0 ? (
                                                data.metricFilters.map((x, index) => {
                                                    let find_any = data.metricSelected.find((y) => y.metricId === x.metricId);
                                                    let displayText = x.value
                                                        .split("|")
                                                        .join(x.operator === FILTER_OPERATOR_TYPE_ENUM.Range ? " & " : "");

                                                    return (
                                                        <div key={`cf-${x.metricId}-${index}`}>
                                                            <Badge
                                                                status="default"
                                                                text={
                                                                    <span>
                                                                        {find_any?.displayName}
                                                                        <span
                                                                            style={{
                                                                                fontWeight: "bold",
                                                                                margin: "0px 0.651vw",
                                                                            }}
                                                                        >
                                                                            ({FILTER_OPERATOR_DISPLAYNAME[x.operator as number]})
                                                                        </span>
                                                                        {displayText}
                                                                    </span>
                                                                }
                                                            />
                                                            <br />
                                                        </div>
                                                    );
                                                })
                                            ) : (
                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                            )}
                                        </Col>
                                        <Col span={12} className="custom-filter-content">
                                            <h4>Custom Sort:</h4>
                                            {data.metricSorts && data.metricSorts.length > 0 ? (
                                                data.metricSorts.map((x, index) => {
                                                    let find_any = data.metricSelected.find((y) => y.metricId === x.metricId);
                                                    //let displayText = x.name;

                                                    return (
                                                        <div key={`cs-${x.metricId}-${index}`}>
                                                            <Badge
                                                                status="default"
                                                                text={
                                                                    <>
                                                                        <span>{find_any?.displayName}</span>
                                                                        <span style={{ fontWeight: "bold", marginLeft: "0.651vw" }}>
                                                                            (by {SORTING[x.order]})
                                                                        </span>
                                                                    </>
                                                                }
                                                            />
                                                            <br />
                                                        </div>
                                                    );
                                                })
                                            ) : (
                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                            )}
                                        </Col>
                                    </Row>
                                </Card>
                                <Card bordered title="Post Report Process" className="inline-card-style">
                                    <Card.Grid style={{ ...gridStyle, ...titleStyle }} hoverable={false}>
                                        Email(s)
                                    </Card.Grid>
                                    <Card.Grid style={{ ...gridStyle, ...{ width: "82%" } }} hoverable={false}>
                                        {data.emails && data.emails.length > 0 ? data.emails.join(" , ") : "-"}
                                    </Card.Grid>
                                    <Card.Grid style={{ ...gridStyle, ...titleStyle }} hoverable={false}>
                                        Filter by Tag(s)
                                    </Card.Grid>
                                    <Card.Grid style={{ ...gridStyle, ...{ width: "82%" } }} hoverable={false}>
                                        {data.tags && data.tags.length > 0
                                            ? (data.tags as Tags[]).map((x) => (
                                                  <Tag key={x.id} style={{ width: "auto", margin: "0.707vh 0.3255vw" }}>
                                                      {x.name}
                                                  </Tag>
                                              ))
                                            : "-"}
                                    </Card.Grid>
                                </Card>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </SitePageHeader>
    );
};

export default ViewTemplate;
