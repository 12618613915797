import { StringFormat } from "../../utils/array";
import { hasAnyKey } from "../../utils/object";
import { isEmptyOrNull } from "../../utils/string";
import { ProfileInfoProps } from "./AccountProfiles/viewProfile";

export const getAccountProfileParams = (
    serverId: number,
    accountId: number,
    fromSource: string,
    viewReportId: string,
    clientId: number | string,
    regulatorId: number | string,
    crmServerId: number | string,
    dateRangeFilter: string = ""
) => {
    const params = {
        serverId,
        accountId,
        fromSource,
        viewReportId,
        clientId,
        regulatorId,
        crmServerId,
        dateRangeFilter,
    };
    const queryString = Object.entries(params)
        .filter(([key, value]) => !isEmptyOrNull(value))
        .map(([key, value]) => `${key}=${value}`)
        .join("&");

    return `${queryString}`;
};

export const getAccountProfileId = (
    serverId: number,
    accountId: number,
    fromSource: string,
    viewReportId: string,
    clientId: number | string,
    regulatorId: number | string,
    crmServerId: number | string,
    dateRangeFilter: string = ""
) => {
    return btoa(
        `${serverId}|${accountId}|${fromSource}|${viewReportId}|${clientId}|${regulatorId}|${crmServerId}${
            dateRangeFilter.length > 0 ? "|" + dateRangeFilter : ""
        }`
    );
};
export const getAccountProfileIdByObj = (x: ProfileInfoProps) => {
    return btoa(
        `${x.serverId}|${x.accountId}|${x.fromModule ? x.fromModule : ""}|${x.viewReportId ? x.viewReportId : ""}|${x.clientId ? x.clientId : ""}|${
            x.regulatorId ? x.regulatorId : ""
        }|${x.crmServerId ? x.crmServerId : ""}${x.dateRangeFilter && hasAnyKey(x.dateRangeFilter) ? "|" + JSON.stringify(x.dateRangeFilter) : ""}`
    );
};

export const AccountProfileNavigationUrls: { [key: string]: string } = {
    clientlist: `/analysis/clientlist/view?{0}`,
    accountprofile: `/analysis/accountprofile/view?{0}`,
    watchlist: `/analysis/watchlist/view?{0}`,
    accounttagginglist: `/tagging/list/view?{0}`,
    viewreportinstances: `/analysis/accountprofile/view?{0}`,
    monitorprofilelist: `/realtime/trademonitor/view/{0}`,
    historicalEventlist: `/realtime/historicaleventbatches/view/{0}`,
    historicalEventSummarylist: `/realtime/historicaleventsummary/view?{0}`,
    decayAnalysis: `/realtime/decaychart/view/{0}`,
    closedDecayAnalysis: `/realtime/closeddecaychart/view/{0}`,
    pmiTable: `/realtime/pmi/view/{0}`,
    toxicAccount: `/realtime/toxicaccount/view?{0}`,
    problematicClients: `/analysis/problematicClients/view?{0}`,
    premarketAccount: `/analysis/premarketAccountModel/view?{0}`,
    lp360: `/lpmonitor/lp360/view/{0}`,
    intradayMonitorSummary: `/intradaymonitor/summary/view?{0}`,
    intradayMonitorSearch: `/intradaymonitor/search/view?{0}`,
    intradayMonitorAlarm: `/intradaymonitor/alarm/accountview?{0}`,
};

export const ACCOUNT_PROFILE_FROM_MODULE = {
    CLIENTS_LIST: `clientlist`,
    ACCOUNT_PROFILE_LIST: `accountprofile`,
    WATCH_LIST: `watchlist`,
    TAGGING_LIST: `accounttagginglist`,
    VIEW_REPORT_INSTANCES: `viewreportinstances`,
    MONITOR_PROFILE_LIST: `monitorprofilelist`,
    HISTORICAL_EVENT_LIST: `historicalEventlist`,
    HISTORICAL_EVENT_SUMMARY_LIST: `historicalEventSummarylist`,
    DECAY_ANALYSIS: `decayAnalysis`,
    CLOSED_DECAY_ANALYSIS: `closedDecayAnalysis`,
    PMI_TABLE: `pmiTable`,
    TOXIC_ACCOUNT: `toxicAccount`,
    PROBLEMATIC_CLIENT_ACCOUNT: `problematicClients`,
    PREMARKET_ACCOUNT: `premarketAccount`,
    INTRADAY_MONITOR_SUMMARY: "intradayMonitorSummary",
    INTRADAY_MONITOR_SEARCH: "intradayMonitorSearch",
    INTRADAY_MONITOR_ALARM: "intradayMonitorAlarm",
};

export const getNavigationUrl = (fromModule: string, id: string) => {
    return StringFormat(`${AccountProfileNavigationUrls[fromModule]}`, id);
};

export const getAccountInfo = (profileId: string): ProfileInfoProps => {
    if (profileId.includes("?")) {
        const params = new URLSearchParams(profileId);
        const entries = Object.fromEntries(params.entries());

        return {
            ...(entries.accountId && { accountId: entries.accountId }),
            ...(entries.serverId && { serverId: entries.serverId }),
            ...(entries.fromSource && { fromModule: entries.fromSource }),
            ...(entries.clientId && { clientId: entries.clientId }),
            ...(entries.regulatorId && { regulatorId: entries.regulatorId }),
            ...(entries.crmServerId && { crmServerId: entries.crmServerId }),
            ...(entries.dateRangeFilter && { dateRangeFilter: entries.dateRangeFilter }),
            ...(entries.viewReportId && { viewReportId: entries.viewReportId }),
        } as ProfileInfoProps;
    } else {
        let pInfo = atob(profileId).split("|");
        return pInfo.length === 7 || pInfo.length === 8
            ? ({
                  serverId: pInfo[0],
                  accountId: pInfo[1],
                  fromModule: pInfo[2],
                  viewReportId: pInfo[3],
                  clientId: pInfo[4],
                  regulatorId: pInfo[5],
                  crmServerId: pInfo[6],
                  dateRangeFilter: pInfo[7] ? JSON.parse(pInfo[7]) : {},
              } as ProfileInfoProps)
            : ({
                  serverId: "0",
                  accountId: "0",
                  fromModule: "",
                  viewReportId: "",
                  clientId: "",
                  regulatorId: "",
                  crmServerId: "",
                  dateRangeFilter: {},
              } as ProfileInfoProps);
    }
};
