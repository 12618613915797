export interface AlarmHistoricalItemSummaryProps {
    containerClassName?: string;
    title: string;
    SummaryTitle: string;
    SummaryDetails: string;
}

const AlarmHistoricalItemSummary = (props: AlarmHistoricalItemSummaryProps) => {
    return (
        <div className={`summary-item shadow-light ${props.containerClassName || ""}`}>
            <div className="left">
                <span>{props.title}</span>
            </div>
            <div className="right">
                <span className="subtitle">{props.SummaryTitle}</span>
                <span>{props.SummaryDetails}</span>
            </div>
        </div>
    );
};

export default AlarmHistoricalItemSummary;
