import React, { useState, useCallback, useEffect, useMemo } from "react";
import { apiRequest } from "../../../../services/apiConfig";
import { APIs } from "../../../../services/apis";
import { convertValueBasedOnDataType, isEmptyOrNull } from "../../../../utils/string";
import { TimelineItemProps } from "../alarmDashboard/alarmDashboardTimelineItem";
import { Spin, Timeline } from "antd";
import EmptyData from "../../../../components/Common/Empty";
import LoadingComponent from "../../../../components/Loading";
import { DataTableColumnRender } from "../../../../utils/Common";
import { ALARM_BREACH_SEVERITY, ALARM_DASHBOARD_ITEM_CALLBACK_KEY } from "../../../../constants";
import { ClockCircleOutlined } from "@ant-design/icons";
import { getFilterRulesColumnRender, getMetricColumnRender } from "../../../IntradayMonitor/Alarm/List";
import { SortList, ToObjectWithKey } from "../../../../utils/array";
import { DefaultIfEmpty, getAvailableObjectElement } from "../../../../utils/object";
import AlarmModalWrapperComponent from "./wrapperComponent";
import { CommentHistoryItemProps } from "./alarmDashboardCommentHistory";
import AuthHelper, { AuthKeys } from "../../../../helpers/authHelper";
import AlarmDashboardInlineComment from "./alarmDashboardInlineComment";

export interface AlarmDashboardGroupProgressProps {
    utcOffset: number | undefined;
    data: TimelineItemProps;
    mappingData: any;
    lpMappingData: any;
    statusOptions: any[];
}

interface AlarmProgressItemProps {
    propsType?: number;
    alarmEventId: string;
    moduleCode: string;
    moduleName: string;
    breachSeverity: number;
    breachSeverityDesc: string;
    eventDateTimeUtc: string;
    eventMessage: string | null;
    metricFilters: string | null;
    eventStatusCode: string;
    eventStatusDescription: string;
}

const AlarmDashboardGroupProgress = (props: AlarmDashboardGroupProgressProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [alarmList, setAlarmList] = useState<AlarmProgressItemProps[]>([]);
    const [commentList, setCommentList] = useState<CommentHistoryItemProps[]>([]);
    const statusObj = useMemo(() => ToObjectWithKey(props.statusOptions, "value", "isConsiderCaseClosed"), [props.statusOptions]);
    const authHp = new AuthHelper();
    const allowEditable: boolean = authHp.isAuthorized(AuthKeys.ALARM_CENTER_DASHBOARD_EDIT);

    const getItemTemplate = useCallback(
        (data: AlarmProgressItemProps): React.ReactNode => {
            let contentTemplate: React.ReactNode = <></>;

            switch (data.moduleCode) {
                case "MIM":
                    if (!isEmptyOrNull(data.eventMessage) && !isEmptyOrNull(data.eventMessage)) {
                        try {
                            let eventDetails = JSON.parse(data.eventMessage as string),
                                metricInfo = JSON.parse(data.metricFilters as string);

                            contentTemplate = (
                                <>
                                    <div className="title-header">
                                        <div className="left">Metrics</div>
                                        <div className="right">Filter Rule(s)</div>
                                    </div>
                                    <div className="body-content">
                                        <div className="left">
                                            {getMetricColumnRender(`${data.alarmEventId}`, eventDetails, metricInfo, props.mappingData)}
                                        </div>
                                        <div className="right">
                                            {getFilterRulesColumnRender(`${data.alarmEventId}`, eventDetails, metricInfo, props.mappingData)}
                                        </div>
                                    </div>
                                </>
                            );
                        } catch (error) {}
                    }
                    break;
                case "MR":
                    if (!isEmptyOrNull(data.eventMessage) && !isEmptyOrNull(data.eventMessage)) {
                        try {
                            let eventDetails = getAvailableObjectElement(JSON.parse(data.eventMessage as string), true),
                                metricInfo = JSON.parse(data.metricFilters as string).map((x: any) => ({
                                    ...x,
                                    metricName: x.metricName.toLowerCase(),
                                    filterValue: [7].includes(x.filterOp)
                                        ? x.filterValue
                                        : convertValueBasedOnDataType(x.metricDataType, x.filterValue),
                                })),
                                metricNameArr = metricInfo.map((x: any) => x.metricName as string),
                                metricInfoByKey = ToObjectWithKey(metricInfo, "metricName"),
                                markupMetricInfo = [{ metricInnerFilters: metricInfo }];

                            Object.keys(eventDetails)
                                .filter(x => metricNameArr.includes(x))
                                .forEach(x => {
                                    eventDetails[x] = convertValueBasedOnDataType(metricInfoByKey[x].metricDataType, eventDetails[x]);
                                });
                            contentTemplate = (
                                <>
                                    <div className="title-header">
                                        <div className="left">Metrics</div>
                                        <div className="right">Filter Rule(s)</div>
                                    </div>
                                    <div className="body-content">
                                        <div className="left">
                                            {getMetricColumnRender(`${data.alarmEventId}`, eventDetails, markupMetricInfo, props.lpMappingData)}
                                        </div>
                                        <div className="right">
                                            {getFilterRulesColumnRender(`${data.alarmEventId}`, eventDetails, markupMetricInfo, props.lpMappingData)}
                                        </div>
                                    </div>
                                </>
                            );
                        } catch (error) {}
                    }
                    break;
                default:
                    contentTemplate = (
                        <div className="body-content">
                            {typeof data.eventMessage === "object" ? JSON.stringify(data.eventMessage) : data.eventMessage}
                        </div>
                    );
                    break;
            }

            return (
                <div className="grouped-item shadow-light">
                    <div className="top-header-container">
                        <div className="left">
                            <span className={`severity-container priority-color-${data.breachSeverity}`}>
                                {ALARM_BREACH_SEVERITY[data.breachSeverity]}
                            </span>
                        </div>
                        <div className="right">
                            <ClockCircleOutlined />
                            <span className="date-text">{`${DataTableColumnRender.DateTime_UTC_TO_ServerTime(
                                data.eventDateTimeUtc,
                                props.utcOffset
                            )}`}</span>
                        </div>
                    </div>
                    <div className={`content ${data.moduleCode}`}>{contentTemplate}</div>
                </div>
            );
        },
        [props.mappingData, props.lpMappingData]
    );

    const getCommentList = useCallback(() => {
        setIsLoading(true);
        apiRequest(
            APIs.GET_ALARM_COMMENT_HISTORY,
            isEmptyOrNull(props.data.correlationHashKey)
                ? { alarmEventId: props.data.alarmEventId }
                : { correlationHashKey: props.data.correlationHashKey }
        )
            .then((res: CommentHistoryItemProps[]) => {
                setCommentList(res && res.length > 0 ? res.map(x => ({ ...x, propsType: 2, sortDateUtc: x.commentDateUtc })) : []);
            })
            .finally(() => setIsLoading(false));
    }, [props.data]);

    const getGroupedAlarmList = useCallback(() => {
        setIsLoading(true);
        apiRequest(APIs.GET_ALARM_BREACH_SEVERITY_PROGRESS, { correlationHashKey: props.data.correlationHashKey })
            .then((res: AlarmProgressItemProps[]) => {
                setAlarmList(res && res.length > 0 ? res.map(x => ({ ...x, propsType: 1, sortDateUtc: x.eventDateTimeUtc })) : []);
            })
            .finally(() => setIsLoading(false));
    }, [props.data]);

    const timelineItemList: (AlarmProgressItemProps | CommentHistoryItemProps)[] = useMemo(() => {
        return SortList([...alarmList, ...commentList], "sortDateUtc", undefined, "DESC");
    }, [commentList, alarmList]);

    useEffect(() => {
        getGroupedAlarmList();
        getCommentList();
    }, [props.data]);

    return (
        <>
            {allowEditable && (
                <div className="view-mode-selection">
                    <AlarmDashboardInlineComment
                        data={props.data}
                        statusOptions={props.statusOptions}
                        callback={(type: ALARM_DASHBOARD_ITEM_CALLBACK_KEY, data: any) => {
                            switch (type) {
                                case ALARM_DASHBOARD_ITEM_CALLBACK_KEY.REFRESH_LIST:
                                    getGroupedAlarmList();
                                    getCommentList();
                                    break;
                                default:
                                    break;
                            }
                        }}
                    />
                </div>
            )}
            <AlarmModalWrapperComponent>
                <div className="group-progress-container">
                    {isLoading ? (
                        <LoadingComponent />
                    ) : timelineItemList.length > 0 ? (
                        <Timeline>
                            {timelineItemList.map((x: AlarmProgressItemProps | CommentHistoryItemProps, index: number) =>
                                x.propsType === 1 ? (
                                    <Timeline.Item key={`aldsb-ch-r-${index}`}>
                                        {DefaultIfEmpty(statusObj, (x as AlarmProgressItemProps).eventStatusCode || "", false) ? (
                                            <Spin
                                                indicator={<span>{(x as AlarmProgressItemProps).eventStatusDescription}</span>}
                                                wrapperClassName="status-itl-container"
                                            >
                                                {getItemTemplate(x as AlarmProgressItemProps)}
                                            </Spin>
                                        ) : (
                                            getItemTemplate(x as AlarmProgressItemProps)
                                        )}
                                    </Timeline.Item>
                                ) : (
                                    <Timeline.Item key={`aldsb-ch-r-${index}`}>
                                        <div className="alarm-dashboard-comment-popover-item">
                                            <div className="message-content">{(x as CommentHistoryItemProps).eventComment}</div>
                                            <div className="message-footer">{`${DataTableColumnRender.DateTime_UTC_TO_ServerTime(
                                                (x as CommentHistoryItemProps).commentDateUtc,
                                                props.utcOffset
                                            )} - ${(x as CommentHistoryItemProps).commentedBy}`}</div>
                                        </div>
                                    </Timeline.Item>
                                )
                            )}
                        </Timeline>
                    ) : (
                        <EmptyData />
                    )}
                </div>
            </AlarmModalWrapperComponent>
        </>
    );
};

export default AlarmDashboardGroupProgress;
