import { Datum } from "@ant-design/charts";
import { LineChartOutlined } from "@ant-design/icons";
import moment from "moment";
import { useEffect, useState } from "react";
import { SUCCESS_FAILED } from "../../../../../constants";
import { SymbolAnalysisFilterParams } from "../../../../../constants/type";
import { apiRequest, APIs } from "../../../../../services/apiConfig";
import { currencyRender, ErrorCatchValidator, ErrorMessageHandler } from "../../../../../utils/Common";
import { ProfileInfoProps } from "../../viewProfile";
import ChartPanel from "../chartPanel";
import { PNLSymbolProps } from "../pnlSymbolSummary";
import { Column } from "@ant-design/plots";
import EmptyData from "../../../../../components/Common/Empty";
import CustomSkeleton from "../../../../../components/Common/Skeleton";

export interface DailyPNLSummaryCurrSymbolChartProps extends ProfileInfoProps {
    selectedSymbol?: string;
    forPrint?: boolean;
    params: SymbolAnalysisFilterParams;
}

const DailyPNLSummaryCurrSymbolChart = (props: DailyPNLSummaryCurrSymbolChartProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [pnlDatas, setPnlDatas] = useState<PNLSymbolProps[]>([]);

    useEffect(() => {
        if (props.params.symbols === null || props.params.symbols === undefined || props.params.symbols.length === 0) {
            setPnlDatas([]);
        } else if (props.params.dateTo === "" && props.params.dateFrom === "") {
            delete props.params.dateTo;
            delete props.params.dateFrom;

            setIsLoading(true);
            apiRequest(APIs.ACCOUNT_HISTORICAL_PNL, {
                ...props.params,
                metricType: 2,
                timeFrame: 1,
                metricIds: [72],
                dataSamplingMode: 6,
                dataGroupingMode: 1,
            })
                .then((data: any) => {
                    if (data && data.length > 0) {
                        let markupData: any[] = [];
                        data.map((x: any) => {
                            if (x.symbol === props.selectedSymbol) {
                                let label = "PNL";
                                markupData = markupData.concat(
                                    x.groupedData[0].plotData.map((y: any) => ({
                                        name: label,
                                        date: moment(y.date).format("YYYY-MM-DD"),
                                        value: y.value,
                                    }))
                                );
                            }

                            return false;
                        });
                        setPnlDatas(markupData);
                    } else {
                        setPnlDatas([]);
                    }
                })
                .catch((error) => {
                    ErrorCatchValidator(error, (err: any) =>
                        ErrorMessageHandler(`daily pnl data for ${props.selectedSymbol}`, SUCCESS_FAILED.FAILED_LOAD_DATA, err)
                    );
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            setIsLoading(true);
            apiRequest(APIs.ACCOUNT_HISTORICAL_PNL, {
                ...props.params,
                metricType: 2,
                timeFrame: 1,
                metricIds: [72],
                dataSamplingMode: 6,
                dataGroupingMode: 1,
            })
                .then((data: any) => {
                    if (data && data.length > 0) {
                        let markupData: any[] = [];
                        data.map((x: any) => {
                            if (x.symbol === props.selectedSymbol) {
                                let label = "PNL";
                                markupData = markupData.concat(
                                    x.groupedData[0].plotData.map((y: any) => ({
                                        name: label,
                                        date: moment(y.date).format("YYYY-MM-DD"),
                                        value: y.value,
                                    }))
                                );
                            }

                            return false;
                        });
                        setPnlDatas(markupData);
                    } else {
                        setPnlDatas([]);
                    }
                })
                .catch((error) => {
                    ErrorCatchValidator(error, (err: any) =>
                        ErrorMessageHandler(`daily pnl data for ${props.selectedSymbol}`, SUCCESS_FAILED.FAILED_LOAD_DATA, err)
                    );
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
        return () => {};
    }, [props.params]);

    return (
        <>
            {isLoading ? (
                <ChartPanel
                    forPrint={props.forPrint}
                    type={1}
                    icon={<LineChartOutlined />}
                    title={"Daily PNL"}
                    subTitle={"Daily's profit & loss summary for current symbol"}
                >
                    <CustomSkeleton rows={7} />
                </ChartPanel>
            ) : (
                <ChartPanel
                    forPrint={props.forPrint}
                    type={1}
                    icon={<LineChartOutlined />}
                    title={"Daily PNL"}
                    subTitle={"Daily's profit & loss summary for current symbol"}
                >
                    <div className="chart">
                        {pnlDatas?.length > 0 ? (
                            <Column
                                {...{
                                    data: pnlDatas,
                                    padding: "auto",
                                    xField: "date",
                                    yField: "value",
                                    ...(props.forPrint
                                        ? {
                                              xAxis: {
                                                  label: {
                                                      autoRotate: true,
                                                  },
                                                  tickLine: {
                                                      alignTick: true,
                                                  },
                                              },
                                          }
                                        : {
                                              xAxis: {
                                                  label: {
                                                      autoRotate: true,
                                                  },
                                                  tickLine: {
                                                      alignTick: true,
                                                  },
                                              },
                                              slider: {
                                                  start: 0,
                                                  end: 1,
                                              },
                                          }),
                                    yAxis: {
                                        label: {
                                            formatter: (v: any) => currencyRender(v),
                                        },
                                    },
                                    tooltip: {
                                        formatter: (datum: Datum) => {
                                            return {
                                                name: "Daily PNL",
                                                value: datum.value === 0 ? 0 : currencyRender(datum.value, 2),
                                            };
                                        },
                                    },
                                    color: ({ date }: any) => {
                                        let x: any = pnlDatas.find((i) => i.date === date);
                                        if (x.value < 0) {
                                            return "#F4664A";
                                        } else return "#63d8a8";
                                    },
                                }}
                            />
                        ) : (
                            <>
                                <EmptyData />
                            </>
                        )}
                    </div>
                </ChartPanel>
            )}
        </>
    );
};

export default DailyPNLSummaryCurrSymbolChart;
