import { Col, Empty, Row, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { APIs, apiRequest } from "../../../../services/apiConfig";
import { ErrorCatchValidator, ErrorMessageHandler, currencyRender, roundUpValue } from "../../../../utils/Common";
import { SUCCESS_FAILED } from "../../../../constants";
import { DimemsionFilterType, mapFilterParams } from "..";
import { hasAnyKey } from "../../../../utils/object";
import CustomSkeleton from "../../../../components/Common/Skeleton";
import { MimMetricsExtra } from "../../../../constants/type";

interface StatisticProps {
    metrics: MimMetricsExtra[];
    selectedParams?: DimemsionFilterType[];
    resetState: number;
}

const Statistic = (props: StatisticProps) => {
    const itemsPerRow = 3;
    const [runRefetchData, setRunRefetchData] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<any[]>([]);

    const getAsOfNow = () => {
        setIsLoading(true);
        apiRequest(APIs.GET_MIM_METRIC_AS_OF_NOW, {
            ...(props.metrics.length > 0 && {
                metricSelectors: props.metrics.map((x: MimMetricsExtra) => ({
                    metricName: x.metricName,
                    columnOrder: x.metricOrder,
                })),
            }),
            ...(hasAnyKey(props.selectedParams) && { dimensionFilters: mapFilterParams(props.selectedParams) }),
        })
            .then((res: any) => {
                if (res && res.length > 0) {
                    const sortedDataWithValues = props.metrics.map(metric => ({
                        ...metric,
                        value: res[0] && metric.metricName in res[0] ? res[0][metric.metricName] : null,
                    }));
                    setData(sortedDataWithValues);
                } else {
                    setData([]);
                }
            })
            .catch(error => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("statistic", SUCCESS_FAILED.FAILED_LOAD_DATA, err)))
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        if (hasAnyKey(props.selectedParams)) setRunRefetchData(true);
        return () => {};
    }, [props.selectedParams]);

    useEffect(() => {
        if (runRefetchData) {
            setIsLoading(true);
            getAsOfNow();
            setRunRefetchData(false);
        }
        return () => {};
    }, [runRefetchData]);

    useEffect(() => setRunRefetchData(true), [props.resetState]);

    const rowCount = Math.ceil(data.length / itemsPerRow);

    const rows = Array.from({ length: rowCount }, (_, rowIndex) => data.slice(rowIndex * itemsPerRow, (rowIndex + 1) * itemsPerRow));

    return (
        <div className="statistic-container">
            {isLoading ? (
                <CustomSkeleton />
            ) : data.length === 0 ? (
                <Empty />
            ) : (
                rows.map((row, rowIndex) => (
                    <Row key={rowIndex} gutter={[16, 16]}>
                        {row.map((col: any, colIndex: any) => (
                            <Col key={colIndex} span={8}>
                                <div className="value">
                                    <Tooltip placement="top" title={currencyRender(col.value, 2)}>
                                        {roundUpValue(col.value)}
                                    </Tooltip>
                                </div>
                                <div className="title">{col.metricDisplayName}</div>
                            </Col>
                        ))}
                    </Row>
                ))
            )}
        </div>
    );
};

export default Statistic;
