import { Col, Form, FormInstance, Row } from "antd";
import { FormComponent } from "../../../components/FormComponent";
import { ComponentType, STATUS_TYPE } from "../../../constants";
import { REQUIRED_FIELD } from "../../../constants/errorMessage";
import { OptionType, User } from "../../../constants/type";
import { ToOptionTypeList } from "../../../utils/array";

export interface MenuCreateEditPageProps {
    form: FormInstance;
}

const CreateEditPage = (props: MenuCreateEditPageProps) => {
    return (
        <>
            <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} form={props.form} layout="horizontal" initialValues={{}}>
                <FormComponent label={""} name={"id"} extra={{ type: ComponentType.hidden, value: "" }} />
                <FormComponent label={""} name={"parentId"} extra={{ type: ComponentType.hidden, value: "" }} />
                <Row>
                    <Col span={20}>
                        <FormComponent
                            label="Key"
                            name="key"
                            extra={{
                                type: ComponentType.text,
                                value: "",
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={20}>
                        <FormComponent
                            label="Title"
                            name="title"
                            extra={{
                                type: ComponentType.text,
                                value: "",
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={20}>
                        <FormComponent
                            label="Type"
                            name="type"
                            extra={{
                                type: ComponentType.dropdown,
                                value: [
                                    { text: "Module", value: 1 },
                                    { text: "Action", value: 2 },
                                    { text: "API", value: 3 },
                                ] as OptionType[],
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={20}>
                        <FormComponent
                            label="Status"
                            name="status"
                            extra={{
                                type: ComponentType.dropdown,
                                value: ToOptionTypeList(STATUS_TYPE),
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={20}>
                        <FormComponent
                            label="Order"
                            name="order"
                            extra={{
                                type: ComponentType.number,
                                value: "",
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                            }}
                        />
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default CreateEditPage;
