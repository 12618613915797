import { Row, Col, Form, message } from "antd";
import { FormComponent } from "../../../components/FormComponent";
import LoadingComponent from "../../../components/Loading";
import SitePageHeaderInline, { SitePageHeaderInlineActionType } from "../../../components/PageHeader/inlineIndex";
import { ComponentType, InnerPageActionMode, SUCCESS_FAILED } from "../../../constants";
import { REQUIRED_FIELD } from "../../../constants/errorMessage";
import { useMemo, useState, useCallback, useEffect } from "react";
import { CreateEditInlineFormBased, MRGroupList } from "../../../constants/type";
import { apiRequest } from "../../../services/apiConfig";
import { APIs } from "../../../services/apis";
import { ErrorMessageHandler, ErrorCatchValidator } from "../../../utils/Common";
import { isEmptyOrNull } from "../../../utils/string";
import MRBrandPKServerPanel from "./Component/MRBrandPKServerPanel";

interface MRGroupConfigEditPageProps extends CreateEditInlineFormBased {
    callback: (action: InnerPageActionMode, value?: any) => void;
    resetState: number;
}

const MRGroupConfigEditPage = (props: MRGroupConfigEditPageProps) => {
    const isAddAction = useMemo(() => props.mode === InnerPageActionMode.CREATE_NEW, [props.mode]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [configForm] = Form.useForm();
    const [configData, setConfigData] = useState<any>({ mrLPAccounts: [], mrPKServers: [] });

    const onSubmit = useCallback(
        (values: any) => {
            if (isEmptyOrNull(values.mrBrands)) {
                message.warning("Please add at lease one MR Brand");
                return;
            }

            const transformedArray = values.lpAccounts.map((item: any) => {
                if (typeof item === "string") {
                    const [lp, accountId] = item.split("-");
                    return { lp, accountId };
                } else {
                    const [lp, accountId] = item.value.split("-");
                    return { lp, accountId };
                }
            });
            const submitParams = {
                ...values,
                lpAccounts: transformedArray,
                mrBrands: values.mrBrands.map((mrBrand: any) => ({
                    ...mrBrand,
                    pkServers: mrBrand.pkServers.map((pkServer: any) => ({ serverName: pkServer.value })),
                })),
            };

            if (isAddAction) {
                apiRequest(APIs.CREATE_MR_GROUP, submitParams)
                    .then(res => {
                        ErrorMessageHandler("MR Group configuration", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                        setTimeout(() => props.callback && props.callback(InnerPageActionMode.BACK, { refreshMainList: true }), 400);
                    })
                    .catch(error =>
                        ErrorCatchValidator(error, (err: any) =>
                            ErrorMessageHandler("MR Group configuration", SUCCESS_FAILED.FAILED_CREATE_DATA, err)
                        )
                    );
            } else {
                apiRequest(APIs.UPDATE_MR_GROUP, submitParams)
                    .then(res => {
                        ErrorMessageHandler("MR Group configuration", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                        setTimeout(() => props.callback && props.callback(InnerPageActionMode.BACK, { refreshMainList: true }), 400);
                    })
                    .catch(error =>
                        ErrorCatchValidator(error, (err: any) =>
                            ErrorMessageHandler("MR Group configuration", SUCCESS_FAILED.FAILED_UPDATE_DATA, err)
                        )
                    );
            }
        },
        [isAddAction]
    );

    const fillForm = useCallback(
        (data?: MRGroupList) => {
            if (isEmptyOrNull(data)) {
                configForm.resetFields();
            } else {
                let currentData = data as MRGroupList,
                    tmp: any = {
                        marketGroupId: currentData.marketGroupId,
                        marketGroup: currentData.marketGroup,
                        isEnabled: currentData.isEnabled,
                        lpAccounts: currentData.lpAccounts
                            .sort((a, b) => a.lp.localeCompare(b.lp))
                            .map((x: any) => ({ text: `${x.lp}-${x.accountId}`, value: `${x.lp}-${x.accountId}` })),
                        mrBrands: currentData.mrBrands.map((x: any) => ({
                            marketBrand: x.marketBrand,
                            pkServers: x.pkServers
                                .sort((a: { showName: string }, b: { showName: string }) => a.showName.localeCompare(b.showName))
                                .map((y: any) => ({ text: `${y.showName}`, value: `${y.serverName}` })),
                        })),
                    };

                configForm.setFieldsValue(tmp);
            }
        },
        [configData]
    );

    const getMRConfigDetails = (id?: number | string | undefined) => {
        if (isEmptyOrNull(id)) {
            fillForm(undefined);
            setIsLoading(false);
            return;
        }

        setIsLoading(true);
        apiRequest(APIs.GET_MR_GROUP_LIST, { marketGroupIds: [id as number] })
            .then((res: any) => {
                if (res.length > 0) {
                    fillForm(res[0]);
                } else {
                    ErrorMessageHandler("MR group configuration", SUCCESS_FAILED.FAILED_LOAD_DATA);
                }
            })
            .catch(error => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("MR group configuration", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                props.callback && props.callback(InnerPageActionMode.BACK);
            })
            .finally(() => setIsLoading(false));
    };

    const getConfigList = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, {
            filterType: ["marketrisklpaccount", "marketriskpkserver"],
        })
            .then((data: any) => {
                setConfigData({
                    mrLPAccounts: data.mrLPAccount
                        .sort((a: { lp: string }, b: { lp: string }) => a.lp.localeCompare(b.lp))
                        .map((x: any) => ({ text: `${x.lp}-${x.accountId}`, value: `${x.lp}-${x.accountId}` })),
                    mrPKServers: data.mrPkServer
                        .sort((a: { showName: string }, b: { showName: string }) => a.showName.localeCompare(b.showName))
                        .map((x: any) => ({ text: `${x.showName}`, value: `${x.serverName}` })),
                });
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => console.log("Failed to get filter config list: ", err)))
            .finally(() => setIsLoading(false));
    };

    useEffect(() => getMRConfigDetails(props.id), [props.resetState]);
    useEffect(() => getConfigList(), []);

    return (
        <SitePageHeaderInline
            enableSubmit
            title={isAddAction ? "Create New MR Group Configuration" : "Edit MR Group Configuration"}
            callback={(action: SitePageHeaderInlineActionType) => {
                switch (action) {
                    case SitePageHeaderInlineActionType.OnBack:
                    case SitePageHeaderInlineActionType.OnCancel:
                        props.callback && props.callback(InnerPageActionMode.BACK);
                        configForm.resetFields();
                        return;
                    case SitePageHeaderInlineActionType.OnSubmit:
                        configForm
                            .validateFields()
                            .then(res => onSubmit(res))
                            .catch(err => console.log("form-error:", err));
                        return;
                    default:
                        break;
                }
            }}
        >
            {isLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Loading..." />
                </div>
            ) : (
                <div className="lpmonitor-create-edit-form">
                    <Form labelAlign="left" labelCol={{ span: 7 }} form={configForm} layout="horizontal" initialValues={{}} onFinish={onSubmit}>
                        <FormComponent label="" name="marketGroupId" extra={{ type: ComponentType.hidden, value: "" }} />
                        <Row>
                            <Col span={11} className="main-panel-left">
                                <FormComponent
                                    label="Group Name"
                                    name="marketGroup"
                                    extra={{ type: ComponentType.text, value: "", rules: [{ required: true, message: REQUIRED_FIELD }] }}
                                />
                                <FormComponent
                                    label="LP Accounts"
                                    name="lpAccounts"
                                    extra={{
                                        type: ComponentType.dropdown,
                                        value: configData.mrLPAccounts,
                                        rules: [{ required: true, message: REQUIRED_FIELD }],
                                        inputProps: {
                                            mode: "multiple",
                                        },
                                    }}
                                />

                                <FormComponent
                                    label="Status"
                                    name="isEnabled"
                                    extra={{ type: ComponentType.switch, value: ["Inactive", "Active"] }}
                                />
                            </Col>
                            <Col span={13} className="main-panel-right">
                                <MRBrandPKServerPanel form={configForm} configData={configData} isAddAction={isAddAction} />
                            </Col>
                        </Row>
                    </Form>
                </div>
            )}
        </SitePageHeaderInline>
    );
};

export default MRGroupConfigEditPage;
