import BasicFilter from "@/components/BasicFilter";
import FlexiDataTable from "@/components/FlexiDataTable";
import { FormComponent } from "@/components/FormComponent";
import { CALLBACK_KEY, ComponentType } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { CustomPaginationProps, FlexiDataTableCallbackProps } from "@/constants/type";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { DTColProps } from "@/utils/Common";
import { DownloadOutlined, EditOutlined, ExclamationCircleOutlined, WarningOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, message, Modal, Row, Tooltip } from "antd";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { ReadOnlyTableFilterParamsProps, ReadOnlyTableProps } from "../type";

interface ReadOnlySyndicateTradingData {
    id: number;
    batchNum: number;
    cmd: number;
    createTime: string;
    optUserId: number;
    optUserName: string | null;
    readOnly: number;
    serverType: string;
    symbolBasic: string;
    updateTime: string;
    updateTimeStr: string;
};

const currentDateTime: ReadOnlyTableFilterParamsProps = {
    startDate: moment().format("YYYY-MM-DD HH:mm"),
    endDate: moment().format("YYYY-MM-DD HH:mm")
};

const ReadOnlyTable = (props: ReadOnlyTableProps) => {
    const [data, setData] = useState<ReadOnlySyndicateTradingData[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isExporting, setIsExporting] = useState<boolean>(false);
    const [filterForm] = Form.useForm();
    const [pagination, setPagination] = useState<CustomPaginationProps>({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const refreshState = useMemo(() => props.refreshState, [props.refreshState]);
    const [defaultDates, setDefaultDates] = useState<ReadOnlyTableFilterParamsProps>(currentDateTime);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [enableUpdate] = useState<boolean>(props.ableToEdit);
    const [filterParams, setFilterParams] = useState<any>({});

    const colsProps = {
        xl: 8,
        lg: 12,
        md: 12,
        sm: 24,
        style: { marginBottom: "1.414vh" },
    };

    const columns: any[] = useMemo(() => [
        DTColProps.XSmall({
            title: "Batch Number",
            dataIndex: "batchNum",
            key: "batchNum",
            fixed: "left",
            sorter: (a: any, b: any) => a.batchNum - b.batchNum,
        }),
        DTColProps.Small({
            title: "Symbol Basic",
            dataIndex: "symbolBasic",
            key: "symbolBasic",
            sorter: (a: any, b: any) => a.symbolBasic.localeCompare(b.symbolBasic),
        }),
        DTColProps.XXSmall({
            title: "Side",
            dataIndex: "cmd",
            key: "cmd",
            sorter: (a: any, b: any) => a.cmd - b.cmd,
            render: (cmd: number) => cmd === 0 ? "Buy"
                : cmd === 1 ? "Sell"
                    : cmd,
        }),
        DTColProps.Small({
            title: "Time",
            dataIndex: "updateTimeStr",
            key: "updateTimeStr",
            sorter: (a: any, b: any) => a.updateTimeStr.localeCompare(b.updateTimeStr),
        }),
        DTColProps.Small({
            title: "Operator",
            dataIndex: "optUserName",
            key: "optUserName",
            sorter: (a: any, b: any) => {
                if (!a.optUserName && !b.optUserName) return 0;
                if (!a.optUserName) return 1;
                if (!b.optUserName) return -1;
                return a.optUserName.localeCompare(b.optUserName);
            },
        }),
        ...(enableUpdate ? [
            DTColProps.XXSmall({
                title: "Action",
                dataIndex: "action",
                key: "action",
                fixed: "right",
                render: (_: any, rowData: any) => (
                    <Tooltip title="Cancel ReadOnly">
                        <Button
                            key={`r-${rowData.batchNum}-e`}
                            type="text"
                            icon={<EditOutlined style={{ fontSize: "1.125rem" }} />}
                            onClick={e => {
                                e.preventDefault();
                                componentCallback(CALLBACK_KEY.DO_EDIT, rowData);
                            }}
                        />
                    </Tooltip>
                ),
            },
                ["text-center"]
            ),
        ] : []),
    ], [enableUpdate]);

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.HANDLE_PAGINATION_SORTING:
                if (FormData.pagination.current !== pagination.current || FormData.pagination.pageSize !== pagination.pageSize) {
                    setPagination((prev) => ({ ...prev, current: FormData.pagination.current, pageSize: FormData.pagination.pageSize }));
                    setRunRefetchDataList(true);
                };
                break;
            case CALLBACK_KEY.DO_EDIT:
                Modal.confirm({
                    title: "Are you sure you want to cancel ReadOnly?",
                    content: `Batch Number: ${FormData.batchNum}`,
                    icon: <WarningOutlined />,
                    onOk: () => {
                        plainAxiosInstance
                            .get(`${APIs.RC_SYNDICATE_TRADING.GET_CANCEL_READ_ONLY}?batchId=${FormData.batchNum}`)
                            .then(res => {
                                const data: any = res.data;
                                if (data.code === 0) {
                                    Modal.success({
                                        title: "Successfully cancel readonly",
                                        content: data.value ? data.value : "",
                                    });
                                    setRunRefetchDataList(true);
                                } else {
                                    Modal.error({
                                        icon: <ExclamationCircleOutlined />,
                                        title: "Failed to cancel readonly",
                                        content: data.desc ? data.desc : "",
                                    });
                                };
                            })
                            .catch(err => {
                                message.error(`Failed to cancel readonly: ${err}`, 3);
                            });
                    },
                    onCancel: () => { },
                })
                break;
            default:
                break;
        };
    };

    const onFinished = (values: any) => {
        let tmpFP: any = {};
        Object.keys(values)
            .filter(x => (values[x] === undefined ? false : values[x].toString().length > 0))
            .forEach(x => {
                if (x === "updateTimeStr") {
                    tmpFP["startDate"] = values[x][0].format("YYYY-MM-DD HH:mm");
                    tmpFP["endDate"] = values[x][1].format("YYYY-MM-DD HH:mm");
                } else {
                    tmpFP[x] = values[x];
                };
            });
        setFilterParams(tmpFP);
        setRunRefetchDataList(true);
    };

    const getReadOnlyData = () => {
        let current = pagination.current === undefined ? 1 : pagination.current;
        let pageSize = pagination.pageSize === undefined ? 10 : pagination.pageSize;

        setIsLoading(true);
        plainAxiosInstance
            .post(`${APIs.RC_SYNDICATE_TRADING.POST_READ_ONLY_DATA}`, {
                limit: pageSize,
                offset: (current - 1) * pageSize,
            })
            .then(res => {
                const data: any = res.data;
                if (res.status === 200) {
                    setData(data.rows);
                    setPagination((prev) => ({ ...prev, total: data.total }));
                } else {
                    setData([]);
                    setPagination((prev) => ({ ...prev, total: 0 }));
                };
            })
            .catch(err => {
                message.error(`Failed to get ReadOnly data: ${err}`, 3);
                setData([]);
                setPagination((prev) => ({ ...prev, total: 0 }));
            })
            .finally(() => setIsLoading(false));
    };

    const getDownloadTime = () => {
        setIsExporting(true);
        plainAxiosInstance
            .get(`${APIs.RC_SYNDICATE_TRADING.GET_DOWNLOAD_TIME}`)
            .then(res => {
                const data: any = res.data;
                if (res.status === 200) {
                    filterForm.setFieldValue("updateTimeStr", [moment(data.startTime), moment(data.endTime)]);
                    setDefaultDates({
                        startDate: moment(data.startTime).format("YYYY-MM-DD HH:mm"),
                        endDate: moment(data.endTime).format("YYYY-MM-DD HH:mm"),
                    });
                } else {
                    filterForm.setFieldValue("updateTimeStr", [moment(), moment()]);
                    setDefaultDates(currentDateTime);
                };
            })
            .catch(err => {
                message.error(`Failed to get download time: ${err}`, 3);
            })
            .finally(() => setIsExporting(false));
    };

    const exportCSV = () => {
        setIsExporting(true);
        plainAxiosInstance
            .get(`${APIs.RC_SYNDICATE_TRADING.GET_READ_ONLY_DOWNLOAD}?startTime=${filterParams.startDate}&endTime=${filterParams.endDate}`, {
                headers: {
                    Accept: "application/octet-stream, */*",
                },
                responseType: "blob",
            })
            .then(res => {
                const fileName = `Trading_Together_ReadOnly_Record-${moment().format("YYYYMMDDHHmmss")}.xlsx`;
                const contentType = res.headers["content-type"];
                if (
                    contentType === "application/octet-stream" ||
                    contentType === "text/csv" ||
                    contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ) {
                    // Handle the file download response
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", (`${fileName}`)); // or any other extension
                    link.setAttribute("type", "hidden");
                    document.body.appendChild(link);
                    link.click();
                    if (link.parentNode) {
                        link.parentNode.removeChild(link); // Clean up and remove the link
                    } else document.body.removeChild(link);
                    // Clean up
                    window.URL.revokeObjectURL(url);
                } else {
                    message.error(`Received non-file response. Error: ${res}`, 3);
                }
            })
            .catch(err => {
                message.error(`Download error: ${err}`, 3);
            })
            .finally(() => setIsExporting(false));
    };

    useEffect(() => {
        if (refreshState) {
            getReadOnlyData();
        };
        if (runRefetchDataList) {
            getReadOnlyData();
            setRunRefetchDataList(false);
        };
    }, [refreshState, runRefetchDataList]);

    useEffect(() => {
        getDownloadTime();
        getReadOnlyData();
    }, []);

    return (
        <div>
            <BasicFilter
                titleBarChildren={
                    <>
                        <Button
                            htmlType="button"
                            style={{ marginLeft: "0.651vw", width: "auto" }}
                            icon={<DownloadOutlined style={{ fontSize: "0.875rem" }} />}
                            onClick={event => {
                                event.preventDefault();
                                event.stopPropagation();
                                exportCSV();
                            }}
                            disabled={!enableUpdate}
                            loading={isExporting}
                        >
                            Download
                        </Button>
                    </>
                }
            >
                <Form form={filterForm} layout="vertical" onFinish={onFinished} initialValues={{ defaultDates }}>
                    <Row gutter={24} style={{ width: "100%" }}>
                        <Col {...colsProps}>
                            <FormComponent
                                label="Update Time"
                                name="updateTimeStr"
                                extra={{
                                    type: ComponentType.daterange,
                                    value: [],
                                    rules: [{ required: true, message: `${REQUIRED_FIELD} in order to download record` }],
                                    inputProps: {
                                        showTime: { format: "HH:mm" },
                                        format: "YYYY-MM-DD HH:mm",
                                    },
                                }}
                            />
                        </Col>
                    </Row>
                    <Divider orientation="left" className="divider-nogap"></Divider>
                    <Row style={{ width: "100%" }}>
                        <Col span={24} key="flt-f-c-btns" className="flt-f-c-btns">
                            <Button
                                type="default"
                                onClick={e => {
                                    e.preventDefault();
                                    filterForm.resetFields();
                                    filterForm.setFieldValue("updateTimeStr", [moment(defaultDates.startDate), moment(defaultDates.endDate)]);
                                }}
                            >
                                Reset
                            </Button>
                            <Button type="primary" htmlType="submit">
                                Search
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </BasicFilter>
            <FlexiDataTable
                bordered
                rowKeyProperty="batchNum"
                title={false}
                columns={columns}
                options={{
                    enableFilter: false,
                    showHideColumns: false,
                }}
                dataSource={data}
                callback={componentCallback}
                loading={isLoading}
                serverSide={true}
                pagination={pagination}
            />
        </div>
    );
};

export default ReadOnlyTable;