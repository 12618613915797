import { FormComponent } from "@/components/FormComponent";
import LoadingComponent from "@/components/Loading";
import SitePageHeader from "@/components/PageHeader";
import { SUCCESS_FAILED, ComponentType } from "@/constants";
import { apiRequest } from "@/services/apiConfig";
import { APIs } from "@/services/apis";
import { ErrorMessageHandler, ErrorCatchValidator } from "@/utils/Common";
import { HomeOutlined } from "@ant-design/icons";
import { Form, Row, Col, Button } from "antd";
import { useState, useEffect, useCallback, useMemo } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { isEmptyOrNull, defaultIfEmptyOrNull } from "@/utils/string";
import { FilterOptionsProps } from ".";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import BrandMappingComponent from "./components/brandMappingComponent";
import BridgeMappingComponent from "./components/bridgeMappingComponent";
import { KeyValuePair } from "@/constants/type";
import { SortList, ToObjectWithKey } from "@/utils/array";
import { DefaultIfEmpty, objectRemoveProps } from "@/utils/object";

export interface ServerV2CreateEditPageProps {}

type ServerCreateEditPageState = {
    action: string;
    data: any;
};

interface CreateFilterOptionsProps extends FilterOptionsProps {
    bridges: KeyValuePair[];
    brands: KeyValuePair[];
}

const ServerV2CreateEditPage = (props: ServerV2CreateEditPageProps) => {
    let navigate = useNavigate();
    let location = useLocation();
    let com_state: ServerCreateEditPageState = location.state as ServerCreateEditPageState;
    let { id } = useParams();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [filterOptions, setFilterOptions] = useState<CreateFilterOptionsProps>({
        bridges: [],
        brands: [],
        serverTypes: [],
        serverActor: [],
        serverUseCase: [],
    });
    const [serverTypes, setServerTypes] = useState<any>({});
    const [serverForm] = Form.useForm();

    const stateInfo = useMemo(() => {
        let tmpState = Object.assign({ action: "add", data: null }, com_state || {});
        return {
            isAddAction: isEmptyOrNull(id) ? tmpState.action === "add" : false,
            data: defaultIfEmptyOrNull(tmpState.data, id),
        };
    }, [com_state, id]);

    const dataConverter = useCallback(
        (fromAPI: boolean, data: any) => {
            if (fromAPI) {
                return {
                    ...objectRemoveProps(data, ["brandServerSettings"]),
                    brandServerSettings: data.brandServerSettings.map((x: any) => ({ ...x, enable: true })),
                };
            }
            return {
                ...objectRemoveProps(data, stateInfo.isAddAction ? ["refServerId", "serverId", "sourceId"] : []),
                sourceType: DefaultIfEmpty(serverTypes, data.serverTypeId, ""),
                ...(!stateInfo.isAddAction && {
                    brandServerSettings: data.brandServerSettings.map((x: any) => ({ ...x, serverId: data.serverId })),
                    bridgeServerSettings: data.bridgeServerSettings.map((x: any) => ({ ...x, serverId: data.serverId })),
                }),
            };
        },
        [stateInfo, serverTypes]
    );

    const getServerById = useCallback(() => {
        setIsLoading(true);
        apiRequest(APIs.GET_SERVERS_DETAIL_V2, { serverId: stateInfo.data })
            .then((data: any) => {
                serverForm.setFieldsValue(dataConverter(true, data));
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("server", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                navigate("/siteadmin/brandserver/server");
            })
            .finally(() => setIsLoading(false));
    }, [stateInfo, dataConverter]);

    const onSubmit = useCallback(
        (values: any) => {
            setIsLoading(true);
            if (stateInfo.isAddAction) {
                apiRequest(APIs.CREATE_SERVER_V2, dataConverter(false, values))
                    .then(data => {
                        ErrorMessageHandler("new server", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                        navigate("/siteadmin/brandserver/server");
                    })
                    .catch(error => {
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("new server", SUCCESS_FAILED.FAILED_CREATE_DATA, err));
                        setIsLoading(false);
                    });
            } else {
                apiRequest(APIs.UPDATE_SERVER_V2, dataConverter(false, values))
                    .then(data => {
                        ErrorMessageHandler("existing server", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                        navigate("/siteadmin/brandserver/server");
                    })
                    .catch(error => {
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("existing server", SUCCESS_FAILED.FAILED_UPDATE_DATA, err));
                        setIsLoading(false);
                    });
            }
        },
        [navigate, stateInfo, dataConverter]
    );

    const getConfig = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, {
            filterType: [
                "riskinsightservertype",
                "riskinsightserveractor",
                "riskinsightserverusecase",
                "brand",
                "riskinsightbridge",
                "riskInsightServer",
            ],
        }).then((res: any) => {
            setFilterOptions({
                bridges: SortList(
                    res.riskInsightBridges.map((x: any) => ({ text: x.bridgeDisplayName, value: x.bridgeId })),
                    "text"
                ),
                brands: SortList(
                    res.brands.map((x: any) => ({ text: x.brand, value: x.id })),
                    "text"
                ),
                serverTypes: SortList(
                    res.riskInsightServerTypes.map((x: any) => ({ text: x.serverType, value: x.serverTypeId })),
                    "text"
                ),
                serverActor: SortList(
                    res.riskInsightServerActors.map((x: any) => ({ text: x.serverActor, value: x.serverActorId })),
                    "text"
                ),
                serverUseCase: SortList(
                    res.riskInsightServerUseCases.map((x: any) => ({ text: x.serverUseCase, value: x.serverUseCaseId })),
                    "text"
                ),
            });
            setServerTypes(ToObjectWithKey(res.riskInsightServerTypes, "serverTypeId", "sourceTypeId"));
        });
    };

    useEffect(() => {
        if (stateInfo.isAddAction) {
            setIsLoading(false);
        } else {
            getServerById();
        }
    }, [stateInfo]);

    useEffect(() => {
        getConfig();

        return () => {};
    }, []);

    return (
        <SitePageHeader
            title={stateInfo.isAddAction ? "Create New Server" : "Edit Server"}
            routes={[
                {
                    path: "/siteadmin/brandserver/server",
                    breadcrumbName: "Server Listing",
                    icon: <HomeOutlined />,
                },
                {
                    path: "",
                    breadcrumbName: stateInfo.isAddAction ? "Create New Server" : "Edit Server",
                },
            ]}
            onBack={() => navigate("/siteadmin/brandserver/server")}
        >
            {isLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Submitting..." />
                </div>
            ) : (
                <Form
                    colon={false}
                    labelAlign="left"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    form={serverForm}
                    layout="horizontal"
                    initialValues={stateInfo.data}
                    onFinish={onSubmit}
                >
                    <Row className="server-page-v2-createedit-container">
                        <Col span={8} className="left">
                            <FormComponent label={""} name={"serverId"} extra={{ type: ComponentType.hidden, value: "" }} />
                            <FormComponent label={""} name={"sourceId"} extra={{ type: ComponentType.hidden, value: "" }} />
                            <FormComponent label={""} name={"refServerId"} extra={{ type: ComponentType.hidden, value: "" }} />
                            <div className="title-container">
                                <span>General Settings</span>
                            </div>
                            <FormComponent
                                label={"Server Unique No. "}
                                name={"serverUno"}
                                extra={{
                                    type: ComponentType.number,
                                    value: "",
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                    ...(!stateInfo.isAddAction && {
                                        inputProps: { disabled: true },
                                    }),
                                }}
                            />
                            <FormComponent
                                label={"Server Name"}
                                name={"serverName"}
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                    ...(!stateInfo.isAddAction && {
                                        inputProps: { disabled: true },
                                    }),
                                }}
                            />
                            <FormComponent
                                label={"Server Display Name"}
                                name={"serverDisplayName"}
                                extra={{ type: ComponentType.text, value: "", rules: [{ required: true, message: REQUIRED_FIELD }] }}
                            />
                            <FormComponent
                                label={"Server Type"}
                                name={"serverTypeId"}
                                extra={{
                                    type: ComponentType.dropdown,
                                    value: filterOptions.serverTypes,
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                            <FormComponent
                                label={"Server Actor"}
                                name={"serverActorId"}
                                extra={{
                                    type: ComponentType.dropdown,
                                    value: filterOptions.serverActor,
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                            <FormComponent
                                label={"Server Use Case"}
                                name={"serverUseCaseId"}
                                extra={{
                                    type: ComponentType.dropdown,
                                    value: filterOptions.serverUseCase,
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                            <FormComponent
                                label={"Comment"}
                                name={"comment"}
                                extra={{
                                    type: ComponentType.textarea,
                                    value: "",
                                    inputProps: {
                                        row: 8,
                                    },
                                }}
                            />
                            <FormComponent
                                label={"Status"}
                                name={"enable"}
                                extra={{
                                    type: ComponentType.switch,
                                    value: ["Inactive", "Active"],
                                }}
                            />
                        </Col>
                        <Col span={16} className="right">
                            <div className="title-container">
                                <span>Brand Mapping</span>
                            </div>
                            <BrandMappingComponent brands={filterOptions.brands} />
                            <div className="title-container" style={{ marginTop: "3.535vh" }}>
                                <span>Bridge Mapping</span>
                            </div>
                            <BridgeMappingComponent bridges={filterOptions.bridges} />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "24px" }}>
                        <Col span={24}>
                            <div className="step-btns-group">
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            )}
        </SitePageHeader>
    );
};

export default ServerV2CreateEditPage;
