import { BackTop, Badge, Button, Col, Layout, Row } from "antd";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import NavigationBar from "../components/NavigationBar";
import {
    ArrowRightOutlined,
    LeftOutlined,
    LoginOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    RightOutlined,
    SwapRightOutlined,
    ToolOutlined,
    UpOutlined,
} from "@ant-design/icons";
import { getAppConfig, userInfo } from "../services/api";
import { useAppDispatch, useAppSelector } from "../store/hook";
import { setSideBarCollapse } from "../store/reducers/system";
import Notifications from "../components/Notifications";
import { GlobalProvider } from "../GlobalProvider";
import SettingButton from "../components/SettingButton";
import ElasticSearchInput from "../components/ElasticSearchInput";
import { apiRequest, APIs } from "../services/apiConfig";
import { getNavigationUrl, ACCOUNT_PROFILE_FROM_MODULE, getAccountProfileParams } from "../pages/Analysis/AccountProfileHelper";
import { useEffect, useRef } from "react";
import { KeyValuePair } from "../constants/type";
import { getMonitorProfilePrefetchList } from "../pages/RealTime/Trade/TradeMonitoring";
import { getAppSettings, getRefreshToken, getToken, updateAppSettings } from "../services/localStorage";
import { cancelPreviousPageRequests } from "../services/axiosSetup";
import WorldTime from "../components/WorldTime";
import AuthHelper, { AuthKeys } from "../helpers/authHelper";
import RC_Logo from "../assets/images/rc_logo.png";
import { KBarProvider } from "kbar";
import { useKBarActions } from "@/services/kbar/kbaractions";

const { Content } = Layout;

const AuthLayout = () => {
    let navigate = useNavigate();

    const authHp = new AuthHelper();
    const userinfo = userInfo();
    const systemState = useAppSelector((state: any) => state.system);
    const dispatch = useAppDispatch();

    const rcURL: string = process.env.REACT_APP_RC_APP || "";
    const rtURL: string = process.env.REACT_APP_RISK_TOOL_APP || "";
    let previousPath = "";
    let currentPath = "";
    const location = useLocation();
    const prevPathRef = useRef(previousPath);
    const currPathRef = useRef(currentPath);

    useEffect(() => {
        if (!prevPathRef.current && !currPathRef.current) {
            prevPathRef.current = location.pathname;
            currPathRef.current = location.pathname;
        } else {
            prevPathRef.current = currPathRef.current;
            currPathRef.current = location.pathname;
        }
        if (prevPathRef.current !== currPathRef.current) {
            cancelPreviousPageRequests(prevPathRef.current);
        }
        return () => { };
    }, [location.pathname]);

    const onSelect = (value: any) => {
        navigate(value);
        window.scrollTo(0, 0);
    };

    const getAccountList = (searchText: string) => {
        if (searchText && searchText.length > 0) {
            return new Promise(resolve => {
                apiRequest(APIs.GET_ACCOUNT_SEARCH, { accountId: searchText }).then((res: any) => {
                    let returnResult: any[] = [];
                    if (res && res.length > 0) {
                        returnResult = [
                            {
                                label: <span>Accounts</span>,
                                options: res.map((x: any) => ({
                                    label: (
                                        <div className="els-account-container">
                                            <div>
                                                <span>ID: {x.accountId}</span>
                                                <span>Server: {x.server}</span>
                                            </div>
                                        </div>
                                    ),
                                    value: getNavigationUrl(
                                        ACCOUNT_PROFILE_FROM_MODULE.ACCOUNT_PROFILE_LIST,
                                        getAccountProfileParams(
                                            x.serverId,
                                            x.accountId,
                                            ACCOUNT_PROFILE_FROM_MODULE.ACCOUNT_PROFILE_LIST,
                                            "",
                                            "",
                                            "",
                                            "",
                                            ""
                                        )
                                    ),
                                })),
                            },
                        ];
                    }
                    resolve(returnResult);
                });
            });
        }
        return Promise.resolve([]);
    };

    useEffect(() => {
        getMonitorProfilePrefetchList().then((res: any) => {
            updateAppSettings({
                RELATIVE_TIME_RANGE: res["RELATIVE_TIME_RANGE"] as KeyValuePair[],
                RELATIVE_DATE_RANGE: res["RELATIVE_DATE_RANGE"] as KeyValuePair[],
                SYMBOL_TYPE: res["SYMBOL_TYPE"] as KeyValuePair[],
            });
        });

        // getAppConfig(["PriceOutageConfig"]).then((value: any) => {
        //     if (value && value.length > 0) {
        //         let findIdx: number = value.findIndex((x: any) => x.key === "PriceOutageConfig");
        //         if (findIdx > -1) {
        //             try {
        //                 updateAppSettings({
        //                     PRICE_OUTAGE_PAGE_CONFIG: { ...getAppSettings().PRICE_OUTAGE_PAGE_CONFIG, ...JSON.parse(value[findIdx].value) },
        //                 });
        //             } catch (error) {}
        //         }
        //     }
        // });
        return () => { };
    }, []);
    const actions = useKBarActions();
    return (
        <GlobalProvider>
            <KBarProvider actions={actions}>
                <Layout className="main-layout" hasSider>
                    <NavigationBar />
                    <Layout style={{
                        marginInlineStart: systemState.sideBarCollapsed ? 80 : 200,
                        transition: "margin-inline-start 0.2s ease-in-out",
                    }}>
                        {/** HEADER */}
                        <div className="header">
                            <div className="left-panel">
                                {/* <Affix offsetTop={20}> */}
                                <div className="collapse-btn" onClick={() => dispatch(setSideBarCollapse())}>
                                    <Button
                                        type="primary"
                                        shape="circle"
                                        size="small"
                                        icon={systemState.sideBarCollapsed ? <RightOutlined /> : <LeftOutlined />}
                                    />
                                </div>
                                {/* <img className="logo" alt={"PNGlogo"} src={PNGlogo} /> */}
                                {/* <PageTitle /> */}
                                {/* </Affix> */}
                                {authHp.isAuthorized(AuthKeys.ANALYSIS_ACCOUNT_PROFILE_VIEW) && (
                                    <ElasticSearchInput
                                        inputType="number"
                                        placeholder={"Fill in Account ID, press [Enter]"}
                                        serverSide
                                        options={getAccountList}
                                        onSelect={onSelect}
                                    />
                                )}
                                <WorldTime />
                            </div>
                            <div className="right-panel">
                                <Row>
                                    {/* <Col className="notification">
                                        <Switch
                                            checked={!systemState.isMuteAlarm}
                                            checkedChildren={
                                                <>
                                                    <AlertFilled /> ON
                                                </>
                                            }
                                            unCheckedChildren={
                                                <>
                                                    <AlertOutlined /> OFF
                                                </>
                                            }
                                            onClick={(checked: boolean) => dispatch(setIsMuteAlarm(!checked))}
                                        />
                                    </Col> */}
                                    <Col className="notification">
                                        <span>
                                            <Notifications />
                                        </span>
                                    </Col>
                                    {/* <Col className="notification">
                                        <span>
                                            <UserManualDrawer />
                                        </span>
                                    </Col> */}
                                    {(rcURL.length > 0 || rtURL.length > 0) && (
                                        <Col className="navigation">
                                            <div className="btn-group">
                                                {rcURL.length > 0 && (
                                                    <Button
                                                        icon={
                                                            <img
                                                                src={RC_Logo}
                                                                alt="Risk Centre"
                                                                style={{ width: "15px", height: "15px", marginRight: "5px" }}
                                                            />
                                                        }
                                                        target="_self"
                                                        href={`${rcURL}?token=${getToken()}&refreshToken=${getRefreshToken()}`}
                                                        style={{ display: "inline-block" }}
                                                    >
                                                        {"Risk Center"} <ArrowRightOutlined />
                                                    </Button>
                                                )}
                                                {rtURL.length > 0 && (
                                                    <Button
                                                        icon={<ToolOutlined />}
                                                        target="_blank"
                                                        href={`${rtURL}`}
                                                        style={{ display: "inline-block" }}
                                                    >
                                                        {"Risk Tool"} <ArrowRightOutlined />
                                                    </Button>
                                                )}
                                            </div>
                                        </Col>
                                    )}
                                    <Col className="user-info-panel">
                                        <span>{userinfo?.username || ""}</span>
                                        <span>
                                            <Badge
                                                status={systemState.isWsConnected ? "success" : "error"}
                                                text={systemState.isWsConnected ? "Online" : "Offline"}
                                                className="badge-blinking"
                                            />
                                        </span>
                                    </Col>
                                    <Col>
                                        <SettingButton />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <Content>
                            <div className="main-content-div">
                                <Outlet />
                            </div>
                            <BackTop className="back-to-top-container">
                                <UpOutlined />
                            </BackTop>
                        </Content>
                    </Layout>
                </Layout>
            </KBarProvider>
        </GlobalProvider>
    );
};

export default AuthLayout;
