import { useEffect, useState } from "react";
import { Button, Collapse, Divider, Form, message, Modal } from "antd";
import FlexiDataTable from "@/components/FlexiDataTable";
import { currencyRender, DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { FlexiDataTableOptionsProps, KeyValuePair } from "@/constants/type";
import { FormComponent } from "@/components/FormComponent";
import { ComponentType, SUCCESS_FAILED } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import moment from "moment";
import { DateTimeUtil } from "@/utils/datetime";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { APIs } from "@/services/apis";
import { LineChartOutlined } from "@ant-design/icons";
import MidBiasLineChart from "./MidBiasLineChart";

interface HistoricalMidBiasData {
    dateTime: string;
    dateTimeStr: string;
    server: string;
    serverUno: number;
    symbol: string;
    cleanSymbol: string;
    symbolType: number;
    compareServer: string;
    cserverUno: number;
    compareSymbol: string;
    alarmType: number;
    scoreSum: number;
    scoreMax: number;
    ppmcc: number;
    solved: number;
    solvedTime: string;
    solvedTimeStr: string;
    solvedUser: string;
    evaluate: number;
    evaluateTime: string;
    evaluateTimeStr: string;
    evaluateUser: string;
    showScoreSum?: number;
    showMaxScore?: number;
    showPPMCC?: number;
    newKey?: string;
};

const initialFilterParams = {
    startDate: DateTimeUtil.GetOrigin(moment(), "YYYY-MM-DD"),
    endDate: DateTimeUtil.GetOrigin(moment(), "YYYY-MM-DD"),
};

const HistoryTab = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [goodData, setGoodData] = useState<HistoricalMidBiasData[]>([]);
    const [badData, setBadData] = useState<HistoricalMidBiasData[]>([]);
    const [filterForm] = Form.useForm();
    const [searchDisabled, setSearchDisabled] = useState<boolean>(false);
    const [filterParams, setFilterParams] = useState<any>(initialFilterParams);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [compareServerList, setCompareServerList] = useState<KeyValuePair[]>([]);
    const [biasLineCompareData, setBiasLineCompareData] = useState<any>(null);
    const [biasLineTitle, setBiasLineTitle] = useState<string>("");

    const columns = [
        DTColProps.XXSmall({
            title: "Line",
            dataIndex: "action",
            key: "action",
            fixed: "left",
            render: (action: any, rowData: any) => <Button
                icon={<LineChartOutlined />}
                type="primary"
                onClick={() => showBiasLine(rowData)}
            />
        },
            ["text-center"]
        ),
        DTColProps.Small({
            title: "Date Time",
            dataIndex: "dateTimeStr",
            key: "dateTimeStr",
        }),
        DTColProps.Small({
            title: "Server",
            dataIndex: "server",
            key: "server",
        }),
        DTColProps.Small({
            title: "Symbol",
            dataIndex: "symbol",
            key: "symbol",
        }),
        DTColProps.Small({
            title: "Compare Server",
            dataIndex: "compareServer",
            key: "compareServer",
        }),
        DTColProps.SCurrency(
            {
                title: "Max Score",
                dataIndex: "showMaxScore",
                key: "showMaxScore",
            },
            [],
            -1,
        ),
        DTColProps.SCurrency(
            {
                title: "Score Sum",
                dataIndex: "showScoreSum",
                key: "showScoreSum",
            },
            [],
            -1,
        ),
        DTColProps.Small({
            title: "Correlation",
            dataIndex: "showPPMCC",
            key: "showPPMCC",
        },
            ["text-right"]
        ),
        DTColProps.Small({
            title: "Evaluate User",
            dataIndex: "evaluateUser",
            key: "evaluateUser",
        },
            ["text-right"]
        ),
        DTColProps.Small({
            title: "Evaluate Time",
            dataIndex: "evaluateTimeStr",
            key: "evaluateTimeStr",
            fixed: "right",
        },
            ["text-right"]
        ),
    ];

    const options: FlexiDataTableOptionsProps = {
        enableFilter: false,
        recordRowClassName: (record: any) => {
            return record.ppmcc < 80 || record.scoreMax > 15
                ? "row-red"
                : record.ppmcc < 95 && record.ppmcc > 80 && (record.scoreSum > 50 || record.scoreMax > 8)
                    ? "row-yellow"
                    : "";
        },
    };

    const onFinished = (values: any) => {
        let fParams: any = {};
        Object.keys(values)
            .filter((x) => values[x] !== undefined && values[x].toString().length > 0)
            .map((x) => {
                if (x === "dateValue") {
                    if (values[x] === null) return false;
                    else {
                        fParams["startDate"] = DateTimeUtil.GetOrigin(values[x][0], "YYYY-MM-DD");
                        fParams["endDate"] = DateTimeUtil.GetOrigin(values[x][1], "YYYY-MM-DD");
                    };
                } else {
                    fParams[x] = values[x];
                };
                return x;
            });
        setFilterParams(fParams);
        setRunRefetchDataList(true);
    };

    const showBiasLine = async (record: any) => {
        const reqStr: string = `${record["dateTimeStr"].substring(0, record["dateTimeStr"].length - 3)},${record["server"]},${record["symbol"]},${record["compareServer"]},${record["compareSymbol"]},${record["serverUno"]}`;
        const formData = new FormData();
        formData.append("keyStr", reqStr);
        const resp = await plainAxiosInstance.post(`${APIs.RC_MIDBIAS.POST_MIDBIAS_CHART}`, formData);
        if (resp?.data) {
            setBiasLineCompareData(resp.data);
            setBiasLineTitle(
                record["server"] + "-" + record["symbol"] + " vs " + record["compareServer"] + "-" + record["compareSymbol"] + " " + record["dateTimeStr"]
            );
        } else {
            message.error("Error: No data found for this record");
        };
    };

    const getConfig = () => {
        plainAxiosInstance.get(APIs.RC_MIDBIAS.GET_COMPARE_SERVERS)
            .then((res: any) => {
                if (res.data && res.data.length > 0) {
                    setCompareServerList(res.data.map((x: any) => ({ text: x.compareServer, value: x.cserverUno })));
                } else {
                    setCompareServerList([]);
                };
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("compare servers list", SUCCESS_FAILED.FAILED_LOAD_DATA, err)));
    };

    const getHistoricalData = () => {
        plainAxiosInstance
            .get(`${APIs.RC_MIDBIAS.GET_MIDBIAS_HISTORICAL_DATA}/${filterParams.startDate}/${filterParams.endDate}/${filterParams.serverUno}`)
            .then((res: any) => {
                if (res.status === 200) {
                    if (res.data.length === 0) {
                        let newData: any = res.data.map((x: HistoricalMidBiasData) => ({
                            ...x,
                            newKey: `${x.server}|${x.symbol}|${x.compareServer}|${x.compareSymbol}|${x.dateTimeStr}`,
                            showScoreSum: currencyRender(x.scoreSum, 2),
                            showMaxScore: currencyRender(x.scoreMax, 2),
                            showPPMCC: currencyRender(x.ppmcc, 2),
                        }));
                        let gData: any = newData.filter((x: any) => x.evaluate === 1);
                        let bData: any = newData.filter((x: any) => x.evaluate === 2);
                        setGoodData(gData);
                        setBadData(bData);
                    } else {
                        setGoodData([]);
                        setBadData([]);
                    };
                };
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => {
                ErrorMessageHandler("mid bias historical data", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                setGoodData([]);
                setBadData([]);
            }))
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            getHistoricalData();
            setRunRefetchDataList(false);
        };
    }, [runRefetchDataList]);

    useEffect(() => {
        getConfig();

        return () => { };
    }, []);

    return (
        <div className="mid-bias-alarm-history-tab">
            <div style={{ padding: "1rem" }}>
                <div className="top-search-panel">
                    <Form
                        form={filterForm}
                        layout="inline"
                        initialValues={{ dateValue: [moment(), moment()] }}
                        onFinish={onFinished}
                    >
                        <FormComponent
                            label="Download Date"
                            name="dateValue"
                            extra={{
                                type: ComponentType.daterange,
                                value: [],
                                rules: [
                                    { required: true, message: REQUIRED_FIELD },
                                    {
                                        validator: async (_, dates) => {
                                            if (dates !== null && dates[1].diff(dates[0], "days") > 32) {
                                                return Promise.reject(new Error("Selected date range should not exceed 32 days"));
                                            };
                                        },
                                    },
                                ],
                                inputProps: {
                                    dateOnly: true,
                                    format: "YYYY-MM-DD",
                                    onCalendarChange: (dates: any) => {
                                        if (dates !== null) {
                                            if (dates[1] && dates[1].diff(dates[0], "days") > 32) {
                                                setSearchDisabled(true);
                                            } else {
                                                setSearchDisabled(false);
                                            };
                                        } else setSearchDisabled(true);
                                    },
                                },
                            }}
                        />
                        <FormComponent
                            label="Compare Server"
                            name="serverUno"
                            extra={{
                                type: ComponentType.dropdown,
                                value: compareServerList,
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                                inputProps: {
                                    style: { width: 250 },
                                },
                            }}
                        />
                        <Divider type="vertical" style={{ height: "auto" }} />
                        <div className="filter-buttons">
                            <Button
                                onClick={() => {
                                    filterForm.resetFields();
                                    setSearchDisabled(false);
                                }}
                            >
                                Reset
                            </Button>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={isLoading}
                                disabled={searchDisabled}
                            >
                                Search
                            </Button>
                        </div>
                    </Form>
                </div>
                <div className="main-content">
                    <Collapse defaultActiveKey={["1", "2"]}>
                        <Collapse.Panel header="We Good" key="1">
                            <FlexiDataTable
                                bordered
                                rowKeyProperty="newKey"  // server|symbol|compareServer|compareSymbol|dateTimeStr
                                title={""}
                                columns={columns}
                                options={options}
                                dataSource={goodData}
                                callback={() => { }}
                                loading={isLoading}
                                pagination={{ defaultPageSize: 50 }}
                            />
                        </Collapse.Panel>
                        <Collapse.Panel header="We Bad" key="2">
                            <FlexiDataTable
                                bordered
                                rowKeyProperty="newKey"  // server|symbol|compareServer|compareSymbol|dateTimeStr
                                title={""}
                                columns={columns}
                                options={options}
                                dataSource={badData}
                                callback={() => { }}
                                loading={isLoading}
                                pagination={{ defaultPageSize: 50 }}
                            />
                        </Collapse.Panel>
                    </Collapse>
                    {biasLineCompareData && (
                        <Modal
                            open={biasLineCompareData !== null}
                            closable={true}
                            onCancel={() => {
                                setBiasLineCompareData(null);
                                setBiasLineTitle("");
                            }}
                            width={1080}
                            footer={null}
                            title={biasLineTitle}
                        >
                            <MidBiasLineChart biaslineCompareData={biasLineCompareData} />
                        </Modal>
                    )}
                </div>
            </div>
        </div>
    );
};

export default HistoryTab;