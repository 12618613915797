import React, { useMemo } from "react";
import AuthHelper, { AuthKeys } from "../../../helpers/authHelper";
import { Tabs } from "antd";
import ToxicClientLabelConfigList from "./LabelConfiguration";
import ToxicClientCheckList from "./CheckList";
import ImportEntryAuditLog from "./ImportEntryAuditLog";
import HistoricalRestrictionAuditLog from "./HistoricalRestrictionAuditLog";
import ReportDashboard from "./ReportDashboard";
import NotificationConfiguration from "./NotificationConfiguration";

const ToxicClientCheck = () => {
    const authHp = new AuthHelper();
    const enableEdit = authHp.isAuthorized(AuthKeys.TOXIC_CLIENT_CHECK_EDIT);
    const tabItems = useMemo(() => {
        let defaultList = [
            {
                label: `Toxic Client Check List`,
                key: "1",
                children: <ToxicClientCheckList />,
            },
            {
                label: `Report Dashboard`,
                key: "2",
                children: <ReportDashboard />,
            },
            {
                label: `Historical Import Audit Log`,
                key: "3",
                children: <ImportEntryAuditLog />,
            },
            {
                label: `Historical Restriction Audit Log`,
                key: "4",
                children: <HistoricalRestrictionAuditLog />,
            }
        ];
        if (enableEdit) {
            defaultList.push(
                {
                    label: `Label Configuration`,
                    key: "5",
                    children: <ToxicClientLabelConfigList />,
                },
                { label: `Notification Configuration`, key: "6", children: <NotificationConfiguration /> }
            );
        };
        return defaultList;
    }, [enableEdit]);

    return (
        <>
            <Tabs type="card" defaultActiveKey="1" destroyInactiveTabPane tabBarStyle={{ marginBottom: "0" }} items={tabItems} />
        </>
    );
};

export default ToxicClientCheck;
