import { create } from "zustand";
import type { IRCDataCenter, IRCDataCenterSettings } from "../hooks/useRCDataCenter";
type RCDataCenterStore = {
    records: IRCDataCenter[];
    hoveredSettings: string[];
    settingsViewMode: "table" | "card";
};
type RCDataCenterActions = {
    updateRecords: (newRecord: IRCDataCenter) => void;
    addHoveredSettings: (
        key: // get key from IRCDataCenterSettings, and put array
        keyof IRCDataCenterSettings
    ) => void;
    removeHoveredSettings: (
        key: // get key from IRCDataCenterSettings, and put array
        keyof IRCDataCenterSettings
    ) => void;
    setSettingsViewMode: (mode: "table" | "card") => void;
};

const useRCDataCenterStore = create<RCDataCenterStore & RCDataCenterActions>(set => ({
    records: [],
    settingsViewMode: "card",
    setSettingsViewMode: mode =>
        set(state => {
            return {
                settingsViewMode: mode,
            };
        }),
    updateRecords: newRecord =>
        // only store latest 20 records
        set(state => ({
            records: [newRecord, ...state.records].slice(0, 20),
        })),
    hoveredSettings: [],
    addHoveredSettings: key =>
        set(state => {
            return {
                hoveredSettings: [...state.hoveredSettings, key],
            };
        }),
    removeHoveredSettings: key =>
        set(state => {
            return {
                hoveredSettings: state.hoveredSettings.filter(k => k !== key),
            };
        }),
}));

export default useRCDataCenterStore;
