import { Bar, Column, DualAxes, Line, Pie } from "@ant-design/plots";
import { Row, Col, Breadcrumb, Tooltip, Button, Descriptions } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";
import { getNavigationUrl, ACCOUNT_PROFILE_FROM_MODULE, getAccountProfileIdByObj } from "../../../pages/Analysis/AccountProfileHelper";
import { ViewCollectionItemProps } from "../../../pages/RealTime/Trade/TradeMonitoringPanel";
import { DTColProps } from "../../../utils/Common";
import { TimerButton } from "../../Common";
import FlexiDataTable from "../../FlexiDataTable";
import cloneDeep from "lodash/cloneDeep";
import { ViewProps, FlexiDataTableCallbackProps } from "../../../constants/type";
import { CALLBACK_KEY, ComponentType } from "../../../constants";
import { FileSearchOutlined } from "@ant-design/icons";
import { findObjectKeys } from "../../../utils/object";
import { getThresholdTemplate } from "../../../pages/RealTime/Trade/TradeMonitoring";
import { getAppSettings } from "../../../services/localStorage";
import { DateTimeUtil } from "../../../utils/datetime";

export interface ChartDisplayTemplateProps {
    configuration: ChartConfigurationProps;
    dataList: any;
    componentCallback: FlexiDataTableCallbackProps;
    isDashboardView?: boolean;
}

export interface ChartConfigurationProps {
    views: ViewProps[];
    name?: string;
    description?: string;
    isLoadingView?: boolean;
    disabledTimer: boolean;
    profileId: number;
    currentViewId: string;
    threshold: string;
    viewConfig: ViewProps;
    viewCollection?: ViewCollectionItemProps[];
}

const ChartDisplayTemplate = (props: ChartDisplayTemplateProps) => {
    const [refreshRate, setRefreshRate] = useState<number>(60);
    const appSetting = getAppSettings();

    const isNextViewAvailable = (views: ViewProps[], currentViewId: string) => {
        let tmp_views: ViewProps[] = [...views];
        tmp_views.sort((a: ViewProps, b: ViewProps) => a.level - b.level);
        let foundIdx = tmp_views.findIndex(x => (x.id || "").toString() === currentViewId);
        return foundIdx + 1 < tmp_views.length;
    };

    const fillEmptyViewData = (oriViewData: any[], chartConfig: any) => {
        let viewData: any[] = cloneDeep(oriViewData);
        let finalViewData: any[] = [];
        if (findObjectKeys(chartConfig, ["fillEmpty"]) && chartConfig.fillEmpty) {
            if (chartConfig.seriesField && chartConfig.seriesField.length > 0) {
                let distinctXField = viewData.reduce((list, x, idx) => {
                    if (x[chartConfig.xField] !== null && list.indexOf(x[chartConfig.xField]) === -1) {
                        list.push(x[chartConfig.xField]);
                    }
                    return list;
                }, []);
                let distinctSeries: string[] = [];
                if (chartConfig.seriesField && chartConfig.seriesField.length) {
                    distinctSeries = viewData.reduce((list: any[], x: any) => {
                        if (x[chartConfig.seriesField] !== null && list.indexOf(x[chartConfig.seriesField]) === -1) {
                            list.push(x[chartConfig.seriesField]);
                        }
                        return list;
                    }, []);
                }

                let distinctGroup: string[] = [];
                if (chartConfig.groupField && chartConfig.groupField.length) {
                    distinctGroup = viewData.reduce((list, x, idx) => {
                        if (x[chartConfig.groupField] !== null && list.indexOf(x[chartConfig.groupField]) === -1) {
                            list.push(x[chartConfig.groupField]);
                        }
                        return list;
                    }, []);
                    distinctGroup.sort();
                }

                let existingData: any = {};
                if (distinctGroup.length > 0) {
                    viewData.map(x => {
                        existingData[`${x[chartConfig.xField]}|${x[chartConfig.seriesField]}|${x[chartConfig.groupField]}`] = x;
                        return false;
                    });
                } else {
                    viewData.map(x => {
                        existingData[`${x[chartConfig.xField]}|${x[chartConfig.seriesField]}`] = x;
                        return false;
                    });
                }

                distinctXField.forEach((x: string) => {
                    if (distinctSeries.length > 0) {
                        distinctSeries.forEach((y: string) => {
                            if (distinctGroup.length > 0) {
                                distinctGroup.forEach((z: string) => {
                                    if (existingData?.hasOwnProperty(`${x}|${y}|${z}`)) {
                                        finalViewData.push(existingData[`${x}|${y}|${z}`]);
                                    } else {
                                        let tmp: any = {};
                                        tmp[chartConfig.xField] = x;
                                        tmp[chartConfig.yField] = 0;
                                        tmp[chartConfig.seriesField] = y;
                                        tmp[chartConfig.groupField] = z;
                                        finalViewData.push(tmp);
                                    }
                                });
                            } else {
                                if (existingData?.hasOwnProperty(`${x}|${y}`)) {
                                    finalViewData.push(existingData[`${x}|${y}`]);
                                } else {
                                    let tmp: any = {};
                                    tmp[chartConfig.xField] = x;
                                    tmp[chartConfig.yField] = 0;
                                    tmp[chartConfig.seriesField] = y;
                                    finalViewData.push(tmp);
                                }
                            }
                        });
                    } else {
                        let tmp: any = {};
                        tmp[chartConfig.xField] = x;
                        tmp[chartConfig.yField] = 0;
                        finalViewData.push(tmp);
                    }
                });

                finalViewData = finalViewData.map((x: any, i: number) => {
                    x["id"] = i;
                    return x;
                });
            }
        } else {
            finalViewData = [...viewData];
        }

        if (finalViewData.length > 0 && findObjectKeys(finalViewData[0], ["time"])) {
            let timeProperties = Object.keys(finalViewData[0]).filter(x => {
                return x.toLowerCase().indexOf("time") > -1 && x.toLowerCase().indexOf("time[[ori]]") === -1;
            });

            finalViewData = finalViewData.map(x => {
                timeProperties.forEach(y => {
                    x[`${y}[[ori]]`] = cloneDeep(x[y]);
                    x[y] = DateTimeUtil.GetUTC(x[y], "YYYY-MM-DD HH:mm:ss");
                });
                return x;
            });
        }

        return finalViewData;
    };

    const onComponentCallback = (type: CALLBACK_KEY, data: any) => {
        switch (type) {
            case CALLBACK_KEY.REFRESH_RATE_CHANGED:
                if (props.configuration.viewConfig.chartType === 0) {
                    setRefreshRate(data.currentRefreshPeriod);
                } else if (props.configuration.viewConfig.chartType !== 0) {
                    setRefreshRate(data);
                }
                break;
            case CALLBACK_KEY.REFRESH:
                props.componentCallback &&
                    props.componentCallback(CALLBACK_KEY.REFRESH, {
                        id: props.configuration.viewConfig.id,
                        isEventRuleView: props.configuration.viewConfig.isEventRuleView,
                        params: "",
                    });
                break;
            case CALLBACK_KEY.ROW_SELECTION_CALLBACK:
                let oriData = data;
                Object.keys(data)
                    .filter(x => x.indexOf("[[ori]]") > -1)
                    .map(x => {
                        oriData[x.replace("[[ori]]", "")] = oriData[x];
                        return false;
                    });
                props.componentCallback &&
                    props.componentCallback(CALLBACK_KEY.ROW_SELECTION_CALLBACK, {
                        profileId: props.configuration.profileId,
                        viewId: props.configuration.viewConfig.id,
                        isEventRuleView: props.configuration.viewConfig.isEventRuleView,
                        params: oriData,
                    });
                break;
            case CALLBACK_KEY.OTHERS:
                props.componentCallback && props.componentCallback(type, data);
                break;
        }
    };

    const getSuitableColumnProps = (key: string, obj: any) => {
        if (key.toLowerCase().indexOf("time") > -1) {
            return DTColProps.Middle({ ...obj, sorter: false });
        } else if (["holding duration"].indexOf(key.toLowerCase()) > -1) {
            return DTColProps.Middle(obj);
        } else {
            return DTColProps.Small(obj);
        }
    };

    const getChartTemplate = (mainConfig: any, config: ViewProps, viewDataList: any) => {
        switch (config.chartType) {
            case 0:
                // DataTable
                if (viewDataList.length < 1) {
                    return (
                        <>
                            <FlexiDataTable
                                rowKeyProperty="id"
                                title={
                                    <div className="title-container table-view">
                                        <span className="title">{config?.name}</span>
                                        <span className="subtitle">{config?.description}</span>
                                    </div>
                                }
                                columns={[]}
                                options={{
                                    enableFilter: false,
                                    refresh: {
                                        timer: true,
                                        refreshSecond: refreshRate,
                                        enablePeriodSelection: true,
                                    },
                                }}
                                callback={(type: CALLBACK_KEY, data: any) =>
                                    onComponentCallback(type, type === CALLBACK_KEY.ROW_SELECTION_CALLBACK ? data.selectedRows : data)
                                }
                                dataSource={[]}
                                loading={props.configuration?.isLoadingView || false}
                            />
                        </>
                    );
                }

                let tempObj = config.chartConfig && config.chartConfig.length > 0 ? JSON.parse(config.chartConfig) : {};
                let finalViewData = fillEmptyViewData(viewDataList, tempObj);
                let selectColumns = Object.keys(finalViewData[0])
                    .filter(x => x !== "id")
                    .filter(x => x.indexOf("[[ori]]") === -1);
                if (config.isAccLevel) {
                    selectColumns = selectColumns.filter(x => x !== "Server ID");
                }
                if (Object.keys(tempObj).findIndex(x => x === "exceptColumn") > -1 && tempObj["exceptColumn"].length > 0) {
                    let exceptColsArr: string[] = tempObj["exceptColumn"]
                        .split(",")
                        .map((x: string) => x.trim())
                        .concat(["Children", "Config"]);
                    selectColumns = selectColumns.filter(x => exceptColsArr.indexOf(x) === -1);
                }
                let isTicketTable: boolean = selectColumns.filter((x: string) => ["Ticket", "Open Time", "Close Time"].indexOf(x) > -1).length > 1;
                let columns = selectColumns.map(x => {
                    let final: any = isTicketTable
                        ? getSuitableColumnProps(x, { title: x, dataIndex: x, key: x })
                        : {
                              title: x,
                              dataIndex: x,
                              key: x,
                          };
                    if (config.isAccLevel || config.isEventRuleView) {
                        if (x.indexOf("Account ID") > -1) {
                            final["render"] = (text: string, record: any) => {
                                return (
                                    <Link
                                        target="_blank"
                                        to={getNavigationUrl(
                                            ACCOUNT_PROFILE_FROM_MODULE.MONITOR_PROFILE_LIST,
                                            getAccountProfileIdByObj({
                                                accountId: text,
                                                serverId: record["Server ID"],
                                                fromModule: ACCOUNT_PROFILE_FROM_MODULE.MONITOR_PROFILE_LIST,
                                                viewReportId: "",
                                            })
                                        )}
                                        style={{ color: "#0e65cc" }}
                                    >
                                        {text}
                                    </Link>
                                );
                            };
                            final["options"] = {
                                filter: {
                                    type: ComponentType.text,
                                },
                            };
                            final = DTColProps.Small(final);
                        } else if (x.indexOf("Tickets") > -1) {
                            final["render"] = (text: string, record: any) => {
                                return (
                                    <Button
                                        type="text"
                                        icon={<FileSearchOutlined />}
                                        onClick={() => onComponentCallback(CALLBACK_KEY.OTHERS, record)}
                                    ></Button>
                                );
                            };
                            final = DTColProps.XSmall(final);
                        }
                    }

                    let finalViewDataTypeOfArray = finalViewData.map((o: any) => {
                        return o[x];
                    });
                    let excludeMarkupNumber: string[] = ["Account ID", "Ticket", "Position ID"];
                    if (finalViewDataTypeOfArray.every(i => typeof i === "number") && !excludeMarkupNumber.some(k => x.indexOf(k) !== -1)) {
                        final["sorter"] = (a: any, b: any) => a[x] - b[x];
                        final = DTColProps.Currency(final, [], -1);
                    } else if (x.indexOf("%") > -1) {
                        final["sorter"] = (a: any, b: any) => a[x].replace("%", "") - b[x].replace("%", "");
                        final = DTColProps.Small(final, ["text-right"]);
                    } else if (x === "Holding Duration") {
                        final["render"] = (text: string, record: any) => {
                            try {
                                let tmp: any = JSON.parse(text);
                                if (Array.isArray(tmp)) {
                                    return (
                                        <Descriptions column={1} size="small" className="tiny-description">
                                            {(tmp as any[]).map(x => (
                                                <Descriptions.Item
                                                    key={`ti-${Math.random()}`}
                                                >{`${x["Holding Time"]} (${x["Ticket"]})`}</Descriptions.Item>
                                            ))}
                                        </Descriptions>
                                    );
                                } else {
                                    return tmp["Holding Time"];
                                }
                            } catch (error) {}
                            return text;
                        };
                    } else {
                        if (!x.toUpperCase().includes("TIME") && !x.includes("%")) {
                            final["options"] = {
                                filter: {
                                    type: ComponentType.text,
                                },
                            };
                        }

                        final = DTColProps.Small(final);
                    }

                    return final;
                });

                //** Decide row clicked available or not -- START -- */
                let rowClickDelegation = {};
                if (mainConfig.views.length > 0) {
                    if (isNextViewAvailable(mainConfig.views, (config.id || "").toString())) {
                        if (config.isViewTicket) {
                            if (Object.keys(columns).indexOf("Tickets") === -1) {
                                columns.push(
                                    DTColProps.XSmall({
                                        title: "Tickets",
                                        dataIndex: "tickets",
                                        key: "tickets",
                                        render: (text: string, record: any) => {
                                            return (
                                                <Button
                                                    type="text"
                                                    icon={<FileSearchOutlined />}
                                                    onClick={() => onComponentCallback(CALLBACK_KEY.ROW_SELECTION_CALLBACK, record)}
                                                ></Button>
                                            );
                                        },
                                    })
                                );
                            }
                        } else {
                            rowClickDelegation = {
                                enableRowSelection: true,
                                rowSelectionData: {
                                    rowSelectionType: "row",
                                    selectedRowKeys: [],
                                },
                            };
                        }
                    }
                }
                //** Decide row clicked available or not -- END -- */

                return (
                    <FlexiDataTable
                        rowKeyProperty="id"
                        title={
                            <div className="title-container table-view">
                                <span className="title">{config?.name}</span>
                                <span className="subtitle">{config?.description}</span>
                            </div>
                        }
                        columns={columns}
                        options={{
                            enableFilter: true,
                            refresh: {
                                timer: true,
                                refreshSecond: refreshRate,
                                enablePeriodSelection: true,
                            },
                            ...rowClickDelegation,
                        }}
                        scroll={{
                            x: 900,
                        }}
                        callback={(type: CALLBACK_KEY, data: any) =>
                            onComponentCallback(type, type === CALLBACK_KEY.ROW_SELECTION_CALLBACK ? data.selectedRows : data)
                        }
                        dataSource={finalViewData || []}
                        loading={props.configuration?.isLoadingView || false}
                    />
                );
            case 1:
                //Bar
                if (config.chartConfig && config.chartConfig.length > 0) {
                    let tempObj = JSON.parse(config.chartConfig);
                    let finalViewData = fillEmptyViewData(viewDataList, tempObj);
                    let chartConfigObj: any = {
                        data: finalViewData,
                        animation: false,
                        xAxis: {
                            nice: true,
                            label: {
                                autoHide: true,
                                autoRotate: true,
                            },
                        },
                        yAxis: {
                            title: {
                                text: tempObj.yField,
                            },
                        },
                        legend: {
                            position: "bottom",
                            padding: [25, 0, 0, 0],
                        },
                        tooltip: {
                            enterable: true,
                            position: "left",
                            offset: 30,
                            domStyles: {
                                "g2-tooltip-list": {
                                    maxHeight: "500px",
                                    overflowY: "auto",
                                    padding: "10px",
                                },
                            },
                        },
                        slider: {
                            start: 0,
                            end: 1,
                        },
                        ...(tempObj.groupField &&
                            tempObj.groupField.length && {
                                groupField: tempObj.groupField,
                                isGroup: true,
                            }),
                        ...(tempObj.seriesField &&
                            tempObj.seriesField.length && {
                                seriesField: tempObj.seriesField,
                                isStack: true,
                            }),
                    };

                    if (mainConfig.views.length > 0) {
                        if (isNextViewAvailable(mainConfig.views, (config.id || "").toString())) {
                            chartConfigObj["onReady"] = (plot: any) => {
                                plot.on("element:click", (evt: any) => {
                                    let plotClickedItem = evt.data.data;
                                    onComponentCallback(CALLBACK_KEY.ROW_SELECTION_CALLBACK, plotClickedItem);
                                });
                            };
                        }
                    }

                    chartConfigObj = Object.assign(chartConfigObj, tempObj.extra && tempObj.extra);
                    if (tempObj.chartType === "column") {
                        chartConfigObj = { ...chartConfigObj, xField: tempObj.xField, yField: tempObj.yField };
                        return <Column {...chartConfigObj} />;
                    } else if (tempObj.chartType === "bar") {
                        chartConfigObj = { ...chartConfigObj, xField: tempObj.xField, yField: tempObj.yField };
                        return <Bar {...chartConfigObj} />;
                    }
                }
                break;
            case 2:
                //Line
                if (config.chartConfig && config.chartConfig.length > 0) {
                    let tempObj = JSON.parse(config.chartConfig);
                    let finalViewData = cloneDeep(viewDataList);
                    let chartConfigObj: any = {
                        data: finalViewData,
                        xAxis: {
                            nice: true,
                            label: {
                                autoHide: true,
                                autoRotate: true,
                            },
                        },
                        yAxis: {
                            nice: true,
                            tickCount: 5,
                            title: {
                                text: tempObj.yField,
                            },
                        },
                        legend: {
                            position: "bottom",
                            padding: [25, 0, 0, 0],
                        },
                        slider: {
                            start: 0,
                            end: 1,
                        },
                        tooltip: {
                            enterable: true,
                            position: "left",
                            offset: 30,
                            domStyles: {
                                "g2-tooltip-list": {
                                    maxHeight: "500px",
                                    overflowY: "auto",
                                    padding: "10px",
                                },
                            },
                        },
                        animation: false,
                    };

                    chartConfigObj = Object.assign(chartConfigObj, tempObj.extra && tempObj.extra);
                    // Advance Handle for Data Visualization setup - START
                    let finalData: any[] = [];
                    if (Object.keys(tempObj).findIndex(x => x === "columnAsMetric") > -1 && tempObj.columnAsMetric) {
                        let exceptColumns = (tempObj.exceptColumn || "").split(",");
                        let types =
                            finalViewData.length > 0
                                ? Object.keys(finalViewData[0])
                                      .filter(x => x !== tempObj.xField && x !== "id")
                                      .filter(x => exceptColumns.indexOf(x) === -1)
                                : [];
                        finalViewData.map((x: any) => {
                            types.map(y => {
                                let itm: any = { value: x[y], type: y };
                                itm[tempObj.xField] = x[tempObj.xField];
                                finalData.push(itm);
                                return true;
                            });
                            return true;
                        });
                        tempObj["yField"] = "value";
                        tempObj["seriesField"] = "type";
                        chartConfigObj["data"] = finalData;
                    }

                    chartConfigObj["data"] = fillEmptyViewData(chartConfigObj["data"], tempObj);

                    if (mainConfig.views.length > 0) {
                        if (isNextViewAvailable(mainConfig.views, (config.id || "").toString())) {
                            chartConfigObj["onReady"] = (plot: any) => {
                                plot.on("element:click", (evt: any) => {
                                    //let axis_x_field = tempObj.xField;
                                    let axis_x_points = evt.data.points;
                                    let selected_x = evt.x;
                                    let nearly_idx = axis_x_points.reduce((pos: number, current: any, idx: number) => {
                                        if (Math.abs(selected_x - axis_x_points[pos].x) > Math.abs(selected_x - current.x)) {
                                            return idx;
                                        }
                                        return pos;
                                    }, 0);
                                    let plotClickedItem = evt.data.data[nearly_idx];
                                    onComponentCallback(CALLBACK_KEY.ROW_SELECTION_CALLBACK, plotClickedItem);
                                });
                            };
                        }
                    }
                    // Advance Handle for Data Visualization setup - END

                    if (tempObj.chartType === "single") {
                        chartConfigObj = { ...chartConfigObj, xField: tempObj.xField, yField: tempObj.yField };
                        return <Line {...chartConfigObj} />;
                    } else if (tempObj.chartType === "dual") {
                        chartConfigObj = {
                            ...chartConfigObj,
                            data: [chartConfigObj["data"], chartConfigObj["data"]],
                            xField: tempObj.xField,
                            yField: [tempObj.yField, tempObj.yField2],
                        };
                        return <DualAxes {...chartConfigObj} />;
                    } else if (tempObj.chartType === "multi") {
                        chartConfigObj = { ...chartConfigObj, xField: tempObj.xField, yField: tempObj.yField, seriesField: tempObj.seriesField };
                        return <Line {...chartConfigObj} />;
                    }
                }
                break;
            case 3:
                // Pie
                if (config.chartConfig && config.chartConfig.length > 0) {
                    let tempObj = JSON.parse(config.chartConfig);
                    let finalViewData = fillEmptyViewData(viewDataList, tempObj);
                    let chartConfigObj: any = {
                        appendPadding: 10,
                        data: finalViewData,
                        angleField: tempObj.yField,
                        colorField: tempObj.xField,
                        animation: false,
                        label: {
                            type: "spider",
                            labelHeight: 28,
                            content: "{name}: {percentage}",
                        },
                        legend: {
                            position: "bottom",
                        },
                    };

                    if (mainConfig.views.length > 0) {
                        if (isNextViewAvailable(mainConfig.views, (config.id || "").toString())) {
                            chartConfigObj["onReady"] = (plot: any) => {
                                plot.on("element:click", (evt: any) => {
                                    onComponentCallback(CALLBACK_KEY.ROW_SELECTION_CALLBACK, evt.data.data);
                                });
                            };
                        }
                    }

                    return <Pie {...chartConfigObj} />;
                }
                break;
            default:
                break;
        }

        return <></>;
    };

    const getViewBreadcrum = (mainConfig: any, currViewConfig: ViewProps, v: ViewCollectionItemProps[]) => {
        let tmp: React.ReactNode[] = [];
        let orderedViews = [...mainConfig.views];
        orderedViews.sort((a: ViewProps, b: ViewProps) => a.level - b.level);

        let foundIdx = orderedViews.findIndex((x: ViewProps) => x.id?.toString() === currViewConfig.id?.toString());
        if (foundIdx > -1) {
            orderedViews.map((x: ViewProps, i: number) => {
                if (i < foundIdx) {
                    tmp.push(
                        <Breadcrumb.Item
                            key={`mpp-bc-${i}`}
                            href="#"
                            onClick={() => {
                                let fidx = v.findIndex(z => z.viewId.toString() === x.id?.toString());
                                if (fidx > -1) {
                                    props.componentCallback && props.componentCallback(CALLBACK_KEY.BACK_TO_PREVIOUS, v[fidx]);
                                }
                            }}
                        >
                            {x.description && x.description.length > 0 ? <Tooltip title={x.description}>{x.name}</Tooltip> : x.name}
                        </Breadcrumb.Item>
                    );
                }
                return false;
            });

            let y = orderedViews[foundIdx];
            tmp.push(
                <Breadcrumb.Item key={`mpp-bc-${foundIdx}`}>
                    {y.description && y.description.length > 0 ? <Tooltip title={y.description}>{y.name}</Tooltip> : y.name}
                </Breadcrumb.Item>
            );
        }
        return <Breadcrumb separator={" > "}>{tmp}</Breadcrumb>;
    };

    const getSelectedTitleTemplate = (mainConfig: any, currViewConfig: ViewProps, v: ViewCollectionItemProps[]) => {
        let selectedParamTitle: string = "";
        let findCurrViewIdx = v.findIndex(x => x.viewId.toString() === currViewConfig.id?.toString());
        if (findCurrViewIdx > -1) {
            let paramObj = cloneDeep(v[findCurrViewIdx].params);
            if (mainConfig.views[findCurrViewIdx].chartType !== 0) {
                let tmpObj = JSON.parse(currViewConfig.chartConfig || "{}");
                if (tmpObj?.hasOwnProperty("xField") && paramObj?.hasOwnProperty(tmpObj["xField"])) {
                    selectedParamTitle = DateTimeUtil.GetUTC(paramObj[tmpObj["xField"]], "YYYY-MM-DD HH:mm:ss");
                }
            } else {
                let findLastView = mainConfig.views[findCurrViewIdx - 1];
                if (findLastView && findLastView.isAccLevel) {
                    if (findObjectKeys(paramObj, ["Account ID", "Server ID"])) {
                        return (
                            <div className="selected-account-container">
                                <span className="title"> Account ID: </span>
                                <Link
                                    target="_blank"
                                    to={getNavigationUrl(
                                        ACCOUNT_PROFILE_FROM_MODULE.MONITOR_PROFILE_LIST,
                                        getAccountProfileIdByObj({
                                            accountId: paramObj["Account ID"],
                                            serverId: paramObj["Server ID"],
                                            fromModule: ACCOUNT_PROFILE_FROM_MODULE.MONITOR_PROFILE_LIST,
                                            viewReportId: "",
                                        })
                                    )}
                                    style={{ color: "#0e65cc" }}
                                >
                                    {paramObj["Account ID"]}
                                </Link>
                                <span className="title"> Brand: </span> {paramObj["Brand"]}
                                <span className="title"> Server: </span> {paramObj["Server"]}
                            </div>
                        );
                    }
                } else {
                    let foundCol: string[] = Object.keys(paramObj).filter(x => x.toLowerCase() === "time");
                    if (foundCol.length > 0) {
                        selectedParamTitle = DateTimeUtil.GetUTC(paramObj[foundCol[0]], "YYYY-MM-DD HH:mm:ss");
                    }
                }
            }
        }

        return selectedParamTitle.length > 0 ? (
            <div className="selected-time-container">
                <span> Selected Period: {selectedParamTitle}</span>
            </div>
        ) : (
            <></>
        );
    };

    return (
        <>
            <div className={props.configuration.viewConfig.chartType !== 0 ? "chart-main-div" : ""}>
                {/* {props.isDashboardView && <div className="chart-title-container">{props.configuration.name}</div>} */}
                {
                    <div className="chart-title-container">
                        <span className="title">{props.configuration.name}</span>
                        {props.configuration.threshold && getThresholdTemplate(appSetting, props.configuration.threshold, undefined, true)}
                    </div>
                }
                {(props.configuration.viewCollection || []).length > 0 && (
                    <div className="custom-breadcrum-container">
                        <span></span>
                        {getViewBreadcrum(props.configuration, props.configuration.viewConfig, props.configuration.viewCollection || [])}
                    </div>
                )}

                {props.configuration.viewConfig.chartType !== 0 && (
                    <Row className="main-title-row-1">
                        <Col span={24}>
                            {getSelectedTitleTemplate(props.configuration, props.configuration.viewConfig, props.configuration.viewCollection || [])}
                        </Col>
                        <Col span={12}>
                            <div className="title-container">
                                <span className="title">{props.configuration.viewConfig.name}</span>{" "}
                                {/* {props.configuration.viewConfig.description ? ` - ` : ""} */}
                                <span className="subtitle">
                                    {props.configuration.viewConfig.description ? props.configuration.viewConfig.description : ""}
                                </span>
                            </div>
                        </Col>
                        {!props.configuration.disabledTimer && (
                            <Col span={12}>
                                <TimerButton period={refreshRate} enablePeriodSelection={true} onFinish={onComponentCallback} key="tmpcounter_1" />
                            </Col>
                        )}
                    </Row>
                )}
                {props.configuration.viewConfig.chartType === 0 &&
                    getSelectedTitleTemplate(props.configuration, props.configuration.viewConfig, props.configuration.viewCollection || [])}
                <div>{getChartTemplate(props.configuration, props.configuration.viewConfig, props.dataList)}</div>
            </div>
        </>
    );
};

export default ChartDisplayTemplate;
