import { FormComponent } from "@/components/FormComponent";
import LoadingComponent from "@/components/Loading";
import SitePageHeader from "@/components/PageHeader";
import { ComponentType, SUCCESS_FAILED } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { KeyValuePair } from "@/constants/type";
import { apiRequest, APIs } from "@/services/apiConfig";
import { ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { isEmptyOrNull, defaultIfEmptyOrNull } from "@/utils/string";
import { HomeOutlined } from "@ant-design/icons";
import { Button, Col, Form, Row } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export type BridgeState = {
    action: string;
    data: any;
};

function BridgeCreateEditPageV2() {
    let navigate = useNavigate();
    let location = useLocation();
    let com_state: BridgeState = location.state as BridgeState;
    let { id } = useParams();

    const stateInfo = useMemo(() => {
        let tmpState = Object.assign({ action: "add", data: null }, com_state || {});
        return {
            isAddAction: isEmptyOrNull(id) ? tmpState.action === "add" : false,
            data: defaultIfEmptyOrNull(tmpState.data, id),
        };
    }, [com_state, id]);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [bridgeTypes, setBridgeTypes] = useState<KeyValuePair[]>([]);
    const [bridgeForm] = Form.useForm();

    const onSubmit = useCallback(
        (values: any) => {
            setIsLoading(true);
            if (stateInfo.isAddAction) {
                apiRequest(APIs.CREATE_BRIDGE, values)
                    .then(data => {
                        ErrorMessageHandler("New bridge", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                        navigate("/siteadmin/brandserver/bridge");
                    })
                    .catch(error => {
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("new bridge", SUCCESS_FAILED.FAILED_CREATE_DATA, err));
                        setIsLoading(false);
                    });
            } else {
                apiRequest(APIs.UPDATE_BRIDGE, values)
                    .then(data => {
                        ErrorMessageHandler("Existing bridge", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                        navigate("/siteadmin/brandserver/bridge");
                    })
                    .catch(error => {
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("existing bridge", SUCCESS_FAILED.FAILED_UPDATE_DATA, err));
                        setIsLoading(false);
                    });
            }
        },
        [stateInfo]
    );

    const getBridgeById = useCallback(() => {
        setIsLoading(true);
        apiRequest(APIs.GET_BRIDGE_LIST, { bridgeId: stateInfo.data })
            .then((data: any) => {
                if (data) {
                    bridgeForm.setFieldsValue(data);
                }
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("bridge list", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                navigate("/siteadmin/brandserver/bridge");
            })
            .finally(() => setIsLoading(false));
    }, [stateInfo]);

    const getConfig = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["riskinsightbridgetype"] }).then((res: any) => {
            if (res.riskInsightBridgeTypes && res.riskInsightBridgeTypes.length > 0) {
                setBridgeTypes(res.riskInsightBridgeTypes.map((x: any) => ({ text: x.bridgeType, value: x.bridgeTypeId })));
            }
        });
    };

    useEffect(() => {
        getConfig();

        if (!stateInfo.isAddAction) {
            getBridgeById();
        } else {
            bridgeForm.setFieldsValue({ enable: true });
        }
        return () => {};
    }, [stateInfo, getBridgeById]);

    return (
        <SitePageHeader
            title={stateInfo.isAddAction ? "Create New Bridge" : "Edit Bridge"}
            routes={[
                {
                    path: "/siteadmin/brandserver/bridge",
                    breadcrumbName: "Bridge Listing",
                    icon: <HomeOutlined />,
                },
                {
                    path: "",
                    breadcrumbName: stateInfo.isAddAction ? "Create New Bridge" : "Edit Bridge",
                },
            ]}
            onBack={() => navigate("/siteadmin/brandserver/bridge")}
        >
            {isLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Submitting..." />
                </div>
            ) : (
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    form={bridgeForm}
                    layout="horizontal"
                    initialValues={stateInfo.data}
                    onFinish={onSubmit}
                >
                    <Row>
                        <Col span={15}>
                            <FormComponent label={""} name={"bridgeId"} extra={{ type: ComponentType.hidden, value: "" }} />
                            <FormComponent
                                label="Bridge Name"
                                name="bridgeName"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                    disabled: !stateInfo.isAddAction,
                                }}
                            />
                            <FormComponent
                                label="Bridge Display Name"
                                name="bridgeDisplayName"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                            <FormComponent
                                label="Bridge Type"
                                name="bridgeTypeId"
                                extra={{
                                    type: ComponentType.dropdown,
                                    value: bridgeTypes,
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                            <FormComponent
                                label="Version"
                                name="version"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                            <FormComponent
                                label="Host"
                                name="host"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                }}
                            />
                            <FormComponent
                                label="Port"
                                name="port"
                                extra={{
                                    type: ComponentType.number,
                                    value: "",
                                }}
                            />
                            <FormComponent
                                label="Login"
                                name="login"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                }}
                            />
                            <FormComponent
                                label="Password"
                                name="password"
                                extra={{
                                    type: ComponentType.password,
                                    value: "",
                                }}
                            />
                            <FormComponent
                                label="Broker VHost"
                                name="brokerVhost"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                }}
                            />
                            <FormComponent
                                label="Broker Login"
                                name="brokerLogin"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                }}
                            />
                            <FormComponent
                                label="Broker Password"
                                name="brokerPassword"
                                extra={{
                                    type: ComponentType.password,
                                    value: "",
                                }}
                            />
                            <FormComponent
                                label="Enable"
                                name="enable"
                                extra={{
                                    type: ComponentType.switch,
                                    value: ["Inactive", "Active"],
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div className="step-btns-group">
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            )}
        </SitePageHeader>
    );
}

export default BridgeCreateEditPageV2;
