import React from "react";
import CardBox from "../../../../components/Common/CardBox";
import DownloadForm from "./DownloadForm";
import GenerateConfigForm from "./GenerateConfigForm";
import { Divider } from "antd";
import { plainAxiosInstance } from "../../../../services/axiosSetup";
import { APIs } from "../../../../services/apis";
import { KeyValuePair } from "../../../../constants/type";
import LoadingComponent from "../../../../components/Loading";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";

const ConfigDiff = () => {
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.CONFIG_DIFF_EDIT);

    const [serversList, setServersList] = React.useState<KeyValuePair[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);

    React.useEffect(() => {
        plainAxiosInstance
            .get(`${APIs.RC_ROOT.GET_SERVERS}`)
            .then((res: any) => {
                if (res.status === 200 && res.data.length > 0) {
                    setServersList(res.data.map((x: any) => ({ text: x.serverName, value: x.serverId })));
                };
            })
            .finally(() => setIsLoading(false));
        return () => { };
    }, []);

    return (
        <div className="config-diff-container">
            <CardBox title="Config Diff">
                <LoadingComponent tip="Loading filters..." spinning={isLoading}>
                    <DownloadForm servers={serversList} />
                    {enableUpdate && (
                        <>
                            <Divider />
                            <GenerateConfigForm servers={serversList} />
                        </>
                    )}
                </LoadingComponent>
            </CardBox>
        </div>
    );
};

export default ConfigDiff;