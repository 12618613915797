import { Table, Row, Col, Dropdown, Popconfirm, Button, Tag, Form, Tooltip } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import FlexiFilter from "../FlexiFilter";
import { DeleteOutlined, EditOutlined, EyeOutlined, MenuOutlined } from "@ant-design/icons";
import { DTColProps, currencyRender } from "../../utils/Common";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import moment from "moment";
import { cloneDeep } from "lodash";
import {
    CustomPaginationProps,
    FlexiColumnFilterProps,
    FlexiDataColumnProps,
    FlexiDataTableCallbackProps,
    FlexiDataTableOptionsProps,
    IconKeyValuePair,
    KeyValuePair,
} from "../../constants/type";
import { CALLBACK_KEY, ComponentType } from "../../constants";
import {
    DefaultIfEmpty,
    findObjectKeys,
    getAvailableObjectElementKeys,
    getObjectWithKeys,
    objectRemoveProps,
    objectToArray,
    resetObjectValues,
} from "../../utils/object";
import { ARRAY_ACTION_TYPE, GetConstraintKeyList, ToObjectWithKey } from "../../utils/array";
import { isEmptyOrNull } from "../../utils/string";

export interface FlexiDataTableProps {
    title: string | React.ReactNode;
    rowKeyProperty: string;
    options: FlexiDataTableOptionsProps;
    filterInitialValue?: any;
    filterFormInstance?: any;
    tableFormInstance?: any;
    callback: FlexiDataTableCallbackProps;
    columns: FlexiDataColumnProps[];
    dataSource: any;
    loading: boolean;
    pagination?: ExtraPagination | boolean;
    serverSide?: boolean;
    bordered?: boolean;
    size?: any;
    exporting?: boolean;
    scroll?: any;
    tableProps?: any;
}

export interface ExtraPagination extends CustomPaginationProps {
    defaultPageSize?: number;
    pageSizeOptions?: number[];
    position?: string[];
    hideOnSinglePage?: boolean;
}

const initialDataTable = {
    bordered: false,
    serverSide: false,
    options: {
        add: false,
        edit: false,
        delete: false,
        separateActionButton: false,
        enableFilter: true,
        autoFilter: true,
        serverFiltering: false,
        hideRowSelectionsSummary: false,
        enableRowSelection: false,
        rowSelectionData: {
            rowSelectionType: "checkbox",
            selectedRowKeys: [],
        },
        refresh: false,
        resync: false,
        extraButtons: [],
        showHideColumns: true,
        defaultCollapseFilterPanel: false,
        disableFilterTagClosable: false,
        hideFilterResetButton: false,
    },
};

export const getShowColumnsTitle = (columns: FlexiDataColumnProps[], getTitleList: boolean = true): string[] | FlexiDataColumnProps[] => {
    let tmpList: FlexiDataColumnProps[] = columns.filter(x =>
        x.options !== undefined && x.options.visible !== undefined ? x.options.visible : true
    );
    return getTitleList ? tmpList.map(x => `${x.realTitle || x.title}`) : tmpList;
};

const FlexiDataTable = (props: FlexiDataTableProps) => {
    const [filteredData, setFilteredData] = useState<any>(props.filterInitialValue || {});
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>(
        props.options.enableRowSelection ? props.options.rowSelectionData?.selectedRowKeys || [] : []
    );
    const [selectionData, setSelectionData] = useState<{ [key: string]: any }>(ToObjectWithKey(props.dataSource, props.rowKeyProperty));
    const [tableOpt, setTableOpt] = useState<FlexiDataTableOptionsProps>(Object.assign({ ...initialDataTable.options }, props.options));
    const [tableCol, setTableCol] = useState<FlexiDataColumnProps[]>([...props.columns]);
    const [exportingState, setExportingState] = useState<boolean>(props.exporting || false);
    const [showColumnsList, setShowColumnsList] = useState<string[]>(getShowColumnsTitle(props.columns) as string[]);

    useEffect(() => {
        setSelectionData(ToObjectWithKey(props.dataSource, props.rowKeyProperty));
        props.options.enableRowSelection && setSelectedRowKeys(props.options.rowSelectionData?.selectedRowKeys || []);
        return () => {};
    }, [props.dataSource]);

    useEffect(() => {
        setTableOpt(
            Object.assign({ ...initialDataTable.options }, props.options, { defaultCollapseFilterPanel: tableOpt.defaultCollapseFilterPanel })
        );
        return () => {};
    }, [props.options]);

    useEffect(() => {
        let newCols: string[] = GetConstraintKeyList(
            getShowColumnsTitle(tableCol),
            getShowColumnsTitle(props.columns),
            ARRAY_ACTION_TYPE.RIGHT_OUTER
        );
        newCols.length > 0 && setShowColumnsList((prev: any) => [...prev, ...newCols]);
        setTableCol(props.columns);
        return () => {};
    }, [props.columns]);

    useEffect(() => {
        setExportingState(props.exporting || false);
        return () => {};
    }, [props.exporting]);

    const getFilteredDataTags = useCallback(
        (filterValues: any) => {
            let filter_keys = getAvailableObjectElementKeys(filterValues);
            if (filter_keys.length === 0) return <></>;

            return (
                <Row className="filter-tags-container">
                    <Col span={24} className="filter-tags-div">
                        {filter_keys.map((x: string) => {
                            let displayText: any = `${filterValues[x]}`,
                                currColIdx: number = tableCol.findIndex(y => y.key === x && y.options?.filter),
                                closeable: boolean = true;
                            if (currColIdx > -1) {
                                let currentFilterOptions: any = tableCol[currColIdx].options?.filter;
                                if (
                                    currentFilterOptions.hasOwnProperty("rules") &&
                                    currentFilterOptions.rules.length > 0 &&
                                    currentFilterOptions.rules.some((x: any) => x.hasOwnProperty("required") && x.required)
                                ) {
                                    closeable = false;
                                }
                                switch (currentFilterOptions.type) {
                                    case ComponentType.treeselect:
                                        let tmpArr: string[] = objectToArray(
                                            filterValues[x].reduce((obj: any, x: string) => {
                                                if (x.indexOf("-i-") === -1) {
                                                    return {
                                                        ...obj,
                                                        ...currentFilterOptions.value
                                                            .filter((y: any) => y.pId === x)
                                                            .reduce((iObj: any, y: any) => {
                                                                iObj[y.value.substring(y.value.indexOf("-i-") + 3)] = y.title;
                                                                return iObj;
                                                            }, {}),
                                                    };
                                                }

                                                let fIdx: number = currentFilterOptions.value.findIndex((y: any) => y.id === x);
                                                if (fIdx > -1) {
                                                    let foundItem = currentFilterOptions.value[fIdx];
                                                    obj[foundItem.value.substring(foundItem.value.indexOf("-i-") + 3)] = foundItem.title;
                                                }
                                                return obj;
                                            }, {})
                                        );
                                        tmpArr.sort((a: string, b: string) => a.toLowerCase().localeCompare(b.toLowerCase()));
                                        displayText = tmpArr.join(", ");

                                        break;
                                    case ComponentType.radio:
                                    case ComponentType.dropdown:
                                        if (Array.isArray(filterValues[x])) {
                                            if (
                                                currentFilterOptions?.hasOwnProperty("inputProps") &&
                                                currentFilterOptions.inputProps.mode === "tags"
                                            ) {
                                                displayText = filterValues[x].join(", ");
                                            } else {
                                                displayText = filterValues[x]
                                                    .map((z: any) => {
                                                        let foundItem = (currentFilterOptions.value as KeyValuePair[]).filter(
                                                            y => `${y.value}` === `${z}`
                                                        );
                                                        if (foundItem.length > 0) {
                                                            return foundItem[0].text;
                                                        }
                                                        return undefined;
                                                    })
                                                    .filter((x: string | undefined) => x !== undefined)
                                                    .join(", ");
                                            }
                                        } else {
                                            try {
                                                displayText = (currentFilterOptions.value as KeyValuePair[]).filter(
                                                    y => `${y.value}` === `${filterValues[x]}`
                                                )[0].text;

                                                if (typeof displayText !== "string") {
                                                    displayText = displayText.props.children[1].props.children;
                                                }
                                            } catch (err: any) {
                                                console.log(err.toString());
                                            }
                                        }
                                        break;
                                    case ComponentType.daterange:
                                        if (
                                            Object.keys(filterValues).findIndex(x => x.toUpperCase() === "EVENTDATE") > -1 ||
                                            currentFilterOptions.inputProps?.dateOnly
                                        ) {
                                            displayText = `${filterValues[x][0].format("YYYY-MM-DD")} ~ ${filterValues[x][1].format("YYYY-MM-DD")}`;
                                        } else {
                                            displayText = `${filterValues[x][0].format("YYYY-MM-DD HH:mm:ss")} ~ ${filterValues[x][1].format(
                                                "YYYY-MM-DD HH:mm:ss"
                                            )}`;
                                        }
                                        break;
                                    case ComponentType.date:
                                        if (!isEmptyOrNull(currentFilterOptions?.dateFormat)) {
                                            displayText = `${filterValues[x].format(currentFilterOptions.dateFormat)}`;
                                        } else {
                                            displayText = `${filterValues[x].format("YYYY-MM-DD HH:mm:ss")}`;
                                        }
                                        break;
                                    case ComponentType.timerange:
                                        displayText = `${filterValues[x][0].format("HH:mm:ss")} ~ ${filterValues[x][1].format("HH:mm:ss")}`;
                                        break;
                                    case ComponentType.rebateAccounts:
                                        if (Array.isArray(filterValues[x])) {
                                            displayText = filterValues[x]
                                                .reduce((arr: string[], z: string) => {
                                                    let tmpText: string[] = z.split("|");
                                                    arr.push(
                                                        `${tmpText[0]}|${DefaultIfEmpty(currentFilterOptions.value, tmpText[1], { text: "" }).text}`
                                                    );
                                                    return arr;
                                                }, [])
                                                .join(", ");
                                        } else {
                                            let tmpText: string[] = displayText.split("|");
                                            displayText = `${tmpText[0]}|${
                                                DefaultIfEmpty(currentFilterOptions.value, tmpText[1], { text: "" }).text
                                            }`;
                                        }

                                        break;
                                    case ComponentType.switch:
                                        displayText = filterValues[x] ? currentFilterOptions.value[1] : currentFilterOptions.value[0];
                                        break;
                                }
                                displayText = (
                                    <>
                                        <b>{`${tableCol[currColIdx].realTitle || tableCol[currColIdx].title}: `}</b>
                                        <>&nbsp;</>
                                        <span className="text-wrap">{`${displayText}`}</span>
                                    </>
                                );
                            }

                            return (
                                <Tag
                                    key={`fdb-fltg-ky-${x}`}
                                    {...(!tableOpt.disableFilterTagClosable && {
                                        closable: closeable,
                                        onClose: () =>
                                            findObjectKeys(filteredData, [x]) &&
                                            componentCallback(CALLBACK_KEY.FILTER_FORM_SUBMIT, {
                                                ...objectRemoveProps(filteredData, [x]),
                                                [x]: undefined,
                                            }),
                                    })}
                                >
                                    {displayText}
                                </Tag>
                            );
                        })}
                    </Col>
                </Row>
            );
        },
        [tableOpt, tableCol, filteredData]
    );

    const getActualDataSource = (dataSource: any[], filterValues: any) => {
        const ori_data = cloneDeep(dataSource);
        let filter_values = tableOpt.serverFiltering ? {} : filterValues;
        let filter_keys = getAvailableObjectElementKeys(filter_values);
        if (filter_keys.length === 0) return ori_data;

        let filter_callback = tableCol
            .filter(x => x.options?.filter && x.options?.filter?.callback && x.key !== undefined)
            .reduce((obj: any, x: any) => {
                obj[x.key] = x.options?.filter?.callback;
                return obj;
            }, {});

        return ori_data.filter((x: any) => {
            let isValid = true;
            filter_keys.forEach((k: string) => {
                if (x.children) {
                    x.children = x.children.filter((y: string) => rowChecking(filter_callback, filter_values, k, y));
                    if (x.children.length < 1) isValid = false;
                } else {
                    if (!rowChecking(filter_callback, filter_values, k, x)) isValid = false;
                }
            });
            return isValid;
        });
    };

    const rowChecking = (filter_callback: { [key: string]: Function }, filter_values: { [key: string]: any }, k: string, x: any) => {
        if (filter_callback[k] !== undefined) {
            if (!filter_callback[k](filter_values[k], x)) {
                return false;
            }
        } else {
            if (isEmptyOrNull(x[k])) return false;

            switch (typeof x[k]) {
                case "string":
                    if (typeof filter_values[k] !== "string" && filter_values[k].length === 2) {
                        // for daterange checking
                        try {
                            let r_date = moment(x[k]);
                            if (filter_values[k][0] !== undefined && filter_values[k][0] > r_date) {
                                return false;
                            }
                            if (filter_values[k][1] !== undefined && filter_values[k][1] < r_date) {
                                return false;
                            }
                        } catch {}
                    } else {
                        if (x[k].trim().toLowerCase().indexOf(filter_values[k].trim().toString().toLowerCase()) === -1) {
                            return false;
                        }
                    }
                    break;
                case "number":
                    if (x[k] !== Number(filter_values[k])) {
                        return false;
                    }
                    break;
                case "boolean":
                    if (x[k] !== Boolean(filter_values[k])) {
                        return false;
                    }
                    break;
                case "object":
                    if (!x[k].includes(filter_values[k])) {
                        return false;
                    }
                    break;
                default:
                    break;
            }
        }
        return true;
    };

    const prepareColumnOption = (
        columns: FlexiDataColumnProps[],
        options: FlexiDataTableOptionsProps,
        showColumns: string[]
    ): FlexiDataColumnProps[] => {
        let _columns = [...columns];
        // let _columns = cloneDeep(columns); //clonedeep cause javascript performance issue

        //#region exclude visible: false
        _columns = (getShowColumnsTitle(_columns, false) as FlexiDataColumnProps[]).filter(x => showColumns.includes(`${x.realTitle || x.title}`));
        //#endregion

        //#region markupColumn
        _columns.map(x => {
            if (x.options && x.options.filter) {
                x.options.filter = Object.assign({ type: ComponentType.text } as FlexiColumnFilterProps, x.options.filter);
            }
            return x;
        });
        //#endregion

        //#region set action column
        if (options.view || options.delete || options.edit || options.rowExtra || options.customExtraActionButton) {
            let fullSize = 6.255;
            if (options.separateActionButton) {
                //fullSize = 7.882;
                if (!options.view && fullSize > 100) fullSize -= 1.051;
                if (!options.delete && fullSize > 100) fullSize -= 1.051;
                if (!options.edit && fullSize > 100) fullSize -= 1.051;
                if (!options.rowExtra && fullSize > 100) fullSize -= 1.051;
            }
            _columns.push(
                DTColProps.Action({
                    width: options.actionColumnWidth ? options.actionColumnWidth : `${fullSize}vw`,
                    render: (text: any, record: any, index: number) => {
                        let menuList: ItemType[] = [];
                        let view_button: React.ReactNode = undefined;
                        let custom_extra_button: React.ReactNode | undefined = undefined;
                        if (options.customExtraActionButton !== undefined) {
                            if (typeof options.customExtraActionButton === "function") {
                                custom_extra_button = options.customExtraActionButton(record, props.callback);
                            } else {
                                let customExtra_list = options.customExtraActionButton as IconKeyValuePair[];
                                if (customExtra_list.length > 0) {
                                    custom_extra_button = (
                                        <>
                                            {customExtra_list.map((x: any, i: any) => (
                                                <Button
                                                    key={`r-${record.id || index}-cex-${i}`}
                                                    type="text"
                                                    icon={x.icon}
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        props.callback(CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK, { key: x.value, data: record });
                                                    }}
                                                />
                                            ))}
                                        </>
                                    );
                                }
                            }
                        }
                        if (options.view) {
                            view_button = (
                                <Button
                                    key={`r-${record.id || index}-v`}
                                    type="text"
                                    icon={<EyeOutlined style={{ fontSize: "1.125rem" }} />}
                                    onClick={event => {
                                        event.preventDefault();
                                        props.callback(CALLBACK_KEY.VIEW_RECORD, record);
                                    }}
                                />
                            );
                            if (typeof options.view === "function") {
                                view_button = options.view(record, view_button);
                            }
                        }
                        if (options.edit) {
                            const edit_button = options.separateActionButton ? (
                                <Button
                                    key={`r-${record.id || index}-e`}
                                    type="text"
                                    icon={<EditOutlined style={{ fontSize: "1.125rem" }} />}
                                    onClick={e => {
                                        e.preventDefault();
                                        props.callback(CALLBACK_KEY.DO_EDIT, record);
                                    }}
                                />
                            ) : (
                                <Button
                                    type="text"
                                    onClick={e => {
                                        e.preventDefault();
                                        props.callback(CALLBACK_KEY.DO_EDIT, record);
                                    }}
                                >
                                    Edit
                                </Button>
                            );
                            if (typeof options.edit === "function") {
                                let editOption = options.edit(record, {
                                    key: `r-${record.id || index}-e`,
                                    label: edit_button,
                                } as ItemType);
                                if (editOption) menuList.push(editOption);
                            } else {
                                menuList.push({
                                    key: `r-${record.id || index}-e`,
                                    label: edit_button,
                                } as ItemType);
                            }
                        }
                        if (options.delete) {
                            const delete_button = (
                                <Popconfirm
                                    key={`r-${record.id || index}-d`}
                                    placement="topRight"
                                    title={"Confirm to delete?"}
                                    icon={<DeleteOutlined style={{ fontSize: "0.875rem" }} />}
                                    onConfirm={() => props.callback(CALLBACK_KEY.DO_DELETE, record)}
                                    okText={"Yes"}
                                    cancelText={"No"}
                                    style={{ width: "15.765vw" }}
                                >
                                    {options.separateActionButton ? (
                                        <Button
                                            key={`r-${record.id || index}-d-btn`}
                                            type="text"
                                            icon={<DeleteOutlined style={{ fontSize: "1.125rem" }} />}
                                            onClick={e => {
                                                e.preventDefault();
                                            }}
                                        />
                                    ) : (
                                        <Button
                                            type="text"
                                            onClick={e => {
                                                e.preventDefault();
                                            }}
                                        >
                                            Delete
                                        </Button>
                                    )}
                                </Popconfirm>
                            );
                            if (typeof options.delete === "function") {
                                let deleteOption = options.delete(record, {
                                    key: `r-${record.id || index}-d`,
                                    label: delete_button,
                                } as ItemType);
                                if (deleteOption) menuList.push(deleteOption);
                            } else {
                                menuList.push({
                                    key: `r-${record.id || index}-d`,
                                    label: delete_button,
                                } as ItemType);
                            }
                        }
                        if (options.rowExtra !== undefined) {
                            let rowExtra_list: IconKeyValuePair[] = [];

                            if (typeof options.rowExtra === "function") {
                                rowExtra_list = options.rowExtra(record, props.callback);
                            } else {
                                rowExtra_list = options.rowExtra as IconKeyValuePair[];
                            }

                            if (rowExtra_list.length > 0) {
                                if (options.separateActionButton) {
                                    let tmpButton: ItemType[] = [];
                                    rowExtra_list.map((x: any, i: any) => {
                                        tmpButton.push({
                                            key: `r-${record.id || index}-ex-${i}`,
                                            label: x.icon ? (
                                                isEmptyOrNull(x.text) ? (
                                                    <Button
                                                        key={`r-${record.id || index}-ex-${i}`}
                                                        type="text"
                                                        icon={x.icon}
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            props.callback(CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK, { key: x.value, data: record });
                                                        }}
                                                    />
                                                ) : (
                                                    <Tooltip key={`r-${record.id || index}-ex-${i}`} title={x.text}>
                                                        <Button
                                                            type="text"
                                                            icon={x.icon}
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                props.callback(CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK, {
                                                                    key: x.value,
                                                                    data: record,
                                                                });
                                                            }}
                                                        />
                                                    </Tooltip>
                                                )
                                            ) : (
                                                <Button
                                                    key={`r-${record.id || index}-ex-${i}`}
                                                    type="text"
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        props.callback(CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK, { key: x.value, data: record });
                                                    }}
                                                >
                                                    {x.text}
                                                </Button>
                                            ),
                                        } as ItemType);

                                        return false;
                                    });

                                    if (tmpButton.length > 0) {
                                        menuList = tmpButton;
                                        // menuList.push({
                                        //     key: `r-${record.id || index}-hex`,
                                        //     label: (
                                        //         <Dropdown
                                        //             trigger={["click"]}
                                        //             overlayClassName="row-dropdow-panel"
                                        //             menu={{
                                        //                 items: [...tmpButton]
                                        //             }}
                                        //         >
                                        //             <MenuOutlined />
                                        //         </Dropdown>
                                        //     ),
                                        // } as ItemType);
                                    }
                                } else {
                                    rowExtra_list.map((x: any, i: any) => {
                                        menuList.push({
                                            key: `r-${record.id || index}-ex-${i}`,
                                            label: (
                                                <Button
                                                    type="text"
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        props.callback(CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK, { key: x.value, data: record });
                                                    }}
                                                >
                                                    {x.text}
                                                </Button>
                                            ),
                                        } as ItemType);

                                        return false;
                                    });
                                }
                            }
                        }
                        return menuList.length > 0 || view_button || custom_extra_button !== undefined ? (
                            <div className="row-action-button-container">
                                {custom_extra_button !== undefined && custom_extra_button}
                                {view_button && view_button}
                                {menuList.length > 0 &&
                                    (options.separateActionButton ? (
                                        menuList.map((x: any) => {
                                            return x.label;
                                        })
                                    ) : (
                                        <Dropdown
                                            trigger={["click"]}
                                            overlayClassName="row-dropdow-panel"
                                            menu={{
                                                items: [...menuList],
                                            }}
                                        >
                                            <MenuOutlined style={{ minWidth: "32px", display: "block" }} />
                                        </Dropdown>
                                    ))}
                            </div>
                        ) : (
                            <></>
                        );
                    },
                })
            );
        }
        //#endregion
        return _columns;
    };

    const componentCallback = (type: CALLBACK_KEY, data: any) => {
        switch (type) {
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                //if (tableOpt.autoFilter) {
                data = Object.keys(data).reduce((obj: any, i: any) => {
                    obj[i] = (Array.isArray(data[i]) ? data[i].length === 0 : data[i] === null) ? undefined : data[i];
                    return obj;
                }, {});
                setFilteredData(data);
                //}
                setTableOpt(prev => ({ ...prev, defaultCollapseFilterPanel: true }));
                break;
            case CALLBACK_KEY.ROW_SELECTION_CALLBACK:
                if (typeof data.selectedRowKeys === "string") {
                    let tmpRR: string[] = [];
                    tmpRR.push(data.selectedRowKeys);
                    setSelectedRowKeys(tmpRR);
                } else {
                    setSelectedRowKeys(data.selectedRowKeys);
                }
                break;
            case CALLBACK_KEY.SHOW_HIDE_COLUMNS_SELECTION:
                setShowColumnsList(data.selectedList);
                break;
            case CALLBACK_KEY.COLLAPSE:
                //setIsCollapse(data.length === 0);
                setTableOpt(prev => ({ ...prev, defaultCollapseFilterPanel: data.length === 0 }));
                break;
            case CALLBACK_KEY.FILTER_FORM_RESET:
                let resetData = resetObjectValues(data);
                if (tableOpt.resetFollowDefaultInitFilterValue === undefined) {
                    resetData = { ...resetData, ...props.filterInitialValue };
                } else {
                    if (typeof tableOpt.resetFollowDefaultInitFilterValue === "boolean") {
                        if (tableOpt.resetFollowDefaultInitFilterValue) {
                            resetData = { ...resetData, ...props.filterInitialValue };
                        }
                    } else {
                        resetData = {
                            ...resetData,
                            ...getObjectWithKeys(props.filterInitialValue, tableOpt.resetFollowDefaultInitFilterValue as string[]),
                        };
                    }
                }
                setFilteredData(resetData);
                tableOpt.serverFiltering && props.callback && props.callback(CALLBACK_KEY.FILTER_FORM_SUBMIT, resetData);
                return;
            default:
                break;
        }

        props.callback && props.callback(type, data);
    };

    const getTableComponent = useCallback(
        (tableComponent: React.ReactNode) => {
            return props.tableFormInstance !== undefined ? (
                <Form form={props.tableFormInstance} layout="inline">
                    {tableComponent}
                </Form>
            ) : (
                <>{tableComponent}</>
            );
        },
        [props.tableFormInstance]
    );

    return (
        <div className={`flexi-datatable ${props.bordered ? "bordered" : ""}`}>
            <Row key="flexi-filter">
                <Col span={24}>
                    <FlexiFilter
                        title={props.title}
                        initialValues={filteredData}
                        options={tableOpt}
                        columns={tableCol}
                        callback={componentCallback}
                        enableFilter={tableOpt.enableFilter}
                        exporting={exportingState}
                        showColumnsTitleList={showColumnsList}
                        filterFormInstance={props.filterFormInstance}
                    />
                </Col>
            </Row>
            {!tableOpt.hideRowSelectionsSummary &&
                tableOpt.enableRowSelection &&
                tableOpt.rowSelectionData &&
                tableOpt.rowSelectionData.rowSelectionType === "checkbox" &&
                selectedRowKeys !== undefined &&
                selectedRowKeys.length > 0 && (
                    <Row>
                        <Col span={24}>
                            <div className="flexi-selectedRow-Container">
                                {selectedRowKeys.map((x, index) => {
                                    return (
                                        <Tag
                                            key={`flexi-sr-${x}`}
                                            closable
                                            onClose={e => {
                                                e.preventDefault();
                                                let tmp = [...selectedRowKeys];
                                                tmp.splice(index, 1);
                                                setSelectedRowKeys(tmp);
                                                componentCallback(CALLBACK_KEY.ROW_SELECTION_CALLBACK, {
                                                    selectedRowKeys: tmp,
                                                    selectedRows: tmp.map((x: string) => selectionData[x]),
                                                });
                                            }}
                                        >
                                            {tableOpt.rowSelectionData?.selectedKeyRender
                                                ? tableOpt.rowSelectionData?.selectedKeyRender(x, selectionData[x])
                                                : x}
                                        </Tag>
                                    );
                                })}
                            </div>
                        </Col>
                    </Row>
                )}
            {tableOpt.defaultCollapseFilterPanel && getFilteredDataTags(filteredData)}
            <Row key="flexi-table">
                <Col span={24}>
                    {getTableComponent(
                        <Table
                            className={`flexi-table`}
                            rowClassName={(record, index) => {
                                return props.options.recordRowClassName ? props.options.recordRowClassName(record, index) : undefined;
                            }}
                            {...(props.options.rowClassName &&
                                props.options.rowClassName.length > 0 && {
                                    rowClassName: props.options.rowClassName.join(" "),
                                })}
                            size={props.size || "small"}
                            rowKey={record => record[props.rowKeyProperty] + ""}
                            columns={prepareColumnOption(tableCol, props.options, showColumnsList)}
                            dataSource={getActualDataSource(props.dataSource, filteredData)}
                            loading={props.loading}
                            pagination={
                                typeof props.pagination === "boolean"
                                    ? false
                                    : Object.assign(
                                          {
                                              //position: ["topRight", "bottomRight"],
                                              showTotal: (total: number, range: number[]) =>
                                                  `${currencyRender(range[0])} - ${currencyRender(range[1])} of ${currencyRender(total)}`,
                                              showSizeChanger: true,
                                              defaultPageSize: 10,
                                          },
                                          props.pagination
                                      )
                            }
                            scroll={Object.assign({ x: 900 }, props.scroll || {})}
                            onChange={(pagination: CustomPaginationProps, filters: any, sorter: any) => {
                                if (props.serverSide || initialDataTable.serverSide)
                                    componentCallback(CALLBACK_KEY.HANDLE_PAGINATION_SORTING, { pagination: pagination, sorter: sorter });
                            }}
                            {...(tableOpt.enableRowSelection &&
                                (tableOpt.rowSelectionData?.rowSelectionType !== "checkbox"
                                    ? {
                                          onRow: (record: any, rowIndex: any) => {
                                              return {
                                                  onClick: (event: any) =>
                                                      componentCallback &&
                                                      componentCallback(CALLBACK_KEY.ROW_SELECTION_CALLBACK, {
                                                          selectedRowKeys: record[props.rowKeyProperty],
                                                          selectedRows: record,
                                                      }),
                                              };
                                          },
                                      }
                                    : {
                                          rowSelection: {
                                              type: "checkbox",
                                              selectedRowKeys: selectedRowKeys,
                                              onChange: (selectedKeys, selectedRows) =>
                                                  componentCallback(CALLBACK_KEY.ROW_SELECTION_CALLBACK, {
                                                      selectedRowKeys: selectedKeys,
                                                      selectedRows,
                                                  }),
                                              ...(tableOpt.rowSelectionData.options && tableOpt.rowSelectionData.options),
                                              onSelect: (record, selected, selectedRows) =>
                                                  componentCallback(CALLBACK_KEY.ON_SELECT_TABLE_ROW_CALLBACK, {
                                                      selectedRecord: record,
                                                      selected,
                                                      selectedRows,
                                                  }),
                                          },
                                      }))}
                            expandable={props.options.expandable}
                            sticky={true}
                            {...{ ...{ tableLayout: "fixed" }, ...props.tableProps }}
                            {...(tableOpt.summary !== undefined && {
                                summary: tableOpt.summary,
                            })}
                        />
                    )}
                </Col>
            </Row>
        </div>
    );
};

FlexiDataTable.defaultProps = {
    rowKeyProperty: "Id",
    options: {
        add: false,
        edit: false,
        delete: false,
        autoFilter: true,
        serverFiltering: false,
        enableRowSelection: false,
        rowSelectionData: {
            rowSelectionType: "checkbox",
            selectedRowKeys: [],
        },
    },
    callback: function (type: number, data: any): void {
        console.log("Function not implemented.");
    },
    serverSide: false,
};

export default FlexiDataTable;
