import { Select } from "antd";
import { CALLBACK_KEY, MONITORING_PAGINATION } from "../../../../../constants";

export interface MonitoringPaginationProps {
    pageNum: number;
    onChangePgNum: (type: CALLBACK_KEY, currentPageNum: number) => void;
}

const MonitoringPagination = (props: MonitoringPaginationProps) => {
    const paginationSelection = Object.keys(MONITORING_PAGINATION).map((x: string) => ({ label: x, value: MONITORING_PAGINATION[x] }));
    return (
        <Select
            style={{ marginLeft: "2px" }}
            defaultValue={props.pageNum}
            options={paginationSelection}
            onChange={(value: number) => props.onChangePgNum && props.onChangePgNum(CALLBACK_KEY.MONITORING_PAGINATION, value)}
        />
    );
};

export default MonitoringPagination;
