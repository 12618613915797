import GroupCharts from "./Charts/groupChart";

export interface DevelopmentProps {}

const Development = (props: DevelopmentProps) => {
    const defaultProps: any = {};
    return (
        <div className="development-container">
            <GroupCharts />
        </div>
    );
};

export default Development;
