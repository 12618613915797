import { useEffect, useMemo, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { ToObjectWithKey } from "../../utils/array";
import { DND_ACTION } from "../../constants";
import { DnDCallback } from "../../constants/type";
import { hasAnyKey } from "../../utils/object";

export interface SimpleDnDProps {
    mode?: SimpleDndMode;
    propertyId: string;
    itemKey: string;
    data: any[];
    itemTemplate?: (item: any, index: number, provider: any, enableSwitching: boolean) => React.ReactNode;
    isDragDisabled?: boolean;
    startSwitching?: boolean;
    callback?: DnDCallback;
    defaultArrangement?: string[];
}

export type SimpleDndMode = "vertical" | "horizontal";

const SimpleDnD = (props: SimpleDnDProps) => {
    const [orderedKey, setOrderedKey] = useState<string[]>(props.defaultArrangement ? props.defaultArrangement : []);
    const [dataObject, setDataObject] = useState<any>({});

    const reorder = (list: string[], startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result: any) => {
        if (!result.destination) return;
        setOrderedKey(reorder(orderedKey, result.source.index, result.destination.index));
    };

    const componentKey = useMemo(() => `dnd-svl-${props.propertyId}`, [props.propertyId]);
    const isDragDisabled = useMemo(() => (props.isDragDisabled === undefined ? true : props.isDragDisabled), [props.isDragDisabled]);
    const startSwitching = useMemo(() => (props.startSwitching === undefined ? false : props.startSwitching), [props.startSwitching]);

    const _itemTemplate = useMemo(
        () =>
            props.itemTemplate
                ? props.itemTemplate
                : (item: any, index: number, provider: any, enableSwitching: boolean): React.ReactNode => (
                      <div ref={provider.innerRef} {...provider.draggableProps} {...provider.dragHandleProps}>
                          {item}
                      </div>
                  ),
        [props.itemTemplate]
    );

    const innerList = useMemo(
        () =>
            hasAnyKey(dataObject) && orderedKey.length > 0 ? (
                orderedKey
                    .filter(x => dataObject?.hasOwnProperty(x))
                    .map((x: string, index: number) => (
                        <Draggable key={`${componentKey}-${x}`} draggableId={x} index={index} isDragDisabled={isDragDisabled}>
                            {provided => <>{_itemTemplate(dataObject[x], index, provided, startSwitching)}</>}
                        </Draggable>
                    ))
            ) : (
                <></>
            ),
        [orderedKey, dataObject, isDragDisabled, startSwitching]
    );

    useEffect(() => {
        let currentKeys = props.data.map(x => x[props.itemKey]);
        if (orderedKey.length > 0) {
            currentKeys = orderedKey.concat(currentKeys.filter(x => !orderedKey.includes(x)));
        }
        setOrderedKey(currentKeys);
        setDataObject(ToObjectWithKey(props.data, props.itemKey));
    }, [props.itemKey, props.data]);

    useEffect(() => {
        orderedKey.length > 0 && props.callback && props.callback(DND_ACTION.DRAGGABLE_END, { id: props.propertyId, newOrder: orderedKey });
    }, [orderedKey]);

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={componentKey} direction={props.mode ? props.mode : "vertical"} isDropDisabled={isDragDisabled}>
                {provided => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                        {innerList}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};
export default SimpleDnD;
