import { Row, Col, FormInstance, Form } from "antd";
import { ComponentType } from "../../../constants";
import { REQUIRED_FIELD } from "../../../constants/errorMessage";
import { FormComponent } from "../../FormComponent";

export interface LineTemplateProps {
    prefix: string;
    form: FormInstance;
}

const LineTemplate = (props: LineTemplateProps) => {
    const cType = Form.useWatch(`${props.prefix}_chartType`, props.form);
    const isChecked = Form.useWatch(`${props.prefix}_columnAsMetric`, props.form);

    return (
        <>
            <Row>
                <Col span={24}>
                    <FormComponent
                        label={"Chart Type"}
                        name={`${props.prefix}_chartType`}
                        extra={{
                            type: ComponentType.radio,
                            value: [
                                { text: "Single Y axis", value: "single" },
                                { text: "Dual Y axis", value: "dual" },
                                { text: "Multi line", value: "multi" },
                            ],
                            itemProps: {
                                labelAlign: "left",
                                labelCol: { span: 5 },
                                wrapperCol: { span: 19 },
                            },
                            rules: [
                                {
                                    required: true,
                                    message: REQUIRED_FIELD,
                                },
                            ],
                        }}
                    />
                </Col>
            </Row>

            {cType === "multi" && (
                <Row>
                    <Col span={24} offset={5}>
                        <FormComponent
                            label={""}
                            name={`${props.prefix}_columnAsMetric`}
                            extra={{
                                type: ComponentType.checkbox,
                                value: "Column name as series field (Except X Axis)",
                            }}
                        />
                    </Col>
                </Row>
            )}

            <Row>
                <Col span={24}>
                    <FormComponent
                        label={"X Axis"}
                        name={`${props.prefix}_xField`}
                        extra={{
                            type: ComponentType.text,
                            value: "",
                            itemProps: {
                                labelAlign: "left",
                                labelCol: { span: 5 },
                                wrapperCol: { span: 11 },
                            },
                            rules: [
                                {
                                    required: true,
                                    message: REQUIRED_FIELD,
                                },
                            ],
                        }}
                    />
                </Col>
            </Row>

            {cType === "multi" && isChecked && (
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label={"Except Column(s)"}
                            name={`${props.prefix}_exceptColumn`}
                            extra={{
                                type: ComponentType.text,
                                value: "",
                                itemProps: {
                                    labelAlign: "left",
                                    labelCol: { span: 5 },
                                    wrapperCol: { span: 11 },
                                },
                                inputProps: {
                                    placeholder: "',' as separator if multiple column",
                                },
                            }}
                        />
                    </Col>
                </Row>
            )}

            {!(cType === "multi" && isChecked) && (
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label={"Y Axis"}
                            name={`${props.prefix}_yField`}
                            extra={{
                                type: ComponentType.text,
                                value: "",
                                itemProps: {
                                    labelAlign: "left",
                                    labelCol: { span: 5 },
                                    wrapperCol: { span: 11 },
                                },
                                rules: [
                                    {
                                        required: isChecked ? false : true,
                                        message: REQUIRED_FIELD,
                                    },
                                ],
                                inputProps: {
                                    disabled: isChecked,
                                },
                            }}
                        />
                    </Col>
                </Row>
            )}
            {cType === "dual" && (
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label={"Y Axis"}
                            name={`${props.prefix}_yField2`}
                            extra={{
                                type: ComponentType.text,
                                value: "",
                                itemProps: {
                                    labelAlign: "left",
                                    labelCol: { span: 5 },
                                    wrapperCol: { span: 11 },
                                },
                                rules: [
                                    {
                                        required: true,
                                        message: REQUIRED_FIELD,
                                    },
                                ],
                            }}
                        />
                    </Col>
                </Row>
            )}
            {cType === "multi" && !isChecked && (
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label={"Series Field"}
                            name={`${props.prefix}_seriesField`}
                            extra={{
                                type: ComponentType.text,
                                value: "",
                                itemProps: {
                                    labelAlign: "left",
                                    labelCol: { span: 5 },
                                    wrapperCol: { span: 11 },
                                },
                                rules: [
                                    {
                                        required: isChecked ? false : true,
                                        message: REQUIRED_FIELD,
                                    },
                                ],
                                inputProps: {
                                    disabled: isChecked,
                                },
                            }}
                        />
                    </Col>
                </Row>
            )}
            <Row>
                <Col span={24}>
                    <FormComponent
                        label=" "
                        name={`${props.prefix}_fillEmpty`}
                        extra={{
                            type: ComponentType.checkbox,
                            value: "Fill Empty Data",
                            itemProps: {
                                colon: false,
                            },
                        }}
                    />
                </Col>
            </Row>
        </>
    );
};

export default LineTemplate;
