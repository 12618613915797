import { BellFilled, InfoCircleOutlined, WarningOutlined } from "@ant-design/icons";
import { Badge, Button, Dropdown, notification } from "antd";
import { useEffect, useRef, useState } from "react";
import { apiRequest, APIs } from "../../services/apiConfig";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { incrementNotifCount, setIsWsConnected, setNotifCount } from "../../store/reducers/system";
import SignalRHelper from "../../helpers/signalRHelper";
import alarmSound from "../../assets/audios/hall-alarm.wav";
import useAudio from "../../helpers/audioHelper";
import AuthHelper from "../../helpers/authHelper";
import { ErrorCatchValidator } from "../../utils/Common";
import NotificationPopover from "./NotifcationPopover";
import { NotifMessageObj, NotifResult, NotificationListByTab, NotificationTotalByTab } from "../../constants/type";
import { NOTIFICATION_LEVEL_IDS } from "../../constants";
import { useNavigate } from "react-router-dom";

const Notifications = () => {
    let navigate = useNavigate();
    const [playing, toggle] = useAudio(alarmSound);

    const [sRConnection] = useState<SignalRHelper>(new SignalRHelper("tp-notification", `/notification`));
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const systemState = useAppSelector((state: any) => state.system);
    const dispatch = useAppDispatch();
    const isMute = useRef<boolean>(systemState.isMuteAlarm);
    const authHp = new AuthHelper();
    const [notificationList, setNotificationList] = useState<NotificationListByTab>({ "1,2": [], "3,4": [] });
    const [notificationTotal, setNotificationTotal] = useState<NotificationTotalByTab>({
        "1,2": { total: 0, unReadTotal: 0 },
        "3,4": { total: 0, unReadTotal: 0 },
    });
    const notificationListRef = useRef<NotificationListByTab>({ "1,2": [], "3,4": [] });
    const notificationTotalRef = useRef<NotificationTotalByTab>({ "1,2": { total: 0, unReadTotal: 0 }, "3,4": { total: 0, unReadTotal: 0 } });

    const getInitialData = () => {
        apiRequest(APIs.GET_NOTIFICATION_CONTENTS_LIST, {
            current: 1,
            limit: 1,
            type: 1,
            levels: [[1, 2, 3, 4]],
        })
            .then((data: any) => dispatch(setNotifCount(data[0].unReadTotal)))
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => console.log("Failed to get notifications list: ", err)));
    };

    const getNotificationList = () => {
        setIsLoading(true);
        apiRequest(APIs.GET_NOTIFICATION_CONTENTS_LIST, {
            current: 1,
            limit: 10,
            order: "CreatedDateUtc,DESC",
            type: 1,
            levels: [
                [3, 4],
                [1, 2],
            ],
        })
            .then((data: any) => {
                let tmp: any = {},
                    tmpTotal: any = {};
                data.forEach((currData: any) => {
                    tmp[`${currData.levelIds}`] = [...currData.result];
                    tmpTotal[`${currData.levelIds}`] = { total: currData.total, unReadTotal: currData.unReadTotal };
                });
                setNotificationList((prev: any) => ({ ...prev, ...tmp }));
                setNotificationTotal((prev: any) => ({ ...prev, ...tmpTotal }));
                notificationListRef.current = { ...notificationListRef.current, ...tmp };
                notificationTotalRef.current = { ...notificationTotalRef.current, ...tmpTotal };
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => console.log("Failed to get notifications list: ", err)))
            .finally(() => setIsLoading(false));
    };

    const messageToNotifItem = (messageObj: NotifMessageObj) => {
        return {
            content: JSON.stringify({ subject: messageObj.Subject, content: messageObj.Content }),
            levelId: messageObj.LevelId,
            typeId: messageObj.TypeId,
            createdDateUtc: messageObj.CreatedDateUtc,
            isRead: false,
            uid: messageObj.MessageUid,
            createdByName: "Insight System",
            contentId: messageObj.ContentId,
        };
    };

    const updateNotifWarningTab = (newNotifItem: NotifResult) => {
        let updatedWarningTabList = [newNotifItem, ...notificationListRef.current["3,4"]];
        let updatedWarningTabTotal = {
            total: notificationTotalRef.current["3,4"].total + 1,
            unReadTotal: notificationTotalRef.current["3,4"].unReadTotal + 1,
        };
        setNotificationList((prev: any) => ({ ...prev, "3,4": updatedWarningTabList }));
        setNotificationTotal((prev: any) => ({ ...prev, "3,4": updatedWarningTabTotal }));
        notificationListRef.current = { ...notificationListRef.current, "3,4": updatedWarningTabList };
        notificationTotalRef.current = { ...notificationTotalRef.current, "3,4": updatedWarningTabTotal };
    };

    useEffect(() => {
        sRConnection.setHandler((hubConnection: any) => {
            dispatch(setIsWsConnected(true));

            hubConnection.on("BroadcastNotification", (message: any) => {
                if (message.length > 0) {
                    let messageObj = JSON.parse(message);

                    dispatch(incrementNotifCount());

                    switch (messageObj.LevelId) {
                        case NOTIFICATION_LEVEL_IDS.HIGH:
                            let newHighNotifItem: NotifResult = messageToNotifItem(messageObj);
                            updateNotifWarningTab(newHighNotifItem);
                            return notification.warning({
                                message: "You have a new notification",
                            });

                        case NOTIFICATION_LEVEL_IDS.CRITICAL:
                            let newCriticalNotifItem: NotifResult = messageToNotifItem(messageObj);
                            updateNotifWarningTab(newCriticalNotifItem);
                            return notification.open({
                                message: "You have a new notification",
                                icon: <WarningOutlined style={{ color: "#F44336" }} />,
                            });

                        case NOTIFICATION_LEVEL_IDS.LOW:
                        case NOTIFICATION_LEVEL_IDS.NORMAL:
                        default:
                            let newDefaultNotifItem: NotifResult = messageToNotifItem(messageObj);
                            let updatedDefaultList = [newDefaultNotifItem, ...notificationListRef.current["1,2"]];
                            let updatedDefaultTotal = {
                                total: notificationTotalRef.current["1,2"].total + 1,
                                unReadTotal: notificationTotalRef.current["1,2"].unReadTotal + 1,
                            };
                            setNotificationList((prev: any) => ({ ...prev, "1,2": updatedDefaultList }));
                            setNotificationTotal((prev: any) => ({ ...prev, "1,2": updatedDefaultTotal }));
                            notificationListRef.current = { ...notificationListRef.current, "1,2": updatedDefaultList };
                            notificationTotalRef.current = { ...notificationTotalRef.current, "1,2": updatedDefaultTotal };
                            return notification.open({
                                message: "You have a new notification",
                                icon: <InfoCircleOutlined style={{ color: "#1890ff" }} />,
                            });
                    }
                }
            });
        });
        sRConnection.startConnection();

        getInitialData();

        return () => {};
    }, []);

    useEffect(() => {
        if (systemState.isMuteAlarm) {
            playing && toggle();
        }
        isMute.current = systemState.isMuteAlarm;
        return () => {};
    }, [systemState.isMuteAlarm]);

    return (
        <div className="header-notification">
            <Dropdown
                menu={{
                    items: [
                        {
                            key: "view-all",
                            label: <div className="notification-view-all-option">View All</div>,
                            onClick: () => navigate("/notifications"),
                        },
                    ],
                }}
                trigger={["click"]}
                placement="bottomRight"
                dropdownRender={(menu) => (
                    <div>
                        <NotificationPopover
                            notificationList={notificationList}
                            notificationTotal={notificationTotal}
                            isLoading={isLoading}
                            setListCallback={(newListData: any) => {
                                setNotificationList((prev: any) => ({ ...prev, ...newListData }));
                                notificationListRef.current = { ...notificationListRef.current, ...newListData };
                            }}
                            setTotalCallback={(newTotal: any) => {
                                setNotificationTotal((prev: any) => ({ ...prev, ...newTotal }));
                                notificationTotalRef.current = { ...notificationTotalRef.current, ...newTotal };
                            }}
                        />
                        {menu}
                    </div>
                )}
            >
                <Button
                    shape="circle"
                    size="middle"
                    className={`notification-button ${systemState.notifCount > 0 && "active"}`}
                    icon={
                        <Badge count={systemState.notifCount} overflowCount={99}>
                            <BellFilled rotate={25} className="notif-icon" />
                        </Badge>
                    }
                    onClick={() => getNotificationList()}
                />
            </Dropdown>
        </div>
    );
};

export default Notifications;
