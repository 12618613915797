import { Tabs } from "antd";
import { useMemo } from "react";
import IntradayMonitorAlarmConfiguration from "./Configuration";
import IntradayMonitorAlarmListing from "./List";
import AuthHelper, { AuthKeys } from "../../../helpers/authHelper";

export interface IntradayMonitorAlarmProps {}

const IntradayMonitorAlarm = (props: IntradayMonitorAlarmProps) => {
    const authHp = new AuthHelper();
    const enableEdit = authHp.isAuthorized(AuthKeys.INTRADAY_ALARM_EDIT);
    const tabItems = useMemo(() => {
        let defaultList = [];
        if (authHp.isAuthorized(AuthKeys.INTRADAY_ALARM_VIEW)) {
            defaultList.push({
                label: `Alarm Listing`,
                key: "1",
                children: <IntradayMonitorAlarmListing />,
            });
        }
        if (authHp.isAuthorized(AuthKeys.INTRADAY_ALARM_EDIT)) {
            defaultList.push({
                label: `Alarm Configuration`,
                key: "2",
                children: <IntradayMonitorAlarmConfiguration />,
            });
        }
        return defaultList;
    }, [enableEdit]);

    return (
        <>
            <Tabs type="card" defaultActiveKey="1" destroyInactiveTabPane tabBarStyle={{ marginBottom: "0" }} items={tabItems} />
        </>
    );
};

export default IntradayMonitorAlarm;
