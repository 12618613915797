import { useMemo, useState } from "react";
import { Button, Divider, message, Select, Space, Tooltip, Typography } from "antd";
import FlexiDataTable from "@/components/FlexiDataTable";
import { DTColProps } from "@/utils/Common";
import { FlexiDataTableCallbackProps, FlexiDataTableOptionsProps, KeyValuePair, RollOverBatchRecordList, RollOverSupplementaryList } from "@/constants/type";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { APIs } from "@/services/apis";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { CALLBACK_KEY, ComponentType } from "@/constants";
import moment from "moment";
import {
    CaretRightOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    DownloadOutlined,
    InfoCircleOutlined,
    PlayCircleOutlined,
    SyncOutlined,
} from "@ant-design/icons";

const { Title, Text } = Typography;

export interface RollOverSupplementaryTableProps {
    data: RollOverSupplementaryList[];
    tbTitle: string;
    currentState: RollOverBatchRecordList;
    loading: boolean;
    supplementaryExecutestatus: any;
    executeSupplementary: () => void;
    getExecuteStatusLabel: (status: any) => string[];
    refreshSupplementaryStatus: () => void;
    finishSupplementaryStep: () => void;
    serverDetailList: KeyValuePair[];
    callDataBasedOnID: (secID: string, sec: number) => void;
}

const RollOverSuppllementaryTable = (props: RollOverSupplementaryTableProps) => {
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.RISK_TOOLS_ROLLOVER_EDIT);
    const [supplementedServerId, setSupplementedServerId] = useState("AU1");

    const columns = [
        DTColProps.XSmall({
            title: "Server",
            dataIndex: "server",
            key: "server",
            sorter: (a: any, b: any) => a.server - b.server,
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: props.serverDetailList,
                },
            },
        }),
        DTColProps.XSmall({
            title: "Login",
            dataIndex: "login",
            key: "login",
        }),
        DTColProps.XSmall({
            title: "Adjustment Product",
            dataIndex: "adjustmentProduct",
            key: "adjustmentProduct",
        }),
        DTColProps.XSmall({
            title: "Currency Product",
            dataIndex: "currencyProduct",
            key: "currencyProduct",
        }),
        DTColProps.XSmall({
            title: "Exchange Rate",
            dataIndex: "exchangeRate",
            key: "exchangeRate",
        }),
        DTColProps.XSmall({
            title: "Adjustment Client",
            dataIndex: "adjustmentClient",
            key: "adjustmentClient",
        }),
        DTColProps.XSmall({
            title: "Currency Client",
            dataIndex: "currencyClient",
            key: "currencyClient",
        }),
        DTColProps.XSmall({
            title: "Comment",
            dataIndex: "comment",
            key: "comment",
        }),
        DTColProps.XSmall(
            {
                title: "Supplemented",
                dataIndex: "status",
                key: "status",
                render: (text: number) => {
                    return (
                        <span>
                            {text === 1 ? (
                                <CheckCircleOutlined style={{ color: "#0ab76e", fontSize: "1.375rem" }} />
                            ) : (
                                <CloseCircleOutlined style={{ color: "#f00f00", fontSize: "1.375rem" }} />
                            )}
                        </span>
                    );
                },
            },
            ["text-center"]
        ),
        DTColProps.XSmall({
            title: "Error Message",
            dataIndex: "errorMsg",
            key: "errorMsg",
        }),
    ];

    const options: FlexiDataTableOptionsProps = useMemo(
        () => ({
            showHideColumns: false,
            enableFilter: true,
            extraButtons: () => (
                <Space key={`btns-${Math.random()}`}>
                    <Button
                        key={`btn-exec-supplementary-${Math.random()}`}
                        style={{ marginLeft: "0.651vw", width: "auto" }}
                        icon={<PlayCircleOutlined />}
                        disabled={props.currentState.currentStep != 2 || props.loading || props.supplementaryExecutestatus.status === 1}
                        onClick={event => {
                            event.preventDefault();
                            event.stopPropagation();
                            props.executeSupplementary();
                        }}
                    >
                        Click execute supplementary
                    </Button>
                    <Tooltip title="Execute supplementary" placement="topLeft">
                        <InfoCircleOutlined style={{ fontSize: "1.125rem", cursor: "pointer", marginLeft: "0.5vw" }} />
                    </Tooltip>
                    <Button
                        key={`btn-finish-supplementary-step-${Math.random()}`}
                        type="primary"
                        icon={<CheckCircleOutlined />}
                        onClick={props.finishSupplementaryStep}
                        loading={props.loading}
                        disabled={props.currentState.currentStep != 2 || props.loading}
                    >
                        Finish supplementary step
                    </Button>
                </Space>
            ),
        }),
        [props.data, props.loading, props.currentState.currentStep, props.supplementaryExecutestatus.status]
    );

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                props.callDataBasedOnID(`${FormData["server"]}`, 2);
                break;
            default:
                break;
        }
    };

    const onDownloadNotYetSupplementedCSV = () => {
        try {
            plainAxiosInstance
                .get(
                    `${APIs.RISK_TOOL.GET_ROLLOVER_DOWNLOAD_SUPPLEMENTARY_DEDUCTION}?batchId=${props.currentState.id}&serverId=${supplementedServerId}&type=1`,
                    {
                        headers: {
                            Accept: "application/octet-stream,text/csv, */*",
                        },
                        responseType: "blob",
                    }
                )
                .then(response => {
                    const contentType = response.headers["content-type"];
                    const contentDisposition = response.headers["content-disposition"] || response.headers["Content-Disposition"];

                    if (
                        contentType === "application/octet-stream" ||
                        contentType === "text/csv" ||
                        contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    ) {
                        // Extract filename from content-disposition if available
                        let fileName = `DownloadSupplementaryDeduction_${moment().format("YYYYMMDDHHmmss")}.csv`;
                        if (contentDisposition) {
                            const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
                            if (fileNameMatch && fileNameMatch[1]) {
                                fileName = fileNameMatch[1];
                            }
                        }

                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", fileName);
                        document.body.appendChild(link);
                        link.click();
                        // Clean up
                        window.URL.revokeObjectURL(url);
                    } else {
                        message.error(`Received non-file response. Error: ${response}`, 3);
                    }
                })
                .catch((error: any) => message.error(`Error occurred during download: "${error.message}"`, 3));
        } catch (e: any) {
            message.error(`Error occured during download: "${e.message}"`, 3);
        }
    };

    return (
        <>
            <br />
            <Divider style={{ borderColor: "#FFA500" }}>{props.tbTitle}</Divider>
            <div className="execute-status">
                <Title
                    level={5}
                    type={
                        props.supplementaryExecutestatus.status == -1
                            ? "secondary"
                            : props.supplementaryExecutestatus.status == 1
                                ? "warning"
                                : props.supplementaryExecutestatus.status == 2
                                    ? "success"
                                    : props.supplementaryExecutestatus.status == 3
                                        ? "danger"
                                        : undefined
                    }
                >
                    Execute status: {props.getExecuteStatusLabel(props.supplementaryExecutestatus.status)}
                </Title>
                <Text disabled={props.supplementaryExecutestatus.status == -1}>
                    {props.supplementaryExecutestatus.successCount} / {props.supplementaryExecutestatus.allCount}
                </Text>
            </div>
            <FlexiDataTable
                rowKeyProperty="login"
                title={false}
                columns={columns}
                options={options}
                dataSource={props.data}
                loading={props.loading}
                callback={componentCallback}
                pagination={{ defaultPageSize: 10 }}
                scroll={{ x: true }}
                filterInitialValue={{ server: "" }}
            />
            <Divider />
            <Space>
                <Select
                    style={{ width: 120 }}
                    value={supplementedServerId}
                    options={props.serverDetailList.filter(server => server.value !== "")}
                    optionFilterProp="label"
                    onChange={setSupplementedServerId}
                ></Select>
                <Button type="primary" icon={<DownloadOutlined />} onClick={onDownloadNotYetSupplementedCSV}>
                    Download not yet supplemented CSV
                </Button>
                <CaretRightOutlined />
                <Button type="primary" icon={<SyncOutlined />} onClick={props.refreshSupplementaryStatus}>
                    Refresh supplemented status
                </Button>
            </Space>
        </>
    );
};
export default RollOverSuppllementaryTable;
