import { Empty } from "antd";
import { DataTableColumnRender, ErrorCatchValidator, ErrorMessageHandler, currencyRender } from "../../../../utils/Common";
import TinySummaryChart2 from "../TinySummaryChart2";
import { useCallback, useEffect, useState } from "react";
import { LPMetricsTrendInterval } from "../../../../constants/type";
import { SUCCESS_FAILED } from "../../../../constants";
import { apiRequest } from "../../../../services/apiConfig";
import { APIs } from "../../../../services/apis";
import LoadingComponent from "../../../../components/Loading";

export interface LP360TrendIntervalDataProps {
    SelectedLP: string;
}

const LP360TrendIntervalData = (props: LP360TrendIntervalDataProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [LPMetricsTrendIntervalData, setLPMetricsTrendIntervalData] = useState<LPMetricsTrendInterval[]>([]);
    const [lastUpdatedTime, setLastUpdatedTime] = useState<string | undefined>(undefined);

    const getLPMetricsTrendIntervalData = useCallback(() => {
        setIsLoading(true);
        const [lpValue, accountId, marketGroupId] = props.SelectedLP.split("-");
        apiRequest(APIs.GET_METRIC_TREND_INTERVAL, {
            lPs: [lpValue],
            accountIds: [accountId],
            marketGroupIds: [marketGroupId ? Number(marketGroupId) : marketGroupId],
            historicalTrendInMinutes: 1440,
            frequencyInMinutes: 10,
            viewExposureByAccountLevel: true,
        })
            .then((res: any) => {
                setLastUpdatedTime(res.latestCreatedDateUtc);
                setLPMetricsTrendIntervalData(res.lpMetricTrendResults);
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("LP Metric Trend Interval", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsLoading(false));
    }, [props.SelectedLP]);

    useEffect(() => {
        if (props.SelectedLP !== "") {
            getLPMetricsTrendIntervalData();
        } else {
            setLPMetricsTrendIntervalData([]);
            setIsLoading(false);
        }
    }, [props.SelectedLP]);

    return (
        <>
            {lastUpdatedTime && (
                <div className="last-refresh-time">
                    <span>Last Sync (local): {DataTableColumnRender.DateTime(lastUpdatedTime)}</span>
                </div>
            )}
            <div className="list-item-container">
                {isLoading ? (
                    <LoadingComponent />
                ) : LPMetricsTrendIntervalData.length > 0 ? (
                    LPMetricsTrendIntervalData.map((x: LPMetricsTrendInterval) => (
                        <TinySummaryChart2
                            key={x.metric}
                            title={x.metricDesc}
                            value={currencyRender(x.total, 0)}
                            {...(x.metric === 1 && {
                                valueToolTips: <>Sum of absolute value of position's exposure</>,
                            })}
                            enableChangePercentage
                            tinyChartProps={{
                                data: x.metricTrend.map(y => parseFloat(y.total.toFixed(2))),
                                customContent: (title: any, data: any) => {
                                    if (data && data.length > 0) {
                                        return (
                                            <div className="tiny-chart-tooltips-custom-container">
                                                <span className="title">
                                                    {DataTableColumnRender.DateTime_ServerTime(x.metricTrend[parseInt(data[0].title)].serverDateTime)}
                                                </span>
                                                <span>{currencyRender(data[0].value, 2)}</span>
                                            </div>
                                        );
                                    }
                                    return "";
                                },
                            }}
                        />
                    ))
                ) : (
                    <Empty />
                )}
            </div>
        </>
    );
};

export default LP360TrendIntervalData;
