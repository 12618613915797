import React, { useState, useEffect } from "react";
import { Tabs } from "antd";
import TabDaily from "./TabDaily";
import TabWeekend from "./TabWeekend";
import TabRA from "./TabRA";
import TabAdmin from "./TabAdmin";
import SystemBreadCrumbs from "../components/SystemBreadCrumbs";

const StatusMonitor = () => {
    const [currTab, setCurrTab] = useState<string | null>(null);

    // Ensure user can change tab by URL params
    useEffect(() => {
        const currTabParam = new URLSearchParams(window.location.search).get("tab");
        if (currTabParam) {
            setCurrTab(currTabParam);
        } else {
            setCurrTab("ra");
        }
    }, []);

    // Handle popstate event
    useEffect(() => {
        const handlePopState = () => {
            const currTabParam = new URLSearchParams(window.location.search).get("tab");
            if (currTabParam) {
                setCurrTab(currTabParam);
            } else {
                setCurrTab("ra");
            }
        };

        window.addEventListener("popstate", handlePopState);

        return () => {
            window.removeEventListener("popstate", handlePopState);
        };
    }, []);

    const handleChangeTab = (activeKey: string) => {
        setCurrTab(activeKey);
        window.history.pushState({}, "", `${window.location.pathname}?tab=${activeKey}`);
    };
    if (!currTab) return null;
    return (
        <>
            <SystemBreadCrumbs currMenu="Status Monitor" />
            <Tabs
                activeKey={currTab}
                onChange={handleChangeTab}
                type="card"
                style={{ marginBottom: 0 }}
                items={[
                    {
                        label: "RA",
                        key: "ra",
                        children: currTab === "ra" && <TabRA />,
                    },
                    {
                        label: "Admin",
                        key: "admin",
                        children: currTab === "admin" && <TabAdmin />,
                    },
                    {
                        label: "Daily",
                        key: "daily",
                        children: currTab === "daily" && <TabDaily />,
                    },
                    {
                        label: "Weekend",
                        key: "weekend",
                        children: currTab === "weekend" && <TabWeekend />,
                    },
                ]}
                tabBarStyle={{ marginBottom: 0 }}
            />
        </>
    );
};

export default StatusMonitor;
