import { useEffect, useMemo, useState } from "react";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { CALLBACK_KEY, ComponentType, InnerPageActionMode, SUCCESS_FAILED } from "@/constants";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { Button, Form, message, Modal, Table, Tooltip } from "antd";
import { CheckOutlined, CommentOutlined, EditOutlined, WarningOutlined } from "@ant-design/icons";
import FlexiDataTable from "@/components/FlexiDataTable";
import { FlexiDataTableCallbackProps, FlexiDataTableOptionsProps, HightlightExpression } from "@/constants/type";
import SoundButton from "@/pages/SystemMonitor/components/SoundButton";
import peAlarm from "@/assets/audios/rc/pe-alarm.mp3";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { APIs } from "@/services/apis";
import { FormComponent } from "@/components/FormComponent";
import { REQUIRED_FIELD } from "@/constants/errorMessage";

interface MidBiasDataSummary {
    serverUno: number;
    serverName: string;
    mtLogin: number;
    takerOrderId: number;
    peSymbol: string;
    mtSymbol: string;
    peGroup: string;
    mtGroup: string;
    peSide: number;
    mtSide: number;
    pePrice: number;
    mtPrice: number;
    peTime: string;
    peTimeStr: string;
    mtTime: string;
    mtTimeStr: string;
    checkTime: string;
    checkTimeStr: string;
    checkUser: string;
    comment: string;
    status: number;
    doneTime: string;
    doneTimeStr: string;
    doneUser: string;
    newKey?: string;
};

const DataTab = () => {
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.RC.RC_PE_ORDER_COMPARE_EDIT);

    const [data, setData] = useState<MidBiasDataSummary[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    const [currEdit, setCurrEdit] = useState<InnerPageActionMode.CREATE_NEW | MidBiasDataSummary | null>(null);
    const [commentForm] = Form.useForm();
    const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [isChecking, setIsChecking] = useState<boolean>(false);
    const [selectedRows, setSelectedRows] = useState<MidBiasDataSummary[]>([]);
    const [isSolving, setIsSolving] = useState<boolean>(false);

    const alarmTime = 60000 * 5;
    const checkTime = 60000 * 5;
    const [timerFlag, setTimerFlag] = useState<boolean>(true);
    const [playAudio, setPlayAudio] = useState<boolean>(false);

    const checkAlarm = () => {
        const result = data.some((item: any) => item.status === 0);
        if (result) {
            setPlayAudio(true);
        } else {
            setPlayAudio(false);
        };
    };

    const columns = useMemo(() => {
        const highlightLogin: HightlightExpression = (peLogin: number | string, rowData: any) => peLogin !== rowData.mtLogin;
        const highlightSymbol: HightlightExpression = (peSymbol: string, rowData: any) => peSymbol !== rowData.mtSymbol;
        const highlightGroup: HightlightExpression = (peGroup: string, rowData: any) => peGroup !== rowData.mtGroup;
        const highlightFPrice: HightlightExpression = (pePrice: number, rowData: any) => pePrice !== rowData.mtPrice;
        const highlightTime: HightlightExpression = (peTimeStr: string, rowData: any) => {
            const peTimeMillis = new Date(peTimeStr).getTime();
            const mtTimeMillis = new Date(rowData.mtTimeStr).getTime();
            const timeDifference = Math.abs(peTimeMillis - mtTimeMillis);
            return timeDifference >= 2000 ? true : false;
        };

        return [
            DTColProps.XSmall({
                title: "Server",
                dataIndex: "serverName",
                key: "serverName",
                fixed: "left",
                sorter: (a: any, b: any) => a.serverName.localeCompare(b.serverName),
                options: {
                    filter: {
                        type: ComponentType.text,
                        value: "",
                    },
                },
            }),
            DTColProps.XSmall(
                {
                    title: "Login",
                    dataIndex: "peLogin",
                    key: "peLogin",
                    fixed: "left",
                    align: "left",
                    sorter: (a: any, b: any) => a.peLogin - b.peLogin,
                    options: {
                        filter: {
                            type: ComponentType.text,
                            value: "",
                        },
                    },
                },
                [],
                highlightLogin,
                "row-yellow"
            ),
            DTColProps.XSmall({
                title: "Ticket",
                dataIndex: "takerOrderId",
                key: "takerOrderId",
                sorter: (a: any, b: any) => a.takerOrderId - b.takerOrderId,
                options: {
                    filter: {
                        type: ComponentType.text,
                        value: "",
                    },
                },
            }),
            DTColProps.Small(
                {
                    title: "Symbol",
                    dataIndex: "peSymbol",
                    key: "peSymbol",
                    options: {
                        filter: {
                            type: ComponentType.text,
                            value: "",
                        },
                    },
                },
                [],
                highlightSymbol,
                "row-yellow"
            ),
            DTColProps.Middle(
                {
                    title: "Group",
                    dataIndex: "peGroup",
                    key: "peGroup",
                    options: {
                        filter: {
                            type: ComponentType.text,
                            value: "",
                        },
                    },
                },
                [],
                highlightGroup,
                "row-yellow"
            ),
            DTColProps.XXSmall({
                title: "Side",
                dataIndex: "peSide",
                key: "peSide",
                sorter: (a: any, b: any) => a.peSide.localeCompare(b.peSide),
                render: (peSide: number, rowData: any) => <span className={peSide !== rowData.mtSide ? `row-yellow` : ""}>{
                    peSide === 0 ? "Buy"
                        : peSide === 1 ? "Sell"
                            : peSide
                }</span>,
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: [
                            { text: "Buy", value: 0 },
                            { text: "Sell", value: 1 },
                        ],
                    },
                },
            }),
            DTColProps.Small(
                {
                    title: "Fill Price",
                    dataIndex: "pePrice",
                    key: "pePrice",
                    sorter: (a: any, b: any) => a.pePrice - b.pePrice,
                },
                [],
                highlightFPrice,
                "row-yellow"
            ),
            DTColProps.Middle(
                {
                    title: "Time",
                    dataIndex: "peTimeStr",
                    key: "peTimeStr",
                    width: "10vw",
                },
                [],
                highlightTime,
                "row-yellow"
            ),
            DTColProps.Middle({
                title: "Check Time",
                dataIndex: "checkTimeStr",
                key: "checkTimeStr",
                width: "10vw",
            }),
            DTColProps.Small({
                title: "Comment",
                dataIndex: "comment",
                key: "comment",
                fixed: "right",
                options: {
                    filter: {
                        type: ComponentType.text,
                        value: "",
                    },
                },
            }),
            ...(enableUpdate ? [
                DTColProps.XXSmall({
                    title: "",
                    dataIndex: "actions",
                    key: "actions",
                    fixed: "right",
                    render: (_: any, rowData: any) => (
                        <div>
                            <Tooltip title="Edit Comment" placement="right" key={`comment-${rowData.newKey}`}>
                                <Button
                                    type="text"
                                    icon={<CommentOutlined />}
                                    onClick={() =>
                                        componentCallback(CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK, { key: "edit-comment", data: rowData })
                                    }
                                />
                            </Tooltip>
                        </div>
                    ),
                },
                    ["text-center"]
                ),
            ] : []),
        ]
    }, [enableUpdate]);

    const modalCols = [
        DTColProps.XSmall({
            title: "Server",
            dataIndex: "serverName",
            key: "serverName",
            sorter: (a: any, b: any) => a.serverName.localeCompare(b.serverName),
        }),
        DTColProps.Small({
            title: "Ticket",
            dataIndex: "takerOrderId",
            key: "takerOrderId",
            sorter: (a: any, b: any) => a.takerOrderId - b.takerOrderId,
        }),
    ];

    const options: FlexiDataTableOptionsProps = useMemo(() => ({
        separateActionButton: true,
        extraButtons: () => {
            return (
                <div className="peoc-d-extra-buttons" key={"peoc-d-eb"}>
                    {enableUpdate ? (
                        <div key={"peoc-d-b"} className="extra-table-header-buttons">
                            <Button key={"comment"} icon={<CommentOutlined />} loading={isBtnLoading} disabled={selectedRowKeys.length === 0} onClick={event => {
                                event.preventDefault();
                                event.stopPropagation();
                                componentCallback(CALLBACK_KEY.OTHERS, "comment");
                            }}>
                                Batch Comment
                            </Button>
                            <Button key={"check"} icon={<EditOutlined />} loading={isChecking} disabled={selectedRowKeys.length === 0} onClick={event => {
                                event.preventDefault();
                                event.stopPropagation();
                                componentCallback(CALLBACK_KEY.OTHERS, "check");
                            }}>
                                Check
                            </Button>
                            <Button key={"done"} icon={<CheckOutlined />} loading={isSolving} disabled={selectedRowKeys.length === 0} onClick={event => {
                                event.preventDefault();
                                event.stopPropagation();
                                componentCallback(CALLBACK_KEY.OTHERS, "done");
                            }}>
                                Done
                            </Button>
                        </div>
                    ) : null}
                    {/* <div key={"alarm-btn"} className="alarm-btn">
                        <SoundButton soundSrc={peAlarm} isActive={playAudio} />
                    </div> */}
                </div>
            );
        },
        ...(enableUpdate && {
            enableRowSelection: true,
            hideRowSelectionsSummary: true,
            rowSelectionData: {
                rowSelectionType: "checkbox",
                selectedRowKeys: selectedRowKeys,
                options: {
                    fixed: "left",
                    selections: [
                        Table.SELECTION_ALL,
                        Table.SELECTION_NONE,
                    ],
                    preserveSelectedRowKeys: true,
                    disabled: data.length === 0 || isLoading,
                },
            },
        }),
    }), [enableUpdate, isLoading, selectedRowKeys, data]);

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK:
                switch (FormData.key) {
                    case "edit-comment":
                        setCurrEdit(FormData.data);
                        commentForm.setFieldsValue({
                            serverUno: FormData.data.serverUno,
                            takerOrderId: FormData.data.takerOrderId,
                            comment: FormData.data.comment,
                        });
                        break;
                    default:
                        break;
                };
                break;
            case CALLBACK_KEY.OTHERS:
                switch (FormData) {
                    case "comment":
                        setCurrEdit(InnerPageActionMode.CREATE_NEW);
                        break;
                    case "check":
                        checkData();
                        break;
                    case "done":
                        onAgreeSolve();
                        break;
                    default:
                        break;
                };
                break;
            case CALLBACK_KEY.ROW_SELECTION_CALLBACK:
                setSelectedRowKeys(FormData.selectedRowKeys);
                setSelectedRows(FormData.selectedRows);
                break;
            default:
                break;
        };
    };

    const onFormSubmit = () => {
        commentForm
            .validateFields()
            .then((values: any) => {
                let reqStr: string = "";
                if (currEdit === InnerPageActionMode.CREATE_NEW) {
                    // Batch Comment
                    reqStr = selectedRowKeys + ";" + values.comment;
                } else {
                    reqStr = currEdit ? `${currEdit.serverUno}|${currEdit.takerOrderId};${values.comment}` : "";
                };
                setIsBtnLoading(true);
                const formData = new FormData();
                formData.append("keyStr", reqStr);
                plainAxiosInstance
                    .post(`${APIs.RC_PE_ORDER_COMPARE.POST_PEOC_COMMENT}`, formData)
                    .then((res: any) => {
                        if (res.data > 0) {
                            ErrorMessageHandler(`${currEdit === InnerPageActionMode.CREATE_NEW ? "Batch comment(s)" : "Comment"}`, SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                            setCurrEdit(null);
                            setRunRefetchDataList(true);
                            commentForm.resetFields();
                            setSelectedRowKeys([]);
                        } else {
                            message.error(`Failed to update ${currEdit === InnerPageActionMode.CREATE_NEW ? "batch comment(s)" : "comment"}.`, 3);
                        };
                    })
                    .catch((error: any) => ErrorCatchValidator(error, (err: any) => {
                        ErrorMessageHandler(`${currEdit === InnerPageActionMode.CREATE_NEW ? "comment(s)" : "comment"}`, SUCCESS_FAILED.FAILED_UPDATE_DATA, err);
                    }))
                    .finally(() => setIsBtnLoading(false));
            })
    };

    const getHistoricalData = () => {
        setIsLoading(true);
        plainAxiosInstance
            .get(`${APIs.RC_PE_ORDER_COMPARE.GET_PEOC_HISTORICAL_DATA}`)
            .then((res: any) => {
                if (res.data.length > 0) {
                    let newData = res.data.map((x: MidBiasDataSummary) => ({
                        ...x,
                        newKey: `${x.serverUno}|${x.takerOrderId}`,
                    }));
                    setData(newData);
                } else setData([]);
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => {
                ErrorMessageHandler("mid bias data summary", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                setData([]);
            }))
            .finally(() => setIsLoading(false));
    };

    const checkData = () => {
        let reqStr = selectedRowKeys.join(",");
        const formData = new FormData();
        formData.append("keyStr", reqStr);
        setIsChecking(true);
        plainAxiosInstance
            .post(`${APIs.RC_PE_ORDER_COMPARE.POST_PEOC_CHECK}`, formData)
            .then((res: any) => {
                if (res.data > 0) {
                    message.success("Check success.", 3);
                    setRunRefetchDataList(true);
                    setSelectedRowKeys([]);
                    setSelectedRows([]);
                } else {
                    message.error("Check error.", 3);
                };
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => {
                message.error(`Check error. ${err ? err.message : ""}`, 3);
            }))
            .finally(() => setIsChecking(false));
    };

    const onAgreeSolve = () => {
        const allStatusAreOne = selectedRows.every((x: any) => x.status === 1);
        if (!allStatusAreOne) {
            Modal.confirm({
                icon: <WarningOutlined />,
                title: "Error!",
                content: "All selected rows must be checked before proceeding.",
                centered: true,
            });
            return;
        } else {
            Modal.confirm({
                width: "40%",
                title: "Are you sure you want to solve these selected rows?",
                content: <div>
                    <Table
                        size="small"
                        rowKey={(record: any) => record.newKey}
                        columns={modalCols}
                        dataSource={selectedRows}
                        pagination={selectedRowKeys.length < 11 ? false : {}}
                    />
                </div>,
                centered: true,
                okText: "Submit",
                onOk: () => {
                    const formData = new FormData();
                    formData.append("keyStr", selectedRowKeys.join(","));
                    setIsSolving(true);
                    plainAxiosInstance
                        .post(`${APIs.RC_PE_ORDER_COMPARE.POST_PEOC_SOLVED}`, formData)
                        .then((res: any) => {
                            if (res.data > 0) {
                                message.success("Selected rows have been solved successfully.", 3);
                                setRunRefetchDataList(true);
                                setSelectedRowKeys([]);
                                setSelectedRows([]);
                            } else {
                                message.error("Failed to solve selected rows.", 3);
                            };
                        })
                        .catch((error: any) => ErrorCatchValidator(error, (err: any) => {
                            message.error(`Failed to solve selected rows. ${err ? err.message : ""}`, 3);
                        }))
                        .finally(() => setIsSolving(false));
                }
            });
        };
    };

    useEffect(() => {
        getHistoricalData();
        const timer = setInterval(() => {
            getHistoricalData();
        }, alarmTime);
        const timer2 = setInterval(() => {
            setTimerFlag(true);
        }, checkTime);

        return () => {
            clearInterval(timer);
            clearInterval(timer2);
        };
    }, []);

    useEffect(() => {
        if (timerFlag) {
            checkAlarm();
            if (data.length > 0) {
                setTimerFlag(false);
            };
        };
    }, [timerFlag]);

    useEffect(() => {
        if (runRefetchDataList) {
            getHistoricalData();
            setRunRefetchDataList(false);
        };
    }, [runRefetchDataList]);

    return (
        <div className="peoc-data-tab">
            <FlexiDataTable
                bordered
                rowKeyProperty="newKey"  // serverUno|takerOrderId
                title=""
                columns={columns}
                options={options}
                dataSource={data}
                callback={componentCallback}
                loading={isLoading}
                pagination={{ defaultPageSize: 50 }}
            />
            <Modal
                title={`${currEdit === InnerPageActionMode.CREATE_NEW ? "Batch Comment" : "Edit Comment"}`}
                open={currEdit !== null}
                okText="Submit"
                okButtonProps={{ loading: isBtnLoading }}
                onOk={onFormSubmit}
                onCancel={() => {
                    setCurrEdit(null);
                    commentForm.resetFields();
                }}
            >
                <Form form={commentForm} initialValues={{ comment: "" }}>
                    <FormComponent
                        label="Comment"
                        name="comment"
                        extra={{
                            type: ComponentType.textarea,
                            value: "",
                            rules: [{ required: true, message: REQUIRED_FIELD }],
                            inputProps: {
                                row: 6,
                            },
                        }}
                    />
                </Form>
            </Modal>
        </div>
    );
};

export default DataTab;