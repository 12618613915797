import { FormComponent } from "@/components/FormComponent";
import LoadingComponent from "@/components/Loading";
import SitePageHeader from "@/components/PageHeader";
import { ComponentType, SUCCESS_FAILED } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { apiRequest } from "@/services/apiConfig";
import { APIs } from "@/services/apis";
import { ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { isEmptyOrNull, defaultIfEmptyOrNull } from "@/utils/string";
import { HomeOutlined } from "@ant-design/icons";
import { Button, Col, Form, Row } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import { DataCenterConfigBaseProps, getDataCenterCombinations } from ".";
import { SortList } from "@/utils/array";

interface CreateEditDataCenterPageProps {}

type CreateEditDataCenterPageState = {
    action: string;
    data: any;
};

const CreateEditDataCenterPage = (props: CreateEditDataCenterPageProps) => {
    let navigate = useNavigate();
    let location = useLocation();
    let com_state: CreateEditDataCenterPageState = location.state as CreateEditDataCenterPageState;
    let { id } = useParams();

    const [fetchDetails, setFetchDetails] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [configState, setConfigState] = useState<DataCenterConfigBaseProps>({
        servers: [],
        dataCenters: [],
        dataCenterCombinationDic: {},
    });
    const [dcForm] = Form.useForm();

    const stateInfo = useMemo(() => {
        let tmpState = Object.assign({ action: "add", data: null }, com_state || {});
        return {
            isAddAction: isEmptyOrNull(id) ? tmpState.action === "add" : false,
            data: defaultIfEmptyOrNull(tmpState.data, id),
        };
    }, [com_state, id]);

    const getDataCenterList = useCallback(() => {
        setIsLoading(true);
        apiRequest(APIs.GET_DATA_CENTER_LIST_V2, { dcId: stateInfo.data })
            .then((data: any) => {
                if (data) {
                    dcForm.setFieldsValue({ ...data, typeFlag: data.typeFlag === 0 ? [] : configState.dataCenterCombinationDic[data.typeFlag] });
                }
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("data center setting", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                navigate("/siteadmin/brandserver/datacenter");
            })
            .finally(() => setIsLoading(false));
    }, [stateInfo, configState]);

    const onSubmit = useCallback(
        (values: any) => {
            setIsLoading(true);
            values = {
                ...values,
                typeFlag: values.typeFlag.reduce((sum: number, x: any) => {
                    return sum + (typeof x !== "number" ? parseInt(x) : x);
                }, 0),
                ...(isEmptyOrNull(values.comment) && { comment: "" }),
            };

            if (stateInfo.isAddAction) {
                apiRequest(APIs.CREATE_DATA_CENTER_V2, values)
                    .then(data => {
                        ErrorMessageHandler("New data center setting", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                        navigate("/siteadmin/brandserver/datacenter");
                    })
                    .catch(error => {
                        ErrorCatchValidator(error, (err: any) =>
                            ErrorMessageHandler("new data center setting", SUCCESS_FAILED.FAILED_CREATE_DATA, err)
                        );
                        setIsLoading(false);
                    });
            } else {
                apiRequest(APIs.UPDATE_DATA_CENTER_V2, values)
                    .then(data => {
                        ErrorMessageHandler("Existing data center setting", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                        navigate("/siteadmin/brandserver/datacenter");
                    })
                    .catch(error => {
                        ErrorCatchValidator(error, (err: any) =>
                            ErrorMessageHandler("existing data center setting", SUCCESS_FAILED.FAILED_UPDATE_DATA, err)
                        );
                        setIsLoading(false);
                    });
            }
        },
        [stateInfo]
    );

    const getConfig = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["riskinsightserver", "riskinsightdatacentertype"] })
            .then((res: any) => {
                setConfigState({
                    servers: SortList(
                        res.riskInsightServers.map((x: any) => ({ text: x.serverDisplayName, value: x.serverId })),
                        "text"
                    ),
                    dataCenters: SortList(
                        res.riskInsightDataCenterTypes.map((x: any) => ({ text: x.name, value: x.type })),
                        "text"
                    ),
                    dataCenterCombinationDic: getDataCenterCombinations(res.riskInsightDataCenterTypes.map((x: any) => x.type)),
                });
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => console.log("data center page config", err)))
            .finally(() => setFetchDetails(true));
    };

    useEffect(() => {
        if (!stateInfo.isAddAction && fetchDetails) {
            getDataCenterList();
        } else {
            dcForm.setFieldsValue({ enable: true });
            setIsLoading(false);
        }
    }, [stateInfo, getDataCenterList, fetchDetails]);

    useEffect(() => {
        getConfig();
    }, []);

    return (
        <div className="create-edit-data-center-page-container">
            <SitePageHeader
                title={stateInfo.isAddAction ? "Create New Data Center Setting" : "Edit Data Center Setting"}
                routes={[
                    {
                        path: "/siteadmin/brandserver/datacenter",
                        breadcrumbName: "Data Center Listing",
                        icon: <HomeOutlined />,
                    },
                    {
                        path: "",
                        breadcrumbName: stateInfo.isAddAction ? "Create New Data Center Setting" : "Edit Data Center Setting",
                    },
                ]}
                onBack={() => navigate("/siteadmin/brandserver/datacenter")}
            >
                {isLoading ? (
                    <div className="loading-container">
                        <LoadingComponent tip="Submitting..." />
                    </div>
                ) : (
                    <Form
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        form={dcForm}
                        layout="horizontal"
                        initialValues={stateInfo.data}
                        onFinish={onSubmit}
                    >
                        <Row>
                            <Col span={15}>
                                <FormComponent label={""} name={"dcId"} extra={{ type: ComponentType.hidden, value: "" }} />
                                <FormComponent
                                    label="Server"
                                    name="serverId"
                                    extra={{
                                        type: ComponentType.dropdown,
                                        value: configState.servers,
                                        rules: [{ required: true, message: REQUIRED_FIELD }],
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={15}>
                                <FormComponent
                                    label="Data Center Name"
                                    name="dcDn"
                                    extra={{
                                        type: ComponentType.text,
                                        value: "",
                                        rules: [{ required: true, message: REQUIRED_FIELD }],
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={15}>
                                <FormComponent
                                    label="Data Center Url"
                                    name="dcUrl"
                                    extra={{
                                        type: ComponentType.text,
                                        value: "",
                                        rules: [{ required: true, message: REQUIRED_FIELD }],
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={15}>
                                <FormComponent
                                    label="Type"
                                    name="typeFlag"
                                    extra={{
                                        type: ComponentType.dropdown,
                                        value: configState.dataCenters,
                                        inputProps: { mode: "multiple" },
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={15}>
                                <FormComponent
                                    label="Comment"
                                    name="comment"
                                    extra={{
                                        type: ComponentType.textarea,
                                        value: "",
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={15}>
                                <FormComponent
                                    label="Enable"
                                    name="enable"
                                    extra={{
                                        type: ComponentType.switch,
                                        value: ["Inactive", "Active"],
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <div className="step-btns-group">
                                    <Button type="primary" htmlType="submit">
                                        Submit
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                )}
            </SitePageHeader>
        </div>
    );
};

export default CreateEditDataCenterPage;
