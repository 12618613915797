import { useMemo } from "react";
import { Button, Divider, message, Modal } from "antd";
import FlexiDataTable from "@/components/FlexiDataTable";
import { DTColProps } from "@/utils/Common";
import { FlexiDataTableOptionsProps, RollOverAdjustmentList, RollOverBatchRecordList, SymbolLeverageToolBatchRecordList } from "@/constants/type";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { APIs } from "@/services/apis";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { defaultIfEmptyOrNull } from "@/utils/string";
import { ExclamationCircleOutlined, MailOutlined } from "@ant-design/icons";

export interface RollOverAdjustmentTableProps {
    data: RollOverAdjustmentList[];
    currentState: RollOverBatchRecordList;
    tbTitle: string;
    loading: boolean;
    queryAdjustmentData: () => void;
    queryBatchRecord: () => void;
    setUploadLoading(loading: boolean): void;
    sendAdjustmentValueMail(): void;
}

const RollOverAdjustmentTable = (props: RollOverAdjustmentTableProps) => {
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.RISK_TOOLS_ROLLOVER_EDIT);
    const columns = [
        DTColProps.XSmall({
            title: "Server",
            dataIndex: "server",
            key: "server",
        }),
        DTColProps.XSmall({
            title: "Clean Symbol",
            dataIndex: "cleanSymbol",
            key: "cleanSymbol",
            sorter: (a: any, b: any) => a.cleanSymbol.localeCompare(b.cleanSymbol),
        }),
        DTColProps.XSmall({
            title: "Currency",
            dataIndex: "currency",
            key: "currency",
        }),
        DTColProps.XSmall({
            title: "Old Bid",
            dataIndex: "oldBid",
            key: "oldBid",
        }),
        DTColProps.XSmall({
            title: "Old Ask",
            dataIndex: "oldAsk",
            key: "oldAsk",
        }),
        DTColProps.XSmall({
            title: "New Bid",
            dataIndex: "newBid",
            key: "newBid",
        }),
        DTColProps.XSmall({
            title: "New Ask",
            dataIndex: "newAsk",
            key: "newAsk",
        }),
        DTColProps.XSmall({
            title: "AU Hub Roll Fee",
            dataIndex: "rollFee",
            key: "rollFee",
        }),
        DTColProps.XSmall({
            title: "Contract Size",
            dataIndex: "contractSize",
            key: "contractSize",
        }),
        DTColProps.XSmall({
            title: "Buy Adjustment",
            dataIndex: "buyAdjustment",
            key: "buyAdjustment",
        }),
        DTColProps.XSmall({
            title: "Sell Adjustment",
            dataIndex: "sellAdjustment",
            key: "sellAdjustment",
        }),
    ];

    const handleSubmit = (file: any) => {
        try {
            let fileExtension: string[] = defaultIfEmptyOrNull(/\.[^\.]+/.exec(file.name), [""]),
                isLt5M = file.size / 1024 / 1024 < 5;

            if (fileExtension[0] !== ".xlsx") {
                message.error("Please check file type. Only .xlsx files are allowed.", 3);
                return;
            } else if (!isLt5M) {
                message.error("Please check file size less than 5 MB", 3);
                return;
            }

            Modal.confirm({
                icon: <ExclamationCircleOutlined />,
                title: "Are you sure you want to import Roll Over Adjustment Data?",
                width: "30%",
                onOk() {
                    var formData = new FormData();
                    formData.append("uploadFile", file);
                    formData.append("id", `${props.currentState.id}`);

                    plainAxiosInstance
                        .post(APIs.RISK_TOOL.GET_ROLLOVER_ADD_ADJUSTMENT_BY_UPLOAD, formData)
                        .then(res => {
                            if (res.status == 200 && res.data === "success") {
                                message.success("File upload successfully.", 3);
                            } else {
                                message.error(res.data, 3);
                            }
                        })
                        .catch((error: any) => {
                            message.error(`File upload failed: (${error.response.data.message})`, 3);
                        })
                        .finally(() => {
                            props.queryAdjustmentData();
                            props.queryBatchRecord();
                        });
                },
                onCancel: () => {
                    props.setUploadLoading(false);
                },
            });
        } catch (error) {
            message.error(`Error during uploading file`, 3);
        }
    };

    const options: FlexiDataTableOptionsProps = useMemo(
        () => ({
            showHideColumns: false,
            enableFilter: false,
            ...(enableUpdate && {
                upload: {
                    name: "file",
                    multiple: false,
                    accept: ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                    showUploadList: false,
                    disabled: props.currentState.currentStep > 2,
                    onChange: (info: any) => {
                        if (info.file.status === "error") {
                            message.error(`${info.file.name} file upload failed.`);
                        }
                    },
                    customRequest: ({ file, onSuccess }: any) =>
                        setTimeout(() => {
                            onSuccess("ok");
                        }, 0),
                    beforeUpload: (file: any) => handleSubmit(file),
                },
            }),
            extraButtons: () => (
                <Button
                    key={`btn-mail-${Math.random()}`}
                    style={{ marginLeft: "0.651vw", width: "auto" }}
                    icon={<MailOutlined />}
                    disabled={props.loading || props.currentState.currentStep > 3}
                    onClick={event => {
                        event.preventDefault();
                        event.stopPropagation();
                        props.sendAdjustmentValueMail();
                    }}
                >
                    Send Adjustment Value Mail
                </Button>
            ),
        }),
        [props.data, enableUpdate, props.loading, props.currentState.currentStep]
    );

    return (
        <>
            <br />
            <Divider style={{ borderColor: "#FFA500" }}>{props.tbTitle}</Divider>
            <FlexiDataTable
                rowKeyProperty="uniqueKey"
                title={false}
                columns={columns}
                options={options}
                dataSource={props.data}
                loading={false}
                callback={() => { }}
                pagination={{ defaultPageSize: 10 }}
                scroll={{ x: true }}
            />
        </>
    );
};
export default RollOverAdjustmentTable;
