import { useState } from "react";
import type { IRCMidBias } from "../../../hooks/useRCMidBias";
import { Button, Modal, Table, notification } from "antd";
import { CheckOutlined, LineChartOutlined, WhatsAppOutlined } from "@ant-design/icons";
import { plainAxiosInstance } from "../../../services/axiosSetup";
import { APIs } from "../../../services/apis";
import MidBiasLineChart from "./MidBiasLineChart";

const MidBiasTable = ({ data, refetch }: { data: IRCMidBias[]; refetch: () => void }) => {
    // console.log("rcMidBiasFuture", rcMidBiasFuture);
    // console.log("rcMidBiasGeneral", rcMidBiasGeneral);

    const columns = [
        {
            title: "Our Symbol",
            dataIndex: "symbol",
            align: "left",
        },
        {
            title: "First Appear",
            dataIndex: "firstAppearStr",
            align: "left",
        },
        {
            title: "Our Servers",
            align: "center",
            dataIndex: "ourServerListStr",
        },
        {
            title: "Competitor Server",
            dataIndex: "cserv",
        },
        {
            title: "Count",
            dataIndex: "count",
        },

        {
            title: "Solved",
            align: "center",
            dataIndex: "Action",
            render: (text: any, data: any, record: any, index: number) => (
                <Button className="m-1" type="primary" icon={<CheckOutlined />} onClick={e => groupSolvedMid(data)} />
            ),
        },
    ];
    const brandColumns = [
        {
            title: "Brand",
            dataIndex: "brandId",
            align: "left",
        },
        {
            title: "Count",
            dataIndex: "count",
        },
        {
            title: "Send",
            align: "center",
            dataIndex: "Action",
            render: (text: any, data: any, index: any) => (
                <Button className="m-1" type="primary" icon={<WhatsAppOutlined />} onClick={e => sendToWhatsApp(data, index)} />
            ),
        },
    ];
    const dateColumns = [
        {
            title: "Line",
            align: "center",
            dataIndex: "Action",
            render: (text: any, data: any) => (
                <Button
                    className="m-1"
                    type="primary"
                    icon={<LineChartOutlined />}
                    // onClick={e => console.log(data)}
                    onClick={e => showBiasLine(data)}
                />
            ),
        },
        {
            title: "Datetime",
            dataIndex: "dateTimeStr",
            align: "left",
            defaultSortOrder: "ascend",
            // sorter: (a, b) => defaultSort(a, b, "dateTimeStr"),
        },

        {
            title: "Symbol",
            dataIndex: "symbolListStr",
            align: "left",
        },
        {
            title: "Max Score Range",
            dataIndex: "maxScoreRange",
            align: "right",
        },
        {
            title: "Score Sum Range",
            dataIndex: "scoreSumRange",
            align: "right",
        },
        {
            title: "Correlation Range",
            dataIndex: "correlationRange",
            align: "right",
        },
        {
            title: "Count",
            dataIndex: "count",
        },
        {
            title: "Solved",
            align: "center",
            dataIndex: "Action",
            render: (text: any, data: any, index: any) => (
                <Button className="m-1" type="primary" icon={<CheckOutlined />} onClick={e => dateGroupSolvedMid(data, index)} />
            ),
        },
    ];

    const detailColumns = [
        {
            title: "Line",
            align: "center",
            dataIndex: "Action",
            render: (text: any, data: any) => (
                <Button className="m-1" type="primary" icon={<LineChartOutlined />} onClick={e => showBiasLine(data)} />
            ),
        },
        {
            title: "Datetime",
            dataIndex: "dateTimeStr",
            align: "left",
        },
        {
            title: "Server",
            dataIndex: "server",
            align: "left",
        },

        {
            title: "Symbol",
            dataIndex: "symbol",
            align: "left",
        },
        {
            title: "Compare Server",
            dataIndex: "compareServer",
            align: "left",
        },
        {
            title: "Max score",
            dataIndex: "showMaxScore",
            align: "right",
        },
        {
            title: "Score Sum",
            dataIndex: "showScoreSum",
            align: "right",
        },
        {
            title: "Correlation",
            dataIndex: "showPPMCC",
            align: "right",
            // defaultSortOrder: 'ascend',
            // sorter: (a, b) => numberSort(a, b, "showPPMCC"),
        },
        {
            title: "ScoreRank",
            dataIndex: "scoreRank",
            align: "right",
            defaultSortOrder: "ascend",
            // sorter: (a, b) => numberSort(a, b, "scoreRank"),
        },
        {
            title: "Solved",
            align: "center",
            dataIndex: "Action",
            render: (text: any, data: any, index: number) => (
                <>
                    <Button className="m-1" type="primary" icon={<CheckOutlined />} onClick={e => solvedMid(data, index)} />
                </>
            ),
        },
    ];

    const [biasLineCompareData, setBiasLineCompareData] = useState(null);
    const [biasLineTitle, setBiasLineTitle] = useState("");

    const showBiasLine = async (data: any) => {
        const requestStr =
            data["dateTimeStr"].substring(0, data["dateTimeStr"].length - 3) +
            "," +
            data["server"] +
            "," +
            data["symbol"] +
            "," +
            data["compareServer"] +
            "," +
            data["compareSymbol"] +
            ",1," +
            data["serverUno"];
        const formData = new FormData();
        formData.append("keyStr", requestStr);
        const resp = await plainAxiosInstance.post(`${APIs.RC_MIDBIAS.GET_MIDBIAS_CHART}`, formData);
        if (resp?.data) {
            setBiasLineCompareData(resp.data);
            setBiasLineTitle(
                data["server"] + "-" + data["symbol"] + " vs " + data["compareServer"] + "-" + data["compareSymbol"] + " " + data["dateTimeStr"]
            );
        } else {
            notification.error({
                message: "Error",
                description: "No data found",
            });
        }
    };

    const groupSolvedMid = async (data: any) => {
        const requestStr = data["symbol"] + ",1";

        const formData = new FormData();
        formData.append("keyStr", requestStr);

        await plainAxiosInstance.post(`${APIs.RC_MIDBIAS.POST_GROUPSOLVED}/20/0`, formData).then(resp => {
            refetch();
        });
    };

    const dateGroupSolvedMid = async (line: any, index: any) => {
        const requestStr = line["dateTimeStr"] + "," + line["cleansymbol"] + "," + line["ourServerListStr"] + ",1";

        const formData = new FormData();
        formData.append("keyStr", requestStr);
        await plainAxiosInstance.post(APIs.RC_MIDBIAS.POST_DATAGROUPSOLVED, formData).then(resp => {
            refetch();
        });
    };

    const sendToWhatsApp = async (line: any, index: any) => {
        // console.log(line)
        const requestStr =
            line["brandId"] + ";" + line["firstAppearStr"] + ";" + line["lastAppearStr"] + ";" + line["cleansymbol"] + ";" + line["cserv"];

        const formData = new FormData();
        formData.append("keyStr", requestStr);
        await plainAxiosInstance.post(APIs.RC_MIDBIAS.POST_WHATSAPP, formData).then(resp => {
            if (resp.status === 200 && resp.data === "OK") {
                notification.success({
                    message: "Notify Successfully",
                    description: "Notify Successfully",
                });
            } else {
                notification.error({
                    message: "Notify Failed",
                    description: resp.data,
                });
            }

            refetch();
        });
    };

    const solvedMid = async (line: any, index: any) => {
        const requestStr =
            line["dateTimeStr"] + "," + line["serverUno"] + "," + line["symbol"] + "," + line["cserverUno"] + "," + line["compareSymbol"] + ",1";

        const formData = new FormData();
        formData.append("keyStr", requestStr);

        await plainAxiosInstance.post(APIs.RC_MIDBIAS.POST_SOLVED, formData).then(resp => {
            refetch();
        });
    };
    return (
        <div>
            <Table
                // loading={loading}
                // @ts-ignore
                columns={columns}
                dataSource={data ?? []}
                size="small"
                rowKey={record => record.symbol}
                // rowClassName={(record, index) =>
                //     record.alarmStatus === 1 ? "bg-yellow-700" : ""
                // }
                pagination={{
                    defaultPageSize: 50,
                }}
                expandable={{
                    expandedRowRender: brandRecord => (
                        <Table
                            rowKey={brandRecord => brandRecord.brandId}
                            pagination={false}
                            // @ts-ignore
                            columns={brandColumns}
                            // rowClassName={(dateRecord, index) =>
                            //     (dateRecord.ppmcc < 80) ? "bg-red-800" :(dateRecord.ppmcc < 95 && dateRecord.ppmcc > 80  && (dateRecord.scoreSum > 50 || dateRecord.scoreMax > 8)) ? "bg-yellow-700":  ""
                            // }
                            dataSource={brandRecord.detail}
                            expandable={{
                                expandedRowRender: dateRecord => (
                                    <Table
                                        rowKey={dateRecord => dateRecord.dateTimeStr + "_" + dateRecord.symbolListStr}
                                        pagination={false}
                                        // @ts-ignore
                                        columns={dateColumns}
                                        // rowClassName={(dateRecord, index) =>
                                        //     (dateRecord.ppmcc < 80) ? "bg-red-800" :(dateRecord.ppmcc < 95 && dateRecord.ppmcc > 80  && (dateRecord.scoreSum > 50 || dateRecord.scoreMax > 8)) ? "bg-yellow-700":  ""
                                        // }
                                        dataSource={dateRecord.detail}
                                        expandable={{
                                            expandedRowRender: record => (
                                                <Table
                                                    rowKey={record =>
                                                        record.serverUno +
                                                        "_" +
                                                        record.symbol +
                                                        "_" +
                                                        record.cserverUno +
                                                        "_" +
                                                        record.compareSymbol +
                                                        "_" +
                                                        record.dateTimeStr
                                                    }
                                                    pagination={false}
                                                    // @ts-ignore
                                                    columns={detailColumns}
                                                    rowClassName={(record, index) =>
                                                        record.ppmcc < 80 || record.scoreMax > 15
                                                            ? "bg-red-800"
                                                            : record.ppmcc < 95 && record.ppmcc > 80 && (record.scoreSum > 50 || record.scoreMax > 8)
                                                            ? "bg-yellow-700"
                                                            : ""
                                                    }
                                                    dataSource={record.detail}
                                                />
                                            ),
                                        }}
                                    />
                                ),
                            }}
                        />
                    ),
                }}
            />

            {biasLineCompareData && (
                <Modal
                    open={biasLineCompareData !== null}
                    closable={true}
                    onCancel={() => {
                        setBiasLineCompareData(null);
                        setBiasLineTitle("");
                    }}
                    width={1080}
                    footer={null}
                    title={biasLineTitle}
                >
                    <MidBiasLineChart biaslineCompareData={biasLineCompareData} />
                </Modal>
            )}
        </div>
    );
};

export default MidBiasTable;
