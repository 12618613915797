import { DeleteOutlined, EditOutlined, HomeOutlined, PlusOutlined, WarningOutlined } from "@ant-design/icons";
import { Alert, Button, Col, Divider, Empty, Form, Modal, Popconfirm, Row, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import FlexiDataTable from "../../../components/FlexiDataTable";
import { FormComponent } from "../../../components/FormComponent";
import LoadingComponent from "../../../components/Loading";
import SitePageHeader from "../../../components/PageHeader";
import { CALLBACK_KEY, ComponentType, STATUS_TYPE, SUCCESS_FAILED } from "../../../constants";
import { REQUIRED_FIELD } from "../../../constants/errorMessage";
import {
    Brands3,
    KeyValuePair,
    metricItem,
    metricProps,
    RiskScoreConfiguration,
    tierProps,
    typeProps,
    FlexiDataTableOptionsProps,
    FlexiDataTableCallbackProps,
    FlexiDataColumnProps,
    Brands,
    AccountTypes,
} from "../../../constants/type";
import { apiRequest, APIs } from "../../../services/apiConfig";
import { ToOptionTypeList } from "../../../utils/array";
import { currencyRender, DTColProps, getGUID, ErrorMessageHandler, ErrorCatchValidator } from "../../../utils/Common";
import CreateEditMetricComponent from "./Components/createEditMetric";
import CreateEditTierComponent from "./Components/createEditTier";
import CreateEditTypeComponent from "./Components/createEditType";
import EmptyData from "../../../components/Common/Empty";

export type RiskScoreState = {
    action: string;
    profileId: string;
};

interface modalProps {
    isShow: boolean;
    data: any;
}

interface errorDesc {
    isShow: boolean;
    error: string[];
}

const initialValue = (): RiskScoreConfiguration => ({
    id: 0,
    name: "",
    description: "",
    status: 1,
    types: [],
    brands: [],
    isDefault: false,
});

const enum comDataAction {
    DELETE_TYPE = 1,
    UPDATE_TYPE = 2,
    DELETE_METRIC = 3,
    UPDATE_METRIC = 4,
    DELETE_TIER = 5,
    UPDATE_TIER = 6,
}

const CreateEditTemplate = () => {
    let location = useLocation();
    let navigate = useNavigate();
    let com_state: RiskScoreState = location.state as RiskScoreState;

    const [currentState] = useState<RiskScoreState>(com_state || { action: "add", data: null });
    const [isAddAction] = useState<boolean>(currentState.action === "add");
    const [loading, setLoading] = useState<boolean>(true);
    const [btnLoading, setBtnLoading] = useState<boolean>(false);
    const [comData, setComData] = useState<RiskScoreConfiguration>({ ...initialValue() });
    const [typeData, setTypeData] = useState<modalProps>({ isShow: false, data: null });
    const [metricData, setMetricData] = useState<modalProps>({ isShow: false, data: null });
    const [tierData, setTierData] = useState<modalProps>({ isShow: false, data: null });
    const [metrics, setMetrics] = useState<metricItem[]>([]);
    const [currentSelectedMetric, setCurrentSelectedMetric] = useState<string>("");
    const [errorDesc, setErrorDesc] = useState<errorDesc>({ isShow: false, error: [] });
    const [, setBrandsData] = useState<Brands[]>([]);
    const [accountTypes, setAccountTypes] = useState<AccountTypes[]>([]);

    const [riskScoreForm] = Form.useForm();
    const [typeForm] = Form.useForm();
    const [metricForm] = Form.useForm();
    const [tierForm] = Form.useForm();

    const columns: FlexiDataColumnProps[] = [
        DTColProps.Middle({
            title: "Name",
            dataIndex: "name",
            key: "name",
            options: {
                filter: {
                    type: ComponentType.text,
                },
            },
        }),

        {
            title: "Description",
            dataIndex: "description",
            key: "description",
            options: {
                filter: {
                    type: ComponentType.text,
                },
            },
            render: (description: string) => (description ? description : "-"),
        },
    ];

    const metricColumns: FlexiDataColumnProps[] = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            render: (name: string, rowData: any) => {
                return (
                    <div className="rsp-mt-info">
                        <Button type="link" onClick={() => setSelectedMetric(rowData)} className="link-clickable">
                            {name}
                        </Button>
                        {rowData.tiers && rowData.tiers.length < 1 && (
                            <Tooltip title="At least 1 tier needed">
                                <WarningOutlined className="rsp-mt-icon" />
                            </Tooltip>
                        )}
                    </div>
                );
            },
            options: {
                filter: {
                    type: ComponentType.text,
                },
            },
        },
        {
            title: "Selected Metric",
            dataIndex: "aggregations",
            key: "aggregations",
            render: (value: any) => {
                let valueArray = value.metricId.toString();
                return metrics.find((x) => x.metricId.toString() === valueArray)?.displayName;
            },
            options: {
                filter: {
                    type: ComponentType.text,
                },
            },
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
            options: {
                filter: {
                    type: ComponentType.text,
                },
            },
        },

        DTColProps.XSmall({
            title: "Weight",
            dataIndex: "weight",
            key: "weight",
            options: {
                filter: {
                    type: ComponentType.text,
                },
            },
        }),
    ];

    const options: FlexiDataTableOptionsProps = {
        add: true,
        edit: true,
        delete: true,
        separateActionButton: true,
        enableFilter: false,
        scroll: {
            y: "max-content",
        },
        expandable: {
            expandedRowRender: (record: typeProps) => {
                let currentTyId = record.typeId;
                return (
                    <div className="metric-outer-container">
                        <FlexiDataTable
                            bordered
                            rowKeyProperty="aggregateId"
                            title={
                                <>
                                    Metric List
                                    {record.aggregates &&
                                        record.aggregates.length > 0 &&
                                        getWeightCountTemplate(record.aggregates.map((x) => x.weight))}
                                </>
                            } //"Metric List"
                            columns={metricColumns}
                            options={{
                                add: true,
                                edit: true,
                                delete: true,
                                separateActionButton: true,
                                enableFilter: false,
                                scroll: {
                                    y: "max-content",
                                },
                                showHideColumns: false,
                            }}
                            pagination={false}
                            dataSource={record.aggregates.map((x) => ({ ...x, typeId: currentTyId })) || []}
                            callback={(type, data) => metricComponentCallback(type, currentTyId, data)}
                            loading={false}
                        />
                    </div>
                );
            },
            rowExpandable: (data: typeProps) => true,
        },
        showHideColumns: false,
    };

    const getUpdatedComData = (type: number, typeId: string = "", metricId: string = "", data: any = {}) => {
        let currentComData: RiskScoreConfiguration = { ...comData };
        switch (type) {
            case comDataAction.DELETE_TYPE:
                currentComData.types = currentComData.types.filter((x) => x.typeId.toString() !== typeId);
                break;
            case comDataAction.UPDATE_TYPE:
                if (data["typeId"].toString() === "0") {
                    currentComData.types.push({
                        typeId: getGUID(true),
                        name: data["name"],
                        description: data["description"] || "",
                        aggregates: [],
                    } as typeProps);
                } else {
                    let typeIdx = currentComData.types.findIndex((x) => x.typeId.toString() === data["typeId"].toString());
                    if (typeIdx > -1) {
                        currentComData.types[typeIdx] = {
                            ...currentComData.types[typeIdx],
                            name: data["name"],
                            description: data["description"] || "",
                        };
                    }
                }
                break;
            case comDataAction.DELETE_METRIC:
                {
                    let typeIdx = currentComData.types.findIndex((x) => x.typeId.toString() === typeId);
                    if (typeIdx > -1) {
                        currentComData.types[typeIdx].aggregates = currentComData.types[typeIdx].aggregates.filter(
                            (x) => x.aggregateId.toString() !== metricId
                        );
                    }
                }
                break;
            case comDataAction.UPDATE_METRIC:
                {
                    let typeIdx = currentComData.types.findIndex((x) => x.typeId.toString() === data["typeId"].toString());
                    if (typeIdx > -1) {
                        if (data["aggregateId"].toString() === "0") {
                            let findIndex = metrics.findIndex((x) => `${x.metricId}` === data["aggregations"]);
                            if (findIndex > -1) {
                                let foundMetric = metrics[findIndex];
                                data["aggregations"] = {
                                    metricId: foundMetric.metricId,
                                    name: foundMetric.metricName,
                                    dataType: foundMetric.dataTypeId,
                                    displayName: foundMetric.displayName,
                                };
                            }

                            currentComData.types[typeIdx].aggregates.push({
                                aggregateId: getGUID(true),
                                name: data["name"],
                                weight: data["weight"],
                                aggregations: data["aggregations"],
                                description: data["description"] || "",
                                tiers: [],
                            } as metricProps);
                        } else {
                            let metricIdx = currentComData.types[typeIdx].aggregates.findIndex(
                                (x) => x.aggregateId.toString() === data["aggregateId"].toString()
                            );
                            if (metricIdx > -1) {
                                let findIndex = metrics.findIndex((x) => `${x.metricId}` === data["aggregations"]);
                                if (findIndex > -1) {
                                    let foundMetric = metrics[findIndex];
                                    data["aggregations"] = {
                                        metricId: foundMetric.metricId,
                                        name: foundMetric.metricName,
                                        dataType: foundMetric.dataTypeId,
                                        displayName: foundMetric.displayName,
                                    };
                                }

                                currentComData.types[typeIdx].aggregates[metricIdx] = {
                                    ...currentComData.types[typeIdx].aggregates[metricIdx],
                                    name: data["name"],
                                    weight: data["weight"],
                                    aggregations: data["aggregations"],
                                    description: data["description"] || "",
                                };
                            }
                        }
                    }
                }
                break;
            case comDataAction.DELETE_TIER:
                {
                    let typeIdx = currentComData.types.findIndex((x) => x.typeId.toString() === typeId);
                    if (typeIdx > -1) {
                        let metricIdx = currentComData.types[typeIdx].aggregates.findIndex((x) => x.aggregateId.toString() === metricId);
                        if (metricIdx > -1) {
                            currentComData.types[typeIdx].aggregates[metricIdx].tiers = currentComData.types[typeIdx].aggregates[
                                metricIdx
                            ].tiers.filter((x) => x.tierId.toString() !== (data as string));
                        }
                    }
                }
                break;
            case comDataAction.UPDATE_TIER:
                {
                    let typeIdx = currentComData.types.findIndex((x) => x.typeId.toString() === data["typeId"].toString());
                    if (typeIdx > -1) {
                        let metricIdx = currentComData.types[typeIdx].aggregates.findIndex(
                            (x) => x.aggregateId.toString() === data["aggregateId"].toString()
                        );
                        if (metricIdx > -1) {
                            if (data["tierId"].toString() === "0") {
                                currentComData.types[typeIdx].aggregates[metricIdx].tiers.push({
                                    tierId: getGUID(true),
                                    operator: data["operator"],
                                    fromValue: data["fromValue"],
                                    toValue: data["operator"].toString() === "3" ? data["toValue"] : "0",
                                    point: data["point"],
                                } as tierProps);
                            } else {
                                let tierIdx = currentComData.types[typeIdx].aggregates[metricIdx].tiers.findIndex(
                                    (x) => x.tierId.toString() === data["tierId"].toString()
                                );
                                if (tierIdx > -1) {
                                    currentComData.types[typeIdx].aggregates[metricIdx].tiers[tierIdx] = {
                                        ...currentComData.types[typeIdx].aggregates[metricIdx].tiers[tierIdx],
                                        operator: data["operator"],
                                        fromValue: data["fromValue"],
                                        toValue: data["operator"].toString() === "3" ? data["toValue"] : "0",
                                        point: data["point"],
                                    };
                                }
                            }
                        }
                    }
                }
                break;
        }

        return currentComData;
    };

    const typeComponentCallback: FlexiDataTableCallbackProps = (type, data) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                setTypeData({ isShow: true, data: null });
                typeForm.setFieldsValue({ typeId: 0, name: "", description: "", aggregates: [] });
                break;
            case CALLBACK_KEY.DO_EDIT:
                typeForm.setFieldsValue(data);
                setTypeData({ isShow: true, data: data });
                break;
            case CALLBACK_KEY.DO_DELETE:
                setComData(getUpdatedComData(comDataAction.DELETE_TYPE, data.typeId.toString()));
                break;
            default:
                break;
        }
    };

    const metricComponentCallback = (type: CALLBACK_KEY, typeId: number, data: any) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                metricForm.setFieldsValue({ typeId, aggregateId: 0, name: "", description: "", aggregations: "", weight: "", tiers: [] });
                setMetricData({ isShow: true, data: null });
                break;
            case CALLBACK_KEY.DO_EDIT:
                metricForm.setFieldsValue({ ...data, typeId, aggregations: `${data.aggregations.metricId}` });
                setMetricData({ isShow: true, data: data });
                break;
            case CALLBACK_KEY.DO_DELETE:
                setComData(getUpdatedComData(comDataAction.DELETE_METRIC, typeId.toString(), data.aggregateId.toString()));
                break;
            default:
                break;
        }
    };

    const tierComponentCallback = (type: CALLBACK_KEY, typeId: number, aggregateId: number, data: any) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                tierForm.setFieldsValue({ typeId, aggregateId, tierId: 0, operator: "", fromValue: "", toValue: "", point: "" });
                setTierData({ isShow: true, data: { typeId, aggregateId, tierId: 0, operator: "", fromValue: "", toValue: "", point: "" } });
                break;
            case CALLBACK_KEY.DO_DELETE:
                setComData(getUpdatedComData(comDataAction.DELETE_TIER, typeId.toString(), aggregateId.toString(), data));
                break;
            case CALLBACK_KEY.DO_EDIT:
                tierForm.setFieldsValue({ typeId, aggregateId, ...data });
                setTierData({ isShow: true, data: { typeId, aggregateId, ...data } });
                break;
        }
    };

    const getTierTemplateContainer = (typeId: number, aggregateId: number, title: string, childNode: React.ReactNode) => {
        return (
            <div className="tier-list">
                <Row>
                    <Col className="tier-list-title" span={24}>
                        <h1>Tier List {title && title.length > 0 ? `[${title}]` : ""}</h1>
                        <Button
                            type="default"
                            onClick={() =>
                                tierComponentCallback(CALLBACK_KEY.CREATE_NEW, typeId, aggregateId, {
                                    tierId: 0,
                                    operator: "",
                                    fromValue: "",
                                    toValue: "",
                                    point: "",
                                })
                            }
                            icon={<PlusOutlined />}
                        >
                            Add Tier
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <div className="list-item-div">{childNode}</div>
                    </Col>
                </Row>
            </div>
        );
    };

    const getTierTemplate = (selectedMetric: string) => {
        let cMetric = selectedMetric.split("|");
        let title = "";
        if (cMetric.length === 2) {
            let typeIdx = comData.types.findIndex((x) => x.typeId.toString() === cMetric[0]);
            if (typeIdx > -1) {
                let metricIdx = comData.types[typeIdx].aggregates.findIndex((x) => x.aggregateId.toString() === cMetric[1]);
                if (metricIdx > -1) {
                    title = `Type: ${comData.types[typeIdx].name}, Metric: ${comData.types[typeIdx].aggregates[metricIdx].name}`;
                    if (comData.types[typeIdx].aggregates[metricIdx].tiers.length > 0) {
                        return getTierTemplateContainer(
                            comData.types[typeIdx].typeId,
                            comData.types[typeIdx].aggregates[metricIdx].aggregateId,
                            title,
                            comData.types[typeIdx].aggregates[metricIdx].tiers.map((x, index) => (
                                <div key={`rs-tr-${x.tierId}`} className="list-item-style">
                                    <div className="left-item">
                                        <span className="expression-div">
                                            {x.operator === 3
                                                ? `${currencyRender(x.fromValue)} - ${currencyRender(x.toValue)}`
                                                : `${x.operator === 1 ? "> " : "< "}${currencyRender(x.fromValue)}`}
                                        </span>
                                        <span className="point-div">Point : {x.point}</span>
                                    </div>
                                    <Button
                                        type="link"
                                        icon={<EditOutlined />}
                                        onClick={() => {
                                            tierComponentCallback(
                                                CALLBACK_KEY.DO_EDIT,
                                                comData.types[typeIdx].typeId,
                                                comData.types[typeIdx].aggregates[metricIdx].aggregateId,
                                                comData.types[typeIdx].aggregates[metricIdx].tiers[index]
                                            );
                                        }}
                                    />
                                    <Button
                                        type="link"
                                        danger
                                        icon={<DeleteOutlined />}
                                        onClick={() =>
                                            tierComponentCallback(
                                                CALLBACK_KEY.DO_DELETE,
                                                comData.types[typeIdx].typeId,
                                                comData.types[typeIdx].aggregates[metricIdx].aggregateId,
                                                x.tierId.toString()
                                            )
                                        }
                                    />
                                </div>
                            ))
                        );
                    }

                    return getTierTemplateContainer(
                        comData.types[typeIdx].typeId,
                        comData.types[typeIdx].aggregates[metricIdx].aggregateId,
                        title,
                        <EmptyData />
                    );
                }
            }
        }

        return <EmptyData />;
    };

    const getWeightCountTemplate = (list: number[]) => {
        if (list.length > 0) {
            let totalWeight: number = 0;
            list.map((x) => {
                totalWeight += typeof x === "string" ? parseFloat(x) : x;
                return false;
            });

            if (totalWeight < 100) {
                return (
                    <span className="error" style={{ paddingLeft: "0.651vw" }}>
                        {`(Total weight: ${totalWeight}, remaining: ${100 - totalWeight} needed)`}
                    </span>
                );
            } else if (totalWeight > 100) {
                return (
                    <span className="error" style={{ paddingLeft: "0.651vw" }}>
                        {`(Total weight: ${totalWeight}, extra: ${totalWeight - 100})`}
                    </span>
                );
            }
        }
        return "";
    };

    const setSelectedMetric = (rowData: any) => {
        setCurrentSelectedMetric(`${rowData["typeId"]}|${rowData["aggregateId"]}`);
    };

    const onSubmit = (values: any) => {
        let submitData: RiskScoreConfiguration = Object.assign({}, { ...comData }, { ...values });
        let errorDescArr: string[] = [];
        let isValid = true;

        //1st Step: check type's total weight
        if (submitData.types.length < 1) {
            isValid = false;
            errorDescArr.push("At least 1 type needed.");
        } else {
            // let totalTypeWeight = submitData.types.reduce(function (sum: number, x: typeProps) {
            //     return sum + (typeof x.weight === "string" ? parseFloat(x.weight) : (x.weight as number));
            // }, 0);
            // if (totalTypeWeight !== 100) {
            //     isValid = false;
            //     errorDescArr.push("Total weight of all type(s) must be equal to 100");
            // }

            submitData.types.map((x) => {
                /**
                 * 3/10/2022 Lee Xin
                 * no longer need weight configured for each type but leaving this here just in case
                 */
                // let metricWeightOfType = x.aggregates.reduce(function (sum: number, y: metricProps) {
                //     return sum + (typeof y.weight === "string" ? parseFloat(y.weight) : (y.weight as number));
                // }, 0);
                // if (metricWeightOfType !== 100) {
                //     isValid = false;
                //     errorDescArr.push(`(Type: ${x.name}) Total weight of all metric(s) must be equal to 100.`);
                // }

                //if (
                x.aggregates.map((y) => {
                    if (y.tiers.length < 1) {
                        isValid = false;
                        errorDescArr.push(`(Type: ${x.name}, Metric: ${y.name}) At least 1 tier needed.`);
                    }
                    return y;
                });
                //)
                return x;
            });
        }

        if (!isValid) {
            setErrorDesc({ isShow: true, error: errorDescArr });
            return;
        }

        if (isAddAction) {
            //reset all id to 0
            submitData.types = submitData.types.map((x) => {
                x.typeId = 0;
                x.aggregates = x.aggregates.map((y) => {
                    y.aggregateId = 0;
                    y.tiers = y.tiers.map((k) => {
                        k.tierId = 0;
                        return k;
                    });

                    if (typeof y.aggregations === "string") {
                        let aArr = y.aggregations as string;
                        let foundIndex: number = metrics.findIndex((g) => `${g.metricId}` === aArr);
                        if (foundIndex !== -1) {
                            y.aggregations = { ...metrics[foundIndex] } as metricItem;
                        }
                    }

                    return y;
                });
                return x;
            });
        } else {
            submitData.types = submitData.types.map((x) => {
                if (x.typeId < 0) x.typeId = 0;

                x.aggregates = x.aggregates.map((y) => {
                    if (y.aggregateId < 0) y.aggregateId = 0;

                    let aArr = y.aggregations as string;
                    let foundIndex: number = metrics.findIndex((g) => `${g.metricId}` === aArr);
                    if (foundIndex !== -1) {
                        y.aggregations = { ...metrics[foundIndex] } as metricItem;
                    }

                    y.tiers = y.tiers.map((z) => {
                        if (z.tierId < 0) z.tierId = 0;
                        return z;
                    });
                    return y;
                });
                return x;
            });
        }

        setBtnLoading(true);
        setErrorDesc({ isShow: false, error: [] });
        apiRequest(isAddAction ? APIs.CREATE_RISK_SCORE_PROFILE : APIs.UPDATE_RISK_SCORE_PROFILE, submitData)
            .then((data) => {
                isAddAction
                    ? ErrorMessageHandler("New risk score profile", SUCCESS_FAILED.SUCCESS_CREATE_DATA)
                    : ErrorMessageHandler("Risk score profile", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                navigate("/siteadmin/riskscore/account");
            })
            .catch((error) =>
                ErrorCatchValidator(error, (err: any) =>
                    isAddAction
                        ? ErrorMessageHandler("new risk score profile", SUCCESS_FAILED.FAILED_CREATE_DATA, err)
                        : ErrorMessageHandler("risk score profile", SUCCESS_FAILED.FAILED_UPDATE_DATA, err)
                )
            )
            .finally(() => {
                setBtnLoading(false);
            });
    };

    const getFilterConfigList = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["accounttype"] })
            .then((data: any) => {
                setAccountTypes(data.accountTypes);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => console.log(err));
            })
            .finally(() => {
                setBtnLoading(false);
            });
    };

    useEffect(() => {
        setLoading(true);
        apiRequest(APIs.GET_RISK_SCORE_METRICS, {})
            .then((data: any) => {
                getFilterConfigList();
                setMetrics(
                    data.metric.map((x: any) => ({
                        metricId: x.metricId,
                        metricTypeId: x.metricTypeId,
                        metricName: x.metricName,
                        dataTypeId: x.dataTypeId,
                        displayName: x.displayName,
                    }))
                );
                setBrandsData(data.brands);

                if (!isAddAction) {
                    apiRequest(APIs.GET_RISK_SCORE_LIST, { profileId: currentState.profileId })
                        .then((data: any) => {
                            let current_data: RiskScoreConfiguration = data as RiskScoreConfiguration;
                            setComData(
                                Object.assign(comData, current_data, {
                                    brands: (current_data.brands as Brands3[]).map((x) => x.id),
                                })
                            );
                        })
                        .catch((error: any) => {
                            ErrorCatchValidator(error, (err: any) =>
                                ErrorMessageHandler("risk score profile list", SUCCESS_FAILED.FAILED_LOAD_DATA, err)
                            );
                            navigate("/siteadmin/riskscore/account");
                        })
                        .finally(() => {
                            setLoading(false);
                        });
                } else {
                    setLoading(false);
                }
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("risk score profile", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                navigate("/siteadmin/riskscore/account");
            });
        setComData({ ...initialValue() });
        return () => {};
    }, []);

    const deleteAccountRiskScore = (profileId: string, navigator: NavigateFunction) => {
        apiRequest(APIs.DELETE_RISK_SCORE_PROFILE, { profileId })
            .then((data) => {
                ErrorMessageHandler("The account risk score", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                navigator("/siteadmin/riskscore/account");
            })
            .catch((error) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("account risk score", SUCCESS_FAILED.FAILED_DELETE_DATA, err))
            );
    };

    return (
        <>
            <SitePageHeader
                title={isAddAction ? "Create New Account Risk Score" : "Edit Account Risk Score"}
                routes={[
                    {
                        path: "/siteadmin/riskscore/account",
                        breadcrumbName: "Account Risk Score Configuration",
                        icon: <HomeOutlined />,
                    },
                    {
                        path: "",
                        breadcrumbName: isAddAction ? "Create New Account Risk Score" : "Edit Account Risk Score",
                    },
                ]}
                onBack={() => navigate("/siteadmin/riskscore/account")}
                extraProps={{
                    extra: isAddAction
                        ? []
                        : [
                              <Popconfirm
                                  key={"cetmp-del"}
                                  title="Confirm to delete?"
                                  onConfirm={() => deleteAccountRiskScore(currentState.profileId, navigate)}
                                  okText="Yes"
                                  cancelText="No"
                              >
                                  <Button type="primary" danger>
                                      Delete Account Risk Score
                                  </Button>
                              </Popconfirm>,
                          ],
                }}
            >
                {loading ? (
                    <div className="loading-container">
                        <LoadingComponent tip="Loading..." />
                    </div>
                ) : (
                    <Form form={riskScoreForm} layout="horizontal" initialValues={comData} onFinish={onSubmit}>
                        {errorDesc.isShow && errorDesc.error.length > 0 && (
                            <Alert
                                className="rsp-error-desc-div"
                                type="error"
                                message={
                                    <ul className="rsp-error-desc">
                                        {errorDesc.error.map((x, index) => (
                                            <li key={`f-e-${index}`} className="error">
                                                {x}
                                            </li>
                                        ))}
                                    </ul>
                                }
                                banner
                            />
                        )}
                        <Row>
                            <Col span={11}>
                                <FormComponent
                                    label="Profile Name"
                                    name="name"
                                    extra={{
                                        type: ComponentType.text,
                                        itemProps: {
                                            labelAlign: "left",
                                            labelCol: { span: 6 },
                                        },
                                        rules: [
                                            {
                                                required: true,
                                                message: REQUIRED_FIELD,
                                            },
                                        ],
                                        value: "",
                                    }}
                                />
                            </Col>
                            <Col span={1}></Col>
                            <Col span={12}>
                                <FormComponent
                                    label="Description"
                                    name="description"
                                    extra={{
                                        type: ComponentType.text,
                                        itemProps: {
                                            labelAlign: "left",
                                            labelCol: { span: 6 },
                                        },
                                        value: "",
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={11}>
                                <FormComponent
                                    label="Status"
                                    name="status"
                                    extra={{
                                        type: ComponentType.dropdown,
                                        itemProps: {
                                            labelAlign: "left",
                                            labelCol: { span: 6 },
                                        },
                                        rules: [
                                            {
                                                required: true,
                                                message: REQUIRED_FIELD,
                                            },
                                        ],
                                        value: ToOptionTypeList(STATUS_TYPE),
                                    }}
                                />
                            </Col>
                            <Col span={1}></Col>
                            {/**
                             * 28/09/2022 Lee Xin
                             *
                             * - FUTURE: brands first after status, then isDefault option
                             * - CURRENT design when brands is of no use in config yet:
                             *    - putting isDefault tag here first
                             */}
                            <Col span={12}>
                                <FormComponent
                                    label="Set Profile As"
                                    name="isDefault"
                                    extra={{
                                        type: ComponentType.checkbox,
                                        itemProps: {
                                            labelAlign: "left",
                                            labelCol: { span: 6 },
                                        },
                                        value: "Default",
                                    }}
                                />
                                {/* <FormComponent
                                    label="Brands"
                                    name="brands"
                                    extra={{
                                        type: ComponentType.dropdown,
                                        itemProps: {
                                            labelAlign: "left",
                                            labelCol: { span: 6 },
                                        },
                                        inputProps: {
                                            style: { width: "100%" },
                                            mode: "multiple",
                                            allowClear: true,
                                        },
                                        value: brandsData.map((x) => ({
                                            text: x.brand,
                                            value: x.id,
                                        })),
                                    }}
                                /> */}
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col span={11}>
                                <FormComponent
                                    label="Set Profile As"
                                    name="isDefault"
                                    extra={{
                                        type: ComponentType.checkbox,
                                        itemProps: {
                                            labelAlign: "left",
                                            labelCol: { span: 6 },
                                        },
                                        value: "Default",
                                    }}
                                />
                            </Col>
                        </Row> */}
                        <Row>
                            <Col span={11}>
                                <FormComponent
                                    label="Account Type"
                                    name="accountTypes"
                                    extra={{
                                        type: ComponentType.dropdown,
                                        itemProps: {
                                            labelAlign: "left",
                                            labelCol: { span: 6 },
                                        },
                                        inputProps: {
                                            style: { width: "100%" },
                                            mode: "multiple",
                                            allowClear: true,
                                        },
                                        value: accountTypes.map((x) => ({ text: x.name, value: x.id })),
                                    }}
                                />
                            </Col>
                        </Row>
                        <Divider style={{ marginTop: 0 }} />
                        <Row>
                            <Col span={16} className="create-risk-score-panel">
                                <FlexiDataTable
                                    pagination={false}
                                    bordered
                                    rowKeyProperty="typeId"
                                    title={
                                        <>
                                            Type List
                                            {/* {comData.types && comData.types.length > 0 && getWeightCountTemplate(comData.types.map((x) => x.weight))} */}
                                        </>
                                    }
                                    columns={columns}
                                    options={options}
                                    dataSource={comData.types || []}
                                    callback={typeComponentCallback}
                                    loading={loading}
                                />
                            </Col>
                            <Col span={8} className="tier-panel">
                                {currentSelectedMetric.length > 0 && getTierTemplate(currentSelectedMetric)}
                            </Col>
                        </Row>
                        <Divider type="horizontal" />
                        <Row>
                            <Col span={24}>
                                <Button type="primary" htmlType="submit" loading={btnLoading}>
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                )}
            </SitePageHeader>

            <Modal
                destroyOnClose
                maskClosable={false}
                title="Add New Type"
                open={typeData.isShow}
                onOk={() => {
                    typeForm
                        .validateFields()
                        .then((values) => {
                            setComData(getUpdatedComData(comDataAction.UPDATE_TYPE, "", "", values));
                            setTypeData({ isShow: false, data: null });
                        })
                        .catch((err) => {
                            console.log("Validate Failed:", err);
                        });
                }}
                onCancel={() => {
                    setTypeData({ isShow: false, data: null });
                }}
            >
                <CreateEditTypeComponent form={typeForm} data={typeData.data} />
            </Modal>
            <Modal
                destroyOnClose
                maskClosable={false}
                title="Add New Metric"
                open={metricData.isShow}
                onOk={() => {
                    metricForm
                        .validateFields()
                        .then((values) => {
                            setComData(getUpdatedComData(comDataAction.UPDATE_METRIC, "", "", values));
                            setMetricData({ isShow: false, data: null });
                        })
                        .catch((err) => {
                            console.log("Validate Failed:", err);
                        });
                }}
                onCancel={() => {
                    setMetricData({ isShow: false, data: null });
                }}
            >
                <CreateEditMetricComponent
                    form={metricForm}
                    data={metricData.data}
                    metrics={metrics.map((x) => ({ text: x.displayName, value: `${x.metricId}` } as KeyValuePair))}
                />
            </Modal>

            <Modal
                destroyOnClose
                maskClosable={false}
                title="Add New Tier"
                open={tierData.isShow}
                onOk={() => {
                    tierForm
                        .validateFields()
                        .then((values) => {
                            setComData(getUpdatedComData(comDataAction.UPDATE_TIER, "", "", values));
                            setTierData({ isShow: false, data: null });
                        })
                        .catch((err) => {
                            console.log("Validate Failed:", err);
                        });
                }}
                onCancel={() => {
                    setTierData({ isShow: false, data: null });
                }}
            >
                <CreateEditTierComponent form={tierForm} data={tierData.data} />
            </Modal>
        </>
    );
};

export default CreateEditTemplate;
