import { createApi } from "@reduxjs/toolkit/query/react";
import { TradeMonitorEventSummary } from "../../constants/type";
import { APIs, defaultBaseQueryProps, ResponseProps } from "../../services/apiConfig";
import { axiosBaseQuery } from "../axiosBaseQuery";

export interface tradeMonitorEventSummaryReq {}

export interface tradeMonitorEventSummaryRes extends ResponseProps<TradeMonitorEventSummary[]> {}

export const tradeMonitorApiSlice = createApi({
    reducerPath: "api_15",
    baseQuery: axiosBaseQuery(),
    keepUnusedDataFor: 5, //global caching duration (seconds)
    endpoints: (builder) => ({
        getTradeMonitorEventSummary: builder.query<tradeMonitorEventSummaryRes, tradeMonitorEventSummaryReq | undefined>({
            keepUnusedDataFor: 0, //disable for caching for particular api request
            query: () => ({ ...defaultBaseQueryProps, url: APIs.VIEW_TRADE_EVENT_SUMMARY, method: "POST" }),
            transformResponse: (returnValue: tradeMonitorEventSummaryRes, meta) => {
                return returnValue;
            },
        }),
    }),
});

export const { useGetTradeMonitorEventSummaryQuery } = tradeMonitorApiSlice;
