import { useEffect, useMemo, useState } from "react";
import { APIs, apiRequest } from "../../../services/apiConfig";
import { GetUniqueKeysList, ToObjectWithKey } from "../../../utils/array";
import { Badge, Empty, Input, Tooltip } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import useFilterConfigs from "../../../hooks/useFilterConfigs";
import { DefaultIfEmpty } from "@/utils/object";
import { defaultIfEmptyOrNull } from "@/utils/string";

interface IHolidayNotice {
    description: string;
    symbol: string;
    serverId: number;
    server: string;
    timeRange: string;
}

const HolidayNotice = () => {
    const [filterText, setFilterText] = useState<string>("");
    const [holidayNoticeList, setHolidayNoticeList] = useState<IHolidayNotice[]>([]);
    const { filterConfigs, isLoading } = useFilterConfigs({ filterType: ["riskinsightserver"] });

    const holidayList = useMemo(() => {
        if (!isLoading && filterConfigs.hasOwnProperty("riskInsightServers") && filterConfigs["riskInsightServers"].length > 0) {
            let serverObj = ToObjectWithKey(
                filterConfigs["riskInsightServers"].map((x: any) => ({ text: x.serverName, value: x.serverId })),
                "value"
            );

            return holidayNoticeList.map((x: IHolidayNotice) => ({ ...x, server: DefaultIfEmpty(serverObj, x.serverId, { text: x.serverId }).text }));
        }

        return [];
    }, [holidayNoticeList, filterConfigs, isLoading]);

    const getTemplate = (fText: string, list: IHolidayNotice[]) => {
        let filteredText: string = `${defaultIfEmptyOrNull(fText, "")}`.toLowerCase();
        let filteredList = list.filter(
            x =>
                `${defaultIfEmptyOrNull(x.server, "")}`.toLowerCase().indexOf(filteredText) > -1 ||
                `${defaultIfEmptyOrNull(x.description, "")}`.toLowerCase().indexOf(filteredText) > -1 ||
                `${defaultIfEmptyOrNull(x.symbol, "")}`.toLowerCase().indexOf(filteredText) > -1 ||
                `${defaultIfEmptyOrNull(x.timeRange, "")}`.toLowerCase().indexOf(filteredText) > -1
        );

        if (filteredList.length > 0) {
            return (
                <div className="item-container nice-scrollbar">
                    {GetUniqueKeysList(filteredList, "description", true).map((x: string, index: number) => {
                        const tmp = filteredList.filter(y => y.description === x);
                        return (
                            <div key={`thl-i-${index}`} className="item">
                                <div className="title">
                                    <span className="desc">{x}</span>
                                    <Badge count={tmp.length} overflowCount={99999} />
                                </div>
                                {tmp.map((y: IHolidayNotice, idx: number) => (
                                    <div key={`thl-i-si-${idx}`} className="sub-item">
                                        <Tooltip title={y.server}>
                                            <span>{y.server}</span>
                                        </Tooltip>
                                        <Tooltip title={y.symbol}>
                                            <span>{y.symbol}</span>
                                        </Tooltip>
                                        <Tooltip title={y.timeRange}>
                                            <span>{y.timeRange}</span>
                                        </Tooltip>
                                    </div>
                                ))}
                            </div>
                        );
                    })}
                </div>
            );
        }
        return (
            <div style={{ marginTop: "50px" }}>
                <Empty />
            </div>
        );
    };

    const getHolidayNoticeList = () => {
        apiRequest(APIs.GET_HOLIDAY_NOTICE, { isTodayOnly: true })
            .then((res: any) => setHolidayNoticeList(res && res.length > 0 ? res : []))
            .catch((err: any) => console.log("APIs.GET_HOLIDAY_NOTICE", err));
    };

    useEffect(() => {
        getHolidayNoticeList();

        return () => {};
    }, []);

    return (
        <div className="holiday-notice-container">
            <div className="title-div">Today Holiday Symbol</div>
            <div className="search-panel">
                <Input type="text" prefix={<SearchOutlined />} onChange={(e: any) => setFilterText(e.target.value)} />
            </div>
            {isLoading ? "loading..." : getTemplate(filterText, holidayList)}
        </div>
    );
};

export default HolidayNotice;
