import { createApi } from "@reduxjs/toolkit/query/react";
import { APIs, defaultBaseQueryProps, ResponseProps } from "../../services/apiConfig";
import { AccountProfile, ResponseBase } from "../../constants/type";
import { axiosBaseQuery } from "../axiosBaseQuery";

export interface accountProfileRequest {}

export interface accountProfileResponse extends ResponseProps<ResponseBase<AccountProfile[]>> {}

export const accountProfilesApiSlice = createApi({
    reducerPath: "api_ap_1",
    baseQuery: axiosBaseQuery(),
    keepUnusedDataFor: 5, //global caching duration (seconds)
    endpoints: (builder) => ({
        getAccountProfiles: builder.query<accountProfileResponse, accountProfileRequest | undefined>({
            keepUnusedDataFor: 0, //disable for caching for particular api request
            query: (arg) => {
                return { ...defaultBaseQueryProps, url: APIs.GET_ACCOUNT_PROFILE_LIST, method: "POST", data: arg };
            },
            transformResponse: (returnValue: accountProfileResponse, meta) => {
                if (returnValue.data && returnValue.data.result) {
                    returnValue.data.result = returnValue.data.result.map((x: AccountProfile) => {
                        x.id = `${x.serverId}|${x.server}|${x.brandId}|${x.brand}|${x.accountId}`;
                        return x;
                    });
                }
                return returnValue;
            },
        }),
    }),
});

export const { useGetAccountProfilesQuery } = accountProfilesApiSlice;
