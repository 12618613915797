import { useQuery } from "@tanstack/react-query";
import { APIs } from "../services/apiConfig";
import { plainAxiosInstance } from "../services/axiosSetup";

const useRCSpreadGroups = () => {
    //convert request to form data
    const { data, refetch, isLoading, error, isFetching } = useQuery({
        queryKey: ["rcSpreadGroups"],
        queryFn: () =>
            plainAxiosInstance.get(`${APIs.RC_SPREAD.GET_SPREAD_SERVER_GROUPS}`, {
                headers: {
                    Accept: "application/json",
                },
            }),
        // axios.get(`${process.env.REACT_APP_API_URL}${APIs.RC_SPREAD.GET_SPREAD_SERVER_GROUPS}`, {
        //     headers: {
        //         Accept: "application/json",
        //         // "Content-type": "multipart/form-data",
        //         Authorization: `Bearer ${token}`,
        //     },
        // }),
        staleTime: 60 * 1000, //(60 x 1000ms = 1minute)
    });

    const rcSpreadGroups = data?.data?.status
        ? null
        : data?.data
        ? //convert data to target output
          data.data.reduce((acc: any, item: any) => {
              if (acc[item.groupKind]) {
                  acc[item.groupKind].push(item.symbol);
              } else {
                  acc[item.groupKind] = [item.symbol];
              }
              return acc;
          }, {})
        : [];

    return {
        rcSpreadGroups,
        // @ts-ignore
        rcSpreadGroupsError: data?.data?.message || error?.message || error || null,
        refetchRcSpreadGroups: refetch,
        isFetching,
        isLoading,
    };
};

export default useRCSpreadGroups;
