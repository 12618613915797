import { HolderOutlined, SearchOutlined } from "@ant-design/icons";
import { Collapse, Input, Tooltip, FormInstance } from "antd";
import { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./styles.less";
import { FormComponent } from "../FormComponent";
import { ComponentType } from "../../constants";
import { CustomTransferTextProps, TransferItemGroupProps, TransferItemProps } from "../../constants/type";

export interface CustomTransferProps {
    form: FormInstance;
    name: string;
    itemList: TransferItemGroupProps[];
    selectedList: string[];
    onChange?: (selectedOption: string[]) => void;
    text?: CustomTransferTextProps;
}

const CustomTransfer = (props: CustomTransferProps) => {
    const [optionList, setOptionList] = useState<TransferItemProps[]>([]);
    const [filteredOptionList, setFilteredOptionList] = useState<TransferItemGroupProps[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<string[]>(props.selectedList);
    const [searchText, setSearchText] = useState<string>("");

    const getFilteredOption = (itemList: TransferItemGroupProps[], sText: string) => {
        return (
            sText.length > 0
                ? cloneDeep(itemList)
                      .filter((x) => x.title.toLowerCase().indexOf(sText) > -1 || x.children.some((y) => y.text.toLowerCase().indexOf(sText) > -1))
                      .map((x) => {
                          if (x.title.toLowerCase() !== sText) {
                              x.children = x.children.filter((y) => y.text.toLowerCase().indexOf(sText) > -1);
                          }
                          return x;
                      })
                : cloneDeep(itemList)
        ).filter((w) => w.children.length > 0);
    };

    const onSearchTextChanged = (e: any) => {
        let currentText = e.target.value.toLowerCase();
        setFilteredOptionList(getFilteredOption(props.itemList, currentText));
        setSearchText(currentText);
    };

    const onOptionToggle = (optionId: string) => {
        let latestSelectedOptions = cloneDeep(selectedOptions);
        latestSelectedOptions =
            latestSelectedOptions.indexOf(optionId) > -1 ? latestSelectedOptions.filter((x) => x !== optionId) : [...latestSelectedOptions, optionId];

        setSelectedOptions(latestSelectedOptions);

        props.onChange && props.onChange(latestSelectedOptions);
    };

    const reorder = (list: string[], startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result: any) => {
        if (!result.destination) {
            return;
        }
        let finalList = reorder(selectedOptions, result.source.index, result.destination.index);
        // let formValues: any = {};
        // formValues[props.name] = finalList;
        // props.form.setFieldsValue({ ...formValues });
        setSelectedOptions(finalList);
        props.onChange && props.onChange(finalList);
    };

    const setOptionListData = () => {
        let temp = getFilteredOption(props.itemList, "");
        setOptionList(
            temp.reduce((list: TransferItemProps[], x: TransferItemGroupProps) => {
                return list.concat(cloneDeep(x.children));
            }, [])
        );
        setFilteredOptionList(getFilteredOption(props.itemList, searchText));
    };

    useEffect(() => {
        setOptionListData();
        return () => {};
    }, []);

    useEffect(() => {
        setOptionListData();
        return () => {};
    }, [props.itemList]);

    useEffect(() => {
        setSelectedOptions(props.selectedList);
        return () => {};
    }, [props.selectedList]);

    return (
        <div className="custom-transfer-container">
            <div className="left-panel">
                <div>
                    <FormComponent label={""} name={props.name} extra={{ type: ComponentType.hidden, value: "" }} />
                    <Input name="optionSearch" prefix={<SearchOutlined />} allowClear onChange={onSearchTextChanged} />
                </div>
                <div className="label-div">
                    <span>{props.text?.optionSelectText || `Press any option to select:`}</span>
                </div>
                <div className="items-panel">
                    <Collapse style={{ border: "none" }}>
                        {filteredOptionList.length > 0 &&
                            filteredOptionList.map((x: TransferItemGroupProps) => (
                                <Collapse.Panel key={`fc-il-${x.id}`} header={x.title} className="item">
                                    <div className="item-children-panel">
                                        {x.children.length > 0 &&
                                            x.children.map((y: TransferItemProps) => (
                                                <div
                                                    key={`fc-il-${x.id}-${y.id}`}
                                                    className={`option-item ${selectedOptions.indexOf(`${y.id}`) > -1 ? "active" : ""}`}
                                                    onClick={() => onOptionToggle(`${y.id}`)}
                                                >
                                                    <Tooltip title={y.description}>{y.text}</Tooltip>
                                                </div>
                                            ))}
                                    </div>
                                </Collapse.Panel>
                            ))}
                    </Collapse>
                </div>
            </div>
            <div className="right-panel">
                <div className="sorting-panel">
                    <div className="title">{props.text?.selectedTitle || `Selected Options`}</div>
                    <div className="content">
                        {optionList.length > 0 && (
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId={`custom-transfer-${props.name}`} direction="vertical">
                                    {(provided, snapshot) => (
                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                            {selectedOptions.map((x: string, index: number) => {
                                                let currentItem = optionList[optionList.findIndex((k) => `${k.id}` === x)];
                                                if (currentItem === undefined) return <></>;

                                                return (
                                                    <Draggable key={`fc-tfr-sp-i-${x}`} draggableId={x} index={index}>
                                                        {(provided, snapshot) => (
                                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                <div className="sort-item">
                                                                    <HolderOutlined />
                                                                    {currentItem.text}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            })}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomTransfer;
