import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { APIs } from "../services/apis";
import { plainAxiosInstance } from "../services/axiosSetup";

/* sample api response
[
  {
    "type": "default_clean_symbol",
    "value": "XAUUSD,EURUSD,DJ30,GBPUSD,NAS100,GBPJPY,USDJPY,GER40,BTCUSD,USDCAD,CL-OIL,EURAUD,EURJPY,AUDCAD,AUDUSD,USOUSD,GBPAUD,USDCHF,NAS100ft,CHFJPY,EURGBP,HK50ft,GBPCAD,EURCAD,HK50,SP500,NZDUSD,DJ30ft,GBPNZD,NZDCAD,AUDNZD,XAGUSD,XAUEUR,UKOUSD,GER40ft,UK100,ETHUSD,SP500ft,UKOUSDft,Nikkei225,SHBUSD,BTCEUR"
  },
  {
    "type": "default_score_max",
    "value": "4"
  },
  {
    "type": "default_score_sum",
    "value": "20"
  },
  {
    "type": "future_score_max",
    "value": "6"
  },
  {
    "type": "future_spread_ratio_threshold",
    "value": "5"
  },
  {
    "type": "future_symbol",
    "value": "DJ30,DJ30ft,NAS100,NAS100ft,SP500,SP500ft,CL-OIL,UKOUSD,UKOUSDft,USOUSD"
  },
  {
    "type": "spread_ratio_threshold",
    "value": "3"
  },
  {
    "type": "wave_ratio",
    "value": "0"
  }
]
*/

interface IRCMidBiasSettingsResponse {
    type: string;
    value: string;
}

const useRCMidBiasSettings = () => {
    //convert request to form data

    const { data, refetch, isLoading, error, isFetching, dataUpdatedAt } = useQuery({
        queryKey: ["rcMidBiasSettings"],
        queryFn: () =>
            plainAxiosInstance.get(APIs.RC_MIDBIAS.GET_MIDBIAS_SETTINGS, {
                headers: {
                    Accept: "application/json",
                },
            }),
        staleTime: 15 * 1000, //(60 x 1000ms = 1minute)
        refetchInterval: 15 * 1000,
    });

    const rcMidBiasSettings: IRCMidBiasSettingsResponse[] = data?.data?.status ? null : data?.data || null;

    // @ts-ignore
    const rcMidBiasSettingsError = data?.data?.message || error?.message || error || null;

    return {
        rcMidBiasSettings,
        dataUpdatedAt,
        rcMidBiasSettingsError,
        refetchRcMidBiasSettings: refetch,
        isFetching,
        isLoading,
    };
};

export const useMidBiasSettingsMutation = () => {
    const queryClient = useQueryClient();
    // post POST_MIDBIAS_SETTINGS
    const {
        mutateAsync: updateMidBiasSettings,
        isLoading: updateMidBiasSettingsLoading,
        error: updateMidBiasSettingsError,
    } = useMutation(
        (data: any) =>
            plainAxiosInstance.post(APIs.RC_MIDBIAS.POST_MIDBIAS_SETTINGS, data, {
                headers: {
                    Accept: "application/json",
                },
            }),
        {
            onSuccess: res => {
                queryClient.invalidateQueries(["rcMidBiasSettings"]);
                // console.log(`Solving reject orders response 200`, res);
                return res;
            },
            onError: error => {
                queryClient.invalidateQueries(["rcMidBiasSettings"]);
                // @ts-ignore
                const errorMsg = error?.response?.data?.message || error?.message || error;
                console.error(`Error on updating mid bias settings:`, errorMsg);
                return errorMsg;
            },
        }
    );

    // upload file

    const {
        mutateAsync: uploadTimeThreshold,
        isLoading: isLoadingUploadTimeThreshold,
        error: uploadTimeThresholdError,
    } = useMutation(
        async ({ formData }: { formData: FormData }): Promise<any> => {
            return plainAxiosInstance.post(
                // .post(`${restfulUrl}/setting/file/` + props.logType, formData)
                APIs.RC_MIDBIAS.POST_UPLOAD_TIMETHRESHOLD,
                formData
                // {
                //     headers: {
                //         Accept: "application/json",
                //     },
                // }
            );
        },
        {
            onSuccess: res => {
                return res;
            },
            onError: error => {
                console.error(`Error on uploading time threshold:`, error);
                return error;
            },
        }
    );

    return {
        updateMidBiasSettings,
        updateMidBiasSettingsLoading,
        updateMidBiasSettingsError,
        uploadTimeThreshold,
        isLoadingUploadTimeThreshold,
        uploadTimeThresholdError,
    };
};

export default useRCMidBiasSettings;
