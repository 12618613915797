import React, { useCallback, useEffect, useMemo, useState } from "react";
import FlexiDataTable from "../../../../../components/FlexiDataTable";
import { FlexiDataTableCallbackProps, FlexiDataTableOptionsProps, KeyValuePair } from "../../../../../constants/type";
import { APIs } from "../../../../../services/apiConfig";
import { plainAxiosInstance } from "../../../../../services/axiosSetup";
import { CALLBACK_KEY, ComponentType } from "../../../../../constants";
import { DTColProps, ErrorCatchValidator } from "../../../../../utils/Common";
import { FileTextOutlined } from "@ant-design/icons";
import { StringFormat } from "../../../../../utils/array";
import { Form, message } from "antd";
import moment from "moment";
import FilterFormComponent, { FilterFormComponentCallbackKey } from "./filterFormComponent";
import NoteEntitiesModal, { NoteEntitiesModalCallbackKey, NoteEntitiesProps } from "./noteEntitiesModal";
import { defaultIfEmptyOrNull } from "../../../../../utils/string";
import { DefaultIfEmpty } from "../../../../../utils/object";
import AddNoteModal, { AddNoteModalCallbackKey } from "./addNoteModal";

export interface DataTableComponentProps {
    KeyStr: string;
    currentTabKey: string;
    title: React.ReactNode;
    columns: string[];
    excludeCompareColumns: string[];
    resetFirstLoad: number;
}

const ActionTypeProps = {
    0: "Create",
    1: "Update",
    2: "Delete",
    3: "Create",
};

const DataTableComponent = (props: DataTableComponentProps) => {
    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [isNoteModalVisible, setIsNoteModalVisible] = useState<boolean>(false);
    const [isAddNoteModalVisible, setIsAddNoteModalVisible] = useState<boolean>(false);
    const [currentNoteEntities, setCurrentNoteEntities] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<any[]>([]);
    const [servers, setServers] = useState<KeyValuePair[]>([]);
    const [filterParams, setFilterParams] = useState<any>({
        startDate: moment().subtract(1, "day").format("YYYY-MM-DD 00:00:00"),
        endDate: moment().format("YYYY-MM-DD 23:59:59"),
    });
    const [selectedKey, setSelectedKey] = useState<string[]>([]);
    const [filterForm] = Form.useForm();

    const columnWrapper = (type: string, textAlign: string, values: any, className: string[] = []) => {
        switch (type) {
            case "xsm":
                return DTColProps.XSmall(values, [...[`text-${textAlign}`], ...className]);
            case "m":
                return DTColProps.Middle(values, [...[`text-${textAlign}`], ...className]);
            case "l":
                return DTColProps.Large(values, [...[`text-${textAlign}`], ...className]);
            case "xl":
                return DTColProps.XLarge(values, [...[`text-${textAlign}`], ...className]);
            case "sc":
                return DTColProps.SCurrency(values, className, -1);
            case "c":
                return DTColProps.Currency(values, className, -1);
            default:
                return DTColProps.Small({ ...values, width: "9vw" }, [...[`text-${textAlign}`], ...className]);
        }
    };

    const columns = useMemo(
        () => [
            ...[
                {
                    title: "Action Type",
                    dataIndex: "currentAction",
                    key: "currentAction",
                    options: {
                        visible: false,
                        filter: {
                            type: ComponentType.dropdown,
                            value: Object.keys(
                                data.reduce((acc: any, x: any) => {
                                    acc[x.currentAction] = 1;
                                    return acc;
                                }, {})
                            ).map(x => ({ text: DefaultIfEmpty(ActionTypeProps, x, "Unknown"), value: x })),
                        },
                    },
                },
                DTColProps.Middle({
                    title: "Note Entities",
                    dataIndex: "noteEntities",
                    key: "noteEntities",
                    render: (text: NoteEntitiesProps[] | null) => defaultIfEmptyOrNull(text, [{ note: "" }])[0].note,
                    options: {
                        filter: {
                            type: ComponentType.switch,
                            value: ["Show All", "Empty Note Only"],
                            callback: (filterValue: any, rowData: any) =>
                                filterValue ? defaultIfEmptyOrNull(rowData.noteEntities, []).length === 0 : true,
                        },
                    },
                }),
            ],
            ...props.columns.map((x: string) => {
                let colTxtArr = x.split("||"),
                    colTxt = colTxtArr[0],
                    colType = colTxtArr[1],
                    colAlign = colTxtArr.length > 2 ? colTxtArr[2] : "left";
                if (props.excludeCompareColumns.includes(colTxt)) {
                    return colTxt === "Time"
                        ? columnWrapper(colType, colAlign, {
                              title: colTxt,
                              dataIndex: `current${colTxt}`,
                              key: `current${colTxt}`,
                              render: (text: any) => `${defaultIfEmptyOrNull(text, "")}`,
                          })
                        : columnWrapper(colType, colAlign, {
                              title: colTxt,
                              dataIndex: `${colTxt[0].toLowerCase()}${colTxt.slice(1)}`,
                              key: `${colTxt[0].toLowerCase()}${colTxt.slice(1)}`,
                          });
                }

                return columnWrapper(
                    colType,
                    colAlign,
                    {
                        title: colTxt,
                        dataIndex: `current${colTxt}`,
                        key: `current${colTxt}`,
                        render: (text: any, record: any) =>
                            record[`current${colTxt}`] !== record[`previous${colTxt}`] ? (
                                ActionTypeProps?.hasOwnProperty(record[`previousAction`]) ? (
                                    <div className="diff-lah">
                                        <div>{`${defaultIfEmptyOrNull(text, "")}`}</div>
                                        <div>{`${defaultIfEmptyOrNull(record[`previous${colTxt}`], "")}`}</div>
                                    </div>
                                ) : (
                                    <div className="diff-lah">{`${defaultIfEmptyOrNull(text, "")}`}</div>
                                )
                            ) : (
                                `${defaultIfEmptyOrNull(text, "")}`
                            ),
                    },
                    ["compare-color"]
                );
            }),
            ...[
                DTColProps.XSmall(
                    {
                        title: "Action Type",
                        dataIndex: "currentAction",
                        key: "currentAction",
                        // render: (text: number, record: any) =>
                        //     ActionTypeProps?.hasOwnProperty(record[`previousAction`]) ? (
                        //         <div className="diff-lah">
                        //             <div>{DefaultIfEmpty(ActionTypeProps, record[`currentAction`], "Unknown")}</div>
                        //             <div>{DefaultIfEmpty(ActionTypeProps, record[`previousAction`], "Unknown")}</div>
                        //         </div>
                        //     ) : (
                        //         <div className="diff-lah">{DefaultIfEmpty(ActionTypeProps, record[`currentAction`], "Unknown")}</div>
                        //     ),
                        render: (text: number) => DefaultIfEmpty(ActionTypeProps, text, "Unknown"),
                    },
                    ["text-center"]
                ),
            ],
        ],
        [props.columns, props.excludeCompareColumns, servers, data]
    );

    const options: FlexiDataTableOptionsProps = {
        defaultCollapseFilterPanel: true,
        separateActionButton: true,
        rowExtra: [{ text: "", value: "moredetails", icon: <FileTextOutlined /> }],
        enableRowSelection: true,
        hideRowSelectionsSummary: true,
        rowSelectionData: {
            rowSelectionType: "checkbox",
            selectedRowKeys: [],
            options: {
                fixed: true,
            },
        },
        ...(selectedKey.length > 0 && {
            extraButtons: [{ text: "Add Notes", value: "addnotes", type: "primary" }],
        }),
    };

    const componentCallback: FlexiDataTableCallbackProps = (type: number, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.ROW_SELECTION_CALLBACK:
                setSelectedKey(FormData.selectedRowKeys);
                break;
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                break;
            case CALLBACK_KEY.OTHERS:
                if (FormData === "addnotes") {
                    setIsAddNoteModalVisible(true);
                }
                break;
            case CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK:
                if (FormData.key === "moredetails") {
                    setCurrentNoteEntities(defaultIfEmptyOrNull(FormData.data.noteEntities, []));
                    setIsNoteModalVisible(true);
                }
                break;
            default:
                break;
        }
    };

    const submitReason = useCallback(
        (reason: any) => {
            plainAxiosInstance
                .post(StringFormat(APIs.RISK_TOOL.GET_CONFIG_DIFF_RECORD_LIST_ADD_NOTES, [props.KeyStr]), reason)
                .then((res: any) => setRunRefetchDataList(true))
                .catch((error: any) =>
                    ErrorCatchValidator(error, (err: any) => message.error(`Error occurred during create reason: ${err.message}`, 3))
                );
        },
        [props.KeyStr]
    );

    const getDataList = useCallback(
        (offset: number, totalRecords: number) => {
            plainAxiosInstance
                .post(`${StringFormat(APIs.RISK_TOOL.GET_CONFIG_DIFF_RECORD_LIST, [props.KeyStr])}`, {
                    configDiffType: props.KeyStr,
                    offset,
                    limit: 500,
                    ...filterParams,
                })
                .then((res: any) => {
                    if (res.data && res.data.length > 0) {
                        setData(prev => [...prev, ...res.data]);
                    }
                })
                .catch((error: any) => ErrorCatchValidator(error, (err: any) => console.log(err)))
                .finally(() => {
                    if (totalRecords > offset + 500) {
                        getDataList(offset + 500, totalRecords);
                    } else {
                        setIsLoading(false);
                    }
                });
        },
        [props.KeyStr, filterParams]
    );

    const getDataListCount = useCallback(() => {
        plainAxiosInstance
            .post(`${StringFormat(APIs.RISK_TOOL.GET_CONFIG_DIFF_RECORD_LIST, [props.KeyStr])}/count`, {
                configDiffType: props.KeyStr,
                ...filterParams,
            })
            .then((res: any) => {
                if (res.data > 0) {
                    setData([]);
                    setTimeout(() => getDataList(0, res.data), 300);
                } else {
                    setData([]);
                    setIsLoading(false);
                }
            })
            .catch((error: any) => {
                setData([]);
                setIsLoading(false);
                ErrorCatchValidator(error, (err: any) => console.log(err));
            })
            .finally(() => setIsFirstLoad(false));
    }, [props.KeyStr, filterParams]);

    const getConfig = () => {
        setIsLoading(true);
        plainAxiosInstance
            .get(APIs.RC_ROOT.GET_SERVERS)
            .then((res: any) => {
                if (res.data && res.data.length > 0) {
                    setServers(res.data.map((x: any) => ({ text: x.serverName, value: x.serverId })));
                }
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        setIsFirstLoad(true);
    }, [props.resetFirstLoad]);

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            getDataListCount();
            setRunRefetchDataList(false);
        }
    }, [runRefetchDataList]);

    useEffect(() => {
        getConfig();
        return () => {};
    }, []);

    return (
        <>
            <div className="table-component-container">
                <div className="search-panel">
                    <div>
                        <span>Please select the searching condition :</span>
                    </div>
                    <div className="main-panel">
                        <FilterFormComponent
                            KeyStr={props.KeyStr}
                            currentTabKey={props.currentTabKey}
                            form={filterForm}
                            servers={servers}
                            callback={(type: number, data: any) => {
                                if (type === FilterFormComponentCallbackKey.FormSubmit) {
                                    setFilterParams(data);
                                    setRunRefetchDataList(true);
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="table-panel">
                    {!isFirstLoad && (
                        <FlexiDataTable
                            bordered
                            rowKeyProperty="sid"
                            title={
                                <>
                                    <span
                                        style={{ fontSize: "1rem", fontWeight: "bold", color: "#000000" }}
                                    >{`${props.title} Configuration Difference View`}</span>
                                    <span
                                        style={{ fontSize: "1rem", fontWeight: "bold", color: "#f00f00" }}
                                    >{` (** Top : current value, Bottom: previous value)`}</span>
                                </>
                            }
                            columns={columns}
                            dataSource={data}
                            options={options}
                            callback={componentCallback}
                            loading={isLoading}
                            filterInitialValue={{
                                noteEntities: false,
                            }}
                            tableProps={{
                                rowClassName: (record: any, index: number) => {
                                    let actionClassName = "";
                                    switch (record.currentAction) {
                                        case 0:
                                            actionClassName = "row-add";
                                            break;
                                        case 1:
                                            actionClassName = "row-update";
                                            break;
                                        case 2:
                                            actionClassName = "row-delete";
                                            break;
                                        default:
                                            actionClassName = "row-create";
                                            break;
                                    }
                                    return actionClassName;
                                },
                            }}
                        />
                    )}
                </div>
            </div>
            <NoteEntitiesModal
                isModalVisible={isNoteModalVisible}
                noteEntities={currentNoteEntities}
                callback={(type: number, data: any) => {
                    switch (type) {
                        case NoteEntitiesModalCallbackKey.Close:
                            setCurrentNoteEntities([]);
                            setIsNoteModalVisible(false);
                            break;
                        default:
                            break;
                    }
                }}
            />
            <AddNoteModal
                selectedKeys={selectedKey}
                isModalVisible={isAddNoteModalVisible}
                callback={(type: number, data: any) => {
                    switch (type) {
                        case AddNoteModalCallbackKey.FormSubmit:
                            submitReason(data);
                            setSelectedKey([]);
                            setIsAddNoteModalVisible(false);
                            break;
                        case AddNoteModalCallbackKey.Close:
                            setIsAddNoteModalVisible(false);
                            break;
                        default:
                            break;
                    }
                }}
            />
        </>
    );
};

export default DataTableComponent;
