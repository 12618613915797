import { Col, FormInstance, Row } from "antd";
import { ComponentType } from "../../../constants";
import { FormComponent } from "../../FormComponent";

export interface TableTemplateProps {
    prefix: string;
    form: FormInstance;
}

const TableTemplate = (props: TableTemplateProps) => {
    return (
        <>
            <Row>
                <Col span={24}>
                    <FormComponent
                        label={"Except Column(s)"}
                        name={`${props.prefix}_exceptColumn`}
                        extra={{
                            type: ComponentType.text,
                            value: "",
                            itemProps: {
                                labelAlign: "left",
                                labelCol: { span: 5 },
                                wrapperCol: { span: 11 },
                            },
                            inputProps: {
                                placeholder: "',' as separator if multiple column",
                            },
                        }}
                    />
                </Col>
            </Row>
        </>
    );
};

export default TableTemplate;
