import { HomeOutlined } from "@ant-design/icons";
import { Button, Col, Form, Popconfirm, Row } from "antd";
import { useEffect, useState } from "react";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { FormComponent } from "../../../components/FormComponent";
import LoadingComponent from "../../../components/Loading";
import SitePageHeader from "../../../components/PageHeader";
import { ComponentType, SUCCESS_FAILED } from "../../../constants";
import { REQUIRED_FIELD } from "../../../constants/errorMessage";
import { apiRequest, APIs } from "../../../services/apiConfig";
import { ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";

export type TagState = {
    action: string;
    data: any;
};

function TagCreateEditPage() {
    let navigate = useNavigate();
    let location = useLocation();
    let com_state: TagState = location.state as TagState;
    const [currentState] = useState<TagState>(com_state || { action: "add", data: null });
    const [isAddAction] = useState<boolean>(currentState.action === "add");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [tagForm] = Form.useForm();

    const onSubmit = (values: any) => {
        setIsLoading(true);

        if (isAddAction) {
            apiRequest(APIs.CREATE_NEW_TAG_PROFILE, values)
                .then((data) => {
                    ErrorMessageHandler("New tag profile", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                    navigate("/siteadmin/tag");
                })
                .catch((error) =>
                    ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("new tag profile", SUCCESS_FAILED.FAILED_CREATE_DATA, err))
                )
                .finally(() => setIsLoading(false));
        } else {
            const status = values.status === true || values.status === 1 ? 1 : 0;
            apiRequest(APIs.UPDATE_TAG_PROFILE, {
                ...values,
                status,
                tagProfileId: values.id,
            })
                .then((data) => {
                    ErrorMessageHandler("Existing tag profile", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                    navigate("/siteadmin/tag");
                })
                .catch((error) =>
                    ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("existing tag profile", SUCCESS_FAILED.FAILED_UPDATE_DATA, err))
                )
                .finally(() => setIsLoading(false));
        }
    };

    const deleteTag = (tagProfileId: string, navigator: NavigateFunction) => {
        apiRequest(APIs.DELETE_TAG_PROFILE, { tagProfileId })
            .then((data) => {
                ErrorMessageHandler("The tag profile", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                navigator("/siteadmin/tag");
            })
            .catch((error) => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("tag profile", SUCCESS_FAILED.FAILED_DELETE_DATA, err)));
    };

    useEffect(() => {
        !isAddAction ? tagForm.setFieldsValue(currentState.data) : tagForm.resetFields();
        return () => {};
    }, [currentState.data, isAddAction, tagForm]);

    return (
        <SitePageHeader
            title={isAddAction ? "Create New Tag" : "Edit Tag"}
            routes={[
                {
                    path: "/siteadmin/tag",
                    breadcrumbName: "Tag Management",
                    icon: <HomeOutlined />,
                },
                {
                    path: "",
                    breadcrumbName: isAddAction ? "Create New Tag" : "Edit Tag",
                },
            ]}
            onBack={() => navigate("/siteadmin/tag")}
            extraProps={{
                extra: isAddAction
                    ? []
                    : [
                          <Popconfirm
                              key={"cetmp-del"}
                              title="Confirm to delete?"
                              onConfirm={() => deleteTag(currentState.data.id, navigate)}
                              okText="Yes"
                              cancelText="No"
                          >
                              <Button type="primary" danger>
                                  Delete Tag
                              </Button>
                          </Popconfirm>,
                      ],
            }}
        >
            {isLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Submitting..." />
                </div>
            ) : (
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    form={tagForm}
                    layout="horizontal"
                    initialValues={currentState.data}
                    onFinish={onSubmit}
                >
                    <Row>
                        <Col span={15}>
                            <FormComponent label={""} name={"id"} extra={{ type: ComponentType.hidden, value: "" }} />
                            <FormComponent
                                label="Tag Name"
                                name="name"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Description"
                                name="description"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                        </Col>
                    </Row>
                    {!isAddAction ? (
                        <Row>
                            <Col span={15}>
                                <FormComponent
                                    label="Status"
                                    name="status"
                                    extra={{
                                        type: ComponentType.switch,
                                        value: ["Inactive", "Active"],
                                    }}
                                />
                            </Col>
                        </Row>
                    ) : null}
                    <Row>
                        <Col span={24}>
                            <div className="step-btns-group">
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            )}
        </SitePageHeader>
    );
}

export default TagCreateEditPage;
