import { useQuery } from "@tanstack/react-query";
import { APIs } from "../services/apis";
import { plainAxiosInstance } from "../services/axiosSetup";

/*
sample response from the API
{
    "serverId": "AU1",
    "serverName": "AU1",
    "serverType": 0,
    "comment": null,
    "serverAttributes": 1,
    "oldServerId": null,
    "login": null,
    "password": null
}
*/

interface RCServer {
    serverId: string;
    serverName: string;
    serverType: number;
    comment: string | null;
    serverAttributes: number;
    oldServerId: string | null;
    login: string | null;
    password: string | null;
}

const useRCServers = () => {
    const { data, refetch, isLoading, error, isFetching } = useQuery({
        queryKey: ["rcServers"],
        queryFn: () =>
            plainAxiosInstance.get(`${APIs.RC_ROOT.GET_SERVERS}`, {
                headers: {
                    Accept: "application/json",
                },
            }),
        // axios.get(`${process.env.REACT_APP_API_URL}${APIs.RC_ROOT.GET_SERVERS}`, {
        //     headers: {
        //         Accept: "application/json",
        //         Authorization: `Bearer ${token}`,
        //     },
        // }),
        staleTime: 60 * 1000, //(10 x 1000ms = 10 seconds)
        // refetchInterval: 60 * 1000, //(10 x 1000ms = 10 seconds)
    });

    const rcServers: RCServer[] | null = data?.data?.status ? null : data?.data || null;
    // @ts-ignore
    const rcServersError: string | null = data?.data?.message || error?.message || error || null;

    return {
        rcServers,
        rcServersError,
        refetchRcServers: refetch,
        isFetching,
        isLoading,
    };
};

export default useRCServers;
