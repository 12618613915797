import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import FlexiDataTable from "../../../components/FlexiDataTable";
import { useGetBrandsQuery } from "../../../store/apis/brand";
import { apiRequest, APIs } from "../../../services/apiConfig";
import CardBox from "../../../components/Common/CardBox";
import AuthHelper, { AuthKeys } from "../../../helpers/authHelper";
import { ComponentType, CALLBACK_KEY, SUCCESS_FAILED } from "../../../constants";
import { BrandsList, FlexiDataTableCallbackProps, FlexiDataTableOptionsProps } from "../../../constants/type";
import { ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";

const BrandListPage = () => {
    const { isLoading, data, refetch } = useGetBrandsQuery({});
    const [manualLoading, setManualLoading] = useState<boolean>(false);
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.ADMIN_BRAND_EDIT);
    const [brands, setBrands] = useState<BrandsList[]>([]);

    let navigate = useNavigate();

    const getFilterConfigList = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["brand"] })
            .then((data: any) => {
                setBrands(data.brands);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => console.log(err));
            })
            .finally(() => {
                setManualLoading(false);
            });
    };

    useEffect(() => {
        getFilterConfigList();
        refetch();
        return () => {};
    }, []);

    const columns: any[] = [
        {
            title: "Brand Name",
            dataIndex: "id",
            key: "id",
            render: (id: number) => {
                let idx = brands.findIndex((x) => x.id === id);
                return idx > -1 ? brands[idx].brand : id;
            },
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: brands.map((x) => ({ text: x.brand, value: x.id })),
                },
            },
        },
    ];

    const options: FlexiDataTableOptionsProps = {
        add: () => {
            if (enableUpdate) {
                return "/siteadmin/serverbrand/brand/create";
            } else return enableUpdate;
        },
        edit: (record: any, overwriteProps: any) => {
            if (enableUpdate) {
                let newProps = { ...overwriteProps };
                newProps.label = <Link to={`/siteadmin/serverbrand/brand/edit/${record.id}`}>{newProps.label}</Link>;
                return newProps;
            } else return enableUpdate;
        },
        delete: enableUpdate,
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, BrandData) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                navigate("/siteadmin/serverbrand/brand/create", { state: { data: null, action: "add" } });
                break;
            case CALLBACK_KEY.DO_EDIT:
                navigate(`/siteadmin/serverbrand/brand/edit/${BrandData.id}`, { state: { data: BrandData.id, action: "edit" } });
                break;
            case CALLBACK_KEY.DO_DELETE:
                setManualLoading(true);
                apiRequest(APIs.DELETE_BRAND, {
                    brandId: BrandData.id,
                })
                    .then((data) => ErrorMessageHandler(BrandData.brand, SUCCESS_FAILED.SUCCESS_DELETE_DATA))
                    .catch((error) => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("brand", SUCCESS_FAILED.FAILED_DELETE_DATA, err)))
                    .finally(() => {
                        setManualLoading(false);
                        refetch();
                    });
                break;
            default:
                break;
        }
    };

    return (
        <>
            <CardBox title={"Brand Listing"}>
                <FlexiDataTable
                    rowKeyProperty="id"
                    title=""
                    columns={columns}
                    options={options}
                    dataSource={data?.data || []}
                    callback={componentCallback}
                    loading={isLoading || manualLoading}
                />
            </CardBox>
        </>
    );
};

export default BrandListPage;
