import { HolderOutlined, SortAscendingOutlined } from "@ant-design/icons";
import { Modal, Form } from "antd";
import { cloneDeep } from "lodash";
import { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useNavigate } from "react-router-dom";
import CardBox from "../../../../components/Common/CardBox";
import FlexiDataTable from "../../../../components/FlexiDataTable";
import { FormComponent } from "../../../../components/FormComponent";
import { ComponentType, CALLBACK_KEY, SUCCESS_FAILED } from "../../../../constants";
import { FlexiDataTableOptionsProps, FlexiDataTableCallbackProps, MetricGroup } from "../../../../constants/type";
import AuthHelper, { AuthKeys } from "../../../../helpers/authHelper";
import { apiRequest } from "../../../../services/apiConfig";
import { APIs } from "../../../../services/apis";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../../../utils/Common";

const MetricGroupPage = () => {
    // const { isLoading, data, refetch } = useGetMetricGroupQuery({});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [manualLoading, setManualLoading] = useState<boolean>(false);
    const [data, setData] = useState<MetricGroup[]>([]);
    const [isShowSortingModal, setIsShowSortingModal] = useState<boolean>(false);
    const [sortingList, setSortingList] = useState<MetricGroup[]>([]);
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.APP_CONFIGURATION_EDIT);
    const [sortingForm] = Form.useForm();

    let navigate = useNavigate();

    const columns: any[] = [
        DTColProps.XLarge({
            title: "Category Name",
            dataIndex: "name",
            key: "name",
        }),
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
        },
    ];

    const options: FlexiDataTableOptionsProps = {
        enableFilter: false,
        add: enableUpdate,
        edit: enableUpdate,
        delete: enableUpdate,
        extraButtons: [
            {
                text: "Sorting",
                value: "ee-sorting",
                icon: <SortAscendingOutlined />,
            },
        ],
    };

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, data: any) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                navigate("/siteadmin/appconfig/metriccategory/create", { state: { data: null, action: "add" } });
                break;
            case CALLBACK_KEY.DO_EDIT:
                navigate("/siteadmin/appconfig/metriccategory/edit", { state: { data: data, action: "edit" } });
                break;
            case CALLBACK_KEY.DO_DELETE:
                setManualLoading(true);
                apiRequest(APIs.DELETE_METRIC_GROUP, { id: data.id })
                    .then((data) => ErrorMessageHandler("metric category", SUCCESS_FAILED.SUCCESS_DELETE_DATA))
                    .catch((error) =>
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("metric category", SUCCESS_FAILED.FAILED_DELETE_DATA, err))
                    )
                    .finally(() => {
                        setManualLoading(false);
                        getMetricGroupListing();
                    });
                break;
            case CALLBACK_KEY.OTHERS:
                setIsShowSortingModal(true);
                break;
            default:
                break;
        }
    };

    const reorder = (list: MetricGroup[], startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result: any) => {
        if (!result.destination) {
            return;
        }
        let finalList = reorder(sortingList, result.source.index, result.destination.index);
        sortingForm.setFieldsValue({ groupSorting: finalList.map((x) => `${x.id}`) });
        setSortingList(finalList);
    };

    const getMetricGroupListing = () => {
        apiRequest(APIs.GET_METRIC_GROUP_LIST, {})
            .then((res: any) => {
                let currentData: MetricGroup[] = cloneDeep(res);
                currentData.sort((a: MetricGroup, b: MetricGroup) => a.orderSeq - b.orderSeq);
                setData(currentData);
                setSortingList(currentData);
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("metric category", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsLoading(false));
    };

    const submitSortingList = (list: string[]) => {
        setIsShowSortingModal(false);
        setIsLoading(true);
        apiRequest(APIs.UPDATE_METRIC_GROUP_ORDER, { ids: list })
            .then(() => {
                ErrorMessageHandler("metric category ordering", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                getMetricGroupListing();
            })
            .catch((error) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("metric category ordering", SUCCESS_FAILED.SUCCESS_UPDATE_DATA, err))
            )
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        getMetricGroupListing();
        return () => {};
    }, []);

    return (
        <>
            <CardBox title={"Metric Category"}>
                <FlexiDataTable
                    rowKeyProperty="id"
                    title=""
                    columns={columns}
                    options={options}
                    dataSource={data || []}
                    callback={componentCallback}
                    loading={isLoading || manualLoading}
                />
            </CardBox>
            {isShowSortingModal && (
                <Modal
                    open={true}
                    title="Metric Category Sorting"
                    onCancel={() => setIsShowSortingModal(false)}
                    onOk={() => {
                        sortingForm
                            .validateFields()
                            .then((values) => submitSortingList(values["groupSorting"]))
                            .catch((err) => {});
                    }}
                >
                    <Form form={sortingForm} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} layout="horizontal">
                        <FormComponent label={""} name={"groupSorting"} extra={{ type: ComponentType.hidden, value: "" }} />
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId={`metric-group-sorting-list`} direction="vertical">
                                {(provided, snapshot) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps} className="metric-group-dragging-container">
                                        <div className="title-div">
                                            <span>Drag any category for sorting</span>
                                        </div>
                                        <div className="items-div">
                                            {sortingList.map((x: MetricGroup, index: number) => {
                                                return (
                                                    <Draggable key={`fc-tfr-sp-i-${x.id}`} draggableId={`g-${x.id}`} index={index}>
                                                        {(provided, snapshot) => (
                                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                <div className="sort-item">
                                                                    <HolderOutlined />
                                                                    {x.name}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            })}
                                            {provided.placeholder}
                                        </div>
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </Form>
                </Modal>
            )}
        </>
    );
};

export default MetricGroupPage;
