import React, { useRef, useState } from "react";
import { Button, Table, Modal, Space, Form, Typography, notification, Row, Col, Select, Tooltip, Input, InputNumber, Card, Radio } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import Loader from "../components/Loader";
import { IRCDataCenterSettings, useRCDataCenterMutate, useRCDataCenterServers, useRCDataCenterSettings } from "../../../hooks/useRCDataCenter";
import { colorMap } from "../StatusMonitor/statusUtils";
import MessageCard from "../components/MessageCard";
import { motion } from "framer-motion";
import useRCDataCenterStore from "../../../store/useRCDataCenterStore";

const DataCenterSettingsTable = () => {
    const { Text } = Typography;
    const { rcDataCenterSettings, isLoading } = useRCDataCenterSettings();
    const [currEdit, setCurrEdit] = useState<{ serverId: string; dcName: string } | "__new__" | null>(null);
    const [currDelete, setCurrDelete] = useState<{ serverId: string; dcName: string } | null>(null);
    const currEditInfo = rcDataCenterSettings?.find(
        (each: any) => typeof currEdit === "object" && each.serverId === currEdit?.serverId && each.dcName === currEdit?.dcName
    );

    const handleEdit = (server: { serverId: string; dcName: string } | "__new__" | null) => {
        if (server === null) {
            setCurrEdit(null);
            return;
        }
        setCurrEdit(server);
    };

    const handleCloseEditModal = () => {
        setCurrEdit(null);
    };

    const handleDelete = ({ serverId, dcName }: { serverId: string; dcName: string }) => {
        setCurrDelete({ serverId, dcName });
    };

    const columns = [
        {
            title: "Server",
            dataIndex: "serverId",
            sorter: (a: any, b: any) => {
                // sort alphabetically and length
                if (a.serverId?.toLowerCase() < b.serverId?.toLowerCase()) {
                    return -1;
                }
                if (a.serverId?.toLowerCase() > b.serverId?.toLowerCase()) {
                    return 1;
                }
                return 0;
            },
            width: 150,
            key: "serverId",
            render: (text: any, data: any, index: any) => (
                <div style={{ display: "flex", gap: 8, width: 200 }}>
                    <Tooltip title={`Edit ${data.serverId} - ${data.dcName}`}>
                        <Button
                            type="primary"
                            size="small"
                            icon={<EditOutlined />}
                            onClick={e =>
                                handleEdit({
                                    serverId: data.serverId,
                                    dcName: data.dcName,
                                })
                            }
                        />
                    </Tooltip>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Text style={{ lineHeight: 1, color: text?.toLowerCase() === "default" ? colorMap.primary : "inherit" }} strong>
                            {text}
                        </Text>
                        <Text style={{ lineHeight: 0.8 }} type="secondary">
                            <small>{data.dcName}</small>
                        </Text>
                    </div>
                </div>
            ),
        },
        {
            title: "CPU Max Rate",
            dataIndex: "cpuRateMax",
            width: 80,
            sorter: (a: any, b: any) => a.cpuRateMax - b.cpuRateMax,
        },
        {
            title: "CPU Max Tolerance Count",
            dataIndex: "cpuMaxToleranceCount",
            width: 80,
            sorter: (a: any, b: any) => a.cpuMaxToleranceCount - b.cpuMaxToleranceCount,
        },
        {
            title: "Min. Free Memory (MB)",
            dataIndex: "freeMemMin",
            width: 80,
            sorter: (a: any, b: any) => a.freeMemMin - b.freeMemMin,
        },
        {
            title: "Free Memory Min. Tolerance Count",
            dataIndex: "freeMemMinToleranceCount",
            width: 80,
            sorter: (a: any, b: any) => a.freeMemMinToleranceCount - b.freeMemMinToleranceCount,
        },
        {
            title: "Min. Free Disk (GB)",
            dataIndex: "freeDiskMin",
            width: 80,
            sorter: (a: any, b: any) => a.freeDiskMin - b.freeDiskMin,
        },
        {
            title: "Free Disk Min. Tolerance Count",
            dataIndex: "freeDiskMinToleranceCount",
            width: 80,
            sorter: (a: any, b: any) => a.freeDiskMinToleranceCount - b.freeDiskMinToleranceCount,
        },
        {
            title: "Max NIC",
            dataIndex: "nicMax",
            width: 80,
            sorter: (a: any, b: any) => a.nicMax - b.nicMax,
        },
        {
            title: "NIC Max Tolerance Count",
            dataIndex: "nicMaxToleranceCount",
            width: 80,
            sorter: (a: any, b: any) => a.nicMaxToleranceCount - b.nicMaxToleranceCount,
        },
        {
            title: "Max Expired Next Update Time",
            dataIndex: "maxExpiredNextUpdateTime",
            width: 80,
            sorter: (a: any, b: any) => a.maxExpiredNextUpdateTime - b.maxExpiredNextUpdateTime,
        },
        {
            title: "System Task Interval Minutes",
            dataIndex: "systemTaskIntervalMinutes",
            width: 80,
            sorter: (a: any, b: any) => a.systemTaskIntervalMinutes - b.systemTaskIntervalMinutes,
        },
        {
            title: "Login Task Interval Minutes",
            dataIndex: "loginTaskIntervalMinutes",
            width: 80,
            sorter: (a: any, b: any) => a.loginTaskIntervalMinutes - b.loginTaskIntervalMinutes,
        },
        {
            title: "Action",
            width: 170,
            render: (text: any, data: any, idx: any) => (
                <Space style={{ width: " 100%" }}>
                    {/* button to solved */}
                    <Button
                        type="primary"
                        size="small"
                        icon={<EditOutlined />}
                        onClick={e =>
                            handleEdit({
                                serverId: data.serverId,
                                dcName: data.dcName,
                            })
                        }
                    >
                        Edit
                    </Button>
                    <Button
                        type="text"
                        size="small"
                        color="red"
                        icon={<DeleteOutlined />}
                        onClick={e => handleDelete({ serverId: data.serverId, dcName: data.dcName })}
                        disabled={data.serverId === "default"}
                    >
                        Delete
                    </Button>
                </Space>
            ),
        },
    ];

    const settingsViewMode = useRCDataCenterStore(state => state.settingsViewMode);
    const setSettingsViewMode = useRCDataCenterStore(state => state.setSettingsViewMode);
    const { Group: RadioGroup, Button: RadioButton } = Radio;
    return (
        <div style={{ position: "relative" }}>
            <div style={{ display: "flex", justifyContent: "flex-start", flexWrap: "wrap", gap: "4px", alignItems: "center", padding: "1rem" }}>
                <Button onClick={() => handleEdit("__new__")} icon={<PlusOutlined />}>
                    Add
                </Button>
                <Space style={{ marginLeft: "auto" }}>
                    <Text>View Mode: </Text>
                    <RadioGroup
                        buttonStyle="solid"
                        value={settingsViewMode}
                        onChange={e => {
                            setSettingsViewMode(e.target.value);
                        }}
                    >
                        <RadioButton value="card">Card</RadioButton>
                        <RadioButton value="table">Table</RadioButton>
                    </RadioGroup>
                </Space>
            </div>
            {isLoading && !rcDataCenterSettings ? (
                <MessageCard type="info">Loading Data Center settings...</MessageCard>
            ) : settingsViewMode === "card" ? (
                <Row gutter={[8, 8]} style={{ padding: "0 0.5rem" }}>
                    {rcDataCenterSettings !== null &&
                        rcDataCenterSettings
                            ?.sort(each => {
                                return each.serverId === "default" ? -1 : 1;
                            })
                            ?.map((each: any, idx: number) => {
                                return (
                                    <DataCenterSettingCards
                                        data={each}
                                        key={each.serverId + each.dcName + idx}
                                        index={idx}
                                        actions={[
                                            <Tooltip title="Edit">
                                                <EditOutlined
                                                    onClick={e =>
                                                        handleEdit({
                                                            serverId: each.serverId,
                                                            dcName: each.dcName,
                                                        })
                                                    }
                                                />
                                            </Tooltip>,
                                            <Tooltip title={each.serverId === "default" ? "" : "Delete"}>
                                                <DeleteOutlined
                                                    style={{ color: each.serverId === "default" ? colorMap.disabled : "inherit" }}
                                                    onClick={e =>
                                                        each.serverId === "default"
                                                            ? null
                                                            : handleDelete({
                                                                  serverId: each.serverId,
                                                                  dcName: each.dcName,
                                                              })
                                                    }
                                                />
                                            </Tooltip>,
                                        ]}
                                    />
                                );
                            })}
                </Row>
            ) : (
                <div style={{ overflowX: "scroll", maxHeight: "calc(100vh - 150px)", overflowY: "auto", position: "relative" }}>
                    <Table
                        // style={{ width: "max-content", position: "relative" }}
                        // @ts-ignore
                        columns={columns}
                        dataSource={
                            rcDataCenterSettings?.sort(each => {
                                return each.serverId === "default" ? -1 : 1;
                            }) || []
                        }
                        rowKey={(record: any) => record.serverId + record.dcName}
                        loading={isLoading}
                        pagination={false}
                        sticky
                        size="small"
                    />
                </div>
            )}
            {currEdit && (
                <SettingsModal
                    open={currEdit !== null}
                    handleCloseModal={() => {
                        handleCloseEditModal();
                    }}
                    currEdit={currEdit}
                    data={currEditInfo}
                />
            )}
            {currDelete && (
                <DeleteModal
                    open={currDelete !== null}
                    handleCloseModal={() => {
                        setCurrDelete(null);
                    }}
                    serverDetails={currDelete}
                />
            )}
        </div>
    );
};

const formInputData = [
    {
        label: "CPU Max Rate",
        name: "cpuRateMax",
        rules: [{ required: true, message: "Please fill in CPU Max Rate, default is 80" }],
    },
    {
        label: "CPU Max Tolerance Count",
        name: "cpuMaxToleranceCount",
        rules: [{ required: true, message: "Please fill in CPU Max Tolerance Count, default is 5" }],
    },
    {
        label: "Min. Free Memory (MB)",
        name: "freeMemMin",
        rules: [{ required: true, message: "Please fill in Min. Free Memory, default is 500" }],
    },
    {
        label: "Free Memory Min. Tolerance Count",
        name: "freeMemMinToleranceCount",
        rules: [{ required: true, message: "Please fill in Free Memory Min. Tolerance Count, default is 5" }],
    },
    {
        label: "Min. Free Disk (GB)",
        name: "freeDiskMin",
        rules: [{ required: true, message: "Please fill in Min. Free Disk, default is 20" }],
    },
    {
        label: "Free Disk Min. Tolerance Count",
        name: "freeDiskMinToleranceCount",
        rules: [{ required: true, message: "Please fill in Free Disk Min. Tolerance Count, default is 5" }],
    },
    {
        label: "Max NIC",
        name: "nicMax",
        rules: [{ required: true, message: "Please fill in Max NIC, default is 50" }],
    },
    {
        label: "NIC Max Tolerance Count",
        name: "nicMaxToleranceCount",
        rules: [{ required: true, message: "Please fill in NIC Max Tolerance Count, default is 5" }],
    },
    {
        label: "Max Expired Next Update Time",
        name: "maxExpiredNextUpdateTime",
        rules: [{ required: true, message: "Please fill in Max Expired Next Update Time, default is 5" }],
    },
    {
        label: "System Task Interval Minutes",
        name: "systemTaskIntervalMinutes",
        rules: [{ required: true, message: "Please fill in System Task Interval Minutes, default is 1" }],
    },
    {
        label: "Login Task Interval Minutes",
        name: "loginTaskIntervalMinutes",
        rules: [{ required: true, message: "Please fill in Login Task Interval Minutes, default is 5" }],
    },
];

const FormInput = ({
    label,
    name,
    rules,
    type = "number",
    onChange,
    min = 0,
    max = 10000,
    value,
}: {
    label: string;
    name: string;
    rules: any[];
    type?: "number" | "text";
    onChange: any;
    value?: any;
    min?: number;
    max?: number;
}) => {
    const { Item } = Form;
    const { Text } = Typography;
    return (
        <Col span={12} xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }}>
            <Text>{label}</Text>
            <Item style={{ margin: 0, padding: 0 }} name={name} rules={rules}>
                {type === "number" ? (
                    <InputNumber
                        min={min}
                        max={max}
                        style={{ width: "100%" }}
                        onChange={data => {
                            onChange(data);
                        }}
                    />
                ) : (
                    <Input
                        style={{ width: "100%" }}
                        onChange={e => {
                            onChange(e.target.value);
                        }}
                    />
                )}
            </Item>
        </Col>
    );
};

const SettingsModal = ({
    open,
    handleCloseModal,
    data,
    currEdit,
}: {
    open: boolean;
    currEdit:
        | {
              serverId: string;
              dcName: string;
          }
        | "__new__"
        | null;
    handleCloseModal: () => void;
    data?: {
        serverId?: string;
        dcName?: string;
        cpuRateMax?: number;
        cpuMaxToleranceCount?: number;
        freeMemMin?: number;
        freeMemMinToleranceCount?: number;
        freeDiskMin?: number;
        freeDiskMinToleranceCount?: number;
        nicMax?: number;
        nicMaxToleranceCount?: number;
        maxExpiredNextUpdateTime?: number;
        systemTaskIntervalMinutes?: number;
        loginTaskIntervalMinutes?: number;
    };
}) => {
    // const { RangePicker } = TimePicker;
    const { rcDataCenterServers, isLoading } = useRCDataCenterServers();
    const { rcDataCenterSettings } = useRCDataCenterSettings();
    const [currServerID, setCurrServerID] = useState<string | null>(null);
    const [currDCName, setCurrDCName] = useState<string | null>(null);

    const [form] = Form.useForm();
    const { Item } = Form;
    const { Text } = Typography;

    const serverOptions = rcDataCenterServers?.reduce(
        (acc, curr) => {
            // only record unique serverId
            if (!acc.find((each: any) => each.value === curr.serverId)) {
                // @ts-ignore
                acc.push({ value: curr.serverId, label: curr.serverId });
            }
            return acc;
        },
        // [{ value: "default", label: "default" }]
        []
    );
    const dcOptions = rcDataCenterServers
        ?.reduce(
            (acc, curr) => {
                // only record unique serverId
                if (!acc.find((each: any) => each.value === curr.dcName)) {
                    // @ts-ignore
                    acc.push({ value: curr.dcName, label: curr.dcName, parentServer: curr.serverId });
                }
                return acc;
            },
            // [{ value: "default", label: "default" }]
            []
        )
        .filter((each: any) => each.parentServer === currServerID);

    const initFormValue = {
        serverId: data?.serverId || null,
        dcName: data?.dcName || null,
        cpuRateMax: data?.cpuRateMax || 80,
        cpuMaxToleranceCount: data?.cpuMaxToleranceCount || 5,
        freeMemMin: data?.freeMemMin || 500,
        freeMemMinToleranceCount: data?.freeMemMinToleranceCount || 5,
        freeDiskMin: data?.freeDiskMin || 20,
        freeDiskMinToleranceCount: data?.freeDiskMinToleranceCount || 3,
        nicMax: data?.nicMax || 50,
        nicMaxToleranceCount: data?.nicMaxToleranceCount || 5,
        maxExpiredNextUpdateTime: data?.maxExpiredNextUpdateTime || 5,
        systemTaskIntervalMinutes: data?.systemTaskIntervalMinutes || 1,
        loginTaskIntervalMinutes: data?.loginTaskIntervalMinutes || 5,
    };

    const existFromCurrentSettings =
        currEdit === "__new__" && rcDataCenterSettings?.find(each => each.serverId === currServerID && each.dcName === currDCName);

    const formRef = useRef<any>(null);

    const { updateDCSettings } = useRCDataCenterMutate();

    const handleSubmit = async (values: any) => {
        try {
            const resp = await updateDCSettings([
                {
                    serverId: values.serverId,
                    dcName: values.dcName,
                    cpuRateMax: values.cpuRateMax,
                    cpuMaxToleranceCount: values.cpuMaxToleranceCount,
                    freeMemMin: values.freeMemMin,
                    freeMemMinToleranceCount: values.freeMemMinToleranceCount,
                    freeDiskMin: values.freeDiskMin,
                    freeDiskMinToleranceCount: values.freeDiskMinToleranceCount,
                    nicMax: values.nicMax,
                    nicMaxToleranceCount: values.nicMaxToleranceCount,
                    maxExpiredNextUpdateTime: values.maxExpiredNextUpdateTime,
                    systemTaskIntervalMinutes: values.systemTaskIntervalMinutes,
                    loginTaskIntervalMinutes: values.loginTaskIntervalMinutes,
                },
            ]);
            // console.log(resp);
            if (resp.status === 200) {
                notification.success({
                    message: `Success`,
                    description: `Successfully updated Data Center settings`,
                });
                handleCloseModal();
            } else {
                notification.error({
                    message: `Error`,
                    description: `Failed to update Data Center settings, Error: ${resp.message || "Unknown"}`,
                });
            }
        } catch (e) {
            console.error(e);
            notification.error({
                message: `Error`,
                description: `Failed to update Data Center settings`,
            });
        }
    };

    return (
        <Form
            ref={formRef}
            form={form}
            layout="vertical"
            initialValues={initFormValue}
            requiredMark={true}
            onFinish={handleSubmit}
            key={
                typeof currEdit === "object" && currEdit?.serverId && currEdit?.dcName
                    ? currEdit?.serverId + currEdit?.dcName
                    : typeof currEdit === "string"
                    ? currEdit
                    : ""
            }
        >
            <Modal
                title={
                    (currEdit === "__new__" ? "Add " : `Edit `) +
                    `Data Center Settings` +
                    (currEdit === "__new__" ? "" : ` : ${currEdit?.serverId} - ${currEdit?.dcName}`)
                }
                okText={currEdit !== "__new__" ? "Update" : existFromCurrentSettings ? "Overwrite" : "Add"}
                okButtonProps={{
                    danger: existFromCurrentSettings && currEdit === "__new__",
                    // icon: <DownloadOutlined />,
                }}
                open={open}
                onOk={() => form.submit()}
                onCancel={() => handleCloseModal()}
                // disable click outside of modal to close
                maskClosable={false}
                width={1000}
            >
                {isLoading && !rcDataCenterServers ? (
                    <Loader />
                ) : (
                    <Row gutter={[8, 8]}>
                        <Col span={12} xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 12 }}>
                            <Text>Server *</Text>
                            <Item style={{ margin: 0, padding: 0 }} name="serverId" rules={[{ required: true, message: "Please select server" }]}>
                                <Select
                                    allowClear
                                    style={{ width: "100%" }}
                                    placeholder="Select Servers"
                                    options={serverOptions}
                                    showSearch
                                    onChange={data => {
                                        form.setFieldsValue({ serverId: data });
                                        if (data) {
                                            setCurrServerID(data);
                                        } else {
                                            setCurrServerID(null);
                                        }
                                        form.setFieldsValue({ dcName: null });
                                        setCurrDCName(null);
                                    }}
                                    disabled={currEdit !== "__new__"}
                                />
                            </Item>
                        </Col>
                        <Col span={12} xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 12 }}>
                            <Text>Data Center *</Text>
                            <Item style={{ margin: 0, padding: 0 }} name="dcName" rules={[{ required: true, message: "Please select Data Center" }]}>
                                <Select
                                    allowClear
                                    style={{ width: "100%" }}
                                    placeholder="Select Data Center"
                                    options={dcOptions}
                                    showSearch
                                    onChange={data => {
                                        form.setFieldsValue({ dcName: data });
                                        setCurrDCName(data);
                                    }}
                                    disabled={currEdit !== "__new__" || !currServerID}
                                />
                            </Item>
                        </Col>
                        {existFromCurrentSettings && (
                            <Col span={24}>
                                <MessageCard type="warning">
                                    <Text strong>
                                        Note: The server and data center combination already exists, updating the settings will overwrite the existing
                                        settings.
                                    </Text>
                                </MessageCard>
                            </Col>
                        )}
                        {formInputData.map((each, idx) => {
                            return (
                                <FormInput
                                    key={idx}
                                    label={each.label}
                                    name={each.name}
                                    rules={each.rules}
                                    type="number"
                                    onChange={(value: any) => {
                                        form.setFieldsValue({ [each.name]: value });
                                    }}
                                    value={form.getFieldValue(each.name)}
                                />
                            );
                        })}
                    </Row>
                )}
            </Modal>
        </Form>
    );
};

const DeleteModal = ({
    open,
    handleCloseModal,
    serverDetails,
}: {
    open: boolean;
    handleCloseModal: () => void;
    serverDetails: { serverId: string; dcName: string };
}) => {
    const { deleteDCSettings } = useRCDataCenterMutate();
    const handleDelete = async () => {
        try {
            const resp = await deleteDCSettings([{ serverId: serverDetails.serverId, dcName: serverDetails.dcName }]);
            console.log(resp);
            notification.success({
                message: `Success`,
                description: `Successfully deleted Data Center settings`,
            });
            handleCloseModal();
        } catch (e) {
            console.error(e);
            notification.error({
                message: `Error`,
                description: `Failed to delete Data Center settings`,
            });
        }
    };
    return (
        <Modal
            title="Delete Data Center Settings"
            okText={"Delete"}
            okButtonProps={{
                danger: true,
            }}
            open={open}
            onOk={handleDelete}
            onCancel={handleCloseModal}
        >
            <p>
                Are you sure you want to delete Data Center settings of{" "}
                <strong>
                    {serverDetails.serverId} - {serverDetails.dcName}
                </strong>
                ?
            </p>
        </Modal>
    );
};

const DataCenterSettingCards = ({ data, actions, index }: { data: IRCDataCenterSettings; actions: any; index: number }) => {
    const addHoveredSettings = useRCDataCenterStore(state => state.addHoveredSettings);
    const removeHoveredSettings = useRCDataCenterStore(state => state.removeHoveredSettings);
    const hoveredSettings = useRCDataCenterStore(state => state.hoveredSettings);
    const {
        serverId,
        dcName,
        cpuRateMax,
        cpuMaxToleranceCount,
        freeMemMin,
        freeMemMinToleranceCount,
        freeDiskMin,
        freeDiskMinToleranceCount,
        nicMax,
        nicMaxToleranceCount,
        maxExpiredNextUpdateTime,
        systemTaskIntervalMinutes,
        loginTaskIntervalMinutes,
    } = data;
    const { Title, Text } = Typography;

    const variantHovered = {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
    };
    const variantDefault = {
        backgroundColor: "rgba(0,0,0,0)",
    };

    return (
        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }}>
            <motion.div initial={{ opacity: 0, y: 10 }} animate={{ opacity: 1, y: 0, transition: { delay: index * 0.08 } }}>
                <Card title={`${serverId} - ${dcName}`} actions={actions}>
                    {formInputData.map((each, idx) => {
                        if (!each.name) return null;

                        // @ts-ignore
                        const value = data?.[each.name];

                        return (
                            <motion.div
                                key={idx}
                                style={{ display: "flex", gap: 8 }}
                                animate={hoveredSettings.includes(each.name) ? variantHovered : variantDefault}
                                onMouseEnter={() => {
                                    // @ts-ignore
                                    addHoveredSettings(each.name);
                                }}
                                onMouseLeave={() => {
                                    // @ts-ignore
                                    removeHoveredSettings(each.name);
                                }}
                            >
                                <Text style={{ fontSize: "0.75rem", minWidth: 30, textAlign: "right" }}>{value}</Text>
                                <Text style={{ fontSize: "0.75rem" }} strong>
                                    {each.label}
                                </Text>
                            </motion.div>
                        );
                    })}
                </Card>
            </motion.div>
        </Col>
    );
};

export default DataCenterSettingsTable;
