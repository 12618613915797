import React from "react";
import useRCPriceBackendOpt from "../../../../hooks/useRCPriceBackendOpt";

import RestartServers from "../RestartServers";
import { Typography } from "antd";
const PriceCalculateTools = () => {
    const { rcPriceBackendOpt } = useRCPriceBackendOpt({ type: "Price Calculate" });

    const { Title } = Typography;
    return (
        <div>
            <Title level={5}>Price Calculate Tools</Title>
            <RestartServers data={rcPriceBackendOpt} canBatchRestart={true} />
        </div>
    );
};

export default PriceCalculateTools;
