import React, { useState } from "react";
import FloatingTitleBox from "../../components/FloatingTitleBox";
import { Tabs } from "antd";
import useRCIssuesLogStore from "../../../../store/useRCIssuesLogStore";
import useRCBalanceAdjustment from "../../../../hooks/useRCBalanceAdjustment";
import BalanceAdjustmentTable from "./BalanceAdjustmentTable";
import DownloadBalanceAdjustment from "./DownloadBalanceAdjustment";
import BalanceAdjustmentSettings from "./BalanceAdjustmentSettings";

const IssueBalanceAdjustmentMonitor = () => {
    const updateInView = useRCIssuesLogStore(state => state.updateInView);
    const [currTab, setCurrTab] = useState<string>("unsolved");
    const handleChangeTab = (activeKey: string) => {
        setCurrTab(activeKey);
        // window.history.pushState({}, "", `${window.location.pathname}?tab=${activeKey}`);
    };

    const { rcBalanceAdjustment: dataUnsolved, refetchRcBalanceAdjustment: refetchUnsolved } = useRCBalanceAdjustment({ type: "unsolved" });
    const { rcBalanceAdjustment: dataSolved, refetchRcBalanceAdjustment: refetchSolved } = useRCBalanceAdjustment({ type: "solved" });
    const { rcBalanceAdjustment: dataPendingVerify, refetchRcBalanceAdjustment: refetchPendingVerify } = useRCBalanceAdjustment({
        type: "pendingVerify",
    });

    return (
        <FloatingTitleBox
            title="Balance Adjustment Monitor"
            // titleExtra={<Badge count={rcIssuesLog?.length + 5} />}
            inViewCallback={(isInView: boolean) => {
                updateInView({ BALANCE_ADJUSTMENT_MONITOR: isInView });
            }}
            titleBarTabs={
                <Tabs
                    activeKey={currTab}
                    onChange={handleChangeTab}
                    type="card"
                    style={{
                        padding: "0.5rem 0.5rem 0 0.5rem",
                    }}
                    tabBarStyle={{
                        marginBottom: 0,
                    }}
                    items={[
                        {
                            label: "Pending",
                            key: "unsolved",
                        },
                        {
                            label: "Pending Verify",
                            key: "pendingVerify",
                        },
                        {
                            label: "Solved",
                            key: "solved",
                        },
                        {
                            label: "Report",
                            key: "report",
                        },
                        {
                            label: "Settings",
                            key: "settings",
                        },
                    ]}
                />
            }
        >
            {currTab === "unsolved" && (
                // <div style={{ padding: "1rem" }}>
                <BalanceAdjustmentTable
                    data={/* dummyMonitor?.data || */ dataUnsolved?.data || []}
                    mutationCallback={() => {
                        refetchUnsolved();
                        refetchPendingVerify();
                    }}
                />
                // </div>
            )}
            {currTab === "pendingVerify" && (
                // <div style={{ padding: "1rem" }}>
                <BalanceAdjustmentTable
                    data={/* dummyVerify?.data || */ dataPendingVerify?.data || []}
                    commented
                    mutationCallback={() => {
                        refetchPendingVerify();
                        refetchSolved();
                    }}
                />
                // </div>
            )}
            {currTab === "solved" && (
                // <div style={{ padding: "1rem" }}>
                <BalanceAdjustmentTable data={/* dummySolved?.data || */ dataSolved?.data || []} commented solved />
                // </div>
            )}
            {currTab === "report" && <DownloadBalanceAdjustment />}
            {currTab === "settings" && <BalanceAdjustmentSettings />}
        </FloatingTitleBox>
    );
};

export default IssueBalanceAdjustmentMonitor;
