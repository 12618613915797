import { AlertOutlined } from "@ant-design/icons";
import { useEffect, useMemo, useState } from "react";
import { APIs, apiRequest } from "../../../../services/apiConfig";
import { ErrorCatchValidator, ErrorMessageHandler } from "../../../../utils/Common";
import { INTRADAY_BREACH_SEVERITY, SUCCESS_FAILED } from "../../../../constants";
import EmptyData from "../../../../components/Common/Empty";
import LoadingComponent from "../../../../components/Loading";
import { KeyValuePair } from "../../../../constants/type";
import { SortList, ToObjectWithKey } from "../../../../utils/array";
import { DefaultIfEmpty } from "../../../../utils/object";

interface AlarmSummaryProps {}

interface ResponseAlarmProps {
    eventStatsByAlarm: ResponseAlarmByAlarmProps[];
    eventStatsByBrand: ResponseAlarmByBrandProps[];
}

interface ResponseAlarmByAlarmProps {
    alarmEventCount: number;
    alarmEventCountBySeverity: ResponseAlarmSeverityProps[];
    alarmId: number;
    alarmName: string;
}

interface ResponseAlarmByBrandProps {
    alarmEventCount: number;
    alarmEventCountBySeverity: ResponseAlarmSeverityProps[];
    brandId: number;
}

interface ResponseAlarmSeverityProps {
    alarmEventCount: number;
    breachSeverity: number;
}

interface AlarmByBrandCountProps {
    brandId: number;
    brandName: string;
    severityCount: ResponseAlarmSeverityProps[];
}

const AlarmSummary = (props: AlarmSummaryProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<ResponseAlarmProps>({ eventStatsByAlarm: [], eventStatsByBrand: [] });
    const [brand, setBrand] = useState<KeyValuePair[]>([]);

    const brandObj = useMemo(() => {
        if (brand.length > 0) {
            return ToObjectWithKey(brand, "value", "text");
        }
        return {};
    }, [brand]);

    const alarmInfo = useMemo(() => {
        let info = { list: [] as AlarmByBrandCountProps[], total: [] as ResponseAlarmSeverityProps[] };
        if (data.eventStatsByBrand.length > 0) {
            info.list = data.eventStatsByBrand.map(
                x =>
                    ({
                        brandId: x.brandId,
                        brandName: DefaultIfEmpty(brandObj, x.brandId, ""),
                        severityCount: SortList(x.alarmEventCountBySeverity, "breachSeverity", undefined, "DESC"),
                    } as AlarmByBrandCountProps)
            );
        }
        if (data.eventStatsByAlarm.length > 0) {
            let totalObj = data.eventStatsByAlarm.reduce(
                (final: any, x: ResponseAlarmByAlarmProps) => {
                    if (x.alarmEventCountBySeverity.length > 0) {
                        x.alarmEventCountBySeverity.forEach(y => {
                            final[y.breachSeverity] += y.alarmEventCount;
                        });
                    }
                    return final;
                },
                Object.keys(INTRADAY_BREACH_SEVERITY).reduce((finalObj: any, x: string) => {
                    if (x === "0") return finalObj;

                    finalObj[parseInt(x)] = 0;
                    return finalObj;
                }, {})
                //{ 1: 0, 2: 0, 3: 0, 4: 0 }
            );
            info.total = SortList(
                Object.keys(totalObj).map(
                    x => ({ breachSeverity: parseInt(x), alarmEventCount: totalObj[parseInt(x)] } as ResponseAlarmSeverityProps)
                ),
                "breachSeverity",
                undefined,
                "DESC"
            );
        }
        return info;
    }, [data, brandObj]);

    const getAlarmSummary = () => {
        setIsLoading(true);
        apiRequest(APIs.GET_ALARM_SUMMARY_LIST, { isIntradayOnly: true })
            .then((res: ResponseAlarmProps) => {
                setData(res);
            })
            .catch(error => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("alarm list", SUCCESS_FAILED.FAILED_LOAD_DATA, err)))
            .finally(() => setIsLoading(false));
    };

    const getConfig = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["brand"] })
            .then(res => {
                if (res.brands && res.brands.length > 0) {
                    setBrand(res.brands.map((x: any) => ({ text: x.brand, value: x.id })));
                }
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => console.log("error during get brands list", err)))
            .finally(() => getAlarmSummary());
    };

    useEffect(() => {
        getConfig();
    }, []);

    return (
        <div className="alarm-summary-container shadow-light">
            <div className="title">
                <div className="left">
                    <AlertOutlined style={{ color: "#f00f00" }} />
                    <span className="title-text">Alarm Summary</span>
                </div>
                <div className="right">
                    {alarmInfo.total.length > 0 && (
                        <div className="total-container">
                            <span className="total-text">Total</span>
                            {alarmInfo.total.map((x: any, idx: number) => (
                                <div key={`intd-sm-al-t-${idx}`}>
                                    <span className={`breachSeverity-text priority-color-${x.breachSeverity}-text`}>
                                        {INTRADAY_BREACH_SEVERITY[x.breachSeverity]} :
                                    </span>
                                    <span className="">{x.alarmEventCount}</span>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
            <div className="alarm-summary-list nice-scrollbar">
                {isLoading ? (
                    <LoadingComponent />
                ) : alarmInfo.list.length > 0 ? (
                    alarmInfo.list.map((x: AlarmByBrandCountProps, xIdx: number) => (
                        <div key={`asl-${x.brandId}-${xIdx}`} className="item">
                            <div className="left">{x.brandName}</div>
                            <div className="right">
                                {x.severityCount.map((y: ResponseAlarmSeverityProps, yIdx: number) => (
                                    <div
                                        key={`asl-${x.brandId}-${xIdx}-${yIdx}`}
                                        className={`count-container priority-color-${y.breachSeverity}`}
                                        onClick={() => {
                                            const newTab = window.open(`/intradaymonitor/alarm/${y.breachSeverity}/${x.brandId}`, "_blank");
                                            newTab?.focus();
                                        }}
                                    >
                                        <span>{y.alarmEventCount}</span>
                                        <span>{INTRADAY_BREACH_SEVERITY[y.breachSeverity]}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))
                ) : (
                    <EmptyData />
                )}
            </div>
        </div>
    );
};

export default AlarmSummary;
