import { Col, Form, Input, Row, Tag, Typography, Tooltip, Button, notification, Space, Modal } from "antd";
import TabContent from "../../SystemMonitor/components/TabContent";
import useRCMidBiasSettings, { useMidBiasSettingsMutation } from "../../../hooks/useRCMidBiasSettings";

import React, { useEffect, useRef, useState } from "react";
import { CloseOutlined, DownloadOutlined, EditOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import type { InputRef } from "antd";
import { colorMap } from "../../SystemMonitor/StatusMonitor/statusUtils";
import { matchSorter } from "match-sorter";
import MessageCard from "../../SystemMonitor/components/MessageCard";
import { plainAxiosInstance } from "../../../services/axiosSetup";
import { APIs } from "../../../services/apis";
import moment from "moment";

const TabSettings = () => {
    const { rcMidBiasSettings, isLoading } = useRCMidBiasSettings();

    const [form] = Form.useForm();
    const { Text, Title } = Typography;
    const { Item } = Form;
    const [hasChanges, setHasChanges] = useState(false);

    const initFormValue = {
        range: null,
        default_clean_symbol: rcMidBiasSettings?.find(setting => setting.type === "default_clean_symbol")?.value.split(",") || [],
        future_symbol: rcMidBiasSettings?.find(setting => setting.type === "future_symbol")?.value.split(",") || [],
        spread_ratio_threshold: rcMidBiasSettings?.find(setting => setting.type === "spread_ratio_threshold")?.value || "",
        future_spread_ratio_threshold: rcMidBiasSettings?.find(setting => setting.type === "future_spread_ratio_threshold")?.value || "",
        default_score_max: rcMidBiasSettings?.find(setting => setting.type === "default_score_max")?.value || "",
        default_score_sum: rcMidBiasSettings?.find(setting => setting.type === "default_score_sum")?.value || "",
        future_score_max: rcMidBiasSettings?.find(setting => setting.type === "future_score_max")?.value || "",
        wave_ratio: rcMidBiasSettings?.find(setting => setting.type === "wave_ratio")?.value || "",
    };

    // console.log(rcMidBiasSettings);
    const {
        updateMidBiasSettings,
        // updateMidBiasSettingsLoading,
        // updateMidBiasSettingsError,
        uploadTimeThreshold,
        // isLoadingUploadTimeThreshold,
        // uploadTimeThresholdError,
    } = useMidBiasSettingsMutation();

    const handleSubmit = async (values: any) => {
        // put all values into array
        const final = [
            // {
            //     type: "default_clean_symbol",
            //     value: values.default_clean_symbol.join(","),
            // },
            {
                type: "future_symbol",
                value: values.future_symbol.join(","),
            },
            {
                type: "spread_ratio_threshold",
                value: values.spread_ratio_threshold,
            },
            {
                type: "future_spread_ratio_threshold",
                value: values.future_spread_ratio_threshold,
            },
            {
                type: "default_score_max",
                value: values.default_score_max,
            },
            {
                type: "default_score_sum",
                value: values.default_score_sum,
            },
            {
                type: "future_score_max",
                value: values.future_score_max,
            },
            {
                type: "wave_ratio",
                value: values.wave_ratio,
            },
        ];

        // console.log("final submitting values", final);

        try {
            const resp = await updateMidBiasSettings(final);
            if (resp) {
                notification.success({
                    message: "Success",
                    description: "Settings updated successfully",
                });
                setHasChanges(false);
            }
        } catch (error) {
            notification.error({
                message: "Error",
                // @ts-ignore
                description: `Failed to update settings: ${error?.response?.data?.message || error?.message || error || ""}`,
            });
        }
    };

    const handleDownloadTimeThreshold = () => {
        // download time threshold
        plainAxiosInstance
            .post(
                APIs.RC_MIDBIAS.POST_DOWNLOAD_TIMETHRESHOLD,
                {},
                {
                    headers: {
                        Accept: "application/octet-stream, */*",
                    },
                    responseType: "blob",
                }
            )
            .then(response => {
                // Check if the response content type is 'application/octet-stream'
                const contentType = response.headers["content-type"];
                if (
                    contentType === "application/octet-stream" ||
                    contentType === "text/csv" ||
                    contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ) {
                    // Handle the file download response
                    const getCurrentDate = moment().format("YYYY-MM-DD_HH-mm-ss");
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `MidBias_Time_Threshold_${getCurrentDate}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                    // Clean up
                    window.URL.revokeObjectURL(url);
                    notification.success({
                        message: "Downloaded",
                        description: `Time Threshold downloaded successfully`,
                    });
                } else {
                    notification.error({
                        message: "Error",
                        description: `Received non-file response. Error: ${response}`,
                    });
                    console.log("Received non-file response:", response);
                }
            })
            .catch(err => {
                notification.error({
                    message: "Error",
                    description: `Download error: ${err}`,
                });
                console.log("download error", err);
            });
    };

    // for upload

    const [openUploadModal, setOpenUploadModal] = useState(false);
    const [uploadFileBlob, setUploadFileBlob] = useState<File | null>(null);
    const [formUpload] = Form.useForm();
    const initFormUploadValue = {
        file: null,
    };

    const handleUpload = async ({ formData }: { formData: FormData }) => {
        try {
            const resp = await uploadTimeThreshold({ formData });

            if (resp.status === 200 && resp?.data !== 0) {
                notification.error({
                    message: "Failed to upload settings",
                    description: "Error: " + resp?.data,
                });
            } else {
                notification.success({
                    message: "Settings uploaded",
                    description: "Settings uploaded successfully",
                });
                formUpload.resetFields();
                setOpenUploadModal(false);
            }
            // console.log(resp);
        } catch (err) {
            notification.error({
                message: "Failed to upload settings",
                description: `Error: ${err}`,
            });
            console.log("error while uploading settings", err);
        }
    };

    return (
        <>
            <TabContent style={{ paddingBottom: 0 }}>
                <div style={{ padding: "1rem", border: "1px solid", borderColor: hasChanges ? colorMap.warning : "transparent" }}>
                    {isLoading ? (
                        <div>Loading...</div>
                    ) : (
                        <Space wrap direction="vertical" style={{ width: "100%" }}>
                            <Form
                                form={form}
                                layout="vertical"
                                initialValues={initFormValue}
                                /* onValuesChange={handleFormUpdate} */ requiredMark={true}
                                onFinish={handleSubmit}
                            >
                                <Space style={{ width: "100%" }} direction="vertical" wrap size={24}>
                                    {hasChanges && <MessageCard type="warning">You have unsaved changes</MessageCard>}
                                    {/* <div>
                                        <Title level={4}>Calculate Clean Symbol</Title>
                                        <Item
                                            style={{ margin: 0, padding: 0 }}
                                            labelCol={{ span: 24 }}
                                            wrapperCol={{ span: 24 }}
                                            required
                                            name="default_clean_symbol"
                                        >
                                            <ChipsInput
                                                value={form.getFieldValue("default_clean_symbol")}
                                                onChange={(value: string[]) => {
                                                    form.setFieldsValue({ default_clean_symbol: value });
                                                    setHasChanges(true);
                                                }}
                                            />
                                        </Item>
                                    </div> */}
                                    <div>
                                        <div style={{ marginBottom: "2.1vh", border: "1px solid #ffb7b2", padding: "1.1312vh 0.651vw" }}>
                                            <span
                                                style={{ fontSize: "1rem", fontWeight: "bold", color: "#e24242" }}
                                            >{`**Please remember to restart the 【 Price Calculate Tool 】 by going to "Price Monitor -> Price Setting -> Price Backend Opt" after making any changes to the settings. Thank you!`}</span>
                                        </div>
                                        <Title level={4}>Future Clean Symbol</Title>
                                        <Item
                                            style={{ margin: 0, padding: 0 }}
                                            labelCol={{ span: 24 }}
                                            wrapperCol={{ span: 24 }}
                                            required
                                            name="future_symbol"
                                            // rules={[{ required: true, message: "Please select date range" }]}
                                        >
                                            {/* allow user to add multiple chips, and remove chips */}
                                            <ChipsInput
                                                value={form.getFieldValue("future_symbol")}
                                                onChange={(value: string[]) => {
                                                    form.setFieldsValue({ future_symbol: value });
                                                    setHasChanges(true);
                                                }}
                                            />
                                            {/* allow user to add multiple tags, and remove tags */}
                                        </Item>
                                    </div>
                                    <div>
                                        <Title level={4}>MidBias Param Setting</Title>
                                        <div style={{ display: "flex", gap: 8, flexDirection: "column" }}>
                                            <Row gutter={[8, 8]}>
                                                <Col
                                                    xs={{ span: 24 }}
                                                    sm={{ span: 12 }}
                                                    md={{ span: 8 }}
                                                    style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }}
                                                >
                                                    <Text>Spread Ratio Threshold</Text>
                                                    <Item
                                                        style={{ margin: 0, padding: 0 }}
                                                        labelCol={{ span: 24 }}
                                                        wrapperCol={{ span: 24 }}
                                                        required
                                                        name="spread_ratio_threshold"
                                                        rules={[{ required: true, message: "This field is required" }]}
                                                    >
                                                        <Input
                                                            value={form.getFieldValue("spread_ratio_threshold")}
                                                            onChange={e => {
                                                                form.setFieldsValue({ spread_ratio_threshold: e.target.value });
                                                                setHasChanges(true);
                                                            }}
                                                        />
                                                    </Item>
                                                </Col>
                                                <Col
                                                    xs={{ span: 24 }}
                                                    sm={{ span: 12 }}
                                                    md={{ span: 8 }}
                                                    style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }}
                                                >
                                                    <Text>Future Spread Ratio Threshold</Text>
                                                    <Item
                                                        style={{ margin: 0, padding: 0 }}
                                                        labelCol={{ span: 24 }}
                                                        wrapperCol={{ span: 24 }}
                                                        required
                                                        name="future_spread_ratio_threshold"
                                                        rules={[{ required: true, message: "This field is required" }]}
                                                    >
                                                        <Input
                                                            value={form.getFieldValue("future_spread_ratio_threshold")}
                                                            onChange={e => {
                                                                form.setFieldsValue({ future_spread_ratio_threshold: e.target.value });
                                                                setHasChanges(true);
                                                            }}
                                                        />
                                                    </Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={[8, 8]}>
                                                <Col
                                                    xs={{ span: 24 }}
                                                    sm={{ span: 12 }}
                                                    md={{ span: 8 }}
                                                    style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }}
                                                >
                                                    <Text>Score Max</Text>
                                                    <Item
                                                        style={{ margin: 0, padding: 0 }}
                                                        labelCol={{ span: 24 }}
                                                        wrapperCol={{ span: 24 }}
                                                        required
                                                        name="default_score_max"
                                                        rules={[{ required: true, message: "This field is required" }]}
                                                    >
                                                        <Input
                                                            value={form.getFieldValue("default_score_max")}
                                                            onChange={e => {
                                                                form.setFieldsValue({ default_score_max: e.target.value });
                                                                setHasChanges(true);
                                                            }}
                                                        />
                                                    </Item>
                                                </Col>
                                                <Col
                                                    xs={{ span: 24 }}
                                                    sm={{ span: 12 }}
                                                    md={{ span: 8 }}
                                                    style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }}
                                                >
                                                    <Text>Score Sum</Text>
                                                    <Item
                                                        style={{ margin: 0, padding: 0 }}
                                                        labelCol={{ span: 24 }}
                                                        wrapperCol={{ span: 24 }}
                                                        required
                                                        name="default_score_sum"
                                                        rules={[{ required: true, message: "This field is required" }]}
                                                    >
                                                        <Input
                                                            value={form.getFieldValue("default_score_sum")}
                                                            onChange={e => {
                                                                form.setFieldsValue({ default_score_sum: e.target.value });
                                                                setHasChanges(true);
                                                            }}
                                                        />
                                                    </Item>
                                                </Col>
                                                <Col
                                                    xs={{ span: 24 }}
                                                    sm={{ span: 12 }}
                                                    md={{ span: 8 }}
                                                    style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }}
                                                >
                                                    <Text>Future Score Max</Text>
                                                    <Item
                                                        style={{ margin: 0, padding: 0 }}
                                                        labelCol={{ span: 24 }}
                                                        wrapperCol={{ span: 24 }}
                                                        required
                                                        name="future_score_max"
                                                        rules={[{ required: true, message: "This field is required" }]}
                                                    >
                                                        <Input
                                                            value={form.getFieldValue("future_score_max")}
                                                            onChange={e => {
                                                                form.setFieldsValue({ future_score_max: e.target.value });
                                                                setHasChanges(true);
                                                            }}
                                                        />
                                                    </Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={[8, 8]}>
                                                <Col
                                                    xs={{ span: 24 }}
                                                    sm={{ span: 12 }}
                                                    md={{ span: 8 }}
                                                    style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }}
                                                >
                                                    <Text>Wave Ratio</Text>
                                                    <Item
                                                        style={{ margin: 0, padding: 0 }}
                                                        labelCol={{ span: 24 }}
                                                        wrapperCol={{ span: 24 }}
                                                        required
                                                        name="wave_ratio"
                                                        rules={[{ required: true, message: "This field is required" }]}
                                                    >
                                                        <Input
                                                            value={form.getFieldValue("wave_ratio")}
                                                            onChange={e => {
                                                                form.setFieldsValue({ wave_ratio: e.target.value });
                                                                setHasChanges(true);
                                                            }}
                                                        />
                                                    </Item>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    {hasChanges && <MessageCard type="warning">You have unsaved changes</MessageCard>}
                                    <MessageCard type="info">Please restart the server after changing the settings</MessageCard>
                                    <Space>
                                        {/* reset */}
                                        <Button
                                            type="default"
                                            onClick={() => {
                                                form.resetFields();
                                                setHasChanges(false);
                                            }}
                                        >
                                            Reset
                                        </Button>
                                        <Button type="primary" htmlType="submit">
                                            Save
                                        </Button>
                                    </Space>
                                </Space>
                            </Form>
                        </Space>
                    )}
                </div>
            </TabContent>
            <TabContent style={{ paddingBottom: 0 }}>
                <div style={{ padding: "1rem" }}>
                    <Space direction="vertical">
                        <Title level={4}>Time Threshold</Title>
                        <Space>
                            {/* download and upload btn */}
                            <Button type="ghost" icon={<DownloadOutlined />} onClick={() => handleDownloadTimeThreshold()}>
                                Download
                            </Button>
                            <Button type="ghost" icon={<UploadOutlined />} onClick={() => setOpenUploadModal(true)}>
                                Upload
                            </Button>
                        </Space>
                    </Space>
                </div>
            </TabContent>
            <Form
                form={formUpload}
                layout="vertical"
                initialValues={initFormUploadValue}
                /* onValuesChange={handleFormUpdate} */ requiredMark={true}
                onFinish={() => {
                    // const formFile = formUpload.getFieldValue("file");
                    // // convert formFile to File blob
                    // const formFileBlob = new Blob([formFile], { type: "text/csv" });

                    const formData = new FormData();
                    formData.append("uploadFile", uploadFileBlob as Blob);
                    handleUpload({ formData });
                }}
            >
                <Modal
                    title={"Upload settings"}
                    open={openUploadModal}
                    onCancel={() => {
                        formUpload.resetFields();
                        setOpenUploadModal(false);
                        setUploadFileBlob(null);
                    }}
                    onOk={() => formUpload.submit()}
                    okText="Import"
                    okButtonProps={{ danger: true }}
                >
                    <Space style={{ width: "100%" }} direction="vertical" size={16}>
                        <div>
                            <Text>Excel File *</Text>
                            <Item
                                style={{ margin: 0, padding: 0 }}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                required
                                name="file"
                                rules={[{ required: true, message: "Excel File is required" }]}
                            >
                                <Input
                                    type="file"
                                    // accept both .xlsx and .XLSX
                                    accept=".xlsx, .XLSX"
                                    onChange={e => {
                                        if (e.target.files && e.target.files.length > 0) {
                                            formUpload.setFieldsValue({ file: e.target.value });
                                            setUploadFileBlob(e.target.files[0]);
                                        } else {
                                            formUpload.setFieldsValue({ file: null });
                                            setUploadFileBlob(null);
                                        }
                                    }}
                                />
                            </Item>
                        </div>
                        <MessageCard type="info">This will replace duplicated settings</MessageCard>
                    </Space>
                </Modal>
            </Form>
        </>
    );
};

// const TagsInput = ({value, onChange}: {value: string[], onChange: (value: string[]) => void}) => {

const ChipsInput = ({ value, onChange }: { value: string[]; onChange: (value: string[]) => void }) => {
    const [plainTextMode, setPlainTextMode] = useState(false);
    const [plainTextValue, setPlainTextValue] = useState(value?.join(",") || "");
    const [chips, setChips] = useState<string[]>(value || []);
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [editInputIndex, setEditInputIndex] = useState(-1);
    const [editInputValue, setEditInputValue] = useState("");
    const [originalEditValue, setOriginalEditValue] = useState(""); // New state for original edit value
    const inputRef = useRef<InputRef>(null);
    const editInputRef = useRef<InputRef>(null);
    const { Group } = Input;
    const [searchValue, setSearchValue] = useState("");

    const result = matchSorter(chips, searchValue);

    useEffect(() => {
        if (inputVisible) {
            inputRef.current?.focus();
        }
    }, [inputVisible]);

    useEffect(() => {
        if (editInputIndex !== -1) {
            editInputRef.current?.focus();
        }
    }, [editInputIndex]);

    const handleAdd = () => {
        // check if the input value is not empty, and not repeat from the list
        if (inputValue && !chips.includes(inputValue)) {
            const newChips = [...chips, inputValue];
            setChips(newChips);
            onChange(newChips);
            setPlainTextValue(newChips.join(","));
            setInputValue("");
            setInputVisible(false);
            notification.success({
                message: "Success",
                description: `Value added: ${inputValue}`,
            });
        } else {
            if (chips.includes(inputValue)) {
                notification.error({
                    message: "Error",
                    description: `${inputValue} already exists`,
                });
            } else {
                notification.error({
                    message: "Error",
                    description: "Value cannot be empty",
                });
            }
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter" && inputValue) {
            if (inputValue && !chips.includes(inputValue)) {
                const newChips = [...chips, inputValue];
                setChips(newChips);
                setPlainTextValue(newChips.join(",")); // Update the plain text value
                onChange(newChips);
                setInputValue("");
                setInputVisible(false);
                notification.success({
                    message: "Success",
                    description: `Value added: ${inputValue}`,
                });
            } else {
                if (chips.includes(inputValue)) {
                    notification.error({
                        message: "Error",
                        description: `${inputValue} already exists`,
                    });
                } else {
                    notification.error({
                        message: "Error",
                        description: "Value cannot be empty",
                    });
                }
            }
        }
    };

    const handleDelete = (chipToDelete: string) => {
        const newChips = [...chips].filter(chip => chip !== chipToDelete);
        // console.log("chips after delete", newChips);
        setChips(newChips);
        setPlainTextValue(newChips.join(",")); // Update the plain text value
        onChange(newChips);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEditInputValue(e.target.value);
    };

    const handleEditInputConfirm = () => {
        // Check if the new value is not empty and is not a duplicate (excluding the original value)
        if (originalEditValue === editInputValue) {
            // No changes made
            setEditInputIndex(-1);
            setEditInputValue("");
            setOriginalEditValue("");
            return;
        }
        if (editInputValue && (editInputValue === originalEditValue || !chips.includes(editInputValue))) {
            const newChips = [...chips, editInputValue].filter(chip => chip !== originalEditValue);
            // newChips[editInputIndex] = editInputValue;

            setChips(newChips);
            setPlainTextValue(newChips.join(",")); // Update the plain text value
            onChange(newChips);
            notification.success({
                message: "Success",
                description: `${originalEditValue} updated to ${editInputValue}`,
            });
        } else {
            if (!editInputValue) {
                notification.error({
                    message: "Error",
                    description: "Value cannot be empty",
                });
            } else if (editInputValue !== originalEditValue && chips.includes(editInputValue)) {
                notification.error({
                    message: "Error",
                    description: `${editInputValue} already exists`,
                });
            }
        }

        // Reset the edit input state
        setEditInputIndex(-1);
        setEditInputValue("");
        setOriginalEditValue(""); // Reset the original edit value
    };

    const showInput = () => {
        setInputVisible(true);
    };

    const tagPlusStyle: React.CSSProperties = {
        height: 22,
        background: colorMap.primary,
        color: "white",
        borderStyle: "dashed",
        cursor: "pointer",
    };

    const { Text } = Typography;
    const { TextArea } = Input;
    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            {/* swap mode */}
            <Tooltip title={plainTextMode ? "Switch to Tag Mode" : "Switch to Plain Text Mode"}>
                <Button
                    size="small"
                    type="text"
                    onClick={() => {
                        setPlainTextMode(!plainTextMode);
                    }}
                    style={{ float: "right" }}
                >
                    <Text style={{ color: colorMap.primary }}>{plainTextMode ? "Plain Text Mode" : "Tag Mode"}</Text>
                </Button>
            </Tooltip>
            {plainTextMode ? (
                <>
                    <TextArea
                        value={plainTextValue}
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        onChange={e => {
                            // remove all white space and split by comma
                            const newValue = e.target.value.replace(/\s/g, "").split(",");
                            setPlainTextValue(e.target.value);
                            setChips(newValue);
                            onChange(newValue);
                        }}
                    />
                </>
            ) : (
                <>
                    <Input
                        size="small"
                        style={{ width: "100%" }}
                        placeholder={`Search from ${chips.length} items`}
                        value={searchValue}
                        onChange={e => setSearchValue(e.target.value)}
                    />
                    <Text type="secondary" style={{ fontSize: "0.75rem" }}>
                        {chips.length} {chips.length > 1 ? "items" : "item"}
                        {
                            // check if any duplicated
                            chips.length !== new Set(chips).size ? (
                                <span style={{ color: colorMap.error, marginLeft: 8 }}>(Duplicated: {chips.length - new Set(chips).size})</span>
                            ) : null
                        }
                    </Text>
                    <Space wrap size={2}>
                        {result.map((chip, index) => {
                            if (editInputIndex === index) {
                                return (
                                    <Group key={chip + index} compact style={{ display: "inline-block", width: "auto" }}>
                                        <Input
                                            ref={editInputRef}
                                            key={chip}
                                            size="small"
                                            style={{ width: "auto" }}
                                            value={editInputValue}
                                            onChange={handleEditInputChange}
                                            onBlur={handleEditInputConfirm}
                                            onPressEnter={handleEditInputConfirm}
                                        />
                                        <Button
                                            type="primary"
                                            size="small"
                                            onClick={handleEditInputConfirm}
                                            style={{ width: "auto", padding: "0 0.5rem" }}
                                            // icon={<InfoOutlined />}
                                        >
                                            OK
                                        </Button>
                                    </Group>
                                );
                            }
                            return (
                                <Tag key={chip + index} closable onClose={() => handleDelete(chip)} style={{ userSelect: "none", margin: 0 }}>
                                    <span
                                        onClick={e => {
                                            setEditInputIndex(index);
                                            setEditInputValue(chip);
                                            setOriginalEditValue(chip); // Store the original edit value
                                            e.preventDefault();
                                        }}
                                        style={{ cursor: "pointer" }}
                                    >
                                        {chip}
                                        <EditOutlined style={{ marginLeft: 4 }} />
                                    </span>
                                </Tag>
                            );
                        })}
                        {inputVisible ? (
                            <Group compact style={{ display: "inline-block", width: "auto" }}>
                                <Input
                                    ref={inputRef}
                                    type="text"
                                    size="small"
                                    style={{ width: "auto", minWidth: 64 }}
                                    value={inputValue}
                                    onChange={handleInputChange}
                                    onPressEnter={handleKeyDown}
                                />
                                <Button
                                    type="primary"
                                    size="small"
                                    onClick={handleAdd}
                                    style={{ width: "auto", padding: "0 0.5rem" }}
                                    icon={<PlusOutlined />}
                                >
                                    Add
                                </Button>
                                <Button
                                    type="ghost"
                                    size="small"
                                    onClick={() => setInputVisible(false)}
                                    style={{ width: "auto", padding: "0 0.5rem" }}
                                    icon={<CloseOutlined style={{ fontSize: "0.75em" }} />}
                                />
                            </Group>
                        ) : (
                            <Tag style={tagPlusStyle} icon={<PlusOutlined />} onClick={showInput}>
                                New Tag
                            </Tag>
                        )}
                    </Space>
                </>
            )}
        </Space>
    );
};

export default TabSettings;
