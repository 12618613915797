import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import { Form, Modal } from "antd";
import { useEffect, useState } from "react";
import SummaryBar from "../../../../components/Common/SummaryBar";
import { FormComponent } from "../../../../components/FormComponent";
import { ComponentType, SUCCESS_FAILED } from "../../../../constants";
import { MetricGroup } from "../../../../constants/type";
import "../../../../index.less";
import { apiRequest, APIs } from "../../../../services/apiConfig";
import { ErrorCatchValidator, ErrorMessageHandler } from "../../../../utils/Common";
import { ProfileInfoProps } from "../viewProfile";
import EmptyData from "../../../../components/Common/Empty";
import CustomSkeleton from "../../../../components/Common/Skeleton";

interface AccountSummaryPanelProps {
    metricCategories: MetricGroup[];
    accountData: ProfileInfoProps;
    //loading: boolean;
    currency: string;
    forPrint?: boolean;
    dateRangeFilter?: any;
}

export interface MetricStats {
    metricId: number;
    metricName: string;
    value: number;
    categoryIds: number[];
    isView: boolean;
    orderSeq?: number;
    formatType: number;
    metricDesc: string;
}

const AccountSummaryPanel = (props: AccountSummaryPanelProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<MetricStats[]>([]);
    const [visible, setVisible] = useState<boolean>(false);
    const [searchKey, setSearchKey] = useState<string>("");
    const [metricfilterForm] = Form.useForm();

    const fetchData = () => {
        setIsLoading(true);
        apiRequest(APIs.GET_ACCOUNT_SUMMARY_PANEL, {
            accountId: props?.accountData?.accountId,
            brandId: props?.accountData?.brandId,
            serverId: props?.accountData?.serverId,
            metricType: 1,
            isMetricSummary: true,
            ...(props?.accountData?.dateRangeFilter && props?.accountData?.dateRangeFilter),
        })
            .then((data: any) => {
                data === null ? setData([]) : setData(data);
            })
            .catch((error) => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("summary", SUCCESS_FAILED.FAILED_LOAD_DATA, err)))
            .finally(() => setIsLoading(false));
    };

    const getFilteredMetric = (searchKey: string, metrics: MetricStats[]) => {
        let lowerSearchText = searchKey.toLowerCase();
        return searchKey.length > 0 ? metrics.filter((x) => x.metricName.toLowerCase().indexOf(lowerSearchText) > -1) : metrics;
    };

    const viewAllMetrics = (metrics: MetricStats[], groups: MetricGroup[], sKey: string) => {
        let filteredMetric = getFilteredMetric(sKey, metrics);
        let availableGroupList = filteredMetric.reduce((gList: number[], m: MetricStats) => {
            if (m.categoryIds && m.categoryIds.length > 0) {
                m.categoryIds.forEach((c) => {
                    if (!gList.includes(c)) {
                        gList.push(c);
                    }
                });
            }

            return gList;
        }, []);

        let groupWithNewMetric = [...groups, { id: 0, name: "New Metric", description: "New Metric", orderSeq: 0 } as MetricGroup];
        let finalGroup = groupWithNewMetric.filter((x) => availableGroupList.includes(x.id));
        finalGroup.sort((a: MetricGroup, b: MetricGroup) => a.orderSeq - b.orderSeq);

        return finalGroup.map((grp: MetricGroup) => (
            <div className="account-summary-group" key={`asmgpop-${grp.id}`}>
                <div className="group-title-container">
                    <span>{grp.name}</span>
                </div>
                <div className="metric-container">
                    {filteredMetric
                        .filter((y) => y.categoryIds.includes(grp.id))
                        .map((currData: any, index: number) => (
                            <SummaryBar
                                key={`ao-as-vall-${index}`}
                                iconType={currData.formatType}
                                customIcon={props.currency}
                                name={currData.metricName}
                                value={currData.value}
                                forPrint={props.forPrint}
                                desc={currData.metricDesc}
                            />
                        ))}
                </div>
            </div>
        ));
    };

    const isViewSummaryOnly = (metrics: MetricStats[]) => {
        let finalMetrics = metrics.filter((x) => x.isView === true);
        finalMetrics.sort((a: any, b: any) => a.orderSeq - b.orderSeq);
        return finalMetrics;
    };

    const onFilterValueChanged = (values: any) => setSearchKey(values["searchKey"]);

    useEffect(() => {
        if ((props.accountData.accountId as number) < 0) {
            setIsLoading(false);
        } else {
            fetchData();
        }
        return () => {};
    }, [props?.accountData]);

    return (
        <>
            <div className={`account-summary-panel mt-10 ${props.forPrint ? "for-print" : ""}`}>
                <div className="top">
                    <h1 className="title">Account Summary</h1>
                    {!props.forPrint && data.length > 0 && (
                        <EyeOutlined
                            style={{ fontSize: 20 }}
                            onClick={() => {
                                setSearchKey("");
                                metricfilterForm.setFieldsValue({ searchKey: "" });
                                setVisible(true);
                            }}
                        />
                    )}
                </div>
                {isLoading ? (
                    <div className={`metric-items-container ${props.forPrint ? "repeat-4" : ""}`}>
                        {Array.from({ length: 16 }, () => (
                            <CustomSkeleton key={`sk-${Math.random()}`} type="square" size="large" />
                        ))}
                    </div>
                ) : data.length > 0 ? (
                    <div className={`metric-items-container ${props.forPrint ? "repeat-4" : ""}`}>
                        {isViewSummaryOnly(data).map((currData: any, index: number) => (
                            <SummaryBar
                                key={`ao-as-${index}`}
                                iconType={currData.formatType}
                                customIcon={props.currency}
                                name={currData.metricName}
                                value={currData.value}
                                forPrint={props.forPrint}
                                desc={currData.metricDesc}
                            />
                        ))}
                    </div>
                ) : (
                    <EmptyData />
                )}
            </div>
            <Modal
                style={{ top: 20 }}
                open={visible}
                width={"55%"}
                title={"Account Summary (All)"}
                okButtonProps={{
                    style: {
                        display: "none",
                    },
                }}
                cancelText="Close"
                onCancel={() => {
                    setVisible(false);
                }}
            >
                <Form
                    form={metricfilterForm}
                    colon={false}
                    labelCol={{ span: 16 }}
                    wrapperCol={{ span: 8 }}
                    layout="horizontal"
                    onValuesChange={onFilterValueChanged}
                >
                    <div className="search-panel">
                        <FormComponent
                            label={" "}
                            name={"searchKey"}
                            extra={{
                                type: ComponentType.text,
                                value: "",
                                itemProps: {
                                    style: {
                                        marginBottom: "0px",
                                    },
                                },
                                inputProps: {
                                    prefix: <SearchOutlined />,
                                    allowClear: true,
                                    placeholder: "search text",
                                },
                            }}
                        />
                    </div>
                </Form>
                <div className={`account-summary-panel ${props.forPrint ? "for-print" : ""}`}>
                    <div className={`metric-items-container pop-up-grid ${props.forPrint ? "repeat-4" : ""}`}>
                        {viewAllMetrics(data, props.metricCategories, searchKey)}
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default AccountSummaryPanel;
