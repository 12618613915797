import { createApi } from "@reduxjs/toolkit/query/react";
import { APIs, defaultBaseQueryProps, ResponseProps } from "../../services/apiConfig";
import { GroupPurposes } from "../../constants/type";
import { axiosBaseQuery } from "../axiosBaseQuery";

export interface groupPurposesRequest {}

export interface groupPurposesResponse extends ResponseProps<GroupPurposes[]> {}

export const groupPurposesApiSlice = createApi({
    reducerPath: "api_10",
    baseQuery: axiosBaseQuery(),
    keepUnusedDataFor: 5, //global caching duration (seconds)
    endpoints: (builder) => ({
        getGroupPurposes: builder.query<groupPurposesResponse, groupPurposesRequest | undefined>({
            keepUnusedDataFor: 0, //disable for caching for particular api request
            query: (arg) => ({ ...defaultBaseQueryProps, url: APIs.GET_GROUP_PURPOSES_LIST, method: "POST", params: arg }),
            transformResponse: (returnValue: groupPurposesResponse, meta) => {
                return returnValue;
            },
        }),
    }),
});

export const { useGetGroupPurposesQuery } = groupPurposesApiSlice;
