import React from "react";
import useRCSpreadReportStore from "../../../store/useRCSpreadReportStore";
import useRCSpreadGroups from "../../../hooks/useRCSpreadGroups";
import useRCSymbols from "../../../hooks/useRCSymbols";
import { Select, DatePicker, Space, Row, Col, Typography, Button, Alert, TimePicker } from "antd";
import type { SelectProps } from "antd";
import moment from "moment";
import BasicFilter from "../../../components/BasicFilter";
import CommonPaddingWrapper from "../CommonPaddingWrapper";
import Loader from "../../SystemMonitor/components/Loader";

const AverageSpreadAnalysisFilters: React.FC = () => {
    // Implement your component logic here
    const { RangePicker } = DatePicker;
    const { RangePicker: TimeRangePicker } = TimePicker;
    const { Text } = Typography;

    const start = useRCSpreadReportStore(state => state.averageSpreadAnalysis.start);
    const end = useRCSpreadReportStore(state => state.averageSpreadAnalysis.end);
    const symbol = useRCSpreadReportStore(state => state.averageSpreadAnalysis.symbol);
    const groups = useRCSpreadReportStore(state => state.averageSpreadAnalysis.groups);
    const startTime = useRCSpreadReportStore(state => state.averageSpreadAnalysis.startTime);
    const endTime = useRCSpreadReportStore(state => state.averageSpreadAnalysis.endTime);
    const setAverageSpreadAnalysis = useRCSpreadReportStore(state => state.setAverageSpreadAnalysis);
    const updateAverageSpreadAnalysisQuery = useRCSpreadReportStore(state => state.updateAverageSpreadAnalysisQuery);

    const { rcSymbols, rcSymbolsError, refetchRcSymbols, isLoading: isLoadingRcSymbols, isFetching: isFetchingRcSymbols } = useRCSymbols();
    const {
        rcSpreadGroups,
        rcSpreadGroupsError,
        refetchRcSpreadGroups,
        isLoading: isLoadingRcSpreadGroups,
        isFetching: isFetchingRcSpreadGroups,
    } = useRCSpreadGroups();

    // console.log("spreadReportQuery", spreadReportQuery);
    // console.log("rcSpreadGroups", rcSpreadGroups);
    const optionsRcSymbols: SelectProps["options"] = rcSymbols?.map((each: any) => ({ label: each?.name, value: each?.name })) ?? [];
    const handleChangeSymbol = (value: string) => {
        setAverageSpreadAnalysis({
            symbol: value,
        });
    };
    const handleResetFilter = () => {
        setAverageSpreadAnalysis({
            start: new Date().toISOString().split("T")[0],
            end: new Date().toISOString().split("T")[0],
            startTime: "00:00",
            endTime: "23:59",
            currType: "servers",
            groups: { 1: [], 2: [], 4: [] },
            symbol: null,
        });
    };
    // console.log(start, end, symbol, groups);
    if ((isLoadingRcSymbols || isLoadingRcSpreadGroups) && !rcSymbolsError && !rcSpreadGroupsError) {
        return (
            <CommonPaddingWrapper>
                <Loader />
            </CommonPaddingWrapper>
        );
    }
    if (rcSymbolsError) {
        return (
            <CommonPaddingWrapper>
                <Alert
                    message={`Having some error while loading symbol. Error: ${rcSymbolsError}`}
                    type="warning"
                    action={
                        <Button size="small" type="text" onClick={() => refetchRcSymbols()} disabled={isLoadingRcSymbols}>
                            Retry
                        </Button>
                    }
                />
            </CommonPaddingWrapper>
        );
    }
    if (rcSpreadGroupsError) {
        return (
            <CommonPaddingWrapper>
                <Alert
                    message={`Having some error while loading spread groups. Error: ${rcSpreadGroupsError}`}
                    type="warning"
                    action={
                        <Button size="small" type="text" onClick={() => refetchRcSpreadGroups()} disabled={isLoadingRcSpreadGroups}>
                            Retry
                        </Button>
                    }
                />
            </CommonPaddingWrapper>
        );
    }

    return (
        <BasicFilter>
            <Row gutter={[8, 8]}>
                <Col span={8} xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 8 }}>
                    <Text>Symbol *</Text>
                    <Select
                        allowClear
                        style={{ width: "100%" }}
                        placeholder="Select Symbol"
                        value={symbol}
                        onChange={handleChangeSymbol}
                        options={optionsRcSymbols}
                        // allow type to search
                        showSearch
                    />
                </Col>
                <Col span={16} xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 8 }}>
                    <Text>Date Range *</Text>
                    <RangePicker
                        style={{ width: "100%" }}
                        // showTime={{ format: "HH:mm" }}
                        // format="YYYY-MM-DD HH:mm"
                        format="YYYY-MM-DD"
                        onChange={(dates, dateStrings) => {
                            // console.log(dateStrings);
                            setAverageSpreadAnalysis({
                                start: dateStrings[0],
                                end: dateStrings[1],
                            });
                        }}
                        // disable selecting the day after today, and 20 days before today
                        disabledDate={current => {
                            return current && (current > moment().endOf("day") || current < moment().subtract(20, "days").startOf("day"));
                        }}
                        // disabledDate={(current) => current && current > moment().endOf("day")}

                        // read start and end from store
                        value={[moment(start), moment(end)]}
                    />
                </Col>
                <Col span={16} xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 8 }}>
                    <Text>Time Range *</Text>
                    <TimeRangePicker
                        style={{ width: "100%" }}
                        // showTime={{ format: "HH:mm" }}
                        // format="YYYY-MM-DD HH:mm"
                        format="HH:mm"
                        onChange={(time, timeString) => {
                            // console.log(time, timeString);
                            setAverageSpreadAnalysis({
                                startTime: timeString[0],
                                endTime: timeString[1],
                            });
                        }}
                        // read start and end from store
                        value={[moment(startTime, "HH:mm"), moment(endTime, "HH,mm")]}
                    />
                </Col>
            </Row>
            <Row gutter={[8, 8]}>
                {/* <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 8 }}>
                    <Text>List Type *</Text>
                    <br />
                    <Radio.Group
                        value={currType}
                        onChange={e => setAverageSpreadAnalysis({ currType: e.target.value, groups: { 1: [], 2: [], 4: [] } })}
                        // options={[
                        //     { label: "Our Servers", value: "servers" },
                        //     { label: "Outsider Brokers", value: "brokers" },
                        // ]}
                        optionType="button"
                        style={{ display: "flex" }}
                        // make button take 100% width
                        // buttonStyle="solid"
                    >
                        <Radio.Button value="servers" style={{ flex: 1 }}>
                            Our Servers
                        </Radio.Button>
                        <Radio.Button value="brokers" style={{ flex: 1 }}>
                            Outsider Brokers
                        </Radio.Button>
                    </Radio.Group>
                </Col> */}
                {Object.keys(rcSpreadGroups)?.map((each: any) => {
                    const options = rcSpreadGroups[each]?.map((each: any) => ({ label: each, value: each })) ?? [];
                    const groupNaming = {
                        1: "Liquidity Provider",
                        2: "Our Servers",
                        4: "Outsider Brokers",
                    };
                    const order = {
                        1: 3,
                        2: 1,
                        4: 2,
                    };
                    // if (each === "1") return null;
                    // if (each === "2" && currType === "brokers") return null;
                    // if (each === "4" && currType === "servers") return null;
                    return (
                        <Col span={8} key={each} xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 8 }} order={order[each as keyof typeof order]}>
                            <Text>{groupNaming[each as keyof typeof groupNaming]}</Text>
                            <Select
                                allowClear
                                mode="multiple"
                                showSearch
                                placeholder={`Select ${groupNaming[each as keyof typeof groupNaming]}`}
                                value={groups[each] ?? []}
                                style={{ width: "100%" }}
                                onChange={value => {
                                    // setGroups(each, value ?? []);
                                    // const putValueInArray = value ? [value] : [];
                                    // setAverageSpreadAnalysis({
                                    //     // @ts-ignore
                                    //     groups: { ...groups, [each]: putValueInArray },
                                    // });
                                    setAverageSpreadAnalysis({
                                        // @ts-ignore
                                        groups: { ...groups, [each]: value },
                                    });
                                }}
                                options={options}
                            />
                        </Col>
                    );
                })}
            </Row>
            <Space style={{ marginTop: "1rem", display: "flex", flexWrap: "wrap", borderTop: "1px solid rgba(0, 0, 0, 0.06)", paddingTop: "1rem" }}>
                <Button type="default" onClick={() => handleResetFilter()} style={{ minWidth: 100 }}>
                    Reset
                </Button>
                <Button
                    type="primary"
                    onClick={() => {
                        updateAverageSpreadAnalysisQuery();
                    }}
                    disabled={!start || !end || !symbol || (groups[1]?.length <= 0 && groups[2]?.length <= 0 && groups[4]?.length <= 0)}
                    style={{ minWidth: 100 }}
                >
                    Generate Report
                </Button>
            </Space>
        </BasicFilter>
    );
};

export default AverageSpreadAnalysisFilters;
