import { Badge, Button, Col, Row, Space, Tag, Tooltip, Typography } from "antd";
import { IPriceOutage } from "../../../hooks/useRCPriceOutage";
import { IHourlyStats } from "../../../store/useRCPriceOutageStore";
import { colorMap } from "../../SystemMonitor/StatusMonitor/statusUtils";
import React, { useEffect, useState } from "react";
import { LuPin, LuPinOff } from "react-icons/lu";
import { BackwardOutlined, ForwardOutlined } from "@ant-design/icons";
const PriceOutageSummary = ({
    rawData,
    selectedData,
    summarized,
    setSelectedData,
    finalRawStats,
    finalRawData,
}: {
    rawData: { [timestamp: number]: IPriceOutage[] };
    selectedData: number | null;
    summarized: IHourlyStats[];
    setSelectedData: (timestamp: number | null) => void;
    finalRawStats: IHourlyStats | null;
    finalRawData: IPriceOutage[] | null;
}) => {
    const finalData = finalRawStats;

    const totalLevels = Object.keys(finalData?.uniqueLevels || {}).length;
    const totalSymbol = Object.keys(finalData?.uniqueCleanSymbols || {}).length;
    const totalServer = Object.keys(finalData?.uniqueServers || {}).length;

    // take timestamp as localtime, check current localtime gmt, and convert it back to gmt+3

    const convertTimeToGMTPlus3 = (timestamp: number) => {
        const localTime = new Date(timestamp);
        const gmt = localTime.getTime() + localTime.getTimezoneOffset() * 60000;
        const gmtPlus3 = new Date(gmt + 3 * 3600000);

        return gmtPlus3.toLocaleString();
    };

    // console.log("finalData", finalData);
    const { Title, Text } = Typography;
    return (
        <div>
            <Space direction="vertical" size={4} style={{ width: "100%" }}>
                <SummaryContainer styles={{ backgroundColor: "rgba(0,0,0,0.025)" }}>
                    <Row gutter={[4, 4]}>
                        <Col flex="50px" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Button
                                // icon={<LeftOutlined />}
                                // icon={<GrBackTen />}
                                icon={<BackwardOutlined />}
                                type="text"
                                shape="circle"
                                disabled={
                                    // if it's the first data, disable the button
                                    selectedData === summarized?.[0]?.timestamp || summarized?.length <= 1
                                }
                                onClick={() => {
                                    // if selectedData is null, get 2nd last data
                                    if (!selectedData) {
                                        setSelectedData(summarized[summarized?.length - 2].timestamp);
                                        return;
                                    } else {
                                        const index = summarized?.findIndex(data => data.timestamp === selectedData);
                                        setSelectedData(summarized[index - 1]?.timestamp);
                                    }
                                }}
                            />
                        </Col>
                        <Col flex="auto">
                            <Space direction="vertical" size={0} style={{ width: "100%", textAlign: "center", fontSize: "0.75rem" }}>
                                {!selectedData ||
                                // it's the last data
                                selectedData === summarized?.[summarized?.length - 1]?.timestamp ? (
                                    <Space>
                                        <Badge status="processing" />
                                        <Text style={{ fontSize: "0.65rem", color: colorMap.error }}>LIVE</Text>
                                        {!selectedData ? null : (
                                            // <Tooltip title="Pin this data">
                                            //     <Button
                                            //         type="text"
                                            //         shape="circle"
                                            //         size="small"
                                            //         style={{
                                            //             display: "flex",
                                            //             justifyContent: "center",
                                            //             alignItems: "center",
                                            //             padding: 1,
                                            //             lineHeight: 1,
                                            //         }}
                                            //         onClick={() => {
                                            //             setSelectedData(summarized[summarized?.length - 1].timestamp);
                                            //         }}
                                            //         icon={<LuPin />}
                                            //     />
                                            // </Tooltip>
                                            <Tooltip title="Unpin this data">
                                                <Button
                                                    danger
                                                    type="text"
                                                    shape="circle"
                                                    size="small"
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        padding: 1,
                                                        lineHeight: 1,
                                                    }}
                                                    onClick={() => setSelectedData(null)}
                                                    icon={<LuPinOff />}
                                                />
                                            </Tooltip>
                                        )}
                                    </Space>
                                ) : (
                                    <Space>
                                        <Badge status="warning" />
                                        <Text style={{ fontSize: "0.65rem", color: colorMap.warning }}>
                                            Historical Data
                                            {
                                                // if over 1 hour and not inside summarize, show "more than 1 hour ago"
                                                selectedData < summarized[summarized?.length - 1].timestamp - 3600000 &&
                                                selectedData !== summarized[summarized?.length - 1].timestamp
                                                    ? " (more than 1 hour ago)"
                                                    : ""
                                            }
                                        </Text>
                                        <Tooltip title="Unpin this data">
                                            <Button
                                                danger
                                                type="text"
                                                shape="circle"
                                                size="small"
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    padding: 1,
                                                    lineHeight: 1,
                                                }}
                                                onClick={() => setSelectedData(null)}
                                                icon={<LuPinOff />}
                                            />
                                        </Tooltip>
                                    </Space>
                                )}

                                {/* time */}
                                <Text>
                                    <strong>Local Time:</strong>{" "}
                                    {selectedData
                                        ? new Date(selectedData).toLocaleString()
                                        : new Date(summarized[summarized?.length - 1].timestamp).toLocaleString()}
                                </Text>
                                {/* convert time to gmt+3, and show as dd/MM/YYYY HH:MM:ss */}
                                <Text>
                                    <strong>Server Time:</strong>{" "}
                                    {selectedData
                                        ? convertTimeToGMTPlus3(selectedData)
                                        : convertTimeToGMTPlus3(summarized[summarized?.length - 1].timestamp)}
                                </Text>
                                <Text>
                                    <strong>Total Incidents:</strong> {finalData?.totalIncidents}
                                </Text>
                            </Space>
                        </Col>
                        <Col flex="50px" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Button
                                // icon={<RightOutlined />}
                                shape="circle"
                                icon={
                                    <ForwardOutlined />
                                    // selectedData === summarized?.[summarized?.length - 1]?.timestamp || !selectedData ? (
                                    //     <GrCirclePlay />
                                    // ) : (
                                    //     // <GrForwardTen />
                                    //     <ForwardOutlined />
                                    // )
                                }
                                type="text"
                                // disabled={
                                //     // if it's the last data, disable the button
                                //     selectedData === summarized?.[summarized?.length - 1]?.timestamp || !selectedData
                                // }
                                disabled={
                                    // if it's the last data, disable the button
                                    !selectedData
                                }
                                onClick={() => {
                                    // if it's the last data, setSelectedData to null
                                    // adjusted to -2, skip last data and use special adjusted liva data bar
                                    if (selectedData === summarized?.[summarized?.length - 2]?.timestamp) {
                                        setSelectedData(null);
                                        return;
                                    }

                                    // if selectedData is null, get 2nd data
                                    if (!selectedData) {
                                        return;
                                    } else {
                                        const index = summarized?.findIndex(data => data.timestamp === selectedData);
                                        setSelectedData(summarized[index + 1].timestamp);
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                </SummaryContainer>
                <Row gutter={[4, 4]} style={{ width: "calc(100% + 4px)", marginLeft: -2, marginRight: -2 }}>
                    <Col xs={{ span: 8 }} style={{ textAlign: "center" }}>
                        <SummaryContainer>
                            {/* map all uniqueLevels with numbers */}
                            {totalLevels <= 0
                                ? "All Good"
                                : Object.keys(finalData?.uniqueLevels || {}).map(level => (
                                      <div key={level}>
                                          <Text style={{ fontSize: "0.75rem" }}>Level {level}</Text>{" "}
                                          <Tag style={{ borderRadius: 30 }}>{finalData?.uniqueLevels[level]}</Tag>
                                      </div>
                                  ))}
                        </SummaryContainer>
                    </Col>
                    <Col xs={{ span: 8 }} style={{ textAlign: "center", display: "flex", flexDirection: "column" }}>
                        <SummaryContainer>
                            <Title level={1} style={{ margin: 0 }}>
                                {/* total uniqueCleanSymbols */}
                                {totalSymbol}
                            </Title>
                            <Text>Symbol{totalSymbol >= 2 ? "s" : ""}</Text>
                        </SummaryContainer>
                        {/* list symbols */}
                        {finalData && Object.keys(finalData?.uniqueCleanSymbols).length >= 1 && finalRawData && (
                            <ItemTags totalItems={totalSymbol} items={finalData?.uniqueCleanSymbols} finalRawData={finalRawData} type="symbol" />
                        )}
                    </Col>
                    <Col xs={{ span: 8 }} style={{ textAlign: "center", display: "flex", flexDirection: "column" }}>
                        <SummaryContainer>
                            <Title level={1} style={{ margin: 0 }}>
                                {/* total uniqueServers */}
                                {totalServer}
                            </Title>
                            <Text>Server{totalServer >= 2 ? "s" : ""}</Text>
                        </SummaryContainer>
                        {/* list servers */}
                        {finalData && Object.keys(finalData?.uniqueServers).length >= 1 && finalRawData && (
                            <ItemTags totalItems={totalServer} items={finalData?.uniqueServers} finalRawData={finalRawData} type="server" />
                        )}
                    </Col>
                </Row>
            </Space>
        </div>
    );
};

const SummaryContainer = ({ children, styles }: { children?: React.ReactNode; styles?: React.CSSProperties }) => (
    <div
        style={{
            padding: "1rem",
            background: "rgba(0,0,0,0.05)",
            borderRadius: "0.25rem",
            // height: "100%",
            minHeight: "100px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            ...styles,
        }}
    >
        {children}
    </div>
);

interface ItemTagsProps {
    totalItems: number;
    items: { [key: string]: number };
    finalRawData: IPriceOutage[] | null;
    type: "server" | "symbol";
}

const ItemTags: React.FC<ItemTagsProps> = ({ totalItems, items, finalRawData, type }) => {
    // console.log("items", items);
    // console.log("finalRawData", finalRawData);
    // console.log("totalItems", totalItems);
    // console.log("type", type);
    const [isMounted, setIsMounted] = useState(false);
    useEffect(() => {
        setIsMounted(true);
        return () => {
            setIsMounted(false);
        };
    }, []);

    if (!isMounted || !finalRawData || totalItems === 0) return null;

    const { Text } = Typography;
    return (
        <Space
            wrap
            size={1}
            style={{
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                padding: "0.5rem",
                background: "rgba(0,0,0,0.015)",
                flex: 1,
            }}
        >
            {totalItems <= 0 || !items
                ? null
                : Object.keys(items)?.map(item => {
                      // if item is symbol, show symbol maximum outage time
                      let maxOutage = 0;
                      let startTime = null;
                      if (type === "symbol") {
                          //   const symbol = finalRawData?.find(data => data.symbol === item);
                          //   maxOutage = symbol?.duration || 0;

                          // there might be multiple matched, find the max outage
                          const symbol = finalRawData?.filter(data => data.cleanSymbol === item) || null;
                          if (!symbol || symbol.length <= 0) return null;
                          //   console.log("symbol", symbol);
                          maxOutage = Math.max(...symbol?.map(data => data.duration || 0));
                          // startDate is string, compare and get the earliest
                          startTime = symbol?.map(data => data.startDate)?.sort()[0] || null;

                          //   console.log("maxOutage", maxOutage);
                          //   console.log("symbol", symbol);
                      } else if (type === "server") {
                          //   const server = finalRawData?.find(data => data.serverId === item);
                          //   maxOutage = server?.duration || 0;

                          // there might be multiple matched, find the max outage
                          const server = finalRawData?.filter(data => data.serverId === item) || null;
                          if (!server || server.length <= 0) return null;
                          maxOutage = Math.max(...server?.map(data => data.duration || 0));
                          // startDate is string, compare and get the earliest
                          startTime = server?.map(data => data.startDate)?.sort()[0] || null;
                      }

                      // convert timestamp to HH:MM:ss

                      // convert ms to HH:MM:ss
                      const convertDuration = (ms: number) => {
                          const seconds = Math.floor(ms / 1000);
                          const minutes = Math.floor(seconds / 60);
                          const hours = Math.floor(minutes / 60);
                          return `${hours % 24}:${String(minutes % 60).padStart(2, "0")}:${String(seconds % 60).padStart(2, "0")}`;
                          //   return `${hours % 24}h ${minutes % 60}m ${seconds % 60}s`;
                      };

                      return (
                          <Tooltip title={startTime || ""} key={item}>
                              <Tag
                                  key={item}
                                  style={{
                                      borderRadius: 4,
                                      margin: 0,
                                      display: "flex",
                                      alignItems: "center",
                                      gap: 4,
                                      paddingRight: 3,
                                      //   cursor: "pointer",
                                  }}
                              >
                                  <Space direction="vertical" size={1} style={{ lineHeight: 1.5 }}>
                                      <Space size={1} style={{ alignItems: "center", justifyContent: "center" }}>
                                          <Text>{item}</Text>
                                          <Badge count={items[item]} size="small" key={item} />
                                      </Space>
                                      {/* {type === "symbol" && <span style={{ fontSize: "0.65rem" }}>{convertTimestamp(maxOutage)}</span>} */}
                                      <span style={{ fontSize: "0.65rem" }}>{convertDuration(maxOutage)}</span>
                                  </Space>
                              </Tag>
                          </Tooltip>
                      );
                  })}
        </Space>
    );
};

export default PriceOutageSummary;
