import React, { useState } from "react";
import FloatingTitleBox from "../../components/FloatingTitleBox";
import { Tabs } from "antd";
import useRCIssuesLogStore from "../../../../store/useRCIssuesLogStore";
import CommonIssuesLogTable from "../CommonIssuesLogTable";
import CommonIssuesLogHistoryTable from "../CommonIssuesLogHistoryTable";
import CommonIssuesLogTypeDownload from "../CommonIssuesLogTypeDownload";
import AuthHelper, { AuthKeys } from "../../../../helpers/authHelper";
const IssueModifyOpenOrder = () => {
    const updateInView = useRCIssuesLogStore(state => state.updateInView);
    const authHp = new AuthHelper();
    const canUpload = authHp.isAuthorized(AuthKeys.RC.RC_ISSUES_LOG_ACCOUNT_MONITOR_UPLOAD_EDIT);
    // console.log("canUpload", canUpload);
    const [currTab, setCurrTab] = useState<string>("monitor");
    const handleChangeTab = (activeKey: string) => {
        setCurrTab(activeKey);
        // window.history.pushState({}, "", `${window.location.pathname}?tab=${activeKey}`);
    };
    return (
        <FloatingTitleBox
            title="Account Monitor"
            // titleExtra={<Badge count={rcIssuesLog?.length + 5} />}
            inViewCallback={(isInView: boolean) => {
                updateInView({ ACCOUNT_MONITOR: isInView });
            }}
            titleBarTabs={
                <Tabs
                    activeKey={currTab}
                    onChange={handleChangeTab}
                    type="card"
                    style={{
                        padding: "0.5rem 0.5rem 0 0.5rem",
                    }}
                    tabBarStyle={{
                        marginBottom: 0,
                    }}
                    items={[
                        {
                            label: "Monitoring",
                            key: "monitor",
                        },
                        {
                            label: "History",
                            key: "history",
                        },
                        {
                            label: "Settings",
                            key: "settings",
                        },
                    ]}
                />
            }
        >
            {currTab === "monitor" && (
                <CommonIssuesLogTable
                    type="ACCOUNT_MONITOR"
                    // filterData={[
                    //     {
                    //         dataKey: "msg",
                    //         dataKeyLabel: "Message",
                    //         dataValueToFilter: [
                    //             {
                    //                 label: "Config change",
                    //                 value: "config",
                    //             },
                    //             {
                    //                 label: "Order change",
                    //                 value: "order",
                    //             },
                    //             {
                    //                 label: "Balance change",
                    //                 value: "changed balance",
                    //             },
                    //             {
                    //                 label: "Credit change",
                    //                 value: "changed credit",
                    //             },
                    //             {
                    //                 label: "Tick change",
                    //                 value: "add tick",
                    //             },
                    //         ],
                    //     },
                    // ]}
                />
            )}
            {currTab === "history" && <CommonIssuesLogHistoryTable type="ACCOUNT_MONITOR" />}
            {currTab === "settings" && <CommonIssuesLogTypeDownload type="ACCOUNT_MONITOR" canUpload={canUpload} />}
        </FloatingTitleBox>
    );
};

export default IssueModifyOpenOrder;
