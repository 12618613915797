import { TinyLine } from "@ant-design/charts";
import { useMemo } from "react";
import { currencyRender } from "../../../utils/Common";
import { TinyChartProps } from "./TinySummaryChart";
import { Tooltip } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

/**
 * default config as below
 *
 * {
        title: "lp total exposure",
        value: 3312459.66,
        tinyChartProps: {
            data: [264, 417, 438, 887, 309, 397, 550, 575, 563, 430, 525],
            customContent: (title: any, data: any) => React.ReactNode;
        },
    }
 *
 */
export interface TinySummaryChart2Props {
    title: string;
    value: number | string;
    valueToolTips?: React.ReactNode;
    enableChangePercentage?: boolean;
    tinyChartProps?: TinyChartProps;
}

const TinySummaryChart2 = (props: TinySummaryChart2Props) => {
    const defaultChartProps = useMemo(
        () => ({
            width: 160,
            height: 43,
            autoFit: false,
            smooth: true,
            color: "#004b57",
            tooltip: {
                customContent:
                    props.tinyChartProps && props.tinyChartProps.customContent
                        ? props.tinyChartProps.customContent
                        : (title: any, data: any) => {
                              if (data && data.length > 0) return currencyRender(data[0].value, 2);
                              return "";
                          },
            },
        }),
        [props.tinyChartProps]
    );

    const getChangesPercentage = (data: number[]) => {
        if (data.length - 6 > -1 && data[data.length - 6] !== 0) {
            let pValue: number = (data[data.length - 1] - data[data.length - 7]) / data[data.length - 7],
                isPositiveValue: boolean = pValue > 0,
                toFixedStringValue: string = pValue.toFixed(2),
                toFixedNumberValue: number = parseFloat(toFixedStringValue);

            if (pValue === 0) return <span>{`( 0% )`}</span>;

            return (
                <span className={`sub-value val-${isPositiveValue ? "positive" : "negative"}`}>
                    ({toFixedNumberValue === 0 ? `${isPositiveValue ? "<+" : ">-"}0.01` : `${isPositiveValue ? "+" : ""}${toFixedStringValue}`}%)
                </span>
            );
        }
        return <span>{`( 0% )`}</span>;
    };

    const markupValue = useMemo(() => {
        let markupVal = currencyRender(props.value, 0);
        return markupVal.indexOf(".") > -1 ? markupVal.substring(0, markupVal.indexOf(".")) : markupVal;
    }, [props.value]);

    return (
        <div className="tiny-summary-chart2-container">
            <div>
                <div className="left">
                    <TinyLine {...Object.assign(defaultChartProps, props.tinyChartProps)} />
                </div>
                <div className="right">
                    <div className="value-container">
                        <span className="main-value">{markupValue}</span>
                        {props.enableChangePercentage && getChangesPercentage(props.tinyChartProps?.data || [])}
                    </div>
                    <div className="main-title">
                        {props.title.toUpperCase()}
                        {props.valueToolTips && (
                            <Tooltip title={props.valueToolTips} mouseEnterDelay={0}>
                                <ExclamationCircleOutlined style={{ marginLeft: "0.32vw", color: "#fc5252", cursor: "pointer" }} />
                            </Tooltip>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TinySummaryChart2;
