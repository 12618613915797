import FlexiDataTable from "@/components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType } from "@/constants";
import { FlexiDataTableCallbackProps, FlexiDataTableOptionsProps, KeyValuePair } from "@/constants/type";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { RedoOutlined, RollbackOutlined, SaveOutlined } from "@ant-design/icons";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ServersProps } from "..";
import { GetUniqueKeysList, SortList, ToObjectWithKey } from "@/utils/array";
import { DefaultIfEmpty, objectRemoveProps } from "@/utils/object";
import { DTColProps, ErrorCatchValidator } from "@/utils/Common";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import EditSymbolSettingModal, { EditSymbolSettingModalCallbackKey } from "./EditSymbolSettingModal";
import { message, Modal } from "antd";
import { isEmptyOrNull } from "@/utils/string";

export interface PriceAnalysisSymbolSettingsPageProps {}

interface PriceAnalysisSymbolSettingsPageState {
    isChangedStatus: boolean;
    data: any[];
}

interface PriceAnalysisSymbolSettingsProps {
    baseServerDn: null | string;
    baseServerUno: number;
    baseSymbol: string;
    groupId: number;
    otherServerDn: null | string;
    otherServerUno: number;
    otherSymbol: string;
}

export interface PriceAnalysisSymbolInfoProps {
    isBaseServer: boolean;
    serverName: string;
    serverNo: number;
    symbols: KeyValuePair[];
}

const ServerSortExpression = (a: ServersProps, b: ServersProps) => {
    if (a.isBaseServer === b.isBaseServer) {
        return a.serverDn.localeCompare(b.serverDn);
    }

    return Number(b.isBaseServer) - Number(a.isBaseServer);
};

const PriceAnalysisSymbolSettingsPage = (props: PriceAnalysisSymbolSettingsPageProps) => {
    const [isEditModalVisible, setIsEditModalVisible] = useState<boolean>(false);
    const [editData, setEditData] = useState<any | undefined>(undefined);
    const [deleteSymbolList, setDeleteSymbolList] = useState<string[]>([]);
    const [updatedSymbolData, setUpdatedSymbolData] = useState<{ [key: string]: any }>({});
    const [createSymbolData, setCreateSymbolData] = useState<{ [key: string]: any }>({});
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [runRefetchServerSymbol, setRunRefetchServerSymbol] = useState<boolean>(false);
    const [isChangedStatus, setIsChangedStatus] = useState<boolean>(false);
    const [availableGroups, setAvailableGroups] = useState<KeyValuePair[]>([]);
    const [servers, setServers] = useState<ServersProps[]>([]);
    const [symbolInfo, setSymbolInfo] = useState<{ [key: number]: PriceAnalysisSymbolInfoProps }>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [oriData, setOriData] = useState<PriceAnalysisSymbolSettingsProps[]>([]);
    const [selectedGroupId, setSelectedGroupId] = useState<number | undefined>(undefined);

    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.PRICE_SETTING_SPREAD_ALARM_EDIT);

    const tableStates: PriceAnalysisSymbolSettingsPageState = useMemo(() => {
        let colProps = SortList(servers, "", ServerSortExpression);

        return {
            isChangedStatus:
                isChangedStatus ||
                deleteSymbolList.length > 0 ||
                Object.keys(updatedSymbolData).length > 0 ||
                Object.keys(createSymbolData).filter((x: string) => !deleteSymbolList.includes(x)).length > 0,
            data: [
                ...GetUniqueKeysList(oriData, "baseSymbol")
                    .filter((x: string) => !deleteSymbolList.includes(x))
                    .map((x: string) => {
                        let tmpOri = oriData.filter(z => z.baseSymbol === x),
                            tmp = ToObjectWithKey(tmpOri, "otherServerUno", "otherSymbol");

                        return {
                            symbol: x,
                            ...colProps.reduce((obj: any, y: ServersProps) => {
                                obj[y.serverUno] = y.isBaseServer ? tmpOri[0].baseSymbol : DefaultIfEmpty(tmp, y.serverUno, "");
                                return obj;
                            }, {}),
                        };
                    }),
                ...Object.keys(createSymbolData)
                    .filter((x: string) => !deleteSymbolList.includes(x))
                    .map((x: string) => createSymbolData[x]),
            ],
        };
    }, [oriData, servers, isChangedStatus, createSymbolData, deleteSymbolList, updatedSymbolData]);

    const columns: any[] = useMemo(
        () => [
            ...[
                {
                    title: "Group Name",
                    dataIndex: "groupId",
                    key: "groupId",
                    options: {
                        visible: false,
                        filter: {
                            type: ComponentType.dropdown,
                            value: availableGroups,
                            rules: [{ required: true, message: REQUIRED_FIELD }],
                        },
                    },
                },
            ],
            ...SortList(servers, "", ServerSortExpression).map((x: ServersProps) =>
                DTColProps.Small({
                    title: x.serverDn,
                    dataIndex: x.serverUno,
                    key: x.serverUno,
                    render: (text: number, rowData: any) => {
                        let tmp = DefaultIfEmpty(updatedSymbolData, rowData["symbol"], { [x.serverUno]: text });
                        return x.isBaseServer ? (
                            <span
                                style={{ color: "#00615D", fontWeight: "bold" }}
                                className={rowData.hasOwnProperty("newAdd") ? "highlight-col" : ""}
                            >
                                {text}
                            </span>
                        ) : tmp[x.serverUno] !== text ? (
                            <span className="highlight-col">{tmp[x.serverUno]}</span>
                        ) : (
                            <span className={rowData.hasOwnProperty("newAdd") ? "highlight-col" : ""}>{text}</span>
                        );
                    },
                })
            ),
        ],
        [availableGroups, servers, updatedSymbolData]
    );

    const options: FlexiDataTableOptionsProps = {
        separateActionButton: true,
        ...(!isEmptyOrNull(selectedGroupId) && {
            add: enableUpdate,
            edit: enableUpdate,
            delete: enableUpdate,
            extraButtons: [
                ...[{ text: "Refresh", icon: <RedoOutlined />, value: "refresh" }],
                ...(enableUpdate && tableStates.isChangedStatus
                    ? [
                          { text: "Rollback", icon: <RollbackOutlined />, value: "rollback" },
                          { text: "Save", icon: <SaveOutlined />, value: "saveSettings" },
                      ]
                    : []),
            ],
        }),
    };

    const saveAllSettings = useCallback(async () => {
        setIsLoading(true);
        let oriDataObject = oriData.reduce((obj: any, x: PriceAnalysisSymbolSettingsProps) => {
                obj[`${x.baseSymbol}||${x.otherServerUno}`] = x;
                return obj;
            }, {}),
            updatedSymbolList = Object.keys(updatedSymbolData)
                .filter((x: string) => !deleteSymbolList.includes(x))
                .reduce(
                    (arrList: any, x: string) => {
                        Object.keys(updatedSymbolData[x]).forEach((y: string) => {
                            try {
                                let serverNo: number = parseInt(y);
                                if (oriDataObject.hasOwnProperty(`${x}||${serverNo}`)) {
                                    if (oriDataObject[`${x}||${serverNo}`].otherSymbol !== updatedSymbolData[x][serverNo]) {
                                        arrList.oriList.push({ ...oriDataObject[`${x}||${serverNo}`] });
                                        arrList.updateList.push({
                                            ...oriDataObject[`${x}||${serverNo}`],
                                            otherSymbol: updatedSymbolData[x][serverNo],
                                        });
                                    }
                                }
                            } catch (error) {}
                        });

                        return arrList;
                    },
                    { oriList: [], updateList: [] }
                ),
            createSymbolList = Object.keys(createSymbolData)
                .filter((x: string) => !deleteSymbolList.includes(x))
                .reduce((arrList: any, x: string) => {
                    // Apply the updated data to the original created data
                    if (updatedSymbolData.hasOwnProperty(x)) {
                        let tmp = updatedSymbolData[x];
                        arrList = arrList.concat(
                            Object.keys(objectRemoveProps(createSymbolData[x], ["symbol", "baseServerUno", "baseSymbol"])).map((y: string) => {
                                let serverNo: number = parseInt(y);
                                return {
                                    groupId: selectedGroupId,
                                    symbol: createSymbolData[x].symbol,
                                    baseServerUno: createSymbolData[x].baseServerUno,
                                    baseSymbol: createSymbolData[x].baseSymbol,
                                    otherServerUno: serverNo,
                                    otherSymbol: tmp[serverNo],
                                };
                            })
                        );
                    } else {
                        arrList = arrList.concat(
                            Object.keys(objectRemoveProps(createSymbolData[x], ["symbol", "baseServerUno", "baseSymbol"])).map((y: string) => {
                                let serverNo: number = parseInt(y);
                                return {
                                    groupId: selectedGroupId,
                                    symbol: createSymbolData[x].symbol,
                                    baseServerUno: createSymbolData[x].baseServerUno,
                                    baseSymbol: createSymbolData[x].baseSymbol,
                                    otherServerUno: serverNo,
                                    otherSymbol: createSymbolData[x][serverNo],
                                };
                            })
                        );
                    }

                    return arrList;
                }, []),
            deletedSymbolList = deleteSymbolList.reduce((arrList: any[], x: string) => {
                return arrList.concat(
                    Object.keys(oriDataObject)
                        .filter((y: string) => y.split("||")[0] === x)
                        .map((z: string) => oriDataObject[z])
                );
            }, []);

        // // TODO: call api to update
        // console.log(createSymbolList);
        // console.log(updatedSymbolList);
        // console.log(deletedSymbolList);
        let symbolParams = [
                ...updatedSymbolList.updateList.map((x: any) => ({ groupId: x.groupId, serverUno: x.otherServerUno, symbol: x.otherSymbol })),
                ...createSymbolList.map((x: any) => ({ groupId: x.groupId, serverUno: x.otherServerUno, symbol: x.otherSymbol })),
            ],
            symbolDeleteParams = [
                ...updatedSymbolList.oriList.map((x: any) => ({ groupId: x.groupId, serverUno: x.otherServerUno, symbol: x.otherSymbol })),
                ...deletedSymbolList.map((x: any) => ({ groupId: x.groupId, serverUno: x.otherServerUno, symbol: x.otherSymbol })),
            ],
            comparingParams = [...updatedSymbolList.updateList, ...createSymbolList],
            comparingDeleteParams = [...updatedSymbolList.oriList, ...deletedSymbolList];
        if (symbolParams.length > 0) {
            plainAxiosInstance
                .post(APIs.RC_PRICE_SETTINGS.UPDATE_PRICE_ANALYSIS_SYMBOL_SETTING, symbolParams)
                .then((res: any) => {
                    if (res.status === 200) {
                        if (symbolDeleteParams.length > 0) {
                            plainAxiosInstance
                                .delete(APIs.RC_PRICE_SETTINGS.UPDATE_PRICE_ANALYSIS_SYMBOL_SETTING, { data: symbolDeleteParams })
                                .then((res2: any) => {
                                    if (res.status === 200) {
                                        message.success("symbol setting update successfully.", 3);
                                    } else {
                                        message.error(
                                            `Delete symbol setting failed: ${
                                                res2.data.message !== undefined ? res2.data.message : "Unknown reason."
                                            }`,
                                            3
                                        );
                                    }
                                })
                                .catch((error2: any) =>
                                    ErrorCatchValidator(error2, (err2: any) => {
                                        message.error(`Delete symbol setting failed: ${err2}`, 3);
                                    })
                                );
                        } else {
                            message.success("symbol setting update successfully.", 3);
                        }
                    } else {
                        message.error(`Add symbol setting failed: ${res.data.message !== undefined ? res.data.message : "Unknown reason."}`, 3);
                    }
                })
                .catch((error: any) =>
                    ErrorCatchValidator(error, (err: any) => {
                        message.error(`Add symbol setting failed: ${err}`, 3);
                    })
                );
        }

        if (comparingParams.length > 0) {
            plainAxiosInstance
                .post(APIs.RC_PRICE_SETTINGS.GET_PRICE_ANALYSIS_SYMBOL_SETTING_COMPARING, comparingParams)
                .then((res: any) => {
                    if (res.status === 200) {
                        if (comparingDeleteParams.length > 0) {
                            plainAxiosInstance
                                .delete(APIs.RC_PRICE_SETTINGS.GET_PRICE_ANALYSIS_SYMBOL_SETTING_COMPARING, { data: comparingDeleteParams })
                                .then((res2: any) => {
                                    if (res.status === 200) {
                                        message.success("Comparing symbol setting update successfully.", 3);
                                        setRunRefetchDataList(true);
                                    } else {
                                        message.error(
                                            `Delete comparing symbol setting failed: ${
                                                res2.data.message !== undefined ? res2.data.message : "Unknown reason."
                                            }`,
                                            3
                                        );
                                    }
                                })
                                .catch((error2: any) =>
                                    ErrorCatchValidator(error2, (err2: any) => {
                                        message.error(`Delete comparing symbol setting failed: ${err2}`, 3);
                                    })
                                )
                                .finally(() => setIsLoading(false));
                        } else {
                            message.success("Comparing symbol setting update successfully.", 3);
                            setRunRefetchDataList(true);
                        }
                    } else {
                        message.error(
                            `Add comparing symbol setting failed: ${res.data.message !== undefined ? res.data.message : "Unknown reason."}`,
                            3
                        );
                        setIsLoading(false);
                    }
                })
                .catch((error: any) =>
                    ErrorCatchValidator(error, (err: any) => {
                        setIsLoading(false);
                        message.error(`Add comparing symbol setting failed: ${err}`, 3);
                    })
                );
        }
    }, [selectedGroupId, oriData, createSymbolData, deleteSymbolList, updatedSymbolData]);

    const componentCallback: FlexiDataTableCallbackProps = (type, FormData) => {
        switch (type) {
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                setSelectedGroupId(FormData.groupId);
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.CREATE_NEW:
                setEditData(undefined);
                setIsEditModalVisible(true);
                break;
            case CALLBACK_KEY.DO_EDIT:
                setEditData(FormData);
                setIsEditModalVisible(true);
                break;
            case CALLBACK_KEY.DO_DELETE:
                setDeleteSymbolList(prev => [...prev, FormData.symbol]);
                break;
            case CALLBACK_KEY.OTHERS:
                if (FormData === "refresh") {
                    Modal.confirm({
                        title: "Are you sure to reload all group settings?",
                        content: "Notice: After reload all groups your unsaved changes will disappear, please check out.",
                        onOk: () => {
                            setRunRefetchDataList(true);
                        },
                    });
                } else if (FormData === "rollback") {
                    Modal.confirm({
                        title: "Are you sure to rollback all modified configurations?",
                        content: "Notice: After reload configurations your unsaved changes will disappear, please check out.",
                        onOk: () => {
                            setDeleteSymbolList([]);
                            setUpdatedSymbolData({});
                            setCreateSymbolData({});
                        },
                    });
                } else if (FormData === "saveSettings") {
                    Modal.confirm({
                        title: "Are you sure to save all modified configurations?",
                        content: "Notice: After save all of your changes will affect the price alarm outage.",
                        onOk: () => {
                            saveAllSettings();
                        },
                    });
                }
                break;
            default:
                break;
        }
    };

    const processCreateNewSetting = useCallback(
        (data: any) => {
            let baseServerObj = servers.find(x => x.isBaseServer);
            if (baseServerObj !== undefined) {
                setCreateSymbolData(prev => ({
                    ...prev,
                    ...{
                        [data[(baseServerObj as ServersProps).serverUno]]: {
                            ...data,
                            baseServerUno: (baseServerObj as ServersProps).serverUno,
                            baseSymbol: data[(baseServerObj as ServersProps).serverUno],
                            symbol: data[(baseServerObj as ServersProps).serverUno],
                            newAdd: true,
                        },
                    },
                }));
            }
        },
        [servers]
    );

    const getIndividualServerInfo = async (serverNo: number, extra: any) => {
        try {
            const res = await plainAxiosInstance.get(`${APIs.RC_PRICE_SETTINGS.GET_PRICE_ANALYSIS_SYMBOL_SETTING}/${serverNo}`);
            return {
                ...extra,
                serverNo: serverNo,
                symbols: res.status === 200 && res.data && res.data.length > 0 ? res.data.map((x: any) => ({ text: x.symbol, value: x.symbol })) : [],
            };
        } catch (error) {}
        return { ...extra, serverNo: serverNo, symbols: [] };
    };

    const allSettingDetails = useCallback(async () => {
        const result = await Promise.all(
            servers.map(x => getIndividualServerInfo(x.serverUno, { serverName: x.serverDn, isBaseServer: x.isBaseServer }))
        );
        setSymbolInfo(ToObjectWithKey(result, "serverNo"));
        setIsLoading(false);
    }, [servers]);

    const getSymbolSettingsList = useCallback(() => {
        setIsLoading(true);
        // Reset all states -- START --
        setEditData(undefined);
        setIsChangedStatus(false);
        setDeleteSymbolList([]);
        setUpdatedSymbolData({});
        setCreateSymbolData({});
        // Reset all states -- END --

        if (selectedGroupId === undefined) {
            setServers([]);
            setOriData([]);
            setSymbolInfo({});
            setIsLoading(false);
            return;
        }

        plainAxiosInstance.get(`${APIs.RC_PRICE_SETTINGS.UPDATE_PRICE_ANALYSIS_SERVER_SETTING}/${selectedGroupId}`).then((res: any) => {
            setServers(res.status === 200 && res.data && res.data.length > 0 ? res.data : []);
            setRunRefetchServerSymbol(true);
        });
        plainAxiosInstance.get(`${APIs.RC_PRICE_SETTINGS.GET_PRICE_ANALYSIS_SYMBOL_SETTING_COMPARING}/${selectedGroupId}`).then((res: any) => {
            setOriData(res.status === 200 && res.data && res.data.length > 0 ? res.data : []);
        });
    }, [selectedGroupId]);

    const getGroupList = () => {
        plainAxiosInstance.get(`${APIs.RC_PRICE_SETTINGS.UPDATE_PRICE_ANALYSIS_GROUP_SETTING}/enabled`).then((res: any) => {
            if (res.status === 200 && res.data && res.data.length > 0) {
                setAvailableGroups(res.data.map((x: any) => ({ text: x.groupDn, value: x.groupId })));
            }
        });
    };

    useEffect(() => {
        if (runRefetchServerSymbol) {
            allSettingDetails();
            setRunRefetchServerSymbol(false);
        }
    }, [runRefetchServerSymbol]);

    useEffect(() => {
        if (runRefetchDataList) {
            getSymbolSettingsList();
            setRunRefetchDataList(false);
        }
    }, [runRefetchDataList]);

    useEffect(() => {
        getGroupList();
    }, []);

    return (
        <>
            <div className="price-analysis-symbol-settings-container">
                <FlexiDataTable
                    bordered
                    rowKeyProperty="symbol"
                    title=""
                    columns={columns}
                    options={options}
                    dataSource={tableStates.data}
                    callback={componentCallback}
                    loading={isLoading}
                />
            </div>
            <EditSymbolSettingModal
                isModalVisible={isEditModalVisible}
                modalTitle={editData !== undefined ? "Edit Comparing Symbol Setting" : "Add New Comparing Symbol Setting"}
                serverSymbolInfo={symbolInfo}
                data={editData === undefined ? undefined : { ...editData, ...DefaultIfEmpty(updatedSymbolData, editData.symbol, {}) }}
                callback={useCallback(
                    (type: number, data: any) => {
                        switch (type) {
                            case EditSymbolSettingModalCallbackKey.Close:
                                setIsEditModalVisible(false);
                                break;
                            case EditSymbolSettingModalCallbackKey.FormSubmit:
                                if (isEmptyOrNull(data.symbol)) {
                                    processCreateNewSetting(data);
                                } else {
                                    setUpdatedSymbolData(prev => ({ ...prev, ...{ [data.symbol]: data } }));
                                }
                                setIsEditModalVisible(false);
                                break;
                            default:
                                break;
                        }
                    },
                    [processCreateNewSetting]
                )}
            />
        </>
    );
};

export default PriceAnalysisSymbolSettingsPage;
