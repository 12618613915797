import { create } from "zustand";
import { isEqual } from "lodash";
interface ISpreadReportRequest {
    symbol: string | null;
    start: string;
    end: string;
    groups: string[];
    startTime?: string;
    endTime?: string;
}

type SpreadReportState = {
    start: string;
    end: string;
    groups: Record<number, string[]>;
    symbol: string | null;
    spreadReportQuery: ISpreadReportRequest | null;
    queryRecords: any[];
    chartType: "line" | "bar";
    dailyAvg: {
        startDate: string;
        endDate: string;
    };
    averageSpreadAnalysis: {
        start: string;
        end: string;
        startTime: string;
        endTime: string;
        currType: "servers" | "brokers";
        groups: Record<number, string[]>;
        symbol: string | null;
    };
    averageSpreadAnalysisQuery: ISpreadReportRequest | null;
    averageSpreadQueryRecords: any[];
};
type SpreadReportActions = {
    setStart: (start: string) => void;
    setEnd: (end: string) => void;
    setGroups: (kind: number, groups: string[]) => void;
    setSymbol: (symbol: string | null) => void;
    updateSpreadReportQuery: () => void;
    manuallyUpdateSpreadReportQuery: (query: ISpreadReportRequest | null) => void;
    removeQueryRecord: (query: ISpreadReportRequest) => void;
    setChartType: (chartType: "line" | "bar") => void;
    setDailyAvg: ({ startDate, endDate }: { startDate?: string; endDate?: string }) => void;
    setAverageSpreadAnalysis: (averageSpreadAnalysis: {
        start?: string;
        end?: string;
        startTime?: string;
        endTime?: string;
        currType?: "servers" | "brokers";
        groups?: Record<number, string[]>;
        symbol?: string | null;
    }) => void;
    updateAverageSpreadAnalysisQuery: () => void;
    manuallyUpdateAverageSpreadAnalysisQuery: (query: ISpreadReportRequest | null) => void;
    removeAverageSpreadAnalysisQueryRecord: (query: ISpreadReportRequest) => void;
};

const useSpreadReportStore = create<SpreadReportState & SpreadReportActions>(set => ({
    // Initialize your state properties here
    // init start and end to today date
    start: new Date().toISOString().split("T")[0],
    end: new Date().toISOString().split("T")[0],
    groups: { 1: [], 2: [], 4: [] },
    symbol: null,
    spreadReportQuery: null,
    queryRecords: [],
    chartType: "line",
    dailyAvg: {
        startDate: new Date().toISOString().split("T")[0],
        endDate: new Date().toISOString().split("T")[0],
    },
    averageSpreadAnalysis: {
        // result format should be "2024-04-02T15:20", comes with time without seconds
        start: new Date().toISOString().split("T")[0],
        end: new Date().toISOString().split("T")[0],
        startTime: "00:00",
        endTime: "23:59",
        currType: "servers",
        groups: { 1: [], 2: [], 4: [] },
        symbol: null,
    },
    averageSpreadAnalysisQuery: null,
    averageSpreadQueryRecords: [],
    // Implement your actions here
    setStart: (start: string) => set({ start }),
    setEnd: (end: string) => set({ end }),
    setGroups: (kind: number, groups: string[]) =>
        set(state => {
            const newGroups = { ...state.groups };
            newGroups[kind] = groups;
            return { groups: newGroups };
        }),
    setSymbol: (symbol: string | null) => set({ symbol }),
    setChartType: (chartType: "line" | "bar") => set({ chartType }),
    updateSpreadReportQuery: () =>
        set(state => {
            const { start, end, groups, symbol } = state;
            const finalGroups = [...groups[1], ...groups[2], ...groups[4]] || [];
            // console.log("final groups", finalGroups);
            const newReportQuery = { start, end, groups: [...finalGroups], symbol, originalGroups: { ...groups } };
            // check if queryRecords already has this query, use lodash isEqual
            // console.log("state.queryRecords", state.queryRecords);
            const findQueryRecord = state.queryRecords.find(record => isEqual(record, newReportQuery));
            // if already has same query, just update spreadReportQuery
            if (findQueryRecord) {
                // console.log("found the same query");
                return { spreadReportQuery: { ...newReportQuery } };
            }
            // console.log("unique");
            return {
                spreadReportQuery: { ...newReportQuery },
                // push new query to queryRecords
                queryRecords: [...state.queryRecords, { ...newReportQuery }],
            };
        }),
    manuallyUpdateSpreadReportQuery: (query: ISpreadReportRequest | null) => set({ spreadReportQuery: query }),
    removeQueryRecord: (query: ISpreadReportRequest) =>
        set(state => {
            const newQueryRecords = state.queryRecords.filter(record => !isEqual(record, query));
            return { queryRecords: newQueryRecords };
        }),
    setDailyAvg: ({ startDate, endDate }: { startDate?: string; endDate?: string }) =>
        set(state => {
            return {
                dailyAvg: { startDate: startDate || state.dailyAvg.startDate, endDate: endDate || state.dailyAvg.endDate },
            };
        }),
    setAverageSpreadAnalysis: (averageSpreadAnalysis: {
        start?: string;
        end?: string;
        startTime?: string;
        endTime?: string;
        currType?: "servers" | "brokers";
        groups?: Record<number, string[]>;
        symbol?: string | null;
    }) =>
        set(state => {
            return {
                averageSpreadAnalysis: {
                    start: averageSpreadAnalysis.start !== undefined ? averageSpreadAnalysis.start : state.averageSpreadAnalysis.start,
                    end: averageSpreadAnalysis.end !== undefined ? averageSpreadAnalysis.end : state.averageSpreadAnalysis.end,
                    startTime:
                        averageSpreadAnalysis.startTime !== undefined ? averageSpreadAnalysis.startTime : state.averageSpreadAnalysis.startTime,
                    endTime: averageSpreadAnalysis.endTime !== undefined ? averageSpreadAnalysis.endTime : state.averageSpreadAnalysis.endTime,
                    currType: averageSpreadAnalysis.currType !== undefined ? averageSpreadAnalysis.currType : state.averageSpreadAnalysis.currType,
                    groups: averageSpreadAnalysis.groups !== undefined ? averageSpreadAnalysis.groups : state.averageSpreadAnalysis.groups,
                    symbol: averageSpreadAnalysis.symbol !== undefined ? averageSpreadAnalysis.symbol : state.averageSpreadAnalysis.symbol,
                },
            };
        }),
    updateAverageSpreadAnalysisQuery: () =>
        set(state => {
            const { start, end, startTime, endTime, groups, symbol, currType } = state.averageSpreadAnalysis;
            // console.log(start, end, groups, symbol);
            const finalGroups = [...groups[1], ...groups[2], ...groups[4]] || [];
            // console.log("final groups", ...groups[2], ...groups[4]);
            const newReportQuery = { start, end, startTime, endTime, groups: [...finalGroups], symbol, originalGroups: { ...groups }, currType };
            // check if queryRecords already has this query, use lodash isEqual
            // console.log("newReportQuery", newReportQuery);
            const findQueryRecord = state.averageSpreadQueryRecords.find(record => isEqual(record, newReportQuery));
            // if already has same query, just update spreadReportQuery
            if (findQueryRecord) {
                // console.log("found the same query");
                return { averageSpreadAnalysisQuery: { ...newReportQuery } };
            }
            // console.log("unique");
            return {
                averageSpreadAnalysisQuery: { ...newReportQuery },
                // push new query to queryRecords
                averageSpreadQueryRecords: [...state.averageSpreadQueryRecords, { ...newReportQuery }],
            };
        }),
    manuallyUpdateAverageSpreadAnalysisQuery: (query: ISpreadReportRequest | null) => set({ averageSpreadAnalysisQuery: query }),
    removeAverageSpreadAnalysisQueryRecord: (query: ISpreadReportRequest) =>
        set(state => {
            const newQueryRecords = state.averageSpreadQueryRecords.filter(record => !isEqual(record, query));
            return { averageSpreadQueryRecords: newQueryRecords };
        }),
}));

export default useSpreadReportStore;
