import CardBox from "@/components/Common/CardBox";
import FlexiDataTable from "@/components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { CustomPaginationProps, KeyValuePair, FlexiDataTableOptionsProps, FlexiDataTableCallbackProps } from "@/constants/type";
import ReportBatchModal, { ReportBatchModalCallbackKey } from "@/pages/ReportingModule/ReportBatch";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { currencyRender, DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { isEmptyOrNull } from "@/utils/string";
import { ContainerOutlined, DownloadOutlined } from "@ant-design/icons";
import { message } from "antd";
import moment from "moment";
import { useState, useCallback, useMemo, useEffect } from "react";

export interface DividendRecordProcessPageProps {}

interface DividendRecordProps {
    serverId: number;
    server: string;
    separateDate: string;
    login: number;
    symbol: string;
    group: string;
    subAccount: number;
    cleanSymbol: string;
    position: number;
    contractSize: number;
    baseDividend: number;
    eodPrice: number;
    status: number;
    dividend: number;
    digits: number;
    createDate: string;
    formatCreateDate: string;
    updateDate: string | null;
}

const DividendRecordProcessPage = (props: DividendRecordProcessPageProps) => {
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(true);
    const [isReportModalVisible, setIsReportModalVisible] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<DividendRecordProps[]>([]);
    const [pagination, setPagination] = useState<CustomPaginationProps>({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [filterParams, setFilterParams] = useState<any>({
        serverId: "",
        separateDate: moment(),
        symbol: "",
    });
    const [sorting, setSorting] = useState<string>("");
    const [servers, setServers] = useState<KeyValuePair[]>([]);

    const exportCSV = useCallback(() => {
        let params = {
            ...filterParams,
            separateDate: moment(filterParams.separateDate).format("YYYYMMDD"),
        };

        plainAxiosInstance
            .post(APIs.RC_DIVIDEND.POST_DOWNLOAD, params)
            .then((res: any) => {
                if (res.data === "success") {
                    message.success("Add to batch successed. Please download from【Report Listing】", 3);
                } else {
                    message.error(`Add to batch failed`, 3);
                }
            })
            .catch((error: any) => message.error(`Add to batch failed: "${error.message}"`, 3));
    }, [filterParams]);

    const columns: any[] = useMemo(
        () => [
            {
                title: "Server",
                dataIndex: "serverId",
                key: "serverId",
                options: {
                    visible: false,
                    filter: {
                        type: ComponentType.dropdown,
                        value: servers,
                    },
                },
            },
            {
                title: "Separate Date",
                dataIndex: "separateDate",
                key: "separateDate",
                options: {
                    visible: false,
                    filter: {
                        type: ComponentType.date,
                        value: servers,
                        dateFormat: "YYYY-MM-DD",
                        rules: [{ required: true, message: REQUIRED_FIELD }],
                    },
                },
            },
            DTColProps.Small({
                title: "Server",
                dataIndex: "server",
                key: "server",
                sorter: (a: any, b: any) => a.server - b.server,
            }),
            DTColProps.Small({
                title: "Ex-dividend date",
                dataIndex: "separateDate",
                key: "separateDate",
            }),
            DTColProps.Small({
                title: "Login",
                dataIndex: "login",
                key: "login",
            }),
            DTColProps.Small({
                title: "Symbol",
                dataIndex: "symbol",
                key: "symbol",
                sorter: (a: any, b: any) => a.symbol - b.symbol,
                options: {
                    filter: {
                        type: ComponentType.text,
                        value: "",
                    },
                },
            }),
            DTColProps.Middle({
                title: "Group",
                dataIndex: "group",
                key: "group",
            }),
            DTColProps.XSmall(
                {
                    title: "Sub Account",
                    dataIndex: "subAccount",
                    key: "subAccount",
                    render: (subAccount: number) => (subAccount === 1 ? "Yes" : "No"),
                },
                ["text-center"]
            ),
            DTColProps.Small({
                title: "Clean Symbol",
                dataIndex: "cleanSymbol",
                key: "cleanSymbol",
            }),
            DTColProps.SCurrency(
                {
                    title: "Position",
                    dataIndex: "position",
                    key: "position",
                },
                [],
                -1
            ),
            DTColProps.XSCurrency(
                {
                    title: "Contract Size",
                    dataIndex: "contractSize",
                    key: "contractSize",
                },
                [],
                -1
            ),
            DTColProps.SCurrency(
                {
                    title: "Base Dividend",
                    dataIndex: "baseDividend",
                    key: "baseDividend",
                },
                [],
                -1
            ),
            DTColProps.SCurrency({
                title: "Eod Price",
                dataIndex: "eodPrice",
                key: "eodPrice",
                render: (dividend: number) => currencyRender(dividend.toFixed(5), 5),
            }),
            DTColProps.XSmall(
                {
                    title: "Status",
                    dataIndex: "status",
                    key: "status",
                    render: (status: number) => (status === 1 ? "Received" : "Unreceived"),
                },
                ["text-center"]
            ),
            DTColProps.SCurrency(
                {
                    title: "Dividend",
                    dataIndex: "dividend",
                    key: "dividend",
                },
                [],
                -1
            ),
            DTColProps.XSmall(
                {
                    title: "Digits",
                    dataIndex: "digits",
                    key: "digits",
                },
                ["text-center"]
            ),
            DTColProps.Small({
                title: "Create Date",
                dataIndex: "formatCreateDate",
                key: "formatCreateDate",
            }),
        ],
        [servers]
    );

    const options: FlexiDataTableOptionsProps = {
        serverFiltering: true,
        extraButtons: [
            { text: "Report Listing", value: "reportlist", icon: <ContainerOutlined /> },
            { text: "Download Report", value: "downloadreport", icon: <DownloadOutlined /> },
        ],
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, FormData) => {
        switch (type) {
            case CALLBACK_KEY.HANDLE_PAGINATION_SORTING:
                FormData.sorter.order === undefined
                    ? setSorting("")
                    : setSorting(
                          FormData.sorter.column && FormData.sorter.order
                              ? `${FormData.sorter.column.key},${FormData.sorter.order === "ascend" ? "asc" : "desc"}`
                              : ""
                      );
                setPagination(prev => ({ ...prev, current: FormData.pagination.current, pageSize: FormData.pagination.pageSize }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                let filterParams: any = {};
                Object.keys(FormData)
                    .filter(x => FormData[x] && FormData[x].toString().length > 0)
                    .map(x => {
                        if (x === "separateDate") {
                            filterParams[x] = moment(FormData[x]).format("YYYYMMDD");
                        } else {
                            filterParams[x] = FormData[x];
                        }
                        return x;
                    });
                setFilterParams(filterParams);
                setPagination(prev => ({ ...prev, current: 1 }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.OTHERS:
                if (FormData === "reportlist") {
                    setIsReportModalVisible(true);
                } else if (FormData === "downloadreport") {
                    exportCSV();
                }
                break;
            default:
                break;
        }
    };

    const getDividendRecordList = useCallback(() => {
        setIsLoading(true);
        let params = {
            page: pagination.current,
            rows: pagination.pageSize,
            ...filterParams,
            ...(!isEmptyOrNull(sorting) &&
                sorting.split(",").reduce((sortObj: any, x: string, idx: number) => {
                    if (idx === 0) {
                        sortObj["sortBy"] = [x];
                    } else {
                        sortObj["sortDesc"] = [x === "asc" ? false : true];
                    }
                    return sortObj;
                }, {})),
        };

        plainAxiosInstance
            .post(APIs.RC_DIVIDEND.POST_DIVIDEND, params)
            .then((res: any) => {
                if (res.data.resultList && res.data.resultList.length > 0) {
                    setData(res.data.resultList.map((x: any) => ({ ...x, id: `${x.createDate}-${x.login}` })));
                    setPagination(prev => ({ ...prev, total: res.data.total }));
                } else {
                    setData([]);
                    setPagination(prev => ({ ...prev, total: 0 }));
                }
            })
            .catch(error => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("dividend record", SUCCESS_FAILED.FAILED_LOAD_DATA, err)))
            .finally(() => setIsLoading(false));
    }, [filterParams, pagination, sorting]);

    const getConfig = () => {
        plainAxiosInstance.get(APIs.RC_DIVIDEND.GET_SERVERS, {}).then((res: any) => {
            setServers(res.data.map((x: any) => ({ text: x.name, value: x.id })));
        });
    };

    useEffect(() => {
        if (runRefetchDataList) {
            getDividendRecordList();
            setRunRefetchDataList(false);
        }
    }, [runRefetchDataList]);

    useEffect(() => {
        getConfig();
    }, []);

    return (
        <>
            <div className="dividend-record-container">
                <CardBox title={"Dividend Record"}>
                    <FlexiDataTable
                        rowKeyProperty="id"
                        title=""
                        columns={columns}
                        options={options}
                        dataSource={data}
                        callback={componentCallback}
                        loading={isLoading}
                        pagination={pagination}
                        serverSide={true}
                        filterInitialValue={filterParams}
                    />
                </CardBox>
            </div>
            <ReportBatchModal
                isModalVisible={isReportModalVisible}
                modalTitle={"Download Report"}
                callback={(type: number) => {
                    switch (type) {
                        case ReportBatchModalCallbackKey.Close:
                            setIsReportModalVisible(false);
                            break;
                        default:
                            break;
                    }
                }}
                filterCallback={(record: any) => record.batchName === "DividendSeparateProcess"}
            />
        </>
    );
};

export default DividendRecordProcessPage;
