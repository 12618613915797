import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useEffect, useState } from "react";
import { SUCCESS_FAILED } from "@/constants";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";

type CreateSettingsModalProps = {
    open: boolean;
    onModalCancel: () => void;
    onModalOk: () => void;
    data?: any;
};

const CommentModal = ({ open, onModalCancel, onModalOk, data }: CreateSettingsModalProps) => {
    const [comment, setComment] = useState<string>(data?.comment ?? "");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setComment(data?.comment ?? "");
    }, [data]);

    function onCancelForm() {
        setComment("");
        onModalCancel();
    }

    async function onSubmitForm() {
        const params = {
            serverUno: data.serverUno,
            login: data.login,
            createTime: data.createTime,
            comment: comment,
        };

        return new Promise<void>((resolve, reject) => {
            plainAxiosInstance
                .put(APIs.RC_LINKED_ACCOUNT_RECORD.POST_UPDATE_EQUITY_MONITOR_COMMENT, params)
                .then((res: any) => {
                    ErrorMessageHandler("Comment", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                    resolve();
                    onModalOk();
                    setComment("");
                })
                .catch((error: any) => {
                    reject(error);
                });
        }).catch(error => {
            ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("Comment", SUCCESS_FAILED.FAILED_UPDATE_DATA, err));
        });
    }

    function onOkConfirm() {
        Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            title: `Are you sure you want to edit comment?`,
            width: "30%",
            onOk() {
                return onSubmitForm();
            },
            onCancel() {},
        });
    }

    return (
        <Modal width="50vw" destroyOnClose maskClosable={false} title={"Edit Comment"} open={open} onCancel={onCancelForm} onOk={onOkConfirm}>
            <Row>
                <div>Comment:</div>
                <TextArea disabled={false} value={comment} onChange={e => setComment(e.target.value)} />
            </Row>
        </Modal>
    );
};

export default CommentModal;
