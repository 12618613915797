import { Column, Datum } from "@ant-design/charts";
import { BarChartOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { SUCCESS_FAILED } from "../../../../../constants";
import { SymbolAnalysisFilterParams } from "../../../../../constants/type";
import { apiRequest, APIs } from "../../../../../services/apiConfig";
import { currencyRender, ErrorCatchValidator, ErrorMessageHandler } from "../../../../../utils/Common";
import { ProfileInfoProps } from "../../viewProfile";
import ChartPanel from "../chartPanel";
import EmptyData from "../../../../../components/Common/Empty";
import CustomSkeleton from "../../../../../components/Common/Skeleton";

export interface TicketSizeDistrubutionSummaryProps extends ProfileInfoProps {
    forPrint?: boolean;
    selectedSymbol?: string;
    params: SymbolAnalysisFilterParams;
}

const TicketSizeDistrubutionSummary = (props: TicketSizeDistrubutionSummaryProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<any>([]);

    useEffect(() => {
        if (props.params.symbols === null || props.params.symbols === undefined || props.params.symbols.length === 0) {
            setData([]);
        } else if (
            (props.params.dateTo === "" || props.params.dateTo === undefined) &&
            (props.params.dateFrom === "" || props.params.dateFrom === undefined)
        ) {
            delete props.params.dateTo;
            delete props.params.dateFrom;

            setIsLoading(true);
            apiRequest(APIs.GET_SOURCE_DISTRIBUTION_SUMMARY, {
                ...props.params,
                analysisType: 3,
            })
                .then((data: any) => {
                    if (data.stats && data.stats.length > 0) {
                        if (props.selectedSymbol) {
                            let newData = data.stats.filter((currStat: any) => currStat.symbol === props.selectedSymbol);
                            newData.sort((a: any, b: any) => a.index - b.index);
                            setData(newData);
                        }
                    } else {
                        setData([]);
                    }
                })
                .catch((error) =>
                    ErrorCatchValidator(error, (err: any) =>
                        ErrorMessageHandler(`ticket size distribution summary for ${props.selectedSymbol}`, SUCCESS_FAILED.FAILED_LOAD_DATA, err)
                    )
                )
                .finally(() => setIsLoading(false));
        } else {
            setIsLoading(true);
            apiRequest(APIs.GET_SOURCE_DISTRIBUTION_SUMMARY, {
                ...props.params,
                analysisType: 3,
            })
                .then((data: any) => {
                    if (data.stats && data.stats.length > 0) {
                        if (props.selectedSymbol) {
                            let newData = data.stats.filter((currStat: any) => currStat.symbol === props.selectedSymbol);
                            newData.sort((a: any, b: any) => a.index - b.index);
                            setData(newData);
                        }
                    } else {
                        setData([]);
                    }
                })
                .catch((error) =>
                    ErrorCatchValidator(error, (err: any) =>
                        ErrorMessageHandler(`ticket size distribution summary for ${props.selectedSymbol}`, SUCCESS_FAILED.FAILED_LOAD_DATA, err)
                    )
                )
                .finally(() => setIsLoading(false));
        }
        return () => {};
    }, [props.params]);

    return (
        <>
            {isLoading ? (
                <ChartPanel
                    forPrint={props.forPrint}
                    type={1}
                    icon={<BarChartOutlined />}
                    title={"Ticket Size Distribution"}
                    subTitle={"Overall ticket size distribution for current symbol"}
                >
                    <CustomSkeleton rows={10} />
                </ChartPanel>
            ) : (
                <ChartPanel
                    forPrint={props.forPrint}
                    type={1}
                    icon={<BarChartOutlined />}
                    title={"Ticket Size Distribution"}
                    subTitle={"Overall ticket size distribution for current symbol"}
                >
                    <div className="chart">
                        {data?.length > 0 ? (
                            <Column
                                {...{
                                    data,
                                    appendPadding: 20,
                                    xField: "key",
                                    yField: "value",
                                    ...(props.forPrint
                                        ? {
                                              xAxis: {
                                                  label: {
                                                      autoRotate: true,
                                                  },
                                                  tickLine: {
                                                      alignTick: true,
                                                  },
                                              },
                                          }
                                        : {
                                              xAxis: {
                                                  label: {
                                                      autoRotate: true,
                                                  },
                                                  tickLine: {
                                                      alignTick: true,
                                                  },
                                              },
                                              slider: {
                                                  start: 0,
                                                  end: 1,
                                              },
                                          }),
                                    yAxis: {
                                        label: {
                                            formatter: (v: any) => currencyRender(v, -1),
                                        },
                                    },
                                    label: {
                                        position: "middle",
                                        formatter: (val: any) => currencyRender(val.value),
                                    },
                                    tooltip: {
                                        formatter: (datum: Datum) => {
                                            return {
                                                name: `Total count of trade(s)`,
                                                value: currencyRender(datum.value, -1),
                                            };
                                        },
                                    },
                                }}
                            />
                        ) : (
                            <>
                                <EmptyData />
                            </>
                        )}
                    </div>
                </ChartPanel>
            )}
        </>
    );
};

export default TicketSizeDistrubutionSummary;
