import { Col, Form, Row } from "antd";
import React, { useEffect, useState } from "react";
import { FormComponent } from "../../../components/FormComponent";
import { ComponentType } from "../../../constants";
import { MetricGroup, SymbolAnalysisFilterParams } from "../../../constants/type";
import { findObjectKeys } from "../../../utils/object";
import AccountSymbolSummaryPanel from "./Components/SymbolAnalysisTab/accountSymbolSummaryPanel";
import DailyPnlCurrSymbolChart from "./Components/SymbolAnalysisTab/dailyPnlCurrSymbolChart";
import TicketSizeDistrubutionSummary from "./Components/SymbolAnalysisTab/ticketSizeDistributionSummary";
import { ProfileInfoProps } from "./viewProfile";
import DailyPNLSummaryCurrSymbolChart from "./Components/SymbolAnalysisTab/dailyPnlSummaryCurrSymbolChart";

export interface SymbolAnalysisProps extends ProfileInfoProps {
    metricCategories: MetricGroup[];
    symbols: string[];
}

const SymbolAnalysis = (props: SymbolAnalysisProps) => {
    const [filterForm] = Form.useForm();
    const [filterParams, setFilterParams] = useState<SymbolAnalysisFilterParams>({
        accountId: props.accountId,
        serverId: props.serverId,
        brandId: props.brandId,
        symbols: props.symbols[0] ? [props.symbols[0]] : [],
        dateTo: "",
        dateFrom: "",
    });
    const [symbols] = useState<string[]>([...props.symbols]);
    const [selectedSymbol, setSelectedSymbol] = useState<string>(props.symbols[0] || "");

    const onFilterValueChanged = (values: any) => {
        let filterData = { ...filterParams };

        if (findObjectKeys(values, ["symbol"])) {
            filterData["symbols"] = [values["symbol"]];
            setSelectedSymbol(values["symbol"]);
        } else if (findObjectKeys(values, ["period"])) {
            try {
                if (values["period"] !== null) {
                    filterData["dateFrom"] = values["period"][0].toISOString();
                    filterData["dateTo"] = values["period"][1].toISOString();
                } else {
                    delete filterData["dateFrom"];
                    delete filterData["dateTo"];
                }
            } catch (error) {
                delete filterData["dateFrom"];
                delete filterData["dateTo"];
            }
        }
        setFilterParams(filterData);
    };

    useEffect(() => {
        filterForm.setFieldValue("symbol", props.symbols[0]);
        return () => {};
    }, []);

    const paddingTopStyle: any = { paddingTop: "2.828vh" };

    return (
        <>
            <div className="main-panel">
                <Form
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 19 }}
                    labelAlign={"left"}
                    form={filterForm}
                    layout="horizontal"
                    onValuesChange={onFilterValueChanged}
                >
                    <Row gutter={24} style={{ width: "100%" }}>
                        <Col span={12}>
                            <FormComponent
                                label="Symbol"
                                name="symbol"
                                extra={{
                                    type: ComponentType.dropdown,
                                    value: symbols.map((x) => ({ text: x, value: x })),
                                    inputProps: {
                                        allowClear: false,
                                    },
                                }}
                            />
                        </Col>
                        <Col span={12}>
                            <FormComponent
                                label="Period"
                                name="period"
                                extra={{
                                    type: ComponentType.daterange,
                                    value: [],
                                    inputProps: {
                                        format: "YYYY-MM-DD",
                                    },
                                }}
                            />
                        </Col>
                    </Row>
                </Form>
                <div className="daily-pnl-symbol-chart-container" style={paddingTopStyle}>
                    <DailyPnlCurrSymbolChart {...props} params={filterParams} selectedSymbol={selectedSymbol} />
                </div>
                <div className="daily-pnl-symbol-chart-container" style={paddingTopStyle}>
                    <DailyPNLSummaryCurrSymbolChart {...props} params={filterParams} selectedSymbol={selectedSymbol} />
                </div>
                <div className="ticket-size-distribution-summary-container" style={paddingTopStyle}>
                    <TicketSizeDistrubutionSummary {...props} params={filterParams} selectedSymbol={selectedSymbol} />
                </div>
            </div>
            <div className="sub-panel">
                <div className="account-symbol-summary-panel-container" style={paddingTopStyle}>
                    <AccountSymbolSummaryPanel
                        {...props}
                        params={filterParams}
                        metricCategories={props.metricCategories}
                        selectedSymbol={selectedSymbol}
                    />
                </div>
            </div>
        </>
    );
};

export default SymbolAnalysis;
