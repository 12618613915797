import { Form, Modal, Timeline } from "antd";
import { ALARM_DASHBOARD_ITEM_CALLBACK_KEY, SUCCESS_FAILED } from "../../../../constants";
import EmptyData from "../../../../components/Common/Empty";
import AlarmDashboardTimelineItem, { TimelineItemProps } from "./alarmDashboardTimelineItem";
import { useCallback, useMemo, useState } from "react";
import { DefaultIfEmpty, objectRemoveProps } from "../../../../utils/object";
import { APIs, apiRequest } from "../../../../services/apiConfig";
import { getAlarmItemFeatureModalProps } from "./alarmDashboardContentComponent";
import { ModalProps, callbackParams, KeyValuePair } from "../../../../constants/type";
import { ErrorCatchValidator, ErrorMessageHandler } from "../../../../utils/Common";
import { isEmptyOrNull } from "../../../../utils/string";
import { ToObjectWithKey } from "../../../../utils/array";

export interface AlarmDashboardUnAssignedPanelProps {
    data: TimelineItemProps[];
    callback: (type: ALARM_DASHBOARD_ITEM_CALLBACK_KEY, data: any) => void;
    utcOffset: number | undefined;
    isLoading: boolean;
    mappingData: any;
    lpMappingData: any;
    statusObject: any;
    userOptions: KeyValuePair[];
    editable: boolean;
}

const AlarmDashboardUnAssignedPanel = (props: AlarmDashboardUnAssignedPanelProps) => {
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [callbackParams, setCallbackParams] = useState<callbackParams | undefined>(undefined);
    const [commentForm] = Form.useForm();

    const timelineItemCallback = (type: ALARM_DASHBOARD_ITEM_CALLBACK_KEY, data: TimelineItemProps) => {
        switch (type) {
            case ALARM_DASHBOARD_ITEM_CALLBACK_KEY.COMMENT_HISTORICAL:
                setCallbackParams({ type, data });
                setIsModalVisible(true);
                break;
            case ALARM_DASHBOARD_ITEM_CALLBACK_KEY.SEVERITY_PROGRESS:
                setCallbackParams({ type, data });
                setIsModalVisible(true);
                break;
            case ALARM_DASHBOARD_ITEM_CALLBACK_KEY.LEAVE_COMMENT:
                commentForm.setFieldsValue({
                    clientUsedCommentType: "",
                    mode: 1,
                    alarmEventId: data.alarmEventId,
                    correlationHashKey: data.correlationHashKey,
                    moduleCode: data.moduleCode,
                    statusCode: "",
                    comment: "",
                });
                setCallbackParams({ type, data });
                setIsModalVisible(true);
                break;
            case ALARM_DASHBOARD_ITEM_CALLBACK_KEY.TASK_ASSIGN:
                commentForm.setFieldsValue({ alarmEventId: data.alarmEventId, targetDueDateUtc: "", assigneeId: "" });
                setCallbackParams({ type, data });
                setIsModalVisible(true);
                break;
        }
    };

    const updateCommentAndStatus = useCallback(
        (data: any) => {
            let statusCodeKeyObj: any = ToObjectWithKey(props.statusObject[data.moduleCode], "value", "isConsiderCaseClosed"),
                refreshNeeded = DefaultIfEmpty(statusCodeKeyObj, data.statusCode, false);
            apiRequest(APIs.UPDATE_ALARM_STATUS, data)
                .then(res => {
                    ErrorMessageHandler("alarm comment", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                })
                .catch(error =>
                    ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("alarm comment", SUCCESS_FAILED.FAILED_UPDATE_DATA, err))
                )
                .finally(() => refreshNeeded && props.callback && props.callback(ALARM_DASHBOARD_ITEM_CALLBACK_KEY.REFRESH_LIST, {}));
        },
        [props.statusObject, props.callback]
    );

    const modalProps: ModalProps = useMemo(() => {
        let returnParams: ModalProps = getAlarmItemFeatureModalProps(
            props.utcOffset,
            callbackParams,
            props.statusObject,
            props.mappingData,
            props.lpMappingData,
            props.userOptions,
            commentForm
        );
        if (callbackParams !== undefined) {
            switch (callbackParams.type) {
                case ALARM_DASHBOARD_ITEM_CALLBACK_KEY.LEAVE_COMMENT:
                    returnParams.extraModalProps = {
                        ...returnParams.extraModalProps,
                        onOk: () => {
                            commentForm
                                .validateFields()
                                .then(values => {
                                    if (values.mode === 1) {
                                        updateCommentAndStatus(objectRemoveProps(values, ["clientUsedCommentType", "mode", "correlationHashKey"]));
                                    } else if (values.mode === 2) {
                                        updateCommentAndStatus(objectRemoveProps(values, ["clientUsedCommentType", "mode", "alarmEventId"]));
                                    } else if (values.mode === 3) {
                                        updateCommentAndStatus(objectRemoveProps(values, ["clientUsedCommentType", "mode"]));
                                    }
                                    setIsModalVisible(false);
                                    commentForm.resetFields();
                                })
                                .catch(err => console.log(err));
                        },
                    };
                    break;
                case ALARM_DASHBOARD_ITEM_CALLBACK_KEY.TASK_ASSIGN:
                    returnParams.extraModalProps = {
                        ...returnParams.extraModalProps,
                        onOk: () => {
                            commentForm
                                .validateFields()
                                .then(values => {
                                    assignTaskApiRequest(values);
                                    setIsModalVisible(false);
                                    commentForm.resetFields();
                                })
                                .catch(err => console.log(err));
                        },
                    };
                    break;
            }
        }

        return returnParams;
    }, [props.statusObject, props.mappingData, props.lpMappingData, props.userOptions, updateCommentAndStatus, callbackParams]);

    const alarmItemData = useMemo(() => {
        return props.data;
    }, [props.data]);

    const assignTaskApiRequest = (data: any) => {
        if (isEmptyOrNull(data.targetDueDateUtc)) {
            delete data.targetDueDateUtc;
        }

        apiRequest(APIs.UPDATE_ALARM_ASSIGN_TASK, data)
            .then(res => {
                ErrorMessageHandler("task assign", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
            })
            .catch(error => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("task assign", SUCCESS_FAILED.FAILED_UPDATE_DATA, err)))
            .finally(() => props.callback && props.callback(ALARM_DASHBOARD_ITEM_CALLBACK_KEY.REFRESH_LIST, {}));
    };

    return (
        <>
            <div className="title">Alarm Summary - Pending List</div>
            <div className="timeline-container nice-scrollbar">
                {alarmItemData.length > 0 ? (
                    <Timeline>
                        {alarmItemData.map((x: TimelineItemProps, index: number) => (
                            <Timeline.Item key={`aldsb-pl-i-${index}`}>
                                <AlarmDashboardTimelineItem
                                    data={x}
                                    callback={timelineItemCallback}
                                    utcOffset={props.utcOffset}
                                    mappingData={props.mappingData}
                                    lpMappingData={props.lpMappingData}
                                    editable={props.editable}
                                />
                            </Timeline.Item>
                        ))}
                    </Timeline>
                ) : (
                    <EmptyData />
                )}
            </div>
            <Modal
                maskClosable={false}
                open={isModalVisible}
                destroyOnClose={true}
                wrapClassName="alarmdashboard-unassigned-item-modal-popover"
                onCancel={() => {
                    setIsModalVisible(false);
                }}
                {...(modalProps.extraModalProps && modalProps.extraModalProps)}
                style={{ top: 20 }}
            >
                {modalProps.component}
            </Modal>
        </>
    );
};

export default AlarmDashboardUnAssignedPanel;
