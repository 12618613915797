import { Descriptions } from "antd";
import cronstrue from "cronstrue";
import { useEffect, useState } from "react";
import CardBox from "../../../components/Common/CardBox";
import FlexiDataTable from "../../../components/FlexiDataTable";
import { useGetReportJobsQuery } from "../../../store/apis/reportJobs";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";
import "./JobSchedulers.less";
import AuthHelper, { AuthKeys } from "../../../helpers/authHelper";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "../../../constants";
import { FlexiDataTableCallbackProps, FlexiDataTableOptionsProps } from "../../../constants/type";
import { apiRequest, APIs } from "../../../services/apiConfig";

function JobSchedulers() {
    const { data, isLoading, refetch } = useGetReportJobsQuery({});
    const [, setIsBtnLoading] = useState<boolean>(false);

    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.ADMIN_SCHEDULER_EDIT);

    useEffect(() => {
        refetch();
        return () => {};
    }, []);

    const columns = [
        DTColProps.XSmall({
            title: "Report ID",
            dataIndex: "reportId",
            key: "reportId",
            fixed: "left",
            sorter: (a: any, b: any) => a.reportId - b.reportId,
        }),

        DTColProps.Middle({
            title: "Report Name",
            dataIndex: "reportName",
            key: "reportName",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),

        DTColProps.Middle({
            title: "Job Key",
            dataIndex: "jobKey",
            key: "jobKey",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
    ];

    const getExpandedRowRender = (record: any) => {
        return (
            <div>
                <Descriptions title="" layout="vertical" bordered column={5} className="job-scheduler-expanded-row-description-item">
                    <Descriptions.Item label="Job ID">
                        <ul>
                            {record.triggers.map((t: any, index: number) => (
                                <li key={index}>{t.jobId}</li>
                            ))}
                        </ul>
                    </Descriptions.Item>
                    <Descriptions.Item label="Trigger Key">
                        <ul>
                            {record.triggers.map((t: any, index: number) => (
                                <li key={index}>{t.triggerKey}</li>
                            ))}
                        </ul>
                    </Descriptions.Item>
                    <Descriptions.Item label="Cron Expression">
                        <ul>
                            {record.triggers.map((t: any, index: number) => (
                                <li key={index}>{cronstrue.toString(t.cronExpression)}</li>
                            ))}
                        </ul>
                    </Descriptions.Item>
                    <Descriptions.Item label="Time Zone ID">
                        <ul>
                            {record.triggers.map((t: any, index: number) => (
                                <li key={index}>{t.timeZoneId}</li>
                            ))}
                        </ul>
                    </Descriptions.Item>
                    <Descriptions.Item label="Snapshot">
                        <ul>
                            {record.triggers.map((t: any, index: number) => (
                                <li key={index}>{t.snapshot.toString()}</li>
                            ))}
                        </ul>
                    </Descriptions.Item>
                </Descriptions>
            </div>
        );
    };

    const options: FlexiDataTableOptionsProps = {
        resync: enableUpdate,
        expandable: { expandedRowRender: getExpandedRowRender },
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, FormData) => {
        switch (type) {
            case CALLBACK_KEY.RESYNC_JOB_SCHEDULER:
                setIsBtnLoading(true);
                apiRequest(APIs.DROP_AND_RESYNC_REPORT_JOB_SCHEDULERS, {})
                    .then((res) => ErrorMessageHandler("Drop and resync all report jobs", SUCCESS_FAILED.SUCCESS_UPDATE_DATA))
                    .catch((error) =>
                        ErrorCatchValidator(error, (err: any) =>
                            ErrorMessageHandler("during resync. Please try again.", SUCCESS_FAILED.FAILED_UPDATE_DATA, err)
                        )
                    )
                    .finally(() => setIsBtnLoading(false));
                break;
            default:
                break;
        }
    };

    return (
        <CardBox title={"Job Schedulers"}>
            <FlexiDataTable
                title=""
                columns={columns}
                dataSource={data?.data ?? []}
                options={options}
                callback={componentCallback}
                rowKeyProperty={"reportId"}
                loading={isLoading}
            />
        </CardBox>
    );
}

export default JobSchedulers;
