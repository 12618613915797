import { BaseQueryFn } from "@reduxjs/toolkit/dist/query";
import { AxiosRequestConfig, AxiosError } from "axios";
import { apiRequest } from "../services/apiConfig";
import { plainAxiosInstance } from "../services/axiosSetup";
export const axiosBaseQuery =
    (): BaseQueryFn<
        { url: string; method: AxiosRequestConfig["method"]; data?: AxiosRequestConfig["data"]; params?: AxiosRequestConfig["params"] },
        unknown,
        unknown
    > =>
    async ({ url, method, data, params }) => {
        try {
            const result = await apiRequest(url, data || params, method);
            return { data: { data: result } };
        } catch (axiosError) {
            let err = axiosError as AxiosError;
            return {
                error: {
                    status: err.response?.status,
                    data: err.response?.data || err.message,
                },
            };
        }
    };

export const axiosBaseQueryRC =
    (): BaseQueryFn<{ url: string; method: AxiosRequestConfig["method"]; data?: AxiosRequestConfig["data"] }, unknown, unknown> =>
    async ({ url, method, data }) => {
        try {
            const result = await (method === "POST"
                ? plainAxiosInstance.post(url, data)
                : plainAxiosInstance.get(url, { headers: { Accept: "application/json" } }));
            return { data: { data: result.data } };
        } catch (axiosError) {
            let err = axiosError as AxiosError;
            return {
                error: {
                    status: err.response?.status,
                    data: err.response?.data || err.message,
                },
            };
        }
    };
