import React, { useEffect, useState } from "react";
import useRCPriceCompareServers, { useRCPriceCompareSymbols } from "../../../hooks/useRCPriceCompareServers";
import { Select, Form, Typography, Button, Space, Card, Row, Col, Tag, Input, Divider } from "antd";
import useRCPriceCompareStore from "../../../store/useRCPriceCompareStore";
import { CloseCircleOutlined } from "@ant-design/icons";
import { colorMap, colorMapRGB } from "../../SystemMonitor/StatusMonitor/statusUtils";
import { matchSorter } from "match-sorter";
import MessageCard from "../../SystemMonitor/components/MessageCard";

interface ColItem {
    id: number;
    type: "server" | "competitor" | null;
    value: string | null;
}

const PriceCompareQuery = () => {
    const { rcPriceCompareOwnServers, rcPriceCompareCompetitorServers } = useRCPriceCompareServers();
    // console.log("rcPriceCompareOwnServers,", rcPriceCompareOwnServers);
    const setMainServer = useRCPriceCompareStore(state => state.setMainServer);
    const selectedSymbols = useRCPriceCompareStore(state => state.selectedSymbols);
    const setSelectedSymbols = useRCPriceCompareStore(state => state.setSelectedSymbols);
    const mainServer = useRCPriceCompareStore(state => state.mainServer);
    const { rcPriceCompareSymbols } = useRCPriceCompareSymbols({ serverName: mainServer || undefined });
    // console.log("rcPriceCompareSymbols", rcPriceCompareSymbols);
    const comparisonList = useRCPriceCompareStore(state => state.comparisonList);
    const setComparisonList = useRCPriceCompareStore(state => state.setComparisonList);
    const [isMounted, setIsMounted] = useState(false);
    const [form] = Form.useForm();
    const { Item } = Form;
    const { Text, Title } = Typography;

    // console.log("rcPriceCompareSymbols", rcPriceCompareSymbols);

    const [totalCols, setTotalCols] = useState<ColItem[]>([
        { id: 1, type: "server", value: null },
        { id: 2, type: "competitor", value: null },
    ]);

    const initialValues = {
        type_1: "server",
        value_1: null,
        type_2: "competitor",
        value_2: null,
    };

    useEffect(() => {
        setIsMounted(true);
        setComparisonList([]);
        return () => setIsMounted(false);
    }, []);

    // useEffect(() => {
    //     //setTotalCols to comparisonList on mount

    //     if (isMounted) {
    //         // temp add id
    //         const comparisonListWithID = comparisonList.map((item, index) => ({ ...item, id: index + 1 }));
    //         setTotalCols(comparisonListWithID);
    //     }
    // }, [isMounted]);

    const handleSubmit = async (values: any) => {
        // Implement your submit logic here
        // check if all of them are not undefined or null

        // console.log(values);
        const isValid = Object.keys(values).reduce((acc, key) => {
            if (key.includes("type")) {
                return acc && values[key] !== null && values[key] !== undefined;
            } else {
                return acc && values[key] !== null && values[key] !== undefined;
            }
        }, true);
        if (!isValid) {
            // console.log("Please fill in all fields");
            return;
        } else {
            // @ts-ignore
            setComparisonList(totalCols);
        }
    };

    const addNewColumn = () => {
        const newId = Math.max(...totalCols.map(col => col.id)) + 1;
        form.setFieldValue(`type_${newId}`, null);
        form.setFieldValue(`value_${newId}`, null);
        setTotalCols([...totalCols, { id: newId, type: null, value: null }]);
    };

    const removeColumn = (id: number) => {
        setTotalCols(prev => prev.filter(col => col.id !== id));
    };

    const updateColumnType = (id: number, type: "server" | "competitor" | null) => {
        setTotalCols(prev => prev.map(col => (col.id === id ? { ...col, type, value: null } : col)));
    };

    const updateColumnValue = (id: number, value: string | null) => {
        setTotalCols(prev => prev.map(col => (col.id === id ? { ...col, value } : col)));
        if (id === 1) {
            setMainServer(value);
        }
    };

    const [symbolQuery, setSymbolQuery] = useState("");
    const symbolResults = matchSorter(rcPriceCompareSymbols || [], symbolQuery, { keys: ["symbol"] });

    if (!isMounted) return null;

    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            <Space direction="vertical" style={{ width: "100%" }}>
                <Title level={5} style={{ margin: 0 }}>
                    Select Servers / Competitors
                </Title>
                <Form form={form} layout="vertical" requiredMark={true} onFinish={handleSubmit} initialValues={initialValues}>
                    <Space direction="vertical" style={{ width: "100%" }}>
                        <Row gutter={[4, 4]}>
                            {totalCols.map((col, index) => (
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }} key={col.id}>
                                    <Card style={{ height: "100%", background: `rgba(${colorMapRGB.primary}, 0.1)` }}>
                                        {index !== 0 && (
                                            <Button
                                                type="link"
                                                icon={<CloseCircleOutlined />}
                                                onClick={() => removeColumn(col.id)}
                                                style={{ position: "absolute", top: 0, right: 0 }}
                                            />
                                        )}
                                        <Space direction="vertical" style={{ width: "100%" }}>
                                            <div>
                                                <Text>{index === 0 && "Main "}Type *</Text>
                                                <Item
                                                    style={{ margin: 0, padding: 0 }}
                                                    labelCol={{ span: 24 }}
                                                    wrapperCol={{ span: 24 }}
                                                    required
                                                    name={`type_${col.id}`}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please select a type",
                                                            // ensure value can't be null
                                                            validator: async (_, value) => {
                                                                if (value === null) {
                                                                    return Promise.reject(new Error("Please select a type"));
                                                                }
                                                                return Promise.resolve();
                                                            },
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        showSearch
                                                        style={{ width: "100%" }}
                                                        placeholder="Select a type"
                                                        value={index === 0 ? "server" : col.type ?? null}
                                                        disabled={index === 0}
                                                        allowClear={index !== 0}
                                                        onChange={(value: "server" | "competitor" | null) => {
                                                            updateColumnType(col.id, value);
                                                            // clear the value if the type is changed
                                                            form.setFieldsValue({ [`value_${col.id}`]: null });
                                                            updateColumnValue(col.id, null);
                                                        }}
                                                    >
                                                        <Select.Option value="server">Server</Select.Option>
                                                        <Select.Option value="competitor">Competitor</Select.Option>
                                                    </Select>
                                                </Item>
                                            </div>
                                            <div>
                                                <Text>
                                                    {index === 0 && "Main "}
                                                    {col.type || "Select a type"} *
                                                </Text>

                                                <Item
                                                    style={{ margin: 0, padding: 0 }}
                                                    labelCol={{ span: 24 }}
                                                    wrapperCol={{ span: 24 }}
                                                    required
                                                    name={`value_${col.id}`}
                                                    rules={[{ required: true, message: `Please select a ${col.type ?? "type"}` }]}
                                                >
                                                    <Select
                                                        showSearch
                                                        style={{ width: "100%" }}
                                                        placeholder={col.type === "server" ? "Select a server" : "Select a competitor"}
                                                        value={col.value}
                                                        allowClear
                                                        disabled={!col.type}
                                                        onChange={(value: string) => updateColumnValue(col.id, value)}
                                                    >
                                                        {(col.type === "server"
                                                            ? rcPriceCompareOwnServers
                                                            : col.type === "competitor"
                                                            ? rcPriceCompareCompetitorServers
                                                            : []
                                                        ).map((item: any, index: number) => (
                                                            <Select.Option key={index} value={item.name}>
                                                                {item.name}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                </Item>
                                            </div>
                                        </Space>
                                    </Card>
                                </Col>
                            ))}
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }}>
                                <Card
                                    style={{
                                        height: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Space direction="vertical" style={{ width: "100%" }}>
                                        <Button type="ghost" onClick={addNewColumn} style={{ alignSelf: "stretch", width: "100%" }}>
                                            Add More
                                        </Button>
                                        <Button type="primary" htmlType="submit" style={{ alignSelf: "stretch", width: "100%" }}>
                                            Apply
                                        </Button>
                                    </Space>
                                </Card>
                            </Col>
                        </Row>
                    </Space>
                </Form>
            </Space>
            {/* <Divider />
            <div>
                <Space direction="vertical" style={{ width: "100%" }}>
                    <Title level={5} style={{ margin: 0 }}>
                        Select Symbol(s)
                    </Title>
                    <Input placeholder="Search Symbol" value={symbolQuery} onChange={e => setSymbolQuery(e.target.value)} />
                    {!mainServer ? (
                        <MessageCard type="warning">Please select a main server to view symbols</MessageCard>
                    ) : (
                        <Space wrap style={{ width: "100%" }} size={2}>
                            <Tag
                                //clearAll button
                                style={{
                                    margin: 0,
                                    borderRadius: 30,
                                    display: "flex",
                                    gap: 2,
                                    alignItems: "center",
                                    justifyContent: "center",
                                    cursor: "pointer",
                                    color: colorMap.warning,
                                }}
                                onClick={() => setSelectedSymbols([])}
                            >
                                Clear All
                            </Tag>
                            {symbolResults?.map(each => {
                                return (
                                    <Tag
                                        key={each.symbol}
                                        style={{
                                            margin: 0,
                                            borderRadius: 30,
                                            display: "flex",
                                            gap: 2,
                                            alignItems: "center",
                                            justifyContent: "center",
                                            cursor: "pointer",
                                            backgroundColor: selectedSymbols.includes(each.symbol) ? colorMap.success : "inherit",
                                            // color: selectedSymbols.includes(each.symbol) ? "white" : "inherit",
                                        }}
                                        onClick={() => {
                                            if (selectedSymbols.includes(each.symbol)) {
                                                setSelectedSymbols(selectedSymbols.filter(symbol => symbol !== each.symbol));
                                            } else {
                                                setSelectedSymbols([...selectedSymbols, each.symbol]);
                                            }
                                        }}
                                    >
                                        {each.symbol}
                                    </Tag>
                                );
                            })}
                        </Space>
                    )}
                </Space>
            </div> */}
        </Space>
    );
};

export default PriceCompareQuery;
