import { APIs } from "./apis";
import axiosInstance, { getInstance, plainAxiosInstance } from "./axiosSetup";
import { ResponseType } from "axios";
import { message } from "antd";

interface ResponseProps<T> {
    status: number;
    message: string | null;
    data?: T;
}

type BaseQueryProps = {
    url: string;
    method: string;
    params?: any;
};

export const defaultBaseQueryProps: BaseQueryProps = {
    url: "",
    method: "POST",
    params: {},
};

export const apiRequest = async (path: string, data: any, method: string = "POST", responseType: ResponseType = "json", headers: any = {}) => {
    // if (!skipTokenChecking) {
    //     let tmp = userInfo();
    //     if (tmp?.isTokenExpired() && path !== APIs.USER_LOGIN) {
    //         // try {
    //         //     refreshToken();
    //         // } catch (err) {
    //         userLogout().then((res) => {
    //             //message.error(err);
    //             setTimeout(() => {
    //                 window.location.href = "/login";
    //             }, 2500);
    //         });
    //         // }
    //     }
    // }

    const instance = getInstance(window.location.pathname);
    const res = instance({
        method: method,
        url: path,
        responseType: responseType,
        headers: Object.assign({ Accept: "text/plain", "Content-type": "application/json" }, headers),
        timeout: 300000,
        ...(method === "GET"
            ? {
                  params: data,
              }
            : { data: data || {} }),
    });
    return res;
};

export { APIs };
export type { ResponseProps, BaseQueryProps };
