import FlexiDataTable from "@/components/FlexiDataTable";
import { CALLBACK_KEY } from "@/constants";
import { CustomPaginationProps, FlexiDataTableCallbackProps } from "@/constants/type";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { DTColProps, currencyRender } from "@/utils/Common";
import { useCallback, useEffect, useState } from "react";
import { render } from "react-dom";

export interface CADividendSeparateProcessTableProps {
    currentTab: string;
    filterParam: any;
    runRefetchDataList: number;
}

interface CADividendSeparateProcessDataProps {
    serverId: number;
    server: string;
    separateDate: string;
    login: number;
    ticket: number;
    symbol: string;
    cleanSymbol: string;
    group: string;
    netPosition: number;
    contractSize: number;
    baseDividend: number;
    swapLong: number;
    swapShort: number;
    grossPosition: number;
    eodPrice: number;
    taxRate: number;
    currency: string;
    comment: string;
    exDate: string;
    subAccount: number;
    status: number;
    dividendProduct: number;
    dividendClient: number;
    loginName: string;
    isDeductionTax: number;
    createDate: string;
    updateDate: string;
}

const CADividendSeparateProcessTable = (props: CADividendSeparateProcessTableProps) => {
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<CADividendSeparateProcessDataProps[]>([]);
    const [pagination, setPagination] = useState<CustomPaginationProps>({
        current: 1,
        pageSize: 10,
        total: 0,
    });

    const columns = [
        DTColProps.XSmall({
            title: "Separate Date",
            dataIndex: "separateDate",
            key: "separateDate",
        }),
        DTColProps.Small({
            title: "Server",
            dataIndex: "server",
            key: "server",
        }),
        DTColProps.XSmall(
            {
                title: "Sub Account",
                dataIndex: "subAccount",
                key: "subAccount",
                render: (text: number) => (text === 1 ? "Yes" : "No"),
            },
            ["text-center"]
        ),
        DTColProps.Middle({
            title: "Group",
            dataIndex: "group",
            key: "group",
        }),
        DTColProps.Small({
            title: "Login",
            dataIndex: "login",
            key: "login",
        }),
        DTColProps.Small({
            title: "Ticket",
            dataIndex: "ticket",
            key: "ticket",
            render: (text: any) => `${text}`,
        }),
        DTColProps.Small({
            title: "Clean Symbol",
            dataIndex: "cleanSymbol",
            key: "cleanSymbol",
        }),
        DTColProps.XSCurrency({
            title: "Net Position",
            dataIndex: "netPosition",
            key: "netPosition",
            render: (text: number) => currencyRender(text.toFixed(2)),
        }),
        DTColProps.XSmall(
            {
                title: "Contract Size",
                dataIndex: "contractSize",
                key: "contractSize",
            },
            ["text-right"]
        ),
        DTColProps.XSCurrency({
            title: "Base Dividend",
            dataIndex: "baseDividend",
            key: "baseDividend",
            render: (text: number) => currencyRender(text.toFixed(2)),
        }),
        DTColProps.XSCurrency({
            title: "Tax Rate",
            dataIndex: "taxRate",
            key: "taxRate",
            render: (text: number) => currencyRender(text.toFixed(2)),
        }),
        DTColProps.XSmall(
            {
                title: "Currency",
                dataIndex: "currency",
                key: "currency",
            },
            ["text-center"]
        ),
        DTColProps.Small(
            {
                title: "Include Tax",
                dataIndex: "isDeductionTax",
                key: "isDeductionTax",
                render: (text: any) => (text === 1 ? "Deduction Tax" : "Non-Deduction Tax"),
            },
            ["text-center"]
        ),
        DTColProps.XSmall(
            {
                title: "Status",
                dataIndex: "status",
                key: "status",
                render: (text: any) => (text === 1 ? "Received" : "Unreceived"),
            },
            ["text-center"]
        ),
        DTColProps.XSCurrency({
            title: "Eod Price",
            dataIndex: "eodPrice",
            key: "eodPrice",
            render: (text: number, rowData: CADividendSeparateProcessDataProps) =>
                currencyRender(text.toFixed(rowData.group.endsWith("_BTC") ? 8 : 5)),
        }),
        DTColProps.XSCurrency({
            title: "Dividend Product CCY",
            dataIndex: "dividendProduct",
            key: "dividendProduct",
            render: (text: number, rowData: CADividendSeparateProcessDataProps) =>
                currencyRender(text.toFixed(rowData.group.endsWith("_BTC") ? 8 : 2)),
        }),
        DTColProps.XSCurrency({
            title: "Dividend Client CCY",
            dataIndex: "dividendClient",
            key: "dividendClient",
            render: (text: number, rowData: CADividendSeparateProcessDataProps) =>
                currencyRender(text.toFixed(rowData.group.endsWith("_BTC") ? 8 : 2)),
        }),
        DTColProps.XSmall({
            title: "Create Date",
            dataIndex: "createDate",
            key: "createDate",
            render: (text: string) => text.split(" ")[0],
        }),
    ];

    const componentCallback: FlexiDataTableCallbackProps = (type, FormData) => {
        switch (type) {
            case CALLBACK_KEY.HANDLE_PAGINATION_SORTING:
                setPagination(prev => ({ ...prev, current: FormData.pagination.current, pageSize: FormData.pagination.pageSize }));
                setRunRefetchDataList(true);
                break;
            default:
                break;
        }
    };

    const getDataList = useCallback(() => {
        plainAxiosInstance
            .post(APIs.RISK_TOOL.GET_CA_DIVIDEND_SEPARATE_PROCESS_DATA_LIST, {
                page: pagination.current,
                rows: pagination.pageSize,
                sortBy: [],
                sortDesc: [],
                ...props.filterParam,
            })
            .then((res: any) => {
                if (res.data.resultList.length > 0) {
                    setData(res.data.resultList);
                    setPagination(prev => ({ ...prev, total: res.data.total }));
                } else {
                    setData([]);
                    setPagination(prev => ({ ...prev, total: 0 }));
                }
            })
            .finally(() => setIsLoading(false));
    }, [props.filterParam, pagination]);

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            getDataList();
            setRunRefetchDataList(false);
        }
    }, [runRefetchDataList]);

    useEffect(() => {
        setRunRefetchDataList(true);
    }, [props.runRefetchDataList]);

    return (
        <div style={{ display: props.currentTab === "Listing" ? "block" : "none" }}>
            <FlexiDataTable
                rowKeyProperty="id"
                title=""
                columns={columns}
                options={{ serverFiltering: true, enableFilter: false }}
                dataSource={data}
                callback={componentCallback}
                loading={isLoading}
                pagination={pagination}
                serverSide={true}
            />
        </div>
    );
};

export default CADividendSeparateProcessTable;
