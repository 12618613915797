import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.less";
//import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import AppRoute from "./routes";
import { store } from "./store";
import { useEffect } from "react";
import { clarity } from "react-microsoft-clarity";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import ErrorBoundary from "antd/lib/alert/ErrorBoundary";
import { Button, Divider, Space } from "antd";

const queryClient = new QueryClient();

const WrappedApp = () => {
    useEffect(() => {
        if (process.env.REACT_APP_MICROSOFT_CLARITY_ID !== undefined) {
            clarity.init(process.env.REACT_APP_MICROSOFT_CLARITY_ID);
        }
    }, []);

    return (
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <BrowserRouter>
                    <ErrorBoundary
                        message={
                            <Space direction="vertical" style={{ width: "100%" }}>
                                <Space direction="vertical" size={0} style={{ lineHeight: 1.2 }}>
                                    <strong>Sorry, something went wrong.</strong>
                                    <small>Please snapshot this page and send it to the developer.</small>
                                </Space>
                                <small style={{ color: "rgba(0,0,0,0.5)" }}>{window.location.href}</small>
                                <Space>
                                    <Button type="primary">
                                        <a href="/">Go Insight Homepage</a>
                                    </Button>
                                    {/* refresh button */}
                                    <Button type="ghost" onClick={() => window.location.reload()}>
                                        Refresh
                                    </Button>
                                </Space>
                                <Divider />
                            </Space>
                        }
                    >
                        <AppRoute />
                    </ErrorBoundary>
                </BrowserRouter>
            </Provider>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    );
};

ReactDOM.render(<WrappedApp />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
