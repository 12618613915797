import { CalendarOutlined, ClockCircleOutlined, HomeOutlined, NumberOutlined, OneToOneOutlined, PercentageOutlined } from "@ant-design/icons";
import { Form, Row, Col, Button } from "antd";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FormComponent } from "../../../../components/FormComponent";
import LoadingComponent from "../../../../components/Loading";
import SitePageHeader from "../../../../components/PageHeader";
import { SUCCESS_FAILED, ComponentType, METRIC_FORMAT_TYPE_ENUM } from "../../../../constants";
import { REQUIRED_FIELD } from "../../../../constants/errorMessage";
import { apiRequest } from "../../../../services/apiConfig";
import { APIs } from "../../../../services/apis";
import { ErrorCatchValidator, ErrorMessageHandler } from "../../../../utils/Common";
import { ServerState } from "../../ServersPage/ServerCreateEditPage";

const AccSumSymbolConfigEditPage = () => {
    let navigate = useNavigate();
    let location = useLocation();
    let com_state: ServerState = location.state as ServerState;
    const [currentState] = useState<ServerState>(com_state || { action: "add", data: null });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [symbolGlossaryForm] = Form.useForm();

    const onSubmit = (values: any) => {
        setIsLoading(true);
        apiRequest(APIs.UPDATE_ACC_SYMBOL_LIST_360, values)
            .then(() => {
                ErrorMessageHandler("Existing account symbol", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                navigate("/siteadmin/appconfig/accSymbolConfig");
            })
            .catch((error) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("existing account symbol", SUCCESS_FAILED.FAILED_UPDATE_DATA, err))
            )
            .finally(() => setIsLoading(false));
    };

    return (
        <SitePageHeader
            title={"Edit Account-Symbol Summary Configuration"}
            routes={[
                {
                    path: "/siteadmin/appconfig/accSymbolConfig",
                    breadcrumbName: "Account-Symbol Summary Configuration",
                    icon: <HomeOutlined />,
                },
                {
                    path: "",
                    breadcrumbName: "Edit Account-Symbol Summary Configuration",
                },
            ]}
            onBack={() => navigate("/siteadmin/appconfig/accSymbolConfig")}
        >
            {isLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Submitting..." />
                </div>
            ) : (
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    form={symbolGlossaryForm}
                    layout="horizontal"
                    initialValues={currentState.data}
                    onFinish={onSubmit}
                >
                    <Row>
                        <Col span={15}>
                            <FormComponent label={""} name={"metricId"} extra={{ type: ComponentType.hidden, value: "" }} />
                            <FormComponent
                                label="Display Name"
                                name="displayName"
                                extra={{
                                    type: ComponentType.labelOnly,
                                    value: "",
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label={"Format Type"}
                                name={`formatType`}
                                extra={{
                                    type: ComponentType.dropdown,
                                    value: [
                                        {
                                            text: (
                                                <Row>
                                                    <Col span={2}>
                                                        <CalendarOutlined />
                                                    </Col>
                                                    <Col>Date</Col>
                                                </Row>
                                            ),
                                            value: METRIC_FORMAT_TYPE_ENUM.DATE,
                                        },
                                        {
                                            text: (
                                                <Row>
                                                    <Col span={2}>
                                                        <NumberOutlined />
                                                    </Col>
                                                    <Col>Count</Col>
                                                </Row>
                                            ),
                                            value: METRIC_FORMAT_TYPE_ENUM.COUNT,
                                        },
                                        {
                                            text: (
                                                <Row>
                                                    <Col span={2}>
                                                        <ClockCircleOutlined />
                                                    </Col>
                                                    <Col>Time</Col>
                                                </Row>
                                            ),
                                            value: METRIC_FORMAT_TYPE_ENUM.TIME,
                                        },
                                        {
                                            text: (
                                                <Row>
                                                    <Col span={2}>
                                                        <PercentageOutlined />
                                                    </Col>
                                                    <Col>Percentage</Col>
                                                </Row>
                                            ),
                                            value: METRIC_FORMAT_TYPE_ENUM.PERCENTAGE,
                                        },
                                        {
                                            text: (
                                                <Row>
                                                    <Col span={2}>
                                                        <OneToOneOutlined />
                                                    </Col>
                                                    <Col>Ratio</Col>
                                                </Row>
                                            ),
                                            value: METRIC_FORMAT_TYPE_ENUM.RATIO,
                                        },
                                        {
                                            text: (
                                                <Row>
                                                    <Col span={2}>
                                                        <b>USD</b>
                                                    </Col>
                                                    <Col>USD</Col>
                                                </Row>
                                            ),
                                            value: METRIC_FORMAT_TYPE_ENUM.USD,
                                        },
                                    ],
                                    rules: [
                                        {
                                            required: true,
                                            message: REQUIRED_FIELD,
                                        },
                                    ],
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={5}></Col>
                        <Col span={9}>
                            <FormComponent
                                label=""
                                name="isView"
                                extra={{
                                    type: ComponentType.checkbox,
                                    value: "View in Account Summary ",
                                    itemProps: {
                                        colon: false,
                                    },
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div className="step-btns-group">
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            )}
        </SitePageHeader>
    );
};

export default AccSumSymbolConfigEditPage;
