import { Empty } from "antd";
import React from "react";

export interface EmptyProps {
    image?: React.ReactNode;
    className?: string;
}

const EmptyData = (props: EmptyProps) => {
    return (
        <>
            <Empty className={`${props.className ? props.className : "empty-container"}`} {...(props.image && { image: props.image })} />
        </>
    );
};

export default EmptyData;
