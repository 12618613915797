import { Button, Modal, notification } from "antd";
import FlexiDataTable from "../../../components/FlexiDataTable";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";
import { FlexiDataTableCallbackProps, FlexiDataTableOptionsProps, PriceAnalysisAlarmLatestList } from "../../../constants/type";
import { useCallback, useEffect, useState } from "react";
import { BarChartOutlined, CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined, LineChartOutlined } from "@ant-design/icons";
import { APIs } from "../../../services/apis";
import { plainAxiosInstance } from "../../../services/axiosSetup";
import KLineChart from "./charts/KLineChart";
import SecTicChart, { secondTicksChartDataTemplate } from "./charts/SecTicChart";
import { CALLBACK_KEY, ComponentType, PRICE_ANALYSIS_ALARM_TAB, SUCCESS_FAILED } from "../../../constants";

export interface PAAlarmServerDetailProps {
    svData: any[];
    isLoading: boolean;
    from: number;
    callback?: (type: CALLBACK_KEY.CLSE_MODAL_CALLBACK) => void;
}

const PAAlarmServerDetail = (props: PAAlarmServerDetailProps) => {
    const [isChartModalVisible, setIsChartModalVisible] = useState<boolean>(false);
    const [chartData, setChartData] = useState<any>();
    const [chartTitle, setChartTitle] = useState<string>("");
    const [selectedEventIds, setSelectedEventIds] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(true);
    const [SVComparingData, setSVComparingData] = useState<any[]>([]);

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            comparingData();
            setRunRefetchDataList(false);
        }
    }, [runRefetchDataList]);

    useEffect(() => {
        if (props.isLoading && !runRefetchDataList) {
            setRunRefetchDataList(true);
        }
    }, [props.isLoading]);

    useEffect(() => {
        comparingData();
    }, []);

    const comparingData = () => {
        setSelectedEventIds([]);
        if (props.from === PRICE_ANALYSIS_ALARM_TAB.MONITORWALL) {
            setSVComparingData(
                props.svData.map(item => ({
                    ...item,
                    uniqueKey: `${item.n}_${item.groupId}_${item.baseServerUno}_${item.baseSymbol}_${item.otherServerUno}_${item.otherSymbol}`,
                }))
            );
        } else if (props.from === PRICE_ANALYSIS_ALARM_TAB.HISTORY) {
            setSVComparingData(
                props.svData.map(item => ({
                    ...item,
                    uniqueKey: `${item.alarmMinutes}_${item.groupId}_${item.baseServerUno}_${item.baseSymbol}_${item.alarmTime}`,
                }))
            );
        }
    };

    const showChart = useCallback(async (record: any, type: number) => {
        try {
            if (type === 1) {
                const { alarmTime, baseServerDn, baseSymbol, otherServerDn, otherSymbol } = record;
                const requestStr =
                    alarmTime.substring(0, alarmTime.length - 3).replaceAll("-", "/") +
                    "," +
                    baseServerDn +
                    "," +
                    baseSymbol +
                    "," +
                    otherServerDn +
                    "," +
                    otherSymbol +
                    ",1";
                const formData = new FormData();
                formData.append("keyStr", requestStr);
                const resp = await plainAxiosInstance.post(`${APIs.RC_PRICE_ANALYSIS.POST_PA_ALARM_KLINE}`, formData);
                if (resp?.data) {
                    setChartData({ dt: resp.data, type: type });
                    props.from === PRICE_ANALYSIS_ALARM_TAB.HISTORY
                        ? setChartTitle(
                              "K-Line Charts ( " +
                                  record["baseServerDn"] +
                                  " : " +
                                  record["baseSymbol"] +
                                  " vs " +
                                  record["otherServerDn"] +
                                  " : " +
                                  record["otherSymbol"] +
                                  " )"
                          )
                        : setChartTitle("K-Line Charts ( " + record["baseSymbol"] + " )");
                } else {
                    notification.error({
                        message: "Error",
                        description: "No data found",
                    });
                }
            } else if (type === 2) {
                const { groupId, baseServerUno, baseSymbol, alarmTime } = record;
                const formData = new FormData();
                formData.append("groupId", groupId);
                formData.append("baseServerUno", baseServerUno);
                formData.append("baseSymbol", baseSymbol);
                formData.append("alarmTime", alarmTime);
                const resp = await plainAxiosInstance.post(`${APIs.RC_PRICE_ANALYSIS.POST_PA_ALARM_COMPARING}`, formData);

                if (resp?.data) {
                    let xAxis = resp.data.xAxis;
                    let legend = resp.data.legend;
                    let series: any[] = [];
                    legend.flatMap((item: any, index: string | number) => {
                        series.push({ name: item, type: "line", data: resp.data.series[index], smooth: false });
                    });

                    setChartData({ dt: secondTicksChartDataTemplate(legend, series, xAxis), type: type });
                    props.from === PRICE_ANALYSIS_ALARM_TAB.HISTORY
                        ? setChartTitle(
                              "Second Ticks Charts ( " +
                                  record["baseServerDn"] +
                                  " : " +
                                  record["baseSymbol"] +
                                  " vs " +
                                  record["otherServerDn"] +
                                  " : " +
                                  record["otherSymbol"] +
                                  " )"
                          )
                        : setChartTitle("Second Ticks Charts ( " + record["baseSymbol"] + " )");
                } else {
                    notification.error({
                        message: "Error",
                        description: "No data found",
                    });
                }
            }
            setIsChartModalVisible(true);
        } catch (error) {
            console.log("Error: ", error);
            notification.error({
                message: "Error",
                description: "No data found",
            });
        }
    }, []);

    const columns = [
        DTColProps.XSmall({
            title: "K Chart",
            dataIndex: "Action1",
            key: "Action1",
            render: (text: string, record: any) => <Button type="primary" icon={<LineChartOutlined />} onClick={() => showChart(record, 1)} />,
        }),
        DTColProps.XSmall({
            title: "Sec Ticks",
            dataIndex: "Action2",
            key: "Action2",
            render: (text: string, record: any) => <Button type="primary" icon={<BarChartOutlined />} onClick={() => showChart(record, 2)} />,
        }),
        DTColProps.XSmall({
            title: "Base Server",
            dataIndex: "baseServerDn",
            key: "baseServerDn",
            options: {
                filter: {
                    type: ComponentType.text,
                },
            },
        }),
        DTColProps.XSmall({
            title: "Base Symbol",
            dataIndex: "baseSymbol",
            key: "baseSymbol",
            options: {
                filter: {
                    type: ComponentType.text,
                },
            },
        }),
        DTColProps.XSmall({
            title: "Compare Server",
            dataIndex: "otherServerDn",
            key: "otherServerDn",
            options: {
                filter: {
                    type: ComponentType.text,
                },
            },
        }),
        DTColProps.XSmall({
            title: "Compare Symbol",
            dataIndex: "otherSymbol",
            key: "otherSymbol",
            options: {
                filter: {
                    type: ComponentType.text,
                },
            },
        }),
        DTColProps.XSmall({
            title: "Score Max",
            dataIndex: "scoreMax",
            key: "scoreMax",
            options: {
                filter: {
                    type: ComponentType.number,
                },
            },
        }),
        DTColProps.XSmall({
            title: "Score Sum",
            dataIndex: "scoreSum",
            key: "scoreSum",
            options: {
                filter: {
                    type: ComponentType.number,
                },
            },
        }),
        DTColProps.XSmall({
            title: "ppmcc",
            dataIndex: "ppmcc",
            key: "ppmcc",
            options: {
                filter: {
                    type: ComponentType.number,
                },
            },
        }),
        DTColProps.XSmall({
            title: "Alarm Type",
            dataIndex: "alarmType",
            key: "alarmType",
            options: {
                filter: {
                    type: ComponentType.text,
                },
            },
        }),
        DTColProps.XSmall({
            title: "Alarm Time",
            dataIndex: "alarmTime",
            key: "alarmTime",
            options: {
                filter: {
                    type: ComponentType.text,
                },
            },
        }),
        ...(props.from === PRICE_ANALYSIS_ALARM_TAB.HISTORY
            ? [
                  DTColProps.XSmall({
                      title: "Solved",
                      dataIndex: "solved",
                      key: "solved",
                      align: "center",
                      options: {
                          filter: {
                              type: ComponentType.text,
                          },
                      },
                      render: (solved: boolean) => {
                          if (solved) {
                              return <CheckCircleOutlined style={{ color: "#0ab76e", fontSize: "1.375rem" }} />;
                          } else {
                              return <CloseCircleOutlined style={{ color: "#f00f00", fontSize: "1.375rem" }} />;
                          }
                      },
                  }),
                  DTColProps.XSmall({
                      title: "Solved By",
                      dataIndex: "solvedInfo",
                      key: "solvedInfo",
                      options: {
                          filter: {
                              type: ComponentType.text,
                          },
                      },
                      render: (solvedInfo: any, rowData: PriceAnalysisAlarmLatestList) => (
                          <div className="solved-info">
                              <div className="title">{rowData.solvedTime}</div>
                              <div className="value">{rowData.solvedUser}</div>
                          </div>
                      ),
                  }),
                  {
                      title: "Solved Time",
                      dataIndex: "solvedTime",
                      key: "solvedTime",
                      options: {
                          filter: {
                              type: ComponentType.text,
                          },
                          visible: false,
                      },
                  },
                  {
                      title: "Solved",
                      dataIndex: "solvedUser",
                      key: "solvedUser",
                      options: {
                          filter: {
                              type: ComponentType.text,
                          },
                          visible: false,
                      },
                  },
              ]
            : []),
    ];

    const tableOpts: FlexiDataTableOptionsProps = {
        defaultCollapseFilterPanel: true,
        enableRowSelection: true,
        hideRowSelectionsSummary: true,
        rowSelectionData: {
            rowSelectionType: "checkbox",
            selectedRowKeys: [],
            options: {
                getCheckboxProps: (record: any) => ({
                    disabled: record.isResolved,
                }),
            },
        },
        ...(selectedEventIds.length > 0 &&
            selectedEventIds.length < SVComparingData.length && {
                extraButtons: () => (
                    <Button
                        key={`btn-resolved-${Math.random()}`}
                        type="primary"
                        htmlType="button"
                        style={{ marginLeft: "0.651vw", width: "auto" }}
                        onClick={event => {
                            event.preventDefault();
                            event.stopPropagation();
                            onClickResolved();
                        }}
                    >
                        Resolved
                    </Button>
                ),
            }),
        ...((selectedEventIds.length < 0 || selectedEventIds.length === SVComparingData.length) && {
            extraButtons: () => (
                <Button
                    key={`btn-solveall-${Math.random()}`}
                    type="primary"
                    htmlType="button"
                    style={{ marginLeft: "0.651vw", width: "auto" }}
                    onClick={event => {
                        event.preventDefault();
                        event.stopPropagation();
                        onClickSolveAll();
                    }}
                >
                    Solve All
                </Button>
            ),
        }),
        ...(props.from === PRICE_ANALYSIS_ALARM_TAB.MONITORWALL && {
            refresh: {
                timer: false,
                enablePeriodSelection: false,
            },
        }),
    };

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.ROW_SELECTION_CALLBACK:
                setSelectedEventIds(FormData.selectedRowKeys.map((x: string) => x));
                break;
            case CALLBACK_KEY.REFRESH:
                setRunRefetchDataList(true);
                break;
            default:
                break;
        }
    };

    const onClickResolved = useCallback(async () => {
        if (props.from === PRICE_ANALYSIS_ALARM_TAB.MONITORWALL) {
            const reqBody = {
                keys: SVComparingData.filter(item => selectedEventIds.includes(item.uniqueKey)).map((x: any) => ({
                    n: x.n,
                    groupId: x.groupId,
                    serverUno: x.baseServerUno,
                    symbol: x.baseSymbol,
                    cserverUno: x.otherServerUno,
                    csymbol: x.otherSymbol,
                })),
            };
            setIsLoading(true);
            plainAxiosInstance
                .post(`${APIs.RC_PRICE_ANALYSIS.POST_PA_ALARM_SOLVE}`, reqBody)
                .then(() => {
                    ErrorMessageHandler("alarm resolved status", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                    if (props && props.callback) {
                        props.callback(CALLBACK_KEY.CLSE_MODAL_CALLBACK);
                        setRunRefetchDataList(true);
                    }
                })
                .catch((error: any) =>
                    ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("alarm resolved status", SUCCESS_FAILED.FAILED_UPDATE_DATA, err))
                )
                .finally(() => setIsLoading(false));
        } else if (props.from === PRICE_ANALYSIS_ALARM_TAB.HISTORY) {
            const reqBody = {
                keys: SVComparingData.filter(item => selectedEventIds.includes(item.uniqueKey)).map((x: any) => ({
                    groupId: x.groupId,
                    serverUno: x.baseServerUno,
                    symbol: x.baseSymbol,
                    cserverUno: x.otherServerUno,
                    csymbol: x.otherSymbol,
                    alarmMinutes: x.alarmMinutes,
                })),
            };
            setIsLoading(true);
            plainAxiosInstance
                .post(`${APIs.RC_PRICE_ANALYSIS.POST_PA_ALARM_SOLVE_HISTORY}`, reqBody)
                .then(() => {
                    ErrorMessageHandler("alarm resolved status", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                    setRunRefetchDataList(true);
                })
                .catch((error: any) =>
                    ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("alarm resolved status", SUCCESS_FAILED.FAILED_UPDATE_DATA, err))
                )
                .finally(() => setIsLoading(false));
        }
    }, [props.callback, selectedEventIds, SVComparingData]);

    const onClickSolveAll = useCallback(async () => {
        const { groupId, baseServerUno, otherServerUno } = SVComparingData[0];
        const reqBody = {
            groupId: groupId,
            serverUno: baseServerUno,
            cserverUno: otherServerUno,
        };
        Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            title: "Are you sure to solve all alarm records ?",
            width: "50%",
            onOk() {
                plainAxiosInstance
                    .post(`${APIs.RC_PRICE_ANALYSIS.POST_PA_ALARM_SOLVE_ALL}`, reqBody)
                    .then((data: any) => {
                        ErrorMessageHandler("All alarm records", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                        if (props && props.callback) {
                            props.callback(CALLBACK_KEY.CLSE_MODAL_CALLBACK);
                            setRunRefetchDataList(true);
                        }
                    })
                    .catch(error =>
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("all alarm records", SUCCESS_FAILED.FAILED_DELETE_DATA, err))
                    );
            },
            onCancel() {},
        });
    },  [props.callback, selectedEventIds, SVComparingData]);

    const closeModal = () => {
        setChartData(null);
        setIsChartModalVisible(false);
        setRunRefetchDataList(true);
    };

    return (
        <div className="pa-alarm-server-detail">
            <FlexiDataTable
                rowKeyProperty="uniqueKey"
                title={""}
                columns={columns}
                options={tableOpts}
                dataSource={SVComparingData}
                loading={false}
                callback={componentCallback}
                pagination={{
                    defaultPageSize: 10,
                }}
                scroll={{
                    x: true,
                }}
            />
            {isChartModalVisible && chartData?.type === 1 && (
                <Modal open={isChartModalVisible} onCancel={closeModal} width={1080} footer={null} title={chartTitle}>
                    <KLineChart biaslineCompareData={chartData.dt} />
                </Modal>
            )}
            {isChartModalVisible && chartData?.type === 2 && (
                <Modal open={isChartModalVisible} onCancel={closeModal} width={1080} footer={null} title={chartTitle}>
                    <SecTicChart secTicChartData={chartData.dt} visible={isChartModalVisible} onClose={closeModal} />
                </Modal>
            )}
        </div>
    );
};

export default PAAlarmServerDetail;
