import React, { useRef, useState } from "react";
import useRCServerPerformanceSettings from "../../../hooks/useRCServerPerformanceSettings";
import { Button, Modal, Table, Typography, notification, Form, Row, Col, Select, Switch, Space, InputNumber, Alert } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import moment from "moment-timezone";
import useRCServers from "../../../hooks/useRCServers";
import Loader from "../components/Loader";
import { colorMap, colorMapRGB } from "../StatusMonitor/statusUtils";
import { FaInfo, FaInfoCircle } from "react-icons/fa";

const ServerPerformanceSettingsTable = () => {
    const { rcServerPerformanceSettings } = useRCServerPerformanceSettings();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [currEdit, setCurrEdit] = useState<string | null>(null);
    const [currDelete, setCurrDelete] = useState<string | null>(null);
    const currEditInfo = rcServerPerformanceSettings?.find((each: any) => each.serverName === currEdit);
    const massagedData = rcServerPerformanceSettings?.map((data: any) => {
        return {
            ...data,
            key: data.serverName,
        };
    });
    // console.log("rcServerPerformanceSettings", rcServerPerformanceSettings);
    const columns = [
        {
            title: "ServerName",
            dataIndex: "serverName",
        },
        {
            title: "Cpu",
            dataIndex: "cpu",
        },
        {
            title: "Cpu Count",
            dataIndex: "cpuCount",
        },
        {
            title: "Free Memory",
            dataIndex: "freeMemory",
        },
        {
            title: "Action",
            render: (text: any, data: any) => (
                <Space>
                    <Button
                        className="m-1"
                        type="primary"
                        icon={<EditOutlined />}
                        // onClick={(e) => edit(data)}>
                        onClick={() => {
                            handleEdit(data.serverName);
                        }}
                        size="small"
                    >
                        Edit
                    </Button>
                    <Button type="text" size="small" color="red" icon={<DeleteOutlined />} onClick={e => handleDelete(data.serverName)}>
                        Delete
                    </Button>
                </Space>
            ),
        },
    ];

    const onSelectChange = (selectedKeys: any) => {
        setSelectedRowKeys(selectedKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const handleEdit = (server: string | null) => {
        if (server === null) {
            setCurrEdit(null);
            return;
        }
        setCurrEdit(server);
    };
    const handleDelete = (server: string) => {
        setCurrDelete(server);
        // console.log("Delete", server);
    };
    const handleCloseEditModal = () => {
        setCurrEdit(null);
    };

    return (
        <>
            <div style={{ display: "flex", justifyContent: "flex-start", flexWrap: "wrap", gap: "4px", alignItems: "center", padding: "1rem" }}>
                <Button onClick={() => handleEdit("__new__")} icon={<PlusOutlined />}>
                    Add
                </Button>
            </div>
            <Table columns={columns} dataSource={massagedData} size="small" pagination={false} />
            {currEdit && (
                <SettingsModal
                    open={currEdit !== null}
                    handleCloseModal={() => {
                        handleCloseEditModal();
                    }}
                    currEdit={currEdit}
                    data={currEditInfo}
                />
            )}
            {currDelete && (
                <DeleteModal
                    open={currDelete !== null}
                    handleCloseModal={() => {
                        setCurrDelete(null);
                    }}
                    server={currDelete}
                />
            )}
        </>
    );
};

const SettingsModal = ({
    open,
    handleCloseModal,
    data,
    currEdit,
}: {
    open: boolean;
    currEdit: string | null;
    handleCloseModal: () => void;
    data?: {
        serverName?: string;
        cpu?: number;
        cpuCount?: number;
        freeMemory?: number;
    };
}) => {
    // const { RangePicker } = TimePicker;

    const { rcServers, isLoading } = useRCServers();
    const { rcServerPerformanceSettings, editSettings, addSettings } = useRCServerPerformanceSettings();

    const [form] = Form.useForm();
    const { Item } = Form;
    const { Text } = Typography;

    const serverOptions =
        rcServers
            ?.filter((each: any) => {
                if (currEdit === "__new__") {
                    return rcServerPerformanceSettings?.find((item: any) => item.serverName !== each.serverName);
                } else {
                    return each === data;
                }
            })
            ?.map((each: any) => ({ label: each.serverName, value: each.serverName })) ?? [];
    // console.log(serverOptions);
    const initFormValue = {
        serverName: data?.serverName || null,
        // init startime as moment object
        cpu: data?.cpu || 80,
        // init endtime as moment object
        cpuCount: data?.cpuCount || 1,
        freeMemory: data?.freeMemory || 700,
    };

    const formRef = useRef<any>(null);

    const handleSubmit = async (values: any) => {
        try {
            if (currEdit === "__new__") {
                const resp = await addSettings(values);
                if (resp) {
                    notification.success({
                        message: `Success`,
                        description: `Successfully added server performance settings`,
                    });
                }
            } else {
                if (currEdit !== null) {
                    const resp = await editSettings(values);
                    if (resp) {
                        notification.success({
                            message: `Success`,
                            description: `Successfully updated server performance settings`,
                        });
                    }
                }
            }
            handleCloseModal();
        } catch (e) {
            console.error(e);
            notification.error({
                message: `Error`,
                description: `Failed to ${currEdit === "__new__" ? "add" : "update"} server performance settings`,
            });
        }
    };

    return (
        <Form ref={formRef} form={form} layout="vertical" initialValues={initFormValue} requiredMark={true} onFinish={handleSubmit} key={currEdit}>
            <Modal
                title={
                    (currEdit === "__new__" ? "Add " : `Edit `) +
                    `Server Performance Threshold Settings` +
                    (currEdit === "__new__" ? "" : ` - ${currEdit}`)
                }
                okText={"Save"}
                okButtonProps={
                    {
                        // icon: <DownloadOutlined />,
                    }
                }
                open={open}
                onOk={() => form.submit()}
                onCancel={() => handleCloseModal()}
                // disable click outside of modal to close
                maskClosable={false}
            >
                {isLoading && !rcServers ? (
                    <Loader />
                ) : (
                    <Space direction="vertical" style={{ width: "100%" }}>
                        <div>
                            <Text>Server *</Text>
                            <Item style={{ margin: 0, padding: 0 }} name="serverName" rules={[{ required: true, message: "Please select server" }]}>
                                <Select
                                    allowClear
                                    style={{ width: "100%" }}
                                    placeholder="Select Servers"
                                    options={serverOptions}
                                    showSearch
                                    onChange={data => {
                                        form.setFieldsValue({ serverName: data });
                                    }}
                                    disabled={currEdit !== "__new__"}
                                />
                            </Item>
                        </div>
                        <div>
                            <Text>CPU *</Text>
                            <Space direction="vertical" style={{ width: "100%" }} size={4}>
                                <Item
                                    style={{ margin: 0, padding: 0 }}
                                    name="cpu"
                                    rules={[{ required: true, message: "Please fill in CPU (default 80)" }]}
                                >
                                    <InputNumber
                                        style={{ width: "100%" }}
                                        placeholder="CPU (default 80)"
                                        onChange={data => {
                                            form.setFieldsValue({ cpu: data });
                                        }}
                                    />
                                </Item>
                                <Alert
                                    message="The default is 80, when the cpu usage is higher than 80%, it will alarm."
                                    type="info"
                                    showIcon
                                    icon={<FaInfoCircle style={{ color: colorMap.info }} />}
                                    style={{ background: `rgba(${colorMapRGB.info},0.25)`, border: "none", fontSize: "0.9em" }}
                                />
                            </Space>
                        </div>
                        <div>
                            <Text>CPU Count *</Text>
                            <Space direction="vertical" style={{ width: "100%" }} size={4}>
                                <Item
                                    style={{ margin: 0, padding: 0 }}
                                    name="cpuCount"
                                    rules={[{ required: true, message: "Please fill in CPU Count (default 1)" }]}
                                >
                                    <InputNumber
                                        style={{ width: "100%" }}
                                        placeholder="CPU Count (default 1)"
                                        onChange={data => {
                                            form.setFieldsValue({ cpuCount: data });
                                        }}
                                    />
                                </Item>
                                <Alert
                                    message="The default is 1, when the cpu usage is higher than 80% for 1 times, it will alarm"
                                    type="info"
                                    showIcon
                                    icon={<FaInfoCircle style={{ color: colorMap.info }} />}
                                    style={{ background: `rgba(${colorMapRGB.info},0.25)`, border: "none", fontSize: "0.9em" }}
                                />
                            </Space>
                        </div>
                        <div>
                            <Text>Free Memory (MB) *</Text>
                            <Space direction="vertical" style={{ width: "100%" }} size={4}>
                                <Item
                                    style={{ margin: 0, padding: 0 }}
                                    name="freeMemory"
                                    rules={[{ required: true, message: "Please fill in Free Memory (default 700)" }]}
                                >
                                    <InputNumber
                                        style={{ width: "100%" }}
                                        placeholder="Free Memory (MB) (default 700)"
                                        onChange={data => {
                                            form.setFieldsValue({ freeMemory: data });
                                        }}
                                    />
                                </Item>
                                <Alert
                                    message="The default is 700, when free memory falls below 700 MB, it will alarm"
                                    type="info"
                                    showIcon
                                    icon={<FaInfoCircle style={{ color: colorMap.info }} />}
                                    style={{ background: `rgba(${colorMapRGB.info},0.25)`, border: "none", fontSize: "0.9em" }}
                                />
                            </Space>
                        </div>
                    </Space>
                )}
            </Modal>
        </Form>
    );
};

const DeleteModal = ({ open, handleCloseModal, server }: { open: boolean; handleCloseModal: () => void; server: string }) => {
    const { deleteSettings } = useRCServerPerformanceSettings();
    const handleDelete = async () => {
        try {
            const resp = await deleteSettings({ serverNames: [server] });
            // console.log(resp);
            if (resp) {
                notification.success({
                    message: `Success`,
                    description: `Successfully deleted Server Performance Threshold Settings`,
                });
            }
            handleCloseModal();
        } catch (e) {
            console.error(e);
            notification.error({
                message: `Error`,
                description: `Failed to delete Server Performance Threshold Settings`,
            });
        }
    };
    return (
        <Modal
            title="Delete Server Performance Threshold Settings"
            okText={"Delete"}
            okButtonProps={{
                danger: true,
            }}
            open={open}
            onOk={handleDelete}
            onCancel={handleCloseModal}
        >
            <p>
                Are you sure you want to delete Server Performance Threshold Settings of server <strong>{server}</strong>?
            </p>
        </Modal>
    );
};

export default ServerPerformanceSettingsTable;
