import { useCallback, useEffect, useMemo, useState } from "react";
import {
    FlexiDataTableCallbackProps,
    Mt4Mt5ClosedTrade,
    NavigationItem,
    ServersList,
    SymbolsList,
    uploadedMt4Mt5Tickets,
} from "../../../constants/type";
import { APIs, apiRequest } from "../../../services/apiConfig";
import { DTColProps, DataTableColumnRender, ErrorCatchValidator, ErrorMessageHandler, currencyRender } from "../../../utils/Common";
import { CALLBACK_KEY, ComponentType, RESTORE_TRADE_GROUP_TYPES, SUCCESS_FAILED } from "../../../constants";
import FlexiDataTable from "../../../components/FlexiDataTable";
import { DateTimeUtil } from "../../../utils/datetime";
import { Button, Dropdown, Segmented, Table, Tooltip, Upload, UploadProps, message } from "antd";
import { findObjectKeys, getAvailableObjectElementKeys } from "../../../utils/object";
import { DownloadOutlined, ExportOutlined, UploadOutlined } from "@ant-design/icons";
import AuthHelper, { AuthKeys } from "../../../helpers/authHelper";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import BatchUploadTab from "./BatchUploadTab";
import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";
import { isEmptyOrNull } from "../../../utils/string";
import { REQUIRED_FIELD } from "@/constants/errorMessage";

export interface TicketListProps {
    symbols: SymbolsList[];
    servers: ServersList[];
}

export interface TableRowSummary {
    totalAcc: number;
    totalPnl: number;
}

export interface selectedTicketData {
    login: number;
    ticket: number;
    serverName: string;
    symbol: string;
    closeTime?: string;
    openTime?: string;
    profitInUSD: number;
    comment: string;
    order?: number;
    deal?: number;
    profit: number;
    swap: number;
    swapInUSD: number;
    commission: number;
    commissionInUSD: number;
}

interface Mt4Mt5ClosedTradeWithPNL extends Mt4Mt5ClosedTrade {
    pnl: number;
}

interface Mt4Mt5ClosedTradeGroup extends TableRowSummary {
    tradeGroupTypeId: string;
    tradeGroupType: string;
    tickets: Mt4Mt5ClosedTradeWithPNL[];
    totalAccounts: number;
    totalPnl: number;
    totalSelectedAccounts: number;
    totalSelectedPnl: number;
}

const uploadDummyRequest = ({ file, onSuccess }: any) => {
    setTimeout(() => {
        onSuccess("ok");
    }, 0);
};

const SegmentList: NavigationItem[] = [
    { key: "1", title: "Manual Search" },
    { key: "2", title: "Batch Upload" },
];

const TicketList = (props: TicketListProps) => {
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.ADMIN_TOOLS_TICKET_RESTORATION_EDIT);
    let navigate = useNavigate();

    const [currentActiveSegment, setCurrentActiveSegment] = useState<string>("1"); // For segment
    const [data, setData] = useState<Mt4Mt5ClosedTradeGroup[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [filterParams, setFilterParams] = useState<any>({});
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [overallData, setOverallData] = useState<TableRowSummary>({ totalAcc: 0, totalPnl: 0 });
    const [selectedTicketObj, setSelectedTicketObj] = useState<any>({});
    const [isMt5, setIsMt5] = useState<boolean>(false);
    const [uploadedServer, setUploadedServer] = useState<ServersList | undefined>(undefined);
    const [fileItem, setFileItem] = useState<any>();
    const [fileData, setFileData] = useState<any>([]);
    const [errMsg, setErrMsg] = useState<string>("");
    const [isDuplicate, setIsDuplicate] = useState<boolean>(false);

    const tableCols = useMemo(() => {
        return [
            {
                title: "Restore Trade Type",
                dataIndex: "tradeGroupType",
                key: "tradeGroupType",
                render: (tradeGroupType: string, rowData: Mt4Mt5ClosedTradeGroup) => {
                    return (
                        <>
                            <Tooltip
                                placement="top"
                                overlayInnerStyle={{ width: 400 }}
                                title={
                                    tradeGroupType === RESTORE_TRADE_GROUP_TYPES.GROUP_1
                                        ? "Open time not between start date and end date, Close time between start date and end date"
                                        : tradeGroupType === RESTORE_TRADE_GROUP_TYPES.GROUP_2
                                            ? "Open time between start date and end date, Close time not between start date and end date"
                                            : "Open time and Close time between start date and end date"
                                }
                            >
                                <p style={{ margin: "auto" }}>
                                    {tradeGroupType}
                                    <span style={{ fontWeight: "bold", marginLeft: "3.255vw" }}>Total Accounts : </span> {rowData.totalAccounts}
                                    <span style={{ fontWeight: "bold", marginLeft: "1.953vw" }}>Total P&L (USD) : </span> ≈{" "}
                                    {currencyRender(rowData.totalPnl, 2)}
                                    {rowData.totalSelectedAccounts > 0 && (
                                        <>
                                            <span style={{ fontWeight: "bold", marginLeft: "6.51vw" }}>Total Selected Accounts : </span>{" "}
                                            {rowData.totalSelectedAccounts}
                                            <span style={{ fontWeight: "bold", marginLeft: "1.953vw" }}>Total Selected P&L (USD) : </span> ≈{" "}
                                            {currencyRender(rowData.totalSelectedPnl, 2)}
                                        </>
                                    )}
                                </p>
                            </Tooltip>
                        </>
                    );
                },
            },
            DTColProps.Small({
                title: "Server",
                dataIndex: "serverName",
                key: "serverName",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: props.servers.map((x: ServersList) => ({ text: x.server, value: `${x.id}|${x.server}` })),
                        rules: [{ required: true, message: REQUIRED_FIELD }],
                    },
                    visible: false,
                },
            }),
            DTColProps.Small({
                title: "Symbol",
                dataIndex: "symbol",
                key: "symbol",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: props.symbols.map((x: SymbolsList) => ({ text: x.name, value: x.name })),
                        inputProps: { mode: "multiple" },
                    },
                    visible: false,
                },
            }),
            DTColProps.Small({
                title: "Account ID",
                dataIndex: "login",
                key: "login",
                options: {
                    filter: {
                        // type: ComponentType.dropdown,
                        // value: logins.map((x) => ({ text: x, value: x })),
                        // inputProps: { mode: "multiple" },
                        type: ComponentType.text,
                        value: "",
                    },
                    visible: false,
                },
            }),
            DTColProps.DateTime_ServerTime({
                title: "Start & End Date (Server)",
                dataIndex: "startEndDate",
                key: "startEndDate",
                options: {
                    filter: {
                        type: ComponentType.daterange,
                        value: "",
                        rules: [{ required: true, message: REQUIRED_FIELD }],
                        inputProps: {
                            showTime: { format: "HH:mm:ss" },
                            ranges: {
                                Today: [moment().startOf("day"), moment().endOf("day")],
                                "This Week": [moment().startOf("week"), moment().endOf("day")],
                                "This Month": [moment().startOf("month"), moment().endOf("day")],
                            },
                        },
                    },
                    visible: false,
                },
            }),
        ];
    }, [props.servers, props.symbols]);

    const expandedColumns = [
        DTColProps.XSmall({
            title: "Ticket",
            dataIndex: "ticket",
            key: "ticket",
            sorter: (a: any, b: any) => a.ticket - b.ticket,
        }),
        DTColProps.Small({
            title: "Server",
            dataIndex: "serverName",
            key: "serverName",
            render: (serverName: string) => (serverName ? serverName : "-"),
        }),
        DTColProps.XSmall({
            title: "Symbol",
            dataIndex: "symbol",
            key: "symbol",
            render: (symbol: string) => (symbol ? symbol : "-"),
            sorter: (a: any, b: any) => (a.symbol > b.symbol ? 1 : -1),
        }),
        DTColProps.Small({
            title: "Account ID",
            dataIndex: "accountId",
            key: "accountId",
            sorter: (a: any, b: any) => a.accountId - b.accountId,
        }),
        DTColProps.DateTime_ServerTime({
            title: "Open Time (Server)",
            dataIndex: "openTime",
            key: "openTime",
            sorter: (a: any, b: any) => (moment(b.openTime) > moment(a.openTime) ? -1 : 1),
            render: (value: string) => (value ? DataTableColumnRender.DateTime_ServerTime(value, "YYYY-MM-DD HH:mm:ss.SSS") : ""),
        }),
        DTColProps.DateTime_ServerTime({
            title: "Close Time (Server)",
            dataIndex: "closeTime",
            key: "closeTime",
            sorter: (a: any, b: any) => (moment(b.closeTime) > moment(a.closeTime) ? -1 : 1),
            render: (value: string) => (value ? DataTableColumnRender.DateTime_ServerTime(value, "YYYY-MM-DD HH:mm:ss.SSS") : ""),
        }),
        DTColProps.XSmall({
            title: "Profit",
            dataIndex: "profit",
            key: "profit",
            sorter: (a: any, b: any) => a.profit - b.profit,
            render: (value: number) => currencyRender(value),
            align: "right",
        }),
        DTColProps.XSmall({
            title: "Profit (USD)",
            dataIndex: "profitInUSD",
            key: "profitInUSD",
            sorter: (a: any, b: any) => a.profitInUSD - b.profitInUSD,
            render: (value: number) => currencyRender(value),
            align: "right",
        }),
        DTColProps.XSmall({
            title: "Swap",
            dataIndex: "swap",
            key: "swap",
            sorter: (a: any, b: any) => a.swap - b.swap,
            render: (value: number) => currencyRender(value),
            align: "right",
        }),
        DTColProps.XSmall({
            title: "Swap (USD)",
            dataIndex: "swapInUSD",
            key: "swapInUSD",
            sorter: (a: any, b: any) => a.swapInUSD - b.swapInUSD,
            render: (value: number) => currencyRender(value),
            align: "right",
        }),
        DTColProps.Small({
            title: "Commission",
            dataIndex: "commission",
            key: "commission",
            sorter: (a: any, b: any) => a.commission - b.commission,
            render: (value: number) => currencyRender(value),
            align: "right",
        }),
        DTColProps.Small({
            title: "Commission (USD)",
            dataIndex: "commissionInUSD",
            key: "commissionInUSD",
            sorter: (a: any, b: any) => a.commissionInUSD - b.commissionInUSD,
            render: (value: number) => currencyRender(value),
            align: "right",
        }),
        DTColProps.Middle({
            title: "Comment",
            dataIndex: "comment",
            key: "comment",
            render: (value: string) => (value === "" ? "-" : value),
        }),
    ];

    const exportColumns = useMemo(
        () => [
            { label: "Restore Trade Type", key: "tradeGroupType" },
            ...(isMt5
                ? [
                    { label: "Order", key: "order" },
                    { label: "Deal", key: "deal" },
                ]
                : [{ label: "Ticket", key: "ticket" }]),
            { label: "Server", key: "serverName" },
            { label: "Symbol", key: "symbol" },
            { label: "Account ID", key: "login" },
            ...(!isMt5 ? [{ label: "Open Time (Server)", key: "openTime" }] : []),
            { label: "Close Time (Server)", key: "closeTime" },
            { label: "Profit", key: "profit" },
            { label: "Profit (USD)", key: "profitInUSD" },
            { label: "Swap", key: "swap" },
            { label: "Swap (USD)", key: "swapInUSD" },
            { label: "Commission", key: "commission" },
            { label: "Commission (USD)", key: "commissionInUSD" },
            { label: "Comment", key: "comment" },
        ],
        [isMt5, selectedTicketObj]
    );

    const reCalculateData = (rowData: Mt4Mt5ClosedTradeGroup[], groupTypePrefix: string, selectedTickets: any) => {
        let fIdx: number = rowData.findIndex(x => `${x.tradeGroupType}|` === groupTypePrefix);
        if (fIdx > -1) {
            let totalAccounts: any = {},
                totalPnl: number = 0;
            Object.keys(selectedTickets).forEach(x => {
                if (x.indexOf(groupTypePrefix) === 0) {
                    totalAccounts[selectedTickets[x].login] = 1;
                    totalPnl += selectedTickets[x].pnl;
                }
            });
            rowData[fIdx] = { ...rowData[fIdx], totalSelectedAccounts: Object.keys(totalAccounts).length, totalSelectedPnl: totalPnl };
        }
        return rowData;
    };

    const dataMarkup = (data: Mt4Mt5ClosedTrade[]): any => {
        if (data && data.length > 0) {
            let accountsObj: any = {};
            let totalPnl: number = 0;
            let finalData = data.reduce((finalObj: any, x: Mt4Mt5ClosedTrade) => {
                if (!findObjectKeys(finalObj, [x.tradeGroupType])) {
                    finalObj[`${x.tradeGroupType}`] = {
                        tradeGroupTypeId: x.tradeGroupType,
                        tradeGroupType: x.tradeGroupType,
                        tickets: [],
                        totalAccounts: {},
                        totalPnl: 0,
                    };
                }

                finalObj[`${x.tradeGroupType}`].tickets.push({
                    ...x,
                    pnl: x.profitInUSD + x.swapInUSD + x.commissionInUSD,
                });
                finalObj[`${x.tradeGroupType}`].totalAccounts[`${isMt5 ? x.login : x.accountId}`] = 1;
                finalObj[`${x.tradeGroupType}`].totalPnl += x.profitInUSD + x.swapInUSD + x.commissionInUSD;
                accountsObj[`${isMt5 ? x.login : x.accountId}`] = 1;

                return finalObj;
            }, {});

            let tmpList = Object.keys(finalData);
            tmpList.sort((a: string, b: string) => a.toLowerCase().localeCompare(b.toLowerCase()));
            setData(
                tmpList.reduce((finalList: Mt4Mt5ClosedTradeGroup[], key: string) => {
                    finalData[key].totalAccounts = Object.keys(finalData[key].totalAccounts).length;
                    finalList.push(finalData[key]);
                    totalPnl += finalData[key].totalPnl;
                    return finalList;
                }, [])
            );
            setOverallData({
                totalAcc: Object.keys(accountsObj).length,
                totalPnl,
            });
        } else {
            setData([]);
            setOverallData({ totalAcc: 0, totalPnl: 0 });
        }
    };

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                let tempFilterParams: any = getAvailableObjectElementKeys(FormData).reduce((tFilter: any, x: any) => {
                    if (x === "serverName") {
                        let serverInfo = FormData[x].split("|");
                        if (serverInfo[1].includes("MT5")) setIsMt5(true);
                        else setIsMt5(false);
                        tFilter["serverId"] = parseInt(serverInfo[0]);
                    } else if (x === "symbol") {
                        tFilter["symbols"] = FormData[x];
                    } else if (x === "login") {
                        tFilter["logins"] = [parseInt(FormData[x])];
                    } else if (x === "startEndDate") {
                        if (FormData[x] === null) return false;
                        else {
                            tFilter["startDateTime"] = DateTimeUtil.GetOrigin(FormData[x][0]);
                            tFilter["endDateTime"] = DateTimeUtil.GetOrigin(FormData[x][1]);
                        }
                    } else {
                        tFilter[x] = FormData[x];
                    }

                    return tFilter;
                }, {});
                setFilterParams(tempFilterParams);
                setSelectedTicketObj({});
                if (
                    tempFilterParams?.hasOwnProperty("serverId") &&
                    tempFilterParams?.hasOwnProperty("startDateTime") &&
                    tempFilterParams?.hasOwnProperty("endDateTime")
                ) {
                    setRunRefetchDataList(true);
                } else if (tempFilterParams?.hasOwnProperty("serverId")) {
                    message.error(`Date range is required. Please set a date range to continue.`);
                } else if (tempFilterParams?.hasOwnProperty("startDateTime") && tempFilterParams?.hasOwnProperty("endDateTime")) {
                    message.error(`Server is required. Please choose any server to continue.`);
                } else message.error(`Server and date range is required. Please choose any server and set a date range to continue.`);
                break;
            case CALLBACK_KEY.OTHERS:
                let selectedTicketData: selectedTicketData[] = Object.values(FormData);
                navigate("/admintools/ticket/calculate", { state: { selectedTicketData, serverId: filterParams.serverId, method: "byManual" } });
                break;
            default:
                break;
        }
    };

    const getExpandedRowRender = useCallback(
        (record: any) => {
            let searchingGroupType: string = `${record.tradeGroupType}|`,
                searchingGroupTypeIdx: number = searchingGroupType.length,
                selectedKeysArr: number[] = Object.keys(selectedTicketObj).reduce((list: number[], key: string) => {
                    if (key.indexOf(searchingGroupType) === 0) {
                        list.push(parseInt(key.substring(searchingGroupTypeIdx)));
                    }
                    return list;
                }, []);
            return (
                <div>
                    <Table
                        bordered
                        className="modal-table-expanded-row"
                        size="small"
                        rowKey={`${isMt5 ? "deal" : "ticket"}`}
                        columns={expandedColumns}
                        dataSource={record.tickets}
                        pagination={{
                            showTotal: (total: number, range: number[]) =>
                                `${currencyRender(range[0])} - ${currencyRender(range[1])} of ${currencyRender(total)}`,
                            defaultPageSize: 100,
                        }}
                        scroll={{ y: 280 }}
                        rowSelection={{
                            type: "checkbox",
                            selectedRowKeys: selectedKeysArr,
                            onSelect: (selectedRecord: any, selected: boolean) => {
                                let newSelectedObj = { ...selectedTicketObj };
                                if (selected) {
                                    if (isMt5) {
                                        newSelectedObj[`${searchingGroupType}${selectedRecord.deal}`] = {
                                            login: selectedRecord.login,
                                            deal: selectedRecord.deal,
                                            serverName: selectedRecord.serverName,
                                            symbol: selectedRecord.symbol,
                                            comment: selectedRecord.comment,
                                            profit: selectedRecord.profit,
                                            pnl: selectedRecord.pnl,
                                            order: selectedRecord.order,
                                            closeTime: selectedRecord.closeTime,
                                            swap: selectedRecord.swap,
                                            commission: selectedRecord.commission,
                                            tradeGroupType: selectedRecord.tradeGroupType,
                                            profitInUSD: selectedRecord.profitInUSD,
                                            swapInUSD: selectedRecord.swapInUSD,
                                            commissionInUSD: selectedRecord.commissionInUSD,
                                        };
                                    } else {
                                        newSelectedObj[`${searchingGroupType}${selectedRecord.ticket}`] = {
                                            login: selectedRecord.accountId,
                                            ticket: selectedRecord.ticket,
                                            serverName: selectedRecord.serverName,
                                            symbol: selectedRecord.symbol,
                                            closeTime: selectedRecord.closeTime,
                                            comment: selectedRecord.comment,
                                            profit: selectedRecord.profit,
                                            pnl: selectedRecord.pnl,
                                            openTime: selectedRecord.openTime,
                                            swap: selectedRecord.swap,
                                            commission: selectedRecord.commission,
                                            tradeGroupType: selectedRecord.tradeGroupType,
                                            profitInUSD: selectedRecord.profitInUSD,
                                            swapInUSD: selectedRecord.swapInUSD,
                                            commissionInUSD: selectedRecord.commissionInUSD,
                                        };
                                    }
                                } else {
                                    if (isMt5) delete newSelectedObj[`${searchingGroupType}${selectedRecord.deal}`];
                                    else delete newSelectedObj[`${searchingGroupType}${selectedRecord.ticket}`];
                                }
                                setSelectedTicketObj(newSelectedObj);
                                setData(prev => reCalculateData(prev, searchingGroupType, newSelectedObj));
                            },
                            onSelectAll: (selected: boolean) => {
                                let newSelectedObj = { ...selectedTicketObj };
                                if (selected) {
                                    record.tickets.forEach((item: any) => {
                                        if (isMt5) {
                                            newSelectedObj[`${searchingGroupType}${item.deal}`] = {
                                                login: item.login,
                                                deal: item.deal,
                                                serverName: item.serverName,
                                                symbol: item.symbol,
                                                comment: item.comment,
                                                profit: item.profit,
                                                pnl: item.pnl,
                                                order: item.order,
                                                closeTime: item.closeTime,
                                                swap: item.swap,
                                                commission: item.commission,
                                                tradeGroupType: item.tradeGroupType,
                                                profitInUSD: item.profitInUSD,
                                                swapInUSD: item.swapInUSD,
                                                commissionInUSD: item.commissionInUSD,
                                            };
                                        } else {
                                            newSelectedObj[`${searchingGroupType}${item.ticket}`] = {
                                                login: item.accountId,
                                                ticket: item.ticket,
                                                serverName: item.serverName,
                                                symbol: item.symbol,
                                                closeTime: item.closeTime,
                                                comment: item.comment,
                                                profit: item.profit,
                                                pnl: item.pnl,
                                                openTime: item.openTime,
                                                swap: item.swap,
                                                commission: item.commission,
                                                tradeGroupType: item.tradeGroupType,
                                                profitInUSD: item.profitInUSD,
                                                swapInUSD: item.swapInUSD,
                                                commissionInUSD: item.commissionInUSD,
                                            };
                                        }
                                    });
                                } else {
                                    selectedKeysArr.forEach((ticketId: number) => {
                                        delete newSelectedObj[`${searchingGroupType}${ticketId}`];
                                    });
                                }
                                setSelectedTicketObj(newSelectedObj);
                                setData(prev => reCalculateData(prev, searchingGroupType, newSelectedObj));
                            },
                        }}
                    />
                </div>
            );
        },
        [selectedTicketObj]
    );

    const getMainTableTitleSummary = useCallback(() => {
        let tmpArrKeys = Object.keys(selectedTicketObj);
        let totalSelectedInfo: any =
            tmpArrKeys.length > 0
                ? tmpArrKeys.reduce(
                    (finalObj: any, x: string) => {
                        finalObj.totalAcc[selectedTicketObj[x].login] = 1;
                        finalObj.totalPnl += selectedTicketObj[x].pnl;
                        return finalObj;
                    },
                    { totalAcc: {}, totalPnl: 0 }
                )
                : { totalAcc: {}, totalPnl: 0 };
        return (
            <p style={{ margin: "auto" }}>
                <span style={{ fontWeight: "bold" }}>Overall Total Accounts : </span> {overallData.totalAcc ? overallData.totalAcc : "-"}
                <span style={{ fontWeight: "bold", marginLeft: "1.953vw" }}>Overall Total P&L (USD) : </span>{" "}
                {`${overallData.totalPnl !== 0 ? "≈" : ""} ${currencyRender(overallData.totalPnl, 2, "-")}`}
                {Object.keys(selectedTicketObj).length > 0 && (
                    <>
                        <span style={{ fontWeight: "bold", marginLeft: "6.51vw" }}>Overall Total Selected Accounts : </span>
                        {Object.keys(totalSelectedInfo.totalAcc).length}
                        <span style={{ fontWeight: "bold", marginLeft: "1.953vw" }}>Overall Total Selected P&L (USD) : </span>≈{" "}
                        {currencyRender(totalSelectedInfo.totalPnl, 2, "-")}
                    </>
                )}
            </p>
        );
    }, [overallData, data, selectedTicketObj]);

    const getClosedTrades = () => {
        if (isMt5) {
            apiRequest(APIs.GET_MT5_CLOSED_TRADES, {
                ...(Object.keys(filterParams).length > 0 && { ...filterParams }),
            })
                .then((data: any) => dataMarkup(data))
                .catch((error: any) => {
                    ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("MT5 closed trades", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            apiRequest(APIs.GET_MT4_CLOSED_TRADES, {
                ...(Object.keys(filterParams).length > 0 && { ...filterParams }),
            })
                .then((data: any) => dataMarkup(data))
                .catch((error: any) => {
                    ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("MT4 closed trades", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    if (isMt5) {
        expandedColumns.splice(
            0,
            1,
            DTColProps.XSmall({
                title: "Order",
                dataIndex: "order",
                key: "order",
                sorter: (a: any, b: any) => a.order - b.order,
            }),
            DTColProps.XSmall({
                title: "Deal",
                dataIndex: "deal",
                key: "deal",
                sorter: (a: any, b: any) => a.deal - b.deal,
            })
        );
        expandedColumns.splice(
            4,
            1,
            DTColProps.Small({
                title: "Account ID",
                dataIndex: "login",
                key: "login",
                sorter: (a: any, b: any) => a.login - b.login,
            })
        );
        expandedColumns.splice(5, 1);
    }

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            setRunRefetchDataList(false);
            getClosedTrades();
        }
        return () => { };
    }, [runRefetchDataList]);

    const previewButton = useMemo(() => {
        return enableUpdate && Object.keys(selectedTicketObj).length > 0
            ? {
                extraButtons: [{ text: "Preview", value: selectedTicketObj, icon: <ExportOutlined /> }],
            }
            : {};
    }, [selectedTicketObj]);

    const createHashKey = (excelRows: uploadedMt4Mt5Tickets[]) => {
        let newExcelRows: any[] = [];
        if (Object.keys(excelRows[0]).includes("Ticket")) {
            newExcelRows = excelRows.map((row: uploadedMt4Mt5Tickets) => {
                return {
                    hashKey: `${row.ServerName}|${row.Ticket}`,
                    ...row,
                };
            });
        }
        if (Object.keys(excelRows[0]).includes("Order")) {
            newExcelRows = excelRows.map((row: uploadedMt4Mt5Tickets) => {
                return {
                    hashKey: `${row.ServerName}|${row.Order}|${row.Deal}`,
                    ...row,
                };
            });
        }
        return newExcelRows;
    };

    const checkForInvalidServerNames = (excelRows: uploadedMt4Mt5Tickets[]) => {
        for (let i = 0; i < excelRows.length; i++) {
            let uploadedServer = props.servers.find((x: ServersList) => x.server.toLowerCase() === excelRows[i].ServerName.toLowerCase());
            if (isEmptyOrNull(uploadedServer)) {
                return true;
            }
        }
        return false;
    };

    const convertExcelToJSON = (uploadedFile: any) => {
        if (!uploadedFile) return null;
        if (uploadedFile.size > 5000000) {
            message.error("Maximum file size is 5 mb");
            return null;
        }

        /* Boilerplate to set up FileReader */
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;

        // Set up callback for when FileReader is done loading
        reader.onload = (event: any) => {
            let duplicates: any[] = [];

            /* Parse data */
            const bstr = event.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array", bookVBA: true, raw: true });

            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];

            /* Convert array of arrays */
            const excelRows: uploadedMt4Mt5Tickets[] = XLSX.utils.sheet_to_json(ws);
            // console.log("sheet to json: ", { excelRows });

            if (excelRows.length === 0) {
                message.error(`File is empty`);
                return null;
            }

            let newArr = createHashKey(excelRows).reduce((finalArr: any, currRow: any) => {
                if (!finalArr[currRow["hashKey"]]) {
                    finalArr[currRow["hashKey"]] = [];
                }
                finalArr[currRow["hashKey"]].push(currRow);
                return finalArr;
            }, {});
            Object.values(newArr).forEach((currArr: any) => {
                if (currArr.length > 1) {
                    duplicates.push(currArr[0]);
                }
            });

            if (duplicates.length > 0) {
                setErrMsg(
                    `There are ${duplicates.length} duplicate(s) of ${excelRows[0].ServerName.toLowerCase().includes("mt4") ? "MT4 tickets" : "MT5 deals"
                    } found in the csv file.`
                );
                setFileData(excelRows);
                setIsDuplicate(true);
            } else {
                if (checkForInvalidServerNames(excelRows)) {
                    setErrMsg(`Server name not found in the system. Please verify the spelling and try again.`);
                    setFileData(excelRows);
                    setIsDuplicate(false);
                    setUploadedServer(undefined);
                } else {
                    let currUploadedServer = props.servers.find((x: ServersList) => x.server.toLowerCase() === excelRows[0].ServerName.toLowerCase());
                    setUploadedServer(currUploadedServer);
                    setFileItem(uploadedFile);
                    setFileData(excelRows);
                    setIsDuplicate(false);
                    setErrMsg("");
                }
            }
        };

        // Call FileReader
        if (rABS) {
            reader.readAsBinaryString(uploadedFile);
        } else {
            reader.readAsArrayBuffer(uploadedFile);
        }
    };

    const uploadProps: UploadProps = {
        name: "file",
        customRequest: uploadDummyRequest,
        accept: ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
        onChange(info) {
            // if (info.file.status !== "uploading") {
            //     console.log(info.file, info.fileList);
            // }
            if (info.file.status === "error") {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        multiple: true,
        showUploadList: false,
        beforeUpload: (file: any) => {
            convertExcelToJSON(file);
        },
    };

    const mt4ColumnsTemplate = [
        {
            title: "ServerName",
            key: "ServerName",
        },
        {
            title: "Login",
            key: "Login",
        },
        {
            title: "Ticket",
            key: "Ticket",
        },
        {
            title: "Symbol",
            key: "Symbol",
        },
        {
            title: "Profit",
            key: "Profit",
        },
        {
            title: "ServerOpenTime",
            key: "ServerOpenTime",
        },
        {
            title: "ServerCloseTime",
            key: "ServerCloseTime",
        },
        {
            title: "Comment",
            key: "Comment",
        },
    ];

    const mt5ColumnsTemplate = [
        {
            title: "ServerName",
            key: "ServerName",
        },
        {
            title: "Login",
            key: "Login",
        },
        {
            title: "Order",
            key: "Order",
        },
        {
            title: "Deal",
            key: "Deal",
        },
        {
            title: "Symbol",
            key: "Symbol",
        },
        {
            title: "Profit",
            key: "Profit",
        },
        {
            title: "ServerCloseTime",
            key: "ServerCloseTime",
        },
        {
            title: "Comment",
            key: "Comment",
        },
    ];

    const wholeData: any = useMemo(() => {
        let newData: any = [];
        if (data.length > 0) {
            data.forEach((y: any) => {
                y.tickets.forEach((x: any) => {
                    newData.push({
                        ...x,
                        tradeGroupType: y.tradeGroupType,
                        login: isMt5 ? x.login : x.accountId,
                    });
                });
            });
            return newData;
        } else {
            return [];
        }
    }, [data, isMt5]);

    return (
        <div>
            <div className="ticket-restoration-content-navs">
                <Segmented
                    value={currentActiveSegment}
                    options={SegmentList.map((x: NavigationItem) => ({
                        label: x.title,
                        value: x.key,
                    }))}
                    onChange={(activeKey: any) => setCurrentActiveSegment(activeKey)}
                />
                {currentActiveSegment === "2" && (
                    <div className="extra-buttons">
                        <Upload {...uploadProps}>
                            <Button
                                icon={<UploadOutlined />}
                                style={{
                                    width: "auto",
                                    marginRight: 10,
                                }}
                            >
                                Upload Tickets
                            </Button>
                        </Upload>
                        <Dropdown
                            trigger={["click"]}
                            menu={{
                                items: [
                                    {
                                        key: "1",
                                        label: (
                                            <CSVLink
                                                key={`btn-export-${Math.random()}`}
                                                data={[
                                                    {
                                                        ServerName: "MT4_SERVER_NAME",
                                                        Login: 1234567890,
                                                        Ticket: 1234567890,
                                                        Symbol: "TESTING123",
                                                        Profit: 0,
                                                        ServerOpenTime: "01/01/1970 00:00:00",
                                                        ServerCloseTime: "01/01/1970 00:00:00",
                                                        Comment: "dummy comment",
                                                    },
                                                ]}
                                                headers={mt4ColumnsTemplate.map(x => {
                                                    return { label: x.title, key: x.key };
                                                })}
                                                filename={`MT4_Ticket_Restoration_Batch_Upload_Format-${new Date().getTime()}.csv`}
                                            >
                                                MT4 template
                                            </CSVLink>
                                        ),
                                    },
                                    {
                                        key: "2",
                                        label: (
                                            <CSVLink
                                                key={`btn-export-${Math.random()}`}
                                                data={[
                                                    {
                                                        ServerName: "MT5_SERVER_NAME",
                                                        Login: 1234567890,
                                                        Order: 1234567890,
                                                        Deal: 1234567890,
                                                        Symbol: "TESTING123",
                                                        Profit: 0,
                                                        ServerCloseTime: "01/01/1970 00:00:00.000",
                                                        Comment: "dummy comment",
                                                    },
                                                ]}
                                                headers={mt5ColumnsTemplate.map(x => {
                                                    return { label: x.title, key: x.key };
                                                })}
                                                filename={`MT5_Ticket_Restoration_Batch_Upload_Format-${new Date().getTime()}.csv`}
                                            >
                                                MT5 template
                                            </CSVLink>
                                        ),
                                    },
                                ],
                            }}
                            placement="bottomRight"
                            arrow
                        >
                            <Button icon={<DownloadOutlined />}>Download Template</Button>
                        </Dropdown>
                    </div>
                )}
            </div>
            <div className="ticket-restoration-ticket-list-tab-content">
                <div className="content">
                    <div className="major-content">
                        {currentActiveSegment === "1" && (
                            <div className="content">
                                <FlexiDataTable
                                    rowKeyProperty="tradeGroupTypeId"
                                    title={getMainTableTitleSummary()}
                                    columns={tableCols}
                                    options={{
                                        ...previewButton,
                                        expandable: {
                                            expandedRowRender: getExpandedRowRender,
                                        },
                                        ...(data.length > 0 && {
                                            export: {
                                                text: "",
                                                Element: (
                                                    <Dropdown
                                                        trigger={["click"]}
                                                        menu={{
                                                            items: [
                                                                {
                                                                    key: "selected",
                                                                    label: (
                                                                        <CSVLink
                                                                            key={`btn-export-${Math.random()}`}
                                                                            data={Object.values(selectedTicketObj)
                                                                                .map((y: any) => ({ ...y }))
                                                                                .map((x: any) => {
                                                                                    return {
                                                                                        ...x,
                                                                                        openTime: DataTableColumnRender.DateTime_ServerTime(
                                                                                            x.openTime
                                                                                        ),
                                                                                        closeTime: DataTableColumnRender.DateTime_ServerTime(
                                                                                            x.closeTime
                                                                                        ),
                                                                                    };
                                                                                })}
                                                                            headers={exportColumns}
                                                                            filename={`Ticket_Restoration_Selected_List-${new Date().getTime()}.csv`}
                                                                        >
                                                                            Selected only
                                                                        </CSVLink>
                                                                    ),
                                                                    disabled: Object.keys(selectedTicketObj).length === 0,
                                                                },
                                                                {
                                                                    key: "all",
                                                                    label: (
                                                                        <CSVLink
                                                                            key={`btn-export-${Math.random()}`}
                                                                            data={wholeData.map((x: any) => {
                                                                                return {
                                                                                    ...x,
                                                                                    openTime: DataTableColumnRender.DateTime_ServerTime(x.openTime),
                                                                                    closeTime: DataTableColumnRender.DateTime_ServerTime(x.closeTime),
                                                                                };
                                                                            })}
                                                                            headers={exportColumns}
                                                                            filename={`Ticket_Restoration_List-${new Date().getTime()}.csv`}
                                                                        >
                                                                            All
                                                                        </CSVLink>
                                                                    ),
                                                                },
                                                            ],
                                                        }}
                                                        placement="bottomRight"
                                                        arrow
                                                    >
                                                        <Button icon={<DownloadOutlined />} style={{ marginLeft: 10 }}>
                                                            Export as CSV
                                                        </Button>
                                                    </Dropdown>
                                                ),
                                            },
                                        }),
                                    }}
                                    callback={componentCallback}
                                    dataSource={data || []}
                                    loading={isLoading}
                                />
                            </div>
                        )}
                        {currentActiveSegment === "2" && (
                            <BatchUploadTab
                                uploadedServer={uploadedServer}
                                fileItemData={fileData || []}
                                servers={props.servers}
                                symbols={props.symbols}
                                errorMessage={errMsg}
                                isDuplicate={isDuplicate || false}
                                uploadedFileItem={fileItem}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TicketList;
