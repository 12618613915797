import React, { useEffect, useState } from "react";
import FlexiDataTable from "../../../../components/FlexiDataTable";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../../../utils/Common";
import { CALLBACK_KEY, ComponentType, InnerPageActionMode, SUCCESS_FAILED } from "../../../../constants";
import { CreateEditInlineFormBased, FlexiDataTableCallbackProps, ToxicClientLabelProps } from "../../../../constants/type";
import { Tag } from "antd";
import { APIs, apiRequest } from "../../../../services/apiConfig";
import AuthHelper, { AuthKeys } from "../../../../helpers/authHelper";
import ToxicClientLabelCreateEdit from "./CreateEditPage";

const ToxicClientLabelConfigList = () => {
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.TOXIC_CLIENT_CHECK_EDIT);

    const [data, setData] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isFirstOpt, setIsFirstOpt] = useState<boolean>(true);
    const [refetch, setRefetch] = useState<number>(0);
    const [formObject, setFormObject] = useState<CreateEditInlineFormBased>({ mode: InnerPageActionMode.CREATE_NEW });

    const columns: any[] = [
        DTColProps.Middle({
            title: "Label Name",
            dataIndex: "labelName",
            key: "labelName",
            options: {
                filter: {
                    type: ComponentType.text,
                },
            },
        }),
        DTColProps.Middle({
            title: "Label Description",
            dataIndex: "labelDescription",
            key: "labelDescription",
        }),
        DTColProps.Large({
            title: "Label Attributes",
            dataIndex: "labelAttrs",
            key: "labelAttrs",
            render: (labelAttrs: any) => {
                return labelAttrs.map((currAttr: any) => (
                    <Tag
                        key={currAttr.attrId}
                        style={{ marginBottom: "0.9898vh", padding: "0 0.651vw" }}
                        color={currAttr.isAttrActive ? "green" : ""}
                    >
                        {currAttr.attrName}
                    </Tag>
                ));
            },
        }),
        DTColProps.Status({
            title: "Status",
            dataIndex: "isLabelActive",
            key: "isLabelActive",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: [
                        { text: "Active", value: true },
                        { text: "Inactive", value: false },
                    ],
                },
            },
        }),
        DTColProps.XSmall({
            title: "Label Entry Count",
            dataIndex: "labelEntryCount",
            key: "labelEntryCount",
            sorter: (a: ToxicClientLabelProps, b: ToxicClientLabelProps) => (a.labelEntryCount > b.labelEntryCount ? 1 : -1),
        }),
    ];

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, rowData: ToxicClientLabelProps) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                setIsFirstOpt(prev => !prev);
                setFormObject({ mode: InnerPageActionMode.CREATE_NEW, id: undefined });
                setRefetch(prev => prev + 1);
                break;
            case CALLBACK_KEY.DO_EDIT:
                setIsFirstOpt(prev => !prev);
                setFormObject({ mode: InnerPageActionMode.EDIT, id: rowData["labelId"] });
                setRefetch(prev => prev + 1);
                break;
            case CALLBACK_KEY.DO_DELETE:
                setIsLoading(true);
                apiRequest(APIs.DELETE_TOXIC_CLIENT_LABEL, { labelId: rowData["labelId"] })
                    .then((res: any) => {
                        ErrorMessageHandler(`Toxic client label [ ${rowData.labelName} ]`, SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                        setTimeout(() => getToxicClientLabelList(), 300);
                    })
                    .catch((error: any) => {
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("toxic client label", SUCCESS_FAILED.FAILED_DELETE_DATA, err));
                    })
                    .finally(() => setIsLoading(false));
                break;
            default:
                break;
        }
    };

    const getToxicClientLabelList = () => {
        setIsLoading(true);
        apiRequest(APIs.GET_TOXIC_CLIENT_LABEL_LIST, {})
            .then((res: any) => {
                if (res.length > 0) setData(res);
                else setData([]);
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("toxic client label list", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        getToxicClientLabelList();
        return () => {};
    }, []);

    return (
        <div className="single-page with-background">
            <div className={`${isFirstOpt ? "active" : ""}`}>
                <FlexiDataTable
                    bordered
                    rowKeyProperty="labelId"
                    title=""
                    columns={columns}
                    options={{
                        add: enableUpdate,
                        edit: enableUpdate,
                        delete: enableUpdate
                            ? (record: ToxicClientLabelProps, option: any) => (record.labelEntryCount > 0 ? undefined : option)
                            : false,
                    }}
                    dataSource={data ?? []}
                    callback={componentCallback}
                    loading={isLoading}
                />
            </div>
            <div className={`${isFirstOpt ? "" : "active"}`}>
                <ToxicClientLabelCreateEdit
                    {...formObject}
                    callback={(action: InnerPageActionMode, value: any) => {
                        switch (action) {
                            case InnerPageActionMode.BACK:
                                setIsFirstOpt(prev => !prev);
                                if (value?.hasOwnProperty("refreshMainList") && value["refreshMainList"]) {
                                    getToxicClientLabelList();
                                }
                                break;
                        }
                    }}
                    resetState={refetch}
                />
            </div>
        </div>
    );
};

export default ToxicClientLabelConfigList;
