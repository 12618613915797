import { useCallback, useEffect, useState } from "react";
import { AlarmDashboardFilterParamsProps } from "../../alarmDashboard";
import { ALARM_DASHBOARD_ITEM_CALLBACK_KEY } from "../../../../constants";
import { APIs, apiRequest } from "../../../../services/apiConfig";
import { ErrorCatchValidator } from "../../../../utils/Common";
import AlarmDashboardAssignPanel from "./alarmDashboardAssignPanel";
import { getProfile } from "../../../../services/localStorage";
import AlarmDashboardUnAssignedPanel from "./alarmDashboardUnassignedPanel";
import { TimelineItemProps } from "./alarmDashboardTimelineItem";
import { DefaultIfEmpty } from "../../../../utils/object";
import AlarmDashboardCommentHistory from "../alarmEventComponent/alarmDashboardCommentHistory";
import AlarmDashboardGroupProgress from "../alarmEventComponent/alarmDashboardGroupProgress";
import AlarmDashboardLeaveComment from "../alarmEventComponent/alarmDashboardLeaveComment";
import AlarmDashboardTaskAssign from "../alarmEventComponent/alarmDashboardTaskAssign";
import { FormInstance, Spin } from "antd";
import { GetObjectCountByKey } from "../../../../utils/array";
import { isEmptyOrNull } from "../../../../utils/string";
import AuthHelper, { AuthKeys } from "../../../../helpers/authHelper";
import { callbackParams, KeyValuePair, ModalProps } from "../../../../constants/type";

export interface AlarmDashboardContentComponentsProps {
    filterParams: AlarmDashboardFilterParamsProps;
    statusObject: any;
    isLoading: boolean;
    mappingData: any;
    lpMappingData: any;
    userOptions: KeyValuePair[];
    refreshState: number;
    isMute: boolean;
}

export const getAlarmItemFeatureModalProps = (
    serverTime_UTCOffset: number | undefined,
    currentItem: callbackParams | undefined,
    statusOptionsObject: { [key: string]: any[] },
    mappingData: { [key: string]: any },
    lpMappingData: { [key: string]: any },
    userOptions: KeyValuePair[],
    form: FormInstance
) => {
    let returnParams: ModalProps = {
        component: <></>,
        extraModalProps: undefined,
    };

    if (currentItem !== undefined) {
        switch (currentItem.type) {
            case ALARM_DASHBOARD_ITEM_CALLBACK_KEY.COMMENT_HISTORICAL:
                returnParams.component = <AlarmDashboardCommentHistory data={currentItem.data} />;
                returnParams.extraModalProps = {
                    title: "Comment History",
                    width: "40%",
                    footer: null,
                };
                break;
            case ALARM_DASHBOARD_ITEM_CALLBACK_KEY.SEVERITY_PROGRESS:
                returnParams.component = (
                    <AlarmDashboardGroupProgress
                        utcOffset={serverTime_UTCOffset}
                        data={currentItem.data}
                        mappingData={mappingData}
                        lpMappingData={lpMappingData}
                        statusOptions={DefaultIfEmpty(statusOptionsObject, currentItem.data.moduleCode, [])}
                    />
                );
                returnParams.extraModalProps = {
                    title: `Comments${!isEmptyOrNull(currentItem.data.correlationHashKey) && " and Breach Severity Progress"}`,
                    width: "46%",
                    footer: null,
                };
                break;
            case ALARM_DASHBOARD_ITEM_CALLBACK_KEY.LEAVE_COMMENT:
                returnParams.component = (
                    <AlarmDashboardLeaveComment form={form} statusOptions={DefaultIfEmpty(statusOptionsObject, currentItem.data.moduleCode, [])} />
                );
                returnParams.extraModalProps = {
                    title: "Comment",
                    width: "30%",
                };
                break;
            case ALARM_DASHBOARD_ITEM_CALLBACK_KEY.TASK_ASSIGN:
                returnParams.component = <AlarmDashboardTaskAssign form={form} userOptions={userOptions} />;
                returnParams.extraModalProps = {
                    title: "Task Assigning",
                    width: "30%",
                };
                break;
        }
    }

    return returnParams;
};

export const processAlarmItemStackCountAndGrouping = (list: TimelineItemProps[]) => {
    let countObj: { [key: string]: number } = GetObjectCountByKey(list, "correlationHashKey"),
        tmpObj: { [key: string]: boolean } = {};
    return list
        .map(x => ({ ...x, stackCount: DefaultIfEmpty(countObj, x.correlationHashKey, 0) }))
        .filter(x => {
            if (!isEmptyOrNull(x.correlationHashKey)) {
                if (tmpObj?.hasOwnProperty(x.correlationHashKey as string)) return false;

                tmpObj[x.correlationHashKey as string] = true;
                return true;
            }

            return true;
        });
};

const AlarmDashboardContentComponents = (props: AlarmDashboardContentComponentsProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<any>({ assigned: [], unassigned: [] });
    const uPr: any = getProfile();
    const serverTime_UTCOffset: number | undefined = uPr?.hasOwnProperty("stom") ? uPr.stom : undefined;
    const authHp = new AuthHelper();
    const allowEditable: boolean = authHp.isAuthorized(AuthKeys.ALARM_CENTER_DASHBOARD_EDIT);

    const speechReading = useCallback(
        (pendingEvents: number) => {
            if (!props.isMute && pendingEvents > 0) {
                var utterance = new SpeechSynthesisUtterance(`警报中心页面，有 ${pendingEvents} 待解决的警报需要处理！！快快去看呀！`);
                utterance.lang = "zh-CN";
                window.speechSynthesis.speak(utterance);
            }
        },
        [props.isMute]
    );

    const timelineItemCallback = (type: ALARM_DASHBOARD_ITEM_CALLBACK_KEY, data: any) => {
        switch (type) {
            case ALARM_DASHBOARD_ITEM_CALLBACK_KEY.REFRESH_LIST:
                getEventList();
                break;
        }
    };

    const getEventList = useCallback(() => {
        setIsLoading(true);
        apiRequest(APIs.GET_ALARM_DASHBOARD_EVENT_LIST, { ...props.filterParams })
            .then(res => {
                if (!isEmptyOrNull(res)) {
                    let unassigedTask = processAlarmItemStackCountAndGrouping(res.unassigned);
                    setData({
                        assigned: processAlarmItemStackCountAndGrouping(res.assigned),
                        unassigned: unassigedTask,
                    });

                    setTimeout(() => speechReading(unassigedTask.length), 500);
                } else {
                    setData({ assigned: [], unassigned: [] });
                }
            })
            .catch(error => ErrorCatchValidator(error, (err: any) => console.log("Error load data.", err)))
            .finally(() => setIsLoading(false));
    }, [props.filterParams, speechReading]);

    useEffect(() => {
        !props.isLoading && getEventList();
    }, [props.filterParams, props.isLoading, props.refreshState]);

    return (
        <Spin spinning={isLoading}>
            <div className="main-content-container">
                <div className="left">
                    <AlarmDashboardUnAssignedPanel
                        data={data.unassigned}
                        callback={timelineItemCallback}
                        utcOffset={serverTime_UTCOffset}
                        isLoading={isLoading}
                        mappingData={props.mappingData}
                        lpMappingData={props.lpMappingData}
                        statusObject={props.statusObject}
                        userOptions={props.userOptions}
                        editable={allowEditable}
                    />
                </div>
                <div className="right">
                    <AlarmDashboardAssignPanel
                        data={data.assigned}
                        callback={timelineItemCallback}
                        utcOffset={serverTime_UTCOffset}
                        isLoading={isLoading}
                        mappingData={props.mappingData}
                        lpMappingData={props.lpMappingData}
                        statusObject={props.statusObject}
                        userOptions={props.userOptions}
                        editable={allowEditable}
                    />
                </div>
            </div>
        </Spin>
    );
};

export default AlarmDashboardContentComponents;
