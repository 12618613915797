import { useQuery } from "@tanstack/react-query";
import { APIs } from "../services/apis";
import { plainAxiosInstance } from "../services/axiosSetup";

/* sample api response
"serverTime": "2024-06-13 05:27:03",
    "data": [
      {
        "dateTime": "2024-06-13T02:14:12.000+00:00",
        "dateTimeStr": "2024/06/13 05:14:12",
        "server": "MT5-AU",
        "serverUno": 16,
        "symbol": "HK50.st",
        "cleanSymbol": "HK50",
        "symbolType": 0,
        "compareServer": "IC-ECN",
        "cserverUno": 501,
        "compareSymbol": "HK50",
        "alarmType": 2,
        "scoreSum": 23.66999449372148,
        "scoreMax": 1.8981565676096355,
        "ppmcc": 99.21839452480222,
        "solved": 1,
        "solvedTime": "2024-06-13T02:21:11.000+00:00",
        "solvedTimeStr": "2024/06/13 05:21:11",
        "solvedUser": "lisa.li@hytechc.com"
      },

*/

interface IRCMidBiasResponse {
    serverTime: string;
    data: IRCMidBiasSolved[];
}

interface IRCMidBiasSolved {
    dateTime: string;
    dateTimeStr: string;
    server: string;
    serverUno: number;
    symbol: string;
    cleanSymbol: string;
    symbolType: number;
    compareServer: string;
    cserverUno: number;
    compareSymbol: string;
    alarmType: number;
    scoreSum: number;
    scoreMax: number;
    ppmcc: number;
    solved: number;
    solvedTime: string;
    solvedTimeStr: string;
    solvedUser: string;
}

const useRCMidBiasSolved = ({ duration, from, to = null }: { duration: "20min" | "1day" | number; from: Date; to?: Date | null }) => {
    //convert request to form data
    const durationToNumber = typeof duration === "number" ? duration : duration === "20min" ? 20 : duration === "1day" ? 1440 : 20;

    const { data, refetch, isLoading, error, isFetching, dataUpdatedAt } = useQuery({
        queryKey: ["rcMidBiasSolved", duration, from, to],
        queryFn: () =>
            plainAxiosInstance.get(`${APIs.RC_MIDBIAS.GET_MIDBIAS_SOLVED}/${durationToNumber}/1`, {
                headers: {
                    Accept: "application/json",
                },
            }),
        staleTime: 15 * 1000, //(60 x 1000ms = 1minute)
        refetchInterval: 15 * 1000,
    });

    const rcMidBiasSolved: IRCMidBiasResponse = data?.data?.status ? null : data?.data || null;
    const decimalFormat = (value: any, fixed: number) => {
        return parseFloat(value)
            .toFixed(fixed)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    };

    const massagedData = !rcMidBiasSolved
        ? []
        : rcMidBiasSolved?.data
              // const massagedData = dummyMidBias1Day?.data
              ?.map(item => {
                  return {
                      ...item,
                      rowKey: `${item.server}_${item.symbol}_${item.compareServer}_${item.compareSymbol}_${item.dateTimeStr}`,
                      showScoreSum: decimalFormat(item.scoreSum, 2),
                      showMaxScore: decimalFormat(item.scoreMax, 2),
                      showPPMCC: decimalFormat(item.ppmcc, 2),
                  };
              })
              .filter((item: IRCMidBiasSolved) => {
                  // if to is not null, filter data between from and to, ignore the time, as long it matched the date
                  if (to) {
                      const itemDate = new Date(item.dateTime);
                      return itemDate >= from && itemDate <= to;
                  }
                  return true;
              });

    // @ts-ignore
    const rcMidBiasSolvedError = data?.data?.message || error?.message || error || null;

    return {
        rcMidBiasSolved,
        massagedData,
        // dummy: dummyMidBias1Day,
        dataUpdatedAt,
        rcMidBiasSolvedError,
        refetchRcMidBiasSolved: refetch,
        isFetching,
        isLoading,
    };
};

export default useRCMidBiasSolved;
