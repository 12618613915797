import { FieldTimeOutlined, FileTextOutlined, UserDeleteOutlined, UserOutlined } from "@ant-design/icons";
import { Tabs } from "antd";
import { useMemo } from "react";
import ChangeSecurityHistory from "./ChangeSecurityHistory";
import MTClientSecurityList from "./MTClientSecurityList";
import ResignForChangeSecurity from "./ResignForChangeSecurity";
import ScheduledChangeSecurity from "./ScheduledChangeSecurity";

const MTClientSecurity = () => {
    const tabItems = useMemo(() => [
      {
        label: (
          <span>
            <UserOutlined />
            MT Client Security List
          </span>
        ),
        key: "1",
        children: <MTClientSecurityList />,
      },
      {
        label: (
          <span>
            <FieldTimeOutlined />
            Scheduled Change Security
          </span>
        ),
        key: "2",
        children: <ScheduledChangeSecurity />,
      },
      {
        label: (
          <span>
            <UserDeleteOutlined />
            Resign For Change Security
          </span>
        ),
        key: "3",
        children: <ResignForChangeSecurity />,
      },
      {
        label: (
          <span>
            <FileTextOutlined />
            Change Security History
          </span>
        ),
        key: "4",
        children: <ChangeSecurityHistory />,
      }],[]
    );

    return (
        <div className="mt-client-security-container">
            <div className="header-row">
                <div className="left">
                    <div className="main-title">MT Client Security</div>
                </div>
            </div>
            <Tabs type="card" defaultActiveKey="1" tabBarStyle={{ marginBottom: "0" }} items={tabItems}/>
        </div>
    );
};

export default MTClientSecurity;
