import React, { useState, useEffect, useRef } from "react";
import { Button, Slider, Space, notification } from "antd";
import { FaVolumeDown, FaVolumeUp, FaVolumeMute, FaVolumeOff } from "react-icons/fa";
import useSound from "use-sound";

interface SoundButtonProps {
    soundSrc?: string; // Specify the type correctly
    isActive?: boolean; // depends when should the sound play
    repeatTime?: number; // repeat time in seconds
    isMutedOutside?: boolean;
    isMutedOutsideCallback?: (isMuted: boolean) => void;
}

const Context = React.createContext({ name: "Default" });

const SoundController: React.FC<{
    isMuted: boolean;
    toggleMute: () => void;
    volume: number;
    handleVolumeChange: (value: number) => void;
}> = ({ isMuted, toggleMute, volume, handleVolumeChange }) => {
    return (
        <Space size={4} style={{ alignItems: "center", justifyContent: "center" }}>
            <Button
                onClick={toggleMute}
                style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                type={isMuted ? "ghost" : "primary"}
                shape="circle"
            >
                {isMuted ? <FaVolumeMute /> : volume === 0 ? <FaVolumeOff /> : volume < 50 ? <FaVolumeDown /> : <FaVolumeUp />}
            </Button>
            <Slider style={{ width: 50 }} min={0} max={100} value={isMuted ? 0 : volume} onChange={handleVolumeChange} />
        </Space>
    );
};

const SoundButton: React.FC<SoundButtonProps> = ({
    soundSrc = "/path/to/default/sound.mp3", // Provide a default sound source
    isActive = true,
    repeatTime,
    isMutedOutside,
    isMutedOutsideCallback,
}) => {
    const [isMuted, setIsMuted] = useState(isMutedOutside ?? false);
    const [volume, setVolume] = useState(50);
    const [playSound, { stop: stopSound, sound }] = useSound(soundSrc, { volume: volume / 100, interrupt: true });
    const isMountedRef = useRef(false);

    const toggleMute = () => {
        setIsMuted(!isMuted);
        if (isMutedOutsideCallback) {
            isMutedOutsideCallback(!isMuted);
        }
    };
    const handleVolumeChange = (value: number) => {
        setIsMuted(false);
        setVolume(value);
    };

    const [api, contextHolder] = notification.useNotification();
    // notification.warning({
    //     message: "Autoplay was prevented",
    //     description: "Please click Play button to enable autoplay.",
    //     btn,
    //     duration: null,
    //     key,
    // });
    const [isSuspensed, setIsSuspensed] = useState(false);
    const openNotification = () => {
        api.info({
            message: `Autoplay was prevented`,
            description: `Please click Play button to enable autoplay.`,
            duration: null,
            key: "autoplayNotification",
            btn: (
                <Button
                    type="primary"
                    size="small"
                    onClick={() => {
                        setIsSuspensed(false);
                        playSound();
                        notification.close("autoplayNotification");
                    }}
                >
                    Play
                </Button>
            ),
        });
    };

    useEffect(() => {
        if (isMutedOutside !== undefined && isMutedOutside !== isMuted) {
            setIsMuted(isMutedOutside);
        }
    }, [isMutedOutside]);

    useEffect(() => {
        if (isSuspensed) {
            // check if the notification is already open from another source, if yes dont open double notification

            openNotification();
        } else {
            notification.close("autoplayNotification");
        }
    }, [isSuspensed]);

    useEffect(() => {
        const context = new AudioContext();
        // console.log(context);
        // console.log(context.state);
        if (context.state === "suspended") {
            setIsSuspensed(true);
        } else {
            setIsSuspensed(false);
        }
        isMountedRef.current = true;
        let intervalId: any;

        if (!isMuted && isActive) {
            playSound();
            intervalId = setInterval(
                () => {
                    if (!isMountedRef.current) return;
                    stopSound();
                    playSound();
                },
                repeatTime !== null && repeatTime !== undefined ? repeatTime * 1000 : (sound?.duration() ?? 0) * 1000 + 1000
            );
        } else {
            stopSound();
        }

        return () => {
            isMountedRef.current = false;
            stopSound();
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [isMuted, playSound, stopSound, isActive, repeatTime, sound]);

    // if suspended, make a notification to user to click on OK to resume the audio context
    // if (isSuspensed) {
    //     const key = "autoplayNotification";
    //     const btn = (
    //         <Button
    //             type="primary"
    //             size="small"
    //             onClick={() => {
    //                 setIsSuspensed(false);
    //                 playSound();
    //                 notification.close(key);
    //             }}
    //         >
    //             Play
    //         </Button>
    //     );

    //     notification.warning({
    //         message: "Autoplay was prevented",
    //         description: "Please click Play button to enable autoplay.",
    //         btn,
    //         duration: null,
    //         key,
    //     });
    // }

    return (
        <>
            {contextHolder}
            <SoundController isMuted={isMuted} toggleMute={toggleMute} volume={volume} handleVolumeChange={handleVolumeChange} />
        </>
    );
};

export default SoundButton;
