import { useState, useEffect, useMemo } from "react";
import { Tag, Modal, message, Button, Popconfirm, Tooltip } from "antd";
import { CheckCircleOutlined, DeleteOutlined, ExclamationCircleOutlined, FolderOpenOutlined, MailOutlined } from "@ant-design/icons";
import FlexiDataTable from "../../../../components/FlexiDataTable";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../../../utils/Common";
import { stepInfo } from "./stepInfo";
import { FlexiDataTableCallbackProps, FlexiDataTableOptionsProps, RollOverBatchRecordList } from "../../../../constants/type";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "../../../../constants";
import { plainAxiosInstance } from "../../../../services/axiosSetup";
import { APIs } from "../../../../services/apis";
import CardBox from "../../../../components/Common/CardBox";
import AuthHelper, { AuthKeys } from "../../../../helpers/authHelper";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { getRollOverBatchRecordListDummyData } from "./dummydata/getRollOverBatchRecordListDummyData";
import { isEmptyOrNull } from "@/utils/string";

const RollOverTool = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [batchData, setBatchData] = useState<RollOverBatchRecordList[]>([]);
    const [emailTo, setEmailTo] = useState<string>("");
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.RISK_TOOLS_ROLLOVER_EDIT);
    let navigate = useNavigate();
    const [steps, setSteps] = useState([0, 1, 2, 3, 4]);

    const columns = useMemo(() => {
        return [
            DTColProps.XSmall({
                title: "Batch ID",
                dataIndex: "id",
                key: "id",
            }),
            DTColProps.XSmall({
                title: "Symbol",
                dataIndex: "cleanSymbol",
                key: "cleanSymbol",
            }),
            DTColProps.XSmall({
                title: "Current Step",
                dataIndex: "currentStep",
                key: "currentStep",
                render: (currentStep: number) => stepTag(currentStep),
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: stepInfo.map(x => ({
                            text: x.label,
                            value: x.value,
                        })),
                        inputProps: {
                            mode: "multiple",
                            allowClear: true,
                        },
                        callback: (filterValue: any, rowData: any) => {
                            if (isEmptyOrNull(filterValue as number[])) return true;

                            return (filterValue as number[]).includes(rowData["currentStep"] || 0);
                        },
                    },
                },
            }),
            DTColProps.DateTime({
                width: "15vw",
                title: "Create Time",
                dataIndex: "createDate",
                key: "createDate",
                sorter: (a: RollOverBatchRecordList, b: RollOverBatchRecordList) => (moment(a.createDate) > moment(b.createDate) ? -1 : 1),
                render: (createDate: string) => <span>{createDate ? moment(createDate).format("YYYY-MM-DD HH:mm:ss") : ""}</span>,
            }),
            DTColProps.DateTime({
                width: "15vw",
                title: "Last Modify Time",
                dataIndex: "updateDate",
                key: "updateDate",
                sorter: (a: RollOverBatchRecordList, b: RollOverBatchRecordList) => (moment(a.updateDate) > moment(b.updateDate) ? -1 : 1),
                render: (updateDate: string) => <span>{updateDate ? moment(updateDate).format("YYYY-MM-DD HH:mm:ss") : ""}</span>,
            }),
            DTColProps.XSmall({
                title: "",
                dataIndex: "action",
                key: "action",
                render: (value: string, rowData: RollOverBatchRecordList) => (
                    <>
                        {enableUpdate && (
                            <Tooltip title="Edit Batch" key={`open-roll-${rowData.id}`}>
                                <Button
                                    type="text"
                                    icon={<FolderOpenOutlined />}
                                    onClick={() => componentCallback(CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK, { key: "edit", data: rowData })}
                                />
                            </Tooltip>
                        )}
                        {enableUpdate && (
                            <Tooltip title="Send Email To" key={`edit-roll-${rowData.id}`}>
                                <Button
                                    type="text"
                                    icon={<MailOutlined />}
                                    disabled={rowData.currentStep < 1}
                                    onClick={() => componentCallback(CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK, { key: "sendEmailTo", data: rowData })}
                                />
                            </Tooltip>
                        )}
                        {enableUpdate && (
                            <Popconfirm
                                key={`del-roll-${rowData.id}`}
                                title="Confirm to delete?"
                                onConfirm={() => {
                                    setIsLoading(true);
                                    plainAxiosInstance
                                        .get(`${APIs.RISK_TOOL.GET_ROLLOVER_DELETE_BATCH_RECORD}?batchId=${rowData.id}`)
                                        .then((res: any) => {
                                            if (res.data === "success") {
                                                ErrorMessageHandler(`Batch ID: [ ${rowData.id} ]`, SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                                                queryBatchData();
                                            }
                                        })
                                        .catch((error: any) =>
                                            ErrorCatchValidator(error, (err: any) =>
                                                ErrorMessageHandler("batch record list", SUCCESS_FAILED.FAILED_LOAD_DATA, err)
                                            )
                                        )
                                        .finally(() => setIsLoading(false));
                                }}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Tooltip title="Delete Batch" key={`delete-roll-${rowData.id}`}>
                                    <Button type="text" icon={<DeleteOutlined />} />
                                </Tooltip>
                            </Popconfirm>
                        )}
                    </>
                ),
            }),
        ];
    }, [batchData, enableUpdate]);

    const options: FlexiDataTableOptionsProps = useMemo(
        () => ({
            showHideColumns: false,
            enableFilter: true,
            add: enableUpdate,
        }),
        [batchData]
    );

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                setIsLoading(true);

                Modal.confirm({
                    icon: <ExclamationCircleOutlined />,
                    title: "Are you sure you want to add new batch?",
                    width: "30%",
                    onOk() {
                        plainAxiosInstance
                            .get(APIs.RISK_TOOL.GET_ROLLOVER_ADD_BATCH_RECORD)
                            .then(res => {
                                if (res.status === 200) {
                                    message.success("Added batch successfully.", 3);
                                } else {
                                    message.error(res.data, 3);
                                }
                            })
                            .catch((error: any) => {
                                message.error(`Added batch failed: (${error.response.data.message})`, 3);
                            })
                            .finally(() => setRunRefetchDataList(true));
                    },
                    onCancel: () => {
                        setIsLoading(false);
                    },
                });
                break;
            case CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK:
                if (FormData.key === "sendEmailTo") {
                    setIsLoading(true);

                    Modal.confirm({
                        icon: <ExclamationCircleOutlined />,
                        title: "Are you sure send roll over mail to 【" + emailTo + "】?",
                        width: "30%",
                        onOk() {
                            plainAxiosInstance
                                .get(`${APIs.RISK_TOOL.GET_ROLLOVER_SEND_EMAIL}?batchId=${FormData.data.id}`)
                                .then(res => {
                                    if (res.data === "success") {
                                        message.success("Send mail successfully.", 3);
                                    } else {
                                        message.error(res.data, 3);
                                    }
                                })
                                .catch((error: any) => {
                                    message.error(`Added batch failed: (${error.response.data.message})`, 3);
                                })
                                .finally(() => setRunRefetchDataList(true));
                        },
                        onCancel: () => {
                            setIsLoading(false);
                        },
                    });
                    break;
                } else if (FormData.key === "edit") {
                    navigate(`/admintools/rollover/edit/${FormData.data.id}`, { state: { data: FormData.data, action: "edit" } });
                    break;
                }
                break;
            default:
                break;
        }
    };

    const queryBatchData = async () => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append("steps", steps.join(","));
        plainAxiosInstance
            .post(APIs.RISK_TOOL.POST_ROLLOVER_BATCH_RECORD_LIST, formData)
            .then((res: any) => {
                if (res.data && res.data.length > 0) {
                    const realData: RollOverBatchRecordList[] = res.data;
                    setBatchData(realData);
                    setIsLoading(false);
                } else {
                    setBatchData([]);
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("rollover batch list", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            );
    };

    const queryEmailTo = async () => {
        setIsLoading(true);
        plainAxiosInstance
            .get(APIs.RISK_TOOL.GET_ROLLOVER_EMAILTO)
            .then((res: any) => {
                if (res.data && res.data.length > 0) {
                    const realData: string = res.data;
                    setEmailTo(realData);
                    setIsLoading(false);
                } else {
                    setEmailTo("");
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("rollover email to", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            );
    };

    const stepTag = (step: number) => {
        return <Tag color={stepInfo[step].color}>{stepInfo[step].label}</Tag>;
    };

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            queryBatchData();
            setRunRefetchDataList(false);
        }
        return () => { };
    }, [runRefetchDataList]);

    useEffect(() => {
        queryEmailTo();
        queryBatchData();
    }, []);

    return (
        <CardBox title={"Roll Over Tool"}>
            <FlexiDataTable
                rowKeyProperty="id"
                title={false}
                columns={columns}
                options={options}
                dataSource={batchData}
                callback={componentCallback}
                loading={isLoading}
                pagination={{
                    defaultPageSize: 20,
                }}
                scroll={{
                    x: true,
                }}
                filterInitialValue={{ currentStep: steps }}
            />
        </CardBox>
    );
};

export default RollOverTool;
