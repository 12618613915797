import { FormComponent } from "@/components/FormComponent";
import { ComponentType } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { Modal, Form, Row, Col } from "antd";
import { useEffect } from "react";
import { PriceAlarmColorSettingProps } from ".";

export interface CreateEditPriceAlarmColorModalProps {
    isModalVisible: boolean;
    modalTitle: string;
    callback: (type: number, data: any) => void;
    data?: PriceAlarmColorSettingProps | undefined;
}

export enum CreateEditPriceAlarmColorModalCallbackKey {
    Close = 0,
    FormSubmit = 1,
}

const CreateEditPriceAlarmColorModal = (props: CreateEditPriceAlarmColorModalProps) => {
    const [executeForm] = Form.useForm();

    useEffect(() => {
        if (props.isModalVisible) {
            executeForm.setFieldsValue(props.data !== undefined ? props.data : { symbol: "", warningColor: "#EB144C" });
        }
    }, [props.isModalVisible]);

    return (
        <Modal
            style={{ top: 10 }}
            width={"25vw"}
            title={props.modalTitle}
            open={props.isModalVisible}
            okText="Submit"
            onOk={() => {
                executeForm
                    .validateFields()
                    .then((values: any) => {
                        props.callback(CreateEditPriceAlarmColorModalCallbackKey.FormSubmit, values);
                    })
                    .catch((err: any) => console.log(err));
            }}
            onCancel={() => props.callback(CreateEditPriceAlarmColorModalCallbackKey.Close, null)}
            maskClosable={false}
        >
            <Form form={executeForm} layout="horizontal" labelCol={{ span: 10 }} labelAlign="left">
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Symbol"
                            name="symbol"
                            extra={{
                                type: ComponentType.text,
                                value: "",
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Color Code"
                            name="warningColor"
                            extra={{
                                type: ComponentType.colorpicker,
                                value: "",
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                            }}
                        />
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default CreateEditPriceAlarmColorModal;
