import { ApartmentOutlined, ControlOutlined, ProjectOutlined, ReadOutlined, ShakeOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import CardBox from "../../../components/Common/CardBox";

interface AppConfigItemProps {
    title: string;
    titleSize?: string;
    description?: string;
    icon?: React.ReactNode;
    path?: string;
    children?: AppConfigItemProps[];
}

const AppConfigRoute: AppConfigItemProps[] = [
    // {
    //     title: "Metric Configuration",
    //     titleSize: "12.369",
    //     children: [
    //         {
    //             title: "Metric Category",
    //             description: "Create/Edit metric category",
    //             icon: <ProjectOutlined />,
    //             path: "/siteadmin/appconfig/metriccategory",
    //         },
    //         {
    //             title: "Metric Mapping",
    //             description: "Group metric by category",
    //             icon: <ProjectOutlined />,
    //             path: "/siteadmin/appconfig/metricmapping",
    //         },
    //         {
    //             title: "Metric Glossary",
    //             description: "Edit metric information",
    //             icon: <ProjectOutlined />,
    //             path: "/siteadmin/appconfig/metricglossary",
    //         },
    //     ],
    // },
    // {
    //     title: "Account 360 Configuration",
    //     titleSize: "15.624",
    //     children: [
    //         {
    //             title: "Account Summary Metric Configuration",
    //             description: "Configure account 360 summary metrics",
    //             icon: <UnorderedListOutlined />,
    //             path: "/siteadmin/appconfig/accSumMetricConfig",
    //         },
    //         {
    //             title: "Account-Symbol Summary Metric Configuration",
    //             description: "Configure account 360 summary metrics",
    //             icon: <UnorderedListOutlined />,
    //             path: "/siteadmin/appconfig/accSymbolConfig",
    //         },
    //         {
    //             title: "Account Watch List Group",
    //             description: "Create/Edit account watch list group",
    //             icon: <UnorderedListOutlined />,
    //             path: "/siteadmin/appconfig/accWatchListGroup",
    //         },
    //     ],
    // },
    // {
    //     title: "User Manual Configuration",
    //     titleSize: "15.624",
    //     children: [
    //         {
    //             title: "User Manual Configuration",
    //             description: "User Manual Configuration",
    //             icon: <ReadOutlined />,
    //             path: "/siteadmin/appconfig/userManualConfig",
    //         },
    //     ],
    // },
    {
        title: "Application Settings",
        titleSize: "12.624",
        children: [
            {
                title: "Notification Channel Management",
                description: "Notification channel configuration",
                icon: <ShakeOutlined />,
                path: "/siteadmin/appconfig/notificationchannelconfig",
            },
            // {
            //     title: "MT Server & Hub Configuration",
            //     description: "MT Server & Hub configuration",
            //     icon: <ApartmentOutlined />,
            //     path: "/siteadmin/appconfig/mtserverhubconfig",
            // },
        ],
    },
];

const AppConfigurationPage = () => {
    const getConfigurationTemplate = (x: AppConfigItemProps, index: string) => {
        return x.children && x.children.length > 0 ? (
            <div className="config-group" key={`apggd-${index}`}>
                <div className="title-panel">
                    <span>{x.title}</span>
                    <hr className="divider" style={{ width: `calc(100% - ${x.titleSize}vw)` }} />
                </div>
                <div className="content">{x.children.map((y: AppConfigItemProps, i: number) => getConfigurationTemplate(y, `${index}-${i}`))}</div>
            </div>
        ) : (
            <Link key={`apggd-SGL-${index}`} to={x.path || ""} style={{ display: "inline-block" }}>
                <div className="config-card">
                    <div className="icon-panel">{x.icon}</div>
                    <div className="description-panel">
                        <span className="title">{x.title}</span>
                        {x.description && <span className="subtitle">{x.description}</span>}
                    </div>
                </div>
            </Link>
        );
    };

    return (
        <div>
            <CardBox title={"App Configuration"}>
                <div className="app-config-container">
                    {AppConfigRoute.map((x: AppConfigItemProps, index: number) => getConfigurationTemplate(x, `${index}`))}
                </div>
            </CardBox>
        </div>
    );
};

export default AppConfigurationPage;
