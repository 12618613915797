import { Tabs, Form, Row, Col, Checkbox, FormInstance } from "antd";
import { useState } from "react";
import { FormComponent, } from "../../../components/FormComponent";
import { ComponentType, CronType, WEEK_DAYS } from "../../../constants";
import { MAX, MIN, REQUIRED_AT_LEAST_ONE, REQUIRED_FIELD } from "../../../constants/errorMessage";
import { OptionType, ScheduledData } from "../../../constants/type";
import { StringFormat } from "../../../utils/array";

const { TabPane } = Tabs;

export interface ScheduledSetupTemplateProps {
    form: FormInstance;
}

const initialDaily: ScheduledData = {
    dMinute: 0,
    dHour: 0,
    dType: "1",
    dTime: "",
    dDays: 0,
    dWeekdayOnly: false,
    dtSnapshot: false,
    wTime: "",
    wWeekday: [],
    wtSnapshot: false,
    mTime: "",
    mDays: 0,
    mtSnapshot: false,
};

const ScheduledSetupTemplate = (props: ScheduledSetupTemplateProps) => {
    const [enableDailyInput, setEnableDailyInput] = useState<boolean>(true);
    const [currentTabKey, setCurrentTabKey] = useState<string>("1");

    return (
        <div className="scheduled-div">
            <Form form={props.form} name="scheduled_form" initialValues={initialDaily}>
                <FormComponent label="" name={"dType"} extra={{ type: ComponentType.hidden, value: "" }} />
                <Tabs
                    tabPosition="left"
                    type="card"
                    defaultActiveKey="1"
                    onChange={(activeKey) => {
                        props.form.setFieldsValue({ dType: activeKey });
                        setCurrentTabKey(activeKey);
                    }}
                >
                    <TabPane tab="Minute" key={CronType.Minute.toString()}>
                        <Row>
                            <Col span={24} className="tab-content-cls">
                                <Row>
                                    <Col span={6}>Every</Col>
                                    <Col span={16}>
                                        <FormComponent
                                            label=""
                                            name={"dMinute"}
                                            extra={{
                                                type: ComponentType.number,
                                                value: "",
                                                rules: [
                                                    { required: true, message: REQUIRED_FIELD },
                                                    {
                                                        type: "number",
                                                        min: 1,
                                                        message: StringFormat(MIN, 1),
                                                        transform: (value) => (Number(value) ? Number(value) : 0),
                                                    },
                                                    {
                                                        type: "number",
                                                        max: 59,
                                                        message: StringFormat(MIN, 59),
                                                        transform: (value) => (Number(value) ? Number(value) : 0),
                                                    },
                                                ],
                                                inputProps: {
                                                    min: 1,
                                                    max: 59,
                                                    addonAfter: "minute(s)",
                                                },
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tab="Hour" key={CronType.Hour.toString()}>
                        <Row>
                            <Col span={24} className="tab-content-cls">
                                <Row>
                                    <Col span={6}>Every</Col>
                                    <Col span={16}>
                                        <FormComponent
                                            label=""
                                            name={"dHour"}
                                            extra={{
                                                type: ComponentType.number,
                                                value: "",
                                                rules: [
                                                    { required: true, message: REQUIRED_FIELD },
                                                    {
                                                        type: "number",
                                                        min: 1,
                                                        message: StringFormat(MIN, 1),
                                                        transform: (value) => (Number(value) ? Number(value) : 0),
                                                    },
                                                    {
                                                        type: "number",
                                                        max: 23,
                                                        message: StringFormat(MIN, 23),
                                                        transform: (value) => (Number(value) ? Number(value) : 0),
                                                    },
                                                ],
                                                inputProps: {
                                                    min: 1,
                                                    max: 23,
                                                    addonAfter: "hour(s)",
                                                },
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tab="Daily" key={CronType.Daily.toString()}>
                        <Row>
                            <Col span={24} className="tab-content-cls">
                                <Row>
                                    <Col span={6}>Start Time</Col>
                                    <Col span={16}>
                                        <FormComponent
                                            label=""
                                            name={"dTime"}
                                            extra={{
                                                type: ComponentType.time,
                                                value: "",
                                                rules:
                                                    currentTabKey === CronType.Daily.toString() ? [{ required: true, message: REQUIRED_FIELD }] : [],
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={6}>Every</Col>
                                    <Col span={8}>
                                        <FormComponent
                                            label=""
                                            name={"dDays"}
                                            extra={{
                                                type: ComponentType.number,
                                                value: "",
                                                disabled: !enableDailyInput,
                                                rules:
                                                    currentTabKey === CronType.Daily.toString() && enableDailyInput
                                                        ? [
                                                            { required: true, message: REQUIRED_FIELD },
                                                            {
                                                                type: "number",
                                                                min: 1,
                                                                message: StringFormat(MIN, 1),
                                                                transform: (value) => (Number(value) ? Number(value) : 0),
                                                            },
                                                            {
                                                                type: "number",
                                                                max: 31,
                                                                message: StringFormat(MIN, 31),
                                                                transform: (value) => (Number(value) ? Number(value) : 0),
                                                            },
                                                        ]
                                                        : [],
                                                inputProps: {
                                                    min: 0,
                                                    max: 31,
                                                    addonAfter: "day(s)",
                                                },
                                            }}
                                        />
                                    </Col>
                                    <Col span={10} className="check-box-style">
                                        <Form.Item name={"dWeekdayOnly"} valuePropName="checked">
                                            <Checkbox
                                                onChange={(e) => {
                                                    props.form.resetFields(["dDays"]);
                                                    setEnableDailyInput(!e.target.checked);
                                                }}
                                            >
                                                Weekday only
                                            </Checkbox>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={6}></Col>
                                    <Col span={16}>
                                        <Form.Item name={"dtSnapshot"} valuePropName="checked">
                                            <Checkbox>T-1 Snapshot</Checkbox>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tab="Weekly" key={CronType.Weekly.toString()}>
                        <Row>
                            <Col span={24} className="tab-content-cls">
                                <Row>
                                    <Col span={6}>Start Time</Col>
                                    <Col span={16}>
                                        <FormComponent
                                            label=""
                                            name={"wTime"}
                                            extra={{
                                                type: ComponentType.time,
                                                value: "",
                                                rules: [
                                                    {
                                                        required: currentTabKey === CronType.Weekly.toString(),
                                                        message: REQUIRED_FIELD,
                                                    },
                                                ],
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={6}></Col>
                                    <Col span={16}>
                                        <Form.Item name={"wtSnapshot"} valuePropName="checked">
                                            <Checkbox>T-1 Snapshot</Checkbox>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={6}></Col>
                                    <Col>
                                        <FormComponent
                                            label=""
                                            name={"wWeekday"}
                                            extra={{
                                                type: ComponentType.checkboxgroup,
                                                value: Object.keys(WEEK_DAYS).map((x) => ({ text: WEEK_DAYS[x], value: x } as OptionType)),
                                                rules: [
                                                    {
                                                        required: currentTabKey === CronType.Weekly.toString(),
                                                        message: StringFormat(REQUIRED_AT_LEAST_ONE, "day"),
                                                    },
                                                ],
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tab="Monthly" key={CronType.Monthly.toString()}>
                        <Row>
                            <Col span={24} className="tab-content-cls">
                                <Row>
                                    <Col span={6}>Start Time</Col>
                                    <Col span={16}>
                                        <FormComponent
                                            label=""
                                            name={"mTime"}
                                            extra={{
                                                type: ComponentType.time,
                                                value: "",
                                                rules: [
                                                    {
                                                        required: currentTabKey === CronType.Monthly.toString(),
                                                        message: REQUIRED_FIELD,
                                                    },
                                                ],
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={6}>Day </Col>
                                    <Col span={16}>
                                        <FormComponent
                                            label=""
                                            name={"mDays"}
                                            extra={{
                                                type: ComponentType.number,
                                                value: "",
                                                rules:
                                                    currentTabKey === CronType.Monthly.toString()
                                                        ? [
                                                            { required: true, message: REQUIRED_FIELD },
                                                            {
                                                                type: "number",
                                                                min: -31,
                                                                message: StringFormat(MIN, -31),
                                                                transform: (value) => (Number(value) ? Number(value) : 0),
                                                            },
                                                            {
                                                                type: "number",
                                                                max: 31,
                                                                message: StringFormat(MAX, 31),
                                                                transform: (value) => (Number(value) ? Number(value) : 0),
                                                            },
                                                        ]
                                                        : [],
                                                inputProps: {
                                                    min: -31,
                                                    max: 31,
                                                    addonAfter: "of every month(s)",
                                                },
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={6}></Col>
                                    <Col span={16}>
                                        <Form.Item name={"mtSnapshot"} valuePropName="checked">
                                            <Checkbox>T-1 Snapshot</Checkbox>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </TabPane>
                </Tabs>
            </Form>
        </div>
    );
};

export default ScheduledSetupTemplate;
