import { Form, Row, Col } from "antd";
import { FormComponent } from "../../../../components/FormComponent";
import { ComponentType } from "../../../../constants";
import { REQUIRED_FIELD, MAX } from "../../../../constants/errorMessage";
import { KeyValuePair } from "../../../../constants/type";
import { StringFormat } from "../../../../utils/array";

export interface createEditMetricComponentProps {
    form: any;
    data: any;
    metrics: KeyValuePair[];
}

const CreateEditMetricComponent = (props: createEditMetricComponentProps) => {
    return (
        <div>
            <Form form={props.form} layout="horizontal" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} initialValues={props.data}>
                <Row>
                    <Col span={22}>
                        <FormComponent
                            label=""
                            name="aggregateId"
                            extra={{
                                type: ComponentType.hidden,
                                value: "",
                            }}
                        />
                        <FormComponent
                            label=""
                            name="typeId"
                            extra={{
                                type: ComponentType.hidden,
                                value: "",
                            }}
                        />
                        <FormComponent
                            label="Name"
                            name="name"
                            extra={{
                                type: ComponentType.text,
                                rules: [
                                    {
                                        required: true,
                                        message: REQUIRED_FIELD,
                                    },
                                ],
                                value: "",
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={22}>
                        <FormComponent
                            label="Description"
                            name="description"
                            extra={{
                                type: ComponentType.text,
                                value: "",
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={22}>
                        <FormComponent
                            label="Selected Metric"
                            name="aggregations"
                            extra={{
                                type: ComponentType.dropdown,
                                value: props.metrics,
                                rules: [
                                    {
                                        required: true,
                                        message: REQUIRED_FIELD,
                                    },
                                ],
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={22}>
                        <FormComponent
                            label="Weight"
                            name="weight"
                            extra={{
                                type: ComponentType.number,
                                rules: [
                                    {
                                        required: true,
                                        message: REQUIRED_FIELD,
                                    },
                                    {
                                        type: "number",
                                        max: 100,
                                        message: StringFormat(MAX, 100),
                                        transform: (value) => (Number(value) ? Number(value) : 0),
                                    },
                                ],
                                value: "",
                            }}
                        />
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default CreateEditMetricComponent;
