import FlexiDataTable from "../../../components/FlexiDataTable";
import { useEffect, useState } from "react";
import {
    CustomPaginationProps,
    FlexiDataTableOptionsProps,
    FlexiDataTableCallbackProps,
    HubSwitcherHistoricalItem,
    HubSwitcherHistoricalItemSecurity,
    HubSwitcherHistoricalSignalRMessage,
    statusObjectType,
} from "../../../constants/type";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "../../../constants";
import { DateTimeUtil } from "../../../utils/datetime";
import { apiRequest } from "../../../services/apiConfig";
import { APIs } from "../../../services/apis";
import { DTColProps, DataTableColumnRender, ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";
import {
    CheckCircleOutlined,
    DoubleRightOutlined,
    DownloadOutlined,
    FieldTimeOutlined,
    FileSearchOutlined,
    WarningOutlined,
} from "@ant-design/icons";
import { Button, Modal } from "antd";
import SignalRHelper from "../../../helpers/signalRHelper";
import { isEmptyOrNull } from "../../../utils/string";
import { getModeSource } from ".";
import { CSVLink } from "react-csv";

export interface HubSwitcherHistoricalProps {
    mode: string;
}

const HubSwitcherHistorical = (props: HubSwitcherHistoricalProps) => {
    const [data, setData] = useState<HubSwitcherHistoricalItem[]>([]);
    const [securitiesData, setSecuritiesData] = useState<HubSwitcherHistoricalItemSecurity[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(true);
    const [pagination, setPagination] = useState<CustomPaginationProps>({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [sorting, setSorting] = useState<string>("createdDateUtc,desc");
    const [filterParams, setFilterParams] = useState<any>({});
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [statusData, setStatusData] = useState<statusObjectType>({});
    const [sRConnection] = useState<SignalRHelper>(new SignalRHelper("tp-hubSwitcherOver-Historical", `/hubFailoverSwitchHub`));
    const [wholeData, setWholeData] = useState<any>([]);

    const getHistoricalListing = () => {
        let sourceObj: any = getModeSource(props.mode);
        apiRequest(APIs.GET_HUBSWITCHER_HISTORICAL_LIST, {
            ...sourceObj,
            limit: pagination.pageSize,
            current: pagination.current,
            ...(sorting.length > 0 && { order: sorting }),
            ...(Object.keys(filterParams).length > 0 && { ...filterParams }),
        })
            .then((data: any) => {
                if (data && data.result && data.result.length > 0) {
                    let newStatusObj: statusObjectType = (data.result as HubSwitcherHistoricalItem[]).reduce(
                        (finalObj: statusObjectType, x: HubSwitcherHistoricalItem) => {
                            if (x.managerSecurities.length > 0) {
                                finalObj[x.batchId] = {
                                    status: isEmptyOrNull(x.completedDateUtc) ? 0 : 1,
                                    error: "",
                                    completedDateUtc: x.completedDateUtc || "",
                                };
                                x.managerSecurities.forEach(y => {
                                    finalObj[y.subId] = { status: y.progressStatus, error: y.remarks, completedDateUtc: y.completedDateUtc || "" };
                                });
                            }
                            return finalObj;
                        },
                        {}
                    );
                    setStatusData(prev => ({ ...prev, ...newStatusObj }));
                    setData(data.result);
                    setPagination(prev => ({ ...prev, total: data.total }));
                } else {
                    setData([]);
                    setPagination(prev => ({ ...prev, total: 0 }));
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("hub switcher historical list", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsLoading(false));
    };

    const openDetailsModal = (batchId: string) => {
        let fidx: number = data.findIndex(x => x.batchId === batchId);
        if (fidx > -1) {
            let newData = data[fidx].managerSecurities.map((x: any) => {
                return {
                    ...data[fidx],
                    batchCompletedDateUtc: data[fidx].completedDateUtc,
                    ...x,
                };
            });
            setWholeData(newData);
            setSecuritiesData(data[fidx].managerSecurities);
            setModalVisible(true);
        }
    };

    const columns = [
        DTColProps.DateTime({
            title: "Created At (Local)",
            dataIndex: "createdDateUtc",
            key: "createdDateUtc",
            options: {
                filter: {
                    type: ComponentType.daterange,
                    value: "",
                    inputProps: {
                        dateOnly: true,
                    },
                },
            },
        }),
        DTColProps.Middle({
            title: "Created By",
            dataIndex: "createdByName",
            key: "createdByName",
        }),
        {
            title: "Server & Security",
            dataIndex: "managerSecurities",
            key: "managerSecurities",
            render: (details: HubSwitcherHistoricalItemSecurity[], rowData: HubSwitcherHistoricalItem) => {
                let servers: any = Object.keys(
                    details.reduce((finalObj: any, x: HubSwitcherHistoricalItemSecurity) => {
                        finalObj[x.server] = 1;
                        return finalObj;
                    }, {})
                ).join(", ");
                let Securities: any = Object.keys(
                    details.reduce((finalObj: any, x: HubSwitcherHistoricalItemSecurity) => {
                        finalObj[x.security] = 1;
                        return finalObj;
                    }, {})
                ).join(", ");
                return (
                    <div className="details-panel">
                        <div className="desc">
                            <div className="mode">
                                Switch Mode: <span>{rowData.switchMode}</span>
                            </div>
                            <div className="sub-panel">
                                <div>
                                    <div>Affected Servers:</div>
                                    <div>{servers}</div>
                                </div>
                                <div>
                                    <div>Affected Securities:</div>
                                    <div>{Securities}</div>
                                </div>
                            </div>
                        </div>
                        <Button type="text" icon={<FileSearchOutlined />} onClick={() => openDetailsModal(rowData.batchId)} />
                    </div>
                );
            },
        },
        DTColProps.Middle({
            title: "Status",
            dataIndex: "completedDateUtc",
            key: "completedDateUtc",
            render: (completedDateUtc: string, rowData: HubSwitcherHistoricalItem) => {
                let errorCount: number = rowData.managerSecurities.filter(x =>
                    statusData?.hasOwnProperty(x.subId) ? statusData[x.subId].status === 3 : false
                ).length;
                let timeconsume: string = DateTimeUtil.getDiffBetween2Date(
                    rowData.createdDateUtc,
                    statusData[rowData.batchId].completedDateUtc as string
                ) as string;
                return (
                    <>
                        {statusData?.hasOwnProperty(rowData.batchId) ? (
                            isEmptyOrNull(statusData[rowData.batchId].completedDateUtc) ? (
                                <div className="in-progress">
                                    <span className="dot"></span>
                                    <span>In Progress</span>
                                </div>
                            ) : (
                                <div className="complete">
                                    <div className="status">
                                        <CheckCircleOutlined />
                                        <span>Completed</span>
                                    </div>
                                    <div className="time-desc">
                                        <FieldTimeOutlined />
                                        <span>{timeconsume === "00:00:00" ? "00:00:01" : timeconsume}</span>
                                    </div>
                                    {errorCount > 0 && (
                                        <div className="error-panel" style={{ cursor: "pointer" }} onClick={() => openDetailsModal(rowData.batchId)}>
                                            <WarningOutlined />
                                            <span>{errorCount} failed</span>
                                        </div>
                                    )}
                                </div>
                            )
                        ) : (
                            <></>
                        )}
                    </>
                );
            },
        }),
    ];

    const options: FlexiDataTableOptionsProps = {
        serverFiltering: true,
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, FormData) => {
        switch (type) {
            case CALLBACK_KEY.HANDLE_PAGINATION_SORTING:
                FormData.sorter.order === undefined
                    ? setSorting("createdDateUtc,desc")
                    : setSorting(
                          FormData.sorter.column && FormData.sorter.order
                              ? `${FormData.sorter.column.key},${FormData.sorter.order === "ascend" ? "asc" : "desc"}`
                              : ""
                      );
                setPagination(prev => ({ ...prev, current: FormData.pagination.current, pageSize: FormData.pagination.pageSize }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                let filterParams: any = {};
                Object.keys(FormData)
                    .filter(x => FormData[x] && FormData[x].toString().length > 0)
                    .map(x => {
                        if (x === "createdDateUtc") {
                            filterParams.createdDateUtcFrom = DateTimeUtil.GetUTC(FormData[x][0], "YYYY-MM-DD");
                            filterParams.createdDateUtcTo = DateTimeUtil.GetUTC(FormData[x][1], "YYYY-MM-DD");
                        } else {
                            filterParams[x] = FormData[x];
                        }
                        return x;
                    });
                setFilterParams(filterParams);
                setSorting("createdDateUtc,desc");
                setPagination(prev => ({ ...prev, current: 1 }));
                setRunRefetchDataList(true);
                break;
            default:
                break;
        }
    };

    const getStatusTemplate = (fromStatus: number, toStatus: number) => {
        return fromStatus === 1 ? (
            <div className="status-desc-panel">
                <span>Active</span>
                <DoubleRightOutlined />
                <span className="error">Inactive</span>
            </div>
        ) : (
            <div className="status-desc-panel">
                <span>Inactive</span>
                <DoubleRightOutlined />
                <span className="success">Active</span>
            </div>
        );
    };

    const modalTableColumns = [
        DTColProps.XSmall({
            title: "Switch Mode",
            dataIndex: "switchMode",
            key: "switchMode",
            options: {
                visible: false,
            },
        }),
        DTColProps.XSmall({
            title: "Batch Created At",
            dataIndex: "createdDateUtc",
            key: "createdDateUtc",
            options: {
                visible: false,
            },
        }),
        DTColProps.XSmall({
            title: "Batch Completed At",
            dataIndex: "batchCompletedDateUtc",
            key: "batchCompletedDateUtc",
            options: {
                visible: false,
            },
        }),
        DTColProps.Middle({
            title: "Server",
            dataIndex: "server",
            key: "server",
        }),
        {
            title: "OZ Manager Security",
            dataIndex: "security",
            key: "security",
        },
        DTColProps.Middle({
            title: "OZ Manager Status",
            dataIndex: "sourceStatusFrom",
            key: "sourceStatusFrom",
            render: (sourceStatusFrom: string, rowData: HubSwitcherHistoricalItemSecurity) =>
                getStatusTemplate(rowData.sourceStatusFrom, rowData.sourceStatusTo),
        }),
        DTColProps.Middle({
            title: "PE Manager Status",
            dataIndex: "targetStatusFrom",
            key: "targetStatusFrom",
            render: (targetStatusFrom: string, rowData: HubSwitcherHistoricalItemSecurity) =>
                getStatusTemplate(rowData.targetStatusFrom, rowData.targetStatusTo),
        }),
        DTColProps.Large({
            title: "Status",
            dataIndex: "completedDateUtc",
            key: "completedDateUtc",
            render: (completedDateUtc: string | null, rowData: HubSwitcherHistoricalItemSecurity) => {
                return (
                    <>
                        {statusData?.hasOwnProperty(rowData.subId) ? (
                            isEmptyOrNull(statusData[rowData.subId].completedDateUtc) ? (
                                statusData[rowData.subId].status === 0 ? (
                                    <div className="pending">
                                        <span className="dot"></span>
                                        <span>Pending</span>
                                    </div>
                                ) : (
                                    <div className="in-progress">
                                        <span className="dot"></span>
                                        <span>In Progress</span>
                                    </div>
                                )
                            ) : statusData[rowData.subId].status === 2 ? (
                                <span className="success">Success</span>
                            ) : (
                                <div className="error-panel">
                                    <span className="error">
                                        <WarningOutlined />
                                        Failed
                                    </span>
                                    <span className="error-desc error">- {statusData[rowData.subId].error}</span>
                                </div>
                            )
                        ) : (
                            <></>
                        )}
                    </>
                );
            },
        }),
        DTColProps.XSmall({
            title: "Initiated At",
            dataIndex: "initDateUtc",
            key: "initDateUtc",
            options: {
                visible: false,
            },
        }),
        DTColProps.XSmall({
            title: "Progress Completed At",
            dataIndex: "progressCompletedDateUtc",
            key: "progressCompletedDateUtc",
            options: {
                visible: false,
            },
        }),
        DTColProps.XSmall({
            title: "Created By",
            dataIndex: "createdByName",
            key: "createdByName",
            options: {
                visible: false,
            },
        }),
    ];

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            getHistoricalListing();
            setRunRefetchDataList(false);
        }
        return () => {};
    }, [runRefetchDataList]);

    useEffect(() => {
        sRConnection.setHandler((hubConnection: any) => {
            hubConnection.on("HubFailoverSwitchStream", (message: any) => {
                if (message.events.length > 0) {
                    let tmpObj: statusObjectType = (message.events as HubSwitcherHistoricalSignalRMessage[]).reduce(
                        (finalObj: statusObjectType, x: HubSwitcherHistoricalSignalRMessage) => {
                            finalObj[x.uid] = { status: x.status, error: x.remarks, completedDateUtc: x.completedDateUtc };
                            return finalObj;
                        },
                        {}
                    );
                    setStatusData(prev => ({ ...prev, ...tmpObj }));
                }
            });
        });
        sRConnection.startConnection();

        return () => {};
    }, []);

    return (
        <>
            <div className="hubswitcher-historical-container">
                <FlexiDataTable
                    rowKeyProperty="batchId"
                    title=""
                    columns={columns}
                    options={options}
                    dataSource={data}
                    callback={componentCallback}
                    loading={isLoading}
                    pagination={pagination}
                    serverSide={true}
                />
            </div>
            <Modal
                open={modalVisible}
                destroyOnClose={true}
                width="70%"
                title="Preview"
                wrapClassName="hubswitcher-modal-container"
                okButtonProps={{
                    style: {
                        display: "none",
                    },
                }}
                cancelText="Close"
                onCancel={() => {
                    setModalVisible(false);
                }}
            >
                <FlexiDataTable
                    rowKeyProperty="subId"
                    title={""}
                    columns={modalTableColumns}
                    options={{
                        enableFilter: false,
                        showHideColumns: false,
                        export: {
                            text: "",
                            Element: (
                                <CSVLink
                                    key={`btn-export-${Math.random()}`}
                                    data={wholeData.map((y: any) => {
                                        return {
                                            ...y,
                                            sourceStatusFrom: y.sourceStatusFrom === 1 ? "Active >> Inactive" : "Inactive >> Active",
                                            targetStatusFrom: y.targetStatusFrom === 1 ? "Active >> Inactive" : "Inactive >> Active",
                                            createdDateUtc: DataTableColumnRender.DateTime(y.createdDateUtc),
                                            initDateUtc: DataTableColumnRender.DateTime(y.initDateUtc),
                                            completedDateUtc: statusData?.hasOwnProperty(y.subId)
                                                ? isEmptyOrNull(statusData[y.subId].completedDateUtc)
                                                    ? statusData[y.subId].status === 0
                                                        ? "Pending"
                                                        : "In Progress"
                                                    : statusData[y.subId].status === 2
                                                    ? "Success"
                                                    : `Failed - ${statusData[y.subId].error}`
                                                : null,
                                            progressCompletedDateUtc: statusData?.hasOwnProperty(y.subId)
                                                ? isEmptyOrNull(statusData[y.subId].completedDateUtc)
                                                    ? ""
                                                    : DataTableColumnRender.DateTime(statusData[y.subId].completedDateUtc)
                                                : null,
                                            batchCompletedDateUtc: y.batchCompletedDateUtc
                                                ? DataTableColumnRender.DateTime(y.batchCompletedDateUtc)
                                                : statusData?.hasOwnProperty(y.batchId)
                                                ? isEmptyOrNull(statusData[y.batchId].completedDateUtc)
                                                : ""
                                                ? statusData[y.subId].status === 2
                                                : DataTableColumnRender.DateTime(statusData[y.batchId].completedDateUtc),
                                        };
                                    })}
                                    headers={modalTableColumns.map(x => {
                                        return { label: x.title, key: x.key };
                                    })}
                                    filename={`HubSwitcherHistorical-${new Date().getTime()}.csv`}
                                >
                                    <Button htmlType="button" icon={<DownloadOutlined style={{ fontSize: "0.875rem" }} />}>
                                        Export as CSV
                                    </Button>
                                </CSVLink>
                            ),
                        },
                    }}
                    dataSource={securitiesData}
                    callback={() => {}}
                    loading={false}
                />
            </Modal>
        </>
    );
};

export default HubSwitcherHistorical;
