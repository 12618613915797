import { useMemo } from "react";
import { Button, Divider, message, Select, Space, Tooltip } from "antd";
import FlexiDataTable from "@/components/FlexiDataTable";
import { DTColProps } from "@/utils/Common";
import {
    FlexiDataTableCallbackProps,
    FlexiDataTableOptionsProps,
    KeyValuePair,
    RollOverBatchRecordList,
    RollOverVerificationList,
} from "@/constants/type";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { DownloadOutlined, SearchOutlined, ToolFilled } from "@ant-design/icons";
import { CALLBACK_KEY } from "@/constants";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { APIs } from "@/services/apis";
import moment from "moment";

export interface RollOverVerificationTableProps {
    data: RollOverVerificationList[];
    tbTitle: string;
    loading: boolean;
    currentState: RollOverBatchRecordList;
    verificationServerId: string;
    serverDetailList: KeyValuePair[];
    queryVerificationAndStopOutData: any;
    setVerificationServerId: (secID: string) => void;
}

const RollOverVerificationTable = (props: RollOverVerificationTableProps) => {
    const columns = [
        DTColProps.XSmall({
            title: "Server",
            dataIndex: "server",
            key: "server",
            sorter: (a: any, b: any) => a.server - b.server,
        }),
        DTColProps.XSmall({
            title: "Ticket/Deal",
            dataIndex: "ticket",
            key: "ticket",
        }),
        DTColProps.XSmall({
            title: "Login",
            dataIndex: "login",
            key: "login",
        }),
        DTColProps.XSmall({
            title: "Open Time",
            dataIndex: "openTime",
            key: "openTime",
            render: (openTime: string) => <span>{openTime ? moment(openTime).format("YYYY-MM-DD HH:mm:ss") : ""}</span>,
        }),
        DTColProps.XSmall({
            title: "Profit",
            dataIndex: "profit",
            key: "profit",
        }),
        DTColProps.XSmall({
            title: "Comment",
            dataIndex: "comment",
            key: "comment",
        }),
    ];

    const options: FlexiDataTableOptionsProps = useMemo(
        () => ({
            showHideColumns: false,
            enableFilter: false,
        }),
        [props.data]
    );

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.EXPORT_CSV_EXCEL:
                try {
                    plainAxiosInstance
                        .get(
                            `${APIs.RISK_TOOL.GET_ROLLOVER_DOWNLOAD_VERIFICATION_AND_STOP_OUT}?batchId=${props.currentState.id}&serverId=${props.verificationServerId}`,
                            {
                                headers: {
                                    Accept: "application/octet-stream,text/csv, */*",
                                },
                                responseType: "blob",
                            }
                        )
                        .then(response => {
                            const contentType = response.headers["content-type"];
                            const contentDisposition = response.headers["content-disposition"] || response.headers["Content-Disposition"];

                            if (
                                contentType === "application/octet-stream" ||
                                contentType === "text/csv" ||
                                contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            ) {
                                // Extract filename from content-disposition if available
                                let fileName = `DownloadRollOverVerification_${moment().format("YYYYMMDDHHmmss")}.csv`;
                                if (contentDisposition) {
                                    const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
                                    if (fileNameMatch && fileNameMatch[1]) {
                                        fileName = fileNameMatch[1];
                                    }
                                }

                                const url = window.URL.createObjectURL(new Blob([response.data]));
                                const link = document.createElement("a");
                                link.href = url;
                                link.setAttribute("download", fileName);
                                document.body.appendChild(link);
                                link.click();
                                // Clean up
                                window.URL.revokeObjectURL(url);
                            } else {
                                message.error(`Received non-file response. Error: ${response}`, 3);
                            }
                        })
                        .catch((error: any) => message.error(`Error occured during download: "${error.message}"`, 3));
                } catch (e: any) {
                    message.error(`Error occured during download: "${e.message}"`, 3);
                }
                break;
            default:
                break;
        }
    };

    return (
        <>
            <Space>
                <Select
                    style={{ width: 120 }}
                    value={props.verificationServerId || "All Servers"}
                    options={props.serverDetailList.map(option => ({
                        label: option.value === "" ? "All Servers" : option.text,
                        value: option.value,
                    }))}
                    onChange={props.setVerificationServerId}
                    autoFocus
                />
                <Tooltip title="Search">
                    <Button
                        disabled={props.loading}
                        loading={props.loading}
                        icon={<SearchOutlined />}
                        type="primary"
                        onClick={props.queryVerificationAndStopOutData}
                    />
                </Tooltip>
                <Tooltip title="Download Excel">
                    <Button icon={<DownloadOutlined />} onClick={() => componentCallback(CALLBACK_KEY.EXPORT_CSV_EXCEL, { data: props.data })} />
                </Tooltip>
            </Space>
            <br />
            <Divider style={{ borderColor: "#FFA500" }}>{props.tbTitle}</Divider>
            <FlexiDataTable
                rowKeyProperty="uniqueKey"
                title={false}
                columns={columns}
                options={options}
                dataSource={props.data}
                loading={props.loading}
                callback={componentCallback}
                pagination={{ defaultPageSize: 10 }}
                scroll={{ x: true }}
            />
        </>
    );
};
export default RollOverVerificationTable;
