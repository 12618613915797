import { BarChartOutlined } from "@ant-design/icons";
import { Empty, Modal } from "antd";
import { useEffect, useRef, useState } from "react";
import LoadingComponent from "../../../../components/Loading";
import { apiRequest } from "../../../../services/apiConfig";
import { APIs } from "../../../../services/apis";
import { currencyRender, ErrorCatchValidator, ErrorMessageHandler } from "../../../../utils/Common";
import ChartPanel from "./chartPanel";
import { Treemap, Line } from "@ant-design/plots";
import { Datum } from "@ant-design/charts";
import { cloneDeep } from "lodash";
import moment from "moment";
import { ProfileInfoProps } from "../viewProfile";
import { SUCCESS_FAILED } from "../../../../constants";
import { AccountSymbolSummaryData, DateRangeFilter } from "../../../../constants/type";
import SummaryBar from "../../../../components/Common/SummaryBar";
import EmptyData from "../../../../components/Common/Empty";
import CustomSkeleton from "../../../../components/Common/Skeleton";

export interface PNLSymbolSummaryChartProps extends ProfileInfoProps {
    forPrint?: boolean;
    pNLSymbolChartOnly?: boolean;
    pNLSymbolChartOnlyOption?: PNLSymbolChartOnlyOption;
    setSymbolCallback?: (symbols: string[]) => void;
}

export interface PNLSymbolChartOnlyOption {
    getIndex: number[];
    customTemplate?: (chart: React.ReactElement, index: number) => React.ReactElement;
}

export interface PNLSymbolChartDataProps {
    title: string;
    symbol: string;
    seq: number;
    data: PNLSymbolProps[];
}

export interface PNLSymbolProps {
    date: string;
    type: string;
    name: string;
    value: number;
    seq: number;
}

type StatsProps = {
    symbol: string;
    metrics: metricsProps[];
};

interface metricsProps {
    metricId: number;
    metricName: string;
    value: number;
}

const PNLSymbolSummaryChart = (props: PNLSymbolSummaryChartProps) => {
    const ref = useRef<HTMLHeadingElement>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [isLoadingChart, setIsLoadingChart] = useState<boolean>(true);
    const [seletedSymbol, setSeletedSymbol] = useState<string>("");
    const [seletedSymbolData, setSeletedSymbolData] = useState<PNLSymbolProps[]>([]);
    const [data, setData] = useState<PNLSymbolProps[]>([]);
    const [pnlDatas, setPnlDatas] = useState<PNLSymbolChartDataProps[]>([]);
    const [summaryDatas, setSummaryDatas] = useState<AccountSymbolSummaryData[]>([]);

    const getItemTemplate = (items: PNLSymbolProps[], isLast: boolean = false, totalItem: number = 3): React.ReactNode => {
        let returnTemplate: React.ReactNode[] = [];
        for (let index = 0; index < (items.length > totalItem ? totalItem : items.length); index++) {
            const x = items[isLast ? items.length - 1 - index : index];
            returnTemplate.push(
                <div className="title-value-div" key={`pnl-ts-${index}`}>
                    <div className="title">{x.name}</div>
                    <div className="value">{currencyRender(x.value, 2)}</div>
                </div>
            );
        }

        return returnTemplate;
    };

    const getPnlChartData = (symbol: string, dateFilter: any) => {
        setSeletedSymbol(symbol);
        apiRequest(APIs.ACCOUNT_HISTORICAL_PNL, {
            accountId: props?.accountId,
            brandId: props?.brandId,
            serverId: props?.serverId,
            metricType: 2,
            timeFrame: 1,
            symbols: [symbol],
            dataSamplingMode: 1,
            dataGroupingMode: 1,
            metricIds: [72],
            ...(dateFilter && dateFilter),
        })
            .then((data: any) => {
                if (data && data.length > 0) {
                    let markupData: any[] = [];
                    data.map((x: any) => {
                        if (x.symbol === symbol) {
                            let label = `Daily PNL Symbol (${symbol})`;
                            markupData = markupData.concat(
                                x.groupedData[0].plotData.map((y: any) => ({
                                    name: label,
                                    date: moment(y.date).format("YYYY-MM-DD"),
                                    value: y.value,
                                }))
                            );
                        }

                        return false;
                    });
                    setSeletedSymbolData(markupData);
                } else {
                    setSeletedSymbolData([]);
                }
            })
            .catch((error) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("daily PNL data", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
            })
            .finally(() => setIsLoadingChart(false));

        apiRequest(APIs.GET_ACCOUNT_SUMMARY_PANEL, {
            accountId: props?.accountId,
            brandId: props?.brandId,
            serverId: props?.serverId,
            metricType: 2,
            symbols: [symbol],
            dataGroupingMode: 1,
            isMetricSummary: true,
            ...(dateFilter && dateFilter),
        })
            .then((data: any) => {
                if (data && data.length > 0) {
                    let markupData: any[] = data?.map((x: any) => {
                        return {
                            symbol: x.symbol,
                            groupedData: x.groupedData,
                        };
                    });
                    setSummaryDatas(markupData);
                } else {
                    setSummaryDatas([]);
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("account symbol summary data", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            );
    };

    const getPNLAccSymbolData = (data: any) => {
        return data?.groupedData?.length > 0 ? (
            <div className={`metric-items-container-modal ${props.forPrint ? "for-print" : ""}`}>
                {data?.groupedData
                    .filter((x: any) => x.isView)
                    .map((currData: any, index: number) => {
                        return (
                            <SummaryBar
                                key={`ao-as-mdl-${index}`}
                                iconType={currData.formatType}
                                customIcon={props.currency}
                                name={currData.metricName}
                                value={currData.value}
                                forPrint={props.forPrint}
                                desc={currData.metricDesc}
                                modalMode
                            />
                        );
                    })}
            </div>
        ) : (
            <EmptyData />
        );
    };

    const getPNLSymbolChart = (data: PNLSymbolProps[]) => {
        return (
            <Line
                {...{
                    data,
                    appendPadding: 20,
                    xField: "date",
                    yField: "value",
                    seriesField: "name",
                    ...(props.forPrint
                        ? {
                              xAxis: {
                                  label: {
                                      autoRotate: true,
                                  },
                                  tickLine: {
                                      alignTick: true,
                                  },
                              },
                          }
                        : {
                              xAxis: {
                                  label: {
                                      autoRotate: true,
                                  },
                                  tickLine: {
                                      alignTick: true,
                                  },
                              },
                              slider: {
                                  start: 0,
                                  end: 1,
                              },
                          }),
                    yAxis: {
                        label: {
                            formatter: (v) => currencyRender(v),
                        },
                    },
                    tooltip: {
                        formatter: (datum: Datum) => {
                            return { name: datum.name, value: currencyRender(datum.value, 2) };
                        },
                    },
                }}
            />
        );
    };

    const getPNLSymbolChartOnlyHandler = (symbolList: PNLSymbolProps[], dateFilter: any) => {
        let selectedSymbols: string[] = [];
        if (props.pNLSymbolChartOnlyOption && props.pNLSymbolChartOnlyOption.getIndex.length > 0) {
            selectedSymbols = props.pNLSymbolChartOnlyOption.getIndex.filter((x) => x < symbolList.length).map((x) => symbolList[x].name);

            if (selectedSymbols.length > 0) {
                apiRequest(APIs.ACCOUNT_HISTORICAL_PNL, {
                    accountId: props.accountId,
                    brandId: props.brandId,
                    serverId: props.serverId,
                    metricType: 2,
                    timeFrame: 1,
                    symbols: selectedSymbols,
                    dataSamplingMode: 1,
                    dataGroupingMode: 1,
                    metricIds: [72],
                    ...(dateFilter && dateFilter),
                })
                    .then((data: any) => {
                        if (data && data.length > 0) {
                            let markupData: PNLSymbolChartDataProps[] = data.map((x: any) => {
                                return {
                                    title: `Daily PNL By Symbol (${x.symbol})`,
                                    symbol: x.symbol,
                                    seq: selectedSymbols.findIndex((y) => y === x.symbol),
                                    data: x.groupedData[0].plotData.map((y: any) => ({
                                        name: `Daily PNL By Symbol (${x.symbol})`,
                                        date: moment(y.date).format("YYYY-MM-DD"),
                                        value: y.value,
                                    })),
                                };
                            });
                            markupData.sort((a, b) => a.seq - b.seq);
                            setPnlDatas(markupData);
                            //// Below block join multiple symbol into 1 chart -- START --
                            // let markupData: PNLSymbolProps[] = [];
                            // data.stats.map((x: any) => {
                            //     markupData = markupData.concat(
                            //         x.groupedData[0].plotData.map((y: any) => ({
                            //             name: `(${x.symbol})`,
                            //             date: moment(y.date).format("YYYY-MM-DD"),
                            //             value: y.value,
                            //         }))
                            //     );
                            // });
                            //
                            // setPnlDatas([
                            //     {
                            //         title: "Daily PNL By Top 3 Symbols",
                            //         symbol: "",
                            //         seq: 1,
                            //         data: markupData,
                            //     },
                            // ]);
                            //// Below block join multiple symbol into 1 chart -- END --
                        } else {
                            setPnlDatas([]);
                        }
                    })
                    .catch((error) => {
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("daily PNL data", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                    })
                    .finally(() => setIsLoading(false));

                apiRequest(APIs.GET_ACCOUNT_SUMMARY_PANEL, {
                    accountId: props?.accountId,
                    brandId: props?.brandId,
                    serverId: props?.serverId,
                    metricType: 2,
                    symbols: selectedSymbols,
                    dataGroupingMode: 1,
                    isMetricSummary: true,
                    ...(dateFilter && dateFilter),
                })
                    .then((data: any) => {
                        if (data && data.length > 0) {
                            let markupData: AccountSymbolSummaryData[] = data?.map((x: any) => {
                                return {
                                    symbol: x.symbol,
                                    seq: selectedSymbols.findIndex((y) => y === x.symbol),
                                    groupedData: x.groupedData,
                                };
                            });
                            markupData.sort((a, b) => a.seq - b.seq);
                            setSummaryDatas(markupData);
                        } else {
                            setSummaryDatas([]);
                        }
                    })
                    .catch((error: any) => {
                        ErrorCatchValidator(error, (err: any) =>
                            ErrorMessageHandler("account symbol summary data", SUCCESS_FAILED.FAILED_LOAD_DATA, err)
                        );
                    });
            } else {
                setIsLoading(false);
            }
        }
    };

    const getSymbolRankingByMetric = (dateFilter: any) => {
        setIsLoading(true);
        apiRequest(APIs.ACCOUNT_SYMBOLS_RANKING_BY_METRIC, {
            accountId: props.accountId,
            brandId: props.brandId,
            serverId: props.serverId,
            metricStatsType: 1,
            rankDirection: 1,
            rankMetricId: 72,
            ...(dateFilter && dateFilter),
        })
            .then((data: any) => {
                if (data.stats && data.stats.length > 0) {
                    let tmpData: PNLSymbolProps[] = data.stats.map((x: StatsProps, index: number) => {
                        const currMetric = x.metrics.find((y: any) => y.metricId === data.rankMetricId);
                        return {
                            type: currMetric && currMetric.value < 0 ? "Loss" : "Profit",
                            name: x.symbol,
                            value: currMetric && currMetric.value,
                            seq: index,
                        };
                    });

                    setData(tmpData);

                    if (props.pNLSymbolChartOnly) {
                        getPNLSymbolChartOnlyHandler(tmpData, dateFilter);
                    }

                    props.setSymbolCallback && props.setSymbolCallback(tmpData.map((x) => x.name));
                } else {
                    setData([]);
                    props.setSymbolCallback && props.setSymbolCallback([]);
                    setIsLoading(false);
                }
            })
            .catch((error) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("account symbol ranking data", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => !props.pNLSymbolChartOnly && setIsLoading(false));
    };

    useEffect(() => {
        getSymbolRankingByMetric(props.dateRangeFilter);
        return () => {};
    }, [props.dateRangeFilter]);

    const getConfig = (dateFilter: any): any => ({
        data: {
            name: "root",
            children: cloneDeep(data).map((x) => {
                x.value = Math.abs(x.value);
                return x;
            }),
        },
        legend: {
            position: "right",
        },
        colorField: "type",
        color: ({ type }: any) => (type === "Loss" ? "#dc1616" : "#1c9898"),
        ...(ref.current?.clientWidth !== undefined && {
            height: ref.current?.clientWidth / 2,
        }),
        tooltip: {
            formatter: (data: PNLSymbolProps) => {
                return { name: data.name, value: currencyRender(data.value * (data.type === "Loss" ? -1 : 1), 2) };
            },
        },
        onReady: (plot: any) => {
            plot.on("element:click", (evt: any) => {
                if (evt.data.data) {
                    setIsVisible(true);
                    setIsLoadingChart(true);
                    setTimeout(getPnlChartData.bind(null, (evt.data.data as PNLSymbolProps).name, dateFilter), 100);
                }
            });
        },
    });

    return (
        <>
            {isLoading ? (
                <ChartPanel
                    forPrint={props.forPrint}
                    type={1}
                    icon={<BarChartOutlined />}
                    title={"PNL Symbols Summary"}
                    subTitle={"Overall PNL symbol(s) for current account"}
                >
                    <CustomSkeleton rows={9} />
                </ChartPanel>
            ) : props.pNLSymbolChartOnly ? (
                pnlDatas.length > 0 &&
                pnlDatas.map((x, index) => {
                    const summary = summaryDatas.find((currSummary) => currSummary.symbol === x.symbol);
                    let chartElement = (
                        <ChartPanel forPrint={props.forPrint} type={1} icon={<BarChartOutlined />} title={x.title} subTitle={" "}>
                            {getPNLAccSymbolData(summary)}
                            {getPNLSymbolChart(x.data)}
                        </ChartPanel>
                    );
                    if (props.pNLSymbolChartOnlyOption && props.pNLSymbolChartOnlyOption.customTemplate) {
                        return props.pNLSymbolChartOnlyOption.customTemplate(chartElement, index);
                    }
                    return chartElement;
                })
            ) : (
                <ChartPanel
                    forPrint={props.forPrint}
                    type={1}
                    icon={<BarChartOutlined />}
                    title={"PNL Symbols Summary"}
                    subTitle={"Overall PNL symbol(s) for current account"}
                    rightExtra={
                        data?.length > 0
                            ? data.length > 5
                                ? [
                                      {
                                          title: "Top 3 PNL Symbols",
                                          content: <div className="tag-summary-desc-panel">{getItemTemplate(data)}</div>,
                                      },
                                      {
                                          title: "Bottom 3 PNL Symbols",
                                          content: <div className="tag-summary-desc-panel">{getItemTemplate(data, true)}</div>,
                                      },
                                  ]
                                : [
                                      {
                                          title: "PNL Symbols",
                                          content: <div className="tag-summary-desc-panel">{getItemTemplate(data, false, 5)}</div>,
                                      },
                                  ]
                            : []
                    }
                    rightFooter={
                        <div>
                            <span>Symbol(s) Traded:</span> <span style={{ fontSize: "16px", fontWeight: "bold" }}>{data.length || 0}</span>
                        </div>
                    }
                >
                    {data?.length > 0 ? (
                        <div className="chart">
                            <Treemap {...getConfig(props.dateRangeFilter)} width={ref.current?.clientWidth} />
                        </div>
                    ) : (
                        <>
                            <EmptyData />
                        </>
                    )}
                </ChartPanel>
            )}

            <Modal
                width={900}
                style={{ top: 20 }}
                open={isVisible}
                maskClosable={false}
                title={`Symbol Analysis (${seletedSymbol})`}
                cancelText="Close"
                onCancel={() => setIsVisible(false)}
                okButtonProps={{ style: { display: "none" } }}
                bodyStyle={{ padding: "20px 30px" }}
            >
                {isLoadingChart ? (
                    <div className="loading-container">
                        <LoadingComponent tip="Loading..." />
                    </div>
                ) : (
                    <>
                        <div>{getPNLAccSymbolData(summaryDatas[0])}</div>
                        <div>{getPNLSymbolChart(seletedSymbolData)}</div>
                    </>
                )}
            </Modal>
        </>
    );
};

export default PNLSymbolSummaryChart;
