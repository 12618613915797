import { Button, Empty, Form, Modal, Popover, Space, Timeline, Typography } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DataTableColumnRender, ErrorCatchValidator, ErrorMessageHandler } from "../../../../utils/Common";
import { APIs, apiRequest } from "../../../../services/apiConfig";
import { DefaultIfEmpty, hasAnyKey } from "../../../../utils/object";
import { DimemsionFilterType, UserList, mapFilterParams } from "..";
import { ComponentType, INTRADAY_BREACH_SEVERITY, SUCCESS_FAILED } from "../../../../constants";
import { ToObjectWithKey, ToOptionTypeList } from "../../../../utils/array";
import { isEmptyOrNull } from "../../../../utils/string";
import CustomSkeleton from "../../../../components/Common/Skeleton";
import { ClockCircleOutlined, EyeOutlined } from "@ant-design/icons";
import {
    AlarmComment,
    getCombineSeverityProgressionAndEventCommentProgression,
    getFilterRulesColumnRender,
    getMetricColumnRender,
} from "../../Alarm/List";
import moment from "moment";
import { DateTimeUtil } from "../../../../utils/datetime";
import { FormComponent } from "../../../../components/FormComponent";
import { REQUIRED_FIELD } from "../../../../constants/errorMessage";
import { FaRegCommentAlt, FaRegUserCircle } from "react-icons/fa";
import { getProfile } from "../../../../services/localStorage";

interface AlarmSummaryProps {
    mappingData: any;
    selectedParams?: DimemsionFilterType[] | boolean;
    resetState: number;
    onCallBack: (value: any) => void;
    userList: UserList[];
}

const AlarmSummaryForSearch = (props: AlarmSummaryProps) => {
    const [runRefetchData, setRunRefetchData] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<any[]>([]);
    const priorityObj = ToObjectWithKey(ToOptionTypeList(INTRADAY_BREACH_SEVERITY), "value");
    const [latestDateTime, setLatestDateTime] = useState<any>("");
    const [commentForm] = Form.useForm();
    const [visible, setVisible] = useState<boolean>(false);
    const [comData] = useState<AlarmComment>();
    const uPr: any = getProfile();
    const serverTime_UTCOffset: number | undefined = uPr?.hasOwnProperty("stom") ? uPr.stom : undefined;

    const readPhrase = (newEvent: number) => {
        var utterance = new SpeechSynthesisUtterance(`Intraday 监控页面，有 ${newEvent} 新的警报要处理!快快去看呀!`);
        utterance.lang = "zh-CN";
        window.speechSynthesis.speak(utterance);
    };

    const getAlarmDetail = useCallback(() => {
        setIsLoading(true);
        apiRequest(APIs.GET_ALARM_LISTING, {
            isIntradayOnly: true,
            ...(hasAnyKey(props.selectedParams) && { dimensionFilters: mapFilterParams(props.selectedParams) }),
        })
            .then((data: any) => {
                if (data && data.length > 0) {
                    if (isEmptyOrNull(latestDateTime)) {
                        setLatestDateTime(data[0].eventTimeUtc);
                        readPhrase(data.length);
                    } else {
                        let diffSeconds: number = DateTimeUtil.getDiffBetween2Date(latestDateTime, data[0].eventTimeUtc, 2) as number;
                        if (diffSeconds > 0) {
                            let oldDate = moment(latestDateTime),
                                newEventCount = data.filter((x: any) => moment(x.eventTimeUtc) > oldDate).length;
                            setLatestDateTime(data[0].eventTimeUtc);

                            if (newEventCount > 0) readPhrase(newEventCount);
                        }
                    }
                    setData(data);
                } else {
                    setData([]);
                }
            })
            .catch(error => {
                setData([]);
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("alarm summary", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
            })
            .finally(() => setIsLoading(false));
    }, [latestDateTime, props.selectedParams]);

    const renderMetrics = (v: string, i: number, metricFilters: string, eventHashKey: any) => {
        let eventDetails = JSON.parse(v),
            metricInfo = JSON.parse(metricFilters);
        return getMetricColumnRender(eventHashKey, eventDetails, metricInfo, props.mappingData);
    };

    const massageLabel = (obj: any, value: any, extra: any = undefined) => {
        let objKey = DefaultIfEmpty(obj, extra, { objKey: "" }).objKey;

        if (!isEmptyOrNull(objKey)) {
            const options = props.mappingData[objKey];
            return DefaultIfEmpty(options, value, { text: "" }).text;
        }
        return value;
    };

    const renderDimensions = (eventDetails: string, i: number) => {
        let ED = JSON.parse(eventDetails);

        return (
            <div className="alarm-di-me" key={`tag-box-${i}`}>
                {Object.keys(ED)
                    .filter(x => props.mappingData.dimension?.hasOwnProperty(x))
                    .map((x: any, idx: number) => {
                        return (
                            <div key={`tag-tag-${idx}`} className="item">
                                <span>{DefaultIfEmpty(props.mappingData.dimension, x, { text: "" }).text}</span>
                                <span>{massageLabel(props.mappingData.dimension, ED[x], x)}</span>
                            </div>
                        );
                    })}
            </div>
        );
    };

    const massageCallbackDimensionsFromAlarmListing = (eventDetails: string) => {
        let ED = JSON.parse(eventDetails);
        return () => {
            props.onCallBack({
                ...(ED.account_id && {
                    Account: [`${ED.account_id}|${ED.server_id}|${DefaultIfEmpty(props.mappingData["server"], ED.server_id, { text: "" }).text}`],
                }),
                ...(ED.group && { AccGroup: [ED.group] }),
                ...(ED.symbol && { Symbol: [ED.symbol] }),
                ...(ED.symbol_asset_type_id && { SymbolAssetType: [ED.symbol_asset_type_id] }),
                ...(ED.server_id && { Server: [ED.server_id] }),
                ...(ED.brand_id && { Brand: [ED.brand_id] }),
                ...(ED.country && { Country: [ED.country] }),
                ...(ED.rebate_account_id &&
                    ED.rebate_server_id && {
                        IB: [
                            `${ED.rebate_account_id}|${ED.rebate_server_id}|${
                                DefaultIfEmpty(props.mappingData["server"], ED.rebate_server_id, { text: "" }).text
                            }`,
                        ],
                    }),
            });
        };
    };

    useEffect(() => {
        if (hasAnyKey(props.selectedParams)) setRunRefetchData(true);
        return () => {};
    }, [props.selectedParams]);

    useEffect(() => {
        if (runRefetchData) {
            setIsLoading(true);
            getAlarmDetail();
            setRunRefetchData(false);
        }
        return () => {};
    }, [runRefetchData]);

    useEffect(() => {
        setRunRefetchData(true);
        return () => {};
    }, [props.resetState]);

    const memoizedTimelineItems = useMemo(() => {
        return data.map((currData: any, i: number) => {
            let combinedArray = getCombineSeverityProgressionAndEventCommentProgression(
                currData["severityProgression"],
                currData["eventCommentProgression"],
                1,
                2
            );

            if (combinedArray.length === 0) return null;

            return (
                <Timeline.Item key={i}>
                    <div className="alarm-detail-box">
                        <div className={`content`}>
                            <div className="title-cont">
                                <Button type="link" onClick={massageCallbackDimensionsFromAlarmListing(currData.eventDetails)}>
                                    {currData.alarmName}
                                </Button>
                                <Space>
                                    {!isEmptyOrNull(currData.alarmCenterEventHashKey) && (
                                        <FaRegCommentAlt
                                            onClick={() => {
                                                setVisible(true);
                                                commentForm.setFieldsValue({
                                                    alarmCenterCorrelationHashKey: currData.alarmCenterCorrelationHashKey,
                                                    eventComment: "",
                                                });
                                            }}
                                            style={{ cursor: "pointer" }}
                                        />
                                    )}
                                    <Popover
                                        placement="right"
                                        overlayClassName="alarm-historical-popover-content"
                                        content={
                                            <div className="alarm-historical-popover-timeline-container nice-scrollbar">
                                                <Timeline>
                                                    {combinedArray.map((x: any, xIdx: number) => {
                                                        let eventDetails = x["eventDetails"] && JSON.parse(x["eventDetails"]);
                                                        let metricInfo = x["metricFilters"] && JSON.parse(x["metricFilters"]);
                                                        let timelineKey = `${Math.random()}`;
                                                        let itemKey = x.alarmEventId && x.createdDateUtc && `${x.alarmEventId}-${x.createdDateUtc}`;
                                                        return (
                                                            <Timeline.Item
                                                                key={`tli-${timelineKey}-${xIdx}`}
                                                                color={x.type === 1 ? "#004b57" : "green"}
                                                            >
                                                                {x.type === 2 ? (
                                                                    <div className="alarm-popover-detail-container shadow-light">
                                                                        <div className="title-container">
                                                                            <div className="left">
                                                                                <div className="comment-by">
                                                                                    <FaRegUserCircle />
                                                                                    <span className="user-name">
                                                                                        {
                                                                                            DefaultIfEmpty(
                                                                                                ToObjectWithKey(props.userList, "value"),
                                                                                                x.eventCommentBy,
                                                                                                { text: "" }
                                                                                            ).text
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="right">
                                                                                <ClockCircleOutlined />{" "}
                                                                                {DataTableColumnRender.DateTime_UTC_TO_ServerTime(
                                                                                    x.eventCommentDateUtc,
                                                                                    serverTime_UTCOffset
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                        <div className="comment-content">
                                                                            <Typography.Paragraph>{x.eventComment}</Typography.Paragraph>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div className="alarm-popover-detail-container shadow-light">
                                                                        <div className="title-container">
                                                                            <div className="left">
                                                                                <span className={`priority-div priority-color-${x.breachSeverity}`}>
                                                                                    {
                                                                                        DefaultIfEmpty(priorityObj, x.breachSeverity, {
                                                                                            text: "",
                                                                                        }).text
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                            <div className="right">
                                                                                <ClockCircleOutlined />{" "}
                                                                                {DataTableColumnRender.DateTime_ServerTime(x.eventTime)}
                                                                            </div>
                                                                        </div>
                                                                        <div className="content-container">
                                                                            <div className="left">
                                                                                <div className="theader">Metrics</div>
                                                                                <div className="content">
                                                                                    {getMetricColumnRender(
                                                                                        itemKey,
                                                                                        eventDetails,
                                                                                        metricInfo,
                                                                                        props.mappingData
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div className="right">
                                                                                <div className="theader">Filter Rule(s)</div>
                                                                                <div className="content">
                                                                                    {getFilterRulesColumnRender(
                                                                                        itemKey,
                                                                                        eventDetails,
                                                                                        metricInfo,
                                                                                        props.mappingData
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Timeline.Item>
                                                        );
                                                    })}
                                                </Timeline>
                                            </div>
                                        }
                                        title={<span className="alarm-historical-popover-title">Breach Severity Progression</span>}
                                    >
                                        <EyeOutlined style={{ cursor: "pointer" }} />
                                    </Popover>
                                    <div className={`count-container priority-color-${currData.breachSeverity}`}>
                                        <span>{INTRADAY_BREACH_SEVERITY[currData.breachSeverity]}</span>
                                    </div>
                                </Space>
                            </div>
                            <div className="columns">
                                <div className="first-column">{renderDimensions(currData.eventDetails, i)}</div>
                                <div className="second-column">
                                    {renderMetrics(currData.eventDetails, i, currData.metricFilters, currData.eventHashKey)}
                                </div>
                            </div>
                        </div>
                        <div className="time">{DataTableColumnRender.DateTime_ServerTime(currData.eventTime)}</div>
                    </div>
                </Timeline.Item>
            );
        });
    }, [data]);

    return (
        <div className={`alarm-summary-for-search-container`}>
            <div className="title">
                <span className="title-text">Alarm Summary</span>
            </div>
            <div className="alarm-det-container nice-scrollbar">
                {isLoading ? <CustomSkeleton /> : data.length === 0 ? <Empty /> : <Timeline>{memoizedTimelineItems}</Timeline>}
                <Modal
                    open={visible}
                    title={"Create New Comment"}
                    onCancel={() => {
                        commentForm.resetFields();
                        setVisible(false);
                    }}
                    onOk={() => {
                        commentForm
                            .validateFields()
                            .then((values: any) => {
                                const alarmComment = {
                                    alarmCenterCorrelationHashKey: values.alarmCenterCorrelationHashKey,
                                    eventComment: values.eventComment,
                                };
                                commentForm.resetFields();
                                return apiRequest(APIs.CREATE_ALARM_COMMENT, alarmComment);
                            })
                            .then((data: any) => {
                                ErrorMessageHandler("New comment", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                                setRunRefetchData(true);
                                setVisible(false);
                            })
                            .catch((error: any) => {
                                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("new comment", SUCCESS_FAILED.FAILED_CREATE_DATA, err));
                            });
                    }}
                >
                    <Form form={commentForm} labelCol={{ span: 4.5 }} layout="horizontal" initialValues={comData}>
                        <FormComponent label={""} name={"alarmCenterCorrelationHashKey"} extra={{ type: ComponentType.hidden, value: "" }} />
                        <FormComponent
                            label="Comment"
                            name={"eventComment"}
                            extra={{
                                type: ComponentType.textarea,
                                value: "",
                                rules: [
                                    {
                                        required: true,
                                        message: REQUIRED_FIELD,
                                    },
                                ],
                                inputProps: {
                                    showCount: true,
                                    rows: 5,
                                },
                            }}
                        />
                    </Form>
                </Modal>
            </div>
        </div>
    );
};

export default AlarmSummaryForSearch;
