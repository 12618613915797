import {
    CalendarOutlined,
    CheckCircleOutlined,
    ClockCircleOutlined,
    CloseCircleOutlined,
    HolderOutlined,
    NumberOutlined,
    OneToOneOutlined,
    PercentageOutlined,
    SortAscendingOutlined,
} from "@ant-design/icons";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Form, Modal, Row } from "antd";
import CardBox from "../../../../components/Common/CardBox";
import FlexiDataTable from "../../../../components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType, METRIC_FORMAT_TYPE_ENUM, SUCCESS_FAILED } from "../../../../constants";
import { FlexiDataTableCallbackProps, AccMetric, FlexiDataTableOptionsProps } from "../../../../constants/type";
import AuthHelper, { AuthKeys } from "../../../../helpers/authHelper";
import { apiRequest } from "../../../../services/apiConfig";
import { APIs } from "../../../../services/apis";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../../../utils/Common";
import { FormComponent } from "../../../../components/FormComponent";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { cloneDeep } from "lodash";

const AccSumMetricConfig = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [accMetrics, setAccMetrics] = useState<AccMetric[]>([]);
    const [isShowSortingModal, setIsShowSortingModal] = useState<boolean>(false);
    const [sortingList, setSortingList] = useState<AccMetric[]>([]);
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.APP_CONFIGURATION_EDIT);
    const [sortingForm] = Form.useForm();

    let navigate = useNavigate();

    const getTableColumnConfig = () => [
        {
            title: "Metric Name",
            dataIndex: "displayName",
            key: "displayName",
            options: {
                filter: {
                    type: ComponentType.text,
                },
            },
        },

        DTColProps.Middle({
            title: "Format Type",
            dataIndex: "formatType",
            key: "formatType",
            render: (formatType: number, record: any) => {
                if (formatType < 0) return "";
                switch (formatType) {
                    case METRIC_FORMAT_TYPE_ENUM.USD:
                        return (
                            <Row>
                                <Col span={8}>
                                    <b>USD</b>
                                </Col>
                                <Col>USD</Col>
                            </Row>
                        );
                    case METRIC_FORMAT_TYPE_ENUM.COUNT:
                        return (
                            <Row>
                                <Col span={8}>
                                    <NumberOutlined style={{ fontSize: "1rem" }} />
                                </Col>
                                <Col>Count</Col>
                            </Row>
                        );
                    case METRIC_FORMAT_TYPE_ENUM.PERCENTAGE:
                        return (
                            <Row>
                                <Col span={8}>
                                    <PercentageOutlined style={{ fontSize: "1rem" }} />
                                </Col>
                                <Col>Percentage</Col>
                            </Row>
                        );
                    case METRIC_FORMAT_TYPE_ENUM.RATIO:
                        return (
                            <Row>
                                <Col span={8}>
                                    <OneToOneOutlined style={{ fontSize: "1rem" }} />
                                </Col>
                                <Col>Ratio</Col>
                            </Row>
                        );
                    case METRIC_FORMAT_TYPE_ENUM.DATE:
                        return (
                            <Row>
                                <Col span={8}>
                                    <CalendarOutlined style={{ fontSize: "1rem" }} />
                                </Col>
                                <Col>Date</Col>
                            </Row>
                        );
                    case METRIC_FORMAT_TYPE_ENUM.TIME:
                        return (
                            <Row>
                                <Col span={8}>
                                    <ClockCircleOutlined style={{ fontSize: "1rem" }} />
                                </Col>
                                <Col>Time</Col>
                            </Row>
                        );
                    default:
                        return <></>;
                }
            },
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: [
                        {
                            text: (
                                <Row>
                                    <Col span={3}>
                                        <CalendarOutlined />
                                    </Col>
                                    <Col>Date</Col>
                                </Row>
                            ),
                            value: METRIC_FORMAT_TYPE_ENUM.DATE,
                        },
                        {
                            text: (
                                <Row>
                                    <Col span={3}>
                                        <NumberOutlined />
                                    </Col>
                                    <Col>Count</Col>
                                </Row>
                            ),
                            value: METRIC_FORMAT_TYPE_ENUM.COUNT,
                        },
                        {
                            text: (
                                <Row>
                                    <Col span={3}>
                                        <ClockCircleOutlined />
                                    </Col>
                                    <Col>Time</Col>
                                </Row>
                            ),
                            value: METRIC_FORMAT_TYPE_ENUM.TIME,
                        },
                        {
                            text: (
                                <Row>
                                    <Col span={3}>
                                        <PercentageOutlined />
                                    </Col>
                                    <Col>Percentage</Col>
                                </Row>
                            ),
                            value: METRIC_FORMAT_TYPE_ENUM.PERCENTAGE,
                        },
                        {
                            text: (
                                <Row>
                                    <Col span={3}>
                                        <OneToOneOutlined />
                                    </Col>
                                    <Col>Ratio</Col>
                                </Row>
                            ),
                            value: METRIC_FORMAT_TYPE_ENUM.RATIO,
                        },
                        {
                            text: (
                                <Row>
                                    <Col span={3}>
                                        <b>USD</b>
                                    </Col>
                                    <Col>USD</Col>
                                </Row>
                            ),
                            value: METRIC_FORMAT_TYPE_ENUM.USD,
                        },
                    ],
                },
            },
        }),
        DTColProps.Small({
            title: "View in Account Summary",
            dataIndex: "isView",
            key: "isView",
            align: "center",
            render: (isView: boolean, rowData: any) => {
                if (rowData.isView) {
                    return <CheckCircleOutlined style={{ color: "#0ab76e", fontSize: "1.375rem" }} />;
                } else {
                    return <CloseCircleOutlined style={{ color: "#f00f00", fontSize: "1.375rem" }} />;
                }
            },
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: [
                        {
                            text: "Yes",
                            value: true,
                        },
                        {
                            text: "No",
                            value: false,
                        },
                    ],
                },
            },
        }),
    ];

    const options: FlexiDataTableOptionsProps = {
        edit: enableUpdate,
        extraButtons: [
            {
                text: "Sorting",
                value: "ee-sorting",
                icon: <SortAscendingOutlined />,
            },
        ],
    };

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, data: any) => {
        switch (type) {
            case CALLBACK_KEY.DO_EDIT:
                navigate("/siteadmin/appconfig/accSumMetricConfig/edit", { state: { data: data, action: "edit" } });
                break;
            case CALLBACK_KEY.OTHERS:
                setIsShowSortingModal(true);
                break;
            default:
                break;
        }
    };

    const getAccSumMetricListing = () => {
        setIsLoading(true);
        apiRequest(APIs.GET_ACC_METRIC_SUM_LIST_360, {})
            .then((res: any) => {
                let currentData: AccMetric[] = cloneDeep(res);
                currentData.sort((a: AccMetric, b: AccMetric) => a.order - b.order);
                setAccMetrics(currentData);
                setSortingList(currentData.filter((x: AccMetric) => x.isView === true));
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("account metric summary list", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsLoading(false));
    };

    const reorder = (list: AccMetric[], startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result: any) => {
        if (!result.destination) {
            return;
        }
        let finalList = reorder(sortingList, result.source.index, result.destination.index);
        sortingForm.setFieldsValue({ groupSorting: finalList.map((x) => `${x.metricId}`) });
        setSortingList(finalList);
    };

    const submitSortingList = (list: string[]) => {
        setIsShowSortingModal(false);
        setIsLoading(true);
        apiRequest(APIs.SORT_ACC_METRIC_SUM_LIST_360, { metricIds: list })
            .then(() => {
                ErrorMessageHandler("Account metric ordering", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                getAccSumMetricListing();
            })
            .catch((error) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("account metric ordering", SUCCESS_FAILED.SUCCESS_UPDATE_DATA, err))
            )
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        getAccSumMetricListing();
        return () => {};
    }, []);

    return (
        <>
            <CardBox title={"Account Summary Metric Configuration"}>
                <FlexiDataTable
                    rowKeyProperty="metricId"
                    title=""
                    columns={getTableColumnConfig()}
                    options={options}
                    dataSource={accMetrics || []}
                    callback={componentCallback}
                    loading={isLoading}
                />
            </CardBox>
            {isShowSortingModal && (
                <Modal
                    open={true}
                    title="Account Summary Metric Sorting"
                    onCancel={() => setIsShowSortingModal(false)}
                    onOk={() => {
                        sortingForm
                            .validateFields()
                            .then((values) => submitSortingList(values["groupSorting"]))
                            .catch((err) => {});
                    }}
                >
                    <Form form={sortingForm} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} layout="horizontal">
                        <FormComponent label={""} name={"groupSorting"} extra={{ type: ComponentType.hidden, value: "" }} />
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId={`acc-sum-metric-group-sorting-list`} direction="vertical">
                                {(provided, snapshot) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps} className="metric-group-dragging-container">
                                        <div className="title-div">
                                            <span>Drag any account metric for sorting</span>
                                        </div>
                                        <div className="items-div">
                                            {sortingList.map((x: AccMetric, index: number) => {
                                                return (
                                                    <Draggable key={`fc-tfr-sp-i-${x.metricId}`} draggableId={`g-${x.metricId}`} index={index}>
                                                        {(provided, snapshot) => (
                                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                <div className="sort-item">
                                                                    <HolderOutlined />
                                                                    {x.displayName}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            })}
                                            {provided.placeholder}
                                        </div>
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </Form>
                </Modal>
            )}
        </>
    );
};

export default AccSumMetricConfig;
