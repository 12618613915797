import { Tabs } from "antd";
import { useMemo } from "react";
import AuthHelper, { AuthKeys } from "../../../helpers/authHelper";
import LPMonitorAlarmConfiguration from "./Configuration";
import LPMonitorAlarmListing from "./List";

export interface LPMonitorAlarmProps {}

const LPMonitorAlarm = (props: LPMonitorAlarmProps) => {
    const authHp = new AuthHelper();
    const enableEdit = authHp.isAuthorized(AuthKeys.LP360_ALARM_EDIT);
    const tabItems = useMemo(() => {
        let defaultList = [];
        if (authHp.isAuthorized(AuthKeys.LP360_ALARM_VIEW)) {
            defaultList.push({
                label: `Alarm Listing`,
                key: "1",
                children: <LPMonitorAlarmListing />,
            });
        }
        if (authHp.isAuthorized(AuthKeys.LP360_ALARM_EDIT)) {
            defaultList.push({
                label: `Alarm Configuration`,
                key: "2",
                children: <LPMonitorAlarmConfiguration />,
            });
        }
        return defaultList;
    }, [enableEdit]);

    return (
        <>
            <Tabs type="card" defaultActiveKey="1" destroyInactiveTabPane tabBarStyle={{ marginBottom: "0" }} items={tabItems} />
        </>
    );
};

export default LPMonitorAlarm;
