import { Button } from "antd";
import "../../../index.less";
import { CSVLink } from "react-csv";
import { DownloadOutlined } from "@ant-design/icons";

const DownloadBatchUploadTemplate = () => {
    const problematicClientTemplateColumns = [
        {
            title: "Server",
            key: "Server",
        },
        {
            title: "Login",
            key: "Login",
        },
        {
            title: "Brand",
            key: "Brand",
        },
        {
            title: "Regulator",
            key: "Regulator",
        },
        {
            title: "Status",
            key: "Status",
        },
        {
            title: "Comment",
            key: "Comment",
        },
        {
            title: "BlockType",
            key: "BlockType",
        },
    ];

    return (
        <CSVLink
            key={`btn-export-${Math.random()}`}
            data={[
                {
                    Server: "MT4_SERVER",
                    Login: "7980793",
                    Brand: "AU",
                    Regulator: "VFSC",
                    Status: "Pending",
                    Comment: "Please Check",
                    BlockType: "NT",
                },
                {
                    Server: "MT4_SERVER",
                    Login: "7250080",
                    Brand: "AU",
                    Regulator: "VFSC",
                    Status: "Normal",
                    Comment: "Normal Client",
                    BlockType: "NT",
                },
                {
                    Server: "MT4_SERVER",
                    Login: "7676852",
                    Brand: "AU",
                    Regulator: "VFSC",
                    Status: "Suspicious",
                    Comment: "More information is needed to clarify the client’s status",
                    BlockType: "NT",
                },
                {
                    Server: "MT4_SERVER",
                    Login: "7683586",
                    Brand: "AU",
                    Regulator: "VFSC",
                    Status: "Toxic",
                    Comment: "Remove NT after solve the case",
                    BlockType: "NT",
                },
                {
                    Server: "MT4_SERVER",
                    Login: "7224626",
                    Brand: "AU",
                    Regulator: "VFSC",
                    Status: "Pending",
                    Comment: "Please Check",
                    BlockType: "NT",
                },
                {
                    Server: "MT4_SERVER",
                    Login: "7843780",
                    Brand: "AU",
                    Regulator: "VFSC",
                    Status: "Pending",
                    Comment: "Please Check",
                    BlockType: "NT",
                },
            ]}
            headers={problematicClientTemplateColumns.map(x => {
                return { label: x.title, key: x.key };
            })}
            filename={`Problematic_Clients_Batch_Upload_Format-${new Date().getTime()}.csv`}
        >
            <Button htmlType="button" style={{ marginLeft: "0.651vw", width: "auto" }} icon={<DownloadOutlined style={{ fontSize: "0.875rem" }} />}>
                Download Batch Upload Template
            </Button>
        </CSVLink>
    );
};

export default DownloadBatchUploadTemplate;
