import React, { useState } from "react";
import useRCIssuesLog, { useRCIssuesLogMutate } from "../../../hooks/useRCIssuesLog";
import type { RCIssueLogType } from "../../../hooks/useRCIssuesLog";
import { Badge, Button, Checkbox, Col, Input, Modal, Row, Space, Table, Tag, Typography, notification } from "antd";
import MessageCard from "../components/MessageCard";
import { EditOutlined } from "@ant-design/icons";
import moment from "moment";
import { capsToTitleCase } from "../StatusMonitor/statusUtils";
import BasicFilter from "../../../components/BasicFilter";
import useRCIssuesLogStore from "../../../store/useRCIssuesLogStore";
// import search library
import { matchSorter } from "match-sorter";

type IFilterData = {
    dataKey: string;
    dataKeyLabel: string;
    dataValueToFilter: {
        label: string;
        value: string;
    }[];
}[];

const trimAndRemoveAllSpace = (str: string) => str.replace(/\s/g, "").toLowerCase();

const CommonIssuesLogTable = ({ type, filterData = [] }: { type: RCIssueLogType; filterData?: IFilterData }) => {
    // const typeNumber = mapIssueLogBackToEnum(type);
    const { rcIssuesLog, isLoading, rcIssuesLogError } = useRCIssuesLog(type);
    const { checkIssueLog, doneIssueLog, updateCommentIssueLog } = useRCIssuesLogMutate({ type });
    const { TextArea } = Input;
    const { Text } = Typography;
    const [currEditCommentRowID, setCurrEditCommentRowID] = useState<number[] | []>([]);
    const [textAreaValue, setTextAreaValue] = useState<string>("");
    const filterStore = useRCIssuesLogStore(state => state.filterStore?.[type] ?? {});

    // const updateFilterStore = useRCIssuesLogStore(state => state.updateFilterStore);

    const columns = [
        {
            title: "Server",
            dataIndex: "server",
            sorter: (a: any, b: any) => {
                // sort alphabetically and length
                if (a.server?.toLowerCase() < b.server?.toLowerCase()) {
                    return -1;
                }
                if (a.server?.toLowerCase() > b.server?.toLowerCase()) {
                    return 1;
                }
                return 0;
            },
        },
        {
            title: "Time",
            dataIndex: "time",
            sorter: (a: any, b: any) => {
                // sort alphabetically and length
                if (a.time?.toLowerCase() < b.time?.toLowerCase()) {
                    return -1;
                }
                if (a.time?.toLowerCase() > b.time?.toLowerCase()) {
                    return 1;
                }
                return 0;
            },
        },
        {
            title: "IP",
            dataIndex: "ip",
            sorter: (a: any, b: any) => {
                // sort alphabetically and length
                if (a.ip?.toLowerCase() < b.ip?.toLowerCase()) {
                    return -1;
                }
                if (a.ip?.toLowerCase() > b.ip?.toLowerCase()) {
                    return 1;
                }
                return 0;
            },
        },
        {
            title: "Message",
            dataIndex: "msg",
            sorter: (a: any, b: any) => {
                // sort alphabetically and length
                if (a.msg?.toLowerCase() < b.msg?.toLowerCase()) {
                    return -1;
                }
                if (a.msg?.toLowerCase() > b.msg?.toLowerCase()) {
                    return 1;
                }
                return 0;
            },
        },
        {
            title: "Check User",
            dataIndex: "checkUser",
            sorter: (a: any, b: any) => {
                // sort alphabetically and length
                if (a.checkUser?.toLowerCase() < b.checkUser?.toLowerCase()) {
                    return -1;
                }
                if (a.checkUser?.toLowerCase() > b.checkUser?.toLowerCase()) {
                    return 1;
                }
                return 0;
            },
        },
        {
            title: "Check Time",
            dataIndex: "checkTime",
            render: (dateTime: any) => (dateTime ? moment(dateTime).format("YYYY-MM-DD HH:mm:ss") : ""),
            sorter: (a: any, b: any) => {
                // sort alphabetically and length
                if (a.checkTime?.toLowerCase() < b.checkTime?.toLowerCase()) {
                    return -1;
                }
                if (a.checkTime?.toLowerCase() > b.checkTime?.toLowerCase()) {
                    return 1;
                }
                return 0;
            },
        },
        {
            title: "Comments",
            dataIndex: "comment",
            render: (text: any, data: any) => {
                return (
                    <Space size={1}>
                        <Button
                            type="link"
                            onClick={() => {
                                setCurrEditCommentRowID([data.id]);
                                setTextAreaValue(text);
                            }}
                            icon={<EditOutlined />}
                        />
                        {text}
                    </Space>
                );
            },
            sorter: (a: any, b: any) => {
                // sort alphabetically and length
                if (a.comment?.toLowerCase() < b.comment?.toLowerCase()) {
                    return -1;
                }
                if (a.comment?.toLowerCase() > b.comment?.toLowerCase()) {
                    return 1;
                }
                return 0;
            },
        },
    ];
    // console.log(rcIssuesLog);

    // massage rcIssuesLog with unique key

    // const dummy = [
    //     {
    //         id: 5640928,
    //         server: "PUG2",
    //         time: "2024-05-23 04:18:20.423000",
    //         ip: "",
    //         msg: "'842076': request deleted due timeout (180 seconds)",
    //         checkUser: null,
    //         checkTime: null,
    //         comment: null,
    //     },
    // ];

    // const massagedData =
    //     dummy?.length >= 1
    //         ? dummy?.map((item: any, index: number) => {
    //               return {
    //                   ...item,
    //                   key: item.id,
    //               };
    //           })
    //         : [];
    const massagedData =
        rcIssuesLog?.length >= 1
            ? rcIssuesLog?.map((item: any, index: number) => {
                  return {
                      ...item,
                      key: item.id,
                  };
              })
            : [];

    const massagedDataFiltered = massagedData?.filter((eachData: any) => {
        // If filterData is empty, return all data
        if (filterData.length <= 0) {
            return true;
        }

        // Check if each data matches any of the filterData
        return filterData.some(eachFilterData => {
            // If filterStoreGroup is not defined, return all data
            if (Object.keys(filterStore).length <= 0) {
                return true;
            }

            // Check if eachFilterData.dataKey exists in each data
            if (!eachData[eachFilterData.dataKey]) {
                return false;
            }

            // check eachFilterData.dataValueToFilter, and see if it exist in filterStoreGroup, if it does, check if it matches the data
            return eachFilterData.dataValueToFilter.some(eachFilter => {
                const filterKey = trimAndRemoveAllSpace(eachFilter.label);

                if (filterStore?.[filterKey] === true || filterStore[filterKey] === undefined) {
                    return eachData[eachFilterData.dataKey].toString().toLowerCase().includes(eachFilter.value.toLowerCase());
                }
                return false;
            });

            // // Check if each data matches any of the filter values
            // return eachFilterData.dataValueToFilter.some(eachFilter => {
            //     const filterKey = trimAndRemoveAllSpace(eachFilter.label);
            //     if (filterStoreGroup[filterKey] === true || filterStoreGroup[filterKey] === undefined) {
            //         // return each[eachFilterData.dataKey].toString().toLowerCase().includes(eachFilter.value.toLowerCase());
            //         return each[eachFilterData.dataKey].toString().toLowerCase().includes(eachFilter.value.toLowerCase());
            //     }
            //     return false;
            // });
        });
    });

    const [searchQuery, setSearchQuery] = useState("");

    const result = matchSorter(massagedDataFiltered ?? [], searchQuery ?? "", {
        keys: ["server", "msg"],
    });

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [openCheckModal, setOpenCheckModal] = useState(false);
    const [openDoneModal, setOpenDoneModal] = useState(false);

    // @ts-ignore
    const selectedRowHasMissingCheckUser = massagedData?.filter((each: any) => selectedRowKeys.includes(each?.id) && !each.checkUser)?.length >= 1;

    // console.log(selectedRowHasMissingCheckUser);
    const onSelectChange = (selectedKeys: any) => {
        setSelectedRowKeys(selectedKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const handleCheck = async () => {
        try {
            const resp = await checkIssueLog({ ids: selectedRowKeys });
            if (resp) {
                setSelectedRowKeys([]);
                setOpenCheckModal(false);
                notification.success({
                    message: "Success Checked",
                    description: `Selected Alarm are checked.`,
                });
            }
        } catch (error) {
            console.log("Error in checking alarm", error);
            notification.error({
                message: "Check failed",
                description: `Selected Alarm are not checked. Error: ${error}`,
            });
        }
    };
    const handleDone = async () => {
        try {
            const resp = await doneIssueLog({ ids: selectedRowKeys });
            if (resp) {
                setSelectedRowKeys([]);
                setOpenDoneModal(false);
                notification.success({
                    message: "Success marked as done",
                    description: `Selected Alarm are marked as done.`,
                });
            }
        } catch (error) {
            console.log("Error in marking alarm as done", error);
            notification.error({
                message: "Failed to mark as done",
                description: `Selected Alarm are not marked as done. Error: ${error}`,
            });
        }
    };

    const handleUpdateComment = async () => {
        try {
            const resp = await updateCommentIssueLog({
                ids: currEditCommentRowID,
                comment: textAreaValue,
            });

            if (resp.status === 200 && resp.data > 0) {
                setTextAreaValue("");
                setCurrEditCommentRowID([]);
                notification.success({
                    message: "Comment updated",
                    description: "Comment updated successfully",
                });
            }
        } catch (err) {
            console.log("Error in handleUpdateComment", err);
            notification.error({
                message: "Failed to update comment",
                description: `Failed to update comment. Error: ${err}`,
            });
        }
    };

    return (
        <>
            {filterData?.length >= 1 && (
                <BasicFilter>
                    <Row gutter={[8, 8]}>
                        {filterData.map((each, index) => {
                            return (
                                <Col key={index} xs={24} md={24}>
                                    <div>
                                        <Space direction="vertical" style={{ width: "100%" }}>
                                            <Text strong>{each.dataKeyLabel}</Text>
                                            {/* <MyCheckbox type={type} label={each.dataKey} value={each.dataValueToFilter[0].value} /> */}
                                            <Row gutter={[2, 2]}>
                                                {each.dataValueToFilter.map((eachFilter, index) => {
                                                    const totalMatchedWithData =
                                                        massagedData?.filter((eachData: any) => {
                                                            // check if eachData[each.dataKey] is string and if it includes eachFilter.value
                                                            return eachData[each.dataKey]
                                                                .toString()
                                                                .toLowerCase()
                                                                .includes(eachFilter.value.toLowerCase());
                                                        })?.length ?? 0;
                                                    return (
                                                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }} key={index}>
                                                            <MyCheckbox
                                                                type={type}
                                                                label={eachFilter.label}
                                                                value={eachFilter.value}
                                                                total={totalMatchedWithData}
                                                            />
                                                        </Col>
                                                    );
                                                })}
                                            </Row>
                                        </Space>
                                    </div>
                                </Col>
                            );
                        })}
                    </Row>
                </BasicFilter>
            )}
            <Space direction="vertical" style={{ width: "100%", padding: "1rem" }}>
                {isLoading ? (
                    <MessageCard type="info">Loading...</MessageCard>
                ) : rcIssuesLogError ? (
                    <MessageCard type="error">{rcIssuesLogError}</MessageCard>
                ) : (
                    <>
                        {massagedData.length === 0 ? (
                            <MessageCard type="success">All good</MessageCard>
                        ) : (
                            <MessageCard type={"error"}>
                                {filterData.length >= 1 ? (
                                    <>
                                        {filterData.length >= 1 ? massagedDataFiltered?.length : massagedData?.length} out of {massagedData?.length}{" "}
                                    </>
                                ) : (
                                    massagedData?.length
                                )}{" "}
                                {capsToTitleCase(type)} Alarm
                            </MessageCard>
                        )}

                        <Space style={{ width: "100%" }}>
                            <Button danger disabled={selectedRowKeys?.length <= 0} onClick={() => setOpenCheckModal(true)}>
                                Mark as Checked
                            </Button>
                            <Button
                                danger
                                disabled={selectedRowKeys?.length <= 0 || selectedRowHasMissingCheckUser}
                                onClick={() => setOpenDoneModal(true)}
                            >
                                Mark as Done
                            </Button>
                            <Button
                                danger
                                disabled={selectedRowKeys?.length <= 0}
                                onClick={() => setCurrEditCommentRowID(selectedRowKeys)}
                                style={{ marginLeft: "auto" }}
                            >
                                Mass Edit Comments
                            </Button>
                        </Space>
                        <Input
                            placeholder="Search Server, message"
                            onChange={e => {
                                setSearchQuery(e.target.value);
                            }}
                            allowClear
                        />
                        <Table
                            columns={columns}
                            // dataSource={massagedDataFiltered}
                            // @ts-ignore
                            dataSource={result}
                            size="small"
                            rowSelection={rowSelection}
                            pagination={{
                                defaultPageSize: 20,
                                // page size changer
                                // showSizeChanger: true,
                                pageSizeOptions: ["10", "20", "50", "100", "500", "1000"],
                            }}
                            // scroll={{ y: 500 }}
                        />
                        <Modal
                            title={"Mark alarm as checked"}
                            open={openCheckModal}
                            onCancel={() => {
                                setOpenCheckModal(false);
                            }}
                            onOk={() => handleCheck()}
                            okText="Confirm mark as checked"
                            okButtonProps={{ danger: true }}
                        >
                            <p>Are you sure you want to mark selected alarm as checked?</p>
                            <div>
                                {selectedRowKeys.map(each => {
                                    return <Tag key={each}>{each}</Tag>;
                                })}
                            </div>
                        </Modal>
                        <Modal
                            title={"Mark alarm as done"}
                            open={openDoneModal}
                            onCancel={() => {
                                setOpenDoneModal(false);
                            }}
                            onOk={() => handleDone()}
                            okText="Confirm mark as done"
                            okButtonProps={{ danger: true }}
                        >
                            <p>Are you sure you want to mark selected alarm as done?</p>
                            <div>
                                {selectedRowKeys.map(each => {
                                    return <Tag key={each}>{each}</Tag>;
                                })}
                            </div>
                        </Modal>
                        <Modal
                            title={"Edit Comment"}
                            open={currEditCommentRowID?.length >= 1}
                            onCancel={() => {
                                setCurrEditCommentRowID([]);
                                setTextAreaValue("");
                            }}
                            onOk={() => handleUpdateComment()}
                        >
                            <Space direction="vertical" style={{ width: "100%" }} size={16}>
                                <div>
                                    <Text strong>Current editing IDs:</Text>
                                    <div>
                                        {currEditCommentRowID.map(each => {
                                            return <Tag key={each}>{each}</Tag>;
                                        })}
                                    </div>
                                </div>
                                <div>
                                    <Text strong>Comments</Text>
                                    <TextArea
                                        value={textAreaValue}
                                        onChange={e => {
                                            setTextAreaValue(e.target.value);
                                        }}
                                        rows={4}
                                        placeholder="Enter comment here"
                                    />
                                </div>
                            </Space>
                        </Modal>
                    </>
                )}
            </Space>
        </>
    );
};

const MyCheckbox = ({ type, label, value, total }: { type: RCIssueLogType; label: string; value: string; total: number }) => {
    const customLabel = trimAndRemoveAllSpace(label).toLowerCase();
    const filterStore = useRCIssuesLogStore(state => state.filterStore?.[type] ?? {});
    const filterStoreSingleData = useRCIssuesLogStore(state => state.filterStore?.[type]?.[customLabel] ?? true);
    const updateFilterStore = useRCIssuesLogStore(state => state.updateFilterStore);

    return (
        <Checkbox
            // @ts-ignore
            checked={filterStoreSingleData === true}
            onChange={e => {
                // console.log(type, customLabel, filterStore, filterStoreSingleData);
                updateFilterStore({
                    [type]: {
                        ...filterStore,
                        [customLabel]: e.target.checked,
                    },
                });
            }}
        >
            {label} <Badge count={total} overflowCount={99999} />
        </Checkbox>
    );
};
// const MyCheckbox = ({ type, label, value, total }: { type: RCIssueLogType; label: string; value: string; total: number }) => {
//     const customLabel = trimAndRemoveAllSpace(value).toLowerCase();
//     const filterStore = useRCIssuesLogStore(state => state.filterStore?.[type] ?? {});
//     const filterStoreSingleData = filterStore[customLabel] ?? false;
//     const updateFilterStore = useRCIssuesLogStore(state => state.updateFilterStore);

//     return (
//         <Checkbox
//             checked={filterStoreSingleData}
//             onChange={e => {
//                 updateFilterStore({
//                     [type]: {
//                         ...filterStore,
//                         [customLabel]: e.target.checked,
//                     },
//                 });
//             }}
//         >
//             {label} <Badge count={total} overflowCount={99999} />
//         </Checkbox>
//     );
// };

export default CommonIssuesLogTable;
