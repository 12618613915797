import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../../../utils/Common";
import { isEmptyOrNull } from "../../../../utils/string";
import {
    BrandsList,
    CreateEditInlineFormBased,
    CrmBrandList,
    FlexiDataTableCallbackProps,
    FlexiDataTableOptionsProps,
    RegulatorList,
    ToxicClientCheckAccViewRestrictionsCatalogue,
} from "../../../../constants/type";
import SitePageHeaderInline, { SitePageHeaderInlineActionType } from "../../../../components/PageHeader/inlineIndex";
import {
    CALLBACK_KEY,
    ComponentType,
    InnerPageActionMode,
    SUCCESS_FAILED,
    TOXIC_CLIENT_CHECK_ACCOUNT_RESTRICTIONS_CODES,
    TOXIC_CLIENT_CHECK_CLIENT_RESTRICTIONS_CODES,
} from "../../../../constants";
import { Checkbox, Form, Switch, Table, Tooltip } from "antd";
import { APIs, apiRequest } from "../../../../services/apiConfig";
import FlexiDataTable from "../../../../components/FlexiDataTable";
import { FormComponent } from "../../../../components/FormComponent";

interface BatchUpdateRestrictions2Props extends CreateEditInlineFormBased {
    callback: (action: InnerPageActionMode, value?: any) => void;
    resetState: number;
    filterParams?: any;
    servers: any[];
    brands: BrandsList[];
    backActiveSegment?: string;
    crmBrands: CrmBrandList[];
    regulators: RegulatorList[];
}

interface ToxicClientCheckAccViewRestrictionsCatalogueExtra extends ToxicClientCheckAccViewRestrictionsCatalogue {
    accountKey: string;
}

interface IsDefaultColsVals {
    addingCidTool: boolean;
    closeOnly: boolean;
    readOnly: boolean;
    sBook: boolean;
    closeAccount: boolean;
    kyc: boolean;
    leverage: boolean;
    warningLetter: boolean;
    withdrawBan: boolean;
    creditPromotion: boolean;
    openAccount: boolean;
    swap: boolean;
}

const initialDefaultCols: IsDefaultColsVals = {
    addingCidTool: true,
    closeOnly: true,
    readOnly: true,
    sBook: true,
    closeAccount: true,
    kyc: true,
    leverage: true,
    warningLetter: true,
    withdrawBan: true,
    creditPromotion: true,
    openAccount: true,
    swap: true,
};

const BatchUpdateRestrictions = (props: BatchUpdateRestrictions2Props) => {
    const [data, setData] = useState<ToxicClientCheckAccViewRestrictionsCatalogueExtra[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
    const [batchUpdateRestrictionsForm] = Form.useForm();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [accClientInfoData, setAccClientInfoData] = useState<any>({ accounts: {}, clients: {} });
    const [isDefaultCols, setIsDefaultCols] = useState<IsDefaultColsVals>(initialDefaultCols);
    const [fParams, setFParams] = useState<any>(undefined);

    const handleColSwitchChange = useCallback(
        (checked: boolean, colName: string) => {
            let clonedTableDataObj = batchUpdateRestrictionsForm.getFieldValue("table");
            let accountKeys = Object.keys(clonedTableDataObj);

            // UPDATING TABLE DATA OBJ - update all selected rows only
            accountKeys
                .filter((x: string) => selectedRowKeys.includes(x))
                .forEach((x: string) => {
                    clonedTableDataObj[x][colName] = checked;
                });
            batchUpdateRestrictionsForm.setFieldValue("table", clonedTableDataObj);

            // UPDATING CHANGES OBJ to pass to api onsubmit later
            let clonedChangesObj = batchUpdateRestrictionsForm.getFieldValue("changes");
            accountKeys
                .filter((x: string) => selectedRowKeys.includes(x))
                .forEach((currAccKey: string) => {
                    let accKeyValues = currAccKey.split("_"); // [ clientId, accountId, serverId, brandId ]
                    let thisClientId: string = accKeyValues[0];
                    let thisClientAccountIds = Object.keys(clonedTableDataObj).filter((currKey: string) => currKey.indexOf(thisClientId) === 0);
                    const oriDataRowObj = data.find((item: ToxicClientCheckAccViewRestrictionsCatalogueExtra) => item.accountKey === currAccKey);

                    if (isEmptyOrNull(oriDataRowObj)) return false;
                    else {
                        const oriValue = oriDataRowObj && oriDataRowObj[colName as keyof ToxicClientCheckAccViewRestrictionsCatalogueExtra];
                        if (oriValue !== checked) {
                            // if value is different from original
                            if (clonedChangesObj?.hasOwnProperty(colName)) {
                                // handling CLIENT restrictions
                                if (colName !== "addingCidTool" && colName !== "closeOnly" && colName !== "readOnly" && colName !== "sBook") {
                                    let accountKeyOfTheSameClientInThisCurrCol =
                                        Object.keys(clonedChangesObj[colName]).find((x: string) => x.indexOf(thisClientId) === 0) || "";
                                    if (isEmptyOrNull(accountKeyOfTheSameClientInThisCurrCol)) {
                                        clonedChangesObj[colName][currAccKey] = {
                                            restrictionName: colName,
                                            restrictionValue: checked,
                                            clientKey: `${oriDataRowObj?.clientId}_${oriDataRowObj?.crmServerId}_${oriDataRowObj?.crmBrandId}`,
                                            accounts: thisClientAccountIds,
                                        };
                                    } else return false;
                                } else {
                                    // handling ACCOUNT restrictions
                                    clonedChangesObj[colName][currAccKey] = {
                                        restrictionName: colName,
                                        restrictionValue: checked,
                                        clientKey: `${oriDataRowObj?.clientId}_${oriDataRowObj?.crmServerId}_${oriDataRowObj?.crmBrandId}`,
                                    };
                                }
                            } else {
                                // handling empty colName obj in changes obj
                                clonedChangesObj[colName] = {
                                    [currAccKey]: {
                                        restrictionName: colName,
                                        restrictionValue: checked,
                                        clientKey: `${oriDataRowObj?.clientId}_${oriDataRowObj?.crmServerId}_${oriDataRowObj?.crmBrandId}`,
                                    },
                                };

                                // handling CLIENT restrictions - update to include list of all accounts under the same client
                                if (colName !== "addingCidTool" && colName !== "closeOnly" && colName !== "readOnly" && colName !== "sBook") {
                                    clonedChangesObj[colName][currAccKey] = {
                                        ...clonedChangesObj[colName][currAccKey],
                                        accounts: thisClientAccountIds,
                                    };
                                }
                            }
                        } else {
                            // handling same value as original - need to remove from changes obj if found
                            if (clonedChangesObj?.hasOwnProperty(colName)) {
                                if (clonedChangesObj[colName]?.hasOwnProperty(currAccKey)) {
                                    delete clonedChangesObj[colName][currAccKey];
                                }
                            }
                        }
                    }
                });
            batchUpdateRestrictionsForm.setFieldValue("changes", clonedChangesObj);
        },
        [data, batchUpdateRestrictionsForm, selectedRowKeys]
    );

    const handleBackToDefault = (checked: boolean, colName: string) => {
        setIsDefaultCols((prev: any) => ({
            ...prev,
            [colName]: checked,
        }));

        let clonedChangesObj = batchUpdateRestrictionsForm.getFieldValue("changes");
        let clonedTableDataObj = batchUpdateRestrictionsForm.getFieldValue("table");

        if (checked) {
            // 1) if true, remove from changes obj
            if (clonedChangesObj?.hasOwnProperty(colName)) {
                delete clonedChangesObj[colName];
            }
            batchUpdateRestrictionsForm.setFieldValue("changes", clonedChangesObj);

            // 2) reset the whole col data to initial data
            Object.keys(clonedTableDataObj).forEach((currAccKey: string) => {
                const oriDataRowObj = data.find((item: ToxicClientCheckAccViewRestrictionsCatalogueExtra) => item.accountKey === currAccKey);
                if (isEmptyOrNull(oriDataRowObj)) return false;
                else {
                    const oriValue = oriDataRowObj && oriDataRowObj[colName as keyof ToxicClientCheckAccViewRestrictionsCatalogueExtra];
                    clonedTableDataObj[currAccKey][colName] = oriValue;
                }
            });
            batchUpdateRestrictionsForm.setFieldValue("table", clonedTableDataObj);
        }
    };

    const columns = useMemo(
        () => [
            {
                title: "Account Info",
                key: "accountInfo",
                className: "",
                children: [
                    DTColProps.Middle({
                        title: "Account ID, Server, Brand",
                        dataIndex: "accountId",
                        key: "accountId",
                        fixed: "left",
                        width: "10vw",
                        render: (accountId: number, rowData: ToxicClientCheckAccViewRestrictionsCatalogueExtra) => {
                            let thisServer = props.servers.find((x: any) => x.value === rowData.serverId);
                            let thisBrand = props.brands.find((x: BrandsList) => x.id === rowData.brandId);
                            return (
                                <div className="account-client-info-col">
                                    <FormComponent label="" name={"changes"} extra={{ type: ComponentType.hidden, value: "" }} />
                                    <span className="account-client-id">{accountId}</span>, {isEmptyOrNull(thisServer) ? "" : thisServer.text},{" "}
                                    {isEmptyOrNull(thisBrand) ? "" : thisBrand?.brand}
                                </div>
                            );
                        },
                    }),
                ],
            },
            {
                title: "Client Info",
                key: "clientInfo",
                className: "",
                children: [
                    DTColProps.Middle({
                        title: "Client ID, CRM Brand, Regulator",
                        dataIndex: "clientId",
                        key: "clientId",
                        fixed: "left",
                        width: "10vw",
                        render: (clientId: number, rowData: ToxicClientCheckAccViewRestrictionsCatalogueExtra) => {
                            let thisCrmBrand = props.crmBrands.find((x: CrmBrandList) => x.id === rowData.crmBrandId);
                            let thisRegulator = props.regulators.find((x: RegulatorList) => x.id === rowData.regulatorId);
                            return (
                                <div className="account-client-info-col">
                                    <span className="account-client-id">{clientId}</span>, {isEmptyOrNull(thisCrmBrand) ? "" : thisCrmBrand?.brand},{" "}
                                    {isEmptyOrNull(thisRegulator) ? "" : thisRegulator?.name}
                                </div>
                            );
                        },
                    }),
                ],
            },
            {
                title: "Account Restrictions",
                key: "accountRestrictions",
                className: "",
                children: [
                    DTColProps.XSmall({
                        title: (
                            <div className="header-title-container">
                                <div className="header-title-text">Adding CID Tool</div>
                                <div style={{ display: "flex", justifyContent: "space-around", marginTop: 5 }}>
                                    <Tooltip title="Apply to all accounts">
                                        <Switch
                                            onChange={(checked: boolean) => handleColSwitchChange(checked, "addingCidTool")}
                                            checkedChildren="Yes"
                                            unCheckedChildren="No"
                                            disabled={isDefaultCols.addingCidTool}
                                        />
                                    </Tooltip>
                                    <Tooltip title={`${isDefaultCols.addingCidTool ? "Default" : "Click to reset to default"}`}>
                                        <Checkbox
                                            checked={isDefaultCols.addingCidTool}
                                            onChange={(e: any) => handleBackToDefault(e.target.checked, "addingCidTool")}
                                        />
                                    </Tooltip>
                                </div>
                            </div>
                        ),
                        dataIndex: "addingCidTool",
                        key: "addingCidTool",
                        width: "5vw",
                        render: (_: boolean, rowData: ToxicClientCheckAccViewRestrictionsCatalogueExtra) => {
                            let aidkeyIdx: number = selectedRowKeys.findIndex((x: string) => x === rowData.accountKey);
                            return (
                                <FormComponent
                                    label=""
                                    name={["table", rowData.accountKey, "addingCidTool"]}
                                    extra={{
                                        type: ComponentType.switch,
                                        value: ["No", "Yes"],
                                        inputProps: {
                                            disabled: aidkeyIdx > -1 ? false : true,
                                            onChange: (checked: boolean) => handleSwitchChange(checked, rowData.accountKey, "addingCidTool", rowData),
                                        },
                                    }}
                                />
                            );
                        },
                        // className: "editted-row",
                    }),
                    DTColProps.XSmall({
                        title: (
                            <div className="header-title-container">
                                <div className="header-title-text">Close Only</div>
                                <div style={{ display: "flex", justifyContent: "space-around", marginTop: 5 }}>
                                    <Tooltip title="Apply to all accounts">
                                        <Switch
                                            onChange={(checked: boolean) => handleColSwitchChange(checked, "closeOnly")}
                                            checkedChildren="Yes"
                                            unCheckedChildren="No"
                                            disabled={isDefaultCols.closeOnly}
                                        />
                                    </Tooltip>
                                    <Tooltip title={`${isDefaultCols.closeOnly ? "Default" : "Click to reset to default"}`}>
                                        <Checkbox
                                            checked={isDefaultCols.closeOnly}
                                            onChange={(e: any) => handleBackToDefault(e.target.checked, "closeOnly")}
                                        />
                                    </Tooltip>
                                </div>
                            </div>
                        ),
                        dataIndex: "closeOnly",
                        key: "closeOnly",
                        width: "5vw",
                        render: (_: boolean, rowData: ToxicClientCheckAccViewRestrictionsCatalogueExtra) => {
                            let aidkeyIdx: number = selectedRowKeys.findIndex((x: string) => x === rowData.accountKey);
                            return (
                                <FormComponent
                                    label=""
                                    name={["table", rowData.accountKey, "closeOnly"]}
                                    extra={{
                                        type: ComponentType.switch,
                                        value: ["No", "Yes"],
                                        inputProps: {
                                            disabled: aidkeyIdx > -1 ? false : true,
                                            onChange: (checked: boolean) => handleSwitchChange(checked, rowData.accountKey, "closeOnly", rowData),
                                        },
                                    }}
                                />
                            );
                        },
                    }),
                    DTColProps.XSmall({
                        title: (
                            <div className="header-title-container">
                                <div className="header-title-text">Read Only</div>
                                <div style={{ display: "flex", justifyContent: "space-around", marginTop: 5 }}>
                                    <Tooltip title="Apply to all accounts">
                                        <Switch
                                            onChange={(checked: boolean) => handleColSwitchChange(checked, "readOnly")}
                                            checkedChildren="Yes"
                                            unCheckedChildren="No"
                                            disabled={isDefaultCols.readOnly}
                                        />
                                    </Tooltip>
                                    <Tooltip title={`${isDefaultCols.readOnly ? "Default" : "Click to reset to default"}`}>
                                        <Checkbox
                                            checked={isDefaultCols.readOnly}
                                            onChange={(e: any) => handleBackToDefault(e.target.checked, "readOnly")}
                                        />
                                    </Tooltip>
                                </div>
                            </div>
                        ),
                        dataIndex: "readOnly",
                        key: "readOnly",
                        width: "5vw",
                        render: (_: boolean, rowData: ToxicClientCheckAccViewRestrictionsCatalogueExtra) => {
                            let aidkeyIdx: number = selectedRowKeys.findIndex((x: string) => x === rowData.accountKey);
                            return (
                                <FormComponent
                                    label=""
                                    name={["table", rowData.accountKey, "readOnly"]}
                                    extra={{
                                        type: ComponentType.switch,
                                        value: ["No", "Yes"],
                                        inputProps: {
                                            disabled: aidkeyIdx > -1 ? false : true,
                                            onChange: (checked: boolean) => handleSwitchChange(checked, rowData.accountKey, "readOnly", rowData),
                                        },
                                    }}
                                />
                            );
                        },
                    }),
                    DTColProps.XSmall({
                        title: (
                            <div className="header-title-container">
                                <div className="header-title-text">S Book</div>
                                <div style={{ display: "flex", justifyContent: "space-around", marginTop: 5 }}>
                                    <Tooltip title="Apply to all accounts">
                                        <Switch
                                            onChange={(checked: boolean) => handleColSwitchChange(checked, "sBook")}
                                            checkedChildren="Yes"
                                            unCheckedChildren="No"
                                            disabled={isDefaultCols.sBook}
                                        />
                                    </Tooltip>
                                    <Tooltip title={`${isDefaultCols.sBook ? "Default" : "Click to reset to default"}`}>
                                        <Checkbox
                                            checked={isDefaultCols.sBook}
                                            onChange={(e: any) => handleBackToDefault(e.target.checked, "sBook")}
                                        />
                                    </Tooltip>
                                </div>
                            </div>
                        ),
                        dataIndex: "sBook",
                        key: "sBook",
                        render: (_: string, rowData: ToxicClientCheckAccViewRestrictionsCatalogueExtra) => {
                            let aidkeyIdx: number = selectedRowKeys.findIndex((x: string) => x === rowData.accountKey);
                            return (
                                <FormComponent
                                    label=""
                                    name={["table", rowData.accountKey, "sBook"]}
                                    extra={{
                                        type: ComponentType.switch,
                                        value: ["No", "Yes"],
                                        inputProps: {
                                            disabled: aidkeyIdx > -1 ? false : true,
                                            onChange: (checked: boolean) => handleSwitchChange(checked, rowData.accountKey, "sBook", rowData),
                                        },
                                    }}
                                />
                            );
                        },
                    }),
                    DTColProps.Middle({
                        title: "Group Change",
                        dataIndex: "groupChange",
                        key: "groupChange",
                        render: (_: string, rowData: ToxicClientCheckAccViewRestrictionsCatalogueExtra) => {
                            let aidkeyIdx: number = selectedRowKeys.findIndex((x: string) => x === rowData.accountKey);
                            return (
                                <FormComponent
                                    label=""
                                    name={["table", rowData.accountKey, "groupChange"]}
                                    extra={{
                                        type: ComponentType.textarea,
                                        value: "",
                                        inputProps: {
                                            autoSize: { minRows: 1, maxRows: 3 },
                                            disabled: aidkeyIdx > -1 ? false : true,
                                            onBlur: (e: any) => handleBlur(e, rowData.accountKey, "groupChange", rowData),
                                        },
                                    }}
                                />
                            );
                        },
                    }),
                ],
            },
            {
                title: "Client Restrictions",
                key: "clientRestrictions",
                className: "",
                children: [
                    DTColProps.XSmall({
                        title: (
                            <div className="header-title-container">
                                <div className="header-title-text">Close Account</div>
                                <div style={{ display: "flex", justifyContent: "space-around", marginTop: 5 }}>
                                    <Tooltip title="Apply to all accounts">
                                        <Switch
                                            onChange={(checked: boolean) => handleColSwitchChange(checked, "closeAccount")}
                                            checkedChildren="Yes"
                                            unCheckedChildren="No"
                                            disabled={isDefaultCols.closeAccount}
                                        />
                                    </Tooltip>
                                    <Tooltip title={`${isDefaultCols.closeAccount ? "Default" : "Click to reset to default"}`}>
                                        <Checkbox
                                            checked={isDefaultCols.closeAccount}
                                            onChange={(e: any) => handleBackToDefault(e.target.checked, "closeAccount")}
                                        />
                                    </Tooltip>
                                </div>
                            </div>
                        ),
                        dataIndex: "closeAccount",
                        key: "closeAccount",
                        width: "5vw",
                        render: (_: boolean, rowData: ToxicClientCheckAccViewRestrictionsCatalogueExtra) => {
                            let aidkeyIdx: number = selectedRowKeys.findIndex((x: string) => x === rowData.accountKey);
                            return (
                                <FormComponent
                                    label=""
                                    name={["table", rowData.accountKey, "closeAccount"]}
                                    extra={{
                                        type: ComponentType.switch,
                                        value: ["No", "Yes"],
                                        inputProps: {
                                            disabled: aidkeyIdx > -1 ? false : true,
                                            onChange: (checked: boolean) => handleSwitchChange(checked, rowData.accountKey, "closeAccount", rowData),
                                        },
                                    }}
                                />
                            );
                        },
                    }),
                    DTColProps.XSmall({
                        title: (
                            <div className="header-title-container">
                                <div className="header-title-text">KYC</div>
                                <div style={{ display: "flex", justifyContent: "space-around", marginTop: 5 }}>
                                    <Tooltip title="Apply to all accounts">
                                        <Switch
                                            onChange={(checked: boolean) => handleColSwitchChange(checked, "kyc")}
                                            checkedChildren="Yes"
                                            unCheckedChildren="No"
                                            disabled={isDefaultCols.kyc}
                                        />
                                    </Tooltip>
                                    <Tooltip title={`${isDefaultCols.kyc ? "Default" : "Click to reset to default"}`}>
                                        <Checkbox checked={isDefaultCols.kyc} onChange={(e: any) => handleBackToDefault(e.target.checked, "kyc")} />
                                    </Tooltip>
                                </div>
                            </div>
                        ),
                        dataIndex: "kyc",
                        key: "kyc",
                        width: "5vw",
                        render: (_: boolean, rowData: ToxicClientCheckAccViewRestrictionsCatalogueExtra) => {
                            let aidkeyIdx: number = selectedRowKeys.findIndex((x: string) => x === rowData.accountKey);
                            return (
                                <FormComponent
                                    label=""
                                    name={["table", rowData.accountKey, "kyc"]}
                                    extra={{
                                        type: ComponentType.switch,
                                        value: ["No", "Yes"],
                                        inputProps: {
                                            disabled: aidkeyIdx > -1 ? false : true,
                                            onChange: (checked: boolean) => handleSwitchChange(checked, rowData.accountKey, "kyc", rowData),
                                        },
                                    }}
                                />
                            );
                        },
                    }),
                    DTColProps.XSmall({
                        title: (
                            <div className="header-title-container">
                                <div className="header-title-text">Leverage</div>
                                <div style={{ display: "flex", justifyContent: "space-around", marginTop: 5 }}>
                                    <Tooltip title="Apply to all accounts">
                                        <Switch
                                            onChange={(checked: boolean) => handleColSwitchChange(checked, "leverage")}
                                            checkedChildren="Yes"
                                            unCheckedChildren="No"
                                            disabled={isDefaultCols.leverage}
                                        />
                                    </Tooltip>
                                    <Tooltip title={`${isDefaultCols.leverage ? "Default" : "Click to reset to default"}`}>
                                        <Checkbox
                                            checked={isDefaultCols.leverage}
                                            onChange={(e: any) => handleBackToDefault(e.target.checked, "leverage")}
                                        />
                                    </Tooltip>
                                </div>
                            </div>
                        ),
                        dataIndex: "leverage",
                        key: "leverage",
                        width: "5vw",
                        render: (_: boolean, rowData: ToxicClientCheckAccViewRestrictionsCatalogueExtra) => {
                            let aidkeyIdx: number = selectedRowKeys.findIndex((x: string) => x === rowData.accountKey);
                            return (
                                <FormComponent
                                    label=""
                                    name={["table", rowData.accountKey, "leverage"]}
                                    extra={{
                                        type: ComponentType.switch,
                                        value: ["No", "Yes"],
                                        inputProps: {
                                            disabled: aidkeyIdx > -1 ? false : true,
                                            onChange: (checked: boolean) => handleSwitchChange(checked, rowData.accountKey, "leverage", rowData),
                                        },
                                    }}
                                />
                            );
                        },
                    }),
                    DTColProps.XSmall({
                        title: (
                            <div className="header-title-container">
                                <div className="header-title-text">Warning Letter</div>
                                <div style={{ display: "flex", justifyContent: "space-around", marginTop: 5 }}>
                                    <Tooltip title="Apply to all accounts">
                                        <Switch
                                            onChange={(checked: boolean) => handleColSwitchChange(checked, "warningLetter")}
                                            checkedChildren="Yes"
                                            unCheckedChildren="No"
                                            disabled={isDefaultCols.warningLetter}
                                        />
                                    </Tooltip>
                                    <Tooltip title={`${isDefaultCols.warningLetter ? "Default" : "Click to reset to default"}`}>
                                        <Checkbox
                                            checked={isDefaultCols.warningLetter}
                                            onChange={(e: any) => handleBackToDefault(e.target.checked, "warningLetter")}
                                        />
                                    </Tooltip>
                                </div>
                            </div>
                        ),
                        dataIndex: "warningLetter",
                        key: "warningLetter",
                        width: "5vw",
                        render: (_: boolean, rowData: ToxicClientCheckAccViewRestrictionsCatalogueExtra) => {
                            let aidkeyIdx: number = selectedRowKeys.findIndex((x: string) => x === rowData.accountKey);
                            return (
                                <FormComponent
                                    label=""
                                    name={["table", rowData.accountKey, "warningLetter"]}
                                    extra={{
                                        type: ComponentType.switch,
                                        value: ["No", "Yes"],
                                        inputProps: {
                                            disabled: aidkeyIdx > -1 ? false : true,
                                            onChange: (checked: boolean) => handleSwitchChange(checked, rowData.accountKey, "warningLetter", rowData),
                                        },
                                    }}
                                />
                            );
                        },
                    }),
                    DTColProps.XSmall({
                        title: (
                            <div className="header-title-container">
                                <div className="header-title-text">Withdraw Ban</div>
                                <div style={{ display: "flex", justifyContent: "space-around", marginTop: 5 }}>
                                    <Tooltip title="Apply to all accounts">
                                        <Switch
                                            onChange={(checked: boolean) => handleColSwitchChange(checked, "withdrawBan")}
                                            checkedChildren="Yes"
                                            unCheckedChildren="No"
                                            disabled={isDefaultCols.withdrawBan}
                                        />
                                    </Tooltip>
                                    <Tooltip title={`${isDefaultCols.withdrawBan ? "Default" : "Click to reset to default"}`}>
                                        <Checkbox
                                            checked={isDefaultCols.withdrawBan}
                                            onChange={(e: any) => handleBackToDefault(e.target.checked, "withdrawBan")}
                                        />
                                    </Tooltip>
                                </div>
                            </div>
                        ),
                        dataIndex: "withdrawBan",
                        key: "withdrawBan",
                        width: "5vw",
                        render: (_: boolean, rowData: ToxicClientCheckAccViewRestrictionsCatalogueExtra) => {
                            let aidkeyIdx: number = selectedRowKeys.findIndex((x: string) => x === rowData.accountKey);
                            return (
                                <FormComponent
                                    label=""
                                    name={["table", rowData.accountKey, "withdrawBan"]}
                                    extra={{
                                        type: ComponentType.switch,
                                        value: ["No", "Yes"],
                                        inputProps: {
                                            disabled: aidkeyIdx > -1 ? false : true,
                                            onChange: (checked: boolean) => handleSwitchChange(checked, rowData.accountKey, "withdrawBan", rowData),
                                        },
                                    }}
                                />
                            );
                        },
                    }),
                    {
                        title: "Blacklist",
                        key: "blacklist",
                        children: [
                            DTColProps.XSmall({
                                title: (
                                    <div className="header-title-container">
                                        <div className="header-title-text">Credit & Promotion</div>
                                        <div style={{ display: "flex", justifyContent: "space-around", marginTop: 5 }}>
                                            <Tooltip title="Apply to all accounts">
                                                <Switch
                                                    onChange={(checked: boolean) => handleColSwitchChange(checked, "creditPromotion")}
                                                    checkedChildren="Yes"
                                                    unCheckedChildren="No"
                                                    disabled={isDefaultCols.creditPromotion}
                                                />
                                            </Tooltip>
                                            <Tooltip title={`${isDefaultCols.creditPromotion ? "Default" : "Click to reset to default"}`}>
                                                <Checkbox
                                                    checked={isDefaultCols.creditPromotion}
                                                    onChange={(e: any) => handleBackToDefault(e.target.checked, "creditPromotion")}
                                                />
                                            </Tooltip>
                                        </div>
                                    </div>
                                ),
                                dataIndex: "creditPromotion",
                                key: "creditPromotion",
                                width: "5vw",
                                render: (_: boolean, rowData: ToxicClientCheckAccViewRestrictionsCatalogueExtra) => {
                                    let aidkeyIdx: number = selectedRowKeys.findIndex((x: string) => x === rowData.accountKey);
                                    return (
                                        <FormComponent
                                            label=""
                                            name={["table", rowData.accountKey, "creditPromotion"]}
                                            extra={{
                                                type: ComponentType.switch,
                                                value: ["No", "Yes"],
                                                inputProps: {
                                                    disabled: aidkeyIdx > -1 ? false : true,
                                                    onChange: (checked: boolean) =>
                                                        handleSwitchChange(checked, rowData.accountKey, "creditPromotion", rowData),
                                                },
                                            }}
                                        />
                                    );
                                },
                            }),
                            DTColProps.XSmall({
                                title: (
                                    <div className="header-title-container">
                                        <div className="header-title-text">Open Account</div>
                                        <div style={{ display: "flex", justifyContent: "space-around", marginTop: 5 }}>
                                            <Tooltip title="Apply to all accounts">
                                                <Switch
                                                    onChange={(checked: boolean) => handleColSwitchChange(checked, "openAccount")}
                                                    checkedChildren="Yes"
                                                    unCheckedChildren="No"
                                                    disabled={isDefaultCols.openAccount}
                                                />
                                            </Tooltip>
                                            <Tooltip title={`${isDefaultCols.openAccount ? "Default" : "Click to reset to default"}`}>
                                                <Checkbox
                                                    checked={isDefaultCols.openAccount}
                                                    onChange={(e: any) => handleBackToDefault(e.target.checked, "openAccount")}
                                                />
                                            </Tooltip>
                                        </div>
                                    </div>
                                ),
                                dataIndex: "openAccount",
                                key: "openAccount",
                                width: "5vw",
                                render: (_: boolean, rowData: ToxicClientCheckAccViewRestrictionsCatalogueExtra) => {
                                    let aidkeyIdx: number = selectedRowKeys.findIndex((x: string) => x === rowData.accountKey);
                                    return (
                                        <FormComponent
                                            label=""
                                            name={["table", rowData.accountKey, "openAccount"]}
                                            extra={{
                                                type: ComponentType.switch,
                                                value: ["No", "Yes"],
                                                inputProps: {
                                                    disabled: aidkeyIdx > -1 ? false : true,
                                                    onChange: (checked: boolean) =>
                                                        handleSwitchChange(checked, rowData.accountKey, "openAccount", rowData),
                                                },
                                            }}
                                        />
                                    );
                                },
                            }),
                            DTColProps.XSmall({
                                title: (
                                    <div className="header-title-container">
                                        <div className="header-title-text">Swap</div>
                                        <div style={{ display: "flex", justifyContent: "space-around", marginTop: 5 }}>
                                            <Tooltip title="Apply to all accounts">
                                                <Switch
                                                    onChange={(checked: boolean) => handleColSwitchChange(checked, "swap")}
                                                    checkedChildren="Yes"
                                                    unCheckedChildren="No"
                                                    disabled={isDefaultCols.swap}
                                                />
                                            </Tooltip>
                                            <Tooltip title={`${isDefaultCols.swap ? "Default" : "Click to reset to default"}`}>
                                                <Checkbox
                                                    checked={isDefaultCols.swap}
                                                    onChange={(e: any) => handleBackToDefault(e.target.checked, "swap")}
                                                />
                                            </Tooltip>
                                        </div>
                                    </div>
                                ),
                                dataIndex: "swap",
                                key: "swap",
                                width: "5vw",
                                render: (_: boolean, rowData: ToxicClientCheckAccViewRestrictionsCatalogueExtra) => {
                                    let aidkeyIdx: number = selectedRowKeys.findIndex((x: string) => x === rowData.accountKey);
                                    return (
                                        <FormComponent
                                            label=""
                                            name={["table", rowData.accountKey, "swap"]}
                                            extra={{
                                                type: ComponentType.switch,
                                                value: ["No", "Yes"],
                                                inputProps: {
                                                    disabled: aidkeyIdx > -1 ? false : true,
                                                    onChange: (checked: boolean) => handleSwitchChange(checked, rowData.accountKey, "swap", rowData),
                                                },
                                            }}
                                        />
                                    );
                                },
                            }),
                        ],
                    },
                ],
            },
        ],
        [
            props.brands,
            props.servers,
            selectedRowKeys,
            batchUpdateRestrictionsForm,
            props.crmBrands,
            props.regulators,
            isDefaultCols,
            handleColSwitchChange,
            handleBackToDefault,
        ]
    );

    const options: FlexiDataTableOptionsProps = {
        serverFiltering: false,
        enableFilter: false,
        enableRowSelection: true,
        showHideColumns: false,
        hideRowSelectionsSummary: true,
        rowSelectionData: {
            rowSelectionType: "checkbox",
            selectedRowKeys: selectedRowKeys,
            options: {
                fixed: "left",
                selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
            },
        },
    };

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.ROW_SELECTION_CALLBACK:
                setSelectedRowKeys(FormData.selectedRowKeys);
                break;
            case CALLBACK_KEY.ON_SELECT_TABLE_ROW_CALLBACK:
                let clonedChangesObj = batchUpdateRestrictionsForm.getFieldValue("changes");
                let clonedTableDataObj = batchUpdateRestrictionsForm.getFieldValue("table");
                let keyValues: any = FormData.selectedRecord.accountKey.split("_"); // [clientId, accountId, serverId, brandId]
                let thisClientId: string = keyValues[0];
                let newClonedTableData = clonedTableDataObj;

                Object.keys(clonedChangesObj).forEach((currColName: string) => {
                    if (currColName === "addingCidTool" || currColName === "closeOnly" || currColName === "readOnly" || currColName === "sBook")
                        return false;
                    else {
                        // updating SELECTED account's CLIENT RESTRICTIONS if have any
                        let accountKeyOfTheSameClientInThisCurrCol =
                            Object.keys(clonedChangesObj[currColName]).find((x: string) => x.indexOf(thisClientId) === 0) || "";
                        if (isEmptyOrNull(accountKeyOfTheSameClientInThisCurrCol)) {
                            // if colName is found in changes obj but it is empty, then update it to ori value
                            const oriDataRowObj = data.find(
                                (item: ToxicClientCheckAccViewRestrictionsCatalogueExtra) => item.accountKey === FormData.selectedRecord.accountKey
                            );
                            if (isEmptyOrNull(oriDataRowObj)) return false;
                            else {
                                const oriValue =
                                    oriDataRowObj && oriDataRowObj[currColName as keyof ToxicClientCheckAccViewRestrictionsCatalogueExtra];
                                newClonedTableData[FormData.selectedRecord.accountKey][currColName] = oriValue;
                            }
                        } else {
                            // if other account of the same client has this restriction, then update the selected account with the same value
                            newClonedTableData[FormData.selectedRecord.accountKey][currColName] =
                                clonedTableDataObj[accountKeyOfTheSameClientInThisCurrCol][currColName];
                        }
                    }
                    batchUpdateRestrictionsForm.setFieldValue("table", newClonedTableData);
                });
                break;
            default:
                break;
        }
    };

    const onSubmit = useCallback(
        (values: any) => {
            let changes = values.changes;
            let accountsByKeys: any = {},
                clientByKeys: any = {};
            Object.keys(changes).forEach((currColName: string) => {
                if (
                    currColName === "addingCidTool" ||
                    currColName === "closeOnly" ||
                    currColName === "readOnly" ||
                    currColName === "groupChange" ||
                    currColName === "sBook"
                ) {
                    for (const accKey in changes[currColName]) {
                        if (!accountsByKeys[accKey]) {
                            accountsByKeys[accKey] = [];
                        }
                        accountsByKeys[accKey].push(changes[currColName][accKey]);
                    }
                } else {
                    for (const accKey in changes[currColName]) {
                        let accKeyValues = accKey.split("_"); // [ clientId, accountId, serverId, brandId ]
                        if (!clientByKeys[accKeyValues[0]]) {
                            clientByKeys[accKeyValues[0]] = [];
                        }
                        if (accKey.includes(accKeyValues[0])) clientByKeys[accKeyValues[0]].push(changes[currColName][accKey]);
                    }
                }
            });

            const removeDuplicates = (obj: any) => {
                // Helper function to remove duplicates from an array of objects
                const removeArrayDuplicates = (arr: any) => {
                    return arr.reduce((unique: any, item: any) => {
                        if (unique.findIndex((u: any) => JSON.stringify(u) === JSON.stringify(item)) === -1) {
                            unique.push(item);
                        }
                        return unique;
                    }, []);
                };

                // Transform the object
                const newObj: any = {};
                for (const [key, value] of Object.entries(obj)) {
                    newObj[key] = removeArrayDuplicates(value);
                }
                return newObj;
            };
            const newClientByCids: any = removeDuplicates(clientByKeys);

            let accountRestrictions = Object.keys(accountsByKeys)
                .filter((x: string) => selectedRowKeys.includes(x))
                .map((currAccKey: string) => {
                    const accInfo = currAccKey.split("_"); // [ clientId, accountId, serverId, brandId ]
                    const currClientKey = accountsByKeys[currAccKey][0].clientKey;
                    let restrictionsWithValues: any[] = accountsByKeys[currAccKey].map((currColData: any) => {
                        return {
                            restrictionCode: TOXIC_CLIENT_CHECK_ACCOUNT_RESTRICTIONS_CODES[currColData.restrictionName],
                            restrictionValue: currColData.restrictionValue,
                        };
                    });
                    return {
                        accountId: parseInt(accInfo[1]),
                        serverId: parseInt(accInfo[2]),
                        brandId: parseInt(accInfo[3]),
                        ...accClientInfoData["clients"][currClientKey],
                        restrictionsWithValues,
                    };
                });

            let clientRestrictions = Object.keys(newClientByCids)
                .map((currClientId: string) => {
                    const currClientKey = newClientByCids[currClientId][0].clientKey;
                    let thisSelectedClientAccountIds = selectedRowKeys.filter((currKey: string) => currKey.indexOf(currClientId) === 0);
                    let restrictionsWithValues: any[] = newClientByCids[currClientId]
                        .map((currColData: any) => {
                            return {
                                restrictionCode: TOXIC_CLIENT_CHECK_CLIENT_RESTRICTIONS_CODES[currColData.restrictionName],
                                restrictionValue: currColData.restrictionValue,
                                triggeredByAccounts: thisSelectedClientAccountIds.map((x: string) => {
                                    const currAccInfo = x.split("_");
                                    return {
                                        accountId: parseInt(currAccInfo[1]),
                                        serverId: parseInt(currAccInfo[2]),
                                        brandId: parseInt(currAccInfo[3]),
                                    };
                                }),
                            };
                        })
                        .filter((x: any) => x.triggeredByAccounts.length > 0);
                    return {
                        ...accClientInfoData["clients"][currClientKey],
                        restrictionsWithValues,
                    };
                })
                .filter((y: any) => y.restrictionsWithValues.length > 0);

            setIsLoading(true);
            apiRequest(APIs.BATCH_UPDATE_TOXIC_CLIENT_CHECK_RESTRICTIONS, {
                accountRestrictions,
                clientRestrictions,
            })
                .then((data: any) => {
                    ErrorMessageHandler("Toxic client batch restrictions", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                    setTimeout(() => {
                        batchUpdateRestrictionsForm.resetFields();
                        props.callback &&
                            props.callback(InnerPageActionMode.BACK, {
                                refreshMainList: true,
                                reloadSegment: props.backActiveSegment,
                                fParams: fParams,
                            });
                    }, 400);
                })
                .catch((error: any) =>
                    ErrorCatchValidator(error, (err: any) =>
                        ErrorMessageHandler("toxic client batch restrictions", SUCCESS_FAILED.FAILED_UPDATE_DATA, err)
                    )
                )
                .finally(() => setIsLoading(false));
        },
        [selectedRowKeys, accClientInfoData]
    );

    const handleBlur = (e: any, key: string, columnName: string, rowData: ToxicClientCheckAccViewRestrictionsCatalogueExtra) => {
        let clonedChangesObj: any = batchUpdateRestrictionsForm.getFieldValue("changes");
        if (clonedChangesObj?.hasOwnProperty(columnName)) {
            if (clonedChangesObj[columnName]?.hasOwnProperty(key)) {
                clonedChangesObj[columnName][key] = {
                    ...clonedChangesObj[columnName][key],
                    [columnName]: e.target.value,
                };
            } else {
                clonedChangesObj[columnName][key] = {
                    restrictionName: columnName,
                    restrictionValue: e.target.value,
                    clientKey: `${rowData?.clientId}_${rowData?.crmServerId}_${rowData?.crmBrandId}`,
                };
            }
            batchUpdateRestrictionsForm.setFieldValue("changes", clonedChangesObj);
        } else {
            let clonedEmptyColChangesObj = clonedChangesObj;
            if (e.target.value.length > 0) {
                let innerValuesObj: any = {
                    restrictionName: columnName,
                    restrictionValue: e.target.value,
                    clientKey: `${rowData?.clientId}_${rowData?.crmServerId}_${rowData?.crmBrandId}`,
                };
                clonedEmptyColChangesObj[columnName] = {
                    [key]: innerValuesObj,
                };
                batchUpdateRestrictionsForm.setFieldValue("changes", clonedEmptyColChangesObj);
            }
        }
    };

    const handleSwitchChange = (checked: boolean, key: string, columnName: string, rowData?: ToxicClientCheckAccViewRestrictionsCatalogueExtra) => {
        let clonedChangesObj: any = batchUpdateRestrictionsForm.getFieldValue("changes");
        let clonedTableDataObj: any = batchUpdateRestrictionsForm.getFieldValue("table");
        let keyValues: any = key.split("_"); // [clientId, accountId, serverId, brandId]
        let thisClientId: string = keyValues[0];
        let thisClientAccountIds = Object.keys(clonedTableDataObj).filter((currKey: string) => currKey.indexOf(thisClientId) === 0);

        setIsDefaultCols((prev: any) => ({
            ...prev,
            [columnName]: false,
        }));

        if (clonedChangesObj?.hasOwnProperty(columnName)) {
            if (clonedChangesObj[columnName]?.hasOwnProperty(key)) {
                delete clonedChangesObj[columnName][key];
            } else {
                // handling CLIENT restrictions
                if (columnName !== "addingCidTool" && columnName !== "closeOnly" && columnName !== "readOnly" && columnName !== "sBook") {
                    let currChangesClientAccountIds = Object.keys(clonedChangesObj[columnName]).filter(
                        (currKey: string) => currKey.indexOf(thisClientId) === 0
                    );
                    if (currChangesClientAccountIds.length > 0) {
                        currChangesClientAccountIds.forEach((x: string) => {
                            delete clonedChangesObj[columnName][x];
                        });
                    } else {
                        clonedChangesObj[columnName][key] = {
                            restrictionName: columnName,
                            restrictionValue: checked,
                            clientKey: `${rowData?.clientId}_${rowData?.crmServerId}_${rowData?.crmBrandId}`,
                            accounts: thisClientAccountIds,
                        };
                    }
                } else {
                    // handling ACCOUNT restrictions
                    clonedChangesObj[columnName][key] = {
                        restrictionName: columnName,
                        restrictionValue: checked,
                        clientKey: `${rowData?.clientId}_${rowData?.crmServerId}_${rowData?.crmBrandId}`,
                    };
                }
            }
            // handling CLIENT restrictions - update table to affect all SELECTED accounts under the same client
            if (columnName !== "addingCidTool" && columnName !== "closeOnly" && columnName !== "readOnly" && columnName !== "sBook") {
                let newClonedTableData = clonedTableDataObj;
                thisClientAccountIds
                    .filter((x: string) => selectedRowKeys.includes(x))
                    .forEach((x: string) => {
                        newClonedTableData[x][columnName] = checked;
                    });
                batchUpdateRestrictionsForm.setFieldValue("table", newClonedTableData);
            }
            batchUpdateRestrictionsForm.setFieldValue("changes", clonedChangesObj);
        } else {
            // handling empty colName obj in changes obj
            let clonedEmptyColChangesObj = clonedChangesObj;
            let innerValuesObj: any = {
                restrictionName: columnName,
                restrictionValue: checked,
                clientKey: `${rowData?.clientId}_${rowData?.crmServerId}_${rowData?.crmBrandId}`,
            };
            clonedEmptyColChangesObj[columnName] = {
                [key]: innerValuesObj,
            };

            // handling CLIENT restrictions - to include list of all accounts under the same client
            if (columnName !== "addingCidTool" && columnName !== "closeOnly" && columnName !== "readOnly" && columnName !== "sBook") {
                clonedChangesObj[columnName][key] = {
                    ...clonedChangesObj[columnName][key],
                    accounts: thisClientAccountIds,
                };
                let newClonedTableData = clonedTableDataObj;
                thisClientAccountIds
                    .filter((x: string) => selectedRowKeys.includes(x))
                    .forEach((x: string) => {
                        newClonedTableData[x][columnName] = checked;
                    });
                batchUpdateRestrictionsForm.setFieldValue("table", newClonedTableData);
            }
            batchUpdateRestrictionsForm.setFieldValue("changes", clonedEmptyColChangesObj);
        }
    };

    useEffect(() => {
        setFParams(props.filterParams);
        setIsLoading(true);
        apiRequest(APIs.GET_TOXIC_CLIENT_CHECK_LIST_ACC_RESTRICTIONS_CATALOGUE, {
            ...props.filterParams,
        })
            .then((data: any) => {
                let accountKeys: string[] = [];
                let newData = data.map((y: ToxicClientCheckAccViewRestrictionsCatalogueExtra) => ({
                    ...y,
                    accountKey: `${y.clientId}_${y.accountId}_${y.serverId}_${y.brandId}`,
                }));
                newData.forEach((x: ToxicClientCheckAccViewRestrictionsCatalogueExtra) => accountKeys.push(x.accountKey));
                setSelectedRowKeys(accountKeys);
                setData(newData);

                let clients: any = {},
                    accounts: any = {};
                data.forEach((x: ToxicClientCheckAccViewRestrictionsCatalogueExtra) => {
                    clients[`${x.clientId}_${x.crmServerId}_${x.crmBrandId}`] = {
                        clientId: x.clientId,
                        crmServerId: x.crmServerId,
                        crmBrandId: x.crmBrandId,
                        regulatorId: x.regulatorId,
                    };
                    accounts[`${x.clientId}_${x.accountId}_${x.serverId}_${x.brandId}`] = {
                        accountId: x.accountId,
                        serverId: x.serverId,
                        brandId: x.brandId,
                        clientId: x.clientId,
                        crmServerId: x.crmServerId,
                        crmBrandId: x.crmBrandId,
                        regulatorId: x.regulatorId,
                    };
                });
                setAccClientInfoData({ accounts, clients });
                setIsDefaultCols(initialDefaultCols);

                let tmpObj: any = {};
                data.forEach((currData: ToxicClientCheckAccViewRestrictionsCatalogue) => {
                    tmpObj[`${currData.clientId}_${currData.accountId}_${currData.serverId}_${currData.brandId}`] = { ...currData };
                });
                batchUpdateRestrictionsForm.setFieldsValue({ table: tmpObj, changes: {} });
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) =>
                    ErrorMessageHandler("batch update restrictions preview", SUCCESS_FAILED.FAILED_LOAD_DATA, err)
                )
            )
            .finally(() => setIsLoading(false));
        return () => {};
    }, [props.resetState]);

    return (
        <SitePageHeaderInline
            enableSubmit
            title={"Batch Update Restrictions"}
            callback={(action: SitePageHeaderInlineActionType) => {
                switch (action) {
                    case SitePageHeaderInlineActionType.OnBack:
                    case SitePageHeaderInlineActionType.OnCancel:
                        batchUpdateRestrictionsForm.resetFields();
                        props.callback && props.callback(InnerPageActionMode.BACK);
                        return;
                    case SitePageHeaderInlineActionType.OnSubmit:
                        batchUpdateRestrictionsForm
                            .validateFields()
                            .then(res => onSubmit(res))
                            .catch(err => console.log("form-error:", err));
                        return;
                    default:
                        break;
                }
            }}
        >
            <div className="batch-update-restrictions-container">
                <Form form={batchUpdateRestrictionsForm} onFinish={onSubmit}>
                    <FlexiDataTable
                        title={false}
                        rowKeyProperty={"accountKey"}
                        loading={isLoading}
                        columns={columns}
                        dataSource={data}
                        options={options}
                        callback={componentCallback}
                        pagination={{ defaultPageSize: 100 }}
                        tableProps={{ bordered: true }}
                    />
                </Form>
            </div>
        </SitePageHeaderInline>
    );
};

export default BatchUpdateRestrictions;
