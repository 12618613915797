import { Button, Collapse, Form, Input } from "antd";
import { TimelineItemProps } from "../alarmDashboard/alarmDashboardTimelineItem";
import { FormComponent } from "../../../../components/FormComponent";
import { ALARM_DASHBOARD_ITEM_CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "../../../../constants";
import { REQUIRED_FIELD } from "../../../../constants/errorMessage";
import { isEmptyOrNull } from "../../../../utils/string";
import { useCallback, useState } from "react";
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import { apiRequest } from "../../../../services/apiConfig";
import { APIs } from "../../../../services/apis";
import { ErrorMessageHandler, ErrorCatchValidator } from "../../../../utils/Common";
import { objectRemoveProps } from "../../../../utils/object";

const { Panel } = Collapse;

export interface AlarmDashboardInlineCommentProps {
    data: TimelineItemProps;
    statusOptions: any[];
    callback: (type: ALARM_DASHBOARD_ITEM_CALLBACK_KEY, data: any) => void;
}

const modeOptions = [
    { text: "Apply to current event", value: 1 },
    { text: "Apply to all related events", value: 2 },
    { text: "Apply to all related events before current event", value: 3 },
];

const AlarmDashboardInlineComment = (props: AlarmDashboardInlineCommentProps) => {
    const [activeKey, setActiveKey] = useState<string>("");
    const [commentForm] = Form.useForm();

    const updateCommentAndStatus = useCallback(
        (data: any) => {
            apiRequest(APIs.UPDATE_ALARM_STATUS, data)
                .then(res => {
                    ErrorMessageHandler("alarm comment", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                })
                .catch(error =>
                    ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("alarm comment", SUCCESS_FAILED.FAILED_UPDATE_DATA, err))
                )
                .finally(() => props.callback && props.callback(ALARM_DASHBOARD_ITEM_CALLBACK_KEY.REFRESH_LIST, {}));
        },
        [props.callback]
    );

    return (
        <div className="alarm-dashboard-inline-comment-container">
            <Collapse
                accordion
                ghost
                activeKey={activeKey}
                collapsible="icon"
                expandIconPosition="end"
                onChange={(key: string | string[]) => setActiveKey(key as string)}
                expandIcon={panelProps => {
                    return (
                        <>
                            {!panelProps.isActive && (
                                <Button
                                    type="default"
                                    icon={<EditOutlined />}
                                    onClick={() => {
                                        commentForm.setFieldsValue({
                                            alarmEventId: props.data.alarmEventId,
                                            correlationHashKey: props.data.correlationHashKey,
                                            mode: isEmptyOrNull(props.data.correlationHashKey) ? 1 : 3,
                                            statusCode: props.data.eventStatusCode,
                                            comment: "",
                                        });
                                    }}
                                >
                                    Add Comment & Status
                                </Button>
                            )}
                        </>
                    );
                }}
            >
                <Panel
                    header=""
                    key="1"
                    {...(!isEmptyOrNull(activeKey) && {
                        extra: (
                            <div className="enable-edit-btns">
                                <Button
                                    type="primary"
                                    icon={<CheckOutlined />}
                                    onClick={() => {
                                        commentForm
                                            .validateFields()
                                            .then(values => {
                                                if (values.mode === 1) {
                                                    updateCommentAndStatus(objectRemoveProps(values, ["mode", "correlationHashKey"]));
                                                } else if (values.mode === 2) {
                                                    updateCommentAndStatus(objectRemoveProps(values, ["mode", "alarmEventId"]));
                                                } else if (values.mode === 3) {
                                                    updateCommentAndStatus(objectRemoveProps(values, ["mode"]));
                                                }
                                                setActiveKey("");
                                            })
                                            .catch(error => console.log(error));
                                    }}
                                >
                                    Save
                                </Button>
                                <Button type="default" icon={<CloseOutlined />} onClick={() => setActiveKey("")}>
                                    Cancel
                                </Button>
                            </div>
                        ),
                    })}
                >
                    <div className="form-components-container">
                        <Form form={commentForm} labelCol={{ span: 5 }} labelAlign="left" wrapperCol={{ span: 19 }} layout="horizontal">
                            <FormComponent label="" name="alarmEventId" extra={{ type: ComponentType.hidden, value: "" }} />
                            <FormComponent label="" name="correlationHashKey" extra={{ type: ComponentType.hidden, value: "" }} />
                            <FormComponent label="" name="moduleCode" extra={{ type: ComponentType.hidden, value: "" }} />
                            <Form.Item label="Status">
                                <Input.Group compact>
                                    {props.statusOptions.length > 0 ? (
                                        <FormComponent
                                            label=""
                                            name="statusCode"
                                            extra={{
                                                type: ComponentType.dropdown,
                                                value: props.statusOptions,
                                                itemProps: { noStyle: true },
                                                inputProps: { style: { width: "30%" } },
                                            }}
                                        />
                                    ) : (
                                        <FormComponent label="" name="statusCode" extra={{ type: ComponentType.hidden, value: "" }} />
                                    )}
                                    <FormComponent
                                        label=""
                                        name="mode"
                                        extra={{
                                            type: ComponentType.dropdown,
                                            value: modeOptions,
                                            itemProps: { noStyle: true },
                                            inputProps: { style: { width: "70%" } },
                                        }}
                                    />
                                </Input.Group>
                            </Form.Item>
                            <FormComponent
                                label="Comment"
                                name="comment"
                                extra={{
                                    type: ComponentType.textarea,
                                    value: "",
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                    inputProps: {
                                        row: 6,
                                    },
                                }}
                            />
                        </Form>
                    </div>
                </Panel>
            </Collapse>
        </div>
    );
};

export default AlarmDashboardInlineComment;
