import { Pie } from "@ant-design/plots";
import { useMemo } from "react";
import { AlarmHistoricalSummaryByModulesProps } from "../../../../constants/type";
import { Datum } from "@ant-design/charts";
import { currencyRender } from "../../../../utils/Common";

export interface AlarmHistoricalSummaryByModuleProps {
    containerClassName?: string;
    title: string;
    SummaryDetails: AlarmHistoricalSummaryByModulesProps[];
}

const AlarmHistoricalSummaryByModule = (props: AlarmHistoricalSummaryByModuleProps) => {
    const configData: any = useMemo(() => {
        return {
            data: props.SummaryDetails.map(x => ({ ...x, name: `(${x.clientApp}) ${x.moduleName}` })),
            angleField: "totalEvents",
            colorField: "name",
            radius: 0.9,
            animation: false,
            label: {
                type: "inner",
                offset: "-50%",
                style: {
                    fontSize: 12,
                    textAlign: "center",
                },
                content: (data: Datum, mappingData: any) => {
                    return `(${data["clientApp"]})\n${data["moduleName"]}\n${currencyRender(data["percent"] * 100, 2)}%`;
                },
            },
            legend: {
                layout: "horizontal",
                position: "bottom",
            },
            tooltip: {
                formatter: (data: Datum) => {
                    return { name: data["name"], value: `${data["totalEvents"]} event${data["totalEvents"] > 1 ? "s" : ""}` };
                },
            },
            interactions: [
                {
                    type: "element-active",
                },
            ],
        };
    }, [props.SummaryDetails]);
    return (
        <div className={`alarm-historical-summary-by-module-container shadow-light ${props.containerClassName ? props.containerClassName : ""}`}>
            <div className="title-container">{props.title}</div>
            <div className="chart-content">
                <Pie {...configData} />
            </div>
        </div>
    );
};

export default AlarmHistoricalSummaryByModule;
