import {
    DeleteOutlined,
    EditOutlined,
    HistoryOutlined,
    HomeOutlined,
    NotificationOutlined,
    PlusOutlined,
    SettingOutlined,
    SyncOutlined,
} from "@ant-design/icons";
import { Form, Row, Col, Button, Divider, Empty, Modal, Tooltip, Popconfirm } from "antd";
import { useState, useEffect } from "react";
import { useNavigate, useLocation, NavigateFunction } from "react-router-dom";
import { FormComponent } from "../../../components/FormComponent";
import LoadingComponent from "../../../components/Loading";
import SitePageHeader from "../../../components/PageHeader";
import { ComponentType, SUCCESS_FAILED } from "../../../constants";
import { REQUIRED_FIELD } from "../../../constants/errorMessage";
import { EventRuleProps, KeyValuePair, NotificationChannel, Tags, ThresholdObjectProps, ViewProps } from "../../../constants/type";
import { apiRequest } from "../../../services/apiConfig";
import { APIs } from "../../../services/apis";
import { getGUID, ErrorMessageHandler, ErrorCatchValidator } from "../../../utils/Common";
import EventRuleCreateEdit, { EVENT_RULE_THRESHOLD_PREFIX } from "./EventRuleCreateEdit";
import ViewCreateEdit from "./ViewCreateEdit";
import ThresholdEdit from "./ThresholdEdit";
import { UserInfoProps, userInfo } from "../../../services/api";
import { FormInstance } from "antd/es/form";
import { findObjectKeys, objectRemoveProps } from "../../../utils/object";
import { cloneDeep } from "lodash";
import { getAppSettings } from "../../../services/localStorage";
import { getThresholdTemplate } from "./TradeMonitoring";
import { DateTimeUtil } from "../../../utils/datetime";
import EmptyData from "../../../components/Common/Empty";

export type MonitorProfileState = {
    action: string;
    data: any;
};

export interface MonitorProfileProps {
    profileId?: number;
    name: string;
    description: string;
    setting?: string;
    isViewInDahboard: boolean;
    isEventSummaryProfile: boolean;
    views: ViewProps[];
    eventRules: EventRuleProps[];
}

const initialValue: MonitorProfileProps = {
    profileId: 0,
    name: "",
    description: "",
    isViewInDahboard: false,
    isEventSummaryProfile: false,
    setting: "",
    eventRules: [],
    views: [],
};

export const ChartType: { [key: number]: string } = {
    0: "Table",
    1: "Bar",
    2: "Line",
};

const TradeMonitoringCreateEditPage = () => {
    let navigate = useNavigate();
    let location = useLocation();
    let com_state: MonitorProfileState = location.state as MonitorProfileState;

    const userinfo = userInfo() as UserInfoProps;

    const [showAddEventRuleModal, setShowAddEventRuleModal] = useState<boolean>(false);
    const [eventRuleTitle, setEventRuleTitle] = useState<string>("");
    const [currEventData] = useState<any>({});

    const [showAddDataVisualModal, setShowAddDataVisualModal] = useState<boolean>(false);
    const [dataVisualTitle, setDataVisualTitle] = useState<string>("");
    const [currDataVisual] = useState<any>({});
    const [tags, setTags] = useState<Tags[]>([]);
    const [notifChannels, setNotifChannels] = useState<NotificationChannel[]>([]);

    const [currentState] = useState<MonitorProfileState>(com_state || { action: "add", data: null });
    const [isAddAction] = useState<boolean>(currentState.action === "add");
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [monitorProfileForm] = Form.useForm();
    const [eventRuleForm] = Form.useForm();
    const [dataVisualForm] = Form.useForm();
    const [profileThresholdForm] = Form.useForm();
    const [currData, setCurrData] = useState<MonitorProfileProps>({ ...initialValue });
    const [showAddThresholdModal, setShowAddThresholdModal] = useState<boolean>(false);
    const appSettings = getAppSettings();
    const monitorProfileFormStatus = Form.useWatch("status", monitorProfileForm);

    const onSubmit = (values: any) => {
        handleFormSubmission(values);
    };

    const handleStatusConfirm = () => {
        handleFormSubmission(monitorProfileForm.getFieldsValue());
    };

    const handleFormSubmission = (values: any) => {
        // replace client side generated id to '0'
        //let submittedData: any = { ...values };
        let submittedData: any = cloneDeep(values);
        if (submittedData.eventRules.length > 0) {
            submittedData.eventRules = submittedData.eventRules.map((x: any) => {
                if (x.id && x.id.toString().indexOf("ctn-") > -1) {
                    x.id = "0";
                }
                x.status = submittedData.status === false ? 0 : x.status ? 1 : 0;
                return x;
            });
        }
        if (submittedData.views.length > 0) {
            submittedData.views = submittedData.views.map((x: any) => {
                if (x.id && x.id.toString().indexOf("dvt-") > -1) {
                    x.id = "0";
                }

                let chartConfig: any = {};
                Object.keys(x)
                    .filter((y) => y.indexOf("c_") > -1)
                    .map((y) => {
                        chartConfig[y.split("_")[2]] = x[y];
                        return 0;
                    });
                x["chartConfig"] = JSON.stringify(chartConfig);
                Object.keys(x)
                    .filter((y) => y.indexOf("c_") > -1)
                    .map((y) => {
                        delete x[y];

                        return false;
                    });
                return x;
            });
        }

        if (submittedData.isViewInDashboard === undefined) {
            submittedData.isViewInDashboard = false;
        }
        if (submittedData.isEventSummaryProfile === undefined) {
            submittedData.isEventSummaryProfile = false;
        }

        setIsLoading(true);
        if (isAddAction) {
            let newSubmittedData = {
                name: submittedData.name,
                description: submittedData.description,
                eventRules: submittedData.eventRules,
                isViewInDashboard: submittedData.isViewInDashboard,
                isEventSummaryProfile: submittedData.isEventSummaryProfile,
                views: submittedData.views,
                threshold: submittedData.threshold,
                setting: submittedData.setting,
                status: submittedData.status ? 1 : 0,
            };

            apiRequest(APIs.CREATE_MONITOR_PROFILE, newSubmittedData)
                .then((data) => {
                    ErrorMessageHandler("New monitor profile", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                    navigate("/realtime/trademonitor");
                })
                .catch((error) => {
                    ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("monitor profile", SUCCESS_FAILED.FAILED_CREATE_DATA, err));
                    setIsLoading(false);
                });
        } else {
            let newSubmittedData = {
                profileId: submittedData.profileId,
                name: submittedData.name,
                description: submittedData.description,
                isViewInDashboard: submittedData.isViewInDashboard,
                isEventSummaryProfile: submittedData.isEventSummaryProfile,
                eventRules: submittedData.eventRules,
                views: submittedData.views,
                threshold: submittedData.threshold,
                setting: submittedData.setting,
                status: submittedData.status ? 1 : 0,
            };

            apiRequest(APIs.UPDATE_MONITOR_PROFILE, newSubmittedData)
                .then((data) => {
                    ErrorMessageHandler("Existing monitor profile", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                    navigate("/realtime/trademonitor");
                })
                .catch((error) => {
                    ErrorCatchValidator(error, (err: any) => {
                        ErrorMessageHandler("monitor profile", SUCCESS_FAILED.FAILED_UPDATE_DATA, err);
                        console.log("Error updating monitior profile: ", err);
                    });
                    setIsLoading(false);
                });
        }
    };

    const onAddEditEventRuleClicked = (isAdded: boolean, data?: EventRuleProps) => {
        eventRuleForm.setFieldsValue(
            Object.assign(
                {
                    id: `ctn-${getGUID()}`,
                    profileId: 0,
                    name: "",
                    description: "",
                    dataQuery: "",
                    status: true,
                    refreshIntervalInSec: 0,
                },
                isAdded ? {} : { ...data },
                isAddAction ? {} : { profileId: currData.profileId }
            )
        );
        setEventRuleTitle(isAdded ? "Add Event Rule" : "Edit Event Rule");
        setShowAddEventRuleModal(true);
    };

    const onAddEditDataVisualClicked = (isAdded: boolean, data?: ViewProps) => {
        dataVisualForm.setFieldsValue(
            Object.assign(
                {
                    id: `dvt-${getGUID()}`,
                    profileId: 0,
                    name: "",
                    description: "",
                    level: "",
                    isAccLevel: false,
                    isEventRuleView: false,
                    dataQuery: "",
                    chartType: 0,
                    chartConfig: "",
                    isViewInDahboard: false,
                },
                isAdded ? {} : { ...data },
                isAddAction ? {} : { profileId: currData.profileId }
            )
        );
        setDataVisualTitle(isAdded ? "Add View" : "Edit View");
        setShowAddDataVisualModal(true);
    };

    const onEditThresholdClicked = (isShow: boolean, data?: string) => {
        profileThresholdForm.setFieldsValue({ threshold: data || "" });
        setShowAddThresholdModal(isShow);
    };

    const isThresholdShowUp = (isAdministrator: boolean, tmpForm: FormInstance) => {
        let isShow: boolean = true;
        if (!isAdministrator) {
            try {
                let tmp = JSON.parse(tmpForm.getFieldValue("threshold"));
                if (!(Array.isArray(tmp) && tmp.length > 0)) {
                    isShow = false;
                }
            } catch (error) {
                isShow = false;
            }
        }

        return isShow ? (
            <Button
                type="dashed"
                onClick={() => onEditThresholdClicked(true, tmpForm.getFieldValue("threshold"))}
                icon={<SettingOutlined />}
                style={{ marginRight: "0.651vw" }}
            >
                Set Threshold
            </Button>
        ) : (
            <></>
        );
    };

    const reOrderingLevel = () => {
        let views = [...monitorProfileForm.getFieldValue("views")];
        views.sort((a: ViewProps, b: ViewProps) => a.level - b.level);
        views = views.map((x: any, index: number) => {
            x.level = index + 1;
            return x;
        });
        monitorProfileForm.setFieldsValue({ views: views });
    };

    const deleteProfile = (profileId: string, navigator: NavigateFunction) => {
        apiRequest(APIs.DELETE_MONITOR_PROFILE, { profileId })
            .then((data) => {
                ErrorMessageHandler("The monitor profile", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                navigator("/realtime/trademonitor");
            })
            .catch((error) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("monitor profile", SUCCESS_FAILED.FAILED_DELETE_DATA, err))
            );
    };

    const displayNotifChannelInfo = (channelUid: string) => {
        let currChannelIdx = notifChannels.findIndex((x: NotificationChannel) => x.channelUid === channelUid);
        return (
            <Tooltip title="Notification channel">
                <NotificationOutlined />
                <span className="text">{notifChannels[currChannelIdx].name}</span>
            </Tooltip>
        );
    };

    const getNotificationChannelsList = () => {
        apiRequest(APIs.GET_NOTIFICATION_CHANNEL_LIST, {})
            .then((res: any) => {
                setNotifChannels(res || []);
            })
            .catch((error) => ErrorCatchValidator(error, (err: any) => {}));
    };

    useEffect(() => {
        apiRequest(APIs.GET_METRIC_INFO, {})
            .then((res: any) => {
                setTags(res.tags || []);
            })
            .catch((error) => ErrorCatchValidator(error, (err: any) => {}));
        return () => {};
    }, []);

    useEffect(() => {
        apiRequest(APIs.GET_NOTIFICATION_CHANNEL_LIST, {})
            .then((res: any) => {
                setNotifChannels(res || []);
            })
            .catch((error) => ErrorCatchValidator(error, (err: any) => {}))
            .finally(() => {
                if (!isAddAction) {
                    apiRequest(APIs.GET_MONITOR_PROFILE, { profileId: currentState.data })
                        .then((res: any) => {
                            let data: any = { ...res, profileId: res.id };
                            if (data.eventRules) {
                                data.eventRules = data.eventRules.map((x: any) => {
                                    x.status = x.status === 1;
                                    return x;
                                });
                            } else {
                                data.eventRules = [];
                            }
                            if (data.views) {
                                data.views = data.views.map((x: any) => {
                                    let tempObj = JSON.parse(x.chartConfig);
                                    Object.keys(tempObj).map((y) => {
                                        x[`c_${x.chartType}_${y}`] = tempObj[y];

                                        return false;
                                    });
                                    delete x["chartConfig"];
                                    return x;
                                });
                                data.views.sort((a: ViewProps, b: ViewProps) => a.level - b.level);
                            } else {
                                data.views = [];
                            }
                            setCurrData(data);
                            monitorProfileForm.setFieldsValue(data);
                        })
                        .catch((error: any) => {
                            ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("profile details", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                            navigate("/realtime/trademonitor");
                        })
                        .finally(() => {
                            setIsLoading(false);
                        });
                } else {
                    monitorProfileForm.setFieldsValue({ ...initialValue });
                    setIsLoading(false);
                }
            });
        return () => {};
    }, [currentState.data, isAddAction, monitorProfileForm]);

    return (
        <SitePageHeader
            title={isAddAction ? "Create New Monitor Profile" : "Edit Monitor Profile"}
            routes={[
                {
                    path: "/realtime/trademonitor",
                    breadcrumbName: "Monitor Profile List",
                    icon: <HomeOutlined />,
                },
                {
                    path: "",
                    breadcrumbName: isAddAction ? "Create New Monitor Profile" : "Edit Monitor Profile",
                },
            ]}
            onBack={() => navigate("/realtime/trademonitor")}
            extraProps={{
                extra: isAddAction
                    ? []
                    : [
                          <Popconfirm
                              key={"cetmp-del"}
                              title="Confirm to delete?"
                              onConfirm={() => deleteProfile(currentState.data, navigate)}
                              okText="Yes"
                              cancelText="No"
                          >
                              <Button type="primary" danger>
                                  Delete Monitor Profile
                              </Button>
                          </Popconfirm>,
                      ],
            }}
        >
            {isLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Submitting..." />
                </div>
            ) : (
                <Form form={monitorProfileForm} layout="horizontal" initialValues={currentState.data} onFinish={onSubmit}>
                    <Row>
                        <Col span={24} className="mg-btm-title custom-subtitle">
                            Profile Information
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <FormComponent label={""} name={"profileId"} extra={{ type: ComponentType.hidden, value: "" }} />
                            <FormComponent label={""} name={"threshold"} extra={{ type: ComponentType.hidden, value: "" }} />
                            <FormComponent label={""} name={"isViewInDashboard"} extra={{ type: ComponentType.hidden, value: "" }} />
                            <FormComponent
                                label="Name"
                                name="name"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    itemProps: {
                                        labelAlign: "left",
                                        labelCol: { span: 5 },
                                    },
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                            <Row>
                                <Col span={5}></Col>
                                {/* <Col span={9}>
                                    <FormComponent
                                        label=""
                                        name="isViewInDashboard"
                                        extra={{
                                            type: ComponentType.checkbox,
                                            value: "View in Dashboard",
                                            itemProps: {
                                                colon: false,
                                            },
                                        }}
                                    />
                                </Col>
                                <Col span={9}> */}
                                <Col span={18}>
                                    <FormComponent
                                        label=" "
                                        name="isEventSummaryProfile"
                                        extra={{
                                            type: ComponentType.checkbox,
                                            value: "Is Event Summary Profile",
                                            itemProps: {
                                                colon: false,
                                            },
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <FormComponent
                                        label="Profile Status"
                                        name="status"
                                        extra={{
                                            type: ComponentType.switch,
                                            value: ["Inactive", "Active"],
                                            itemProps: {
                                                labelAlign: "left",
                                                labelCol: { span: 5 },
                                            },
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col style={{ width: "17px" }}></Col>
                        <Col span={11}>
                            <FormComponent
                                label="Description"
                                name="description"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    itemProps: {
                                        labelAlign: "right",
                                        labelCol: { span: 5 },
                                    },
                                }}
                            />
                            {userinfo.isAdministrator ? (
                                <FormComponent
                                    label="Setting"
                                    name="setting"
                                    extra={{
                                        type: ComponentType.textarea,
                                        value: "",
                                        itemProps: {
                                            labelAlign: "right",
                                            labelCol: { span: 5 },
                                        },
                                        rules: [
                                            {
                                                validator: async (_, value) => {
                                                    if (value === undefined || value.length === 0) {
                                                        return Promise.resolve();
                                                    }

                                                    try {
                                                        let tmp: any = JSON.parse(value);
                                                        return Promise.resolve();
                                                    } catch (error) {
                                                        return Promise.reject(new Error("Invalid JSON format."));
                                                    }
                                                },
                                            },
                                        ],
                                    }}
                                />
                            ) : (
                                <FormComponent label={""} name={"setting"} extra={{ type: ComponentType.hidden, value: "" }} />
                            )}
                        </Col>
                    </Row>
                    <Row className="monitor-profile-create-div">
                        <Col span={12}>
                            <div className="data-visualization-container">
                                <Row>
                                    <Col className="title-div" span={24}>
                                        <h1>Views</h1>
                                        <div>
                                            {isThresholdShowUp(userinfo.isAdministrator, monitorProfileForm)}
                                            <Button
                                                type="dashed"
                                                onClick={() => reOrderingLevel()}
                                                icon={<SyncOutlined />}
                                                style={{ marginRight: "0.651vw" }}
                                            >
                                                Reordering level
                                            </Button>
                                            <Button type="dashed" onClick={() => onAddEditDataVisualClicked(true)} icon={<PlusOutlined />}>
                                                Add View
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <div className="list-item-div">
                                            <Form.List name={"views"}>
                                                {(fields, { add, remove }) => {
                                                    return fields.length > 0 ? (
                                                        <>
                                                            {fields.map((field, index) => {
                                                                let currView: ViewProps[] = monitorProfileForm.getFieldValue("views");
                                                                return (
                                                                    <div key={field.key} className="item-container">
                                                                        <div className="left-panel">
                                                                            <div className="icon-panel">{currView[index].level}</div>
                                                                            <div>
                                                                                <div className="title">{currView[index].name}</div>
                                                                                <div className="subtitle">{currView[index].description}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="right-panel">
                                                                            <div className="buttons-group">
                                                                                <Button
                                                                                    type="link"
                                                                                    icon={<EditOutlined />}
                                                                                    onClick={() => onAddEditDataVisualClicked(false, currView[index])}
                                                                                />
                                                                                <Button
                                                                                    type="link"
                                                                                    danger
                                                                                    icon={<DeleteOutlined />}
                                                                                    onClick={() => remove(index)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </>
                                                    ) : (
                                                        <EmptyData />
                                                    );
                                                }}
                                            </Form.List>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col>
                            <Divider type="vertical" orientationMargin={10} style={{ height: "calc(100%)" }} />
                        </Col>
                        <Col span={11}>
                            <div className="event-rule-container">
                                <Row>
                                    <Col className="title-div" span={24}>
                                        <h1>Event Rules</h1>
                                        <Button type="dashed" onClick={() => onAddEditEventRuleClicked(true)} icon={<PlusOutlined />}>
                                            Add Event Rule
                                        </Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <div className="list-item-div">
                                            <Form.List name={"eventRules"}>
                                                {(fields, { add, remove }) => {
                                                    return fields.length > 0 ? (
                                                        <>
                                                            {fields.map((field, index) => {
                                                                let currentEventRule: EventRuleProps[] =
                                                                    monitorProfileForm.getFieldValue("eventRules");
                                                                return (
                                                                    <div key={field.key} className="item-container">
                                                                        <div className="left-panel">
                                                                            {/* <div className="icon-panel">
                                                                                <CodeOutlined />
                                                                            </div> */}
                                                                            <div>
                                                                                <div className="title">{currentEventRule[index].name}</div>
                                                                                <div className="subtitle">{currentEventRule[index].description}</div>
                                                                                <div className="threshold-panel">
                                                                                    {getThresholdTemplate(
                                                                                        appSettings,
                                                                                        currentEventRule[index].threshold || "",
                                                                                        undefined,
                                                                                        true
                                                                                    )}
                                                                                </div>
                                                                                <div className="config-row">
                                                                                    <div className="interval">
                                                                                        <Tooltip title="Refresh interval in second">
                                                                                            <HistoryOutlined />
                                                                                            <span className="text">
                                                                                                {DateTimeUtil.convertSecondToReadableTimeDesc(
                                                                                                    currentEventRule[index].refreshIntervalInSec
                                                                                                )}
                                                                                            </span>
                                                                                        </Tooltip>
                                                                                    </div>
                                                                                    {currentEventRule[index].channelUid && (
                                                                                        <div className="notification-channel">
                                                                                            {displayNotifChannelInfo(
                                                                                                currentEventRule[index].channelUid as string
                                                                                            )}
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="right-panel">
                                                                            <div className="status-desc">
                                                                                <span className={currentEventRule[index].status ? "active" : ""}>
                                                                                    {currentEventRule[index].status ? "Active" : "Inactive"}
                                                                                </span>
                                                                            </div>
                                                                            <div className="buttons-group">
                                                                                <Button
                                                                                    type="link"
                                                                                    icon={<EditOutlined />}
                                                                                    onClick={() =>
                                                                                        onAddEditEventRuleClicked(false, currentEventRule[index])
                                                                                    }
                                                                                />
                                                                                <Button
                                                                                    type="link"
                                                                                    danger
                                                                                    icon={<DeleteOutlined />}
                                                                                    onClick={() => remove(index)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </>
                                                    ) : (
                                                        <EmptyData />
                                                    );
                                                }}
                                            </Form.List>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div className="step-btns-group">
                                {(monitorProfileForm.getFieldValue("status") === 0 ||
                                    monitorProfileFormStatus === 0 ||
                                    monitorProfileForm.getFieldValue("status") === false) &&
                                monitorProfileForm.getFieldValue("eventRules").some((rule: any) => rule.status === true) ? (
                                    <Popconfirm
                                        key={"status-confirmation"}
                                        title={
                                            <div>
                                                Event Rule Status will automatically set to Inactive when Profile Status is Inactive, <br />
                                                Are you sure you want to continue?
                                            </div>
                                        }
                                        onConfirm={handleStatusConfirm}
                                        okText="Yes"
                                        cancelText="No"
                                        placement="topLeft"
                                    >
                                        <Button type="primary" htmlType="submit">
                                            Submit
                                        </Button>
                                    </Popconfirm>
                                ) : (
                                    <Button type="primary" htmlType="submit">
                                        Submit
                                    </Button>
                                )}
                            </div>
                        </Col>
                    </Row>
                </Form>
            )}

            <Modal
                width="45.57vw"
                destroyOnClose
                maskClosable={false}
                title={eventRuleTitle}
                open={showAddEventRuleModal}
                onOk={() => {
                    eventRuleForm
                        .validateFields()
                        .then((values) => {
                            let eventRules: EventRuleProps[] = [...monitorProfileForm.getFieldValue("eventRules")];
                            let findIdx = eventRules.findIndex((x) => x.id === values["id"]);
                            if (findIdx > -1) {
                                if (userinfo.isAdministrator) {
                                    eventRules[findIdx] = values;
                                } else {
                                    eventRules[findIdx] = objectRemoveProps(values, [EVENT_RULE_THRESHOLD_PREFIX]);
                                    let tKeys = findObjectKeys(values, [EVENT_RULE_THRESHOLD_PREFIX], undefined, true) as string[];
                                    if (tKeys.length > 0) {
                                        eventRules[findIdx]["threshold"] = JSON.stringify(
                                            JSON.parse(values["threshold"]).map((x: ThresholdObjectProps) => ({
                                                ...x,
                                                value:
                                                    x.fieldType === "number"
                                                        ? parseInt(values[`${EVENT_RULE_THRESHOLD_PREFIX}${x.name}`])
                                                        : values[`${EVENT_RULE_THRESHOLD_PREFIX}${x.name}`],
                                            }))
                                        );
                                    }
                                }
                            } else {
                                eventRules.push(values);
                            }
                            monitorProfileForm.setFieldsValue({ eventRules });
                            setShowAddEventRuleModal(false);
                        })
                        .catch((err) => {});
                }}
                onCancel={() => {
                    eventRuleForm.resetFields();
                    setShowAddEventRuleModal(false);
                }}
            >
                <div className="event-rule-creat-container">
                    <EventRuleCreateEdit
                        form={eventRuleForm}
                        data={currEventData}
                        tagsList={tags}
                        isAdministrator={userinfo.isAdministrator}
                        notifChannelsList={notifChannels}
                        callback={() => getNotificationChannelsList()}
                    />
                </div>
            </Modal>

            <Modal
                width="45.57vw"
                destroyOnClose
                maskClosable={false}
                title={dataVisualTitle}
                open={showAddDataVisualModal}
                onOk={() => {
                    dataVisualForm
                        .validateFields()
                        .then((values) => {
                            let views: ViewProps[] = [...monitorProfileForm.getFieldValue("views")];
                            let findIdx = views.findIndex((x) => x.id === values["id"]);
                            if (findIdx > -1) {
                                views[findIdx] = values;
                            } else {
                                views.push(values);
                            }
                            monitorProfileForm.setFieldsValue({ views });
                            setShowAddDataVisualModal(false);
                        })
                        .catch((err) => {});
                }}
                onCancel={() => {
                    dataVisualForm.resetFields();
                    setShowAddDataVisualModal(false);
                }}
            >
                <ViewCreateEdit form={dataVisualForm} data={currDataVisual} />
            </Modal>

            <Modal
                width="45.57vw"
                destroyOnClose
                maskClosable={false}
                title={"Set Threshold"}
                open={showAddThresholdModal}
                onOk={() => {
                    profileThresholdForm
                        .validateFields()
                        .then((values) => {
                            console.log("values: ", values);
                            monitorProfileForm.setFieldsValue({
                                threshold: userinfo.isAdministrator
                                    ? values["threshold"]
                                    : JSON.stringify(
                                          JSON.parse(monitorProfileForm.getFieldValue("threshold")).map((x: ThresholdObjectProps) => ({
                                              ...x,
                                              value: x.fieldType === "number" ? Number(values[x.name]) : values[x.name],
                                          }))
                                      ),
                            });
                            setShowAddThresholdModal(false);
                        })
                        .catch((err) => {});
                }}
                onCancel={() => {
                    setShowAddThresholdModal(false);
                }}
            >
                <div className="profile-threshold-container">
                    <ThresholdEdit
                        form={profileThresholdForm}
                        data={{ threshold: monitorProfileForm.getFieldValue("threshold") }}
                        isAdministrator={userinfo.isAdministrator}
                    />
                </div>
            </Modal>
        </SitePageHeader>
    );
};

export default TradeMonitoringCreateEditPage;
