import { FileSearchOutlined, HomeOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Descriptions, Empty, Modal } from "antd";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CardBox from "../../../components/Common/CardBox";
import FlexiDataTable from "../../../components/FlexiDataTable";
import LoadingComponent from "../../../components/Loading";
import { CALLBACK_KEY, ComponentType } from "../../../constants";
import {
    CustomPaginationProps,
    HistoricalEvent,
    FlexiDataTableOptionsProps,
    FlexiDataTableCallbackProps,
    FlexiDataColumnProps,
    HistoricalTrades,
    EventRuleList,
    MonitorProfileList,
    MonitorProfiles,
    APPSETTINGS,
} from "../../../constants/type";
import { apiRequest } from "../../../services/apiConfig";
import { APIs } from "../../../services/apis";
import { DataTableColumnRender, DTColProps, ErrorCatchValidator } from "../../../utils/Common";
import { findObjectKeys, objectRemoveProps, objectToTableColumns } from "../../../utils/object";
import { getNavigationUrl, ACCOUNT_PROFILE_FROM_MODULE, getAccountProfileIdByObj } from "../../Analysis/AccountProfileHelper";
import { getThresholdTemplate } from "../Trade/TradeMonitoring";
import { getAppSettings } from "../../../services/localStorage";
import { DateTimeUtil } from "../../../utils/datetime";
import EmptyData from "../../../components/Common/Empty";

const HistoricalEventPage = () => {
    // const getColumnsConfig = (
    //     monitorProfileList: MonitorProfileList[] = [],
    //     profile: MonitorProfile[] = [],
    //     eventRuleList: EventRuleList[] = [],
    //     eventRule: EventRule[] = []
    // ) => {
    //     return [
    //         {
    //             title: "Event Info",
    //             dataIndex: "profileId",
    //             key: "profileId",
    //             render: (profileId: number, record: any) => (
    //                 <div>
    //                     <div>
    //                         <span style={{ fontWeight: "bold" }}>Monitor Profile: </span>
    //                         {record["profileName"]}
    //                     </div>
    //                     <div>
    //                         <span style={{ fontWeight: "bold" }}>Event Rule: </span>
    //                         {record["eventRuleName"]}
    //                     </div>
    //                 </div>
    //             ),
    //             options: {
    //                 filter: {
    //                     text: "Monitor Profile",
    //                     type: ComponentType.dropdown,
    //                     value: monitorProfileList.map((x) => ({ text: x.name, value: x.id })),
    //                 },
    //             },
    //         },
    //         {
    //             title: "",
    //             dataIndex: "eventRuleId",
    //             key: "eventRuleId",
    //             options: {
    //                 visible: false,
    //                 filter: {
    //                     text: "Event Rule",
    //                     type: ComponentType.dropdown,
    //                     value: eventRuleList.map((x) => ({ text: x.name, value: x.id })),
    //                 },
    //             },
    //         },
    //         //Table.EXPAND_COLUMN,
    //         // DTColProps.DateTime_ServerTime({
    //         //     title: (
    //         //         <>
    //         //             Capture Time(From) <br />
    //         //             (Server Time)
    //         //         </>
    //         //     ),
    //         //     dataIndex: "eventTimeFrom",
    //         //     key: "eventTimeFrom",
    //         //     options: {
    //         //         filter: {},
    //         //     },
    //         //     sorter: true,
    //         // }),
    //         // DTColProps.DateTime_ServerTime({
    //         //     title: (
    //         //         <>
    //         //             Capture Time(To) <br />
    //         //             (Server Time)
    //         //         </>
    //         //     ),
    //         //     dataIndex: "eventTimeTo",
    //         //     key: "eventTimeTo",
    //         //     options: {
    //         //         filter: {},
    //         //     },
    //         //     sorter: true,
    //         // }),
    //         DTColProps.DateTime_ServerTime({
    //             title: <>Triggered Time (Server Time)</>,
    //             dataIndex: "capturedTime",
    //             key: "capturedTime",
    //             options: {
    //                 filter: {
    //                     type: ComponentType.daterange,
    //                     value: "",
    //                     text: "Triggered Time (Server Time)",
    //                     inputProps: {
    //                         showTime: { format: "HH:mm:ss" },
    //                     },
    //                 },
    //             },
    //             defaultSortOrder: "descend",
    //             sortDirections: ["ascend", "descend"],
    //             sorter: true,
    //             width: "250px",
    //         }),
    //     ];
    // };

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [isShowTicketModal, setIsShowTicketModal] = useState<boolean>(false);
    const [ticketLoading, setTicketLoading] = useState<boolean>(true);
    const [tickets, setTickets] = useState<any[]>([]);
    const [selectedTicketInfo, setSelectedTicketInfo] = useState<any>({});
    //const [tableCols, setTableCols] = useState<FlexiDataColumnProps[]>([]);
    const [monitorProfiles, setMonitorProfiles] = useState<any[]>([]);
    const [eventRules, setEventRules] = useState<any[]>([]);
    const [data, setData] = useState<HistoricalEvent[]>([]);
    const [pagination, setPagination] = useState<CustomPaginationProps>({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [filterParams, setFilterParams] = useState<any>({});
    const [sorting, setSorting] = useState<string>("");
    const [isAccountView, setIsAccountView] = useState<boolean>(false);
    const [selectedHistorical, setSelectedHistorical] = useState<any>({});
    const appSettingObj = getAppSettings();

    const options: FlexiDataTableOptionsProps = {
        serverFiltering: true,
        enableFilter: true,
        enableRowSelection: true,
        rowSelectionData: {
            rowSelectionType: "row",
            selectedRowKeys: [],
        },
    };

    const getColumnConfig = (appSetting: APPSETTINGS, mp: any[], er: any[]) => [
        {
            title: "Event Info",
            dataIndex: "profileId",
            key: "profileId",
            render: (profileId: number, record: any) => (
                <div>
                    <div>
                        <span style={{ fontWeight: "bold" }}>Monitor Profile: </span>
                        {record["profileName"]}
                    </div>
                    <div>
                        <span style={{ fontWeight: "bold" }}>Event Rule: </span>
                        {record["eventRuleName"]}
                    </div>
                    <div>
                        <span style={{ fontWeight: "bold" }}>Event Batch ID: </span>
                        {record["eventBatchId"]}
                    </div>
                </div>
            ),
            options: {
                filter: {
                    text: "Monitor Profile",
                    type: ComponentType.dropdown,
                    value: mp,
                },
            },
        },
        {
            title: "",
            dataIndex: "eventRuleId",
            key: "eventRuleId",
            options: {
                visible: false,
                filter: {
                    text: "Event Rule",
                    type: ComponentType.dropdown,
                    value: er,
                },
            },
        },
        DTColProps.Large({
            title: "Threshold",
            dataIndex: "threshold",
            key: "threshold",
            render: (name: string, rowData: MonitorProfiles) => getThresholdTemplate(appSetting, name, `${rowData["id"]}`),
        }),
        {
            title: "Event Batch ID",
            dataIndex: "eventBatchId",
            key: "eventBatchId",
            options: {
                visible: false,
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        },
        DTColProps.DateTime_ServerTime({
            title: <>Triggered Time (Server)</>,
            realTitle: "Triggered Time (Server)",
            dataIndex: "capturedTime",
            key: "capturedTime",
            options: {
                filter: {
                    type: ComponentType.daterange,
                    value: "",
                    text: "Triggered Time (Server)",
                    inputProps: {
                        showTime: { format: "HH:mm:ss" },
                    },
                },
            },
            defaultSortOrder: "descend",
            //sortDirections: ["ascend", "descend"],
            sorter: true,
            width: "250px",
        }),
    ];

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.HANDLE_PAGINATION_SORTING:
                setSorting(
                    FormData.sorter.column && FormData.sorter.order
                        ? `${FormData.sorter.column.key},${FormData.sorter.order === "ascend" ? "asc" : "desc"}`
                        : ""
                );
                setPagination((prev) => ({ ...prev, current: FormData.pagination.current, pageSize: FormData.pagination.pageSize }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                let filterParams: any = {};
                Object.keys(FormData)
                    .filter((x) => FormData[x] !== undefined && FormData[x].toString().length > 0)
                    .map((x) => {
                        if (x === "capturedTime") {
                            filterParams["eventTimeFrom"] = DateTimeUtil.GetOrigin(FormData[x][0]);
                            filterParams["eventTimeTo"] = DateTimeUtil.GetOrigin(FormData[x][1]);
                        } else {
                            filterParams[x] = FormData[x];
                        }
                        return x;
                    });
                setFilterParams(filterParams);
                setPagination((prev) => ({ ...prev, current: 1 }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.ROW_SELECTION_CALLBACK:
                setSelectedHistorical(FormData.selectedRows);
                setIsAccountView(true);
                break;
        }
    };

    const ticketTableColumnConfig = [
        DTColProps.Small({
            title: "Ticket",
            dataIndex: "Ticket",
            key: "Ticket",
            //sorter: (a: any, b: any) => a["Ticket"] - b["Ticket"],
            options: {
                filter: {
                    type: ComponentType.number,
                    callback: (filterValue: any, rowData: any) => rowData["Ticket"].toString().indexOf(filterValue) > -1,
                },
            },
        }),
        DTColProps.Small({
            title: "Position ID",
            dataIndex: "Position ID",
            key: "Position ID",
            sorter: (a: any, b: any) => a["Position ID"] - b["Position ID"],
            options: {
                filter: {
                    type: ComponentType.number,
                    callback: (filterValue: any, rowData: any) => rowData["Position ID"].toString().indexOf(filterValue) > -1,
                },
            },
        }),
        DTColProps.Small({
            title: "Symbol",
            dataIndex: "Symbol",
            key: "Symbol",
        }),

        DTColProps.XSmall({
            title: "Type",
            dataIndex: "Type",
            key: "Type",
        }),
        DTColProps.XSmall({
            title: "Direction",
            dataIndex: "Direction",
            key: "Direction",
        }),
        DTColProps.Small(
            {
                title: "Contract Size",
                dataIndex: "Contract Size",
                key: "Contract Size",
                sorter: (a: any, b: any) => a["Contract Size"] - b["Contract Size"],
            },
            ["text-right"]
        ),
        DTColProps.Small(
            {
                title: "Volume",
                dataIndex: "Volume",
                key: "Volume",
                sorter: (a: any, b: any) => a["Volume"] - b["Volume"],
            },
            ["text-right"]
        ),
        DTColProps.Small(
            {
                title: "Volume Closed",
                dataIndex: "Volume Closed",
                key: "Volume Closed",
                sorter: (a: any, b: any) => a["Volume Closed"] - b["Volume Closed"],
            },
            ["text-right"]
        ),
        DTColProps.DateTime({
            title: "Open Time (Server)",
            dataIndex: "Open Time",
            key: "Open Time",
            sorter: false,
            render: (text: any, record: HistoricalTrades) => DateTimeUtil.GetUTC(text, "YYYY-MM-DD HH:mm:ss"),
        }),
        DTColProps.DateTime({
            title: "Close Time (Server)",
            dataIndex: "Close Time",
            key: "Close Time",
            sorter: false,
            render: (text: any, record: HistoricalTrades) => DateTimeUtil.GetUTC(text, "YYYY-MM-DD HH:mm:ss"),
        }),
        DTColProps.SCurrency(
            {
                title: "Open Price",
                dataIndex: "Open Price",
                key: "Open Price",
                sorter: (a: any, b: any) => a["Open Price"] - b["Open Price"],
            },
            ["text-right"],
            -1
        ),
        DTColProps.SCurrency(
            {
                title: "Close Price",
                dataIndex: "Close Price",
                key: "Close Price",
                sorter: (a: any, b: any) => a["Close Price"] - b["Close Price"],
            },
            ["text-right"],
            -1
        ),
        DTColProps.SCurrency(
            {
                title: "Stop Loss",
                dataIndex: "Stop Loss",
                key: "Stop Loss",
                sorter: (a: any, b: any) => a["Stop Loss"] - b["Stop Loss"],
            },
            ["text-right"],
            -1
        ),
        DTColProps.SCurrency(
            {
                title: "Take Profit",
                dataIndex: "Take Profit",
                key: "Take Profit",
                sorter: (a: any, b: any) => a["Take Profit"] - b["Take Profit"],
            },
            ["text-right"],
            -1
        ),
        DTColProps.SCurrency(
            {
                title: "Profit",
                dataIndex: "Profit",
                key: "Profit",
                sorter: (a: any, b: any) => a["Profit"] - b["Profit"],
            },
            ["text-right"],
            -1
        ),
        DTColProps.SCurrency(
            {
                title: "Commission",
                dataIndex: "Commission",
                key: "Commission",
                sorter: (a: any, b: any) => a["Commission"] - b["Commission"],
            },
            ["text-right"],
            -1
        ),
        DTColProps.SCurrency(
            {
                title: "Swaps",
                dataIndex: "Swaps",
                key: "Swaps",
                sorter: (a: any, b: any) => a["Swaps"] - b["Swaps"],
            },
            ["text-right"],
            -1
        ),
        DTColProps.SCurrency(
            {
                title: "Taxes",
                dataIndex: "Taxes",
                key: "Taxes",
                sorter: (a: any, b: any) => a["Taxes"] - b["Taxes"],
            },
            ["text-right"],
            -1
        ),

        DTColProps.SCurrency(
            {
                title: "Fee",
                dataIndex: "Fee",
                key: "Fee",
                sorter: (a: any, b: any) => a["Fee"] - b["Fee"],
            },
            ["text-right"],
            -1
        ),
        DTColProps.SCurrency(
            {
                title: "P&L",
                dataIndex: "P&L",
                key: "P&L",
                sorter: (a: any, b: any) => a["P&L"] - b["P&L"],
            },
            ["text-right"],
            -1
        ),
        DTColProps.Middle({
            title: "Holding Duration",
            dataIndex: "Holding Duration",
            key: "Holding Duration",
            render: (text: any) => {
                try {
                    let tmp: any = typeof text === "string" ? JSON.parse(text) : text;
                    if (Array.isArray(tmp)) {
                        return (
                            <Descriptions column={1} size="small" className="tiny-description">
                                {(tmp as any[]).map((x) => (
                                    <Descriptions.Item key={`ti-${Math.random()}`}>{`${x["Holding Time"]} (${x["Ticket"]})`}</Descriptions.Item>
                                ))}
                            </Descriptions>
                        );
                    } else {
                        return tmp["Holding Time"];
                    }
                } catch (error) {}
                return text;
            },
        }),
    ];

    const getTicketList = (ticketGUID: string, record: any) => {
        setSelectedTicketInfo(record);
        setIsShowTicketModal(true);
        setTicketLoading(true);
        apiRequest(APIs.GET_MONITOR_PROFILE_TICKETS, { ticketBatchId: ticketGUID })
            .then((res: any) => {
                setTickets(res);
            })
            .catch((error) => ErrorCatchValidator(error, (err: any) => {}))
            .finally(() => setTicketLoading(false));
    };

    const getAccountHeaderInfo = (record: any) => {
        return findObjectKeys(record, ["Account ID", "Server"]) ? (
            <div className="historical-event-summary-account-info">
                <span className="title">Account: </span>
                <Link
                    target="_blank"
                    to={getNavigationUrl(
                        ACCOUNT_PROFILE_FROM_MODULE.HISTORICAL_EVENT_LIST,
                        getAccountProfileIdByObj({
                            accountId: record["Account ID"],
                            serverId: record["Server ID"],
                            fromModule: ACCOUNT_PROFILE_FROM_MODULE.HISTORICAL_EVENT_LIST,
                            viewReportId: "",
                        })
                    )}
                    style={{ color: "#0e65cc" }}
                >
                    {record["Account ID"]}
                </Link>

                <span className="title">Brand: </span>
                {record["Brand"]}
                <span className="title">Server: </span>
                {record["Server"]}
            </div>
        ) : (
            false
        );
    };

    const getAccountLevelView = (record: any) => {
        let capturedAccounts: any[] = [];
        let captureAccountTableCol: FlexiDataColumnProps[] = [];
        try {
            if (!findObjectKeys(record, ["eventDetails"])) return <></>;

            let jsonObj = typeof record["eventDetails"] === "string" ? JSON.parse(record["eventDetails"]) : record["eventDetails"];
            let tmp: any[] = [];
            if (Array.isArray(jsonObj)) {
                tmp = tmp.concat(jsonObj.map((x) => JSON.parse(x["event"])));
            } else {
                tmp.push(jsonObj);
            }

            if (tmp.length > 0) {
                tmp = tmp.reduce((accList: any[], x: any) => {
                    if (findObjectKeys(x, ["Account ID", "Server ID"])) {
                        accList.push(x);
                    }
                    if (findObjectKeys(x, ["Children"])) {
                        if (x["Children"].some((y: any) => findObjectKeys(y, ["Account ID", "Server ID"]))) {
                            accList = accList.concat(x["Children"]);
                        }
                    }
                    return accList;
                }, []);

                if (tmp.length > 0) {
                    //#region markup account listing and exclude all useless props
                    capturedAccounts = tmp.map((x) => Object.assign(objectRemoveProps(x, ["Children", "Config"]), { id: `rw-${Math.random()}` }));
                    captureAccountTableCol = objectToTableColumns(
                        tmp[0],
                        [
                            {
                                key: "Account ID",
                                render: (text: string, record: any) => {
                                    return (
                                        <Link
                                            target="_blank"
                                            to={getNavigationUrl(
                                                ACCOUNT_PROFILE_FROM_MODULE.HISTORICAL_EVENT_LIST,
                                                getAccountProfileIdByObj({
                                                    accountId: text,
                                                    serverId: record["Server ID"],
                                                    fromModule: ACCOUNT_PROFILE_FROM_MODULE.HISTORICAL_EVENT_LIST,
                                                    viewReportId: "",
                                                })
                                            )}
                                            style={{ color: "#0e65cc" }}
                                        >
                                            {text}
                                        </Link>
                                    );
                                },
                            },
                            DTColProps.XSmall({
                                title: "Tickets",
                                key: "Ticket Batch",
                                render: (text: string, record: any) => (
                                    <Button type="text" icon={<FileSearchOutlined />} onClick={() => getTicketList(text, record)} />
                                ),
                            }),
                        ],
                        [
                            "id",
                            "Config",
                            "Server ID",
                            "Brand ID",
                            "Tickets",
                            "Capture Time From",
                            "Capture Time To",
                            "Event Time From",
                            "Event Time To",
                        ]
                    );
                }
            }
        } catch (error) {}

        return (
            <>
                <div className="event-desc-container">
                    <span>Monitor Profile: </span>
                    {record["profileName"]}
                    <br />
                    <span>Event Rule: </span>
                    {record["eventRuleName"]}
                    <br />
                    <span>Event Batch ID: </span>
                    {record["eventBatchId"]}
                    <br />
                    <span className="captured-desc">{`Event captured (Server) between ${DataTableColumnRender.DateTime_ServerTime(
                        record["eventTimeFrom"]
                    )} to ${DataTableColumnRender.DateTime_ServerTime(record["eventTimeTo"])}`}</span>
                </div>
                <FlexiDataTable
                    rowKeyProperty="id"
                    title={false}
                    columns={captureAccountTableCol}
                    options={{
                        enableFilter: false,
                    }}
                    dataSource={capturedAccounts || []}
                    loading={false}
                />
            </>
        );
    };

    const getHistoricalEventList = () => {
        apiRequest(APIs.GET_HISTORICAL_EVENT_LIST, {
            limit: pagination.pageSize,
            current: pagination.current,
            ...(Object.keys(filterParams).length > 0 && { ...filterParams }),
            ...(sorting.length > 0 ? { order: sorting } : { order: "capturedTime,desc" }),
        })
            .then((data: any) => {
                if (data && data.result && data.result.length > 0) {
                    let markupData = data.result.map((x: HistoricalEvent) => {
                        x.id = `rw-${Math.random()}`;
                        return x;
                    });
                    setData(markupData);
                    setPagination((prev) => ({ ...prev, total: data.total }));
                } else {
                    setData([]);
                    setPagination((prev) => ({ ...prev, total: 0 }));
                }
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => console.log(err));
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["monitoreventrule", "monitorprofile"] })
            .then((data: any) => {
                setMonitorProfiles(
                    data.monitorProfiles
                        .filter((x: any) => !x.isDeleted)
                        .map((y: MonitorProfileList[]) => y)
                        .map((x: any) => ({ text: x.name, value: x.id }))
                );
                setEventRules(
                    data.eventRules
                        .filter((x: any) => !x.isDeleted)
                        .map((z: EventRuleList[]) => z)
                        .map((x: any) => ({ text: x.name, value: x.id }))
                );
                setRunRefetchDataList(true);

                // apiRequest(APIs.GET_PROFILE_EVENT_LIST, {})
                //     .then((data2: any) => {
                //         // setTableCols(
                //         //     getColumnsConfig(
                //         //         data.monitorProfiles.map((y: MonitorProfileList[]) => y),
                //         //         data2.map((x: MonitorProfile) => x),
                //         //         data.eventRules.map((z: EventRuleList[]) => z),
                //         //         data2
                //         //             .filter((x: MonitorProfile) => x.eventRules.length > 0)
                //         //             .reduce((list: EventRule[], item: MonitorProfile) => {
                //         //                 return list.concat(item.eventRules);
                //         //             }, [])
                //         //     )
                //         // );
                //         setRunRefetchDataList(true);
                //     })
                //     .catch((error: any) => {
                //         ErrorCatchValidator(error, (err: any) => console.log(err));
                //     });
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => console.log(err));
            })
            .finally(() => {
                setIsLoading(false);
            });
        return () => {};
    }, []);

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            getHistoricalEventList();
            setRunRefetchDataList(false);
        }
        return () => {};
    }, [runRefetchDataList]);

    return (
        <>
            <CardBox title={"Historical Event Batches"}>
                <div className={isAccountView ? "hide" : ""}>
                    <FlexiDataTable
                        rowKeyProperty="id"
                        title=""
                        columns={getColumnConfig(appSettingObj, monitorProfiles, eventRules)}
                        options={options}
                        dataSource={data ?? []}
                        callback={componentCallback}
                        loading={isLoading}
                        pagination={pagination}
                        serverSide={true}
                    />
                </div>
                <div className={`historical-account-level-container ${!isAccountView ? "hide" : ""}`}>
                    <Breadcrumb>
                        <Breadcrumb.Item
                            href="#"
                            onClick={() => {
                                setIsAccountView(false);
                                setSelectedHistorical({});
                            }}
                        >
                            <HomeOutlined />
                            <span>Historical Event List</span>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>Account Level View</Breadcrumb.Item>
                    </Breadcrumb>
                    <div>{getAccountLevelView(selectedHistorical)}</div>
                </div>
            </CardBox>

            <Modal
                width="90%"
                destroyOnClose
                maskClosable={false}
                title={"Tickets"}
                open={isShowTicketModal}
                cancelText={"Close"}
                okButtonProps={{
                    style: {
                        display: "none",
                    },
                }}
                onCancel={() => setIsShowTicketModal(false)}
            >
                {ticketLoading ? (
                    <div className="loading-container">
                        <LoadingComponent tip="Loading..." />
                    </div>
                ) : tickets.length === 0 ? (
                    <EmptyData />
                ) : (
                    <>
                        <FlexiDataTable
                            rowKeyProperty="Ticket"
                            title={getAccountHeaderInfo(selectedTicketInfo)}
                            columns={ticketTableColumnConfig}
                            options={{ enableFilter: false }}
                            dataSource={tickets}
                            loading={false}
                            callback={componentCallback}
                        />
                    </>
                )}
            </Modal>
        </>
    );
};

export default HistoricalEventPage;
