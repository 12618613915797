import { useCallback, useEffect, useMemo, useState } from "react";
import FlexiDataTable from "../../../../../components/FlexiDataTable";
import {
    FlexiDataTableOptionsProps,
    FlexiDataTableCallbackProps,
    KeyValuePair,
    GroupTransferToolUserInfoProps,
    FlexiDataColumnProps,
} from "../../../../../constants/type";
import { APIs } from "../../../../../services/apiConfig";
import { plainAxiosInstance } from "../../../../../services/axiosSetup";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "../../../../../constants";
import { REQUIRED_FIELD } from "../../../../../constants/errorMessage";
import { defaultIfEmptyOrNull, isEmptyOrNull } from "../../../../../utils/string";
import { Form, message, Modal, Segmented } from "antd";
import { ErrorCatchValidator, ErrorMessageHandler } from "../../../../../utils/Common";
import { FormComponent } from "../../../../../components/FormComponent";
import GroupTransferModal, { GroupTransferModalCallbackKey } from "./grouptransferModal";

export interface GroupTransferPageProps {
    reasonOptions: KeyValuePair[];
}

interface MarkupProps {
    Success: GroupTransferToolUserInfoProps[];
    Fail: GroupTransferToolUserInfoProps[];
}

type DisplayType = "preview" | "execution" | "";

const GroupTransferPage = (props: GroupTransferPageProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [serverOpt, setServerOpt] = useState<KeyValuePair[]>([]);
    const [data, setData] = useState<GroupTransferToolUserInfoProps[]>([]);
    const [transferGroupsOpt, setTransferGroupsOpt] = useState<KeyValuePair[]>([]);
    const [currentTab, setCurrentTab] = useState<string>("Success");
    const [selectedKey, setSelectedKey] = useState<string[]>([]);
    const [displayType, setDisplayType] = useState<DisplayType>("");
    const [filterParams, setFilterParams] = useState<any>({});
    const [isExecutionModalVisible, setIsExecutionModalVisible] = useState<boolean>(false);
    const [executeForm] = Form.useForm();

    const markupData: MarkupProps = useMemo(() => {
        return {
            Success: data.filter((x: GroupTransferToolUserInfoProps) => x.status !== "Failed"),
            Fail: data.filter((x: GroupTransferToolUserInfoProps) => x.status === "Failed"),
        };
    }, [data]);

    const columns: FlexiDataColumnProps[] = useMemo(
        () => [
            {
                title: "Server",
                dataIndex: "server",
                key: "server",
                options: {
                    visible: false,
                    filter: {
                        type: ComponentType.dropdown,
                        value: serverOpt,
                        rules: [{ required: true, message: REQUIRED_FIELD }],
                        callback: () => true,
                    },
                },
            },
            {
                title: "Login",
                dataIndex: "login",
                key: "login",
                options: {
                    filter: {
                        type: ComponentType.text,
                        value: "",
                        rules: [{ required: true, message: REQUIRED_FIELD }],
                        callback: () => true,
                    },
                },
            },
            {
                title: "From Group",
                dataIndex: "fromGroup",
                key: "fromGroup",
            },
            {
                title: "To Group",
                dataIndex: "toGroup",
                key: "toGroup",
                render: (text: string, record: GroupTransferToolUserInfoProps) => {
                    if (
                        (displayType === "execution" && record.status === "OK" && record.toGroup !== "") ||
                        (displayType === "preview" && record.status === "Failed")
                    )
                        return record.toGroup;

                    return (
                        <FormComponent label={""} name={`ddp_${record.login}`} extra={{ type: ComponentType.dropdown, value: transferGroupsOpt }} />
                    );
                },
            },
            {
                title: "Operation Time",
                dataIndex: "operationTime",
                key: "operationTime",
            },
            {
                title: "Status",
                dataIndex: "status",
                key: "status",
            },
            {
                title: "Execute Msg",
                dataIndex: "message",
                key: "message",
            },
        ],
        [serverOpt, transferGroupsOpt, displayType]
    );

    const options: FlexiDataTableOptionsProps = useMemo(
        () => ({
            autoFilter: false,
            ...(displayType === "preview" && {
                enableRowSelection: true,
                hideRowSelectionsSummary: true,
                ...(selectedKey.length > 0 && {
                    extraButtons: [{ text: "Apply All", value: "applyall", type: "primary" }],
                }),
            }),
        }),
        [selectedKey, displayType]
    );

    const componentCallback: FlexiDataTableCallbackProps = (type: number, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                let tmpFP = Object.keys(FormData)
                    .filter(x => FormData[x] && FormData[x].toString().length > 0)
                    .reduce((finalObj: any, x: string) => {
                        if (x === "login") {
                            finalObj["logins"] = FormData[x]
                                .split(/\r?\n/)
                                .filter((x: string) => !isEmptyOrNull(x))
                                .filter((x: string, idx: number, self: string[]) => self.indexOf(x) === idx);
                        } else {
                            finalObj[x] = FormData[x];
                        }
                        return finalObj;
                    }, {});
                setData([]);
                setSelectedKey([]);
                setTransferGroupsOpt([]);
                setCurrentTab("Success");
                setDisplayType("preview");
                setFilterParams(tmpFP);
                getPreviewGroupTransferList(tmpFP);
                break;
            case CALLBACK_KEY.ROW_SELECTION_CALLBACK:
                setSelectedKey(FormData.selectedRowKeys);
                break;
            case CALLBACK_KEY.OTHERS:
                if (FormData === "applyall") {
                    setIsExecutionModalVisible(true);
                }
                break;
            default:
                break;
        }
    };

    const executeTransferGroup = useCallback(
        (values: any) => {
            Modal.confirm({
                title: "Group Transfer Confirmation",
                content: "Are you sure you want to do group transfer?",
                onOk: () => {
                    setIsLoading(true);
                    let toGroupForm = executeForm.getFieldsValue();
                    const formData = new FormData();
                    formData.append("server", filterParams["server"]);
                    formData.append(
                        "groupTransferList",
                        JSON.stringify(
                            data
                                .filter((x: GroupTransferToolUserInfoProps) => selectedKey.includes(x.keyId as string))
                                .map((x: GroupTransferToolUserInfoProps) => ({
                                    ...x,
                                    ...(toGroupForm?.hasOwnProperty(`ddp_${x.login}`) && { toGroup: toGroupForm[`ddp_${x.login}`] }),
                                }))
                        )
                    );
                    formData.append("changeReason", values["changeReason"]);
                    formData.append("changeReasonFrontDesk", values["changeReasonFrontDesk"]);

                    plainAxiosInstance
                        .post(APIs.RISK_TOOL.GET_GROUP_TRANSFER_TOOL_EXECUTE_TRANSFER, formData)
                        .then((res: any) => {
                            if (res.data.value.result.length > 0) {
                                setData(res.data.value.result);
                                message.success("Group transfer has been executed successfully.", 5);
                            }
                        })
                        .catch((error: any) => ErrorCatchValidator(error, (err: any) => message.error(err)))
                        .finally(() => setIsLoading(false));
                },
            });
        },
        [filterParams, data, selectedKey]
    );

    const getPreviewGroupTransferList = (values: any) => {
        if (!(values?.hasOwnProperty("logins") && values?.hasOwnProperty("server"))) return;

        setIsLoading(true);
        const formData = new FormData();
        formData.append("server", values["server"]);
        formData.append("logins", values["logins"]);
        plainAxiosInstance
            .post(APIs.RISK_TOOL.GET_GROUP_TRANSFER_TOOL_PREVIEW, formData)
            .then((res: any) => {
                if (res.data.msg !== "Failed") {
                    let dataList = res.data.value.allUserInfo.map((x: GroupTransferToolUserInfoProps) => ({
                        ...x,
                        server: defaultIfEmptyOrNull(x.server, ""),
                        keyId: `${x.server}-${x.login}-${x.operationTime}`,
                    }));
                    setTransferGroupsOpt(res.data.value.allGroup.map((x: string) => ({ text: x, value: x })));
                    setData(dataList);
                    executeForm.setFieldsValue(
                        dataList
                            .filter((x: GroupTransferToolUserInfoProps) => x.status !== "Failed")
                            .reduce((finalObj: any, x: GroupTransferToolUserInfoProps) => {
                                finalObj[`ddp_${x.login}`] = x.toGroup;
                                return finalObj;
                            }, {})
                    );
                } else {
                    message.error(res.data.customRespMsg, 5);
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("group transfer tool record", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => {
                setIsLoading(false);
            });
    };

    const getConfig = () => {
        plainAxiosInstance
            .get(APIs.RC_ROOT.GET_SERVERS)
            .then((res: any) => {
                if (res.status === 200 && res.data.length > 0) {
                    setServerOpt(res.data.map((x: any) => ({ text: x.serverName, value: x.serverId })));
                }
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        getConfig();
    }, []);

    return (
        <>
            <div className="group-transfer-container">
                <FlexiDataTable
                    bordered
                    rowKeyProperty="keyId"
                    title={
                        markupData.Success.length > 0 || markupData.Fail.length > 0 ? (
                            <div className="table-header-panel">
                                <span>Record Status: </span>
                                <Segmented value={currentTab} options={["Success", "Fail"]} onChange={(activeKey: any) => setCurrentTab(activeKey)} />
                            </div>
                        ) : (
                            <></>
                        )
                    }
                    columns={columns}
                    options={options}
                    dataSource={(markupData as any)[currentTab]}
                    callback={componentCallback}
                    loading={isLoading}
                    tableFormInstance={executeForm}
                />
            </div>
            <GroupTransferModal
                isModalVisible={isExecutionModalVisible}
                callback={(type: number, data: any) => {
                    switch (type) {
                        case GroupTransferModalCallbackKey.FormSubmit:
                            executeTransferGroup(data);
                            setIsExecutionModalVisible(false);
                            break;
                        case GroupTransferModalCallbackKey.Close:
                            setIsExecutionModalVisible(false);
                            break;
                        default:
                            break;
                    }
                }}
                reasonOptions={props.reasonOptions}
            />
        </>
    );
};

export default GroupTransferPage;
