import { SUCCESS_FAILED } from "@/constants";
import { KeyValuePair } from "@/constants/type";
import useDebounce from "@/hooks/useDebounce";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import { Button, Checkbox, Divider, Empty, Form, Input, Radio, RadioChangeEvent, Spin } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useEffect, useState } from "react";
import { CompareItem } from "../index";

type FilterBySymbolProps = {
    servers: KeyValuePair[];
    onAddCompareItem: (items: CompareItem[]) => void;
};
const FilterBySymbol = ({ servers, onAddCompareItem }: FilterBySymbolProps) => {
    const [dSymbolKeyword, symbolKeyword, setSymbolKeyword] = useDebounce<string>("");
    const [bridgeCheckList, setBridgeCheckList] = useState<string[]>([]);
    const [bridgeServerList, setBridgeServerList] = useState<any[]>([]);
    const [serversBySymbolKeywordList, setServersBySymbolKeywordList] = useState<KeyValuePair[]>([]);
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);

    const [checkedBridge, setCheckedBridge] = useState<string>("");
    const [selectedServers, setSelectedServers] = useState<string[]>([]);

    const { data: bridgeListRes, error: bridgeListErr } = useQuery({
        queryKey: [APIs.RC_LP_PRICE_COMPARE.GET_BRIDGE_MAIN_SERVER_SETTING],
        queryFn: () => plainAxiosInstance.get(`${APIs.RC_LP_PRICE_COMPARE.GET_BRIDGE_MAIN_SERVER_SETTING}`),
        staleTime: 60 * 1000 * 5,
    });

    const {
        data: serversBySymbolRes,
        isLoading,
        error: serversBySymbolErr,
        isFetching,
    } = useQuery({
        queryKey: [APIs.RC_LP_PRICE_COMPARE.GET_SERVER_LIST_BY_SYMBOL, dSymbolKeyword],
        queryFn: () => plainAxiosInstance.get(`${APIs.RC_LP_PRICE_COMPARE.GET_SERVER_LIST_BY_SYMBOL}/${dSymbolKeyword}`),
        staleTime: 60 * 1000 * 5,
        enabled: dSymbolKeyword !== "",
    });

    useEffect(() => {
        const res = bridgeListRes?.data;
        // const res = dummy
        if (res) {
            const uniqueBridge = [...new Set<string>(res.map((item: any) => item.bridgeName as string))];
            setBridgeCheckList(uniqueBridge);
            setBridgeServerList(res);
        }
    }, [bridgeListRes?.data]);

    useEffect(() => {
        if (bridgeListErr) {
            ErrorCatchValidator(bridgeListErr, (err: any) => ErrorMessageHandler("Bridge Server Settings", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
        }
    }, [bridgeListErr]);

    useEffect(() => {
        const res = serversBySymbolRes?.data;
        if (res) {
            setServersBySymbolKeywordList(
                res.map((item: any) => ({
                    text: item.mainServerId,
                    value: item.serverId,
                }))
            );
        }
    }, [serversBySymbolRes?.data]);

    useEffect(() => {
        if (serversBySymbolErr) {
            ErrorCatchValidator(serversBySymbolErr, (err: any) => ErrorMessageHandler("Servers by Symbol", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
        }
    }, [serversBySymbolErr]);

    function onGroupChange(e: RadioChangeEvent) {
        const val = e.target.value;
        setCheckedBridge(val);
        setIndeterminate(true);
        setCheckAll(false);

        const serverLabel = bridgeServerList
            .filter(s => s.bridgeName === e.target.value)
            .map(function (item) {
                return item["serverUno"];
            });
        const final = servers.filter(s => serverLabel.includes(s.value)).map(x => x.text);
        setSelectedServers(final);
    }

    function onCheckboxChange(checkedValue: Array<any>) {
        setCheckedBridge("");
        setSelectedServers(checkedValue);
        setIndeterminate(!!checkedValue.length && checkedValue.length < serversBySymbolKeywordList.length);
        setCheckAll(checkedValue.length === serversBySymbolKeywordList.length);
    }

    function onCheckAllChange(e: CheckboxChangeEvent) {
        setCheckedBridge("");
        setIndeterminate(false);
        setCheckAll(e.target.checked);
        setSelectedServers(e.target.checked ? serversBySymbolKeywordList.map(x => x.text) : []);
    }

    function onAddToList() {
        const items = selectedServers.map(item => ({
            compareServerName: item,
            compareServer: serversBySymbolKeywordList.find(s => s.text === item)?.value as number,
            compareSymbol: symbolKeyword,
        }));
        onAddCompareItem(items);
    }

    return (
        <Form layout={"vertical"}>
            <div className="symbol-input-wrapper">
                <Form.Item label="Symbol">
                    <Input
                        style={{ width: 150 }}
                        value={symbolKeyword}
                        onChange={e => {
                            setSymbolKeyword(e.target.value.toUpperCase());
                            if (e.target.value === "") {
                                setServersBySymbolKeywordList([]);
                            }
                        }}
                    />
                </Form.Item>
                <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                    Check all
                </Checkbox>
            </div>
            <Radio.Group onChange={onGroupChange} value={checkedBridge} options={bridgeCheckList} />

            <Divider />
            <div className="servers-checkbox">
                <Spin spinning={isLoading && isFetching}>
                    {serversBySymbolKeywordList.length === 0 ? (
                        <Empty 
                            description={symbolKeyword === "" ? 
                                "Search servers by symbol" : !isLoading 
                                ? "No servers found" : null} 
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                        />
                    ) : (
                        <Checkbox.Group options={serversBySymbolKeywordList.map(x => x.text)} value={selectedServers} onChange={onCheckboxChange} />
                    )}
                </Spin>
            </div>
            <Form.Item>
                <Button onClick={onAddToList} icon={<PlusCircleOutlined />} disabled={selectedServers.length === 0}>
                    Add to Overview
                </Button>
            </Form.Item>
        </Form>
    );
};

export default FilterBySymbol;