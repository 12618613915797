import { useEffect, useState } from "react";
import { apiRequest } from "../../../services/apiConfig";
import { APIs } from "../../../services/apis";
import TradeMonitorProfileComponent from "../../RealTime/Trade/TradeMonitorProfileComponent";
import { ErrorCatchValidator } from "../../../utils/Common";

interface EventSummaryProfileProps {}

const EventSummaryProfile = (props: EventSummaryProfileProps) => {
    const [profileId, setProfileId] = useState<string>("");

    useEffect(() => {
        apiRequest(APIs.GET_MONITOR_PROFILE, {})
            .then((res: any) => {
                if (res.length > 0) {
                    let eventSummaryIdx: number = (res as any[]).findIndex((x: any) => x.isEventSummaryProfile);
                    if (eventSummaryIdx > -1) {
                        setProfileId(`${res[eventSummaryIdx].id}`);
                    }
                }
            })
            .catch((error) => ErrorCatchValidator(error, (err: any) => console.log("Failed to load event summary profile: ", err)));
        return () => {};
    }, []);

    return <>{profileId.length > 0 && <TradeMonitorProfileComponent currProfileId={profileId} hideBreadcrum isDashboardView={true} />}</>;
};

export default EventSummaryProfile;
