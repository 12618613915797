import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CardBox from "../../../../components/Common/CardBox";
import FlexiDataTable from "../../../../components/FlexiDataTable";
import { ComponentType, CALLBACK_KEY } from "../../../../constants";
import { FlexiDataTableOptionsProps, FlexiDataTableCallbackProps, MetricType2 } from "../../../../constants/type";
import { apiRequest } from "../../../../services/apiConfig";
import { APIs } from "../../../../services/apis";
import { ErrorCatchValidator } from "../../../../utils/Common";

const MetricMapping = () => {
    const [data, setData] = useState<MetricType2[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    let navigate = useNavigate();

    const getMetricMappingList = () => {
        setIsLoading(true);
        apiRequest(APIs.GET_METRIC_CONFIGURATION_LIST, {})
            .then((res: any) => {
                if (res) {
                    setData(res.metricType as MetricType2[]);
                }
            })
            .catch((error) => ErrorCatchValidator(error, (err: any) => {}))
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        getMetricMappingList();
        return () => {};
    }, []);

    const columns: any[] = [
        {
            title: "Metric Type",
            dataIndex: "metricType",
            key: "metricType",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        },
    ];

    const options: FlexiDataTableOptionsProps = {
        edit: true,
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, data) => {
        switch (type) {
            case CALLBACK_KEY.DO_EDIT:
                navigate("/siteadmin/appconfig/metricmapping/edit", { state: { data: data, action: "edit" } });
                break;
            default:
                break;
        }
    };

    return (
        <>
            <CardBox title={"Metric Mapping"}>
                <FlexiDataTable
                    rowKeyProperty="metricTypeId"
                    title=""
                    columns={columns}
                    options={options}
                    dataSource={data || []}
                    callback={componentCallback}
                    loading={isLoading}
                />
            </CardBox>
        </>
    );
};
// const MetricConfiguration = (props: MetricConfigurationProps) => {
//     let navigate = useNavigate();

//     const [refreshGroupData, setRefreshGroupData] = useState<boolean>(true);
//     const [isLoading, setIsLoading] = useState<boolean>(true);
//     const [showAddGroupModal, setShowAddGroupModal] = useState<boolean>(false);
//     const [groups, setGroups] = useState<BoardGroupProps[]>([]);
//     const [groupForm] = Form.useForm();

//     const getGroupInformation = () => {
//         apiRequest(APIs.GET_METRIC_INFO, {}, "GET")
//             .then((res: any) => {
//                 let groupList = res.categories.map((x: BoardGroupProps) => ({
//                     ...x,
//                     items: res.metric
//                         .filter((y: any) => y.categories.indexOf(x.id) > -1)
//                         .map((y: any) => ({ id: y.metricId, text: y.displayName, description: y.description })),
//                 }));
//                 setGroups(groupList);
//             })
//             .catch((err) => ErrorCatchValidator(error, (err: any) => message.error("Error during retrieve group information. Please refresh the page and try again.")))
//             .finally(() => setIsLoading(false));
//     };

//     useEffect(() => {
//         if (refreshGroupData) {
//             getGroupInformation();
//             setRefreshGroupData(false);
//         }
//     }, []);

//     return (
//         <SitePageHeader
//             title={"Metric Configuration"}
//             routes={[
//                 {
//                     path: "/siteadmin/appconfig",
//                     breadcrumbName: "App Configuration",
//                     icon: <HomeOutlined />,
//                 },
//                 {
//                     path: "",
//                     breadcrumbName: "Metric Configuration",
//                 },
//             ]}
//             onBack={() => navigate("/siteadmin/appconfig")}
//             extraProps={{
//                 extra: (
//                     <Button
//                         type="primary"
//                         icon={<PlusOutlined />}
//                         onClick={() => {
//                             groupForm.setFieldsValue({ id: `c-gln${getGUID()}`, name: "", description: "" });
//                             setShowAddGroupModal(true);
//                         }}
//                     >
//                         Create New Group
//                     </Button>
//                 ),
//             }}
//         >
//             {isLoading ? (
//                 <div className="loading-container">
//                     <LoadingComponent tip="Loading..." />
//                 </div>
//             ) : (
//                 <div>
//                     <BoardList data={groups} />
//                 </div>
//             )}

//             <Modal
//                 width="700px"
//                 destroyOnClose
//                 maskClosable={false}
//                 title={"Add New Group"}
//                 open={showAddGroupModal}
//                 onOk={() => {
//                     groupForm.validateFields().then((values) => {
//                         let currentGroupList = [...groups];
//                         currentGroupList.push({ ...values, orderSeq: 0, items: [] });
//                         setGroups(currentGroupList);
//                         setShowAddGroupModal(false);
//                     });
//                 }}
//                 onCancel={() => {
//                     groupForm.resetFields();
//                     setShowAddGroupModal(false);
//                 }}
//             >
//                 <Form labelCol={{ span: 7 }} wrapperCol={{ span: 14 }} form={groupForm} layout="horizontal">
//                     <Row>
//                         <Col span={24}>
//                             <FormComponent
//                                 label=""
//                                 name="id"
//                                 extra={{
//                                     type: ComponentType.hidden,
//                                     value: "",
//                                 }}
//                             />
//                             <FormComponent
//                                 label="Name"
//                                 name="name"
//                                 extra={{
//                                     type: ComponentType.text,
//                                     value: "",
//                                     rules: [{ required: true, message: REQUIRED_FIELD }],
//                                 }}
//                             />
//                         </Col>
//                     </Row>
//                     <Row>
//                         <Col span={24}>
//                             <FormComponent
//                                 label="Description"
//                                 name="description"
//                                 extra={{
//                                     type: ComponentType.text,
//                                     value: "",
//                                 }}
//                             />
//                         </Col>
//                     </Row>
//                 </Form>
//             </Modal>
//         </SitePageHeader>
//     );
// };

export default MetricMapping;
