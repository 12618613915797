import FlexiDataTable from "../../../components/FlexiDataTable";
import { useEffect, useState } from "react";
import { ServersList, FlexiDataTableCallbackProps, HightlightExpression } from "../../../constants/type";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "../../../constants";
import TinyAreaChart from "../../../components/Charts/SubComponents/TinyArea";
import { DTColProps, DataTableColumnRender, ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";
import { apiRequest } from "../../../services/apiConfig";
import { APIs } from "../../../services/apis";
import moment from "moment";
import { Link } from "react-router-dom";
import { getNavigationUrl, ACCOUNT_PROFILE_FROM_MODULE, getAccountProfileId } from "../../Analysis/AccountProfileHelper";
import DecayAnalysisAccountSummary from "./Components/DecayAnalysisAccountSummary";
import { DecayAnalysisAccountStats } from "../PMI/eventSnapshot";
import LoadingComponent from "../../../components/Loading";

export interface TicketViewProps {
    servers: ServersList[];
    data?: SelectedAccountInfoProps;
    refreshRate: number;
    onRefreshRateCallback: (seconds: number) => void;
    onModalCallback: (data: any) => void;
}

export interface SelectedAccountInfoProps {
    accountId: string;
    serverId: string;
    isGoldSymbolOnly?: boolean;
}

export interface TimeSeriesProps {
    decaySecond: number;
    profit: number;
    dpm: number;
    decayTime: string;
}

const TicketView = (props: TicketViewProps) => {
    const [data, setData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [accountData, setAccountData] = useState<DecayAnalysisAccountStats | undefined>(undefined);

    const columnsConfig = () => {
        const hightExpression: HightlightExpression = (value: number) => value > 0;
        return [
            DTColProps.Small({
                title: "Ticket",
                dataIndex: "ticket",
                key: "ticket",
                options: {
                    filter: {
                        type: ComponentType.number,
                    },
                },
            }),
            DTColProps.XSmall({
                title: "Position ID",
                dataIndex: "positionId",
                key: "positionId",
            }),
            DTColProps.XSmall({
                title: "Symbol",
                dataIndex: "symbol",
                key: "symbol",
            }),

            DTColProps.DateTime_ServerTime({
                title: "Open Time (Server)",
                dataIndex: "openTime",
                key: "openTime",
                sorter: (a: any, b: any) => (moment(a.openTime) > moment(b.openTime) ? -1 : 1),
                render: (value: string) => (value ? DataTableColumnRender.DateTime_ServerTime(value, "YYYY-MM-DD HH:mm:ss.SSS") : ""),
                options: {
                    filter: {
                        type: ComponentType.daterange,
                        value: "",
                        inputProps: {
                            showTime: { format: "HH:mm:ss" },
                        },
                    },
                },
            }),
            DTColProps.Small({
                width: "5vw",
                title: "Contract Size",
                dataIndex: "contractSize",
                key: "contractSize",
                sorter: (a: any, b: any) => a.contractSize - b.contractSize,
            }),
            DTColProps.XSmall({
                width: "3.8vw",
                title: "Lot Size",
                dataIndex: "lotSize",
                key: "lotSize",
                sorter: (a: any, b: any) => a.lotSize - b.lotSize,
            }),
            DTColProps.XSmall({
                width: "3.2vw",
                title: "Action",
                dataIndex: "action",
                key: "action",
            }),
            DTColProps.XSmall({
                width: "3vw",
                title: "Entry",
                dataIndex: "entry",
                key: "entry",
            }),
            {
                title: "DPM",
                children: [
                    // DTColProps.SCurrency(
                    //     {
                    //         title: "-30s",
                    //         dataIndex: "dpmN30",
                    //         key: "dpmN30",
                    //         width: "3.5vw",
                    //         sorter: (a: any, b: any) => a.dpmN30 - b.dpmN30,
                    //     },
                    //     [],
                    //     -1,
                    //     hightExpression
                    // ),
                    // DTColProps.SCurrency(
                    //     {
                    //         title: "-10s",
                    //         dataIndex: "dpmN10",
                    //         key: "dpmN10",
                    //         width: "3.5vw",
                    //         sorter: (a: any, b: any) => a.dpmN10 - b.dpmN10,
                    //     },
                    //     [],
                    //     -1,
                    //     hightExpression
                    // ),
                    // DTColProps.SCurrency(
                    //     {
                    //         title: "-5s",
                    //         dataIndex: "dpmN5",
                    //         key: "dpmN5",
                    //         width: "3.5vw",
                    //         sorter: (a: any, b: any) => a.dpmN5 - b.dpmN5,
                    //     },
                    //     [],
                    //     -1,
                    //     hightExpression
                    // ),
                    // DTColProps.SCurrency(
                    //     {
                    //         title: "-1s",
                    //         dataIndex: "dpmN1",
                    //         key: "dpmN1",
                    //         width: "3.5vw",
                    //         sorter: (a: any, b: any) => a.dpmN1 - b.dpmN1,
                    //     },
                    //     [],
                    //     -1,
                    //     hightExpression
                    // ),
                    // DTColProps.SCurrency(
                    //     {
                    //         title: "0s",
                    //         dataIndex: "dpm0",
                    //         key: "dpm0",
                    //         width: "3.5vw",
                    //         sorter: (a: any, b: any) => a.dpm0 - b.dpm0,
                    //     },
                    //     [],
                    //     -1,
                    //     hightExpression
                    // ),
                    // DTColProps.SCurrency(
                    //     {
                    //         title: "1s",
                    //         dataIndex: "dpm1",
                    //         key: "dpm1",
                    //         width: "3.5vw",
                    //         sorter: (a: any, b: any) => a.dpm1 - b.dpm1,
                    //     },
                    //     [],
                    //     -1,
                    //     hightExpression
                    // ),
                    // DTColProps.SCurrency(
                    //     {
                    //         title: "5s",
                    //         dataIndex: "dpm5",
                    //         key: "dpm5",
                    //         width: "3.5vw",
                    //         sorter: (a: any, b: any) => a.dpm5 - b.dpm5,
                    //     },
                    //     [],
                    //     -1,
                    //     hightExpression
                    // ),
                    // DTColProps.SCurrency(
                    //     {
                    //         title: "10s",
                    //         dataIndex: "dpm10",
                    //         key: "dpm10",
                    //         width: "3.5vw",
                    //         sorter: (a: any, b: any) => a.dpm10 - b.dpm10,
                    //     },
                    //     [],
                    //     -1,
                    //     hightExpression
                    // ),
                    // DTColProps.SCurrency(
                    //     {
                    //         title: "30s",
                    //         dataIndex: "dpm30",
                    //         key: "dpm30",
                    //         width: "3.5vw",
                    //         sorter: (a: any, b: any) => a.dpm30 - b.dpm30,
                    //     },
                    //     [],
                    //     -1,
                    //     hightExpression
                    // ),
                    DTColProps.SCurrency(
                        {
                            title: "1min",
                            dataIndex: "dpm60",
                            key: "dpm60",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.dpm60 - b.dpm60,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "2min",
                            dataIndex: "dpm120",
                            key: "dpm120",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.dpm120 - b.dpm120,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "3min",
                            dataIndex: "dpm180",
                            key: "dpm180",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.dpm180 - b.dpm180,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "4min",
                            dataIndex: "dpm240",
                            key: "dpm240",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.dpm240 - b.dpm240,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "5min",
                            dataIndex: "dpm300",
                            key: "dpm300",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.dpm300 - b.dpm300,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "6min",
                            dataIndex: "dpm360",
                            key: "dpm360",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.dpm360 - b.dpm360,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "7min",
                            dataIndex: "dpm420",
                            key: "dpm420",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.dpm420 - b.dpm420,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "8min",
                            dataIndex: "dpm480",
                            key: "dpm480",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.dpm480 - b.dpm480,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "9min",
                            dataIndex: "dpm540",
                            key: "dpm540",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.dpm540 - b.dpm540,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "10min",
                            dataIndex: "dpm600",
                            key: "dpm600",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.dpm600 - b.dpm600,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "11min",
                            dataIndex: "dpm660",
                            key: "dpm660",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.dpm660 - b.dpm660,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "12min",
                            dataIndex: "dpm720",
                            key: "dpm720",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.dpm720 - b.dpm720,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "13min",
                            dataIndex: "dpm780",
                            key: "dpm780",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.dpm780 - b.dpm780,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "14min",
                            dataIndex: "dpm840",
                            key: "dpm840",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.dpm840 - b.dpm840,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "15min",
                            dataIndex: "dpm900",
                            key: "dpm900",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.dpm900 - b.dpm900,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "16min",
                            dataIndex: "dpm960",
                            key: "dpm960",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.dpm960 - b.dpm960,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "17min",
                            dataIndex: "dpm1020",
                            key: "dpm1020",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.dpm1020 - b.dpm1020,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "18min",
                            dataIndex: "dpm1080",
                            key: "dpm1080",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.dpm1080 - b.dpm1080,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "19min",
                            dataIndex: "dpm1140",
                            key: "dpm1140",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.dpm1140 - b.dpm1140,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "20min",
                            dataIndex: "dpm1200",
                            key: "dpm1200",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.dpm1200 - b.dpm1200,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                ],
            },
            {
                title: "Profit",
                children: [
                    // DTColProps.SCurrency(
                    //     {
                    //         title: "-30s",
                    //         dataIndex: "profitN30",
                    //         key: "profitN30",
                    //         width: "5.5vw",
                    //         sorter: (a: any, b: any) => a.profitN30 - b.profitN30,
                    //     },
                    //     [],
                    //     -1,
                    //     hightExpression
                    // ),
                    // DTColProps.SCurrency(
                    //     {
                    //         title: "-10s",
                    //         dataIndex: "profitN10",
                    //         key: "profitN10",
                    //         width: "5.5vw",
                    //         sorter: (a: any, b: any) => a.profitN10 - b.profitN10,
                    //     },
                    //     [],
                    //     -1,
                    //     hightExpression
                    // ),
                    // DTColProps.SCurrency(
                    //     {
                    //         title: "-5s",
                    //         dataIndex: "profitN5",
                    //         key: "profitN5",
                    //         width: "5.5vw",
                    //         sorter: (a: any, b: any) => a.profitN5 - b.profitN5,
                    //     },
                    //     [],
                    //     -1,
                    //     hightExpression
                    // ),
                    // DTColProps.SCurrency(
                    //     {
                    //         title: "-1s",
                    //         dataIndex: "profitN1",
                    //         key: "profitN1",
                    //         width: "5.5vw",
                    //         sorter: (a: any, b: any) => a.profitN1 - b.profitN1,
                    //     },
                    //     [],
                    //     -1,
                    //     hightExpression
                    // ),
                    // DTColProps.SCurrency(
                    //     {
                    //         title: "0s",
                    //         dataIndex: "profit0",
                    //         key: "profit0",
                    //         width: "5.5vw",
                    //         sorter: (a: any, b: any) => a.profit0 - b.profit0,
                    //     },
                    //     [],
                    //     -1,
                    //     hightExpression
                    // ),
                    // DTColProps.SCurrency(
                    //     {
                    //         title: "1s",
                    //         dataIndex: "profit1",
                    //         key: "profit1",
                    //         width: "5.5vw",
                    //         sorter: (a: any, b: any) => a.profit1 - b.profit1,
                    //     },
                    //     [],
                    //     -1,
                    //     hightExpression
                    // ),
                    // DTColProps.SCurrency(
                    //     {
                    //         title: "5s",
                    //         dataIndex: "profit5",
                    //         key: "profit5",
                    //         width: "5.5vw",
                    //         sorter: (a: any, b: any) => a.profit5 - b.profit5,
                    //     },
                    //     [],
                    //     -1,
                    //     hightExpression
                    // ),
                    // DTColProps.SCurrency(
                    //     {
                    //         title: "10s",
                    //         dataIndex: "profit10",
                    //         key: "profit10",
                    //         width: "5.5vw",
                    //         sorter: (a: any, b: any) => a.profit10 - b.profit10,
                    //     },
                    //     [],
                    //     -1,
                    //     hightExpression
                    // ),
                    // DTColProps.SCurrency(
                    //     {
                    //         title: "30s",
                    //         dataIndex: "profit30",
                    //         key: "profit30",
                    //         width: "5.5vw",
                    //         sorter: (a: any, b: any) => a.profit30 - b.profit30,
                    //     },
                    //     [],
                    //     -1,
                    //     hightExpression
                    // ),
                    DTColProps.SCurrency(
                        {
                            title: "1min",
                            dataIndex: "profit60",
                            key: "profit60",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.profit60 - b.profit60,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "2min",
                            dataIndex: "profit120",
                            key: "profit120",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.profit120 - b.profit120,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "3min",
                            dataIndex: "profit180",
                            key: "profit180",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.profit180 - b.profit180,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "4min",
                            dataIndex: "profit240",
                            key: "profit240",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.profit240 - b.profit240,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "5min",
                            dataIndex: "profit300",
                            key: "profit300",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.profit300 - b.profit300,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "6min",
                            dataIndex: "profit360",
                            key: "profit360",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.profit360 - b.profit360,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "7min",
                            dataIndex: "profit420",
                            key: "profit420",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.profit420 - b.profit420,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "8min",
                            dataIndex: "profit480",
                            key: "profit480",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.profit480 - b.profit480,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "9min",
                            dataIndex: "profit540",
                            key: "profit540",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.profit540 - b.profit540,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "10min",
                            dataIndex: "profit600",
                            key: "profit600",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.profit600 - b.profit600,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "11min",
                            dataIndex: "profit660",
                            key: "profit660",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.profit660 - b.profit660,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "12min",
                            dataIndex: "profit720",
                            key: "profit720",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.profit720 - b.profit720,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "13min",
                            dataIndex: "profit780",
                            key: "profit780",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.profit780 - b.profit780,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "14min",
                            dataIndex: "profit840",
                            key: "profit840",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.profit840 - b.profit840,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "15min",
                            dataIndex: "profit900",
                            key: "profit900",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.profit900 - b.profit900,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "16min",
                            dataIndex: "profit960",
                            key: "profit960",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.profit960 - b.profit960,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "17min",
                            dataIndex: "profit1020",
                            key: "profit1020",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.profit1020 - b.profit1020,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "18min",
                            dataIndex: "profit1080",
                            key: "profit1080",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.profit1080 - b.profit1080,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "19min",
                            dataIndex: "profit1140",
                            key: "profit1140",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.profit1140 - b.profit1140,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "20min",
                            dataIndex: "profit1200",
                            key: "profit1200",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.profit1200 - b.profit1200,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                ],
            },
            DTColProps.Middle({
                title: "Trend",
                dataIndex: "timeSeriesStats",
                key: "timeSeriesStats",
                fixed: "right",
                render: (timeSeries: any[]) => {
                    if (timeSeries.length < 1) return <span></span>;

                    let ftmp: any[] = timeSeries.filter((x) => x.decaySecond > -1);
                    ftmp.sort((a: any, b: any) => a.decaySecond - b.decaySecond);
                    let checkColorIdx = ftmp.length - 1;
                    for (; checkColorIdx > 0; checkColorIdx--) {
                        if (ftmp[checkColorIdx].dpm !== null && ftmp[checkColorIdx].dpm !== undefined) break;
                    }
                    return (
                        <TinyAreaChart
                            data={ftmp.map((x) => x.dpm)}
                            config={
                                ftmp[checkColorIdx].dpm >= 0
                                    ? {
                                          width: 220,
                                          color: "#9bdab5",
                                          line: {
                                              color: "#009F42",
                                          },
                                      }
                                    : {
                                          width: 220,
                                          color: "#ec9892",
                                          line: {
                                              color: "#f00f00",
                                          },
                                      }
                            }
                        />
                    );
                },
            }),
        ];
    };

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.VIEW_RECORD:
                props.onModalCallback && props.onModalCallback(FormData.timeSeriesStats);
                break;
            case CALLBACK_KEY.REFRESH_RATE_CHANGED:
                props.onRefreshRateCallback && props.onRefreshRateCallback(FormData.currentRefreshPeriod);
                break;
            case CALLBACK_KEY.REFRESH:
                props.data && getTicekList(props.data);
                break;
        }
    };

    const getTicekList = (param: SelectedAccountInfoProps) => {
        apiRequest(APIs.GET_DECAY_CHART_TICKET_LIST, param)
            .then((data: any) => {
                setData(data.tradeStats);
                setAccountData(data.accountStats);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("decay analysis - ticket view", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                setData([]);
            })
            .finally(() => setIsLoading(false));
    };

    const getTitle = (accountId: string, serverId: string) => {
        let serverDisplay: string = `${serverId}`;
        let sIdx: number = props.servers.findIndex((x) => `${x.id}` === `${serverId}`);
        if (sIdx > -1) {
            serverDisplay = props.servers[sIdx].server;
        }
        return (
            <div className="decay-ticket-view-container">
                <span className="title">Account ID:</span>
                <span>
                    <Link
                        to={getNavigationUrl(
                            ACCOUNT_PROFILE_FROM_MODULE.DECAY_ANALYSIS,
                            getAccountProfileId(parseInt(serverId), parseInt(accountId), ACCOUNT_PROFILE_FROM_MODULE.DECAY_ANALYSIS, "", "", "", "", "")
                        )}
                        target="_blank"
                        style={{ color: "#0e65cc" }}
                    >
                        {accountId}
                    </Link>
                </span>
                <span className="title">Server:</span>
                <span>{serverDisplay}</span>
            </div>
        );
    };

    useEffect(() => {
        props.data && getTicekList(props.data);
        return () => {};
    }, []);

    return (
        <>
            {isLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Loading..." />
                </div>
            ) : !isLoading && accountData ? (
                <>
                    <div className="decay-account-desc-container">
                        <DecayAnalysisAccountSummary accountStats={accountData} />
                    </div>
                    <FlexiDataTable
                        rowKeyProperty="ticket"
                        title={props.data ? getTitle(props.data.accountId, props.data.serverId) : ""}
                        columns={columnsConfig()}
                        options={{
                            view: true,
                            refresh: {
                                timer: true,
                                refreshSecond: props.refreshRate,
                                enablePeriodSelection: true,
                            },
                        }}
                        dataSource={data ?? []}
                        callback={componentCallback}
                        loading={isLoading}
                        tableProps={{
                            bordered: true,
                        }}
                    />
                </>
            ) : (
                <FlexiDataTable
                    rowKeyProperty="ticket"
                    title={props.data ? getTitle(props.data.accountId, props.data.serverId) : ""}
                    columns={columnsConfig()}
                    options={{
                        view: true,
                        refresh: {
                            timer: true,
                            refreshSecond: props.refreshRate,
                            enablePeriodSelection: true,
                        },
                    }}
                    dataSource={data ?? []}
                    callback={componentCallback}
                    loading={isLoading}
                    tableProps={{
                        bordered: true,
                    }}
                />
            )}
        </>
    );
};

export default TicketView;
