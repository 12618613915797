import { Datum } from "@ant-design/charts";
import { TinyArea } from "@ant-design/plots";
import { currencyRender } from "../../../utils/Common";

export interface TinyAreaChartProps {
    data: any[];
    config: any;
}

const TinyAreaChart = (props: TinyAreaChartProps) => {
    const config = Object.assign(
        {
            height: 30,
            autoFit: false,
            data: props.data,
            animation: false,
            tooltip: {
                formatter: (datum: Datum) => {
                    return { name: "", value: currencyRender(datum.y) };
                },
            },
        },
        props.config
    );

    return <TinyArea {...config} />;
};

export default TinyAreaChart;
