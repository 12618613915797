import { create } from "zustand";

type RCServerPerformanceStore = {
    ignoredServers: string[];
    currentFocusServer: string | null;
};
type RCServerPerformanceActions = {
    setIgnoredServers: (servers: string[]) => void;
    setCurrentFocusServer: (server: string | null) => void;
};

const useRCServerPerformanceStore = create<RCServerPerformanceStore & RCServerPerformanceActions>(set => ({
    ignoredServers: [],
    currentFocusServer: null,
    setIgnoredServers: servers =>
        set(state => ({
            ignoredServers: servers,
        })),
    setCurrentFocusServer: server =>
        set(state => ({
            currentFocusServer: server,
        })),
}));

export default useRCServerPerformanceStore;
