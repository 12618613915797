import React, { useState } from "react";
import FloatingTitleBox from "../../components/FloatingTitleBox";
import useRCIssuesLogStore from "../../../../store/useRCIssuesLogStore";
import CommonIssuesLogTable from "../CommonIssuesLogTable";
import { Tabs } from "antd";
import RequestTimeoutSettings from "./RequestTimeoutSettings";

const IssueRequestTimeout = () => {
    const updateInView = useRCIssuesLogStore(state => state.updateInView);
    const [currTab, setCurrTab] = useState<string>("monitor");
    const handleChangeTab = (activeKey: string) => {
        setCurrTab(activeKey);
    };
    return (
        <FloatingTitleBox
            title="Request Timeout"
            inViewCallback={(isInView: boolean) => {
                updateInView({ REQUEST_TIMEOUT: isInView });
            }}
            titleBarTabs={
                <Tabs
                    activeKey={currTab}
                    onChange={handleChangeTab}
                    type="card"
                    style={{
                        padding: "0.5rem 0.5rem 0 0.5rem",
                    }}
                    tabBarStyle={{
                        marginBottom: 0,
                    }}
                    items={[
                        {
                            label: "Monitoring",
                            key: "monitor",
                        },
                        {
                            label: "Settings",
                            key: "settings",
                        },
                    ]}
                />
            }
        >
            {currTab === "monitor" && <CommonIssuesLogTable type="REQUEST_TIMEOUT" />}
            {currTab === "settings" && <RequestTimeoutSettings />}
        </FloatingTitleBox>
    );
};

export default IssueRequestTimeout;
