import { useRCBalanceAdjustmentMutate, useRCBalanceAdjustmentSMSSettings } from "@/hooks/useRCBalanceAdjustment";
import { EditOutlined } from "@ant-design/icons";
import { Button, Form, Input, notification, Space, Table, Typography } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useState } from "react";

interface ISMSModalProps {
    level: number;
    threshold: number;
    mobile: string;
}
const BalanceAdjustmentSMS = () => {
    const { rcBalanceAdjustmentSMSSettings, isLoading } = useRCBalanceAdjustmentSMSSettings();
    const [openSMSModal, setOpenSMSModal] = useState<ISMSModalProps | null>(null);
    // console.log("rcBalanceAdjustmentSMSSettings", rcBalanceAdjustmentSMSSettings);
    // const dummy = [
    //     {
    //         level: 1,
    //         threshold: 2000,
    //         mobile: "+886978705668",
    //     },
    //     {
    //         level: 2,
    //         threshold: 5000,
    //         mobile: "+61451818289",
    //     },
    //     {
    //         level: 3,
    //         threshold: 10000,
    //         mobile: "",
    //     },
    // ];
    const columns = [
        {
            title: "Level",
            dataIndex: "level",
        },
        {
            title: "Threshold",
            dataIndex: "threshold",
        },
        {
            title: "Mobile",
            dataIndex: "mobile",
        },
        {
            title: "Action",
            dataIndex: "action",
            render: (text: any, data: any) => {
                return (
                    <Button
                        type="link"
                        onClick={() => {
                            setOpenSMSModal(data);
                        }}
                        icon={<EditOutlined />}
                    />
                );
            },
        },
    ];

    return (
        <>
            <div>
                <Table
                    // dataSource={dummy}
                    dataSource={rcBalanceAdjustmentSMSSettings}
                    columns={columns}
                    pagination={{
                        defaultPageSize: 50,
                        pageSizeOptions: ["10", "20", "50", "100", "500", "1000"],
                    }}
                    size="small"
                    // use level + threshold as row key
                    rowKey={record => `${record?.level ?? ""}-${record?.threshold ?? ""}`}
                    loading={isLoading}
                />
            </div>
            {openSMSModal && <BalanceAdjustmentSMSModal data={openSMSModal} open={openSMSModal !== null} onClose={() => setOpenSMSModal(null)} />}
        </>
    );
};

const BalanceAdjustmentSMSModal = ({ data, open, onClose }: { data: ISMSModalProps | null; open: boolean; onClose: () => void }) => {
    const [form] = Form.useForm();
    const { Item } = Form;
    const { Text } = Typography;

    const initFormValue = {
        level: data?.level ?? 0,
        threshold: data?.threshold ?? 0,
        mobile: data?.mobile ?? "",
    };

    const { mutateUpdateSMSSetting } = useRCBalanceAdjustmentMutate();
    const handleSubmit = async () => {
        // console.log("------FORM VALUE", form.getFieldsValue());
        try {
            const resp = await mutateUpdateSMSSetting(form.getFieldsValue());
            // console.log("-----HELLLLO Resp", resp);
            if (resp?.data === 1) {
                notification.success({
                    message: "Success",
                    description: "SMS Settings updated successfully",
                });
                onClose();
            }
        } catch (error) {
            console.error("Error on updating SMS Settings:", error);
            notification.error({
                message: "Error",
                // @ts-ignore
                description: error?.response?.data?.message || "Error on updating SMS Settings",
            });
        }
    };

    const keyboardStyle = {
        // fontSize: "0.75rem",
        fontWeight: "normal",
        height: "fit-content",
        display: "inline-block",
        alignSelf: "center",
        background: "#ccc",
        borderRadius: "4px",
        color: "#333",
        padding: "2px 5px",
    };

    return (
        <Form
            form={form}
            layout="vertical"
            initialValues={initFormValue}
            /* onValuesChange={handleFormUpdate} */ requiredMark={true}
            onFinish={handleSubmit}
        >
            <Modal title="Balance Adjustment SMS Settings" open={open} onCancel={onClose} onOk={() => form.submit()}>
                <Space direction="vertical" style={{ width: "100%" }}>
                    <div>
                        <Text>Level *</Text>
                        <Item
                            style={{ margin: 0, padding: 0 }}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            required
                            name="level"
                            rules={[{ required: true, message: "This field is required" }]}
                        >
                            <Input
                                disabled
                                type="number"
                                value={form.getFieldValue("level")}
                                onChange={e => form.setFieldsValue({ level: e.target.value })}
                            />
                        </Item>
                    </div>
                    <div>
                        <Text>Threshold *</Text>
                        <Item
                            style={{ margin: 0, padding: 0 }}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            required
                            name="threshold"
                            rules={[{ required: true, message: "This field is required" }]}
                        >
                            <Input
                                type="number"
                                value={form.getFieldValue("threshold")}
                                onChange={e => form.setFieldsValue({ threshold: e.target.value })}
                            />
                        </Item>
                    </div>
                    <div>
                        <Text>Mobile *</Text>
                        <br />
                        <Text style={{ color: "#666", marginBottom: 4, display: "block" }}>
                            <small>
                                Add <span style={keyboardStyle}>+</span> infront of number and Seperate numbers with{" "}
                                <span style={keyboardStyle}>,</span>
                            </small>
                        </Text>
                        <Item
                            style={{ margin: 0, padding: 0 }}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            required
                            name="mobile"
                            // rules={[{ required: true, message: "This field is required" }]}
                        >
                            <Input
                                type="number"
                                value={form.getFieldValue("mobile")}
                                onChange={e => form.setFieldsValue({ mobile: e.target.value })}
                            />
                        </Item>
                    </div>
                </Space>
            </Modal>
        </Form>
    );
};

export default BalanceAdjustmentSMS;
