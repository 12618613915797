import CardBox from "@/components/Common/CardBox";
import FlexiDataTable from "@/components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "@/constants";
import { FlexiDataTableCallbackProps, FlexiDataTableOptionsProps } from "@/constants/type";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { apiRequest, APIs } from "@/services/apiConfig";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export type BridgeSettingList = {
    bridgeId: number;
    bridgeName: string;
    bridgeDisplayName: string;
    bridgeType: string;
    bridgeTypeId: number;
    enable: boolean;
    version: string;
    host: string;
    login: string;
    password: string;
    port: number;
    brokerVhost: string;
    brokerLogin: string;
    brokerPassword: string;
};

const BridgeSettingPage = () => {
    let navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<BridgeSettingList[]>([]);

    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.ADMIN_BRAND_EDIT);

    const columns: any[] = [
        {
            title: "Bridge Name",
            dataIndex: "bridgeName",
            key: "bridgeName",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
            sorter: (a: any, b: any) => a.bridgeName.localeCompare(b.bridgeName),
        },
        {
            title: "Bridge Display Name",
            dataIndex: "bridgeDisplayName",
            key: "bridgeDisplayName",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
            sorter: (a: any, b: any) => a.bridgeDisplayName.localeCompare(b.bridgeDisplayName),
            defaultSortOrder: "ascend",
        },
        DTColProps.XSmall({
            title: "Bridge Type",
            dataIndex: "bridgeType",
            key: "bridgeType",
        }),
        DTColProps.Middle({
            title: "Host",
            dataIndex: "host",
            key: "host",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.XSmall({
            title: "Port",
            dataIndex: "port",
            key: "port",
        }),
        DTColProps.Small({
            title: "Login",
            dataIndex: "login",
            key: "login",
        }),
        DTColProps.Small({
            title: "VHost",
            dataIndex: "brokerVhost",
            key: "brokerVhost",
        }),
        DTColProps.Small({
            title: "BrokerLogin",
            dataIndex: "brokerLogin",
            key: "brokerLogin",
        }),
        DTColProps.XSmall({
            title: "Version",
            dataIndex: "version",
            key: "version",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.Status({
            title: "Status",
            dataIndex: "enable",
            key: "enable",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: [
                        { text: "Active", value: true },
                        { text: "Inactive", value: false },
                    ],
                },
            },
        }),
    ];

    const options: FlexiDataTableOptionsProps = {
        add: () => {
            if (enableUpdate) {
                return "/siteadmin/brandserver/bridge/create";
            } else return enableUpdate;
        },
        edit: enableUpdate,
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, FormData) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                navigate("/siteadmin/brandserver/bridge/create", { state: { data: null, action: "add" } });
                break;
            case CALLBACK_KEY.DO_EDIT:
                navigate(`/siteadmin/brandserver/bridge/edit/${FormData.bridgeId}`, { state: { data: FormData.bridgeId, action: "edit" } });
                break;
            default:
                break;
        }
    };

    const getBridgeList = () => {
        setIsLoading(true);
        apiRequest(APIs.GET_BRIDGE_LIST, {})
            .then((res: any) => {
                setData(res && res.length > 0 ? res : []);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("bridge list", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getBridgeList();

        return () => {};
    }, []);

    return (
        <div className="v2-bridge-setting">
            <CardBox title={"Bridge Listing"}>
                <FlexiDataTable
                    rowKeyProperty="bridgeId"
                    title=""
                    columns={columns}
                    options={options}
                    dataSource={data}
                    callback={componentCallback}
                    loading={isLoading}
                />
            </CardBox>
        </div>
    );
};

export default BridgeSettingPage;
