import React, { useEffect, useMemo, useState } from "react";
import { Form, Collapse, Button, Col, Divider, Row, Popover, Checkbox, Upload } from "antd";
import {
    DownloadOutlined,
    FilterFilled,
    FilterOutlined,
    LayoutOutlined,
    PlusOutlined,
    RedoOutlined,
    SyncOutlined,
    UploadOutlined,
} from "@ant-design/icons";
import "./styles.less";
import { FormComponent } from "../FormComponent";
import { TimerButton } from "../Common";
import { Link } from "react-router-dom";
import { CALLBACK_KEY, ComponentType } from "../../constants";
import { FlexiColumnFilterProps, FlexiDataColumnProps, FlexiDataTableOptionsProps } from "../../constants/type";
import { getShowColumnsTitle } from "../FlexiDataTable";
import { objectRemoveProps } from "../../utils/object";
import { defaultIfEmptyOrNull } from "../../utils/string";

const { Panel } = Collapse;
const CheckboxGroup = Checkbox.Group;

interface FlexiFilterProps {
    title: string | React.ReactNode;
    columns: FlexiDataColumnProps[];
    options: FlexiDataTableOptionsProps;
    enableFilter?: boolean;
    initialValues?: any;
    callback: (type: number, data: any) => void;
    exporting?: boolean;
    showColumnsTitleList: string[];
    filterFormInstance?: any;
}

const initialDefaultProps: FlexiFilterProps = {
    title: "",
    columns: [],
    options: {},
    callback: () => {},
    enableFilter: true,
    exporting: false,
    showColumnsTitleList: [],
};

const FlexiFilter = (props: FlexiFilterProps): JSX.Element => {
    const [currentFilterForm] = Form.useForm();
    const filterForm = useMemo(() => (props.filterFormInstance ? props.filterFormInstance : currentFilterForm), [props.filterFormInstance]);
    const [currentProps, setCurrentProps] = useState(Object.assign({}, { ...initialDefaultProps }, props));
    const [isShowHideColPopover, setIsShowHideColPopover] = useState<boolean>(false);
    const formValues = Form.useWatch([], filterForm);

    /*
    const getExtraElement = (exporting: boolean, tableColumns: FlexiDataColumnProps[], showColumnsTitleList: string[]): React.ReactNode => {
        let elements: React.ReactNode[] = [];
        let openNewLinkUrl: string = "";
        let showColumnsCount: number = getShowColumnsTitle(tableColumns).length;

        if (currentProps.options.showHideColumns && showColumnsCount > 0) {
            elements.push(
                <Popover
                    zIndex={1000}
                    key={`btn-hideshow-col-${Math.random()}`}
                    open={isShowHideColPopover}
                    onOpenChange={(newOpen: boolean) => setIsShowHideColPopover(newOpen)}
                    content={
                        <div className="show-columns-panel">
                            <CheckboxGroup
                                options={getShowColumnsTitle(tableColumns) as string[]}
                                value={showColumnsTitleList}
                                onChange={(checkedValue: any[]) => {
                                    if (checkedValue.length == 0) return false;
                                    currentProps.callback(CALLBACK_KEY.SHOW_HIDE_COLUMNS_SELECTION, { selectedList: checkedValue });
                                }}
                            />
                        </div>
                    }
                    title="Show Columns"
                    trigger="click"
                    placement="bottomRight"
                >
                    <Button
                        htmlType="button"
                        className="ft-flr-btn-hideshow-col"
                        icon={<LayoutOutlined style={{ fontSize: "0.875rem" }} />}
                        onClick={event => {
                            //event.preventDefault();
                            event.stopPropagation();
                            currentProps.callback(CALLBACK_KEY.SHOW_HIDE_COLUMNS, {});
                        }}
                    />
                </Popover>
            );
        }

        if (currentProps.options.extraButtons) {
            if (Array.isArray(currentProps.options.extraButtons) && currentProps.options.extraButtons.length > 0) {
                currentProps.options.extraButtons.forEach(x => {
                    elements.push(
                        <Button
                            key={`btn-ee-${Math.random()}`}
                            htmlType="button"
                            style={{ marginLeft: "0.651vw", width: "auto" }}
                            onClick={event => {
                                event.preventDefault();
                                event.stopPropagation();
                                currentProps.callback(CALLBACK_KEY.OTHERS, x.value);
                            }}
                            {...(x.icon && {
                                icon: x.icon,
                            })}
                        >
                            {x.text}
                        </Button>
                    );
                });
            } else if (typeof currentProps.options.extraButtons === "function") {
                elements.push(currentProps.options.extraButtons());
            }
        }

        if (currentProps.options.add) {
            let createText = "Create";
            if (typeof currentProps.options.add !== "boolean") {
                if (typeof currentProps.options.add === "function") {
                    openNewLinkUrl = currentProps.options.add();
                } else {
                    createText = currentProps.options.add.text;
                }
            }
            elements.push(
                <Link to={openNewLinkUrl} key={`btn-add-${Math.random()}`}>
                    <Button
                        htmlType="button"
                        className="ft-flr-btn-add"
                        style={{ marginLeft: "0.651vw" }}
                        icon={<PlusOutlined style={{ fontSize: "0.875rem" }} />}
                        onClick={event => {
                            event.preventDefault();
                            event.stopPropagation();
                            currentProps.callback(CALLBACK_KEY.CREATE_NEW, {});
                        }}
                    >
                        {createText}
                    </Button>
                </Link>
            );
        }

        if (currentProps.options.refresh) {
            elements.push(
                currentProps.options.refresh.timer ? (
                    <TimerButton
                        key={`btn-refresh-timer-${Math.random()}`}
                        period={currentProps.options.refresh.refreshSecond || Number(process.env.REACT_APP_REFRESH_TIME_IN_SECOND) || 0}
                        enablePeriodSelection={currentProps.options.refresh.enablePeriodSelection || false}
                        onFinish={(type: CALLBACK_KEY, period: number) => {
                            currentProps.callback(type, { currentRefreshPeriod: period });
                        }}
                    />
                ) : (
                    <Button
                        key={`btn-refresh-${Math.random()}`}
                        htmlType="button"
                        style={{ marginLeft: "0.651vw" }}
                        icon={<RedoOutlined style={{ fontSize: "0.875rem" }} />}
                        onClick={event => {
                            event.preventDefault();
                            event.stopPropagation();
                            currentProps.callback(CALLBACK_KEY.REFRESH, { currentRefreshPeriod: 0 });
                        }}
                    >
                        Refresh
                    </Button>
                )
            );
        }

        if (currentProps.options.resync) {
            elements.push(
                <Button
                    key={`btn-resync-${Math.random()}`}
                    htmlType="button"
                    style={{ marginLeft: "0.651vw" }}
                    icon={<SyncOutlined style={{ fontSize: "0.875rem" }} />}
                    onClick={event => {
                        event.preventDefault();
                        event.stopPropagation();
                        currentProps.callback(CALLBACK_KEY.RESYNC_JOB_SCHEDULER, {});
                    }}
                >
                    Resync
                </Button>
            );
        }

        if (currentProps.options.upload) {
            let uploadText = currentProps.options.upload.uploadText ? currentProps.options.upload.uploadText : "Upload",
                defaultProps: any = {
                    ...{
                        name: "file",
                        accept: ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                        multiple: false,
                        showUploadList: false,
                    },
                    ...currentProps.options.upload,
                };
            elements.push(
                <Upload key={`btn-upload-${Math.random()}`} {...defaultProps}>
                    <Button
                        htmlType="button"
                        icon={<UploadOutlined style={{ fontSize: "0.875rem" }} />}
                        style={{ marginLeft: "0.651vw", width: "auto" }}
                        // onClick={(event) => {
                        //     event.preventDefault();
                        //     event.stopPropagation();
                        //     currentProps.callback(CALLBACK_KEY.UPLOAD_CSV_EXCEL, {});
                        // }}
                    >
                        {uploadText}
                    </Button>
                </Upload>
            );
        }

        if (currentProps.options.export) {
            if (typeof currentProps.options.export !== "boolean") {
                if (currentProps.options.export.Element) {
                    elements.push(currentProps.options.export.Element);
                } else {
                    elements.push(
                        <Button
                            key={`btn-export-${Math.random()}`}
                            htmlType="button"
                            style={{ marginLeft: "0.651vw", width: "auto" }}
                            icon={<DownloadOutlined style={{ fontSize: "0.875rem" }} />}
                            loading={exporting}
                            onClick={event => {
                                event.preventDefault();
                                event.stopPropagation();
                                currentProps.callback(CALLBACK_KEY.EXPORT_CSV_EXCEL, {});
                            }}
                        >
                            {currentProps.options.export.text}
                        </Button>
                    );
                }
            } else {
                elements.push(
                    <Button
                        key={`btn-export-${Math.random()}`}
                        htmlType="button"
                        style={{ marginLeft: "0.651vw", width: "auto" }}
                        icon={<DownloadOutlined style={{ fontSize: "0.875rem" }} />}
                        loading={exporting}
                        onClick={event => {
                            event.preventDefault();
                            event.stopPropagation();
                            currentProps.callback(CALLBACK_KEY.EXPORT_CSV_EXCEL, {});
                        }}
                    >
                        Export
                    </Button>
                );
            }
        }

        return elements;
    };*/

    const extraTableElement = useMemo(() => {
        let exporting = currentProps.exporting || false,
            tableColumns = currentProps.columns,
            showColumnsTitleList = currentProps.showColumnsTitleList,
            elements: React.ReactNode[] = [],
            openNewLinkUrl: string = "",
            showColumnsTitle = getShowColumnsTitle(tableColumns) as string[],
            showColumnsCount: number = showColumnsTitle.length;

        if (currentProps.options.showHideColumns && showColumnsCount > 0) {
            elements.push(
                <Popover
                    zIndex={1000}
                    key={`btn-hideshow-col-${Math.random()}`}
                    open={isShowHideColPopover}
                    onOpenChange={(newOpen: boolean) => setIsShowHideColPopover(newOpen)}
                    content={
                        <div className="show-columns-panel">
                            <CheckboxGroup
                                options={showColumnsTitle}
                                value={showColumnsTitleList}
                                onChange={(checkedValue: any[]) => {
                                    if (checkedValue.length === 0) return false;
                                    currentProps.callback(CALLBACK_KEY.SHOW_HIDE_COLUMNS_SELECTION, { selectedList: checkedValue });
                                }}
                            />
                        </div>
                    }
                    title="Show Columns"
                    trigger="click"
                    placement="bottomRight"
                >
                    <Button
                        htmlType="button"
                        className="ft-flr-btn-hideshow-col"
                        icon={<LayoutOutlined style={{ fontSize: "0.875rem" }} />}
                        onClick={event => {
                            //event.preventDefault();
                            event.stopPropagation();
                            currentProps.callback(CALLBACK_KEY.SHOW_HIDE_COLUMNS, {});
                        }}
                    />
                </Popover>
            );
        }

        if (currentProps.options.extraButtons) {
            if (Array.isArray(currentProps.options.extraButtons) && currentProps.options.extraButtons.length > 0) {
                currentProps.options.extraButtons.forEach(x => {
                    elements.push(
                        <Button
                            key={`btn-ee-${Math.random()}`}
                            type={x.type || "default"}
                            htmlType="button"
                            style={{ marginLeft: "0.651vw", width: "auto" }}
                            onClick={event => {
                                event.preventDefault();
                                event.stopPropagation();
                                currentProps.callback(CALLBACK_KEY.OTHERS, x.value);
                            }}
                            {...(x.icon && {
                                icon: x.icon,
                            })}
                        >
                            {x.text}
                        </Button>
                    );
                });
            } else if (typeof currentProps.options.extraButtons === "function") {
                elements.push(currentProps.options.extraButtons());
            }
        }

        if (currentProps.options.add) {
            let createText = "Create";
            if (typeof currentProps.options.add !== "boolean") {
                if (typeof currentProps.options.add === "function") {
                    openNewLinkUrl = currentProps.options.add();
                } else {
                    createText = currentProps.options.add.text;
                }
            }
            elements.push(
                <Link to={openNewLinkUrl} key={`btn-add-${Math.random()}`}>
                    <Button
                        htmlType="button"
                        className="ft-flr-btn-add"
                        style={{ marginLeft: "0.651vw" }}
                        icon={<PlusOutlined style={{ fontSize: "0.875rem" }} />}
                        onClick={event => {
                            event.preventDefault();
                            event.stopPropagation();
                            currentProps.callback(CALLBACK_KEY.CREATE_NEW, {});
                        }}
                    >
                        {createText}
                    </Button>
                </Link>
            );
        }

        if (currentProps.options.refresh) {
            elements.push(
                currentProps.options.refresh.timer ? (
                    <TimerButton
                        key={`btn-refresh-timer-${Math.random()}`}
                        period={currentProps.options.refresh.refreshSecond || Number(process.env.REACT_APP_REFRESH_TIME_IN_SECOND) || 0}
                        enablePeriodSelection={currentProps.options.refresh.enablePeriodSelection || false}
                        onFinish={(type: CALLBACK_KEY, period: number) => {
                            currentProps.callback(type, { currentRefreshPeriod: period });
                        }}
                    />
                ) : (
                    <Button
                        key={`btn-refresh-${Math.random()}`}
                        htmlType="button"
                        style={{ marginLeft: "0.651vw" }}
                        icon={<RedoOutlined style={{ fontSize: "0.875rem" }} />}
                        onClick={event => {
                            event.preventDefault();
                            event.stopPropagation();
                            currentProps.callback(CALLBACK_KEY.REFRESH, { currentRefreshPeriod: 0 });
                        }}
                    >
                        Refresh
                    </Button>
                )
            );
        }

        if (currentProps.options.resync) {
            elements.push(
                <Button
                    key={`btn-resync-${Math.random()}`}
                    htmlType="button"
                    style={{ marginLeft: "0.651vw" }}
                    icon={<SyncOutlined style={{ fontSize: "0.875rem" }} />}
                    onClick={event => {
                        event.preventDefault();
                        event.stopPropagation();
                        currentProps.callback(CALLBACK_KEY.RESYNC_JOB_SCHEDULER, {});
                    }}
                >
                    Resync
                </Button>
            );
        }

        if (currentProps.options.upload) {
            let uploadText = defaultIfEmptyOrNull(currentProps.options.upload.uploadText, "Upload"),
                defaultProps: any = {
                    name: "file",
                    accept: ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                    multiple: false,
                    showUploadList: false,
                    ...currentProps.options.upload,
                };
            elements.push(
                <Upload key={`btn-upload-${Math.random()}`} {...defaultProps}>
                    <Button
                        htmlType="button"
                        icon={<UploadOutlined style={{ fontSize: "0.875rem" }} />}
                        style={{ marginLeft: "0.651vw", width: "auto" }}
                        // onClick={(event) => {
                        //     event.preventDefault();
                        //     event.stopPropagation();
                        //     currentProps.callback(CALLBACK_KEY.UPLOAD_CSV_EXCEL, {});
                        // }}
                    >
                        {uploadText}
                    </Button>
                </Upload>
            );
        }

        if (currentProps.options.export) {
            if (typeof currentProps.options.export !== "boolean") {
                if (currentProps.options.export.Element) {
                    elements.push(currentProps.options.export.Element);
                } else {
                    elements.push(
                        <Button
                            key={`btn-export-${Math.random()}`}
                            htmlType="button"
                            style={{ marginLeft: "0.651vw", width: "auto" }}
                            icon={<DownloadOutlined style={{ fontSize: "0.875rem" }} />}
                            loading={exporting}
                            onClick={event => {
                                event.preventDefault();
                                event.stopPropagation();
                                currentProps.callback(CALLBACK_KEY.EXPORT_CSV_EXCEL, filterForm.getFieldsValue());
                            }}
                        >
                            {currentProps.options.export.text}
                        </Button>
                    );
                }
            } else {
                elements.push(
                    <Button
                        key={`btn-export-${Math.random()}`}
                        htmlType="button"
                        style={{ marginLeft: "0.651vw", width: "auto" }}
                        icon={<DownloadOutlined style={{ fontSize: "0.875rem" }} />}
                        loading={exporting}
                        onClick={event => {
                            event.preventDefault();
                            event.stopPropagation();
                            currentProps.callback(CALLBACK_KEY.EXPORT_CSV_EXCEL, {});
                        }}
                    >
                        Export
                    </Button>
                );
            }
        }

        return elements;
    }, [currentProps, isShowHideColPopover]);

    const filterAvailableCols = useMemo(() => currentProps.columns.filter(x => x.options?.filter && x.options?.filter !== null), [currentProps]);

    useEffect(() => {
        setCurrentProps(Object.assign({}, { ...initialDefaultProps }, props));
        return () => {};
    }, [props]);

    useEffect(() => {
        filterForm.setFieldsValue(props.initialValues || {});
    }, [props.initialValues]);

    //let extraTableElement: any = getExtraElement(currentProps.exporting || false, currentProps.columns, currentProps.showColumnsTitleList);
    //let filterAvailableCols: FlexiDataColumnProps[] = currentProps.columns.filter(x => x.options?.filter && x.options?.filter !== null);
    return (
        <>
            {/* {currentProps.options.add || currentProps.title ? ( */}
            {currentProps.enableFilter ? (
                <Collapse
                    activeKey={props.options.defaultCollapseFilterPanel ? [] : ["flexi-filter-panel"]}
                    collapsible="header"
                    // {...(!props.options.defaultCollapseFilterPanel && {
                    //     defaultActiveKey: "flexi-filter-panel",
                    // })}
                    bordered={false}
                    className="flexi-filter"
                    onChange={key => currentProps.callback(CALLBACK_KEY.COLLAPSE, key)}
                    expandIconPosition="start"
                    expandIcon={panelProps => {
                        return (
                            <Button
                                type="ghost"
                                icon={
                                    panelProps.isActive ? (
                                        <FilterOutlined style={{ fontSize: "1.125rem" }} />
                                    ) : (
                                        <FilterFilled style={{ fontSize: "1.125rem" }} />
                                    )
                                }
                            >
                                <span style={{ fontSize: "0.9375rem" }}>Filter</span>
                            </Button>
                        );
                    }}
                >
                    <Panel
                        {...(filterAvailableCols.length === 0 && {
                            collapsible: "disabled",
                            showArrow: false,
                        })}
                        header={<>{currentProps.title}</>}
                        key="flexi-filter-panel"
                        className="flexi-filter-panel"
                        extra={extraTableElement}
                    >
                        <Form
                            form={filterForm}
                            layout="vertical"
                            initialValues={currentProps.initialValues}
                            onFinish={values => currentProps.callback(CALLBACK_KEY.FILTER_FORM_SUBMIT, values)}
                            onFinishFailed={values => currentProps.callback(CALLBACK_KEY.FILTER_FORM_SUBMIT_FAILED, values)}
                        >
                            {filterAvailableCols.length > 0 && (
                                <>
                                    <Row gutter={24} style={{ width: "100%" }}>
                                        {filterAvailableCols.map(x => {
                                            if (x.options?.filter) {
                                                let col_opt: FlexiColumnFilterProps = x.options.filter as FlexiColumnFilterProps;
                                                let currentFormValues = formValues as any;
                                                return (
                                                    <Col key={"flt-f-c-" + x.key} xl={8} lg={12} md={12} sm={24} style={{ marginBottom: "1.414vh" }}>
                                                        <FormComponent
                                                            label={col_opt?.text || x.title || ""}
                                                            name={x.key || ""}
                                                            extra={{
                                                                type: col_opt.type || ComponentType.text,
                                                                value: col_opt.dependencyElement
                                                                    ? col_opt.dependencyElement.expression(
                                                                          currentFormValues &&
                                                                              currentFormValues[col_opt.dependencyElement.targetColumnKey]
                                                                              ? currentFormValues[col_opt.dependencyElement.targetColumnKey] || ""
                                                                              : "",
                                                                          col_opt.value,
                                                                          filterForm
                                                                      )
                                                                    : col_opt.value,
                                                                ...objectRemoveProps({ ...col_opt }, ["type", "value", "dependencyElement"]),
                                                                ...(x.options.form && {
                                                                    form: x.options.form,
                                                                }),
                                                            }}
                                                        />
                                                    </Col>
                                                );
                                            }
                                        })}
                                    </Row>
                                    <Divider orientation="left" className="divider-nogap"></Divider>
                                    <Row style={{ width: "100%" }}>
                                        <Col span={24} key="flt-f-c-btns" className="flt-f-c-btns">
                                            <Button
                                                type="default"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    filterForm.resetFields();
                                                    currentProps.callback(CALLBACK_KEY.FILTER_FORM_RESET, currentProps.initialValues);
                                                }}
                                            >
                                                Reset
                                            </Button>
                                            <Button type="primary" htmlType="submit">
                                                Search
                                            </Button>
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </Form>
                    </Panel>
                </Collapse>
            ) : typeof currentProps.title === "boolean" && !currentProps.title && extraTableElement.length === 0 ? (
                <></>
            ) : (
                <div className="disabled-filter-panel">
                    <div className="title">{currentProps.title || ""}</div>
                    <div className="extra">{extraTableElement}</div>
                </div>
            )}
        </>
    );
};

export default FlexiFilter;
export type { FlexiFilterProps };
