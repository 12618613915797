import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Modal, Popconfirm, Row, message } from "antd";
import { FormComponent } from "../../../../components/FormComponent";
import { ComponentType, InnerPageActionMode, SUCCESS_FAILED, TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE, TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM } from "../../../../constants";
import { REQUIRED_FIELD } from "../../../../constants/errorMessage";
import { AttributeProps, ColourListProps, CreateEditInlineFormBased, ToxicClientLabelProps } from "../../../../constants/type";
import EmptyData from "../../../../components/Common/Empty";
import AttributeCreateEdit from "./Components/AttributeCreateEdit";
import SitePageHeaderInline, { SitePageHeaderInlineActionType } from "../../../../components/PageHeader/inlineIndex";
import LoadingComponent from "../../../../components/Loading";
import { isEmptyOrNull } from "../../../../utils/string";
import { ErrorCatchValidator, ErrorMessageHandler, getGUID } from "../../../../utils/Common";
import { APIs, apiRequest } from "../../../../services/apiConfig";
import { cloneDeep } from "lodash";
import { colours } from "../../../../constants/colours";

interface ToxicClientLabelCreateEditProps extends CreateEditInlineFormBased {
    callback: (action: InnerPageActionMode, value?: any) => void;
    resetState: number;
}

const ToxicClientLabelCreateEdit = (props: ToxicClientLabelCreateEditProps) => {
    const isAddAction = useMemo(() => props.mode === InnerPageActionMode.CREATE_NEW, [props.mode]);
    const [tcLabelForm] = Form.useForm();
    const [tcLabelAttrForm] = Form.useForm();
    const [tcLabelAttrTitle, setTcLabelAttrTitle] = useState<string>("");
    const [showAddLabelAttributeModal, setShowAddLabelAttributeModal] = useState<boolean>(false);
    const [isAddLabelAttr, setIsAddLabelAttr] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const tcLabelStatus = Form.useWatch("isLabelActive", tcLabelForm);
    const [isDeletable, setIsDeletable] = useState<boolean>(false);

    const onSubmit = useCallback((values: any) => {
        let newSubmittedData: ToxicClientLabelProps = cloneDeep(values);
        newSubmittedData.labelAttrs = newSubmittedData.labelAttrs.map((x: any) => {
            if (x.attrId && x.attrId.toString().indexOf("tcla-") > -1) {
                delete x.attrId;
            }
            return x;
        });

        if (newSubmittedData.labelId === undefined) delete newSubmittedData.labelId;

        const attrNames: string[] = [];
        newSubmittedData.labelAttrs.forEach((x: AttributeProps) => attrNames.push(x.attrName.toLowerCase()));
        const duplicates = attrNames.filter((name, index) => attrNames.indexOf(name) !== index);

        if (duplicates.length > 0) {
            message.error(`Please resolve duplicates before continuing: ${duplicates.join(", ")}`);
        } else {
            setIsLoading(true);
            if (isAddAction) {
                apiRequest(APIs.CREATE_TOXIC_CLIENT_LABEL, newSubmittedData)
                    .then((res) => {
                        ErrorMessageHandler("toxic client label", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                        setTimeout(() => {
                            props.callback && props.callback(InnerPageActionMode.BACK, { refreshMainList: true })
                            tcLabelForm.resetFields();
                        }, 400);
                    })
                    .catch((error) =>
                        ErrorCatchValidator(error, (err: any) => {
                            if (err.status === -3) {
                                Modal.warning({
                                    title: "Warning!",
                                    content: err.message,
                                });
                            } else ErrorMessageHandler("toxic client label", SUCCESS_FAILED.FAILED_CREATE_DATA, err);
                        })
                    )
                    .finally(() => setIsLoading(false));
            } else {
                apiRequest(APIs.UPDATE_TOXIC_CLIENT_LABEL, newSubmittedData)
                    .then((res) => {
                        ErrorMessageHandler(`Toxic client label: [ ${newSubmittedData.labelName} ]`, SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                        setTimeout(() => {
                            props.callback && props.callback(InnerPageActionMode.BACK, { refreshMainList: true })
                            tcLabelForm.resetFields();
                        }, 400);
                    })
                    .catch((error) =>
                        ErrorCatchValidator(error, (err: any) => {
                            if (err.status === -3) {
                                Modal.warning({
                                    title: "Warning!",
                                    content: err.message,
                                });
                            } else ErrorMessageHandler("toxic client label", SUCCESS_FAILED.FAILED_UPDATE_DATA, err);
                        })
                    )
                    .finally(() => setIsLoading(false));
            };
        };
    }, [isAddAction]);

    const onAddEditLabelAttributeClicked = (isAdded: boolean, data?: AttributeProps) => {
        tcLabelAttrForm.setFieldsValue(
            Object.assign(
                {
                    attrId: `tcla-${getGUID()}`,
                    attrName: "",
                    attrDescription: "",
                    isAttrActive: true,
                    isMandatory: false,
                    isMultiselectable: false,
                },
                isAdded ? {} : { ...data },
            )
        );
        setTcLabelAttrTitle(isAdded ? "Add Attribute" : "Edit Attribute");
        setShowAddLabelAttributeModal(true);
        setIsAddLabelAttr(isAdded);
    };

    const fillForm = useCallback((data?: ToxicClientLabelProps) => {
        if (isEmptyOrNull(data)) {
            let temp = {
                labelName: "",
                labelDescription: "",
                isLabelActive: true,
                labelAttrs: [],
                labelColor: "",
                isSymbolRequired: false,
                isAllowSdOverIp: false,
            };
            tcLabelForm.setFieldsValue(temp);
        } else {
            let currentData = data as ToxicClientLabelProps,
                temp: any = {
                    labelId: currentData.labelId,
                    labelName: currentData.labelName,
                    labelDescription: currentData.labelDescription,
                    isLabelActive: currentData.isLabelActive,
                    labelAttrs: currentData.labelAttrs,
                    labelColor: currentData.labelColor,
                    isSymbolRequired: currentData.isSymbolRequired,
                    isAllowSdOverIp: currentData.isAllowSdOverIp,
                };
            tcLabelForm.setFieldsValue(temp);
        };
    }, []);

    const getToxicClientLabelDetails = (id?: number | string | undefined) => {
        if (isEmptyOrNull(id)) {
            fillForm(undefined);
            setIsLoading(false);
            return;
        };

        setIsLoading(true);
        apiRequest(APIs.GET_TOXIC_CLIENT_LABEL_LIST, { labelId: id })
            .then((res: ToxicClientLabelProps) => {
                fillForm(res);
                setIsDeletable(res.labelEntryCount === 0 ? true : false);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("toxic client label", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                props.callback && props.callback(InnerPageActionMode.BACK);
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        getToxicClientLabelDetails(props.id);
        return () => { };
    }, [props.resetState]);

    return (
        <SitePageHeaderInline
            title={isAddAction ? "Create New Toxic Client Label" : "Edit Toxic Client Label"}
            callback={(action: SitePageHeaderInlineActionType) => {
                switch (action) {
                    case SitePageHeaderInlineActionType.OnBack:
                    case SitePageHeaderInlineActionType.OnCancel:
                        tcLabelForm.resetFields();
                        props.callback && props.callback(InnerPageActionMode.BACK);
                        return;
                    default:
                        break;
                }
            }}
            extraProps={{
                extra: [
                    (tcLabelForm.getFieldValue("isLabelActive") === 0 ||
                        tcLabelStatus === 0 ||
                        tcLabelForm.getFieldValue("isLabelActive") === false) &&
                        tcLabelForm.getFieldValue("labelAttrs").some((attribute: any) => attribute.isAttrActive === true) ? (
                        <Popconfirm
                            key={"status-confirmation"}
                            title={
                                <div>
                                    Attribute Status will automatically set to Inactive when Label Status is Inactive, <br />
                                    Are you sure you want to continue?
                                </div>
                            }
                            onConfirm={() => {
                                tcLabelForm
                                    .validateFields()
                                    .then((res) => onSubmit(res))
                                    .catch((err) => console.log("form-error:", err));
                            }}
                            okText="Yes"
                            cancelText="No"
                            placement="left"
                        >
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Popconfirm>
                    ) : ([
                        <Button key={"regular-submit"} type="primary" htmlType="submit" onClick={() => {
                            tcLabelForm
                                .validateFields()
                                .then((res) => onSubmit(res))
                                .catch((err) => console.log("form-error:", err));
                        }}>
                            Submit
                        </Button>
                    ]),
                    <Button key={"cancel"} onClick={() => {
                        tcLabelForm.resetFields();
                        props.callback && props.callback(InnerPageActionMode.BACK)
                    }}>
                        Cancel
                    </Button>,
                    isAddAction || !isDeletable ? []
                        : [
                            <Popconfirm
                                key={"cetmp-del"}
                                title="Confirm to delete?"
                                onConfirm={() => {
                                    setIsLoading(true);
                                    apiRequest(APIs.DELETE_TOXIC_CLIENT_LABEL, { labelId: props.id })
                                        .then((res: any) => {
                                            ErrorMessageHandler("Toxic client label", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                                            setTimeout(() => {
                                                props.callback && props.callback(InnerPageActionMode.BACK, { refreshMainList: true })
                                                tcLabelForm.resetFields();
                                            }, 400);
                                        })
                                        .catch((error: any) => {
                                            ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("toxic client label", SUCCESS_FAILED.FAILED_DELETE_DATA, err));
                                        })
                                        .finally(() => setIsLoading(false));
                                }}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button type="primary" danger>
                                    Delete Toxic Client Label
                                </Button>
                            </Popconfirm>,
                        ],
                ],
            }}
        >
            {
                isLoading ? (
                    <div className="loading-container" >
                        <LoadingComponent tip="Loading..." />
                    </div>
                ) : (
                    <div className="toxic-client-label-create-edit-form">
                        <Form form={tcLabelForm} layout="horizontal" initialValues={{}} onFinish={onSubmit}>
                            <Row>
                                <Col span={24} className="mg-btm-title custom-subtitle">
                                    Label Information
                                </Col>
                            </Row>
                            <Row justify={"space-evenly"}>
                                <Col span={11} className="main-panel-left">
                                    <FormComponent label={""} name={"labelId"} extra={{ type: ComponentType.hidden, value: "" }} />
                                    <FormComponent
                                        label="Name"
                                        name="labelName"
                                        extra={{
                                            type: ComponentType.text,
                                            value: "",
                                            itemProps: {
                                                labelAlign: "left",
                                                labelCol: { span: 6 },
                                            },
                                            rules: [{ required: true, message: REQUIRED_FIELD }],
                                        }}
                                    />
                                    <Row>
                                        <Col span={24}>
                                            <FormComponent
                                                label="Description"
                                                name="labelDescription"
                                                extra={{
                                                    type: ComponentType.text,
                                                    value: "",
                                                    itemProps: {
                                                        labelAlign: "left",
                                                        labelCol: { span: 6 },
                                                    },
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <FormComponent
                                                label="Colour"
                                                name="labelColor"
                                                extra={{
                                                    type: ComponentType.dropdown,
                                                    value: colours.map((x: ColourListProps) => ({
                                                        text: (
                                                            <Row>
                                                                <Col span={2} style={{ margin: "5px 0" }}>
                                                                    <div style={{ width: 18, height: 18, backgroundColor: x.hexCode, padding: 5 }}></div>
                                                                </Col>
                                                                <Col>{x.name}</Col>
                                                            </Row>
                                                        ),
                                                        value: x.hexCode
                                                    })),
                                                    itemProps: {
                                                        labelAlign: "left",
                                                        labelCol: { span: 6 },
                                                    },
                                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                                    inputProps: {
                                                        showSearch: false,  // false for now because list can't be searched due to html structure of options -Xin
                                                    }
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <FormComponent
                                                label="Is Symbol Required"
                                                name="isSymbolRequired"
                                                extra={{
                                                    type: ComponentType.switch,
                                                    value: ["No", "Yes"],
                                                    itemProps: {
                                                        labelAlign: "left",
                                                        labelCol: { span: 6 },
                                                    },
                                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <FormComponent
                                                label="Allow SD Over IP"
                                                name="isAllowSdOverIp"
                                                extra={{
                                                    type: ComponentType.switch,
                                                    value: ["No", "Yes"],
                                                    itemProps: {
                                                        labelAlign: "left",
                                                        labelCol: { span: 6 },
                                                        tooltip: "Suggested Deduction (SD), Illegal Profits (IP)",
                                                    },
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <FormComponent
                                                label="Status"
                                                name="isLabelActive"
                                                extra={{
                                                    type: ComponentType.switch,
                                                    value: ["Inactive", "Active"],
                                                    itemProps: {
                                                        labelAlign: "left",
                                                        labelCol: { span: 6 },
                                                    },
                                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={12} className="main-panel-right">
                                    <div className="toxic-label-attribute-container">
                                        <Row>
                                            <Col className="title-div" span={24}>
                                                <h1>Attributes</h1>
                                                <div>
                                                    <Button type="dashed" onClick={() => onAddEditLabelAttributeClicked(true)} icon={<PlusOutlined />}>
                                                        Add Attribute
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <div className="list-item-div">
                                                    <Form.List name={"labelAttrs"}>
                                                        {(fields, { add, remove }) => {
                                                            return fields.length > 0 ? (
                                                                <>
                                                                    {fields.map((field, index) => {
                                                                        let currAttribute: AttributeProps[] = tcLabelForm.getFieldValue("labelAttrs");
                                                                        return (
                                                                            <div key={field.key} className="item-container">
                                                                                <div className="left-panel">
                                                                                    <div className="icon-panel">{index + 1}</div>
                                                                                    <div>
                                                                                        <div className="title">
                                                                                            {currAttribute[index].attrName}
                                                                                            {currAttribute[index].isMandatory && <div className="required-mark">*</div>}
                                                                                        </div>
                                                                                        <div className="subtitle">{currAttribute[index].attrDescription} - {TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE[currAttribute[index].uiElementType]}</div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="right-panel">
                                                                                    <div className="status-desc">
                                                                                        <span className={currAttribute[index].isAttrActive ? "active" : ""}>
                                                                                            {currAttribute[index].isAttrActive ? "Active" : "Inactive"}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="buttons-group">
                                                                                        <Button
                                                                                            type="link"
                                                                                            icon={<EditOutlined />}
                                                                                            onClick={() => onAddEditLabelAttributeClicked(false, currAttribute[index])}
                                                                                        />
                                                                                        <Popconfirm
                                                                                            key={"delete-attr-confirm"}
                                                                                            title={
                                                                                                <div>
                                                                                                    Some entry records may utilize this attribute, thus its deletion could result in data loss. <br />
                                                                                                    Are you sure you want to continue?
                                                                                                </div>
                                                                                            }
                                                                                            onConfirm={() => remove(index)}
                                                                                            okText="Yes"
                                                                                            cancelText="No"
                                                                                            placement="left"
                                                                                        >
                                                                                            <Button
                                                                                                type="link"
                                                                                                danger
                                                                                                icon={<DeleteOutlined />}
                                                                                            />
                                                                                        </Popconfirm>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </>
                                                            ) : (
                                                                <EmptyData />
                                                            );
                                                        }}
                                                    </Form.List>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                )}

            <Modal
                width="50vw"
                destroyOnClose
                maskClosable={false}
                title={tcLabelAttrTitle}
                open={showAddLabelAttributeModal}
                onOk={() => {
                    tcLabelAttrForm
                        .validateFields()
                        .then((values: any) => {
                            let attributes: AttributeProps[] = [...tcLabelForm.getFieldValue("labelAttrs")];
                            let findIdx = attributes.findIndex((x) => x.attrId === values["attrId"]);
                            let newValues: any = {};
                            if (findIdx > -1) {
                                if (values.uiElementType === TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.Checkbox) {
                                    newValues = {
                                        ...values,
                                        isMultiselectable: true,
                                    };
                                    attributes[findIdx] = newValues;
                                } else {
                                    attributes[findIdx] = values;
                                };
                            } else {
                                if (values.uiElementType === TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.Checkbox) {
                                    newValues = {
                                        ...values,
                                        isMultiselectable: true,
                                    };
                                    attributes.push(newValues);
                                } else {
                                    attributes.push(values);
                                };
                            }
                            tcLabelForm.setFieldValue("labelAttrs", attributes);
                            setShowAddLabelAttributeModal(false);
                            tcLabelAttrForm.resetFields();
                        })
                        .catch((err: any) => {
                            console.log("Validate failed: ", err);
                        });
                }}
                onCancel={() => {
                    tcLabelAttrForm.resetFields();
                    setShowAddLabelAttributeModal(false);
                }}
            >
                <AttributeCreateEdit form={tcLabelAttrForm} isAddAction={isAddAction} isAddLabelAttr={isAddLabelAttr} data={{}} />
            </Modal>
        </SitePageHeaderInline >
    )
}

export default ToxicClientLabelCreateEdit;