import {
    CheckCircleOutlined,
    DeleteOutlined,
    DoubleRightOutlined,
    DownloadOutlined,
    HomeOutlined,
    SyncOutlined,
    WarningOutlined,
} from "@ant-design/icons";
import FlexiDataTable from "../../../components/FlexiDataTable";
import { DTColProps, DataTableColumnRender, ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";
import { SUCCESS_FAILED } from "../../../constants";
import {
    FlexiDataTableOptionsProps,
    HubSwitcherHistoricalItemSecurity,
    HubSwitcherHistoricalSignalRMessage,
    statusObjectType,
} from "../../../constants/type";
import { Button, Space, Typography, message } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SitePageHeader from "../../../components/PageHeader";
import { APIs, apiRequest } from "../../../services/apiConfig";
import { useEffect, useMemo, useRef, useState } from "react";
import loading_gif from "../../../assets/images/loading1.gif";
import SignalRHelper from "../../../helpers/signalRHelper";
import { getModeSource } from ".";
import { isEmptyOrNull } from "../../../utils/string";
import { CSVLink } from "react-csv";
import { checkHubSwitchingAvailable } from "../../../services/localStorage";
import { isArray } from "lodash";
const { Text } = Typography;

const HubSwitcherPreview = () => {
    let navigate = useNavigate();
    let location = useLocation();
    let com_state = location.state as any;
    let { id } = useParams();

    const isWaitingProgressState = useMemo(() => id !== undefined && typeof id === "string", [id]);
    const [sRConnection] = useState<SignalRHelper>(new SignalRHelper("tp-hubFailoverSwitchHub", `/hubFailoverSwitchHub`));
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<any>([]);
    const [isSwitching, setIsSwitching] = useState<boolean>(false);
    const [statusData, setStatusData] = useState<statusObjectType>({});
    const isAllDone = useRef<boolean>(false);

    const columnConfig = useMemo(
        () => [
            DTColProps.XSmall({
                title: "Switch Mode",
                dataIndex: "switchMode",
                key: "switchMode",
                options: {
                    visible: false,
                },
            }),
            DTColProps.XSmall({
                title: "Batch Created At",
                dataIndex: "createdDateUtc",
                key: "createdDateUtc",
                options: {
                    visible: false,
                },
            }),
            DTColProps.XSmall({
                title: "Batch Completed At",
                dataIndex: "batchCompletedDateUtc",
                key: "batchCompletedDateUtc",
                options: {
                    visible: false,
                },
            }),
            DTColProps.Middle({
                title: "Server",
                dataIndex: "server",
                key: "server",
            }),
            {
                title: "OZ & PE Manager Security",
                dataIndex: "security",
                key: "security",
            },
            DTColProps.Middle({
                title: "OZ Manager Status",
                dataIndex: "sourceStatus",
                key: "sourceStatus",
                render: (sourceStatus: number, rowData: any) => {
                    if (id) {
                        return (
                            <Space>
                                <Text>{rowData.sourceStatusFrom === 1 ? "Active" : "Inactive"}</Text>
                                <DoubleRightOutlined />
                                {rowData.sourceStatusTo === 1 ? <Text type="success">Active</Text> : <Text type="danger">Inactive</Text>}
                            </Space>
                        );
                    } else {
                        const isActive: boolean = sourceStatus === 1;
                        return (
                            <Space>
                                <Text>{isActive ? "Active" : "Inactive"}</Text>
                                <DoubleRightOutlined />
                                {!isActive ? <Text type="success">Active</Text> : <Text type="danger">Inactive</Text>}
                            </Space>
                        );
                    }
                },
            }),
            DTColProps.Middle({
                title: "PE Manager Status",
                dataIndex: "targetStatus",
                key: "targetStatus",
                render: (targetStatus: number, rowData: any) => {
                    if (id) {
                        return (
                            <Space>
                                <Text>{rowData.targetStatusFrom === 1 ? "Active" : "Inactive"}</Text>
                                <DoubleRightOutlined />
                                {rowData.targetStatusTo === 1 ? <Text type="success">Active</Text> : <Text type="danger">Inactive</Text>}
                            </Space>
                        );
                    } else {
                        const isActive: boolean = targetStatus === 1;
                        return (
                            <Space>
                                <Text>{isActive ? "Active" : "Inactive"}</Text>
                                <DoubleRightOutlined />
                                {!isActive ? <Text type="success">Active</Text> : <Text type="danger">Inactive</Text>}
                            </Space>
                        );
                    }
                },
            }),
            DTColProps.Large({
                title: "Progress",
                dataIndex: "progressStatus",
                key: "progressStatus",
                render: (progressStatus: number, rowData: any) => {
                    return rowData?.hasOwnProperty("subId") && statusData?.hasOwnProperty(rowData.subId) ? (
                        isEmptyOrNull(statusData[rowData.subId].completedDateUtc) ? (
                            <img src={loading_gif} alt="switching" style={{ height: 30 }} />
                        ) : statusData[rowData.subId].status === 2 ? (
                            <CheckCircleOutlined style={{ color: "#52C41A", fontSize: "1.25rem" }} />
                        ) : (
                            <Text type="danger">
                                <WarningOutlined /> Failed
                                <div>{statusData[rowData.subId].error}</div>
                            </Text>
                        )
                    ) : (
                        <></>
                    );
                },
            }),
            DTColProps.XSmall({
                title: "Initiated At",
                dataIndex: "initDateUtc",
                key: "initDateUtc",
                options: {
                    visible: false,
                },
            }),
            DTColProps.XSmall({
                title: "Progress Completed At",
                dataIndex: "progressCompletedDateUtc",
                key: "progressCompletedDateUtc",
                options: {
                    visible: false,
                },
            }),
            DTColProps.XSmall({
                title: "Created By",
                dataIndex: "createdByName",
                key: "createdByName",
                options: {
                    visible: false,
                },
            }),
            ...(!isWaitingProgressState
                ? [
                    DTColProps.XSmall(
                        {
                            title: "",
                            dataIndex: "",
                            key: "delete",
                            width: "3vw",
                            render: (rowData: any) => (
                                <DeleteOutlined
                                    style={{ color: "#f00f00", fontSize: "1.125rem" }}
                                    onClick={() => setData((prev: any[]) => prev.filter(y => y.key !== rowData.key))}
                                />
                            ),
                        },
                        ["text-center"]
                    ),
                ]
                : []),
        ],
        [isWaitingProgressState, statusData]
    );

    const wholeData: any = useMemo(() => {
        if (data.length > 0) {
            let newData = data.map((x: any) => {
                if (statusData?.hasOwnProperty(x.batchId)) {
                    let currBatchStatusObj = statusData[x.batchId];
                    return {
                        batchCompletedDateUtc: currBatchStatusObj.completedDateUtc,
                        ...x,
                        key: `${x.serverId}-${x.security}`,
                    };
                } else if (x?.hasOwnProperty("completedDateUtc")) {
                    return {
                        batchCompletedDateUtc: x.completedDateUtc,
                        ...x,
                        key: `${x.serverId}-${x.security}`,
                    };
                } else
                    return {
                        ...x,
                        key: `${x.serverId}-${x.security}`,
                    };
            });
            return newData;
        } else {
            return [];
        }
    }, [statusData, data]);

    const options: FlexiDataTableOptionsProps = useMemo(
        () => ({
            showHideColumns: false,
            enableFilter: false,
            ...(isWaitingProgressState && {
                export: {
                    text: "",
                    Element: (
                        <CSVLink
                            key={`btn-export-${Math.random()}`}
                            data={wholeData.map((y: any) => {
                                return {
                                    ...y,
                                    sourceStatus: y.sourceStatusFrom === 1 ? "Active >> Inactive" : "Inactive >> Active",
                                    targetStatus: y.targetStatusFrom === 1 ? "Active >> Inactive" : "Inactive >> Active",
                                    progressStatus: statusData?.hasOwnProperty(y.subId)
                                        ? isEmptyOrNull(statusData[y.subId].completedDateUtc)
                                            ? statusData[y.subId].status === 0
                                                ? "Pending"
                                                : "In Progress"
                                            : statusData[y.subId].status === 2
                                                ? "Success"
                                                : `Failed - ${statusData[y.subId].error}`
                                        : null,
                                    createdDateUtc: DataTableColumnRender.DateTime(y.createdDateUtc),
                                    initDateUtc: DataTableColumnRender.DateTime(y.initDateUtc),
                                    batchCompletedDateUtc: DataTableColumnRender.DateTime(y.batchCompletedDateUtc),
                                    progressCompletedDateUtc: statusData?.hasOwnProperty(y.subId)
                                        ? isEmptyOrNull(statusData[y.subId].completedDateUtc)
                                            ? ""
                                            : DataTableColumnRender.DateTime(statusData[y.subId].completedDateUtc)
                                        : null,
                                };
                            })}
                            headers={columnConfig.map(x => {
                                return { label: x.title, key: x.key };
                            })}
                            filename={`HubSwitcherHistorical-${new Date().getTime()}.csv`}
                        >
                            <Button htmlType="button" icon={<DownloadOutlined style={{ fontSize: "0.875rem" }} />}>
                                Export as CSV
                            </Button>
                        </CSVLink>
                    ),
                },
            }),
        }),
        [isWaitingProgressState, wholeData, columnConfig, isAllDone.current]
    );

    const startSwitching = () => {
        let postParam = { ...getModeSource("1"), managerSecurities: data },
            hexParam = btoa(JSON.stringify(postParam));

        if (!checkHubSwitchingAvailable(hexParam)) {
            message.warning("Switching process too frequent. Please try again shortly.");
            return;
        }

        setIsLoading(true);
        setIsSwitching(true);
        apiRequest(APIs.SUBMIT_HUBFAILOVER_SECURITIES_SWITCHES, postParam)
            .then((res: any) => navigate(`/admintools/hubSwitcher/preview/${res.batchId}`, { replace: true }))
            .catch(error => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("start switching", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                setIsSwitching(false);
            })
            .finally(() => setIsLoading(false));
    };

    const getListByBatchId = (batchId: string, priority: boolean = false) => {
        apiRequest(APIs.GET_HUBSWITCHER_HISTORICAL_LIST, {
            ...getModeSource("1"),
            batchId,
            current: 1,
            limit: 1,
        })
            .then((res: any) => {
                if (res && res.result[0].managerSecurities.length > 0) {
                    let newStatusObj: statusObjectType = (res.result[0].managerSecurities as HubSwitcherHistoricalItemSecurity[]).reduce(
                        (finalObj: statusObjectType, x: HubSwitcherHistoricalItemSecurity) => {
                            finalObj[x.subId] = {
                                status: x.progressStatus,
                                error: x.remarks,
                                completedDateUtc: x.completedDateUtc || "",
                            };
                            return finalObj;
                        },
                        {}
                    );
                    if (data.filter((x: any) => x.progressStatus === 1 || x.progressStatus === 0).length === 0) {
                        isAllDone.current = true;
                    }
                    if (priority) {
                        setStatusData(prev => ({ ...prev, ...newStatusObj }));
                    } else {
                        setStatusData(prev => ({ ...newStatusObj, ...prev }));
                        setData(res.result[0].managerSecurities.map((x: any) => ({ ...res.result[0], ...x, key: `${x.serverId}-${x.security}` })));
                    }
                } else {
                    if (!priority) {
                        setData([]);
                    }
                }
            })
            .catch(error =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("get hub switcher batch detail", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            );
    };

    useEffect(() => {
        const secondInterval = setInterval(() => isWaitingProgressState && !isAllDone.current && getListByBatchId(id as string, true), 2000);
        if (isWaitingProgressState) {
            sRConnection.setHandler((hubConnection: any) => {
                hubConnection.on("HubFailoverSwitchStream", (message: any) => {
                    if (message.events.length > 0) {
                        let tmpObj: statusObjectType = (message.events as HubSwitcherHistoricalSignalRMessage[]).reduce(
                            (finalObj: statusObjectType, x: HubSwitcherHistoricalSignalRMessage) => {
                                finalObj[x.uid] = { status: x.status, error: x.remarks, completedDateUtc: x.completedDateUtc };
                                return finalObj;
                            },
                            {}
                        );
                        setStatusData(prev => ({ ...prev, ...tmpObj }));
                    }
                });
            });
            sRConnection.startConnection();

            getListByBatchId(id as string);
        } else {
            if (com_state !== null) {
                setData(com_state.data.map((x: any) => ({ ...x, key: `${x.serverId}-${x.security}` })));
            } else {
                window.addEventListener("message", handleMessage);
            };
        };
        return () => {
            clearInterval(secondInterval);
            window.removeEventListener("message", handleMessage);
        };
    }, [id]);

    const handleMessage = (event: MessageEvent) => {
        const data = event.data;
        if (isArray(data)) {
            setData(data.map((x: any) => ({ ...x, key: `${x.serverId}-${x.security}` })));
        }
    };

    return (
        <>
            <SitePageHeader
                title={"Preview"}
                routes={[
                    {
                        path: "/admintools/hubSwitcher",
                        breadcrumbName: "Hub Failover Switcher List",
                        icon: <HomeOutlined />,
                    },
                    { path: "", breadcrumbName: "Preview" },
                ]}
                onBack={() => navigate("/admintools/hubSwitcher")}
                {...(!isWaitingProgressState && {
                    extraProps: {
                        extra: [
                            <Button type="primary" key="btn-hubswitch" disabled={isSwitching} onClick={() => startSwitching()}>
                                {isSwitching ? (
                                    <>
                                        <SyncOutlined spin /> Switching
                                    </>
                                ) : (
                                    "Start Switching"
                                )}
                            </Button>,
                        ],
                    },
                })}
            >
                <FlexiDataTable
                    rowKeyProperty="key"
                    title={false}
                    columns={columnConfig}
                    options={options}
                    pagination={false}
                    dataSource={data}
                    loading={isLoading}
                    callback={() => { }}
                />
            </SitePageHeader>
        </>
    );
};

export default HubSwitcherPreview;
