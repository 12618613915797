import { Form } from "antd";
import { FormInstance } from "antd/es/form";
import { FormComponent } from "../../../../components/FormComponent";
import { ComponentType } from "../../../../constants";
import { REQUIRED_FIELD } from "../../../../constants/errorMessage";
import AlarmModalWrapperComponent from "./wrapperComponent";
import { isEmptyOrNull } from "../../../../utils/string";

export interface AlarmDashboardLeaveCommentProps {
    form: FormInstance;
    statusOptions: any[];
}

const AlarmDashboardLeaveComment = (props: AlarmDashboardLeaveCommentProps) => {
    const clientUsedCommentType = Form.useWatch("clientUsedCommentType", props.form);

    const modeOptions = [
        { text: "Apply to current event", value: 1 },
        { text: "Apply to all related events", value: 2 },
        { text: "Apply to all related events before current event", value: 3 },
    ];
    return (
        <AlarmModalWrapperComponent>
            <div className="leave-comment-history">
                <Form form={props.form} labelCol={{ span: 6 }} labelAlign="left" wrapperCol={{ span: 18 }} layout="horizontal">
                    <FormComponent label="" name="clientUsedCommentType" extra={{ type: ComponentType.hidden, value: "" }} />
                    <FormComponent label="" name="alarmEventId" extra={{ type: ComponentType.hidden, value: "" }} />
                    <FormComponent label="" name="correlationHashKey" extra={{ type: ComponentType.hidden, value: "" }} />
                    <FormComponent label="" name="moduleCode" extra={{ type: ComponentType.hidden, value: "" }} />
                    {isEmptyOrNull(clientUsedCommentType) ? (
                        <>
                            <FormComponent label="Mode" name="mode" extra={{ type: ComponentType.dropdown, value: modeOptions }} />
                            {props.statusOptions.length > 0 ? (
                                <FormComponent
                                    label="Status"
                                    name="statusCode"
                                    extra={{ type: ComponentType.dropdown, value: props.statusOptions }}
                                />
                            ) : (
                                <FormComponent label="" name="statusCode" extra={{ type: ComponentType.hidden, value: "" }} />
                            )}
                        </>
                    ) : (
                        <>
                            <FormComponent label="Mode" name="mode" extra={{ type: ComponentType.hidden, value: "" }} />
                            <FormComponent label="" name="statusCode" extra={{ type: ComponentType.hidden, value: "" }} />
                        </>
                    )}

                    <FormComponent
                        label="Comment"
                        name="comment"
                        extra={{
                            type: ComponentType.textarea,
                            value: "",
                            rules: [{ required: true, message: REQUIRED_FIELD }],
                            inputProps: {
                                row: 6,
                            },
                        }}
                    />
                </Form>
            </div>
        </AlarmModalWrapperComponent>
    );
};

export default AlarmDashboardLeaveComment;
