import TabContent from "../../SystemMonitor/components/TabContent";
import { Space, Typography } from "antd";
import TimeDiff from "../../SystemMonitor/components/TimeDiff";
import MidBiasSolvedTable from "./MidBiasSolvedTable";
import useRCMidBiasSolved from "@/hooks/useRCMidBiasSolved";
import { useState } from "react";

const { Title } = Typography;

const TabSolved = () => {
    const [dateFrom] = useState<any>(new Date());
    const { isLoading, refetchRcMidBiasSolved, dataUpdatedAt, massagedData } = useRCMidBiasSolved({ duration: "1day", from: dateFrom });

    return (
        <TabContent>
            <div style={{ padding: "1rem" }}>
                <div style={{ display: "flex", alignItems: "center", marginBottom: "1rem", gap: 16 }}>
                    <Space style={{ alignItems: "center" }} wrap>
                        <Title level={2} style={{ padding: 0, margin: 0 }}>
                            Mid Bias Solved
                        </Title>
                        <TimeDiff timestamp={dataUpdatedAt} refetch={refetchRcMidBiasSolved} isFetching={isLoading} rounded />
                    </Space>
                </div>
                <MidBiasSolvedTable data={massagedData} isLoading={isLoading} />
            </div>
        </TabContent>
    );
};

export default TabSolved;
