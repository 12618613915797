import React from "react";
import "./index.less";

export interface CardBoxProps {
    title: string;
    children?: React.ReactNode;
    extra?: React.ReactNode;
    small?: boolean;
}

const CardBox = (props: CardBoxProps) => {
    return (
        <div className={`card-box-container ${props.small ? "small" : ""}`}>
            <div className="inner-container">
                <div className="card-box-header">
                    <div className="left-panel">{props.title}</div>
                    <div className="right-panel">{props.extra}</div>
                </div>
                <div className="card-box-content">
                    <div>{props.children}</div>
                </div>
            </div>
        </div>
    );
};

export default CardBox;
