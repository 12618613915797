import React, { useRef } from "react";
import BasicFilter from "../../../../components/BasicFilter";
import useRCIssuesLogStore from "../../../../store/useRCIssuesLogStore";
import { Col, DatePicker, Form, Row, Typography } from "antd";
import moment from "moment-timezone";
const NoMoneyMailTableFilter = () => {
    const noMoneyFilter = useRCIssuesLogStore(state => state.noMoney.mailFilter);
    const updateNoMoneyMailFilter = useRCIssuesLogStore(state => state.updateNoMoneyMailFilter);

    const handleFilterChange = (newFilter: Partial<typeof noMoneyFilter>) => {
        updateNoMoneyMailFilter(newFilter);
    };

    const [form] = Form.useForm();
    const { Item } = Form;
    const formRef = useRef<any>(null);
    const { Text } = Typography;
    const { RangePicker } = DatePicker;

    const initialValue = {
        // get from store
        range: noMoneyFilter.startTime !== "" && noMoneyFilter.endTime !== "" ? [moment(noMoneyFilter.startTime), moment(noMoneyFilter.endTime)] : [],
    };

    return (
        <BasicFilter>
            <Form ref={formRef} form={form} layout="vertical" initialValues={initialValue} requiredMark={true}>
                <Row gutter={[8, 8]}>
                    <Col span={12} xs={{ span: 24 }} md={{ span: 12 }}>
                        <Text>Date Range *</Text>
                        <Item style={{ margin: 0, padding: 0 }} name="range" rules={[{ required: true, message: "Please select date range" }]}>
                            <RangePicker
                                style={{ width: "100%" }}
                                // disable selecting the day after today, and 20 days before today
                                disabledDate={current => {
                                    return current && (current > moment().endOf("day") || current < moment().subtract(20, "days").startOf("day"));
                                }}
                                onChange={data => {
                                    const startDate = data?.[0] !== undefined ? moment(data[0]).format("YYYY-MM-DD") : "";
                                    const endDate = data?.[1] !== undefined ? moment(data[1]).format("YYYY-MM-DD") : "";
                                    handleFilterChange({ startTime: startDate, endTime: endDate });
                                    form.setFieldsValue({ range: data });
                                }}
                            />
                        </Item>
                    </Col>
                </Row>
            </Form>
        </BasicFilter>
    );
};

export default NoMoneyMailTableFilter;
