import { Empty, Skeleton, Tag } from "antd";
import ChartPanel from "./chartPanel";
import { TagsOutlined } from "@ant-design/icons";
import { DateRangeFilter, TagSummary } from "../../../../constants/type";
import { useEffect, useRef, useState } from "react";
import { Pie } from "@ant-design/charts";
import { apiRequest, APIs } from "../../../../services/apiConfig";
import LoadingComponent from "../../../../components/Loading";
import { ProfileInfoProps } from "../viewProfile";
import { ErrorCatchValidator } from "../../../../utils/Common";
import EmptyData from "../../../../components/Common/Empty";
import CustomSkeleton from "../../../../components/Common/Skeleton";

export interface TagSummaryProps extends ProfileInfoProps {
    forPrint?: boolean;
}

const TaggingSummary = (props: TagSummaryProps) => {
    const ref = useRef<HTMLHeadingElement>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<TagSummary[]>([]);
    const config = {
        appendPadding: 10,
        data,
        angleField: "tagCount",
        colorField: "tagName",
        radius: 0.8,
        label: {
            type: "outer",
            content: "{name} {percentage}",
        },
        interactions: [
            {
                type: "pie-legend-active",
            },
            {
                type: "element-active",
            },
        ],
    };

    const getChartData = (dateFilter: any) => {
        apiRequest(APIs.GET_TAGGING_SUMMARY, {
            accountId: props?.accountId,
            brandId: props?.brandId,
            serverId: props?.serverId,
            ...(dateFilter && dateFilter),
        })
            .then((data: any) => {
                setData(data);
            })
            .catch((error) => {
                ErrorCatchValidator(error, (err: any) => console.log(err));
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getChartData(props.dateRangeFilter);
        return () => {};
    }, [props.dateRangeFilter]);

    return (
        <>
            {isLoading ? (
                <ChartPanel
                    forPrint={props.forPrint}
                    type={1}
                    icon={<TagsOutlined />}
                    title={"Tagging Summary"}
                    subTitle={"Overall tagging summary for current account"}
                >
                    <CustomSkeleton rows={9} />
                </ChartPanel>
            ) : (
                <ChartPanel
                    forPrint={props.forPrint}
                    type={1}
                    icon={<TagsOutlined />}
                    title={"Tagging Summary"}
                    subTitle={"Overall tagging summary for current account"}
                    rightExtra={
                        data?.length > 0
                            ? [
                                  {
                                      title: "Tagged Count",
                                      content: (
                                          <div className="tag-summary-desc-panel">
                                              {data &&
                                                  data.map((x, index) => {
                                                      return (
                                                          <div className="value-small-tag-div" key={`ts-${index}`}>
                                                              <div className="title">{x.tagName}</div>
                                                              <div className="tagDiv">
                                                                  <Tag>{x.tagCount}</Tag>
                                                              </div>
                                                          </div>
                                                      );
                                                  })}
                                          </div>
                                      ),
                                  },
                              ]
                            : []
                    }
                >
                    {data?.length > 0 ? (
                        props.forPrint ? (
                            <Pie {...config} height={300} />
                        ) : (
                            <div ref={ref} className="pie-chart">
                                <Pie {...config} width={ref.current?.clientWidth} />
                            </div>
                        )
                    ) : (
                        <>
                            <EmptyData />
                        </>
                    )}
                </ChartPanel>
            )}
        </>
    );
};

export default TaggingSummary;
