import { HomeOutlined } from "@ant-design/icons";
import { Form, Popconfirm, Button, Row, Col } from "antd";
import { useState, useEffect } from "react";
import { useNavigate, useParams, NavigateFunction } from "react-router-dom";
import { FormComponent } from "../../../../components/FormComponent";
import LoadingComponent from "../../../../components/Loading";
import SitePageHeader from "../../../../components/PageHeader";
import { SUCCESS_FAILED, ComponentType } from "../../../../constants";
import { REQUIRED_FIELD } from "../../../../constants/errorMessage";
import { apiRequest } from "../../../../services/apiConfig";
import { APIs } from "../../../../services/apis";
import { ErrorMessageHandler, ErrorCatchValidator } from "../../../../utils/Common";
import { ServerState } from "../../ServersPage/ServerCreateEditPage";
import { SymbolAssetTypesList } from "../../../../constants/type";

export interface CleanSymbolCreateEditPageProps {}

const CleanSymbolCreateEditPage = () => {
    let navigate = useNavigate();
    let { id } = useParams();

    const [currentState] = useState<ServerState>(id ? { action: "edit", data: id } : { action: "add", data: null });
    const [isAddAction, setIsAddAction] = useState<boolean>(currentState.action === "add");
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
    const [symbolAssetType, setSymbolAssetType] = useState<SymbolAssetTypesList[]>([]);
    const [cleanSymbolForm] = Form.useForm();

    const onSubmit = (values: any) => {
        setIsBtnLoading(true);
        if (isAddAction) {
            apiRequest(APIs.CREATE_CLEAN_SYMBOL, values)
                .then(() => {
                    ErrorMessageHandler("New clean symbol", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                    navigate("/siteadmin/symbolconfig/cleansymbol");
                })
                .catch(error =>
                    ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("new clean symbol", SUCCESS_FAILED.FAILED_CREATE_DATA, err))
                )
                .finally(() => setIsBtnLoading(false));
        } else {
            apiRequest(APIs.UPDATE_CLEAN_SYMBOL, values)
                .then(() => {
                    ErrorMessageHandler("Existing clean symbol", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                    navigate("/siteadmin/symbolconfig/cleansymbol");
                })
                .catch(error =>
                    ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("existing clean symbol", SUCCESS_FAILED.FAILED_UPDATE_DATA, err))
                )
                .finally(() => setIsBtnLoading(false));
        }
    };

    const getCleanSymbolList = () => {
        setIsLoading(true);
        apiRequest(APIs.GET_CLEAN_SYMBOL_LISTING, { cleanSymbols: [currentState.data ? currentState.data : id] })
            .then((data: any) => {
                if (currentState.data || id) {
                    setIsAddAction(false);
                    cleanSymbolForm.setFieldsValue(data[0]);
                } else {
                    setIsAddAction(true);
                    cleanSymbolForm.resetFields();
                }
                setIsLoading(false);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("clean symbol", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                navigate("/siteadmin/symbolconfig/cleansymbol");
            });
    };

    const deleteCleanSymbol = (cleanSymbol: string, navigator: NavigateFunction) => {
        apiRequest(APIs.DELETE_CLEAN_SYMBOL, { cleanSymbol })
            .then(() => {
                ErrorMessageHandler("The clean symbol", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                navigator("/siteadmin/symbolconfig/cleansymbol");
            })
            .catch(error => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("clean symbol", SUCCESS_FAILED.FAILED_DELETE_DATA, err)));
    };

    const getConfigList = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["symbolassettype"] })
            .then(res => {
                setSymbolAssetType(res.symbolAssetTypes);
                isAddAction ? setIsLoading(false) : getCleanSymbolList();
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("config info", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                navigate("/siteadmin/symbolconfig/cleansymbol");
            });
    };

    useEffect(() => {
        getConfigList();
        return () => {};
    }, [isAddAction]);

    return (
        <SitePageHeader
            title={isAddAction ? "Create New Clean Symbol" : "Edit Clean Symbol"}
            routes={[
                {
                    path: "/siteadmin/symbolconfig/cleansymbol",
                    breadcrumbName: "Clean Symbol Listing",
                    icon: <HomeOutlined />,
                },
                {
                    path: "",
                    breadcrumbName: isAddAction ? "Create New Clean Symbol" : "Edit Clean Symbol",
                },
            ]}
            onBack={() => navigate("/siteadmin/symbolconfig/cleansymbol")}
            extraProps={{
                extra: isAddAction
                    ? []
                    : [
                          <Popconfirm
                              key={"cetmp-del"}
                              title="Confirm to delete?"
                              onConfirm={() => deleteCleanSymbol(currentState.data ? currentState.data : id, navigate)}
                              okText="Yes"
                              cancelText="No"
                          >
                              <Button type="primary" danger>
                                  Delete Clean Symbol
                              </Button>
                          </Popconfirm>,
                      ],
            }}
        >
            {isLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Loading..." />
                </div>
            ) : (
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    form={cleanSymbolForm}
                    layout="horizontal"
                    initialValues={currentState.data}
                    onFinish={onSubmit}
                >
                    <Row>
                        <Col span={15}>
                            <FormComponent label="" name="isMainStream" extra={{ type: ComponentType.hidden, value: "" }} />
                            <FormComponent
                                label="Clean Symbol"
                                name="cleanSymbol"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                    ...(!isAddAction && {
                                        inputProps: {
                                            readOnly: true,
                                        },
                                    }),
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Symbol Asset Type"
                                name="symbolAssetTypeId"
                                extra={{
                                    type: ComponentType.dropdown,
                                    value: symbolAssetType.map((x: SymbolAssetTypesList) => ({ text: x.name, value: x.id })),
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div className="step-btns-group">
                                <Button type="primary" htmlType="submit" loading={isBtnLoading}>
                                    Submit
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            )}
        </SitePageHeader>
    );
};

export default CleanSymbolCreateEditPage;
