import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQueryRC } from "../../axiosBaseQuery";
import { APIs } from "../../../services/apis";
import { PriceMonitorSpreadAlarmProps } from "../../../constants/type";

export interface PriceMonitorSpreadAlarmResponse {
    serverTime: string;
    data: PriceMonitorSpreadAlarmProps[];
}

export const priceMonitorSpreadAlarmApiSlice = createApi({
    reducerPath: "priceMonitorSpreadAlarm",
    baseQuery: axiosBaseQueryRC(),
    keepUnusedDataFor: 5, //global caching duration (seconds)
    endpoints: builder => ({
        getSpreadAlarmList: builder.query<PriceMonitorSpreadAlarmResponse, any>({
            keepUnusedDataFor: 0, //disable for caching for particular api request
            query: arg => ({ url: `${APIs.RC_PRICE_MONITOR.GET_SPREAD_ALARM_LIST}${arg.url}`, method: "GET" }),
            transformResponse: (returnValue: any) => returnValue.data,
        }),
        getResolvedAlarmList: builder.query<any, any>({
            keepUnusedDataFor: 0, //disable for caching for particular api request
            query: arg => ({ url: `${APIs.RC_PRICE_MONITOR.GET_RESOLVED_SPREAD_ALARM_LIST}${arg.url}`, method: "GET" }),
            transformResponse: (returnValue: any) => returnValue.data,
        }),
    }),
});

export const { useGetSpreadAlarmListQuery, useGetResolvedAlarmListQuery } = priceMonitorSpreadAlarmApiSlice;
