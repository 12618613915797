import React, { useRef } from "react";
import TabContent from "../components/TabContent";

import ServerPerformanceTable from "./ServerPerformanceTable";
import BasicFilter from "../../../components/BasicFilter";
import { Col, Row, Typography } from "antd";
import IgnoredServersButton from "./IgnoredServersButton";
import ServerPerformanceChart from "./ServerPerformanceChart";
import { motion, useScroll, useTransform } from "framer-motion";
import DownloadServerPerformanceButton from "./DownloadServerPerformanceButton";
import SoundButton from "../components/SoundButton";
import soundFile from "../../../assets/audios/rc/performance-alert.mp3";
import useRCServerPerformance from "../../../hooks/useRCServerPerformance";
import useRCServerPerformanceStore from "../../../store/useRCServerPerformanceStore";
const SPChart = () => {
    const chartRef = useRef(null);
    const { scrollYProgress } = useScroll({
        target: chartRef,
        offset: ["start end", "start 80vh"],
    });

    // use scrollYProgress to scale the chart, from 0.95 to 1

    const scale = useTransform(scrollYProgress, [0, 1], [0.95, 1]);

    return (
        <motion.div
            ref={chartRef}
            style={{
                zIndex: 1,
                background: "white",
                position: "relative",
                padding: "1rem",
                borderRadius: "0.5rem",
                // boxShadow: `0px 1.6px 3.6px rgba(0, 0, 0, 0.024), 0px 4.4px 10px rgba(0, 0, 0, 0.035), 0px 10.6px 24.1px rgba(0, 0, 0, 0.046), 0px 35px 80px rgba(0, 0, 0, 0.07)`,
                boxShadow: `
                0px 0.2px 0.7px rgba(0, 0, 0, 0.035),
                0px 0.6px 1.9px rgba(0, 0, 0, 0.05),
                0px 1.5px 4.5px rgba(0, 0, 0, 0.065),
                0px 5px 15px rgba(0, 0, 0, 0.1)
                `,
                marginTop: "2rem",
                // scale is from 0.5 to 1
                scale,
                minHeight: "85vh",
            }}
            initial={false}
            animate={
                {
                    // y: scrollYProgress * 50,
                }
            }
        >
            <ServerPerformanceChart />
        </motion.div>
    );
};
const TabServerPerformance = () => {
    const { Text } = Typography;
    const chartRef = useRef(null);
    const { scrollYProgress } = useScroll({
        target: chartRef,
        offset: ["0.65 end", "start 20vh"],
    });
    const opacity = useTransform(scrollYProgress, [0, 1], [1, 0.15]);
    const scale = useTransform(scrollYProgress, [0, 1], [1, 0.98]);

    const { rcServerPerformance } = useRCServerPerformance();
    const ignoreServers = useRCServerPerformanceStore(state => state.ignoredServers);
    const finalMassagedData = rcServerPerformance?.data?.filter(item => !ignoreServers.includes(item.serverName)) ?? [];

    return (
        <div style={{ marginBottom: "3.5rem" }}>
            <motion.div
                style={{ position: "sticky", top: 0, zIndex: 1, opacity, scale }}
                // animate={{
                //     y,
                // }}
            >
                <TabContent>
                    <BasicFilter
                        titleBarChildren={
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    flexWrap: "wrap",
                                    gap: "4px",
                                    alignItems: "center",
                                }}
                            >
                                <SoundButton soundSrc={soundFile} isActive={finalMassagedData && finalMassagedData?.length >= 1} />
                                <DownloadServerPerformanceButton />
                            </div>
                        }
                    >
                        <Row gutter={[8, 8]}>
                            <Col span={8} xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 8 }}>
                                <Text>Ignored Servers</Text>
                                <IgnoredServersButton />
                            </Col>
                        </Row>
                    </BasicFilter>
                    <ServerPerformanceTable />
                </TabContent>
            </motion.div>
            <div ref={chartRef}>
                <SPChart />
            </div>
        </div>
    );
};

export default TabServerPerformance;
