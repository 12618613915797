import { SearchOutlined } from "@ant-design/icons";
import { AutoComplete, Input } from "antd";
import { useEffect, useState } from "react";
import LoadingComponent from "../../../../../components/Loading";

interface FilterSearchInputProps {
    options: ESOption[] | Function;
    onSelect: (value: any) => void;
    placeholder?: string;
    inputType?: string;
    filterType?: number;
    serverSide?: boolean;
}

export enum filterOptionType {
    CONTAINS = 0,
    EXACT = 1,
}

export interface ESOption {
    label: string | React.ReactNode;
    value: string;
    options?: ESOption[];
}

const FilterSearchInput = (props: FilterSearchInputProps) => {
    const [optionList, setOptionList] = useState<ESOption[]>(props.serverSide ? [] : (props.options as ESOption[]));
    const [isServerSide] = useState<boolean>(props.serverSide || false);
    const [forceOpen, setForceOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const filterFunction = (searchText: string, opt: ESOption, filterType: number = filterOptionType.CONTAINS): boolean => {
        if (!props.serverSide) {
            switch (filterType) {
                case filterOptionType.EXACT:
                    return searchText === opt.value;
                default:
                    return opt.value.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
            }
        }
        return true;
    };

    const fetchData = (searchText: string) => {
        (props.options as Function)(searchText)
            .then((res: any) => setOptionList(res))
            .finally(() => {
                setForceOpen(true);
                setIsLoading(false);
            });
    };

    useEffect(() => {}, []);

    return (
        <AutoComplete
            options={optionList}
            filterOption={(inputValue: string, option: any) => filterFunction(inputValue, option, props.filterType)}
            onSelect={(value: any) => {
                props.serverSide && setOptionList([]);
                props.onSelect(value);
            }}
            {...(isServerSide
                ? forceOpen && { defaultOpen: true, notFoundContent: "No content was found", defaultActiveFirstOption: false }
                : {
                      notFoundContent: "No content was found",
                  })}
        >
            <Input
                className="filter-input"
                type={props.inputType || "text"}
                prefix={<SearchOutlined style={{ color: "#c4c3c3" }} />}
                placeholder={props.placeholder || ""}
                {...(isServerSide && {
                    onPressEnter: (e: any) => {
                        setIsLoading(true);
                        setOptionList([]);
                        setTimeout(() => fetchData(e.target.value), 300);
                    },
                    onChange: () => setForceOpen(false),
                })}
                {...(isLoading && {
                    suffix: <LoadingComponent size="small" />,
                })}
            />
        </AutoComplete>
    );
};

export default FilterSearchInput;
