import { AlertOutlined, MessageOutlined } from "@ant-design/icons";
import { Button, Divider, List, Skeleton, message } from "antd";
import { formatDistanceToNow } from "date-fns";
import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { APIs, apiRequest } from "../../services/apiConfig";
import { ErrorCatchValidator, ErrorMessageHandler } from "../../utils/Common";
import { NotifResult } from "../../constants/type";
import { SUCCESS_FAILED } from "../../constants";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { clearNotifCountByX, decrementNotifCount } from "../../store/reducers/system";

interface NotificationTabProps {
    type: string;
    notificationTotal: any;
    items: any[];
    setTotalCallback?: (newTotal: any) => void;
    setListCallback?: (newListData: any) => void;
}

const NotificationTab = (props: NotificationTabProps) => {
    const systemState = useAppSelector((state: any) => state.system);
    const dispatch = useAppDispatch();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [limit, setLimit] = useState<number>(10);

    const loadMoreData = () => {
        if (isLoading) {
            return;
        }
        if (props.items.length < props.notificationTotal.total) {
            let newLimit = limit + 10;
            setIsLoading(true);
            apiRequest(APIs.GET_NOTIFICATION_CONTENTS_LIST, {
                current: 1,
                limit: newLimit,
                order: "CreatedDateUtc,DESC",
                type: 1,
                levels: [props.type === "3,4" ? [3, 4] : [1, 2]],
            })
                .then((data: any) => {
                    let tmp: any = {},
                        tmpTotal: any = {};
                    tmp[props.type] = [...data[0].result];
                    tmpTotal[props.type] = { total: data[0].total, unReadTotal: data[0].unReadTotal };
                    props.setListCallback && props.setListCallback(tmp);
                    props.setTotalCallback && props.setTotalCallback(tmpTotal);
                    setLimit(newLimit);
                    setIsLoading(false);
                })
                .catch((error: any) => {
                    ErrorCatchValidator(error, (err: any) => {
                        console.log("Failed to get notifications list: ", err);
                        ErrorMessageHandler("notifications list", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                    });
                    setIsLoading(false);
                });
        }
    };

    return (
        <>
            <div className="top-panel">
                <span className="text">LATEST</span>
                {props.notificationTotal.unReadTotal > 0 && (
                    <Button
                        type="link"
                        onClick={() => {
                            apiRequest(APIs.UPDATE_NOTIFICATION_AS_READ, {
                                contentId: 0,
                                levelIds: props.type === "3,4" ? [3, 4] : [1, 2],
                            })
                                .then(() => {
                                    if (systemState.notifCount > 0) dispatch(clearNotifCountByX(props.notificationTotal.unReadTotal));
                                    let tmp: any = {},
                                        tmpTotal: any = {};
                                    let newData = props.items.map((currItem: NotifResult) => {
                                        return {
                                            ...currItem,
                                            isRead: true,
                                        };
                                    });
                                    tmp[props.type] = [...newData];
                                    tmpTotal[props.type] = { total: props.notificationTotal.total, unReadTotal: 0 };
                                    props.setListCallback && props.setListCallback(tmp);
                                    props.setTotalCallback && props.setTotalCallback(tmpTotal);
                                })
                                .catch((error: any) =>
                                    ErrorCatchValidator(error, (err: any) => {
                                        message.error(`Failed to mark all as read: ${err}`, 3);
                                    })
                                );
                        }}
                    >
                        Mark all as read
                    </Button>
                )}
            </div>
            <div id="scrollableDiv" className="nice-scrollbar">
                <InfiniteScroll
                    dataLength={props.items.length}
                    next={loadMoreData}
                    hasMore={props.items.length < props.notificationTotal.total}
                    loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
                    endMessage={
                        <Divider plain style={{ color: "#a9a9a9" }}>
                            That's all of your notifications.
                        </Divider>
                    }
                    scrollableTarget="scrollableDiv"
                >
                    <List
                        dataSource={props.items}
                        renderItem={(item: NotifResult) => (
                            <div
                                className="notification-item"
                                onClick={() => {
                                    if (!item.isRead) {
                                        apiRequest(APIs.UPDATE_NOTIFICATION_AS_READ, {
                                            contentId: item.contentId,
                                        })
                                            .then(() => {
                                                if (systemState.notifCount > 0) dispatch(decrementNotifCount());
                                                let tmp: any = {},
                                                    tmpTotal: any = {};
                                                let newData = props.items.map((currItem: NotifResult) => {
                                                    return {
                                                        ...currItem,
                                                        isRead: currItem.contentId === item.contentId ? true : currItem.isRead,
                                                    };
                                                });
                                                tmp[props.type] = [...newData];
                                                tmpTotal[props.type] = {
                                                    total: props.notificationTotal.total,
                                                    unReadTotal: props.notificationTotal.unReadTotal - 1,
                                                };
                                                props.setListCallback && props.setListCallback(tmp);
                                                props.setTotalCallback && props.setTotalCallback(tmpTotal);
                                            })
                                            .catch((error: any) =>
                                                ErrorCatchValidator(error, (err: any) => console.log("Failed to mark notif item as read: ", err))
                                            );
                                    }
                                }}
                            >
                                <div className="icon-panel">
                                    {props.type === "1,2" && (
                                        <span className="information">
                                            <MessageOutlined style={{ fontSize: "12px" }} />
                                        </span>
                                    )}
                                    {props.type === "3,4" && (
                                        <span className={item.levelId === 4 ? "critical" : ""}>
                                            <AlertOutlined style={{ fontSize: "12px" }} />
                                        </span>
                                    )}
                                </div>
                                <div className="content">
                                    <div className="content-header">
                                        <div className="title">
                                            {`${props.type === "3,4" ? "Attention:" : ""}`} {JSON.parse(item.content).subject}
                                        </div>
                                        {!item.isRead && <span className="status-dot"></span>}
                                    </div>
                                    <div className="sub-content">
                                        {/* note: gonna make sure send from trusted source only */}
                                        <div dangerouslySetInnerHTML={{ __html: JSON.parse(item.content).content }} />
                                        {/* {JSON.parse(item.content)
                                            .content.split("\\r\\n")
                                            .map((line: string, index: number) => (
                                                <p key={index} style={{ marginBottom: 0 }}>
                                                    {line}
                                                </p>
                                            ))} */}
                                    </div>
                                    <div className="footer-panel">
                                        <span className="time-desc">{formatDistanceToNow(new Date(item.createdDateUtc))}</span>
                                        <span className="dot-style"></span>
                                        <span>By {item.createdByName}</span>
                                    </div>
                                </div>
                            </div>
                        )}
                    />
                </InfiniteScroll>
            </div>
        </>
    );
};

export default NotificationTab;
