import React, { useState, useEffect } from "react";
import { Badge, Space, Tabs } from "antd";
import TabAlarmHistory from "./TabAlarmHistory";
import PAAlarmServerGroup from "./PAAlarmServerGroup";
import useRCPriceAnalysisAlarmLatest from "../../../hooks/useRCPriceAnalysisAlarmLatest";

const PriceAnalysisAlarm = () => {
    const [currentTab, setCurrentTab] = useState<string>("1");
    const { rcPALatestList } = useRCPriceAnalysisAlarmLatest(43200);

    return (
        <>
            <div className="price-analysis-alarm-container">
                <Tabs
                    activeKey={currentTab}
                    onChange={(activeKey: string) => setCurrentTab(activeKey)}
                    type="card"
                    tabBarStyle={{ marginBottom: "0px" }}
                    items={[
                        {
                            label: (
                                <Space>
                                    Monitor Wall
                                    <Badge className="site-badge-count-109" count={rcPALatestList?.length} style={{ backgroundColor: "red" }} />
                                </Space>
                            ),
                            key: "1",
                            children: <PAAlarmServerGroup />,
                        },
                        {
                            label: "Alarm History",
                            key: "2",
                            children: <TabAlarmHistory />,
                        },
                    ]}
                />
            </div>
        </>
    );
};

export default PriceAnalysisAlarm;
