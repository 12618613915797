import { useCallback, useMemo } from "react";
import FlexiDataTable from "../../../../../components/FlexiDataTable";
import { CALLBACK_KEY } from "../../../../../constants";
import { FlexiDataTableOptionsProps, RejectRecordChildren } from "../../../../../constants/type";
import AuthHelper, { AuthKeys } from "../../../../../helpers/authHelper";
import { DTColProps } from "../../../../../utils/Common";
import { plainAxiosInstance } from "../../../../../services/axiosSetup";
import { APIs } from "../../../../../services/apis";
import { Button, message, Modal, Popconfirm, Space, Tag } from "antd";
import { CheckCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import moment from "moment";
import { statusMap } from "./recordHistory";

interface RejectRecordExpandableTableProps {
    record: any[];
    callback?: (type: CALLBACK_KEY, data?: any) => void;
}

const RejectRecordExpandableTable = (props: RejectRecordExpandableTableProps) => {
    const columns = useMemo(
        () => [
            DTColProps.DateTime({
                title: "Date Time",
                dataIndex: "dateTime",
                key: "dateTime",
                sorter: (a: RejectRecordChildren, b: RejectRecordChildren) => (moment(a.dateTime) > moment(b.dateTime) ? -1 : 1),
                render: (dateTime: string) => <span>{dateTime ? moment(dateTime).format("YYYY-MM-DD HH:mm:ss") : ""}</span>,
            }),
            DTColProps.XSmall({
                title: "Duration (HH:mm:ss)",
                dataIndex: "duration",
                key: "duration",
                render: (duration: string) => (
                    <span>{duration ? moment.utc(moment.duration(duration).asMilliseconds()).format("HH:mm:ss") : ""}</span>
                ),
            }),
            DTColProps.XSmall({
                title: "Server",
                dataIndex: "server",
                key: "server",
                sorter: (a: any, b: any) => a.server - b.server,
            }),
            DTColProps.Small({
                title: "Login",
                dataIndex: "login",
                key: "login",
                sorter: (a: any, b: any) => a.login - b.login,
            }),
            DTColProps.Small({
                title: "Status",
                dataIndex: "status",
                key: "status",
                sorter: (a: any, b: any) => a.status - b.status,
                render: (status: string | number) => {
                    const { text, color } = statusMap[status] || { text: "Unknown", color: "default" };
                    return <Tag color={color}>{text}</Tag>;
                },
            }),
            DTColProps.Small({
                title: "Download User",
                dataIndex: "downloadUser",
                key: "downloadUser",
                render: (downloadUser: string) => (downloadUser ? downloadUser : "-"),
            }),
            {
                title: "Comment",
                dataIndex: "comment",
                align: "center",
                render: (comment: string, rowData: RejectRecordChildren) => {
                    if (rowData.status == 3) {
                        return <div>{comment ? comment : "Unknown Fail"}</div>;
                    } else {
                        return "";
                    }
                },
            },
            DTColProps.Small({
                title: "",
                dataIndex: "action",
                key: "Action",
                align: "right",
                render: (action: string, rowData: RejectRecordChildren) => (
                    <Space>
                        <Button
                            className="m-1"
                            type="primary"
                            icon={<CheckCircleOutlined />}
                            onClick={() => solved(rowData)}
                            disabled={!rowData.downloadUser}
                        >
                            Solved
                        </Button>
                        {rowData.status === 3 && (
                            <Popconfirm
                                key={"order-del"}
                                title="Would you want to remove this record?"
                                onConfirm={() => props.callback && props.callback(CALLBACK_KEY.DO_DELETE, rowData)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button type="primary" danger icon={<DeleteOutlined />} />
                            </Popconfirm>
                        )}
                    </Space>
                ),
            }),
        ],
        [props.record]
    );

    const solved = useCallback(
        async (data: RejectRecordChildren) => {
            const newData = {
                dateTime: data.dateTime,
                duration: data.duration,
                server: data.server,
                login: data.login,
                status: data.status,
                downloadUser: data.downloadUser,
                comment: data.comment,
            };
            Modal.confirm({
                title: "Solved " + data.server + ":" + data.login + "?",
                content: "",
                onOk: () => {
                    plainAxiosInstance
                        .put(APIs.RISK_TOOL.GET_ORDER_ROUTING_RECORD, [newData])
                        .then((res: any) => {
                            if (res.status === 200) {
                                message.success("Solved Success").then(() => {
                                    props.callback && props.callback(CALLBACK_KEY.REFRESH);
                                });
                            } else {
                                message.error(res.data.msg ? res.data.msg : "Failed to solve order reject record. Please try again later.");
                            }
                        })
                        .catch((err: any) => {
                            message.error(`Failed to solve order reject record. Error: ${err.message}`);
                        })
                },
            });
        },
        [props.record]
    );

    const options: FlexiDataTableOptionsProps = useMemo(
        () => ({
            enableFilter: false,
            showHideColumns: false,
            recordRowClassName: (record: RejectRecordChildren) => {
                return record.alarmStatus === 1 ? "alert-bg-error" : "";
            },
        }),
        [props.record]
    );

    return (
        <div className="order-routing-record-reject-expanded-table-container">
            <FlexiDataTable
                rowKeyProperty="uniqueKey"
                title={false}
                columns={columns}
                options={options}
                dataSource={props.record}
                callback={() => {}}
                loading={false}
                scroll={{ y: 280 }}
            />
        </div>
    );
};

export default RejectRecordExpandableTable;
