import { Space } from "antd";
import AverageSpreadAnalysisFilters from "./AverageSpreadAnalysisFilters";
import AverageSpreadAnalysisRecords from "./AverageSpreadAnalysisRecords";
import CommonPaddingWrapper from "../CommonPaddingWrapper";

const AverageSpreadAnalysisPage = () => {
    return (
        <Space style={{ width: "100%" }} direction="vertical" size="large">
            <AverageSpreadAnalysisFilters />
            <CommonPaddingWrapper>
                <AverageSpreadAnalysisRecords />
            </CommonPaddingWrapper>
        </Space>
    );
};

export default AverageSpreadAnalysisPage;
