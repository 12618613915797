import { useCallback, useEffect, useMemo, useState } from "react";
import FlexiDataTable from "@/components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "@/constants";
import { FlexiDataTableCallbackProps, FlexiDataTableOptionsProps, KeyValuePair } from "@/constants/type";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Form, message, Modal, Table, Upload } from "antd";
import ViewHistoryModal from "./components/ViewHistoryModal";
import { CidHistoryData, CidLabelSettingsList } from "./components/type";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { APIs } from "@/services/apis";
import moment from "moment";

interface HistoryProps {
    servers: KeyValuePair[];
    labels: CidLabelSettingsList[];
};

const History = (props: HistoryProps) => {
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.CID_EDIT);

    const [data, setData] = useState<CidHistoryData[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isExporting, setIsExporting] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    const [isHistoryModalOpen, setIsHistoryModalOpen] = useState<boolean>(false);
    const [isAddAction, setIsAddAction] = useState<boolean>(false);
    const [historyForm] = Form.useForm();
    const labels = useMemo(() => props.labels, [props.labels]);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(true);
    const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
    const [isUploading, setIsUploading] = useState<boolean>(false);

    const columns = [
        DTColProps.DateTime_ServerDateOnly({
            title: "Date",
            dataIndex: "updateDate",
            key: "updateDate",
            options: {
                filter: {
                    type: ComponentType.daterange,
                    value: "",
                    dateFormat: "YYYY-MM-DD",
                    inputProps: {
                        showTime: false,
                        dateOnly: true,
                    },
                    callback: (filterValues: any, rowData: CidHistoryData) => {
                        if (filterValues.length === 0) return true;
                        else {
                            const filterStartDate = moment(filterValues[0] as moment.Moment).startOf("day").format("YYYY-MM-DD");
                            const filterEndDate = moment(filterValues[1] as moment.Moment).startOf("day").format("YYYY-MM-DD");
                            return filterStartDate <= rowData["updateDate"] && rowData["updateDate"] <= filterEndDate;
                        };
                    },
                },
            },
        }),
        DTColProps.Small({
            title: "Server",
            dataIndex: "mainServerId",
            key: "mainServerId",
            sorter: (a: any, b: any) => a.mainServerId - b.mainServerId,
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.Small({
            title: "Login",
            dataIndex: "login",
            key: "login",
            sorter: (a: any, b: any) => a.login - b.login,
            options: {
                filter: {
                    type: ComponentType.number,
                    value: "",
                },
            },
        }),
        DTColProps.Middle({
            title: "CID",
            dataIndex: "cid",
            key: "cid",
            sorter: (a: any, b: any) => a.cid.localeCompare(b.cid),
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.Small({
            title: "Label",
            dataIndex: "labelName",
            key: "labelName",
            sorter: (a: any, b: any) => a.labelName.localeCompare(b.labelName),
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
    ];

    const handleModalCancel = () => {
        setIsHistoryModalOpen(false);
        historyForm.resetFields();
    };

    const options: FlexiDataTableOptionsProps = useMemo(() => ({
        export: { text: "Download" },
        add: enableUpdate,
        edit: enableUpdate,
        delete: enableUpdate,
        separateActionButton: true,
        ...(enableUpdate && {
            extraButtons: () => {
                return (
                    <div className="extra-header-buttons" key={"rccidh-extra-buttons"}>
                        <Button
                            key={"rccidh-batch-del"}
                            icon={<DeleteOutlined />}
                            loading={isDeleting}
                            disabled={selectedRowKeys.length === 0 || isLoading}
                            onClick={() => componentCallback(CALLBACK_KEY.OTHERS, "batch-delete")}
                        >
                            Batch Delete
                        </Button>
                        <Upload
                            key={"rccidh-batch-upload"}
                            showUploadList={false}
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            beforeUpload={(file => {
                                return new Promise((resolve, reject) => {
                                    uploadCidHistory(file);
                                    reject();
                                })
                            })}
                        >
                            <Button icon={<UploadOutlined />} loading={isUploading}>Upload Excel</Button>
                        </Upload>
                    </div>
                );
            },
            enableRowSelection: true,
            hideRowSelectionsSummary: true,
            rowSelectionData: {
                rowSelectionType: "checkbox",
                selectedRowKeys: selectedRowKeys,
                options: {
                    fixed: "left",
                    selections: [
                        Table.SELECTION_ALL,
                        Table.SELECTION_NONE,
                    ],
                    preserveSelectedRowKeys: true,
                },
            },
        }),
    }), [enableUpdate, selectedRowKeys, isLoading, isDeleting, isUploading]);

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                setIsAddAction(true);
                setIsHistoryModalOpen(true);
                break;
            case CALLBACK_KEY.DO_EDIT:
                let newFormData = {
                    ...FormData,
                    labelId: FormData.labelId.split(","),
                    updateDate: moment(FormData.updateDate),
                };
                setIsHistoryModalOpen(true);
                setIsAddAction(false);
                historyForm.setFieldsValue(newFormData);
                break;
            case CALLBACK_KEY.EXPORT_CSV_EXCEL:
                downloadCidHistory();
                break;
            case CALLBACK_KEY.ROW_SELECTION_CALLBACK:
                setSelectedRowKeys(FormData.selectedRowKeys);
                break;
            case CALLBACK_KEY.OTHERS:
                if (FormData === "batch-delete") {
                    batchDeleteCidHistory();
                };
                break;
            case CALLBACK_KEY.DO_DELETE:
                setIsLoading(true);
                plainAxiosInstance
                    .delete(`${APIs.RC_CID.DELETE_BATCH_CID_HISTORY}?id=${FormData.newKey}`)
                    .then((res: any) => {
                        if (res.data === 0) {
                            ErrorMessageHandler("CID history", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                            setRunRefetchDataList(true);
                            setSelectedRowKeys([]);
                        } else {
                            message.error(`Failed to delete CID history${res.data.length > 0 ? `: ${res.data}` : ""}`, 3);
                            setIsLoading(false);
                        };
                    })
                    .catch((error: any) => ErrorCatchValidator(error, (err: any) => {
                        ErrorMessageHandler("CID history", SUCCESS_FAILED.FAILED_DELETE_DATA, err);
                        setIsLoading(false);
                    }));
                break;
            default:
                break;
        };
    };

    const batchDeleteCidHistory = useCallback(() => {
        Modal.confirm({
            title: "Are you sure you want to delete these whitelist settings?",
            content: <ul>
                {selectedRowKeys.map((x: string) => {
                    let currRowData = x.split("-");  // record.cid|record.mainServerId|record.login
                    return (
                        <li key={x}>
                            {currRowData[0]} ({currRowData[1]} - {currRowData[2]})
                        </li>
                    )
                })}
            </ul>,
            width: "30%",
            okText: "Confirm",
            onOk: () => {
                setIsLoading(true);
                setIsDeleting(true);
                plainAxiosInstance
                    .delete(`${APIs.RC_CID.DELETE_BATCH_CID_HISTORY}?id=${selectedRowKeys.join(",")}`)
                    .then((res: any) => {
                        if (res.data === 0) {
                            ErrorMessageHandler("CID history(s)", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                            setRunRefetchDataList(true);
                            setSelectedRowKeys([]);
                        } else {
                            message.error(`Failed to delete CID history(s)${res.data.length > 0 ? `: ${res.data}` : ""}`, 3);
                            setIsLoading(false);
                        };
                    })
                    .catch((error: any) => ErrorCatchValidator(error, (err: any) => {
                        ErrorMessageHandler("CID history(s)", SUCCESS_FAILED.FAILED_DELETE_DATA, err);
                        setIsLoading(false);
                    }))
                    .finally(() => setIsDeleting(false));
            },
        });
    }, [selectedRowKeys]);

    const onFormSubmit = (obj: any) => {
        setIsBtnLoading(true);
        if (isAddAction) {
            plainAxiosInstance
                .post(`${APIs.RC_CID.POST_CREATE_CID_HISTORY}`, {
                    ...obj,
                    labelId: obj.labelId.toString(),
                })
                .then((res: any) => {
                    if (res.data > 0) {
                        ErrorMessageHandler("CID history", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                        setIsHistoryModalOpen(false);
                        setRunRefetchDataList(true);
                        historyForm.resetFields();
                    } else {
                        message.error("Failed to create CID history due to duplication of existing data.", 3);
                    };
                })
                .catch((error: any) => ErrorCatchValidator(error, (err: any) => {
                    ErrorMessageHandler("CID history", SUCCESS_FAILED.FAILED_CREATE_DATA, err);
                }))
                .finally(() => setIsBtnLoading(false));
        } else {
            plainAxiosInstance
                .post(`${APIs.RC_CID.POST_UPDATE_CID_HISTORY}`, {
                    ...obj,
                    labelId: obj.labelId.toString(),
                })
                .then((res: any) => {
                    if (res.data > 0) {
                        ErrorMessageHandler("CID history", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                        setIsHistoryModalOpen(false);
                        setRunRefetchDataList(true);
                        historyForm.resetFields();
                    } else {
                        message.error(`Failed to update CID history${res.data && `: ${res.data}`}`, 3);
                    };
                })
                .catch((error: any) => ErrorCatchValidator(error, (err: any) => {
                    ErrorMessageHandler("CID history", SUCCESS_FAILED.FAILED_UPDATE_DATA, err);
                }))
                .finally(() => setIsBtnLoading(false));
        };
    };

    const getCidHistoryData = () => {
        plainAxiosInstance
            .get(`${APIs.RC_CID.GET_CID_HISTORY}`)
            .then((res: any) => {
                if (res.status === 200) {
                    let newData: any = res.data.map((x: CidHistoryData) => ({
                        ...x,
                        newKey: `${x.cid}-${x.mainServerId}-${x.login}`,
                    }));
                    setData(newData);
                } else setData([]);
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => {
                ErrorMessageHandler("CID history", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                setData([]);
            }))
            .finally(() => setIsLoading(false));
    };

    const downloadCidHistory = () => {
        setIsExporting(true);
        plainAxiosInstance
            .post(`${APIs.RC_CID.POST_CID_HISTORY_DOWNLOAD}`,
                {},
                {
                    /**
                     *  12/09/2024 - Xin
                     *  not sure to include this or not, just leaving it here just in case
                     *  - because GET method included the headers but POST method doesn't in RC
                     */
                    // headers: {
                    //     Accept: "application/octet-stream, */*",
                    // },
                    responseType: "blob",
                }
            )
            .then(res => {
                const fileName = `Cid_history-${moment().format("YYYY-MM-DD_HH_mm")}.xlsx`;
                const contentType = res.headers["content-type"];
                if (
                    contentType === "application/octet-stream" ||
                    contentType === "text/csv" ||
                    contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ) {
                    // Handle the file download response
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", (`${fileName}`)); // or any other extension
                    link.setAttribute("type", "hidden");
                    document.body.appendChild(link);
                    link.click();
                    if (link.parentNode) {
                        link.parentNode.removeChild(link); // Clean up and remove the link
                    } else document.body.removeChild(link);
                    // Clean up
                    window.URL.revokeObjectURL(url);
                } else {
                    message.error(`Received non-file response. Error: ${res}`, 3);
                }
            })
            .catch(err => {
                message.error(`Download error: ${err}`, 3);
            })
            .finally(() => setIsExporting(false));
    };

    const uploadCidHistory = (file: any) => {
        Modal.confirm({
            title: "Are you sure you want to upload?",
            width: "30%",
            okText: "Confirm",
            onOk: () => {
                setIsLoading(true);
                setIsUploading(true);
                const formData = new FormData();
                formData.append("uploadFile", file);
                plainAxiosInstance
                    .post(`${APIs.RC_CID.POST_UPLOAD_CID_HISTORY}`, formData)
                    .then((res: any) => {
                        if (res.status === 200) {
                            if (res.data === 0) {
                                ErrorMessageHandler("CID history", SUCCESS_FAILED.SUCCESS_UPLOAD_DATA);
                                setRunRefetchDataList(true);
                            } else {
                                message.error(`Failed to upload CID history${res.data.length > 0 ? `: ${res.data}` : ""}`, 3);
                                setIsLoading(false);
                            };
                        };
                    })
                    .catch((error: any) => ErrorCatchValidator(error, (err: any) => {
                        ErrorMessageHandler("CID history", SUCCESS_FAILED.FAILED_UPLOAD_DATA, err);
                        setIsLoading(false);
                    }))
                    .finally(() => setIsUploading(false));
            },
        });
    };

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            getCidHistoryData();
            setRunRefetchDataList(false);
        };
        return () => { };
    }, [runRefetchDataList]);

    return (
        <div className="history-tab">
            <FlexiDataTable
                rowKeyProperty={"newKey"}  // record.cid|record.mainServerId|record.login
                title={""}
                columns={columns}
                options={options}
                callback={componentCallback}
                dataSource={data}
                loading={isLoading}
                exporting={isExporting}
            />
            <Modal
                width={700}
                title={`${isAddAction ? "Create New" : "Edit"} History Details`}
                maskClosable={false}
                open={isHistoryModalOpen}
                onCancel={handleModalCancel}
                footer={[
                    <Button key={"cancel"} onClick={handleModalCancel}>
                        Cancel
                    </Button>,
                    <Button key={"regular-submit"} type="primary" htmlType="submit" loading={isBtnLoading} onClick={() => {
                        historyForm
                            .validateFields()
                            .then((res) => onFormSubmit(res))
                            .catch((err) => {
                                message.error("Failed to submit form. Please check the form for errors.");
                                // console.log("form-error:", err);
                            });
                    }}>
                        Submit
                    </Button>,
                ]}
            >
                <ViewHistoryModal form={historyForm} isAddAction={isAddAction} servers={props.servers} labels={labels} />
            </Modal>
        </div >
    );
};

export default History;